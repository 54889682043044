<div class="initiate-submission-container padding-bottom-s margin-top-l">
  <div class="margin-top-l">
    <div
      class="margin-top-l d-flex flex-row justify-content-between align-items-center"
    >
      <h3 class="font-size-24 font-bold padding-top-es text-align-left">
        Initiate new global submission
      </h3>
      <div class="d-flex">
        <dds-button
          *ngIf="
            permissionObj['isGlobalEngDefineEngagementConfirmAndProceedVisible']
          "
          class="custom-button margin-right-m"
          [theme]="primaryButton.theme"
          [kind]="primaryButton.kind"
          [size]="htmlElementSize"
          [ariaLabel]="primaryButton.arialLabel"
          [width]="primaryButton.width"
          [disabled]="
            !permissionObj[
              'isGlobalEngDefineEngagementConfirmAndProceedEnable'
            ] || !isSubmitDisabled()
          "
          (clicked)="onSubmit()"
          >Confirm and proceed</dds-button
        >
        <dds-button
          *ngIf="permissionObj['isGlobalEngDefineEngagementCancelVisible']"
          class="custom-button"
          [theme]="secondaryButton.theme"
          [kind]="secondaryButton.kind"
          [size]="htmlElementSize"
          [width]="secondaryButton.width"
          [ariaLabel]="secondaryButton.ariaLabel"
          [disabled]="!permissionObj['isGlobalEngDefineEngagementCancelEnable']"
          (clicked)="onCancel()"
          >Cancel</dds-button
        >
      </div>
    </div>
  </div>
  <hr />
  <div
    *ngIf="permissionObj['isGlobalEngInitiationSubmissionTypeVisible']"
    class="submission-type-section text-align-left margin-top-m"
  >
    <div class="d-flex margin-top-m">
      <div class="d-flex">
        <span *ngIf="!submissionType">
          <img
            class="margin-right-xxs"
            src="\assets\images\draftIcon.svg"
            alt="Draft Icon"
          />
        </span>
        <span
          class="text-label font-bold-600 font-size-16 line-height-m margin-bottom-xs"
        >
          Select submission type
        </span>
      </div>
    </div>
    <div class="d-flex">
      <div>
        <div class="select-submission margin-top-small margin-bottom-s">
          <dds-select
            class="custom-select"
            [list]="submissionTypeList"
            [(ngModel)]="submissionType"
            [size]="htmlElementSize"
            [placeholder]="'Select type'"
            [stickerMaxHeight]="300"
            [disabled]="
              !permissionObj['isGlobalEngInitiationSubmissionTypeEnable']
            "
          >
          </dds-select>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="submissionType" class="submission-type-section">
    <hr />
    <div class="text-align-left">
      <span
        class="text-label font-bold-600 font-size-16 line-height-m margin-bottom-m margin-top-s"
      >
        Define engagement
      </span>
      <br />
      <div>
        <div class="padding-top-xs">
          <dds-radio-group
            class="d-flex"
            autosave
            [(ngModel)]="engagementType"
            [name]="'engagementType'"
            (ngModelChange)="onEngagementTypeChange($event)"
          >
            <dds-radio
              *ngIf="
                permissionObj[
                  'isGlobalEngDefineEngagementSelectExistingVisible'
                ]
              "
              class="margin-right-m"
              [value]="'Select existing'"
              [label]="'Select existing'"
              [disabled]="
                !permissionObj[
                  'isGlobalEngDefineEngagementSelectExistingEnable'
                ]
              "
            >
            </dds-radio>

            <dds-radio [value]="'Create new'" [label]="'Create new'">
            </dds-radio>
          </dds-radio-group>
        </div>
      </div>
      <div *ngIf="showCreateNewDiv">
        <div
          *ngIf="
            permissionObj[
              'isGlobalEngDefineEngagementSelectDeliveryCenterVisible'
            ]
          "
          class="submission-type-section text-align-left margin-top-m"
        >
          <div class="d-flex margin-top-m">
            <div class="d-flex">
              <span *ngIf="!deliveryCenter">
                <img
                  class="margin-right-xxs"
                  src="\assets\images\draftIcon.svg"
                  alt="Draft Icon"
                />
              </span>
              <span
                class="text-label font-bold-400 font-size-14 line-height-m margin-bottom-xs"
              >
                Select Delivery Center:
              </span>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="select-submission margin-top-small margin-bottom-s">
                <dds-select
                  class="custom-select"
                  [list]="deliveryCenterList"
                  [(ngModel)]="deliveryCenter"
                  [size]="htmlElementSize"
                  [placeholder]="'Select'"
                  [stickerMaxHeight]="300"
                  [disabled]="
                    !permissionObj[
                      'isGlobalEngDefineEngagementSelectDeliveryCenterEnable'
                    ]
                  "
                >
                </dds-select>
              </div>
            </div>
          </div>
        </div>
        <ng-container
          *ngIf="
            permissionObj['isGlobalEngDefineEngagementSelectMemberFirmVisible']
          "
        >
          <div class="d-flex margin-top-m">
            <div class="d-flex">
              <span *ngIf="!memberFirm">
                <img
                  class="margin-right-xxs"
                  src="\assets\images\draftIcon.svg"
                  alt="Draft Icon"
                />
              </span>
              <span
                class="text-label font-bold-400 font-size-14 line-height-m margin-bottom-xs"
              >
                Select member firm:
              </span>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="select-submission margin-top-small margin-bottom-s">
                <dds-select
                  class="custom-select"
                  [list]="memberFirmList"
                  [(ngModel)]="memberFirm"
                  [size]="htmlElementSize"
                  [placeholder]="'Select type'"
                  [stickerMaxHeight]="300"
                  [disabled]="
                    !permissionObj[
                      'isGlobalEngDefineEngagementSelectMemberFirmEnable'
                    ]
                  "
                >
                </dds-select>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              memberFirm === memberfirmOtherOption &&
              permissionObj[
                'isGlobalEngDefineEngagementEnterMemberFirmNameVisible'
              ]
            "
          >
            <div class="d-flex margin-top-m">
              <div class="d-flex">
                <span *ngIf="!otherFirmName">
                  <img
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                    alt="Draft Icon"
                  />
                </span>
                <span
                  class="text-label font-bold-400 font-size-14 line-height-m margin-bottom-xs"
                >
                  Enter the member firm's name
                </span>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <div class="select-submission margin-top-small margin-bottom-s">
                  <dds-textarea
                    [maxLength]="100"
                    [maxLengthPosition]="'bottom-right'"
                    [(ngModel)]="otherFirmName"
                    [disabled]="
                      !permissionObj[
                        'isGlobalEngDefineEngagementEnterMemberFirmNameEnable'
                      ]
                    "
                  >
                  </dds-textarea>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            permissionObj['isGlobalEngDefineEngagementSelectOpportunityVisible']
          "
        >
          <div>
            <div class="d-flex margin-top-m">
              <div class="d-flex">
                <span
                  *ngIf="
                    opportunityDetailsList.length === 0 && !showClientDetails
                  "
                >
                  <img
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                    alt="Draft Icon"
                  />
                </span>
                <span
                  class="text-label font-bold-400 font-size-14 line-height-m margin-bottom-xs"
                >
                  Select opportunity
                </span>
              </div>
            </div>
            <div>
              <div
                *ngIf="!showOpportunityDetails"
                class="margin-top-small margin-bottom-s"
              >
                <div class="d-flex align-items-center flex-row">
                  <div class="select-submission relative">
                    <dds-search
                      [size]="htmlElementSize"
                      [icon]="'dds-icon_search'"
                      (fieldCleared)="fieldCleared()"
                      [disabled]="
                        !permissionObj[
                          'isGlobalEngDefineEngagementSelectOpportunityEnable'
                        ]
                      "
                    >
                      <input
                        ddsSearch
                        [placeholder]="'Search by Jupiter opportunity ID'"
                        [(ngModel)]="selectedOportunity"
                        (ngModelChange)="selectOpportunityChanged($event)"
                        [disabled]="
                          !permissionObj[
                            'isGlobalEngDefineEngagementSelectOpportunityEnable'
                          ] || showClientDetails
                        "
                      />
                    </dds-search>
                    <div
                      *ngIf="isSpinnerVisible"
                      class="spinner-inside-input absolute"
                    >
                      <dds-spinner
                        [size]="spinnerOptions.size"
                        [theme]="spinnerOptions.theme"
                        [customClass]="spinnerOptions.customClass"
                        [isInverse]="spinnerOptions.isInverse"
                        [thickness]="spinnerOptions.thickness"
                      ></dds-spinner>
                    </div>
                  </div>
                  <div class="margin-left-xs">
                    <dds-checkbox-group>
                      <dds-checkbox
                        [label]="'Not available'"
                        (stateChanged)="notAvailabeStateChanged($event)"
                      ></dds-checkbox>
                    </dds-checkbox-group>
                  </div>
                </div>

                <div
                  *ngIf="showDropdown || notFound"
                  class="list-of-oppportunities d-flex flex-row-column flex-row-column padding-left-xs padding-right-xs margin-top-xs"
                  (scroll)="fetchOpportunitySearchResultMore($event)"
                >
                  <div *ngIf="!notFound" class="tabs-container">
                    <dds-tabs
                      [theme]="tabContainerOptions.theme ?? 'primary'"
                      [themeType]="tabContainerOptions.themeType ?? 'primary'"
                      [size]="tabContainerOptions.size ?? 'md'"
                      [isResponsive]="tabContainerOptions.isResponsive ?? true"
                      [disabled]="tabContainerOptions.disabled ?? false"
                      [ariaLabel]="tabContainerOptions.ariaLabel ?? 'Tabs'"
                      [activeIndex]="selectTabIndex"
                      class="text-align-left"
                    >
                      <span *ngFor="let tab of tabs">
                        <dds-tab
                          class="padding-right-xs"
                          [label]="tab.label ?? ''"
                          [counter]="tab.count ?? 0"
                          (tabChanged)="tabChanged(tab.code)"
                        >
                        </dds-tab>
                      </span>
                    </dds-tabs>
                  </div>
                  <div *ngFor="let item of opportunityList">
                    <div
                      class="opportunity-items d-flex flex-row-column"
                      (click)="
                        fetchOpportunityDetails(item, 'searchOpportunity')
                      "
                    >
                      <div
                        class="opportunity-header font-size-14 font-bold-600 text-align-left"
                      >
                        {{ item?.opportunityName }}
                        <span
                          class="client-status font-bold-400"
                          *ngIf="item?.stage == 'Closed Lost'"
                          >[Lost]</span
                        >
                        <span
                          class="client-status font-bold-400"
                          *ngIf="item?.stage == 'Closed Abandoned'"
                          >[Abandoned]</span
                        >
                      </div>
                      <div
                        class="opportunity-details font-size-12 text-align-left font-bold-400"
                      >
                        <span class="detail-heading font-bold-400">ID: </span>
                        <span
                          class="details-values font-size-12 text-align-left font-bold-400"
                          >{{ item?.opportunityNumber }}
                        </span>
                        <span class="separator"> | </span>
                        <span class="detail-heading font-bold-400"
                          >Opportunity stage:
                        </span>
                        <span
                          class="details-values font-size-12 text-align-left font-bold-400"
                          >{{ item?.stage }}</span
                        >
                        <span class="separator"> | </span>
                        <span class="detail-heading font-bold-400"
                          >Leader:
                        </span>
                        <span
                          class="details-values font-size-12 text-align-left font-bold-400"
                          >{{ item?.leader }}</span
                        >
                        <span class="separator"> | </span>
                        <span class="detail-heading font-bold-400"
                          >Client:
                        </span>
                        <span
                          class="details-values font-size-12 text-align-left font-bold-400"
                          >{{ item?.client }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div [innerHTML]="notFound" class="not-found"></div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="showOpportunityDetails" class="opportunity-details-table">
            <table class="dds-data-table dds-data-table_fixed-header">
              <thead class="dds-data-table__header">
                <tr>
                  <th
                    *ngFor="let column of opportunityTableColumns"
                    class="dds-data-table__header-cell"
                    [ngStyle]="{
                      width: column.width,
                      minWidth: column.minWidth
                    }"
                  >
                    <div>
                      <span>{{ column.header }}</span>
                    </div>
                    <div class="dds-data-table__content">
                      <span> </span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody class="dds-data-table__body">
                <tr
                  *ngFor="let row of opportunityDetailsList; index as index"
                  class="dds-data-table__row font-size-14 font-bold-400 line-height-sm"
                >
                  <td
                    *ngFor="let column of opportunityTableColumns"
                    class="table-cell"
                  >
                    <span
                      *ngIf="column.name === 'numberOfSplits'"
                      class="icon-hover"
                      [ddsTooltip]="getNumberOfSplits(nonChangeOrder)"
                      [tooltipWidth]="tooltipWidth"
                      >{{
                        row[column.name] | ifEmpty : defaultEmptyFields
                      }}</span
                    >
                    <span
                      *ngIf="column.name !== 'numberOfSplits'"
                      class="text-block"
                      >{{
                        row[column.name] | ifEmpty : defaultEmptyFields
                      }}</span
                    >
                  </td>
                  <td class="relative cross-btn-top">
                    <dds-button
                      class="custom-icon"
                      [theme]="closeBtnOptions.theme ?? ''"
                      [kind]="closeBtnOptions.kind ?? ''"
                      [size]="closeBtnOptions.size ?? ''"
                      [width]="closeBtnOptions.width ?? ''"
                      [icon]="closeBtnOptions.icon ?? ''"
                      [isIconLeft]="closeBtnOptions.isIconLeft ?? false"
                      [role]="closeBtnOptions.role ?? ''"
                      [isInverse]="closeBtnOptions.isInverse ?? false"
                      [isColorBg]="closeBtnOptions.isColorBg ?? false"
                      [isLoading]="closeBtnOptions.isLoading ?? false"
                      [ariaLabel]="'Close'"
                      [customClass]="closeBtnOptions.customClass ?? ''"
                      (clicked)="removeOpportunity()"
                    ></dds-button>
                  </td>
                </tr>
                <tr
                  *ngFor="let row of opportunityList; index as index"
                  class="dds-data-table__row"
                >
                  <td *ngFor="let column of opportunityTableColumns">
                    <span
                      *ngIf="
                        column.name == 'client' &&
                        (row['watchlistStatus'] === 'Serve with conditions' ||
                          row['watchlistStatus'] === 'Do not serve')
                      "
                      [ngClass]="{
                        'serve-with-conditions-container':
                          row['watchlistStatus'] === 'Serve with conditions',
                        'do-not-serve-container':
                          row['watchlistStatus'] === 'Do not serve'
                      }"
                    >
                      {{
                        row["watchlistStatus"] | ifEmpty : defaultEmptyFields
                      }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>

        <div
          *ngIf="
            permissionObj['isGlobalEngDefineEngagementSelectClientVisible'] &&
            showClientDetails === true
          "
        >
          <div class="d-flex margin-top-m">
            <div class="d-flex">
              <span *ngIf="!clientNumber && !addClientManually">
                <img
                  class="margin-right-xxs"
                  src="\assets\images\draftIcon.svg"
                  alt="Draft Icon"
                />
              </span>
              <span
                class="text-label font-bold-400 font-size-14 line-height-m margin-bottom-xs"
              >
                Select end client
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div>
              <div class="select-submission margin-top-small margin-bottom-s">
                <app-client-picker
                  [showClientSelectionConfirmPopup]="false"
                  [showClientSelectedId]="true"
                  (clientSelected)="clientSearched($event)"
                  [disabled]="
                    !permissionObj[
                      'isGlobalEngDefineEngagementSelectClientEnable'
                    ]
                  "
                />
              </div>
            </div>
            <div class="margin-left-xs">
              <dds-checkbox-group>
                <dds-checkbox
                  [label]="'Add manually'"
                  (stateChanged)="addManuallyChecked($event)"
                ></dds-checkbox>
              </dds-checkbox-group>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            permissionObj[
              'isGlobalEngDefineEngagementEnterClientNameVisible'
            ] && addClientManually
          "
        >
          <div class="d-flex margin-top-m">
            <div class="d-flex">
              <span *ngIf="!clientName">
                <img
                  class="margin-right-xxs"
                  src="\assets\images\draftIcon.svg"
                  alt="Draft Icon"
                />
              </span>
              <span
                class="text-label font-bold-400 font-size-14 line-height-m margin-bottom-xs"
              >
                Enter the client's name
              </span>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="select-submission margin-top-small margin-bottom-s">
                <dds-textarea
                  [maxLength]="100"
                  [maxLengthPosition]="'bottom-right'"
                  [(ngModel)]="clientName"
                  [disabled]="
                    !permissionObj[
                      'isGlobalEngDefineEngagementEnterClientNameEnable'
                    ]
                  "
                >
                </dds-textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          showSelectExistingDiv &&
          permissionObj['isGlobalEngDefineEngagementSelectOpportunityVisible']
        "
      >
        <div class="d-flex margin-top-m">
          <div class="d-flex">
            <span *ngIf="!opportunityDetailsList">
              <img
                class="margin-right-xxs"
                src="\assets\images\draftIcon.svg"
                alt="Draft Icon"
              />
            </span>
            <span
              class="text-label font-bold-400 font-size-14 line-height-m margin-bottom-xs"
            >
              Select opportunity
            </span>
          </div>
        </div>
        <div class="d-flex">
          <div>
            <div
              *ngIf="!showOpportunityDetails"
              class="margin-top-small margin-bottom-s"
            >
              <div class="d-flex align-items-center">
                <div class="search-box-submission relative">
                  <dds-search
                    [size]="htmlElementSize"
                    [icon]="'dds-icon_search'"
                    (fieldCleared)="fieldCleared()"
                    [disabled]="
                      !permissionObj[
                        'isGlobalEngDefineEngagementSelectOpportunityEnable'
                      ]
                    "
                  >
                    <input
                      ddsSearch
                      [placeholder]="
                        'Search by submission ID, Opportunity, Client, or Responsible Delivery Center PPMD'
                      "
                      [disabled]="
                        !permissionObj[
                          'isGlobalEngDefineEngagementSelectOpportunityEnable'
                        ]
                      "
                      [(ngModel)]="selectedOportunity"
                      (ngModelChange)="selectOpportunityChanged($event)"
                    />
                  </dds-search>
                  <div
                    *ngIf="isSpinnerVisible"
                    class="spinner-inside-input absolute"
                  >
                    <dds-spinner
                      [size]="spinnerOptions.size"
                      [theme]="spinnerOptions.theme"
                      [customClass]="spinnerOptions.customClass"
                      [isInverse]="spinnerOptions.isInverse"
                      [thickness]="spinnerOptions.thickness"
                    ></dds-spinner>
                  </div>
                </div>
              </div>
              <div
                *ngIf="showDropdown || notFound"
                class="list-of-oppportunities d-flex flex-row-column flex-row-column padding-xs"
                (scroll)="fetchOpportunitySearchResultMore($event)"
              >
                <div *ngFor="let item of opportunityList">
                  <div
                    class="opportunity-items d-flex flex-row-column"
                    (click)="fetchOpportunityDetails(item, 'searchOpportunity')"
                  >
                    <div
                      class="opportunity-header font-size-14 font-bold-600 text-align-left"
                    >
                      {{ item?.engagementTitle }}
                    </div>
                    <div
                      class="opportunity-details font-size-12 text-align-left font-bold-400"
                    >
                      <span class="detail-heading font-bold-400"
                        >Engagement ID:
                      </span>
                      <span
                        class="details-values font-size-12 text-align-left font-bold-400"
                        >{{ item?.engagementID }}
                      </span>
                      <span class="separator"> | </span>
                      <span class="detail-heading font-bold-400"
                        >Jupiter ID:
                      </span>
                      <span
                        class="details-values font-size-12 text-align-left font-bold-400"
                        >{{ item?.jupiterOpportunityID }}</span
                      >
                      <span class="separator"> | </span>
                      <span class="detail-heading font-bold-400">Client: </span>
                      <span
                        class="details-values font-size-12 text-align-left font-bold-400"
                        >{{ item?.clientName }}</span
                      >
                      <span class="separator"> | </span>
                      <span class="detail-heading font-bold-400"
                        >Responsible Delivery Center PPMD:
                      </span>
                      <span
                        class="details-values font-size-12 text-align-left font-bold-400"
                        >{{ item?.responsibleDeliveryCenterPPMD }}</span
                      >
                    </div>
                  </div>
                </div>
                <div [innerHTML]="notFound" class="not-found"></div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showOpportunityDetails" class="opportunity-details-table">
          <table class="dds-data-table dds-data-table_fixed-header">
            <thead class="dds-data-table__header">
              <tr>
                <th
                  *ngFor="let column of engagementTableColumns"
                  class="dds-data-table__header-cell"
                  [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
                >
                  <div>
                    <span>{{ column.header }}</span>
                  </div>
                  <div class="dds-data-table__content">
                    <span> </span>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody class="dds-data-table__body">
              <tr
                *ngFor="let row of opportunityDetailsList; index as index"
                class="dds-data-table__row font-size-14 font-bold-400 line-height-sm"
              >
                <td
                  *ngFor="let column of engagementTableColumns"
                  class="table-cell"
                >
                  <span
                    *ngIf="column.name === 'numberOfSplits'"
                    class="icon-hover"
                    [ddsTooltip]="getNumberOfSplits(nonChangeOrder)"
                    [tooltipWidth]="tooltipWidth"
                    >{{ row[column.name] | ifEmpty : defaultEmptyFields }}</span
                  >
                  <span
                    *ngIf="column.name !== 'numberOfSplits'"
                    class="text-block"
                    >{{ row[column.name] | ifEmpty : defaultEmptyFields }}</span
                  >
                </td>
                <td class="relative cross-btn-top">
                  <dds-button
                    class="custom-icon"
                    [theme]="closeBtnOptions.theme ?? ''"
                    [kind]="closeBtnOptions.kind ?? ''"
                    [size]="closeBtnOptions.size ?? ''"
                    [width]="closeBtnOptions.width ?? ''"
                    [icon]="closeBtnOptions.icon ?? ''"
                    [isIconLeft]="closeBtnOptions.isIconLeft ?? false"
                    [role]="closeBtnOptions.role ?? ''"
                    [isInverse]="closeBtnOptions.isInverse ?? false"
                    [isColorBg]="closeBtnOptions.isColorBg ?? false"
                    [isLoading]="closeBtnOptions.isLoading ?? false"
                    [ariaLabel]="'Close'"
                    [customClass]="closeBtnOptions.customClass ?? ''"
                    (clicked)="removeOpportunity()"
                  ></dds-button>
                </td>
              </tr>
              <tr
                *ngFor="let row of opportunityList; index as index"
                class="dds-data-table__row"
              >
                <td *ngFor="let column of engagementTableColumns">
                  <span
                    *ngIf="
                      column.name == 'client' &&
                      (row['watchlistStatus'] === 'Serve with conditions' ||
                        row['watchlistStatus'] === 'Do not serve')
                    "
                    [ngClass]="{
                      'serve-with-conditions-container':
                        row['watchlistStatus'] === 'Serve with conditions',
                      'do-not-serve-container':
                        row['watchlistStatus'] === 'Do not serve'
                    }"
                  >
                    {{ row["watchlistStatus"] | ifEmpty : defaultEmptyFields }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
