<progress-indicator></progress-indicator>
<div class="tiles-container text-align-left relative">
  <div class="header">
    <app-page-header>
      <div class="header-custom-container row">
        <div class="d-flex align-items-center flex-item-1">
          <span
            class="dds-icon dds-icon_home__l__stroke cursor-clickable"
            (click)="backToHome()"
          ></span>
          <p class="title">External Communication</p>
          <div class="seperator"></div>
          <div ddsFocus>
            <select
              class="role-dropdown"
              [(ngModel)]="communicationType"
              [ngStyle]="{ width: getRoleWidth() }"
              (ngModelChange)="getExternalCommunications()"
            >
              <option
                *ngFor="let item of communicationTypeList"
                class="dropdownItems"
                [value]="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </app-page-header>
  </div>

  <div class="d-flex mt-3">
    <div class="row" *ngIf="submittedExternalCommunications?.length">
      <div class="width-left-menu-panel margin-top-xxs">
        <div
          class="sidebar position-top-intake sticky-element-after-black-header padding-top-s"
        >
          <div class="nav-items">
            <div
              *ngFor="
                let item of submittedExternalCommunications;
                let i = index;
                trackBy: trackByValue
              "
              class="nav-item"
              [class.active]="i === activeIndex"
              (click)="setActive(i)"
            >
              <span
                class="status-indicator"
                [ngClass]="
                  i === activeIndex
                    ? 'status-active-indicator'
                    : 'status-inactive-indicator'
                "
              ></span>
              <div class="nav-item-content" id="item.submissionId">
                <div class="icon-info-display">
                  <img src="\assets\images\file-icon.svg" alt="file img" />
                  <div>
                    <h4 [ddsTooltip]="item?.submissionTitle ?? ''" ddsFocus>
                      {{ item?.submissionTitle }}
                    </h4>
                    <div class="sub-info">
                      <span class="item-type">{{ extCommBoxValue }}</span>
                      <span
                        class="padding-xxs"
                        class="padding-xxs"
                        [style.color]="
                          transformStatus(item.submissionStatus)
                            | statusStyle : 'color'
                        "
                        [style.backgroundColor]="
                          transformStatus(item.submissionStatus)
                            | statusStyle : 'backgroundColor'
                        "
                        >{{ item.submissionStatus }}</span
                      >
                    </div>
                  </div>
                </div>
                <span *ngIf="!item.isFormCompleted" class="draft-status">
                  <img src="\assets\images\draftIcon.svg"
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="currentActiveSubmissionId"
      class="submission-detail left-border margin-top-xxs"
    >
      <app-external-communication-submission
        (submissionTitleChange)="onSubmissionTitleChange($event)"
        [submissionId]="currentActiveSubmissionId"
        [communicationType]="communicationType"
        [communicationTypeId]="communicationTypeId"
        [permissionObj]="permissionObj"
        (allRequiredFieldsFilled)="onRequiredFieldsFilledChange($event)"
        (updateLeftNav)="updateLeftNav($event)"
      ></app-external-communication-submission>
    </div>
  </div>
</div>
