import { RecentlyViewedComponent } from './../recently-viewed/recently-viewed.component';
import { Component } from '@angular/core';
import { ReviewerDashboardWebapiService } from '../../http/dashboard/reviewer-webapi.service';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { environment } from '../../../environment/environment';
import {
  DashboardDataCollection,
  DashboardGridLevelFilter,
  DashboardTileCode,
  GridColumn,
} from '../../common/models/start-page/start-page.model';
import {
  ReviewerDashboardData,
  ReviewerDashboardTilesCount,
  ReviewerTileCode,
  ReviewerTileCodeDetails,
} from '../../common/models/start-page/reviewer-dashboard.model';
import {
  buttonOptions,
  CCOverviewColumns,
  GridColumns,
  NCAPendingApprovalColumns,
  PendingReviewsColumns,
  PendingReviewsOtherColumns,
  RecentReviewsColumns,
  SelfReviewsColumns,
} from '../dashboard.helper';

import { Router } from '@angular/router';
import {
  defaultGridColumns,
  reviewerSortParamaterCode,
} from '../dashboard.helper';
import { StatusEnum } from '../../intake/constants/status-enum';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Subject, takeUntil } from 'rxjs';
import { IntakeConstant } from '../../intake/constants/intake.constant';

@Component({
  selector: 'app-reviewer-dashboard',
  templateUrl: './reviewer-dashboard.component.html',
  styleUrl: './reviewer-dashboard.component.less',
})
export class ReviewerDashboardComponent {
  public reviewerTiles = ReviewerTileCode;
  public reviewerTileCodeDetails = Array.from(ReviewerTileCodeDetails.values());
  public selectedTileCode: string = '';
  public dashboard: string = IntakeConstant.REVIEWER_DASHBOARD;
  public employeeId: string = '00000000';
  public reviewerDashboardTilesCountModel: ReviewerDashboardTilesCount | any;
  public sortParamaterCode: number = 1;
  public isAscending: boolean = true;
  public countforPagination: number = 0;
  public gotoPage: number = 1;
  public itemsPerPage: number = 10;
  public itemsPerPageOptions: number[] = [10, 25, 50];
  public gridTitle: string = '';
  public model: ReviewerDashboardData[] = [];
  public reviewerDashboardfilters: DashboardGridLevelFilter[] = [];
  public currentDashboardTileCode = DashboardTileCode.Reviewer;
  public rootUrl: string = environment.rootUrl;
  public gridColumns: GridColumn[] = [];
  options: ButtonOptions = buttonOptions;
  public buttonOptionsList: {
    label: string;
    action: string;
    condition: boolean;
  }[] = [];
  public defaultGridColumns: GridColumn[] = defaultGridColumns;
  public columnName = GridColumns;
  unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    private readonly modalService: ModalService,
    private readonly reviewerWebapiService: ReviewerDashboardWebapiService,
    private readonly progressIndicatorService: ProgressIndicatorService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.progressIndicatorService.show();
    this.selectedTileCode = this.reviewerTiles.PendingReviews;

    this.gridTitle =
      this.reviewerTileCodeDetails.find(
        (x) => x.tileCode === this.selectedTileCode
      )?.title || '';
    this.reviewerWebapiService
      .getReviewerDashboardTilesCount()
      .subscribe((response: ReviewerDashboardTilesCount) => {
        this.reviewerDashboardTilesCountModel = response;
      });
    this.sortParamaterCode = reviewerSortParamaterCode[this.selectedTileCode];
    this.gotoPage = 1;
    this.onPageChanged(this.gotoPage);
  }

  onTileSelected(selectedTileCode: string) {
    this.selectedTileCode = selectedTileCode;
    this.gridTitle =
      this.reviewerTileCodeDetails.find(
        (x) => x.tileCode === this.selectedTileCode
      )?.title || '';

    // Reset the filters and sorting state
    this.sortParamaterCode = reviewerSortParamaterCode[this.selectedTileCode];
    this.isAscending = true;
    this.reviewerDashboardfilters = [];
    // Reset the page number
    this.gotoPage = 1;
    this.onPageChanged(this.gotoPage);
  }

  refreshGrid() {
    this.progressIndicatorService.show();
    this.reviewerWebapiService
      .getReviewerDashBoardGridData(
        this.selectedTileCode,
        this.gotoPage,
        this.itemsPerPage,
        this.sortParamaterCode,
        this.isAscending,
        this.reviewerDashboardfilters
      )
      .subscribe((response: DashboardDataCollection<ReviewerDashboardData>) => {
        this.model = response.dataList;
        this.countforPagination = response.totalDataCount;
        this.progressIndicatorService.hide();
      });

    this.gridColumns = [...this.defaultGridColumns];

    if (this.selectedTileCode === this.reviewerTiles.PendingReviews) {
      this.gridColumns = PendingReviewsColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }
    if (this.selectedTileCode === this.reviewerTiles.PendingReviewsOthers) {
      this.gridColumns = PendingReviewsOtherColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.reviewerTiles.NCAPendingApproval) {
      this.gridColumns = NCAPendingApprovalColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.reviewerTiles.CCDueNow) {
      this.gridColumns = CCOverviewColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.reviewerTiles.RecentReviews) {
      this.gridColumns = RecentReviewsColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.reviewerTiles.SelfReviewChanges) {
      this.gridColumns = SelfReviewsColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    // Adjust column widths dynamically excluding the expand-collapse icon column
    const totalColumns = this.gridColumns.length;
    const baseWidth = 87 / totalColumns; // 87% to leave space for the expand-collapse icon column
    this.gridColumns.forEach((column: GridColumn) => {
      if (
        column.name !== this.columnName.ActionByName &&
        column.name !== this.columnName.ClientName &&
        column.name !== this.columnName.ParentClientName &&
        column.name != this.columnName.SubmissionTitle &&
        column.name != this.columnName.Focus &&
        column.name != this.columnName.SubmissionStatusName &&
        column.name != this.columnName.ContinuanceStatus &&
        column.name != this.columnName.OpenWBS &&
        column.name != this.columnName.Actions &&
        column.name !== this.columnName.LEP
      ) {
        column.width = `${baseWidth}%`;
      }

      if (column.name === this.columnName.Focus) column.width = column.minWidth;
      if(this.selectedTileCode==this.reviewerTiles.CCDueNow){
        column.width = (column.name == 'clientName' || column.name == 'continuanceStatus')?"8rem":(column.name == 'openOpportunities')?"8.90909%":column.width;
      }
    });

    this.getActionButtons();
  }

  onPageChanged(value: number) {
    this.gotoPage = value - 1;
    this.refreshGrid();
  }

  onValueChanged(item: number) {
    this.itemsPerPage = item;
    this.gotoPage = 0;
    this.refreshGrid();
  }

  gridUpdatedEvent(event: {
    sortParamaterCode: number;
    isAscending: boolean;
    dashboardfilters: any[];
  }): void {
    const shouldRefreshGrid =
      this.sortParamaterCode !== event.sortParamaterCode ||
      this.isAscending !== event.isAscending ||
      JSON.stringify(this.reviewerDashboardfilters) !==
        JSON.stringify(event.dashboardfilters);

    this.sortParamaterCode = event.sortParamaterCode;
    this.isAscending = event.isAscending;
    this.reviewerDashboardfilters = event.dashboardfilters;
    if (shouldRefreshGrid) {
      this.refreshGrid();
    }
  }

  getActionButtons(): void {
    this.buttonOptionsList = [
      {
        label: IntakeConstant.START_REVIEW,
        action: IntakeConstant.START_REVIEW_ACTION,
        condition: this.selectedTileCode === this.reviewerTiles.PendingReviews,
      },
    ];
  }

  buttonSelected(event: { value: string; rowItem: any }): void {
    const { value, rowItem } = event;
    if (value === IntakeConstant.START_REVIEW_ACTION) {
      this.goToSubmission(rowItem?.jupiterId, rowItem?.submissionId);
    }
  }

  public goToSubmission(jupiterId: string, submissionId: string): void {
    this.router.navigate(['/submission/opportunity-details', jupiterId], {
      state: {
        navigatingFromDashboard: true,
        submissionID: submissionId,
      },
    });
  }

  openModalForRecentlyViewed(modalBtn?: ButtonComponent): void {
    let openRecentViewModal = this.modalService.open(RecentlyViewedComponent, {
      isFooter: false,
      size: 'lg',
      isInverse: false,
    });
    openRecentViewModal
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          if (modalBtn) {
            modalBtn.focus();
          }
        },
        error: (err) => {
          console.error('An error occurred:', err);
        },
      });
  }
}
