<div class="dds-modal-overlay" (click)="closeModal()"></div>

<div ddsResize class="dds-modal dds-modal_lg modalWidth">
  <div class="dds-modal__header">
    <span class="dds-modal__title">
      {{ showAddRecordPopup ? "Add record" : "Edit record" }}
    </span>
    <dds-button
      [size]="modalCloseButtonOptions.size ?? emptyString"
      [icon]="modalCloseButtonOptions.icon ?? emptyString"
      [kind]="modalCloseButtonOptions.kind ?? emptyString"
      [theme]="modalCloseButtonOptions.theme ?? emptyString"
      [ariaLabel]="modalCloseButtonOptions.ariaLabel ?? emptyString"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="closeModal()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div>
      <!-- responsible practitioner control-->
      <div class="d-flex text-align-left margin-top-m">
        <div class="margin-left-input-control">
          <span *ngIf="!ppmdId">
            <img class="margin-right-xxs" src="\assets\images\draftIcon.svg" />
          </span>
          <span class="margin-bottom-s">Responsible Practitioner:</span>
          <div class="select-submission margin-top-xs">
            <div
              *ngIf="ppmdId"
              class="box-search d-flex justify-content-between padding-xxs"
            >
              <span class="margin-left-xs"> {{ searchedName }} </span>
              <span
                (click)="removeSearchedPerson()"
                class="font-bold cursor-clickable margin-right-xs"
                >x</span
              >
            </div>
            <app-people-picker
              *ngIf="!ppmdId"
              [isTechinalReviewerSearch]="true"
              [isLep]="true"
              (userSelected)="onSelected($event)"
            ></app-people-picker>
          </div>
        </div>
      </div>

 <!-- comment control -->
 <div class="margin-top-m">
  <span class="text-label comments-text-label eclipse-small-header-grey">
    Comments
  </span>

  <div class="padding-top-xs value-box d-flex">
    <dds-input
      class="box-size"
      [size]="inputOptions.size ?? emptyString"
      [isInverse]="inputOptions.isInverse ?? false"
      [errorState]="inputOptions.errorState ?? emptyString"
      [placeholder]="inputOptions.placeholder ?? emptyString"
      [tooltipIcon]="inputOptions.tooltipIcon ?? emptyString"
      [tooltipType]="inputOptions.tooltipType ?? emptyString"
      [tooltipSize]="inputOptions.tooltipSize ?? emptyString"
      [tooltipPosition]="inputOptions.tooltipPosition ?? emptyString"
      [tooltipIndent]="inputOptions.tooltipIndent ?? 20"
      [tooltipHasBeak]="inputOptions.tooltipHasBeak ?? false"
      [tooltipTheme]="inputOptions.tooltipTheme ?? emptyString"
      [(ngModel)]="inputComments"
    >
    </dds-input>
  </div>
</div>

      <!-- Active control -->
      <div class="margin-top-m">
        <span class="text-label comments-text-label eclipse-small-header-grey">
          Activation status
        </span>

        <div class="padding-top-xs value-box d-flex">
          <dds-select
            class="box-size"
            [description]="selectOptions.description ?? emptyString"
            [errorMessage]="selectOptions.errorMessage ?? emptyString"
            [disabled]="selectOptions.disabled ?? false"
            [isError]="selectOptions.isError ?? false"
            [errorState]="selectOptions.errorState ?? emptyString"
            [isResponsive]="selectOptions.isResponsive ?? true"
            [isRequired]="selectOptions.isRequired ?? false"
            [isInverse]="selectOptions.isInverse ?? false"
            [list]="selectItems"
            [label]="selectOptions.label ?? emptyString"
            [labelPosition]="selectOptions.labelPosition ?? emptyString"
            [customClass]="selectOptions.customClass ?? emptyString"
            [placeholder]="selectOptions.placeholder ?? emptyString"
            [size]="selectOptions.size ?? emptyString"
            [(ngModel)]="selectedActiveStatus"
          >
          </dds-select>
        </div>
      </div>

      <div class="margin-top-s" *ngIf="rowData?.modifiedDate && rowData?.modifiedBy">
        <span class="text-label comments-text-label eclipse-small-header-grey">
          Last modified {{ rowData?.modifiedDate | date : dateFormat }} by
          {{ rowData?.modifiedBy }}
        </span>
      </div>

      <div class="horizontal-divider"></div>

      <div>
        <div class="d-flex justify-content-left padding-top-m">
          <div class="margin-right-s">
            <dds-button
              class="custom-button"
              (click)="saveEditAndUpdateRecord()"
              [disabled]="!ppmdId"
              >Save</dds-button
            >
          </div>
          <div>
            <dds-button
              class="custom-button"
              [theme]="buttonOptions.theme ?? emptyString"
              [kind]="buttonOptions.kind ?? emptyString"
              [size]="buttonOptions.size ?? emptyString"
              [width]="buttonOptions.width ?? emptyString"
              [icon]="buttonOptions.icon ?? emptyString"
              [isIconLeft]="buttonOptions.isIconLeft ?? false"
              [role]="buttonOptions.role ?? emptyString"
              [isInverse]="buttonOptions.isInverse ?? false"
              [isColorBg]="buttonOptions.isColorBg ?? false"
              [isLoading]="buttonOptions.isLoading ?? false"
              [ariaLabel]="buttonOptions.ariaLabel ?? emptyString"
              [customClass]="buttonOptions.customClass ?? emptyString"
              [disabled]="buttonOptions.disabled ?? false"
              (clicked)="closeModal()"
              >Cancel</dds-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
