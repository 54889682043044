<div class="dds-modal-overlay" (click)="closeModal()"></div>

<div ddsResize [ngClass]="{
  'dds-modal_lg': size === 'lg',
  'dds-modal_inverse': isInverse
  }" class="dds-modal" [ngStyle]="getNotifyPopupStyles()">
    <div class="dds-modal__header">
        <span class="dds-modal__title">{{title}}</span>
        <dds-button size="sm" icon="dds-icon_close" kind="silent" theme="dark" ariaLabel="Close modal"
            class="dds-modal__close" [isInverse]="isInverse" (clicked)="closeModal()"></dds-button>
    </div>

    <div class="dds-modal__body body-overflow">
       
        <span [innerHTML]="trustedNotifyMessage"></span>
    </div>
    <div class="dds-modal__footer">
        <div class="dds-modal__footer-content dds-modal__footer-content_left">
         
            <dds-button *ngIf="!isDeletePopup" class="dds-modal__footer-item margin-left" kind="secondaryLoud" [isInverse]="isInverse"
                (clicked)="closeModal()" (keyboardClicked)="closeModal()">{{footerButtonText}}</dds-button>

                <dds-button *ngIf="isDeletePopup" class="dds-modal__footer-item yes-margin-left" (clicked)="onConfirm()"
                (keyboardClicked)="onConfirm()">{{confirmButtonText}}</dds-button>
            <dds-button *ngIf="isDeletePopup" class="dds-modal__footer-item" kind="secondaryLoud" [isInverse]="isInverse"
                (clicked)="onCancel()" (keyboardClicked)="onCancel()">{{cancelButtonText}}</dds-button>

        </div>
        <div class="dds-modal__footer-content dds-modal__footer-content_right"></div>
    </div>
</div>
