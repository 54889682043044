import { Component, HostListener, QueryList, ViewChildren } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { ButtonKind, Column, ExtThemes, PositionState, Size, Themes, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { StickerDirective, StickerOptions } from '@usitsdasdesign/dds-ng/sticker';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { Agreement, GridColumn, ThirdPartyRepositoryApiService, TPAFilterSortParameterCode, TPAGridLevelFilter } from '../../http/third-party-repository/third-party-repository-api.service';
import { TPAWebApiService } from '../../http/thirdpartyagreement/tpa.webapi.service';
import { environment } from '../../../environment/environment';
import { Filter, FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { DropdownItemOptions } from '@usitsdasdesign/dds-ng/dropdown';
import { ShowMoreComponent } from '../../watchlist/show-more/show-more.component';
import { Subscription } from 'rxjs';
import { DaterangepickerOptions } from '@usitsdasdesign/dds-ng/datepicker';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { DocumentService } from '../../http/document/document.service';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { WINDOW_SCROLL_SIZE } from '../../app.constants';
import { EntityDetails, EntityType } from '../../http/user.service';

@Component({
  selector: 'app-third-party-repository-grid',
  templateUrl: './third-party-repository-grid.component.html',
  styleUrl: './third-party-repository-grid.component.less',
  providers: [DatePipe]
})
export class ThirdPartyRepositoryGridComponent {

  @ViewChildren('stickerDir') sticker: QueryList<StickerDirective> | undefined;
  constructor(
    private thirdPartyRepositoryapiservice: ThirdPartyRepositoryApiService,
    private modalService: ModalService,
    private progressIndicator: ProgressIndicatorService,
    private router: Router,
    private datePipe: DatePipe,
    private securityWebapiService: SecurityWebapiService,
    private commonHelperService: CommonHelperService,
    private documentService: DocumentService,
    private tpaWebApiService: TPAWebApiService
  ) {
  }
  private subscriptions: Subscription[] = [];
  public isAddNewAgreementButtonVisible: boolean = true;
  public isExportButtonVisible: boolean = true;
  public isLoading: boolean = true;
  public isShownFilter!: boolean;
  public filteringColumn!: GridColumn;
  public model: Agreement[] = [];
  public countforPagination: number = 10;
  public gotoPage: number = 0;
  public itemsPerPage: number = 10;
  public sortParamaterCode: number = 1;
  public isAscending: boolean = true;
  public enumFilterSortParameterCode = TPAFilterSortParameterCode;
  rootUrl: string = environment.rootUrl;
  public tpaGridfilter: TPAGridLevelFilter[] = [];
  public dropdownItems: DropdownItemOptions[] = [];
  public fromValue: number | null = null;
  public toValue: number | null = null;
  selectedAutoRenewItems: string[] = [];
  selectedStatusItems: string[] = [];
  isAutoRenewAllFilterSelected: boolean = true;
  isStatusAllFilterSelected: boolean = true;
  filterData: FilterItem[] = [];
  headerGuidance: any = '';
  public isImageLoaded = false;

  private securityExport: string = 'TPA.ExportButton'
  private securityAddNewAgreementButton: string = 'TPA.AddNewAgreementButton';
  private securityThirdPartyAgreementRepositoryVisible: string = 'Application.ThirdPartyAgreementRepository';
  public isThirdPartyAgreementRepositoryVisible: boolean = false;
  public tpaGuidanceKeyValue: string = 'THIRD_PARTY_AGREEMENT_REPOSITORY_GUIDANCE';

  ngOnInit() {
    document.title = BrowserTabConstant.Browser_TabName_TPA;
    this.progressIndicator.show();
    this.securityWebapiService.getPermissionsByEmployeeId(EntityDetails.None, EntityType.None);
    this.securityWebapiService.data.subscribe((rules: any) => {
      if (!rules.empty) {
        this.isThirdPartyAgreementRepositoryVisible = rules[this.securityThirdPartyAgreementRepositoryVisible] && rules[this.securityThirdPartyAgreementRepositoryVisible].Visible !== undefined ? rules[this.securityThirdPartyAgreementRepositoryVisible].Visible : false;
        if (!this.isThirdPartyAgreementRepositoryVisible) {
          this.router.navigate(['/']);
        }
      }
    });
    this.setGridFilters();
    this.subscriptions.push(
      this.thirdPartyRepositoryapiservice.getTPAGridData(this.gotoPage, this.itemsPerPage, this.sortParamaterCode, this.isAscending, this.tpaGridfilter).subscribe((data: any) => {
        this.model = data.agreement;
        this.countforPagination = data.totalDataCount;
        this.isLoading = false;
        this.progressIndicator.hide();
      })
    );
    this.getGuidanceData();
  }
  getGuidanceData() {
    this.tpaWebApiService.GetAppConfigurationLabelByKey().subscribe
    ({
      next: (response: any) => {
        if(response != undefined && response != null && response.length > 0)
        this.headerGuidance = response.find((item: any) => item.appConfigurationLabelKey === this.tpaGuidanceKeyValue ).appConfigurationLabelValue;
        },
        error: (err: any) => {
        }
    });
  }
  addagreementoptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primaryLoud,
    size: Size.lg,
    width: WidthState.fixed,
    disabled: false,
    role: 'button'
  }

  autoRenewFilterData: FilterItem[] = [
    {
      title: 'Yes',
      name: 'Yes',
      isChecked: true
    },
    {
      title: 'No',
      name: 'No',
      isChecked: true
    }
  ];

  statusFilterData: FilterItem[] = [
    {
      title: 'Active',
      name: 'Active',
      isChecked: true
    },
    {
      title: 'Draft',
      name: 'Draft',
      isChecked: true
    },
    {
      title: 'Duplicate',
      name: 'Duplicate',
      isChecked: true
    },
    {
      title: 'Expired',
      name: 'Expired',
      isChecked: true
    },
    {
      title: 'Discontinued',
      name: 'Discontinued',
      isChecked: true
    }
  ];

  dateRangePickerOptions: DaterangepickerOptions = {
    size: Size.md,
    placeholder: ['MM/DD/YYYY', 'MM/DD/YYYY'],
    format: 'MM/dd/yyyy',
    isManualInput: false,
    stickerPosition: PositionState.bottomLeft,
  };

  exportbuttonoptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: WidthState.fixed,
    icon: 'dds-icon_download',
    role: 'button'
  }

  filterOptions: Filter = {
    theme: Themes.green,
    isInverse: false,
    propName: 'name',
    itemPropNameValue: 'value'
  };

  public filterStickerOptions: StickerOptions = {
    stickerPosition: PositionState.bottomLeft,
    stickerIsOutsideClick: true,
    stickerIndent: -1,
    stickerWidth: 380,
  };

  agreementTooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 5,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
    tooltipMaxWidth: 300
  };

  tpaTooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'bottom',
    tooltipIndent: 5,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'lg',
    tooltipTheme: Themes.dark,
    tooltipMaxWidth: 1000
  };

  public defaultGridColumns: GridColumn[] = [
    { name: 'sourceId', header: 'Source ID', dataType: 'number', minWidth: '7rem', filterable: false, searchValue: '' },
    { name: 'thirdPartyName', header: 'Third party name', dataType: 'string', minWidth: '15rem', filterable: false, searchValue: '' },
    { name: 'agreementName', header: 'Agreement name', dataType: 'string', minWidth: '15rem', filterable: false, searchValue: '' },
    { name: 'agreementType', header: 'Type of agreement', dataType: 'string', minWidth: '10.75rem', filterable: false, searchValue: '' },
    { name: 'effectiveDate', header: 'Effective date', dataType: 'date', minWidth: '10rem', filterable: false, searchValue: '' },
    { name: 'terminationDate', header: 'Termination date', dataType: 'date', minWidth: '7rem', filterable: false, searchValue: '' },
    { name: 'autoRenew', header: 'Auto-renew', dataType: 'string', minWidth: '6rem', filterable: false, searchValue: '' },
    { name: 'agreementStatus', header: 'Status', dataType: '', minWidth: '3rem', filterable: false, searchValue: '' },
    { name: '', header: '', dataType: '', minWidth: '2rem', filterable: false, searchValue: '' },
  ];

  public documentGridColumns: GridColumn[] = [
    { name: 'documentTitle', header: 'Title', dataType: 'string', minWidth: '12rem' },
    { name: 'documentTypeName', header: 'Type', dataType: 'string', minWidth: '12rem' },
    { name: 'effectiveDate', header: 'Effective date', dataType: 'date', minWidth: '7rem' },
    { name: 'terminationDate', header: 'Termination date', dataType: 'date', minWidth: '7rem' },
    { name: 'isAutoRenewal', header: 'Auto renew', dataType: 'string', minWidth: '7rem' },
    { name: 'modifiedDate', header: 'Modified date', dataType: 'date', minWidth: '7rem' },
    { name: 'modifiedBy', header: 'Modified by', dataType: 'date', minWidth: '7rem' }
  ];

  //Banner buttons functionality logic
  addNewAgreement() {
    this.router.navigate(['/thirdpartyrepository/add']);
  }

  onDownload(row: any) {
    let downloadRequest = { DocumentName: row.documentTitle, DocumentKey: row.attachmentguid, FileFolderPath: row.folderFilePath };
    this.documentService.getDownloadUrl(downloadRequest).subscribe
      ({
        next: (response: any) => {
          if (response != null && response.documentDownloadUrl != null) {
            window.open(response.documentDownloadUrl, "_blank");
          }
        },
        error: (err: any) => {
        }
      });
  }

  public sortColumn(column: Column, event: Event, row: any): void {
    event.preventDefault();
    event.stopPropagation();


    if (this.documentSortingState && this.documentSortingState.property === column.name) {
      if (this.documentSortingState.ascending) {
        this.documentSortingState = { ...this.documentSortingState, ascending: false };
      } else {
        this.documentSortingState = { ...this.documentSortingState, ascending: true };
      }
    } else {
      this.documentSortingState = {
        property: column.name,
        ascending: false,
        dataType: column.dataType,
      };
    }

    row.documentList.sort((a: any, b: any) => {
      let valueA = a[column.name];
      let valueB = b[column.name];

      if (column.dataType === 'date') {
        valueA = new Date(valueA).getTime();
        valueB = new Date(valueB).getTime();
      }

      if (valueA < valueB) {
        return this.documentSortingState.ascending ? -1 : 1;
      } else if (valueA > valueB) {
        return this.documentSortingState.ascending ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  public clearAllFilter() {
    this.tpaGridfilter = [];
    this.isImageLoaded = false;
    this.gotoPage = 0;
    let filterWasActive = this.defaultGridColumns.some(column => column.filterable);
    this.defaultGridColumns.forEach(column => {
      column.filterable = false;
      column.searchValue = '';
    });
    this.setGridFilters();

    this.autoRenewFilterData = this.autoRenewFilterData.map(item => ({
      ...item,
      isChecked: this.selectedAutoRenewItems.includes(item['name'])
    }));

    this.statusFilterData = this.statusFilterData.map(item => ({
      ...item,
      isChecked: this.selectedStatusItems.includes(item['name'])
    }));

    

    if (filterWasActive) {
      this.filterHide();
      this.refreshGrid();
    }
  }

  public selectAllStatusFilter(event: any): void {
    if (event) {
      this.selectedStatusItems = this.statusFilterData.map(item => item['name']);
      this.statusFilterData.forEach(item => item['isChecked'] = true);
      this.isAutoRenewAllFilterSelected = true;
    } else {
      this.selectedStatusItems = [];
      this.statusFilterData.forEach(item => item['isChecked'] = false);
      this.isAutoRenewAllFilterSelected = false;
    }
    this.filteringColumn.searchValue = this.selectedStatusItems.join(', ');

    this.statusFilterData.forEach(item => {
      item['isChecked'] = this.selectedStatusItems.includes(item['name']);
    });
  }

  public selectStatusFilterItem(event: any, filterItem: any): void {
    if (event) {
      if (!this.selectedStatusItems.includes(filterItem.name)) {
        this.selectedStatusItems.push(filterItem.name);
      }
    } else {
      this.selectedStatusItems = this.selectedStatusItems.filter(item => item !== filterItem.name);
    }

    this.isStatusAllFilterSelected = this.selectedStatusItems.length === this.statusFilterData.length;
    this.filteringColumn.searchValue = this.selectedStatusItems.join(', ');

    // Update the filterData to reflect the current state of selectedItems
    this.statusFilterData.forEach(item => {
      item['isChecked'] = this.selectedStatusItems.includes(item['name']);
    });
  }

  public selectAllAutoRenewFilter(event: any): void {
    if (event) {
      this.selectedAutoRenewItems = this.autoRenewFilterData.map(item => item['name']);
      this.autoRenewFilterData.forEach(item => item['isChecked'] = true);
      this.isAutoRenewAllFilterSelected = true;
    } else {
      this.selectedAutoRenewItems = [];
      this.autoRenewFilterData.forEach(item => item['isChecked'] = false);
      this.isAutoRenewAllFilterSelected = false;
    }
    this.filteringColumn.searchValue = this.selectedAutoRenewItems.join(', ');

    this.autoRenewFilterData.forEach(item => {
      item['isChecked'] = this.selectedAutoRenewItems.includes(item['name']);
    });
  }

  public selectAutoRenewFilterItem(event: any, filterItem: any): void {
    if (event) {
      if (!this.selectedAutoRenewItems.includes(filterItem.name)) {
        this.selectedAutoRenewItems.push(filterItem.name);
      }
    } else {
      this.selectedAutoRenewItems = this.selectedAutoRenewItems.filter(item => item !== filterItem.name);
    }

    this.isAutoRenewAllFilterSelected = this.selectedAutoRenewItems.length === this.autoRenewFilterData.length;
    this.filteringColumn.searchValue = this.selectedAutoRenewItems.join(', ');

    // Update the filterData to reflect the current state of selectedItems
    this.autoRenewFilterData.forEach(item => {
      item['isChecked'] = this.selectedAutoRenewItems.includes(item['name']);
    });
  }


  setGridFilters() {
    var autoRenewParameterCode = this.enumFilterSortParameterCode['autoRenew'];

    this.tpaGridfilter = [{ filtertypecode: autoRenewParameterCode, filtercriteria: 'Yes' },
    { filtertypecode: autoRenewParameterCode, filtercriteria: 'No' }]; //autoRenew filter

    var agreementStatusParameterCode = this.enumFilterSortParameterCode['agreementStatus'];

    this.tpaGridfilter = [...this.tpaGridfilter, { filtertypecode: agreementStatusParameterCode, filtercriteria: 'Active' }, { filtertypecode: agreementStatusParameterCode, filtercriteria: 'Draft' }
      , { filtertypecode: agreementStatusParameterCode, filtercriteria: 'Duplicate' }, { filtertypecode: agreementStatusParameterCode, filtercriteria: 'Expired' }
      , { filtertypecode: agreementStatusParameterCode, filtercriteria: 'Discontinued' }
    ]; //agreementStatus filter

    this.selectedAutoRenewItems = [...new Set([...this.selectedAutoRenewItems, 'Yes', 'No'])];
    this.selectedStatusItems = [...new Set([...this.selectedStatusItems, 'Active', 'Draft', 'Duplicate', 'Expired', 'Discontinued'])];
  }

  exportToExcel() {

  }

  //Grid Logic

  refreshGrid() {
    this.progressIndicator.show();

    this.thirdPartyRepositoryapiservice.getTPAGridData(this.gotoPage, this.itemsPerPage, this.sortParamaterCode, this.isAscending, this.tpaGridfilter).subscribe((response: any) => {
      this.model = response.agreement;
      this.countforPagination = response.totalDataCount;
      this.progressIndicator.hide();
    });

  }

  onRowClick(row: any, event: MouseEvent, sourceId: string) {
    if (!this.isAddNewAgreementButtonVisible || this.isAddNewAgreementButtonVisible == undefined) {
      return;
    }
    let target = event.target as HTMLElement;

    while (target && target.tagName !== 'TD') {
      target = target.parentElement as HTMLElement;
    }

    if (target) {
      const columnName = target.getAttribute('data-column-name');
      this.navigateToAddAgreement(row.sourceId);
    }
  }

  navigateToAddAgreement(thirdPartyAgreementID: string) {
    let url = this.rootUrl + 'thirdpartyrepository/' + thirdPartyAgreementID;
    window.open(url, '_blank');
  }

  getAccordianStyles() {
    var paddingLeft = '0rem'; //defualt Padding for accordian
    return { 'padding-left': paddingLeft };
  }

  public itemExpand(item: any, $event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    item.expanded = !item.expanded;
  }

  public gotoAgreement(agreementNumber: string) {
  }

  public stopEventPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  // Pagination, Sort and Filter logic 
  public toggleFilter(column: GridColumn) {
    this.filteringColumn = column;
    this.isShownFilter = true;
    if (column.name === 'autoRenew') {
      this.filterData = this.autoRenewFilterData;
    }
    else if (column.name === 'agreementStatus') {
      this.filterData = this.statusFilterData;
    }
  }

  public filterOnHidden(): void {
    this.isShownFilter = false;
  }

  onPageChanged(value: number) {
    this.gotoPage = value - 1;
    this.refreshGrid();
  }

  onValueChanged(item: number) {
    this.itemsPerPage = item;
    this.gotoPage = 0;
    this.refreshGrid();
  }

  public sortingState = {
    property: this.defaultGridColumns[0].name,
    ascending: true,
    dataType: this.defaultGridColumns[0].dataType,
  };

  public documentSortingState = {
    property: this.documentGridColumns[0].name,
    ascending: true,
    dataType: this.documentGridColumns[0].dataType,
  };

  public filterSorted(isAscending: boolean): void {
    const parameterCode = this.enumFilterSortParameterCode[this.filteringColumn.name as keyof typeof TPAFilterSortParameterCode];
    this.sortParamaterCode = parameterCode;
    this.isAscending = isAscending;

    this.sortingState = {
      property: this.filteringColumn.name,
      ascending: isAscending,
      dataType: this.filteringColumn.dataType,
    };

    this.filterHide();
    this.refreshGrid();
  }
  public filterHide(): void {
    this.sticker?.forEach((item) => {
      if (item.isActive) {
        item.hide();
      }
    });
  }

  public filterApply(searchValue: string) {
    if (searchValue === '' || searchValue === null) {
      this.clearFilter(this.filteringColumn.name);
      return;
    }

    const parameterCode = this.enumFilterSortParameterCode[this.filteringColumn.name as keyof typeof TPAFilterSortParameterCode];

    let filterCriteria: string;

    if (
      this.filteringColumn.dataType == 'number' &&
      this.fromValue !== null &&
      this.toValue !== null
    ) {
      filterCriteria = `${this.fromValue},${this.toValue}`;
    } else if (Array.isArray(searchValue)) {
      const [fromDate, toDate] = searchValue;
      const fromDateString = fromDate.toLocaleDateString('en-US');
      const toDateString = toDate.toLocaleDateString('en-US');
      filterCriteria = `${fromDateString},${toDateString}`;
    } else {
      filterCriteria = searchValue;
    }

    this.tpaGridfilter = this.tpaGridfilter.filter(filter => filter.filtertypecode !== parameterCode);

    if (this.filteringColumn.name === 'agreementStatus' || this.filteringColumn.name === 'autoRenew') {
      const values = searchValue.split(',');
      const newFilters = values.map(value => ({
        filtertypecode: parameterCode,
        filtercriteria: value.trim()
      }));

      this.tpaGridfilter = [...(this.tpaGridfilter || []), ...newFilters];
    } else {
      const newFilter = { filtertypecode: parameterCode, filtercriteria: filterCriteria };
      this.tpaGridfilter = [...(this.tpaGridfilter || []), newFilter];
    }
    this.filteringColumn.filterable = true;
    this.filterHide();
    this.gotoPage = 0;
    this.refreshGrid();
  }

  isApplyButtonEnabled(): boolean {

    if (this.filteringColumn.searchValue.length < 1) {
      return false;
    }
    return this.filteringColumn.filterable || !!this.filteringColumn.searchValue;

  }

  public cancel(value: number) {
    if (value != null && this.filteringColumn.filterable == false) {
      this.filteringColumn.searchValue = '';
    }
    this.filterHide();
  }

  public clearFilter(column: string) {
    //remove the filter for the specified column from the list
    this.tpaGridfilter = this.tpaGridfilter.filter(filter => {
      const parametercode = this.enumFilterSortParameterCode[column as keyof typeof TPAFilterSortParameterCode];
      return filter.filtertypecode !== parametercode;
    });

    // reset the filterable and searchvalue properties for the specified column
    this.filteringColumn.filterable = false;
    this.filteringColumn.searchValue = '';

    this.filterHide();
    this.refreshGrid();
  }

  downloadThirdPartyAgreementReport() {
    this.thirdPartyRepositoryapiservice.ExportThirdPartyAgreementData().subscribe((response: any) => {
      const base64Data = response.base64String;
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
      const downloadLink = document.createElement('a');
      const formattedDate = this.datePipe.transform(new Date(), 'MMddyyyy');
      const fileName = `${response.fileName} - ${formattedDate}.xlsx`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    );
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    if (window.scrollY > WINDOW_SCROLL_SIZE) {
      this.addagreementoptions.size = Size.sm;
      this.exportbuttonoptions.size = Size.sm;
    }
    else {
      this.addagreementoptions.size = Size.lg;
      this.exportbuttonoptions.size = Size.lg;
    }
  }

  imageLoaded() {
    this.isImageLoaded = true;
  }

}
