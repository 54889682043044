import { GlobalEngagementComponent } from './global-engagement/global-engagement.component';
import { InitiateGlobalEngagementsComponent } from './initiate-global-engagements/initiate-global-engagements.component';

export const GLOBAL_ENGAGEMENTS_ROUTES = [
  {
    path: '',
    children: [
      {
        path: 'create',
        component: InitiateGlobalEngagementsComponent,
      },
      {
        path:'details/:id',
        component : GlobalEngagementComponent,
      }
    ],
  },
];
