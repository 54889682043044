<div class="row">
    <div>
      <span class="icon-required-yellow"></span>
      <span>{{ deliverable_radioGroupOptions.title }}</span>
    </div>
  
    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            autosave
            [(ngModel)]="deliverable"
            [name]="deliverable"
            [isUncheckable]="deliverable_radioGroupOptions.isUncheckable"
            [isRequired]="deliverable_radioGroupOptions.isRequired"
            [errorState]="deliverable_radioGroupOptions.errorState"
            (stateChanged)="deliverableStateChanged($event)"
          >
            <ng-container *ngFor="let radio of deliverable_radio_values">
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </div>
  