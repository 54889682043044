import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { ClientStatusWebapiService } from '../../../http/client/client-status-webapi.service';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { CommonHelperService } from '../../../common/services/common-helper.service';

@Component({
  selector: 'app-convert-cc-to-nca-dialog',
  templateUrl: './convert-cc-to-nca-dialog.component.html',
  styleUrl: './convert-cc-to-nca-dialog.component.less'
})
export class ConvertCcToNcaDialogComponent implements OnInit {
  public convertCcToNcaReason: string = '';
  @Input() resolve: any;

  constructor(private modalService: ModalService,
              private clientstatuswebapiservice: ClientStatusWebapiService,
              private progressindicator: ProgressIndicatorService,
              private commonHelperServive:CommonHelperService
  ) {}

  public ngOnInit() { }

  textareaOptions: TextareaOptions = {
    placeholder: 'Enter reason',
    minHeight: 100,
    maxLength: 4000,
    maxLengthPosition: 'bottom-right',
    size: Size.md
  };

  public confirm() {
    this.progressindicator.show();
    this.convertCcToNcaReason=this.commonHelperServive.trimPrefixEmptySpace(this.convertCcToNcaReason);
    this.clientstatuswebapiservice
      .convertCCtoNCA(this.resolve.clientMilestoneId,this.convertCcToNcaReason)
        .subscribe({
                    next: (value) => {
                      window.location.reload();
                      this.progressindicator.hide();
                    },      
                    error: (error) => {
                    console.error(error);
                    },
                    complete: () => {
                      this.modalService.close();
                    }
                  });
  }
  public cancel() {
    //This will disable the scroll when the pop-up is open
    document.body.classList.remove('no-scroll');
    this.modalService.close();
  }
}
