import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { EntityType } from '../user.service';
import { GlobalSearchResponse } from '../../common/models/start-page/global-search.model';
import { AdminUrlConstants } from '../../admin/constants/adminUrl-constants';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchWebApiService {
  constructor(private readonly httpService: HttpClient) { }

  apiUrl: string = environment.apiUrl;

  search(
    searchTerm: string
  ): Observable<GlobalSearchResponse> {
    const params = new HttpParams()
      .set('searchTerm', searchTerm);

    return this.httpService.get<GlobalSearchResponse>(
      `${this.apiUrl}${AdminUrlConstants.GET_GLOBAL_SEARCH_DATA}`,
      { params }
    );
  }

  addLastSearchItem(entityId: string, entityTypeId: EntityType): Observable<any> {
    const url = `${this.apiUrl}${AdminUrlConstants.ADD_LAST_SEARCH_GLOBAL_SEARCH_DATA}?entityId=${entityId}&entityTypeId=${entityTypeId}`;
    return this.httpService.post<any>(url, {});
  }

  getLastSearchData(): Observable<any> {
    const url = `${this.apiUrl}${AdminUrlConstants.GET_LAST_SEARCH_GLOBAL_SEARCH_DATA}`;
    return this.httpService.get<any>(url);
  }

  
}
