import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartEmitData, ChartItem } from '../../../models/chart-item.model';

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrl: './chart-legend.component.less'
})
export class ChartLegendComponent {
  @Input() legendBar: ChartItem = {} as ChartItem;
  @Output() public legendItemClick: EventEmitter<ChartEmitData[]> = new EventEmitter<ChartEmitData[]>();

  constructor() {

  }

  getFrameColor(index: number): any {
    if (this.legendBar?.itemColor[index]) {
      return this.legendBar?.itemColor[index];
    }
  }

  itemClick(index: number) {
    var chartClick: ChartEmitData[] = [{
      type: 'legend',
      value: this.legendBar.legendItemClickSendValues[index]
    }];
    this.legendItemClick.emit(chartClick);
  }
}
