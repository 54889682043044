<progress-indicator></progress-indicator>
<div class="initiate-submission-container padding-bottom-s margin-top-l">
  <div class="margin-top-l">
    <div
      class="margin-top-l d-flex flex-row justify-content-between align-items-center"
    >
      <h3 class="font-size-24 font-bold padding-top-es text-align-left">
        Initiate External Communication
      </h3>
      <div class="d-flex">
        <dds-button
          class="custom-button margin-right-m"
          [theme]="primaryButton.theme"
          [kind]="primaryButton.kind"
          [size]="htmlElementSize"
          [width]="primaryButton.width"
          [disabled]="isConfirmationDisabled()"
          (clicked)="onSubmit()"
          >Confirm and proceed</dds-button
        >
        <dds-button
          class="custom-button cancel-btn"
          [theme]="secondaryButton.theme"
          [kind]="secondaryButton.kind"
          [isInverse]="true"
          [size]="htmlElementSize"
          [width]="secondaryButton.width"
          (clicked)="onCancel()"
          ><span class="cancel-span">Cancel</span></dds-button
        >
      </div>
    </div>
  </div>
  <hr />
  <div class="submission-type-section text-align-left margin-top-m">
    <span class="text-align-left styled-box font-size-14 margin-top-s"
      >Please provide all the required information indicated by a yellow
      bubble.</span
    >
    <div class="d-flex margin-top-m">
      <div class="d-flex">
        <span *ngIf="!communicationType">
          <img class="margin-right-xxs" src="\assets\images\draftIcon.svg" />
        </span>
        <span
          class="text-label font-bold-600 font-size-16 line-height-m margin-bottom-xs"
        >
          Select communication type
        </span>
      </div>
    </div>
    <div class="d-flex">
      <div>
        <div class="select-submission margin-top-small margin-bottom-s">
          <dds-select
            class="custom-select"
            [size]="htmlElementSize"
            [list]="communicationTypeList"
            [placeholder]="'Select type'"
            [stickerMaxHeight]="300"
            (valueChanged)="onSubmissionTypeChange($event)"
            [(ngModel)]="communicationType"
          >
          </dds-select>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="communicationType" class="submission-type-section">
    <hr />
    <div class="d-flex">
      <span
        class="text-label font-bold-600 font-size-16 line-height-m margin-bottom-m margin-top-s"
      >
        External Communication information
      </span>
    </div>
    <div class="d-fle text-align-left">
      <div>
        <span *ngIf="!communicationTitle">
          <img class="margin-right-xxs" src="\assets\images\draftIcon.svg" />
        </span>
        <span class="margin-bottom-s">Title of communication:</span>
        <div class="select-submission margin-top-xs margin-bottom-s">
          <dds-input
            [(ngModel)]="communicationTitle"
            [placeholder]="'Enter title'"
            (input)="onCommunicationTitleInput($event)"
          >
          </dds-input>
        </div>
      </div>
    </div>
    <div class="d-flex text-align-left">
      <div>
        <span *ngIf="!ppmdId">
          <img class="margin-right-xxs" src="\assets\images\draftIcon.svg" />
        </span>
        <span class="margin-bottom-s">Responsible Practitioner:</span>
        <div class="select-submission margin-top-xs">
          <div
            *ngIf="ppmdId"
            class="box-search d-flex justify-content-between padding-xxs"
          >
            <span class="margin-left-xs"> {{ searchedName }} </span>
            <span
              (click)="removeSearchedPerson()"
              class="font-bold cursor-clickable margin-right-xs"
              >x</span
            >
          </div>
          <app-people-picker
            *ngIf="!ppmdId"
            [isTechinalReviewerSearch]="true"
            [isLep]="true"
            (userSelected)="onSelected($event)"
          ></app-people-picker>
        </div>
      </div>
    </div>
  </div>
</div>
