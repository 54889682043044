import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import {
  ButtonKind,
  ErrorState,
  ExtThemes,
  Size,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { ExternalCommunicationConstant } from './constants/external_communication.constant';

export const secondaryButton = {
  theme: ExtThemes.green,
  kind: ButtonKind.secondary,
  width: WidthState.fixed,
  isLoading: false,
  icon: 'dds-icon_close',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: '',
  customClass: '',
  role: 'button',
};

export const SecondaryLoudButtonOptions: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.secondaryLoud,
  size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: undefined,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: undefined,
  customClass: '',
  role: 'button',
};

export const primaryButton = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  width: WidthState.fixed,
  isLoading: false,
  icon: '',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: true,
  customClass: '',
  role: 'button',
};

export const radioBtns: RadioButton[] = [
  {
    options: {
      label: 'Yes',
      theme: Themes.green,
    },
    value: 'Yes',
  },
  {
    options: {
      label: 'No',
      theme: Themes.green,
    },
    value: 'No',
  },
];

export const radioGroupOptions: RadioGroupOptions = {
  name: 'group1',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  isRequired: false,
  isInverse: false,
};

export const PrimaryButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primary,
  size: Size.md,
  width: WidthState.fixed,
};

export const SHOW_MORE_BTN_OPTIONS: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.silent,
  size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: ExternalCommunicationConstant.DDS_ICON_ARROW_UP,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Show Less',
  customClass: '',
  role: 'button',
};

export const ModalCloseButtonOptions: ButtonOptions = {
  theme: ExtThemes.dark,
  kind: ButtonKind.silent,
  size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: ExternalCommunicationConstant.DDS_ICON_CLOSE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Close modal',
  customClass: '',
  role: 'button',
};

export const ToggleGroupOptions = {
  title: '',
  isInverse: false,
  isRequired: false,
};

export const ToggleOptions = {
  theme: Themes.green,
  label: 'Hide all draft documents (e.g. drafts and supporting documents)',
  ariaLabel: 'Hide all draft documents (e.g. drafts and supporting documents)',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isProcessingStatusActive: false,
  isRequired: false,
  customClass: '',
};

export const UploaderAreaOptions = {
  heading: '',
  dragAndDropText: 'Drag and drop file(s)',
  description:
    'All common file types are supported, including: docx, pptx, pdf, .zip, etc. Max size of 100MB per file',
  isDragAndDrop: true,
  isMultiple: true,
  acceptFormats: '',
  acceptExtensions: '',
  restrictedFormats: '',
  restrictedExtensions: '',
  exceptSymbols: '',
  btnLabel: 'Attach document(s)',
  chooseFileBtnKind: 'primary',
  isDownloadable: false,
  allowFilesWithoutType: false,
  disabled: false,
  isInverse: false,
};

export const docTableColumns = [
  { name: 'Title', header: 'Title', dataType: 'string' },
  { name: '', header: '', dataType: 'string' },
  { name: 'Type', header: 'Type', dataType: 'string' },
  {
    name: 'Status',
    header: 'Status',
    dataType: 'string',
  },
  { name: 'Uploaded Date', header: 'Uploaded date', dataType: 'string' },
  { name: 'Uploaded By', header: 'Uploaded by', dataType: 'string' },
  { name: 'Actions', header: 'Actions', dataType: 'string' },
];

export const documentTitleTooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'top',
  tooltipIndent: 15,
  tooltipIsOutsideClick: false,
  tooltipHasBeak: true,
  tooltipIsDisabled: false,
  tooltipIsInverse: false,
  tooltipIsDynamic: false,
  tooltipCustomClass: '',
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipShowDelay: 0,
  tooltipHideDelay: 0,
  tooltipTheme: Themes.dark,
  tooltipMaxWidth: 300,
};

//TODO: Update this with the correct data and use in document component
export const documentBoxMessages = [
  {
    text: 'Upload the review document',
    icon: null,
    class: 'styled-box',
    order: 1,
  },
  {
    text: 'Upload the review document',
    icon: null,
    class: null,
    order: 2,
  },
  {
    text: 'Upload the review document',
    icon: null,
    class: 'styled-box-green',
    order: 3,
  },
  {
    text: 'Upload the review document',
    icon: 'icon-bullet-star-green',
    class: 'styled-box-green',
    order: 4,
  },
];

export const newDocumentIds = {
  submissionDocumentId: { file: 1, link: 2 },
  attachmentId: { file: 0, link: 1 },
};
