<progress-indicator></progress-indicator>
<app-page-header>
  <div class="header-custom-container row">
    <div class="col-6 d-flex align-items-center flex-item-1">
      <div
        class="title"
        *ngIf="permissionObj['isIntakeHeaderOpportunityTitleVisible']"
      >
        {{ opportunityDetailTitle }}
      </div>
    </div>

    <div class="col-6 d-flex align-items-center flex-item-2">
      <div
        *ngIf="permissionObj['isIntakeHeaderGoToJupiterVisible']"
        class="icon-container"
        (click)="onNavigateToJupiterURL()"
      >
        <span class="padding-right-xxs">Go to Jupiter</span>
        <span>
          <svg
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
          >
            <path
              d="M3.67188 2.22412H1.67188V8.896H8.32812V6.896H9.67188V10.2241H0.328125V0.895996H3.67188V2.22412ZM5.67188 0.895996V2.22412H7.39062L3.1875 6.42725L4.14062 7.36475L8.32812 3.17725V4.896H9.67188V0.895996H5.67188Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </div>
      <div class="icon-divider-container">
        <svg width="1" height="33" viewBox="0 0 1 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="divider-icon">
          <rect width="1" height="32" transform="translate(0 0.792969)" fill="currentColor"/>
          </svg>
      </div>
      <div
        *ngIf="permissionObj['isIntakeHeaderGoToFocusVisible']"
        class="icon-container"
        (click)="onFocusUrlClicked()"
      >
        <span class="padding-right-xxs">Go to Focus</span>
        <span class="padding-right-s">
          <svg
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
          >
            <path
              d="M3.67188 2.22412H1.67188V8.896H8.32812V6.896H9.67188V10.2241H0.328125V0.895996H3.67188V2.22412ZM5.67188 0.895996V2.22412H7.39062L3.1875 6.42725L4.14062 7.36475L8.32812 3.17725V4.896H9.67188V0.895996H5.67188Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</app-page-header>
<div class="d-flex flex-row">
  <div class="left-panel-div right-border">
    <div class="d-flex margin-top-m flex-row-column">
      <div
        class="eclipse-section-subheader text-align-left sticky-element-after-black-header margin-top-l"
      >
        Opportunity
        <span
          class="float-right right-icon clickable padding-right-vl"
          (click)="moveBackToEdit()"
          ><img src="/assets/images/chevron_down__l__stroke.svg"
        /></span>
        <span
          class="accepted font-size-12 font-bold-400 float-right cursor-clickable"
          (click)="moveBackToEdit()"
          >{{ viewAndEditLabel }}</span
        >
      </div>
      <div class="bottom-border text-align-left set-top-margin">
        <dds-tabs
          [theme]="tabContainerOptions.theme ?? ''"
          [themeType]="tabContainerOptions.themeType ?? ''"
          [size]="tabContainerOptions.size ?? ''"
          [isResponsive]="tabContainerOptions.isResponsive ?? false"
          [customClass]="tabContainerOptions.customClass ?? ''"
          [disabled]="tabContainerOptions.disabled ?? false"
          [isInverse]="tabContainerOptions.isInverse ?? false"
          [ariaLabel]="tabContainerOptions.ariaLabel ?? ''"
        >
          <dds-tab [label]="tabOptions1.label ?? ''">
            <div class="d-flex flex-row-column top-border">
              <!-- Service Model Section -->
              <div class="margin-top-vs">
                <div class="eclipse-section-header text-align-left">
                  Jupiter Opportunity {{ opportunityNumber }} Services ({{
                    opportunityStatusName
                  }})
                </div>
                <div class="margin-top-s">
                  <app-service-delivery
                    [opportunityNumber]="
                      opportunityNumber ? opportunityNumber : ''
                    "
                    [summary]="true"
                    (eventForCyberQuestionInSummary)="makeGetCyberCall()"
                  ></app-service-delivery>
                </div>
              </div>
              <!-- Details section -->
              <div
                class="d-flex margin-top-l margin-bottom-l flex-row-column fee-type-height"
              >
                <div class="d-flex flex-row">
                  <div class="d-flex flex-row width-full">
                    <div class="width-partial">
                      <div
                        class="d-flex text-align-left flex-row align-items-center"
                      >
                        <img
                          class="icon-summary"
                          src="/assets/images/dollar-icon.svg"
                        />
                        <span
                          class="eclipse-small-header-grey margin-left-xs"
                          >{{ feeTypeLabel }}</span
                        >
                      </div>
                      <ul class="margin-left-xs">
                        <li
                          class="eclipse-content text-align-left margin-top-fee-type"
                          *ngFor="let feeType of feeTypeList"
                        >
                          {{
                            feeType?.label
                              ? feeType?.label
                              : defaultForEmptyFields
                          }}
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex flex-row-column width-40">
                      <div class="d-flex flex-row-column">
                        <div
                          class="d-flex text-align-left flex-row align-items-center"
                        >
                          <img
                            class="icon-summary"
                            src="/assets/images/calendar-icon.svg"
                          />
                          <span
                            class="eclipse-small-header-grey margin-left-xs"
                            >{{ durationLabel }}</span
                          >
                        </div>
                        <div
                          class="text-align-left margin-left-s eclipse-content"
                        >
                        {{ startDate }} - {{ endDate }} 
                        <span class="margin-left-xxs"> 
                          ({{ monthDuration }}m)
                        </span>
                        </div>
                      </div>
                      <div class="d-flex flex-row-column margin-top-m">
                        <div
                          class="d-flex text-align-left flex-row align-items-center"
                        >
                          <img
                            class="icon-summary"
                            src="/assets/images/direction-icon.svg"
                          />
                          <span
                            class="eclipse-small-header-grey margin-left-xs"
                            >{{ businessLabel }}</span
                          >
                        </div>
                        <!-- TODO: to be added after integration -->
                        <div
                          class="text-align-left margin-left-s eclipse-content"
                        >
                          {{ oportunityDetailsInfo.crossBusinessOpportunity }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row-column margin-top-l margin-bottom-s width-full"
                >
                  <div
                    class="d-flex text-align-left flex-row align-items-center"
                  >
                    <img
                      class="icon-summary"
                      src="/assets/images/description-icon.svg"
                    />
                    <span class="eclipse-small-header-grey margin-left-xs">{{
                      descriptionLabel
                    }}</span>
                  </div>
                  <div class="text-align-left margin-left-s eclipse-content">
                    <span class="margin-left-xs">{{
                      opportunityDescription | ifEmpty : defaultEmptyFields
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </dds-tab>
          <dds-tab [label]="tabOptions2.label ?? ''">
            <div
              class="d-flex flex-row padding-top-s margin-left-s top-border h-fit-content gap-2"
            >
              <div class="complexity-section-width d-flex flex-row-column">
                <div class="d-flex flex-row-column">
                  <div class="title-section">
                    <img src="/assets/images/list-options-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ opportunityComplexityLabel }}
                    </span>
                  </div>
                  <div class="content-section">
                    <div
                      class="d-flex flex-row-column margin-left-m"
                      *ngFor="
                        let complexity of opportunityComplexityOptionsOriginal
                      "
                    >
                      <div
                        class="eclipse-content margin-left-s margin-top-s d-flex flex-row"
                      >
                        <div
                          *ngIf="
                            complexity.label &&
                            selectedOpportunityComplexity.includes(
                              complexity.label
                            )
                          "
                          class="margin-right-s"
                        >
                          <img src="/assets/images/tick-icon.svg" />
                        </div>
                        <div
                          *ngIf="
                            complexity.label &&
                            !selectedOpportunityComplexity.includes(
                              complexity.label
                            )
                          "
                          class="margin-right-xs empty-space"
                        ></div>
                        <span
                          class="ellipsis w-fit-content"
                          [ddsTooltip]="complexity.label ?? ''"
                          [tooltipInvokeType]="
                            groupToolTipOptions.tooltipInvokeType ?? ''
                          "
                          [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                          [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                          [tooltipPosition]="
                            groupToolTipOptions.tooltipPosition ?? ''
                          "
                          [tooltipIndent]="
                            groupToolTipOptions.tooltipIndent ?? 0
                          "
                          [tooltipHasBeak]="
                            groupToolTipOptions.tooltipHasBeak ?? false
                          "
                          [tooltipTheme]="
                            groupToolTipOptions.tooltipTheme ?? ''
                          "
                          ddsFocus
                          [tooltipMaxWidth]="
                            groupToolTipOptions.tooltipMaxWidth ?? 0
                          "
                        >
                          {{ complexity.label }}</span
                        >
                        <span
                          *ngIf="complexity.label === regulatoryText"
                          class="icon-s icon-info position-alignment margin-left-s clickable"
                          [ddsTooltip]="regulatoryConsiderationTooltip"
                          [tooltipInvokeType]="
                            groupToolTipOptions.tooltipInvokeType ?? ''
                          "
                          [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                          [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                          [tooltipPosition]="
                            groupToolTipOptions.tooltipPosition ?? ''
                          "
                          [tooltipIndent]="
                            groupToolTipOptions.tooltipIndent ?? 0
                          "
                          [tooltipHasBeak]="
                            groupToolTipOptions.tooltipHasBeak ?? false
                          "
                          [tooltipTheme]="
                            groupToolTipOptions.tooltipTheme ?? ''
                          "
                          ddsFocus
                          [tooltipMaxWidth]="
                            groupToolTipOptions.tooltipMaxWidth ?? 0
                          "
                        >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-top-m">
                  <div class="title-section">
                    <img src="/assets/images/briefcase-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ thirdPartyLabel }}
                    </span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs"
                  >
                    {{ thirdPartyInvolvement }}
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-top-m">
                  <div class="title-section">
                    <img src="/assets/images/desktop-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s">
                      {{ dataInDEloitteLabel }}</span
                    >
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs"
                  >
                    {{ dataInDeloittesOrDeloittesHostedEnvironment }}
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-top-m">
                  <div class="title-section d-flex flex-row">
                    <img src="/assets/images/rent-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ memberFirmInvolvementLabel }}
                    </span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs margin-bottom-s d-flex"
                  >
                    <span> {{ memberFirmInvolvement }}</span>
                    <span
                      *ngIf="memberFirmInvolvement === YES"
                      class="icon-s icon-info position-alignment margin-left-s clickable d-inline-block"
                      [ddsTooltip]="memberFirmTooltip"
                      [tooltipInvokeType]="
                        groupToolTipOptions.tooltipInvokeType ?? ''
                      "
                      [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                      [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                      [tooltipPosition]="
                        groupToolTipOptions.tooltipPosition ?? ''
                      "
                      [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
                      [tooltipHasBeak]="
                        groupToolTipOptions.tooltipHasBeak ?? false
                      "
                      [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
                      ddsFocus
                      [tooltipMaxWidth]="
                        groupToolTipOptions.tooltipMaxWidth ?? 0
                      "
                    ></span>
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-bottom-l">
                  <div class="title-section">
                    <img
                      src="\assets\images\dds-icons\serviceInvolvementLogo.svg"
                    />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ serviceInvolvementLabel }}
                    </span>
                  </div>
                  <div class="content-section" *ngIf="containCyberQuestions">
                    <div
                      class="d-flex flex-row-column margin-left-m"
                      *ngFor="let cyber of serviceInvolvementOptions"
                    >
                      <div
                        class="eclipse-content margin-left-s margin-top-s d-flex flex-row"
                      >
                        <div
                          *ngIf="
                            cyber.label &&
                            serviceInvolvement?.includes(cyber.label)
                          "
                          class="margin-right-xs"
                        >
                          <img src="/assets/images/tick-icon.svg" />
                        </div>
                        <div
                          *ngIf="
                            cyber.label &&
                            !serviceInvolvement?.includes(cyber.label)
                          "
                          class="margin-right-xs empty-space"
                        ></div>
                        <span
                          class="ellipsis w-fit-content"
                          [ddsTooltip]="cyber.label ?? ''"
                          ddsFocus
                        >
                          {{ cyber.label }}</span
                        >
                        <span
                          *ngIf="cyber.label === regulatoryText"
                          class="icon-s icon-info position-alignment margin-left-s clickable"
                          [ddsTooltip]="regulatoryConsiderationTooltip"
                          [tooltipInvokeType]="
                            groupToolTipOptions.tooltipInvokeType ?? ''
                          "
                          [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                          [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                          [tooltipPosition]="
                            groupToolTipOptions.tooltipPosition ?? ''
                          "
                          [tooltipIndent]="
                            groupToolTipOptions.tooltipIndent ?? 0
                          "
                          [tooltipHasBeak]="
                            groupToolTipOptions.tooltipHasBeak ?? false
                          "
                          [tooltipTheme]="
                            groupToolTipOptions.tooltipTheme ?? ''
                          "
                          ddsFocus
                          [tooltipMaxWidth]="
                            groupToolTipOptions.tooltipMaxWidth ?? 0
                          "
                        >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="complexity-section-width flex-row-column">
                <div class="d-flex flex-row-column">
                  <div class="title-section">
                    <img src="/assets/images/search-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s">{{
                      productAndToolsLabel
                    }}</span>
                  </div>
                  <div class="content-section">
                    <div
                      class="d-flex flex-row-column margin-left-m"
                      *ngFor="let complexity of productAndToolsOptionsOriginal"
                    >
                      <div
                        class="eclipse-content margin-left-s margin-top-s d-flex flex-row"
                      >
                        <div
                          *ngIf="
                            complexity.label &&
                            selectedProductAndTools.includes(complexity.label)
                          "
                          class="margin-right-s"
                        >
                          <img src="/assets/images/tick-icon.svg" />
                        </div>
                        <div
                          *ngIf="
                            complexity.label &&
                            !selectedProductAndTools.includes(complexity.label)
                          "
                          class="margin-right-xs empty-space"
                        ></div>
                        {{ complexity.label }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-top-m">
                  <div class="title-section">
                    <img src="/assets/images/diamond-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ sensitiveDataLabel }}
                    </span>
                  </div>
                  <div class="content-section">
                    <div
                      class="d-flex flex-row-column margin-left-m"
                      *ngFor="let complexity of sensitiveDataOptionsOriginal"
                    >
                      <div
                        class="eclipse-content margin-left-s margin-top-s d-flex flex-row"
                      >
                        <div
                          *ngIf="
                            complexity.label &&
                            selectedSensitiveData.includes(complexity.label)
                          "
                          class="margin-right-s"
                        >
                          <img src="/assets/images/tick-icon.svg" />
                        </div>
                        <div
                          *ngIf="
                            complexity.label &&
                            !selectedSensitiveData.includes(complexity.label)
                          "
                          class="margin-right-xs empty-space"
                        ></div>
                        {{ complexity.label }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-top-m">
                  <div class="title-section">
                    <img src="/assets/images/badge-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s">{{
                      deloitteSowLabel
                    }}</span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs"
                  >
                    {{ deloitteStandardELSOWTemplateReferred }}
                  </div>
                </div>
                <div class="d-flex flex-row-column">
                  <div class="title-section">
                    <img src="/assets/images/contract-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s">{{
                      useOfExistingLabel
                    }}</span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs d-flex align-items-center"
                  >
                    <span> {{ useOfExistingMSAsOrContractMakerGBTs }} </span>

                    <span
                      *ngIf="useOfExistingMSAsOrContractMakerGBTs === NO"
                      class="icon-s icon-info position-alignment margin-left-s clickable d-inline-block"
                      [ddsTooltip]="existingMSAsOrContractMakerGBTsTooltip"
                      [tooltipInvokeType]="
                        groupToolTipOptions.tooltipInvokeType ?? ''
                      "
                      [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                      [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                      [tooltipPosition]="
                        groupToolTipOptions.tooltipPosition ?? ''
                      "
                      [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
                      [tooltipHasBeak]="
                        groupToolTipOptions.tooltipHasBeak ?? false
                      "
                      [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
                      ddsFocus
                      [tooltipMaxWidth]="
                        groupToolTipOptions.tooltipMaxWidth ?? 0
                      "
                    ></span>
                  </div>
                </div>
                <div
                  *ngIf="containCyberQuestions"
                  class="d-flex flex-row-column"
                >
                  <div class="title-section">
                    <img
                      class="margin-right-minus-xxs"
                      src="\assets\images\dds-icons\IsrealInvolvementLogo.svg"
                    />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ deloitteIsraelLabel }}
                    </span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs margin-bottom-xs d-flex align-items-center"
                  >
                    <span> {{ deloitteIsrael }} </span>
                    <span
                      *ngIf="deloitteIsrael === 'None (Explain)'"
                      class="icon-s icon-info position-alignment margin-left-xs clickable d-inline-block"
                      [ddsTooltip]="deloitteIsraelExplain"
                    ></span>
                  </div>
                </div>
                <div
                  *ngIf="containCyberQuestions"
                  class="d-flex flex-row-column"
                >
                  <div class="title-section">
                    <img
                      src="\assets\images\dds-icons\potentialClientLogo.svg"
                    />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ servicesDisruptClientLabel }}
                    </span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs margin-bottom-xs d-flex align-items-center"
                  >
                    <span> {{ servicesDisruptClient }} </span>
                    <span
                      *ngIf="servicesDisruptClient === YES"
                      class="icon-s icon-info position-alignment margin-left-s clickable d-inline-block"
                      [ddsTooltip]="
                        'Generated new ERS 230: \n' +
                        hasERS230 +
                        '\n\nApplication security consult:\n ' +
                        infraSecurityconsult
                      "
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </dds-tab>
        </dds-tabs>
      </div>
      <div class="d-flex bottom-border width-full">
        <!-- CLIENT DATA -->
        <div class="others-height width-half">
          <div class="eclipse-section-header text-align-left px-2 py-3">
            <span class="font-size-16">{{ clientLabel }}</span>
            <div class="" *ngIf="clientNumber">
              <app-intake-client
                [clientNumber]="clientNumber"
                (independenceDataEmitter)="receiveIndependenceData($event)"
              ></app-intake-client>
            </div>
          </div>
        </div>
        <!-- TEAM DATA -->
        <div
          class="d-flex left-border width-half flex-row-column others-height"
        >
          <div class="eclipse-section-header text-align-left p-3">
            <span class="font-size-16">{{ teamLabel }}</span>
          </div>

          <div class="d-flex text-align-left flex-row px-3">
            <app-team-opportunity-summary
              [dataOpportunityId]="opportunityNumber"
            />
          </div>
        </div>
      </div>
      <div class="d-flex flex-row width-full margin-top-l margin-bottom-l">
        <div class="eclipse-section-header text-align-left">
          {{ independenceLabel }}
          <div class="margin-top-s d-flex gap">
            <div class="gap-between">
              <p class="eclipse-small-header-grey padding-none margin-bottom-s">
                <span
                  class="green-color margin-right-xxs cursor-clickable"
                  (click)="independanceDescClicked()"
                >
                  <u>DESC</u></span
                >designation (Services)
              </p>
              <p class="eclipse-content padding-none margin-bottom-s">
                {{ independanceService || defaultEmptyFields }}
              </p>
              <p class="eclipse-small-header-grey padding-none margin-bottom-s">
                Services Restriction Type
              </p>
              <p class="eclipse-content padding-none">
                {{ independanceServiceDescription || defaultEmptyFields}}
              </p>
            </div>
            <div class="gap-between">
              <p
                class="eclipse-small-header-grey padding-none margin-bottom-s text-capitalize"
              >
                Oppty involves any of the following (US)
              </p>
              <p class="eclipse-content padding-none text-capitalize">
                {{ opptyInvolvedInUS || defaultEmptyFields }}
              </p>
              <div *ngIf="requiredConflictProcedure">
                <p
                  class="eclipse-small-header-grey padding-none margin-bottom-s text-capitalize"
                >
                  Req'd Conflict Proc. Taken by LEP (US)
                </p>
                <p class="eclipse-content padding-none">
                  {{ requiredConflictProcedure || defaultEmptyFields }}
                </p>
              </div>
              <div *ngIf="requiredConflictProcedure">
                <p
                  class="eclipse-small-header-grey padding-none margin-bottom-s text-capitalize"
                >
                  Req'd Conflict Procedures Taken (US)
                </p>
                <p class="eclipse-content padding-none">
                  {{ requiredConflictProcedure || defaultEmptyFields }}
                </p>
              </div>
              <p
                class="eclipse-small-header-grey padding-none margin-bottom-s text-capitalize"
              >
                Results of Conflict Check Procs (US)
              </p>
              <p class="eclipse-content padding-none">
                {{ resultofConflictCheck || defaultEmptyFields }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row-column right-section margin-top-l">
    <div class="d-flex bottom-border document-submission-section">
      <div class="eclipse-section-header text-align-left margin-top-m">
        <span class="padding-left-vl">
          {{ documentSubmissionLabel }}
        </span>
        <div class="scroll-container">
          <div *ngFor="let item of documentSubmissionList">
            <app-document-submission
              [item]="item"
              [opportunityNumber]="opportunityNumber"
              [archiveStatus]="archiveStatus"
            ></app-document-submission>
          </div>
        </div>
      </div>
    </div>
    <div class="archive-section padding-m">
      <div class="eclipse-section-header text-align-left padding-none">
        {{ archiveLabel }}
        <p
          class="eclipse-small-header-grey padding-none margin-bottom-xs margin-top-s"
        >
          Archive name
        </p>
        <p
          *ngIf="!archiveName"
          class="eclipse-content padding-none margin-bottom-xs"
        >
          Archive is not yet selected
        </p>
        <div
          *ngIf="archiveName"
          [style.color]="transformStatus(archiveStatus) | statusStyle : 'color'"
          class="padding-none margin-bottom-xs eclipse-content d-flex align-items-center"
        >
          <span (click)="openArchiveUrl()">{{ archiveName }}</span>
          <span
            class="icon-s icon-info position-alignment margin-left-s clickable d-inline-block"
            [ddsTooltip]="wbsArchiveSwfidivetails"
          ></span>
        </div>
        <span
          *ngIf="previousConnectedArchiveCount"
          class="eclipse-small-header-grey margin-top-xxs margin-bottom-xxs"
          >{{ previousConnectedArchiveCount }}
          {{ previousConnectedArchiveLabel }}
        </span>
        <div class="d-flex flex-row margin-top-xs">
          <dds-button
            class="margin-right-s"
            [theme]="greenLinedButton.theme ?? ''"
            [kind]="greenLinedButton.kind ?? ''"
            [size]="greenLinedButton.size ?? ''"
            [width]="greenLinedButton.width ?? ''"
            [isIconLeft]="greenLinedButton.isIconLeft ?? false"
            [role]="greenLinedButton.role ?? ''"
            [isInverse]="greenLinedButton.isInverse ?? false"
            [isColorBg]="greenLinedButton.isColorBg ?? false"
            [isLoading]="greenLinedButton.isLoading ?? false"
            [ariaLabel]="'Archive'"
            [customClass]="greenLinedButton.customClass ?? ''"
            [disabled]="greenLinedButton.disabled ?? false"
            (clicked)="openArchivePopup()"
          >
            <span *ngIf="archiveName">Change archive</span>
            <span *ngIf="!archiveName">Select archive</span>
          </dds-button>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</div>

<ng-template #wbsArchiveSwfidivetails>
  <div class="w-fit-content h-fit-content">
    <div class="d-flex">
      <div class="padding-xs">Archive name:</div>
      <div class="padding-xs font-bold-600">{{ archiveName }}</div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Archive description:</div>
      <div class="padding-xs font-bold-600">{{ archiveDescription }}</div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project name:</div>
      <div class="padding-xs font-bold-600">{{ swiftProjectName }}</div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project start:</div>
      <div class="padding-xs font-bold-600">
        {{ swiftStartDate }}
      </div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project end:</div>
      <div class="padding-xs font-bold-600">{{ swiftEndDate }}</div>
    </div>
    <div>
      <div class="padding-xs">Swift project PPMD:</div>
      <div class="padding-xs font-bold-600">{{ swiftPPMD }}</div>
    </div>
  </div>
</ng-template>
