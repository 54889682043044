<div class="dds-modal-init dds-modal-open">
    <div class="dds-modal-overlay"></div>
    <div class="dds-modal">
      <div class="dds-modal__header">
        <span class="dds-modal__title">{{resolve.labelHeader}}</span>
        <button class="dds-modal__close dds-icon dds-icon_close" aria-label="Close modal" (click)="close()"></button>
      </div>
      <div class="dds-modal__body">
        <div class="assign-users-block">
            <ng-container *ngFor="let user of users; let i = index">
                <div class="radio-inline">
                    <input id="nca-assign-to-user-{{i}}" type="radio"
                           [(ngModel)]="selectedUser"
                           [value]="user.empID"
                           name="nca-assign-to-user" />
                    <label for="nca-assign-to-user-{{i}}" (click)="selectUser(user.empID)">
                        <span>{{user.outlookDisplayName}}</span>
                    </label>
                </div>
            </ng-container>
            <span *ngIf="!users || !users.length">No users</span>
        </div>
      </div>
      <div class="dds-modal__footer">
        <div class="dds-modal__footer-content dds-modal__footer-content_left"></div>
        <div class="dds-modal__footer-content dds-modal__footer-content_right">
          <div class="dds-modal__footer-item dds-inline-flex">
            <button class="dds-btn dds-btn_secondary dds-btn_fluid-width" (click)="close()">
              <div class="dds-btn__content">
                <span class="dds-btn__text">Cancel</span>
              </div>
            </button>
          </div>
          <div class="dds-modal__footer-item dds-inline-flex">
            <button class="dds-btn dds-btn_fluid-width" (click)="assignNCASpecialist()" [disabled]="!selectedUser">
              <div class="dds-btn__content">
                <span class="dds-btn__text">Confirm</span>
              </div>
            </button>
          </div>
        </div>
      </div>
  </div>
</div>