import { Component, Input, OnInit } from '@angular/core';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import {
  ADD_REVIEWER_BTN_OPTIONS,
  riskManagerTableHeaderData,
} from './external-communication-workflow-submission.helper';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { PermissionCheck } from '../../../../common/validations/PermissionCheck';
import { SecurityWebapiService } from '../../../../http/security/security-webapi.service';

@Component({
  selector: 'app-external-communication-workflow-submission',
  templateUrl: './external-communication-workflow-submission.component.html',
  styleUrl: './external-communication-workflow-submission.component.less',
})
export class ExternalCommunicationWorkflowSubmissionComponent extends PermissionCheck implements OnInit {
  riskManagerTableHeader: Column[] = riskManagerTableHeaderData;
  options: ButtonOptions = ADD_REVIEWER_BTN_OPTIONS;
  @Input() ppmdName!: string;
  isRequiredCheckboxVisible: boolean = false;
  isRequiredCheckboxEnable: boolean = false;
  isRiskManagerVisible: boolean = false;
  isRiskManagerEnable: boolean = false;
  isSupportingRiskManagerVisible: boolean = false;
  isSupportingRiskManagerEnable: boolean = false;
  isRMSupportVisible: boolean = false;
  isRMSupportEnable: boolean = false;
  isReviewerVisible: boolean = false;
  isReviewerEnable: boolean = false;
  isAddReviewerVisible: boolean = false;
  isAddReviewerEnable: boolean = false;
  constructor(
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
  }

  ngOnInit(): void {
    this.isRequiredCheckboxVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowRequiredCheckbox,
      this.permissionType.Visible
    );
    this.isRequiredCheckboxEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowRequiredCheckbox,
      this.permissionType.Enable
    );
    this.isRiskManagerVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowRiskManager,
      this.permissionType.Visible
    );
    this.isRiskManagerEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowRiskManager,
      this.permissionType.Enable
    );
    this.isSupportingRiskManagerVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowSupportingRiskManager,
      this.permissionType.Visible
    );
    this.isSupportingRiskManagerEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowSupportingRiskManager,
      this.permissionType.Enable
    );
    this.isRMSupportVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowRMSupport,
      this.permissionType.Visible
    );
    this.isRMSupportEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowRMSupport,
      this.permissionType.Enable
    );
    this.isReviewerVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowReviewer,
      this.permissionType.Visible
    );
    this.isReviewerEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowReviewer,
      this.permissionType.Enable
    );
    this.isAddReviewerVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowAddReviewer,
      this.permissionType.Visible
    );
    this.isAddReviewerEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_WorkflowAddReviewer,
      this.permissionType.Enable
    );
  }
}
