<div class="padding-top-s new-pagination-and-grid">
    <div class="pagination-container">
        <span class="dds-item">
            <dds-pager [disabled]="paging.disabled ?? false"
                       [label]="paging.label ?? ''"
                       [size]="paging.size ?? ''"
                       [minValue]="paging.minValue ?? 1"
                       [maxValue]="paging.maxValue ?? 5"
                       [customClass]="paging.customClass ?? ''"
                       [value]="pageUI"
                       (pageChanged)="onPageChanged($event)">
            </dds-pager>
        </span>
        <span>
            <dds-pagination [size]="paginationOptions.size ?? ''"
                            [disabled]="paginationOptions.disabled ?? false"
                            [isInverse]="paginationOptions.isInverse ?? false"
                            [mode]="paginationOptions.mode ?? ''"
                            [pageLength]="paginationOptions.pageLength ?? 1"
                            [pageNumberInSection]="paginationOptions.pageNumberInSection ?? 1"
                            [theme]="paginationOptions.theme ?? ''"
                            [ariaLabel]="paginationOptions.ariaLabel ?? ''"
                            [value]="pageUI"
                            [customClass]="paginationOptions.customClass?? ''"
                            [isIndefinitePageLength]="paginationOptions.isIndefinitePageLength ?? false"
                            [isResponsive]="paginationOptions.isResponsive ?? false"
                            (pageChanged)="onPageChanged($event)">
            </dds-pagination>
        </span>
        <span class="dds-item-per-page"> 
            <dds-items-per-page [isResponsive]="itemPerPage.isResponsive ?? false"
                   [isInverse]="itemPerPage.isInverse ?? false"
                   [icon]="itemPerPage.icon ?? ''"
                   [placeholder]="itemPerPage.placeholder ?? ''"
                   [customClass]="itemPerPage.customClass ?? ''"
                   [title]="itemPerPage.title ?? ''"
                   [size]="itemPerPage.size ?? ''"
                   [list]="list"
                   [value]="item"
                   [disabled]="itemPerPage.disabled ?? false"
                   [stickerMaxHeight]="itemPerPage.stickerMaxHeight ?? ''"
                   [stickerIsDisabled]="itemPerPage.stickerIsDisabled ?? false"
                   [stickerPosition]="itemPerPage.stickerPosition ?? ''"
                   [stickerIndent]="itemPerPage.stickerIndent ?? 0"
                   [stickerCustomClass]="itemPerPage.stickerCustomClass ?? ''"
                   [stickerIsDynamic]="itemPerPage.stickerIsDynamic ?? true"
                   (valueChanged)="onValueChanged($event)">
            </dds-items-per-page>
        </span>
    <div>
</div>
