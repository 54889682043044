import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'split-outlook-display-name',
    template: `<span class="split-outlook-display-name">
                    <span class="name" [innerHtml]="displayName"></span>
                    <span class="location" [innerHtml]="location"></span>
                </span>`,
	styleUrls: ['./split-outlook-display-name.component.less']
})
export class SplitOutlookDisplayName implements OnChanges {
    @Input() name: any;

    posLocation: number = 0;
    searchFor: string = '(US';
    displayName: string= '';
    location: string= '';

    constructor() {
        if (!this.name) {
            return;
        }
        this.splitName();
    }

	public ngOnChanges(changes: any) {
        if (changes.name && changes.name.currentValue) {
            this.splitName();
       }
    }

    private splitName() {
        this.posLocation = 0;
        this.posLocation = this.name.indexOf(this.searchFor);
        if (this.posLocation > 0) {
            this.displayName = this.name.substring(0, this.posLocation);
            this.location = this.name.substring(this.posLocation);
        } else {
            this.displayName = this.name;
            this.location = '';
        }
    }
}