import { ErrorState, Themes } from '@usitsdasdesign/dds-ng/shared';

export const ToggleGroupOptions = {
  title: '',
  isInverse: false,
  isRequired: false,
};

export const ToggleOptions = {
  theme: Themes.green,
  label: 'Hide all draft documents (e.g. drafts and supporting documents)',
  ariaLabel: '',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isProcessingStatusActive: true,
  isRequired: false,
  customClass: '',
};

export const Column = [
  { name: 'Title', header: 'Title', dataType: 'string' },
  { name: '', header: '', dataType: 'string' },
  { name: 'Type', header: 'Type', dataType: 'string' },
  {
    name: 'Status',
    header: 'Status',
    dataType: 'string',
  },
  { name: 'Uploaded Date', header: 'Uploaded Date', dataType: 'string' },
  { name: 'Uploaded By', header: 'Uploaded By', dataType: 'string' },
  { name: 'Actions', header: 'Actions', dataType: 'string' },
];
