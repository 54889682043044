import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  OTHER_RADIO_GROUP_VALUE,
  RADIO_GROUP_OPTIONS_FOR_OTHER_SCOPE,
} from './close-out-other-helper';
import {
  WbsArchiveReceivedModel,
  WbsWithArchiveDetailsModel,
} from '../../../../common/models/wbs-picker.model';
import { OtherOptionValues } from '../../../constants/intake_document_close_out_other_option_values.enum';
import {
  IntakeSubmissionDetailsCommonSectionDataReceivedModal,
  OtherWithWbsArchiveEventEmitterModel,
  SwiftDetails,
} from '../../../../common/models/intake-submission-details-common-section.model';
import { IntakeConstant } from '../../../constants/intake.constant';

@Component({
  selector: 'app-close-out-other',
  templateUrl: './close-out-other.component.html',
  styleUrl: './close-out-other.component.less',
})
export class CloseOutOtherComponent {
  @Input() other = IntakeConstant.EMPTY_SPACE;
  @Input() wbsChargeCode: string = IntakeConstant.EMPTY_SPACE;
  @Input() wbsDescription: string = IntakeConstant.EMPTY_SPACE;
  @Input() dataSubmissionId: number | null = null;
  @Input() dataOpportunityId: string = IntakeConstant.EMPTY_SPACE;
  @Input() archiveDetails: WbsArchiveReceivedModel | null = null;
  @Input() swiftDetails: SwiftDetails | null = null;
  @Input()
  submissionData: IntakeSubmissionDetailsCommonSectionDataReceivedModal | null =
    null;
  @Output()
  otherWithWbsArchiveValueChange: EventEmitter<OtherWithWbsArchiveEventEmitterModel> =
    new EventEmitter<OtherWithWbsArchiveEventEmitterModel>();
  other_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_OTHER_SCOPE;
  other_radio_values: any = OTHER_RADIO_GROUP_VALUE;
  wbs_chargecode_state: string = OtherOptionValues.YES;

  otherStateChanged(event: string): void {
    this.other = event;
    const OtherWithWbsArchiveValue: OtherWithWbsArchiveEventEmitterModel = {
      other: event,
    };
    this.otherWithWbsArchiveValueChange.emit(OtherWithWbsArchiveValue);
  }

  onWbsOrArchiveChange(
    wbsWithArchiveDetails: WbsWithArchiveDetailsModel
  ): void {
    const otherWithWbsArchiveValue: OtherWithWbsArchiveEventEmitterModel = {
      other: this.other ?? IntakeConstant.EMPTY_SPACE,
      ...wbsWithArchiveDetails,
    };

    this.otherWithWbsArchiveValueChange.emit(otherWithWbsArchiveValue);
  }
}
