import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environment/environment';
import { Observable } from 'rxjs';
import { DashboardDataCollection, DashboardGridLevelFilter, OpportunityData } from '../../common/models/start-page/start-page.model';
import { NCADashboardTilesCount, NCASpecialistDashboardModel } from '../../common/models/start-page/nca-specialist-dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class NCASpecialistWebapiService {
  constructor(private httpService: HttpClient) { }

  apiUrl: string = environment.apiUrl;

  getNCADashBoardGridData(tileCode: string, pageNumber: number, pageSize: number, sortParameterCode: number|null, isAscending: boolean, filterData : DashboardGridLevelFilter[]): Observable<DashboardDataCollection<NCASpecialistDashboardModel>> {
    let params = new HttpParams()
              .set('tileCode', tileCode)
              .set('pageNumber', pageNumber.toString())
              .set('pageSize', pageSize.toString())
              .set('isAscending', isAscending.toString());
              if (sortParameterCode !== null && sortParameterCode !== undefined) {
                params = params.set('sortParameterCode', sortParameterCode.toString());
            }
    
    return this.httpService.post<DashboardDataCollection<NCASpecialistDashboardModel>>(this.apiUrl + 'NCA/NCADashboard/GetNCADashBoardGridData',filterData, { params });
  }

  //ExportCCDueData() {    
  //  return this.httpService.get<string>(this.apiUrl + 'NCA/NCADashboard/ExportCCDueData');
  //}
  ExportCCDueData(
  ): Observable<{ Base64String: string, FileName: string }> {

    return this.httpService.get<{ Base64String: string, FileName: string }>(
      this.apiUrl + 'NCA/NCADashboard/ExportCCDueData'
    );
  }


  getNCADashboardTilesCount(): Observable<NCADashboardTilesCount> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpService.get<NCADashboardTilesCount>(this.apiUrl + 'NCA/NCADashboard/GetNCADashboardTilesCount', { headers });
  }


  getOpportunitiesForClient(clientNumber: string): Observable<OpportunityData[]> {
    const params = { clientNumber: clientNumber };
    return this.httpService.get<OpportunityData[]>(this.apiUrl + 'NCA/NCADashboard/GetOpportunitiesForClient', { params });
  }

}

