import { Component, Input } from '@angular/core';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { TPAWebApiService } from '../../http/thirdpartyagreement/tpa.webapi.service';
import { LookupData } from '../../common/models/third-party-repository/third-party-agreement.model';
import { DropdownItem } from '../../common/services/dropdowns';
import {  UploadFile } from '@usitsdasdesign/dds-ng/uploader';
import { FileValidator } from "../../common/validations/file-validation";
import { DocumentService } from '../../http/document/document.service';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { NotifyPopupComponent } from '../../common/notify-popup/notify-popup.component';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { TPADocSettings } from './tpa-document-upload-popup-settings';

@Component({
  selector: 'app-tpa-document-upload-popup',
  templateUrl: './tpa-document-upload-popup.component.html',
  styleUrl: './tpa-document-upload-popup.component.less'
})
export class TPADocumentUploadPopupComponent {
  constructor(private modalService: ModalService,
    private progressIndicator: ProgressIndicatorService,
    private tpaWebApiService: TPAWebApiService,
    private documentService: DocumentService,
    private commonHelperService: CommonHelperService) { }

  displayStyle: any = "none";

  @Input() isInverse: boolean = false;
  @Input() thirdPartyAgreementId: boolean = false;
  @Input() public fileList: any = {};
  popupTitle: string = "";
  terminationDate: string = "";
  effectiveDate: string = "";
  isAutoRenew: any = '';
  documentTypeId: number = 0;
  documentTypeList: any = [];
  isSaveDisabled: boolean = true;
  canUpload: boolean = true;
  disableUpload: boolean = false;
  alertMessage: string = '';

  autoRenewRadioBtn: any = TPADocSettings.autoRenewRadioBtn;
  effectiveDateOptions: any = TPADocSettings.effectiveDateOptions;
  terminationDateOptions: any = TPADocSettings.terminationDateOptions;
  documentTypeOptions: any = TPADocSettings.documentTypeOptions;
  uploadAreaOptions: any = TPADocSettings.uploadAreaOptions;

  ngOnInit(): void {
    this.popupTitle = "Attach client agreement";
    this.getDocumentType();
    if (this.fileList.length > 0) {
      this.disableUpload = true;
    }
  }
  getDocumentType() {
    this.tpaWebApiService.getThirdPartyAgreementLookup('DocumentType')
       .subscribe({
      next: (response: any) => {
           this.documentTypeList = response[0].lookupItem.map((lookupData: LookupData) => ({
             value: lookupData.id,
             itemCode: lookupData.code,
             heading: lookupData.name,
             isItemActive: true
           } as DropdownItem));;
      },
      error: (err) => {
        // Handle error
      }
    })
  }

  changeDocumentTypeOptions(event: any) {
    if(this.documentTypeId > 0) {
      this.enableSaveButton();
    }
  }
  changeEffectiveDateOptions(event: any) {
    if (event != '') {
      this.effectiveDate = event;
      this.enableSaveButton();
    }
  }
  enableSaveButton() {
    if (this.effectiveDate != '' && this.documentTypeId > 0 && this.fileList.length > 0) {
      this.isSaveDisabled = false;
    }
    else {
      this.isSaveDisabled = true;
    }
  }

  clearList() {
    this.fileList = [];
    this.disableUpload = false;
  }

  validateFile(selectedFilesList: any) {
    if (!FileValidator.isFileExtensionValid_SingleFile(selectedFilesList)) {
      return false;
    }
    if (FileValidator.isSigleFileMaxSizeExceed(selectedFilesList)) {
      return false;
    }
    return true;
  }

  uploadedItems(selectedFilesList: UploadFile[]) {
    if (selectedFilesList.length > 0) {
      if (this.validateFile(selectedFilesList)) {
        this.removeBodyOverflow();
        this.fileList = selectedFilesList;
        if (this.fileList.length > 0) {
          this.enableSaveButton();
        }
      }
      else {
        this.showMsg();
        selectedFilesList.forEach((element: any, index: any) => {
          element.remove();
        });
      }
    }
    else {

    }
  }

  showMsg(modalBtn?: ButtonComponent) {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(NotifyPopupComponent, { isFooter: true, size: 'md', isInverse: false, notifyReason: this.alertMessage, footerButtonText: 'OK' });
    modalRef.onClosed().subscribe(() => {
      if (modalBtn) {
        modalBtn.focus();
      }
      this.removeBodyOverflow();
    });
  }

  prepareMetaData(attachmentID : any) {
    let metaData = {
      documentTypeId: this.documentTypeId,
      effectiveDate: this.effectiveDate,
      terminationDate: this.terminationDate,
      isAutoRenew: this.isAutoRenew,
      thirdPartyAgreementId: this.thirdPartyAgreementId,
      attachmentID: attachmentID,
      createdBy: '',
      modifiedBy: '',
      DocumentTypeName: '',
      fileName: this.fileList[0].file.name
    }
    return metaData;
  
  }
  saveNCADocumentMetaData(attachmentID : any) {
    let metaData = this.prepareMetaData(attachmentID);
    this.tpaWebApiService.saveTPADocument(metaData).subscribe({
      next: (response: any) => {
        this.fileList = [];
        this.progressIndicator.hide();
        this.closePopup();
      },
      error: (err) => {
        // Handle error
        this.fileList = [];
        this.progressIndicator.hide();
        this.closePopup();
      }
    });
  }

  getUploadFilePath() {
    return this.commonHelperService.getDocumentFolderPath("TPA");
  }

  uploadFiletoS3() {
    this.progressIndicator.show();
    let filePath = this.getUploadFilePath();    
    for (const fileSource of this.fileList) {
      let file = {fileSource : fileSource.file}
      this.documentService.uploadDocument(file, filePath).subscribe(
        {
          next: (result: any) => {
            if (result.isDocumentUploaded == false && result.isDocumentProtected == true) {
                //this.notifyReason = "DocumentProtected";
                //this.documentUnUploadData.push(result.documentName);
                this.progressIndicator.hide();
                //this.showMsg();                            
            }
            else {              
              this.saveNCADocumentMetaData(result.attachmentID);             
            }
          },
          error: (err: any) => {
            this.progressIndicator.hide();
          },
          complete: () => {
            this.progressIndicator.hide();
          }
        })
    }
  }

  onSave() {
    this.progressIndicator.show();
    this.applyBodyOverflow();
    this.uploadFiletoS3();
  }
  applyBodyOverflow() {
    document.body.style.overflow = 'auto';
  }
  closePopup() {
    this.applyBodyOverflow();
    this.modalService.close(this.fileList);
  }
  closeModal() {
    this.fileList = [];
    this.closePopup();
  }
  removeBodyOverflow() {
    document.body.style.overflow = 'hidden';
  }
  onAutoRenewChangedEvent() {

  }
}
