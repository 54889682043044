import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment';
import { MARSConstant } from '../../client/constants/mars-constant';
@Injectable({
  providedIn: 'root'
})
export class CommonHelperService {

  constructor() { }

  getBaseUrl(): string {
    return environment.rootUrl;
  }

  getDocumentFolderPath(moduleName:any) {
    let filePath = "";
    if (moduleName == "NCA") {
      filePath= environment.docAPIConfig.ncaFolderPath;
    }
    else if (moduleName == "CC") {
      filePath = environment.docAPIConfig.ccFolderPath;
    }
    else if (moduleName == "MARS") {
      filePath = environment.docAPIConfig.marsFolderPath;
    }
    else if (moduleName == "Agreement") {
      filePath = environment.docAPIConfig.agreementFolderPath;
    }
    else if (moduleName == "TPA") {
      filePath = environment.docAPIConfig.tpaFolderPath;
    }
    return filePath;
  }
  loadFormWiseDocumentTypes(response:any,moduleName:any,docSectionId:any){
    let documentTypeObject:any={};
    let documentTypeItemsList:any=[];
     response.forEach((item:any) => {
       documentTypeObject={};
        if(moduleName=="MARS"){
          if(item.issystemtype){
          documentTypeObject.value= Number(item.clientmilestonedocumenttypeid),
          documentTypeObject.heading= item.clientmilestonedocumenttypename
          documentTypeItemsList.push(documentTypeObject);
          }
          //Adding Common Document Type "Other" for MARS
          if(item.clientmilestonedocumenttypename=="Other"){
            documentTypeObject.value= Number(item.clientmilestonedocumenttypeid),
            documentTypeObject.heading= item.clientmilestonedocumenttypename
            documentTypeItemsList.push(documentTypeObject);
          }
        }
        else if(moduleName!="MARS"&& !item.issystemtype){
          documentTypeObject.value= Number(item.clientmilestonedocumenttypeid),
          documentTypeObject.heading= item.clientmilestonedocumenttypename
          documentTypeItemsList.push(documentTypeObject);
        }
      });
       return  documentTypeItemsList=this.loadDocumentTypesBySection(documentTypeItemsList,docSectionId,moduleName);;
  }
  loadDocumentTypesBySection(documentTypeItemsList:any,docSectionId:any,moduleName:any){
  if(moduleName=="MARS"){
    if(docSectionId==MARSConstant.MARS_Finanicial_SectionID){
      documentTypeItemsList= documentTypeItemsList.filter((element:any)=>element.value!=3&&element.value!=14);//removing conflict and other documenttype
    }
    else if(docSectionId==MARSConstant.MARS_Conflict_SectionID){
      documentTypeItemsList= documentTypeItemsList.filter((element:any)=>element.value!=3&&element.value!=13);//removing audit and other documenttype
    }
  }
   return documentTypeItemsList;
  }
  public trimPrefixEmptySpace(comment: any): any {
    return comment= comment==null?comment:comment.trimStart().trimEnd();
  }

  public stripHtmlTags(html: string): string {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
  }
}
