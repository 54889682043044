import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { RushRequestPopupComponent } from './rush-request-popup/rush-request-popup.component';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import {
  DatepickerOptions,
  DatepickerStates,
} from '@usitsdasdesign/dds-ng/datepicker';
import { PositionState, Size } from '@usitsdasdesign/dds-ng/shared';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import { BrowserTabConstant } from '../../../../common/constants/browser-tab-constant';
import { PermissionsObj } from '../../../../common/models/common-models';
import { InitiateExternalCommunicationRequestModel } from '../../../../common/models/external-communication.model';
import { isBusinessDay } from '../../../../common/utils/common-util';

@Component({
  selector: 'app-external-communication-submission-detail',
  templateUrl: './external-communication-submission-detail.component.html',
  styleUrl: './external-communication-submission-detail.component.less',
})
export class ExternalCommunicationSubmissionDetailComponent implements OnInit {
  @Input() submissionDetailModel: any; // TODO: Instead of taking whole model we can take specific variables and use that.
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;
  @Input() isDisabled: boolean = false;

  @Output() businessDaysChange = new EventEmitter<number | null>();
  @Output()
  updateRequestedReviewDate: EventEmitter<InitiateExternalCommunicationRequestModel> =
    new EventEmitter<InitiateExternalCommunicationRequestModel>();

  dateFormater: string = ExternalCommunicationConstant.DATE_FORMAT;
  dateFormaterUS: string = ExternalCommunicationConstant.DATE_FORMAT;
  selectedDate: string | null = null;
  dateForm!: FormGroup;
  businessDays: number = 0;
  htmlElementSize: Size = ExternalCommunicationConstant.UI_ELEMENT_SIZE;
  private readonly unsubscriber$ = new Subject<void>();
  numberOfBusinessDays: number = ExternalCommunicationConstant.TEXTAREA_LENGTH;
  emptyString: string = ExternalCommunicationConstant.EMPTY_SPACE;
  rushRequestValue: string | null = null;
  datePickerOptions: DatepickerOptions = {
    size: this.htmlElementSize,
    placeholder: 'Select date',
    format: this.dateFormater,
    minMode: DatepickerStates.day,
    isResponsive: true,
    isManualInput: false,
    stickerPosition:PositionState.bottomLeft
  };
  isInitializing: boolean = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly modalService: ModalService,
    private readonly externalCommunicationService: ExternalCommunicationService
  ) {}

  ngOnInit(): void {
    this.dateFormInitialize();
    document.title = BrowserTabConstant.Browser_TabName_ExternalCommunications;
    this.externalCommunicationService.resetDatePicker$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.openDatepicker();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changesDetailsModel = changes['submissionDetailModel'];
    if (
      JSON.stringify(changesDetailsModel?.currentValue) !==
      JSON.stringify(changesDetailsModel?.previousValue)
    ) {
      this.isInitializing = true;
      this.rushRequestValue = !!this.submissionDetailModel?.isRushRequest
        ? this.submissionDetailModel?.isRushRequest
        : null;
      this.dateForm?.patchValue({
        selectedDateCtrl: this.submissionDetailModel?.requestedReviewDate
          ? new Date(this.submissionDetailModel?.requestedReviewDate)
          : this.emptyString,
      });
      this.isInitializing = false;
    }
  }

  openDatepicker(): void {
    const datepickerElement = document.getElementById(
      'requestedReviewDate-datepicker'
    );
    const datepickerButton =
      datepickerElement?.querySelector<HTMLButtonElement>(
        '.dds-datepicker__btn'
      );
    datepickerButton?.click();
  }

  openModal(): void {
    const RushRequestPopupModalRef = this.modalService.open(
      RushRequestPopupComponent
    );
    RushRequestPopupModalRef.onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: { [key: string]: string }) => {
          const action: string = data ? data['action'] : 'reset';
          this.rushRequestValue = data ? data['textValue'] : '';
          /*
           * Three actions - update, modify, reset
           * update - when we add the reason and click ok button
           * modify - when we select modify date option
           * reset - when we directly close the modal without clicking any action button
           */
          if (action === 'update') {
            this.updateSubmissionDetails();
          } else {
            this.dateForm?.patchValue({
              selectedDateCtrl: this.submissionDetailModel?.requestedReviewDate
                ? new Date(this.submissionDetailModel.requestedReviewDate)
                : this.emptyString,
            });
          }
        },
        error: (err: Error) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  updateSubmissionDetails(): void {
    const requestData: InitiateExternalCommunicationRequestModel = {
      SubmissionId: this.submissionDetailModel.submissionId,
      CommunicationTypeId: this.submissionDetailModel.communicationTypeId,
      RequestedReviewDate: !!this.selectedDate ? this.selectedDate : null,
      AudiencePublication: this.submissionDetailModel.audiencePublication,
      PrimaryIndustrySectorId:
        this.submissionDetailModel.primaryIndustrySectorId,
      IsDCF: this.submissionDetailModel.isDCF,
      IsGPS: this.submissionDetailModel.isGPS,
      OfferingPortfolioId: this.submissionDetailModel.offeringPortfolioId,
      OfferingId: this.submissionDetailModel.offeringId,
      MarketOfferingId: this.submissionDetailModel.marketOfferingId,
      PrimaryContact: this.submissionDetailModel.primaryContact,
      SecondaryContact: this.submissionDetailModel.secondaryContact,
      MarketingTalentPRContact:
        this.submissionDetailModel.marketingTalentPRContact,
      AdditionalComments: this.submissionDetailModel.additionalComments,
      NotesAndFeedback: this.submissionDetailModel.notesAndFeedback,
      IsRushRequest: this.rushRequestValue,
      InternalQRM: this.submissionDetailModel.internalQRM,
      SubmissionTitle: this.submissionDetailModel.submissionTitle,
    };

    this.updateRequestedReviewDate.emit(requestData);
  }

  dateFormInitialize(): void {
    this.dateForm = this.fb.group({
      selectedDateCtrl: [
        this.submissionDetailModel?.requestedReviewDate
          ? new Date(this.submissionDetailModel?.requestedReviewDate)
          : this.emptyString,
      ],
    });

    this.dateForm.get('selectedDateCtrl')?.valueChanges.subscribe((value) => {
      if (value !== this.emptyString) {
        const localDateString: string = new Date(value).toLocaleDateString(
          ExternalCommunicationConstant.DATESTRING_FORMAT_US
        );
        this.selectedDate = value ? localDateString : null;
        this.displayBusinessDays(value, localDateString);
        this.businessDaysChange.emit(this.businessDays);
      } else {
        this.businessDays = 0;
        this.businessDaysChange.emit(null);
      }
    });
  }

  displayBusinessDays(
    selectedDate: Date | null,
    localDateString: string
  ): void {
    const currentDate: Date = new Date();
    this.businessDays = this.calculateBusinessDays(currentDate, selectedDate);
    if (this.businessDays < this.numberOfBusinessDays) {
      if (!this.isInitializing) {
        this.openModal();
      }
    } else {
      // Condition to avoid calling update API if date is same
      if (localDateString !== this.submissionDetailModel.requestedReviewDate) {
        this.updateSubmissionDetails();
      }
    }
  }

  calculateBusinessDays(currentDate: Date, selectedDate: Date | null): number {
    this.businessDays = 0;
    if (!selectedDate) {
      return this.businessDays;
    }
    let date: Date = new Date(currentDate);

    while (date <= selectedDate) {
      if (isBusinessDay(date)) {
        this.businessDays++;
      }
      date.setDate(date.getDate() + 1);
    }
    return this.businessDays;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
