import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { ParsedRules } from '../../common/models/common-models';
@Injectable({
  providedIn: 'root',
})
export class SecurityWebapiService {
  readonly behaviorSubject: BehaviorSubject<any>;
  public data: Observable<any>;
  constructor(private readonly httpService: HttpClient) {
    const initialState: any = { empty: true };
    this.behaviorSubject = new BehaviorSubject(initialState);
    this.data = this.behaviorSubject.asObservable();
  }
  private permissionObject: ParsedRules = {};
  apiUrl: string = environment.apiUrl;
  // apiUrl: string = 'https://dapi.eclipse.deloitte.com/api/'; //dev
  //apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa

  public getPermissionsByEmployeeId(
    entityId: string,
    entityTypeId: number
  ): Promise<ParsedRules> {
    return new Promise((resolve, reject) => {
      const endpoint = 'Admin/Permission/GetPermissionsByEmployeeId';
      this.httpService
        .get<string[]>(
          `${this.apiUrl}${endpoint}?entityId=${entityId}&entityTypeId=${entityTypeId}`
        )
        .subscribe({
          next: (res: string[]) => {
            const parsedRules = this.parseRules(res);
            this.setPermissions(res);
            resolve(parsedRules);
          },
          error: (err: HttpErrorResponse) => {
            console.error('Error while fetching permissions', err);
            reject();
          },
        });
    });
  }

  public setPermissions(permissions: string[]) {
    this.permissionObject = this.parseRules(permissions);
    this.behaviorSubject.next(this.permissionObject);
  }

  parseRules(result: string[]) {
    let newRules: ParsedRules = {};
    for (let permissionFullName of result) {
      let elementId = permissionFullName.substring(
        0,
        permissionFullName.lastIndexOf('.')
      );
      let permission = permissionFullName.substring(
        permissionFullName.lastIndexOf('.') + 1
      );
      if (!newRules[elementId]) {
        newRules[elementId] = {};
      }
      newRules[elementId][permission] = true;
    }
    return newRules;
  }

  isPermissionPresent(fieldKey: string, permissionType: string): boolean {
    return this.permissionObject?.[fieldKey]?.[permissionType] ?? false;
  }
}
