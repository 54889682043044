import { AdminConstant } from './../constants/admin-constants';
import { Component } from '@angular/core';
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  Themes,
  Size,
  TabThemeType,
  ExtThemes,
  ButtonKind,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import {
  VerticalTabsOptions,
  VerticalTabOptions,
  VerticalTabComponent,
} from '@usitsdasdesign/dds-ng/vertical-tabs';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrl: './admin-page.component.less',
})
export class AdminPageComponent implements OnInit, OnDestroy {
  label = AdminConstant.Label;
  options = AdminConstant.Button_Options;
  tabContainerOptions = AdminConstant.TabContainerOptions;
  verticalTabOptionsOne = AdminConstant.VerticalTabOptionsOne;
  verticalTabOptionsTwo = AdminConstant.VerticalTabOptionsTwo;
  verticalTabOptionsThree = AdminConstant.VerticalTabOptionsThree;

  constructor(private router: Router, private route: ActivatedRoute) {}
 

  ngOnInit(): void {
    // to be implemented according to upcoming stories
  }

   tabChanged(tabKey: string):void {
    this.verticalTabOptionsOne.isActive = tabKey === 'global configuration';
    this.verticalTabOptionsTwo.isActive = tabKey === 'roles list';
    this.verticalTabOptionsThree.isActive = tabKey === 'tables';
  }

  refreshPage($event: Event):void {
    throw new Error('Method not implemented.');
  }

  ngOnDestroy(): void {
    // to be implemented according to upcoming stories
  }
}
