import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { EmployeeView } from '../../common/models/common-models';

@Injectable({
  providedIn: 'root'
})
export class InitiationApiService {

  constructor(private httpService: HttpClient) { }

  apiUrl: string = environment.apiUrl;

  initiateMarsProcess(model: MarsClientInfo): Observable<any> {

    return this.httpService.post<any>(this.apiUrl + 'MARS/InitiateMARSProcess', model);
  }

  searchClients(searchTerm: string): Observable<any>{
    return this.httpService.get<any>(this.apiUrl + 'MARS/SearchClients?searchTerm=' + searchTerm);
  }

  searchPPMD(searchTerm: string): Observable<any> {
    return this.httpService.get<any>(this.apiUrl + 'MARS/SearchPPMD?searchTerm=' + searchTerm);
  }

  getApplicableMarsQrms(): Observable<EmployeeView[]> {
    return this.httpService.get<EmployeeView[]>(this.apiUrl + 'MARS/GetApplicableMarsQrms'); 
  }

  isClientNameExists(clientName: string): Observable<boolean> {
    return this.httpService.get<boolean>(this.apiUrl + 'MARS/IsClientNameExists?clientName=' + clientName);
  }
}

export interface MarsClientInfo {
  clientNumber: string;
  clientName: string;
  marsClientTypeID: number;
  recommendingPpmd: string;
  recommendingPpmdDisplayName: string;
  isHedgeFund: string;
  isConsultedWithQrm: string;
  applicableMarsQrm: string | null;
  consultationSummary: string;
}

export interface ClientSearchResult {
  clientId: number;
  clientName: string;
  clientNumber: string;
  isActiveMarsFormExists: boolean;
  globalClientCode: string;
}

