export enum DashboardGridSortParameter {
  activationStatus = 1,
  offeringPortfolio = 2,
  offering = 3,
  marketOffering = 4,
  excludedFromSelfReview = 5,
  totalFeeThresholds = 6,
  reviewerAvailability = 7,
}

export enum AdminExtTableSortParameter {
  name = 1,
  isActive = 2,
}
