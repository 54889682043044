import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { SpinnerThemes } from '@usitsdasdesign/dds-ng/shared';
import { SpinnerOptions } from '@usitsdasdesign/dds-ng/spinner';
import { ProgressIndicatorService } from '../../services/progress-indicator.service';

@Component({
    selector: 'progress-indicator',
    templateUrl: './progress-indicator.component.html',
    styleUrls: ['./progress-indicator.component.less']
})

export class ProgressIndicatorComponent implements OnInit {
    isVisible = false; 
    spinnerOptions: SpinnerOptions = {
        theme: SpinnerThemes.gray,
        thickness: '3px',
        size: '40px',
        customClass: '',
        isInverse: false
      };
constructor(private progressIndicatorService: ProgressIndicatorService) {}
    ngOnInit() {
        this.progressIndicatorService.spinnerVisibility$.subscribe(visible => {
            this.isVisible = visible;
          });
    }

   
}
