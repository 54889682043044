<div [autosave-module]="saveComplexityCyberModule">
  <form [formGroup]="complexityCyberForm" class="complexity-question-container">
    <div class="margin-top-m">
      <span
        *ngIf="isDeloitteIsraelExplainRequired()"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        1. Deloitte Israel involvement in the delivery of services:
      </span>
      <div
        *ngIf="
          complexityCyberForm.get('deloitteIsrael')?.value ===
          'Onshore/Offshore'
        "
        class="sub-text-section"
      >
        <span class="icon-s icon-pinned"></span>
        <span class="text-label eclipse-content-grey"
          >Access Contract Maker for the Deloitte IL SOW template to be used for
          onshore/offshore work.</span
        >
      </div>

      <div class="padding-top-xs">
        <dds-radio-group
          [name]="deloitteIsraelGroupOptions.name ?? ''"
          [disabled]="radioGroupOptions.disabled ?? false"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? ''"
          formControlName="deloitteIsrael"
        >
          <ng-container *ngFor="let radio of deloitteIsraelBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? ''"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? ''"
              [theme]="radio.options.theme ?? ''"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <!-- Conditional Section: Member Firm Names -->
    <div
      *ngIf="
        complexityCyberForm.get('deloitteIsrael')?.value === 'None [Explain]'
      "
    >
      <div class="row">
        <div class="col-10 margin-top-s">
          <span class="comments-text-label eclipse-small-header-grey"
            >Explain:</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="textarea-container">
            <dds-input
              autosave
              formControlName="deloitteIsraelExplain"
              [placeholder]="'Explain'"
              [maxLength]="textareaOptions.maxLength ?? 0"
              [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
              [size]="htmlElementSize"
            >
            </dds-input>
          </div>
        </div>
      </div>
    </div>
    <!--Question-2-->
    <div class="margin-top-m">
      <span
        *ngIf="isServicesDisruptClientRequired()"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        2. Indicate if the services being provided could disrupt or negatively
        affect the operations or operational systems of the client.
      </span>

      <div class="padding-top-xs">
        <dds-radio-group
          [name]="servicesDisruptClientGroupOptions.name ?? ''"
          [disabled]="radioGroupOptions.disabled ?? false"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? ''"
          formControlName="servicesDisruptClient"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? ''"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? ''"
              [theme]="radio.options.theme ?? ''"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>
    <!--Condtional question-2 - part-1-->
    <div
      *ngIf="complexityCyberForm.get('servicesDisruptClient')?.value === 'Yes'"
      class="margin-top-m"
    >
      <span class="text-label">
        Has the engagement team generated a new copy of the ERS 230 from
        Contract Maker and incorporated the required systems testing agreement,
        assumptions, and appendices from that document?
      </span>

      <div class="padding-top-xs">
        <dds-radio-group
          [name]="hasERS230GroupOptions.name ?? ''"
          [disabled]="radioGroupOptions.disabled ?? false"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? ''"
          formControlName="hasERS230"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? ''"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? ''"
              [theme]="radio.options.theme ?? ''"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>
    <!--Condtional question-2 - part-2-->
    <div
      *ngIf="complexityCyberForm.get('hasERS230')?.value === 'Yes'"
      class="margin-top-m"
    >
      <span class="text-label">
        Has the engagement team consulted with the LEP or Sr. Manager lead of
        either the Infrastructure-Attack Surface Management or Application
        Security-Secure Software Enablement team?
      </span>

      <div class="padding-top-xs">
        <dds-radio-group
          [name]="infraSecurityconsultGroupOptions.name ?? ''"
          [disabled]="radioGroupOptions.disabled ?? false"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? ''"
          formControlName="infraSecurityconsult"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? ''"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? ''"
              [theme]="radio.options.theme ?? ''"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>
    <!--Question-3-->
    <div class="margin-top-s">
      <span
        *ngIf="isServiceInvolvementRequired()"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        3. Does the engagement involve any of the following?
      </span>
      <div *ngIf="hasOptionsSelected" class="sub-text-section-2">
        <div><span class="icon-s icon-pinned"></span></div>
        <span class="text-label eclipse-content-grey">
          Confirm the client's responsibilities have been adequately defined and
          agreed upon (e.g., procurement of licenses, creation of development,
          test and staging environments, development of use cases, test
          scripts).</span
        >
      </div>
      <div class="padding-top-xs value-box">
        <dds-multi-select
          class="box-size"
          [disabled]="multiSelectOptions.disabled ?? false"
          [displayTickAllBtn]="multiSelectOptions.displayTickAllBtn ?? false"
          [isResponsive]="multiSelectOptions.isResponsive ?? false"
          [isRequired]="multiSelectOptions.isRequired ?? false"
          [label]="multiSelectOptions.label ?? ''"
          [size]="htmlElementSize"
          [placeholder]="multiSelectOptions.placeholder ?? ''"
          [type]="multiSelectOptions.type ?? ''"
          [controlType]="multiSelectOptions.controlType ?? ''"
          [theme]="multiSelectOptions.theme ?? ''"
          [isInverse]="multiSelectOptions.isInverse ?? false"
          [list]="serviceInvolvementOptions"
          formControlName="serviceInvolvement"
        >
        </dds-multi-select>
        <div>
          <span
            class="comments-text-label eclipse-small-header-grey"
            *ngIf="
              complexityCyberForm.get('serviceInvolvement')?.value.length > 0
            "
            >Selected values:</span
          >
          <dds-tags>
            <dds-tag
              class="tag-width"
              *ngFor="
                let item of complexityCyberForm.get('serviceInvolvement')?.value
              "
              [theme]="options.theme ?? ''"
              [isRemovable]="options.isRemovable ?? false"
              [disabled]="options.disabled ?? false"
              [customClass]="options.customClass ?? ''"
              [size]="htmlElementSize"
              [isError]="options.isError ?? false"
              [isInverse]="options.isInverse ?? false"
              (removed)="removeServiceInvolvement(item)"
              #tagItems
            >
              {{ item.label }}
            </dds-tag>
          </dds-tags>
        </div>
      </div>
    </div>
  </form>
</div>
