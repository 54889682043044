import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  HostListener,
  EventEmitter,
  Output,
} from '@angular/core';
import { copyToClipboard } from '../../../intake/common/intake.util';
import {
  datePickerOptions,
  tabContainerOptions,
  tabOptionsOne,
  tabOptionsTwo,
  tabOptionsThree,
  tabOptionsFour,
  tabOptionsFive,
  tabOptionsSeven,
  tabRisk,
  YES_NO,
} from './external-communication-submission.helper';
import { ExternalCommunicationService } from '../../../http/external-communication/external-communication.service';
import {
  primaryButton,
  radioBtns,
  radioGroupOptions,
} from '../../external-communications.helper';
import { IntakeConstant } from '../../../intake/constants/intake.constant';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { Subject, takeUntil } from 'rxjs';
import {
  ExtCommSubmissionDetailsModel,
  IndustrySectorModel,
  InitiateExternalCommunicationDropdownModel,
  InitiateExternalCommunicationRequestModel,
  MarketOfferingModel,
  OfferingModel,
  OfferingPortfolioModel,
} from '../../../common/models/external-communication.model';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import { ExternalCommunicationConstant } from '../../constants/external_communication.constant';
import { StatusEnum } from '../../constants/status-enum';
import { PermissionCheck } from '../../../common/validations/PermissionCheck';
import { SecurityWebapiService } from '../../../http/security/security-webapi.service';

@Component({
  selector: 'app-external-communication-submission',
  templateUrl: './external-communication-submission.component.html',
  styleUrls: ['./external-communication-submission.component.less'],
})
export class ExternalCommunicationSubmissionComponent
  extends PermissionCheck
  implements OnInit, OnChanges, OnDestroy
{
  @Input() submissionId: number = 0;
  @Input() communicationType!: string;
  @Input() communicationTypeId!: number;
  @Output() submissionTitleChange: EventEmitter<string | null> =
    new EventEmitter<string | null>();
  @Output() submitButtonStateChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  titleValue: string = 'External Communication Submission';
  submissionTypeCode: string = 'EXT COMM';
  submissionStatus: string = 'Submitted';
  unsubscriber$: Subject<void> = new Subject<void>();
  isVisible: boolean = true;
  tabContainerOptions = tabContainerOptions;
  tabOptionsOne = tabOptionsOne;
  tabOptionsTwo = tabOptionsTwo;
  tabOptionsThree = tabOptionsThree;
  tabOptionsFour = tabOptionsFour;
  tabOptionsFive = tabOptionsFive;
  tabOptionsSix = tabRisk;
  tabOptionsSeven = tabOptionsSeven;
  primaryButton = primaryButton;
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  yesValue = IntakeConstant.YES;
  noValue = IntakeConstant.NO;
  _blank = IntakeConstant.EMPTY_SPACE;
  submissionDetailModel!: ExtCommSubmissionDetailsModel;
  radioBtns = radioBtns;
  radioGroupOptions = radioGroupOptions;
  communicationTypeList: SelectItemOptions[] = [];
  industrySectorList: SelectItemOptions[] = [];
  marketOfferingList: SelectItemOptions[] = [];
  offeringList: SelectItemOptions[] = [];
  offeringPortfolioList: SelectItemOptions[] = [];
  isEditingType: boolean = false;
  public savedetails!: Saver | null;
  detailsRequestBody: InitiateExternalCommunicationRequestModel =
    {} as InitiateExternalCommunicationRequestModel;
  primaryPeople: string | null = null;
  primaryPeopleId: number | null = null;
  secondaryPeople: string = this._blank;
  secondaryPeopleId: number | null = null;
  marketContact: string = this._blank;
  marketContactId: number | null = null;
  isDCF: string | null = null;
  isGPS: string | null = null;
  datePickerOptions = datePickerOptions;
  isGPSDisabled: boolean = false;
  isDCFDisabled: boolean = false;
  governmentPublicServices: number[] = [41, 42, 43, 44];
  corporateFinanceId: number = 40;
  textareaOptions: any =
    ExternalCommunicationConstant.TEXT_AREA_SETTINGS_GLOBAL;
  enter_text_placeholder = ExternalCommunicationConstant.ENTER_TEXT_PLACEHOLDER;
  internalQrmCommunicationTextareaMaxlength: number =
    ExternalCommunicationConstant.TEXTAREA_CHAR_LIMIT_BIGGEST;
  showRiskAssessment: boolean = true;
  ppmdName!: string;
  yesNo = YES_NO;

  businessdaysSelected!: number;
  showRushRequestHeading: boolean = false;
  riskAssessmentRequiredFieldsFilled: boolean = false;
  communicationAttributesRequiredFieldsFilled: boolean = false;

  isTitleAreaSubmissionTitleVisible: boolean = false;
  isTitleAreaEXTCOMMVisible: boolean = false;
  isTitleAreaSubmissionIdVisible: boolean = false;
  isTitleAreaSubmissionStatusVisible: boolean = false;
  isSelfReviewEligibiltyVisible: boolean = false;
  isRushRequestBadgeVisible: boolean = false;
  isCommunicationTypeVisible: boolean = false;
  isPPMDTrainingCompletedVisible: boolean = false;
  isCommunicationTypeEnable: boolean = false;
  isSubmissionTitleVisible: boolean = false;
  isSubmissionTitleEnable: boolean = false;
  isAudience_PublicationVisible: boolean = false;
  isAudience_PublicationEnable: boolean = false;
  isRelatedToGovernmentandPublicServicesVisible: boolean = false;
  isRelatedToGovernmentandPublicServicesEnable: boolean = false;
  isIndustrySectorVisible: boolean = false;
  isIndustrySectorEnable: boolean = false;
  isDeloitteCorporateFinanceVisible: boolean = false;
  isDeloitteCorporateFinanceEnable: boolean = false;
  isOfferingVisible: boolean = false;
  isOfferingEnable: boolean = false;
  isMarketOfferingVisible: boolean = false;
  isMarketOfferingEnable: boolean = false;
  isPrimaryContactVisible: boolean = false;
  isPrimaryContactEnable: boolean = false;
  isSecondaryContactVisible: boolean = false;
  isSecondaryContactEnable: boolean = false;
  isMarketingTalentPRContactVisible: boolean = false;
  isMarketingTalentPRContactEnable: boolean = false;
  isRiskAssessmentVisible: boolean = false;
  isRiskAssessmentEnable: boolean = false;
  isCommunicationAttributesVisible: boolean = false;
  isCommunicationAttributesEnable: boolean = false;
  isNotesAndFeedbackVisible: boolean = false;
  isNotesAndFeedbackEnable: boolean = false;
  hasSubmitButtonPermissionEnable: boolean = false;

  // TODO: NEED to map this below paermissions which are not in use still
  isUpdatedSelfReviewGuidanceVisible: boolean = false;
  isSolutionVisible: boolean = false;
  isSolutionEnable: boolean = false;
  hasTitleDetailsVisiblePermission: boolean = false;

  #previousSubmitButtonState: boolean = false;
  

  constructor(
    private readonly externalCommService: ExternalCommunicationService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly autosaverService: AutosaverService,
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
  }

  ngOnInit(): void {
    this.isTitleAreaSubmissionTitleVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_TitleAreaSubmissionTitle,
      this.permissionType.Visible
    );
    this.isTitleAreaEXTCOMMVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_TitleAreaEXTCOMM,
      this.permissionType.Visible
    );
    this.isTitleAreaSubmissionStatusVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_TitleAreaSubmissionStatus,
      this.permissionType.Visible
    );
    this.isTitleAreaSubmissionIdVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_TitleAreaSubmissionId,
      this.permissionType.Visible
    );
    this.isRushRequestBadgeVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_TitleAreaRushRequestBadge,
      this.permissionType.Visible
    );
    this.hasTitleDetailsVisiblePermission = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsSubmissionDetailsTitle,
      this.permissionType.Visible
    );
    this.isSelfReviewEligibiltyVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsSelfReviewEligibilty,
      this.permissionType.Visible
    );
    this.isCommunicationTypeVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsCommunicationType,
      this.permissionType.Visible
    );
    this.isCommunicationTypeEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsCommunicationType,
      this.permissionType.Enable
    );
    this.isPPMDTrainingCompletedVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsPPMDTrainingCompleted,
      this.permissionType.Visible
    );
    this.isUpdatedSelfReviewGuidanceVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsUpdatedSelfReviewGuidance,
      this.permissionType.Visible
    );
    this.isSubmissionTitleVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsSubmissionTitle,
      this.permissionType.Visible
    );
    this.isSubmissionTitleEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsSubmissionTitle,
      this.permissionType.Enable
    );

    this.isAudience_PublicationVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsAudiencePublications,
      this.permissionType.Visible
    );
    this.isAudience_PublicationEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsAudiencePublications,
      this.permissionType.Enable
    );
    this.isRelatedToGovernmentandPublicServicesVisible =
      this.isPermissionPresent(
        this.permissionEnums
          .EXT_COM_SubmissionDetailsRelatedToGovernmentandPublicServices,
        this.permissionType.Visible
      );
    this.isRelatedToGovernmentandPublicServicesEnable =
      this.isPermissionPresent(
        this.permissionEnums
          .EXT_COM_SubmissionDetailsRelatedToGovernmentandPublicServices,
        this.permissionType.Enable
      );
    this.isIndustrySectorVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsIndustrySector,
      this.permissionType.Visible
    );
    this.isIndustrySectorEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsIndustrySector,
      this.permissionType.Enable
    );
    this.isDeloitteCorporateFinanceVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsDeloitteCorporateFinance,
      this.permissionType.Visible
    );
    this.isDeloitteCorporateFinanceEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsDeloitteCorporateFinance,
      this.permissionType.Enable
    );
    this.isOfferingVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsOffering,
      this.permissionType.Visible
    );
    this.isOfferingEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsOffering,
      this.permissionType.Enable
    );
    this.isSolutionVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsSolution,
      this.permissionType.Visible
    );
    this.isSolutionEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsSolution,
      this.permissionType.Enable
    );
    this.isMarketOfferingVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsMarketOffering,
      this.permissionType.Visible
    );
    this.isMarketOfferingEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsMarketOffering,
      this.permissionType.Enable
    );
    this.isPrimaryContactVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsPrimaryContact,
      this.permissionType.Visible
    );
    this.isPrimaryContactEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsPrimaryContact,
      this.permissionType.Enable
    );
    this.isSecondaryContactVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsSecondaryContact,
      this.permissionType.Visible
    );
    this.isSecondaryContactEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsSecondaryContact,
      this.permissionType.Enable
    );
    this.isMarketingTalentPRContactVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsMarketingTalentPRContact,
      this.permissionType.Visible
    );
    this.isMarketingTalentPRContactEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsMarketingTalentPRContact,
      this.permissionType.Enable
    );
    this.isRiskAssessmentVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_RiskAssessment,
      this.permissionType.Visible
    );
    this.isRiskAssessmentEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_RiskAssessment,
      this.permissionType.Enable
    );
    this.isCommunicationAttributesVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_CommunicationAttributes,
      this.permissionType.Visible
    );
    this.isCommunicationAttributesEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_CommunicationAttributes,
      this.permissionType.Enable
    );
    this.isNotesAndFeedbackVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_NotesAndFeedback,
      this.permissionType.Visible
    );
    this.isNotesAndFeedbackEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_NotesAndFeedback,
      this.permissionType.Enable
    );
    this.hasSubmitButtonPermissionEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmitResubmit,
      this.permissionType.Enable
    );

    this.getCommunicationType();
    this.getIndustrySector();
    this.getMarketOffering();
    this.getOffering();
    this.getOfferingPortfolio();
    setTimeout(() => {
      this.getSubmissionDetails(this.submissionId);
    }, 3000);

    let autoSaverOptions = {
      delay: 500,
      onSuccess: (
        saver: any,
        result: InitiateExternalCommunicationRequestModel
      ) => {},
    } as IAutosaverOptions;

    this.savedetails = this.autosaverService.newSaver(
      'savedetails',
      this,
      async () => this.#saveDetailsValues(),
      autoSaverOptions
    );
    this.savedetails.start();
    this.externalCommService.communicationType$.subscribe((type) => {
      this.showRiskAssessment =
        type !== ExternalCommunicationConstant.COMMUNICATION_TYPE_ID;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submissionId'] && !changes['submissionId'].isFirstChange()) {
      this.getSubmissionDetails(changes['submissionId'].currentValue);
    }
  }

  transformStatus(status: string | undefined | null): StatusEnum {
    // TODO: Need to confirm color options for different docStatus
    if (!status) return StatusEnum.Draft ?? 'Draft';
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  onBusinessDaysChange(value: number) {
    this.businessdaysSelected = value;
    this.showRushRequestHeading =
      this.businessdaysSelected <
      ExternalCommunicationConstant.TWO_BUSINESSDAY_SELECTED;
  }

  getSubmissionDetails(id: number): void {
    this.progressIndicator.show();
    this.externalCommService
      .getExtCommSubmissionDetails(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: ExtCommSubmissionDetailsModel) => {
          if (response) {
            this.submissionDetailModel = response;

            this.ppmdName = this.submissionDetailModel.ppmdName;
            this.updateDetailsRequestBody();
            this.progressIndicator.hide();
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  copyToClipboard(value: string | null): void {
    if (value) {
      copyToClipboard(value);
    }
  }

  tabChanged(id: string | undefined): void {
    console.log('tabChanged', id);
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  getCommunicationType(): void {
    this.externalCommService
      .getSubmissionTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: InitiateExternalCommunicationDropdownModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: InitiateExternalCommunicationDropdownModel) => ({
                value: item.communicationTypeId,
                heading: item.communicationTypeName,
              })
            );
            this.communicationTypeList = convertedArray;
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getIndustrySector(): void {
    this.externalCommService
      .getExtCommIndustrySector()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: IndustrySectorModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: IndustrySectorModel) => ({
                value: parseInt(item.industryId),
                heading: item.industrySectorName,
              })
            );
            this.industrySectorList = convertedArray;
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getMarketOffering(): void {
    this.externalCommService
      .getExtCommMarketOffering()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: MarketOfferingModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: MarketOfferingModel) => ({
                value: parseInt(item.marketOfferingId),
                heading: item.marketOfferingName,
              })
            );
            this.marketOfferingList = convertedArray;
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getOffering(): void {
    this.externalCommService
      .getExtCommOffering()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OfferingModel[]) => {
          if (response) {
            const convertedArray = response.map((item: OfferingModel) => ({
              value: parseInt(item.offeringId),
              heading: item.offeringName,
            }));
            this.offeringList = convertedArray;
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getOfferingPortfolio(): void {
    this.externalCommService
      .getExtCommOfferingPortfolio()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OfferingPortfolioModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: OfferingPortfolioModel) => ({
                value: parseInt(item.offeringPortfolioId),
                heading: item.offeringPortfolioName,
              })
            );
            this.offeringPortfolioList = convertedArray;
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (this.isEditingType && !target.closest('#communicationType')) {
      this.editType();
    }

    if (!target.closest('#updateSubmissionTitle')) {
      this.updateSubmissionTitle();
      this.submissionTitleChange.emit(
        this.submissionDetailModel.submissionTitle
      );
    }
  }

  updateSubmissionTitle(): void {
    this.submissionDetailModel.submissionTitle =
      this.detailsRequestBody.SubmissionTitle;
  }

  editType(): void {
    this.isEditingType = false;

    this.submissionDetailModel.communicationTypeName = String(
      this.communicationTypeList[
        Number(this.detailsRequestBody.CommunicationTypeId) - 1
      ].heading
    );
    this.#saveDetailsValues();
  }


  onEditTypeClick(event: Event): void {
    event.stopPropagation();
    this.isEditingType = true;
  }

  updateDetailsRequestBody(): void {
    this.detailsRequestBody = {
      SubmissionId: this.submissionDetailModel.submissionId,
      CommunicationTypeId: this.submissionDetailModel.communicationTypeId,
      RequestedReviewDate: this.submissionDetailModel.requestedReviewDate,
      AudiencePublication: this.submissionDetailModel.audiencePublication,
      IsDCF: this.submissionDetailModel.isDCF,
      IsGPS: this.submissionDetailModel.isGPS,
      OfferingPortfolioId: this.submissionDetailModel.offeringPortfolioId,
      OfferingId: this.submissionDetailModel.offeringId,
      MarketOfferingId: this.submissionDetailModel.marketOfferingId,
      PrimaryContact: this.submissionDetailModel.primaryContact,
      SecondaryContact: this.submissionDetailModel.secondaryContact,
      MarketingTalentPRContact:
        this.submissionDetailModel.marketingTalentPRContact,
      AdditionalComments: this.submissionDetailModel.additionalComments,
      NotesAndFeedback: this.submissionDetailModel.notesAndFeedback,
      IsRushRequest: this.submissionDetailModel.isRushRequest,
      InternalQRM: this.submissionDetailModel.internalQRM,
      PrimaryIndustrySectorId: this.submissionDetailModel.industryId,
      SubmissionTitle: this.submissionDetailModel.submissionTitle,
    };

    this.primaryPeople = this.submissionDetailModel?.primaryContactName;
    this.secondaryPeople = String(
      this.submissionDetailModel.secondaryContactName
    );
    this.marketContact = String(
      this.submissionDetailModel.marketingTalentPRContactName
    );
    if (this.submissionDetailModel.isDCF) {
      this.isDCF = this.detailsRequestBody.IsDCF ? this.yesValue : this.noValue;
    }
    if (this.submissionDetailModel.isGPS) {
      this.isGPS = this.detailsRequestBody.IsGPS ? this.yesValue : this.noValue;
    }
    if (
      this.governmentPublicServices.includes(
        Number(this.detailsRequestBody.PrimaryIndustrySectorId)
      )
    ) {
      this.isGPSDisabled = true;
    }
    if (this.detailsRequestBody.OfferingId === this.corporateFinanceId) {
      this.isDCFDisabled = true;
    }
  }

  onIndustrySectorChange(id: number): void {
    if (this.governmentPublicServices.includes(id)) {
      this.isGPS = this.yesValue;
      this.isGPSDisabled = true;
      this.#saveDetailsValues();
    } else {
      this.isGPSDisabled = false;
    }
  }

  onOfferingChange(id: number): void {
    if (id === this.corporateFinanceId) {
      this.isDCF = this.yesValue;
      this.isDCFDisabled = true;
      this.#saveDetailsValues();
    } else {
      this.isDCFDisabled = false;
    }
  }

  requiedIndicatorForMarketContact(): boolean {
    if (this.isDCF && this.isDCF === this.yesValue) {
      return !this.detailsRequestBody.MarketingTalentPRContact;
    } else if (Number(this.detailsRequestBody.CommunicationTypeId) === 9) {
      return !this.detailsRequestBody.MarketingTalentPRContact;
    }
    return false;
  }

  #saveDetailsValues(): void {
    if (this.isDCF) {
      this.detailsRequestBody.IsDCF =
        this.isDCF === this.yesValue ? true : false;
    }
    if (this.isGPS) {
      this.detailsRequestBody.IsGPS =
        this.isGPS === this.yesValue ? true : false;
    }
    this.externalCommService
      .putExtCommSubmissionDetails(this.detailsRequestBody)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe();
  }

  onSelectedPrimaryPeople(event: any): void {
    if (this.isPrimaryContactEnable) {
      this.primaryPeople = event.displayName;
      this.primaryPeopleId = event.reviewerId;
      this.detailsRequestBody.PrimaryContact = String(this.primaryPeopleId);
      this.#saveDetailsValues();
    }
  }

  removePrimaryPeople(): void {
    if (this.isPrimaryContactEnable) {
      this.primaryPeople = this._blank;
      this.primaryPeopleId = null;
      this.detailsRequestBody.PrimaryContact = this._blank;
      this.#saveDetailsValues();
    }
  }

  onSelectedSecondaryPeople(event: any): void {
    if (this.isSecondaryContactEnable) {
      this.secondaryPeople = event.displayName;
      this.secondaryPeopleId = event.reviewerId;
      this.detailsRequestBody.SecondaryContact = String(this.secondaryPeopleId);
      this.#saveDetailsValues();
    }
  }

  removeSecondaryPeople(): void {
    if (this.isSecondaryContactEnable) {
      this.secondaryPeople = this._blank;
      this.secondaryPeopleId = null;
      this.detailsRequestBody.SecondaryContact = null;
      this.#saveDetailsValues();
    }
  }

  onSelectedMarketContact(event: any): void {
    if (this.isMarketingTalentPRContactEnable) {
      this.marketContact = event.displayName;
      this.marketContactId = event.reviewerId;
      this.detailsRequestBody.MarketingTalentPRContact = String(
        this.marketContactId
      );
      this.#saveDetailsValues();
    }
  }

  removeMarketContact(): void {
    if (this.isMarketingTalentPRContactEnable) {
      this.marketContact = this._blank;
      this.marketContactId = null;
      this.detailsRequestBody.MarketingTalentPRContact = null;
      this.#saveDetailsValues();
    }
  }


  enableSubmitButton(): boolean {
    const isEnableSubmitButton =
      this.detailsRequestBody.SubmissionTitle &&
      this.detailsRequestBody.AudiencePublication &&
      this.detailsRequestBody.RequestedReviewDate &&
      this.isGPS &&
      this.detailsRequestBody.PrimaryIndustrySectorId &&
      this.isDCF &&
      this.detailsRequestBody.OfferingPortfolioId &&
      this.detailsRequestBody.OfferingId &&
      this.detailsRequestBody.MarketOfferingId &&
      this.detailsRequestBody.PrimaryContact &&
      this.detailsRequestBody.SecondaryContact &&
      this.detailsRequestBody.MarketingTalentPRContact &&
      this.riskAssessmentRequiredFieldsFilled &&
      this.communicationAttributesRequiredFieldsFilled
        ? true
        : false;

    if (isEnableSubmitButton !== this.#previousSubmitButtonState) {
      this.submitButtonStateChange.emit(isEnableSubmitButton);
      this.#previousSubmitButtonState = isEnableSubmitButton;
    }

    return isEnableSubmitButton;
  }

  onRiskAssessmentRequiredFieldsFilled(value: boolean): void {
    this.riskAssessmentRequiredFieldsFilled = value;
    this.enableSubmitButton();
  }

  onCommAttrRequiredFieldsFilled(value: boolean): void {
    this.communicationAttributesRequiredFieldsFilled = value;
    this.enableSubmitButton();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
