import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environment/environment';
import { Observable, of } from 'rxjs';
import {
  DashboardDataCollection,
  DashboardGridLevelFilter,
} from '../../common/models/start-page/start-page.model';
import { RecentlyViewedSubmissionList } from '../../common/models/start-page/reviewer-dashboard.model';
import {
  EngagementDashboardData,
  EngagementDashboardTilesCount,
} from '../../common/models/start-page/engagement-dashboard.model';
import { UrlConstant } from '../../intake/constants/url.constant';
import { SubmissionType } from '../../common/models/common-models';
import { EngagementTileCode } from '../../intake/constants/dashboard-tiles.enum';

@Injectable({
  providedIn: 'root',
})
export class EngagementDashboardWebapiService {
  constructor(private readonly httpService: HttpClient) {}

  apiUrl: string = environment.apiUrl;
  // apiUrl: string = "https://dapi.eclipse.deloitte.com/api/";
  // apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/';//qa

  getSubmissionTypes(): Observable<SubmissionType[]> {
    return this.httpService.get<SubmissionType[]>(
      this.apiUrl + UrlConstant.GET_SUBMISSION_TYPES_API
    );
  }

  getEngagementDashBoardGridData(
    tileCode: string,
    tabCode: string,
    pageNumber: number,
    pageSize: number,
    sortParameterCode: number | null,
    isAscending: boolean,
    filterData: DashboardGridLevelFilter[]
  ): Observable<DashboardDataCollection<EngagementDashboardData>> {
    let params = new HttpParams()
      .set('tileCode', tileCode)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('isAscending', isAscending.toString());
    if (sortParameterCode !== null) {
      params = params.set('sortParameterCode', sortParameterCode.toString());
    }
    if (tileCode === EngagementTileCode.ExtComm) {
      params = params.set('subtilecode', tabCode);
    }
    return this.httpService.post<
      DashboardDataCollection<EngagementDashboardData>
    >(this.apiUrl + UrlConstant.POST_ENGAGEMENT_GRID_DATA, filterData, {
      params,
    });
  }

  getEngagementDashboardTilesCount(): Observable<EngagementDashboardTilesCount> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<EngagementDashboardTilesCount>(
      this.apiUrl + UrlConstant.GET_ENGAGEMENT_TILE_COUNT,
      { headers }
    );
  }

  getRecentlyViewedSubmissions(
    pageNumber: number,
    pageSize: number
  ): Observable<RecentlyViewedSubmissionList> {
    return this.httpService.get<RecentlyViewedSubmissionList>(
      this.apiUrl +
        UrlConstant.GET_RECENTLY_VIEWED_SUBMISSIONS +
        '?pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize
    );
  }
}
