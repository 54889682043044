import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class AdobeAnalyticsService {
  private applicationName: string = "Eclipse";
  constructor() {
  }

  private setCommonDataLayer(w: any, pageName: string) {
    w.analyticsDataLayer.pageInfo.pageName = pageName;
    w.analyticsDataLayer.pageInfo.applicationName = this.applicationName;
    var useralias = this.getUsernameFromEmail(localStorage.getItem('userProfileId')?? "test"/*local*/);
    w.analyticsDataLayer.pageInfo.userID = useralias;
  }

  private createDataLayer(w: any) {
    //set default values
    w.analyticsDataLayer =
    {
      'pageInfo': {
        'applicationName': 'Eclipse',
        'pageName': 'Home Page',
        'userID': 'User Alias' //User Alias, no domain,
      }
    };
  }

  public trackPage(pageName: string) {
    let wnd = window as any;
    if (!wnd.analyticsDataLayer) {
      this.createDataLayer(wnd);
    }
    this.setCommonDataLayer(wnd, pageName);

    // Call Adobe Analytics tracking function here 
    wnd._satellite?.track('pageView');
    console.log(`Tracking page: ${pageName}`);
  }

  getUsernameFromEmail(email: string): string {
    if (!email) {
      return '';
    }
    const [username] = email.split('@');
    return username;
  }

}
