import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SecurityWebapiService } from '../http/security/security-webapi.service';
import {
  DropdownItemOptions,
  DropdownOptions,
} from '@usitsdasdesign/dds-ng/dropdown';
import { DashboardConstant, dropdownOptions } from './dashboard.helper';
import { PositionState } from '@usitsdasdesign/dds-ng/shared';
import { DashboardRoles } from '../common/models/start-page/start-page.model';
import { CommonService } from '../http/intake/common.service';
import { AdobeAnalyticsService } from '../common/services/adobe-analytics.service';
import { RoleService } from '../http/intake/role.service';
import { takeUntil, Subject } from 'rxjs';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.less'],
})
export class StartPageComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  selectedRole: string = '';
  loggedInUserRoles: string[] = [];
  roles: string[] = [];
  dropdownOptions: DropdownOptions = dropdownOptions;
  dropdownItems: DropdownItemOptions[] = [
    {
      heading: 'Contract/Proposal/Eng. Related Review',
      value: 'Contract/Proposal/Eng. Related Review',
    },
  ];
  dropdownStickerPosition: PositionState = PositionState.bottomLeft;
  public clientNumber: string = '';
  private securityNcaDashboard: string = 'ClientStatus.NcaDashboard';
  private isDashboardSecurity: boolean = false;
  public Nca_Specialist: string = DashboardConstant.NCA_SPECIALIST;
  public Reviewer: string = DashboardConstant.REVIEWER;
  public Engagement_Team: string = DashboardConstant.ENGAGEMENT_TEAM;
  public Risk_Manager: string = DashboardConstant.RISK_MANAGER;

  constructor(
    private route: ActivatedRoute,
    private securityWebapiService: SecurityWebapiService,
    private readonly router: Router,
    private readonly commonService: CommonService,
    private analytics: AdobeAnalyticsService,
    private roleService: RoleService
  ) {}

  ngOnInit(): void {
    // Get permissions on route change.
    this.route.params.subscribe((params: Params) => {
      this.securityWebapiService.getPermissions('');
      
      // Subscribe to the role service to get logged in user roles.
      this.roleService.currentUserRole$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response: string[]) => {
            this.loggedInUserRoles = response;
            console.log('loggedInUserRoles', this.loggedInUserRoles);
          },
        });
    });

    // Subscribe to the security API stream.
    this.securityWebapiService.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rules) => {
        if (rules && !rules.empty) {
          this.isDashboardSecurity =
            rules[this.securityNcaDashboard] &&
            rules[this.securityNcaDashboard].Visible;

          if (this.isDashboardSecurity) {
            if (this.loggedInUserRoles.includes(this.Nca_Specialist)) {
              this.roles = [this.Nca_Specialist];
            } else {
              this.roles = [];
            }
          } else {
            let tempRoles: string[] = [];

            if (this.loggedInUserRoles.includes(this.Reviewer)) {
              tempRoles.push(this.Reviewer);
            }
            if (
              this.loggedInUserRoles.find((role) =>
                role.toLowerCase().includes('engagement team')
              )
            ) {
              tempRoles.push(this.Engagement_Team);
            }
            if (this.loggedInUserRoles.includes(this.Risk_Manager)) {
              tempRoles.push(this.Risk_Manager);
            }
            this.roles = tempRoles;
          }
          if (this.roles.length > 0) {
            this.selectedRole = this.roles[0];
            this.commonService.setSelectedDashboard(this.selectedRole);
            this.analytics.trackPage(this.selectedRole + ' dashboard');
          } else {
            console.warn(
              'No available dashboards for the current user roles:',
              this.loggedInUserRoles
            );
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onRoleChange(event: any): void {
    this.commonService.setSelectedDashboard(this.selectedRole);
    this.analytics.trackPage(this.selectedRole + ' dashboard');
  }

  getRoleWidth(): string {
    return this.selectedRole === this.Reviewer ||
      this.selectedRole === this.Engagement_Team
      ? this.selectedRole.length + 3 + 'ch'
      : this.selectedRole.length + 'ch';
  }

  initiateNewItemSelected(event: string): void {
    if (event === 'Contract/Proposal/Eng. Related Review') {
      this.router.navigate(['/submission']);
    }
  }
}