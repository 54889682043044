<div class="dds-modal-overlay" (click)="closeShowAll()"></div>

<div ddsResize class="dds-modal dds-modal_md">
  <div class="dds-modal__header">
    <span class="dds-modal__title">All Splits</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      (clicked)="closeShowAll()"
    ></dds-button>
  </div>

  <div class="dds-modal__body" *ngIf="documentInitiatedDetails?.opportunityNumber">
    <app-service-delivery
      [opportunityNumber]="opportunityNumber"
      [showAllSplits]="true"
    ></app-service-delivery>
  </div>
</div>
