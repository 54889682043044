<div class="row" *ngIf="permissionObj['isIntakeCloseoutFormVisible']">
  <div>
    <span
      *ngIf="!winningProposal"
      class="icon-required-yellow padding-left-sm"
    ></span>
    <span>{{ winning_proposal_radioGroupOptions.title }}</span>
  </div>

  <div>
    <div class="row padding-top-xs">
      <div class="col-12">
        <dds-radio-group
          autosave
          [(ngModel)]="winningProposal"
          [name]="winningProposal"
          [isUncheckable]="winning_proposal_radioGroupOptions.isUncheckable"
          [isRequired]="winning_proposal_radioGroupOptions.isRequired"
          [errorState]="winning_proposal_radioGroupOptions.errorState"
          [disabled]="!permissionObj['isIntakeCloseoutFormEnable']"
          (stateChanged)="winning_proposalStateChanged($event)"
          ngDefaultControl
        >
          <ng-container *ngFor="let radio of winning_proposal_radio_values">
            <dds-radio
              [value]="radio.value"
              [customClass]="'padding-right-half-rem'"
              [label]="radio.options.label"
              [theme]="radio.options.theme"
              [disabled]="!permissionObj['isIntakeCloseoutFormEnable']"
            ></dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>
  </div>
</div>
