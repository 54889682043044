<div>
  <table class="dds-data-table">
    <thead class="dds-data-table__header">
      <tr>
        <ng-container *ngFor="let column of sampleColumns">
          <th
            [attr.aria-sort]="
              sortingState?.property === column.name
                ? sortingState?.descending
                  ? 'descending'
                  : 'ascending'
                : 'none'
            "
            class="dds-data-table__header-cell dds-data-table__header-cell_sorting"
            [ngClass]="{
              'dds-data-table__header-cell_sorted':
                sortingState?.property === column.name,
              'dds-data-table__header-cell_filter-shown':
                isShownFilter && column.name === filteringColumn.name
            }"
            [ddsSticker]="customFilter"
            [stickerPosition]="filterStickerOptions.stickerPosition ?? ''"
            [stickerIsOutsideClick]="
              filterStickerOptions.stickerIsOutsideClick ?? true
            "
            [stickerIndent]="filterStickerOptions.stickerIndent ?? -1"
            [stickerIsDisabled]="isSortFilterPresent(column.name)"
            [stickerWidth]="filterStickerOptions.stickerWidth ?? '280'"
            [attr.aria-expanded]="isShownFilter"
            aria-haspopup="listbox"
            (click)="toggleFilter(column)"
            (onHidden)="filterOnHidden()"
            #stickerDir="dds-sticker"
            [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
          >
            <div
              class="dds-data-table__content"
              *ngIf="column.name !== 'editIcon'"
            >
              <span>{{ column.header || column.name }}</span>
              <span
                *ngIf="sortingState?.property === column.name"
                class="dds-icon dds-data-table__icon"
                aria-label="sorting button"
                [ngClass]="
                  sortingState?.descending
                    ? 'dds-icon_sort-down'
                    : 'dds-icon_sort-up'
                "
              >
              </span>
              <span
                *ngIf="column.name !== 'comments'"
                class="dds-icon dds-icon_chevron_down__l__stroke dds-data-table__icon"
              ></span>

              <span *ngIf="column.filterable == true">
                <span
                  class="dds-icon dds-icon_filter"
                ></span>
              </span>

            </div>
          </th>
        </ng-container>
      </tr>
    </thead>

    <tbody *ngIf="sortingState !== null" class="dds-data-table__body">
      <ng-container
        *ngFor="
          let row of adminExtCommTrainingTableData | sort : sortingState;
          let i = index
        "
      >
        <tr class="dds-data-table__row">
          <td *ngFor="let column of sampleColumns" class="dds-data-table__cell">
            <ng-container *ngIf="column.name === 'editIcon'">
              <span
                #editButton
                class="dds-icon dds-icon_edit__s__filled edit-icon"
                (click)="onEditTypeClick($event, row)"
              ></span>
            </ng-container>

            <ng-container *ngIf="column.name === 'isActive'">
              <span>
                <ng-container *ngIf="row[column.name]; else dashIcon">
                  <img
                    src="/assets/images/dds-icons/dds-green-tick-icon.svg"
                    alt="Green Tick"
                  />
                </ng-container>
                <ng-template #dashIcon>
                  <img
                    src="/assets/images/dds-icons/dds-dash-icon.svg"
                    alt="Green Tick"
                  />
                </ng-template>
              </span>
            </ng-container>
            <ng-container *ngIf="column.name === 'comments'">
              <span
              *ngIf="row[column.name].length > 0"
              class="icon-s icon-info"
              [ddsTooltip]="row[column.name]"
              [tooltipInvokeType]="groupToolTipOptions.tooltipInvokeType ?? ''"
              [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
              [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
              [tooltipPosition]="groupToolTipOptions.tooltipPosition ?? ''"
              [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
              [tooltipHasBeak]="groupToolTipOptions.tooltipHasBeak ?? false"
              [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
              ddsFocus
              [tooltipMaxWidth]="groupToolTipOptions.tooltipMaxWidth ?? 0"
            >
            </span>
            </ng-container>
            
            <ng-container *ngIf="column.name === 'name'">
              {{ row[column.name] }}
            </ng-container>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <ng-template #customFilter>
    <dds-filter
      [items]="filterData"
      (sorted)="filterSorted(isAscending)"
      [theme]="filterOptions.theme ?? ''"
      (cancel)="filterHide()"
      (apply)="filterApply(filteringColumn.searchValue)"
      [template]="customFilterTemp"
      #ddsFilter
    ></dds-filter>
  </ng-template>

  <ng-template #customFilterTemp let-ctx="ctx">
    <span class="dds-filter__title dds-filter__section">Sorting</span>
    <div class="dds-filter__container dds-filter__container_sorting">
      <button
        class="dds-filter__item dds-filter__section dds-filter__section_sorting"
        (click)="filterSorted(true)"
      >
        <span class="dds-filter__icon dds-icon dds-icon_sort-up"></span>
        <span class="dds-filter__text">Sort A–Z</span>
      </button>
      <button
        class="dds-filter__item dds-filter__section dds-filter__section_sorting"
        (click)="filterSorted(false)"
      >
        <span class="dds-filter__icon dds-icon dds-icon_sort-down"></span>
        <span class="dds-filter__text">Sort Z–A</span>
      </button>
    </div>

    <ng-container>
      <span class="dds-filter__title dds-filter__section">Filter</span>
      <dds-search
        class="dds-filter__search dds-filter__section"
        [isInverse]="ctx.isInverse"
        [ariaLabel]="searchOptions.ariaLabel ?? ''"
        [(ngModel)]="filteringColumn.searchValue"
        (keydown)="ctx.tabOut(searchField, $event)"
        #searchField
      ></dds-search>

      <div class="dds-filter__footer dds-filter__section">
        <dds-button
          width="full"
          [kind]="ctx.cancelBtnOptions.kind"
          [theme]="ctx.theme"
          [isInverse]="ctx.isInverse"
          class="dds-filter__btn dds-filter__item"
          (clicked)="filterHide()"
          [ariaLabel]="cancelButtonOptions.ariaLabel ?? ''"
          >Cancel</dds-button
        >
        <dds-button
          width="full"
          [theme]="ctx.theme"
          [isInverse]="ctx.isInverse"
          class="dds-filter__btn dds-filter__item"
          [(ngModel)]="filteringColumn.searchValue"
          (clicked)="filterApply(filteringColumn.searchValue)"
          (keydown)="ctx.tabOut(lastBtn, $event)"
          [disabled]="!filteringColumn.searchValue"
          #lastBtn
          >Apply</dds-button
        >
      </div>
    </ng-container>
  </ng-template>
  <ng-template #noFilter></ng-template>
</div>
