<progress-indicator></progress-indicator>
<div class="ncasummary-container">
  <div class="page-header margin-top-minus-m">
    <div class="d-flex justify-content-between align-items-center">
      <div class="title inline-block font-size-32">NCA Summary</div>
    </div>
  </div>
</div>

<div class="header-container row text-align-left">
  <div class="col-3">
    <p class="page-name eclipse-main-tab-header">Active NCA Summary</p>
  </div>
  <div class="col-3 jupiter-search-container">
    <dds-search
      class="jupiter-search"
      search
      [size]="searchOptions.size ?? ''"
      (valueChanged)="onSearchInput($event)"
      (fieldCleared)="clearAllFilter()"
    >
      <input
        class="jupiter-search-input"
        ddsSearch
        [(ngModel)]="jupiterFilterValue"
        [placeholder]="searchOptions.placeholder"
        [maxLength]="searchOptions.maxLength"
        (click)="inputClick()"
        (keydown)="validateKeypress($event)"
        (input)="onSearchInput($event)"
      />
    </dds-search>
  </div>
</div>

<app-bar-chart [barChartTitle]="barChartTitle" [bars]="bars" [legendBar]="bars[0]"
(barChartClick)="barChartClick($event)" [isChartDataLoaded]="isChartDataLoaded"
></app-bar-chart>

<div class="active-nca-grid margin-top-sm">
  <div class="tabs-container">
    <dds-tabs
      [theme]="tabContainerOptions.theme ?? 'primary'"
      [themeType]="tabContainerOptions.themeType ?? 'primary'"
      [size]="tabContainerOptions.size ?? 'md'"
      [isResponsive]="tabContainerOptions.isResponsive ?? true"
      [disabled]="tabContainerOptions.disabled ?? false"
      [ariaLabel]="tabContainerOptions.ariaLabel ?? 'Tabs'"
      class="text-align-left"
    >
      <dds-tab
        *ngFor="let tab of tabs"
        [label]="tab.label"
        [counter]="tab.count"
        [customClass]="tab.code === selectedTabCode ? 'active' : ''"
        (tabChanged)="onTabChange(tab.code)"
        #tabButton
      >
      </dds-tab>
    </dds-tabs>

    <dds-button
      [theme]="clearButtonoptions.theme ?? ''"
      [kind]="clearButtonoptions.kind ?? ''"
      [size]="clearButtonoptions.size ?? ''"
      [width]="clearButtonoptions.width ?? ''"
      (clicked)="clearAllFilter()"
      >Clear filters
    </dds-button>
    <div class="extra-border"></div>
  </div>

  <startpage-grid-area
    *ngIf="filterDataList && filterDataList.length > 0"
    [currentDashboardTileCode]="currentDashboardTileCode"
    [currentTileNameForGrid]="currentDashboardTileCode"
    [gridColumns]="gridColumns"
    [model]="model"
    [dashboardfilters]="ncaDashboardfilters"
    (gridUpdatedEvent)="gridUpdatedEvent($event)"
    [selectedTileCount]="ActiveNcaSummaryCollectionModel.totalNcasCount"
    [filterDataList]="filterDataList"
  >
  </startpage-grid-area>

  <app-new-pagination-and-grid
    *ngIf="model && model.length > 0"
    [tileCountforPage]="countforPagination"
    [itemsPerPage]="itemsPerPage"
    [list]="itemsPerPageOptions"
    [pageUI]="gotoPage + 1"
    (pageChanged)="onPageChanged($event)"
    (valueChanged)="onValueChanged($event)"
  ></app-new-pagination-and-grid>
</div>

<app-nca-summary-footer>

</app-nca-summary-footer>


