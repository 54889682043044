import {
  Component,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { environment } from '../../../environment/environment';
import {
  PositionState,
  Themes,
  ExtThemes,
  ButtonKind,
  Size,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import {
  StickerDirective,
  StickerOptions,
} from '@usitsdasdesign/dds-ng/sticker';
import { Filter, FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import {
  DropdownItemOptions,
  DropdownOptions,
} from '@usitsdasdesign/dds-ng/dropdown';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { DaterangepickerOptions } from '@usitsdasdesign/dds-ng/datepicker';
import { WatchlistStatus } from '../../http/watchlist/watchlist-api.service';
import { WatchlistDetailModalDialogComponent } from '../../watchlist/watchlist-detail-modal-dialog/watchlist-detail-modal-dialog.component';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import {
  DashboardData,
  DashboardGridSortParameter,
  GridColumn,
  DashboardTileCode,
} from '../../common/models/start-page/start-page.model';
import {
  NCATileCode,
  NCASpecialistDashboardModel,
} from '../../common/models/start-page/nca-specialist-dashboard.model';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { OpportunityListInformationComponent } from '../../client/client-status/opportunity-list-information/opportunity-list-information.component';
import { GridColumns, ReviewerTileCode } from '../../common/models/start-page/reviewer-dashboard.model';

@Component({
  selector: 'app-reviewer-startpage-gridarea',
  templateUrl: './reviewer-startpage-gridarea.component.html',
  styleUrl: './reviewer-startpage-gridarea.component.less',
})
export class ReviewerStartpageGridareaComponent implements OnInit {
  @ViewChildren('stickerDir') sticker: QueryList<StickerDirective> | undefined;
  @Input() currentDashboardTileCode: string = '';
  @Input() currentTileNameForGrid: string | any;
  @Input() gridColumns: GridColumn[] = [];
  @Input() model: any[] = [];
  @Input() gridTitle: string = '';
  @Input() dashboardfilters!: any[];
  @Input() dropdownItems: DropdownItemOptions[] = [];
  @Input() buttonOptionsList: any[] = [];
  @Output() buttonSelectedEvent = new EventEmitter<{
    value: string;
    rowItem: any;
  }>();
  @Output() gridUpdatedEvent = new EventEmitter<{
    sortParamaterCode: number;
    isAscending: boolean;
    dashboardfilters: any[];
  }>();

  public searchValue: string = '';
  public dateRange = '';
  public isShownFilter!: boolean;
  public filteringColumn!: GridColumn;
  public isAscending: boolean = true;
  public sortParamaterCode: number = 1;
  public ncaTileCodes = NCATileCode;
  public reviewerTileCodes = ReviewerTileCode;
  public rootUrl: string = environment.rootUrl;
  public netServicesRevenueToolTip = 'Rolling 13 periods';
  public filterSortParameterCode = DashboardGridSortParameter;
  public filterData: FilterItem[] = []; //Added to fix console errors occuring due to DDS filter component
  public enumWatchlistStatus = WatchlistStatus;
  public jupiterFilterValue: string = '';
  public fromValue: number | null = null;
  public toValue: number | null = null;
  public isRowTooltipDisabled: boolean = false;
  public dashboardTileCode = DashboardTileCode;
  public CCDue: string = 'Client continuances due';
  public columnName = GridColumns

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    document.addEventListener('click', this.onDocumentClick.bind(this));
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (event.target instanceof Element) {
      const isClickedOutsideSearchBox =
        !event.target.closest('.jupiter-search');
      if (
        isClickedOutsideSearchBox &&
        (!this.jupiterFilterValue || this.jupiterFilterValue.length < 10)
      ) {
        this.clearJupiterSearch();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentTileNameForGrid']) {
      // Reset the filters and sorting state
      this.sortParamaterCode = 1;
      this.isAscending = true;
      this.sortingState = {
        property: this.gridColumns[0]?.name,
        ascending: true,
        dataType: this.gridColumns[0]?.dataType,
      };
      this.clearAllFilter();
    }
  }

  public sortingState = {
    property: this.gridColumns[0]?.name,
    ascending: true,
    dataType: this.gridColumns[0]?.dataType,
  };

  public filterOptions: Filter = {
    theme: Themes.green,
    propName: 'name',
    itemPropNameValue: 'value',
  };

  public filterStickerOptions: StickerOptions = {
    stickerPosition: PositionState.bottomRight,
    stickerIsOutsideClick: true,
    stickerIndent: -1,
    stickerWidth: 280,
  };

  public filterStickerOptionsOnlySort: StickerOptions = {
    stickerPosition: PositionState.bottom,
    stickerIsOutsideClick: true,
    stickerIndent: -1,
    stickerWidth: 0,
  };

  public gotoClientStatus(clientNumber: string) {
    let url = this.rootUrl + 'client/' + clientNumber + '/status';
    window.open(url, '_blank');
  }

  public goToSubmissionSummary(clientNumber: string) {
    // let url = this.rootUrl + 'client/' + clientNumber + '/status';
    // window.open(url, '_blank');
    console.log(clientNumber);
  }

  setEventPropagationForDropdown(event: any) {
    event.stopPropagation();
  }

  public toggleFilter(column: GridColumn) {
    this.filteringColumn = column;
    this.isShownFilter = true;
  }

  public filterHide(): void {
    this.sticker?.forEach((item) => {
      if (item.isActive) {
        item.hide();
      }
    });
  }

  public filterOnHidden(): void {
    this.isShownFilter = false;
  }

  public filterApply(searchValue: string | [Date, Date]) {
    if (
      (this.filteringColumn.dataType != 'number' &&
        (searchValue === '' || searchValue === null)) ||
      (this.filteringColumn.dataType == 'number' &&
        (this.fromValue === null || this.toValue === null))
    ) {
      this.clearFilter(this.filteringColumn.name);
      return;
    }
    const parameterCode =
      this.filterSortParameterCode[
        this.filteringColumn.name as keyof typeof DashboardGridSortParameter
      ];
    let filterCriteria: string;

    if (
      this.filteringColumn.dataType == 'number' &&
      this.fromValue !== null &&
      this.toValue !== null
    ) {
      filterCriteria = `${this.fromValue},${this.toValue}`;
    } else if (Array.isArray(searchValue)) {
      const [fromDate, toDate] = searchValue;
      const fromDateString = fromDate.toLocaleDateString('en-US');
      const toDateString = toDate.toLocaleDateString('en-US');
      filterCriteria = `${fromDateString},${toDateString}`;
    } else {
      filterCriteria = searchValue;
    }

    // Remove previous filter for the same column if it exists
    this.dashboardfilters = this.dashboardfilters.filter(
      (filter) => filter.filtertypecode !== parameterCode
    );

    this.filteringColumn.filterable = true;
    const newFilter = {
      filtertypecode: parameterCode,
      filtercriteria: filterCriteria,
    };
    this.dashboardfilters = [...(this.dashboardfilters || []), newFilter];

    this.filterHide();
    this.gridUpdatedEvent.emit({
      sortParamaterCode: this.sortParamaterCode,
      isAscending: this.isAscending,
      dashboardfilters: this.dashboardfilters,
    });
  }

  public filterSorted(isAscending: boolean): void {
    const parameterCode =
      this.filterSortParameterCode[
        this.filteringColumn.name as keyof typeof DashboardGridSortParameter
      ];
    this.sortParamaterCode = parameterCode;
    this.isAscending = isAscending;

    this.sortingState = {
      property: this.filteringColumn.name,
      ascending: isAscending,
      dataType: this.filteringColumn.dataType,
    };

    this.filterHide();
    this.gridUpdatedEvent.emit({
      sortParamaterCode: this.sortParamaterCode,
      isAscending: this.isAscending,
      dashboardfilters: this.dashboardfilters,
    });
  }

  public clearAllFilter() {
    this.dashboardfilters = [];
    this.fromValue = null;
    this.toValue = null;
    this.gridColumns.forEach((column) => {
      column.filterable = false;
      column.searchValue = '';
    });
    this.clearJupiterSearch();
    this.filterHide();
    this.gridUpdatedEvent.emit({
      sortParamaterCode: this.sortParamaterCode,
      isAscending: this.isAscending,
      dashboardfilters: this.dashboardfilters,
    });
  }

  public clearFilter(column: string) {
    // Remove the filter for the specified column from the list
    this.dashboardfilters = this.dashboardfilters.filter((filter) => {
      const parameterCode =
        this.filterSortParameterCode[
          column as keyof typeof this.filterSortParameterCode
        ];
      return filter.filtertypecode !== parameterCode;
    });

    // Reset the filterable and searchValue properties for the specified column
    this.filteringColumn.filterable = false;
    this.filteringColumn.searchValue = '';

    this.filterHide();
    this.gridUpdatedEvent.emit({
      sortParamaterCode: this.sortParamaterCode,
      isAscending: this.isAscending,
      dashboardfilters: this.dashboardfilters,
    });
  }

  public itemExpand(item: any, $event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    item.expandStatus = !item.expandStatus;
  }

  public stopEventPropagation(event: MouseEvent) {
    event.stopPropagation();
    console.log('stop propogation');
  }
  getWatchlistStatus(row: any): string {
    return row.watchlistStatusId ==
      this.enumWatchlistStatus.ServeWithConditions ||
      row.watchlistStatusId == this.enumWatchlistStatus.DoNotServe
      ? row.watchlistStatus
      : 'Prior watchlist client';
  }

  getParentWatchlistStatus(row: any): string {
    return row.pcWatchlistStatusId ==
      this.enumWatchlistStatus.ServeWithConditions ||
      row.pcWatchlistStatusId == this.enumWatchlistStatus.DoNotServe
      ? row.pcWatchlistStatus
      : 'Prior watchlist client';
  }

  onShowWatchlistDetail(
    event: MouseEvent,
    rowItem: NCASpecialistDashboardModel,
    isParentClient: boolean
  ): void {
    this.stopEventPropagation(event);
    let modalRef = this.modalService.open(WatchlistDetailModalDialogComponent);
    modalRef.componentInstance.data = {
      watchlistDetail: rowItem,
      isPriorWatchlistStatusShow: true,
      isParentClient: isParentClient,
    };
  }
  searchOptions: SearchOptions = {
    placeholder: 'Search by Jupiter id',
    size: Size.lg,
    maxLength: 10,
  };

  public showOpenOpps(row: any, $event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    let modalRef = this.modalService.open(OpportunityListInformationComponent);
    modalRef.componentInstance.resolve = {
      title: 'Open Opportunities',
      MilestoneTriggerListInput: row.opportunityList,
    };

    modalRef.onClosed().subscribe(() => {});
  }

  //NCA Dashboard Dropdown options visibility
  isVisibleDropdownItem(
    item: any,
    rowItem: NCASpecialistDashboardModel
  ): boolean {
    if (item.value == 'NCANotRequired') {
      return rowItem.isNcaNotRequiredAvailable || false;
    } else if (item.value == 'OnHold') {
      return rowItem.isOnHoldOptionAvailable || false;
    } else if (item.value == 'ConvertToCC') {
      return rowItem.isConvertNca2CcAavailable || false;
    } else if (
      item.value == 'ConvertToNCA' &&
      this.currentTileNameForGrid == 'CCDueNow'
    ) {
      return true;
    } else if (item.value == 'AssignTo') {
      return (
        (this.currentDashboardTileCode === this.ncaTileCodes.OnHold &&
          rowItem.isAssignToAvailable) ||
        this.currentDashboardTileCode === this.ncaTileCodes.CompleteNcaForm ||
        this.currentDashboardTileCode === this.ncaTileCodes.PreScreenNcaForm
      );
    } else if (item.value == 'Discontinue') {
      return this.currentTileNameForGrid == 'CCDueNow'
        ? true
        : rowItem.isDiscontinueAvailable || false;
    }
    return false;
  }

  public buttonSelected(value: string, rowItem: any) {
    this.buttonSelectedEvent.emit({ value, rowItem });
  }

  clientTooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'right-top',
    tooltipIndent: 5,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
  };

  groupToolTipoptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 15,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
  };

  actionbyToolTipoptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'left',
    tooltipIndent: 15,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
  };
  dateRangePickerOptions: DaterangepickerOptions = {
    size: Size.md,
    placeholder: ['MM/DD/YYYY', 'MM/DD/YYYY'],
    format: 'MM/dd/yyyy',
    isManualInput: false,
    stickerPosition: PositionState.bottomLeft,
  };

  dropdownOptions: DropdownOptions = {
    label: '',
    ariaLabel: '',
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.md,
    width: WidthState.fixed,
    icon: 'dds-icon_dots-v',
    disabled: false,
    stickerWidth: 200,
  };
  dropdownStickerPosition: PositionState = PositionState.bottomRight;

  buttonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.md,
    width: WidthState.fixed,
  };

  public inputClick() {
    if (
      !this.jupiterFilterValue ||
      this.jupiterFilterValue.indexOf('JO-') != 0
    ) {
      this.jupiterFilterValue = 'JO-';
    }
  }

  public validateKeypress(event: KeyboardEvent) {
    const keyPressed = event.key;
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;

    // Prevent deletion of "JO-"
    if (this.isDeletionKey(keyPressed)) {
      if (currentValue.startsWith('JO-') && inputElement.selectionStart! <= 3) {
        event.preventDefault();
      }
      return;
    }

    // Prevent overwriting "JO-"
    if (currentValue.startsWith('JO-') && inputElement.selectionStart! < 3) {
      event.preventDefault();
      return;
    }

    // Ensure numeric entry
    if (!this.isValidKey(event)) {
      event.preventDefault();
    }
  }

  private isDeletionKey(key: string): boolean {
    return key === 'Backspace' || key === 'Delete';
  }

  private isValidKey(event: KeyboardEvent): boolean {
    const key = event.key;
    const validKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']; //allow arrow navigation
    const isCtrlKey = event.ctrlKey && (key === 'c' || key === 'v'); //allow pasting
    const isNumeric = key >= '0' && key <= '9'; // check if the key is a number

    return validKeys.includes(key) || isCtrlKey || isNumeric;
  }

  private clearJupiterSearch() {
    const activeElement = document.activeElement as HTMLInputElement;
    if (
      activeElement &&
      activeElement.classList.contains('jupiter-search-input')
    ) {
      this.jupiterFilterValue = 'JO-';
      activeElement.value = 'JO-';
    } else {
      const searchInput = document.querySelector(
        '.jupiter-search-input'
      ) as HTMLInputElement;
      this.jupiterFilterValue = '';
      searchInput.value = '';
    }
    this.updateClearButtonVisibility();
  }

  private updateClearButtonVisibility() {
    const searchInput = document.querySelector(
      '.jupiter-search-input'
    ) as HTMLInputElement;
    const clearButton = searchInput.nextElementSibling
      ?.nextElementSibling as HTMLElement;
    if (clearButton) {
      clearButton.style.display =
        searchInput && searchInput.value.length > 3 ? 'block' : 'none';
    }
  }

  public onSearchInput(event: any): void {
    var inputValue = event.target.value;
    this.updateClearButtonVisibility();

    if (inputValue.length === 10) {
      const isNumeric = /^\d+$/.test(inputValue.split('JO-')[1]); //check when numbers are pasted
      if (!isNumeric) {
        event.preventDefault();
        return;
      }
      this.filteringColumn = { dataType: 'string', name: 'jupiterId' };
      this.filterApply(this.jupiterFilterValue);
    }
  }

  public rowTooltipDisabled(value: boolean) {
    console.log('Mouse hover', value);
    this.isRowTooltipDisabled = value;
  }
}
