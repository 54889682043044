<div class="main-container margin-left-s width-100">
  <progress-indicator></progress-indicator>
  <div class="title-container">
    <h4
      class="eclipse-main-tab-header text-align-left"
      *ngIf="isTitleAreaSubmissionTitleVisible"
    >
      {{ submissionDetailModel.submissionTitle }}
    </h4>
    <dds-button
      *ngIf="hasSubmitButtonPermissionEnable"
      class="custom-button margin-right-m"
      [theme]="primaryButton.theme"
      [kind]="primaryButton.kind"
      [size]="htmlElementSize"
      [width]="primaryButton.width"
      [disabled]="!enableSubmitButton()"
      >Submit</dds-button
    >
  </div>
  <div class="sub-header">
    <div class="submission-type font-size-12 font-bold" *ngIf="isTitleAreaEXTCOMMVisible">
      {{ submissionTypeCode }}
    </div>
    <div
      class="custom-pad margin-left-xxs font-size-12 font-bold"
      [style.color]="
        transformStatus(submissionDetailModel.submissionStatus)
          | statusStyle : 'color'
      "
      [style.backgroundColor]="
        transformStatus(submissionDetailModel.submissionStatus)
          | statusStyle : 'backgroundColor'
      "
      *ngIf="isTitleAreaSubmissionStatusVisible"
    >
      {{ submissionDetailModel.submissionStatus }}
    </div>
    <div class="id-container eclipse-content">
      <ng-container *ngIf="isTitleAreaSubmissionIdVisible">
        <span class="id-title" >ID:</span>
        <span #textToCopy class="eclipse-content" >{{
          submissionDetailModel.submissionId
        }}</span>
        <span
          class="dds-icon dds-icon_copy__l__stroke icon"
          (click)="copyToClipboard(textToCopy.textContent)"
        ></span>
      </ng-container>
      
      <span
      *ngIf="isRushRequestBadgeVisible && showRushRequestHeading"
        class="textRush font-size-12 font-bold-600"
        >Rush request</span
      >
    </div>
  </div>
  <div class="margin-top-m margin-bottom-s">
    <dds-tabs
      [theme]="tabContainerOptions.theme ?? ''"
      [themeType]="tabContainerOptions.themeType ?? ''"
      [size]="tabContainerOptions.size ?? ''"
      [isResponsive]="tabContainerOptions.isResponsive ?? false"
      [customClass]="tabContainerOptions.customClass ?? ''"
      [disabled]="tabContainerOptions.disabled ?? false"
      [isInverse]="tabContainerOptions.isInverse ?? false"
      [ariaLabel]="tabContainerOptions.ariaLabel ?? ''"
    >
      <dds-tab
        #tab1
        [label]="tabOptionsOne.label ?? ''"
        (tabChanged)="tabChanged(tabOptionsOne.label)"
      >
      </dds-tab>

      <dds-tab
        #tab1
        [label]="tabOptionsSix.label ?? ''"
        (tabChanged)="tabChanged(tabOptionsSix.label)"
      >
      </dds-tab>

      <dds-tab
        #tab1
        [label]="tabOptionsFive.label ?? ''"
        (tabChanged)="tabChanged(tabOptionsFive.label)"
      >
      </dds-tab>

      <dds-tab
        #tab2
        [label]="tabOptionsTwo.label ?? ''"
        (tabChanged)="tabChanged(tabOptionsTwo.label)"
      >
      </dds-tab>

      <dds-tab
        #tab4
        [label]="tabOptionsFour.label ?? ''"
        (tabChanged)="tabChanged(tabOptionsFour.label)"
      >
      </dds-tab>

      <dds-tab
        #tab4
        [label]="tabOptionsSeven.label ?? ''"
        (tabChanged)="tabChanged(tabOptionsSeven.label)"
      >
      </dds-tab>
    </dds-tabs>
  </div>
  <div>
    <dds-accordion [isMulti]="true">
      <dds-accordion-item id="{{ tabOptionsOne.label }}" [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">{{
              tabOptionsOne.label
            }}</span>
          </div>
        </span>

        <span [autosave-module]="savedetails" content>
          <div
            class="d-flex justify-content-between margin-right-l margin-bottom-m custom-width"
          >
            <div *ngIf="isCommunicationTypeVisible">
              <div class="eclipse-small-header-grey text-align-left">
                Type of communication:
              </div>
              <div class="font-size-14 dropdown-width-small">
                <ng-container
                  class="dropdown-width-small"
                  *ngIf="
                    isEditingType && isCommunicationTypeEnable;
                    else displayType
                  "
                >
                  <div>
                    <dds-select
                      id="communicationType"
                      autosave
                      [list]="communicationTypeList"
                      [stickerMaxHeight]="300"
                      [(ngModel)]="detailsRequestBody.CommunicationTypeId"
                    ></dds-select>
                  </div>
                </ng-container>
                <ng-template #displayType>
                  <span>{{ submissionDetailModel.communicationTypeName }}</span>
                  <span
                    *ngIf="isCommunicationTypeEnable"
                    #editButton
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                    (click)="onEditTypeClick($event)"
                  ></span>
                </ng-template>
              </div>
            </div>

            <div *ngIf="isPPMDTrainingCompletedVisible">
              <div class="eclipse-small-header-grey text-align-left">
                PPMD training completed:
              </div>
              <div class="font-size-14">
                {{
                  !submissionDetailModel.ppmdTrainingCompleted
                    ? yesNo.NO
                    : yesNo.YES
                }}
              </div>
            </div>

            <div *ngIf="isSelfReviewEligibiltyVisible">
              <div class="eclipse-small-header-grey text-align-left">
                Self-review eligibility:
              </div>
              <div class="font-size-14">
                {{ submissionDetailModel.selfReviewEligibility }}
              </div>
            </div>
          </div>

          <div *ngIf="detailsRequestBody.CommunicationTypeId === 3">
            <span class="margin-right-xs"
              ><img src="\assets\images\green-pin-icon.svg"
            /></span>
            <span class="font-size-14"
              >Ensure client mentions, including former employment, have LCSP
              approval.</span
            >
          </div>

          <div class="detail-width">
            <div class="margin-top-xs" *ngIf="isSubmissionTitleVisible">
              <div class="font-size-14">
                <span *ngIf="!detailsRequestBody.SubmissionTitle"
                  ><img
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Submission title:</span>
              </div>
              <div *ngIf="isSubmissionTitleEnable">
                <dds-input
                  id="updateSubmissionTitle"
                  autosave
                  [maxLength]="100"
                  [(ngModel)]="detailsRequestBody.SubmissionTitle"
                  [maxLengthPosition]="'top-right'"
                ></dds-input>
              </div>
              <div *ngIf="!isSubmissionTitleEnable && isSubmissionTitleVisible">
                {{ detailsRequestBody.SubmissionTitle }}
              </div>
            </div>

            <div class="margin-top-s">
              <span content>
                <app-external-communication-submission-detail
                  (businessDaysChange)="onBusinessDaysChange($event)"
                  [submissionDetailModel]="submissionDetailModel"
                ></app-external-communication-submission-detail>
              </span>
            </div>

            <div class="margin-top-s" *ngIf="isAudience_PublicationVisible">
              <div class="font-size-14 margin-bottom-xs">
                <span
                  ><img
                    *ngIf="!detailsRequestBody.AudiencePublication"
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Audience/publication:</span>
              </div>
              <div>
                <dds-textarea
                  autosave
                  [maxLength]="200"
                  [maxLengthPosition]="'top-right'"
                  [(ngModel)]="detailsRequestBody.AudiencePublication"
                  [readonly]="!isAudience_PublicationEnable ?? true"
                ></dds-textarea>
              </div>
            </div>

            <div
              class="margin-top-s"
              *ngIf="isRelatedToGovernmentandPublicServicesVisible"
            >
              <div class="font-size-14">
                <span
                  ><img
                    *ngIf="!isGPS"
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Related to Government and Public Services (GPS):</span>
              </div>
              <div>
                <div class="padding-top-xs">
                  <dds-radio-group
                    *ngIf="isRelatedToGovernmentandPublicServicesEnable"
                    autosave
                    [(ngModel)]="isGPS"
                    [disabled]="isGPSDisabled"
                    [name]="'GPS'"
                  >
                    <dds-radio [value]="'Yes'" [label]="'Yes'"> </dds-radio>
                    <br />
                    <dds-radio [value]="'No'" [label]="'No'"> </dds-radio>
                  </dds-radio-group>
                  <span *ngIf="!isRelatedToGovernmentandPublicServicesEnable">{{
                    isGPS
                  }}</span>
                </div>
              </div>
            </div>

            <div
              class="margin-top-s"
              *ngIf="isIndustrySectorVisible || isIndustrySectorEnable"
            >
              <div class="font-size-14 margin-bottom-xs">
                <span
                  ><img
                    *ngIf="!detailsRequestBody.PrimaryIndustrySectorId"
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Primary Industry Sector:</span>
              </div>
              <div class="dropdown-width">
                <dds-select
                  autosave
                  [(ngModel)]="detailsRequestBody.PrimaryIndustrySectorId"
                  [list]="industrySectorList"
                  [stickerMaxHeight]="400"
                  (ngModelChange)="onIndustrySectorChange($event)"
                  [disabled]="!isIndustrySectorEnable"
                ></dds-select>
              </div>
            </div>

            <div
              class="margin-top-s"
              *ngIf="
                isDeloitteCorporateFinanceVisible ||
                isDeloitteCorporateFinanceEnable
              "
            >
              <div class="font-size-14">
                <span
                  ><img
                    *ngIf="!isDCF"
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span
                  >Does this submission (i) include references to Deloitte
                  Corporate Finance LLC (DCF) or
                  <span
                    class="font-bold"
                    [ddsTooltip]="
                      'i.e, investment banking services and/or services otherwise requiring registration as a broker-dealer'
                    "
                  >
                    services provided by DCF
                  </span>
                  , (ii) involve participation of any DCF personnel as
                  contributors, authors and/or speakers, or (iii) contain any
                  references to DCF personnel?</span
                >
              </div>
              <div>
                <div class="padding-top-xs">
                  <dds-radio-group
                    *ngIf="isDeloitteCorporateFinanceEnable"
                    autosave
                    [(ngModel)]="isDCF"
                    [disabled]="isDCFDisabled"
                    [isError]="radioGroupOptions.isError ?? false"
                    [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
                    [isInverse]="radioGroupOptions.isInverse ?? false"
                    [isRequired]="radioGroupOptions.isRequired ?? false"
                    [errorState]="radioGroupOptions.errorState ?? ''"
                    [name]="'DCF'"
                  >
                    <dds-radio [value]="'Yes'" [label]="'Yes'"> </dds-radio>
                    <br />
                    <dds-radio [value]="'No'" [label]="'No'"> </dds-radio>
                  </dds-radio-group>
                  <span *ngIf="!isDeloitteCorporateFinanceEnable">{{
                    isDCF
                  }}</span>
                </div>
              </div>
            </div>

            <div class="margin-top-s">
              <div class="font-size-14 margin-bottom-xs">
                <span
                  ><img
                    *ngIf="!detailsRequestBody.OfferingPortfolioId"
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Offering Portfolio (OP):</span>
              </div>
              <div class="dropdown-width">
                <dds-select
                  autosave
                  [(ngModel)]="detailsRequestBody.OfferingPortfolioId"
                  [list]="offeringPortfolioList"
                  [stickerMaxHeight]="400"
                ></dds-select>
              </div>
            </div>

            <div
              class="margin-top-s"
              *ngIf="isOfferingVisible || isOfferingEnable"
            >
              <div class="font-size-14 margin-bottom-xs">
                <span>Offering:</span>
              </div>
              <div class="dropdown-width">
                <dds-select
                  autosave
                  [(ngModel)]="detailsRequestBody.OfferingId"
                  (ngModelChange)="onOfferingChange($event)"
                  [list]="offeringList"
                  [stickerMaxHeight]="300"
                  [disabled]="!isOfferingEnable"
                ></dds-select>
              </div>
            </div>

            <div
              class="margin-top-s"
              *ngIf="isMarketOfferingVisible || isMarketOfferingEnable"
            >
              <div class="font-size-14 margin-bottom-xs">
                <span>Market Offering:</span>
              </div>
              <div class="dropdown-width">
                <dds-select
                  autosave
                  [(ngModel)]="detailsRequestBody.MarketOfferingId"
                  class="dropdown-width"
                  [stickerMaxHeight]="300"
                  [list]="marketOfferingList"
                  [disabled]="!isMarketOfferingEnable"
                ></dds-select>
              </div>
            </div>

            <div class="d-flex margin-top-s">
              <div
                class="margin-right-l"
                *ngIf="isPrimaryContactVisible || isPrimaryContactEnable"
              >
                <div class="font-size-14 margin-bottom-xs">
                  <span
                    ><img
                      *ngIf="!detailsRequestBody.PrimaryContact"
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                  /></span>
                  <span>Primary contact:</span>
                </div>
                <div class="dropdown-width">
                  <div
                    *ngIf="primaryPeople"
                    class="d-flex picker-box padding-xxs"
                  >
                    <div class="dropdown-width">
                      {{ primaryPeople }}
                    </div>
                    <div
                      *ngIf="isPrimaryContactEnable"
                      (click)="removePrimaryPeople()"
                      class="margin-right-xs font-bold cursor-clickable"
                    >
                      x
                    </div>
                  </div>

                  <app-people-picker
                    *ngIf="!primaryPeople && isPrimaryContactEnable"
                    [isTechinalReviewerSearch]="true"
                    (userSelected)="onSelectedPrimaryPeople($event)"
                    [isLep]="true"
                  ></app-people-picker>
                </div>
              </div>
              <div
                *ngIf="isSecondaryContactVisible || isSecondaryContactEnable"
              >
                <div class="font-size-14 margin-bottom-xs">
                  <span>Secondary contact:</span>
                </div>
                <div class="dropdown-width">
                  <div
                    *ngIf="detailsRequestBody.SecondaryContact"
                    class="d-flex picker-box padding-xxs"
                  >
                    <div class="dropdown-width">
                      {{ secondaryPeople }}
                    </div>
                    <div
                      *ngIf="isSecondaryContactEnable"
                      (click)="removeSecondaryPeople()"
                      class="margin-right-xs font-bold cursor-clickable"
                    >
                      x
                    </div>
                  </div>
                  <app-people-picker
                    *ngIf="
                      !detailsRequestBody.SecondaryContact &&
                      isSecondaryContactEnable
                    "
                    [isTechinalReviewerSearch]="true"
                    (userSelected)="onSelectedSecondaryPeople($event)"
                    [placeholder]="'Search by name'"
                    [isLep]="true"
                  ></app-people-picker>
                </div>
              </div>
            </div>

            <div
              class="margin-top-s"
              *ngIf="
                isMarketingTalentPRContactVisible ||
                isMarketingTalentPRContactEnable
              "
            >
              <div class="font-size-14 margin-bottom-xs">
                <span
                  ><img
                    *ngIf="requiedIndicatorForMarketContact()"
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Marketing/Talent/PR contact:</span>
              </div>
              <div class="dropdown-width">
                <div
                  *ngIf="detailsRequestBody.MarketingTalentPRContact"
                  class="d-flex picker-box padding-xxs"
                >
                  <div class="dropdown-width">
                    {{ marketContact }}
                  </div>
                  <div
                    *ngIf="isMarketingTalentPRContactEnable"
                    (click)="removeMarketContact()"
                    class="margin-right-xs font-bold cursor-clickable"
                  >
                    x
                  </div>
                </div>
                <app-people-picker
                  *ngIf="
                    !detailsRequestBody.MarketingTalentPRContact &&
                    isMarketingTalentPRContactEnable
                  "
                  [isTechinalReviewerSearch]="true"
                  (userSelected)="onSelectedMarketContact($event)"
                  [placeholder]="'Search by name'"
                  [isLep]="true"
                ></app-people-picker>
              </div>
            </div>

            <div
              *ngIf="detailsRequestBody.CommunicationTypeId === 3"
              class="margin-top-s"
            >
              <div class="font-size-14 margin-bottom-xs">
                <span
                  >Add additional comments, instructions, or background
                  information related to this External Communication</span
                >
              </div>
              <div>
                <dds-textarea
                  autosave
                  [maxLength]="1000"
                  [maxLengthPosition]="'bottom-right'"
                  [(ngModel)]="detailsRequestBody.AdditionalComments"
                ></dds-textarea>
              </div>
            </div>
          </div>
          <div class="margin-bottom-l"></div>
        </span>
      </dds-accordion-item>
      <dds-accordion-item
        *ngIf="
          showRiskAssessment &&
          (isRiskAssessmentVisible || isRiskAssessmentEnable)
        "
        id="{{ tabOptionsSix.label }}"
        [isOpen]="true"
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Risk Assessment</span>
          </div>
        </span>

        <span content *ngIf="isRiskAssessmentEnable || isRiskAssessmentVisible">
          <app-risk-assessment
            [submissionDetailModel]="submissionDetailModel"
            (riskAssessmentRequiredFieldsFilled)="
              onRiskAssessmentRequiredFieldsFilled($event)
            "
            [isControlEnable]="isRiskAssessmentEnable"
          ></app-risk-assessment>
        </span>
      </dds-accordion-item>
      <dds-accordion-item
        id="{{ tabOptionsFive.label }}"
        [isOpen]="true"
        *ngIf="
          isCommunicationAttributesVisible || isCommunicationAttributesEnable
        "
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span id="tabOptions2.label" class="eclipse-section-header">{{
              tabOptionsFive.label
            }}</span>
          </div>
        </span>

        <span
          content
          *ngIf="
            isCommunicationAttributesVisible || isCommunicationAttributesEnable
          "
        >
          <div>
            <app-communication-attributes
              [submissionId]="submissionId"
              (commAttrRequiredFieldsFilled)="
                onCommAttrRequiredFieldsFilled($event)
              "
              [isControlEnable]="isCommunicationAttributesEnable"
            />
          </div>
        </span>
      </dds-accordion-item>
      <dds-accordion-item id="{{ tabOptionsTwo.label }}" [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">{{
              tabOptionsTwo.label
            }}</span>
          </div>
        </span>

        <span content>
          <div class="accordion-content">
            <app-external-communication-documents
              [submissionId]="submissionId"
              [submissionStatusId]="submissionDetailModel.submissionStatusId"
              [submissionStatus]="submissionDetailModel.submissionStatus"
              [progressiveStatus]="null"
              [communicationType]="communicationType"
              [communicationTypeId]="communicationTypeId"
            ></app-external-communication-documents>
          </div>
        </span>
      </dds-accordion-item>
      <dds-accordion-item
        id="{{ tabOptionsThree.label }}"
        [isOpen]="true"
        *ngIf="isNotesAndFeedbackVisible || isNotesAndFeedbackEnable"
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">{{
              tabOptionsThree.label
            }}</span>
          </div>
        </span>
        <span [autosave-module]="savedetails" content>
          <div class="accordion-content">
            <div class="notes-paragraph eclipse-content-grey">
              Use this field to (i) provide notes about any relevant history,
              unusual aspects, or unique characteristics of the engagement that
              reviewers should be aware of or (ii) view feedback from reviewers
              on the document
            </div>
            <div>
              <div class="row">
                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="notesAndFeedback"
                          [(ngModel)]="detailsRequestBody.NotesAndFeedback"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="enter_text_placeholder"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="
                            internalQrmCommunicationTextareaMaxlength
                          "
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          [readonly]="!isNotesAndFeedbackEnable"
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </dds-accordion-item>
      <dds-accordion-item id="{{ tabOptionsFour.label }}" [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">{{
              tabOptionsFour.label
            }}</span>
          </div>
        </span>

        <span content>
          <app-external-communication-workflow-submission
            [ppmdName]="ppmdName"
          ></app-external-communication-workflow-submission>
        </span>
      </dds-accordion-item>
      <dds-accordion-item
        id="{{ tabOptionsSeven.label }}"
        *ngIf="isVisible"
        [isOpen]="true"
      >
        <span header class="accordion-header">
          <div class="section-header margin-bottom-s">
            <span class="eclipse-section-header"
              >Internal QRM Team communication</span
            >
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span [autosave-module]="savedetails" content>
          <div class="accordion-content">
            <div>
              <div class="row">
                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="internalQRMTeamCommunication"
                          [(ngModel)]="detailsRequestBody.InternalQRM"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="'Enter your comments'"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="
                            internalQrmCommunicationTextareaMaxlength
                          "
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
    </dds-accordion>
  </div>
</div>
