<div class="row">
  <div class="col-12">
    <span class="icon-required-yellow"></span>
    <span>{{ co_elsow_radioGroupOptions.title }}</span>
  </div>
  <div class="row padding-top-xs">
    <div class="col-12">
      <dds-radio-group
        autosave
        [(ngModel)]="contractSignedExecuted"
        [isUncheckable]="co_elsow_radioGroupOptions.isUncheckable"
        [isRequired]="co_elsow_radioGroupOptions.isRequired"
        [errorState]="co_elsow_radioGroupOptions.errorState"
        (stateChanged)="coAndElSowStateChanged($event)"
      >
        <ng-container *ngFor="let radio of co_elsow_radio_values">
          <dds-radio
            [value]="radio.value"
            [customClass]="'padding-right-half-rem'"
            [label]="radio.options.label"
            [theme]="radio.options.theme"
          ></dds-radio>
          <br />
        </ng-container>
      </dds-radio-group>
    </div>
  </div>
</div>

<div class="row margin-top-m" *ngIf="contractSignedExecuted === CoElSowOptionValueYes">
  <div class="col-12 d-flex">
    <span
      class="icon-required-yellow"
      *ngIf="chargeCodeWBS === '' && !wbsPendingCheckbox"
    ></span>
    <span>Charge code (WBS)</span>
  </div>
  <div class="col-8 padding-top-xs d-flex" *ngIf="wbsForCoElSow.length > 0">
    <dds-select
      autosave
      [list]="wbsForCoElSowDiplayValue"
      [placeholder]="'Select charge code(WBS)'"
      [(ngModel)]="chargeCodeWBS"
      (ngModelChange)="onWbsChargedCodeChange($event)"
    >
    </dds-select>
    <dds-checkbox
      [value]="wbsPendingCheckbox"
      [(ngModel)]="wbsPendingCheckbox"
      theme="green"
      class="margin-left-xs"
    >
    </dds-checkbox>

    <span
      class="dds-icon dds-icon_copy__l__stroke icon margin-left-xs cursor-clickable"
      aria-label="Copy"
      aria-placeholder="Copy"
      title="Copy"
      (click)="clipboardCopy()"
    ></span>
  </div>
</div>
<div
  class="row margin-top-m"
  *ngIf="
    (chargeCodeWBS != '' || chargeCodeWBS != null) &&
    contractSignedExecuted === CoElSowOptionValueYes
  "
>
  <div>
    <span>WBS Description</span>
  </div>

  <div class="col-12">
    <div class="textarea-container">
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-textarea
            autosave
            name="wbsDescription"
            [(ngModel)]="wbsDescription"
            [placeholder]="textareaOptions.placeholder"
            [description]="textareaOptions.description"
            [minHeight]="textareaOptions.minHeight"
            [isRequired]="textareaOptions.isRequired"
            [customClass]="textareaOptions.customClass"
            [isError]="textareaOptions.isError"
            [errorMessage]="textareaOptions.errorMessage"
            [errorState]="textareaOptions.errorState"
            [maxLength]="textareaOptions.maxLength"
            [maxLengthPosition]="textareaOptions.maxLengthPosition"
            [size]="textareaOptions.size"
            [readonly]="textareaOptions.readonly"
            (valueChanged)="wbsDescriptionValueChanged()"
          ></dds-textarea>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row margin-top-s" *ngIf="chargeCodeWBS != ''">
  <div class="col-8 font-bold-600">
    <span>{{ numberOfOpenArchivesLabel }}</span>
  </div>
</div>
