import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import {
  ExtThemes,
  Size,
  WidthState,
  ButtonKind,
  Themes,
  SpinnerThemes,
  TabThemeType,
} from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { SubmissionType } from '../../common/models/common-models';
import { SubmissionSubType } from '../../common/models/common-models';
import { SubmissionService } from '../../http/intake/submission.service';
import { Router } from '@angular/router';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import {
  InitiateSubmissionRequestDataModel,
  IntakeSubmissionResponseDataModel,
} from '../../common/models/initiateSubmission.model';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import {
  OpportunityDetails,
  OpportunityList,
} from '../../common/models/opportunity-details.model';
import {
  opportunityTableColumns,
  options,
  optionsShowMore,
  tabContainerOptions,
} from './initiate-submission-helper';
import { configurationType } from '../../common/models/common-models';
import { latestOpportunityDetails } from '../../common/models/latestOpportunityDetails';
import { latestSubmissionDetails } from '../../common/models/latestSubmissionDetail';
import { toolTipConfig } from '../../common/models/common-models';
import { IntakeConstant } from '../constants/intake.constant';
import { IntakeDocumentSubmissionTypeCode } from '../constants/intake_submission_type_code.enum';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { CommonService } from '../../http/intake/common.service';
import { OppertunitySubmissionStatusCode } from '../../common/models/oppertunity-submission-statusCode.model';
import { IntakeAction } from '../../common/models/intake-action.model';
import { SpinnerOptions } from '@usitsdasdesign/dds-ng/spinner';
import { AdobeAnalyticsService } from '../../common/services/adobe-analytics.service';
import { SubmissionApprovers } from '../../common/models/intake-submission-details-common-section.model';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { AdminModuleService } from '../../admin/admin-services/admin-module.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientDetailsConstant } from '../../client/constants/client-details-constant';
import { TabComponent, TabsOptions } from '@usitsdasdesign/dds-ng/tabs';
@Component({
  selector: 'app-initiate-submission',
  templateUrl: './initiate-submission.component.html',
  styleUrl: './initiate-submission.component.less',
})
export class InitiateSubmissionComponent implements OnInit, OnDestroy {
  _emptySpace: string = IntakeConstant.EMPTY_SPACE;
  submissionData: SubmissionType[] = [];
  submissionTypes: string[] = [];
  submissionSubData: SubmissionSubType[] = [];
  submissionSubTypes: string[] = [];
  submissionType: string = this._emptySpace;
  submissionSubType: string = this._emptySpace;
  changeOrder: string = IntakeConstant.CHANGE_ORDER_LABEL;
  nonChangeOrder: string = IntakeConstant.NON_CHANGE_ORDER;
  tooltipWidth: number = IntakeConstant.TOOLTIP_WIDTH;
  secondSearchCheck: boolean = false;
  submissionTypeId: number | undefined;
  showSelectOpportunity: boolean = false;
  submissionSubTypeId: number | undefined;
  changeOrderDuplicateError: boolean = false;
  CustomSubmissionType: string = this._emptySpace;
  isRequired: boolean = true;
  showSubType: boolean = false;
  pageNumber: number = 0;
  pageSize: number = IntakeConstant.PAGE_NUMBER;
  latestSubmissionDetails: latestSubmissionDetails[] = [];
  notAvailable: boolean = false;
  totalCount: number = 0;
  showDescription: boolean = false;
  public selectedOportunity: string = this._emptySpace;
  showDropdown: boolean = false;
  showOpportunityDetails: boolean = false;
  postButtonEnabled: boolean = false;
  showNoreviewNote: boolean = false;
  changeOrderId: string = this._emptySpace;
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  isOpportunityMA: boolean | null = false;
  warningHeaderMA: string = this._emptySpace;
  warningBodyMA: string = this._emptySpace;
  isShowMoreVisible: boolean = true;
  isShowMoreVisibleConfidentialOppertunity: boolean = true;
  globalConfigurationLabelItems: configurationType[] = [];
  optionsShowLessBtn: ButtonOptions = options;
  optionsShowMore: ButtonOptions = optionsShowMore;
  isOpportunityGPS: boolean | null = false;
  warningBodyGPS: string = this._emptySpace;
  warningHeaderGPS: string = this._emptySpace;
  isShowMoreVisibleGPS: boolean = true;
  isOpportunityDCF: boolean | null = false;
  isOpportunityConfidential: boolean | null = false;
  warningBodyDCF: string = this._emptySpace;
  warningHeaderDCF: string = this._emptySpace;

  confidentialOpportunityHeader: string = this._emptySpace;
  confidentialOpportunityBody: string = this._emptySpace;

  isShowMoreVisibleDCF: boolean = true;
  isOpportunityMaDeliverable: boolean | null = false;
  warningHeaderMaDeliverable: string = this._emptySpace;
  warningBodyMaDeliverable: string = this._emptySpace;
  isShowMoreVisibleMaDeliverable: boolean = true;
  MA: string = IntakeConstant.MA;
  GPS: string = IntakeConstant.GPS;
  DCF: string = IntakeConstant.DCF;
  emptySpace: string = this._emptySpace;
  MA_DELIVERABLE: string = IntakeConstant.MA_DELIVERABLE;
  primaryDgmfId: string = this._emptySpace;
  primaryGmdmId: string = this._emptySpace;
  primaryswiftId: string = this._emptySpace;
  showWarningMessage: boolean = false;
  coOppDonotMatchMessage: string = '';
  totalOpportunityCount: number = 0;
  activeOpportunityCount: number = 0;
  selectedTabCode: string = 'active';
  selectTabIndex:number = 0;

  postData: InitiateSubmissionRequestDataModel = {
    SubmissionTypeDetails: {
      SubmissionTypeId: undefined,
      SubmissionSubTypeId: undefined,
      CustomSubmissionType: this._emptySpace,
    },
    OpportunityDetails: {
      OpportunityNumber: this._emptySpace,
      OpportunityName: this._emptySpace,
      OpportunityStatusId: null,
      ClientNumber: this._emptySpace,
      PursuitLeaderId: this._emptySpace,
      offeringPortfolioId: null,
      offeringId: null,
      ParentOpportunityNumber: null,
    },
  };
  options: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 15,
    tooltipIsOutsideClick: false,
    tooltipHasBeak: true,
    tooltipIsDisabled: false,
    tooltipIsInverse: false,
    tooltipIsDynamic: false,
    tooltipCustomClass: this._emptySpace,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipShowDelay: 0,
    tooltipHideDelay: 0,
    tooltipTheme: Themes.green,
    tooltipMaxWidth: 300,
  };

  closeBtnOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.secondary,
    size: Size.md,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_close',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: this._emptySpace,
    customClass: this._emptySpace,
    role: 'button',
  };

  opportunityList: OpportunityDetails[] = [];
  opportunityTableColumns = opportunityTableColumns;
  tabContainerOptions = tabContainerOptions;
  public opportunityDetailsList: any[] = [];
  changeOrderList: any[] = [];

  unsubscriber$ = new Subject();
  searchOptions: SearchOptions = {
    placeholder: IntakeConstant.SEARCH_OPTION_PLACEHOLDER,
    customClass: this._emptySpace,
  };
  opportunityDetailName: string = this._emptySpace;
  configurationsIntake: configurationType[] = [];
  tooltipIntake: toolTipConfig[] = [];
  notFound: string = this._emptySpace;
  isSpinnerVisible: boolean = false;
  spinnerOptions: SpinnerOptions = {
    theme: SpinnerThemes.gray,
    thickness: '3px',
    size: '30px',
    customClass: this._emptySpace,
    isInverse: false,
  };
  private readonly searchOpportunityQuery$: Subject<string> =
    new Subject<string>();

  markForDeletionText: string = ClientDetailsConstant.MarkForDeletionText;
  isClientStatusValid: boolean = true;

  constructor(
    private readonly submissionService: SubmissionService,
    private readonly router: Router,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly commonService: CommonService,
    private analytics: AdobeAnalyticsService,
    private readonly adminModuleService: AdminModuleService
  ) {}

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_Submissions;
    this.analytics.trackPage('Submission');
    this.loadSubmissionTypes();
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response && response.length > 0) {
            this.configurationsIntake = response;
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
    this.getIntakeTooltips();
    this.loadRolesStatusActions();
    this.searchOpportunityQuery$
      .pipe(
        debounceTime(IntakeConstant.SEARCH_DEBOUNCE_TIME),
        filter(
          (key) => !!key && key.length > IntakeConstant.SEARCH_STRING_LIMIT
        ),
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.fetchOpportunitySearchResult(1, this.selectedTabCode);
      });
  }

  getIntakeTooltips(): void {
    this.submissionService
      .getIntakeTooltips()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: toolTipConfig[]) => {
          if (response && response.length > 0) {
            this.tooltipIntake = response;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getChangeOrderRequestHeader(): string {
    return (
      this.configurationsIntake.find(
        (config: configurationType) =>
          config.appConfigurationLabelKey === 'CO_REQ_GUIDANCEBOX'
      )?.appConfigurationLabelValue || this._emptySpace
    );
  }

  gethangeOrderTooltip(): string {
    return (
      this.tooltipIntake.find(
        (config: toolTipConfig) =>
          config.appConfigurationLabelKey === 'CHANGE_ORDER_TOOL_TIP'
      )?.appConfigurationLabelValue ?? this._emptySpace
    );
  }

  getNoReviewHeader(): string {
    return (
      this.configurationsIntake.find(
        (config) =>
          config.appConfigurationLabelKey === 'CO_ECLIPSE_OPP_NOT_FOUND'
      )?.appConfigurationLabelValue || this._emptySpace
    );
  }

  loadSubmissionTypes(): void {
    this.progressIndicator.show();
    this.submissionService
      .getSubmissionTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (types: SubmissionType[]) => {
          this.submissionData = types;
          this.submissionTypes = this.submissionData.map(
            (sub: SubmissionType) => sub.submissionTypeName
          );
          this.progressIndicator.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('Error fetching submission types', err);
        },
      });
  }

  loadSubmissionSubTypes(ID: number | undefined): void {
    this.progressIndicator.show();
    this.submissionService
      .getSubmissionSubTypes(ID)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (subTypes) => {
          this.submissionSubData = subTypes;
          this.submissionSubTypes = this.submissionSubData.map(
            (sub) => sub.subTypeName
          );
          if (this.submissionSubData.length > 0) {
            this.showSubType = true;
            this.submissionSubType = this._emptySpace;
            this.showDescription = false;
          } else {
            this.showSubType = false;
            this.showDescription = false;
            this.submissionSubType = this._emptySpace;
            this.CustomSubmissionType = this._emptySpace;
          }
          this.progressIndicator.hide();
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching submission sub-types', err);
          this.progressIndicator.hide();
        },
      });
  }

  selectSubmissionSelectOptions: SelectOptions = {
    placeholder: 'Select type',
    disabled: false,
  };

  otherSubTypeSelectOptions: SelectOptions = {
    placeholder: 'Select sub-type',
    disabled: false,
  };

  customSubmissionTypeOptions: TextareaOptions = {
    placeholder: 'Enter text',
    minHeight: 100,
    maxLength: 50,
    maxLengthPosition: 'bottom-right',
  };

  confirmLabel = 'Confirm and proceed';
  confirmButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primaryLoud,
    width: WidthState.fixed,
    isLoading: false,
    icon: this._emptySpace,
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: true,
    ariaLabel: this.confirmLabel,
    customClass: this._emptySpace,
    role: 'button',
  };

  cancelLabel = 'Cancel';
  cancelOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    width: WidthState.fixed,
    isLoading: false,
    icon: this._emptySpace,
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: 'Cancel',
    customClass: this._emptySpace,
    role: 'button',
  };

  closeButton: ButtonOptions = {
    theme: ExtThemes.blue,
    kind: ButtonKind.secondary,
    width: WidthState.fixed,
    isLoading: false,
    icon: this._emptySpace,
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: true,
    ariaLabel: this.confirmLabel,
    customClass: this._emptySpace,
    role: 'button',
  };

  public tabs = [
    {
      label: 'Active',
      code: 'active',
      count: this.totalOpportunityCount,
    },
    {
      label: 'All opportunities',
      code: 'all',
      count: this.activeOpportunityCount,
    }
  ];

  onSubmissionTypeChange(): void {
    if (this.submissionType !== 'Other') {
      this.showSelectOpportunity = true;

      this.postButtonEnabled = this.opportunityList.length > 0;
      this.confirmButtonOptions.disabled = !this.postButtonEnabled;
    } else {
      this.showSelectOpportunity = false;

      this.postButtonEnabled = false;
      this.confirmButtonOptions.disabled = !this.postButtonEnabled;
    }
    this.submissionTypeId = this.submissionData.find(
      (sub: SubmissionType) => sub.submissionTypeName === this.submissionType
    )?.submissionTypeId;
    this.showSubType = false;
    this.showDescription = false;
    this.loadSubmissionSubTypes(this.submissionTypeId);

    this.postData.SubmissionTypeDetails.SubmissionTypeId =
      this.submissionTypeId;
    this.postData.SubmissionTypeDetails.SubmissionSubTypeId = undefined;
    this.opportunityList = [];
    this.opportunityDetailsList = [];
    this.showOpportunityDetails = false;
    this.selectedOportunity = this._emptySpace;
    this.showDropdown = false;
    this.notFound = this._emptySpace;
    this.showNoreviewNote = false;
    this.changeOrderDuplicateError = false;
    this.isOpportunityDCF =
      this.isOpportunityMA =
      this.isOpportunityGPS =
      this.isOpportunityConfidential =
      this.isOpportunityMaDeliverable =
        false;
  }

  onSubmissionSubTypeChange(): void {
    this.showSelectOpportunity = true;
    const selectedSubType = this.submissionSubData.find(
      (subType) => subType.subTypeName === this.submissionSubType
    );
    if (selectedSubType?.enableText) {
      this.showDescription = true;
      this.CustomSubmissionType = this._emptySpace;

      this.postButtonEnabled = false;
      this.confirmButtonOptions.disabled = !this.postButtonEnabled;
    } else {
      this.showDescription = false;
      this.CustomSubmissionType = this._emptySpace;

      this.updateConfirmButtonStatus(0);
      this.confirmButtonOptions.disabled = !this.postButtonEnabled;
    }
    this.postData.SubmissionTypeDetails.SubmissionSubTypeId =
      selectedSubType?.subTypeId;
  }

  onCancel(): void {
    //To Regain the zero state
    this.submissionSubData = [];
    this.submissionSubTypes = [];
    this.submissionType = this._emptySpace;
    this.submissionSubType = this._emptySpace;
    this.submissionTypeId = undefined;
    this.showSelectOpportunity = false;
    this.submissionSubTypeId = undefined;
    this.CustomSubmissionType = this._emptySpace;
    this.showSubType = false;
    this.pageNumber = 0;
    this.latestSubmissionDetails = [];
    this.notAvailable = false;
    this.totalCount = 0;
    this.showDescription = false;
    this.selectedOportunity = this._emptySpace;
    this.showDropdown = false;
    this.showOpportunityDetails = false;
    this.postButtonEnabled = false;
    this.showNoreviewNote = false;
    this.changeOrderId = this._emptySpace;
    this.opportunityList = [];
    this.opportunityDetailsList = [];
    this.changeOrderList = [];
    this.opportunityDetailName = this._emptySpace;
    this.notFound = this._emptySpace;
  }

  prepareInitiateSubmissionData(): void {
    this.postData.SubmissionTypeDetails.CustomSubmissionType =
      this.CustomSubmissionType;
    if (this.opportunityDetailsList.length) {
      this.postData.OpportunityDetails.ClientNumber =
        this.opportunityDetailsList[0]?.clientNumber;
      this.postData.OpportunityDetails.OpportunityName =
        this.opportunityDetailsList[0]?.opportunityName;
      this.postData.OpportunityDetails.OpportunityStatusId =
        this.opportunityDetailsList[0]?.opportunityStatusId;
      this.postData.OpportunityDetails.PursuitLeaderId =
        this.opportunityDetailsList[0]?.pursuitLeaderId;
      this.opportunityDetailName =
        this.opportunityDetailsList[0]?.opportunityName;
      this.postData.OpportunityDetails.offeringPortfolioId =
        this.opportunityDetailsList[0]?.offeringPortfolioId;
      this.postData.OpportunityDetails.offeringId =
        this.opportunityDetailsList[0]?.offeringId;
      if (!this.showNoreviewNote) {
        this.postData.OpportunityDetails.ParentOpportunityNumber = null;
        this.postData.OpportunityDetails.OpportunityNumber =
          this.opportunityDetailsList[0]?.opportunityNumber;
      } else {
        if (this.notAvailable) {
          this.postData.OpportunityDetails.OpportunityNumber =
            this.opportunityDetailsList[0]?.opportunityNumber;
          this.postData.OpportunityDetails.ParentOpportunityNumber = null;
        } else {
          this.postData.OpportunityDetails.OpportunityNumber =
            this.changeOrderList[0]?.opportunityNumber;
          this.postData.OpportunityDetails.ParentOpportunityNumber =
            this.opportunityDetailsList[0]?.opportunityNumber;
        }
      }
    }
  }

  onSubmit(): void {
    this.progressIndicator.show();
    this.postButtonEnabled = false;
    this.prepareInitiateSubmissionData();
    this.submissionService
      .postSubmissionRequest(this.postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: IntakeSubmissionResponseDataModel) => {
          if (response) {
            const id = response.opportunityNumber;
            const opportunityDetailName = this.opportunityDetailName;
            const submissionID = response.submissionId;
            this.progressIndicator.hide();
            document.title =
              BrowserTabConstant.Browser_TabName_Default + submissionID;
            this.router.navigate(['/submission/opportunity-details', id], {
              state: {
                name: opportunityDetailName,
                submissionID: submissionID,
                submissionTypeCode:
                  this.submissionType === IntakeConstant.CHANGE_ORDER_LABEL
                    ? IntakeDocumentSubmissionTypeCode.CO
                    : this._emptySpace,
              },
            });
            this.#getReviewersData(submissionID);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  selectOpportunityChanged(event: string): void {
    this.totalCount = 0;
    this.pageNumber = 1;
    if (event.length > 2 && event !== this._emptySpace) {
      this.searchOpportunityQuery$.next(event);
    } else {
      this.notFound = this._emptySpace;
      this.opportunityList = [];
      this.totalCount = 0;
      this.showDropdown = false;
    }
  }

  fetchOpportunityDetails(
    opportunity: OpportunityDetails,
    fetchType: string
  ): void {
    this.isOpportunityMA = opportunity?.isMAOpportunity;
    this.isOpportunityDCF = opportunity?.isDCFOpportunity;
    this.isOpportunityGPS = opportunity?.isGPSOpportunity;
    this.isOpportunityConfidential = opportunity?.isConfidentialOpportunity;
    this.isOpportunityMaDeliverable =
      opportunity?.isMAOpportunity &&
      this.submissionType === IntakeConstant.DELIVERABLE;

    if (
      opportunity.swiftClientStatus === 'Blocked' ||
      opportunity.swiftClientStatus === 'Duplicate' ||
      opportunity.isMarkedForDeletion == true
    ) {
      this.isClientStatusValid = false;
    } else {
      this.isClientStatusValid = true;
    }

    if (
      this.isOpportunityMA ||
      this.isOpportunityGPS ||
      this.isOpportunityDCF ||
      this.isOpportunityConfidential ||
      this.isOpportunityMaDeliverable
    ) {
      this.getMAOpportunityLabels();
    }
    if (this.submissionType === IntakeConstant.CHANGE_ORDER_LABEL) {
      this.getMAOpportunityLabels();
      if (this.showNoreviewNote) {
        if (
          this.primaryDgmfId === opportunity?.dgmfId ||
          this.primaryswiftId === opportunity?.swiftId ||
          this.primaryGmdmId === opportunity?.gmdmId
        ) {
          this.showWarningMessage = false;
        } else {
          this.showWarningMessage = true;
        }
      }
      this.primaryDgmfId = opportunity?.dgmfId ?? this._emptySpace;
      this.primaryGmdmId = opportunity?.gmdmId ?? this._emptySpace;
      this.primaryswiftId = opportunity?.swiftId ?? this._emptySpace;
      this.checkOpportunity(opportunity?.opportunityNumber, fetchType);
    } else {
      this.updateConfirmButtonStatus(0);
    }
    this.opportunityList = [];
    this.showDropdown = false;
    this.showOpportunityDetails = true;
    if (fetchType === IntakeConstant.CHANGE_ORDER_LABEL) {
      this.changeOrderList = [opportunity];
      if (
        opportunity?.opportunityNumber ===
        this.opportunityDetailsList[0].opportunityNumber
      ) {
        this.changeOrderList = [];
        this.changeOrderDuplicateError = true;
      } else {
        this.changeOrderDuplicateError = false;
        this.selectedOportunity = this._emptySpace;
      }
    } else {
      this.opportunityDetailsList = [opportunity];
      this.changeOrderDuplicateError = false;
      this.selectedOportunity = this._emptySpace;
    }
  }

  getMAOpportunityLabels(): void {
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          this.updateHeaderandBodyLabels(response);
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during getIntakeConfig: ', err);
        },
      });
  }

  updateHeaderandBodyLabels(response: configurationType[]): void {
    this.globalConfigurationLabelItems = response;
    this.globalConfigurationLabelItems.forEach((item: configurationType) => {
      if (this.isOpportunityMA) {
        if (
          item.appConfigurationLabelKey === IntakeConstant.MA_WARNING_BODY_KEY
        ) {
          this.warningBodyMA = item.appConfigurationLabelValue;
        } else if (
          item.appConfigurationLabelKey === IntakeConstant.MA_WARNING_HEADER_KEY
        ) {
          this.warningHeaderMA = item.appConfigurationLabelValue;
        }
      }

      if (this.isOpportunityGPS) {
        if (
          item.appConfigurationLabelKey === IntakeConstant.GPS_WARNING_BODY_KEY
        ) {
          this.warningBodyGPS = item.appConfigurationLabelValue;
        } else if (
          item.appConfigurationLabelKey ===
          IntakeConstant.GPS_WARNING_HEADER_KEY
        ) {
          this.warningHeaderGPS = item.appConfigurationLabelValue;
        }
      }

      if (this.isOpportunityDCF) {
        if (
          item.appConfigurationLabelKey === IntakeConstant.DCF_WARNING_BODY_KEY
        ) {
          this.warningBodyDCF = item.appConfigurationLabelValue;
        } else if (
          item.appConfigurationLabelKey ===
          IntakeConstant.DCF_WARNING_HEADER_KEY
        ) {
          this.warningHeaderDCF = item.appConfigurationLabelValue;
        }
      }

      if (this.isOpportunityConfidential) {
        if (
          item.appConfigurationLabelKey === IntakeConstant.CONFIDENTIAL_OPPORTUNITY_BODY
        ) {
          this.confidentialOpportunityBody = item.appConfigurationLabelValue;
        } else if (
          item.appConfigurationLabelKey ===
          IntakeConstant.CONFIDENTIAL_OPPORTUNITY_HEADER
        ) {
          this.confidentialOpportunityHeader = item.appConfigurationLabelValue;
        }
      }

      if (this.isOpportunityMaDeliverable) {
        if (
          item.appConfigurationLabelKey ===
          IntakeConstant.MA_DELIVERABLE_BODY_KEY
        ) {
          this.warningBodyMaDeliverable = item.appConfigurationLabelValue;
        } else if (
          item.appConfigurationLabelKey ===
          IntakeConstant.MA_DELIVERABLE_TITLE_KEY
        ) {
          this.warningHeaderMaDeliverable = item.appConfigurationLabelValue;
        }
      }

      if (this.submissionType === IntakeConstant.CHANGE_ORDER_LABEL)
        if (
          item.appConfigurationLabelKey === IntakeConstant.CO_OPP_DONOT_MATCH
        ) {
          this.coOppDonotMatchMessage = item.appConfigurationLabelValue;
        }
    });
  }

  clickShowLess(event: Event, section: string): void {
    if (section === this.MA) {
      this.isShowMoreVisible = false;
    } else if (section === this.GPS) {
      this.isShowMoreVisibleGPS = false;
    } else if (section === this.DCF) {
      this.isShowMoreVisibleDCF = false;
    } else if (section === this.MA_DELIVERABLE) {
      this.isShowMoreVisibleMaDeliverable = false;
    } else if (section === IntakeConstant.CONFIDENTIAL_OPPORTUNITY) {
      this.isShowMoreVisibleConfidentialOppertunity = false;
    }
  }

  clickShowMore(event: Event, section: string): void {
    if (section === this.MA) {
      this.isShowMoreVisible = true;
    } else if (section === this.GPS) {
      this.isShowMoreVisibleGPS = true;
    } else if (section === this.DCF) {
      this.isShowMoreVisibleDCF = true;
    } else if (section === this.MA_DELIVERABLE) {
      this.isShowMoreVisibleMaDeliverable = true;
    } else if (section === IntakeConstant.CONFIDENTIAL_OPPORTUNITY) {
      this.isShowMoreVisibleConfidentialOppertunity = true;
    }
  }

  getDuplicateError(): string {
    return (
      this.configurationsIntake.find(
        (config) => config.appConfigurationLabelKey === 'CO_DUPLICATE_OPP_ERROR'
      )?.appConfigurationLabelValue || this._emptySpace
    );
  }

  fetchOpportunitySearchResult(pageNumber: number, tabCode: string): void {
    this.isSpinnerVisible = true;
    this.submissionService
      .getOpportunityDetails(
        this.selectedOportunity,
        pageNumber,
        this.pageSize,
        tabCode
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (opportunity: OpportunityList) => {
          if (opportunity?.totalOpportunityCount > 0) {
            this.notFound = this._emptySpace;
            if (pageNumber === 1) {
              this.opportunityList = opportunity?.opportunities;
            } else {
              this.opportunityList = [
                ...this.opportunityList,
                ...opportunity?.opportunities,
              ];
            }
            this.totalCount = opportunity?.totalOpportunityCount;
            this.tabs[0].count = opportunity?.activeOpportunityCount;
            this.tabs[1].count = opportunity?.totalOpportunityCount;
            if(opportunity.activeOpportunityCount == 0){
              if(this.selectTabIndex == 0){
                this.selectTabIndex = 1;
               this.tabChanged(this.tabs[1].code);
              } 
            }else {
              if(this.selectTabIndex == 1){
                this.selectTabIndex = 0;
                this.tabChanged(this.tabs[0].code);
              }
            }
            if (this.opportunityList.length > 0) this.showDropdown = true;
          } else {
            this.notFound = this.findText(opportunity);
            this.opportunityList = [];
            this.totalCount = 0;
            this.showDropdown = false;
          }
          this.isSpinnerVisible = false;
        },
        error: (err: HttpErrorResponse) => {
          this.isSpinnerVisible = false;
          console.error('Error fetching submission types', err);
        },
      });
  }

  findText(opportunity: OpportunityList): string {
    const notFoundtext = this.configurationsIntake.find(
      (config) => config.appConfigurationLabelKey === opportunity?.notFoundLabel
    )?.appConfigurationLabelValue;
    return notFoundtext ?? this._emptySpace;
  }

  fetchOpportunitySearchResultMore(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      if (this.pageNumber < this.totalCount / this.pageSize) {
        this.pageNumber++;
        this.fetchOpportunitySearchResult(
          this.pageNumber,
          this.selectedTabCode
        );
      }
    }
  }

  removeOpportunity(removeType: string): void {
    if (removeType === IntakeConstant.CHANGE_ORDER_LABEL) {
      this.changeOrderList = [];
    } else {
      this.showOpportunityDetails = false;
      this.selectedOportunity = this._emptySpace;
      this.opportunityList = [];
      this.opportunityDetailsList = [];
      this.showDropdown = false;
      this.postButtonEnabled = false;
      this.confirmButtonOptions.disabled = !this.postButtonEnabled;
      this.showNoreviewNote = false;
      this.changeOrderList = [];
      this.opportunityDetailsList = [];
    }
    this.showWarningMessage = false;
    this.changeOrderDuplicateError = false;
    this.isOpportunityDCF =
      this.isOpportunityMA =
      this.isOpportunityGPS =
      this.isOpportunityMaDeliverable =
      this.isOpportunityConfidential =
        false;
  }

  onCustomSubmissionTypeChange(callFrom: number): void {
    this.updateConfirmButtonStatus(callFrom);
  }

  updateConfirmButtonStatus(callFrom: number): void {
    if (this.showDescription) {
      if (callFrom == 1) {
        this.postButtonEnabled =
          this.CustomSubmissionType.trim() != this._emptySpace &&
          this.opportunityDetailsList.length > 0;
      } else {
        this.postButtonEnabled =
          this.CustomSubmissionType.trim() != this._emptySpace &&
          this.opportunityList.length > 0;
      }
    } else {
      this.postButtonEnabled = this.opportunityList.length > 0;
    }
    this.confirmButtonOptions.disabled = !this.postButtonEnabled;
  }

  fieldCleared(): void {
    this.opportunityList = [];
    this.selectedOportunity = this._emptySpace;
    this.showDropdown = false;
    this.notFound = this._emptySpace;
  }

  checkOpportunity(opportunityId: string, fetchType: string): void {
    this.progressIndicator.show();
    this.submissionService
      .getOpportunitySelected(opportunityId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: latestOpportunityDetails) => {
          if (response) {
            this.latestSubmissionDetails = [response];
            this.confirmButtonOptions.disabled = false;
            if (fetchType === IntakeConstant.CHANGE_ORDER_LABEL) {
              this.showNoreviewNote = true;
              this.secondSearchCheck = true;
            } else {
              this.showNoreviewNote = false;
              this.secondSearchCheck = false;
            }
          } else {
            this.showNoreviewNote = true;
            this.secondSearchCheck = false;
          }
          this.progressIndicator.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  checkEnable(): boolean {
    if (
      this.isOpportunityDCF ||
      this.isOpportunityGPS ||
      this.isOpportunityConfidential ||
      this.isOpportunityMaDeliverable
    ) {
      return false;
    }

    if (this.isClientStatusValid == false) {
      return false;
    }

    if (this.submissionType === IntakeConstant.CHANGE_ORDER_LABEL) {
      if (this.showNoreviewNote) {
        return (
          (this.changeOrderList.length > 0 || this.notAvailable) &&
          this.checkClientStatus()
        );
      } else {
        return (
          this.opportunityDetailsList.length > 0 && this.checkClientStatus()
        );
      }
    } else {
      return this.postButtonEnabled && this.checkClientStatus();
    }
  }

  checkClientStatus(): boolean {
    return (
      this.opportunityDetailsList[0]?.watchlistStatus != 'Do not serve' &&
      this.changeOrderList[0]?.watchlistStatus != 'Do not serve'
    );
  }

  displayChangeOrderCheck(): boolean {
    return (
      this.changeOrderList.length > 0 &&
      !(
        this.changeOrderList[0]?.opportunityNumber ===
        this.opportunityDetailsList[0]?.opportunityNumber
      )
    );
  }

  getNumberOfSplits(fetchType: string): string {
    let splits = this._emptySpace;
    if (fetchType === this.changeOrder) {
      for (let offering of this.changeOrderList[0]?.offering) {
        splits += `${offering} \n`;
      }
    } else {
      for (let offering of this.opportunityDetailsList[0]?.offering) {
        splits += `${offering} \n`;
      }
    }
    return splits;
  }

  loadRolesStatusActions(): void {
    this.commonService
      .getIntakeActions()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: IntakeAction[]) => {
          this.commonService.setIntakeActions(response);
        },
        error: (err: Error) => {
          console.error('Error fetching submission status', err);
        },
      });

    this.commonService
      .getOppertunityStatusCode()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[]) => {
          this.commonService.setIntakeOppertunityDocStatusCode(response);
        },
        error: (err: Error) => {
          console.error('Error fetching submission status', err);
        },
      });
  }

  #getReviewersData(submissionId: string | null): void {
    if (submissionId)
      this.submissionService
        .getSubmissionReviewers(submissionId, true)
        .subscribe((response: SubmissionApprovers[]) => {
          this.#updateReviewersData(response, submissionId);
        });
  }

  #updateReviewersData(
    response: SubmissionApprovers[],
    submissionId: string
  ): void {
    this.progressIndicator.show();
    this.submissionService
      .updateSubmissionReviewers(response, Number(submissionId))
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicator.hide();
        },
        error: (err: Error) => {
          console.error('Error updating submission', err);
          this.progressIndicator.hide();
        },
      });
  }

  tabChanged(event: string): void {
    this.selectedTabCode = event;
    this.fetchOpportunitySearchResult(1, this.selectedTabCode);
  }

  setActiveTab(tabcode:string):void{
    this.fetchOpportunitySearchResult(1, tabcode);
  }

  ngOnDestroy(): void {
    this.progressIndicator.hide();
    this.unsubscriber$.next(this._emptySpace);
    this.unsubscriber$.complete();
  }
}
