import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config, Observable } from 'rxjs';
import { environment } from '../../../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private header = new HttpHeaders({ 'content-type': 'application/json' });
  constructor(private myhttp: HttpClient) {}
  baseURL: string = environment.apiUrl;
  // baseURL: string = 'https://dapi.eclipse.deloitte.com/api/'; //dev
  //baseURL: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa
  getPresignedUrlDetails(data: any): Observable<any> {
    const url = this.baseURL + 'Document/GetPresignedUrlForUpload';
    return this.myhttp.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }
  saveDocumentMetaData(data: any): Observable<any> {
    const url = this.baseURL + 'Document/SaveDocumentMetaData';
    var finalData = JSON.stringify(data);
    return this.myhttp.post(url, finalData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }
  updateDocumentMetaData(data: any): Observable<any> {
    const url = this.baseURL + 'Document/UpdateDocumentMetaData';
    var finalData = JSON.stringify(data);
    return this.myhttp.post(url, finalData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }
  documentUploadByPresignedURL(data: any, fileSource: any): Observable<any> {
    const url = data.url;
    return this.myhttp.put(url, fileSource, {
      headers: new HttpHeaders({
        'Content-Type': 'binary/octet-stream',
        'x-amz-server-side-encryption': 'aws:kms',
        'x-amz-server-side-encryption-aws-kms-key-id': data.kmsArn,
      }),
    });
  }
  getDownloadUrl(downloadRequest: any): Observable<any> {
    var data = JSON.stringify(downloadRequest);
    const url = this.baseURL + 'Document/GetDownloadUrl';
    return this.myhttp.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }
  deleteDocument(documentKey: any, fileFolderPath: any): Observable<any> {
    const url =
      this.baseURL +
      'Document/DeleteDocument?documentKey=' +
      documentKey +
      '&fileFolderPath=' +
      fileFolderPath;
    return this.myhttp.delete(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }
  uploadDocument(file: any, filePath: any) {
    const url = this.baseURL + 'Document/UploadDocument';
    const formData: FormData = new FormData();
    formData.append('file', file.fileSource, file.fileSource.name);
    formData.append('FileFolderPath', filePath);
    return this.myhttp.post(url, formData, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }

  //getDocumentUploadData(attachmentID:string): Observable<DocumentTypeData[]> {
  //  const url = this.baseURL + 'Document/GetDocumentMetaData?attachmentID=' + attachmentID;
  //  return this.myhttp.get<DocumentTypeData[]>(url, {
  //    headers: new HttpHeaders({
  //      'Content-Type': 'application/json',
  //      'Authorization': "Bearer " + localStorage.getItem("idToken")
  //    })
  //  })
  //}
}

export interface DocumentMetaDataDetail {
  attachmentId: number;
  attachmentGuid: string;
  fileName: string;
  fileLength: number;
  fileType: string;
  mimeType: string;
  fileDesc: string;
  fileFolderPath: string;
  fileComments: string;
  createdBy: string;
  createdDate: Date;
  modifiedBy: string;
  modifiedDate: Date;
  isActive: boolean;
}
