import { Component, OnInit, Input, input } from '@angular/core';
import { EntityType, UserService } from '../http/user.service';
import { MsGraphService } from '../http/msgraph.service';
import { filter, Observable } from 'rxjs';
import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
import {
  WidthState,
  Themes,
  Size,
  ButtonKind,
  ExtThemes,
  PositionState,
} from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ProfileOptions } from '@usitsdasdesign/dds-ng/profile';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { NavigationEnd, Router } from '@angular/router';
import {
  DropdownOptions,
  DropdownItemOptions,
} from '@usitsdasdesign/dds-ng/dropdown';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { StartNcaprocessDialogComponentComponent } from '../start-ncaprocess-dialog-component/start-ncaprocess-dialog-component.component';

@Component({
  selector: 'app-eclipse-header',
  templateUrl: './eclipse-header.component.html',
  styleUrl: './eclipse-header.component.less',
})
export class EclipseHeaderComponent implements OnInit {
  public displayUserName: string | null = null;
  public userEmail: string | null = null;
  public imageUrl: any = '';
  isProficPicAvailable: boolean = false;
  isDropdownOpen: boolean = false;
  userRoles: string='';

  headerOptions: HeaderOptions = {
    name: 'Eclipse',
    width: WidthState.full,
    theme: Themes.green,
    isResponsive: false,
    isInverse: false,
    customClass: '',
  };

  responsivePriority: HeaderElmnts[] = [
    HeaderElmnts.logo,
    HeaderElmnts.projectName,
    HeaderElmnts.profile,
    HeaderElmnts.nav,
    HeaderElmnts.icons,
    HeaderElmnts.search,
  ];
  headerButtonOptions: ButtonOptions = {
    theme: Themes.dark,
  };
  chatButtonOptions: ButtonOptions = {
    theme: Themes.dark,
    icon: 'dds-icon_chat__s__stroke',
  };
  profileOptions: ProfileOptions = {
    username: '',
    photoUrl: '',
  };

  searchOptions: SearchOptions = {
    placeholder: 'Search clients, opportunities or submissions by name or ID',
    ariaLabel: '',
    size: Size.md,
    isInverse: false,
    customClass: '',
    isTrimValue: false,
    readonly: false,
    maxLength: 100,
  };

  defaultDropdownStrickerPostion: PositionState =
    PositionState.bottomLeft ?? undefined;
  dropdownOptions: DropdownOptions = {
    label: '',
    ariaLabel: '',
    theme: ExtThemes.dark,
    kind: ButtonKind.primaryLoud,
    size: Size.md,
    width: WidthState.fixed,
    icon: 'dds-icon_plus',
    isColorBg: false,
    isInverse: false,
    customClass: '',
    disabled: false,
    isResponsive: false,
    stickerWidth: 300,
    stickerIsOutsideClick: false,
    stickerShift: 0,
    stickerMaxHeight: '',
    stickerIsDisabled: false,
    stickerPosition: PositionState.bottomRight,
    stickerIndent: 0,
    stickerCustomClass: '',
    stickerIsDynamic: true,
  };

  dropdownItems: DropdownItemOptions[] = [
    {
      heading: 'Opportunity Submission',
      value: 'Opportunity Submission',
    },
    {
      heading: 'External Communication',
      value: 'External Communication',
    },
    {
      heading: 'MARS NCA Request',
      value: 'MARS NCA Request',
    },
  ];

  //TODO: Take Additional Options for Gear menu from Backend If needed
  additionalOptions: DropdownItemOptions[] = [
    {
      heading: 'Start NCA Process',
      value: 'StartNcaProcess',
    },
    {
      heading: 'Watchlist',
      value: 'Watchlist',
    }
  ];

  constructor(
    private userService: UserService,
    private msGraphService: MsGraphService,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    var isValidProilePic = false;
    var userProfilePic = localStorage.getItem('userProfilePic');
    var userPreferredName = localStorage.getItem('userPreferredName');
    this.displayUserName = userPreferredName;
    this.profileOptions.username = this.displayUserName ?? 'User';
    if (!userPreferredName) {
      this.userService.userEmail$.subscribe((useremail) => {
        this.userEmail = localStorage.getItem('userProfileId');
        this.getUserPreferredName();
      });
    }

    if (userProfilePic) {
      this.isValidBase64Image(userProfilePic).subscribe((isValid) => {
        if (isValid) {
          isValidProilePic = true;
          this.isProficPicAvailable = true;
          this.imageUrl = userProfilePic;
          this.profileOptions.photoUrl = this.imageUrl;
        }
      });
    }
    if (!isValidProilePic) {
      this.userService.userEmail$.subscribe((useremail) => {
        this.userEmail = localStorage.getItem('userProfileId');
        this.getUserProfilePic();
      });
    }
    this.handleChangeRoute();
  }

  itemSelected(value: string): void {
    if (value === 'Opportunity Submission') {
      this.router.navigate(['/intake/initiate-submission']);
    }
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  navigateTo(path: string): void {
    this.router.navigate([path]);
    this.toggleDropdown();
  }

  handleChangeRoute(){
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      var entity:string=event.url;
      let entityId,entityTypeId; //TODO: implement logic to get the entity and entityId
      switch(entity){
        case 'Client':
          entityTypeId = EntityType.Client;
          break;
        default: entityTypeId = EntityType.None;
      }

      this.getUserRoles(entityId,entityTypeId)
    });

  }

  getUserRoles(entityId:any,entityTypeId:any) {
      if(this.userEmail){
        this.userService.getUserRoles(entityId,entityTypeId).subscribe((data) => {
          if (data) {
            this.userRoles = data?.employeeRoles?.map((u:any)=>u.roleName).join("\n");
          }
        });
      }
  }

  getUserPreferredName() {
    if (this.userEmail)
      this.msGraphService
        .searchUserByEmail(this.userEmail)
        .subscribe((data) => {
          if (data && data.value && data.value.length > 0) {
            const user = data.value[0];
            let firstName = user.givenName;
            const displayName = user.displayName;

            if (!firstName || firstName.trim() === '') {
              firstName = displayName;
            }

            if (firstName) {
              localStorage.setItem('userPreferredName', firstName);
              this.displayUserName = firstName;
              this.profileOptions.username = this.displayUserName ?? 'User';
            } else {
              console.error(
                'First name and display name are both missing or invalid.'
              );
            }
          } else {
            console.error('No user data found or data structure is invalid.');
          }
        });
  }

  additionaloptionSelected(value: string): void {
    if (value === 'StartNcaProcess') {
      this.startNcaProcessManually();
    }

    if(value == 'Watchlist'){
      this.router.navigate(['/watchlist']);
    }
  }

  public async startNcaProcessManually() {
      let modalRef = this.modalService.open(StartNcaprocessDialogComponentComponent);
  }

  getUserProfilePic() {
    if (this.userEmail)
      this.msGraphService.GetProfileImage(this.userEmail).subscribe((data) => {
        try {
          if (data != null) {
            var imgUrl = data;

            this.isValidBase64Image(imgUrl).subscribe((isValid) => {
              if (isValid) {
                this.imageUrl = imgUrl;
                this.profileOptions.photoUrl = this.imageUrl;
                this.isProficPicAvailable = true;
                localStorage.setItem('userProfilePic', data);
              }
            });
          }
        } catch (error: any) {
          console.error('Error fetching profile image', error);
        }
      });
  }

  isValidBase64Image(imageUrl: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        observer.next(true);
        observer.complete();
      };

      img.onerror = () => {
        observer.next(false);
        observer.complete();
      };
    });
  }
}
