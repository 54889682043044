import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { ButtonKind, ExtThemes, Size, Themes, WidthState, PositionState } from '@usitsdasdesign/dds-ng/shared';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { FormControl } from '@angular/forms';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { ClientSearchWatchlistResult, ClientWatchlistDto, WatchlistApiService, WatchlistEraseItems, WatchlistStatus, WatchlistStatusDropDown, WatchlistRequest } from '../../http/watchlist/watchlist-api.service';
import { MultiSelectOptions, SelectControlTypes, SelectType } from '@usitsdasdesign/dds-ng/multi-select';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { DropdownItemOptions, DropdownOptions } from '@usitsdasdesign/dds-ng/dropdown';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { EraseClientRecordComponent } from '../erase-client-record/erase-client-record.component'
import { Router } from '@angular/router';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { ToggleOptions, ToggleComponent, ToggleGroupOptions } from '@usitsdasdesign/dds-ng/toggle';
import { CheckboxOptions } from '@usitsdasdesign/dds-ng/checkbox';
import { environment } from '../../../environment/environment';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import {
  ContextMenuOptions,
  ContextMenuGroupOptions,
  ContextMenuItemOptions,
} from "@usitsdasdesign/dds-ng/context-menu";
import { AdobeAnalyticsService } from '../../common/services/adobe-analytics.service';
import { SharedService } from '../../common/services/shared-service.service';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { EclipseHeaderService } from '../../http/eclipse-header.service';
import { EntityDetails, EntityType } from '../../http/user.service';
import { ClientDetailsConstant } from '../../client/constants/client-details-constant';

@Component({
  selector: 'app-watchlist-add',
  templateUrl: './watchlist-add.component.html',
  styleUrl: './watchlist-add.component.less'
})
export class WatchlistAddComponent implements OnInit {
  @ViewChild('dropdown', { static: false }) dropdown?: NgbDropdown;
  @ViewChild('searchInput') searchInput!: ElementRef;
  public searchKey: string = '';
  private searchKey$ = new Subject<string>();
  filteredItems: ClientSearchWatchlistResult[] = [];
  selectedItem?: ClientSearchWatchlistResult;

  searchFormCtrl = new FormControl({ value: '', disabled: false });

  public clientSearchItems: Array<any> = [];
  public enumWatchlistStatus = WatchlistStatus;
  public watchlistStatusSelectItems: Array<WatchlistStatusDropDown> = [];
  public isshowmorevisible: boolean = true;
  public isshowmoreparentvisible: boolean = true;
  public isshowmorevisiblePriorWatchlist: boolean = true;
  public clientSearchWatchlistmodel: ClientSearchWatchlistResult = {} as ClientSearchWatchlistResult;
  public WatchlistUpsertModel: WatchlistRequest = {} as WatchlistRequest;
  public WatchlistUpsertModelList: Array<WatchlistRequest> = [];
  public isEdit: boolean = false;
  public watchlistStatusId: number = 0;
  public clientWatchlistStatusComment: string = '';
  public isLoading: boolean = true;
  public isSubsidiaryBlockVisible = false;
  public clientWatchlistDto: ClientWatchlistDto[] = [];

  public subsidiaryClientList: any[] = [];
  public alreadyAddedSubsidiaryCount: number = 0;
  public addToWatchlistButtonText: string = 'Add to watchlist';
  public dropdownItems: DropdownItemOptions[] = [];
  public watchlistItemForErase: WatchlistEraseItems = {} as WatchlistEraseItems;
  public showPreviousWatchlistStatus: boolean = false;
  public orgWatchlistStatusId: number = 0;
  public orgStatusComment: string = '';
  private securityAddAndEditButton: string = 'Watchlist.AddAndEditButton';
  private securityAuditTrail: string = 'Watchlist.AuditTrail';
  private securityEraseFromWatchlist: string = 'Watchlist.EraseFromWatchlist';
  private isAdjustingHeight: boolean = false;
  public isEditEnabled: boolean = false;
  public isEraseFromWatchlistVisible: boolean = false;
  public isAddedCheckboxPartiallyChecked: boolean = true;
  public isAddedCheckboxChecked: boolean = true;
  public isSubsidiarySectionCollapsed: boolean = true;
  public isAllChecked: boolean = true;
  public selectedsubsidiarycount: number = 0;
  public gridColumns: Column[] = [
    { name: 'Added', header: 'Added', dataType: 'string', minWidth: '1rem' },
    { name: 'Inherited', header: 'Inherited', dataType: 'boolean', minWidth: '1rem' },
    { name: 'Clientname', header: 'Client name', dataType: 'string', minWidth: '15rem' },
    { name: 'Status', header: 'Status', dataType: 'string', minWidth: '13rem' },
    { name: 'Statuscomment', header: 'Client risk comment', dataType: 'string', minWidth: '37rem' },
    { name: 'Actions', header: 'Action', dataType: 'boolean', minWidth: '1rem' }
  ];
  public isAuditTrailButtonEnable: boolean = false;
  actionType: string = 'add';
  markForDeletionText:string = ClientDetailsConstant.MarkForDeletionText;
  pageHeaderVisible: boolean = false;

  constructor(private watchlistwebapiservice: WatchlistApiService,
    private progressIndicator: ProgressIndicatorService,
    private modalService: ModalService,
    private router: Router,
    private securityWebapiService: SecurityWebapiService,
    private analytics: AdobeAnalyticsService,
    private sharedService: SharedService,
    private headerService: EclipseHeaderService) { }
  rootUrl: string = environment.rootUrl;

  toastCallbackfn(event: any) {
  }
  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_Watchlist;
    this.analytics.trackPage("Watchlist add")
    
    this.preloadHoverImage();
    let navigation = window.history.state;
    if (navigation && navigation.clientNumber) {
      this.editWatchlist(navigation.clientNumber, navigation.isParentClient);
      // Clear the state after using it
      window.history.replaceState({}, document.title);
    }

    this.headerService.showHeader$.subscribe((showHeader: boolean) => {
      this.pageHeaderVisible = showHeader ?? false;
    });

    this.securityWebapiService.getPermissionsByEmployeeId(EntityDetails.None, EntityType.None);

    this.securityWebapiService.data.subscribe((rules: any) => {
      if (!rules.empty) {
        this.isEditEnabled = rules[this.securityAddAndEditButton] && rules[this.securityAddAndEditButton].Visible !== undefined ? rules[this.securityAddAndEditButton].Visible : false;
        this.isEraseFromWatchlistVisible = rules[this.securityEraseFromWatchlist] && rules[this.securityEraseFromWatchlist].Visible !== undefined ? rules[this.securityEraseFromWatchlist].Visible : false;
        this.isAuditTrailButtonEnable = (rules[this.securityAuditTrail] && rules[this.securityAuditTrail].Enable);
        if (!this.isEditEnabled)
          this.router.navigate(['/']);
      }
    });
    this.dropdownItems = [
      {
        heading: 'Erase client record',
        value: 'Eraseclientrecord',
        disabled: false
      }
    ];
  }

  preloadHoverImage(): void {
    const img = new Image();
    img.src = 'src/assets/images/dds-icons/nca_never_evaluated_dark.svg';
  }

  adjustSearchInputHeight() {
    if (this.searchInput) {
      const inputElement = this.searchInput.nativeElement;
      const selectedItemDisplay = document.querySelector('.selected-item-display');

      if (!selectedItemDisplay) {
        return; // Exit if the element is not found
      }

      // Check if the selected-item-display is overflowing
      const isOverflowing = selectedItemDisplay.scrollHeight > selectedItemDisplay.clientHeight;
      if (isOverflowing) {
        inputElement.classList.add('overflow');
        inputElement.classList.remove('hidden');
      } else {
        inputElement.classList.remove('overflow');
        inputElement.classList.add('hidden');
      }
    }
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  backToList() {
    this.router.navigate(['/watchlist']);
  }

  public clientSearch() {
    if (this.searchKey && this.searchKey.length > 2) {
      this.searchKey$.next(this.searchKey.trim());
    }
    else {
      this.filteredItems = [];
    }
  }

  selectItem(item: any) {
    this.searchKey = '';
    this.searchFormCtrl.setValue(this.searchKey, { emitEvent: false });
    this.filteredItems = [];
    this.selectedItem = item;
    this.adjustSearchInputHeight();
    this.clientSearchWatchlistmodel = item;
    this.watchlistStatusSelectItems = this.clientSearchWatchlistmodel.watchlistStatusList.map(status => ({
      value: status.value,
      heading: status.heading
    }));
    if (this.clientSearchWatchlistmodel.isParentClient) {
      if (this.clientSearchWatchlistmodel.clientNumber && !this.clientSearchWatchlistmodel.watchlistStatus) {
        this.getSubsidiaryDataByClientnumber();
      }
    }
    if (this.clientSearchWatchlistmodel.watchlistStatusId) {
      this.actionType = 'edit';
    }
    else {
      this.actionType = 'add';
    }

    setTimeout(() => {
      this.adjustSearchInputHeight();
    }, 0);
    if (Number(this.clientSearchWatchlistmodel.watchlistId) > 0) {
      this.showAuditHistoryHeaderMenu(this.isAuditTrailButtonEnable);
    }
    else{
      this.showAuditHistoryHeaderMenu(false);
    }
  }

  showAuditHistoryHeaderMenu(show: boolean) {
    this.headerService.showWatchlistAuditHistoryMenu(show);
    this.headerService.setWatchlistAuditParams(this.clientSearchWatchlistmodel.clientName, this.clientSearchWatchlistmodel.clientNumber, Number(this.clientSearchWatchlistmodel.watchlistId));
  }

  public getSubsidiaryDataByClientnumber() {
    this.isSubsidiaryBlockVisible = false;
    if (this.clientSearchWatchlistmodel.isParentClient == false) { return; }
    this.progressIndicator.show();
    this.watchlistwebapiservice.getWatchlistdataByClientnumber(this.clientSearchWatchlistmodel.clientNumber ?? '', this.clientSearchWatchlistmodel.isParentClient).subscribe(
      {
        next: (result: ClientWatchlistDto[]) => {
          if (result) {
            this.clientWatchlistDto = result;
            if (this.clientWatchlistDto.length > 0) {
              this.isSubsidiaryBlockVisible = true;
            }
            //Check if any subsidiary already added to watchlist
            this.alreadyAddedSubsidiaryCount = this.clientWatchlistDto.filter(client => client.watchlistId !== null).length;

            // Create subsidiary client list for multiselect dropdown
            this.subsidiaryClientList = this.clientWatchlistDto.map((client: ClientWatchlistDto) => {
              return {
                value: client.clientNumber,
                label: `${client.clientName} (ID: ${client.clientNumber})`,
                clientId: client.clientId,
                clientNumber: client.clientNumber,
                watchlistStatuses: JSON.parse(client.watchlistStatuses).map((status: WatchlistStatusDropDown) => ({
                  value: status.value,
                  heading: status.heading
                })), // Add property for client status
                statusComment: client.statusComment, // Add property for status comment
                isNCANeverEvaluated: client.isNCANeverEvaluated,
                watchlistStatusId: client.watchlistStatusId,
                watchlistId: client.watchlistId,
                clientName: client.clientName,
                prevWatchlistStatusId: client.prevWatchlistStatusId,
                prevWatchlistStatus: client.prevWatchlistStatus,
                prevStatusComment: client.prevStatusComment,
                disabled: client.watchlistId !== null,
                isInheritedFromParent: this.isEdit ? (client.watchlistId == null ? false : client.isInheritedFromParent) : client.isInheritedFromParent,
                orgWatchlistStatusId: client.watchlistStatusId,
                orgStatusComment: client.statusComment,
                isChecked: this.isEdit ? (client.watchlistId == null ? false : true) : true
              };
            });
            this.checkInDertiminateState();
            this.selectedsubsidiarycount = this.updateSubsidiaryCount();
            if (this.alreadyAddedSubsidiaryCount > 0)
              this.multiSelectOptions.displayTickAllBtn = false;
            else
              this.multiSelectOptions.displayTickAllBtn = true;
            this.progressIndicator.hide();
          }
        },
        error: (err: any) => {
        }
      })
  }

  public inputClick() {
    this.searchKey$.pipe(
      debounceTime(500),
      filter(key => !!key && key.length > 2),
      switchMap(key => this.searchItems(key))
    ).subscribe({
      next: (response: any) => {
        this.filteredItems = response;
        this.isLoading = false;
      },
      error: (err) => {
        this.filteredItems = [];
      }
    });

    this.searchKey$.next(this.searchKey);
    if (this.dropdown && !this.dropdown.isOpen() && this.searchKey.length > 2) {
      this.dropdown.open();
    }
  }

  private searchItems(key: string) {
    this.clientSearchItems.length = 0;
    if (this.dropdown && !this.dropdown.isOpen()) {
      this.dropdown.open();
    }
    return this.watchlistwebapiservice.searchClients(key);
  }

  public clearSearch() {
    this.searchKey = '';
    this.searchKey$.next(this.searchKey);
    this.filteredItems = [];
    this.selectedItem = undefined;
    if (this.dropdown && this.dropdown.isOpen()) {
      this.dropdown.close();
    }
    this.clearFormValues();
    this.adjustSearchInputHeight();
  }

  isFormValid(): boolean {
    if (!this.clientSearchWatchlistmodel.clientName || !this.watchlistStatusId || !this.clientWatchlistStatusComment) {
      return false;
    }
    if (this.watchlistStatusId != this.orgWatchlistStatusId || this.clientWatchlistStatusComment != this.orgStatusComment) {
      return true;
    }
    for (let subsidiary of this.subsidiaryClientList) {
      if (subsidiary.isChecked && (!subsidiary.watchlistStatusId || !subsidiary.statusComment)) {
        return false;
      }
    }
    const modifiedSubsidiaryClient = this.subsidiaryClientList.filter(item => item.isChecked
      && (item.watchlistStatusId != item.orgWatchlistStatusId || item.statusComment != item.orgStatusComment)
    );
    if (modifiedSubsidiaryClient.length > 0) { return true }
    return false;
  }

  upsertWatchlistData() {
    this.WatchlistUpsertModelList = [];
    this.progressIndicator.show();
    if (this.watchlistStatusId != this.orgWatchlistStatusId || this.clientWatchlistStatusComment?.trim() != this.orgStatusComment?.trim()) {
      this.WatchlistUpsertModel.clientNumber = this.clientSearchWatchlistmodel.clientNumber;
      this.WatchlistUpsertModel.watchlistStatusId = this.watchlistStatusId;
      this.WatchlistUpsertModel.statusComment = this.clientWatchlistStatusComment?.trim();
      this.WatchlistUpsertModel.isParentClient = this.clientSearchWatchlistmodel.isParentClient;
      this.WatchlistUpsertModel.isActive = true;
      this.WatchlistUpsertModel.isInheritedFromParent = false;
      this.WatchlistUpsertModel.parentClientNumber = this.clientSearchWatchlistmodel.parentClientNumber;

      // Insert WatchlistUpsertModel into WatchlistUpsertModelList
      this.WatchlistUpsertModelList.push({ ...this.WatchlistUpsertModel });
    }

    this.subsidiaryClientList.forEach(subsidiary => {
      if (subsidiary.watchlistStatusId != subsidiary.orgWatchlistStatusId || subsidiary.statusComment?.trim() != subsidiary.orgStatusComment?.trim() && subsidiary.isChecked) {
        const upsertModel: WatchlistRequest = {
          clientNumber: subsidiary.clientNumber,
          watchlistStatusId: subsidiary.isInheritedFromParent ? this.watchlistStatusId : subsidiary.watchlistStatusId,
          statusComment: subsidiary.isInheritedFromParent ? this.clientWatchlistStatusComment?.trim() : subsidiary.statusComment,
          isParentClient: false, // Assuming subsidiaries are not parent clients
          isActive: true,
          isInheritedFromParent: subsidiary.isInheritedFromParent,
          parentClientNumber: subsidiary.parentClientNumber
        };
        this.WatchlistUpsertModelList.push(upsertModel);
      }
    });
    if (this.WatchlistUpsertModelList.length > 0) {
      return this.watchlistwebapiservice.upsertWatchlistData(this.WatchlistUpsertModelList).toPromise().then((result) => {
        if (result.isSuccess) {
          if (this.actionType == 'add') {
            this.sharedService.showToastNotification('Record added');
          }
          else {
            this.sharedService.showToastNotification('Record updated');
          }
          this.cancel();
        }
        this.progressIndicator.hide();
      });
    }
    else {
      this.progressIndicator.hide();
      this.cancel();
      return;
    }
  }

  cancel() {
    this.clearSearch()
    this.clearFormValues();
    this.addToWatchlistButtonText = 'Add to watchlist';
    this.router.navigate(['/watchlist']);
  }

  sectionExpand() {
    this.isSubsidiarySectionCollapsed = !this.isSubsidiarySectionCollapsed;
  }

  clearFormValues() {
    this.watchlistStatusId = 0;
    this.clientWatchlistStatusComment = '';
    this.clientSearchWatchlistmodel = {} as ClientSearchWatchlistResult;
    this.clientWatchlistDto = [];
    this.subsidiaryClientList = [];
    this.isSubsidiaryBlockVisible = false;
    this.alreadyAddedSubsidiaryCount = 0;
    this.addToWatchlistButtonText = 'Add to watchlist';
    this.showPreviousWatchlistStatus = false;
    this.orgWatchlistStatusId = 0;
    this.orgStatusComment = '';
    this.isEdit = false;
    this.showAuditHistoryHeaderMenu(false);
  }

  editWatchlist(clientNumber: string, isParentClient: boolean) {
    this.clearFormValues();
    this.isEdit = true;
    this.progressIndicator.show();
    this.watchlistwebapiservice.searchClients(clientNumber).subscribe(
      (result: ClientSearchWatchlistResult[]) => {
        if (result && result.length > 0) {
          this.clientSearchWatchlistmodel = result[0];
          this.orgWatchlistStatusId = this.clientSearchWatchlistmodel.watchlistStatusId ?? 0;
          this.orgStatusComment = this.clientSearchWatchlistmodel.statusComment ?? '';
          if (this.watchlistStatusSelectItems.length == 0) {
            this.watchlistStatusSelectItems = this.clientSearchWatchlistmodel.watchlistStatusList.map(status => ({
              value: status.value,
              heading: status.heading
            }));
          }
          this.watchlistStatusId = this.clientSearchWatchlistmodel.watchlistStatusId ?? 0;
          this.clientWatchlistStatusComment = this.clientSearchWatchlistmodel.statusComment ?? '';
          this.getSubsidiaryDataByClientnumber();
          this.addToWatchlistButtonText = 'Save';
          if (this.watchlistStatusId > 0) {
            this.showAuditHistoryHeaderMenu(true);
          }
          this.progressIndicator.hide();
        }
      }
    );
  }
  changeClientWatchlistStatus() {
    this.subsidiaryClientList.forEach(subsidiary => {
      if (subsidiary.isInheritedFromParent) {
        subsidiary.watchlistStatusId = this.watchlistStatusId;
        subsidiary.statusComment = this.clientWatchlistStatusComment
        this.onSubsidiaryStatusChange(subsidiary);
      }
    });
    if (this.watchlistStatusId === this.enumWatchlistStatus.Removed) {
      this.clientWatchlistStatusComment = '';
    }
  }

  onWatchlistStatusCommentChange() {
    this.subsidiaryClientList.forEach(subsidiary => {
      if (subsidiary.isInheritedFromParent) {
        subsidiary.watchlistStatusId = this.watchlistStatusId;
        subsidiary.statusComment = this.clientWatchlistStatusComment
      }
    });
  }
  //Apply all Subsidiary status changed for client
  onSubsidiaryStatusChange(client: any) {
    if (client.watchlistStatusId === this.enumWatchlistStatus.Removed) {
      client.statusComment = '';
    }
  }

  goToAuditTrail(clientName: string, clientNumber: string, watchlistId: number | undefined) {
    this.headerService.navigateWatchlistAuditTrial(clientNumber, watchlistId, clientName);
  }

  eraseClientWatchlist() {
    let watchlistEraseList: Array<WatchlistEraseItems> = [];
    this.watchlistItemForErase.watchlistId = this.clientSearchWatchlistmodel.watchlistId;
    this.watchlistItemForErase.clientName = this.clientSearchWatchlistmodel.clientName;
    this.watchlistItemForErase.clientNumber = this.clientSearchWatchlistmodel.clientNumber;
    this.watchlistItemForErase.isParentClient = this.clientSearchWatchlistmodel.isParentClient;
    this.watchlistItemForErase.shortOrder = 1;

    watchlistEraseList.push({ ...this.watchlistItemForErase });
    
    if (this.clientSearchWatchlistmodel.isParentClient == true) {
      // Filter objects with watchlistId > 0 and add them to watchlistEraseList
      this.clientWatchlistDto
        .filter(client => client.watchlistId ?? 0 > 0)
        .forEach(client => {
          watchlistEraseList.push({
            watchlistId: client.watchlistId ?? 0,
            clientName: client.clientName,
            clientNumber: client.clientNumber,
            isParentClient: false,
            shortOrder: 2
          });
        });
    }
    let modalRef = this.modalService.open(EraseClientRecordComponent);
    modalRef.componentInstance.labelValues = {
      labelHeader: 'Clear client record(s) from watchlist',
      labeldisclaimer: 'This action will totally erase the information about the clients from watchlist, and this can\'t be restored',
      watchlistEraseList: watchlistEraseList
    };
  }

  clickclient(event: Event): void {
    this.isshowmorevisible = false;
  }

  clickclientshowmore(event: Event): void {
    this.isshowmorevisible = true;
  }

  clickparentclient(event: Event): void {
    this.isshowmoreparentvisible = false;
  }

  clickparentclientshowmore(event: Event): void {
    this.isshowmoreparentvisible = true;
  }

  clickpriorWatchlist(event: Event): void {
    this.isshowmorevisiblePriorWatchlist = false;
  }

  clickpriorWatchlistshowmore(event: Event): void {
    this.isshowmorevisiblePriorWatchlist = true;
  }

  onShowPreviousWatchlistStatus() {
    this.showPreviousWatchlistStatus = !this.showPreviousWatchlistStatus;
  }

  onShowPreviousWatchlistSubsidiaryStatus(client: ClientWatchlistDto) {
    client.isPreviousStatusShow = !client.isPreviousStatusShow;
  }

  addToWatchlistButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primaryLoud,
    size: Size.lg,
    width: WidthState.fixed,
    disabled: false,
    role: 'button'
  }

  cancelButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: WidthState.fixed,
    ariaLabel: 'Cancel',
    customClass: '',
    role: 'button'
  }

  editButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.md,
    width: WidthState.fixed,
    ariaLabel: 'Cancel',
    customClass: '',
    role: 'button'
  }

  searchOptions: SearchOptions = {
    placeholder: 'Search by name or ID',
    size: Size.md,
    customClass: '',
  };

  clientStatusSelectOptions: SelectOptions = {
    placeholder: 'Select status',
    size: Size.md,

    disabled: false
  };

  textareaOptions: TextareaOptions = {
    placeholder: 'Enter text',
    minHeight: 80,
    maxLength: 4000,
    size: Size.md,
    maxLengthPosition: 'bottom-right',
  };

  optionsShowMore: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.sm,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_arrow-down',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: 'Show Less',
    customClass: '',
    role: 'button'
  }

  options: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.sm,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_arrow-up',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: 'Show Less',
    customClass: '',
    role: 'button'
  }

  multiSelectOptions: MultiSelectOptions = {
    displayTickAllBtn: true,
    label: 'Subsidiary clients',
    placeholder: 'Select Client',
    type: SelectType.counter,
    controlType: SelectControlTypes.checkbox,
    theme: 'green',
    customClass: '',
    disabled: false
  };

  clientTooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 5,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
    tooltipMaxWidth: 300
  };

  dropdownStickerPosition: PositionState = PositionState.bottomRight;
  dropdownOptions: DropdownOptions = {
    label: '',
    ariaLabel: '',
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: WidthState.fixed,
    icon: 'dds-icon_dots-v',
    disabled: false,
    stickerWidth: 155,
    stickerPosition: "bottom-left"
  };

  toggleoptions: ToggleOptions = {
    theme: Themes.green,
    label: '',
    ariaLabel: '',
    disabled: false,
    isError: false,
    isRequired: false,
    customClass: ''
  };

  checkboxoptions: CheckboxOptions = {
    label: '',
    theme: Themes.green,
    isIndeterminate: false,
    isRequired: false,
    ariaLabel: '',
    customClass: ''
  };

  public contextMenuOptions: ContextMenuOptions = {
    label: "Label",
    size: "md",
    theme: "green",
    maxHeight: "",
    ariaLabel: "Context menu",
    customClass: "",
    isResponsive: false,
    isInverse: false
  };

  public toggleSelection(client: ClientWatchlistDto) {
    const targetClient = this.subsidiaryClientList.find(c => c.clientId === client.clientId);
    if (targetClient) {
      targetClient.isChecked = !targetClient.isChecked;
      if (!targetClient.isChecked) {
        targetClient.isInheritedFromParent = false;
        targetClient.watchlistStatusId = null;
        targetClient.statusComment = '';
      }
    }
    this.checkInDertiminateState();
    this.selectedsubsidiarycount = this.updateSubsidiaryCount();
  }

  // For master Checkbox Check change event
  public masterstateChanged() {
    this.isAllChecked = !this.isAllChecked;

    this.subsidiaryClientList.forEach(item => {
      if (item.watchlistId === null) {
        item.isChecked = this.isAllChecked;
      }
      if (!this.isAllChecked && item.watchlistId == null) {
        item.isInheritedFromParent = false;
        item.watchlistStatusId = null;
        item.statusComment = '';
      }
    });
    this.checkInDertiminateState();
  }
  // For Main Checkbox Partially Checked
  public checkInDertiminateState() {
    const totalClients = this.subsidiaryClientList.length;
    const selectedClients = this.subsidiaryClientList.filter(item => item.isChecked).length;

    if (selectedClients === totalClients) {
      this.isAddedCheckboxPartiallyChecked = false;
      this.isAllChecked = true;
    }
    else if (selectedClients == 0) {
      this.isAllChecked = false;
    }
    else {
      this.isAddedCheckboxPartiallyChecked = true;
      this.isAllChecked = false;
    }
  }

  public stateChanged(client: ClientWatchlistDto) {
    const targetClient = this.subsidiaryClientList.find(c => c.clientId === client.clientId);
    if (targetClient) {
      targetClient.isInheritedFromParent = !targetClient.isInheritedFromParent;
      if (targetClient.isInheritedFromParent && targetClient.isChecked) {
        targetClient.watchlistStatusId = this.watchlistStatusId;
        targetClient.statusComment = this.clientWatchlistStatusComment;
      }
      else if (!targetClient.isInheritedFromParent) {
        targetClient.watchlistStatusId = null;
        targetClient.statusComment = '';
      }
    }
  }
  // Count the checked subsidiary client for counter
  public updateSubsidiaryCount(): number {
    return this.subsidiaryClientList.filter(subsidiary => subsidiary.isChecked).length;
  }

  ngOnDestroy(): void {
    this.showAuditHistoryHeaderMenu(false);
  }
}
