import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { SubmissionService } from '../../../../../../http/intake/submission.service';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { Subject, takeUntil } from 'rxjs';
import { IntakeConstant } from '../../../../../constants/intake-constant';

@Component({
  selector: 'app-document-edit-popup',
  templateUrl: './document-edit-popup.component.html',
  styleUrl: './document-edit-popup.component.less',
})
export class DocumentEditPopupComponent implements OnInit {
  @Input() public documentInitiatedDetails: any = {};
  unsubscriber$ = new Subject();

  constructor(
    public modalService: ModalService,
    private intakeDetailService: SubmissionService
  ) {}

  documentTitle: string = this.documentInitiatedDetails.documentTitle;
  documentComment: string = this.documentInitiatedDetails.comments;
  documentTypeId: number = this.documentInitiatedDetails.documentType;
  documentStatusId: number = this.documentInitiatedDetails.documentStatus;
  documentTypeItemsList: SelectItemOptions[] = [];
  documentStatusItemList: SelectItemOptions[] = [];
  allStatusAvailable: SelectItemOptions[] = [];

  ngOnInit(): void {
    this.documentTitle = this.documentInitiatedDetails.documentTitle;
    this.documentComment = this.documentInitiatedDetails.comments;
    this.documentTypeId = this.documentInitiatedDetails.documentTypeId;
    this.documentStatusId = this.documentInitiatedDetails.documentStatusId;
    this.getDocumentTypeDropdownData(
      this.documentInitiatedDetails.submissionTypeId
    );
    this.getDocumentStatusDropdownData(
      this.documentInitiatedDetails.submissionStatusId,
      this.documentInitiatedDetails.submissionTypeId
    );
  }

  getDocumentStatusDropdownData(id: number, typeId:number): void {
    this.intakeDetailService.getDocumentStatusDropdown(id, typeId).subscribe({
      next: (result: any) => {
        const convertedArray = result.map((item: any) => ({
          value: item.documentStatusId,
          heading: item.documentStatusName,
        }));
        this.documentStatusItemList = convertedArray;
        this.allStatusAvailable=convertedArray;
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  getDocumentTypeDropdownData(id: number): void {
    this.intakeDetailService.getDocumentTypeDropdown(id).subscribe({
      next: (result: any) => {
        const convertedArray = result.map((item: any) => ({
          value: item.documentTypeId,
          heading: item.documentTypeName,
        }));
        this.documentTypeItemsList = convertedArray;
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  updateStatusDropdown(): void {
    if (
      this.documentInitiatedDetails.type ===
      IntakeConstant.CHANGE_ORDER_LABEL_CODE
    ) {
      if (
        this.documentTypeId === IntakeConstant.ENGAGEMENT_LETTER_CODE ||
        this.documentTypeId === IntakeConstant.ORIGNAL_Letter_SOW_Contract_CODE ||
        this.documentTypeId === IntakeConstant.ORIGNAL_Letter_CODE
      ) {
        this.documentStatusItemList=this.allStatusAvailable;
      } else {
        this.documentStatusItemList = this.documentStatusItemList.filter(
          (item) => item.heading !== IntakeConstant.FINAL_ARCHIVE_READY_VERSION
        );
        if (this.documentStatusId === IntakeConstant.FINAL_ARCHIVE_READY_ID) {
          this.documentStatusId = 0;
        }
      }
    }
  }

  async editDocument(): Promise<void> {
    const postRequestData = {
      submissionDocumentId: this.documentInitiatedDetails.submissionDocumentId,
      submissionId: this.documentInitiatedDetails.submissionId,
      documentTypeId: this.documentTypeId,
      documentStatusId: this.documentStatusId,
      submissionDocumentTypeId:
        this.documentInitiatedDetails.submissionDocumentTypeId,
      documentTitle: this.documentTitle,
      comments: this.documentComment,
      modifiedBy: '00000000',
    };
    return new Promise((resolve, reject) => {
      this.intakeDetailService
        .postDocumentEdit(postRequestData)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            resolve();
          },
          error: (err) => {
            console.error('An error occurred during document edit:', err);
            reject(err);
          },
        });
    });
  }

  async closeAll(): Promise<void> {
    try {
      await this.editDocument();
    } catch (error) {
      console.log(error);
    }
    this.close();
  }

  close(): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
