import { SelectOptions } from '@usitsdasdesign/dds-ng/select';

import { RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import { ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';

export const RADIO_GROUP_OPTIONS_FOR_STATUS: RadioGroupOptions = {
  name: 'status',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  title: 'Select a status',
  isRequired: true,
  isInverse: false,
};

export const selectOptions: SelectOptions = {
  placeholder: 'Select Reason',
  isResponsive: true,
};

export const textAreaOptions: TextareaOptions = {
  label: ExternalCommunicationConstant.EMPTY_SPACE,
  placeholder: ExternalCommunicationConstant.ENTER_TEXT_PLACEHOLDER,
  minHeight: ExternalCommunicationConstant.TEXTAREA_MIN_HEIGHT,
  isRequired: false,
  isInverse: false,
  readonly: false,
  isError: false,
  errorState: ErrorState.default,
  description: ExternalCommunicationConstant.EMPTY_SPACE,
  customClass: ExternalCommunicationConstant.EMPTY_SPACE,
  errorMessage: ExternalCommunicationConstant.EMPTY_SPACE,
  maxLength: ExternalCommunicationConstant.TEXTAREA_CHAR_LIMIT,
  maxLengthPosition: ExternalCommunicationConstant.TEXTAREA_CHAR_COUNT_POSTION,
  size: ExternalCommunicationConstant.TEXTAREA_SIZE,
};

