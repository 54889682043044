<div
  *ngIf="!isOpportunitySelected"
  class="main-container"
  [autosave-module]="commonSectionFormModule"
>
  <progress-indicator></progress-indicator>
  <div class="title-container">
    <h4 class="eclipse-main-tab-header text-align-left">{{ titleValue }}</h4>
    <dds-button
      *ngIf="isSubmitBtnVisible()"
      class="custom-button"
      [theme]="submitButtonOptions.theme ?? ''"
      [kind]="submitButtonOptions.kind ?? ''"
      [size]="submitButtonOptions.size ?? ''"
      [width]="submitButtonOptions.width ?? ''"
      [icon]="submitButtonOptions.icon ?? ''"
      [isIconLeft]="submitButtonOptions.isIconLeft ?? false"
      [role]="submitButtonOptions.role ?? ''"
      [isInverse]="submitButtonOptions.isInverse ?? false"
      [isColorBg]="submitButtonOptions.isColorBg ?? false"
      [isLoading]="submitButtonOptions.isLoading ?? false"
      [ariaLabel]="submitButtonOptions.ariaLabel ?? ''"
      [customClass]="submitButtonOptions.customClass ?? ''"
      [disabled]="updateSubmitButtonStatus()"
      (clicked)="submitCommonSectionData()"
      ><span>{{ submitLabel }}</span></dds-button
    >
    <span>
      <dds-button
        *ngIf="isArchiveAndCompleteBtnVisible()"
        class="custom-button margin-left-xs margin-bottom-xs"
        [theme]="archiveAndCompleteBtnOption.theme ?? ''"
        [kind]="archiveAndCompleteBtnOption.kind ?? ''"
        [size]="archiveAndCompleteBtnOption.size ?? ''"
        [width]="archiveAndCompleteBtnOption.width ?? ''"
        [icon]="archiveAndCompleteBtnOption.icon ?? ''"
        [isIconLeft]="archiveAndCompleteBtnOption.isIconLeft ?? false"
        [role]="archiveAndCompleteBtnOption.role ?? ''"
        [isInverse]="archiveAndCompleteBtnOption.isInverse ?? false"
        [isColorBg]="archiveAndCompleteBtnOption.isColorBg ?? false"
        [isLoading]="archiveAndCompleteBtnOption.isLoading ?? false"
        [ariaLabel]="archiveAndCompleteBtnOption.ariaLabel ?? ''"
        [customClass]="archiveAndCompleteBtnOption.customClass ?? ''"
        [disabled]="isArchiveAndCompleteBtnDisabled"
        (clicked)="onclickArchiveAndCompleteBtn()"
        ><span>{{ archiveAndCompleteBtnLabel }}</span></dds-button
      >

      <dds-button
        *ngIf="isSubmitForReviewBtnVisible()"
        class="custom-button margin-left-xs"
        [theme]="resubmitForReviewBtnOption.theme ?? ''"
        [kind]="resubmitForReviewBtnOption.kind ?? ''"
        [size]="resubmitForReviewBtnOption.size ?? ''"
        [width]="resubmitForReviewBtnOption.width ?? ''"
        [icon]="resubmitForReviewBtnOption.icon ?? ''"
        [isIconLeft]="resubmitForReviewBtnOption.isIconLeft ?? false"
        [role]="resubmitForReviewBtnOption.role ?? ''"
        [isInverse]="resubmitForReviewBtnOption.isInverse ?? false"
        [isColorBg]="resubmitForReviewBtnOption.isColorBg ?? false"
        [isLoading]="resubmitForReviewBtnOption.isLoading ?? false"
        [ariaLabel]="resubmitForReviewBtnOption.ariaLabel ?? ''"
        [customClass]="resubmitForReviewBtnOption.customClass ?? ''"
        [disabled]="isResubmitForReviewBtnDisabled"
        (clicked)="onclickResubmitForReviewBtn()"
        ><span>{{ reSubmitForReviewLabel }}</span></dds-button
      >
    </span>
    <!-- TODO: Showing and hiding of the buttons to be implemented once we have the employee id -->
    <div class="d-flex" *ngIf="submissionStatus === 'Submitted'">
      <dds-button class="custom-button padding-right-xs"
        >Complete review</dds-button
      >
      <dds-button class="custom-button" [kind]="reworkButton.kind ?? ''"
        >Return for rework</dds-button
      >
    </div>
  </div>
  <div class="sub-header">
    <div class="submission-type font-size-12 font-bold">
      {{ submissionTypeCode }}
    </div>
    <div
      class="submission-status font-size-12 font-bold {{
        submissionStatusClass
      }}"
    >
      {{ submissionStatus }}
    </div>
    <div class="id-container eclipse-content">
      <span class="id-title">ID:</span>
      <span #textToCopy class="eclipse-content">{{ submissionId }}</span>
      <span
        class="dds-icon dds-icon_copy__l__stroke icon"
        (click)="copyToClipboard(textToCopy.textContent)"
      ></span>
    </div>
  </div>
  <div *ngIf="coHasParentOpportunityNumber" class="search-elsow-section">
    <app-search-elsow></app-search-elsow>
  </div>
  <div class="section-ending"></div>
  <div>
    <dds-accordion [isMulti]="true">
      <dds-accordion-item [isOpen]="true" *ngIf="isCloseOutAccordionVisible()"
        ><!--TODO :  need to add this -->
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Closeout form</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="row">
              <div class="styled-box d-flex">
                <span>Complete the closeout form</span>
              </div>
            </div>
            <div class="row">
              <ng-container
                *ngIf="
                  submissionTypeCode === intakeDocumentSubmissionTypeCodePro
                "
              >
                <app-close-out-proposal
                  (winningProposalChange)="
                    onChangeWinningProposalInCloseOut($event)
                  "
                  [winningProposal]="
                    model.winningProposal ? model.winningProposal : ''
                  "
                ></app-close-out-proposal>
              </ng-container>
              <ng-container
                *ngIf="
                  submissionTypeCode === intakeDocumentSubmissionTypeCodeCO ||
                  submissionTypeCode === intakeDocumentSubmissionTypeCodeELSOW
                "
              >
                <app-close-out-change-order
                  (coAndElSowEventEmitter)="onChangeCoAndElSowCloseOut($event)"
                  [contractSignedExecuted]="model.contractSignedExecuted ?? ''"
                  [chargeCodeWBS]="model.chargeCodeWBS ?? ''"
                  [numberOfOpenArchieve]="model.numberOfOpenArchieve ?? null"
                  [wbsDescription]="model.wbsDescription ?? ''"
                ></app-close-out-change-order>
              </ng-container>
              <ng-container
                *ngIf="
                  submissionTypeCode ===
                  intakeDocumentSubmissionTypeCodeDelivery
                "
              >
                <app-close-out-deliverable
                  (deliverableChange)="onChangeDeliverableInCloseOut($event)"
                  [deliverable]="
                    model.UpdatedVersionForReview
                      ? model.UpdatedVersionForReview
                      : ''
                  "
                ></app-close-out-deliverable>
              </ng-container>
              <ng-container
                *ngIf="
                  submissionTypeCode === intakeDocumentSubmissionTypeCodeOther
                "
              >
                <app-close-out-other
                  (otherChange)="onChangeOtherInCloseOut($event)"
                  [other]="otherSubmissionValueSelected"
                ></app-close-out-other>
              </ng-container>
            </div></div
        ></span>
      </dds-accordion-item>

      <dds-accordion-item
        [isOpen]="isCOAccordionOpen()"
        *ngIf="isAccordionVisible(intakeDocumentSubmissionTypeCodeCO)"
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Change Order</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div>
              <div class="row">
                <div>
                  <span
                    *ngIf="!model.coChangeScope"
                    class="icon-required-yellow"
                  ></span>
                  <span>{{ COChangeScope_radioGroupOptions.title }}</span>
                </div>

                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row padding-top-xs">
                      <div class="col-12">
                        <dds-radio-group
                          autosave
                          [disabled]="
                            submissionStatus ===
                            IntakeDocumentSubmissionStatusSubmitted
                          "
                          [(ngModel)]="model.coChangeScope"
                          [name]="COChangeScope_radioGroupOptions.name"
                          [isUncheckable]="
                            COChangeScope_radioGroupOptions.isUncheckable
                          "
                          [isRequired]="
                            COChangeScope_radioGroupOptions.isRequired
                          "
                          [errorState]="
                            COChangeScope_radioGroupOptions.errorState
                          "
                          (stateChanged)="COChangeScopeStateChanged()"
                        >
                          <ng-container
                            *ngFor="let radio of COChangeScope_radio_values"
                          >
                            <dds-radio
                              [value]="radio.value"
                              [customClass]="'padding-right-half-rem'"
                              [label]="radio.options.label"
                              [theme]="radio.options.theme"
                            ></dds-radio>
                            <br />
                          </ng-container>
                        </dds-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div>
                  <span
                    *ngIf="!model.coDescription"
                    class="icon-required-yellow"
                  ></span>
                  <span>Provide Change Order description</span>
                </div>

                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row padding-top-xs">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="coDescription"
                          [disabled]="
                            submissionStatus ===
                            IntakeDocumentSubmissionStatusSubmitted
                          "
                          [(ngModel)]="model.coDescription"
                          [label]="textareaOptions.label"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="textareaOptions.placeholder"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="textareaOptions.maxLength"
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          [readonly]="textareaOptions.readonly"
                          (valueChanged)="CODescriptionValueChanged()"
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
      <dds-accordion-item [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Self-review eligibility</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="accordion-container">
              <div>
                <div class="comments-text-label eclipse-small-header-grey">
                  Eligibile for self-review
                </div>
                <div class="value-font">{{ selfReviewEligibility }}</div>
              </div>
              <div>
                <div class="comments-text-label eclipse-small-header-grey">
                  Submission title
                </div>
                <div class="input-container" (click)="$event.stopPropagation()">
                  <ng-container *ngIf="isEditingTitle; else displayTitle">
                    <div class="col-12">
                      <dds-input
                        autosave
                        [placeholder]="enter_text_placeholder"
                        [(ngModel)]="model.submissionTitle"
                        id="title-editor"
                      >
                      </dds-input>
                    </div>
                  </ng-container>
                  <ng-template #displayTitle>
                    <span class="title-value value-font">{{ titleValue }}</span>
                    <span
                      class="dds-icon dds-icon_edit__s__filled edit-icon"
                      (click)="editTitle()"
                    ></span>
                  </ng-template>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>

      <dds-accordion-item
        *ngIf="isAccordionVisible(intakeDocumentSubmissionTypeCodeOther)"
        [isOpen]="isOtherAccordionOpen()"
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Other</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="row">
              <div>
                <span
                  *ngIf="!model.otherDescription"
                  class="icon-required-yellow"
                ></span>
                <span>Submission description</span>
              </div>

              <div class="col-12">
                <div class="textarea-container">
                  <div class="row padding-top-xs">
                    <div class="col-12">
                      <dds-textarea
                        autosave
                        name="otherDescription"
                        [(ngModel)]="model.otherDescription"
                        [label]="textareaOptions.label"
                        [labelPosition]="textareaOptions.labelPosition"
                        [placeholder]="textareaOptions.placeholder"
                        [description]="textareaOptions.description"
                        [minHeight]="textareaOptions.minHeight"
                        [isRequired]="textareaOptions.isRequired"
                        [customClass]="textareaOptions.customClass"
                        [isError]="textareaOptions.isError"
                        [errorMessage]="textareaOptions.errorMessage"
                        [errorState]="textareaOptions.errorState"
                        [maxLength]="textareaOptions.maxLength"
                        [maxLengthPosition]="textareaOptions.maxLengthPosition"
                        [size]="textareaOptions.size"
                        [readonly]="textareaOptions.readonly"
                        (valueChanged)="OtherDescriptionValueChanged()"
                      ></dds-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div>
                <span
                  *ngIf="!model.submissionSubTypeId"
                  class="icon-required-yellow"
                ></span>
                <span>{{ other_subtype_radioGroupOptions.title }}</span>
              </div>
              <div class="col-12">
                <div class="textarea-container">
                  <div class="row padding-top-xs">
                    <div
                      class="col-12"
                      *ngIf="otherSubTypes$ | async; let subTypes"
                    >
                      <dds-radio-group
                        autosave
                        [(ngModel)]="model.submissionSubTypeId"
                        [name]="other_subtype_radioGroupOptions.name"
                        [isUncheckable]="
                          other_subtype_radioGroupOptions.isUncheckable
                        "
                      >
                        <ng-container *ngFor="let subType of subTypes">
                          <dds-radio
                            [value]="subType.subTypeId.toString()"
                            [customClass]="'padding-right-half-rem'"
                            [label]="subType.subTypeName"
                            [theme]="RADIO_THEME_COLOR"
                          ></dds-radio>
                          <br />
                        </ng-container>
                      </dds-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>

      <dds-accordion-item
        *ngIf="isAccordionVisible(intakeDocumentSubmissionTypeCodePro)"
        [isOpen]="isProposalAccordionOpen()"
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Proposal</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="row">
              <div class="styled-box d-flex">
                <span>Fill in the required submission details</span>
              </div>
              <div>
                <span
                  *ngIf="!model.proposalDescription"
                  class="icon-required-yellow"
                ></span>
                <span>Describe Proposal</span>
              </div>

              <div class="col-12">
                <div class="textarea-container">
                  <div class="row padding-top-xs">
                    <div class="col-12">
                      <dds-textarea
                        autosave
                        name="proposalDescription"
                        [(ngModel)]="model.proposalDescription"
                        [label]="textareaOptions.label"
                        [labelPosition]="textareaOptions.labelPosition"
                        [placeholder]="textareaOptions.placeholder"
                        [description]="textareaOptions.description"
                        [minHeight]="textareaOptions.minHeight"
                        [isRequired]="textareaOptions.isRequired"
                        [customClass]="textareaOptions.customClass"
                        [isError]="textareaOptions.isError"
                        [errorMessage]="textareaOptions.errorMessage"
                        [errorState]="textareaOptions.errorState"
                        [maxLength]="textareaOptions.maxLength"
                        [maxLengthPosition]="textareaOptions.maxLengthPosition"
                        [size]="textareaOptions.size"
                        [readonly]="textareaOptions.readonly"
                        (valueChanged)="proposalDescriptionValueChanged()"
                      ></dds-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div>
                <span
                  *ngIf="!model.relatedToRFP"
                  class="icon-required-yellow"
                ></span>
                <span>{{ RFP_radioGroupOptions.title }}</span>
              </div>

              <div class="col-12">
                <div class="textarea-container">
                  <div class="row padding-top-xs">
                    <div class="col-12">
                      <dds-radio-group
                        autosave
                        [(ngModel)]="model.relatedToRFP"
                        [name]="RFP_radioGroupOptions.name"
                        [isUncheckable]="RFP_radioGroupOptions.isUncheckable"
                        [isRequired]="RFP_radioGroupOptions.isRequired"
                        [errorState]="RFP_radioGroupOptions.errorState"
                        (stateChanged)="RelatedtoRFPstateChanged()"
                      >
                        <ng-container *ngFor="let radio of REP_radio_values">
                          <dds-radio
                            [value]="radio.value"
                            [label]="radio.options.label"
                            [theme]="radio.options.theme"
                          ></dds-radio>
                          <br />
                        </ng-container>
                      </dds-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
      <dds-accordion-item
        [isOpen]="isDeliverableAccordionOpen()"
        *ngIf="isAccordionVisible(intakeDocumentSubmissionTypeCodeDelivery)"
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Deliverable</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="styled-box d-flex">
              <span>Fill in the required submission details</span>
            </div>

            <div>
              <span
                *ngIf="!model.delDescription"
                class="icon-required-yellow"
              ></span>
              <span>Provide deliverable description</span>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="textarea-container">
                  <div class="row padding-top-xs">
                    <div class="col-12">
                      <dds-textarea
                        autosave
                        name="delDescription"
                        [(ngModel)]="model.delDescription"
                        [label]="textareaOptions.label"
                        [labelPosition]="textareaOptions.labelPosition"
                        [placeholder]="textareaOptions.placeholder"
                        [description]="textareaOptions.description"
                        [minHeight]="textareaOptions.minHeight"
                        [isRequired]="textareaOptions.isRequired"
                        [customClass]="textareaOptions.customClass"
                        [isError]="textareaOptions.isError"
                        [errorMessage]="textareaOptions.errorMessage"
                        [errorState]="textareaOptions.errorState"
                        [maxLength]="textareaOptions.maxLength"
                        [maxLengthPosition]="textareaOptions.maxLengthPosition"
                        [size]="textareaOptions.size"
                        [readonly]="textareaOptions.readonly"
                        (valueChanged)="deliverableDescValueChanged()"
                      ></dds-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
      <dds-accordion-item [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Documents</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>

        <span content>
          <div class="accordion-content">
            <div
              *ngIf="
                submissionTypeCode === intakeDocumentSubmissionTypeCodeCO ||
                submissionTypeCode === intakeDocumentSubmissionTypeCodeELSOW
              "
            >
              <div
                class="styled-box"
                *ngIf="showUploadGuidanceYellowMsgForCoAndElSowOnYes"
              >
                <span
                  >Upload the executed version of the Engagement
                  Letter/Statement of Work or Change Order (i.e., the final
                  document).</span
                >
              </div>
              <div
                class="styled-box padding-top-s"
                *ngIf="showUploadGuidanceInfoMsgForCoAndElSowOnYes"
              >
                <span
                  >❇️ All archive ready documents will be moved to Archive It
                  after submission completion.</span
                >
              </div>

              <div
                class="styled-box padding-top-s"
                *ngIf="showInfoMsgForCoElSowOnSelectNoResubmit"
              >
                <span
                  >Upload the updated document that requires additional Risk
                  Manager review</span
                >
              </div>
            </div>

            <div
              *ngIf="
                isDocumentRequireMsgShow &&
                submissionTypeCode === intakeDocumentSubmissionTypeCodePro
              "
            >
              <div class="styled-box" *ngIf="isFinalDocumentAcceptedMsgShow">
                <span
                  >Upload the final document presented to/accepted by the
                  client</span
                >
              </div>
              <div class="styled-box padding-top-s">
                <span
                  >❇️ All archive ready documents will be moved to Archive It
                  after submission completion.</span
                >
              </div>
            </div>

            <div
              *ngIf="
                showUploadFinalVersionMessage &&
                (submissionTypeCode != intakeDocumentSubmissionTypeCodeCO ||
                  submissionTypeCode != intakeDocumentSubmissionTypeCodeELSOW)
              "
            >
              <div class="styled-box">
                <span>Upload the final version of the document</span>
              </div>
              <div class="styled-box padding-top-s">
                <span
                  >❇️ All archive ready documents will be moved to Archive It
                  after submission completion.</span
                >
              </div>
            </div>

            <div *ngIf="showInformationalGuidanceForDeliverable">
              <div class="styled-box">
                <span
                  >Upload the final document presented to/accepted by the
                  client.</span
                >
              </div>
              <div class="styled-box padding-top-s">
                <span
                  >❇️ All archive ready documents will be transferred to Archive
                  It once the submission status is updated to completed</span
                >
              </div>
            </div>

            <div *ngIf="showUpdatedDocumentMessage">
              <div class="styled-box">
                <span
                  >Upload the updated document that requires additional Risk
                  Manager review</span
                >
              </div>
            </div>

            <div
              *ngIf="showLepConfirmationMessage; else showLEPMessage"
              class="styled-box d-flex"
            >
              <span>
                Confirm the LEP
                {{
                  this.dataLepValue.lepName !== null
                    ? this.dataLepValue.lepName
                    : ""
                }}, has completed their review of the document(s), prior to
                uploading and submitting for review
              </span>
              <dds-button class="button" (clicked)="handleLEPCompletion()"
                >Confirm LEP review completion</dds-button
              >
            </div>
            <ng-template #showLEPMessage>
              <div>
                <div *ngIf="!isValidDocumentUploaded" class="styled-box">
                  <span>{{ configLableDocumet }} </span>
                </div>
                <div class="comments-text-label eclipse-small-header-grey">
                  LEP review:
                </div>
                <div class="col-12 padding-top-xs">
                  <p class="margin-bottom-none">
                    Acknowledged by {{ lepAcknowledgedBy }}:
                    {{ lepAcknowledgementDate }},
                  </p>
                  <p>
                    confirming that {{ lepReviewedBy }} has reviewed the
                    document.
                  </p>
                </div>
              </div>
            </ng-template>
            <div *ngIf="!showLepConfirmationMessage" class="">
              <app-document
                [submissionTypeId]="submissionTypeId"
                [submissionStatusId]="submissionStatusId"
                [submissionId]="submissionId"
                [progressiveStatus]="progressiveStatus"
                [submissionTypeCode]="submissionTypeCode"
                [submissionStatus]="submissionStatus"
                [type]="submissionTypeCode"
                (documentTableData)="handleDocumentTableData($event)"
              ></app-document>
            </div>
            <div
              class="row"
              *ngIf="
                isAccordionVisible(intakeDocumentSubmissionTypeCodeDelivery)
              "
            >
              <div class="col-12">
                <div class="sub-text-section">
                  <span class="icon-s icon-pinned"></span>
                  <span>
                    <div class="text-label font-bold-600">
                      The LEP is ultimately responsible for the quality and risk
                      assessment regarding the deliverable.
                    </div>
                    <div
                      class="vertical-line-green"
                      *ngIf="isShowMoreDocumentNotes"
                    ></div>
                  </span>
                </div>
                <div class="padding-left-s">
                  <span>
                    <div
                      class="margin-top-xs text-gray"
                      *ngIf="isShowMoreDocumentNotes"
                    >
                      <div class="padding-bottom-s">
                        Deliverables requiring Risk Manager review may be
                        submitted.
                      </div>
                      <ul>
                        <li class="padding-bottom-s">
                          <div class="submission-type font-bold-600">
                            Sufficient documentation:
                          </div>
                          Prepare and maintain sufficient documentation to
                          support the results or information in the report or
                          deliverables.
                        </li>
                        <li class="padding-bottom-s">
                          <div class="submission-type font-bold-600">
                            Primary and overriding reviews:
                          </div>
                          Conduct reviews of supporting documentation before
                          submitting deliverables for Risk Manager review.
                        </li>
                        <li class="padding-bottom-s">
                          <div class="submission-type font-bold-600">
                            Deliverable modification consultation:
                          </div>
                          Consult with the Risk Manager if: the client or their
                          advisor disputes our findings, or The client requests
                          changes that could soften or diminish our findings.
                        </li>
                      </ul>
                    </div>
                  </span>
                  <dds-button
                    class="showMoreCls"
                    [theme]="showMoreOptions.theme ?? ''"
                    [kind]="showMoreOptions.kind ?? ''"
                    [size]="showMoreOptions.size ?? ''"
                    [width]="showMoreOptions.width ?? ''"
                    [icon]="showMoreOptions.icon ?? ''"
                    [isIconLeft]="showMoreOptions.isIconLeft ?? false"
                    [role]="showMoreOptions.role ?? ''"
                    [ariaLabel]="showMoreOptions.ariaLabel ?? ''"
                    [customClass]="showMoreOptions.customClass ?? ''"
                    (clicked)="clickToShowDocumentMoreLepMessage()"
                    >{{ showMoreText }}</dds-button
                  >
                </div>
              </div>
            </div>
          </div>
        </span>
      </dds-accordion-item>
      <dds-accordion-item [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Notes/Feedback</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="notes-paragraph eclipse-content-grey">
              Use this field to (i) provide notes about any relevant history,
              unusual aspects, or unique characteristics of the engagement that
              reviewers should be aware of or (ii) view feedback from reviewers
              on the document
            </div>
            <div>
              <div class="row">
                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="notesAndFeedback"
                          [(ngModel)]="model.notesAndFeedback"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="enter_text_placeholder"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="
                            internalQrmCommunicationTextareaMaxlength
                          "
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          [readonly]="textareaOptions.readonly"
                          (valueChanged)="feedbackInputValueChanged()"
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
      <dds-accordion-item [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Workflow</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content relative">
            <div class="div-headers">
              <table class="dds-data-table dds-data-table_selection">
                <thead class="dds-data-table__header">
                  <tr>
                    <th class="dds-data-table__header-cell">Required</th>
                    <th
                      *ngFor="let column of riskManagerTableHeader"
                      class="dds-data-table__header-cell"
                      [ngStyle]="{
                        width: column.width,
                        minWidth: column.minWidth
                      }"
                    >
                      <div class="dds-data-table__content">
                        <span>{{ column.header || column.name }}</span>
                      </div>
                    </th>
                    <th class="dds-data-table__header-cell">
                      <span [hidden]="!showActionsHeader">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="dds-data-table__body">
                  <tr
                    *ngFor="
                      let row of updatedRiskManagerDetails;
                      index as index
                    "
                    class="dds-data-table__row"
                  >
                    <td class="dds-data-table__cell table-checkbox-cell">
                      <dds-checkbox
                        *ngIf="
                          selectedRiskManagers.length === 1;
                          else showAllCheckboxes
                        "
                        [ariaLabel]="'row ' + (index + 1)"
                        [value]="row.isRequired ? row.isRequired : false"
                        theme="green"
                        [disabled]="selectedRiskManagers.includes(row.emailId)"
                        (click)="toggleRow(row.emailId)"
                      ></dds-checkbox>

                      <ng-template #showAllCheckboxes>
                        <dds-checkbox
                          [ariaLabel]="'row ' + (index + 1)"
                          [value]="row.isRequired ? row.isRequired : false"
                          theme="green"
                          (click)="toggleRow(row.emailId)"
                        ></dds-checkbox>
                      </ng-template>
                    </td>
                    <td class="dds-data-table__cell" *ngIf="!row.isEditable">
                      <div>
                        <span
                          class="comments-text-label eclipse-small-header-grey"
                          >{{ row.roleName }}
                        </span>
                      </div>
                      <div>
                        <span class="profile-name">{{ row.displayName }}</span>
                      </div>
                    </td>
                    <td class="dds-data-table__cell" *ngIf="row.isEditable">
                      <ng-container
                        *ngIf="isEditingReviewer(row); else displayOther"
                      >
                        <app-people-picker
                          [isTechinalReviewerSearch]="false"
                          (userSelected)="
                            onMemberSelected(
                              row.emailId,
                              row.reviewerId,
                              $event
                            )
                          "
                        ></app-people-picker>
                      </ng-container>
                      <ng-template #displayOther>
                        <ng-container
                          *ngIf="
                            isEditingTechnicalReviewer(row);
                            else displayDetails
                          "
                        >
                          <app-people-picker
                            [isTechinalReviewerSearch]="true"
                            (userSelected)="
                              onMemberSelected(
                                row.emailId,
                                row.reviewerId,
                                $event
                              )
                            "
                          ></app-people-picker>
                        </ng-container>

                        <ng-template #displayDetails>
                          <span
                            *ngIf="row.roleName !== additionalReviewer"
                            class="comments-text-label eclipse-small-header-grey"
                            >{{ row.roleName }}</span
                          >
                          <span
                            *ngIf="row.roleName == additionalReviewer"
                            class="comments-text-label eclipse-small-header-grey"
                            >{{ reviewerText }}</span
                          ><br />
                          <span class="profile-name">{{
                            row.displayName
                          }}</span>
                          <span
                            class="dds-icon dds-icon_edit__s__filled edit-icon"
                            (click)="editReviewer(row.emailId)"
                          ></span>
                          <span class="margin-left-s dds-icon dds-icon_delete__l__stroke" (click)="clearReviewer(row.emailId, row.isForTechnicalReviewer)"></span>
                        </ng-template>
                      </ng-template>
                    </td>
                    <td class="dds-data-table__cell">
                      <!-- 
                      TODO: Once Employee ID is implemented this needs to be checked
                      <div
                        *ngIf="
                          loggedInEmployeeId === row.reviewerId &&
                          submissionStatus === 'Submitted' &&
                          row.statusReviewedDate === null
                        "
                        class="d-flex"
                      > -->
                      <div
                        *ngIf="
                          submissionStatus === 'Submitted' &&
                          row.statusReviewedDate === null
                        "
                        class="d-flex"
                      >
                        <dds-button
                          class="custom-button padding-right-xs"
                          (clicked)="handleCompleteReview(row)"
                          >Complete review</dds-button
                        >

                        <dds-button
                          class="custom-button"
                          [kind]="reworkButton.kind ?? ''"
                          (clicked)="handleReturnforRework(row)"
                          >Return for rework</dds-button
                        >
                      </div>
                      <div *ngIf="row.statusReviewedDate !== null">
                        <div class="flex-row-col">
                          <p class="text-green-2">Review Completed</p>
                          <p>{{ row.statusReviewedDate }}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!-- TODO: *ngIf="isEmployeePresent" once employee id is integrated -->
                <dds-button
                  [theme]="options.theme ?? ''"
                  [kind]="options.kind ?? ''"
                  [size]="options.size ?? ''"
                  [width]="options.width ?? ''"
                  [icon]="options.icon ?? ''"
                  [isIconLeft]="options.isIconLeft ?? false"
                  [role]="options.role ?? ''"
                  [isInverse]="options.isInverse ?? false"
                  [isColorBg]="options.isColorBg ?? false"
                  [isLoading]="options.isLoading ?? false"
                  [ariaLabel]="options.ariaLabel ?? ''"
                  [customClass]="options.customClass ?? ''"
                  [disabled]="!isAllReviewerValid"
                  (clicked)="addReviewer(roleAdditionalReviewer)"
                  >Add Reviewer</dds-button
                >
              </table>
            </div>
          </div></span
        >
      </dds-accordion-item>
      <dds-accordion-item *ngIf="isVisible" [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header margin-bottom-s">
            <span class="eclipse-section-header"
              >Internal QRM Team communication</span
            >
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div>
              <div class="row">
                <div class="col-12">
                  <div
                    class="text-label eclipse-content-grey padding-bottom-s padding-left-xs"
                  >
                    Viewable by QRM team only, purged 1 year after "Complete"
                    status
                  </div>
                  <div class="textarea-container">
                    <div class="row">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="internalQRMTeamCommunication"
                          [(ngModel)]="model.internalQRMTeamCommunication"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="'Enter your comments'"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="
                            internalQrmCommunicationTextareaMaxlength
                          "
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          [readonly]="textareaOptions.readonly"
                          (valueChanged)="
                            internalQrmCommunicationInputValueChanged()
                          "
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
    </dds-accordion>
  </div>
</div>
