import {
  Component,
  Input,
  input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { StatusStylePipe } from '../../../status-style-pipe/status-style.pipe';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { IntakeClientSummaryModel } from '../../../../common/models/intake-client-summary.model';
import { StatusEnum } from '../../../enum/status-enum';
@Component({
  selector: 'app-intake-client',
  templateUrl: './intake-client.component.html',
  styleUrl: './intake-client.component.less',
})
export class IntakeClientComponent implements OnInit {
  clientSummaryData!: IntakeClientSummaryModel;
  unsubscriber$ = new Subject<void>();
  constructor(private readonly intakeService: SubmissionService) {}
  @Input() clientNumber: string = '';
  clientnumberData: string = '';
  ngOnInit(): void {
    this.clientnumberData = this.clientNumber;
    this.intakeService
      .getIntakeClientSummary(this.clientnumberData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: IntakeClientSummaryModel) => {
          // TODO remove when client Status is not null
          if(!response.clientStatus){
            response.clientStatus='NCA Approved (Continuance Requirements up to date)'
          }
          this.clientSummaryData = response;
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  transformStatus(status: string): StatusEnum {
    return (Object.values(StatusEnum).find((enumValue) => enumValue === status) || StatusEnum.Draft);
  }
  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
