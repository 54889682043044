import { DaterangepickerOptions } from '@usitsdasdesign/dds-ng/datepicker';
import { Filter, FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { ItemsPerPageOptions } from '@usitsdasdesign/dds-ng/items-per-page';
import { PagerOptions } from '@usitsdasdesign/dds-ng/pager';
import { Mode, PaginationOptions } from '@usitsdasdesign/dds-ng/pagination';
import {
  Column,
  PositionState,
  Size,
  Themes,
} from '@usitsdasdesign/dds-ng/shared';
import { StickerOptions } from '@usitsdasdesign/dds-ng/sticker';

export const auditTrailTableColumns: Column[] = [
  {
    name: 'Entity',
    header: 'Entity',
    dataType: 'string',
    width: '9.875rem',
  },
  {
    name: 'EventType',
    header: 'Event type',
    dataType: 'string',
    width: '9.875rem',
  },
  {
    name: 'LogDateTime',
    header: 'Log date/Time',
    dataType: 'date',
    width: '9.875rem',
  },
  {
    name: 'ModifiedBy',
    header: 'Name',
    dataType: 'string',
    width: '9.875rem',
  },
  {
    name: 'Description',
    header: 'Description',
    dataType: 'string',
    width: '47.5rem',
  },
];

export const sampleData: ExampleData[] = [
  {
    name: 'Cyber Risk Management',
    hours: 800,
    clientServiceFees: 200,
    plannedExpenses: 100,
    billableExpenses: 300,
  },
  {
    name: 'Forensic Discovery',
    hours: 300,
    clientServiceFees: 600,
    plannedExpenses: 400,
    billableExpenses: 200,
  },
  {
    name: 'ERP Implementation',
    hours: 600,
    clientServiceFees: 500,
    plannedExpenses: 400,
    billableExpenses: 500,
  },
  {
    name: 'Future of Mobility',
    hours: 400,
    clientServiceFees: 700,
    plannedExpenses: 200,
    billableExpenses: 800,
  },
  {
    name: 'Accounting Standards',
    hours: 400,
    clientServiceFees: 500,
    plannedExpenses: 200,
    billableExpenses: 600,
  },
  {
    name: 'Forensic Discovery Advisory',
    hours: 400,
    clientServiceFees: 700,
    plannedExpenses: 200,
    billableExpenses: 800,
  },
  {
    name: 'ERP Upgrade and Extension',
    hours: 400,
    clientServiceFees: 500,
    plannedExpenses: 200,
    billableExpenses: 600,
  },
  {
    name: 'Tax Reporting and Compliance',
    hours: 900,
    clientServiceFees: 800,
    plannedExpenses: 700,
    billableExpenses: 900,
  },
  {
    name: 'Tax Strategy',
    hours: 1900,
    clientServiceFees: 800,
    plannedExpenses: 700,
    billableExpenses: 600,
  },
  {
    name: 'Performance Management',
    hours: 900,
    clientServiceFees: 800,
    plannedExpenses: 700,
    billableExpenses: 600,
  },
  {
    name: 'Cyber Risk Management',
    hours: 800,
    clientServiceFees: 200,
    plannedExpenses: 100,
    billableExpenses: 300,
  },
];

export const filterData: FilterItem[] = [
  {
    title: 'Cyber Risk Management',
    name: 'Cyber Risk Management',
    hours: 800,
  },
  {
    title: 'Forensic Discovery',
    name: 'Forensic Discovery',
    hours: 300,
  },
  {
    title: 'ERP Implementation',
    name: 'ERP Implementation',
    hours: 600,
  },
  {
    title: 'Future of Mobility',
    name: 'Future of Mobility',
    hours: 400,
  },
  {
    title: 'Accounting Standards',
    name: 'Accounting Standards',
    hours: 400,
  },
  {
    title: 'Forensic Discovery Advisory',
    name: 'Forensic Discovery Advisory',
    hours: 400,
  },
  {
    title: 'ERP Upgrade and Extension',
    name: 'ERP Upgrade and Extension',
    hours: 400,
  },
];

export const filterOptions: Filter = {
  theme: Themes.green,
  isInverse: false,
  propName: 'name',
  itemPropNameValue: 'value',
};

export const paging: PagerOptions = {
  label: 'Go to page',
  size: Size.md,
  disabled: false,
  minValue: 1,
  maxValue: 5,
  customClass: '',
};

export const paginationOptions: PaginationOptions = {
  pageLength: 999,
  mode: Mode.text,
  pageNumberInSection: 5,
  size: 'md',
  theme: Themes.dark,
  disabled: false,
  isInverse: false,
  ariaLabel: 'pagination',
  customClass: '',
  isIndefinitePageLength: false,
  isResponsive: false,
};

export const itemsPerPageOptions: ItemsPerPageOptions = {
  title: 'Items per page',
  placeholder: 'Placeholder',
  size: Size.md,
  disabled: false,
  isResponsive: false,
  isInverse: false,
  icon: 'dds-icon_arrow-down',
  customClass: '',
  stickerWidth: 0,
  stickerShift: 0,
  stickerMaxHeight: '',
  stickerIsDisabled: false,
  stickerPosition: 'bottom-left',
  stickerIndent: 0,
  stickerCustomClass: '',
  stickerIsDynamic: true,
};

export const dateRangePickerOptions: DaterangepickerOptions = {
  size: Size.md,
  placeholder: ['MM/DD/YYYY', 'MM/DD/YYYY'],
  format: 'MM/dd/yyyy',
  isManualInput: false,
  stickerPosition: PositionState.bottomLeft,
};

export const filterStickerOptions: StickerOptions = {
  stickerPosition: PositionState.bottomRight,
  stickerIsOutsideClick: true,
  stickerIndent: -1,
  stickerWidth: 280,
};

//TODO: To be updated once you have original data
export interface ExampleData {
  [key: string]: any;
}
