<div
  *ngIf="!isOpportunitySelected"
  class="main-container"
  [autosave-module]="commonSectionFormModule"
>
  <progress-indicator></progress-indicator>
  <div class="title-container align-items-center padding-top-xs padding-bottom-xs position-top-0">
    <h4
      class="eclipse-main-tab-header text-align-left"
      *ngIf="
        isPermissionPresent(
          permissionEnums.Intake_Submission_Title,
          permissionType.Visible
        )
      "
    >
      {{ titleValue }}
    </h4>
    <span>
      <ng-container class="d-flex no-break" *ngIf="isSelfReviewButtonVisible">
        <div class="no-break">
          <dds-button
            class="custom-button margin-left-xs margin-bottom-xs"
            [theme]="selfReviewBtnOption.theme ?? ''"
            [kind]="selfReviewBtnOption.kind ?? ''"
            [size]="selfReviewBtnOption.size ?? ''"
            [width]="selfReviewBtnOption.width ?? ''"
            [icon]="selfReviewBtnOption.icon ?? ''"
            [isIconLeft]="selfReviewBtnOption.isIconLeft ?? false"
            [role]="selfReviewBtnOption.role ?? ''"
            [isInverse]="selfReviewBtnOption.isInverse ?? false"
            [isColorBg]="selfReviewBtnOption.isColorBg ?? false"
            [isLoading]="selfReviewBtnOption.isLoading ?? false"
            [ariaLabel]="archiveAndCompleteBtnLabel"
            [customClass]="selfReviewBtnOption.customClass ?? ''"
            [disabled]="updateSubmitButtonStatus()"
            (clicked)="onclickSelfReviewBtn()"
            ><span>{{ selfReviewBtnLabel }}</span></dds-button
          >
          <dds-button
            class="custom-button margin-left-xs"
            [theme]="optOutOfSelfReviewBtnOption.theme ?? ''"
            [kind]="optOutOfSelfReviewBtnOption.kind ?? ''"
            [size]="optOutOfSelfReviewBtnOption.size ?? ''"
            [width]="optOutOfSelfReviewBtnOption.width ?? ''"
            [icon]="optOutOfSelfReviewBtnOption.icon ?? ''"
            [isIconLeft]="optOutOfSelfReviewBtnOption.isIconLeft ?? false"
            [role]="optOutOfSelfReviewBtnOption.role ?? ''"
            [isInverse]="optOutOfSelfReviewBtnOption.isInverse ?? false"
            [isColorBg]="optOutOfSelfReviewBtnOption.isColorBg ?? false"
            [isLoading]="optOutOfSelfReviewBtnOption.isLoading ?? false"
            [ariaLabel]="optOutOfSelfReviewBtnLabel"
            [customClass]="optOutOfSelfReviewBtnOption.customClass ?? ''"
            [disabled]="updateSubmitButtonStatus()"
            (clicked)="onclickOptOutOfSelfReviewBtn()"
            ><span>{{ optOutOfSelfReviewBtnLabel }}</span></dds-button
          >
        </div>
      </ng-container>
      <dds-button
        *ngIf="isSubmitBtnVisible()"
        class="custom-button"
        [theme]="submitButtonOptions.theme ?? ''"
        [kind]="submitButtonOptions.kind ?? ''"
        [size]="submitButtonOptions.size ?? ''"
        [width]="submitButtonOptions.width ?? ''"
        [icon]="submitButtonOptions.icon ?? ''"
        [isIconLeft]="submitButtonOptions.isIconLeft ?? false"
        [role]="submitButtonOptions.role ?? ''"
        [isInverse]="submitButtonOptions.isInverse ?? false"
        [isColorBg]="submitButtonOptions.isColorBg ?? false"
        [isLoading]="submitButtonOptions.isLoading ?? false"
        [ariaLabel]="submitLabel"
        [customClass]="submitButtonOptions.customClass ?? ''"
        [disabled]="
          !isPermissionPresent(
            permissionEnums.Intake_Submission_SubmitBtn,
            permissionType.Enable
          ) || updateSubmitButtonStatus()
        "
        (clicked)="submitCommonSectionData(docStatusSubmitted)"
        ><span>{{ submitLabel }}</span></dds-button
      >

      <div class="no-break">
        <dds-button
          *ngIf="isArchiveAndCompleteBtnVisible()"
          class="custom-button margin-left-xs margin-bottom-xs"
          [theme]="archiveAndCompleteBtnOption.theme ?? ''"
          [kind]="archiveAndCompleteBtnOption.kind ?? ''"
          [size]="archiveAndCompleteBtnOption.size ?? ''"
          [width]="archiveAndCompleteBtnOption.width ?? ''"
          [icon]="archiveAndCompleteBtnOption.icon ?? ''"
          [isIconLeft]="archiveAndCompleteBtnOption.isIconLeft ?? false"
          [role]="archiveAndCompleteBtnOption.role ?? ''"
          [isInverse]="archiveAndCompleteBtnOption.isInverse ?? false"
          [isColorBg]="archiveAndCompleteBtnOption.isColorBg ?? false"
          [isLoading]="archiveAndCompleteBtnOption.isLoading ?? false"
          [ariaLabel]="archiveAndCompleteBtnLabel"
          [customClass]="archiveAndCompleteBtnOption.customClass ?? ''"
          [disabled]="
            !this.isPermissionPresent(
              this.permissionEnums.Intake_Submission_ArchiveAndCompleteBtn,
              this.permissionType.Enable
            ) || isArchiveAndCompleteBtnDisabled
          "
          (clicked)="onclickArchiveAndCompleteBtn()"
          ><span>{{ archiveAndCompleteBtnLabel }}</span></dds-button
        >

        <!--TODO: Resubmit for review and resubmit call different function but does the same thing.
         Need to fix-->
        <dds-button
          *ngIf="isSubmitForReviewBtnVisible()"
          class="custom-button margin-left-xs"
          [theme]="resubmitForReviewBtnOption.theme ?? ''"
          [kind]="resubmitForReviewBtnOption.kind ?? ''"
          [size]="resubmitForReviewBtnOption.size ?? ''"
          [width]="resubmitForReviewBtnOption.width ?? ''"
          [icon]="resubmitForReviewBtnOption.icon ?? ''"
          [isIconLeft]="resubmitForReviewBtnOption.isIconLeft ?? false"
          [role]="resubmitForReviewBtnOption.role ?? ''"
          [isInverse]="resubmitForReviewBtnOption.isInverse ?? false"
          [isColorBg]="resubmitForReviewBtnOption.isColorBg ?? false"
          [isLoading]="resubmitForReviewBtnOption.isLoading ?? false"
          [ariaLabel]="reSubmitForReviewLabel"
          [customClass]="resubmitForReviewBtnOption.customClass ?? ''"
          [disabled]="
            !this.isPermissionPresent(
              this.permissionEnums.Intake_Submission_ReSubmitBtn,
              this.permissionType.Enable
            ) || isResubmitForReviewBtnDisabled
          "
          (clicked)="onclickResubmitForReviewBtn()"
          ><span>{{ reSubmitForReviewLabel }}</span></dds-button
        >
      </div>
      <dds-button
        *ngIf="isResubmitBtnVisible() && !isSelfReviewButtonVisible"
        class="custom-button"
        [theme]="submitButtonOptions.theme ?? ''"
        [kind]="submitButtonOptions.kind ?? ''"
        [size]="submitButtonOptions.size ?? ''"
        [width]="submitButtonOptions.width ?? ''"
        [icon]="submitButtonOptions.icon ?? ''"
        [isIconLeft]="submitButtonOptions.isIconLeft ?? false"
        [role]="submitButtonOptions.role ?? ''"
        [isInverse]="submitButtonOptions.isInverse ?? false"
        [isColorBg]="submitButtonOptions.isColorBg ?? false"
        [isLoading]="submitButtonOptions.isLoading ?? false"
        [ariaLabel]="'Resubmit'"
        [customClass]="submitButtonOptions.customClass ?? ''"
        [disabled]="
          !this.isPermissionPresent(
            this.permissionEnums.Intake_Submission_ReSubmitBtn,
            this.permissionType.Visible
          ) || updateSubmitButtonStatus()
        "
        (clicked)="resubmitCommonSectionData()"
        ><span>Resubmit</span></dds-button
      >
    </span>
    <!-- TODO: Showing and hiding of the buttons to be implemented once we have the employee id -->
    <div class="d-flex no-break" *ngIf="showReviewAndReturnBtn">
      <dds-button
        class="custom-button padding-right-xs"
        (clicked)="handleCompleteReviewFromHeader()"
        >Complete review</dds-button
      >
      <dds-button
        *ngIf="
          isPermissionPresent(
            permissionEnums.Intake_Submission_ReturnForReworkBtn,
            permissionType.Enable
          )
        "
        class="custom-button"
        [kind]="reworkButton.kind ?? ''"
        (clicked)="handleReturnForReworkFromHeader()"
        >Return for rework</dds-button
      >
    </div>
  </div>
  <div class="sub-header">
    <div
      *ngIf="
        isPermissionPresent(
          permissionEnums.Intake_Submission_SubmissionType,
          permissionType.Visible
        )
      "
      class="submission-type font-size-12 font-bold-600 line-height-s"
    >
      {{ submissionTypeCode }}
    </div>
    <div
      *ngIf="
        isPermissionPresent(
          permissionEnums.Intake_Submission_SubmissionStatus,
          permissionType.Visible
        )
      "
      [style.color]="
        transformStatusStyle(submissionStatus) | statusStyle : 'color'
      "
      [style.backgroundColor]="
        transformStatusStyle(submissionStatus) | statusStyle : 'backgroundColor'
      "
      class="custom-pad margin-left-xxs font-size-12 font-bold-600 line-height-s"
    >
      {{ submissionStatus }}
    </div>
    <div
      class="id-container eclipse-content"
      *ngIf="
        isPermissionPresent(
          permissionEnums.Intake_Submission_SubmissionId,
          permissionType.Visible
        )
      "
    >
      <span class="id-title">ID:</span>
      <span #textToCopy class="eclipse-content">{{ submissionId }}</span>
      <span
        class="dds-icon dds-icon_copy__l__stroke icon relative"
        (click)="copyToClipboard(textToCopy.textContent)"
      ></span>
    </div>
  </div>
  <div
    *ngIf="
      coHasParentOpportunityNumber &&
      isPermissionPresent(
        permissionEnums.Intake_Submission_ELSOWOpportunityID,
        permissionType.Visible
      )
    "
    class="search-elsow-section"
  >
    <app-search-elsow></app-search-elsow>
  </div>
  <div class="section-ending"></div>
  <div>
    <dds-accordion [isMulti]="true">
      <dds-accordion-item [isOpen]="true" *ngIf="isCloseOutAccordionVisible()">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Closeout form</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="row">
              <ng-container
                *ngIf="
                  submissionTypeCode === intakeDocumentSubmissionTypeCodePro
                "
              >
                <app-close-out-proposal
                  (winningProposalChange)="
                    onChangeWinningProposalInCloseOut($event, 0)
                  "
                  [winningProposal]="model.winningProposal ?? ''"
                ></app-close-out-proposal>
              </ng-container>
              <ng-container
                *ngIf="
                  submissionTypeCode === intakeDocumentSubmissionTypeCodeCO ||
                  submissionTypeCode === intakeDocumentSubmissionTypeCodeELSOW
                "
              >
                <app-close-out-change-order
                  *ngIf="showCloseOutForm"
                  (coAndElSowWithWbsArchiveEventEmitter)="
                    onChangeCoAndElSowWithWbsArchiveCloseOut($event, 0)
                  "
                  [contractSignedExecuted]="model.contractSignedExecuted ?? ''"
                  [wbsChargeCode]="model.wbsChargeCode ?? ''"
                  [numberOfOpenArchieve]="model.numberOfOpenArchieve ?? null"
                  [wbsDescription]="model.wbsDescription ?? ''"
                  [dataSubmissionId]="submissionId"
                  [dataOpportunityId]="dataOpportunityId"
                  [archiveDetails]="archiveDetails"
                  [swiftDetails]="swiftDetails"
                  [submissionData]="submissionData"
                ></app-close-out-change-order>
              </ng-container>
              <ng-container
                *ngIf="
                  submissionTypeCode ===
                  intakeDocumentSubmissionTypeCodeDelivery
                "
              >
                <app-close-out-deliverable
                  (deliverableWithWbsArchiveChange)="
                    onChangeDeliverableWithWbsArchiveCloseOut($event, 0)
                  "
                  [deliverable]="
                    model.updatedVersionForReview
                      ? model.updatedVersionForReview
                      : ''
                  "
                  [wbsChargeCode]="model.wbsChargeCode ?? ''"
                  [wbsDescription]="model.wbsDescription ?? ''"
                  [dataSubmissionId]="submissionId"
                  [dataOpportunityId]="dataOpportunityId"
                  [archiveDetails]="archiveDetails"
                  [swiftDetails]="swiftDetails"
                  [submissionData]="submissionData"
                ></app-close-out-deliverable>
              </ng-container>
              <ng-container
                *ngIf="
                  submissionTypeCode === intakeDocumentSubmissionTypeCodeOther
                "
              >
                <app-close-out-other
                  (otherWithWbsArchiveValueChange)="
                    onChangeOtherWithWbsArchiveInCloseOut($event, 0)
                  "
                  [other]="otherSubmissionValueSelected"
                  [wbsChargeCode]="model.wbsChargeCode ?? ''"
                  [wbsDescription]="model.wbsDescription ?? ''"
                  [dataSubmissionId]="submissionId"
                  [dataOpportunityId]="dataOpportunityId"
                  [archiveDetails]="archiveDetails"
                  [swiftDetails]="swiftDetails"
                  [submissionData]="submissionData"
                ></app-close-out-other>
              </ng-container>
            </div></div
        ></span>
      </dds-accordion-item>

      <dds-accordion-item
        [isOpen]="isCOAccordionOpen()"
        *ngIf="
          isAccordionVisible(intakeDocumentSubmissionTypeCodeCO) &&
          isPermissionPresent(
            permissionEnums.Intake_Submission_ChangeOrder,
            permissionType.Visible
          )
        "
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Change Order</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div>
              <div class="row">
                <div>
                  <span
                    *ngIf="!model.coChangeScope"
                    class="icon-required-yellow"
                  ></span>
                  <span>{{ COChangeScope_radioGroupOptions.title }}</span>
                </div>

                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row padding-top-xs">
                      <div class="col-12">
                        <dds-radio-group
                          autosave
                          [disabled]="
                            isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                            !isPermissionPresent(
                              permissionEnums.Intake_Submission_ChangeOrder,
                              permissionType.Enable
                            )
                          "
                          [(ngModel)]="model.coChangeScope"
                          [name]="COChangeScope_radioGroupOptions.name"
                          [isUncheckable]="
                            COChangeScope_radioGroupOptions.isUncheckable
                          "
                          [isRequired]="
                            COChangeScope_radioGroupOptions.isRequired
                          "
                          [errorState]="
                            COChangeScope_radioGroupOptions.errorState
                          "
                          (stateChanged)="stateHandling()"
                        >
                          <div class="d-flex">
                            <ng-container
                              *ngFor="let radio of COChangeScope_radio_values"
                            >
                              <dds-radio
                                [value]="radio.value"
                                [customClass]="
                                  'padding-right-half-rem padding-right-vs'
                                "
                                [label]="radio.options.label"
                                [theme]="radio.options.theme"
                              ></dds-radio>
                              <br />
                            </ng-container>
                          </div>
                        </dds-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div>
                  <span
                    *ngIf="!model.coDescription && !disableFieldOnComplete()"
                    class="icon-required-yellow"
                  ></span>
                  <span>Provide Change Order description</span>
                </div>

                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row padding-top-xs">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="coDescription"
                          [(ngModel)]="model.coDescription"
                          [label]="textareaOptions.label"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="textareaOptions.placeholder"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="textareaOptions.maxLength"
                          [readonly]="
                            isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                            !isPermissionPresent(
                              permissionEnums.Intake_Submission_ChangeOrder,
                              permissionType.Enable
                            )
                          "
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          (valueChanged)="CODescriptionValueChanged()"
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
      <dds-accordion-item [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Self-review eligibility</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="accordion-container">
              <div
                class="self-review-div"
                *ngIf="
                  isPermissionPresent(
                    permissionEnums.Intake_Submission_EligibleForSelfReview,
                    permissionType.Visible
                  )
                "
              >
                <div class="comments-text-label eclipse-small-header-grey">
                  Eligibile for self-review
                </div>
                <div class="value-font">{{ selfReviewEligibility }}</div>
              </div>
              <div
                class="submission-title-container self-review-div"
                *ngIf="
                  isPermissionPresent(
                    permissionEnums.Intake_Submission_SubmissionTitle,
                    permissionType.Visible
                  )
                "
              >
                <div class="comments-text-label eclipse-small-header-grey">
                  Submission title
                </div>
                <div class="input-container" (click)="$event.stopPropagation()">
                  <ng-container *ngIf="isEditingTitle; else displayTitle">
                    <div class="col-12">
                      <dds-input
                        autosave
                        [placeholder]="enter_text_placeholder"
                        [(ngModel)]="model.submissionTitle"
                        id="title-editor"
                      >
                      </dds-input>
                    </div>
                  </ng-container>
                  <ng-template #displayTitle>
                    <span class="title-value value-font">{{ titleValue }}</span>
                    <span
                      *ngIf="
                        !isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() &&
                        isPermissionPresent(
                          permissionEnums.Intake_Submission_SubmissionTitle,
                          permissionType.Enable
                        )
                      "
                      class="dds-icon dds-icon_edit__s__filled edit-icon"
                      (click)="editTitle()"
                    ></span>
                  </ng-template>
                </div>
              </div>
              <div *ngIf="optOutOfSelfReviewReason" class="self-review-div">
                <div class="comments-text-label eclipse-small-header-grey">
                  Reason for opt-out self-review:
                </div>
                <div>
                  {{ optOutOfSelfReviewReason }}
                </div>
              </div>
            </div>
          </div></span
        >
      </dds-accordion-item>

      <dds-accordion-item
        *ngIf="isAccordionVisible(intakeDocumentSubmissionTypeCodeOther)"
        [isOpen]="
          isOtherAccordionOpen() &&
          isPermissionPresent(
            permissionEnums.Intake_Submission_Other,
            permissionType.Visible
          )
        "
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Other</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="row">
              <div>
                <span
                  *ngIf="!model.otherDescription"
                  class="icon-required-yellow"
                ></span>
                <span>Submission description</span>
              </div>

              <div class="col-12">
                <div class="textarea-container">
                  <div class="row padding-top-xs">
                    <div class="col-12">
                      <dds-textarea
                        autosave
                        name="otherDescription"
                        [disabled]="disableFieldOnComplete()"
                        [(ngModel)]="model.otherDescription"
                        [label]="textareaOptions.label"
                        [labelPosition]="textareaOptions.labelPosition"
                        [placeholder]="textareaOptions.placeholder"
                        [description]="textareaOptions.description"
                        [minHeight]="textareaOptions.minHeight"
                        [isRequired]="textareaOptions.isRequired"
                        [customClass]="textareaOptions.customClass"
                        [isError]="textareaOptions.isError"
                        [errorMessage]="textareaOptions.errorMessage"
                        [errorState]="textareaOptions.errorState"
                        [maxLength]="textareaOptions.maxLength"
                        [maxLengthPosition]="textareaOptions.maxLengthPosition"
                        [size]="textareaOptions.size"
                        [readonly]="
                          isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                          !isPermissionPresent(
                            permissionEnums.Intake_Submission_Other,
                            permissionType.Enable
                          )
                        "
                        (valueChanged)="OtherDescriptionValueChanged()"
                      ></dds-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="others-subtype">
              <div>
                <span
                  *ngIf="
                    !model.submissionSubTypeId && !disableFieldOnComplete()
                  "
                  class="icon-required-yellow"
                ></span>
                <span class="eclipse-small-header-grey">{{
                  other_subtype_radioGroupOptions.title
                }}</span>
              </div>
              <div class="value-font" *ngIf="otherSubTypes$ | async">
                {{ getSubmissionSubType(model.submissionSubTypeId) }}
              </div>
            </div>
          </div></span
        >
      </dds-accordion-item>

      <dds-accordion-item
        *ngIf="
          isAccordionVisible(intakeDocumentSubmissionTypeCodePro) &&
          isPermissionPresent(
            permissionEnums.Intake_Submission_Proposal,
            permissionType.Visible
          )
        "
        [isOpen]="isProposalAccordionOpen()"
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Proposal</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="row">
              <div>
                <span
                  *ngIf="
                    !model.proposalDescription && !disableFieldOnComplete()
                  "
                  class="icon-required-yellow"
                ></span>
                <span>Describe Proposal</span>
              </div>

              <div class="col-12">
                <div class="textarea-container">
                  <div class="row padding-top-xs">
                    <div class="col-12">
                      <dds-textarea
                        autosave
                        name="proposalDescription"
                        [(ngModel)]="model.proposalDescription"
                        [label]="textareaOptions.label"
                        [labelPosition]="textareaOptions.labelPosition"
                        [placeholder]="textareaOptions.placeholder"
                        [description]="textareaOptions.description"
                        [minHeight]="textareaOptions.minHeight"
                        [isRequired]="textareaOptions.isRequired"
                        [customClass]="textareaOptions.customClass"
                        [isError]="textareaOptions.isError"
                        [errorMessage]="textareaOptions.errorMessage"
                        [errorState]="textareaOptions.errorState"
                        [maxLength]="textareaOptions.maxLength"
                        [maxLengthPosition]="textareaOptions.maxLengthPosition"
                        [size]="textareaOptions.size"
                        [readonly]="
                          isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                          !isPermissionPresent(
                            permissionEnums.Intake_Submission_Proposal,
                            permissionType.Enable
                          )
                        "
                        (valueChanged)="proposalDescriptionValueChanged()"
                      ></dds-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div>
                <span
                  *ngIf="!model.relatedToRFP && !disableFieldOnComplete()"
                  class="icon-required-yellow"
                ></span>
                <span>{{ RFP_radioGroupOptions.title }}</span>
              </div>

              <div class="col-12">
                <div class="textarea-container">
                  <div class="row padding-top-xs">
                    <div class="col-12">
                      <dds-radio-group
                        autosave
                        [disabled]="disableFieldOnComplete()"
                        [(ngModel)]="model.relatedToRFP"
                        [name]="RFP_radioGroupOptions.name"
                        [isUncheckable]="RFP_radioGroupOptions.isUncheckable"
                        [isRequired]="RFP_radioGroupOptions.isRequired"
                        [errorState]="RFP_radioGroupOptions.errorState"
                        (stateChanged)="RelatedtoRFPstateChanged()"
                        [disabled]="
                          isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                          !isPermissionPresent(
                            permissionEnums.Intake_Submission_Proposal,
                            permissionType.Enable
                          )
                        "
                      >
                        <ng-container *ngFor="let radio of REP_radio_values">
                          <dds-radio
                            [value]="radio.value"
                            [label]="radio.options.label"
                            [theme]="radio.options.theme"
                          ></dds-radio>
                          <br />
                        </ng-container>
                      </dds-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
      <dds-accordion-item
        [isOpen]="isDeliverableAccordionOpen()"
        *ngIf="
          isAccordionVisible(intakeDocumentSubmissionTypeCodeDelivery) &&
          isPermissionPresent(
            permissionEnums.Intake_Submission_Deliverable,
            permissionType.Visible
          )
        "
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Deliverable</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="styled-box d-flex">
              <span>Fill in the required submission details</span>
            </div>

            <div>
              <span
                *ngIf="!model.delDescription && !disableFieldOnComplete()"
                class="icon-required-yellow"
              ></span>
              <span>Provide deliverable description</span>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="textarea-container">
                  <div class="row padding-top-xs">
                    <div class="col-12">
                      <dds-textarea
                        autosave
                        name="delDescription"
                        [disabled]="disableFieldOnComplete()"
                        [(ngModel)]="model.delDescription"
                        [label]="textareaOptions.label"
                        [labelPosition]="textareaOptions.labelPosition"
                        [placeholder]="textareaOptions.placeholder"
                        [description]="textareaOptions.description"
                        [minHeight]="textareaOptions.minHeight"
                        [isRequired]="textareaOptions.isRequired"
                        [customClass]="textareaOptions.customClass"
                        [isError]="textareaOptions.isError"
                        [errorMessage]="textareaOptions.errorMessage"
                        [errorState]="textareaOptions.errorState"
                        [maxLength]="textareaOptions.maxLength"
                        [maxLengthPosition]="textareaOptions.maxLengthPosition"
                        [size]="textareaOptions.size"
                        [readonly]="
                          isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                          !isPermissionPresent(
                            permissionEnums.Intake_Submission_Deliverable,
                            permissionType.Enable
                          )
                        "
                        (valueChanged)="deliverableDescValueChanged()"
                      ></dds-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
      <dds-accordion-item [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Documents</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>

        <span content>
          <div class="accordion-content">
            <div *ngIf="isSelfReviewButtonVisible" class="styled-box-green">
              <img src="\assets\images\green-pin-icon.svg" alt="lock-icon" />
              {{ selfReviewDocBanner }}
            </div>
            <div
              *ngIf="
                submissionTypeCode === intakeDocumentSubmissionTypeCodeCO ||
                submissionTypeCode === intakeDocumentSubmissionTypeCodeELSOW
              "
            >
              <div
                class="styled-box"
                *ngIf="showUploadGuidanceYellowMsgForCoAndElSowOnYes"
              >
                <span
                  >Upload the executed version of the Engagement
                  Letter/Statement of Work or Change Order (i.e., the final
                  document).</span
                >
              </div>
              <div
                class="margin-bottom-xs"
                *ngIf="showUploadGuidanceInfoMsgForCoAndElSowOnYes"
              >
                <span class="eclipse-icon icon-s icon-bullet-star-green"></span>
                <span>
                  All archive ready documents will be moved to Archive It after
                  submission completion.</span
                >
              </div>

              <div
                class="styled-box padding-top-s"
                *ngIf="showInfoMsgForCoElSowOnSelectNoResubmit"
              >
                <span
                  >Upload the updated document that requires additional Risk
                  Manager review</span
                >
              </div>
            </div>

            <div
              *ngIf="
                isDocumentRequireMsgShow &&
                submissionTypeCode === intakeDocumentSubmissionTypeCodePro
              "
            >
              <div class="styled-box" *ngIf="isFinalDocumentAcceptedMsgShow">
                <span
                  >Upload the final document presented to/accepted by the
                  client</span
                >
              </div>
              <div class="styled-box padding-top-s">
                <span class="eclipse-icon icon-s icon-bullet-star-green"></span>
                <span>
                  All archive ready documents will be moved to Archive It after
                  submission completion.</span
                >
              </div>
            </div>

            <div
              *ngIf="
                showUploadFinalVersionMessage &&
                (submissionTypeCode != intakeDocumentSubmissionTypeCodeCO ||
                  submissionTypeCode != intakeDocumentSubmissionTypeCodeELSOW)
              "
            >
              <div class="styled-box">
                <span>Upload the final version of the document</span>
              </div>
              <div class="styled-box padding-top-s">
                <span class="eclipse-icon icon-s icon-bullet-star-green"></span>
                <span>
                  All archive ready documents will be moved to Archive It after
                  submission completion.</span
                >
              </div>
            </div>

            <div *ngIf="showInformationalGuidanceForDeliverable">
              <div class="styled-box">
                <span
                  >Upload the final document presented to/accepted by the
                  client.</span
                >
              </div>
              <div class="styled-box padding-top-s">
                <span class="eclipse-icon icon-s icon-bullet-star-green"></span>
                <span>
                  All archive ready documents will be transferred to Archive It
                  once the submission status is updated to completed</span
                >
              </div>
            </div>

            <div *ngIf="showUpdatedDocumentMessage">
              <div class="styled-box">
                <span
                  >Upload the updated document that requires additional Risk
                  Manager review</span
                >
              </div>
            </div>

            <ng-container
              *ngIf="
                isPermissionPresent(
                  permissionEnums.Intake_Submission_LEPConfirmation,
                  permissionType.Visible
                )
              "
            >
              <div
                *ngIf="showLepConfirmationMessage; else showLEPMessage"
                class="styled-box d-flex"
              >
                <span class="lep-message-padding">
                  Confirm the LEP
                  {{
                    this.dataLepValue.lepName !== null
                      ? this.dataLepValue.lepName
                      : ""
                  }}, has completed their review of the document(s), prior to
                  uploading and submitting for review
                </span>
                <dds-button class="button" (clicked)="handleLEPCompletion()"
                  >Confirm LEP review completion</dds-button
                >
              </div>
            </ng-container>
            <ng-template #showLEPMessage>
              <div>
                <div *ngIf="!isValidDocumentUploaded" class="styled-box">
                  <span>{{ configLableDocumet }} </span>
                </div>
                <div class="comments-text-label eclipse-small-header-grey">
                  LEP review:
                </div>
                <div class="col-12">
                  <p class="margin-bottom-none">
                    Acknowledged by {{ lepAcknowledgedBy }}:
                    {{ lepAcknowledgementDate }},
                  </p>
                  <p>
                    confirming that {{ lepReviewedBy }} has reviewed the
                    document.
                  </p>
                </div>
              </div>
            </ng-template>
            <div *ngIf="!showLepConfirmationMessage">
              <app-document
                [submissionTypeId]="submissionTypeId"
                [submissionStatusId]="submissionStatusId"
                [submissionId]="submissionId"
                [progressiveStatus]="progressiveStatus"
                [submissionTypeCode]="submissionTypeCode"
                [submissionStatus]="submissionStatus"
                [type]="submissionTypeCode"
                [isDisabledDocuments]="isStatusCompleted() || isDisContinued()"
                (documentTableData)="handleDocumentTableData($event)"
                [isDraftForSelfReview]="isSelfReviewButtonVisible"
              ></app-document>
            </div>
            <div
              class="row"
              *ngIf="
                isAccordionVisible(intakeDocumentSubmissionTypeCodeDelivery)
              "
            >
              <div class="col-12">
                <div class="sub-text-section relative">
                  <span class="icon-s icon-pinned"></span>
                  <span>
                    <div class="text-label font-bold-600">
                      The LEP is ultimately responsible for the quality and risk
                      assessment regarding the deliverable.
                    </div>
                    <div
                      class="vertical-line-green absolute"
                      *ngIf="isShowMoreDocumentNotes"
                    ></div>
                  </span>
                </div>
                <div class="padding-left-s">
                  <span>
                    <div
                      class="margin-top-xs text-gray"
                      *ngIf="isShowMoreDocumentNotes"
                    >
                      <div class="padding-bottom-s">
                        Deliverables requiring Risk Manager review may be
                        submitted.
                      </div>
                      <ul>
                        <li class="padding-bottom-s">
                          <div
                            class="submission-type font-size-12 font-bold-600 line-height-s"
                          >
                            Sufficient documentation:
                          </div>
                          Prepare and maintain sufficient documentation to
                          support the results or information in the report or
                          deliverables.
                        </li>
                        <li class="padding-bottom-s">
                          <div
                            class="submission-type font-size-12 font-bold-600 line-height-s"
                          >
                            Primary and overriding reviews:
                          </div>
                          Conduct reviews of supporting documentation before
                          submitting deliverables for Risk Manager review.
                        </li>
                        <li class="padding-bottom-s">
                          <div
                            class="submission-type font-size-12 font-bold-600 line-height-s"
                          >
                            Deliverable modification consultation:
                          </div>
                          Consult with the Risk Manager if: the client or their
                          advisor disputes our findings, or The client requests
                          changes that could soften or diminish our findings.
                        </li>
                      </ul>
                    </div>
                  </span>
                  <dds-button
                    class="showMoreCls"
                    [theme]="showMoreOptions.theme ?? ''"
                    [kind]="showMoreOptions.kind ?? ''"
                    [size]="showMoreOptions.size ?? ''"
                    [width]="showMoreOptions.width ?? ''"
                    [icon]="showMoreOptions.icon ?? ''"
                    [isIconLeft]="showMoreOptions.isIconLeft ?? false"
                    [role]="showMoreOptions.role ?? ''"
                    [ariaLabel]="showMoreOptions.ariaLabel ?? ''"
                    [customClass]="showMoreOptions.customClass ?? ''"
                    (clicked)="clickToShowDocumentMoreLepMessage()"
                    >{{ showMoreText }}</dds-button
                  >
                </div>
              </div>
            </div>
          </div>
        </span>
      </dds-accordion-item>
      <dds-accordion-item [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Notes/Feedback</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div class="notes-paragraph eclipse-content-grey">
              Use this field to (i) provide notes about any relevant history,
              unusual aspects, or unique characteristics of the engagement that
              reviewers should be aware of or (ii) view feedback from reviewers
              on the document
            </div>
            <span
              *ngIf="checkStatusCompletedByAdmin() || checkStatusDiscontinued()"
              class="padding-bottom-m"
            >
              <div class="sub-text-section margin-top-xs">
                <span class="icon-s icon-alert"></span>
                <span>
                  <div
                    *ngIf="checkStatusCompletedByAdmin()"
                    class="eclipse-header-grey"
                  >
                    Submission admin closed
                  </div>
                  <div
                    *ngIf="checkStatusDiscontinued()"
                    class="eclipse-header-grey"
                  >
                    Submission Discontinued
                  </div>
                  <div
                    class="vertical-line-green-s absolute padding-bottom-xxs"
                    *ngIf="isShowMoreDocumentNotes"
                  ></div>
                </span>
              </div>
              <div class="padding-left-m">
                <span>
                  <div class="margin-top-xs" *ngIf="isShowMoreDocumentNotes">
                    <div *ngFor="let history of submissionActionHistory">
                      <div
                        class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                      >
                        {{ history.resetedOn }} by {{ history.resetedBy }}
                      </div>
                      <div
                        class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                      >
                        Reason: {{ history.reason }}
                      </div>
                      <div
                        *ngIf="checkStatusCompletedByAdmin()"
                        class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                      >
                        Additional details: {{ history.additionalDetails }}
                      </div>
                    </div>
                  </div>
                </span>
                <dds-button
                  class="showMoreCls"
                  [theme]="showMoreOptions.theme ?? ''"
                  [kind]="showMoreOptions.kind ?? ''"
                  [size]="showMoreOptions.size ?? ''"
                  [width]="showMoreOptions.width ?? ''"
                  [icon]="showMoreOptions.icon ?? ''"
                  [isIconLeft]="showMoreOptions.isIconLeft ?? false"
                  [role]="showMoreOptions.role ?? ''"
                  [ariaLabel]="showMoreText"
                  [customClass]="showMoreOptions.customClass ?? ''"
                  (clicked)="clickToShowDocumentMoreLepMessage()"
                  >{{ showMoreText }}</dds-button
                >
              </div>
            </span>
            <span *ngIf="showResetActionMessage" class="padding-bottom-m">
              <div>
                <span>
                  <div class="margin-top-xs">
                    <div>
                      <div
                        class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                      >
                        <div>
                          Submission reset from
                          {{ resetActionMessage?.previousStatus }} to
                          {{ resetActionMessage?.currentStatus }} on
                          {{ resetActionMessage?.resetedOn }} by
                          {{ resetActionMessage?.resetedBy }}.
                        </div>
                        <div>
                          <span>Reason: {{ resetActionMessage?.reason }}</span>
                          <span
                            *ngIf="resetActionMessage?.otherReasonDescription"
                            class="padding-left-xs"
                            >{{
                              resetActionMessage?.otherReasonDescription
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div></span
                >
              </div>
            </span>
            <div>
              <div class="row">
                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="notesAndFeedback"
                          [(ngModel)]="model.notesAndFeedback"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="enter_text_placeholder"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="
                            internalQrmCommunicationTextareaMaxlength
                          "
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          [readonly]="
                            isStatusCompleted() ||
                            !isPermissionPresent(
                              permissionEnums.Intake_Submission_NotesAndFeedback,
                              permissionType.Enable
                            )
                          "
                          (valueChanged)="feedbackInputValueChanged()"
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
      <dds-accordion-item [isOpen]="!isSelfReviewButtonVisible">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Workflow</span>
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content relative">
            <div class="div-headers">
              <table class="dds-data-table dds-data-table_selection">
                <thead class="dds-data-table__header">
                  <tr>
                    <th
                      class="dds-data-table__header-cell"
                      *ngIf="
                        !isStatusCompleted() &&
                        !isDisContinued() &&
                        isPermissionPresent(
                          permissionEnums.Intake_Submission_WorkflowCheckbox,
                          permissionType.Visible
                        )
                      "
                    >
                      Required
                    </th>
                    <th
                      *ngFor="let column of riskManagerTableHeader"
                      class="dds-data-table__header-cell"
                      [ngStyle]="{
                        width: column.width,
                        minWidth: column.minWidth
                      }"
                    >
                      <div class="dds-data-table__content">
                        <span>{{ column.header || column.name }}</span>
                      </div>
                    </th>
                    <th class="dds-data-table__header-cell">
                      <span [hidden]="!showActionsHeader">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="dds-data-table__body">
                  <tr
                    *ngFor="
                      let row of updatedRiskManagerDetails;
                      index as index
                    "
                    class="dds-data-table__row"
                  >
                    <td
                      class="dds-data-table__cell table-checkbox-cell"
                      *ngIf="
                        !isStatusCompleted() &&
                        !isDisContinued() &&
                        isPermissionPresent(
                          permissionEnums.Intake_Submission_WorkflowCheckbox,
                          permissionType.Visible
                        )
                      "
                    >
                      <dds-checkbox
                        class="custom-checkbox-class"
                        [ariaLabel]="'row ' + (index + 1)"
                        [value]="
                          isReviewerCheckboxChecked(row.isRequired, index)
                        "
                        theme="green"
                        [disabled]="
                          isReviewerCheckboxDisabled(row.emailId, index)
                        "
                        (click)="toggleRow(row.emailId, index)"
                      ></dds-checkbox>
                    </td>
                    <td class="dds-data-table__cell">
                      <ng-container *ngIf="!row.isEditable">
                        <div>
                          <span
                            class="comments-text-label eclipse-small-header-grey"
                            >{{ row.roleName }}
                          </span>
                        </div>
                        <div>
                          <span class="profile-name">{{
                            row.displayName || row?.fullName
                          }}</span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="row.isEditable">
                        <ng-container
                          *ngIf="
                            isEditingReviewer(row) ||
                              (isEditingTechnicalReviewer(row) &&
                                isPermissionPresent(
                                  permissionEnums.Intake_Submission_WorkflowRoleAndName,
                                  permissionType.Enable
                                ));
                            else displayDetails
                          "
                        >
                          <app-people-picker
                            [showLabel]="true"
                            [labelText]="
                              isEditingTechnicalReviewer(row)
                                ? roleTechnicalOrIndependentRM
                                : roleAdditionalReviewer
                            "
                            [isTechinalReviewerSearch]="
                              isEditingTechnicalReviewer(row) ? true : false
                            "
                            (userSelected)="
                              onMemberSelected(
                                row.emailId,
                                row.reviewerId,
                                $event
                              )
                            "
                            [id]="'picker-' + index"
                          ></app-people-picker>
                        </ng-container>

                        <ng-template #displayDetails>
                          <span
                            class="comments-text-label eclipse-small-header-grey"
                            >{{ row.roleName }}</span
                          ><br />
                          <span class="profile-name">{{
                            row.displayName || row?.fullName
                          }}</span>
                          <span
                            *ngIf="!disableFieldOnComplete()"
                            class="dds-icon dds-icon_edit__s__filled edit-icon"
                            [ngClass]="{
                              'pe-none opacity-50':
                                isWorkflowDisabled() ||
                                isAddReviewerButtonDisabled ||
                                (row.roleName ===
                                  roleTechnicalOrIndependentRM &&
                                  !isPermissionPresent(
                                    permissionEnums.Intake_Submission_WorkflowRoleAndName,
                                    permissionType.Enable
                                  ))
                            }"
                            (click)="
                              $event.stopPropagation();
                              editReviewer(
                                row.emailId,
                                row.submissionApproverId ?? -1,
                                row.isForTechnicalReviewer ?? false
                              )
                            "
                          ></span>
                          <span
                            class="margin-left-s dds-icon dds-icon_delete__l__stroke"
                            [ngClass]="{
                              'pe-none opacity-50':
                                isWorkflowDisabled() ||
                                isAddReviewerButtonDisabled ||
                                (row.roleName ===
                                  roleTechnicalOrIndependentRM &&
                                  !isPermissionPresent(
                                    permissionEnums.Intake_Submission_WorkflowRoleAndName,
                                    permissionType.Enable
                                  ))
                            }"
                            (click)="
                              $event.stopPropagation();
                              clearReviewer(
                                row.emailId,
                                row.isForTechnicalReviewer
                              )
                            "
                          ></span>
                        </ng-template>
                      </ng-container>
                    </td>
                    <td class="dds-data-table__cell">
                      <!-- 
                      TODO: Once Employee ID is implemented this needs to be checked
                      <div
                        *ngIf="
                          loggedInEmployeeId === row.reviewerId &&
                          submissionStatus === 'Submitted' &&
                          row.statusReviewedDate === null
                        "
                        class="d-flex"
                      > -->
                      <div
                        *ngIf="
                          showReviewAndReturnBtn &&
                          row.statusReviewedDate === null &&
                          row.reviewerId === currentLoggedInUser
                        "
                        class="d-flex"
                      >
                        <dds-button
                          class="custom-button padding-right-xs"
                          (clicked)="handleCompleteReview(row)"
                          >Complete review</dds-button
                        >

                        <dds-button
                          *ngIf="
                            isPermissionPresent(
                              permissionEnums.Intake_Submission_ReturnForReworkBtn,
                              permissionType.Enable
                            )
                          "
                          class="custom-button"
                          [kind]="reworkButton.kind ?? ''"
                          (clicked)="handleReturnforRework(row)"
                          >Return for rework</dds-button
                        >
                      </div>
                      <div *ngIf="row.statusReviewedDate !== null">
                        <div class="flex-row-col">
                          <p class="text-green-2">Review Completed</p>
                          <p>{{ row.statusReviewedDate }}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!-- TODO: *ngIf="isEmployeePresent" once employee id is integrated -->
                <dds-button
                  *ngIf="
                    (!isStatusCompleted() || !isDisContinued()) &&
                    isPermissionPresent(
                      permissionEnums.Intake_Submission_WorkflowAddReviewer,
                      permissionType.Visible
                    )
                  "
                  [theme]="options.theme ?? ''"
                  [kind]="options.kind ?? ''"
                  [size]="options.size ?? ''"
                  [width]="options.width ?? ''"
                  [icon]="options.icon ?? ''"
                  [isIconLeft]="options.isIconLeft ?? false"
                  [role]="options.role ?? ''"
                  [isInverse]="options.isInverse ?? false"
                  [isColorBg]="options.isColorBg ?? false"
                  [isLoading]="options.isLoading ?? false"
                  [ariaLabel]="options.ariaLabel ?? ''"
                  [customClass]="options.customClass ?? ''"
                  [disabled]="isAddReviewerButtonDisabled"
                  (clicked)="
                    $event.stopPropagation();
                    addReviewer(roleAdditionalReviewer)
                  "
                  >Add reviewer</dds-button
                >
              </table>
            </div>
          </div></span
        >
      </dds-accordion-item>
      <dds-accordion-item *ngIf="isInternalQRMVisible" [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header margin-bottom-s">
            <span class="eclipse-section-header"
              >Internal QRM team communication</span
            >
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span content>
          <div class="accordion-content">
            <div>
              <div class="row">
                <div class="col-12">
                  <div class="text-label eclipse-content-grey padding-bottom-s">
                    Viewable by QRM team only, purged 1 year after "Complete"
                    status
                  </div>
                  <div class="textarea-container">
                    <div class="row">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="internalQRMTeamCommunication"
                          [(ngModel)]="model.internalQRMTeamCommunication"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="'Enter your comments'"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="
                            internalQrmCommunicationTextareaMaxlength
                          "
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          [readonly]="isInternalQRMDisabled"
                          (valueChanged)="
                            internalQrmCommunicationInputValueChanged()
                          "
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
    </dds-accordion>
  </div>
</div>
