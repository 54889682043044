import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SecurityWebapiService } from '../http/security/security-webapi.service';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrl: './start-page.component.less',
})
export class StartPageComponent implements OnInit {
  selectedRole: string = '';
  roles: string[] = [];

  public clientNumber: string = '';
  private securityNcaDashboard: string = 'ClientStatus.NcaDashboard';
  private isDashboardSecurity: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private securityWebapiService: SecurityWebapiService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.securityWebapiService.getPermissions('');
    });

    this.securityWebapiService.data.subscribe((rules) => {
      if (!rules.empty) {
        this.isDashboardSecurity =
          rules[this.securityNcaDashboard] &&
          rules[this.securityNcaDashboard].Visible;
        if (this.isDashboardSecurity == true)
          this.roles = ['NCA specialist', 'Reviewer']; // TODO: Need to get the roles from the backend, for now hardcoded.
        else {
          this.roles = ['NCA specialist', 'Reviewer'];
        }
        this.selectedRole = this.roles[1];
      }
    });
  }
  onRoleChange(event: any) {}

  getRoleWidth(): string {
    return this.selectedRole === 'Reviewer'
      ? this.selectedRole.length + 2 + 'ch'
      : this.selectedRole.length + 'ch';
  }
}
