import { Component, Input, input } from '@angular/core';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { TPAWebApiService } from '../../http/thirdpartyagreement/tpa.webapi.service';
import { LookupData } from '../../common/models/third-party-repository/third-party-agreement.model';
import { DropdownItem } from '../../common/services/dropdowns';
import { UploadFile } from '@usitsdasdesign/dds-ng/uploader';
import { SingleFileValidator } from "../../common/validations/single-file-validation";
import { DocumentService } from '../../http/document/document.service';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { NotifyPopupComponent } from '../../common/notify-popup/notify-popup.component';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { TPADocSettings } from './tpa-document-upload-popup-settings';
import { SharedService } from '../../common/services/shared-service.service';
import { Meta } from '@angular/platform-browser';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { forkJoin, from, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-tpa-document-upload-popup',
  templateUrl: './tpa-document-upload-popup.component.html',
  styleUrl: './tpa-document-upload-popup.component.less'
})
export class TPADocumentUploadPopupComponent {
  constructor(private modalService: ModalService,
    private progressIndicator: ProgressIndicatorService,
    private tpaWebApiService: TPAWebApiService,
    private documentService: DocumentService,
    private commonHelperService: CommonHelperService,
    private sharedService: SharedService) { }

  displayStyle: any = "none";

  @Input() isInverse: boolean = false;
  @Input() thirdPartyAgreementId: boolean = false;
  @Input() public fileList: any = {};
  @Input() isEditble: boolean = false;
  @Input() documentData: any = null;
  @Input() uploadedDocuments: any = [];
  @Input() agreementTypeId: number = 0;

  popupTitle: string = "";
  terminationDate: any = null;
  @Input() effectiveDate: any = null;
  isAutoRenew: any = '';
  documentTypeId: number = 0;
  documentTypeList: any = [];
  isSaveDisabled: boolean = true;
  canUpload: boolean = true;
  disableUpload: boolean = false;
  alertMessage: string = '';
  fileName: string = '';

  autoRenewRadioBtn: any = TPADocSettings.autoRenewRadioBtn;
  effectiveDateOptions: any = TPADocSettings.effectiveDateOptions;
  terminationDateOptions: any = TPADocSettings.terminationDateOptions;
  documentTypeOptions: any = TPADocSettings.documentTypeOptions;
  uploadAreaOptions: any = TPADocSettings.uploadAreaOptions;
  textDocumentTypeOptions: any = TPADocSettings.textDocumentTypeOptions;
  textTitle: any = TPADocSettings.textTitle;
  actionType: string = 'add';
  otherDocumentTypeName: string = '';
  otherDocumentTypeVisibility: boolean = false;

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_TPA;
    this.popupTitle = "Attach document(s)";
    this.getDocumentType();
    if (this.fileList.length > 0) {
      this.disableUpload = true;
    }
  }

  setDocumentFormData() {
    if (this.documentData != null) {
      this.fileName = this.commonHelperService.trimPrefixEmptySpace(this.documentData.fileName);
      this.documentTypeId = this.documentData.documentTypeId;
      if (this.documentTypeId == 12)  //Other DocumentType
      {
        this.otherDocumentTypeName = this.documentData.otherDocumentType;
        this.otherDocumentTypeVisibility = true;
      }
      else {
        this.otherDocumentTypeVisibility = false;
      }
      if (this.documentData.terminationDate != null && this.documentData.terminationDate != undefined) {
        this.terminationDate = new Date(this.documentData.terminationDate);
      }
      if (this.documentData.effectiveDate != null && this.documentData.effectiveDate != undefined) {
        this.effectiveDate = new Date(this.documentData.effectiveDate);
      }
      this.isAutoRenew = this.documentData.isAutoRenew?.toString();
    }
    else {
      if (this.agreementTypeId == 1) {
        this.documentTypeId = 7; //Prepopulate DocumentType to Master subcontractor agreement - external 3rd party if agreement type is Master SUBCON
      }
      if (this.agreementTypeId == 2) {
        this.documentTypeId = 13; //Prepopulate DocumentType to subcontractor agreement - external 3rd party if agreement type is One-off SUBCON
      }
      if (this.agreementTypeId == 3) {
        this.documentTypeId = 10; //Prepopulate DocumentType to Non-disclosure agreement (NDA) if agreement type is NDA
      }
      if (this.agreementTypeId == 4) {
        this.documentTypeId = 12;  //Prepopulate DocumentType to Other if agreement type is Other
      }
    }
    this.progressIndicator.hide();
  }
  getDocumentType() {
    this.progressIndicator.show();
    this.tpaWebApiService.getDocumentTypeListbyAgreementTypeId(this.agreementTypeId)
      .subscribe({
        next: (response: any) => {
          this.documentTypeList = response.map((lookupData: LookupData) => ({
            value: lookupData.id,
            itemCode: lookupData.code,
            heading: lookupData.name,
            isItemActive: true
          } as DropdownItem));
          this.setDocumentFormData();
        },
        error: (err) => {
          // Handle error
        }
      })
  }

  compareDataModels() {
    if (this.fileName != this.documentData.fileName) {
      return true;
    }
    if (this.documentTypeId != this.documentData.documentTypeId) {
      return true;
    }
    if (this.documentTypeId == 12 && this.otherDocumentTypeName != this.documentData.otherDocumentType) {
      return true;
    }
    if (this.effectiveDate?.toDateString() != (this.documentData.effectiveDate == null ?
      undefined
      : (new Date(this.documentData.effectiveDate)).toDateString())) {
      return true;
    }
    if (this.terminationDate?.toDateString() != (this.documentData.terminationDate == null ?
      undefined
      : (new Date(this.documentData.terminationDate)).toDateString())) {
      return true;
    }
    if (this.isAutoRenew?.toString() != this.documentData.isAutoRenew?.toString()) {
      return true;
    }
    return false;
  }
  documentTypeTextChangedEvent() {
    if (this.documentData != null) {
      this.isSaveDisabled = !this.compareDataModels();
    }
    else {
      this.enableSaveButton();
    }
  }
  fileNameChangedEvent() { 
    if (this.documentData != null) {
      this.isSaveDisabled = !this.compareDataModels();
    }
  }
  changeDocumentTypeOptions(event: any) {
    if (this.documentTypeId == 12) {
      this.otherDocumentTypeVisibility = true;
    }
    else {
      this.otherDocumentTypeVisibility = false;
    }
    if (this.documentData != null) {
      this.isSaveDisabled = !this.compareDataModels();
    }
    else if (this.documentTypeId > 0) {
      this.enableSaveButton();
    }
  }
  changeEffectiveDateOptions(event: any) {
    this.effectiveDate = event;
    if (this.documentData != null) {
      this.isSaveDisabled = !this.compareDataModels();
    }
    else if (event != '') {
      this.enableSaveButton();
    }
  }
  changeTerminationDateOptions(event: any) {
    this.terminationDate = event;
    if (this.documentData != null) {
      this.isSaveDisabled = !this.compareDataModels();
    }
    else if (event != '') {
      this.enableSaveButton();
    }
  }
  onAutoRenewChangedEvent() {
    if (this.documentData != null) {
      this.isSaveDisabled = !this.compareDataModels();
    }
  }
  enableSaveButton() {
    if (this.effectiveDate != '' && this.effectiveDate != undefined && this.documentTypeId > 0 && this.fileList.length > 0) {
      if (this.documentTypeId == 12 && this.otherDocumentTypeName != undefined && this.otherDocumentTypeName != null && this.otherDocumentTypeName != '') {
        this.isSaveDisabled = false;
      }
      else if (this.documentTypeId != 12) {
        this.isSaveDisabled = false;
      }
      else {
        this.isSaveDisabled = true;
      }
    }
    else {
      this.isSaveDisabled = true;
    }
  }

  clearList() {
    this.fileList = [];
    this.disableUpload = false;
  }

  validateFile(selectedFilesList: any) {
    if (!SingleFileValidator.isFileExtensionValid(selectedFilesList)) {
      return false;
    }
    if (SingleFileValidator.isFileMaxSizeExceed(selectedFilesList)) {
      return false;
    }
    if (this.uploadedDocuments.length > 0) {
      let uploadedFiles: any = [];
      this.uploadedDocuments.forEach((element: any) => {
        uploadedFiles.push(element.fileName);
      });
      if (SingleFileValidator.isFileExist(selectedFilesList[0].file.name, uploadedFiles)) {
        this.alertMessage = "FileExist";
        return false;
      }
    }
    if (!SingleFileValidator.isFileNameValidLength(selectedFilesList)) {
      this.alertMessage = "FileNameExceeds150Char";
      return false;
    }
    return true;
  }

  uploadedItems(selectedFilesList: UploadFile[]) {
    if (selectedFilesList.length > 0) {
       this.disableUpload = true;
      if (this.validateFile(selectedFilesList)) {
        this.removeBodyOverflow();
        this.fileList = selectedFilesList;
        if (this.fileList.length > 0) {
          this.enableSaveButton();
        }
        selectedFilesList.forEach((element: any, index: any) => {
          element.remove();
        });
      }
      else {
        this.showMsg();
        selectedFilesList.forEach((element: any, index: any) => {
          element.remove();
        });
      }
    }
    else {

    }
  }

  showMsg(modalBtn?: ButtonComponent) {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(NotifyPopupComponent, { isFooter: true, size: 'md', isInverse: false, notifyReason: this.alertMessage, footerButtonText: 'OK' });
    modalRef.onClosed().subscribe(() => {
      if (modalBtn) {
        modalBtn.focus();
      }
      this.removeBodyOverflow();
    });
  }
  prepareAttachmentUpdateData() {
    let metaData = {
      attachmentID: this.documentData.attachmentId,
      fileName: this.fileName,
      employeeId: this.documentData.createdBy
    };
    return metaData;
  }
  prepareMetaData(attachmentID: any) {
    let metaData = {
      tpaDocumentID: 0,
      documentTypeId: this.documentTypeId,
      effectiveDate: this.effectiveDate?.toDateString() ?? null,
      terminationDate: this.terminationDate?.toDateString() ?? null,
      isAutoRenew: this.isAutoRenew,
      thirdPartyAgreementId: this.thirdPartyAgreementId,
      attachmentID: attachmentID,
      OtherDocumentType: this.otherDocumentTypeName,
      createdBy: '',
      modifiedBy: '',
      DocumentTypeName: '',
      fileName: this.fileList[0]?.file?.name
    }
    if (this.documentData != null) {
      metaData.tpaDocumentID = this.documentData.tpaDocumentId;
      metaData.attachmentID = this.documentData.attachmentId;
      metaData.createdBy = this.documentData.createdBy;
      metaData.modifiedBy = this.documentData.modifiedBy == null ? '' : this.documentData.modifiedBy;
      metaData.fileName = this.fileName;
    }
    return metaData;

  }
  saveNCADocumentMetaData(attachmentID: any = '') {
    let metaData = this.prepareMetaData(attachmentID);
    this.tpaWebApiService.saveTPADocument(metaData).subscribe({
      next: (response: any) => {
        this.fileList = [];
        this.progressIndicator.hide();
        if (this.actionType == 'add') {
          this.sharedService.showToastNotification('Document(s) added');
        }
        else {
          this.sharedService.showToastNotification('Document(s) updated');
        }
        this.closePopup();
      },
      error: (err) => {
        // Handle error
        this.fileList = [];
        this.progressIndicator.hide();
        this.closePopup();
      }
    });
  }

  updateMetaData() : Observable<any> {
    let metaData = this.prepareMetaData('');
    let attachmentMetaData = this.prepareAttachmentUpdateData();
   
    return forkJoin([
      this.tpaWebApiService.saveTPADocument(metaData),
      this.documentService.updateDocumentName(attachmentMetaData)
    ]).pipe(
      switchMap(([metaDataResponse, attachmentResponse]) => {
        if (metaDataResponse && attachmentResponse) {
          this.sharedService.showToastNotification('Document(s) updated');
          this.progressIndicator.hide();
          this.closePopup();
          return of(metaDataResponse);
        }
        else {
          this.progressIndicator.hide();
          this.closePopup();
          return of(null); // Return an observable
        }
      })
    );
  }

  updateNCADocumentMetaData()  {
    if (this.fileName === this.documentData.fileName) {
      return this.saveNCADocumentMetaData();
    }
    else {
      this.updateMetaData().subscribe();
    }
  }

  getUploadFilePath() {
    return this.commonHelperService.getDocumentFolderPath("TPA");
  }

  uploadFiletoS3() {
    let filePath = this.getUploadFilePath();
    for (const fileSource of this.fileList) {
      let file = { fileSource: fileSource.file };

      this.documentService
        .getUploadUrlForDCRMValidation(file.fileSource.name)
        .subscribe({
          next: (presignedUrlData: any) => {
            this.documentService
              .uploadFileToS3UsingPresignedUrl(
                presignedUrlData.resultData,
                file.fileSource
              )
              .subscribe(
                () => {
                  this.documentService
                    .uploadDocument(
                      file.fileSource.name,
                      presignedUrlData.resultData.documentKey,
                      filePath
                    )
                    .subscribe({
                      next: (result: any) => {
                        if (
                          result.isDocumentUploaded == false &&
                          result.isDocumentProtected == true
                        ) {
                          //this.notifyReason = "DocumentProtected";
                          //this.documentUnUploadData.push(result.documentName);
                          this.progressIndicator.hide();
                          //this.showMsg();
                        } else {
                          this.saveNCADocumentMetaData(result.attachmentID);
                        }
                      },
                      error: (err: any) => {
                        this.progressIndicator.hide();
                      },
                      complete: () => {
                        this.progressIndicator.hide();
                      },
                    });
                },
                (error) => {
                  console.error('Error uploading file:', error);
                  this.progressIndicator.hide();
                }
              );
          },
          error: (error: any) => {
            console.error('Error getting presigned URL:', error);
            this.progressIndicator.hide();
          },
        });
    }
  }

  onSave() {
    this.progressIndicator.show();
    if (this.documentData != null) {
      this.actionType = 'update';
      this.updateNCADocumentMetaData();
    }
    else {
      this.applyBodyOverflow();
      this.actionType = 'add';
      this.uploadFiletoS3();
    }
  }
  applyBodyOverflow() {
    document.body.style.overflow = 'auto';
  }
  closePopup() {
    this.applyBodyOverflow();
    this.modalService.close(this.fileList);
  }
  closeModal() {
    this.fileList = [];
    this.closePopup();
  }
  removeBodyOverflow() {
    document.body.style.overflow = 'hidden';
  }
}
