import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { TokenService } from './http/token.service';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { EntityDetails, EntityType, UserService } from './http/user.service';
import { jwtDecode } from 'jwt-decode';
import { CommonService } from './http/intake/common.service';
import { DashboardRolesForOptions } from './common/models/start-page/start-page.model';
import { EclipseHeaderComponent } from './eclipse-header/eclipse-header.component';
import { EclipseHeaderService } from './http/eclipse-header.service';
import { employeeRoles } from './common/models/common-models';
import { RoleEnum } from './intake/constants/Role.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'eclipse';
  public isAuthorized: boolean = false;
  public imagePath: any;
  public user: any = 'User';
  userName: any;
  isADGroupUser: any = false;
  userEmail: any;
  isTFAdminUser: boolean = false;
  isITSAdminUser: boolean = false;
  isIframe = false;
  profileData: any;
  isUserHasAdminAccess: boolean = false;
  isContentManagementUser: boolean = false;
  isDashboardUser: boolean = false;
  isAdminUser: boolean = false;
  formName: string = '';
  noImageFile: any = 'eyJl';
  // public isAuthorized : boolean ;
  public base64: string =
    'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABmJLR0QA/wD/AP+gvaeTAAADi0lEQVR4nO2cTUtVURSGH9MiBUsqJYhMy8I+iAbRxASptBo47MsaBP2A/kKTIJoFFSH9C0chRaFWGEVUphlC2aiENAujLG2wrmAXO7dz7tlr7+tZD7yj+7H2etl337PXWWeDYRiGYRiGYRhGoJT5HkABmoCjQAvQDGwBqnOvTQPjwAjQD9wBxjyMsWSpAM4CD4H5mBoAuoBy9VGXGMeAUeIbnK8RoEN57CVBFXCb4g3OVzdQqZhH0NQBT0nf5AU9AWrVsgmUOuRn7srkxUtJZs2uwu1MXmpmr1bJLDBcrMmFdEsls4DoQN/kBR1XyC8IKkjnEi6phsnIdfY5/Jm8oNPOswyAR/g3ut95lp5pwr/J88Ac0Og4179YoRkMKRCFQBnKW3Rto1uU40XRqhlM2+idyvGiaNYMpm30ZuV4UdRrBtM2urrwW9RYoxlM+w7LvHK8Qqjlrz2jM4sZrYS20T+V40XxQzOYttFfleNFMa0ZTNvoD8rxohjXDKZt9GvleFEMawbTNnpAOV4Uy7qCtw2pnIVQvWtwnKt3knQgpa0+51kGQBf+jT7pPMsAKEenl+NfGiIj9wwB2vFn9CGF/IKiG32Tb6pkFhiVSPeQlsmPyWinEkg/nMZ6PQxsUMopWGqBQdzO5Mw2OOZTifTFuViTM7tcRHEE+ZkXa/AQGby6iEs5cAapRcTZrs8hO75TBHhDI/SnshqRRpdWpFUh/6ms98ifaR/yVNY7D2M0DMMwDMMwDMMobULcGa4FdgF7gN3ADmAdUJN7rSb3vqlFmgTeIDWOV0j/iGonUiFCMHojUgA6DLQBW1P63jHgPnAXuAd8TOl7S4p9wFVk9mncXZkDXgBXgL0K+XmlHrgIPEPH3EJl1EvAdqcZK3MQ6CGMDqWlZnov0EkYy2hsVgEXgJf4N/N/9Rw4D6x04IcTOoG3+DcuqUaBEwQ8ww8gd0V8G5WWHgD7U3WoSCqQP5Zf+Dcnbf0GrhHActKA9Dz7NsS1BpEDA7zQhuzKfJugpUmUnx8nF/B7kQMvRc2geHDAemDCcUIhawKpvcQiSZ/wZaQukVWqEN96XQfyefBUKIr9dFmSi/IZ7LzPb8Q8qSFJ69SnBJ9ZbsQuuSYxuifBZ5YbKh5sAj7jf530pSkUT9JpB744TihETeKhHbgRuIFchcwmHHgpaDaX43Wkm9UwDMMwDMMwDMMwDCPz/AHwMcyzZEtWEAAAAABJRU5ErkJggg==';
  // public imagePath:any=this.base64;
  isAdGroupDatafetched: boolean = false;
  userData: any;
  isContentDivVisible: boolean = true;

  loginDisplay = false;
  largeHeaderHeight: string = '';
  layoutWidth: string = '';
  layoutHeight: string = '';
  layoutColor: string = '#fafafa';
  unsubscriber$ = new Subject();
  currentRoute: string = '/';
  showHeader: boolean = true;

  @ViewChild('eclipseHeader', { static: false })
  eclipseHeader?: EclipseHeaderComponent;

  constructor(
    private msalService: MsalService,
    private tokenService: TokenService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService,
    private readonly commonService: CommonService,
    public headerService: EclipseHeaderService
  ) {}

  ngOnInit() {
    if (!this.isUserLoggedIn()) {
      this.login();
    } else {
      this.user = localStorage.getItem('username');
      this.isAuthorized = true;
    }

    this.router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe((event) => {
      this.currentRoute = (event as NavigationEnd).urlAfterRedirects;
      this.setLayoutDimensions(this.currentRoute);
    });

    this.commonService.dashboardSelected$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: string | null) => {
        if (this.currentRoute == '/') {
          this.layoutWidth = '94%';
          if (data === DashboardRolesForOptions.EngagementTeam) {
            this.largeHeaderHeight = '15.5rem';
          } else {
            this.largeHeaderHeight = '12.5rem';
          }
        } else {
          this.setLayoutDimensions(this.currentRoute);
        }
      });
    this.headerService.showHeader$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((show: boolean | null) => {
        this.showHeader = show ?? false;
        this.headerService.getHeaderHeight(this.showHeader);
      });
  }

  // login() {
  //   this.msalService.loginPopup()
  //     .subscribe({
  //       next: (result) => {
  //         console.log(result);
  //         debugger;
  //         this.setLoginDisplay();
  //       },
  //       error: (error) => console.log(error)
  //     });
  // }

  setLayoutDimensions(currentRoute: string): void {
    if (currentRoute == '/') {
      this.largeHeaderHeight = '12.5rem';
      this.layoutWidth = '94%';
    } else if (currentRoute == '/watchlist') {
      this.largeHeaderHeight = '10.7rem';
      this.layoutWidth = '93%';
      this.isContentDivVisible = false;
    } else if (currentRoute.includes('/watchlist/audit')) {
      this.largeHeaderHeight = '5.5rem';
      this.layoutWidth = '94%';
      this.isContentDivVisible = true;
    } else if (currentRoute == '/watchlist/add') {
      this.largeHeaderHeight = '6.5rem';
      this.layoutWidth = '94%';
      this.layoutHeight = '43.75rem';
      this.isContentDivVisible = true;
    } else if (currentRoute.includes('/client')) {
      this.largeHeaderHeight = '7.5rem';
      this.layoutWidth = '85vw';
    } else if (currentRoute.includes('/submission/opportunity-summary')) {
      this.largeHeaderHeight = '8.7rem';
      this.layoutWidth = '93%';
    } else if (currentRoute.includes('/submission')) {
      this.largeHeaderHeight = '4.3rem';
      this.layoutWidth = '93%';
      if (currentRoute.includes('/opportunity-details')) {
        this.largeHeaderHeight = '8.7rem';
      }
    } else if (currentRoute.includes('/initiate/mars-nca')) {
      this.largeHeaderHeight = '4.3rem';
      this.layoutWidth = '82.5rem';
    } else if (currentRoute == '/thirdpartyrepository/add') {
      this.largeHeaderHeight = '4.3rem';
      this.layoutWidth = '93%';
      this.isContentDivVisible = false;
    } else if (/^\/thirdpartyrepository\/\d+$/.test(currentRoute)) {
      this.largeHeaderHeight = '4.3rem';
      this.layoutWidth = '93%';
      this.isContentDivVisible = false;
    } else if (currentRoute == '/thirdpartyrepository') {
      this.largeHeaderHeight = '8.7rem';
      this.layoutWidth = '90%';
      this.isContentDivVisible = false;
    } else if (currentRoute == '/externalCommunications/create') {
      this.largeHeaderHeight = '4.3rem';
      this.layoutWidth = '93%';
    } else if (currentRoute.includes('/externalCommunications/details')) {
      this.largeHeaderHeight = '8.7rem';
      this.layoutWidth = '93%';
    } else if (currentRoute == '/ncasummary') {
      this.largeHeaderHeight = '8.188rem';
      this.layoutWidth = '93%';
    } else if (currentRoute == '/admin') {
      this.largeHeaderHeight = '8.7rem';
      this.layoutWidth = '93%';
    } else if (currentRoute == '/audit-trail') {
      this.largeHeaderHeight = '8.7rem';
      this.layoutWidth = '93%';
    } else if (currentRoute.includes('/audit-trail/details')) {
      this.largeHeaderHeight = '8.7rem';
      this.layoutWidth = '93%';
    } else if (currentRoute == '/globalEngagements/create') {
      this.largeHeaderHeight = '4.3rem';
      this.layoutWidth = '93%';
    } else if (currentRoute.includes('/globalEngagements/details')) {
      this.largeHeaderHeight = '7.5rem';
      this.layoutWidth = '93%';
    } else if (
      currentRoute.includes('/opportunity-not-found') ||
      currentRoute == '/error'
    ) {
      this.largeHeaderHeight = '4.3rem';
      this.layoutWidth = '93%';
    }else if (
      currentRoute.includes('/externalCommunications') ||
      currentRoute == '/success'
    ) {
      this.largeHeaderHeight = '4.3rem';
      this.layoutWidth = '93%';
    }else if (
      currentRoute.includes('/externalCommunications') ||
      currentRoute == '/error'
    ) {
      this.largeHeaderHeight = '4.3rem';
      this.layoutWidth = '93%';
    }else if (
      currentRoute.includes('/validate-redirect') 
    ) {
      this.largeHeaderHeight = '4.3rem';
      this.layoutWidth = '93%';
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  login() {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: any) => {
        const account = result.payload.account;
        var fullname = account?.name?.split(/,/);
        var displayName =
          fullname.length > 1
            ? fullname[1].trim() + ' ' + fullname[0].trim()
            : fullname[0].trim();
        //var displayName = fullname[1].trim() + ' ' + fullname[0].trim();
        this.userName = displayName;
        this.tokenService.accessToken = result.payload?.accessToken;
        localStorage.setItem(
          'Acccess_Token_User',
          this.tokenService.accessToken
        );
        this.tokenService.idToken = result.payload?.idToken;
        localStorage.setItem('accessToken', this.tokenService.accessToken);
        localStorage.setItem('idToken', this.tokenService.idToken);
        localStorage.setItem('userProfileId', account?.username);
        localStorage.setItem('userProfileName', displayName);
        this.userService.setUserEmail(account?.username);
        //  this.getPeoplePicker(account.username,this.userName);
        localStorage.setItem('isAuthorized', 'true');
        localStorage.setItem('fromtoken', 'true');
        this.setLoginDisplay();
        this.pageRedirection();
      });
  }

  pageRedirection() {
    this.userEmail = localStorage.getItem('userProfileId')
    if (this.userEmail) {
      this.userService.getUserRoles(EntityType.None as any, EntityDetails.None as any).subscribe({
        next: (data) => {
          if (data) {
            const loggedInUserRoles = data?.employeeRoles as employeeRoles[];
            if (loggedInUserRoles?.length &&
              loggedInUserRoles.some(role => role.roleName === RoleEnum.WATCHLIST_ADMIN && role.isGlobalRole)
              && loggedInUserRoles.filter(role => role.roleName != RoleEnum.WATCHLIST_ADMIN && !role.isGlobalRole).length > 0) {
              this.router.navigate(['/watchlist']);
            }
            else {
              this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe((event) => {
                  this.currentRoute = (event as NavigationEnd).urlAfterRedirects;
                  this.setLayoutDimensions(this.currentRoute);
                });
            }
          }
        }
      });
    }
    
  }

  isUserLoggedIn(): boolean {
    var idToken = localStorage.getItem('idToken');
    if (idToken) {
      if (this.isTokenExpired(idToken)) {
        return false;
      } else return true;
    }
    return false;
  }

  logout() {
    this.msalService.logoutRedirect();
    localStorage.removeItem('username');
    localStorage.removeItem('isAuthorized');
    localStorage.removeItem('userProfileName');
    localStorage.removeItem('userProfileId');
  }

  isTokenExpired(token: string): boolean {
    const decodedToken = this.decodeToken(token);
    if (!decodedToken) {
      return true;
    }
    const expirationDate = decodedToken.exp * 1000;
    return Date.now() > expirationDate;
  }

  decodeToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Invalid token', error);
      return null;
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
