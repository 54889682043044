import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  FeeType,
  SubmissionType,
  configurationType,
  toolTipConfig,
  SubmissionSubType,
} from '../../common/models/common-models';
import {
  IntakeOpportunitySummaryTeam,
  OpportunityDetailsNavModel,
  OpportunityList,
  LinkedOpportunity,
  IntakeOpportunitySummaryTeamManagement,
  teamMembersTeamManagement,
} from '../../common/models/opportunity-details.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environment/environment';
import { InitiateSubmissionRequestDataModel } from '../../common/models/initiateSubmission.model';
import { ServiceDeliveryModel } from '../../common/models/serviceDelivery.model';
import { latestOpportunityDetails } from '../../common/models/latestOpportunityDetails';
import { OpportunityDetailsEdit } from '../../common/models/opportunityDetailsEdit';
import { SaveOpportunityDetailsSection } from '../../common/models/saveOpportunityDetails';
import { UrlConstant } from '../../intake/constants/url.constant';
import {
  EditIntakeDocumentModel,
  IntakeDocumentStatusDropdownModel,
  IntakeDocumentTableModel,
  IntakeDocumentTypeDropdownModel,
  SaveIntakeDocumentModel,
} from '../../common/models/intake-document-table.model';
import {
  complexityCyberQuestionsData,
  complexityQuestionsData,
} from '../../common/models/complexity-question-data.model';
import {
  DiscontinuedRequestModel,
  IntakeSubmissionDeatilsCommonSectionWorkflowSelectedDocumentsModal,
  IntakeSubmissionDetailsCommonSectionDataReceivedModal,
  IntakeSubmissionDetailsCommonSectionDataSendingModal,
  SubmissionApprovers,
} from '../../common/models/intake-submission-details-common-section.model';
import { IntakeClientSummaryModel } from '../../common/models/intake-client-summary.model';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import {
  IntakeSubmissionActionReasonDropdown,
  SaveSubmissionHistoryModel,
  SubmissionActionHistoryModel,
  SubmissionStatusByActionModel,
} from '../../common/models/submission-action.model';
import { IntakeOppertunitySubmissionStatus } from '../../intake/constants/intake_oppertunity_submission_status.enum';
import { DiscontinueReasonModel } from '../../common/models/discontinue-reason.model';
import { WbsDropdownModelResponseAll } from '../../common/models/wbs-dropdown.model';
import {
  ArchiveDetailsModel,
  PreviouslyConnectedArchiveCountResponse,
} from '../../common/models/archive-details.model';
import { ArchiveSaveModel } from '../../common/models/archive-save.model';

@Injectable({
  providedIn: 'root',
})
export class SubmissionService {
  //TODO: Hardcoded employee ID need to remove
  //employeeId = '1234';
  employeeId = '00100015';
  constructor(private readonly http: HttpClient) {}
  apiUrl: string = environment.apiUrl;
  //apiUrl: string = 'https://dapi.eclipse.deloitte.com/api/'; //dev
  //apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa

  public statusMap = new Map<number | null, BehaviorSubject<boolean>>();
  readonly docISValid = new BehaviorSubject<boolean>(false);
  public new$ = this.docISValid.asObservable();
  readonly #adminCloseEnabler: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly #openAdminCloseModal: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly #disableDocumentButtonOnAdminComplete: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly #complexQuestionSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly #detailSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly #complexQuestionCyberSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly #opportunitySelectedDocStatusSubject: BehaviorSubject<IntakeOppertunitySubmissionStatus | null> =
    new BehaviorSubject<IntakeOppertunitySubmissionStatus | null>(null);
  opportunitySelectedDocStatus$: Observable<IntakeOppertunitySubmissionStatus | null> =
    this.#opportunitySelectedDocStatusSubject.asObservable();
  readonly #openDiscontinueModalSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly #submissionIdSource: BehaviorSubject<{
    opportunitynumber: string;
    submissionid: number | null;
  }> = new BehaviorSubject<{
    opportunitynumber: string;
    submissionid: number | null;
  }>({ opportunitynumber: '', submissionid: null });
  readonly #currentonSubmissionPage: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly #otherValueSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  readonly #auditTrailEnabler: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  currentlyOnSubmissionPage$: Observable<boolean> =
    this.#currentonSubmissionPage.asObservable();
  otherValue$: Observable<string> = this.#otherValueSubject.asObservable();
  complexQuestionStatus$ = this.#complexQuestionSubject.asObservable();
  detailStatus$: Observable<boolean> = this.#detailSubject.asObservable();
  adminEnabler$: Observable<boolean> = this.#adminCloseEnabler.asObservable();
  adminCloseModal$: Observable<boolean> =
    this.#openAdminCloseModal.asObservable();
  disableDocumentButton$: Observable<boolean> =
    this.#disableDocumentButtonOnAdminComplete.asObservable();
  complexQuestionCyberStatus$: Observable<boolean> =
    this.#complexQuestionCyberSubject.asObservable();
  openDiscontinueModalObservable$: Observable<boolean> =
    this.#openDiscontinueModalSubject.asObservable();
  currentSubmissionId$: Observable<{
    opportunitynumber: string;
    submissionid: number | null;
  }> = this.#submissionIdSource.asObservable();
  auditTrailEnabler$: Observable<boolean> =
    this.#auditTrailEnabler.asObservable();

  changeSubmissionId(request: {
    opportunitynumber: string;
    submissionid: number | null;
  }) {
    this.#submissionIdSource.next(request);
  }

  updateCurrentSubmissionPageStatus(status: boolean): void {
    this.#currentonSubmissionPage.next(status);
  }
  updateComplexQuestionStatus(isValid: boolean): void {
    this.#complexQuestionSubject.next(isValid);
  }

  updateDetailStatus(isValid: boolean): void {
    this.#detailSubject.next(isValid);
  }

  updateComplexQuestionCyberStatus(isValid: boolean): void {
    this.#complexQuestionCyberSubject.next(isValid);
  }

  enableAdminComplete(isValid: boolean): void {
    this.#adminCloseEnabler.next(isValid);
  }

  openAdminCompleteModal(isValid: boolean): void {
    this.#openAdminCloseModal.next(isValid);
  }

  openDiscontinueSubmissionModal(openModal: boolean): void {
    this.#openDiscontinueModalSubject.next(openModal);
  }

  disableDocumentButtonOnAdminComplte(isValid: boolean): void {
    this.#disableDocumentButtonOnAdminComplete.next(isValid);
  }

  getIntakeConfigurations(): Observable<configurationType[]> {
    return this.http.get<configurationType[]>(
      this.apiUrl + UrlConstant.GET_INTAKE_CONFIGARATIONS_API
    );
  }

  getIntakeTooltips(): Observable<toolTipConfig[]> {
    return this.http.get<toolTipConfig[]>(
      this.apiUrl + UrlConstant.GET_INTAKE_TOOLTIPS_API
    );
  }

  getOpportunitySelected(
    opportunityId: string
  ): Observable<latestOpportunityDetails> {
    return this.http.get<latestOpportunityDetails>(
      this.apiUrl + UrlConstant.GET_SELECTED_OPPERTUNITY_API + opportunityId
    );
  }

  getSubmissionTypes(): Observable<SubmissionType[]> {
    return this.http.get<SubmissionType[]>(
      this.apiUrl + UrlConstant.GET_SUBMISSION_TYPES_API
    );
  }

  getSubmissionSubTypes(
    submissionId: number | undefined
  ): Observable<SubmissionSubType[]> {
    return this.http.get<SubmissionSubType[]>(
      this.apiUrl + UrlConstant.GET_SUBMISSION_SUBTYPES_API + submissionId
    );
  }

  getOpportunityDetails(
    searchItem: string,
    pageNumber: number,
    pageSize: number
  ): Observable<OpportunityList> {
    return this.http.get<OpportunityList>(
      this.apiUrl +
        UrlConstant.GET_OPPORTUNITY_DETAILS_API +
        searchItem +
        '&pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize
    );
  }

  postSubmissionRequest(
    data: InitiateSubmissionRequestDataModel
  ): Observable<any> {
    const url = `${this.apiUrl}${UrlConstant.POST_SUBMISSION_REQUEST_API}`;
    return this.http.post<any>(url, data);
  }

  getIntakeDetails(
    opportunityNumber: string
  ): Observable<OpportunityDetailsNavModel[]> {
    return this.http.get<OpportunityDetailsNavModel[]>(
      this.apiUrl + UrlConstant.GET_INTAKE_DETAILS_API + opportunityNumber
    );
  }

  saveComplexitydata(data: complexityQuestionsData): Observable<any> {
    const url = `${this.apiUrl}${UrlConstant?.POST_COMPLEXITY_DATA_API}`;
    return this.http.post<any>(url, data);
  }

  saveComplexityCyberdata(data: complexityCyberQuestionsData): Observable<any> {
    const url = `${this.apiUrl}${UrlConstant?.POST_COMPLEXITY_CYBER_DATA_API}`;
    return this.http.post<any>(url, data);
  }

  getComplexityResponse(
    opportunityID: string | null
  ): Observable<complexityQuestionsData> {
    return this.http.get<complexityQuestionsData>(
      this.apiUrl + UrlConstant?.GET_COMPLEXITY_DATA_API + opportunityID
    );
  }

  getDeliveryServiceTable(
    opportunityNumber: string
  ): Observable<ServiceDeliveryModel[]> {
    return this.http.get<ServiceDeliveryModel[]>(
      this.apiUrl + UrlConstant.GET_DELIVERY_SERVICE_API + opportunityNumber
    );
  }

  getLinkedOpportunityDetails(
    opportunityNumber: string
  ): Observable<LinkedOpportunity[]> {
    const url = `${this.apiUrl}${UrlConstant.GET_LINKED_OPPORTUNITIES_API}${opportunityNumber}`;
    return this.http.get<LinkedOpportunity[]>(url);
  }

  getSubmissionBySubmissionId(
    opportunityNumber: string,
    submissionId: string
  ): Observable<IntakeSubmissionDetailsCommonSectionDataReceivedModal> {
    const url = `${this.apiUrl}${UrlConstant.GET_SUBMISSION_DETAILS_API}${opportunityNumber}&submissionId=${submissionId}`;
    return this.http
      .get<IntakeSubmissionDetailsCommonSectionDataReceivedModal>(url)
      .pipe(map(this.#transformSubmissionDetails));
  }

  getDocumentStatusDropdown(
    id: number,
    typeId: number
  ): Observable<IntakeDocumentStatusDropdownModel[]> {
    const url = `${this.apiUrl}${
      UrlConstant.getDocumentStatusDropdownurl
    }${id}${'&submissionTypeId=' + typeId}`;
    return this.http.get<IntakeDocumentStatusDropdownModel[]>(url);
  }

  getDocumentTypeDropdown(
    id: number
  ): Observable<IntakeDocumentTypeDropdownModel[]> {
    return this.http.get<IntakeDocumentTypeDropdownModel[]>(
      this.apiUrl + UrlConstant?.getDocumentTypeDropdownurl + id
    );
  }

  getDocumentTableData(
    id: number | null
  ): Observable<IntakeDocumentTableModel[]> {
    return this.http.get<IntakeDocumentTableModel[]>(
      this.apiUrl + UrlConstant?.getDocumentTableData + id
    );
  }
  getOpportunityDetailsInfo(
    opportunityNumber: string
  ): Observable<OpportunityDetailsEdit> {
    const url = `${this.apiUrl}${UrlConstant.GET_OPPORTUNITY_DETAILS_INFO_API}${opportunityNumber}`;
    return this.http.get<OpportunityDetailsEdit>(url);
  }

  updateOpportunityDetails(
    data: SaveOpportunityDetailsSection
  ): Observable<SaveOpportunityDetailsSection> {
    const url = `${this.apiUrl}${UrlConstant.POST_OPPORTUNITY_DETAILS_REQUEST_API}`;
    return this.http.post<SaveOpportunityDetailsSection>(url, data);
  }

  saveArchive(data: ArchiveSaveModel): Observable<ArchiveSaveModel> {
    const url = `${this.apiUrl}${UrlConstant.POST_ARCHIVE_SAVE}`;
    return this.http.post<ArchiveSaveModel>(url, data);
  }

  getFeeTypes(): Observable<FeeType[]> {
    return this.http.get<FeeType[]>(this.apiUrl + UrlConstant.GET_FEE_TYPE_API);
  }

  postDocumentSave(
    data: SaveIntakeDocumentModel[]
  ): Observable<SaveIntakeDocumentModel[]> {
    const url = `${this.apiUrl}${UrlConstant.postDocumentData}`;
    return this.http.post<any>(url, data);
  }

  postDocumentEdit(
    data: EditIntakeDocumentModel
  ): Observable<EditIntakeDocumentModel> {
    const url = `${this.apiUrl}${UrlConstant.PUT_DOCUMENT_EDIT_URL}`;
    return this.http.post<any>(url, data);
  }

  deleteDocument(id: number): Observable<number> {
    const url = `${this.apiUrl}${UrlConstant.Delete_DOCUMENT_URL}${id}`;
    return this.http.delete<any>(url);
  }

  putLepStatus(
    submissionId: number | null,
    lepReviewedBy: string
  ): Observable<string> {
    const url = `${this.apiUrl}${UrlConstant.PUT_LEP_URL}`;
    const data: any = {
      submissionID: submissionId,
      lepAcknowledgedBy: this.employeeId,
      lepReviewedBy: lepReviewedBy,
    };
    return this.http.put<string>(url, data);
  }

  saveIntakeDetailsCommonData(
    dataModel: IntakeSubmissionDetailsCommonSectionDataSendingModal
  ): Observable<IntakeSubmissionDetailsCommonSectionDataSendingModal> {
    return this.http.put<IntakeSubmissionDetailsCommonSectionDataSendingModal>(
      this.apiUrl + UrlConstant.SAVE_INTAKE_DESC_COMMON_DETAILS,
      dataModel
    );
  }

  getIntakeClientSummary(
    clientNumber: string
  ): Observable<IntakeClientSummaryModel> {
    const url = `${this.apiUrl}${UrlConstant.INTAKE_CLIENT_SUMMARY_API}${clientNumber}`;
    return this.http.get<IntakeClientSummaryModel>(url);
  }

  updateSubmissionReviewers(
    approverList: SubmissionApprovers[],
    submissionId: number
  ): Observable<any> {
    const requestBody = {
      submissionId: submissionId,
      submissionApprovers: approverList,
    };
    return this.http.post<SubmissionApprovers[]>(
      this.apiUrl + UrlConstant.UPDATE_INTAKE_SUBMISSION_REVIEWERS,
      requestBody
    );
  }

  getSubmissionReviewers(
    submissionId: string
  ): Observable<SubmissionApprovers[]> {
    return this.http.get<SubmissionApprovers[]>(
      this.apiUrl + UrlConstant.GET_INTAKE_SUBMISSION_REVIEWERS + submissionId
    );
  }

  getOpportunitySummaryTeamData(
    opportunityNumber: string
  ): Observable<IntakeOpportunitySummaryTeam> {
    const url = `${this.apiUrl}${UrlConstant.GET_OPPORTUNITY_SUMMARY_TEAM_DATA}${opportunityNumber}`;
    return this.http.get<IntakeOpportunitySummaryTeam>(url);
  }

  getTechnicalReviewers(
    searchItem: string,
    pageNumber: number,
    isLep: boolean = false,
    pageSize: number = IntakeConstant.PAGE_NUMBER
  ): Observable<SubmissionApprovers[]> {
    if (isLep) {
      return this.http.get<SubmissionApprovers[]>(
        this.apiUrl +
          UrlConstant.GET_TECHNICAL_REVIEWERS +
          searchItem +
          '&pageNumber=' +
          pageNumber +
          '&pageSize=' +
          pageSize +
          '&IsPPMD=true'
      );
    }
    return this.http.get<SubmissionApprovers[]>(
      this.apiUrl +
        UrlConstant.GET_TECHNICAL_REVIEWERS +
        searchItem +
        '&pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize
    );
  }

  updateOtherSubmissionValue(value: string): void {
    this.#otherValueSubject.next(value);
  }

  getChangeOrderStatus(id: string, statusId: number): Observable<boolean> {
    const params = new HttpParams()
      .set('opportunityNumber', id)
      .set('submissionTypeId', statusId);

    const url = this.apiUrl + UrlConstant.GET_CO_DRAFT_STATUS;
    return this.http.get<boolean>(url, { params });
  }

  submitCommonSectionData(
    submissionId: number,
    submissionStatusId: number | undefined,
    archivedocumentid: number | null = null,
    submissionTypeId?: number | undefined,
    IsValidArchive?: boolean | undefined
  ): Observable<string> {
    const body = {
      submissionId: submissionId,
      submissionStatusId: submissionStatusId,
      archivedocumentid: archivedocumentid,
      SubmissionTypeId: submissionTypeId,
      IsValidArchive: IsValidArchive,
    };

    const url = this.apiUrl + UrlConstant.UPDATE_SUBMISSION_STATUS_URL;
    return this.http.put<string>(url, body);
  }

  updateRMStatus(
    data: IntakeSubmissionDeatilsCommonSectionWorkflowSelectedDocumentsModal
  ): Observable<string> {
    const url = `${this.apiUrl}${UrlConstant.UPDATE_RM_STATUS}`;
    return this.http.post<string>(url, data);
  }

  getActionReasons(
    id: number
  ): Observable<IntakeSubmissionActionReasonDropdown[]> {
    return this.http.get<IntakeSubmissionActionReasonDropdown[]>(
      this.apiUrl + UrlConstant?.GET_ACTIONS_REASONS_API + id
    );
  }

  saveSubmissionHistory(
    data: SaveSubmissionHistoryModel
  ): Observable<SaveSubmissionHistoryModel[]> {
    const url = `${this.apiUrl}${UrlConstant.POST_SUBMISSION_HISTORY_API}`;
    return this.http.post<SaveSubmissionHistoryModel[]>(url, data);
  }

  getSubmissionActionHistory(
    id: string
  ): Observable<SubmissionActionHistoryModel[]> {
    return this.http.get<SubmissionActionHistoryModel[]>(
      this.apiUrl + UrlConstant?.GET_SUBMISSION_ACTION_HISTORY + id
    );
  }

  setSelectedOpportunityDocStatus(
    status: IntakeOppertunitySubmissionStatus | null
  ): void {
    this.#opportunitySelectedDocStatusSubject.next(status);
  }

  getDiscontinueReasons(
    actionId: number
  ): Observable<DiscontinueReasonModel[]> {
    return this.http.get<DiscontinueReasonModel[]>(
      this.apiUrl + UrlConstant?.GET_SUBMISSION_DISCONTINUE_REASON + actionId
    );
  }

  updateDiscontinueAsSubmissionStatus(
    requestBody: DiscontinuedRequestModel
  ): Observable<any> {
    const url = `${this.apiUrl}${UrlConstant.UPDATE_SUBMISSION_DISCONTINUE}`;
    return this.http.post<any>(url, requestBody);
  }

  getSubmissionStatusByAction(
    id: string
  ): Observable<SubmissionStatusByActionModel[]> {
    return this.http.get<SubmissionStatusByActionModel[]>(
      this.apiUrl + UrlConstant?.GET_SUBMISSION_ACTION_MODEL + id
    );
  }

  getOpportunityDetailForSummary(
    opportunityNumber: string
  ): Observable<OpportunityDetailsEdit> {
    const url = `${this.apiUrl}${UrlConstant.GET_INTAKE_DETAILS}${opportunityNumber}`;
    return this.http.get<OpportunityDetailsEdit>(url);
  }

  getWBSCodes(wbsNumber: string): Observable<WbsDropdownModelResponseAll> {
    const body = {
      WbsNumber: wbsNumber,
    };
    const url = `${this.apiUrl}${UrlConstant.GET_ARCHIVE_DROPDOWN}`;
    return this.http.post<WbsDropdownModelResponseAll>(url, body);
  }

  getArchiveDetails(archiveNumber: string): Observable<ArchiveDetailsModel> {
    const body = {
      ArchiveNumber: archiveNumber,
    };
    const url = `${this.apiUrl}${UrlConstant.GET_ARCHIVE_DETAILS}`;
    return this.http.post<ArchiveDetailsModel>(url, body);
  }

  getPreviouslyConnectedArchiveCount(
    opportunityNumber: string
  ): Observable<PreviouslyConnectedArchiveCountResponse> {
    const url = `${this.apiUrl}${UrlConstant.GET_PREVIOUS_CONNECTED_ARCHIVE}${opportunityNumber}`;
    return this.http.get<PreviouslyConnectedArchiveCountResponse>(url);
  }
  getTeamMembers(
    opportunityID: string
  ): Observable<IntakeOpportunitySummaryTeamManagement> {
    return this.http.get<IntakeOpportunitySummaryTeamManagement>(
      this.apiUrl + UrlConstant.GET_TEAM_MEMBERS + opportunityID
    );
  }

  updateLepDataTeamManagement(
    opportunityNumber: string,
    reviewerId: string,
    reviewerName: string
  ): Observable<any> {
    const url =
      this.apiUrl + UrlConstant.UPDATE_TEAM_MEMBERS + opportunityNumber;
    return this.http.post<any>(url, {
      leadEngagementPartner: {
        employeeId: reviewerId,
        fullName: reviewerName,
        offeringPortfolio: null, //TODO: Need to make changes once backend is done
        primaryIndustry: null,
        primaryOffering: null,
      },
    });
  }

  fetchEmployeesTeamMember(
    searchItem: string,
    pageNumber: number,
    pageSize: number = IntakeConstant.PAGE_NUMBER_5
  ): Observable<any> {
    return this.http.post<any>(this.apiUrl + UrlConstant.FETCH_TEAM_MEMBERS, {
      SearchTerm: searchItem,
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  }

  updateTeamMembersTeamManagement(
    opportunityNumber: string,
    data: teamMembersTeamManagement[] | null
  ): Observable<any> {
    const url =
      this.apiUrl + UrlConstant.UPDATE_TEAM_MEMBERS + opportunityNumber;
    return this.http.post<any>(url, {
      teamMembers: data,
    });
  }

  updateDescDetails(opportunityNumber: string): Observable<string> {
    const url = `${this.apiUrl}${UrlConstant.INTEGRATION_DATASYNC}`;
    return this.http.post<string>(url, {
      OpportunityNumber: opportunityNumber,
    });
  }

  enableAuditTrail(isValid: boolean): void {
    this.#auditTrailEnabler.next(isValid);
  }

  #transformSubmissionDetails(
    response: any
  ): IntakeSubmissionDetailsCommonSectionDataReceivedModal {
    return {
      ...response,
      submissionId: response?.submissionId,
      submissionTitle: response?.submissionTitle,
      selfReviewEligibilityStatusId: response?.selfReviewEligibilityStatusId,
      selfReviewEligibilityStatus: response?.selfReviewEligibilityStatus,
      submissionTypeCode: response?.submissionTypeCode,
      note: response?.note,
      submissionStatusName: response?.submissionStatusName,
      submissionApprovers: response?.submissionApprovers,
      proposalDescription: response?.proposalDescription,
      coDescription: response?.coDescription,
      delDescription: response?.delDescription,
      otherDescription: response?.otherDescription,
      relatedToRFP: response?.relatedToRFP,
      coChangeScope: response?.coChangeScope,
      internalQRMTeamCommunication: response?.internalQRMTeamCommunication,
      submissionStatusId: response?.submissionStatusId,
      submissionTypeId: response?.submissionTypeId,
      submissionSubTypeId: response?.submissionSubTypeId,
      customSubmissionType: response?.customSubmissionType,
      notesAndFeedback: response?.notesAndFeedback
        ? response.notesAndFeedback
        : '',
      lepAcknowledgedBy: response?.lepAcknowledgedBy,
      lepAcknowledgementDate: response?.lepAcknowledgementDate,
      lepReviewedBy: response?.lepReviewedBy,
      isEligibleTechnicalReviewer: response?.isEligibleTechnicalReviewer
        ? response.isEligibleTechnicalReviewer
        : false,
      wbsChargeCode: response?.wbsChargeCode,
      wbsDescription: response?.wbsDescription,
      numberOfOpenArchieve: response?.numberOfOpenArchieve,
      winningProposal: response?.winningProposal,
      updatedVersionForReview: response?.updatedVersionForReview,
      otherUpdatedVersionForReview: response?.otherUpdatedVersionForReview,
      contractSignedExecuted: response?.contractSignedExecuted,
      reasonForOptOutSelfReview: response?.reasonForOptOutSelfReview,
      isArchiveItIntegrationEnabled:
        response['isArchiveItIntegrationEnabled'] ?? false,
      archiveName: response?.archiveName,
      archiveNumber: response?.archiveNumber,
      archiveDescription: response?.archiveDescription,
      archiveStatus: response?.archiveStatus,
      swiftDetails: response?.swiftDetails,
    };
  }
}
