import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ClientStatusWebapiService, checkNcaProcessResult } from '../http/client/client-status-webapi.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { ButtonKind, ExtThemes, Size } from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { environment } from '../../environment/environment';
import { DropdownItem } from '../common/services/dropdowns';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-start-ncaprocess-dialog-component',
  templateUrl: './start-ncaprocess-dialog-component.component.html',
  styleUrls: ['./start-ncaprocess-dialog-component.component.less']
})
export class StartNcaprocessDialogComponentComponent implements OnInit, OnDestroy {
  @Input() resolve: any;

  public clientNumberOrOppurtunityNumber: string = '';
  public functionToStartNca: number = 1; // Consulting
  public checkNcaProcessResult: checkNcaProcessResult = {} as checkNcaProcessResult;
  public optionsStartNcaFunctions: DropdownItem[] = [];
  private subscriptions: Subscription[] = [];

  rootUrl: string = environment.rootUrl;

  textareaOptions: TextareaOptions = {
    placeholder: 'Client Number/Jupiter Opportunity Number',
    maxLength: 10,
    size: Size.md
  };

  buttonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primaryLoud,
    size: Size.md,
    icon: 'dds-icon_arrow-up',
    ariaLabel: 'Show Less',
    role: 'button'
  }

  constructor(private modalService: ModalService, private clientstatuswebapiservice: ClientStatusWebapiService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.clientstatuswebapiservice.dropdowncommondata$.subscribe(response => {
        if (response) {
          this.optionsStartNcaFunctions = response['optionsStartNcaFunctions'].resultData || [];
        }
      })
    );

    this.clientstatuswebapiservice.getDropdownCommonData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public clientNumberOrOppurtunityNumberChange() {
    if (this.checkNcaProcessResult.isStartNcaProcessAvailable) {
      this.checkNcaProcessResult = {
        isStartNcaProcessAvailable: false,
        startNcaProcessMessage: '',
        clientNumber: null,
        opportunityNumber: null
      };
    }
  }

  public checkNcaProcess() {
    this.subscriptions.push(
      this.clientstatuswebapiservice.checkNcaProcess(this.clientNumberOrOppurtunityNumber).subscribe(response => {
        this.checkNcaProcessResult = response;
        const clientUri = `${this.rootUrl}client/${this.checkNcaProcessResult.clientNumber}/status`;
        this.checkNcaProcessResult.startNcaProcessMessage = this.checkNcaProcessResult.startNcaProcessMessage.replace('#clienturi#', clientUri);
      })
    );
  }

  public startNcaProcessManually() {
    this.subscriptions.push(
      this.clientstatuswebapiservice.startNcaProcessManually(this.checkNcaProcessResult.clientNumber, this.functionToStartNca, null).subscribe(response => {
        this.modalService.closeAll();
        if (response?.isSuccess) {
          const clientUri = `${this.rootUrl}client/${this.checkNcaProcessResult.clientNumber}/status`;
          window.open(clientUri, '_blank');
        }
      })
    );
  }
  applyBodyOverflow() {
    document.body.style.overflow = 'auto';
  }
  public close() {
    this.applyBodyOverflow();
    this.modalService.close();
  }
}
