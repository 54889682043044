import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  ButtonKind,
  ExtThemes,
  Size,
  SpinnerThemes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../constants/intake.constant';
import { SpinnerOptions } from '@usitsdasdesign/dds-ng/spinner';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';

export const closeBtnOptions: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.secondary,
  size: Size.md,
  width: WidthState.fixed,
  isLoading: false,
  icon: 'dds-icon_close',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: IntakeConstant.EMPTY_SPACE,
  customClass: IntakeConstant.EMPTY_SPACE,
  role: 'button',
};

export const spinnerOptions: SpinnerOptions = {
  theme: SpinnerThemes.gray,
  thickness: '0.1875rem',
  size: '1.875rem',
  customClass: IntakeConstant.EMPTY_SPACE,
  isInverse: false,
};

export const searchOptions: SearchOptions = {
    placeholder: IntakeConstant.SEARCH_OPTION_PLACEHOLDER,
    customClass: IntakeConstant.EMPTY_SPACE,
  };

  const MODAL_COMMON_BTN_OPTIONS: ButtonOptions = {
    theme: ExtThemes.green,
    size: IntakeConstant.UI_ELEMENT_SIZE,
    width: WidthState.fixed,
    isLoading: false,
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: true,
    customClass: '',
    role: 'button',
  };

export const RELINK_BTN_OPTIONS = {
  ...MODAL_COMMON_BTN_OPTIONS,
  icon: IntakeConstant.EMPTY_SPACE,
  customClass: IntakeConstant.EMPTY_SPACE,
  kind: ButtonKind.primaryLoud,
  ariaLabel: IntakeConstant.EMPTY_SPACE,
  size: IntakeConstant.UI_ELEMENT_SIZE,
};

export const CANCEL_BTN_OPTIONS = {
  ...MODAL_COMMON_BTN_OPTIONS,
  icon: IntakeConstant.EMPTY_SPACE,
  kind: ButtonKind.secondaryLoud,
  customClass: IntakeConstant.EMPTY_SPACE,
  ariaLabel: IntakeConstant.EMPTY_SPACE,
  size: IntakeConstant.UI_ELEMENT_SIZE,
};