<div class="dds-modal-overlay"
    (click)="close()"></div>

<div ddsResize
     class="dds-modal dds-modal_lg">
  <div class="dds-modal__header">
    <span class="dds-modal__title">{{resolve.title}}</span>
    <dds-button size="sm"
                icon="dds-icon_close"
                kind="silent"
                theme="dark"
                ariaLabel="Close modal"
                class="dds-modal__close"
                (clicked)="close()"></dds-button>
  </div>

  <div class="dds-modal__body">
      <nca-specialist-page-grid-area-item [list]="clientOpportunityList"></nca-specialist-page-grid-area-item>
  </div>
</div>