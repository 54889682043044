  <progress-indicator></progress-indicator>
<div
  class="main-container margin-left-s width-100"
  *ngIf="submissionDetailModel?.submissionId"
>
  <div
    class="external-communication-container sticky-element-after-black-header padding-top-s"
  >
    <div class="title-container">
      <h4
        class="eclipse-main-tab-header text-align-left"
        *ngIf="permissionObj['isExtCommTitleAreaSubmissionTitleVisible']"
      >
        {{ submissionDetailModel?.submissionTitle }}
      </h4>
      <div class="button-container">
        <dds-button
          *ngIf="isSubmitButtonVisible"
          class="custom-button margin-right-m"
          [theme]="primaryLoudButton.theme"
          [kind]="primaryLoudButton.kind"
          [size]="htmlElementSize"
          [width]="primaryLoudButton.width"
          [disabled]="!isSubmitButtonEnabled || disableFieldOnReadOnlyStatus()"
          ariaLabel="Submit"
          (click)="submitSubmission()"
          >Submit</dds-button
        >

        <dds-button
          *ngIf="isSelfReviewButtonVisible"
          class="custom-button margin-right-m"
          [theme]="primaryLoudButton.theme"
          [kind]="primaryLoudButton.kind"
          [size]="htmlElementSize"
          [width]="primaryLoudButton.width"
          ariaLabel="Self-review"
          (click)="selfReviewHandler()"
          >Self-review</dds-button
        >

        <dds-button
          *ngIf="isOptOutSelfReviewButtonVisible"
          class="custom-button margin-right-m"
          [theme]="primaryButton.theme"
          [kind]="primaryButton.kind"
          [size]="htmlElementSize"
          [width]="primaryButton.width"
          [disabled]="
            !permissionObj['isExtCommOptOutSelfReviewButtonEnable'] ||
            disableFieldOnReadOnlyStatus()
          "
          ariaLabel="Opt-out of Self-review"
          (click)="optOutSelfReview()"
          >Opt-out of Self-review</dds-button
        >

        <dds-button
          *ngIf="isReturnToSelfReviewButtonVisible"
          class="custom-button margin-right-m"
          [theme]="primaryButton.theme"
          [kind]="primaryButton.kind"
          [size]="htmlElementSize"
          [width]="primaryButton.width"
          [disabled]="
            !permissionObj['isExtCommForcedSelfReviewEnable'] ||
            disableFieldOnReadOnlyStatus()
          "
          ariaLabel="Return to Self-review"
          (click)="returnToSelfReview()"
          >Return to self-review</dds-button
        >
        <dds-button
          *ngIf="isCompleteReviewButtonVisible"
          class="custom-button margin-right-m"
          [theme]="primaryLoudButton.theme"
          [kind]="primaryLoudButton.kind"
          [size]="htmlElementSize"
          [width]="primaryLoudButton.width"
          ariaLabel="completeReviewBtnLabel"
          [disabled]="
            !permissionObj['isExtCommCompleteReviewEnable'] ||
            disableFieldOnReadOnlyStatus()
          "
          (click)="handleCompleteReview(true)"
          >{{completeReviewBtnLabel}}</dds-button
        >
        <dds-button
          *ngIf="isPrescreenButtonVisible"
          class="custom-button margin-right-m"
          [theme]="primaryLoudButton.theme"
          [kind]="primaryLoudButton.kind"
          [size]="htmlElementSize"
          [width]="primaryLoudButton.width"
          ariaLabel="Prescreen"
          (click)="handleCompleteReview(false)"
          >Prescreen</dds-button
        >
        <!-- TODO: Update disabled check once isValidDocumentUploaded is fixed-->
        <dds-button
          *ngIf="
            submissionDetailModel?.submissionStatus === 'Returned for rework'
          "
          class="custom-button margin-right-m"
          [theme]="primaryLoudButton.theme"
          [kind]="primaryLoudButton.kind"
          [size]="htmlElementSize"
          [width]="primaryLoudButton.width"
          ariaLabel="Resubmit"
          [disabled]="
            !isResubmitButtonEnabled || disableFieldOnReadOnlyStatus()
          "
          (click)="handleResubmit()"
        >
          Resubmit
        </dds-button>

        <dds-button
          *ngIf="isConfirmButtonVisible"
          class="custom-button margin-right-m"
          [theme]="primaryLoudButton.theme"
          [kind]="primaryLoudButton.kind"
          [size]="htmlElementSize"
          [width]="primaryLoudButton.width"
          ariaLabel="Confirm"
          (click)="confirmSubmissionHeader()"
          >Confirm</dds-button
        >
        <dds-button
          *ngIf="isReturnToReworkBtnVisible"
          class="custom-button margin-right-m"
          [theme]="primaryButton.theme"
          [kind]="primaryButton.kind"
          [size]="htmlElementSize"
          [width]="primaryButton.width"
          ariaLabel="Return to rework"
          [disabled]="
            !permissionObj['isExtCommReturnForReworkEnable'] ||
            disableFieldOnReadOnlyStatus()
          "
          (click)="handleReturnToRework()"
          >Return to rework</dds-button
        >
      </div>
    </div>
    <div class="sub-header">
      <div
        class="submission-type font-size-12 font-bold"
        *ngIf="permissionObj['isExtCommTitleAreaEXTCOMMVisible']"
      >
        {{ submissionTypeCode }}
      </div>
      <div
        class="custom-pad margin-left-xxs font-size-12 font-bold"
        [style.color]="
          transformStatus(submissionDetailModel?.submissionStatus)
            | statusStyle : 'color'
        "
        [style.backgroundColor]="
          transformStatus(submissionDetailModel?.submissionStatus)
            | statusStyle : 'backgroundColor'
        "
        *ngIf="permissionObj['isExtCommTitleAreaSubmissionStatusVisible']"
      >
        {{ submissionDetailModel?.submissionStatus }}
      </div>
      <div class="id-container eclipse-content">
        <ng-container
          *ngIf="permissionObj['isExtCommTitleAreaSubmissionIdVisible']"
        >
          <span class="id-title">ID:</span>
          <span #textToCopy class="eclipse-content">{{
            submissionDetailModel?.submissionId
          }}</span>
          <span
            class="dds-icon dds-icon_copy__l__stroke icon"
            (click)="copyToClipboard(textToCopy.textContent)"
          ></span>
        </ng-container>

        <span
          *ngIf="
            permissionObj['isExtCommRushRequestBadgeVisible'] &&
            showRushRequestHeading
          "
          class="textRush font-size-12 font-bold-600"
          >Rush request</span
        >
      </div>
    </div>
    <div class="margin-top-s margin-bottom-s">
      <dds-tabs
        [theme]="tabContainerOptions.theme ?? _blank"
        [themeType]="tabContainerOptions.themeType ?? _blank"
        [size]="tabContainerOptions.size ?? _blank"
        [isResponsive]="tabContainerOptions.isResponsive ?? false"
        [customClass]="tabContainerOptions.customClass ?? _blank"
        [disabled]="tabContainerOptions.disabled ?? false"
        [isInverse]="tabContainerOptions.isInverse ?? false"
        [ariaLabel]="tabContainerOptions.ariaLabel ?? _blank"
      >
        <dds-tab
          #tab1
          [label]="tabOptionsOne.label ?? _blank"
          (tabChanged)="tabChanged(tabOptionsOne.label)"
        >
        </dds-tab>

        <dds-tab
          #tab1
          [label]="tabOptionsSix.label ?? _blank"
          (tabChanged)="tabChanged(tabOptionsSix.label)"
        >
        </dds-tab>

        <dds-tab
          #tab1
          [label]="tabOptionsFive.label ?? _blank"
          (tabChanged)="tabChanged(tabOptionsFive.label)"
        >
        </dds-tab>

        <dds-tab
          #tab2
          [label]="tabOptionsTwo.label ?? _blank"
          (tabChanged)="tabChanged(tabOptionsTwo.label)"
        >
        </dds-tab>

        <dds-tab
          #tab4
          [label]="tabOptionsFour.label ?? _blank"
          (tabChanged)="tabChanged(tabOptionsFour.label)"
        >
        </dds-tab>

        <dds-tab
          #tab4
          [label]="tabOptionsSeven.label ?? _blank"
          (tabChanged)="tabChanged(tabOptionsSeven.label)"
        >
        </dds-tab>
      </dds-tabs>
    </div>
  </div>
  <div>
    <dds-accordion [isMulti]="true">
      <dds-accordion-item
        id="{{ tabOptionsOne.label }}"
        [isOpen]="true"
        [ngClass]="{ 'selected-tab': selectedTab === tabOptionsOne.label }"
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">{{
              tabOptionsOne.label
            }}</span>
          </div>
        </span>

        <span [autosave-module]="savedetails" content>
          <div
            class="d-flex justify-content-between margin-right-l margin-bottom-m custom-width"
          >
            <div *ngIf="permissionObj['isExtCommCommunicationTypeVisible']">
              <div class="eclipse-small-header-grey text-align-left">
                Type of communication:
              </div>
              <div class="font-size-14 dropdown-width-small">
                <ng-container
                  class="dropdown-width-small"
                  *ngIf="
                    isEditingType &&
                      permissionObj['isExtCommCommunicationTypeEnable'];
                    else displayType
                  "
                >
                  <div>
                    <dds-select
                      id="communicationType"
                      autosave
                      [list]="communicationTypeList"
                      [stickerMaxHeight]="300"
                      [(ngModel)]="detailsRequestBody.CommunicationTypeId"
                    ></dds-select>
                  </div>
                </ng-container>
                <ng-template #displayType>
                  <span>{{
                    submissionDetailModel?.communicationTypeName
                  }}</span>
                  <span
                    *ngIf="
                      permissionObj['isExtCommCommunicationTypeEnable'] &&
                      !checkStatusCompleted()
                    "
                    #editButton
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                    (click)="onEditTypeClick($event)"
                  ></span>
                </ng-template>
              </div>
            </div>

            <div *ngIf="permissionObj['isExtCommPPMDTrainingCompletedVisible']">
              <div class="eclipse-small-header-grey text-align-left">
                PPMD training completed:
              </div>
              <div class="font-size-14">
                {{
                  !submissionDetailModel?.ppmdTrainingCompleted
                    ? yesNo.NO
                    : yesNo.YES
                }}
              </div>
            </div>

            <div *ngIf="permissionObj['isExtCommSelfReviewEligibiltyVisible']">
              <div class="eclipse-small-header-grey text-align-left">
                Self-review eligibility:
              </div>
              <div class="font-size-14">
                {{ submissionDetailModel?.selfReviewEligibility }}
              </div>
            </div>
          </div>
          <div
            class="margin-bottom-m custom-width"
            *ngIf="submissionDetailModel?.reasonForOptOutSelfReview"
          >
            <div class="eclipse-small-header-grey text-align-left">
              Reason for opt-out Self-review:
            </div>
            <div class="font-size-14 dropdown-width-small">
              {{ submissionDetailModel.reasonForOptOutSelfReview }}
            </div>
          </div>

          <div *ngIf="detailsRequestBody.CommunicationTypeId === 3">
            <span class="margin-right-xs"
              ><img src="\assets\images\green-pin-icon.svg"
            /></span>
            <span class="font-size-14"
              >Ensure client mentions, including former employment, have LCSP
              approval.</span
            >
          </div>

          <div
            *ngIf="!submissionDetailModel?.ppmdTrainingCompleted"
            class="detail-width padding-bottom-m"
          >
            <div>
              <div class="text-cool-gray">
                <div class="relative">
                  <span class="inline-block icon-s icon-pinned"></span>
                  <span
                    class="padding-left-xxs text-label font-bold-600 padding-bottom-xs"
                  >
                    Updated self-review course for PPMDs:
                  </span>
                  <span>
                    <div class="vertical-line-green-xs absolute"></div
                  ></span>
                  <div
                    class="padding-left-m padding-top-xs"
                    [innerHTML]="configLableDescription"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="detail-width">
            <div
              class="margin-top-xs"
              *ngIf="permissionObj['isExtCommSubmissionTitleVisible']"
            >
              <div class="font-size-14">
                <span
                  *ngIf="
                    !detailsRequestBody?.SubmissionTitle &&
                    !disableFieldOnReadOnlyStatus()
                  "
                  ><img
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Submission title:</span>
                <span class="title-char-count font-size-12 font-bold-400 line-height-s">{{ detailsRequestBody.SubmissionTitle?.length || 0 }}/100</span>
              </div>
              <div *ngIf="permissionObj['isExtCommSubmissionTitleEnable']">
                <dds-input
                  id="updateSubmissionTitle"
                  autosave
                  [maxLength]="100"
                  [(ngModel)]="detailsRequestBody.SubmissionTitle"
                  [maxLengthPosition]="'none'"
                  [disabled]="disableFieldOnReadOnlyStatus()"
                  (valueChanged)="onSubmissionTitleChange()"
                ></dds-input>
              </div>
              <div
                *ngIf="
                  !permissionObj['isExtCommSubmissionTitleEnable'] &&
                  permissionObj['isExtCommSubmissionTitleVisible']
                "
              >
                {{ detailsRequestBody?.SubmissionTitle }}
              </div>
            </div>

            <div class="margin-top-s">
              <span content>
                <app-external-communication-submission-detail
                  (businessDaysChange)="onBusinessDaysChange($event)"
                  [submissionDetailModel]="submissionDetailModel"
                  [permissionObj]="permissionObj"
                  [isDisabled]="disableFieldOnReadOnlyStatus()"
                  (updateRequestedReviewDate)="
                    onUpdateRequestedReviewDate($event)
                  "
                ></app-external-communication-submission-detail>
              </span>
            </div>

            <div
              class="margin-top-s"
              *ngIf="permissionObj['isExtCommAudiencePublicationVisible']"
            >
              <div class="font-size-14 margin-bottom-xs field-container">
                <span
                  ><img
                    *ngIf="
                      !detailsRequestBody.AudiencePublication &&
                      !disableFieldOnReadOnlyStatus()
                    "
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Audience/publication:</span>
                <span
                  class="audience-char-count font-size-12 font-bold-400 line-height-s"
                  >{{
                    detailsRequestBody.AudiencePublication?.length || 0
                  }}/200</span
                >
              </div>
              <div>
                <dds-textarea
                  autosave
                  [maxLength]="200"
                  [maxLengthPosition]="'none'"
                  [(ngModel)]="detailsRequestBody.AudiencePublication"
                  [readonly]="
                    !permissionObj['isExtCommAudiencePublicationEnable'] ||
                    disableFieldOnReadOnlyStatus()
                  "
                ></dds-textarea>
              </div>
            </div>

            <div
              class="margin-top-s"
              *ngIf="
                permissionObj[
                  'isExtCommRelatedToGovernmentandPublicServicesVisible'
                ]
              "
            >
              <div class="font-size-14">
                <span
                  ><img
                    *ngIf="!isGPS && !disableFieldOnReadOnlyStatus()"
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Related to Government and Public Services (GPS):</span>
              </div>
              <div>
                <div class="padding-top-xs">
                  <dds-radio-group
                    *ngIf="
                      permissionObj[
                        'isExtCommRelatedToGovernmentandPublicServicesEnable'
                      ]
                    "
                    autosave
                    [(ngModel)]="isGPS"
                    [disabled]="isGPSDisabled || disableFieldOnReadOnlyStatus()"
                    [name]="'GPS'"
                  >
                    <dds-radio [value]="'Yes'" [label]="'Yes'"> </dds-radio>
                    <br />
                    <dds-radio [value]="'No'" [label]="'No'"> </dds-radio>
                  </dds-radio-group>
                  <span
                    *ngIf="
                      !permissionObj[
                        'isExtCommRelatedToGovernmentandPublicServicesEnable'
                      ]
                    "
                    >{{ isGPS }}</span
                  >
                </div>
              </div>
            </div>

            <div
              class="margin-top-s"
              *ngIf="permissionObj['isExtCommIndustrySectorVisible']"
            >
              <div class="font-size-14 margin-bottom-xs">
                <span
                  ><img
                    *ngIf="
                      !detailsRequestBody.PrimaryIndustrySectorId &&
                      !disableFieldOnReadOnlyStatus()
                    "
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Primary Industry Sector:</span>
              </div>
              <div class="dropdown-width">
                <dds-select
                  autosave
                  [(ngModel)]="detailsRequestBody.PrimaryIndustrySectorId"
                  [list]="industrySectorList"
                  [stickerMaxHeight]="400"
                  (ngModelChange)="onIndustrySectorChange($event)"
                  [disabled]="
                    !permissionObj['isExtCommIndustrySectorEnable'] ||
                    disableFieldOnReadOnlyStatus()
                  "
                ></dds-select>
              </div>
            </div>

            <div
              class="margin-top-s"
              *ngIf="permissionObj['isExtCommDeloitteCorporateFinanceVisible']"
            >
              <div class="font-size-14">
                <span
                  ><img
                    *ngIf="!isDCF && !disableFieldOnReadOnlyStatus()"
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span
                  >Does this submission (i) include references to Deloitte
                  Corporate Finance LLC (DCF) or
                  <span
                    class="font-bold"
                    [ddsTooltip]="
                      'i.e, investment banking services and/or services otherwise requiring registration as a broker-dealer'
                    "
                  >
                    services provided by DCF
                  </span>
                  , (ii) involve participation of any DCF personnel as
                  contributors, authors and/or speakers, or (iii) contain any
                  references to DCF personnel?</span
                >
              </div>
              <div>
                <div class="padding-top-xs">
                  <dds-radio-group
                    *ngIf="
                      permissionObj['isExtCommDeloitteCorporateFinanceEnable']
                    "
                    autosave
                    [(ngModel)]="isDCF"
                    [disabled]="isDCFDisabled || disableFieldOnReadOnlyStatus()"
                    [isError]="radioGroupOptions.isError ?? false"
                    [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
                    [isInverse]="radioGroupOptions.isInverse ?? false"
                    [isRequired]="radioGroupOptions.isRequired ?? false"
                    [errorState]="radioGroupOptions.errorState ?? _blank"
                    [name]="'DCF'"
                  >
                    <dds-radio [value]="'Yes'" [label]="'Yes'"> </dds-radio>
                    <br />
                    <dds-radio [value]="'No'" [label]="'No'"> </dds-radio>
                  </dds-radio-group>
                  <span
                    *ngIf="
                      !permissionObj['isExtCommDeloitteCorporateFinanceEnable']
                    "
                    >{{ isDCF }}</span
                  >
                </div>
              </div>
            </div>
            <div class="margin-top-s">
              <div class="font-size-14 margin-bottom-xs">
                <span
                  ><img
                    *ngIf="
                      !detailsRequestBody.OfferingPortfolioId &&
                      !disableFieldOnReadOnlyStatus()
                    "
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Offering Portfolio (OP):</span>
              </div>
              <div class="dropdown-width">
                <select
                autosave
                name="OfferingPortfolio"
                class="dropdown-width-custom"
                id="OfferingPortfolio"
                [(ngModel)]="detailsRequestBody.OfferingPortfolioId"
                (ngModelChange)="getOffering($event)"
                [disabled]="disableFieldOnReadOnlyStatus()
                "
              >
                <ng-container *ngFor="let offeringPort of offeringPortfolioList">
                  <option [value]="offeringPort.value">
                    {{ offeringPort.heading }}
                  </option>
                </ng-container>
              </select>
              </div>
            </div>
            <div
              class="margin-top-s"
              *ngIf="permissionObj['isExtCommOfferingVisible']"
            >
              <div class="font-size-14 margin-bottom-xs">
                <span>Offering:</span>
              </div>
              <div
                class="dropdown-width"
                *ngIf="offeringList"
              >

              <select
                  autosave
                  name="Offering"
                  id="Offering"
                  class="dropdown-width-custom"
                  [(ngModel)]="detailsRequestBody.OfferingId"
                  (ngModelChange)="getMarketOffering($event)"
                  [disabled]="
                    detailsRequestBody.OfferingPortfolioId === null ||
                    !permissionObj['isExtCommOfferingEnable'] ||
                    disableFieldOnReadOnlyStatus()
                  "
                >
                  <ng-container *ngFor="let offering of offeringList">
                    <option [value]="offering.value">
                      {{ offering.heading }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div
              class="margin-top-s"
              *ngIf="permissionObj['isExtCommMarketOfferingVisible']"
            >
              <div class="font-size-14 margin-bottom-xs">
                <span>Market Offering:</span>
              </div>
              <div class="dropdown-width">
                <select
                  autosave
                  class="dropdown-width-custom"
                  name="Offering"
                  id="Offering"
                  [(ngModel)]="detailsRequestBody.MarketOfferingId"
                  [disabled]="
                  detailsRequestBody.OfferingId === null ||
                  !permissionObj['isExtCommMarketOfferingEnable'] ||
                  disableFieldOnReadOnlyStatus()
                  "
                >
                  <ng-container *ngFor="let marketoffering of marketOfferingList">
                    <option [value]="marketoffering.value">
                      {{ marketoffering.heading }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="d-flex margin-top-s">
              <div
                class="margin-right-l"
                *ngIf="permissionObj['isExtCommPrimaryContactVisible']"
              >
                <div class="font-size-14 margin-bottom-xs">
                  <span
                    ><img
                      *ngIf="
                        !detailsRequestBody.PrimaryContact &&
                        !disableFieldOnReadOnlyStatus()
                      "
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                  /></span>
                  <span>Primary contact:</span>
                </div>
                <div class="select-submission">
                  <div
                    *ngIf="primaryPeople"
                    class="d-flex justify-content-between align-items-center picker-box padding-xxs"
                  >
                    <div>
                      {{ primaryPeople }}
                    </div>
                    <div
                      *ngIf="
                        permissionObj['isExtCommPrimaryContactEnable'] &&
                        !checkStatusCompleted()
                      "
                      (click)="removePrimaryPeople()"
                      class="margin-right-xs font-bold cursor-clickable"
                    >
                      x
                    </div>
                  </div>

                  <app-people-picker
                    *ngIf="
                      !primaryPeople &&
                      permissionObj['isExtCommPrimaryContactEnable'] &&
                      !checkStatusCompleted()
                    "
                    [isTechinalReviewerSearch]="true"
                    [placeholder]="'Search by name'"
                    [isDisabled]="disableFieldOnReadOnlyStatus()"
                    (userSelected)="onSelectedPrimaryPeople($event)"
                  ></app-people-picker>
                </div>
              </div>
              <div *ngIf="permissionObj['isExtCommSecondaryContactVisible']">
                <div class="font-size-14 margin-bottom-xs">
                  <span>Secondary contact:</span>
                </div>
                <div class="select-submission">
                  <div
                    *ngIf="detailsRequestBody.SecondaryContact"
                    class="d-flex justify-content-between align-items-center picker-box padding-xxs"
                  >
                    <div>
                      {{ secondaryPeople }}
                    </div>
                    <div
                      *ngIf="
                        permissionObj['isExtCommSecondaryContactEnable'] &&
                        !checkStatusCompleted()
                      "
                      (click)="removeSecondaryPeople()"
                      class="margin-right-xs font-bold cursor-clickable"
                    >
                      x
                    </div>
                  </div>
                  <app-people-picker
                    *ngIf="
                      !detailsRequestBody.SecondaryContact &&
                      permissionObj['isExtCommSecondaryContactEnable']
                    "
                    [isTechinalReviewerSearch]="true"
                    [isDisabled]="disableFieldOnReadOnlyStatus()"
                    (userSelected)="onSelectedSecondaryPeople($event)"
                    [placeholder]="'Search by name'"
                    [isLep]="true"
                  ></app-people-picker>
                </div>
              </div>
            </div>

            <div
              class="margin-top-s"
              *ngIf="permissionObj['isExtCommMarketingTalentPRContactVisible']"
            >
              <div class="font-size-14 margin-bottom-xs">
                <span
                  ><img
                    *ngIf="requiedIndicatorForMarketContact()"
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                /></span>
                <span>Marketing/Talent/PR contact:</span>
              </div>
              <div class="select-submission">
                <div
                  *ngIf="detailsRequestBody.MarketingTalentPRContact"
                  class="d-flex justify-content-between align-items-center picker-box padding-xxs"
                >
                  <div>
                    {{ marketContact }}
                  </div>
                  <div
                    *ngIf="
                      permissionObj[
                        'isExtCommMarketingTalentPRContactEnable'
                      ] && !checkStatusCompleted()
                    "
                    (click)="removeMarketContact()"
                    class="margin-right-xs font-bold cursor-clickable"
                  >
                    x
                  </div>
                </div>
                <app-people-picker
                  *ngIf="
                    !detailsRequestBody.MarketingTalentPRContact &&
                    permissionObj['isExtCommMarketingTalentPRContactEnable'] &&
                    !checkStatusCompleted()
                  "
                  [isTechinalReviewerSearch]="true"
                  [isDisabled]="disableFieldOnReadOnlyStatus()"
                  (userSelected)="onSelectedMarketContact($event)"
                  [placeholder]="'Search by name'"
                  [isLep]="true"
                ></app-people-picker>
              </div>
            </div>

            <div
              *ngIf="detailsRequestBody.CommunicationTypeId === 3"
              class="margin-top-s"
            >
              <div class="font-size-14 margin-bottom-xs">
                <span
                  >Add additional comments, instructions, or background
                  information related to this External Communication</span
                >
              </div>
              <div>
                <dds-textarea
                  autosave
                  [maxLength]="1000"
                  [maxLengthPosition]="'bottom-right'"
                  [(ngModel)]="detailsRequestBody.AdditionalComments"
                  [disabled]="disableFieldOnReadOnlyStatus()"
                ></dds-textarea>
              </div>
            </div>
          </div>
          <div class="margin-bottom-l"></div>
        </span>
      </dds-accordion-item>
      <dds-accordion-item
        *ngIf="
          showRiskAssessment && permissionObj['isExtCommRiskAssessmentVisible']
        "
        id="{{ tabOptionsSix.label }}"
        [isOpen]="true"
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Risk Assessment</span>
          </div>
        </span>

        <span content>
          <app-risk-assessment
            [submissionId]="submissionId"
            [submissionDetailModel]="submissionDetailModel"
            (riskAssessmentRequiredFieldsFilled)="
              onRiskAssessmentRequiredFieldsFilled($event)
            "
            [isControlEnable]="
              permissionObj['isExtCommRiskAssessmentEnable'] &&
              !disableFieldOnReadOnlyStatus()
            "
            [isDisabledOnReadOnlyStatus]="disableFieldOnReadOnlyStatus()"
            (getSubmissionDetails)="getSubmissionDetails(submissionId, true)"
          ></app-risk-assessment>
        </span>
      </dds-accordion-item>
      <dds-accordion-item
        id="{{ tabOptionsFive.label }}"
        [isOpen]="true"
        *ngIf="permissionObj['isExtCommCommunicationAttributesVisible']"
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span id="tabOptions2.label" class="eclipse-section-header">{{
              tabOptionsFive.label
            }}</span>
          </div>
        </span>

        <span
          content
          *ngIf="permissionObj['isExtCommCommunicationAttributesVisible']"
        >
          <div>
            <app-communication-attributes
              [submissionId]="submissionId"
              (commAttrRequiredFieldsFilled)="
                onCommAttrRequiredFieldsFilled($event)
              "
              [isControlEnable]="
                permissionObj['isExtCommCommunicationAttributesEnable'] &&
                !disableFieldOnReadOnlyStatus()
              "
              [isDisabledOnReadOnlyStatus]="disableFieldOnReadOnlyStatus()"
              (getSubmissionDetails)="getSubmissionDetails(submissionId, true)"
            />
          </div>
        </span>
      </dds-accordion-item>
      <dds-accordion-item id="{{ tabOptionsTwo.label }}" [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">{{
              tabOptionsTwo.label
            }}</span>
          </div>
        </span>

        <span content>
          <ng-container>
            <div
              *ngIf="
                showRespPractitionerConfirmationMessage;
                else showRpAcknowledgeMessage
              "
              class="styled-box d-flex"
            >
              <span class="rp-review-padding">
                Confirm the Responsible Practitioner (RP),
                {{ ppmdReviewedBy ? ppmdReviewedBy : "" }} has completed their
                review of the document(s), prior to uploading and submitting for
                review
              </span>
              <dds-button class="button" (click)="confirmRpReviewCompletion()"
                >Confirm RP review completion</dds-button
              >
            </div>
          </ng-container>
          <ng-template #showRpAcknowledgeMessage>
            <div>
              <div
                class="comments-text-label eclipse-small-header-grey font-size-11"
              >
                Responsible Practitioner review:
              </div>
              <div class="col-12">
                <p class="margin-bottom-none">
                  Acknowledged by {{ ppmdAcknowledgedBy }} on
                  {{ ppmdAcknowledgedDate }}
                </p>
                <p>
                  confirming that {{ ppmdReviewedBy }} has reviewed the
                  document(s) being submitted for review.
                </p>
              </div>
            </div>
          </ng-template>
          <div
            class="accordion-content"
            *ngIf="
              !showRespPractitionerConfirmationMessage &&
              submissionDetailModel?.submissionStatusId
            "
          >
            <app-external-communication-documents
              [submissionId]="submissionId"
              [submissionStatusId]="
                submissionDetailModel.submissionStatusId ?? 0
              "
              [submissionStatus]="
                submissionDetailModel?.submissionStatus ?? _blank
              "
              [progressiveStatus]="null"
              [communicationType]="communicationType"
              [communicationTypeId]="communicationTypeId"
              [permissionObj]="permissionObj"
              [isSelfReview]="
                submissionDetailModel?.selfReviewEligibility === yesValue
              "
              [isUserinWorkFlow]="userInWorkflowTable()"
              [loggedInUseRoles]="loggedInUserRoles"
              [isDisabledDocuments]="disableFieldOnReadOnlyStatus()"
              (ppmdTrainingLabelValue)="ppmdTrainingLabelValue($event)"
            ></app-external-communication-documents>
          </div>
        </span>
      </dds-accordion-item>
      <dds-accordion-item
        id="{{ tabOptionsThree.label }}"
        [isOpen]="true"
        *ngIf="permissionObj['isExtCommNotesAndFeedbackVisible']"
      >
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">{{
              tabOptionsThree.label
            }}</span>
          </div>
        </span>
        <span [autosave-module]="savedetails" content>
          <div class="accordion-content">
            <div class="notes-paragraph eclipse-content-grey">
              Use this field to (i) provide notes about any relevant history,
              unusual aspects, or unique characteristics of the engagement that
              reviewers should be aware of or (ii) view feedback from reviewers
              on the document
            </div>
            <span
              *ngIf="checkStatusDiscontinued() && showDiscontinueMessage"
              class="padding-bottom-m"
            >
              <div class="sub-text-section margin-top-xs">
                <span class="icon-s icon-alert"></span>
                <span>
                  <div class="eclipse-header-grey">Submission Discontinued</div>
                  <div
                    class="vertical-line-green-s absolute padding-bottom-xxs"
                    *ngIf="isShowMoreDocumentNotes"
                  ></div>
                </span>
              </div>
              <div class="padding-left-m">
                <span>
                  <div class="margin-top-xs" *ngIf="isShowMoreDocumentNotes">
                    <div
                      class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                    >
                      {{ discontinueActionMessage?.resetedOn }} by
                      {{ discontinueActionMessage?.resetedBy }}
                    </div>
                    <div
                      class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                    >
                      Reason: {{ discontinueActionMessage?.reason }}
                    </div>
                  </div>
                </span>
                <dds-button
                  class="showMoreCls"
                  [theme]="showMoreOptions.theme ?? emptySpace"
                  [kind]="showMoreOptions.kind ?? emptySpace"
                  [size]="showMoreOptions.size ?? emptySpace"
                  [width]="showMoreOptions.width ?? emptySpace"
                  [icon]="showMoreOptions.icon ?? emptySpace"
                  [isIconLeft]="showMoreOptions.isIconLeft ?? false"
                  [role]="showMoreOptions.role ?? emptySpace"
                  [ariaLabel]="showMoreText"
                  [customClass]="showMoreOptions.customClass ?? emptySpace"
                  (clicked)="clickToShowDocumentMoreLepMessage()"
                  >{{ showMoreText }}</dds-button
                >
              </div>
            </span>
            <span
              *ngIf="checkStatusAdminCompleted() && showAdminCompleteMessage"
              class="padding-bottom-m"
            >
              <div class="sub-text-section margin-top-xs">
                <span class="icon-s icon-alert"></span>
                <span>
                  <div class="eclipse-header-grey">
                    Submission admin closed on
                  </div>
                  <div
                    class="vertical-line-green-s absolute padding-bottom-xxs"
                    *ngIf="isShowMoreDocumentNotes"
                  ></div>
                </span>
              </div>
              <div class="padding-left-m">
                <span>
                  <div class="margin-top-xs" *ngIf="isShowMoreDocumentNotes">
                    <div
                      class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                    >
                      {{ adminCompleteActionMessage?.resetedOn }} by
                      {{ adminCompleteActionMessage?.resetedBy }}
                    </div>
                    <div
                      class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                    >
                      Reason: {{ adminCompleteActionMessage?.reason }}
                      {{ adminCompleteActionMessage?.additionalDetails }}
                    </div>
                  </div>
                </span>
                <dds-button
                  class="showMoreCls"
                  [theme]="showMoreOptions.theme ?? emptySpace"
                  [kind]="showMoreOptions.kind ?? emptySpace"
                  [size]="showMoreOptions.size ?? emptySpace"
                  [width]="showMoreOptions.width ?? emptySpace"
                  [icon]="showMoreOptions.icon ?? emptySpace"
                  [isIconLeft]="showMoreOptions.isIconLeft ?? false"
                  [role]="showMoreOptions.role ?? emptySpace"
                  [ariaLabel]="showMoreText"
                  [customClass]="showMoreOptions.customClass ?? emptySpace"
                  (clicked)="clickToShowDocumentMoreLepMessage()"
                  >{{ showMoreText }}</dds-button
                >
              </div>
            </span>
            <div>
              <div class="row">
                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="notesAndFeedback"
                          [(ngModel)]="detailsRequestBody.NotesAndFeedback"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="enter_text_placeholder"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="
                            internalQrmCommunicationTextareaMaxlength
                          "
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          [readonly]="
                            !permissionObj['isExtCommNotesAndFeedbackEnable'] ||
                            disableFieldOnReadOnlyStatus()
                          "
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </dds-accordion-item>
      <dds-accordion-item id="{{ tabOptionsFour.label }}" [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">{{
              tabOptionsFour.label
            }}</span>
          </div>
        </span>

        <span content>
          <app-external-communication-workflow-submission
            [ppmdName]="ppmdName"
            [submissionId]="submissionId"
            [submissionStatus]="
              submissionDetailModel?.submissionStatus ?? _blank
            "
            [permissionObj]="permissionObj"
            [isReturnForSelfReviewVisible]="isReturnToSelfReviewButtonVisible"
            [isConfirmButtonVisible]="isConfirmButtonVisible"
            (submissionLoadConfirmMsg)="submissionReload($event)"
            [isSelfReview]="
              submissionDetailModel?.selfReviewEligibility === yesValue
            "
            [isWorkflowPractitionerCompleteMsg]="
              isWorkflowPractitionerCompleteMsg
            "
            [confirmedDate]="submissionDetailModel?.confirmedDate ?? _blank"
            (confirmBtnClickEventEmitter)="confirmSubmission($event)"
            (reviewerListValue)="checkReviewerList($event)"
            [isPrescreenVisible]="isPrescreenButtonVisible"
            [isReturnToReworkVisible]="isWorkFlowTableReworkBtnVisible"
            [isDisabled]="disableFieldOnReadOnlyStatus()"
            [isCompleteReviewVisible]="isCompleteReviewButtonVisible"
            (prescreenClickHandler)="openCompleteReviewModal($event, false)"
            (completeReviewClickHandler)="openCompleteReviewModal($event, true)"
            (returnToReworkEventEmitter)="handleReturnToRework()"
            (triggerGetSubmissionDetails)="
              getSubmissionDetails(submissionId, true)
            "
          ></app-external-communication-workflow-submission>
        </span>
        <!-- TODO : backend not giving confirmedDate to show in workflow table post confimed-->
      </dds-accordion-item>
      <dds-accordion-item
        id="{{ tabOptionsSeven.label }}"
        *ngIf="permissionObj['isExtCommInternalQRMVisible'] && isVisible"
        [isOpen]="true"
      >
        <span header class="accordion-header">
          <div class="section-header margin-bottom-s">
            <span class="eclipse-section-header"
              >Internal QRM Team communication</span
            >
          </div>
        </span>
        <dds-list-item header [isClickable]="false" [hasBorder]="false">
        </dds-list-item>
        <span [autosave-module]="savedetails" content>
          <div class="accordion-content">
            <div>
              <div class="row">
                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="internalQRMTeamCommunication"
                          [(ngModel)]="detailsRequestBody.InternalQRM"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="'Enter your comments'"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="
                            internalQrmCommunicationTextareaMaxlength
                          "
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          [disabled]="
                            !permissionObj['isExtCommInternalQRMEnable'] ||
                            disableFieldOnReadOnlyStatus()
                          "
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></div
        ></span>
      </dds-accordion-item>
    </dds-accordion>
  </div>
</div>
