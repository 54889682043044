<div class="row">
  <div class="col-12">
    <span *ngIf="!contractSignedExecuted" class="icon-required-yellow padding-bottom-16"></span>
    <span>{{ co_elsow_radioGroupOptions.title }}</span>
  </div>

  <div class="row padding-top-xs">
    <div class="col-12">
      <dds-radio-group
        [(ngModel)]="contractSignedExecuted"
        [isUncheckable]="co_elsow_radioGroupOptions.isUncheckable"
        [isRequired]="co_elsow_radioGroupOptions.isRequired"
        [errorState]="co_elsow_radioGroupOptions.errorState"
        (stateChanged)="coAndElSowStateChanged($event)"
      >
        <ng-container *ngFor="let radio of co_elsow_radio_values">
          <dds-radio
            [value]="radio.value"
            [customClass]="'padding-right-half-rem'"
            [label]="radio.options.label"
            [theme]="radio.options.theme"
          ></dds-radio>
          <br />
        </ng-container>
      </dds-radio-group>
    </div>
  </div>
</div>

<div class="d-flex" *ngIf="contractSignedExecuted === CoElSowOptionValueYes">
  <app-wbs-charge-code
    [wbsChargeCode]="wbsChargeCode"
    [wbsDescription]="wbsDescription"
    [dataSubmissionId]="dataSubmissionId"
    [dataOpportunityId]="dataOpportunityId"
    [archiveDetails]="archiveDetails"
    [swiftDetails]="swiftDetails"
    (wbsOrArchiveChange)="onWbsOrArchiveChange($event)"
  /> 
</div>

