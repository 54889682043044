import { OpportunityComponent } from "./opportunity/opportunity.component";
import { SubmissionComponent } from "./submission/submission.component";

export const PROFILE_ROUTES = [
    {
        path: 'opportunity/:opportunityId',
        children: [
            {
                path: '',
                component: OpportunityComponent
            }
        ]
    },
    {
        path: 'submission/:submissionId',
        data: {
            name: 'Submission'
        },
        children: [
            {
                path: '',
                component: SubmissionComponent
            }
        ]
    }
]
