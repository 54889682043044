<div>
  <table class="dds-data-table">
    <thead class="dds-data-table__header">
      <tr>
        <ng-container *ngFor="let column of sampleColumns">
          <th class="dds-data-table__header-cell">
            <div class="dds-data-table__content">
              <span>{{ column.header || column.name }}</span>
            </div>
          </th>
        </ng-container>
      </tr>
    </thead>

    <tbody class="dds-data-table__body">
      <ng-container *ngFor="let row of adminExtCommTrainingTableData; let i = index">
        <tr class="dds-data-table__row">
          <td *ngFor="let column of sampleColumns" class="dds-data-table__cell">
            <ng-container *ngIf="column.name === 'editIcon'">
              <span
                #editButton
                class="dds-icon dds-icon_edit__s__filled edit-icon"
                (click)="onEditTypeClick($event, row)"
              ></span>
            </ng-container>

            <ng-container *ngIf="column.name === 'Activation status'">
              <dds-toggle-group>
                <dds-toggle
                  [label]="''"
                  [theme]="'green'"
                  (stateChanged)="toggleActiveStatus(row)"
                  [value]="row.isActive"
                ></dds-toggle>
              </dds-toggle-group>
            </ng-container>
            <ng-container *ngIf="column.name === 'comments'">
              {{ row[column.name] }}
            </ng-container>
            <ng-container *ngIf="column.name === 'name'">
              {{ row.name }}
            </ng-container>
            <ng-container *ngIf="column.name === 'modifiedBy'">
              {{ row.modifiedBy }}
            </ng-container>
            <ng-container *ngIf="column.name === 'modifiedDate'">
              {{ row.modifiedDate | date }}
            </ng-container>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>