import {
  ButtonKind,
  ExtThemes,
  Size,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';

export const ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primary,
  size: Size.md,
  width: WidthState.fixed,
};

export const UploaderOptions = {
  heading: '',
  dragAndDropText: 'Drag and drop file(s)',
  description: 'All common file types are supported including: docx, pptx, pdf, .zip, etc. Max size of 50MB per file',
  isDragAndDrop: true,
  isMultiple: true,
  acceptFormats: '',
  acceptExtensions: '',
  restrictedFormats: '',
  restrictedExtensions: '',
  exceptSymbols: '',
  btnLabel: 'Select file(s)',
  chooseFileBtnKind: 'primary',
  isDownloadable: false,
  allowFilesWithoutType: false,
  disabled: false,
  isInverse: false,
};
