import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SuggestionsTagsInputOptions } from '@usitsdasdesign/dds-ng/suggestions-tags-input';
import {
  INPUT_SEARCH_OPTIONS,
  selectOptionsArchive,
} from '../close-out-change-order/close-out-change-order.helper';
import {
  ArchiveDetailsExtendedModel,
  ArchiveDetailsModel,
  ArchiveDetailsResponseModel,
  ArchiveDetailsUpdatingModel,
  WbsArchiveReceivedModel,
  WbsChargeCodeDetailsModel,
  wbsChargeCodeRequestModel,
  WbsResponseModel,
  WbsWithArchiveDetailsModel,
} from '../../../../common/models/wbs-picker.model';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { CloseOutService } from '../../../../http/intake/close-out.service';
import {
  copyToClipboard,
  setArchiveDetails,
} from '../../../common/intake.util';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { IntakeConstant } from '../../../constants/intake.constant';
import { SwiftDetails } from '../../../../common/models/intake-submission-details-common-section.model';
import { Themes } from '@usitsdasdesign/dds-ng/shared';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { HttpErrorResponse } from '@angular/common/http';
import { PermissionsObj } from '../../../../common/models/common-models';
import { SubmissionService } from '../../../../http/intake/submission.service';
@Component({
  selector: 'app-wbs-charge-code',
  templateUrl: './wbs-charge-code.component.html',
  styleUrl: './wbs-charge-code.component.less',
})
export class WbsChargeCodeComponent implements OnInit, OnDestroy {
  archiveNotListedMsg: string = IntakeConstant.ARCHIVE_NOT_LISTED;
  _EMPTY: string = IntakeConstant.EMPTY_SPACE;
  constructor(
    private readonly submissionservice: SubmissionService,
    private readonly closeoutService: CloseOutService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly _cdr: ChangeDetectorRef
  ) {}
  @Input() wbsChargeCode: string = this._EMPTY;
  @Input() wbsDescription: string = this._EMPTY;
  @Input() dataSubmissionId: number | null = null;
  @Input() dataOpportunityId: string = this._EMPTY;
  @Input() wbsPendingCheckbox: boolean = false;
  pendingWBSCheckboxValue: boolean = false;
  @Input() set archiveDetails(details: WbsArchiveReceivedModel | null) {
    if (
      details?.archiveNumber != null &&
      details?.archiveNumber !== IntakeConstant.ARCHIVE_NOT_LISTED
    ) {
      this.archive = details;
      this.archiveDescription =
        details?.archiveNumber + ' ' + details?.archiveName;
      this.showArchiveDropdown = false;
      this.selectedArchive = details?.archiveNumber;
    } else if (details?.archiveNumber === IntakeConstant.ARCHIVE_NOT_LISTED) {
      this.archiveDescription = details?.archiveDescription ?? this._EMPTY;
      this.showArchiveDropdown = false;
      this.selectedArchive = details?.archiveNumber ?? this._EMPTY;
    } else {
      this.archiveDescription = this._EMPTY;
      this.showArchiveDropdown = true;
    }
  }
  @Input() swiftDetails: SwiftDetails | null = null;
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;

  @Output() wbsOrArchiveChange: EventEmitter<WbsWithArchiveDetailsModel> =
    new EventEmitter<WbsWithArchiveDetailsModel>();

  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  archive: WbsArchiveReceivedModel | null = null;
  showEditWbsChargeCode: boolean = false;
  reloadWbsChargeCode: boolean = true;
  searchList: string[] = [];
  options: SuggestionsTagsInputOptions = INPUT_SEARCH_OPTIONS;
  wbsObjectArray: WbsChargeCodeDetailsModel[] = [];
  archiveSelectOptions = selectOptionsArchive;
  selectedArchive: string = this._EMPTY;
  wbsInputDisabled: boolean = false;
  actionDropdownList: SelectItemOptions[] = [];
  dropdownListData!: ArchiveDetailsResponseModel;
  wbsWarning: boolean = true;
  showArchiveDropdown: boolean = false;
  archiveDescription: string = this._EMPTY;
  checkboxThemeColor: string = IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR;
  emptyString: string = this._EMPTY;
  defaultForEmptyFields: string = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;

  tooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 15,
    tooltipIsOutsideClick: false,
    tooltipHasBeak: true,
    tooltipIsDisabled: false,
    tooltipIsInverse: false,
    tooltipIsDynamic: false,
    tooltipCustomClass: IntakeConstant.EMPTY_SPACE,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipShowDelay: 0,
    tooltipHideDelay: 0,
    tooltipTheme: Themes.dark,
  };

  ngOnInit(): void {
    this.pendingWBSCheckboxValue = this.wbsPendingCheckbox;
    if (this.wbsChargeCode === this._EMPTY || this.wbsChargeCode === null) {
      this.fetchWbsChargeCode(0);
      this.showEditWbsChargeCode = true;
    }
    if (
      this.archiveDetails === null ||
      this.archiveDetails.archiveName === this._EMPTY ||
      this.archiveDetails.archiveNumber === this._EMPTY
    ) {
      this.fetchArchiveDropDownData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['wbsPendingCheckbox'] &&
      changes['wbsPendingCheckbox'].currentValue !==
        changes['wbsPendingCheckbox'].previousValue
    ) {
      this.wbsPendingCheckboxChange(changes['wbsPendingCheckbox'].currentValue);
    }
  }

  clipboardCopy(): void {
    if (this.wbsChargeCode != null && this.wbsChargeCode !== this._EMPTY) {
      copyToClipboard(this.wbsChargeCode);
    }
  }

  editWBSChargeCode(): void {
    if (this.permissionObj['isIntakeSubmissionWBSEnable']) {
      this.wbsChargeCode = this._EMPTY;
      this.wbsDescription = this._EMPTY;
      this.wbsPendingCheckbox = false;
      this.fetchWbsChargeCode(1);
      let archiveDetails: ArchiveDetailsUpdatingModel = setArchiveDetails(
        this._EMPTY,
        []
      );
      const archiveDetailsWithWbsDetails = {
        ...archiveDetails,
        wbsChargeCode: this.wbsChargeCode,
        wbsDescription: this.wbsDescription,
        wbsPending: this.pendingWBSCheckboxValue,
      };
      this.wbsOrArchiveChange.emit(archiveDetailsWithWbsDetails);
      this.showEditWbsChargeCode = true;
      this.selectedArchive = this._EMPTY;
      this.showArchiveDropdown = false;
      this.archiveDescription = this._EMPTY;
      this.showDescriptionAndArchive();
      this.showArchiveDropdown = true;
      this.wbsWarning = true;
    }
  }

  fetchWbsChargeCode(callfrom: number): void {
    this.progressIndicator.show();
    this.searchList = [];
    if (callfrom !== IntakeConstant.SEARCH_STRING_LIMIT) {
      this.wbsChargeCode = this._EMPTY;
    }
    this.wbsPendingCheckbox = false;
    const requestBody = this.createRequestBody();
    this.closeoutService
      .getWbsChargeCodes(requestBody)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: WbsResponseModel) => {
          if (response) {
            this.wbsObjectArray = response.wbsChargeCodeDetails;
            if (response.totalCount === 0) {
              this.searchList.push('No data found');
            } else {
              this.searchList = response.wbsChargeCodeDetails.map(
                (wbs) => wbs.wbsChargeCode
              );
              this.reloadWbsChargeCode = false;
              if (
                this.searchList.length === 1 &&
                callfrom === 0 &&
                !this.pendingWBSCheckboxValue
              ) {
                this.wbsChargeCode = this.searchList[0];
                this.onWbsChangeValue();
              }
              setTimeout(() => {
                this.reloadWbsChargeCode = true;
                this.progressIndicator.hide();
              }, 10);
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('error', error);
        },
      });
  }

  onWbsChangeValue(): void {
    if (this.isElementInArray(this.wbsChargeCode, this.searchList)) {
      const selectedWBSCode: WbsChargeCodeDetailsModel | undefined =
        this.wbsObjectArray.find(
          (wbs: WbsChargeCodeDetailsModel) =>
            wbs.wbsChargeCode === this.wbsChargeCode
        );
      if (selectedWBSCode) {
        this.wbsDescription = selectedWBSCode?.wbsDescription;
        this.wbsChargeCode = selectedWBSCode?.wbsChargeCode;
        if (!this.pendingWBSCheckboxValue) {
          this.wbsWarning = false;
          this.showEditWbsChargeCode = false;
          this.selectedArchive = this._EMPTY;
          this.showArchiveDropdown = false;
          this.archiveDescription = this._EMPTY;
          this.updateWbsValues();
          this.fetchArchiveDropDownData();
          this.showArchiveDropdown = true;
        }
      }
    } else {
      if (
        this.wbsChargeCode.trim().length >=
        IntakeConstant.CHARACTER_LIMIT_IN_SEARCH
      ) {
        this.fetchWbsChargeCode(IntakeConstant.SEARCH_STRING_LIMIT);
      }
    }
  }

  updateWbsValues(): void {
    let wbsWithArchiveDetails: WbsWithArchiveDetailsModel = {
      ...setArchiveDetails(this._EMPTY, []),
      wbsChargeCode: this.wbsChargeCode,
      wbsDescription: this.wbsDescription,
      wbsPending: this.pendingWBSCheckboxValue,
    };
    this.wbsOrArchiveChange.emit(wbsWithArchiveDetails);
  }

  wbsPendingCheckboxChange(event: boolean): void {
    this.pendingWBSCheckboxValue = event;
    this.wbsOrArchiveChange.emit({
      wbsChargeCode: this._EMPTY,
      wbsDescription: this._EMPTY,
      wbsPending: event,
      ...setArchiveDetails(this._EMPTY, []),
    });
    this.wbsInputDisabled = event;
    this.wbsWarning = !event;
  }

  isElementInArray(element: string | null, array: string[]): boolean {
    if (element) return array.includes(element);
    else return false;
  }

  onArchiveSelect(): void {
    this.showArchiveDropdown = false;
    if (this.selectedArchive !== this.archiveNotListedMsg) {
      const selectedArchiveDetails = this.dropdownListData.archiveDetails.find(
        (item) => item.archiveNumber === this.selectedArchive
      );

      this.progressIndicator.show();
      this.archiveDescription =
        selectedArchiveDetails?.archiveNumber +
        ' ' +
        selectedArchiveDetails?.archiveName;
      this.closeoutService
        .postGetArchiveDetails(this.selectedArchive)
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe({
          next: (response: ArchiveDetailsExtendedModel) => {
            if (response) {
              const wbsWithArchiveDetails: WbsWithArchiveDetailsModel = {
                archiveNumber:
                  selectedArchiveDetails?.archiveNumber ?? this._EMPTY,
                archiveName: selectedArchiveDetails?.archiveName ?? this._EMPTY,
                archiveID: selectedArchiveDetails?.archiveID ?? this._EMPTY,
                archiveFolder:
                  selectedArchiveDetails?.archiveFolder ?? this._EMPTY,
                archiveFoldersArray:
                  selectedArchiveDetails?.archiveFoldersArray ?? [],
                clientName: selectedArchiveDetails?.clientName ?? this._EMPTY,
                clientNumber:
                  selectedArchiveDetails?.clientNumber ?? this._EMPTY,
                partner: selectedArchiveDetails?.partner ?? this._EMPTY,
                business: selectedArchiveDetails?.business ?? this._EMPTY,
                archiveDescription: response.archiveDescription,
                archiveStatus: response.archiveStatus,
                wbsChargeCode: this.wbsChargeCode,
                wbsDescription: this.wbsDescription,
                wbsPending: this.pendingWBSCheckboxValue,
              };

              wbsWithArchiveDetails.wbsChargeCode = this.wbsChargeCode;

              wbsWithArchiveDetails.wbsDescription = this.wbsDescription;
              wbsWithArchiveDetails.wbsPending = this.pendingWBSCheckboxValue;
              this.submissionservice.setOnLoadSubmissionPageData(false);
              this.wbsOrArchiveChange.emit(wbsWithArchiveDetails);
            }
            this.progressIndicator.hide();
          },
          error: (error: HttpErrorResponse) => {
            this.progressIndicator.hide();
            console.error('error', error);
          },
        });
    } else {
      this.archiveDescription = this.archiveNotListedMsg;
      const emitDetails = {
        wbsChargeCode: this.wbsChargeCode,
        wbsDescription: this.wbsDescription,
        wbsPending: false,
        ...setArchiveDetails(this._EMPTY, []),
      };
      this.wbsOrArchiveChange.emit({
        ...emitDetails,
        archiveNumber: this.archiveNotListedMsg,
      });
    }
  }

  fetchArchiveDropDownData(): void {
    this.progressIndicator.show();
    this.actionDropdownList = [];
    this.closeoutService
      .postSearchArchiveByWbs(this.wbsChargeCode)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: ArchiveDetailsResponseModel) => {
          if (response) {
            this.dropdownListData = response;
            this.progressIndicator.hide();
            this.actionDropdownList = this.dropdownListData.archiveDetails.map(
              (item: ArchiveDetailsModel) => {
                return {
                  heading: item.archiveNumber + ' ' + item.archiveName,
                  value: item.archiveNumber,
                };
              }
            );
            this.actionDropdownList.push({
              heading: this.archiveNotListedMsg,
              value: this.archiveNotListedMsg,
            });
            this._cdr.detectChanges();
          }
        },
        error: (error: HttpErrorResponse) => {
          this.progressIndicator.hide();
          if (error.status === 400) {
            this.actionDropdownList = [
              {
                heading: this.archiveNotListedMsg,
                value: this.archiveNotListedMsg,
              },
            ];
            this.selectedArchive = this.actionDropdownList[0].value;
            this.onArchiveSelect();
            this._cdr.detectChanges();
          }
          console.error('error', error, error.status);
        },
      });
  }

  editArchiveDetails(): void {
    this.showArchiveDropdown = true;
    const emitDetails = {
      wbsChargeCode: this.wbsChargeCode,
      wbsDescription: this.wbsDescription,
      wbsPending: false,
      ...setArchiveDetails(this._EMPTY, []),
    };
    this.wbsOrArchiveChange.emit(emitDetails);
  }

  showDescriptionAndArchive(): boolean {
    return (
      (this.wbsChargeCode !== this._EMPTY || this.wbsChargeCode !== null) &&
      !this.showEditWbsChargeCode
    );
  }

  createRequestBody(): wbsChargeCodeRequestModel {
    return {
      opportunityId: this.dataOpportunityId,
      submissionId: this.dataSubmissionId,
      searchTerm: this.wbsChargeCode,
      pageNumber: 0,
      pageSize: IntakeConstant.PAGE_NUMBER,
    };
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
