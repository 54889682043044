<div class="dds-modal-overlay" (click)="closeModal()"></div>

<div ddsResize class="dds-modal dds-modal_lg">
  <div class="dds-modal__header eclipse-header">Confirm Rush Request</div>

  <div class="dds-modal__body">
    <div>
      <div class="comments-text-label eclipse-small-header-grey">
        <p>
          The requested review date is prior to the standard turnaround time for
          External Communications. Valid rush requests may be accommodated based
          on the circumstances and may delay other Ext Comms that are already in
          the queue. Consider whether a rush is warranted or modify the
          requested review date.<br />Submit at the earliest opportunity to
          prevent the risk of extending the requested review date.
        </p>
      </div>
      <div>
        <div class="padding-top-xs value-box">
          <dds-textarea
            [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
            [maxLength]="textareaOptions.maxLength ?? 0"
            [minHeight]="textareaOptions.minHeight ?? 150"
            [placeholder]="textareaOptions.placeholder ?? ''"
            [(ngModel)]="textareaValue"
            [size]="htmlElementSize"
            (valueChanged)="valueChanged()"
          ></dds-textarea>
        </div>
        <div class="d-flex flex-right margin-top-s">
          <div class="margin-right-s">
            <dds-button class="custom-button" (click)="resetDatePicker()"
              >Modify Date</dds-button
            >
          </div>
          <div>
            <dds-button
              class="custom-button"
              [kind]="cancelButton.kind ?? ''"
              [disabled]="cancelButton.disabled ?? true"
              (click)="closeModal('update')"
              >Ok</dds-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
