import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { DocumentLinkUploadComponent } from './document-upload/document-link-upload/document-link-upload.component';
import { DocumentEditPopupComponent } from './document-upload/document-edit-popup/document-edit-popup.component';
import { IntakeDocumentUploadComponents } from './document-upload/intake-document-upload/intake-document-upload.component';
import { DocumentTableComponent } from './document-upload/document-table/document-table.component';
import { ButtonOptions, UploaderOptions } from './document-settings';
import { IntakeDocumentSubmissionTypeCode } from '../../../constants/intake_submission_type_code.enum';
import { IntakeDocumentSubmissionStatus } from '../../../constants/intake_document_submission_status.enum';
import { IntakeDocumentTableModel } from '../../../../common/models/intake-document-table.model';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrl: './document.component.less',
})
export class DocumentComponent {
  @Input() type: string = '';
  @Input() submissionTypeId: number = 0;
  @Input() submissionStatusId: number = 0;
  @Input() submissionId: number  = 0;
  @Input() progressiveStatus: boolean | null = null;
  @Input() submissionTypeCode: string | undefined = '';
  @Input() submissionStatus: string | undefined = '';
  @Output() documentTableData: EventEmitter<IntakeDocumentTableModel[]> =
    new EventEmitter<IntakeDocumentTableModel[]>();
  @ViewChild(DocumentTableComponent) tableComponent!: DocumentTableComponent;
  constructor(private readonly modalService: ModalService) {}

  options = ButtonOptions;
  uploadAreaOptions = UploaderOptions;

  triggerDocumentTableDataApi(): void {
    this.tableComponent.getDocumentMetaData();
  }

  openDocumentLinkModel(modalBtn?: any): void {
    let modalRef = this.modalService.open(DocumentLinkUploadComponent, {
      documentInitiatedDetails: {
        submissionId: this.submissionId,
        moduleName: 'Intake',
        submissionStatusId: this.submissionStatusId,
        submissionTypeId: this.submissionTypeId,
        type: this.type,
      },
    });
    modalRef.onClosed().subscribe((response: any) => {
      this.triggerDocumentTableDataApi();
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  openDocumentEditModel(modalBtn?: any): void {
    let modalRef = this.modalService.open(DocumentEditPopupComponent);
    modalRef.onClosed().subscribe((response: any) => {
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  openUploadModel(event: any, modalBtn?: ButtonComponent): void {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(IntakeDocumentUploadComponents, {
      isFooter: true,
      size: 'lg',
      isInverse: false,
      documentInitiatedDetails: {
        moduleName: 'Intake',
        submissionStatusId: this.submissionStatusId,
        submissionTypeId: this.submissionTypeId,
        submissionId: this.submissionId,
        documentUploadCount: 1,
        file: event,
        type: this.type,
      },
    });

    modalRef.onClosed().subscribe((response: any) => {
      this.triggerDocumentTableDataApi();
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  removeBodyOverflow(): void {
    document.body.style.overflowY = 'hidden';
  }
  documentTableDataValue(data: IntakeDocumentTableModel[]): void {
    this.documentTableData.emit(data);
  }
}
