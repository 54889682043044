import {
  DatepickerOptions,
  DatepickerStates,
} from '@usitsdasdesign/dds-ng/datepicker';
import { Size, TabThemeType, Themes } from '@usitsdasdesign/dds-ng/shared';
import { TabOptions, TabsOptions } from '@usitsdasdesign/dds-ng/tabs';

export const tabOptionsOne: TabOptions = {
  label: 'Submission details',
};

export const tabRisk: TabOptions = {
  label: 'Risk assessment',
};
export const YES_NO = {
  YES: 'Yes',
  NO: 'No'
};
export const tabOptionsTwo: TabOptions = {
  label: 'Documents',
};
export const tabOptionsThree: TabOptions = {
  label: 'Notes/feedback',
};
export const tabOptionsFour: TabOptions = {
  label: 'Workflow',
};

export const tabOptionsFive: TabOptions = {
  label: 'Communication attributes',
};

export const tabOptionsSeven: TabOptions = {
  label: 'Internal QRM team communication',
};

export const tabContainerOptions: TabsOptions = {
  theme: Themes.green,
  themeType: TabThemeType.border,
  size: Size.md,
  ariaLabel: 'Horizontal tabs',
  disabled: false,
  isInverse: false,
  customClass: '',
  isResponsive: false,
};

export const datePickerOptions: DatepickerOptions = {
  size: Size.md,
  placeholder: 'Select date',
  format: 'MM/dd/yyyy',
  minMode: DatepickerStates.day,
  isResponsive: true,
  isManualInput: false,
  disabledDates: { before: new Date() }, // This disables all dates before the current date
};
