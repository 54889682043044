import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EclipseCommonModule } from '../common/common.module';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { RouterModule } from '@angular/router';
import { AUDIT_TRAIL_ROUTES } from './audit-trail.states';
import { AuditTrailTableComponent } from './audit-trail/audit-trail-table/audit-trail-table.component';
import { CommonAuditTrailComponent } from './audit-trail/common-audit-trail/common-audit-trail.component';
import { CommonAuditTrailHeaderComponent } from './audit-trail/common-audit-trail/common-audit-trail-header/common-audit-trail-header.component';

@NgModule({
  declarations: [AuditTrailComponent, AuditTrailTableComponent, CommonAuditTrailComponent, CommonAuditTrailHeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    EclipseCommonModule,
    ReactiveFormsModule,
    LibModule,
    RouterModule.forChild(AUDIT_TRAIL_ROUTES),
  ],
})
export class AuditTrailModule {}
