import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  PrimaryButtonOptions,
  SHOW_MORE_BTN_OPTIONS,
  UploaderAreaOptions,
} from '../../../external-communications.helper';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { ExternalCommunicationDocumentUploadComponent } from '../external-communication-document-upload/external-communication-document-upload.component';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { UploaderOptions } from '@usitsdasdesign/dds-ng/uploader';
import { CommunicationType } from '../../../constants/communication-type';
import { ExternalCommunicationDocumentLinkUploadComponent } from '../external-communication-document-link-upload/external-communication-document-link-upload.component';
import { DocumentStatus } from '../../../../common/models/document-dropdown.models';
import { Subject, takeUntil } from 'rxjs';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import { ExternalCommunicationDocumentTableComponent } from '../external-communication-document-table/external-communication-document-table.component';
import {
  boxMessages,
  SaveSubmissionDocument,
} from '../../../../common/models/external-communication.model';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { SubmissionService } from '../../../../http/intake/submission.service';
import {
  configurationType,
  employeeRoles,
} from '../../../../common/models/common-models';
import { RoleEnum } from '../../../../intake/constants/Role.enum';
import { UserService } from '../../../../http/user.service';
import { PermissionCheck } from '../../../../common/validations/PermissionCheck';
import { SecurityWebapiService } from '../../../../http/security/security-webapi.service';

@Component({
  selector: 'app-external-communication-documents',
  templateUrl: './external-communication-documents.component.html',
  styleUrl: './external-communication-documents.component.less',
})
export class ExternalCommunicationDocumentsComponent extends PermissionCheck {
  @Input() submissionId!: number;
  @Input() submissionTypeCode: string =
    ExternalCommunicationConstant.EMPTY_SPACE;
  @Input() submissionStatusId!: number;
  @Input() submissionStatus!: string | null;
  @Input() progressiveStatus!: boolean | null;
  @Input() communicationType!: string;
  @Input() communicationTypeId!: number;
  @Output() documentTableData: EventEmitter<SaveSubmissionDocument[]> =
    new EventEmitter<SaveSubmissionDocument[]>();
  @ViewChild(ExternalCommunicationDocumentTableComponent)
  tableComponent!: ExternalCommunicationDocumentTableComponent;

  isSelfReviewButtonVisible: boolean = false;
  showMoreGuidance: boolean = true;
  boxMessages: boxMessages[] = [];
  showMoreButtonOptions: ButtonOptions = SHOW_MORE_BTN_OPTIONS;
  showMoreButtonText: string = ExternalCommunicationConstant.SHOW_LESS_TEXT;
  uploadAreaOptions: UploaderOptions = UploaderAreaOptions;
  buttonOptions: ButtonOptions = PrimaryButtonOptions;
  emptyString: string = ExternalCommunicationConstant.EMPTY_SPACE;

  documentStatusDropdownData: SelectItemOptions[] = [];
  documentTypeDropdownData: SelectItemOptions[] = [];
  configData: configurationType[] = [];
  addLinkGuidance: string = ExternalCommunicationConstant.EMPTY_SPACE;

  isStatusColumnVisible: boolean = false;
  userHasAccess: boolean = false;
  roles: employeeRoles[] = [];

  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  isGuidanceUploadDocumentForReviewVisible: boolean = false;
  isDragAndDropEnable: boolean = false;
  isAddLinkVisible: boolean = false;
  isAddLinkEnable: boolean = false;

  // These below properties are not used in the component
  isDragAndDropVisible: boolean = false;
  isAttachDocumentsVisible: boolean = false;
  isAttachDocumentsEnable: boolean = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly modalService: ModalService,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly submissionService: SubmissionService,
    private readonly userService: UserService,
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
  }

  ngOnInit(): void {
    this.isGuidanceUploadDocumentForReviewVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_DocumentsGuidanceUploadDocumentForReview,
      this.permissionType.Visible
    );
    this.isDragAndDropVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_DocumentsDragAndDrop,
      this.permissionType.Visible
    );
    this.isDragAndDropEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_DocumentsDragAndDrop,
      this.permissionType.Enable
    );
    this.isAttachDocumentsVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_DocumentsAttachDocuments,
      this.permissionType.Visible
    );
    this.isAttachDocumentsEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_DocumentsAttachDocuments,
      this.permissionType.Enable
    );
    this.isAddLinkVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_DocumentsAddLink,
      this.permissionType.Visible
    );
    this.isAddLinkEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_DocumentsAddLink,
      this.permissionType.Enable
    );

    this.getConfigLabels();
    this.getDocumentTypeDropdownData();
    this.getDocumentStatusDropdownData(this.submissionStatusId);
    this.getRoles()
      .then(() => {
        this.updateStatusColumnVisibility();
      })
      .catch((err) => {
        console.error('An error occurred while getting roles:', err);
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setGreenLineHeight();
    }, 0);
  }

  expandGuidance(): void {
    this.showMoreGuidance = !this.showMoreGuidance;
    if (!this.showMoreGuidance) {
      this.showMoreButtonText = ExternalCommunicationConstant.SHOW_MORE_TEXT;
      this.showMoreButtonOptions.icon =
        ExternalCommunicationConstant.DDS_ICON_ARROW_DOWN;
    } else {
      this.showMoreButtonText = ExternalCommunicationConstant.SHOW_LESS_TEXT;
      this.showMoreButtonOptions.icon =
        ExternalCommunicationConstant.DDS_ICON_ARROW_UP;
    }
    this.cdr.detectChanges();
    this.setGreenLineHeight();
  }

  setGreenLineHeight(): void {
    const expandedSectionHeight = document.getElementById(
      'guidanceExpandedSection'
    )?.offsetHeight;
    if (expandedSectionHeight) {
      document.getElementById(
        'ext-comm-doc-guideline-vertical-line'
      )!.style.height = expandedSectionHeight + 8 + 'px';
    }
  }

  removeBodyOverflow(): void {
    document.body.style.overflowY = 'hidden';
  }

  getRoles(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.currentLoggedInUser$
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (user) => {
            this.roles = user?.employeeRoles ?? [];
            resolve();
          },
          error: (err) => {
            console.error('An error occurred while getting roles:', err);
            reject(err);
          },
        });
    });
  }

  updateStatusColumnVisibility(): void {
    this.userHasAccess = false;
    this.isStatusColumnVisible = false;
    const rolesWithAccess: string[] = [
      RoleEnum.RISK_MANAGER,
      RoleEnum.SUPPORTING_RISK_MANAGER,
      RoleEnum.SPECIALIZED_RISK_MANAGER,
      RoleEnum.REVIEWER,
      RoleEnum.TECHNICAL_OR_INDEPENDENT_REVIEWER,
      RoleEnum.TECHNICAL_OR_INDEPENDENT_RM,
      RoleEnum.SYSTEM_ADMIN,
      RoleEnum.SYSTEM_ADMINISTRATOR,
      RoleEnum.RM_SUPPORT,
    ];
    this.isStatusColumnVisible = this.userHasAccess = this.roles.some((role) =>
      rolesWithAccess.includes(role?.roleName)
    );
  }

  triggerDocumentTableDataApi(): void {
    this.tableComponent.getDocumentMetaData();
  }

  getConfigLabels(): void {
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response) {
            this.configData = response;
            this.setLabels();
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  setLabels(): void {
    this.uploadAreaOptions.description =
      this.configData.find(
        (x) =>
          x.appConfigurationLabelKey ===
          'EC_DocumentSection_DocumentSupportingType'
      )?.appConfigurationLabelValue || UploaderAreaOptions.description;

    const guidanceNoteText = this.configData.find(
      (x) => x.appConfigurationLabelKey === 'EC_DocumentSection_GuidanceNote'
    )?.appConfigurationLabelValue;

    this.addLinkGuidance =
      this.configData.find(
        (x) =>
          x.appConfigurationLabelKey ===
          'EC_DocumentSection_GrantRiskManagerAccess'
      )?.appConfigurationLabelValue ??
      ExternalCommunicationConstant.EMPTY_SPACE;

    if (guidanceNoteText) {
      document.getElementById('guidanceExpandedSection')!.innerHTML =
        guidanceNoteText;
      this.setGreenLineHeight();
    }
  }

  getDocumentStatusDropdownData(submissionStatusId: number): void {
    this.externalCommService
      .getDocumentStatusDropdown(submissionStatusId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (result: DocumentStatus[]) => {
          const convertedArray = result.map((item) => ({
            value: item.documentStatusId,
            heading: item.documentStatusName,
          }));
          this.documentStatusDropdownData = convertedArray;
        },
        error: (err: any) => {
          console.error('An error occurred', err);
        },
      });
  }

  getDocumentTypeDropdownData(): void {
    this.externalCommService
      .getDocumentTypeDropdown()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (result: any) => {
          const convertedArray = result.map((item: any) => ({
            value: item.documentTypeId,
            heading: item.documentTypeName,
          }));
          this.documentTypeDropdownData = convertedArray;
        },
        error: (err: any) => {
          console.error('An error occurred', err);
        },
      });
  }

  openUploadModal(event: any, modalBtn?: ButtonComponent): void {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(
      ExternalCommunicationDocumentUploadComponent,
      {
        isFooter: true,
        size: 'lg',
        isInverse: false,
        documentInitiatedDetails: {
          moduleName: 'ExternalComm',
          submissionStatusId: this.submissionStatusId,
          submissionId: this.submissionId,
          documentUploadCount: 1,
          file: event,
        },
        documentStatusDropdown: this.documentStatusDropdownData,
        documentTypeDropdown: this.documentTypeDropdownData,
        isStatusColumnVisible: this.isStatusColumnVisible,
      }
    );

    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.triggerDocumentTableDataApi();
        event.splice(0, event.length);
        if (modalBtn) {
          modalBtn.focus();
        }
      });
  }

  openDocumentLinkModal(modalBtn?: any): void {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(
      ExternalCommunicationDocumentLinkUploadComponent,
      {
        documentInitiatedDetails: {
          submissionId: this.submissionId,
          moduleName: 'ExternalComm',
          submissionStatusId: this.submissionStatusId,
        },
        documentStatusDropdown: this.documentStatusDropdownData,
        documentTypeDropdown: this.documentTypeDropdownData,
        addLinkGuidanceText: this.addLinkGuidance,
        isStatusVisible: this.isStatusColumnVisible,
      }
    );
    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.triggerDocumentTableDataApi();
        if (modalBtn) {
          modalBtn.focus();
        }
      });
  }

  isAddLinkButtonVisible(): boolean {
    //TODO: Add check for self review, Non-self flow is true
    if (
      (this.isAddLinkEnable || this.isAddLinkVisible) &&
      (this.communicationType === CommunicationType.Podcasts ||
        this.communicationType === CommunicationType.Videos)
    ) {
      return true;
    } else return false;
  }

  isDocumentTableDisabled(): boolean {
    return false;
  }

  handleDocumentTableData(data: any): void {
    //TODO: For later stories, if not needed remove
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
