import { Component } from '@angular/core';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { buttonOptions } from '../dashboard.helper';

@Component({
  selector: 'app-recently-viewed',
  templateUrl: './recently-viewed.component.html',
  styleUrl: './recently-viewed.component.less',
})
export class RecentlyViewedComponent {
  options: ButtonOptions = buttonOptions;

  click(event: Event) {}
}
