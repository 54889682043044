import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { GlobalEngagementService } from '../../../http/global-engagement/global-engagement.service';
import { Subject, takeUntil } from 'rxjs';
import { DocumentService } from '../../../http/document/document.service';
import {
  Column,
  DocumentTableData,
  sampleColumns,
} from '../../../eclipse-header/export-pdf-popup/export-pdf-popup-component-helper';
import {
  ButtonKind,
  ExtThemes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../intake/constants/intake.constant';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-global-eng-export-pdf',
  templateUrl: './global-eng-export-pdf.component.html',
  styleUrl: './global-eng-export-pdf.component.less',
})
export class GlobalEngExportPdfComponent implements OnInit, OnDestroy {
  unsubscriber$: Subject<void> = new Subject<void>();
  submissionID: number | null = null;
  opportunityNumber!: string;
  docMetaData: any;
  docToShow: DocumentTableData[] = [];
  selectedDocumentIds: number[] = [];
  sampleColumns: Column[] = sampleColumns;
  isAllSelected: boolean = false;
  selectedRowCount: number = 0;
  showPopup: boolean = false;

  cancelButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: IntakeConstant.UI_ELEMENT_SIZE,
    width: WidthState.fixed,
    isLoading: false,
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: true,
    customClass: '',
    role: 'button',
  };

  constructor(
    private readonly modalService: ModalService,
    private readonly documentService: DocumentService,
    private readonly globalEngagementService: GlobalEngagementService,
    private readonly progressIndicatorService: ProgressIndicatorService
  ) {}

  ngOnInit(): void {
    this.globalEngagementService.currentSubmissionId$.subscribe((request) => {
      this.submissionID = request.submissionid;
      this.opportunityNumber = request.opportunitynumber;
    });
    this.loadDocumentTableData();
  }

  loadDocumentTableData(): void {
    this.globalEngagementService
      .getGlobalDocumentTable(+this.submissionID!)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.docMetaData = data;
          this.docToShow = data;
          this.docToShow.forEach((item: DocumentTableData) => {
            item.selected = false;
          });

          if (this.docToShow?.length === 0) {
            this.exportSelectedDocs();
          } else {
            this.showPopup = true;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during fetching the data ', err);
        },
      });
  }

  toggleRow(rowData: DocumentTableData): void {
    rowData.selected = !rowData.selected;
    this.checkSelectedRow(this.docToShow);
  }

  selectAllRows(status: boolean, data: DocumentTableData[]): void {
    data.forEach((item: DocumentTableData) => (item.selected = status));
    this.checkSelectedRow(this.docToShow);
  }

  checkSelectedRow(data: DocumentTableData[]): void {
    this.selectedRowCount = data.filter(
      (row: DocumentTableData) => row.selected
    ).length;
    this.isAllSelected = data.length === this.selectedRowCount;
  }

  exportSelectedDocs(): void {
    this.progressIndicatorService.show();
    this.docToShow
      .filter((document: DocumentTableData) => document.selected)
      .forEach((document: DocumentTableData) =>
        this.selectedDocumentIds.push(document.submissionDocumentId)
      );

    let requestBody = {
      submissionId: this.submissionID,
      engagementId: this.opportunityNumber,
      documentIds: this.selectedDocumentIds,
    };

    this.documentService.downloadPdf(requestBody, 'global').subscribe({
      next: (response: any) => {
        if (response?.status === 200) {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          let fileName = 'Engagement.pdf'; // Default file name

          if (contentDisposition) {
            const matches = /filename="([^"]*)"/.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = matches[1];
            }
          }

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          this.close(true);
          this.progressIndicatorService.hide();
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error('Download error:', error);
        this.progressIndicatorService.hide();
      },
    });
  }

  close(isConfirmed: boolean): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
