import { Component, Input } from '@angular/core';
import { environment } from '../../../../environment/environment';

@Component({
    selector: 'display-save-no-module',
    templateUrl: './display-save.component.html',
    styleUrls: ['./display-save.component.less']
})

export class DisplaySaveNoModuleComponent  {
  public url: string;
  public module: any = {} as any;
  @Input() public isSaving: boolean = false;
  get savedTimeStamp() { return this.module.savedTimeStamp; }
  @Input() set savedTimeStamp(v: Date) { this.module.savedTimeStamp = v; }
  rootUrl: string = environment.rootUrl;
  constructor() {
    this.url = this.rootUrl + 'assets/images/auto-save.gif';
  }

}
