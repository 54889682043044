import {
  DatepickerOptions,
  DatepickerStates,
} from '@usitsdasdesign/dds-ng/datepicker';
import {
  Column,
  Size,
  TabThemeType,
  Themes,
} from '@usitsdasdesign/dds-ng/shared';
import { TabOptions, TabsOptions } from '@usitsdasdesign/dds-ng/tabs';
import { RoleEnum } from '../../../intake/constants/Role.enum';
import { ExternalCommSubmissionStatus } from '../../constants/submission-status.enum';
import { GlobalEngagementsConstant } from '../../../global-engagements/constants/global-engagements.constants';

export const tabOptionsOne: TabOptions = {
  label: 'Submission details',
};

export const tabRisk: TabOptions = {
  label: 'Risk assessment',
};
export const YES_NO = {
  YES: 'Yes',
  NO: 'No',
};
export const tabOptionsTwo: TabOptions = {
  label: 'Documents',
};
export const tabOptionsThree: TabOptions = {
  label: 'Notes/feedback',
};
export const tabOptionsFour: TabOptions = {
  label: 'Workflow',
};

export const tabOptionsFive: TabOptions = {
  label: 'Communication attributes',
};

export const tabOptionsSeven: TabOptions = {
  label: 'Internal QRM team communication',
};

export const tabContainerOptions: TabsOptions = {
  theme: Themes.green,
  themeType: TabThemeType.border,
  size: Size.md,
  ariaLabel: 'Horizontal tabs',
  disabled: false,
  isInverse: false,
  customClass: '',
  isResponsive: false,
};

export const datePickerOptions: DatepickerOptions = {
  size: Size.md,
  placeholder: 'Select date',
  format: GlobalEngagementsConstant.DATE_FORMAT,
  minMode: DatepickerStates.day,
  isResponsive: true,
  isManualInput: false,
  disabledDates: { before: new Date() }, // This disables all dates before the current date
};

export const SUBMISSION_STATUS_ALLOW_FOR_WORKFLOW_RETURN_FOR_SELF_REVIEW: string[] =
  [
    ExternalCommSubmissionStatus.SUBMITTED,
    ExternalCommSubmissionStatus.RESUBMITTED,
    ExternalCommSubmissionStatus.DRAFT,
  ];
export const ROLES_ALLOW_FOR_WORKFLOW_RETURN_FOR_SELF_REVIEW: string[] = [
  RoleEnum.EXT_COMM_REVIEWER,
  RoleEnum.EXT_COMM_RISK_MANAGER,
  RoleEnum.EXT_COMM_SUPPORTING_RISK_MANAGER,
  RoleEnum.EXT_COMM_RM_SUPPORT_GLOBAL,
];

export const ROLES_ALLOW_FOR_RETURN_TO_REWORK: string[] = [
  RoleEnum.RESPONSIBLE_PRACTITIONER,
  RoleEnum.EXT_COMM_RISK_MANAGER,
  RoleEnum.EXT_COMM_SUPPORTING_RISK_MANAGER,
  RoleEnum.EXT_COMM_RM_SUPPORT_GLOBAL,
];

export const CompleteReviewPopUpTableHeader: Column[] = [
  { name: 'title', header: 'Title', dataType: 'string', minWidth: '14.375rem' },
  { name: 'Status', header: 'Status', dataType: 'number', minWidth: '8.75rem' },
];
