import {
  ErrorState,
  LabelPosition,
  Size,
  Themes,
} from '@usitsdasdesign/dds-ng/shared';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { IntakeDocumentSubmissionTypeCode } from './intake_submission_type_code.enum';
import { EngagementDashboardTilesCount } from '../../common/models/start-page/engagement-dashboard.model';
import {
  EngagementTileCode,
  RiskManagerTileCode,
} from './dashboard-tiles.enum';
import { RiskManagerDashboardTilesCount } from '../../common/models/start-page/riskmanager-dashboard.model';

export class IntakeConstant {
  public static readonly PAGE_NUMBER_5: number = 5;
  public static readonly PAGE_NUMBER_10: number = 10;
  public static readonly PAGE_NUMBER: number = 15;
  public static readonly TEXTAREA_CHAR_LIMIT: number = 1000;
  public static readonly TEXTAREA_CHAR_LIMIT_BIGGEST: number = 5000;
  public static readonly TEXTAREA_MIN_HEIGHT: number = 70;
  public static readonly INITIAL_SERVICE_TABLE_COLUMN: number = 3;
  public static readonly TOOLTIP_WIDTH = 450;
  public static readonly SET_TIMEOUT_DURATION = 2000;
  public static readonly SET_TIMEOUT_DURATION_BIGGER = 3000;
  public static readonly FINAL_ARCHIVE_READY_ID = 3;
  public static readonly ENGAGEMENT_LETTER_CODE = 6;
  public static readonly ORIGNAL_Letter_SOW_Contract_CODE = 7;
  public static readonly ORIGNAL_Letter_CODE = 8;
  public static readonly SEARCH_DEBOUNCE_TIME: number = 500;
  public static readonly SEARCH_STRING_LIMIT: number = 2;
  public static readonly OTHER_STATUS: number = 11;
  public static readonly TIMOUT_TIMER: number = 300;
  public static readonly TIMOUT_TIMER_1000: number = 300;
  public static readonly TIMEOUT_TIMER_BIGGEST: number = 1000;
  public static readonly CHARACTER_LIMIT_IN_SEARCH: number = 3;
  public static readonly SubmissionActionID: number = 3; //Todo: Need to remove harcoded actionID's in future
  public static readonly ResetActionID: number = 1;
  public static readonly DiscontinueActionID: number = 2; //Todo: Need to remove harcoded actionID's in future
  public static readonly number_11: number = 11;

  public static readonly MA_WARNING_BODY_KEY: string = 'M&A_WARNING_BODY';
  public static readonly MA_WARNING_HEADER_KEY: string = 'M&A_WARNING_HEADER';
  public static readonly GPS_WARNING_HEADER_KEY: string =
    'GPS_WARNING_HEADER_DURING_INITIATION';
  public static readonly GPS_WARNING_BODY_KEY: string =
    'GPS_WARNING_BODY_DURING_INITIATION';
  public static readonly DCF_WARNING_HEADER_KEY: string =
    'DCF_WARNING_HEADER_DURING_INITIATION';
  public static readonly DCF_WARNING_BODY_KEY: string =
    'DCF_WARNING_BODY_DURING_INITIATION';
  public static readonly MA_DELIVERABLE_BODY_KEY: string =
    'M&A_DELIVERABLE_MESSAGE_BODY';
  public static readonly MA_DELIVERABLE_TITLE_KEY: string =
    'M&A_DELIVERABLE_MESSAGE_TITLE';
  public static readonly MA: string = 'MA';
  public static readonly GPS: string = 'GPS';
  public static readonly DCF: string = 'DCF';
  public static readonly MA_DELIVERABLE: string = 'MA_DELIVERABLE';
  public static readonly SEARCH_OPTION_PLACEHOLDER_SIMPLE: string = 'Search';
  public static readonly SEARCH_OPTION_PLACEHOLDER: string =
    'Search by Jupiter Opportunity ID, Client Name or Opportunity Leader';
  public static readonly PLACEHOLDER_TEXT_FOR_SECONDARY_CONTACT: string =
    'Search by name';
  public static readonly SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER: string =
    'Search by user name'; // TODO: need yo update as per story
  public static readonly SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER_LEAD_ENGAGEMENT_PARTNER: string =
    'Add Lead Engagement Partner';
  public static readonly SEARCH_OPTION_PLACEHOLDER_FOR_COMMON_CO_WITHOUT_EL_SOW: string =
    'Search by Jupiter Opportunity ID';
  public static readonly SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER_TEAM_MEMBER: string =
    'Add team member';
  public static readonly CHANGE_ORDER_LABEL: string = 'Change Order';
  public static readonly DELIVERABLE: string = 'Deliverable';
  public static readonly NON_CHANGE_ORDER: string = 'nonChangeOrder';
  public static readonly AMOUNT: string = 'amount';
  public static readonly FSS: string = 'fss';
  public static readonly CLIENT_SERVICE_LEVEL: string = 'clientServiceLevel';
  public static readonly ASCENDING: string = 'ascending';
  public static readonly DECENDING: string = 'decending';
  public static readonly REGULATORY_CONSIDERATION: string =
    'Regulatory considerations';
  public static readonly YES: string = 'Yes';
  public static readonly NO: string = 'No';
  public static readonly NOT_YET_DETERMINED: string = 'Not yet determined';
  public static readonly DISCONTINUED: string = 'Discontinued';
  public static readonly PURGED: string = 'Purged';
  public static readonly PROPOSAL: string = 'PRO';
  public static readonly YES_TEAMING: string = 'Yes, teaming';
  public static readonly NONE: string = 'None';
  public static readonly NO_RESUBMIT_FOR_REVIEW: string =
    'No - Resubmit for review';
  public static readonly CO_OPP_DONOT_MATCH: string =
    'CO_OPPORTUNITIES_DONOT_MATCH';
  public static readonly YES_RESUBMIT_FOR_REVIEW: string =
    'Yes - Resubmit for review';

  public static readonly UI_ELEMENT_SIZE: Size = Size.lg;
  public static readonly MODAL_SIZE: Size = Size.md;
  public static readonly TEXTAREA_SIZE: string = IntakeConstant.UI_ELEMENT_SIZE;
  public static readonly TEXTAREA_CHAR_COUNT_POSTION: string = 'bottom-right';

  public static readonly DEFAULT_FOR_EMPTY_FIELDS: string = '-';
  public static readonly ENTER_TEXT_PLACEHOLDER: string = 'Enter text';
  public static readonly NO_MATCHES_FOUND: string = 'No matches found';
  public static readonly NONE_EXPLAIN: string = 'None [Explain]';
  public static readonly SHOW_MORE_TEXT: string = 'Show more';
  public static readonly SHOW_LESS_TEXT: string = 'Show less';
  public static readonly SUBMIT_BTN_LABEL: string = 'Submit';
  public static readonly RESUBMIT_FOR_REVIEW_BTN_LABEL: string =
    'Resubmit for review';
  public static readonly ARCHIVE_AND_COMPLETE_BTN_LABEL: string =
    'Archive and complete';
  public static readonly OPT_OUT_OF_SELF_REVIEW_BTN_LABEL: string =
    'Opt out of Self-review';
  public static readonly SELF_REVIEW_BTN_LABEL: string = 'Self-review';
  public static readonly ADD_REVIEWER_BTN_LABEL: string = 'Add reviewer';
  public static readonly COPY_SUCCESS_MSG: string = 'Copied to clipboard!';
  public static readonly DDS_ICON_ARROW_UP: string = 'dds-icon_arrow-up';
  public static readonly DDS_ICON_ARROW_DOWN: string = 'dds-icon_arrow-down';
  public static readonly FEE_TYPE_LABEL: string = 'Fee type';
  public static readonly DURATION_LABEL: string = 'Duration';
  public static readonly CROSS_BUSINESS_LABEL: string =
    'Cross-business opportunity (i.e.,Green dot)';
  public static readonly DESCRIPTION_LABEL: string = 'Description';
  public static readonly ARCHIVE_LABEL: string = 'Archive - records retention';
  public static readonly DOCUMENT_SUBMISSION_LABEL: string =
    'Document submission(s)';
  public static readonly INDEPENDENCE_LABEL: string =
    'Independence & conflicts';
  public static readonly TEAM_LABEL: string = 'Team';
  public static readonly CLIENT_LABEL: string = 'Client';
  public static readonly DATE_FORMAT: string = 'MM/dd/yyyy';
  public static readonly OPPORTUNITY_COMPLEXITY_LABEL: string =
    'Opportunity complexity';
  public static readonly PRODUCTS_AND_TOOLS_LABEL: string =
    'Products and tools';
  public static readonly THIRD_PARTY_LABEL: string = 'Third-party involvement';
  public static readonly SENSITIVE_DATA_LABEL: string = 'Sensitive data';
  public static readonly DATA_INDELOITTE_LABEL: string = `Data in Deloitte's or Deloitte's hosted environment`;
  public static readonly MEMBER_FIRM_INVOLVEMENT_LABEL: string =
    'Member firm involvement ';
  public static readonly USE_OF_EXISTING_LABEL: string =
    'Use of existing MSAs or Contract Maker GBTs';
  public static readonly DELOITTE_SOW_LABEL: string =
    'Deloitte standard SOW/EL template referred';
  public static readonly VIEW_EDIT_LABEL: string = 'View & edit';
  public static readonly FINAL_ARCHIVE_READY_VERSION: string =
    'Final - Archive ready version';
  public static readonly SUBMITTED_STATUS: string = 'Submitted';
  public static readonly ADMINISTRATEVELY_CLOSE_MENU_ITEM: string =
    'Administratively close';
  public static readonly SYSTEM_ADMINISTRATOR: string = 'Admin';
  public static readonly DISCONTINUE_SUBMISSION_MENU_ITEM: string =
    'Discontinue submission';
  public static readonly EMPTY_SPACE: string = '';

  public static readonly ADMINISTRATEVELY_CLOSE: string =
    'Administratively close';
  public static readonly RESET_SUBMISSION_STATUS: string =
    'Reset submission status';
  public static readonly EXPORT_TO_PDF_VALUE: string = 'ExportToPdf';
  public static readonly EXPORT_TO_PDF_HEADING: string = 'Export to PDF';
  public static readonly AUDIT_TRAIL: string = 'Audit trail';
  public static readonly COMMON_AUDIT_TRAIL: string = 'AuditTrail';
  public static readonly START_REVIEW: string = 'Start Review';
  public static readonly START_REVIEW_ACTION: string = 'StartReview';
  public static readonly REVIEWER_DASHBOARD: string = 'ReviewerDashboard';
  public static readonly ENGAGEMENT_TEAM_DASHBOARD: string =
    'EngagementTeamDashboard';
  public static readonly ARCHIVE_CONNECTED_LABEL: string =
    ' archive(s) previously connected';
  public static readonly ARCHIVE_NOT_LISTED: string = 'Archive not listed';

  public static readonly DOCUMENT_TABLE_ARCHIVE_HEADING: string =
    'Transfer to Archive it';
  public static readonly DOCUMENT_STATUS: string =
    'Supporting documentation - no review required';
  public static readonly RISK_MANAGER_INSIGHT_DASHBOARD: string =
    'RiskManagerInsightDashboard';
  public static readonly COMPLEX_QUESTION_TPRM_NOTE: string =
    'All third party relationships require TPRM approval.';
  public static readonly TPRM_TEXT: string = 'TPRM';
  public static readonly COMPLEX_QUESTION_IFA_NOTE: string =
    'Go to Contract Maker for instructions on how to create an IFA. IFAs cannot be submitted for review through this portal';
  public static readonly IFA_TEXT: string = 'Contract Maker';
  public static readonly COMPLEX_QUESTION_IFA_HYPERLINK: string =
    'https://contractmaker.deloitteresources.com/contractmaker/contract/template/19';

  public static readonly COMPLEX_QUESTION_NOTES_SUBCONTRACTOR: string =
    "You may submit the Prime Firm's SOW for review.";
  public static readonly VALID_ARCHIVE_LIST: string[] = ['Open', 'Reopen'];
  public static readonly FEE_TYPE_KEY: string = 'feeType';
  public static readonly CLIENT_PROGRAM_LIST: string[] = [
    'Global crown jewel',
    'Global strategic account',
    'Non-Program Client',
  ];
  public static readonly RELINK_DROPDOWN_PLACEHOLDER: string =
    'Relink submission';
  public static readonly LINK_CHANGE_ORDER_TOAST_MESSAGE: string =
    'Change Order submission linked to ';
  public static readonly OTHER_NOT_LISTED_TYPE_ID: number = 4;
  public static readonly CONFIDENTIAL_OPPORTUNITY_BODY: string = "CONFIDENTIAL_OPPORTUNITY_BODY";
  public static readonly CONFIDENTIAL_OPPORTUNITY_HEADER: string = "CONFIDENTIAL_OPPORTUNITY_HEADER";
  public static readonly CONFIDENTIAL_OPPORTUNITY: string = "confidentialOppertunity";
  public static readonly RM_REVIEWED_GUIDANCE_NOTE_HEADER: string = 'RM_REVIEWED_DOCSTATUS_HEADER';
  public static readonly RM_REVIEWED_GUIDANCE_NOTE_BODY: string = 'RM_REVIEWED_DOCSTATUS_BODY';

  public static readonly ENGAGEMENT_DASHBOARD_BUTTONS: {
    [key: string]: { label: string; action: string; condition: string };
  } = {
    SUBMIT: {
      label: 'Submit',
      action: 'Submit',
      condition: 'enableSubmit',
    },
    RESUBMIT: {
      label: 'Resubmit',
      action: 'Resubmit',
      condition: 'enableSubmit',
    },
    SELF_REVIEW: {
      label: 'Self-review',
      action: 'SelfReview',
      condition: 'enableSelfReview',
    },
    OPT_OUT_SELF_REVIEW: {
      label: 'Opt-out of self-review',
      action: 'OptOutSelfReview',
      condition: 'reasonForOptOutSelfReview',
    },
    ARCHIVE_COMPLETE: {
      label: 'Archive and complete',
      action: 'ArchiveComplete',
      condition: 'enableArchiveAndComplete',
    },
    RESUBMIT_REVIEW: {
      label: 'Re-submit for review',
      action: 'ResubmitReview',
      condition: 'enableResubmitForReview',
    },
    COMPLETE_ACTION: {
      label: 'Complete required action(s)',
      action: 'CompleteRequiredAction',
      condition: 'enableSubmitResubmit',
    },
  };
  public static readonly CYBER: string = 'CYBER';
  public static readonly ISREAL_INVOLVEMENT: string = 'Israel involvement';
  public static readonly POTENTIAL_CLIENT_LABEL: string =
    'Potential client operations impact';
  public static readonly SERVICE_INVOLVEMENT: string = 'Service involvement';
  public static readonly INDEPENDENCE_DESC_LINK: string =
    'https://stage3.kingland-dttl-ems.com/admin/entity-restrictions/';

  public static readonly TEXT_AREA_SETTINGS_GLOBAL: TextareaOptions = {
    label: IntakeConstant.EMPTY_SPACE,
    labelPosition: LabelPosition.external,
    placeholder: IntakeConstant.ENTER_TEXT_PLACEHOLDER,
    minHeight: IntakeConstant.TEXTAREA_MIN_HEIGHT,
    isRequired: false,
    isInverse: false,
    readonly: false,
    isError: false,
    errorState: ErrorState.default,
    description: IntakeConstant.EMPTY_SPACE,
    customClass: IntakeConstant.EMPTY_SPACE,
    errorMessage: IntakeConstant.EMPTY_SPACE,
    maxLength: IntakeConstant.TEXTAREA_CHAR_LIMIT,
    maxLengthPosition: IntakeConstant.TEXTAREA_CHAR_COUNT_POSTION,
    size: IntakeConstant.TEXTAREA_SIZE,
  };

  public static readonly INTAKE_CHECKBOX_THEME_COLOR = Themes.green;
  public static readonly INTAKE_INFO_TOOLTIP_THEME_COLOR = Themes.dark;
  public static readonly YES_NO_CHECKBOX_VALUE_TO_BOOLEAN = new Map<
    string | boolean,
    boolean
  >([
    ['yes', true],
    ['no', false],
  ]);
  public static readonly BOOLEAN_CHECKBOX_VALUE_TO_YES_NO = new Map<
    boolean | undefined | string,
    string
  >([
    [true, 'yes'],
    [false, 'no'],
  ]);

  public static readonly EngagementTileCodeDetails = new Map<
    EngagementTileCode,
    {
      title: string;
      countKey: keyof EngagementDashboardTilesCount;
      tileCode: string;
    }
  >([
    [
      EngagementTileCode.Initiated,
      {
        title: 'Initiated',
        countKey: 'initiatedCount',
        tileCode: EngagementTileCode.Initiated,
      },
    ],
    [
      EngagementTileCode.Submitted,
      {
        title: 'Submitted',
        countKey: 'submittedCount',
        tileCode: EngagementTileCode.Submitted,
      },
    ],
    [
      EngagementTileCode.Reviewed,
      {
        title: 'Reviewed',
        countKey: 'reviewedCount',
        tileCode: EngagementTileCode.Reviewed,
      },
    ],
    [
      EngagementTileCode.Completed,
      {
        title: 'Completed',
        countKey: 'completedCount',
        tileCode: EngagementTileCode.Completed,
      },
    ],
    [
      EngagementTileCode.ExtComm,
      {
        title: 'External Communications',
        countKey: 'externalCommunications',
        tileCode: EngagementTileCode.ExtComm,
      },
    ],
    [
      EngagementTileCode.GlobalEng,
      {
        title: 'Global engagement submissions',
        countKey: 'globalEngagementSubmission',
        tileCode: EngagementTileCode.GlobalEng,
      },
    ],
  ]);

  public static readonly RiskManagerTileCodeDetails = new Map<
    RiskManagerTileCode,
    {
      title: string;
      countKey: keyof RiskManagerDashboardTilesCount;
      tileCode: string;
    }
  >([
    [
      RiskManagerTileCode.Inprocess,
      {
        title: 'In Process',
        countKey: 'inProcess',
        tileCode: 'InProcess',
      },
    ],
    [
      RiskManagerTileCode.Completed,
      {
        title: 'Completed',
        countKey: 'completed',
        tileCode: 'Completed',
      },
    ],
    [
      RiskManagerTileCode.Discontinued,
      {
        title: 'Discontinued',
        countKey: 'discontinued',
        tileCode: 'Discontinued',
      },
    ],
    [
      RiskManagerTileCode.ExternalCommunications,
      {
        title: 'External Communications',
        countKey: 'externalCommunications',
        tileCode: 'ExternalCommunications',
      },
    ],
  ]);
}

export const VALID_DOCUMENT_TYPES: Record<
  IntakeDocumentSubmissionTypeCode,
  string[]
> = {
  [IntakeDocumentSubmissionTypeCode.PRO]: [
    'Proposal',
    'Request for Proposal (RFP)',
  ],
  [IntakeDocumentSubmissionTypeCode.EL_SOW]: [
    'Engagement Letter/Statement of Work',
    'Interim Engagement Letter',
  ],
  [IntakeDocumentSubmissionTypeCode.DEL]: ['Deliverable'],
  [IntakeDocumentSubmissionTypeCode.CO]: [
    'Engagement Letter',
    'Original executed Engagement Letter/SOW/Contract',
    'Original Executed Interim Engagement Letter',
    'Engagement Letter/Statement of Work',
  ],
  [IntakeDocumentSubmissionTypeCode.OTH]: [
    'Conflict Check Name Match Report',
    'Conflict Analyst summary spreadsheet of responses',
    'Other (include comments below)',
  ],
};
