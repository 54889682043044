import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  PrimaryButtonOptions,
  SHOW_MORE_BTN_OPTIONS,
  UploaderAreaOptions,
} from '../../../external-communications.helper';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { ExternalCommunicationDocumentUploadComponent } from '../external-communication-document-upload/external-communication-document-upload.component';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { UploaderOptions } from '@usitsdasdesign/dds-ng/uploader';
import { CommunicationType } from '../../../constants/communication-type';
import { ExternalCommunicationDocumentLinkUploadComponent } from '../external-communication-document-link-upload/external-communication-document-link-upload.component';
import { DocumentStatus } from '../../../../common/models/document-dropdown.models';
import { config, Subject, takeUntil } from 'rxjs';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import { ExternalCommunicationDocumentTableComponent } from '../external-communication-document-table/external-communication-document-table.component';
import {
  boxMessages,
  SaveSubmissionDocument,
  SubmissionDocumentDetails,
} from '../../../../common/models/external-communication.model';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { SubmissionService } from '../../../../http/intake/submission.service';
import {
  configurationType,
  employeeRoles,
  PermissionsObj,
} from '../../../../common/models/common-models';
import { RoleEnum } from '../../../../intake/constants/Role.enum';
import { UserService } from '../../../../http/user.service';
import { DocumentStatus as DocStatus } from '../../../constants/document-status.enum';
import { BrowserTabConstant } from '../../../../common/constants/browser-tab-constant';
import { ExternalCommSubmissionStatus } from '../../../constants/submission-status.enum';
import { StatusEnum } from '../../../../common/constants/status-enum';

@Component({
  selector: 'app-external-communication-documents',
  templateUrl: './external-communication-documents.component.html',
  styleUrl: './external-communication-documents.component.less',
})
export class ExternalCommunicationDocumentsComponent {
  @Input() submissionId!: number;
  @Input() submissionTypeCode: string =
    ExternalCommunicationConstant.EMPTY_SPACE;
  @Input() submissionStatusId!: number;
  @Input() submissionStatus!: string | null;
  @Input() progressiveStatus!: boolean | null;
  @Input() communicationType!: string;
  @Input() communicationTypeId!: number;
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;
  @Input() isSelfReview!: boolean;
  @Input() loggedInUseRoles: string[] = [];
  @Input() isUserinWorkFlow: boolean = false;
  @Input() isDisabledDocuments: boolean = false;
  @Output() documentTableData: EventEmitter<SaveSubmissionDocument[]> =
    new EventEmitter<SaveSubmissionDocument[]>();
  @Output() ppmdTrainingLabelValue: EventEmitter<string> =
    new EventEmitter<string>();
  @ViewChild(ExternalCommunicationDocumentTableComponent)
  tableComponent!: ExternalCommunicationDocumentTableComponent;
  documentTableDataCurrently: SubmissionDocumentDetails[] = [];

  isSelfReviewButtonVisible: boolean = false;
  showMoreGuidance: boolean = true;
  boxMessages: boxMessages[] = [];
  showMoreButtonOptions: ButtonOptions = SHOW_MORE_BTN_OPTIONS;
  showMoreButtonText: string = ExternalCommunicationConstant.SHOW_LESS_TEXT;
  uploadAreaOptions: UploaderOptions = UploaderAreaOptions;
  buttonOptions: ButtonOptions = PrimaryButtonOptions;
  emptyString: string = ExternalCommunicationConstant.EMPTY_SPACE;

  documentStatusDropdownData: SelectItemOptions[] = [];
  documentTypeDropdownData: SelectItemOptions[] = [];
  configData: configurationType[] = [];
  addLinkGuidance: string = ExternalCommunicationConstant.EMPTY_SPACE;
  uploadRequiredDocument!: string;
  selfReviewEligibleLabel!: { heading: string; body: string };

  userHasAccess: boolean = false;
  roles: employeeRoles[] = [];

  isValidDocumentUploaded: boolean = false;

  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  isCompleted: boolean = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly modalService: ModalService,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly submissionService: SubmissionService,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_ExternalCommunications;
    this.getConfigLabels();
    this.getDocumentTypeDropdownData();
    this.getDocumentStatusDropdownData(this.submissionStatusId);
    this.getRoles()
      .then(() => {
        this.updateStatusColumnVisibility();
      })
      .catch((err) => {
        console.error('An error occurred while getting roles:', err);
      });

    this.externalCommService.docIsValid$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((status: boolean) => (this.isValidDocumentUploaded = status));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setGreenLineHeight();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // TODO: Need to check whether functioning properly
    // if (changes['submissionStatus']?.currentValue) {
    //   if (this.submissionStatus === StatusEnum.SUBMITTED) {
    //     this.showMoreGuidance = false;
    //     this.expandGuidance();
    //   }
    // }

    if (changes['submissionStatus']?.currentValue) {
      if (
        this.submissionStatus ===
        ExternalCommSubmissionStatus.RM_REVIEWED_PENDING_PPMD_CONFIRMATION
      ) {
        this.showMoreGuidance = true;
        this.expandGuidance();
      }
      this.isCompleted = (this.submissionStatus ===
      ExternalCommSubmissionStatus.COMPLETED || this.submissionStatus ===
      ExternalCommSubmissionStatus.ADMIN_COMPLETE);
    }
  }

  expandGuidance(): void {
    this.showMoreGuidance = !this.showMoreGuidance;
    if (!this.showMoreGuidance) {
      this.showMoreButtonText = ExternalCommunicationConstant.SHOW_MORE_TEXT;
      this.showMoreButtonOptions.icon =
        ExternalCommunicationConstant.DDS_ICON_ARROW_DOWN;
    } else {
      this.showMoreButtonText = ExternalCommunicationConstant.SHOW_LESS_TEXT;
      this.showMoreButtonOptions.icon =
        ExternalCommunicationConstant.DDS_ICON_ARROW_UP;
    }
    this.cdr.detectChanges();
    this.setGreenLineHeight();
  }

  setGreenLineHeight(): void {
    const expandedSectionHeight = document.getElementById(
      'guidanceExpandedSection'
    )?.offsetHeight;
    if (expandedSectionHeight) {
      document.getElementById(
        'ext-comm-doc-guideline-vertical-line'
      )!.style.height = expandedSectionHeight + 8 + 'px';
    }
  }

  removeBodyOverflow(): void {
    document.body.style.overflowY = 'hidden';
  }

  getRoles(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.currentLoggedInUser$
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (user) => {
            this.roles = user?.employeeRoles ?? [];
            resolve();
          },
          error: (err) => {
            console.error('An error occurred while getting roles:', err);
            reject(err);
          },
        });
    });
  }

  updateStatusColumnVisibility(): void {
    this.userHasAccess = false;
    const rolesWithAccess: string[] = [
      RoleEnum.EXT_COMM_RISK_MANAGER,
      RoleEnum.EXT_COMM_SUPPORTING_RISK_MANAGER,
      RoleEnum.SPECIALIZED_RISK_MANAGER,
      RoleEnum.EXT_COMM_REVIEWER,
      RoleEnum.TECHNICAL_INDEPENDENT_RISK_MANAGER,
      RoleEnum.SYSTEM_ADMINISTRATOR,
      RoleEnum.EXT_COMM_RM_SUPPORT_GLOBAL,
    ];
    this.userHasAccess = this.roles.some((role) =>
      rolesWithAccess.includes(role?.roleName)
    );
  }

  triggerDocumentTableDataApi(): void {
    this.tableComponent.getDocumentMetaData();
  }

  getConfigLabels(): void {
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response) {
            this.configData = response;
            this.setLabels();
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  setLabels(): void {
    const labelVal = (key: string): string | undefined => {
      return this.configData.find(
        (config: configurationType) => config.appConfigurationLabelKey === key
      )?.appConfigurationLabelValue;
    };

    const selfReviewEligibleLabel: string =
      labelVal('UPLOAD_DOCUMENTS_EC_SELF_REVIEW') || '';
    const [heading, body] = selfReviewEligibleLabel.split('.');
    this.selfReviewEligibleLabel = {
      heading: heading,
      body: body,
    };

    const ppmdTrainingLabel: string =
      labelVal('PPMD_TRAINING_NOT_COMPLETED_GUIDANCE') || this.emptyString;
    this.ppmdTrainingLabelValue.emit(ppmdTrainingLabel);

    this.uploadRequiredDocument =
      labelVal('UPLOAD_DOCUMENTS_EXT_COMM') ?? this.emptyString;

    this.uploadAreaOptions.description =
      labelVal('EC_DocumentSection_DocumentSupportingType') ||
      UploaderAreaOptions.description;

    const guidanceNoteText = labelVal('EC_DocumentSection_GuidanceNote');

    this.addLinkGuidance =
      labelVal('EC_DocumentSection_GrantRiskManagerAccess') ?? this.emptyString;

    const guidanceElement = document.getElementById('guidanceExpandedSection');
    if (guidanceNoteText && guidanceElement) {
      guidanceElement!.innerHTML = guidanceNoteText;
      this.setGreenLineHeight();
    }

    this.handleDocumentTableData({} as SubmissionDocumentDetails[]);
  }

  getDocumentStatusDropdownData(submissionStatusId: number): void {
    this.externalCommService
      .getDocumentStatusDropdown(submissionStatusId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (result: DocumentStatus[]) => {
          const convertedArray = result.map((item) => ({
            value: item.documentStatusId,
            heading: item.documentStatusName,
          }));
          this.documentStatusDropdownData = this.userHasAccess
            ? convertedArray
            : convertedArray.filter(
                (item) =>
                  item.heading !== DocStatus.RMReviewed &&
                  item.heading !== DocStatus.DOC_RMReviewed
              );
        },
        error: (err: any) => {
          console.error('An error occurred', err);
        },
      });
  }

  getDocumentTypeDropdownData(): void {
    this.externalCommService
      .getDocumentTypeDropdown()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (result: any) => {
          const convertedArray = result.map((item: any) => ({
            value: item.documentTypeId,
            heading: item.documentTypeName,
          }));
          this.documentTypeDropdownData = convertedArray;
        },
        error: (err: any) => {
          console.error('An error occurred', err);
        },
      });
  }

  openUploadModal(event: any, modalBtn?: ButtonComponent): void {
    this.removeBodyOverflow(); 
    let modalRef = this.modalService.open(
      ExternalCommunicationDocumentUploadComponent,
      {
        isFooter: true,
        size: 'lg',
        isInverse: false,
        documentInitiatedDetails: {
          moduleName: 'ExternalComm',
          submissionStatusId: this.submissionStatusId,
          submissionId: this.submissionId,
          documentUploadCount: 1,
          file: event,
        },
        documentTableDataCurrently: this.documentTableDataCurrently,
        documentStatusDropdown: this.documentStatusDropdownData,
        documentTypeDropdown: this.documentTypeDropdownData,
        userHasAccess: this.userHasAccess,
      }
    );

    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.triggerDocumentTableDataApi();
        event.splice(0, event.length);
        if (modalBtn) {
          modalBtn.focus();
        }
      });
  }

  openDocumentLinkModal(modalBtn?: any): void {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(
      ExternalCommunicationDocumentLinkUploadComponent,
      {
        documentInitiatedDetails: {
          submissionId: this.submissionId,
          moduleName: 'ExternalComm',
          submissionStatusId: this.submissionStatusId,
        },
        documentStatusDropdown: this.documentStatusDropdownData,
        documentTypeDropdown: this.documentTypeDropdownData,
        addLinkGuidanceText: this.addLinkGuidance,
        userHasAccess: this.userHasAccess,
      }
    );
    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.triggerDocumentTableDataApi();
        if (modalBtn) {
          modalBtn.focus();
        }
      });
  }

  isAddLinkButtonVisible(): boolean {
    //TODO: Add check for self review, Non-self flow is true
    if (
      this.permissionObj['isExtCommAddLinkVisible'] &&
      (this.communicationType === CommunicationType.Podcasts ||
        this.communicationType === CommunicationType.Videos)
    ) {
      return true;
    } else if (this.isSelfReview) {
      return false;
    } else return false;
  }

  isDocumentTableDisabled(): boolean {
    return false;
  }

  handleDocumentTableData(data: SubmissionDocumentDetails[]): void {
    this.documentTableDataCurrently = data;
    const draftMsgIndex = this.boxMessages.findIndex(
      (message: boxMessages) => message.textId === 1
    );
    // TODO: Need to rethink this, else can directly create separate template in HTML
    if (this.submissionStatus === ExternalCommSubmissionStatus.DRAFT) {
      if (!this.isValidDocumentUploaded && draftMsgIndex === -1) {
        this.boxMessages.unshift({
          textId: 1, // TODO: Can Assign ids to all the texts in separate file
          text: this.uploadRequiredDocument,
          icon: null,
          class: 'styled-box',
          order: 1,
        });
      } else if (this.isValidDocumentUploaded && draftMsgIndex !== -1) {
        this.boxMessages.splice(draftMsgIndex, 1);
      }
    } else if (draftMsgIndex !== -1) {
      this.boxMessages.splice(draftMsgIndex, 1);
    }

    // Update the message text if uploadRequiredDocument is received late
    if (
      draftMsgIndex !== -1 &&
      this.boxMessages[draftMsgIndex]?.text !== this.uploadRequiredDocument
    ) {
      if (this.boxMessages[draftMsgIndex]) {
        this.boxMessages[draftMsgIndex].text = this.uploadRequiredDocument;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
