import { Component, Input, OnInit } from '@angular/core';
import { Column, SortingStates, SortOptions, Themes } from '@usitsdasdesign/dds-ng/shared';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { OpportunityData } from '../../../common/models/start-page/start-page.model';

@Component({
    selector: 'nca-specialist-page-grid-area-item',
    templateUrl: './nca-specialist-gridarea-item.component.html',
    styleUrls: ['./nca-specialist-gridarea-item.component.less']
})

export class NcaSpecialistGridAreaItemComponent implements OnInit {
    @Input() list: Array<OpportunityData> = [];
    @Input() currentTileNameForGrid: string | any = "";
    @Input() isNewClientAcceptanceComponent: boolean = false;
    public resource: any = {};
    public isSortAsc: boolean | undefined;
    public sortBy: string = '';
    public gridColumns: Column[] = [];
    public sortingState: SortOptions={};
    public originalList: OpportunityData[] = [];

    constructor() {
    }
    ngOnInit() {
        this.originalList = this.list;
      this.sortListByMilestoneTrigger();

      this.gridColumns = [
        { name: 'opportunityName', header: 'Jupiter opportunity name', dataType: 'string', minWidth: this.isNewClientAcceptanceComponent ? '6vw' : '8.75rem' },
        { name: 'opportunityNumber', header: 'Jupiter ID', dataType: 'number', minWidth: this.isNewClientAcceptanceComponent ? '6vw' : '6.75rem' },
        { name: 'opportunityExpectedCloseDate', header: 'Expected close date', dataType: 'date', minWidth: this.isNewClientAcceptanceComponent ? '6vw' : '8.75rem' },
        { name: 'opportunityStatusDescription', header: 'Stage', dataType: 'string', minWidth: this.isNewClientAcceptanceComponent ? '6vw' : '8.75rem' },
        { name: 'opportunityModifiedDate', header: this.currentTileNameForGrid == 'NcaSummary' ? 'Status change date' : 'Update date', dataType: 'date', minWidth: this.isNewClientAcceptanceComponent ? '6vw' : '8.75rem' },
        { name: 'marketOfferingSolutionText', header: 'Offering solution', dataType: 'string', minWidth: this.isNewClientAcceptanceComponent ? '10vw' : '10rem' },
        { name: 'marketOfferingSubSolutionText', header: 'Offering sub-solution', dataType: 'string', minWidth: this.isNewClientAcceptanceComponent ? '10vw' : '12rem' },
        { name: 'pursuitLeaderOutlookDisplayName', header: 'Pursuit leader', dataType: 'string', minWidth: this.isNewClientAcceptanceComponent ? '10vw' : '8.75rem' },
      ];

      this.sortingState = {
        property: this.gridColumns[0].name,
        descending: false,
        dataType: this.gridColumns[0].dataType,
      };
    }

    ngOnChanges() {
        this.sortListByMilestoneTrigger();
    }

    private sortListByMilestoneTrigger(): void {
        this.list?.sort((a, b) => {
            if (a.isMilestoneTrigger === b.isMilestoneTrigger) return 0;
            return a.isMilestoneTrigger ? -1 : 1;
        });
    }

    
    public sortColumn(column: Column, event: Event): void {
      event.preventDefault();
      event.stopPropagation();

      // Reset the list to the initial input list before sorting
      this.list = [...this.originalList];

      if (this.sortingState && this.sortingState.property === column.name) {
        if (this.sortingState.descending) {
          this.sortingState = { ...this.sortingState, descending: false };
        } else {
          this.sortingState = { ...this.sortingState, descending: true };
        }
      } else {
        this.sortingState = {
          property: column.name,
          descending: false,
          dataType: column.dataType,
        };
      }

      this.list.sort((a, b) => {
        let valueA = a[column.name];
        let valueB = b[column.name];

        if (column.dataType === 'date') {
          valueA = new Date(valueA).getTime();
          valueB = new Date(valueB).getTime();
        }

        if (valueA < valueB) {
          return this.sortingState.descending ? 1 : -1;
        } else if (valueA > valueB) {
          return this.sortingState.descending ? -1 : 1;
        } else {
          return 0;
        }
      });
    }

    options: TooltipOptions = {
      tooltipInvokeType: 'hover',
      tooltipPosition: 'top',
      tooltipIndent: 15,
      tooltipHasBeak: true,
      tooltipType: 'regular',
      tooltipSize: 'md',
      tooltipTheme: Themes.dark,
      tooltipMaxWidth: 300
    };
}
