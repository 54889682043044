import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { MatrixKeys, PermissionTypes } from '../constants/security-matrix';

export class PermissionCheck {
  permissionEnums: typeof MatrixKeys = MatrixKeys;
  permissionType: typeof PermissionTypes = PermissionTypes;

  constructor(
    protected readonly securityWebapiService: SecurityWebapiService
  ) {}

  isPermissionPresent(fieldKey: string, permissionType: string): boolean {
    return this.securityWebapiService.isPermissionPresent(
      fieldKey,
      permissionType
    );
  }
}
