import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  RADIO_GROUP_OPTIONS_FOR_WINNING_PROPOSAL_SCOPE,
  WINNING_PROPOSAL_RADIO_GROUP_VALUE,
} from './close-out-proposal-helper';
import { IntakeConstant } from '../../../constants/intake.constant';
import { PermissionsObj } from '../../../../common/models/common-models';
import { WinningProposalOptionValues } from '../../../constants/intake_document_close_out_winning_proposal_option_values.enum';

@Component({
  selector: 'app-close-out-proposal',
  templateUrl: './close-out-proposal.component.html',
  styleUrl: './close-out-proposal.component.less',
})
export class CloseOutProposalComponent {
  @Input() winningProposal: string = '';
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;

  @Output() winningProposalChange: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() ProposalFormYesOrNoSelected: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() ProposalFinalizeFormAnswered: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() ProposalFinalizeFormSelectedValue: EventEmitter<string> =
    new EventEmitter<string>();
  winning_proposal_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_WINNING_PROPOSAL_SCOPE;

  winning_proposal_radio_values: any = WINNING_PROPOSAL_RADIO_GROUP_VALUE;

  winning_proposalStateChanged(event: string): void {
    this.winningProposalChange.emit(event);
    if (
      event === IntakeConstant.YES.toLowerCase() ||
      event === WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW
    ) {
      this.ProposalFormYesOrNoSelected.emit(true);
    } else {
      this.ProposalFormYesOrNoSelected.emit(false);
    }
    this.ProposalFinalizeFormSelectedValue.emit(event);
    this.ProposalFinalizeFormAnswered.emit(true);
  }
}
