<progress-indicator></progress-indicator>
<div
  *ngIf="unsyncedWarningMessage"
  class="padding-right-m padding-left-s info font-size-14 line-height-sm margin-bottom-s"
>
  <div class="font-bold-600 text-align-left">Action Required</div>
  <div class="margin-top-xs text-align-left">
    {{ unsyncedWarningMessage }}
  </div>
</div>

<div
  [autosave-module]="saveopportunityDetailsModule"
  class="d-flex flex-column"
>
  <div class="guidance-note">{{ updateLabel }}</div>
  <div class="d-flex flex-row row-distribution">
    <div class="d-flex flex-column">
      <div
        *ngIf="
          isPermissionPresent(
            permissionEnums.Intake_OpportunityDetails_OpportunityNumber,
            permissionType.Visible
          )
        "
      >
        <div class="eclipse-small-header-grey text-align-left">
          Opportunity number:
        </div>
        <div class="eclipse-content text-align-left">
          {{
            oportunityDetailsInfo.opportunityNumber
              ? oportunityDetailsInfo.opportunityNumber
              : defaultForEmptyFields
          }}
        </div>
      </div>
      <div
        *ngIf="
          (originalStartDate || originalEndDate) &&
          isPermissionPresent(
            permissionEnums.Intake_Opportunity_FieldsOutOfSync,
            permissionType.Visible
          )
        "
      >
        <div class="jupiter-placeholder"></div>
      </div>
      <!--TODO: If opportunity number permission is not present need to check align - margin-top -->
      <div
        class="margin-top"
        *ngIf="
          isPermissionPresent(
            permissionEnums.Intake_OpportunityDetails_LEP,
            permissionType.Visible
          )
        "
      >
        <div class="d-flex flex-column">
          <div class="eclipse-small-header-grey text-align-left">
            Lead Engagement Partner:
          </div>
          <div
            class="input-container text-align-left"
            (click)="$event.stopPropagation()"
          >
            <ng-container *ngIf="isEditingLEP; else displayLEP">
              <div class="col-12">
                <app-people-picker
                  id="Lep-editor"
                  [isTechinalReviewerSearch]="true"
                  (userSelected)="onLepEngagementPartnerSelected($event)"
                  [isLep]="true"
                />
              </div>
            </ng-container>
            <ng-template #displayLEP>
              <span class="title-value value-font">{{
                lepValue ? lepValue : defaultForEmptyFields
              }}</span>
              <span
                *ngIf="
                  isPermissionPresent(
                    permissionEnums.Intake_OpportunityDetails_LEP,
                    permissionType.Enable
                  )
                "
                class="dds-icon dds-icon_edit__s__filled edit-icon"
                (click)="editLep()"
              ></span>
            </ng-template>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          originalLepValue &&
          isPermissionPresent(
            permissionEnums.Intake_Opportunity_FieldsOutOfSync,
            permissionType.Visible
          )
        "
        class="d-flex flex-column"
      >
        <div class="eclipse-small-header-grey text-align-left">
          Lead Engagement Partner in jupiter:
        </div>
        <span class="eclipse-small-header-grey text-align-left">
          {{ originalLepValue }}</span
        >
      </div>
    </div>
    <div class="d-flex flex-column">
      <div
        *ngIf="
          isPermissionPresent(
            permissionEnums.Intake_OpportunityDetails_StartDate,
            permissionType.Visible
          )
        "
      >
        <div class="eclipse-small-header-grey text-align-left">Start date:</div>
        <div
          class="input-container text-align-left"
          (click)="$event.stopPropagation()"
        >
          <ng-container *ngIf="isEditingStartDate; else displayStartDate">
            <div class="col-12">
              <dds-datepicker
                autosave
                [size]="htmlElementSize"
                [placeholder]="datePickerOptions.placeholder ?? ''"
                [format]="datePickerOptions.format ?? ''"
                [minMode]="datePickerOptions.minMode ?? ''"
                [disabledDates]="datePickerOptions.disabledDates ?? {}"
                [isResponsive]="datePickerOptions.isResponsive ?? false"
                [isManualInput]="datePickerOptions.isManualInput ?? false"
                [(ngModel)]="tempStartDate"
                id="StartDate-editor"
              >
              </dds-datepicker>
            </div>
          </ng-container>
          <ng-template #displayStartDate>
            <span class="title-value value-font">{{
              startDate ? startDate : defaultForEmptyFields
            }}</span>
            <span
              *ngIf="
                isPermissionPresent(
                  permissionEnums.Intake_OpportunityDetails_StartDate,
                  permissionType.Enable
                )
              "
              class="dds-icon dds-icon_edit__s__filled edit-icon"
              (click)="editStartDate()"
            ></span>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="
          originalStartDate &&
          isPermissionPresent(
            permissionEnums.Intake_Opportunity_FieldsOutOfSync,
            permissionType.Visible
          )
        "
        class="d-flex flex-column"
      >
        <div class="eclipse-small-header-grey text-align-left">
          Start date in jupiter: {{ originalStartDate }}
        </div>
      </div>

      <!--TODO: If Startdate permission is not present need to check align - margin-top -->
      <div
        class="margin-top"
        *ngIf="
          isPermissionPresent(
            permissionEnums.Intake_OpportunityDetails_TotalFees,
            permissionType.Visible
          )
        "
      >
        <div class="d-flex flex-column">
          <div class="eclipse-small-header-grey text-align-left">
            Total fees $:
          </div>
          <div
            class="input-container text-align-left"
            (click)="$event.stopPropagation()"
          >
            <ng-container *ngIf="isEditingTotalFees; else displayTotalFees">
              <div class="col-12">
                <dds-input
                  autosave
                  [placeholder]="commonPlaceholder"
                  [(ngModel)]="tempTotalFees"
                  id="TotalFees-editor"
                >
                </dds-input>
              </div>
            </ng-container>
            <ng-template #displayTotalFees>
              <span class="title-value value-font">{{
                totalFees ? totalFees : defaultForEmptyFields
              }}</span>
              <span
                *ngIf="
                  isPermissionPresent(
                    permissionEnums.Intake_OpportunityDetails_TotalFees,
                    permissionType.Enable
                  )
                "
                class="dds-icon dds-icon_edit__s__filled edit-icon"
                (click)="editTotalFees()"
              ></span>
            </ng-template>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          originalTotalFees &&
          isPermissionPresent(
            permissionEnums.Intake_Opportunity_FieldsOutOfSync,
            permissionType.Visible
          )
        "
        class="d-flex flex-column"
      >
        <div class="eclipse-small-header-grey text-align-left">
          Total fees in jupiter $: {{ originalTotalFees }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div
        *ngIf="
          isPermissionPresent(
            permissionEnums.Intake_OpportunityDetails_EndDate,
            permissionType.Visible
          )
        "
      >
        <div class="eclipse-small-header-grey text-align-left">End date:</div>
        <div
          class="input-container text-align-left"
          (click)="$event.stopPropagation()"
        >
          <ng-container *ngIf="isEditingEndDate; else displayEndDate">
            <div class="col-12">
              <dds-datepicker
                autosave
                [size]="htmlElementSize"
                [placeholder]="datePickerOptions.placeholder ?? ''"
                [format]="datePickerOptions.format ?? ''"
                [minMode]="datePickerOptions.minMode ?? ''"
                [disabledDates]="datePickerOptions.disabledDates ?? {}"
                [isResponsive]="datePickerOptions.isResponsive ?? false"
                [(ngModel)]="tempEndDate"
                id="EndDate-editor"
              >
              </dds-datepicker>
            </div>
          </ng-container>
          <ng-template #displayEndDate>
            <span class="title-value value-font">{{
              endDate ? endDate : defaultForEmptyFields
            }}</span>
            <span
              *ngIf="
                isPermissionPresent(
                  permissionEnums.Intake_OpportunityDetails_EndDate,
                  permissionType.Enable
                )
              "
              class="dds-icon dds-icon_edit__s__filled edit-icon"
              (click)="editEndDate()"
            ></span>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="
          originalEndDate &&
          isPermissionPresent(
            permissionEnums.Intake_Opportunity_FieldsOutOfSync,
            permissionType.Visible
          )
        "
        class="d-flex flex-column"
      >
        <div class="eclipse-small-header-grey text-align-left">
          End date in jupiter: {{ originalEndDate }}
        </div>
      </div>
      <div
        class="d-flex flex-column margin-top"
        *ngIf="
          isPermissionPresent(
            permissionEnums.Intake_OpportunityDetails_FeeType,
            permissionType.Visible
          )
        "
      >
        <div>
          <div
            *ngIf="isEditingFeeType"
            class="eclipse-small-header-grey text-align-left"
          >
            Fee type:
            <!--TODO:check whether we want to show edit button while in edit button-->
            <span
              *ngIf="
                isPermissionPresent(
                  permissionEnums.Intake_OpportunityDetails_FeeType,
                  permissionType.Enable
                )
              "
              class="dds-icon dds-icon_edit__s__filled edit-icon"
              (click)="editFeeType()"
            ></span>
          </div>
          <div
            class="input-container text-align-left"
            (click)="$event.stopPropagation()"
          >
            <ng-container *ngIf="isEditingFeeType; else displayFeeType">
              <div class="col-12">
                <dds-multi-select
                  class="box-size"
                  autosave
                  [disabled]="multiSelectOptions.disabled ?? false"
                  [displayTickAllBtn]="
                    multiSelectOptions.displayTickAllBtn ?? false
                  "
                  [isResponsive]="multiSelectOptions.isResponsive ?? false"
                  [isRequired]="multiSelectOptions.isRequired ?? false"
                  [label]="multiSelectOptions.label ?? ''"
                  [size]="htmlElementSize"
                  [placeholder]="multiSelectOptions.placeholder ?? ''"
                  [type]="multiSelectOptions.type ?? ''"
                  [controlType]="multiSelectOptions.controlType ?? ''"
                  [theme]="multiSelectOptions.theme ?? ''"
                  [isInverse]="multiSelectOptions.isInverse ?? false"
                  [list]="multiselectFeeTypesOptions"
                  [(ngModel)]="feeTypeList"
                  id="Fee-Type"
                >
                </dds-multi-select>
              </div>
            </ng-container>
            <ng-template #displayFeeType>
              <div class="d-flex">
                <div class="eclipse-small-header-grey text-align-left">
                  Fee type:
                </div>
                <span
                  *ngIf="
                    isPermissionPresent(
                      permissionEnums.Intake_OpportunityDetails_FeeType,
                      permissionType.Enable
                    )
                  "
                  class="dds-icon dds-icon_edit__s__filled edit-icon"
                  (click)="editFeeType()"
                ></span>
              </div>
              <div *ngFor="let feeType of feeTypeList">
                <div
                  class="eclipse-content text-align-left margin-top-fee-type"
                >
                  {{ feeType?.label ? feeType?.label : defaultForEmptyFields }}
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          originalFeeTypes.length !== 0 &&
          isPermissionPresent(
            permissionEnums.Intake_Opportunity_FieldsOutOfSync,
            permissionType.Visible
          )
        "
        class="d-flex"
      >
        <div class="eclipse-small-header-grey text-align-left">
          Fee type in jupiter:
        </div>
        <div *ngFor="let feeType of originalFeeTypes">
          <div class="eclipse-content text-align-left margin-top-fee-type">
            {{ feeType?.feeTypeName }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-row margin-top"
    *ngIf="
      isPermissionPresent(
        permissionEnums.Intake_OpportunityDetails_OpportunityDescription,
        permissionType.Visible
      )
    "
  >
    <div class="d-flex flex-column">
      <div class="eclipse-content text-align-left">
        <span
          *ngIf="isOpportunityDescriptionRequiredIconVisible()"
          class="icon-required-yellow"
        ></span
        >Provide opportunity description
      </div>
      <div class="textarea-container">
        <dds-textarea
          autosave
          name="opportunityDescription"
          [(ngModel)]="opportunityDescription"
          [label]="textareaOptions.label"
          [labelPosition]="textareaOptions.labelPosition"
          [placeholder]="textareaOptions.placeholder"
          [description]="textareaOptions.description"
          [minHeight]="textareaOptions.minHeight"
          [isRequired]="textareaOptions.isRequired"
          [customClass]="textareaOptions.customClass"
          [isError]="textareaOptions.isError"
          [errorMessage]="textareaOptions.errorMessage"
          [errorState]="textareaOptions.errorState"
          [maxLength]="textareaOptions.maxLength"
          [maxLengthPosition]="textareaOptions.maxLengthPosition"
          [size]="htmlElementSize"
          [readonly]="
            textareaOptions.readonly ||
            !isPermissionPresent(
              permissionEnums.Intake_OpportunityDetails_OpportunityDescription,
              permissionType.Enable
            )
          "
          (ngModelChange)="valueChanged()"
        ></dds-textarea>
      </div>
    </div>
  </div>
</div>
