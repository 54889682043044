import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OTHER_RADIO_GROUP_VALUE, RADIO_GROUP_OPTIONS_FOR_OTHER_SCOPE } from './close-out-other-helper';

@Component({
  selector: 'app-close-out-other',
  templateUrl: './close-out-other.component.html',
  styleUrl: './close-out-other.component.less'
})
export class CloseOutOtherComponent {
  @Input() other = '';
  @Output() otherChange = new EventEmitter<string>();
  other_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_OTHER_SCOPE
  
  other_radio_values: any = OTHER_RADIO_GROUP_VALUE

  otherStateChanged(event: string): void {
      this.otherChange.emit(event);
  }
  
}
