import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  ButtonKind,
  ErrorState,
  ExtThemes,
  LabelPosition,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { AdminConstant } from '../../../../constants/admin-constants';
import { InputOptions } from '@usitsdasdesign/dds-ng/input';
import {
  SelectItemOptions,
  SelectOptions,
} from '@usitsdasdesign/dds-ng/select';

export const ModalCloseButtonOptions: ButtonOptions = {
  theme: ExtThemes.dark,
  kind: ButtonKind.silent,
  size: AdminConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: AdminConstant.DDS_ICON_CLOSE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Close modal',
  customClass: AdminConstant.EMPTY_SPACE,
  role: 'button',
};

export const buttonOptions: ButtonOptions = {
  theme: AdminConstant.GREEN_THEME,
  kind: ButtonKind.secondaryLoud,
  size: AdminConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: AdminConstant.EMPTY_SPACE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'closeButton',
  customClass: AdminConstant.EMPTY_SPACE,
  role: 'button',
};

export const inputOptions: InputOptions = {
  size: AdminConstant.UI_ELEMENT_SIZE,
  isInverse: false,
  errorState: 'default',
  placeholder: 'Placeholder',
  tooltipIcon: 'dds-icon_info',
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipPosition: 'right',
  tooltipIndent: 20,
  tooltipHasBeak: true,
  tooltipTheme: 'dark',
};

export const selectOptions: SelectOptions = {
  label: AdminConstant.EMPTY_SPACE,
  labelPosition: LabelPosition.external,
  description: AdminConstant.EMPTY_SPACE,
  placeholder: AdminConstant.EMPTY_SPACE,
  size: AdminConstant.UI_ELEMENT_SIZE,
  disabled: false,
  isResponsive: true,
  isRequired: false,
  isInverse: false,
  isError: false,
  errorMessage: AdminConstant.EMPTY_SPACE,
  errorState: ErrorState.default,
  customClass: AdminConstant.EMPTY_SPACE,
};

export const selectItems: SelectItemOptions[] = [
  {
    heading: 'Active',
    value: 'active',
  },
  {
    heading: 'Inactive',
    value: 'inactive',
  },
];
