import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import {
  CO_AND_EL_SOW_RADIO_GROUP_VALUE,
  RADIO_GROUP_OPTIONS_FOR_CO_AND_ELSOW_SCOPE,
} from './close-out-change-order.helper';
import { WbsModel } from '../../../../common/models/wbs-picker.model';
import { CloseOutService } from '../../../../http/intake/close-out.service';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { CoElSowOptionValues } from '../../../constants/intake_document_close_out_co_elsow_option_values.enum';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { copyToClipboard } from '../../../common/intake.util';
import { IntakeConstant } from '../../../constants/intake-constant';
import { CoElSowEventEmitterType } from '../../../../common/models/intake-submission-details-common-section.model';

@Component({
  selector: 'app-close-out-change-order',
  templateUrl: './close-out-change-order.component.html',
  styleUrl: './close-out-change-order.component.less',
})
export class CloseOutChangeOrderComponent implements OnInit, OnDestroy {
  selectedWds!: WbsModel | undefined;
  constructor(private readonly closeOutService: CloseOutService) {}
  @Input() contractSignedExecuted: null | string = '';
  @Input() chargeCodeWBS: null | string = '';
  @Input() numberOfOpenArchieve: null | number = null;
  @Input() wbsDescription: null | string = '';
  @Output() coAndElSowEventEmitter: EventEmitter<CoElSowEventEmitterType> =
    new EventEmitter<CoElSowEventEmitterType>();
  wbsForCoElSow: WbsModel[] = [];
  textareaOptions: any = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  wbsForCoElSowDiplayValue: SelectItemOptions[] = [];
  co_elsow_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_CO_AND_ELSOW_SCOPE;
  co_elsow_radio_values: any = CO_AND_EL_SOW_RADIO_GROUP_VALUE;
  CoElSowOptionValueYes = CoElSowOptionValues.YES;

  wbsPendingCheckbox: boolean = false;
  numberOfOpenArchivesLabel = 'Number of open archives in archive it : ';
  wbsDescriptionChanged: Subject<string> = new Subject<string>();
  readonly #unsubscriber$: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.#loadWdsForCoElSow();
    this.wbsDescriptionChanged
      .pipe(
        takeUntil(this.#unsubscriber$),
        debounceTime(IntakeConstant.SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged()
      )
      .subscribe({
        next: (wbsDesc: string) => {
          if (wbsDesc && wbsDesc.trim() != '') {
            this.coAndElSowEventEmitter.emit({
              chargeCodeWBS: this.selectedWds?.wbsValue
                ? this.selectedWds?.wbsValue
                : null,
              numberOfOpenArchieve: this.selectedWds?.openArchivesNo
                ? this.selectedWds?.openArchivesNo
                : null,
              contractSignedExecuted: this.contractSignedExecuted,
              wbsDescription: wbsDesc,
            });
          }
        },
      });
  }

  coAndElSowStateChanged(event: string): void {
    if (event === CoElSowOptionValues.YES) {
      this.coAndElSowEventEmitter.emit({
        contractSignedExecuted: event,
        chargeCodeWBS: null,
        numberOfOpenArchieve: null,
        wbsDescription: null,
      });
    } else {
      this.chargeCodeWBS = null;
      this.wbsPendingCheckbox = false;
      this.wbsDescription = '';
      this.selectedWds = undefined;
      this.coAndElSowEventEmitter.emit({
        contractSignedExecuted: event,
        chargeCodeWBS: null,
        numberOfOpenArchieve: null,
        wbsDescription: null,
      });
    }
  }

  #loadWdsForCoElSow(): void {
    this.closeOutService
      .getWbsPickers()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: WbsModel[]) => {
          this.wbsForCoElSow = response;
          this.wbsForCoElSowDiplayValue = response.map((wbs: WbsModel) => {
            return {
              heading: wbs.wbsValue,
              value: wbs.wbsValue,
              disabled: false,
            };
          });
        },
        error: (error) => {
          console.log('error', error);
        },
      });
  }

  clipboardCopy(): void {
    if (this.chargeCodeWBS != null) {
      copyToClipboard(this.chargeCodeWBS);
    }
  }

  onWbsChargedCodeChange(event: string): void {
    this.selectedWds = this.wbsForCoElSow.find(
      (wds: WbsModel) => wds.wbsValue === event
    );
    if (this.selectedWds) {
      this.numberOfOpenArchivesLabel += this.selectedWds?.openArchivesNo;
      this.coAndElSowEventEmitter.emit({
        chargeCodeWBS: event ? event : null,
        numberOfOpenArchieve: this.selectedWds?.openArchivesNo,
        contractSignedExecuted: this.contractSignedExecuted,
        wbsDescription: this.wbsDescription,
      });
    }
  }

  wbsDescriptionValueChanged(): void {
    if (this.wbsDescription?.trim() != '') {
      this.wbsDescriptionChanged.next(this.wbsDescription ?? '');
    }
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next('');
    this.#unsubscriber$.complete();
  }
}
