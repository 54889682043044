import { IntakeConstant } from '../../constants/intake.constant';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MultiSelectItem } from '@usitsdasdesign/dds-ng/multi-select';
import { SubmissionService } from '../../../http/intake/submission.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import {
  deloitteIsraelBtns,
  deloitteIsraelGroupOptions,
  hasERS230GroupOptions,
  infraSecurityconsultGroupOptions,
  multiSelectOptions,
  options,
  radioBtns,
  radioGroupOptions,
  serviceInvolvementOptions,
  servicesDisruptClientGroupOptions,
  textareaOptions,
} from '../complexity-questions/complexity-questions-setting';
import {
  complexityCyberQuestionsData,
  complexityQuestionsData,
} from '../../../common/models/complexity-question-data.model';
import { OpportunityDetailsNavModel } from '../../../common/models/opportunity-details.model';
import { commonSectionForSingleCoCheck } from '../../common/intake.util';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { SecurityWebapiService } from '../../../http/security/security-webapi.service';
import { PermissionCheck } from '../../../common/validations/PermissionCheck';

@Component({
  selector: 'app-complexity-cyber-questions',
  templateUrl: './complexity-cyber-questions.component.html',
  styleUrl: './complexity-cyber-questions.component.less',
})
export class ComplexityCyberQuestionsComponent
  extends PermissionCheck
  implements OnInit, OnDestroy
{
  @Input() OpportunityID: string | null = '';
  @Input() submissionData: OpportunityDetailsNavModel[] = [];
  saveComplexityCyberModule!: Saver;
  model: complexityQuestionsData = {} as complexityQuestionsData;
  complexityCyberForm!: FormGroup;
  private readonly destroy$: Subject<any> = new Subject<any>();

  serviceInvolvementOptions = serviceInvolvementOptions;
  textareaOptions = textareaOptions;
  multiSelectOptions = multiSelectOptions;
  options = options;
  deloitteIsraelGroupOptions = deloitteIsraelGroupOptions;
  servicesDisruptClientGroupOptions = servicesDisruptClientGroupOptions;
  hasERS230GroupOptions = hasERS230GroupOptions;
  infraSecurityconsultGroupOptions = infraSecurityconsultGroupOptions;
  radioGroupOptions = radioGroupOptions;
  radioBtns = radioBtns;
  deloitteIsraelBtns = deloitteIsraelBtns;
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;

  constructor(
    private readonly fb: FormBuilder,
    private readonly autosaverService: AutosaverService,
    private readonly submissionService: SubmissionService,
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
  }
  ngOnInit(): void {
    this.initializeForm();
    this.emitFormData();
    this.submissionService.updateComplexQuestionCyberStatus(
      this.complexityCyberForm.valid
    );
    this.complexityCyberForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.submissionService.updateComplexQuestionCyberStatus(
          this.complexityCyberForm.valid
        );
      });
    let autoSaverOptions = {
      onSuccess: (saver: any, result: complexityQuestionsData) => {
        return this.autoSaveHandler();
      },
    } as IAutosaverOptions;

    this.saveComplexityCyberModule = this.autosaverService.newSaver(
      'model',
      this,
      (model) => this.saveDetails(model),
      autoSaverOptions
    );

    this.saveComplexityCyberModule.start();

    this.complexityCyberForm.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.emitFormData();
        this.saveComplexityCyberModule?.saveNow();
      });

    this.getExistingFormData(this.OpportunityID);
  }

  initializeForm(): void {
    this.complexityCyberForm = this.fb.group({
      deloitteIsrael: ['', Validators.required],
      deloitteIsraelExplain: ['', []],
      servicesDisruptClient: ['', Validators.required],
      hasERS230: ['', []],
      infraSecurityconsult: ['', []],
      serviceInvolvement: [[], Validators.required],
    });

    if (
      !this.isPermissionPresent(
        this.permissionEnums.Intake_Opportunity_OfferingServiceQuestions,
        this.permissionType.Enable
      )
    ) {
      this.complexityCyberForm.disable();
      this.options.disabled = true;
    }
    this.complexityCyberForm
      .get('deloitteIsrael')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const deloitteIsraelExplainControl = this.complexityCyberForm.get(
          'deloitteIsraelExplain'
        );
        if (value === IntakeConstant.NONE_EXPLAIN) {
          deloitteIsraelExplainControl?.setValidators([Validators.required]);
        } else {
          deloitteIsraelExplainControl?.clearValidators();
          deloitteIsraelExplainControl?.setValue('');
        }
        deloitteIsraelExplainControl?.updateValueAndValidity();
      });

    this.complexityCyberForm
      .get('servicesDisruptClient')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const hasERS230Control = this.complexityCyberForm.get('hasERS230');
        if (value === IntakeConstant.YES) {
          hasERS230Control?.setValidators([Validators.required]);
        } else {
          hasERS230Control?.clearValidators();
          hasERS230Control?.setValue('');
        }
        hasERS230Control?.updateValueAndValidity();
      });

    this.complexityCyberForm
      .get('hasERS230')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const infraSecurityconsultControl = this.complexityCyberForm.get(
          'infraSecurityconsult'
        );
        if (value === IntakeConstant.YES) {
          infraSecurityconsultControl?.setValidators([Validators.required]);
        } else {
          infraSecurityconsultControl?.clearValidators();
          infraSecurityconsultControl?.setValue('');
        }
        infraSecurityconsultControl?.updateValueAndValidity();
      });
  }

  get hasOptionsSelected(): boolean {
    const selected = this.complexityCyberForm.get('serviceInvolvement')?.value;
    const targetLabels: MultiSelectItem = [
      'This is an implementation of a newer technology/tool/module (e.g., less than two years experience with the technology)',
      'This is a custom development engagement (e.g., Deloitte is developing software or writing code)',
      '[A+C] does not have an established relationship with the technology provider (e.g., MBR, dedicated account team)',
    ];
    return (
      selected?.some((item: MultiSelectItem) =>
        targetLabels['includes'](item.label)
      ) || false
    );
  }

  autoSaveHandler(): void {
    //if refresh is needed after save API, can be done here;
  }

  saveDetails(
    model: complexityCyberQuestionsData
  ): Promise<complexityCyberQuestionsData> {
    if (
      this.isPermissionPresent(
        this.permissionEnums.Intake_Opportunity_OfferingServiceQuestions,
        this.permissionType.Enable
      )
    ) {
      return this.submissionService.saveComplexityCyberdata(model).toPromise();
    }
    return Promise.resolve({} as complexityQuestionsData);
  }

  getExistingFormData(opportunityID: string | null): void {
    if (opportunityID) {
      this.submissionService
        .getComplexityResponse(opportunityID)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.model = data;
            this.patchFormWithData(data);
            this.emitFormData();
          }
        });
    }
  }

  emitFormData(): void {
    const formData = this.complexityCyberForm.value;
    const transformedData: complexityQuestionsData = {
      opportunityNumber: this.OpportunityID,
      deloitteIsrael: formData.deloitteIsrael || null,
      deloitteIsraelExplain: formData.deloitteIsraelExplain || null,
      servicesDisruptClient: formData.servicesDisruptClient || null,
      hasERS230: formData.hasERS230 || null,
      infraSecurityconsult: formData.infraSecurityconsult || null,
      serviceInvolvement:
        formData.serviceInvolvement
          ?.filter((item: MultiSelectItem) => item.value)
          .map((item: MultiSelectItem) => item.label)
          .join(';') || null,
    };
    this.model = transformedData;
  }

  patchFormWithData(data: complexityQuestionsData): void {
    const createMultiSelectItems = (
      dataString: string | null | undefined
    ): MultiSelectItem[] => {
      if (!dataString) return [];
      return dataString
        .split(';')
        .filter((label) => label.trim().length > 0)
        .map((label) => ({
          label: label.trim(),
          value: true,
        }));
    };

    const serviceInvolvement = createMultiSelectItems(data.serviceInvolvement);

    this.complexityCyberForm.patchValue({
      deloitteIsrael: data.deloitteIsrael || '',
      deloitteIsraelExplain: data.deloitteIsraelExplain || '',
      servicesDisruptClient: data.servicesDisruptClient || '',
      hasERS230: data.hasERS230 || '',
      infraSecurityconsult: data.infraSecurityconsult || '',
      serviceInvolvement: serviceInvolvement || [],
    });

    this.emitFormData();

    ['serviceInvolvement'].forEach((item) =>
      this.valueChanged(this.complexityCyberForm.get(item)?.value, item)
    );
  }

  removeServiceInvolvement(item: MultiSelectItem): void {
    const current = this.complexityCyberForm.get('serviceInvolvement')
      ?.value as MultiSelectItem[];
    const updated = current.filter((i) => i !== item);
    this.valueChanged(updated, 'serviceInvolvement');
  }

  isDeloitteIsraelExplainRequired(): boolean {
    if (commonSectionForSingleCoCheck(this.submissionData)) {
      return false;
    } else if (
      this.complexityCyberForm.get('deloitteIsrael')?.invalid ||
      (this.complexityCyberForm.get('deloitteIsrael')?.value ===
        'None [Explain]' &&
        this.complexityCyberForm.get('deloitteIsraelExplain')?.invalid)
    ) {
      return true;
    } else {
      return false;
    }
  }

  isServicesDisruptClientRequired(): boolean {
    if (commonSectionForSingleCoCheck(this.submissionData)) {
      return false;
    } else if (
      this.complexityCyberForm.get('servicesDisruptClient')?.invalid ||
      (this.complexityCyberForm.get('servicesDisruptClient')?.value === 'Yes' &&
        this.complexityCyberForm.get('hasERS230')?.invalid) ||
      (this.complexityCyberForm.get('servicesDisruptClient')?.value === 'Yes' &&
        this.complexityCyberForm.get('hasERS230')?.value === 'Yes' &&
        this.complexityCyberForm.get('infraSecurityconsult')?.invalid)
    ) {
      return true;
    } else {
      return false;
    }
  }

  isServiceInvolvementRequired(): boolean {
    if (commonSectionForSingleCoCheck(this.submissionData)) {
      return false;
    } else if (this.complexityCyberForm.get('serviceInvolvement')?.invalid) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next('');
    this.destroy$.complete();
  }

  valueChanged(event: MultiSelectItem[], formName: string): void {
    const optionsName: string = `${formName}Options`;

    const noneOptionSelected: boolean = event.some(
      (item: MultiSelectItem) =>
        item.label === IntakeConstant.NONE && item.value
    );
    const notYetOptionSelected: boolean = event.some(
      (item: MultiSelectItem) =>
        item.label === IntakeConstant.NOT_YET_DETERMINED && item.value
    );

    if (noneOptionSelected || notYetOptionSelected) {
      (this as unknown as { [key: string]: MultiSelectItem[] })[
        optionsName
      ]?.map((item: MultiSelectItem) => {
        if (item.label === IntakeConstant.NONE && noneOptionSelected) {
          item.disabled = false;
        } else if (
          item.label === IntakeConstant.NOT_YET_DETERMINED &&
          notYetOptionSelected
        ) {
          item.disabled = false;
        } else {
          item.disabled = true;
          item.value = false;
        }
        return item;
      });
    } else {
      (this as unknown as { [key: string]: MultiSelectItem[] })[
        optionsName
      ]?.map((item: MultiSelectItem) => {
        item.disabled = false;
        if (event.length === 0) {
          item.value = false;
        } else {
          item.value = event.some(
            (selected: MultiSelectItem) => selected.label === item.label
          );
        }
        return item;
      });
    }

    this.complexityCyberForm
      .get(formName)
      ?.setValue(
        (this as unknown as { [key: string]: MultiSelectItem[] })[
          optionsName
        ]?.filter((item) => item.value) ?? []
      );
  }
}
