import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { NCASpecialistWebapiService } from '../../http/dashboard/nca-specialist-webapi.service';
import { ClientMilestoneStatus, ClientStatusWebapiService } from '../../http/client/client-status-webapi.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { environment } from '../../../environment/environment';
import { NcaProcessDialogComponent } from '../../client/client-status/nca-process-dialog/nca-process-dialog.component';
import { DropdownItemOptions } from '@usitsdasdesign/dds-ng/dropdown';
import { DiscontinueNcaDialogComponent } from '../../client/client-status/discontinue-nca-dialog/discontinue-nca-dialog.component';
import { ConvertNcaToCcDialogComponent } from '../../client/client-status/convert-nca-to-cc-dialog/convert-nca-to-cc-dialog.component';
import { NcaAssignToDialogComponent } from '../../common/components/nca-assign-to-dialog/nca-assign-to-dialog.component';
import { DashboardGridLevelFilter, DashboardGridSortParameter, DashboardTileCode, GridColumn } from '../../common/models/start-page/start-page.model';
import { NCASpecialistDashboardModel,NCADashboardTilesCount, NCATileCode, NCATileCodeDetails } from '../../common/models/start-page/nca-specialist-dashboard.model';
import { ConvertCcToNcaDialogComponent } from '../../client/client-status/convert-cc-to-nca-dialog/convert-cc-to-nca-dialog.component';
import { DiscontinueCcDialogComponent } from '../../client/client-status/discontinue-nca-dialog/discontinue-cc-dialog.component';
import { MarsNcaService } from '../../http/mars/mars-nca.service';
import { Subject, takeUntil } from 'rxjs';
import { IntakeConstant } from '../../intake/constants/intake.constant';

@Component({
  selector: 'app-nca-specialist-dashboard',
  templateUrl: './nca-specialist-dashboard.component.html',
  styleUrl: './nca-specialist-dashboard.component.less'
})
export class NcaSpecialistDashboardComponent implements OnInit {

  public ncaTiles = NCATileCode;
  public ncaTileCodeDetails = Array.from(NCATileCodeDetails.values());
  public selectedTileCode: string = '';
  public dashboard: string = 'NCADashboard';
  public employeeId: string = '00000000';
  public ncaDashboardTilesCountModel: NCADashboardTilesCount | any;
  public model: NCASpecialistDashboardModel[] = [];
  public gridTitle: string = "";
  public gridColumns: GridColumn[] = [];
  public ncaDashboardfilters: DashboardGridLevelFilter[] = [];
  public countforPagination: number = 0;
  public gotoPage: number = 1;
  public itemsPerPage: number = IntakeConstant.PAGE_NUMBER_10;
  public enumDashboardGridSortParameter:any=DashboardGridSortParameter;
  public enumClientMilestoneStatus = ClientMilestoneStatus;
  public clientDictionary: any;
  public optionsClientMilestoneSubStatus: any[] = [];
  public dropdownItems: DropdownItemOptions[] = [];
  public currentDashboardTileCode = DashboardTileCode.NCASpecialist
  public buttonOptionsList: { label: string; action: string; condition: boolean; }[] = [];
  public rootUrl: string = environment.rootUrl; 
  public sortParamaterCode: number = 1;
  public isAscending: boolean = true;
  public cclabelHeaderText: string = 'Discontinue Client Continuance form';
  public cclabelBodyText: string = 'Provide the reason to discontinue:';
  public ccProcessSubStatusLabel: string ='Client Continuance Sub-Status';
  public optionsClientMilestoneCCSubStatus: any[] = [];
  public selectedTileCount: number = 0;
  public industrySelectItems: any[] = [];
  public marsIndustryLeaders: any[] = [];
  public unsubscriber$ = new Subject();
  public isMarsNCASpecialist: boolean = false;

  public defaultGridColumns: GridColumn[] = [
    { name: 'clientName', header: 'Client', dataType: 'string', minWidth: '12rem', width: '12rem', filterable: false, searchValue: '' },
    { name: 'parentClientName', header: 'Parent client', dataType: 'string', minWidth: '12rem', width: '12rem', filterable: false, searchValue: '' },
    { name: 'business', header: 'Business', dataType: 'string', minWidth: '7rem', filterable: false, searchValue: '' },
    { name: 'clientType', header: 'Client type', dataType: 'string', minWidth: '7rem', filterable: false, searchValue: '' },
    { name: 'recommendingPPMD', header: 'Recommending PPMD', dataType: 'string', minWidth: '7rem', filterable: false, searchValue: '' },
    { name: 'descDesignation', header: 'DESC designation (Services)', dataType: 'string', minWidth: '8.75rem', filterable: false, searchValue: '' },
    { name: 'actionByName', header: 'Action by', dataType: 'string', width: '11.1%', minWidth: '7rem', filterable: false, searchValue: '' },
    { name: 'ncaSpecialistName', header: 'NCA specialist', dataType: 'string', minWidth: '7rem', filterable: false, searchValue: '' },
    { name: 'industry', header: 'Industry', dataType: 'string', minWidth: '5rem', filterable: false, searchValue: ''},
    { name: 'industryLeader', header: 'Industry leader', dataType: 'string', minWidth: '5rem', filterable: false, searchValue: ''},
    { name: 'daysPendingForCCCompletion', header: 'Days pending for CC completion', dataType: 'number', minWidth: '5rem', filterable: false, searchValue: ''},
    { name: 'continuanceExpiredDate', header: 'Continuance expired', dataType: 'date', minWidth: '5rem', filterable: false, searchValue: '' },
    { name: 'approverName', header: 'Approver name', dataType: 'string', minWidth: '5rem', filterable: false, searchValue: '' },
    { name: 'priorFyClosedWBS', header: 'Prior FY closed WBSs', dataType: 'number', minWidth: '8rem', filterable: false, searchValue: '' },
    { name: 'netServiceRevenue', header: 'Net services revenue', dataType: 'number', minWidth: '8.75rem', filterable: false, searchValue: '' },
    { name: 'recommendedClientGroupNumber', header: 'Recommended group', dataType: 'string', minWidth: '5rem', filterable: false, searchValue: '' },
    { name: 'opportunityExpectedCloseDate', header: 'Expected close date', dataType: 'date', minWidth: '8rem', filterable: false, searchValue: '' },
    { name: 'ncaTriggeredDate', header: 'NCA triggered date', dataType: 'date', minWidth: '8rem', filterable: false, searchValue: '' },
    { name: 'actions', header: 'Actions', dataType: '', minWidth: '8.75rem', filterable: false, searchValue: '' },
  ];


  constructor(private modalService: ModalService, 
    private marsNCAService: MarsNcaService,
    private clientstatuswebapiservice: ClientStatusWebapiService,
    private ncaspecialistWebapiService: NCASpecialistWebapiService,
    private progressIndicatorService: ProgressIndicatorService) { }

    
  ngOnInit() {
    this.progressIndicatorService.show();
    this.loadIndustryLeaders();//TODO:need to replace with admin key
    this.clientstatuswebapiservice.getDropdownCommonData();
    this.clientstatuswebapiservice.dropdowncommondata$.subscribe(response => {
      if (response) {
        this.clientDictionary = response;
        // Access specific dictionary entries if needed
        this.optionsClientMilestoneSubStatus = this.clientDictionary['optionsClientMilestoneSubStatus'].resultData || [];
        this.optionsClientMilestoneCCSubStatus = this.clientDictionary['optionsClientMilestoneCCSubStatus'].resultData || [];
        this.industrySelectItems = this.clientDictionary['optionsOpportunityIndustry'].resultData||[];
      }
    });
    this.selectedTileCode = this.ncaTiles.CompleteNcaForm;
    this.gridTitle = this.ncaTileCodeDetails.find(x => x.tileCode === this.selectedTileCode)?.title || '';

    this.ncaspecialistWebapiService.getNCADashboardTilesCount().subscribe(response => {
      this.ncaDashboardTilesCountModel = response;
      this.selectedTileCount = this.ncaDashboardTilesCountModel[this.ncaTileCodeDetails.find(x => x.tileCode === this.selectedTileCode)?.countKey || ''];
    });
    this.gotoPage = 1;
    this.onPageChanged(this.gotoPage); 
  }
  ngOnDestroy(): void {
    this.progressIndicatorService.hide();
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
  onTileSelected(selectedTileCode: string) {
    this.selectedTileCode = selectedTileCode;
    this.gridTitle = this.ncaTileCodeDetails.find(x => x.tileCode === this.selectedTileCode)?.title || '';
    this.selectedTileCount = this.ncaDashboardTilesCountModel[this.ncaTileCodeDetails.find(x => x.tileCode === this.selectedTileCode)?.countKey || ''];

    // Reset the filters and sorting state
    if (this.selectedTileCode == 'CompleteMarsNcaForm') {
      this.sortParamaterCode = this.enumDashboardGridSortParameter.marsActionSort;//Applied for Mars Action Sort
    }
    else {
      this.sortParamaterCode = 1;
    }
    this.isAscending = true;
    this.ncaDashboardfilters = [];
    // Reset the page number
    this.gotoPage = 1;
    this.onPageChanged(this.gotoPage); 
  }
  public loadIndustryLeaders(): void {
    this.progressIndicatorService.show();
    this.marsNCAService.getIndustryLeader()
      .pipe(takeUntil(this.unsubscriber$)).subscribe({
        next: (data: any) => {
          if (data) {
            this.marsIndustryLeaders = data.industryLeader;
          }
          this.progressIndicatorService.hide();
        }
      });
  }
  loadMarsIndustryData() {
    if (this.selectedTileCode == 'CompleteMarsNcaForm' && this.model?.length > 0) {
      this.model.forEach((modalItem: any) => {
        let selectedIndustry = this.industrySelectItems.filter((item) => item.itemCode == modalItem.industry);
        modalItem.industry = selectedIndustry.length > 0 ? selectedIndustry[0].heading : null;
        let industryLeaderData = this.marsIndustryLeaders.filter((item) => item.industry.includes(modalItem.industry));
        modalItem.industryLeader = industryLeaderData.length > 0 ? industryLeaderData[0].leaderName : "";
      });
    }
  }
  refreshGrid() {
    this.progressIndicatorService.show();
    this.ncaspecialistWebapiService.getNCADashBoardGridData(this.selectedTileCode, this.gotoPage, this.itemsPerPage, this.sortParamaterCode, this.isAscending, this.ncaDashboardfilters).subscribe(response => {
      this.model = response.dataList;
      this.loadMarsIndustryData(); //TODO: Need to handle with Admin Data;
      this.handleCCDueColumns();
      this.countforPagination = response.totalDataCount;
      this.progressIndicatorService.hide();
    });
    this.gridColumns = [...this.defaultGridColumns]
          
    if (this.selectedTileCode !== 'NcaNotRequired') {
      this.gridColumns = this.gridColumns.filter(column => column.name !== 'actionByName');
    }
    if (this.selectedTileCode !== 'OnHold' && this.selectedTileCode !== 'NcaDiscontinued') {
      this.gridColumns = this.gridColumns.filter(column => column.name !== 'ncaSpecialistName');
    }
    if (this.selectedTileCode !== 'OnHold' && this.selectedTileCode === 'NcaDiscontinued') {
      this.gridColumns = this.gridColumns.filter(column => column.name !== 'opportunityExpectedCloseDate');
    }
  
    if (this.selectedTileCode === 'CCDueNow') {
      this.gridColumns = this.gridColumns.filter(column => column.name !== 'recommendedClientGroupNumber'&&column.name !== 'opportunityExpectedCloseDate'&&column.name !== 'ncaTriggeredDate');
    }
    else{
      this.gridColumns = this.gridColumns.filter(column => column.name !== 'daysPendingForCCCompletion'&&column.name !== 'continuanceExpiredDate'&&column.name !== 'approverName' && column.name !== 'priorFyClosedWBS' && column.name!=='netServiceRevenue' && column.name !== 'business');
    }
    if (this.selectedTileCode === 'CompleteMarsNcaForm') {
      this.gridColumns = this.gridColumns.filter(column => column.name !== 'descDesignation'&&column.name !== 'recommendedClientGroupNumber'&&column.name !== 'ncaTriggeredDate');
    }
    else{
      this.gridColumns = this.gridColumns.filter(column => column.name !== 'industryLeader'&&column.name !== 'clientType'&&column.name !== 'recommendingPPMD');
    }
    // Adjust column widths dynamically excluding the expand-collapse icon column
    const totalColumns = this.gridColumns.length;
    const baseWidth = 87 / totalColumns; // 95% to leave space for the expand-collapse icon column
    this.gridColumns.forEach(column => {
      if (column.name !== 'actionByName' && column.name !== 'clientName' && column.name !== 'parentClientName') {
        column.width = `${baseWidth}%`;
      }
    });
    //Specific Adjusting Column Width for CCDueNow only
    if(this.selectedTileCode === 'CCDueNow'){
       this.gridColumns.forEach(column => {
          column.width = (column.name == 'clientName' || column.name == 'parentClientName')?"10rem":(column.name == 'descDesignation' || column.name == 'daysPendingForCCCompletion')?"9.5%":column.name == 'continuanceExpiredDate'? "9.7%":column.name=='netServiceRevenue'?"9.7%":column.width;
      });
    }

    this.getActionButtons();
  }
  handleCCDueColumns() { //Handling Mars NCA Specialist View
    if (this.selectedTileCode === 'CCDueNow' && this.model?.length > 0) {
      this.isMarsNCASpecialist = this.model[0]?.isMarsUser;
      this.gridColumns = this.isMarsNCASpecialist ? this.gridColumns : this.gridColumns.filter(column => column.name !== 'business');
      this.gridColumns.forEach(column => {
        column.width = (this.isMarsNCASpecialist && column.name=='daysPendingForCCCompletion')?"7.5%":column.width; //Added for Mars NCA Specialist View
    });
    }
  }
     
  onPageChanged(value: number) {
    this.gotoPage = value - 1;
    this.refreshGrid();
  }

  onValueChanged(item: number) {
    this.itemsPerPage = item;
    this.gotoPage = 0;
    this.refreshGrid();
  }

  buttonSelected(event: { value: string, rowItem: NCASpecialistDashboardModel }) {
    const { value, rowItem } = event;
    // This will disable the scroll when the pop-up is open
    document.body.classList.add('no-scroll');
    if (value == 'NCANotRequired') {
      this.setNCANotRequired(rowItem);
    }
    else if (value == 'OnHold') {
      this.setNCAOnHold(rowItem);
    }
    else if (value == 'InitiateForm') {
      this.initiateForm(rowItem);
    }
    else if(value == 'GoToForm') {
      this.gotoClientStatus(rowItem.clientNumber);
    }
    else if (value == 'ResetNCA') {
      this.setNcaReset(rowItem);
    }
    else if (value == 'Resume') {
      this.setNCAResume(rowItem);   
    }
    else if (value == 'ConvertToCC') {
      this.convertNCAToCC(rowItem);
    }
    else if (value == 'ConvertToNCA') {
      this.convertCCtoNCA(rowItem);
    }
    else if (value == 'AssignTo') {
      this.AssignTo(rowItem.clientMilestoneId);
    }
    else if (value == 'Discontinue') {
     this.selectedTileCode=='CCDueNow'?this.setCCDiscontinue(rowItem):this.setNCADiscontinue(rowItem);
    }
  }

  
  public initiateForm(item: NCASpecialistDashboardModel) {
    this.clientstatuswebapiservice.initiateNCAForm(item.clientNumber, item.clientMilestoneId!)
      .subscribe((data: any) => {
        if (data.isSuccess) {
          this.gotoClientStatus(item.clientNumber);
        }
      });
  }

  public gotoClientStatus(clientNumber : string) {
    let url = this.rootUrl + 'client/'+ clientNumber +'/status';
    window.open(url, '_blank');
  }

  public setNcaReset(item: NCASpecialistDashboardModel) {
    return this.clientstatuswebapiservice.setNCAReset(item.clientMilestoneId!, item.clientNumber)
      .subscribe((data: any) => {
        if (data.isSuccess) {
          location.reload(); // Refresh the page
        }
      });
  };

  public setNCANotRequired(item: NCASpecialistDashboardModel) {
    let modalRef = this.modalService.open(NcaProcessDialogComponent,
      {
        size: 'xl',
        backdrop: 'static',
        centered: true,
        windowClass: ' center-modal modal-fade-in modal-adaptive nca-process-dialog-modal'
      });
    let labelHeaderText = 'NCA Procedures not required/accepted for {clientName}'

    if (item.clientName) {
      labelHeaderText = labelHeaderText
        .replace('{clientName}', item.clientName);
    }

    modalRef.componentInstance.resolve = {
      labelHeader: labelHeaderText,
      labelBody: 'Reason:',
      ncaProcessSubStatus_Label: 'NCA Sub-Status:',
      employeeId: '00000000',//TOOD: Pass proper employeeid
      optionsClientMilestoneSubStatus: this.optionsClientMilestoneSubStatus
        .filter((item: any) => item.clientMilestoneStatusID == this.enumClientMilestoneStatus.NotRequired),
      clientNumber: item.clientNumber,
      clientMilestoneId: item.clientMilestoneId,
      isNCANotRequired: true,
      isClientMilestoneDcAttestNonAttestNca: 0
    };
  }

  public setNCAOnHold(item: NCASpecialistDashboardModel) {
    let modalRef = this.modalService.open(NcaProcessDialogComponent,
      {
        size: 'xl',
        backdrop: 'static',
        centered: true,
        windowClass: ' center-modal modal-fade-in modal-adaptive nca-process-dialog-modal'
      });
    let labelHeaderText = 'Place NCA on hold';

    modalRef.componentInstance.resolve = {
      labelHeader: labelHeaderText,
      labelBody: 'Reason:',
      employeeId: '00000000',
      clientNumber: item.clientNumber,
      clientMilestoneId: item.clientMilestoneId,
      isOnhold: true,
      isClientMilestoneDcAttestNonAttestNca: 0,
    };
  }

  public setNCADiscontinue(item: NCASpecialistDashboardModel) {
    let modalRef = this.modalService.open(DiscontinueNcaDialogComponent,
      {
        size: 'xl',
        backdrop: 'static',
        centered: true,
        windowClass: ' center-modal modal-fade-in modal-adaptive nca-process-dialog-modal'
      });
    modalRef.componentInstance.resolve = {
      labelHeader: 'Discontinue NCA form',
      labelBody: 'Provide the reason to discontinue NCA current form:',
      ncaProcessSubStatus_Label: 'NCA Sub-Status:',
      optionsClientMilestoneSubStatus: this.optionsClientMilestoneSubStatus
        .filter((item: any) => item.clientMilestoneStatusID == this.enumClientMilestoneStatus.Discontinue),
      clientNumber: item.clientNumber,
      clientMilestoneId: item.clientMilestoneId,
      clientMilestoneStatusID: this.enumClientMilestoneStatus.Discontinue,
      isMultiOpportunityDisclaimerVisible: false, //item.isMultiOpportunityDisclaimerVisible, TODO uncomment once api is implemented
      multiOpportunityDisclaimerText: 'Please, note there are active opportunities for this client',

    };
  }
  public setCCDiscontinue(item: NCASpecialistDashboardModel) {
    let modalRef = this.modalService.open(DiscontinueCcDialogComponent,
      {
        size: 'xl',
        backdrop: 'static',
        centered: true,
        windowClass: ' center-modal modal-fade-in modal-adaptive nca-process-dialog-modal'
      });
    modalRef.componentInstance.resolve = {
      labelHeader: this.cclabelHeaderText,
      labelBody: this.cclabelBodyText,
      ncaProcessSubStatus_Label: this.ccProcessSubStatusLabel,
      //CC Substatus selected in dropdown
      optionsClientMilestoneSubStatus: this.optionsClientMilestoneCCSubStatus
        .filter((item: any) => item.clientMilestoneStatusID == this.enumClientMilestoneStatus.Discontinue),
      clientMilestoneId: item.clientMilestoneId,
      clientMilestoneStatusID: this.enumClientMilestoneStatus.Discontinue
    };
  }
  public convertCCtoNCA(item: NCASpecialistDashboardModel) {
    let modalRef = this.modalService.open(ConvertCcToNcaDialogComponent, {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      windowClass: ' center-modal modal-fade-in modal-adaptive convert-nca-to-cc-dialog-modal'
    })
    let labelHeaderText = 'Convert client continuance to NCA';
    modalRef.componentInstance.resolve = {
      labelHeader: labelHeaderText,
      labelBody: 'Provide the reason',
      clientMilestoneId: item.clientMilestoneId
    };
  }
  public convertNCAToCC(item: NCASpecialistDashboardModel) {
    var IsExistsDCSplitPursuitLeader = true;

    if (IsExistsDCSplitPursuitLeader) {
      let modalRef = this.modalService.open(ConvertNcaToCcDialogComponent, {
        size: 'xl',
        backdrop: 'static',
        centered: true,
        windowClass: ' center-modal modal-fade-in modal-adaptive convert-nca-to-cc-dialog-modal'
      })
      let labelHeaderText = 'Convert NCA to client continuance';
      modalRef.componentInstance.resolve = {
        labelHeader: labelHeaderText,
        labelBody: 'Provide the reason',
        clientNumber: item.clientNumber,
        clientMilestoneId: item.clientMilestoneId
      };
    }
  }

  public setNCAResume(item: NCASpecialistDashboardModel) {
    let convertintomilestonestatusid;
    if (item.doesNcaFormExists) {
      convertintomilestonestatusid = (item.clientMilestoneStatusId == this.enumClientMilestoneStatus.OnHold &&
        item.prevClientMilestoneStatusId == this.enumClientMilestoneStatus.PendingApprovals)
        ? this.enumClientMilestoneStatus.PendingApprovals
        : this.enumClientMilestoneStatus.InProcess;
    }
    else {
      convertintomilestonestatusid = this.enumClientMilestoneStatus.NotStarted;
    }

    //TODO: Pass proper employeeid
    this.clientstatuswebapiservice
      .updateNCAStatus(item.clientNumber, null,
        item.clientMilestoneId!, null,
        convertintomilestonestatusid, null)
      .subscribe((data: any) => {
        location.reload();
      });
  }

  public async AssignTo(clientMilestoneId?:number) {
    this.clientstatuswebapiservice.GetNCASpecialists(true/*isFromStartPage*/).subscribe((res)=>{
        let modalRef = this.modalService.open(NcaAssignToDialogComponent);
        modalRef.componentInstance.resolve = {
                    labelHeader: 'Assign to',
                    usersList: res, // EmployeeView[]
                    clientMilestoneID: clientMilestoneId
                };
    });
    
  }

  getActionButtons() {
    this.dropdownItems = [
      {
        heading: 'NCA procedures not required/accepted',
        value: 'NCANotRequired',
        disabled: false
      },
      {
        heading: 'On hold',
        value: 'OnHold'
      },
      {
        heading: 'Convert to CC',
        value: 'ConvertToCC'
      },
      {
        heading: 'Convert to NCA',
        value: 'ConvertToNCA'
      },
      {
        heading: 'Assign to',
        value: 'AssignTo'
      },
      {
        heading: 'Discontinue',
        value: 'Discontinue'
      }
    ];
    
    this.buttonOptionsList = [
      {
        label: 'Initiate form',
        action: 'InitiateForm',
        condition: this.selectedTileCode === this.ncaTiles.CompleteNcaForm
      },
      {
        label: 'Go to form',
        action: 'GoToForm',
        condition: this.selectedTileCode  === this.ncaTiles.PreScreenNcaForm
      },
      {
        label: 'Reset NCA',
        action: 'ResetNCA',
        condition: this.selectedTileCode  === this.ncaTiles.NcaNotRequired
      },
      {
        label: 'Resume',
        action: 'Resume',
        condition: this.selectedTileCode === this.ncaTiles.OnHold || this.selectedTileCode  === this.ncaTiles.NcaDiscontinued
      }
    ];

  }

  gridUpdatedEvent(event: { sortParamaterCode: number, isAscending: boolean, dashboardfilters: any[] }) {
    const shouldRefreshGrid = 
      this.sortParamaterCode !== event.sortParamaterCode ||
      this.isAscending !== event.isAscending ||
      JSON.stringify(this.ncaDashboardfilters) !== JSON.stringify(event.dashboardfilters);

    this.sortParamaterCode = event.sortParamaterCode;
    this.isAscending = event.isAscending;
    this.ncaDashboardfilters = event.dashboardfilters;

    if(this.ncaDashboardfilters.filter(x=>x.filtertypecode == 101)){ //do not offset if the filteration is based on jupiterid
      this.gotoPage = 0 
    }
    if (shouldRefreshGrid) {
      this.refreshGrid();
    }
}
}
