<progress-indicator></progress-indicator>
<div class="watchlist-header-container">
  <div class="back-to-dashboard" (click)="backToHome()">
    <img class="margin-right-xs margin-left-s back-icon" src="../../assets/images/dds-icons/back-icon_white.svg" />
    <span>Back to watchlist dashboard</span>
  </div>
</div>
<div class="watchlist-container">
  <div class="row top-margin">
    <div class="col-9" *ngIf="isEdit==false">
      <p class="eclipse-main-tab-header">Add client to watchlist</p>
      <p class="eclipse-content-grey">Adding the client to the watchlist with "Do not serve" status will disable their ability to create new submissions.</p>
    </div>
    <div class="col-9" *ngIf="isEdit==true">
      <p class="eclipse-main-tab-header">Watchlist record details</p>
    </div>
    <div class="col-3 buttons-flex-container">
      <div id="grid-action-buttons-container" class="flex-container">
        <div id="grid-action-button">
          <dds-button class="margin-right-xs" [theme]="addToWatchlistButtonOptions.theme ?? ''"
                                [kind]="addToWatchlistButtonOptions.kind ?? ''"
                                [size]="addToWatchlistButtonOptions.size ?? ''"
                                [width]="addToWatchlistButtonOptions.width ?? ''"
                                [disabled]="!isFormValid()"
                                (clicked)="upsertWatchlistData()">
            <!--Add to watchlist-->
            {{addToWatchlistButtonText}}
          </dds-button>
          <dds-button [theme]="cancelButtonOptions.theme ?? ''"
                                [kind]="cancelButtonOptions.kind ?? ''"
                                [size]="cancelButtonOptions.size ?? ''"
                                [width]="cancelButtonOptions.width ?? ''"
                                (clicked)="cancel()">
            Cancel
          </dds-button>
        </div>
        <div id="grid-action-button-dropdown" class="action-button-dropdown" *ngIf="isEdit">
          <dds-dropdown [theme]="dropdownOptions.theme ?? ''"
                                      [kind]="dropdownOptions.kind ?? ''"
                                      [size]="dropdownOptions.size ?? ''"
                                      [label]="dropdownOptions.label ?? ''"
                                      [width]="dropdownOptions.width ?? ''"
                                      [icon]="dropdownOptions.icon ?? ''"
                                      [disabled]="dropdownOptions.disabled ?? false"
                                      [stickerPosition]="dropdownStickerPosition"
                                      [stickerWidth]="dropdownOptions.stickerWidth ?? 0">
            <ng-container *ngFor="let item of dropdownItems">
              <dds-dropdown-item class="dds-context-menu_md"
                 [value]="item.value"
                 [counter]="item.counter ?? 0"
                 (click)="eraseClientWatchlist()"
                 [disabled]="item.disabled ?? false">{{item.heading}}</dds-dropdown-item>
            </ng-container>
          </dds-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="section-ending"></div>
  <div>
    <div id="new-client-acceptance-container">
      <p class="col eclipse-section-header">Client</p>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <span class="icon-required-yellow" *ngIf="!clientSearchWatchlistmodel.clientName"></span>
      <span class="margin-bottom-s table-header"> Client name </span>
      <div class="padding-top-xs">
        <div *ngIf="isEdit==true && clientSearchWatchlistmodel?.clientName">
          <span class="client-number padding-right-xxs">{{ clientSearchWatchlistmodel.clientName }}</span>
          <span class="sap-id padding-right-xxs">(ID: {{ clientSearchWatchlistmodel.clientNumber }})</span>
          <span [ddsTooltip]="'NCA never evaluated'" *ngIf="clientSearchWatchlistmodel.isNCANeverEvaluated == true"
                [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
                [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
                ddsFocus
                [tooltipMaxWidth]="clientTooltipOptions.tooltipMaxWidth ?? 0">
            <span class="padding-left-m client-icon"></span>
          </span>
        </div>
        <div ngbDropdown #dropdown="ngbDropdown" class="search-box" *ngIf="isEdit==false">
          <div class="search-container color-placeholder-text" ngbDropdownAnchor>
            <dds-search [formControl]="searchFormCtrl"
                        [size]="searchOptions.size ?? ''"
                        [customClass]="searchOptions.customClass ?? ''"
                        [icon]="!selectedItem ? (searchOptions.icon || 'dds-icon_search') : ''"
                        (valueChanged)="clientSearch()"
                        (fieldCleared)="clearSearch()">
              <input ddsSearch
                     #searchInput
                     [placeholder]="selectedItem ? '' : searchOptions.placeholder ?? ''"
                     [(ngModel)]="searchKey"
                     (input)="clientSearch()"
                     (click)="inputClick()"
                     [ngClass]="{'hidden': selectedItem}" [disabled]="!isEditEnabled" />
            </dds-search>

            <div *ngIf="selectedItem" class="selected-item-wrapper">
              <div class="selected-item-display">
                <span class="client-number padding-right-xxs">{{ selectedItem.clientName }}</span>
                <span class="sap-id">(ID: {{ selectedItem.clientNumber }})</span>
                <span *ngIf="selectedItem.watchlistStatus"
                      [ngClass]="{'serve-with-conditions-container': selectedItem.watchlistStatusId == enumWatchlistStatus.ServeWithConditions,
                      'do-not-serve-container': selectedItem.watchlistStatusId == enumWatchlistStatus.DoNotServe}">
                  <span *ngIf="selectedItem.watchlistStatusId !== enumWatchlistStatus.Removed">
                    {{selectedItem.watchlistStatus}}
                  </span>
                </span>
                <span [ddsTooltip]="'NCA never evaluated'"
                      [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
                      [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                      [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                      [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                      [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                      [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                      [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
                      ddsFocus
                      [tooltipMaxWidth]="clientTooltipOptions.tooltipMaxWidth ?? 0">
                  <span class="padding-left-s client-icon"></span>
                </span>
              </div>
              <div class="close-icon-container">
                <span class="dds-icon dds-icon_close" (click)="clearSearch()"></span>
              </div>
            </div>

          </div>
          <div #menu ngbDropdownMenu role="menu" aria-labelledby="single-button" class="items-dropdown">
            <div class="search-list-label padding-bottom-none" [hidden]="!isLoading">Searching...</div>
            <div [hidden]="isLoading">
              <div class="no-matches" [hidden]="filteredItems.length != 0">No matches found</div>
            </div>
            <button *ngFor="let item of filteredItems" ngbDropdownItem (click)="selectItem(item)">
              <div class="dropdown-item-content">
                <span class="client-number padding-right-xxs">{{ item.clientName }}</span>
                <span class="sap-id padding-right-xxs">(ID: {{ item.clientNumber }})</span>
                <span *ngIf="item.watchlistStatus padding-right-xxs"
                      [ngClass]="{'serve-with-conditions-container': item.watchlistStatusId == enumWatchlistStatus.ServeWithConditions,
                      'do-not-serve-container': item.watchlistStatusId == enumWatchlistStatus.DoNotServe}">
                  <span *ngIf="item.watchlistStatusId !== enumWatchlistStatus.Removed" class="text-word-wrap" [innerHtml]="item.watchlistStatus">
                  </span>
                </span>
              </div>
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="col-2 top-margin" *ngIf="clientSearchWatchlistmodel.watchlistStatus && isEdit==false">
      <div class="padding-top-xs">
        <dds-button [theme]="editButtonOptions.theme ?? ''"
            [kind]="editButtonOptions.kind ?? ''"
            [size]="editButtonOptions.size ?? ''"
            [width]="editButtonOptions.width ?? ''"
            (clicked)="editWatchlist(clientSearchWatchlistmodel.clientNumber !== undefined ? clientSearchWatchlistmodel.clientNumber : '', clientSearchWatchlistmodel.isParentClient)"> Edit watchlist record
    </dds-button>
      </div>
    </div>
    <div class="col-2" *ngIf="(clientSearchWatchlistmodel.clientName && !clientSearchWatchlistmodel.watchlistStatus) || isEdit==true">
      <span class="icon-required-yellow" *ngIf="!watchlistStatusId"></span>
      <span class="required margin-bottom-s table-header"> Status </span>
      <div class="padding-top-xs">
        <dds-select [list]="watchlistStatusSelectItems"
                    [placeholder]="clientStatusSelectOptions.placeholder ?? ''"
                    [size]="clientStatusSelectOptions.size ?? ''"
                    [(ngModel)]="watchlistStatusId"
                    [optionsTemplate]="optionsTemp"
                    [selectedItemTemplate]="selectedItemTemp"
                    (valueChanged)="changeClientWatchlistStatus()">
        </dds-select>

        <ng-template #selectedItemTemp
                     let-item>
          <span class="padding-right-xxs"
                [ngClass]="{'serve-with-conditions-container': item.value == enumWatchlistStatus.ServeWithConditions,
            'do-not-serve-container': item.value == enumWatchlistStatus.DoNotServe}">
            {{item.heading }}
          </span>
        </ng-template>

        <ng-template #optionsTemp
                     let-ctx="ctx">
          <dds-context-menu [size]="ctx.size"
                            [label]="ctx.label"
                            [id]="ctx.id"
                            class="dds-select__list">
            @for (item of ctx.list; track item) {
            <dds-context-menu-item [disabled]="item.disabled"
                                   [customClass]="item.customClass"
                                   (selectedItem)="ctx.changeItem(item)">
              <span class="padding-right-xxs"
                    [ngClass]="{'serve-with-conditions-container': item.value == enumWatchlistStatus.ServeWithConditions,
                                'do-not-serve-container': item.value == enumWatchlistStatus.DoNotServe}">
                {{item.heading }}
              </span>
            </dds-context-menu-item>
            }
          </dds-context-menu>
        </ng-template>
      </div>
    </div>
    <div [ngClass]="isEdit ? 'custom-col-5' : 'col-6'" *ngIf="(clientSearchWatchlistmodel.clientName && !clientSearchWatchlistmodel.watchlistStatus) || isEdit==true">
      <span class="icon-required-yellow" *ngIf="!clientWatchlistStatusComment"></span>
      <span class="text-label table-header"> Client risk comment </span>
      <div class="padding-top-xxs">
        <dds-textarea [placeholder]="textareaOptions.placeholder ?? ''"
                      [minHeight]="textareaOptions.minHeight ?? 0" [maxLength]="textareaOptions.maxLength ?? 0"
                      [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''" [size]="textareaOptions.size ?? ''"
                      (valueChanged)="onWatchlistStatusCommentChange()"
                      [(ngModel)]="clientWatchlistStatusComment"></dds-textarea>
      </div>
    </div>
    <div class="custom-col-1 center-align" *ngIf="isEdit">
      <span class="required margin-bottom-s table-header"> Action </span>
      <div class="padding-top-xxs">
        <span [ddsTooltip]="'Audit Trail/View history'" *ngIf="clientSearchWatchlistmodel.clientNumber"
              [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
              [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
              [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
              [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
              [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
              [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
              [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
              ddsFocus
              [tooltipMaxWidth]="clientTooltipOptions.tooltipMaxWidth ?? 0">
          <span class="pointer-cursor">
            <img class="icon-box" (click)="goToAuditTrail(clientSearchWatchlistmodel.clientName, clientSearchWatchlistmodel.clientNumber, clientSearchWatchlistmodel.watchlistId)" src="\assets\images\delete__l__stroke.png" />
          </span>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="isEdit && clientSearchWatchlistmodel.prevWatchlistStatus">
    <div class="row margin-top-xs" *ngIf="showPreviousWatchlistStatus">
      <div class="col-4"></div>
      <div class="col-2 margin-top-s">
        <span [ngClass]="{'serve-with-conditions-container': clientSearchWatchlistmodel.prevWatchlistStatusId == enumWatchlistStatus.ServeWithConditions,
          'do-not-serve-container': clientSearchWatchlistmodel.prevWatchlistStatusId == enumWatchlistStatus.DoNotServe}">{{clientSearchWatchlistmodel.prevWatchlistStatus}}</span>
      </div>
      <div class="custom-col-5">
        <div class="gray-box">
          <span class="text-word-wrap" [innerHtml]="clientSearchWatchlistmodel.prevStatusComment">
          </span>
        </div>
      </div>
    </div>
    <div class="row top-margin">
      <div class="col-4"></div>
      <div class="col-2">
        <button aria-disabled="false" aria-label="Button"
                class="dds-btn dds-btn_silent margin-left-minus-xs" (click)="onShowPreviousWatchlistStatus()">
          <div class="dds-btn__content">
            <span class="dds-btn__text">{{ showPreviousWatchlistStatus ? 'Hide previous status' : 'Show previous status' }}</span>
            <span class="dds-btn__icon dds-icon" [ngClass]="showPreviousWatchlistStatus ? 'dds-icon_arrow-up' : 'dds-icon_arrow-down'"></span>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="clientSearchWatchlistmodel.clientWatchlistCreatedDate && clientSearchWatchlistmodel.watchlistStatusId != enumWatchlistStatus.Removed && isEdit == false">
    <div class="col-7">
      <div class="container margin-top-s margin-bottom-s">
        <div class="top-header">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="text-container">
            <div class="initial-text">
              <span>Added to the watchlist</span>
            </div>
            <div class="vertical-line" *ngIf="isshowmorevisible"></div>
            <dds-button *ngIf="!isshowmorevisible" [theme]="options.theme ?? ''"
                        [kind]="optionsShowMore.kind ?? ''"
                        [size]="optionsShowMore.size ?? ''"
                        [width]="optionsShowMore.width ?? ''"
                        [icon]="optionsShowMore.icon ??''"
                        [isIconLeft]="optionsShowMore.isIconLeft ?? false"
                        [role]="optionsShowMore.role ?? ''"
                        [ariaLabel]="optionsShowMore.ariaLabel ?? ''"
                        [customClass]="optionsShowMore.customClass ?? ''"
                        (clicked)="clickclientshowmore($event)">Show more</dds-button>
          </div>
        </div>
        <div class="moreText margin-top-xs" *ngIf="isshowmorevisible">
          <div class="not-required">
            <span>Added by {{clientSearchWatchlistmodel.clientWatchlistCreatedBy}} on {{clientSearchWatchlistmodel.clientWatchlistCreatedDate}}</span>
          </div>
          <div class="reason-header margin-top-xs">Client risk comment:</div>
          <div class="margin-top-xxs">
            <span class="text-word-wrap" [innerHtml]="clientSearchWatchlistmodel.statusComment"></span>
          </div>
          <div class="margin-top-s">
            <dds-button [theme]="options.theme ?? ''"
                        [kind]="options.kind ?? ''"
                        [size]="options.size ?? ''"
                        [width]="options.width ?? ''"
                        [icon]="options.icon ??''"
                        [isIconLeft]="options.isIconLeft ?? false"
                        [role]="options.role ?? ''"
                        [ariaLabel]="options.ariaLabel ?? ''"
                        [customClass]="options.customClass ?? ''"
                        (clicked)="clickclient($event)">Show less</dds-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="clientSearchWatchlistmodel.clientWatchlistCreatedDate && clientSearchWatchlistmodel.watchlistStatusId == enumWatchlistStatus.Removed && isEdit == false">
    <div class="col-7">
      <div class="container margin-top-s margin-bottom-s">
        <div class="top-header">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="text-container">
            <div class="initial-text">
              <span>Prior watchlist client</span>
            </div>
            <div class="vertical-line" *ngIf="isshowmorevisiblePriorWatchlist"></div>
            <dds-button *ngIf="!isshowmorevisiblePriorWatchlist" [theme]="optionsShowMore.theme ?? ''"
                        [kind]="optionsShowMore.kind ?? ''"
                        [size]="optionsShowMore.size ?? ''"
                        [width]="optionsShowMore.width ?? ''"
                        [icon]="optionsShowMore.icon ??'dds-icon_arrow-down'"
                        [isIconLeft]="optionsShowMore.isIconLeft ?? false"
                        [role]="optionsShowMore.role ?? ''"
                        [ariaLabel]="optionsShowMore.ariaLabel ?? ''"
                        [customClass]="optionsShowMore.customClass ?? ''"
                        (clicked)="clickpriorWatchlistshowmore($event)">Show more</dds-button>
          </div>
        </div>
        <div class="moreText margin-top-xs" *ngIf="isshowmorevisiblePriorWatchlist">
          <div class="not-required">
            <span>Removed by {{clientSearchWatchlistmodel.clientWatchlistCreatedBy}} on {{clientSearchWatchlistmodel.clientWatchlistCreatedDate}}</span>
          </div>
          <div class="reason-header margin-top-xs">Client risk comment:</div>
          <div class="margin-top-xxs">
            <span class="text-word-wrap" [innerHtml]="clientSearchWatchlistmodel.statusComment"></span>
          </div>
          <div class="not-required margin-top-s">
            <span>Added/Updated to the watchlist with {{clientSearchWatchlistmodel.prevWatchlistStatus}} {{clientSearchWatchlistmodel.prevStatusModifiedDetail}}</span>
          </div>
          <div class="reason-header margin-top-xs">Client risk comment:</div>
          <div class="margin-top-xxs">
            <span class="text-word-wrap" [innerHtml]="clientSearchWatchlistmodel.prevStatusComment"></span>
          </div>
          <div class="margin-top-s">
            <dds-button [theme]="options.theme ?? ''"
                        [kind]="options.kind ?? ''"
                        [size]="options.size ?? ''"
                        [width]="options.width ?? ''"
                        [icon]="options.icon ??''"
                        [isIconLeft]="options.isIconLeft ?? false"
                        [role]="options.role ?? ''"
                        [ariaLabel]="options.ariaLabel ?? ''"
                        [customClass]="options.customClass ?? ''"
                        (clicked)="clickpriorWatchlist($event)">Show less</dds-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-7">
      <div class="container margin-top-s margin-bottom-s" *ngIf="isSubsidiaryBlockVisible">
        <div class="top-header">
          <div class="icon-alert-logo-container">
            <div class="icon-green-pin"></div>
          </div>
          <div class="text-container">
            <div class="initial-text1">
              <span>
                All subsidiaries will inherit parent client's status and risk comments. Update inheritance settings for individual subsidiaries as needed.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="container margin-top-s margin-bottom-s" *ngIf="!clientSearchWatchlistmodel.parentClientWatchlistCreatedDate && clientSearchWatchlistmodel.parentClientName">
        <div class="top-header">
          <div class="icon-alert-logo-container">
            <div class="icon-green-pin"></div>
          </div>
          <div class="text-container">
            <div class="initial-text">
              <span>This is a subsidiary of <a href="/client/{{clientSearchWatchlistmodel.parentClientNumber}}/status" aria-disabled="false" target="_blank" ddsFocus class="dds-link dds-link_silent">{{clientSearchWatchlistmodel.parentClientName}}</a></span>
              <span class="sap-id">(ID: {{clientSearchWatchlistmodel.parentClientNumber}})</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container margin-top-s margin-bottom-s" *ngIf="clientSearchWatchlistmodel.parentClientWatchlistCreatedDate">
        <div class="top-header">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="text-container">
            <div class="initial-text">
              <span>This is a subsidiary of <a href="/client/{{clientSearchWatchlistmodel.parentClientNumber}}/status" aria-disabled="false" target="_blank" ddsFocus class="dds-link dds-link_silent">{{clientSearchWatchlistmodel.parentClientName}}</a></span>
              <span class="sap-id">(ID: {{clientSearchWatchlistmodel.parentClientNumber}})</span>
            </div>
            <div class="vertical-line" *ngIf="isshowmoreparentvisible"></div>
            <dds-button *ngIf="!isshowmoreparentvisible" [theme]="optionsShowMore.theme ?? ''"
                        [kind]="optionsShowMore.kind ?? ''"
                        [size]="optionsShowMore.size ?? ''"
                        [width]="optionsShowMore.width ?? ''"
                        [icon]="optionsShowMore.icon ??''"
                        [isIconLeft]="optionsShowMore.isIconLeft ?? false"
                        [role]="optionsShowMore.role ?? ''"
                        [ariaLabel]="optionsShowMore.ariaLabel ?? ''"
                        [customClass]="optionsShowMore.customClass ?? ''"
                        (clicked)="clickparentclientshowmore($event)">Show more</dds-button>
          </div>
        </div>
        <div class="moreText margin-top-xs" *ngIf="isshowmoreparentvisible">
          <div class="not-required">
            <span>{{clientSearchWatchlistmodel.parentClientName}} was added to the watchlist with "{{clientSearchWatchlistmodel.parentClientWatchlistStatus}}" by {{clientSearchWatchlistmodel.parentClientWatchlistCreatedBy}} on {{clientSearchWatchlistmodel.parentClientWatchlistCreatedDate}}</span>
          </div>
          <div class="reason-header margin-top-xs">Client risk comment:</div>
          <div class="margin-top-xxs">
            <span class="text-word-wrap" [innerHtml]="clientSearchWatchlistmodel.parentClientStatusComment"></span>
          </div>
          <div class="margin-top-s">
            <dds-button [theme]="options.theme ?? ''"
                        [kind]="options.kind ?? ''"
                        [size]="options.size ?? ''"
                        [width]="options.width ?? ''"
                        [icon]="options.icon ??''"
                        [isIconLeft]="options.isIconLeft ?? false"
                        [role]="options.role ?? ''"
                        [ariaLabel]="options.ariaLabel ?? ''"
                        [customClass]="options.customClass ?? ''"
                        (clicked)="clickparentclient($event)">Show less</dds-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="subsidiary-client" *ngIf="isSubsidiaryBlockVisible">
    <div class="section-ending"></div>
    <div class="row">
      <div class="col-10">
        <span class="eclipse-section-header">Subsidiaries</span>
        <span class="eclipse-section-header"> ({{selectedsubsidiarycount}} of {{subsidiaryClientList.length}} added)</span>
      </div>

      <div class="col-2 d-flex justify-content-end">
        <span class="expand-collapse-icon" (click)="sectionExpand()">
          <div [ngClass]="isSubsidiarySectionCollapsed ? 'icon-expand-black' : 'icon-collapse-black'" class="icon-width"></div>
        </span>
      </div>
    </div>

    <div class="row">

      <div class="margin-top-s margin-bottom-s d-flex align-items-center" *ngIf="clientSearchWatchlistmodel.isParentClient == true">
        <div class="icon-green-pin"></div>
        <div class="initial-text1 margin-left-xxs">
          {{ alreadyAddedSubsidiaryCount > 0 ? 'Some subsidiaries are already on the watchlist. Expand the section to manage all the subsidiaries.' : 'Expand the section to manage all the subsidiaries' }}
        </div>
      </div>
    </div>
    <div class="row" *ngIf="subsidiaryClientList.length > 0 && !isSubsidiarySectionCollapsed">
      <div class="col-12 table-header subsidiary-table-header">
        <div class="table-data" *ngIf="subsidiaryClientList.length > 0">
          <table class="dds-data-table dds-data-table_unbordered">

            <thead class="dds-data-table__header">
              <tr>
                <th *ngFor="let column of gridColumns"
                    class="dds-data-table__header-cell"
                    [ngClass]="{'boolean-column': column.dataType === 'boolean'}"
                    [ngStyle]="{ width: column.width, minWidth: column.minWidth }">

                  <div class="dds-data-table__content">
                    <div *ngIf="column.name != 'Added'">
                      <span>{{column.header || column.name}}</span>
                    </div>

                    <div class="align-items-center" *ngIf="column.name === 'Added'">
                      <div class="margin-bottom-xxs">{{column.header || column.name}}</div>
                      <dds-checkbox [label]="checkboxoptions.label ?? ''"
                                    [theme]="checkboxoptions.theme ?? ''"
                                    [isIndeterminate]="isAddedCheckboxPartiallyChecked"
                                    [value]="isAllChecked"
                                    (stateChanged)="masterstateChanged()"
                                    [ariaLabel]="checkboxoptions.ariaLabel ?? ''">
                      </dds-checkbox>
                    </div>
                    </div>
                </th>
              </tr>
            </thead>

            <tbody class="dds-data-table__body">
              <ng-container *ngFor="let client of subsidiaryClientList; let i = index">
                <tr class="dds-data-table__row">
                  <td *ngFor="let column of gridColumns" class="dds-data-table__cell"
                      [ngClass]="{'center-align': column.dataType === 'boolean'}"
                      [ngStyle]="{width: column.width, minWidth: column.minWidth }">

                    <div *ngIf="column.name === 'Added'">
                      <dds-checkbox [value]="client.isChecked ?? true"
                                    [label]="checkboxoptions.label ?? ''"
                                    [theme]="checkboxoptions.theme ?? ''"
                                    [disabled]="client.watchlistId > 0"
                                    [ariaLabel]="checkboxoptions.ariaLabel ?? ''"
                                    (click)="toggleSelection(client)">
                      </dds-checkbox>
                    </div>

                    <div *ngIf="column.name === 'Inherited'">
                      <dds-toggle [value]="client.isInheritedFromParent ?? true"
                                  [label]="toggleoptions.label ?? ''"
                                  [ariaLabel]="toggleoptions.ariaLabel ?? ''"
                                  [theme]="toggleoptions.theme ?? ''"
                                  (stateChanged)="stateChanged(client)"
                                  #toggle></dds-toggle>
                    </div>

                    <div *ngIf="column.name === 'Clientname'">
                      <span class="client-number padding-right-xxs">{{ client.clientName }}</span>
                      <span class="sap-id padding-right-xxs">(ID: {{ client.clientNumber }})</span>
                      <span [ddsTooltip]="'NCA never evaluated'" *ngIf="client.isNCANeverEvaluated == true"
                            [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
                            [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                            [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                            [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                            [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                            [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                            [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
                            ddsFocus
                            [tooltipMaxWidth]="clientTooltipOptions.tooltipMaxWidth ?? 0">
                        <span class="padding-left-m client-icon"></span>
                      </span>
                    </div>

                    <div *ngIf="column.name === 'Status'">
                      <div class="row">
                        <div class="icon-textarea-container margin-top-xxs" *ngIf="(!client.isInheritedFromParent && client.isChecked)">
                          <span class="icon-required-yellow status-comment" *ngIf="!client.watchlistStatusId"></span>

                          <dds-select [list]="client.watchlistStatuses"
                                      [placeholder]="clientStatusSelectOptions.placeholder ?? ''"
                                      [size]="clientStatusSelectOptions.size ?? ''"
                                      [(ngModel)]="client.watchlistStatusId"
                                      [optionsTemplate]="optionsTemp"
                                      [selectedItemTemplate]="selectedItemTemp"
                                      (valueChanged)="onSubsidiaryStatusChange(client)">
                          </dds-select>

                          <ng-template #selectedItemTemp let-item>
                            <span class="padding-right-xxs"
                                  [ngClass]="{'serve-with-conditions-container': item.value == enumWatchlistStatus.ServeWithConditions,
                            'do-not-serve-container': item.value == enumWatchlistStatus.DoNotServe}">
                              {{ item.heading }}
                            </span>
                          </ng-template>

                          <ng-template #optionsTemp let-ctx="ctx">
                            <dds-context-menu [size]="ctx.size"
                                              [label]="ctx.label"
                                              [id]="ctx.id"
                                              class="dds-select__list">
                              @for (item of ctx.list; track item) {
                              <dds-context-menu-item [disabled]="item.disabled"
                                                     [customClass]="item.customClass"
                                                     (selectedItem)="ctx.changeItem(item)">
                                <span class="padding-right-xxs"
                                      [ngClass]="{'serve-with-conditions-container': item.value == enumWatchlistStatus.ServeWithConditions,
                                'do-not-serve-container': item.value == enumWatchlistStatus.DoNotServe}">
                                  {{ item.heading }}
                                </span>
                              </dds-context-menu-item>
                              }
                            </dds-context-menu>
                          </ng-template>
                        </div>

                      </div>

                    </div>

                    <div *ngIf="column.name === 'Statuscomment'">
                      <div class="icon-textarea-container" *ngIf="(!client.isInheritedFromParent && client.isChecked)">
                        <span class="icon-required-yellow status-comment" *ngIf="!client.statusComment"></span>
                        <dds-textarea [(ngModel)]="client.statusComment"
                                      [placeholder]="textareaOptions.placeholder ?? ''"
                                      [minHeight]="textareaOptions.minHeight ?? 0"
                                      [maxLength]="textareaOptions.maxLength ?? 0"
                                      [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
                                      [size]="textareaOptions.size ?? ''"
                                      class="flex-grow">
                        </dds-textarea>
                      </div>

                    </div>

                    <div *ngIf="column.name === 'Actions'">
                      <div class="col-1">
                        <span [ddsTooltip]="'Audit Trail/View history'" *ngIf="client.watchlistId"
                              [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
                              [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                              [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                              [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                              [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                              [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                              [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
                              ddsFocus
                              [tooltipMaxWidth]="clientTooltipOptions.tooltipMaxWidth ?? 0">
                          <span class="pointer-cursor">
                            <img class="icon-box" (click)="goToAuditTrail(client.clientName, client.clientNumber, client.watchlistId)" src="\assets\images\delete__l__stroke.png" />
                          </span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr class="dds-data-table__row" *ngIf="client.prevWatchlistStatus">
                  <td *ngFor="let column of gridColumns" class="dds-data-table__cell"
                      [ngStyle]="{width: column.width, minWidth: column.minWidth }">

                    <div *ngIf="column.name === 'Status'">
                      <div *ngIf="client.prevWatchlistStatus && client.isPreviousStatusShow" class="margin-bottom-xl">
                        <span [ngClass]="{'serve-with-conditions-container': client.prevWatchlistStatusId == enumWatchlistStatus.ServeWithConditions,
'do-not-serve-container': client.prevWatchlistStatusId == enumWatchlistStatus.DoNotServe}">{{client.prevWatchlistStatus}}</span>
                      </div>

                      <div *ngIf="client.prevWatchlistStatus && !client.isPreviousStatusShow && (!client.isInheritedFromParent && client.isChecked)">
                        <button aria-disabled="false" aria-label="Button"
                                class="dds-btn dds-btn_silent margin-left-minus-xs" (click)="onShowPreviousWatchlistSubsidiaryStatus(client)">
                          <div class="dds-btn__content center-align">
                            <span class="dds-btn__text" *ngIf="!client.isPreviousStatusShow">Show previous status</span>
                            <span class="dds-btn__icon dds-icon" [ngClass]="client.isPreviousStatusShow ? 'dds-icon_arrow-up' : 'dds-icon_arrow-down'"></span>
                          </div>
                        </button>
                      </div>
                    </div>

                    <div *ngIf="column.name === 'Statuscomment'">
                      <div *ngIf="client.isPreviousStatusShow">
                        <div class="gray-box">
                          <span class="text-word-wrap" [innerHtml]="client.prevStatusComment">
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="dds-data-table__row no-hover" *ngIf="client.prevWatchlistStatus && client.isPreviousStatusShow && (!client.isInheritedFromParent && client.isChecked)">
                  <td *ngFor="let column of gridColumns" class="dds-data-table__cell"
                      [ngStyle]="{width: column.width, minWidth: column.minWidth }">
                    <div *ngIf="column.name === 'Status'">
                      <div>
                        <button aria-disabled="false" aria-label="Button"
                                class="dds-btn dds-btn_silent margin-left-minus-xs" (click)="onShowPreviousWatchlistSubsidiaryStatus(client)">
                          <div class="dds-btn__content center-align">
                            <span class="dds-btn__text">Hide previous status</span>
                            <span class="dds-btn__icon dds-icon" [ngClass]="client.isPreviousStatusShow ? 'dds-icon_arrow-up' : 'dds-icon_arrow-down'"></span>
                          </div>
                        </button>
                      </div>
                    </div>

                  </td>
                </tr>

                <tr>
                  <td colspan="100%">
                    <div class="row-ending"></div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
