import { Component,ChangeDetectorRef, OnInit } from '@angular/core';
import { AuditRequestParams } from '../../../../common/models/common-models';
import { Subject, takeUntil } from 'rxjs';
import { BrowserTabConstant } from '../../../../common/constants/browser-tab-constant';
import { AuditTrailService } from '../../../../http/audit-trail/audit-trail.service';
import { AuditRequestParameter } from '../../../../common/models/audit-params';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-common-audit-trail-header',
  templateUrl: './common-audit-trail-header.component.html',
  styleUrl: './common-audit-trail-header.component.less'
})
export class CommonAuditTrailHeaderComponent implements OnInit {
  moduleId: number | null = null;
  moduleName!: string;
  private readonly destroy$: Subject<void> = new Subject<void>();
  clientNameValue: string | null = null;

  constructor(private auditService: AuditTrailService, private cdr: ChangeDetectorRef, private readonly route: ActivatedRoute) {}
  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_AuditTrail;
    this.moduleId = Number(this.route.snapshot.paramMap.get('id'));
    this.moduleName =  localStorage.getItem('moduleName')?.toString() || '';
  }

  headerData(data: any): void {
    this.clientNameValue = data.clientName;
    // Trigger change detection
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
