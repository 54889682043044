export class UrlConstant {
  static readonly #COMMON_INATKE_SUBMISSION_PATH = 'Intake/Submission/';
  static readonly #COMMON_INATKE_OPPORTUNITY_PATH = 'Intake/Opportunity/';
  static readonly #COMMON_INATKE_INTAKE_PATH = 'Intake/Intake/';

  public static readonly GET_INTAKE_CONFIGARATIONS_API: string =
    UrlConstant.#COMMON_INATKE_INTAKE_PATH + 'GetIntakeConfigurations';
  public static readonly GET_INTAKE_TOOLTIPS_API: string =
    UrlConstant.#COMMON_INATKE_INTAKE_PATH + 'GetIntakeToolTips';
  public static readonly GET_SELECTED_OPPERTUNITY_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetLatestSubmissionDetail?opportunityNumber=';
  public static readonly GET_SUBMISSION_TYPES_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'GetSubmissionType';
  public static readonly GET_SUBMISSION_SUBTYPES_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetSubmissionSubType?submissionTypeId=';
  public static readonly POST_SUBMISSION_REQUEST_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'InitiateSubmission';
  public static readonly GET_OPPORTUNITY_DETAILS_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'GetOpportunities?searchItem=';
  public static readonly GET_INTAKE_DETAILS_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetLeftMenuSubmissionDetails?opportunityNumber=';
  public static readonly GET_SUBMISSION_DETAILS_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetSubmissionDetail?opportunityNumber=';
  public static readonly GET_LINKED_OPPORTUNITIES_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetLinkedOpportunities?opportunityNumber=';
  public static readonly GET_DELIVERY_SERVICE_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetOpportunityServicesAndDelivery?opportunityNumber=';
  public static readonly POST_COMPLEXITY_DATA_API: string =
    UrlConstant.#COMMON_INATKE_OPPORTUNITY_PATH + 'SaveComplexityResponse';
  public static readonly GET_COMPLEXITY_DATA_API: string =
    UrlConstant.#COMMON_INATKE_OPPORTUNITY_PATH +
    'GetComplexityResponse?opportunityNumber=';
  public static readonly POST_OPPORTUNITY_DETAILS_REQUEST_API: string =
    UrlConstant.#COMMON_INATKE_OPPORTUNITY_PATH + 'EditOpportunity';
  public static readonly GET_OPPORTUNITY_DETAILS_INFO_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetOpportunityDetail?opportunityNumber=';
  public static readonly getDocumentStatusDropdownurl: string =
    'Intake/SubmissionDocument/GetDocumentStatus?submissionStatusId=';
  public static readonly getDocumentTypeDropdownurl: string =
    'Intake/SubmissionDocument/GetDocumentType?submissionTypeId=';
  public static readonly getDocumentTableData: string =
    'Intake/SubmissionDocument/GetSubmissionDocumentDetails?submissionId=';
  public static readonly postDocumentData: string =
    'Intake/SubmissionDocument/SaveSubmissionDocument';
  public static readonly PUT_DOCUMENT_EDIT_URL: string =
    'Intake/SubmissionDocument/EditSubmissionDocument';
  public static readonly Delete_DOCUMENT_URL: string =
    'Intake/SubmissionDocument/DeleteSubmissionDocument?submissionDocumentId=';
  public static readonly PUT_LEP_URL: string =
    'Intake/Submission/UpdateLEPStatus';
  public static readonly GET_OPPORTUNITY_SUMMARY_TEAM_DATA: string =
    'Intake/Opportunity/GetOpportunitySummaryTeam?opportunityNumber=';
  public static readonly UPDATE_RM_STATUS: string =
    'Intake/SubmissionDocument/UpdateRMStatus';

  public static readonly GET_FEE_TYPE_API: string =
    UrlConstant.#COMMON_INATKE_INTAKE_PATH + 'GetFeeTypes';
  public static readonly SAVE_INTAKE_DESC_COMMON_DETAILS: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'UpdateSubmission';
  public static readonly INTAKE_CLIENT_SUMMARY_API: string =
    'Client/GetClientInfo?clientNumber=';
  public static readonly GET_INTAKE_SUBMISSION_REVIEWERS: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'GetReviewers?submissionId=';
  public static readonly UPDATE_INTAKE_SUBMISSION_REVIEWERS: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'UpdateReviewers';
  public static readonly GET_CO_DRAFT_STATUS: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'ValidateSubmission';
  public static readonly PUT_SUBMIT_COMMON_SECTION_DATA: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'UpdateSubmissionStatus';
  public static readonly GET_TECHNICAL_REVIEWERS: string =
    UrlConstant.#COMMON_INATKE_INTAKE_PATH +
    'GetPPMDorSeniorManagerEmployeeDetails?searchItem=';
  public static readonly GET_REVIEWERS_ROLES_PATH =
    './../../../assets/json-constant/roles.json';
  public static readonly GET_WBS_CHARGECODES: string =
    './../../../assets/json-constant/wbs.json';
}
