
<div class="gray-box">
  <div class="row">
    <div class="col-3">
      <p class="eclipse-main-tab-header">NCA summary</p>
    </div>
    
    <div class="col-3" *ngIf="periodLookupList.length>0">
      <dds-select [list]="periodLookupList" [placeholder]="selectPeriodOptions.placeholder ?? ''"
                  [size]="selectPeriodOptions.size ?? ''" (valueChanged)="periodChangeEvent($event)"
                  [(ngModel)]="periodID">
      </dds-select>
    </div>
  </div>

<div class="row">
  <div class="col-6 right-border">
    <p class="eclipse-header left-align">By Specailist</p>
    <app-bar-chart [barChartTitle]="barChartTitle" [bars]="bars" [legendBar]="bars[0]"
                   (barChartClick)="barChartClick($event)" [isChartDataLoaded]="isChartDataLoaded">

    </app-bar-chart>
  </div>
  <div class="col-6">
    <p class="eclipse-header left-align">By Status</p>
    <div class="row">
      <div class="col-4">
        <div class="section"><span>Grand total</span></div>
        <div class="eclipse-main-tab-header"><span>2388</span></div>
      </div>
      <div class="col-4">
        <div class="section"><span>Total Active</span></div>
        <div class="eclipse-main-tab-header"><span>403</span></div>
      </div>
      <div class="col-4">
        <div class="section"><span>Total Completed</span></div>
        <div class="eclipse-main-tab-header"><span>1985</span></div>
      </div>
    </div>
    <div class="nca-summary-footer">
      <span class="completed margin-right-s">1057</span>
      <span>Completed - Accepted</span>
      <span class="discontinued margin-right-s margin-left-s">1</span>
      <span>Declined</span>
      <span class="discontinued margin-right-s margin-left-s">559</span>
      <span>Discontinued</span>
      <span class="converted-to-cc margin-right-s margin-left-s">1</span>
      <span>Converted to CC</span>
    </div><div class="nca-summary-footer">
      <span class="accepted-by-system margin-right-s">75</span>
      <span>De-facto accepted (by System)</span>
      <span class="accepted-by-nca margin-right-s margin-left-s">192</span>
      <span>De-facto accepted (by NCA team)</span>      
    </div><div class="nca-summary-footer">
      <span class="not-accepted margin-right-s">100</span>
      <span>NCA Not Required / Accepted</span>
    </div>
  </div>
</div>
  </div>
