import { Component, Input } from '@angular/core';
import { WatchlistStatus } from '../../http/watchlist/watchlist-api.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Client } from '../../common/models/client.model';

@Component({
  selector: 'app-watchlist-detail-modal-dialog',
  templateUrl: './watchlist-detail-modal-dialog.component.html',
  styleUrl: './watchlist-detail-modal-dialog.component.less'
})
export class WatchlistDetailModalDialogComponent {
  @Input() public data: any;
  @Input() public isPriorWatchlistStatusShow: boolean = false;
  public clientWatchlistDetail: Client = {} as Client;
  public isPriorBlockVisible: boolean = false;
  public enumWatchlistStatus = WatchlistStatus;
  public isParentClient: boolean = false;
  constructor(private modalService: ModalService) {
  }
  ngOnInit(): void {
    this.clientWatchlistDetail = this.data.watchlistDetail;
    this.isPriorBlockVisible = this.data.isPriorWatchlistStatusShow;
    this.isParentClient = this.data.isParentClient;
    if (this.isPriorBlockVisible == true && this.data.watchlistDetail.watchlistStatusId === this.enumWatchlistStatus.Removed) {
      this.isPriorBlockVisible = true;
    }
    else
      this.isPriorBlockVisible = false;
  }

  closeAll() {
    this.modalService.closeAll();
  }

  close() {
    this.modalService.close();
  }

  cancel() {
    this.modalService.close();
  }
}
