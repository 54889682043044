<div class="pt-2 team-container">
  <div class="d-flex flex-row space-between">
    <div>
      <div
        class="comments-text-label eclipse-small-header-grey text-align-left"
      >
        Lead Engagement Partner (LEP)
      </div>
      <div class="padding-top-xs">
        {{ lepName }}
      </div>
    </div>
    <div class="margin-left-l">
      <div
        class="comments-text-label eclipse-small-header-grey text-align-left"
      >
        Engagement Team
      </div>
      <div class="text-align-left padding-left-none">
        <ul
          class="ul-style-none text-align-left padding-left-none margin-bottom-none"
        >
          <li
            *ngFor="let member of visibleTeamMembers; index as index"
            class="padding-top-xs"
          >
            {{ member }}
          </li>
        </ul>
        <div *ngIf="teamMembers.length > 2" class="padding-top-xs">
          <div
            *ngIf="!showAll"
            class="show-more-btn font-size-14 cursor-clickable accepted text-underline"
          >
            See more
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="padding-top-s d-flex content-left margin-bottom-l">
    <dds-button
      [theme]="options.theme ?? ''"
      [kind]="options.kind ?? ''"
      [size]="options.size ?? ''"
      [width]="options.width ?? ''"
      [isIconLeft]="options.isIconLeft ?? false"
      [role]="options.role ?? ''"
      [isInverse]="options.isInverse ?? false"
      [isColorBg]="options.isColorBg ?? false"
      [isLoading]="options.isLoading ?? false"
      [ariaLabel]="options.ariaLabel ?? ''"
      [customClass]="options.customClass ?? ''"
      [disabled]="options.disabled ?? false"
      >Manage Team Members</dds-button
    >
  </div>
</div>
