<div class="nca-container">
  <div class="row">
    <div class="col-8">
      <!--TODO: If opportunityTitle is not present alignment of main section is incorrect. Need to add margin/padding-top-->
      <p
        class="eclipse-main-tab-header text-align-left text-white"
        *ngIf="
          isPermissionPresent(
            permissionEnums.Intake_Header_OpportunityTitle,
            permissionType.Visible
          )
        "
      >
        {{ opportunityDetailTitle }}
      </p>
    </div>
    <div class="col-4 text-align-right">
      <ng-container
        *ngIf="
          isPermissionPresent(
            permissionEnums.Intake_Header_GoToJupiter,
            permissionType.Visible
          )
        "
      >
        <span class="padding-right-s">Show in Jupiter</span>
        <span class="padding-right-s">
          <img src="\assets\images\dds-icon [16px].svg" />
        </span>
        <span class="padding-right-s">
          <img src="\assets\images\divider.png" />
        </span>
      </ng-container>
    </div>
  </div>
</div>
<div class="d-flex flex-row">
  <div class="left-panel-div right-border">
    <div class="d-flex margin-top-m flex-row-column">
      <div class="eclipse-section-header text-align-left">
        Opportunity
        <span
          class="float-right right-icon clickable"
          (click)="moveBackToEdit()"
          ><img src="/assets/images/Right icon.png"
        /></span>
        <span
          class="accepted font-size-12 font-bold-400 float-right cursor-clickable"
          (click)="moveBackToEdit()"
          >{{ viewAndEditLabel }}</span
        >
      </div>
      <div class="bottom-border text-align-left margin-right-s">
        <dds-tabs
          [theme]="tabContainerOptions.theme ?? ''"
          [themeType]="tabContainerOptions.themeType ?? ''"
          [size]="tabContainerOptions.size ?? ''"
          [isResponsive]="tabContainerOptions.isResponsive ?? false"
          [customClass]="tabContainerOptions.customClass ?? ''"
          [disabled]="tabContainerOptions.disabled ?? false"
          [isInverse]="tabContainerOptions.isInverse ?? false"
          [ariaLabel]="tabContainerOptions.ariaLabel ?? ''"
        >
          <dds-tab [label]="tabOptions1.label ?? ''">
            <div class="d-flex flex-row-column top-border">
              <!-- Service Model Section -->
              <div class="margin-top-m">
                <div class="eclipse-section-header text-align-left">
                  Junpiter Opportunity {{ opportunityNumber }} Services
                  (Proposal Submitted)
                </div>
                <div class="margin-top-s">
                  <app-service-delivery
                    [opportunityNumber]="
                      opportunityNumber ? opportunityNumber : ''
                    "
                    [summary]="true"
                    (eventForCyberQuestionInSummary)="makeGetCyberCall()"
                  ></app-service-delivery>
                </div>
              </div>
              <!-- Details section -->
              <div
                class="d-flex margin-top-l margin-bottom-l flex-row-column fee-type-height"
              >
                <div class="d-flex flex-row">
                  <div class="d-flex flex-row width-full">
                    <div class="width-partial">
                      <div class="d-flex text-align-left flex-row">
                        <img
                          class="icon-summary"
                          src="/assets/images/dollar-icon.svg"
                        />
                        <span class="eclipse-small-header-grey margin-left-s">{{
                          feeTypeLabel
                        }}</span>
                      </div>
                      <ul class="margin-left-s margin-top-s">
                        <li
                          class="eclipse-content text-align-left margin-top-fee-type"
                          *ngFor="let feeType of feeTypeList"
                        >
                          {{
                            feeType?.label
                              ? feeType?.label
                              : defaultForEmptyFields
                          }}
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex flex-row-column width-40">
                      <div class="d-flex flex-row-column">
                        <div class="d-flex text-align-left flex-row">
                          <img
                            class="icon-summary"
                            src="/assets/images/calendar-icon.svg"
                          />
                          <span
                            class="eclipse-small-header-grey margin-left-s"
                            >{{ durationLabel }}</span
                          >
                        </div>
                        <div
                          class="text-align-left margin-left-l eclipse-content"
                        >
                          {{ startDate }} - {{ endDate }}( {{ monthDuration }}m
                          )
                        </div>
                      </div>
                      <div class="d-flex flex-row-column margin-top-m">
                        <div class="d-flex text-align-left flex-row">
                          <img
                            class="icon-summary"
                            src="/assets/images/direction-icon.svg"
                          />
                          <span
                            class="eclipse-small-header-grey margin-left-s"
                            >{{ businessLabel }}</span
                          >
                        </div>
                        <!-- TODO: to be added after integration -->
                        <div
                          class="text-align-left margin-left-l eclipse-content"
                        >
                          {{ oportunityDetailsInfo.crossBusinessOpportunity }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row-column margin-top-l margin-bottom-s width-full"
                >
                  <div class="d-flex text-align-left flex-row">
                    <img
                      class="icon-summary"
                      src="/assets/images/description-icon.svg"
                    />
                    <span class="eclipse-small-header-grey margin-left-m">{{
                      descriptionLabel
                    }}</span>
                  </div>
                  <div class="text-align-left margin-left-l eclipse-content">
                    <span class="margin-left-xs">{{
                      opportunityDescription
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </dds-tab>
          <dds-tab [label]="tabOptions2.label ?? ''">
            <div
              class="d-flex flex-row padding-top-s margin-left-s top-border h-fit-content gap-2"
            >
              <div class="complexity-section-width d-flex flex-row-column">
                <div class="d-flex flex-row-column">
                  <div class="title-section">
                    <img src="/assets/images/list-options-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ opportunityComplexityLabel }}
                    </span>
                  </div>
                  <div class="content-section">
                    <div
                      class="d-flex flex-row-column margin-left-m"
                      *ngFor="
                        let complexity of opportunityComplexityOptionsOriginal
                      "
                    >
                      <div
                        class="eclipse-content margin-left-s margin-top-s d-flex flex-row"
                      >
                        <div
                          *ngIf="
                            complexity.label &&
                            selectedOpportunityComplexity.includes(
                              complexity.label
                            )
                          "
                          class="margin-right-s"
                        >
                          <img src="/assets/images/tick-icon.svg" />
                        </div>
                        <div
                          *ngIf="
                            complexity.label &&
                            !selectedOpportunityComplexity.includes(
                              complexity.label
                            )
                          "
                          class="margin-right-xs empty-space"
                        ></div>
                        <span
                          class="ellipsis w-fit-content"
                          [ddsTooltip]="complexity.label ?? ''"
                          [tooltipInvokeType]="
                            groupToolTipOptions.tooltipInvokeType ?? ''
                          "
                          [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                          [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                          [tooltipPosition]="
                            groupToolTipOptions.tooltipPosition ?? ''
                          "
                          [tooltipIndent]="
                            groupToolTipOptions.tooltipIndent ?? 0
                          "
                          [tooltipHasBeak]="
                            groupToolTipOptions.tooltipHasBeak ?? false
                          "
                          [tooltipTheme]="
                            groupToolTipOptions.tooltipTheme ?? ''
                          "
                          ddsFocus
                          [tooltipMaxWidth]="
                            groupToolTipOptions.tooltipMaxWidth ?? 0
                          "
                        >
                          {{ complexity.label }}</span
                        >
                        <span
                          *ngIf="complexity.label === regulatoryText"
                          class="icon-s icon-info position-alignment margin-left-s clickable"
                          [ddsTooltip]="
                            'Identify regulatory considerations such as HIPAA, GDPR, ADA, Export Controls'
                          "
                          [tooltipInvokeType]="
                            groupToolTipOptions.tooltipInvokeType ?? ''
                          "
                          [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                          [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                          [tooltipPosition]="
                            groupToolTipOptions.tooltipPosition ?? ''
                          "
                          [tooltipIndent]="
                            groupToolTipOptions.tooltipIndent ?? 0
                          "
                          [tooltipHasBeak]="
                            groupToolTipOptions.tooltipHasBeak ?? false
                          "
                          [tooltipTheme]="
                            groupToolTipOptions.tooltipTheme ?? ''
                          "
                          ddsFocus
                          [tooltipMaxWidth]="
                            groupToolTipOptions.tooltipMaxWidth ?? 0
                          "
                        >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-top-m">
                  <div class="title-section">
                    <img src="/assets/images/briefcase-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ thirdPartyLabel }}
                    </span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs"
                  >
                    {{ thirdPartyInvolvement }}
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-top-m">
                  <div class="title-section">
                    <img src="/assets/images/desktop-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s">
                      {{ dataInDEloitteLabel }}</span
                    >
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs"
                  >
                    {{ dataInDeloittesOrDeloittesHostedEnvironment }}
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-top-m">
                  <div class="title-section d-flex flex-row">
                    <img src="/assets/images/rent-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ memberFirmInvolvementLabel }}
                    </span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs margin-bottom-s d-flex"
                  >
                    <span> {{ memberFirmInvolvement }}</span>
                    <span
                      *ngIf="memberFirmInvolvement === YES"
                      class="icon-s icon-info position-alignment margin-left-s clickable d-inline-block"
                      [ddsTooltip]="memberFirmTooltip"
                      [tooltipInvokeType]="
                        groupToolTipOptions.tooltipInvokeType ?? ''
                      "
                      [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                      [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                      [tooltipPosition]="
                        groupToolTipOptions.tooltipPosition ?? ''
                      "
                      [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
                      [tooltipHasBeak]="
                        groupToolTipOptions.tooltipHasBeak ?? false
                      "
                      [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
                      ddsFocus
                      [tooltipMaxWidth]="
                        groupToolTipOptions.tooltipMaxWidth ?? 0
                      "
                    ></span>
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-bottom-l">
                  <div class="title-section">
                    <img
                      src="\assets\images\dds-icons\serviceInvolvementLogo.svg"
                    />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ serviceInvolvementLabel }}
                    </span>
                  </div>
                  <div class="content-section" *ngIf="containCyberQuestions">
                    <div
                      class="d-flex flex-row-column margin-left-m"
                      *ngFor="let cyber of serviceInvolvementOptions"
                    >
                      <div
                        class="eclipse-content margin-left-s margin-top-s d-flex flex-row"
                      >
                        <div
                          *ngIf="
                            cyber.label &&
                            serviceInvolvement?.includes(cyber.label)
                          "
                          class="margin-right-xs"
                        >
                          <img src="/assets/images/tick-icon.svg" />
                        </div>
                        <div
                          *ngIf="
                            cyber.label &&
                            !serviceInvolvement?.includes(cyber.label)
                          "
                          class="margin-right-xs empty-space"
                        ></div>
                        <span
                          class="ellipsis w-fit-content"
                          [ddsTooltip]="cyber.label ?? ''"
                          ddsFocus
                        >
                          {{ cyber.label }}</span
                        >
                        <span
                          *ngIf="cyber.label === regulatoryText"
                          class="icon-s icon-info position-alignment margin-left-s clickable"
                          [ddsTooltip]="regulatoryConsiderationTooltip"
                          [tooltipInvokeType]="
                            groupToolTipOptions.tooltipInvokeType ?? ''
                          "
                          [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                          [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                          [tooltipPosition]="
                            groupToolTipOptions.tooltipPosition ?? ''
                          "
                          [tooltipIndent]="
                            groupToolTipOptions.tooltipIndent ?? 0
                          "
                          [tooltipHasBeak]="
                            groupToolTipOptions.tooltipHasBeak ?? false
                          "
                          [tooltipTheme]="
                            groupToolTipOptions.tooltipTheme ?? ''
                          "
                          ddsFocus
                          [tooltipMaxWidth]="
                            groupToolTipOptions.tooltipMaxWidth ?? 0
                          "
                        >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="complexity-section-width flex-row-column">
                <div class="d-flex flex-row-column">
                  <div class="title-section">
                    <img src="/assets/images/search-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s">{{
                      productAndToolsLabel
                    }}</span>
                  </div>
                  <div class="content-section">
                    <div
                      class="d-flex flex-row-column margin-left-m"
                      *ngFor="let complexity of productAndToolsOptionsOriginal"
                    >
                      <div
                        class="eclipse-content margin-left-s margin-top-s d-flex flex-row"
                      >
                        <div
                          *ngIf="
                            complexity.label &&
                            selectedProductAndTools.includes(complexity.label)
                          "
                          class="margin-right-s"
                        >
                          <img src="/assets/images/tick-icon.svg" />
                        </div>
                        <div
                          *ngIf="
                            complexity.label &&
                            !selectedProductAndTools.includes(complexity.label)
                          "
                          class="margin-right-xs empty-space"
                        ></div>
                        {{ complexity.label }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-top-m">
                  <div class="title-section">
                    <img src="/assets/images/diamond-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ sensitiveDataLabel }}
                    </span>
                  </div>
                  <div class="content-section">
                    <div
                      class="d-flex flex-row-column margin-left-m"
                      *ngFor="let complexity of sensitiveDataOptionsOriginal"
                    >
                      <div
                        class="eclipse-content margin-left-s margin-top-s d-flex flex-row"
                      >
                        <div
                          *ngIf="
                            complexity.label &&
                            selectedSensitiveData.includes(complexity.label)
                          "
                          class="margin-right-s"
                        >
                          <img src="/assets/images/tick-icon.svg" />
                        </div>
                        <div
                          *ngIf="
                            complexity.label &&
                            !selectedSensitiveData.includes(complexity.label)
                          "
                          class="margin-right-xs empty-space"
                        ></div>
                        {{ complexity.label }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row-column margin-top-m">
                  <div class="title-section">
                    <img src="/assets/images/badge-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s">{{
                      deloitteSowLabel
                    }}</span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs"
                  >
                    {{ deloitteStandardELSOWTemplateReferred }}
                  </div>
                </div>
                <div class="d-flex flex-row-column">
                  <div class="title-section">
                    <img src="/assets/images/contract-icon.svg" />
                    <span class="eclipse-small-header-grey margin-left-s">{{
                      useOfExistingLabel
                    }}</span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs d-flex align-items-center"
                  >
                    <span> {{ useOfExistingMSAsOrContractMakerGBTs }} </span>

                    <span
                      *ngIf="useOfExistingMSAsOrContractMakerGBTs === NO"
                      class="icon-s icon-info position-alignment margin-left-s clickable d-inline-block"
                      [ddsTooltip]="existingMSAsOrContractMakerGBTsTooltip"
                      [tooltipInvokeType]="
                        groupToolTipOptions.tooltipInvokeType ?? ''
                      "
                      [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                      [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                      [tooltipPosition]="
                        groupToolTipOptions.tooltipPosition ?? ''
                      "
                      [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
                      [tooltipHasBeak]="
                        groupToolTipOptions.tooltipHasBeak ?? false
                      "
                      [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
                      ddsFocus
                      [tooltipMaxWidth]="
                        groupToolTipOptions.tooltipMaxWidth ?? 0
                      "
                    ></span>
                  </div>
                </div>
                <div
                  *ngIf="containCyberQuestions"
                  class="d-flex flex-row-column"
                >
                  <div class="title-section">
                    <img
                      class="margin-right-minus-xxs"
                      src="\assets\images\dds-icons\IsrealInvolvementLogo.svg"
                    />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ deloitteIsraelLabel }}
                    </span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs margin-bottom-xs d-flex align-items-center"
                  >
                    <span> {{ deloitteIsrael }} </span>
                    <span
                      *ngIf="deloitteIsrael === 'None (Explain)'"
                      class="icon-s icon-info position-alignment margin-left-xs clickable d-inline-block"
                      [ddsTooltip]="deloitteIsraelExplain"
                    ></span>
                  </div>
                </div>
                <div
                  *ngIf="containCyberQuestions"
                  class="d-flex flex-row-column"
                >
                  <div class="title-section">
                    <img
                      src="\assets\images\dds-icons\potentialClientLogo.svg"
                    />
                    <span class="eclipse-small-header-grey margin-left-s"
                      >{{ servicesDisruptClientLabel }}
                    </span>
                  </div>
                  <div
                    class="content-section eclipse-content margin-left-l margin-top-xs margin-bottom-xs d-flex align-items-center"
                  >
                    <span> {{ servicesDisruptClient }} </span>
                    <span
                      *ngIf="servicesDisruptClient === YES"
                      class="icon-s icon-info position-alignment margin-left-s clickable d-inline-block"
                      [ddsTooltip]="
                        'Generated new ERS 230: \n' +
                        hasERS230 +
                        '\n\nApplication security consult:\n ' +
                        infraSecurityconsult
                      "
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </dds-tab>
        </dds-tabs>
      </div>
      <div class="d-flex bottom-border width-full">
        <!-- CLIENT DATA -->
        <div class="others-height width-half">
          <div class="eclipse-section-header text-align-left px-2 py-3">
            <span class="font-size-16">{{ clientLabel }}</span>
            <div class="" *ngIf="clientNumber">
              <app-intake-client
                [clientNumber]="clientNumber"
                (independenceDataEmitter)="receiveIndependenceData($event)"
              ></app-intake-client>
            </div>
          </div>
        </div>
        <!-- TEAM DATA -->
        <div
          class="d-flex left-border width-half flex-row-column others-height"
        >
          <div class="eclipse-section-header text-align-left p-3">
            <span class="font-size-16">{{ teamLabel }}</span>
          </div>

          <div class="d-flex text-align-left flex-row px-3">
            <app-team-opportunity-summary
              [dataOpportunityId]="opportunityNumber"
            />
          </div>
        </div>
      </div>
      <div class="d-flex flex-row width-full margin-top-l margin-bottom-l">
        <div class="eclipse-section-header text-align-left">
          {{ independenceLabel }}
          <div class="margin-top-s d-flex gap">
            <div class="gap-between">
              <p
                class="eclipse-small-header-grey padding-none margin-bottom-xs"
              >
                <span
                  class="green-color margin-right-xxs cursor-clickable"
                  (click)="independanceDescClicked()"
                >
                  <u>DESC</u></span
                >designation (Services)
              </p>
              <p class="eclipse-content padding-none margin-bottom-xs">
                {{ independanceService }}
              </p>
              <p
                class="eclipse-small-header-grey padding-none margin-bottom-xs"
              >
                Services Restriction Type
              </p>
              <p class="eclipse-content padding-none">
                {{ independanceServiceDescription }}
              </p>
            </div>
            <div class="gap-between">
              <p
                class="eclipse-small-header-grey padding-none margin-bottom-xs"
              >
                Oppty involves any of the following (US)
              </p>
              <p class="eclipse-content padding-none">
                {{ opptyInvolvedInUS }}
              </p>
              <div *ngIf="requiredConflictProcedure">
                <p
                  class="eclipse-small-header-grey padding-none margin-bottom-xs"
                >
                  Req'd Conflict Proc. Taken by LEP (US)
                </p>
                <p class="eclipse-content padding-none">
                  {{ requiredConflictProcedure }}
                </p>
              </div>
              <div *ngIf="requiredConflictProcedure">
                <p
                  class="eclipse-small-header-grey padding-none margin-bottom-xs"
                >
                  Req'd Conflict Procedures Taken (US)
                </p>
                <p class="eclipse-content padding-none">
                  {{ requiredConflictProcedure }}
                </p>
              </div>
              <p
                class="eclipse-small-header-grey padding-none margin-bottom-xs"
              >
                Results of Conflict Check Procs (US)
              </p>
              <p class="eclipse-content padding-none">
                {{ resultofConflictCheck }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row-column right-section">
    <div
      class="d-flex bottom-border document-submission-section padding-left-xs"
    >
      <div class="eclipse-section-header text-align-left margin-top-m">
        {{ documentSubmissionLabel }}
        <div class="scroll-container">
          <div *ngFor="let item of documentSubmissionList">
            <app-document-submission
              [item]="item"
              [opportunityNumber]="opportunityNumber"
              [archiveStatus]="archiveStatus"
            ></app-document-submission>
          </div>
          <dds-button
            class="margin-top-l2"
            [theme]="greenLinedButton.theme ?? ''"
            [kind]="greenLinedButton.kind ?? ''"
            [size]="greenLinedButton.size ?? ''"
            [width]="greenLinedButton.width ?? ''"
            [isIconLeft]="greenLinedButton.isIconLeft ?? false"
            [role]="greenLinedButton.role ?? ''"
            [isInverse]="greenLinedButton.isInverse ?? false"
            [isColorBg]="greenLinedButton.isColorBg ?? false"
            [isLoading]="greenLinedButton.isLoading ?? false"
            [ariaLabel]="'Initiate new review'"
            [customClass]="greenLinedButton.customClass ?? ''"
            [disabled]="greenLinedButton.disabled ?? false"
            (click)="navigateToInitiateSubmission()"
            >Initiate new review</dds-button
          >
        </div>
      </div>
    </div>
    <div class="archive-section padding-m">
      <div class="eclipse-section-header text-align-left padding-none">
        {{ archiveLabel }}
        <p
          class="eclipse-small-header-grey padding-none margin-bottom-xs margin-top-s"
        >
          Archive name
        </p>
        <p
          *ngIf="!archiveName"
          class="eclipse-content padding-none margin-bottom-xs"
        >
          Archive is not yet selected
        </p>
        <div
          *ngIf="archiveName"
          [style.color]="transformStatus(archiveStatus) | statusStyle : 'color'"
          class="padding-none margin-bottom-xs eclipse-content"
        >
          <span (click)="openArchiveUrl()">{{ archiveName }}</span>
          <span
            class="icon-s icon-info position-alignment margin-left-s clickable d-inline-block"
            [ddsTooltip]="wbsArchiveSwfidivetails"
          ></span>
        </div>
        <span
          *ngIf="previousConnectedArchiveCount"
          class="eclipse-small-header-grey margin-top-xxs margin-bottom-xxs"
          >{{ previousConnectedArchiveCount }}
          {{ previousConnectedArchiveLabel }}
        </span>
        <div class="d-flex flex-row margin-top-xs">
          <dds-button
            class="margin-right-s"
            [theme]="greenLinedButton.theme ?? ''"
            [kind]="greenLinedButton.kind ?? ''"
            [size]="greenLinedButton.size ?? ''"
            [width]="greenLinedButton.width ?? ''"
            [isIconLeft]="greenLinedButton.isIconLeft ?? false"
            [role]="greenLinedButton.role ?? ''"
            [isInverse]="greenLinedButton.isInverse ?? false"
            [isColorBg]="greenLinedButton.isColorBg ?? false"
            [isLoading]="greenLinedButton.isLoading ?? false"
            [ariaLabel]="'Archive'"
            [customClass]="greenLinedButton.customClass ?? ''"
            [disabled]="greenLinedButton.disabled ?? false"
            (clicked)="openArchivePopup()"
          >
            <span *ngIf="archiveName">Change archive</span>
            <span *ngIf="!archiveName">Select archive</span>
          </dds-button>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</div>

<ng-template #wbsArchiveSwfidivetails>
  <div class="w-fit-content h-fit-content">
    <div class="d-flex">
      <div class="padding-xs">Archive name:</div>
      <div class="padding-xs font-bold-600">{{ archiveName }}</div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Archive description:</div>
      <div class="padding-xs font-bold-600">{{ archiveDescription }}</div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project name:</div>
      <div class="padding-xs font-bold-600">{{ swiftProjectName }}</div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project start:</div>
      <div class="padding-xs font-bold-600">
        {{ swiftStartDate }}
      </div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project end:</div>
      <div class="padding-xs font-bold-600">{{ swiftEndDate }}</div>
    </div>
    <div>
      <div class="padding-xs">Swift project PPMD:</div>
      <div class="padding-xs font-bold-600">{{ swiftPPMD }}</div>
    </div>
  </div>
</ng-template>
