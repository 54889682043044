import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DocumentEditPopupComponent } from '../document-edit-popup/document-edit-popup.component';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import {
  Column,
  ToggleGroupOptions,
  ToggleOptions,
} from './document-table-settings';
import { ToggleComponent } from '@usitsdasdesign/dds-ng/toggle';
import { SubmissionService } from '../../../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { ProgressIndicatorService } from '../../../../../../common/services/progress-indicator.service';
import { IntakeDocumentTableModel } from '../../../../../../common/models/intake-document-table.model';
import { DocumentService } from '../../../../../../http/document/document.service';
import { IntakeConstant, VALID_DOCUMENT_TYPES } from '../../../../../constants/intake-constant';
import {
  IntakeDocumentSubmissionTypeCode,
} from '../../../../../constants/intake_submission_type_code.enum';
import { StatusEnum } from '../../../../../enum/status-enum';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrl: './document-table.component.less',
})
export class DocumentTableComponent implements OnChanges, OnDestroy {
  @Input() submissionId: number = 0;
  @Input() submissionTypeId: number = 0;
  @Input() submissionStatusId: number = 0;
  @Input() type: string = '';
  @Input() set progressiveStatus(status: boolean | null) {
    setTimeout(() => {
      if (status != null) {
        this.updateServerValue();
      }
    }, 150);
  }
  @Output() documentTableData: EventEmitter<IntakeDocumentTableModel[]> =
    new EventEmitter<IntakeDocumentTableModel[]>();
  

  constructor(
    private modalService: ModalService,
    private intakeDetailService: SubmissionService,
    private progressIndicator: ProgressIndicatorService,
    private documentService: DocumentService
  ) {}


  private updateStatus(): void {
    const validTypes =
      VALID_DOCUMENT_TYPES[this.type as IntakeDocumentSubmissionTypeCode];
    if ((this.type === IntakeConstant.CHANGE_ORDER_LABEL_CODE)) {
      const status = this.docMetaData.some(
        (item) =>
          validTypes.includes(item.documentType) &&
          item.documentStatus === IntakeConstant.FINAL_ARCHIVE_READY_VERSION
      );
      this.intakeDetailService.docISValid.next(status);
    } else {
      const status = this.docMetaData.some((item) =>
        validTypes.includes(item.documentType)
      );
      this.intakeDetailService.docISValid.next(status);
    }
  }

  unsubscriber$ = new Subject();
  groupOptions = ToggleGroupOptions;
  @ViewChild('toggle', { static: false })
  toggle!: ToggleComponent;
  options = ToggleOptions;
  toggleOpen: boolean = true;
  docToShow: IntakeDocumentTableModel[] = [];
  public docMetaData: IntakeDocumentTableModel[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submissionId'] && changes['submissionId'].currentValue) {
      this.getDocumentMetaData();
    }
  }

  stateChanged(): void {}

  updateServerValue(): void {
    if (this.toggleOpen) {
      this.docToShow = this.docMetaData.filter(
        (doc: any) =>
          ![
            'Draft',
            'RM reviewed',
            'Self reviewed',
            'Supporting documentation',
          ].includes(doc.documentStatus)
      );
    } else {
      this.docToShow = this.docMetaData;
    }
    this.toggleOpen = !this.toggleOpen;
    setTimeout(() => this.toggle?.toggleState(true), 100);
  }

  public sampleColumns = Column;

  onDownload(event: IntakeDocumentTableModel): void {
    let downloadRequest = {
      DocumentName: event.documentTitle,
      DocumentKey: event.attachmentGuid,
      FileFolderPath: 'Intake',
    };
    this.documentService.getDownloadUrl(downloadRequest).subscribe({
      next: (response: any) => {
        if (response != null && response.documentDownloadUrl != null) {
          window.open(response.documentDownloadUrl, '_blank');
        }
      },
      error: (err: any) => {},
    });
  }

  openLink(item: string): void {
    if (item) {
      window.open(item, '_blank');
    }
  }

  onEdit(item: IntakeDocumentTableModel, modalBtn?: any): void {
    let modalRef = this.modalService.open(DocumentEditPopupComponent, {
      isFooter: true,
      size: 'lg',
      isInverse: false,
      documentInitiatedDetails: {
        moduleName: 'Intake',
        submissionStatusId: this.submissionStatusId,
        submissionTypeId: this.submissionTypeId,
        comments: item.comments,
        submissionDocumentId: item.submissionDocumentId,
        documentTitle: item.documentTitle,
        submissionDocumentTypeId: item.submissionDocumentTypeId,
        submissionId: this.submissionId,
        type: this.type,
        attachmentId: item.attachmentId,
      },
    });
    modalRef.onClosed().subscribe((response: any) => {
      this.getDocumentMetaData();
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  onDelete(item: number): void {
    this.intakeDetailService
      .deleteDocument(item)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe();

    this.docMetaData = this.docMetaData.filter(
      (doc: { submissionDocumentId: number }) =>
        doc.submissionDocumentId !== item
    );
    this.docToShow = this.docToShow.filter(
      (doc: { submissionDocumentId: number }) =>
        doc.submissionDocumentId !== item
    );
    this.updateStatus();
    this.documentTableData.emit(this.docToShow);
  }

  getDocumentMetaData(): void {
    this.progressIndicator.show();
    this.intakeDetailService
      .getDocumentTableData(this.submissionId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.docMetaData = data;
          this.docToShow = data;
          this.updateStatus();
          this.documentTableData.emit(this.docToShow);
        },
      });
    this.progressIndicator.hide();
  }

  transformStatus(status: string): StatusEnum {
    return (Object.values(StatusEnum).find((enumValue) => enumValue === status) || StatusEnum.Draft);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
