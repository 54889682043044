<div class="item-info-container">   
  <table *ngIf="list && list.length > 0" class="dds-data-table">
    <thead class="dds-data-table__header margin-top-s">
     <tr>
      <th *ngFor="let column of gridColumns"
          [attr.aria-sort]="(sortingState.property === column.name && (column.name === 'opportunityExpectedCloseDate' || column.name === 'opportunityModifiedDate')) ? (sortingState.descending ? 'descending' : 'ascending') : 'none'"
          class="dds-data-table__header-cell"
          [ngClass]="{'dds-data-table__header-cell_sorting': column.name === 'opportunityExpectedCloseDate' || column.name === 'opportunityModifiedDate', 'dds-data-table__header-cell_sorted': sortingState.property === column.name && (column.name === 'opportunityExpectedCloseDate' || column.name === 'opportunityModifiedDate')}"
          [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
          ddsFocus
          tabindex="0">
        <div class="dds-data-table__content" [ngClass]="{'table_content_md': this.isNewClientAcceptanceComponent}">
          <span>{{column.header || column.name}}</span>
          <div *ngIf="column.name === 'opportunityExpectedCloseDate' || column.name === 'opportunityModifiedDate'" class="sort-icons-container">
            <span *ngIf="!this.isNewClientAcceptanceComponent"
              class="dds-icon dds-data-table__icon"
              aria-label="sort"
              [ngClass]="{
                'dds-icon_sort-up': sortingState.property !== column.name || (sortingState.property === column.name && !sortingState.descending),
                'dds-icon_sort-down': sortingState.property === column.name && sortingState.descending,
                'dds-icon_sort-active': sortingState.property === column.name,
                'dds-icon_sort-disabled': sortingState.property !== column.name
              }"
              (click)="sortColumn(column, $event)">
            </span>
          </div>
        </div>
      </th>
     </tr>
    </thead>
    <tbody class="dds-data-table__body">
      <ng-container *ngFor="let row of list">
        <tr [ngClass]="{'dds-data-table__row': true, 'milestone-trigger-class': row.isMilestoneTrigger === true}">     
        <td *ngFor="let column of gridColumns" class="dds-data-table__cell" [ngClass]="{'opportunity-name-cell': column.name === 'opportunityName'}" [ngStyle]="{ width: column.width, minWidth: column.minWidth }">
           <span *ngIf = "column.name == 'opportunityNumber'" 
                  [ddsTooltip]="row.opportunityEstimatedValueInfoIconText" 
                  [tooltipInvokeType]="options.tooltipInvokeType ?? ''"
                  [tooltipType]="options.tooltipType ?? ''"
                  [tooltipSize]="options.tooltipSize  ?? ''"
                  [tooltipPosition]="options.tooltipPosition ?? ''"
                  [tooltipIndent]="options.tooltipIndent ?? 0"
                  [tooltipHasBeak]="options.tooltipHasBeak ?? false"
                  [tooltipTheme]="options.tooltipTheme ?? ''" ddsFocus>{{ row[column.name] }}</span>
            <div *ngIf="column.name === 'pursuitLeaderOutlookDisplayName'" class="pursuitleader-container">
              <p class="pursuitleader-text-label">{{ row[column.name] }}</p>
              <span [ngbPopover]="popTextPursuitLeader"
                    id="startpage-ncaspecialistmercuryopportunitynumber-value"
                    container="body"
                    [disablePopover]="!row?.pursuitLeaderInfoIconText"
                    class="icon-s icon-info" 
                    triggers="mouseenter:mouseleave"
                    placement="auto">
              </span>
              <ng-template #popTextPursuitLeader>
                <div [innerHTML]="row.pursuitLeaderInfoIconText"></div>
              </ng-template>
            </div>
          <span *ngIf = "column.name != 'opportunityNumber' && column.name != 'pursuitLeaderOutlookDisplayName'">
            {{row[column.name]}}</span>
        </td>
      </tr>
      </ng-container>  
    </tbody>
  </table>  
  <div *ngIf="(!list || list.length == 0)">
    <div>
        <span>No related opportunities available in Jupiter</span>
    </div>
  </div>
</div>
