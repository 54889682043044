<div
  class="uploader-class"
  [ngClass]="{
    'hide-uploader-button': !isPermissionPresent(
      permissionEnums.Intake_Submission_AttachDocument,
      permissionType.Enable
    ),
    'hide-uploader': !isPermissionPresent(
      permissionEnums.Intake_Submission_DocumentDragAndDrop,
      permissionType.Enable
    )
  }"
  *ngIf="!isDisabledDocuments"
>
  <dds-uploader
    id="intake-uploader"
    *ngIf="
      isPermissionPresent(
        permissionEnums.Intake_Submission_DocumentDragAndDrop,
        permissionType.Enable
      )
    "
    [heading]="uploadAreaOptions.heading"
    [description]="uploadAreaOptions.description"
    [dragAndDropText]="uploadAreaOptions.dragAndDropText"
    [isDragAndDrop]="uploadAreaOptions.isDragAndDrop"
    [isMultiple]="uploadAreaOptions.isMultiple"
    [acceptFormats]="uploadAreaOptions.acceptFormats"
    [acceptExtensions]="uploadAreaOptions.acceptExtensions"
    [restrictedFormats]="uploadAreaOptions.restrictedFormats"
    [restrictedExtensions]="uploadAreaOptions.restrictedExtensions"
    [exceptSymbols]="uploadAreaOptions.exceptSymbols"
    [btnLabel]="uploadAreaOptions.btnLabel"
    [isInverse]="uploadAreaOptions.isInverse"
    [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind"
    (uploadedItems)="openUploadModel($event)"
  ></dds-uploader>

  <dds-button
    *ngIf="isAddLinkButtonVisible"
    class="button-style"
    [theme]="options.theme"
    [kind]="options.kind"
    [size]="options.size"
    [width]="options.width"
    (click)="openDocumentLinkModel()"
    >Add link</dds-button
  >
</div>

<app-document-table
  *ngIf="
    isPermissionPresent(
      permissionEnums.Intake_Submission_DocumentTable,
      permissionType.Enable
    )
  "
  [submissionId]="submissionId"
  [submissionTypeId]="submissionTypeId"
  [submissionStatusId]="submissionStatusId"
  [progressiveStatus]="progressiveStatus"
  (documentTableData)="documentTableDataValue($event)"
  [isEditable]="isDisabledDocuments"
  [type]="type"
  [submissionStatus]="submissionStatus"
></app-document-table>
