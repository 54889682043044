import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.less',
})
export class FooterComponent {
  currentYear = new Date().getFullYear();

  constructor() {}

  openLink() {
    const url =
      'https://resources.deloitte.com/sites/US/system/Pages/Personnel_Privacy_Notice.aspx';
    window.open(url, '_blank');
  }
}
