export enum IntakeOppertunitySubmissionDocumentStatus {
  DRAFT = 'Draft for review',
  RETURN_FOR_REWORK = 'Returned for revisions',
  SUBMITTED = 'Submitted',
  RESUBMITTED = 'Re-submitted',
  RM_REVIEWED_PENDING_CLOSEOUT = 'RM reviewed - Pending closeout',
  SELF_REVIEWED_PENDING_CLOSEOUT = 'Self reviewed - Pending closeout',
  COMPLETED = 'Completed',
  DISCONTINUED = 'Discontinued',
  COMPLETED_ADMIN = 'Completed (Admin)',
  FINAL_ARCHIVE_READY_VERSION = 'Final - Archive ready version',
}
