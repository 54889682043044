import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { Filter, FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import {
  ButtonKind,
  Column,
  ExtThemes,
  Size,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { AdminConstant } from '../../../constants/admin-constants';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { GridColumn } from '../../../../http/watchlist/watchlist-api.service';

export const sampleColumns: GridColumn[] = [
  { name: 'editIcon', header: AdminConstant.EMPTY_SPACE, dataType: 'string',     filterable: false,
    minWidth: '2.75rem' },
  {
    name: 'name',
    header: 'Responsible practitioner',
    dataType: 'string',
    filterable: false,
    minWidth: '13.0625rem',
  },
  {
    name: 'comments',
    header: 'Comments',
    dataType: 'string',
    filterable: false,
    minWidth: '35.75rem',
  },
  {
    name: 'isActive',
    header: 'Active',
    dataType: 'boolean',
    filterable: false,
    minWidth: '3.75rem',
  },
];

export const btnOptions: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  size: AdminConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: AdminConstant.EMPTY_SPACE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Add Record',
  customClass: AdminConstant.EMPTY_SPACE,
  role: 'button',
};

export const filterData: FilterItem[] = [
  {
    title: 'Responsible practioner',
    name: 'Responsible practioner',
  },
  {
    title: 'isActive',
    name: 'isActive',
  },
];

export const filter: Filter = {
  theme: Themes.green,
  propName: 'name',
  itemPropNameValue: 'value',
};

export const searchOptionsCtrl: SearchOptions = {
  placeholder: AdminConstant.EMPTY_SPACE,
  ariaLabel: 'searchInput',
  size: AdminConstant.UI_ELEMENT_SIZE,
  isInverse: false,
  customClass: AdminConstant.EMPTY_SPACE,
  isTrimValue: false,
  readonly: false,
  maxLength: 0,
};

export const options: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  size: AdminConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: AdminConstant.EMPTY_SPACE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'CancelButton',
  customClass: AdminConstant.EMPTY_SPACE,
  role: 'button',
};

export const groupToolTipOptions: TooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'top',
  tooltipIndent: 15,
  tooltipHasBeak: true,
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipTheme: Themes.dark,
  tooltipMaxWidth: 300
};