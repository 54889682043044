import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { SubmissionService } from '../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { LinkedOpportunity } from '../../../common/models/opportunity-details.model';
import { TableHeaderData } from '../opportunity-details-settings';
import { AccordionOptions } from './linked-opportunity-setting';
import { TooltipOptions } from '../oportunity-detail-right-panel/service-delivery/service-delivery-settings';

@Component({
  selector: 'app-linked-opportunities',
  templateUrl: './linked-opportunities.component.html',
  styleUrl: './linked-opportunities.component.less',
})
export class LinkedOpportunitiesComponent implements OnChanges, OnDestroy {
  @Input() dataOpportunityId: string = '';
  @Input() dataSubmissionId: number | null = null;
  accordionOptions = AccordionOptions;
  constructor(private linkedOpportunityDetailsService: SubmissionService) {}

  hasLinkedOpportunities: boolean = false;
  unsubscriber$ = new Subject();
  tableHeader: Column[] = TableHeaderData;
  options = TooltipOptions;

  linkedOpportunities: LinkedOpportunity[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataOpportunityId'] || changes['dataSubmissionId']) {
      this.fetchData();
    }
  }

  fetchData(): void {
    this.linkedOpportunityDetailsService
      .getLinkedOpportunityDetails(this.dataOpportunityId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: LinkedOpportunity[]) => {
          this.linkedOpportunities = data;
          data.length === 0
            ? (this.hasLinkedOpportunities = false)
            : (this.hasLinkedOpportunities = true);
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
