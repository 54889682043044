import { Component, OnInit, ViewChild } from '@angular/core';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ButtonKind, ExtThemes, Size, Themes, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { CheckboxOptions } from '@usitsdasdesign/dds-ng/checkbox';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { RadioButton } from '@usitsdasdesign/dds-ng/radio';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, filter, switchMap, takeUntil } from 'rxjs';
import { ClientSearchResult, InitiationApiService, MarsClientInfo } from '../../http/initiation/initiation-api.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientStatusWebapiService } from '../../http/client/client-status-webapi.service';
import { SelectItemOptions, SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { EmployeeView } from '../../common/models/common-models';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { InputOptions } from '@usitsdasdesign/dds-ng/input';
import { InitiationConstants } from '../constants/initiation-constants';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';

@Component({
  selector: 'app-initiation-mars-details',
  templateUrl: './initiation-mars-details.component.html',
  styleUrl: './initiation-mars-details.component.less'
})
export class InitiationMarsDetailsComponent implements OnInit {
  @ViewChild('clientdropdown', { static: false }) clientdropdown?: NgbDropdown;
  public clientSearchKey: string = '';
  private clientSearchKey$ = new Subject<string>();
  clientFilteredItems: ClientSearchResult[] = [];
  clientSelectedItem?: ClientSearchResult;
  clientSearchFormCtrl = new FormControl({ value: '', disabled: false });
  public clientSearchmodel: ClientSearchResult = {} as ClientSearchResult;

  @ViewChild('ppmddropdown', { static: false }) ppmddropdown?: NgbDropdown;
  public ppmdSearchKey: string = '';
  private ppmdSearchKey$ = new Subject<string>();
  ppmdFilteredItems: EmployeeView[] = [];
  ppmdSelectedItem?: EmployeeView;
  ppmdSearchFormCtrl = new FormControl({ value: '', disabled: false });
  public ppmdSearchmodel: ClientSearchResult = {} as ClientSearchResult;

  public isLoading: boolean = false;
  public model: MarsClientInfo = {} as MarsClientInfo;
  public isActiveMarsFormExists: boolean = false;
  public clientDictionary: any;
  
  public optionsMARSClientType: SelectItemOptions[] = [];
  public optionsMarsQrmEmployees: SelectItemOptions[] = [];
  public selectFormCtrl = new FormControl({ value: '', disabled: false });
  public unsubscriber$ = new Subject();

  public checkboxFormCtrl = new FormControl({ value: '', disabled: false });
  public isClientNameExists: boolean = false;
  public isClientNotListed: boolean = false;
  public otherClientName: string = '';
  private checkClientNameTimerId: any = null;
  public isCheckingNameInProcess: boolean = false;

  public consultationSummaryHoverText: string = InitiationConstants.CONSULTATION_SUMMARY_HOVER_TEXT;

  public marsConsultationWithMarsQrmSummarySecurityResourceName: string = 'ClientStatus.MarsConsultationWithMarsQrmSummary';
  public isMarsConsultationWithMarsQrmSummaryDisabledBySecurity: boolean = true;
  constructor(
    private progressIndicator: ProgressIndicatorService,
    private router: Router,
    private clientstatuswebapiservice: ClientStatusWebapiService,
    private initiationApiService: InitiationApiService,
    private securityWebapiService: SecurityWebapiService
  ) { }

  public ngOnInit() {
    this.progressIndicator.show();

    this.securityWebapiService.getPermissions('0000000000');
    this.securityWebapiService.data.subscribe((rules) => {
      if (!rules.empty) {
        this.isMarsConsultationWithMarsQrmSummaryDisabledBySecurity = (rules[this.marsConsultationWithMarsQrmSummarySecurityResourceName] && rules[this.marsConsultationWithMarsQrmSummarySecurityResourceName].Enable !== undefined ? rules[this.marsConsultationWithMarsQrmSummarySecurityResourceName].Enable : false);
      }
    });
    this.clientstatuswebapiservice.getDropdownCommonData();
    this.clientstatuswebapiservice.dropdowncommondata$.subscribe(response => {
      if (response) {
        this.clientDictionary = response;
        this.optionsMARSClientType = this.clientDictionary['optionsMARSClientType'].resultData || [];
      }
    });
    
    this.loadMarsQrms();

    this.progressIndicator.hide();
  }

  public cancelPage() {
    this.clearClientSearch();
    this.clearPPMDSearch();

    this.model = {} as MarsClientInfo; 

    this.router.navigate(['/']);
  }

  /*Client search section - start*/
  public clearClientSearch() {
    this.clientSearchKey = '';
    this.clientSearchKey$.next(this.clientSearchKey);
    this.clientFilteredItems = [];
    this.clientSelectedItem = undefined;

    this.model.clientNumber = this.model.clientName = '';
    this.isActiveMarsFormExists = false;

    if (this.clientdropdown && this.clientdropdown.isOpen()) {
      this.clientdropdown.close();
    }
  }

  public clientSearch() {
    if (this.clientSearchKey && this.clientSearchKey.length > 2) {
      this.clientSearchKey$.next(this.clientSearchKey.trim());
    }
    else {
      this.clientFilteredItems = [];
      if (this.clientdropdown && this.clientdropdown.isOpen()) {
        this.clientdropdown.close();
      }
      this.isLoading = true;
    }
  }

  selectItem(item: ClientSearchResult) {
    this.clientSearchKey = '';
    this.clientSearchFormCtrl.setValue(this.clientSearchKey, { emitEvent: false });
    this.clientFilteredItems = [];
    this.clientSelectedItem = item;
    this.clientSearchmodel = item;

    this.model.clientNumber = item.clientNumber;
    this.model.clientName = item.clientName;
    this.isActiveMarsFormExists = item.isActiveMarsFormExists;
  }

  public clientInputClick() {
    this.isLoading = true;
    this.clientSearchKey$.pipe(
      debounceTime(500),
      filter(key => !!key && key.length > 2),
      switchMap(key => this.searchItems(key))
    ).subscribe({
      next: (response: any) => {
        this.clientFilteredItems = response;
        this.isLoading = false;
      },
      error: (err) => {
        this.clientFilteredItems = [];
      }
    });

    this.clientSearchKey$.next(this.clientSearchKey);
    if (this.clientdropdown && !this.clientdropdown.isOpen() && this.clientSearchKey.length > 2) {
      this.clientdropdown.open();
    }
  }

  private searchItems(key: string) {
    if (this.clientdropdown && !this.clientdropdown.isOpen()) {
      this.clientdropdown.open();
    }

    return this.initiationApiService.searchClients(key);
  }
  /*Client search section - end*/



  /*PPMD search section - start*/
  public clearPPMDSearch() {
    this.ppmdSearchKey = '';
    this.ppmdSearchKey$.next(this.ppmdSearchKey);
    this.ppmdFilteredItems = [];
    this.ppmdSelectedItem = undefined;

    this.model.recommendingPpmd = '';
    this.model.recommendingPpmdDisplayName = '';

    if (this.ppmddropdown && this.ppmddropdown.isOpen()) {
      this.ppmddropdown.close();
    }
  }

  public PPMDSearch() {
    if (this.ppmdSearchKey && this.ppmdSearchKey.length > 2) {
      this.ppmdSearchKey$.next(this.ppmdSearchKey.trim());
    }
    else {
      this.ppmdFilteredItems = [];
      if (this.ppmddropdown && this.ppmddropdown.isOpen()) {
        this.ppmddropdown.close();
      }
      this.isLoading = true;
    }
  }

  selectItemPPMD(item: any) {
    this.ppmdSearchKey = '';
    this.ppmdSearchFormCtrl.setValue(this.ppmdSearchKey, { emitEvent: false });
    this.ppmdFilteredItems = [];
    this.ppmdSelectedItem = item;

    this.model.recommendingPpmd = item.empID;
    this.model.recommendingPpmdDisplayName = item.outlookDisplayName;
  }

  public PPMDInputClick() {
    this.isLoading = true;
    this.ppmdSearchKey$.pipe(
      debounceTime(500),
      filter(key => !!key && key.length > 2),
      switchMap(key => this.searchItemsPPMD(key))
    ).subscribe({
      next: (response: any) => {
        this.ppmdFilteredItems = response;
        this.isLoading = false;
      },
      error: (err) => {
        this.ppmdFilteredItems = [];
      }
    });

    this.ppmdSearchKey$.next(this.ppmdSearchKey);
    if (this.ppmddropdown && !this.ppmddropdown.isOpen() && this.ppmdSearchKey.length > 2) {
      this.ppmddropdown.open();
    }
  }

  private searchItemsPPMD(key: string) {
    if (this.ppmddropdown && !this.ppmddropdown.isOpen()) {
      this.ppmddropdown.open();
    }

    return this.initiationApiService.searchPPMD(key);
  }
  /*PPMD search section - end*/

  public loadMarsQrms(): void {
    this.progressIndicator.show();
    this.initiationApiService.getApplicableMarsQrms()
      .pipe(takeUntil(this.unsubscriber$)).subscribe({
        next: (data: EmployeeView[]) => {
          if (data) {
            this.optionsMarsQrmEmployees = data.map((emp) => {
              return {
                heading: emp.outlookDisplayName,
                value: emp.empID,
                disabled: false,
              };
            });
          }
          this.progressIndicator.hide();
        }
      });
  }

  public initiateMars() {
    if (!this.isValid() || this.isActiveMarsFormExists) {
      return;
    }

    this.progressIndicator.show();

    const data = {
      clientNumber: this.isClientNotListed ?
        null : this.model.clientNumber,
      clientName: this.isClientNotListed ?
        this.otherClientName : null,
      marsClientTypeID: this.model.marsClientTypeID,
      recommendingPpmd: this.model.recommendingPpmd,
      recommendingPpmdDisplayName:this.model.recommendingPpmdDisplayName,
      isHedgeFund: this.model.isHedgeFund,
      isConsultedWithQrm: this.model.isConsultedWithQrm,
      applicableMarsQrm: this.model.applicableMarsQrm,
      consultationSummary: this.model.consultationSummary
    } as MarsClientInfo;

    
    this.initiationApiService.initiateMarsProcess(data).subscribe((response) => {
      this.progressIndicator.hide();
      if (response.isSuccess) {
        this.router.navigate(['/client/' + response.resultData + '/status']);
      }
    });
  
  }

  public isValid() {
    if (!this.model.marsClientTypeID ||
      !this.model.recommendingPpmd ||
      (!this.model.isHedgeFund && this.model.isHedgeFund !== 'false') ||
      (!this.model.isConsultedWithQrm && this.model.isConsultedWithQrm !== 'false')) {
      return false;
    }

    if ((!this.isClientNotListed && !this.model.clientNumber) ||
      (this.isClientNotListed && (!this.otherClientName ||
        !this.otherClientName.trim()))) {
      return false;
    }

    if (this.model.isConsultedWithQrm === 'true' && (!this.model.applicableMarsQrm ||
      !this.model.consultationSummary || !this.model.consultationSummary.trim())) {
      return false;
    }

    if (this.isClientNotListed) {
      return (this.isClientNameExists && !this.isCheckingNameInProcess) ? false : true;
    } 
    return true;
  }

  public selectMarsQrm() {
    this.model.applicableMarsQrm = this.selectFormCtrl.value || null;
  }

  public clearMarsQrm() {
    this.model.applicableMarsQrm = null;
  }

  public changeIsConsultedWithQrm() {
    this.selectFormCtrl.setValue('');
    this.model.applicableMarsQrm = null;
    this.model.consultationSummary = '';
    this.clearMarsQrm();
  }

  public stateChanged() {
    if (this.checkboxFormCtrl.value) {
      this.isClientNotListed = Boolean(this.checkboxFormCtrl.value);

      this.clearClientSearch();
      this.otherClientName = '';
      this.isClientNameExists = false;
    }
    else {
      this.isClientNotListed = Boolean(this.checkboxFormCtrl.value);
    }
  }

  public changeClientName() {
    this.setTimeoutCheckCurrentClientName();
  }

  private setTimeoutCheckCurrentClientName() {
    if (this.checkClientNameTimerId) {
      clearTimeout(this.checkClientNameTimerId);
      this.checkClientNameTimerId = null;
    }
    this.checkClientNameTimerId = setTimeout(() => {
      this.checkCurrentClientName();
    }, 1000)
  }

  private checkCurrentClientName() {
    if (this.otherClientName &&
      this.otherClientName.trim().length) {
      this.isCheckingNameInProcess = true;
      this.initiationApiService.isClientNameExists(this.otherClientName)
        .subscribe((result) => {
          this.isClientNameExists = result;
          this.isCheckingNameInProcess = false;
        });
    } else {
      this.isClientNameExists = false;
      this.isCheckingNameInProcess = false;
    }
  }

  ngOnDestroy(): void {
    this.progressIndicator.hide();
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }

  initiateButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primaryLoud,
    size: Size.lg,
    width: WidthState.fixed
  }

  cancelButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: WidthState.fixed
  }

  searchOptions: SearchOptions = {
    placeholder: 'Search by name or ID',
    size: Size.md,
    customClass: '',
  };

  options: CheckboxOptions = {
    label: 'Client not listed',
    theme: Themes.green,
    isRequired: false,
  };

  clientTooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 1,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
    tooltipMaxWidth: 300
  };
  
  radioBtns: RadioButton[] = [
    {
      options: {
        label: 'Yes',
        theme: Themes.green,
      },
      value: 'true',
    },
    {
      options: {
        label: 'No',
        theme: Themes.green
      },
      value: 'false',
    }
  ];

  selectOptionsMARSClientType: SelectOptions = {
    placeholder: 'Select type',
    size: Size.md,
    disabled: false
  };

  selectOptionsMarsQrm: SelectOptions = {
    placeholder: '',
    size: Size.md,
    disabled: false
  }

  textareaOptions: TextareaOptions = {
    placeholder: 'Enter text',
    minHeight: 100,
    maxLength: 4000,
    size: Size.md,
    maxLengthPosition: 'bottom-right',
  };

  inputOptions: InputOptions = {
    placeholder: 'Enter full legal entity name',
    size: Size.md,
    maxLength: 40,
    maxLengthPosition: 'bottom-right'
  };

}

