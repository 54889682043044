import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  RADIO_GROUP_OPTIONS_FOR_WINNING_PROPOSAL_SCOPE,
  WINNING_PROPOSAL_RADIO_GROUP_VALUE,
} from './close-out-proposal-helper';

@Component({
  selector: 'app-close-out-proposal',
  templateUrl: './close-out-proposal.component.html',
  styleUrl: './close-out-proposal.component.less',
})
export class CloseOutProposalComponent {
  @Input() winningProposal: string = '';
  @Output() winningProposalChange: EventEmitter<string> =
    new EventEmitter<string>();

  winning_proposal_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_WINNING_PROPOSAL_SCOPE;

  winning_proposal_radio_values: any = WINNING_PROPOSAL_RADIO_GROUP_VALUE;

  winning_proposalStateChanged(event: string): void {
    this.winningProposalChange.emit(event);
  }
}
