import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userEmailSubject = new BehaviorSubject<string | null>(null);
  private currentLoggedInUserSubject = new BehaviorSubject<any>(null);
  public currentLoggedInUser$ = this.currentLoggedInUserSubject.asObservable();
  public userEmail$ = this.userEmailSubject.asObservable();
  apiUrl: string = environment.apiUrl;

  constructor(private httpService: HttpClient) { }

  setUserEmail(email: string) {
    this.userEmailSubject.next(email);
    localStorage.setItem('userProfileId', email);
  }
 getUserEmail(): string | null {
    return localStorage.getItem('userProfileId');
  }

  getUserRoles(entityid?: number, entitytypeid?: number): Observable<any> {
    return this.httpService.get<any>(`${this.apiUrl}Admin/Admin/GetEmployeeRoles?entityid=${entityid}&entitytypeid=${entitytypeid}`)
      .pipe(
        tap(data => {
          this.currentLoggedInUserSubject.next(data);
        })
      );
  }

}

export enum EntityType
{
    None = -1,
    Opportunity = 0,
    Submission = 1,
    AdminModule = 3,
    Client = 4,
    ParentClient = 5,
    ClientDocumentsPackage = 6,
    AgreementRepository = 7,
    ClientMilestone = 8
}
