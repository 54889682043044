import { Component, Input } from '@angular/core';
import { DocumentService } from '../../http/document/document.service';
import { ClientWebapiService } from '../../http/client/client-webapi.service';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { SelectItemOptions, SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { ButtonKind, Column, ExtThemes, LabelPosition, Size, Themes, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { forkJoin,from, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-document-meta-data-edit',
  templateUrl: './document-meta-data-edit.component.html',
  styleUrl: './document-meta-data-edit.component.less'
})
export class DocumentMetaDataEditComponent {
  constructor(private docService: DocumentService, private ncaDocService: ClientWebapiService, private commonHelperService: CommonHelperService,
    private modalService: ModalService, private progressIndicator: ProgressIndicatorService) { }
  ngOnInit(): void {
    this.formatComments();
    this.getDocumentTypeDropdownData();
  }
  editDocumentMetaDataResponse: any = {};
  documentTypeItemsList: any = [];
  @Input() isFooter: boolean = false;
  @Input() size: string = "";
  @Input() isInverse: boolean = false;
  @Input() public documentEditMetaData: any = [];
  @Input() public clientMileStoneID: any;
  @Input() public moduleName: any;
  @Input() public docSectionId: any;
  public selectedDocumentTypeId: any;
  textareaOptions: TextareaOptions = {
    placeholder: 'Enter comments',
    minHeight: 100,
    maxLength: 400,
    maxLengthPosition: 'bottom-right',
    size: Size.lg
  };
  selectDocumentTypeOptions: SelectOptions = {
    label: '',
    labelPosition: LabelPosition.external,
    description: '',
    placeholder: 'Select  type',
    size: Size.lg,
    disabled: false,
    isResponsive: false,
    isRequired: true,
    isInverse: false,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: "",
    stickerIsDisabled: false,
    stickerPosition: "bottom-left",
    stickerIndent: 0,
    stickerCustomClass: "",
    stickerIsDynamic: true
  };
  closeModal() {
    this.applyBodyOverflow();
    this.modalService.close(this.editDocumentMetaDataResponse);
  }
  closeEditModal(){
    this.progressIndicator.show();
    this.getDocumentUploadData();
  }
  applyBodyOverflow() {
    document.body.style.overflow = 'auto';
  }
  formatComments() {
    this.documentEditMetaData.fileComments = this.documentEditMetaData.fileComments == null ? this.documentEditMetaData.fileComments : this.documentEditMetaData.fileComments.trim();
  }
  openModal() {
    this.modalService.open(DocumentMetaDataEditComponent);
  }
  onSave() {
    this.progressIndicator.show();
    this.applyBodyOverflow();
    from(this.updateDocumentMetaData()).pipe(
      switchMap(isUpdated => {
         return of(isUpdated);
      })
    ).subscribe();   
  }
  getDocumentTypeDropdownData() {
    this.ncaDocService.getDocumentTypeData().subscribe(
      {
        next: (result: any) => {
          const convertedArray = this.commonHelperService.loadFormWiseDocumentTypes(result, this.moduleName, this.docSectionId);
          this.documentTypeItemsList = convertedArray;
          const item = this.documentTypeItemsList.find((item: { value: number; heading: string }) => item.heading.toLowerCase() === this.documentEditMetaData.clientMilestoneDocumentTypeName.toLowerCase());
          this.selectedDocumentTypeId = item.value;
        },
        error: (err: any) => {
          this.progressIndicator.hide();
        }
      })
  }
  getDocumentUploadData() {
    this.ncaDocService.getNCADocumentUploadData("All", this.clientMileStoneID).subscribe(
      {
        next: (result: any) => {
          this.progressIndicator.hide();
          this.editDocumentMetaDataResponse = {
            isEdited: true,
            documentMetaData: result
          }
          this.closeModal();
        },
        error: (err: any) => {
          this.progressIndicator.hide();
        }
      })
  }
  valueChange() {
    this.documentEditMetaData.clientMilestoneDocumentTypeID = this.selectedDocumentTypeId;
  }
  prepareNCADocumentMetaDataUpdateRequest() {
    return {
      AttachmentID: this.documentEditMetaData.attachmentID,
      ModifiedBy: '00000000',//TODO:Need to replace with Employee ID
      CreatedBy: this.documentEditMetaData.createdBy,
      ClientMilestoneDocumentTypeID: this.documentEditMetaData.clientMilestoneDocumentTypeID,
      ClientMilestoneDocumentID: this.documentEditMetaData.clientMilestoneDocumentID,
      FileComments: this.documentEditMetaData.fileComments,
      FileName:this.documentEditMetaData.fileName,
      ClientMilestoneID: this.clientMileStoneID,
      ClientMilestoneDocumentSectionID: this.documentEditMetaData.clientMilestoneDocumentSectionID,
      isActive: true,
    };
  }
  prepareAttachmentDocumentMetaDataUpdateRequest() {
    return{
      AttachmentID: this.documentEditMetaData.attachmentID,        
      ModifiedBy: '00000000',//TODO:Need to replace with Employee ID
      CreatedBy: this.documentEditMetaData.createdBy,
      FileFolderPath: this.documentEditMetaData.fileFolderPath,
      MIMEType: this.documentEditMetaData.mimeType,
      FileType: this.documentEditMetaData.fileType,
      FileLength: this.documentEditMetaData.fileLength,
      FileName: this.documentEditMetaData.fileName,
      isActive: true,
      AttachmentGuid: this.documentEditMetaData.attachmentGuid
    };
  }
  updateDocumentMetaData(): Observable<any> {
    let documentMetaDataRequestList = this.prepareNCADocumentMetaDataUpdateRequest();
    let attachmentDocumentMetaDataRequestList = this.prepareAttachmentDocumentMetaDataUpdateRequest();
    return forkJoin([
      this.ncaDocService.updateNCADocumentMetaData(documentMetaDataRequestList),
      this.docService.updateDocumentMetaData(attachmentDocumentMetaDataRequestList)
    ]).pipe(
      switchMap(([metaDataResponse, attachmentResponse]) => {
        if (metaDataResponse && attachmentResponse) {
          return this.ncaDocService.getNCADocumentUploadData("All", this.clientMileStoneID).pipe(
            switchMap(res => {
              this.progressIndicator.hide();
              this.editDocumentMetaDataResponse = {
                isEdited: true,
                documentMetaData: res
              }
              this.closeModal();
              return of(this.editDocumentMetaDataResponse); // Return an observable
            })
          );
        } else {
          return of(null); // Return an observable in case of null responses
        }
      })
    );
  }
}
