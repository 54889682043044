import {
  Component,
  EventEmitter,
  Output,
  OutputEmitterRef,
} from '@angular/core';
import { ExternalCommunicationService } from '../../../../../http/external-communication/external-communication.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ExternalCommunicationConstant } from '../../../../constants/external_communication.constant';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SecondaryButton, textareaOptions } from './rush-request-helper';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { CommonHelperService } from '../../../../../common/services/common-helper.service';

@Component({
  selector: 'app-rush-request-popup',
  templateUrl: './rush-request-popup.component.html',
  styleUrl: './rush-request-popup.component.less',
})
export class RushRequestPopupComponent {
  htmlElementSize: Size = ExternalCommunicationConstant.UI_ELEMENT_SIZE;
  cancelButton: ButtonOptions = SecondaryButton;
  textareaValue: string = '';
  textareaOptions: TextareaOptions = textareaOptions;
  textAreaLength: number = ExternalCommunicationConstant.TEXTAREA_LENGTH;

  constructor(
    private readonly modalService: ModalService,
    private readonly externalCommunicationService: ExternalCommunicationService,
    private commonHelperService: CommonHelperService
  ) {}

  ngOnInit(): void {
    this.valueChanged();
  }

  resetDatePicker(): void {
    this.closeModal('modify');
    this.externalCommunicationService.emitResetDatePickerEvent();
  }

  valueChanged(): void {
    if (this.textareaValue.length > this.textAreaLength) {
      this.cancelButton.disabled = false;
    } else {
      this.cancelButton.disabled = true;
    }
  }

  closeModal(action: string = 'reset'): void {
    this.modalService.close({
      action: action,
      textValue: this.commonHelperService.trimPrefixEmptySpace(
        this.textareaValue
      ),
    });
    this.externalCommunicationService.emitTextareaValue(
      this.commonHelperService.trimPrefixEmptySpace(this.textareaValue)
    );
  }
}
