import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  IntakeOpportunitySummaryTeamManagement,
  teamMembersTeamManagement,
} from '../../../common/models/opportunity-details.model';
import { SubmissionService } from '../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { MANAGE_TEAM_MEMBERS_BTN_OPTIONS } from './team-opportunity-summary-helper';
import { SHOW_MORE_BTN_OPTIONS } from '../../opportunity-details/submission-common-section/submission-common-section.helper';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ManageTeamMemberPopupComponent } from './manage-team-member-popup/manage-team-member-popup.component';
import { BrowserTabConstant } from '../../../common/constants/browser-tab-constant';

@Component({
  selector: 'app-team-opportunity-summary',
  templateUrl: './team-opportunity-summary.component.html',
  styleUrl: './team-opportunity-summary.component.less',
})
export class TeamOpportunitySummaryComponent implements OnInit, OnDestroy {
  @Input() dataOpportunityId: string | null = '';

  teamMembers: teamMembersTeamManagement[] = [];
  visibleTeamMembers: teamMembersTeamManagement[] = [];
  showAll: boolean = false;
  showMoreOptions: any = SHOW_MORE_BTN_OPTIONS;
  lepName: string = '';

  unsubscriber$: Subject<any> = new Subject();

  options: ButtonOptions = MANAGE_TEAM_MEMBERS_BTN_OPTIONS;

  constructor(
    private readonly submissionService: SubmissionService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getTeamData();
    document.title = BrowserTabConstant.Browser_TabName_Opportunity;
  }

  getTeamData(): void {
    this.submissionService
      .getTeamMembers(this.dataOpportunityId ? this.dataOpportunityId : '')
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: IntakeOpportunitySummaryTeamManagement) => {
          this.lepName = data.leadEngagementPartner.fullName ?? '';
          this.teamMembers = data.teamMembers;
          this.updateVisibleTeamMembers();
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  updateVisibleTeamMembers(): void {
    this.visibleTeamMembers = this.showAll
      ? this.teamMembers
      : this.teamMembers?.slice(0, 2);
  }

  // TODO: show all functionality to be implemented in another story
  // toggleShowAll(): void {
  //   this.showAll = !this.showAll;
  //   this.updateVisibleTeamMembers();
  // }

  openTeamMembersModal(): void {
   const teamMemberModalRef =  this.modalService.open(ManageTeamMemberPopupComponent, {
      dataOpportunityId: this.dataOpportunityId,
    });

    teamMemberModalRef.onClosed().pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
      this.getTeamData();     
   });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();

  }
}
