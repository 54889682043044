export class UrlConstant {
  static readonly #COMMON_INATKE_SUBMISSION_PATH = 'Intake/Submission/';
  static readonly #COMMON_INATKE_OPPORTUNITY_PATH = 'Intake/Opportunity/';
  static readonly #COMMON_INATKE_INTAKE_PATH = 'Intake/Intake/';
  static readonly #COMMON_INATKE_TEAM_MANAGEMENT_PATH =
    'Intake/TeamManagement/';
    static readonly #COMMON_INATKE_DOCUMENT_PATH =
    'Document/';  

  public static readonly GET_INTAKE_CONFIGARATIONS_API: string =
    UrlConstant.#COMMON_INATKE_INTAKE_PATH + 'GetIntakeConfigurations';
  public static readonly GET_INTAKE_TOOLTIPS_API: string =
    UrlConstant.#COMMON_INATKE_INTAKE_PATH + 'GetIntakeToolTips';
  public static readonly GET_SELECTED_OPPERTUNITY_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetLatestSubmissionDetail?opportunityNumber=';
  public static readonly GET_SUBMISSION_TYPES_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'GetSubmissionType';
  public static readonly GET_SUBMISSION_SUBTYPES_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetSubmissionSubType?submissionTypeId=';
  public static readonly POST_SUBMISSION_REQUEST_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'InitiateSubmission';
  public static readonly GET_OPPORTUNITY_DETAILS_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'GetOpportunities?searchItem=';
  public static readonly GET_INTAKE_DETAILS_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetLeftMenuSubmissionDetails?opportunityNumber=';
  public static readonly GET_SUBMISSION_DETAILS_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetSubmissionDetail?opportunityNumber=';
  public static readonly GET_LINKED_OPPORTUNITIES_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetLinkedOpportunities?opportunityNumber=';
  public static readonly GET_DELIVERY_SERVICE_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetOpportunityServicesAndDelivery?opportunityNumber=';
  public static readonly POST_COMPLEXITY_DATA_API: string =
    UrlConstant.#COMMON_INATKE_OPPORTUNITY_PATH + 'SaveComplexityResponse';
  public static readonly POST_COMPLEXITY_CYBER_DATA_API: string =
    UrlConstant.#COMMON_INATKE_OPPORTUNITY_PATH + 'SaveCyberComplexityResponse';
  public static readonly GET_COMPLEXITY_DATA_API: string =
    UrlConstant.#COMMON_INATKE_OPPORTUNITY_PATH +
    'GetComplexityResponse?opportunityNumber=';
  public static readonly POST_OPPORTUNITY_DETAILS_REQUEST_API: string =
    UrlConstant.#COMMON_INATKE_OPPORTUNITY_PATH + 'EditOpportunity';
  public static readonly GET_WBS_CODE_API: string =
    UrlConstant.#COMMON_INATKE_OPPORTUNITY_PATH + 'GetWBSCodes';
  public static readonly POST_ARCHIVE_SAVE: string = 
    'Intake/Opportunity/EditOpportunity'
  
    public static readonly GET_OPPORTUNITY_DETAILS_INFO_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetOpportunityDetail?opportunityNumber=';
  public static readonly getDocumentStatusDropdownurl: string =
    'Intake/SubmissionDocument/GetDocumentStatus?submissionStatusId=';
  public static readonly getDocumentTypeDropdownurl: string =
    'Intake/SubmissionDocument/GetDocumentType?submissionTypeId=';
  public static readonly getDocumentTableData: string =
    'Intake/SubmissionDocument/GetSubmissionDocumentDetails?submissionId=';
  public static readonly postDocumentData: string =
    'Intake/SubmissionDocument/SaveSubmissionDocument';
  public static readonly PUT_DOCUMENT_EDIT_URL: string =
    'Intake/SubmissionDocument/EditSubmissionDocument';
  public static readonly Delete_DOCUMENT_URL: string =
    'Intake/SubmissionDocument/DeleteSubmissionDocument?submissionDocumentId=';
  public static readonly PUT_LEP_URL: string =
    'Intake/Submission/UpdateLEPStatus';
  public static readonly GET_OPPORTUNITY_SUMMARY_TEAM_DATA: string =
    'Intake/Opportunity/GetOpportunitySummaryTeam?opportunityNumber=';
  public static readonly UPDATE_RM_STATUS: string =
    'Intake/SubmissionDocument/UpdateRMStatus';
  public static readonly GET_FEE_TYPE_API: string =
    UrlConstant.#COMMON_INATKE_INTAKE_PATH + 'GetFeeTypes';
  public static readonly SAVE_INTAKE_DESC_COMMON_DETAILS: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'UpdateSubmission';
  public static readonly INTAKE_CLIENT_SUMMARY_API: string =
    'Client/GetClientInfo?clientNumber=';
  public static readonly GET_INTAKE_SUBMISSION_REVIEWERS: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'GetReviewers?submissionId=';
  public static readonly UPDATE_INTAKE_SUBMISSION_REVIEWERS: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'UpdateReviewers';
  public static readonly GET_CO_DRAFT_STATUS: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'ValidateSubmission';
  public static readonly UPDATE_SUBMISSION_STATUS_URL: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'UpdateSubmissionStatus';
  public static readonly GET_TECHNICAL_REVIEWERS: string =
    UrlConstant.#COMMON_INATKE_INTAKE_PATH +
    'GetPPMDorSeniorManagerEmployeeDetails?searchItem=';
  public static readonly GET_ACTIONS_REASONS_API: string =
    UrlConstant.#COMMON_INATKE_INTAKE_PATH +
    'GetActionReason?submissionActionId=';
  public static readonly POST_SUBMISSION_HISTORY_API: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'SaveSubmissionActionHistory';
  public static readonly GET_SUBMISSION_ACTION_HISTORY: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH +
    'GetSubmissionActionHistory?submissionId=';
  public static readonly GET_SUBMISSION_DISCONTINUE_REASON: string =
    UrlConstant.#COMMON_INATKE_INTAKE_PATH +
    'GetActionReason?submissionActionId=';
  public static readonly UPDATE_SUBMISSION_DISCONTINUE: string =
    UrlConstant.#COMMON_INATKE_SUBMISSION_PATH + 'SaveSubmissionActionHistory';
  public static readonly GET_SUBMISSION_ACTION_MODEL: string =
    UrlConstant.#COMMON_INATKE_INTAKE_PATH +
    'GetSubmissionStatusByAction?submissionActionId=';
  public static readonly GET_INTAKE_DETAILS: string =
    UrlConstant.#COMMON_INATKE_OPPORTUNITY_PATH +
    'GetIntakeOpportunityDetail?opportunityNumber=';
  
  public static readonly GET_ARCHIVE_DETAILS: string = 
    'IntegrationDataSync/GetArchiveDetails';

  public static readonly GET_PREVIOUS_CONNECTED_ARCHIVE: string =
    UrlConstant.#COMMON_INATKE_OPPORTUNITY_PATH +
    'GetPreviouslyConnectedArchiveCount?opportunityNumber=';

  public static readonly POST_ENGAGEMENT_GRID_DATA: string =
    'Intake/EngagementTeamDashboard/GetEngagementTeamDashboardGridData';
  public static readonly GET_ENGAGEMENT_TILE_COUNT: string =
    'Intake/EngagementTeamDashboard/GetEngagementTeamTilesCount';
    public static readonly POST_RISKMANAGER_GRID_DATA: string =
    'Intake/RiskManagerOversightDashboard/GetRiskManagerOversightDashboardGridData';
  public static readonly GET_RISKMANAGER_TILE_COUNT: string =
    'Intake/RiskManagerOversightDashboard/GetRiskManagerOversightTilesCount';
  public static readonly GET_RECENTLY_VIEWED_SUBMISSIONS: string =
    'ReviewerDashboard/GetRecentlyViewedSubmissions';
  public static readonly GET_ARCHIVE_DROPDOWN: string = 
    'IntegrationDataSync/SearchArchiveByWBS';

  public static readonly GET_TEAM_MEMBERS: string =
    UrlConstant.#COMMON_INATKE_TEAM_MANAGEMENT_PATH +
    'GetTeamMembers?opportunityNumber=';
  public static readonly UPDATE_TEAM_MEMBERS: string =
    UrlConstant.#COMMON_INATKE_TEAM_MANAGEMENT_PATH +
    'UpdateTeamMembers?OpportunityNumber=';
  public static readonly FETCH_TEAM_MEMBERS: string =
    UrlConstant.#COMMON_INATKE_TEAM_MANAGEMENT_PATH +
    'FetchEmployees';
    public static readonly GET_ARCHIVE_DOCUMENT_ID: string =
    UrlConstant.#COMMON_INATKE_TEAM_MANAGEMENT_PATH +
    'FetchEmployees';
  public static readonly INTEGRATION_DATASYNC: string =
    'IntegrationDataSync/UpdateDescDetails';
    

    // ___________________________ Document related URL ___________________________
    public static readonly GET_PRESIGNED_DETAILS_PATH: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+'GetPresignedUrlForUpload';
    public static readonly GET_SAVE_DOCUMENT_META__DATA_PATH: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+'SaveDocumentMetaData';
    public static readonly GET_UPDATE_DOCUMENT_META__DATA_PATH: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+'UpdateDocumentMetaData';
    public static readonly GET_DOWNLOAD_PATH: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+'GetDownloadUrl';
    public static readonly GET_ARCHIVE_DOWNLOAD_PATH: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+'GetArchiveDownloadUrl?attachmentId=';
    public static readonly GET_DELETE_DOCUMENT_PATH: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+'DeleteDocument?documentKey=';
    public static readonly GET_UPLOAD_DOCUMENT_PATH: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+'UploadDocument';
    public static readonly GET_EXPORT_CLIENT_DATA_PDF_PATH: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+'ExportClientDataPDF';
    public static readonly GET_ARCHIVE_SUBMISSION_ID_PATH: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+'ArchiveSubmission';
    public static readonly GET_PDF_DOWNLOAD_PATH: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+'ExportSubmissionPdf';
    public static readonly GET_DOCUMENT_TYPES_PATH: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+'GetDocumentTypes';
    public static readonly GENERATE_ARCHIVE_SUBMISSION_PDF: string = UrlConstant.#COMMON_INATKE_DOCUMENT_PATH+"GenerateArchiveSubmissionPDF?submissionId=";
    // ______________________________ Local JSON ______________________________

  public static readonly GET_INTAKE_OPPERTUNITY_STATUS: string =
    './../../../assets/json-constant/intakeOppertunitySubmissionStatus.json';
  public static readonly GET_REVIEWERS_ROLES_PATH =
    './../../../assets/json-constant/roles.json';
  public static readonly GET_WBS_CHARGECODES: string =
    './../../../assets/json-constant/wbs.json'; // TODO : need to fetch from backend once backend ready
  public static readonly GET_INTAKE_ACTIONS: string =
    './../../../assets/json-constant/intake-action.json';

  public static readonly ENGAGEMENT_GRID_DATA_JSON: string =
    './../../../assets/json-constant/engagement-dashboard.json';
}
