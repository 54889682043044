<div class="dds-modal-init dds-modal-open">
    <div class="dds-modal-overlay"></div>
    <div class="dds-modal">
        <div class="dds-modal__header">
            <span class="dds-modal__title" *ngIf="actionType=='Add'">Add new user</span>
            <span class="dds-modal__title" *ngIf="actionType=='Save'">Edit user</span>
            <button class="dds-modal__close dds-icon dds-icon_close" (click)="cancel()"
                aria-label="Close modal"></button>
        </div>
        <div class="dds-modal__body">
            <!-- #region Body -->
            <div class="col-md-12 margin-bottom-s">
                <span class="eclipse-small-header-grey">Role</span><br />
                <span class="font-size-14">{{dialogRoleModel.roleName}}</span>
            </div>
            <div class="col-md-12 margin-bottom-s">
                <span *ngIf="!dialogRoleModel.roleData?.empId"
                                    class="icon-required-yellow"></span>
                <span class="eclipse-small-header-grey">Name</span><br/>
                <app-people-picker [isEmployeeSearch]="true" *ngIf="!dialogRoleModel.roleData?.empId"
                    (userSelected)="onUserSelected($event)" [maxLength]="70"></app-people-picker>
                <div *ngIf="dialogRoleModel.roleData?.empId" class="selected-item-display">
                    <span class="padding-right-xxs font-size-14">{{ dialogRoleModel.roleData.outlookDisplayName }}</span>
                    
                    <span class="dds-icon dds-icon_close" (click)="clearUserSearch()" *ngIf="actionType =='Add'"></span>
                </div>
            </div>

            <div class="col-md-12 margin-bottom-s" *ngIf="isNCAManagerCheckVisible">
                <dds-checkbox class="dds-filter__section_checkbox" label="NCA Manager" theme="green"
                    [value]="dialogRoleModel.roleData.isNcaManager" (stateChanged)="selectIsNcaManager($event)"
                    #checkbox></dds-checkbox>
            </div>            
            <app-admin-nested-multi-select *ngIf="roleModel.roleId === 536" 
            [applicableFssItems]="applicableFssItems" [applicableIndustryItems]="optionsOpportunityIndustryItems" 
            [applicableIndustrySectorItems]="industrySectorItems"
            [excludedIndustrySectorCodes]="excludedIndustrySectorCodes" 
            [selectedEmpId]="dialogRoleModel.roleData?.empId"
            [isEditMode]="isEditMode"
            [selectedFSSTypes]="dialogRoleModel.roleData.applicableFss"
            [selectedIndustrySectorCodes]="selectedIndustrySectorCodes"
            (selectedFssCodesChange)="selectedFssChange($event)"
            (selectedIndustryCodesChange)="selectedIndustryChange($event)"
            (selectedSectorCodesChange)="selectedIndustrySectorChange($event)"
            ></app-admin-nested-multi-select>
            <ng-container *ngIf="roleModel.roleId !== 536">
                <div class="col-md-12 margin-bottom-s" *ngIf = "showApplicableFss">
                    <span *ngIf="(msiApplicableFssItemsFormCtrl?.value?.length ?? 0) == 0"
                                        class="icon-required-yellow"></span>
                    <span class="eclipse-small-header-grey">Applicable FSS:</span><br />
    
                    <div class="multiselect-div margin-top-xxs">
                        <div class="col-md-5 padding-right-xs">
                            <dds-multi-select class="box-size"
                                [displayTickAllBtn]="multiSelectOptions.displayTickAllBtn ?? false"
                                [isResponsive]="multiSelectOptions.isResponsive ?? false"
                                [isRequired]="multiSelectOptions.isRequired ?? false"
                                [label]="multiSelectOptions.label ?? ''" [size]="multiSelectOptions.size ?? ''"
                                [placeholder]="multiSelectOptions.placeholder ?? ''" [type]="multiSelectOptions.type ?? ''"
                                [controlType]="multiSelectOptions.controlType ?? ''"
                                [theme]="multiSelectOptions.theme ?? ''" [isInverse]="multiSelectOptions.isInverse ?? false"
                                [list]="applicableFssItems" [formControl]="msiApplicableFssItemsFormCtrl"
                                (valueChanged)="fssChange($event)">
                            </dds-multi-select>
                        </div>
    
                        <div *ngIf="(msiApplicableFssItemsFormCtrl?.value?.length ?? 0) > 0"
                            class="multiselectddl col-md-7">
                            <span class="comments-text-label eclipse-small-header-grey">Selected values:</span>
                            <dds-tags>
                                <dds-tag class="tag-width" *ngFor="let item of msiApplicableFssItemsFormCtrl?.value"
                                    [theme]="multiOptions.theme ?? ''" [isRemovable]="multiOptions.isRemovable ?? false"
                                    [customClass]="multiOptions.customClass ?? ''" [size]="multiOptions.size ?? ''"
                                    [isError]="multiOptions.isError ?? false" [isInverse]="multiOptions.isInverse ?? false"
                                    (removed)="removeFss(item)" #tagItems>
                                    {{ item?.label }}
                                </dds-tag>
                            </dds-tags>
                        </div>
                    </div>
    
                </div>
    
                <div class="col-md-12 margin-bottom-s" [style]="{'display': showMarsConsultingIndustry ? 'block': 'none'}">
                    <span *ngIf="showMarsConsultingIndustry && !marsConsultingIndustrySelectedValues.length" class="icon-required-yellow"></span>
                    <span class="eclipse-small-header-grey">MARS Consulting designated industry:</span><br />
    
    
                    <div class="multiselect-div margin-top-xxs">
                        <div class="col-md-5 padding-right-xs">
                            <dds-multi-select class="box-size"
                            [displayTickAllBtn]="multiSelectOptions.displayTickAllBtn ?? false"
                            [isResponsive]="multiSelectOptions.isResponsive ?? false"
                            [isRequired]="multiSelectOptions.isRequired ?? false"
                            [label]="multiSelectOptions.label ?? ''" [size]="multiSelectOptions.size ?? ''"
                            [placeholder]="multiSelectOptions.placeholder ?? ''" [type]="multiSelectOptions.type ?? ''"
                            [controlType]="multiSelectOptions.controlType ?? ''"
                            [theme]="multiSelectOptions.theme ?? ''" [isInverse]="multiSelectOptions.isInverse ?? false" [list]="uniqueMarsConsultingIndustryItems"
                                [formControl]="msiMarsConsultingIndustryItemsFormCtrl"
                                (valueChanged)="marsConsultingIndustryChange()">
                            </dds-multi-select>
                        </div>
    
                        <div *ngIf="(msiMarsConsultingIndustryItemsFormCtrl?.value?.length ?? 0) > 0"
                            class="multiselectddl col-md-7">
                            <span class="comments-text-label eclipse-small-header-grey">Selected values:</span>
                            <dds-tags>
                                <dds-tag class="tag-width"
                                    *ngFor="let item of msiMarsConsultingIndustryItemsFormCtrl?.value"
                                    [theme]="multiOptions.theme ?? ''" [isRemovable]="multiOptions.isRemovable ?? false"
                                    [customClass]="multiOptions.customClass ?? ''" [size]="multiOptions.size ?? ''"
                                    [isError]="multiOptions.isError ?? false" [isInverse]="multiOptions.isInverse ?? false"
                                    (removed)="removeMarsConsultingIndustry(item)" #tagItems>
                                    {{ item?.label }}
                                </dds-tag>
                            </dds-tags>
                        </div>
                    </div>
                </div>
    
                <div class="col-md-12 margin-bottom-s" [style]="{'display': showMarsTaxIndustry ? 'block': 'none'}">
                    <span *ngIf="showMarsTaxIndustry && !marsTaxIndustrySelectedValues.length" class="icon-required-yellow"></span>
                    <span class="eclipse-small-header-grey">MARS Tax designated industry:</span><br />
    
    
                    <div class="multiselect-div margin-top-xxs">
                        <div class="col-md-5 padding-right-xs">
                            <dds-multi-select class="box-size"
                            [displayTickAllBtn]="multiSelectOptions.displayTickAllBtn ?? false"
                            [isResponsive]="multiSelectOptions.isResponsive ?? false"
                            [isRequired]="multiSelectOptions.isRequired ?? false"
                            [label]="multiSelectOptions.label ?? ''" [size]="multiSelectOptions.size ?? ''"
                            [placeholder]="multiSelectOptions.placeholder ?? ''" [type]="multiSelectOptions.type ?? ''"
                            [controlType]="multiSelectOptions.controlType ?? ''"
                            [theme]="multiSelectOptions.theme ?? ''" [isInverse]="multiSelectOptions.isInverse ?? false" [list]="uniqueMarsTaxIndustryItems"
                                [formControl]="msiMarsTaxIndustryItemsFormCtrl" (valueChanged)="marsTaxIndustryChange()">
                            </dds-multi-select>
                        </div>
    
                        <div *ngIf="(msiMarsTaxIndustryItemsFormCtrl?.value?.length ?? 0) > 0"
                            class="multiselectddl col-md-7">
                            <span class="comments-text-label eclipse-small-header-grey">Selected values:</span>
                            <dds-tags>
                                <dds-tag class="tag-width" *ngFor="let item of msiMarsTaxIndustryItemsFormCtrl?.value"
                                    [theme]="multiOptions.theme ?? ''" [isRemovable]="multiOptions.isRemovable ?? false"
                                    [customClass]="multiOptions.customClass ?? ''" [size]="multiOptions.size ?? ''"
                                    [isError]="multiOptions.isError ?? false"
                                    [isInverse]="multiOptions.isInverse ?? false" (removed)="removeMarsTaxIndustry(item)"
                                    #tagItems>
                                    {{ item?.label }}
                                </dds-tag>
                            </dds-tags>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 margin-bottom-s" *ngIf="showConsultingIndustry">
                    <span *ngIf="showConsultingIndustry && !consultingIndustrySelectedValues.length" class="icon-required-yellow"></span>
                    <span class="eclipse-small-header-grey">Consulting designated industry:</span><br />
                    
                    <div class="multiselect-div margin-top-xxs">
                        <div class="col-md-5 padding-right-xs">
                            <dds-multi-select class="box-size"
                            [displayTickAllBtn]="multiSelectOptions.displayTickAllBtn ?? false"
                            [isResponsive]="multiSelectOptions.isResponsive ?? false"
                            [isRequired]="multiSelectOptions.isRequired ?? false"
                            [label]="multiSelectOptions.label ?? ''" [size]="multiSelectOptions.size ?? ''"
                            [placeholder]="multiSelectOptions.placeholder ?? ''" [type]="multiSelectOptions.type ?? ''"
                            [controlType]="multiSelectOptions.controlType ?? ''"
                            [theme]="multiSelectOptions.theme ?? ''" [isInverse]="multiSelectOptions.isInverse ?? false" [list]="uniqueConsultingIndustryItems"
                            [formControl]="consultingIndustryItemsFormCtrl" (valueChanged)="consultingIndustryChange()">
                            </dds-multi-select>
                        </div>
    
                        <div *ngIf="(consultingIndustryItemsFormCtrl?.value?.length ?? 0) > 0"
                            class="multiselectddl col-md-7">
                            <span class="comments-text-label eclipse-small-header-grey">Selected values:</span>
                            <dds-tags>
                                <dds-tag class="tag-width" *ngFor="let item of consultingIndustryItemsFormCtrl?.value"
                                    [theme]="multiOptions.theme ?? ''" [isRemovable]="multiOptions.isRemovable ?? false"
                                    [customClass]="multiOptions.customClass ?? ''" [size]="multiOptions.size ?? ''"
                                    [isError]="multiOptions.isError ?? false"
                                    [isInverse]="multiOptions.isInverse ?? false" (removed)="removeConsultingIndustry(item)"
                                    #tagItems>
                                    {{ item?.label }}
                                </dds-tag>
                            </dds-tags>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 margin-bottom-s" *ngIf="showMarsAuditIndustry">
                    <span *ngIf="showMarsAuditIndustry && !marsAuditIndustrySelectedValues.length" class="icon-required-yellow"></span>
                    <span class="eclipse-small-header-grey">MARS Audit designated industry:</span><br />
                    
                    <div class="multiselect-div margin-top-xxs">
                        <div class="col-md-5 padding-right-xs">
                            <dds-multi-select class="box-size"
                            [displayTickAllBtn]="multiSelectOptions.displayTickAllBtn ?? false"
                            [isResponsive]="multiSelectOptions.isResponsive ?? false"
                            [isRequired]="multiSelectOptions.isRequired ?? false"
                            [label]="multiSelectOptions.label ?? ''" [size]="multiSelectOptions.size ?? ''"
                            [placeholder]="multiSelectOptions.placeholder ?? ''" [type]="multiSelectOptions.type ?? ''"
                            [controlType]="multiSelectOptions.controlType ?? ''"
                            [theme]="multiSelectOptions.theme ?? ''" [isInverse]="multiSelectOptions.isInverse ?? false" [list]="uniqueMarsAuditIndustryItems"
                                [formControl]="msiMarsAuditIndustryItemsFormCtrl" (valueChanged)="marsMarsAuditIndustryChange()">
                            </dds-multi-select>
                        </div>
    
                        <div *ngIf="(msiMarsAuditIndustryItemsFormCtrl?.value?.length ?? 0) > 0"
                            class="multiselectddl col-md-7">
                            <span class="comments-text-label eclipse-small-header-grey">Selected values:</span>
                            <dds-tags>
                                <dds-tag class="tag-width" *ngFor="let item of msiMarsAuditIndustryItemsFormCtrl?.value"
                                    [theme]="multiOptions.theme ?? ''" [isRemovable]="multiOptions.isRemovable ?? false"
                                    [customClass]="multiOptions.customClass ?? ''" [size]="multiOptions.size ?? ''"
                                    [isError]="multiOptions.isError ?? false"
                                    [isInverse]="multiOptions.isInverse ?? false" (removed)="removeMarsAuditIndustry(item)"
                                    #tagItems>
                                    {{ item?.label }}
                                </dds-tag>
                            </dds-tags>
                        </div>
                    </div>
                </div>
            </ng-container>
        
            <div class="comment">
                <span class="input-label font-size-12">Comments</span>
                <dds-textarea [placeholder]="textareaOptions.placeholder ?? ''"
                    [(ngModel)]="dialogRoleModel.roleData.roleAssignmentComment" [minHeight]="textareaOptions.minHeight ?? 0"
                    [maxLength]="1000" [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
                    [size]="textareaOptions.size ?? ''"></dds-textarea>
            </div>
            <div class="col-md-12 margin-bottom-s">
                <span class="input-label font-size-12">Activation status</span>
                    <dds-toggle-group>
                        <dds-toggle [label]="toggleOptions.label ?? ''" [theme]="toggleOptions.theme ?? ''"
                            [(ngModel)]="dialogRoleModel.roleData.isActive" #toggle></dds-toggle>
                    </dds-toggle-group>
            </div>
            <div class="col-md-12 margin-bottom-s" *ngIf="actionType == 'Save'">
                <span class="eclipse-small-header-grey">Last modified {{dialogRoleModel.roleData.modifiedDate}} by
                    {{dialogRoleModel.roleData.modifiedByText}}</span>
            </div>
            <!-- #endregion Body -->
        </div>
        <div class="dds-modal__footer">
            <dds-button (click)="confirm()" [disabled]="!allRequiredFielsAreFilled()"
                class="dds-modal__footer-item custom-button">
                {{actionType}}
            </dds-button>
            <dds-button
            class="dds-modal__footer-item custom-button"
            kind="secondaryLoud"
            (click)="cancel()"
            >Cancel</dds-button>
        </div>
    </div>
</div>