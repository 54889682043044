<div
  class="eclipse-main-tab-header"
  *ngIf="
    tabTitle &&
    isPermissionPresent(
      permissionEnums.Intake_Opportunity_Title,
      permissionType.Visible
    )
  "
>
  Opportunity details
</div>

<div class="right-panel-container">
  <dds-accordion
    *ngIf="
      isPermissionPresent(
        permissionEnums.Intake_ServicesAndDelivery,
        permissionType.Visible
      )
    "
    [disabled]="accordionOptions.disabled"
    [isMulti]="accordionOptions.isMulti"
    [isOpen]="false"
    [isInverse]="accordionOptions.isInverse"
    [customClass]="accordionOptions.customClass"
    [size]="accordionOptions.size"
  >
    <dds-accordion-item>
      <span class="text-label eclipse-section-header" header
        >Services & Delivery Model</span
      >
      <div content>
        <app-service-delivery
          [summary]="false"
          [opportunityNumber]="opportunityNumber"
        ></app-service-delivery>
      </div>
    </dds-accordion-item>
  </dds-accordion>
  <!-- Details Section -->
  <div class="client-info margin-top-s">
    <div class="section">
      <dds-accordion
        [disabled]="accordionOptions.disabled"
        [isMulti]="accordionOptions.isMulti"
        [isOpen]="true"
        [isInverse]="accordionOptions.isInverse"
        [customClass]="accordionOptions.customClass"
        [size]="accordionOptions.size"
      >
        <dds-accordion-item>
          <span header>
            <div class="section-header">
              <span class="text-label eclipse-section-header"> Details </span>
            </div>
          </span>
          <span class="section-container1" content>
            <p
              *ngIf="
                isPermissionPresent(
                  permissionEnums.Intake_Opportunity_Description,
                  permissionType.Visible
                )
              "
              class="text-label sub-heading eclipse-content-grey details-sub-header"
            >
              Please review the following information received from Jupiter and
              make edits if the information shown on the screen is incorrect.
            </p>
            <div *ngIf="configLables.length > 0" class="section-container1">
              <app-opportunity-details-section
                [configLables]="configLables"
                [id]="opportunityNumber"
                [submissionData]="submissionDetails"
                (lepValueChange)="onLepValueChange($event)"
              ></app-opportunity-details-section>
            </div>
          </span>
        </dds-accordion-item>
      </dds-accordion>
    </div>
    <!--Complexity section-->
    <dds-accordion
      *ngIf="
        isPermissionPresent(
          permissionEnums.Intake_Opportunity_Complexity,
          permissionType.Visible
        )
      "
      [disabled]="accordionOptions.disabled"
      [isMulti]="accordionOptions.isMulti"
      [isOpen]="true"
      [isInverse]="accordionOptions.isInverse"
      [customClass]="accordionOptions.customClass"
      [size]="accordionOptions.size"
    >
      <dds-accordion-item>
        <span header>
          <div class="section-header">
            <span class="text-label eclipse-section-header heading-adjustment"
              >Opportunity Complexity
            </span>
          </div>
        </span>
        <span class="section-container1" content>
          <p
            *ngIf="
              isPermissionPresent(
                permissionEnums.Intake_Complexity_DescriptionGreyGuidance,
                permissionType.Visible
              )
            "
            class="text-label sub-heading eclipse-content-grey heading-adjustment"
          >
            Answer additional questions to provide further context to support
            the review.
          </p>
          <div *ngIf="configLables.length > 0" class="section-container1">
            <app-complexity-questions
              [configLables]="configLables"
              [submissionData]="submissionDetails"
              [OpportunityID]="opportunityNumber"
            ></app-complexity-questions>
          </div>
        </span>
      </dds-accordion-item>
    </dds-accordion>

    <!--Complexity Cyber Questions-->
    <div *ngIf="shouldShowOfferingQuestions()">
      <dds-accordion
        [disabled]="accordionOptions.disabled"
        [isMulti]="accordionOptions.isMulti"
        [isOpen]="true"
        [isInverse]="accordionOptions.isInverse"
        [customClass]="accordionOptions.customClass"
        [size]="accordionOptions.size"
      >
        <dds-accordion-item>
          <span header>
            <div class="section-header">
              <span class="text-label eclipse-section-header heading-adjustment"
                >Offering/Service Questions</span
              >
            </div>
          </span>
          <span class="section-container1" content>
            <div class="section-container1">
              <app-complexity-cyber-questions
                [OpportunityID]="opportunityNumber"
                [submissionData]="submissionDetails"
              ></app-complexity-cyber-questions>
            </div>
          </span>
        </dds-accordion-item>
      </dds-accordion>
    </div>
    <app-linked-opportunities
      *ngIf="
        isPermissionPresent(
          permissionEnums.Intake_Opportunity_LinkedOpportunities,
          permissionType.Visible
        )
      "
      [dataOpportunityId]="opportunityNumber"
    ></app-linked-opportunities>
  </div>
</div>
