<div *ngIf="docMetaData.length === 0" class="table-without-data">
  The attached documents will appear here
</div>

<div *ngIf="docMetaData.length > 0" class="table-with-data">
  <div class="toggle-data">
   
    <span>
      <dds-toggle-group
        [title]="groupOptions.title"
        [isInverse]="groupOptions.isInverse"
        [isRequired]="groupOptions.isRequired"
      >
        <dds-toggle
          [disabled]="options.disabled"
          [isProcessingStatusActive]="options.isProcessingStatusActive"
          [label]="options.label"
          [ariaLabel]="options.ariaLabel"
          [theme]="options.theme"
          [isError]="options.isError"
          [isRequired]="options.isRequired"
          [errorState]="options.errorState"
          [customClass]="options.customClass"
          (stateChanged)="stateChanged()"
          (processingStateActivated)="updateServerValue()"
          #toggle
        ></dds-toggle>
      </dds-toggle-group>
    </span>
  </div>
  <div class="div-headers">
    <table class="dds-data-table dds-data-table_selection">
      <thead class="dds-data-table__header header-style">
        <tr>
          <th
            *ngFor="let column of sampleColumns"
            class="dds-data-table__header-cell"
          >
            <div class="dds-data-table__content">
              <span>{{ column.header || column.name }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="dds-data-table__body">
        <tr *ngFor="let row of docToShow" class="dds-data-table__row">
          <td class="dds-data-table__cell">
            <div class="flex-container">
              <div
                *ngIf="row.submissionDocumentTypeId === 1"
                class="dds-card__image-block txt-Style"
              ></div>
              <div *ngIf="row.submissionDocumentTypeId === 2">
                <img src="\assets\dds-icons\linkIcon.svg" />
              </div>

              <div class="width100 long-title">
                <span [ddsTooltip]="row.documentTitle" class="margin-Style long-title">{{ row.documentTitle }}</span>
              </div>
            </div>
          </td>
          <td class="dds-data-table__cell padding-zero">
            <div
              *ngIf="row.comments"
              [ddsTooltip]="row.comments"
              class="dds-card__image-block msg-style"
            ></div>
          </td>
          <td class="dds-data-table__cell col-md-2">
            <span>{{ row.documentType }}</span>
          </td>
          <td class="dds-data-table__cell col-md-2">
            <span
            class="padding-status"
              [style.color]="transformStatus(row.documentStatus) | statusStyle : 'color'"
              [style.backgroundColor]="
                transformStatus(row.documentStatus) | statusStyle : 'backgroundColor'
              "
              >{{ row.documentStatus }}</span
            >
          </td>
          <td class="dds-data-table__cell">
            <span>{{ row.createdDate }}</span>
          </td>
          <td class="dds-data-table__cell">
            <span>{{ row.createdBy }}</span>
          </td>
          <td class="dds-data-table__cell">
            <div
              *ngIf="row.submissionDocumentTypeId === 1"
              class="dds-card__image-block action-items"
              (click)="onDownload(row)"
              [ddsTooltip]="'Download'"
            >
              <img src="\assets\dds-icons\download-icon.svg" />
            </div>
            <div
              *ngIf="row.submissionDocumentTypeId === 2"
              class="dds-card__image-block action-items"
              (click)="openLink(row.documentUrl)"
              [ddsTooltip]="'Open'"
            >
              <img src="\assets\dds-icons\openBlackIcon.svg" />
            </div>
            <div
              [ddsTooltip]="'Edit'"
              class="dds-card__image-block action-items"
              (click)="onEdit(row)"
            >
              <img src="\assets\dds-icons\pencil-icon.svg" />
            </div>
            <div
              [ddsTooltip]="'Delete'"
              class="dds-card__image-block action-items"
              (click)="onDelete(row.submissionDocumentId)"
            >
              <img src="\assets\images\dds-icons\dds-bin-icon.svg" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
