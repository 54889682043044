<progress-indicator></progress-indicator>
<div class="tiles-container">
  <div class="page-header">
    <h2 class="title inline-block font-size-32">External communications</h2>
    <span class="seperator inline-block"></span>
    <span [ddsTooltip]="communicationType" ddsFocus>
      <select
        class="role-dropdown"
        [(ngModel)]="communicationType"
        [ngStyle]="{ width: getRoleWidth() }"
        (ngModelChange)="getExternalCommunications()"
      >
        <option
          *ngFor="let item of communicationTypeList"
          [value]="item"
          [ngClass]="{ 'title inline-block font-size-32': true }"
        >
          {{ item }}
        </option>
      </select>
    </span>
  </div>
  <div class="d-flex">
    <div class="row padding-top-l">
      <div class="width-left-menu-panel">
        <div class="sidebar">
          <div class="nav-items">
            <div
              *ngFor="
                let item of submittedExternalCommunications;
                let i = index
              "
              class="nav-item"
              [class.active]="i === activeIndex"
              (click)="setActive(i)"
            >
              <span
                class="status-indicator"
                [ngClass]="
                  i === activeIndex
                    ? 'status-active-indicator'
                    : 'status-inactive-indicator'
                "
              ></span>
              <div class="nav-item-content" id="item.submissionId">
                <div class="icon-info-display">
                  <img src="\assets\images\file-icon.svg" alt="file img" />
                  <div>
                    <h4 [ddsTooltip]="item?.submissionTitle ?? ''" ddsFocus>
                      {{ item?.submissionTitle }}
                    </h4>
                    <div class="sub-info">
                      <span class="item-type">{{ extCommBoxValue }}</span>
                      <span
                        class="padding-xxs"
                        class="padding-xxs"
                        [style.color]="
                          transformStatus(item.submissionStatus)
                            | statusStyle : 'color'
                        "
                        [style.backgroundColor]="
                          transformStatus(item.submissionStatus)
                            | statusStyle : 'backgroundColor'
                        "
                        >{{ item.submissionStatus }}</span
                      >
                    </div>
                  </div>
                </div> 
                <span
                  *ngIf="!item.isFormCompleted"
                  class="draft-status"
                >
                  <img src="\assets\images\draftIcon.svg"
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="currentActiveSubmissionId"
      class="submission-detail left-border margin-top-m"
    >
      <app-external-communication-submission
        (submissionTitleChange)="onSubmissionTitleChange($event)"
        [submissionId]="currentActiveSubmissionId"
        [communicationType]="communicationType"
        [communicationTypeId]="communicationTypeId"
        (submitButtonStateChange)="onSubmitButtonStateChange($event)"
      ></app-external-communication-submission>
    </div>
  </div>
</div>
