import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IntakeOpportunitySummaryTeam } from '../../../common/models/opportunity-details.model';
import { SubmissionService } from '../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { MANAGE_TEAM_MEMBERS_BTN_OPTIONS } from './team-opportunity-summary-helper';
import { SHOW_MORE_BTN_OPTIONS } from '../../opportunity-details/submission-common-section/submission-common-section.helper';

@Component({
  selector: 'app-team-opportunity-summary',
  templateUrl: './team-opportunity-summary.component.html',
  styleUrl: './team-opportunity-summary.component.less',
})
export class TeamOpportunitySummaryComponent implements OnInit, OnDestroy {
  @Input() dataOpportunityId: string | null = '';

  teamMembers: string[] = [];
  visibleTeamMembers: string[] = [];
  showAll: boolean = false;
  showMoreOptions: any = SHOW_MORE_BTN_OPTIONS;
  lepName: string = '';

  unsubscriber$: Subject<any> = new Subject();

  options: ButtonOptions = MANAGE_TEAM_MEMBERS_BTN_OPTIONS;

  constructor(private submissionService: SubmissionService) {}

  ngOnInit(): void {
    this.getTeamData();
  }

  getTeamData(): void {
    this.submissionService
      .getOpportunitySummaryTeamData(
        this.dataOpportunityId ? this.dataOpportunityId : ''
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: IntakeOpportunitySummaryTeam) => {
          this.lepName = data.pursuitLeaderName;
          this.teamMembers = data.teamMembers.split(';');
          this.teamMembers.splice(this.teamMembers.length - 1);
          this.updateVisibleTeamMembers();
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  updateVisibleTeamMembers(): void {
    this.visibleTeamMembers = this.showAll
      ? this.teamMembers
      : this.teamMembers.slice(0, 2);
  }

  // TODO: show all functionality to be implemented in another story
  // toggleShowAll(): void {
  //   this.showAll = !this.showAll;
  //   this.updateVisibleTeamMembers();
  // }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
