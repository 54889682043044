import { Component, Input, OnInit } from '@angular/core';
import { ModalModule, ModalService } from '@usitsdasdesign/dds-ng/modal';
import { WatchlistStatus } from '../../http/watchlist/watchlist-api.service';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrl: './show-more.component.less'
})
export class ShowMoreComponent {

  @Input() labelValues: any;
  public WatchlistStatus = WatchlistStatus;

  constructor(
    private modalService: ModalService
  ) {
  }

  closeAll() {
    this.modalService.closeAll();
  }

  close() {
    this.modalService.close();
  }

  cancel() {
    this.modalService.close();
  }
}
