import { IDisplaySaveInfo } from "./display-save/display-save-info";
import { AutosaverGlobalOptions } from "./autosaver-global-options";
import { BehaviorManager } from "../services/BehaviorManager";


export class Saver implements IDisplaySaveInfo {
    constructor(
        private modelName: string,
        private context: any,
        private saveMethod: (model: any) => Promise<any>,
        private options: IAutosaverOptions,
        private globalOptions: AutosaverGlobalOptions,
        // private tnpDataService: TopNavigationPanelService,
    ) {
        this.isSaving.setValue(false);
    }

    private isSavingEnable = false; //Saver option to 'enable' saving data to server.
    private timerId: any = null;
    private isModelDirty = false;

    public isSaving: BehaviorManager<boolean> = new BehaviorManager<boolean>();
    public savedTimeStamp?: Date;

    start() {
        this.isSavingEnable = true;
        return this;
    }

    stop() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }

        this.globalOptions.numberOfSavers = 0;

        this.isSavingEnable = false;

        return self;
    }

    save() {
        if (!this.isSavingEnable) {
            return;
        }

        if (this.timerId) {
            if (this.globalOptions.numberOfSavers > 0) {
                this.globalOptions.numberOfSavers -= 1;
            }
            clearTimeout(this.timerId);//Cost little performance but without memory leak. Another solution situated bottom.
            this.timerId = null;
        }
        this.globalOptions.numberOfSavers++; //Set +1 to specify when data should be saved.

        this.timerId = setTimeout(() => this.saveToServer(), this.options.delay || 0);
    }


    saveNow() {
        if (this.timerId) {
            if (this.globalOptions.numberOfSavers > 0) {
                this.globalOptions.numberOfSavers -= 1;
            }
            clearTimeout(this.timerId);
            this.timerId = null;
        }

        this.globalOptions.numberOfSavers++; //Set +1 to specify when data should be saved.
        return this.saveToServer();
    }

    private saveToServer() {
        this.isSaving.setValue(true);
        if (this.options.onAutoSaveStarts)
            this.options.onAutoSaveStarts(this);
        return this.saveMethod(this.context[this.modelName])
            .then((result) => {
                this.isSaving.setValue(false);
                this.savedTimeStamp = new Date();

              if (this.options.onSuccess) {
                let r = result || {};
                this.options.onSuccess(this, r.data || result || null);
              }

                if (this.globalOptions.numberOfSavers > 0) {
                    this.globalOptions.numberOfSavers--;
                }

                return result;
                
            }, (error) => {
                console.log('error autosaver.');
                this.isSaving.setValue(false);
                if (this.options.onError) {
                    this.options.onError(this, error.data);
                }
            });
    }

}

export class AutosaverOptions implements IAutosaverOptions {
    constructor(
        public delay: number = 1900,
        public onError: (saver: Saver, error: any) => void,
        public onSuccess: (saver: Saver, result: any) => string,
        public onAutoSaveStarts: (saver: Saver) => void,
        public reloadTnpOnSuccess: boolean = false
    ) { }
}

export interface IAutosaverOptions {
    delay: number,
    onError: (saver: Saver, error: any) => void,
    onSuccess: (saver: Saver, result: any) => string,
    onAutoSaveStarts: (saver: Saver) => void,
    reloadTnpOnSuccess: boolean
}
