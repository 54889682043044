<div class="nca-container relative text-align-left">
  <div class="row flex-container d-flex">
    <div class="col-8 padding-right-none">
      <h2 class="client-title-header font-size-24">
        Application Administration Page
      </h2>
    </div>
    <div class="padding-left-s col-4 text-align-right">
      <dds-button
        [theme]="options.theme ?? ''"
        [kind]="options.kind ?? ''"
        [size]="options.size ?? ''"
        [width]="options.width ?? ''"
        [icon]="options.icon ?? ''"
        [isIconLeft]="options.isIconLeft ?? false"
        [role]="options.role ?? ''"
        [isInverse]="options.isInverse ?? false"
        [isColorBg]="options.isColorBg ?? false"
        [isLoading]="options.isLoading ?? false"
        [ariaLabel]="options.ariaLabel ?? ''"
        [customClass]="options.customClass ?? ''"
        [disabled]="options.disabled ?? false"
        (clicked)="refreshPage()"
        >{{ label }}</dds-button
      >
    </div>
  </div>
</div>

<div class="row padding-top-xl width26pt no-wrap">
  <div class="col-3 tabsection width-left-menu-panel width20pt">
    <div class="sidebar">
      <dds-vertical-tabs
        [theme]="tabContainerOptions.theme ?? ''"
        [themeType]="tabContainerOptions.themeType ?? ''"
        [size]="tabContainerOptions.size ?? ''"
        [ariaLabel]="tabContainerOptions.ariaLabel ?? ''"
        [customClass]="tabContainerOptions.customClass ?? ''"
        [isInverse]="tabContainerOptions.isInverse ?? false"
        [disabled]="tabContainerOptions.disabled ?? false"
      >
        <dds-vertical-tab
          [label]="verticalTabOptionsOne.label ?? ''"
          [counter]="verticalTabOptionsOne.counter ?? 0"
          [icon]="verticalTabOptionsOne.icon ?? ''"
          [disabled]="verticalTabOptionsOne.disabled ?? false"
          [customClass]="verticalTabOptionsOne.customClass ?? ''"
          [isActive]="verticalTabOptionsOne.isActive ?? false"
          (tabChanged)="tabChanged('global configuration')"
        >
        </dds-vertical-tab>

        <dds-vertical-tab
          [label]="verticalTabOptionsTwo.label ?? ''"
          (tabChanged)="tabChanged('roles list')"
          [icon]="verticalTabOptionsTwo.icon ?? ''"
          [isActive]="verticalTabOptionsTwo.isActive ?? false"
        >
        </dds-vertical-tab>

        <dds-vertical-tab
          [label]="verticalTabOptionsThree.label ?? ''"
          (tabChanged)="tabChanged('tables')"
          [icon]="verticalTabOptionsThree.icon ?? ''"
          [isActive]="verticalTabOptionsThree.isActive ?? false"
        >
        </dds-vertical-tab>
      </dds-vertical-tabs>
    </div>
  </div>
  <div class="row col-9 client-status width80pt">
    <app-global-configuration
      *ngIf="verticalTabOptionsOne.isActive"
      (tabChange)="handleTabChange($event)"
    ></app-global-configuration>
    <app-admin-roles *ngIf="verticalTabOptionsTwo.isActive">      
    </app-admin-roles>
    <app-admin-tables
      *ngIf="verticalTabOptionsThree.isActive"
    ></app-admin-tables>
  </div>
</div>

 