<div class="margin-top-xxs relative">
  <label
    *ngIf="showLabel"
    class="label comments-text-label eclipse-small-header-grey"
    >{{ labelText }}</label
  >
  <div class="search-box" *ngIf="!showSelected">
    <dds-search
      [size]="searchOptions.size ?? emptyString"
      [customClass]="searchOptions.customClass ?? emptyString"
      [icon]="searchOptions.icon || 'dds-icon_search'"
      (fieldCleared)="fieldCleared()"
    >
      <input
        ddsSearch
        [placeholder]="searchOptions.placeholder ?? emptyString"
        [(ngModel)]="searchedUser"
        (ngModelChange)="findUsers($event)"
      />
    </dds-search>
  </div>
  <div
    *ngIf="showdropDown"
    class="list-of-oppportunities d-flex flex-column padding-xs margin-top-xs absolute box-shadow-xs">
    <div *ngIf="userList.length > 0; else noUsers">
      <div *ngFor="let item of userList">
        <div
          class="opportunity-items d-flex flex-column"
          (click)="fetchUserDetails(item)">
          <div class="opportunity-details text-align-left" >
            <span class="opportunity-details-name"
              >{{ item?.displayName ?? item?.fullName ?? item?.outlookDisplayName }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noUsers>
      <div class="no-users-found">
        {{ notFound }}
      </div>
    </ng-template>
  </div>
</div>
