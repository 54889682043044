import { ToastOptions } from "@usitsdasdesign/dds-ng/toast";
import { IntakeConstant } from "../../../constants/intake.constant";
import { Themes } from "@usitsdasdesign/dds-ng/shared";

export const ToastMessageOptions: ToastOptions = {
  title: IntakeConstant.EMPTY_SPACE,
  message: IntakeConstant.EMPTY_SPACE,
  lifeTime: 3000,
  withProgressBar: false,
  position: 'bottom-center',
  isCloseIcon: false,
  customClass: 'toast-align',
  isError: false,
  isInverse: false,
  isLoading: false,
  limit: 5,
  theme: Themes.dark,
};