import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { jwtDecode } from "jwt-decode";
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, AccountInfo } from '@azure/msal-browser';
import { TokenService } from '../http/token.service';
import { Observable, from, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, finalize, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpStatus } from '../http/httpStatus';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private tokenRefreshInProgress = false;
  private tokenRefreshedSource = new BehaviorSubject<string | null>(null);
  private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private msalService: MsalService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = localStorage.getItem('idToken');

    if (req.url.startsWith('https://graph.microsoft.com')||req.url.includes('s3.amazonaws.com')) {
      return next.handle(req);
    }

    if (authToken && this.isTokenExpired(authToken)) {
      if (this.tokenRefreshInProgress) {
        return this.tokenRefreshed$.pipe(
          filter(token => token !== null),
          take(1),
          switchMap((newAuthToken: string | null) => {
            const authReq = req.clone({
              setHeaders: {
                'Authorization': `Bearer ${newAuthToken}`
              }
            });
            return next.handle(authReq);
          })
        );
      } else {
        this.tokenRefreshInProgress = true;
        const account = this.msalService.instance.getAllAccounts()[0];
        if (!account) {
          console.error('No active account found, redirecting to login');
          this.msalService.loginRedirect();
          return throwError(() => new Error('No active account found'));
        }

        // Token is expired, try to acquire a new token silently
        return from(this.msalService.acquireTokenSilent({
          scopes: ['openid', 'profile'], // Default scopes
          account: account
        })).pipe(
          switchMap((response: AuthenticationResult) => {
            const newIdToken = response.idToken;
            const newAccessToken = response.accessToken;
         localStorage.setItem("Acccess_Token_User", newAccessToken);
         localStorage.setItem("accessToken",newAccessToken); 
         localStorage.setItem("idToken",newIdToken); 
         localStorage.setItem('isAuthorized', "true");
         localStorage.setItem("fromtoken","true");
        this.tokenRefreshedSource.next(newIdToken); // Notify queued requests
            this.tokenRefreshInProgress = false;

            const authReq = req.clone({
              setHeaders: {
                'Authorization': `Bearer ${newIdToken}`
              }
            });

            return next.handle(authReq);
          }),
          catchError((error: any) => {
            console.error('Silent token acquisition failed, redirecting to login', error);
            this.tokenRefreshedSource.next(null); // Notify queued requests
            this.tokenRefreshInProgress = false;
            this.msalService.loginRedirect();
            return throwError(() => error);
          }),
          finalize(() => {
            this.tokenRefreshInProgress = false;
          })
        );
      }
    } else {
      // Token is valid, proceed with the request
      const authReq = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${authToken}`
        }
      });

      return next.handle(authReq).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === HttpStatus.UNAUTHORIZED) {
            console.error('Unauthorized request', error);
          }
          else if (error.status === HttpStatus.FORBIDDEN) {
            console.error('Forbidden request', error);
            this.router.navigate(['/error']);
          }
          return throwError(() => error);
        })
      );
    }
  }

  isTokenExpired(token: string): boolean {
    const decodedToken = this.decodeToken(token);
    if (!decodedToken) {
      return true;
    }
    const expirationDate = decodedToken.exp * 1000;
    return Date.now() > expirationDate;
  }

  decodeToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Invalid token', error);
      return null;
    }
  }

}