import { ProgressIndicatorService } from './../../common/services/progress-indicator.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  closeBtnOptions,
  primaryButton,
  secondaryButton,
  spinnerOptions,
} from '../global-engagement.helper';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import {
  ButtonKind,
  ExtThemes,
  Size,
  TabThemeType,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { Router } from '@angular/router';
import { GlobalEngagementService } from '../../http/global-engagement/global-engagement.service';
import {
  ExistingEnagementModel,
  ExistingEngagementList,
  GlobalEngagementDeliveryCenterModel,
  GlobalEngagementMemberFirmModel,
  GlobalEngagementSubmissionResponseModel,
  GlobalEngagementSubmissionTypeModel,
} from '../../common/models/global-engagement.model';
import {
  OpportunityDetails,
  OpportunityList,
} from '../../common/models/opportunity-details.model';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { GlobalEngagementsConstant } from '../constants/global-engagements.constants';
import { SubmissionService } from '../../http/intake/submission.service';
import {
  opportunityTableColumns,
  engagementTableColumns,
} from './initiate-global-engagements.helper';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { HttpErrorResponse } from '@angular/common/http';
import { TabsOptions } from '@usitsdasdesign/dds-ng/tabs';
import { tabContainerOptions } from '../global-engagement/global-eng-details/global-eng-details.helper';
import { PermissionCheckService } from '../../common/validations/permission-check.service';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { PermissionsObj } from '../../common/models/common-models';

@Component({
  selector: 'app-initiate-global-engagements',
  templateUrl: './initiate-global-engagements.component.html',
  styleUrl: './initiate-global-engagements.component.less',
})
export class InitiateGlobalEngagementsComponent implements OnInit, OnDestroy {
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  memberfirmOtherOption = GlobalEngagementsConstant.memberFirmOther;
  primaryButton = primaryButton;
  secondaryButton = secondaryButton;
  submissionType!: number;
  engagementType: string = IntakeConstant.EMPTY_SPACE;
  deliveryCenter!: number;
  memberFirm!: number;
  opportunityNumber: string = IntakeConstant.EMPTY_SPACE;
  engagementID!: number;
  submissionTypeList: SelectItemOptions[] = [];
  deliveryCenterList: SelectItemOptions[] = [];
  memberFirmList: SelectItemOptions[] = [];
  createNewLabel: string = GlobalEngagementsConstant.CREATE_NEW_LABEL;
  selectExistingLabel: string = GlobalEngagementsConstant.SELECT_EXISTING_LABEL;
  showCreateNewDiv: boolean = false;
  showSelectExistingDiv: boolean = false;
  otherFirmName: string = IntakeConstant.EMPTY_SPACE;
  clientName: string = IntakeConstant.EMPTY_SPACE;
  selectedOportunity: string = IntakeConstant.EMPTY_SPACE;
  totalCount: number = 0;
  pageNumber: number = 1;
  notFound: string = IntakeConstant.EMPTY_SPACE;
  opportunityList: any[] = [];
  pageSize: number = GlobalEngagementsConstant.PAGE_NUMBER;
  opportunityTableColumns = opportunityTableColumns;
  engagementTableColumns = engagementTableColumns;
  nonChangeOrder: string = GlobalEngagementsConstant.NON_CHANGE_ORDER;
  tooltipWidth: number = GlobalEngagementsConstant.TOOLTIP_WIDTH;
  showOpportunityDetails: boolean = false;
  showSelectOpportunity: boolean = false;
  showClientDetails: boolean = false;
  addClientManually: boolean = false;
  clientNumber!: number | null;
  public opportunityDetailsList: any[] = [];
  public defaultEmptyFields: string = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
  closeBtnOptions = closeBtnOptions;
  totalOpportunityCount: number = 0;
  activeOpportunityCount: number = 0;
  selectedTabCode: string = 'active';
  selectTabIndex:number = 0;
  tabContainerOptions = tabContainerOptions;

  public tabs = [
    {
      label: 'Active',
      code: 'active',
      count: this.activeOpportunityCount,
    },
    {
      label: 'All opportunities',
      code: 'all',
      count: this.totalOpportunityCount,
    },
  ];
  permissionObj: PermissionsObj = {} as PermissionsObj;

  private readonly searchOpportunityQuery$: Subject<string> =
    new Subject<string>();
  showDropdown: boolean = false;
  isSpinnerVisible: boolean = false;
  constructor(
    private readonly router: Router,
    private readonly globalEngagementService: GlobalEngagementService,
    private readonly submissionService: SubmissionService,
    private readonly ProgressIndicatorService: ProgressIndicatorService,
    private readonly permissionCheck: PermissionCheckService,
    private readonly securityWebapiService: SecurityWebapiService
  ) {}

  spinnerOptions = spinnerOptions;

  ngOnInit(): void {
    this.permissionObj = this.permissionCheck.getGlobalEngPermissionObj;

    this.getSubmissionType();
    this.searchOpportunityQuery$
      .pipe(
        debounceTime(IntakeConstant.SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        filter(
          (key) => !!key && key.length > IntakeConstant.SEARCH_STRING_LIMIT
        ),
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.fetchOpportunitySearchResult(1, this.selectedTabCode);
      });
  }

  onCancel(): void {
    this.router.navigate(['/']);
  }

  getSubmissionType(): void {
    this.globalEngagementService
      .getSubmissionTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: GlobalEngagementSubmissionTypeModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: GlobalEngagementSubmissionTypeModel) => ({
                value: item.submissionTypeId,
                heading: item.submissionTypeName,
              })
            );
            this.submissionTypeList = convertedArray;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getDeliveryCenter(): void {
    this.globalEngagementService
      .getDeliveryCenter()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: GlobalEngagementDeliveryCenterModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: GlobalEngagementDeliveryCenterModel) => ({
                value: item.deliveryCenterId,
                heading: item.deliveryCenterName,
              })
            );
            this.deliveryCenterList = convertedArray;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getMemberFirmDetails(): void {
    this.globalEngagementService
      .getMemberFirmDetails()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: GlobalEngagementMemberFirmModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: GlobalEngagementMemberFirmModel) => ({
                value: item.memberFirmId,
                heading: item.memberFirmName,
              })
            );
            this.memberFirmList = convertedArray;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  onEngagementTypeChange(event: string): void {
    this.notFound = IntakeConstant.EMPTY_SPACE;
    this.opportunityList = [];
    this.totalCount = 0;
    this.showDropdown = false;
    if (event === this.createNewLabel) {
      this.getDeliveryCenter();
      this.getMemberFirmDetails();
      this.showCreateNewDiv = true;
      this.showSelectExistingDiv = false;
      this.notFound = IntakeConstant.EMPTY_SPACE;
      this.opportunityDetailsList = [];
      this.totalCount = 0;
      this.showDropdown = false;
      this.showOpportunityDetails = false;
      this.selectedOportunity = IntakeConstant.EMPTY_SPACE;
      this.opportunityList = [];
    } else if (event === this.selectExistingLabel) {
      this.showCreateNewDiv = false;
      this.showSelectExistingDiv = true;
      this.notFound = IntakeConstant.EMPTY_SPACE;
      this.opportunityList = [];
      this.totalCount = 0;
      this.showDropdown = false;
      this.showOpportunityDetails = false;
      this.selectedOportunity = IntakeConstant.EMPTY_SPACE;
      this.opportunityDetailsList = [];
    }
  }

  removeOpportunity(): void {
    this.showOpportunityDetails = false;
    this.selectedOportunity = IntakeConstant.EMPTY_SPACE;
    this.opportunityList = [];
    this.opportunityDetailsList = [];
    this.showDropdown = false;
    this.opportunityDetailsList = [];
  }

  isSubmitDisabled(): boolean {
    if (!this.submissionType) {
      return false;
    }
    if (
      !this.engagementType ||
      this.engagementType.trim() === IntakeConstant.EMPTY_SPACE
    ) {
      return false;
    }
    if (this.engagementType === this.createNewLabel) {
      if (!this.deliveryCenter) {
        return false;
      }
      if (!this.memberFirm) {
        return false;
      }
      if (
        this.memberFirm === GlobalEngagementsConstant.memberFirmOther &&
        (!this.otherFirmName ||
          this.otherFirmName.trim() === IntakeConstant.EMPTY_SPACE)
      ) {
        return false;
      }
      if (this.showClientDetails) {
        if (this.addClientManually) {
          if (
            !this.clientName ||
            this.clientName.trim() === IntakeConstant.EMPTY_SPACE
          ) {
            return false;
          }
        } else {
          if (!this.clientNumber) {
            return false;
          }
        }
      } else {
        if (
          !this.opportunityDetailsList ||
          this.opportunityDetailsList.length === 0
        ) {
          return false;
        }
      }
      return true;
    }
    if (this.engagementType === this.selectExistingLabel) {
      if (
        !this.opportunityDetailsList ||
        this.opportunityDetailsList.length === 0
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  fieldCleared(): void {
    this.opportunityList = [];
    this.selectedOportunity = IntakeConstant.EMPTY_SPACE;
    this.showDropdown = false;
    this.notFound = IntakeConstant.EMPTY_SPACE;
  }

  selectOpportunityChanged(event: string): void {
    this.totalCount = 0;
    this.pageNumber = 1;
    if (event.length > 2 && event !== IntakeConstant.EMPTY_SPACE) {
      this.searchOpportunityQuery$.next(event);
    } else {
      this.notFound = IntakeConstant.EMPTY_SPACE;
      this.opportunityList = [];
      this.totalCount = 0;
      this.showDropdown = false;
    }
  }

  fetchOpportunitySearchResult(pageNumber: number, tabCode: string): void {
    this.isSpinnerVisible = true;
    if (this.engagementType === this.createNewLabel) {
      this.submissionService
        .getOpportunityDetails(
          this.selectedOportunity,
          pageNumber,
          this.pageSize,
          tabCode
        )
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (opportunity: OpportunityList) => {
            if (opportunity?.totalOpportunityCount > 0) {
              this.notFound = IntakeConstant.EMPTY_SPACE;
              if (pageNumber === 1) {
                this.opportunityList = opportunity?.opportunities;
              } else {
                this.opportunityList = [
                  ...this.opportunityList,
                  ...opportunity?.opportunities,
                ];
              }
              this.totalCount = opportunity?.totalOpportunityCount;
              this.tabs[0].count = opportunity?.activeOpportunityCount;
              this.tabs[1].count = opportunity?.totalOpportunityCount;
              if(opportunity.activeOpportunityCount == 0){
                if(this.selectTabIndex == 0){
                  this.selectTabIndex = 1;
                 this.tabChanged(this.tabs[1].code);
                } 
              }else {
                if(this.selectTabIndex == 1){
                  this.selectTabIndex = 0;
                  this.tabChanged(this.tabs[0].code);
                }
              }
              if (this.opportunityList.length > 0) {
                this.showDropdown = true;
              }
            } else {
              this.notFound = 'Not Found';
              this.opportunityList = [];
              this.totalCount = 0;
              this.showDropdown = false;
            }
            this.isSpinnerVisible = false;
          },
          error: (err: HttpErrorResponse) => {
            this.isSpinnerVisible = false;
            console.error('Error fetching submission types', err);
          },
        });
    } else {
      const requestBody: any = {
        SearchTerm: this.selectedOportunity,
        PageNumber: pageNumber,
        PageSize: this.pageSize,
      };

      this.globalEngagementService
        .getExistingEnagementDetails(requestBody)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (opportunity: ExistingEngagementList) => {
            if (opportunity.totalCount > 0) {
              this.notFound = IntakeConstant.EMPTY_SPACE;
              if (pageNumber === 1) {
                this.opportunityList = opportunity?.data;
              } else {
                this.opportunityList = [
                  ...this.opportunityList,
                  ...opportunity?.data,
                ];
              }
              this.totalCount = opportunity?.totalCount;
              if (this.opportunityList.length > 0) {
                this.showDropdown = true;
              }
            } else {
              this.notFound = 'Not Found';
              this.opportunityList = [];
              this.totalCount = 0;
              this.showDropdown = false;
            }
            this.isSpinnerVisible = false;
          },
          error: (err: HttpErrorResponse) => {
            this.isSpinnerVisible = false;
            console.error('Error fetching submission types', err);
          },
        });
    }
  }

  fetchOpportunitySearchResultMore(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      if (this.pageNumber < this.totalCount / this.pageSize) {
        this.pageNumber++;
        this.fetchOpportunitySearchResult(
          this.pageNumber,
          this.selectedTabCode
        );
      }
    }
  }

  getNumberOfSplits(fetchType: string): string {
    let splits = IntakeConstant.EMPTY_SPACE;
    for (let offering of this.opportunityDetailsList[0]?.offering) {
      splits += `${offering} \n`;
    }
    return 'true';
  }

  fetchOpportunityDetails(
    opportunity: OpportunityDetails,
    fetchType: string
  ): void {
    this.opportunityList = [];
    this.showDropdown = false;
    this.showOpportunityDetails = true;
    this.opportunityDetailsList = [opportunity];
    this.opportunityNumber = this.opportunityDetailsList[0]?.opportunityNumber;
    this.selectedOportunity = IntakeConstant.EMPTY_SPACE;
  }

  notAvailabeStateChanged(event: boolean): void {
    if (event) {
      this.showClientDetails = true;
      this.opportunityDetailsList = [];
    } else {
      this.showClientDetails = false;
    }
  }

  addManuallyChecked(event: boolean) {
    if (event) {
      this.addClientManually = true;
      this.clientNumber = null;
    } else {
      this.addClientManually = false;
    }
  }

  onSubmit(): void {
    this.ProgressIndicatorService.show();
    if (this.engagementType === this.createNewLabel) {
      const requestBody = {
        SubmissionTypeId: this.submissionType,
        DeliveryCenterId: this.deliveryCenter,
        MemberFirmId: this.memberFirm,
        MemberFirmOther: this.otherFirmName ? this.otherFirmName : null,
        OpportunityNumber: this.opportunityDetailsList[0]?.opportunityNumber,
        ClientNumber: this.clientNumber ? this.clientNumber : null,
        ClientName: this.clientName ? this.clientName : null,
        EngagementId: 0,
      };

      this.globalEngagementService
        .postInitiateGlobalEngagement(requestBody)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (res: GlobalEngagementSubmissionResponseModel) => {
            if (res) {
              this.router.navigate(
                ['globalEngagements/details', res.engagementId],
                {
                  state: { submissionId: res.engagementId },
                }
              );
            }
            this.ProgressIndicatorService.hide();
          },
          error: (err: Error) => {
            console.error('Error initiating external communication', err);
          },
        });
    } else {
      const requestBody = {
        SubmissionTypeId: this.submissionType,
        OpportunityNumber: this.opportunityDetailsList[0]?.jupiterOpportunityID,
        EngagementId: this.opportunityDetailsList[0]?.engagementID,
      };

      this.globalEngagementService
        .postInitiateGlobalEngagement(requestBody)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (res: GlobalEngagementSubmissionResponseModel) => {
            if (res) {
              this.router.navigate(
                ['globalEngagements/details', res.engagementId],
                {
                  state: { submissionId: res.engagementId },
                }
              );
            }
            this.ProgressIndicatorService.hide();
          },
          error: (err: Error) => {
            console.error('Error initiating external communication', err);
          },
        });
    }
  }

  tabChanged(event: string): void {
    this.selectedTabCode = event;
    this.fetchOpportunitySearchResult(1, this.selectedTabCode);
  }

  clientSearched(clientNumber: number): void {
    this.clientNumber = clientNumber;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
