import { Component, HostListener, Input } from '@angular/core';
import { DocumentService } from '../../../../http/document/document.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { CommonHelperService } from '../../../../common/services/common-helper.service';
import { GlobalEngagementService } from '../../../../http/global-engagement/global-engagement.service';
import { Subject, takeUntil } from 'rxjs';
import {
  SelectItemOptions,
  SelectOptions,
} from '@usitsdasdesign/dds-ng/select';
import { IntakeConstant } from '../../../../intake/constants/intake.constant';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import {  UploadFile } from '@usitsdasdesign/dds-ng/uploader';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { NotifyPopupComponent } from '../../../../common/notify-popup/notify-popup.component';
import {
  GlobalDocumentStatusModel,
  GlobalDocumentTypeModel,
} from '../../../../common/models/global-engagement.model';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalEngagementsConstant } from '../../../constants/global-engagements.constants';
import {
  DocumentColumns,
  options,
  selectDocumentTypeOptions,
  selectOptionsStatus,
  textFileOptions,
  UploaderAreaOptions,
} from '../../../global-engagement.helper';

@Component({
  selector: 'app-engagement-document-upload',
  templateUrl: './engagement-document-upload.component.html',
  styleUrl: './engagement-document-upload.component.less',
})
export class EngagementDocumentUploadComponent {
  constructor(
    private readonly globalService: GlobalEngagementService,
    private readonly docService: DocumentService,
    private readonly modalService: ModalService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly commonHelperService: CommonHelperService
  ) {}
  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  displayStyle: any = 'none';
  fileName: any = GlobalEngagementsConstant.EMPTY_STRING;
  @Input() public documentInitiatedDetails: any = {};
  filePath: any = GlobalEngagementsConstant.EMPTY_STRING;
  uploadFileSourceList: any = [];
  maxFileLength: any = 5;
  notifyReason: any = GlobalEngagementsConstant.EMPTY_STRING;
  uploadRequestList: any = [];
  maxFileSizeAllowed: any = 50 * 1024 * 1024; // 50 MB in bytes
  maxFileSizeAllowedUpto: any = 100 * 1024 * 1024; // 100 MB in bytes
  isFileSize100MBExceed: boolean = false;
  validFileExtensions: any =
    'doc,docx,msg,pptx,pdf,ppt,xls,xlsb,xlsx,xlsm,jpg,jpeg,png,zip'; //Valid File Extensions
  fileSourceList: any = [];
  removeUploadedDocumentFromList: any = [];
  fileUploadPercentage: any = GlobalEngagementsConstant.EMPTY_STRING;
  isEncrypted: boolean = true;
  isUploadingInProgress: boolean = false;
  isFileUploaded: boolean = false;
  isDocumentExists: boolean = false;
  documentUploadData: any = [];
  documentAllSectionsMetaData: any = [];
  documentUploadInProgressData: any = [];
  @Input() isFooter: boolean = false;
  @Input() size: string = GlobalEngagementsConstant.EMPTY_STRING;
  @Input() isInverse: boolean = false;
  isEditing: boolean = false;
  documentTypeItemsList: SelectItemOptions[] = [];
  documentStatusItemList: SelectItemOptions[] = [];
  allStatusAvailable: SelectItemOptions[] = [];
  otherTypeSelected: number = GlobalEngagementsConstant.otherType;

  uploadAreaOptions = UploaderAreaOptions;
  textFileOptions: TextareaOptions = {
    placeholder: GlobalEngagementsConstant.EMPTY_STRING,
    minHeight: 100,
    maxLength: 400,
    maxLengthPosition: 'bottom-right',
    size: IntakeConstant.UI_ELEMENT_SIZE,
  };
  textareaOptions = textFileOptions;
  selectDocumentTypeOptions = selectDocumentTypeOptions;

  selectOptionsStatus = selectOptionsStatus;

  options = options;
  public DocumentColumns = DocumentColumns;

  ngOnInit(): void {
    this.getDocumentTypeDropdownData(
      this.documentInitiatedDetails.submissionTypeId
    );
    this.getDocumentStatusDropdownData();

    this.filePath = this.commonHelperService.getDocumentFolderPath(
      this.documentInitiatedDetails.moduleName
    );

    this.uploadedItems(this.documentInitiatedDetails.file);
  }

  getDocumentStatusDropdownData(): void {
    this.globalService.getGlobalDocumentStatus().subscribe({
      next: (result: GlobalDocumentStatusModel[]) => {
        let convertedArray = result.map((item: GlobalDocumentStatusModel) => ({
          value: item.documentStatusId,
          heading: item.documentStatusName,
        }));

        this.documentStatusItemList = convertedArray;
        this.allStatusAvailable = convertedArray;
      },
      error: (err: HttpErrorResponse) => {
        this.progressIndicator.hide();
      },
    });
  }

  getDocumentTypeDropdownData(id: number): void {
    this.globalService.getGlobalDocumentType(id).subscribe({
      next: (result: GlobalDocumentTypeModel[]) => {
        const convertedArray = result.map((item: GlobalDocumentTypeModel) => ({
          value: item.documentTypeId,
          heading: item.documentTypeName,
        }));
        this.documentTypeItemsList = convertedArray;
      },
      error: (err: HttpErrorResponse) => {
        this.progressIndicator.hide();
      },
    });
  }

  removeDocument(row: any): void {
    const index = this.documentUploadData.indexOf(row);
    if (index === -1) {
      return;
    }
    this.documentUploadData.splice(index, 1);
    this.fileSourceList = this.fileSourceList.filter(
      (element: any, index: any) =>
        this.removeUploadedDocumentFromList.indexOf(element.fileName) === -1
    );
    this.loadFileSourceData(this.fileSourceList);
  }

  async onSave(): Promise<void> {
    try {
      this.progressIndicator.show();
      await this.UploadFiletoS3();
      await this.saveDocumentMetaData();
      this.progressIndicator.hide();
    } catch (error) {
      console.error(error);
    }
    this.closeModal();
  }

  async saveDocumentMetaData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.globalService
        .saveGlobalDocument(this.documentUploadData)
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe({
          next: (response: any) => {
            resolve();
          },
          error: (err) => {
            console.error('An error occurred during metadata save:', err);
            reject(err);
          },
        });
    });
  }

  isSaveButtonDisabled(): boolean {
    if (this.documentUploadData.length === 0) {
      return false;
    }
    let tempData = this.documentUploadData.some(
      (obj: any) =>
        obj.documentTitle.length == 0 ||
        obj.documentTypeId.toString() == 0 ||
        obj.documentStatusId.toString() == 0
    );

    let optionalSelected = this.documentUploadData.some((obj: any) => {
      if (
        obj.documentTypeId === this.otherTypeSelected &&
        obj.comments.length === 0
      ) {
        return true;
      }
      return false;
    });
    return tempData || optionalSelected ? false : true;
  }

  loadFileSourceList(): void {
    this.uploadFileSourceList = [];
    this.documentUploadInProgressData = [];
    this.uploadRequestList = [];
    this.fileSourceList.forEach((element: any) => {
      this.uploadFileSourceList.push({ fileSource: element.file });
      this.documentUploadInProgressData.push({ fileName: element.file.name });
      let uploadRequest = {
        FileFolderPath: this.filePath,
        DocumentName: element.file.name,
      };

      this.uploadRequestList.push(uploadRequest);
      this.documentUploadData.push({
        submissionId: this.documentInitiatedDetails.submissionId,
        documentTypeId: 0,
        documentStatusId: 0,
        submissionDocumentTypeId: 1,
        documentTitle: element.file.name,
        documentUrl: GlobalEngagementsConstant.EMPTY_STRING,
        comments: GlobalEngagementsConstant.EMPTY_STRING,
      });
    });
    this.updateProgressBar('PresignedURLUpdated');
  }

  UploadDraggedAndSelectedDocument(): void {
    this.updateProgressBar(GlobalEngagementsConstant.validationConstant);
    this.loadFileSourceList();
    this.updateProgressBar(GlobalEngagementsConstant.uploaded);
  }

  updateProgressBar(event: any): void {
    if (event == GlobalEngagementsConstant.validationConstant) {
      this.fileUploadPercentage = '50';
    } else if (event == GlobalEngagementsConstant.PresignedURL) {
      this.fileUploadPercentage = '75';
    } else if (event == GlobalEngagementsConstant.PresignedURLUpdated) {
      this.fileUploadPercentage = '95';
    } else if (event == GlobalEngagementsConstant.uploaded) {
      this.fileUploadPercentage = '100';
    }
  }

  async UploadFiletoS3(): Promise<void> {
    console.log(this.documentUploadData);
    const uploadPromises = this.uploadFileSourceList.map(
      (fileSource: any, index: number) => {
        return new Promise<void>((resolve, reject) => {
          this.docService
            .getUploadUrlForDCRMValidation(fileSource.fileSource.name)
            .subscribe({
              next: (presignedUrlData: any) => {
                this.docService
                  .uploadFileToS3UsingPresignedUrl(
                    presignedUrlData.resultData,
                    fileSource.fileSource
                  )
                  .subscribe(
                    () => {
                      this.docService
                        .uploadDocument(
                          fileSource.fileSource.name,
                          presignedUrlData.resultData.documentKey,
                          this.documentInitiatedDetails.moduleName
                        )
                        .subscribe({
                          next: (result: any) => {
                            this.updateProgressBar('PresignedURLUpdated');
                            if (this.documentUploadData[index]) {
                              this.documentUploadData[index].attachmentId =
                                result.attachmentID;
                            }
                            resolve();
                          },
                          error: (err: any) => {
                            console.error('Error uploading file:', err);
                            reject(err);
                          },
                        });
                    },
                    (error) => {
                      console.error('Error uploading file:', error);
                      this.progressIndicator.hide();
                    }
                  );
              },
              error: (error: any) => {
                console.error('Error getting presigned URL:', error);
                this.progressIndicator.hide();
              },
            });
        });
      }
    );

    await Promise.all(uploadPromises);
  }

  enableDisableUIDocumentDetails(): void {
    this.isUploadingInProgress = false;
    this.isFileUploaded = false;
    if (this.documentUploadData.length > 0) {
      this.isFileUploaded = true;
    }
    if (this.fileSourceList.length > 0) {
      this.isUploadingInProgress = true;
    }
  }

  applyBodyOverflow(): void {
    document.body.style.overflow = 'auto';
  }

  openModal(): void {
    this.modalService.open(EngagementDocumentUploadComponent);
  }

  closeModal(): void {
    this.applyBodyOverflow();
    this.uploadFileSourceList = [];
    this.documentUploadInProgressData = [];
    this.uploadRequestList = [];
    this.documentInitiatedDetails.file = [];
    this.documentUploadData = [];

    this.modalService.close(this.documentAllSectionsMetaData);
  }

  isFileExtensionValid(): boolean {
    let isFileExtensionValid = true;
    this.fileSourceList.forEach((element: any) => {
      if (
        !this.validFileExtensions
          .toString()
          .toLowerCase()
          .includes(element.fileExtenstion.toString().toLowerCase())
      ) {
        isFileExtensionValid = false;
      }
    });
    return isFileExtensionValid;
  }

  isFileSizeExceed(): boolean {
    let isFileSizeExceed = false;
    this.isFileSize100MBExceed = false;
    this.fileSourceList.forEach((element: any) => {
      if (
        element.file.size > this.maxFileSizeAllowed &&
        element.file.size <= this.maxFileSizeAllowedUpto
      ) {
        isFileSizeExceed = true;
      } else if (element.file.size > this.maxFileSizeAllowedUpto) {
        this.isFileSize100MBExceed = true;
      }
    });
    return isFileSizeExceed;
  }

  isMaxFileSelectionExceed(): boolean {
    let isMaxFileSelectionExceed = false;
    let selectedFileCount =
      this.fileSourceList.length + this.documentUploadData.length;
    if (selectedFileCount > this.maxFileLength) {
      isMaxFileSelectionExceed = true;
    }
    return isMaxFileSelectionExceed;
  }

  removeBodyOverflow(): void {
    document.body.style.overflow = 'hidden';
  }

  showMsg(modalBtn?: ButtonComponent) {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(NotifyPopupComponent, {
      isFooter: true,
      size: 'md',
      isInverse: false,
      notifyReason: this.notifyReason,
    });

    modalRef.onClosed().subscribe(() => {
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  uploadedItems(filesList: UploadFile[]): void {
    if (filesList.length > 0) {
      this.removePreviousUploadDocument(filesList);
      if (this.fileSourceList.length > 0) {
        if (this.isFileSizeExceed()) {
          this.notifyReason = 'inValidFileSize';
          this.showMsg();
          this.IsDocumentProtected();
        } else if (this.isMaxFileSelectionExceed()) {
          this.notifyReason = 'inValidFileSelection';
          this.showMsg();
        } else if (!this.isFileExtensionValid()) {
          this.notifyReason = 'inValidFileType';
          this.showMsg();
        } else {
          this.IsDocumentProtected();
        }
      }
    }
  }

  IsDocumentProtected(): void {
    this.UploadDraggedAndSelectedDocument();
  }

  loadFileSourceData(fileSource: any): void {
    fileSource.forEach((element: any) => {
      this.removeUploadedDocumentFromList.push(element.fileName);
    });
  }

  removePreviousUploadDocument(filesListSource: any): void {
    if (this.removeUploadedDocumentFromList.length == 0) {
      this.loadFileSourceData(filesListSource);
      this.fileSourceList = filesListSource;
    } else {
      this.fileSourceList = filesListSource.filter(
        (element: any, index: any) =>
          this.removeUploadedDocumentFromList.indexOf(element.fileName) === -1
      );
      this.loadFileSourceData(this.fileSourceList);
    }
  }

  onFileNameEdit(row: any): void {
    row.isFileNameInEditMode = true;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    this.documentUploadData.forEach(
      (obj: { isFileNameInEditMode: boolean }) => {
        if (!target.closest('.editing')) {
          if (this.isEditing) {
            obj.isFileNameInEditMode = false;
            this.isEditing = false;
          } else {
            this.isEditing = true;
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
