import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { CloseOutService } from '../../../../http/intake/close-out.service';
import { Subject, takeUntil } from 'rxjs';
import {
  WbsChargeCodeDetailsModel,
  wbsChargeCodeRequestModel,
  WbsResponseModel,
} from '../../../../common/models/wbs-picker.model';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { SubmissionService } from '../../../../http/intake/submission.service';
import {
  WbsDropdownModelResponseAll,
  WbsDropdownResponseModel,
} from '../../../../common/models/wbs-dropdown.model';
import {
  ArchiveClosedModel,
  ArchiveSaveModel,
} from '../../../../common/models/archive-save.model';
import { ArchiveDetailsModel } from '../../../../common/models/archive-details.model';
import { IntakeConstant } from '../../../constants/intake.constant';

@Component({
  selector: 'app-summary-archive-popup',
  templateUrl: './summary-archive-popup.component.html',
  styleUrls: ['./summary-archive-popup.component.less'],
})
export class SummaryArchivePopupComponent implements OnInit, OnDestroy {
  @Input() isFooter: boolean = true;
  @Input() size: string = 'md';
  @Input() wbsChargeCode: null | string = '';
  @Input() wbsNumber: string = '';
  @Input() dataSubmissionId: number | null = null;
  @Input() dataOpportunityId: string = '';
  @Input() initialSelectedArchive: string = ''; // New input property

  actionDropdownList: SelectItemOptions[] = [];
  wbsDescription: string = '';
  wdsTitle: string = '';
  searchList: string[] = [];
  selectedArchive: string = '';
  archiveNumber: string = '';
  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  wbsObjectArray!: WbsChargeCodeDetailsModel[];
  reloadWbsChargeCode: boolean = false;
  archiveClosed!: ArchiveClosedModel;
  dropdowndata!: WbsDropdownResponseModel[];
  archiveSaveRequestBody!: ArchiveSaveModel;
  selectedArchiveDropdown: WbsDropdownResponseModel | undefined;
  isSaveButtonDisabled: boolean = true;
  archiveStatus: string = '';
  archiveDescription: string = '';
  archiveID: string = '';
  archiveName: string = '';
  archiveFoldersArray: string[] = [];
  archiveFolder: string = '';
  business: string = '';
  clientNumber: string = '';
  clientName: string = '';
  partner: string = '';
  archiveUrl: string = '';

  constructor(
    public modalService: ModalService,
    private readonly closeoutService: CloseOutService,
    public intakeService: SubmissionService
  ) {}

  ngOnInit(): void {
    this.fetchWbsChargeCode();
  }

  fetchWbsChargeCode(): void {
    this.searchList = [];
    const requestBody = this.createRequestBody();
    this.closeoutService
      .getWbsChargeCodes(requestBody)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: WbsResponseModel) => {
          if (response) {
            this.wbsObjectArray = response.wbsChargeCodeDetails;
            this.searchList = response.wbsChargeCodeDetails.map(
              (wbs) => wbs.wbsChargeCode
            );
            this.reloadWbsChargeCode = false;
            setTimeout(() => {
              this.reloadWbsChargeCode = true;
            }, 10);
          }
        },
        error: (error) => {
          this.handleError(error, 'Error fetching WBS charge codes');
        },
      });
  }

  open(): void {
    const requestBody: ArchiveSaveModel = {
      opportunityNumber: this.dataOpportunityId,
      archiveID: this.archiveID,
      archiveNumber: this.archiveNumber,
      archiveName: this.archiveName,
      archiveFoldersArray: this.archiveFoldersArray,
      archiveFolder: this.archiveFolder,
      business: this.business,
      clientNumber: this.clientNumber,
      clientName: this.clientName,
      partner: this.partner,
      archiveStatus: this.archiveStatus,
      archiveDescription: this.archiveDescription,
      wbsChargeCode: String(this.wbsChargeCode),
      wbsDescription: this.wbsDescription,
    };

    this.intakeService
      .saveArchive(requestBody)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response) => {
          this.closeArchiveModel();
        },
        error: (error) => {
          this.handleError(error, 'Error saving archive');
          this.closeArchiveModel();
        },
      });
  }

  closeArchiveModel(): void {
    this.modalService.close();
  }

  changeValue(): void {
    this.isSaveButtonDisabled = true;
    this.selectedArchive = '';
    if (this.isElementInArray(this.wbsChargeCode, this.searchList)) {
      const selectedWBSCode: WbsChargeCodeDetailsModel | undefined =
        this.wbsObjectArray.find(
          (wbs) => wbs.wbsChargeCode === this.wbsChargeCode
        );
      if (selectedWBSCode) {
        this.wbsDescription = selectedWBSCode.wbsDescription;
        this.wbsChargeCode = selectedWBSCode.wbsChargeCode;
        if (this.wbsChargeCode) {
          this.archiveSelected();
        }
      }
    } else {
      if (
        this.wbsChargeCode &&
        this.wbsChargeCode.trim().length >= IntakeConstant.CHARACTER_LIMIT_IN_SEARCH &&
        this.wbsChargeCode.trim().length <= IntakeConstant.number_11
      ) {
        this.fetchWbsChargeCode();
      }
    }
  }

  isElementInArray(element: string | null, array: string[]): boolean {
    return element ? array.includes(element) : false;
  }

  archiveSelected(): void {
    this.isSaveButtonDisabled = true;
    this.intakeService
      .getWBSCodes(String(this.wbsChargeCode))
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: WbsDropdownModelResponseAll) => {
          if (response) {
            this.dropdowndata = response.archiveDetails;
            this.actionDropdownList = this.dropdowndata.map(
              (item: WbsDropdownResponseModel) => ({
                value: item.archiveNumber,
                heading: item.archiveName,
              })
            );
            this.prepopulateDropdown();
          }
        },
        error: (error) => {
          this.handleError(error, 'Error fetching WBS codes');
        },
      });
  }

  prepopulateDropdown(): void {
    if (this.initialSelectedArchive) {
      this.selectedArchive = this.initialSelectedArchive;
      this.archiveChanged();
    }
  }

  archiveChanged(): void {
    this.selectedArchiveDropdown = this.dropdowndata.find(
      (archive) => archive.archiveNumber === this.selectedArchive
    );

    if (this.selectedArchiveDropdown) {
      this.archiveNumber = this.selectedArchiveDropdown.archiveNumber;
      this.archiveID = this.selectedArchiveDropdown.archiveID;
      this.archiveName = this.selectedArchiveDropdown.archiveName;
      this.archiveFoldersArray =
        this.selectedArchiveDropdown.archiveFoldersArray;
      this.archiveFolder = this.selectedArchiveDropdown.archiveFolder;
      this.business = this.selectedArchiveDropdown.business;
      this.clientNumber = this.selectedArchiveDropdown.clientNumber;
      this.clientName = this.selectedArchiveDropdown.clientName;
      this.partner = this.selectedArchiveDropdown.partner;
      this.getArchiveStatus();
    }
  }

  getArchiveStatus(): void {
    this.intakeService
      .getArchiveDetails(this.archiveNumber)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (data: ArchiveDetailsModel) => {
          this.archiveStatus = data.archiveStatus;
          this.archiveDescription = data.archiveDescription;
          this.archiveUrl = data.archiveUrl;
          this.isSaveButtonDisabled = false;
        },
        error: (error) => {
          this.handleError(error, 'Error fetching archive details');
        },
      });
  }

  handleError(error: any, message: string): void {
    console.error(message, error);
  }

  createRequestBody(): wbsChargeCodeRequestModel {
    return {
      opportunityId: this.dataOpportunityId,
      submissionId: this.dataSubmissionId,
      searchTerm: this.wbsChargeCode?.trim() || IntakeConstant.EMPTY_SPACE,
      pageNumber: 0,
      pageSize: IntakeConstant.PAGE_NUMBER,
    };
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
