<div>
    <div class="header-container">
        <p class="tile-name eclipse-main-tab-header">{{gridTitle}}</p>
        <dds-search *ngIf="(currentDashboardTileCode == 'NCASpecialist') && gridTitle != 'Client continuances due'"
            search
            class="jupiter-search"
            [size]="searchOptions.size ?? ''"
            (valueChanged)="onSearchInput($event)"
            (fieldCleared)="clearAllFilter()">
                <input class="jupiter-search-input" ddsSearch
                [(ngModel)]="jupiterFilterValue"
                [placeholder]="searchOptions.placeholder"
                [maxLength]="searchOptions.maxLength"
                (click)="inputClick()"
                (keydown)="validateKeypress($event)"
                (input)="onSearchInput($event)">
        </dds-search>
        <span class="clear-filters"
        (click)="clearAllFilter()">Clear filters</span>
    </div>
    <table class="dds-data-table">
        <thead class="dds-data-table__header">
            <tr>
                <th *ngFor="let column of gridColumns;let i = index"
                    class="dds-data-table__header-cell dds-data-table__header-cell_sorting"
                    [ddsSticker]="column.name != 'actions' ? customFilter : noFilter"
                    [stickerPosition]="(column.name === 'openOpportunities' || column.name === 'priorFyClosedWBS'|| column.name === 'daysPendingForCCCompletion') ? filterStickerOptionsOnlySort?.stickerPosition ?? '' : filterStickerOptions?.stickerPosition ?? ''"
                    [stickerIsOutsideClick]="(column.name === 'openOpportunities' || column.name === 'priorFyClosedWBS'|| column.name === 'daysPendingForCCCompletion') ? filterStickerOptionsOnlySort?.stickerIsOutsideClick ?? false : filterStickerOptions?.stickerIsOutsideClick ?? false"
                    [stickerIndent]="(column.name === 'openOpportunities' || column.name === 'priorFyClosedWBS'|| column.name === 'daysPendingForCCCompletion') ? filterStickerOptionsOnlySort?.stickerIndent ?? 0 : filterStickerOptions?.stickerIndent ?? 0"
                    [stickerWidth]="(column.name === 'openOpportunities' || column.name === 'priorFyClosedWBS'|| column.name === 'daysPendingForCCCompletion') ? filterStickerOptionsOnlySort?.stickerWidth ?? '' : filterStickerOptions?.stickerWidth ?? ''"
                    [attr.aria-expanded]="isShownFilter"
                    aria-haspopup="listbox"
                    (click)="toggleFilter(column)"
                    (onHidden)="filterOnHidden()"
                    #stickerDir="dds-sticker"
                    [ngStyle]="{width: column.width, minWidth: column.minWidth }">
                    <div class="dds-data-table__content">
                        <span *ngIf ="column.name != 'netServiceRevenue'" class="padding-right-xxs">{{column.header || column.name}}</span>
                        <div *ngIf="column.name === 'netServiceRevenue' && (currentDashboardTileCode == 'Reviewer'||currentTileNameForGrid == 'CCDueNow')" class="group-container">
                            <span class="group-text-label">Net Services Revenue</span>
                            <span class="icon-s icon-info" [ddsTooltip]="netServicesRevenueToolTip"
                                  [tooltipInvokeType]="groupToolTipoptions.tooltipInvokeType ?? ''"
                                  [tooltipType]="groupToolTipoptions.tooltipType ?? ''"
                                  [tooltipSize]="groupToolTipoptions.tooltipSize  ?? ''"
                                  [tooltipPosition]="groupToolTipoptions.tooltipPosition ?? ''"
                                  [tooltipIndent]="groupToolTipoptions.tooltipIndent ?? 0"
                                  [tooltipHasBeak]="groupToolTipoptions.tooltipHasBeak ?? false"
                                  [tooltipTheme]="groupToolTipoptions.tooltipTheme ?? ''" ddsFocus>
                            </span>
                        </div>
                        <span *ngIf ="column.name != 'actions'" class="icon-expand-grey"></span>
                        <span *ngIf="sortingState.property === column.name && column.name != 'actions'"
                            class="dds-icon dds-data-table__icon"
                            [ngClass]="sortingState.ascending ? 'dds-icon_sort-up': 'dds-icon_sort-down' ">
                        </span>
                        <span *ngIf ="column.filterable == true">
                            <span *ngIf="column.name != 'actions'" class="dds-icon dds-icon_filter"></span>
                        </span>
                    </div>
                </th>
                <th class="dds-data-table__header-cell"></th>
            </tr>
        </thead>

    <tbody class="dds-data-table__body">
      <ng-container *ngFor="let row of model">
        <tr class="dds-data-table__row" (click)="gotoClientStatus(row.clientNumber)">
          <td *ngFor="let column of gridColumns" class="dds-data-table__cell" (click)="column.name==='actions' && stopEventPropagation($event)"
              [ngClass]="{'desc-designation-cell': column.name === 'descDesignation'}"
              [ngStyle]="{width: column.width, minWidth: column.minWidth }">
            <div *ngIf="column.name == 'clientName'" class="client-container">
              <span *ngIf ="row.clientIconCode != 'Black'"[ngClass]="'padding-left-m client-icon-2 client-icon-code_' + row.clientIconCode"
                    [ddsTooltip]="row.clientIconHover"
                    [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? ''"
                    [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                    [tooltipSize]="clientTooltipOptions.tooltipSize  ?? ''"
                    [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                    [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                    [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                    [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''" ddsFocus></span>
              <span *ngIf ="row.clientIconCode == 'Black'" [ngClass]="'padding-left-m client-icon-2 client-icon-code_' + row.clientIconCode"></span>
              <span class="client-name-2">{{ row[column.name] | ifEmpty:'-' }}</span>
              <div class="margin-left-m margin-top-xxs" style="display:inline-block" *ngIf="row.watchlistStatusId && (currentDashboardTileCode == 'NCASpecialist' || currentDashboardTileCode == 'Reviewer')">
                <span [ngClass]="{'serve-with-conditions-container padding-left-m': row.watchlistStatusId == enumWatchlistStatus.ServeWithConditions,
                          'do-not-serve-container padding-left-m': row.watchlistStatusId == enumWatchlistStatus.DoNotServe,'eclipse-content-grey' : row.watchlistStatusId == enumWatchlistStatus.Removed
                              }">
                  {{getWatchlistStatus(row)}}
                </span>
                <span (click)="onShowWatchlistDetail($event,row, false)" class="padding-left-m margin-left-xxs margin-top-xxs info-icon-green"></span>
              </div>
            </div>
            <div *ngIf="column.name == 'parentClientName'" class="client-container">
              <span *ngIf="row.parentClientName && row.parentClientIconCode != 'Black'" [ngClass]="'padding-left-m parent-client-icon client-icon-code_' + row.parentClientIconCode"
                    [ddsTooltip]="row.parentClientIconHover"
                    [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? ''"
                    [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                    [tooltipSize]="clientTooltipOptions.tooltipSize  ?? ''"
                    [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                    [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                    [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                    [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''" ddsFocus></span>
              <span *ngIf ="row.parentClientName && row.parentClientIconCode == 'Black'" [ngClass]="'padding-left-m parent-client-icon client-icon-code_' + row.parentClientIconCode"></span>
              <span class="client-name-2">{{ row[column.name] | ifEmpty:'-' }}</span>
              <div class="margin-left-m margin-top-xxs" style="display:inline-block" *ngIf="row.pcWatchlistStatusId && (currentDashboardTileCode == 'NCASpecialist' || currentDashboardTileCode == 'Reviewer')">
                <span [ngClass]="{'serve-with-conditions-container padding-left-m': row.pcWatchlistStatusId == enumWatchlistStatus.ServeWithConditions,
                          'do-not-serve-container padding-left-m': row.pcWatchlistStatusId == enumWatchlistStatus.DoNotServe,'eclipse-content-grey' : row.pcWatchlistStatusId == enumWatchlistStatus.Removed
                              }">
                  {{getParentWatchlistStatus(row)}}
                </span>
                <span (click)="onShowWatchlistDetail($event,row,true)" class="padding-left-m margin-left-xxs margin-top-xxs info-icon-green"></span>
              </div>
            </div>
            <div *ngIf="column.name === 'descDesignation'">
              <span>{{ row[column.name] | ifEmpty:'Not in DESC'}}</span>
            </div>
            <div *ngIf="column.name === 'ncaSpecialistName' && currentDashboardTileCode == 'NCASpecialist'">
              <span>{{row.actionBy.outlookDisplayName}}</span>
            </div>
            <div (click)="stopEventPropagation($event)" *ngIf="column.name === 'openOpportunities' && currentDashboardTileCode == 'Reviewer'">
              <span *ngIf="row.openOpportunities != 0" class="open-opps-hyperlink" (click)="showOpenOpps(row, $event)">{{ row[column.name] | ifEmpty:'-' }}</span>
              <span *ngIf="row.openOpportunities == 0">{{ row[column.name] | ifEmpty:'-' }}</span>
            </div>
            <div *ngIf="column.name === 'actionByName' && currentDashboardTileCode == 'NCASpecialist'">
                <div class="group-container no-ellipsis">
                        <p class="group-text-label">Accepted by:</p>
                        <span class="icon-s icon-info" [ddsTooltip]="row.notRequiredReason"
                              [tooltipInvokeType]="actionbyToolTipoptions.tooltipInvokeType ?? ''"
                              [tooltipType]="actionbyToolTipoptions.tooltipType ?? ''"
                              [tooltipSize]="actionbyToolTipoptions.tooltipSize  ?? ''"
                              [tooltipPosition]="actionbyToolTipoptions.tooltipPosition ?? ''"
                              [tooltipIndent]="actionbyToolTipoptions.tooltipIndent ?? 0"
                              [tooltipHasBeak]="actionbyToolTipoptions.tooltipHasBeak ?? false"
                              [tooltipTheme]="actionbyToolTipoptions.tooltipTheme ?? ''" ddsFocus>
                        </span>
                      </div>
                      <div>{{row.actionBy.outlookDisplayName}}</div>
                    </div>

                    <div *ngIf="column.name === 'recommendedClientGroupNumber' && currentDashboardTileCode == 'NCASpecialist'" class="group-container">
                        <p class="group-text-label">Group {{ row.recommendedClientGroupNumber }}</p>
                      <span class="icon-s icon-info" [ddsTooltip]="row.recommendedClientGroupName"
                            [tooltipInvokeType]="groupToolTipoptions.tooltipInvokeType ?? ''"
                            [tooltipType]="groupToolTipoptions.tooltipType ?? ''"
                            [tooltipSize]="groupToolTipoptions.tooltipSize  ?? ''"
                            [tooltipPosition]="groupToolTipoptions.tooltipPosition ?? ''"
                            [tooltipIndent]="groupToolTipoptions.tooltipIndent ?? 0"
                            [tooltipHasBeak]="groupToolTipoptions.tooltipHasBeak ?? false"
                            [tooltipTheme]="groupToolTipoptions.tooltipTheme ?? ''" ddsFocus>
                      </span>
                    </div>
                    
                    <span *ngIf="column.name !== 'actions' && column.name !='clientName' && column.name !='parentClientName' && column.name !='recommendedClientGroupNumber' && column.name != 'descDesignation' && column.name != 'actionByName' && column.name != 'ncaSpecialistName' && column.name != 'openOpportunities'">{{ row[column.name]| ifEmpty:'-' }}</span>
                    <span *ngIf="column.name === 'actions' && currentDashboardTileCode == 'NCASpecialist'">
                      <div id="grid-action-buttons-container" class="flex-container">
                        <div id="grid-action-button">
                          <ng-container *ngFor="let button of buttonOptionsList">
                            <dds-button *ngIf="button.condition"
                                        [theme]="buttonOptions.theme ?? ''"
                                        [kind]="buttonOptions.kind ?? ''"
                                        [size]="buttonOptions.size ?? ''"
                                        [width]="buttonOptions.width ?? ''"
                                        (clicked)="buttonSelected(button.action, row)">{{button.label}}</dds-button>
                          </ng-container>
                        </div>
                        <div id="grid-action-button-dropdown" class="action-button-dropdown" *ngIf="currentTileNameForGrid !== ncaTileCodes.NcaNotRequired">
                          <dds-dropdown [theme]="dropdownOptions.theme ?? ''"
                                        [kind]="dropdownOptions.kind ?? ''"
                                        [size]="dropdownOptions.size ?? ''"
                                        [label]="dropdownOptions.label ?? ''"
                                        [width]="dropdownOptions.width ?? ''"
                                        [icon]="dropdownOptions.icon ?? ''"
                                        [disabled]="dropdownOptions.disabled ?? false"
                                        [stickerWidth]="dropdownOptions.stickerWidth ?? 0"
                                        [stickerPosition]="dropdownStickerPosition"
                                        (itemSelected)="buttonSelected($event, row)">
                            <ng-container *ngFor="let item of dropdownItems">
                              <dds-dropdown-item *ngIf="isVisibleDropdownItem(item, row)"
                                                 [value]="item.value"
                                                 [counter]="item.counter ?? 0"
                                                 [disabled]="item.disabled ?? false">{{item.heading}}</dds-dropdown-item>
                            </ng-container>
                          </dds-dropdown>
                        </div>
                      </div>
                    </span>
                  </td>
                    <td class="dds-data-table__cell" (click)="stopEventPropagation($event)">
                        <span class="expand-collapse-icon"  [ngStyle]="getAccordianStyles()" (click)="itemExpand(row,$event)">
                            <div [ngClass]="row.expandStatus ? 'icon-collapse-black' : 'icon-expand-black'" class="icon-width"></div>
                        </span>
                    </td>
                </tr>
                <tr *ngIf="row.expandStatus" class="second-block" >
                    <ng-container>
                        <td [attr.colspan]="gridColumns.length + 1">
                            <div class="opp-grid">
                                <p class="text-label">Client info</p>
                                <div>
                                    <div class="item-info-container">
                                        <div class="item-info-column" *ngIf="(currentDashboardTileCode == 'NCASpecialist' && currentTileNameForGrid != 'CCDueNow') || (currentDashboardTileCode == 'Reviewer' && currentTileNameForGrid == 'NCAPendingApproval')">
                                            <span class="item-info-label">Industry:</span>
                                            <span class="item-info-value">{{(row.industry | ifEmpty:'-')}}</span>
                                        </div>
                                        <div class="item-info-column" *ngIf="(currentDashboardTileCode == 'NCASpecialist' && currentTileNameForGrid != 'CCDueNow') || (currentDashboardTileCode == 'Reviewer' && currentTileNameForGrid == 'NCAPendingApproval')">
                                            <span class="item-info-label" id="startpage-gridarea-industrysector-label">Industry sector:</span>
                                            <span class="item-info-value" id="startpage-gridarea-industrysector-value">{{row.industrySector | ifEmpty:'-'}}</span>
                                        </div>
                                        <div class="item-info-column" *ngIf="(currentDashboardTileCode == 'NCASpecialist' && currentTileNameForGrid != 'CCDueNow') && (row.recommendedClientGroupNumber === '4' || row.isActiveMsaExists || true)">
                                            <span class="item-info-label" id="startpage-gridarea-isactivemsaexists-label">Active MSA(s):</span>
                                            <span class="item-info-value" id="startpage-gridarea-isactivemsaexists-value">{{row.isActiveMsaExists ? 'Yes' : 'No'}}</span>
                                        </div>
                                        <div class="item-info-column" *ngIf="(currentDashboardTileCode == 'Reviewer' && currentTileNameForGrid == 'CCDueNow')">
                                            <span class="item-info-label" id="startpage-gridarea-lastoppwithclosedwon-label">Last opportunity with closed won:</span>
                                            <span class="item-info-value" id="startpage-gridarea-lastoppwithclosedwon-value">{{row.lastOpportunityWithClosedWonDate | ifEmpty:'-'}}</span>
                                        </div>
                                        <div class="item-info-column" *ngIf="(currentDashboardTileCode == 'NCASpecialist' && currentTileNameForGrid == 'CCDueNow')">
                                          <span class="item-info-label" id="startpage-gridarea-netServiceRevenue-label">Net service revenue:</span>
                                          <span class="item-info-value" id="startpage-gridarea-netServiceRevenue-value">{{row.netServiceRevenue | ifEmpty:'-'}}</span>
                                      </div>
                                        <div class="item-info-column" *ngIf="(currentDashboardTileCode == 'Reviewer' && currentTileNameForGrid == 'CCDueNow')||(currentDashboardTileCode == 'NCASpecialist' && currentTileNameForGrid == 'CCDueNow')">
                                            <span class="item-info-label" id="startpage-gridarea-lastinvoicedate-label">Last invoice date:</span>
                                            <span class="item-info-value" id="startpage-gridarea-lastinvoicedate-value">{{row.lastInvoiceDate | ifEmpty:'-'}}</span>
                                        </div>
                                        <div class="item-info-column" *ngIf="(currentDashboardTileCode == 'Reviewer' && currentTileNameForGrid == 'CCDueNow')||(currentDashboardTileCode == 'NCASpecialist' && currentTileNameForGrid == 'CCDueNow')">
                                            <span class="item-info-label" id="startpage-gridarea-advclientportfolio-label">Program account:</span>
                                            <span class="item-info-value" id="startpage-gridarea-advclientportfolio-value">{{row.programAccount | ifEmpty:'-'}}</span>
                                        </div>
                                        <div class="item-info-column" *ngIf="(currentDashboardTileCode == 'Reviewer' && currentTileNameForGrid == 'CCDueNow')||(currentDashboardTileCode == 'NCASpecialist' && currentTileNameForGrid == 'CCDueNow')">
                                          <span class="item-info-label" id="startpage-gridarea-advclientportfolio-label">Global crown jewel:</span>
                                          <span class="item-info-value" id="startpage-gridarea-advclientportfolio-value">{{row.isGlobalCrownJewel ? 'Yes' : 'No'}}</span>
                                      </div>
                                        <div class="item-info-column" *ngIf="(currentDashboardTileCode == 'Reviewer' && currentTileNameForGrid == 'NCAPendingApproval')">
                                            <span class="item-info-label" id="startpage-gridarea-advclientportfolio-label">Date became a client for A&C:</span>
                                            <span class="item-info-value" id="startpage-gridarea-advclientportfolio-value">{{row.registeredForAdvisoryAndConsultingDate | ifEmpty:'-'}}</span>
                                        </div>
                                        <div class="item-info-column" *ngIf="(currentDashboardTileCode == 'Reviewer' && currentTileNameForGrid == 'NCAPendingApproval')">
                                            <span class="item-info-label" id="startpage-gridarea-advclientportfolio-label">Date became a client for Deloitte:</span>
                                            <span class="item-info-value" id="startpage-gridarea-advclientportfolio-value">{{row.registeredForDeloitteDate | ifEmpty:'-'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="(currentDashboardTileCode == 'NCASpecialist') || (currentDashboardTileCode == 'Reviewer' && currentTileNameForGrid == 'NCAPendingApproval')">
                                    <span class="text-label">Related opportunities</span>
                                    <div class="inner-container">
                                        <nca-specialist-page-grid-area-item [list]="row.opportunityList"></nca-specialist-page-grid-area-item>
                                    </div>
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>

<ng-template #customFilter>
  <dds-filter [items]="filterData"
              [theme]="filterOptions.theme ?? ''"
              (sorted)="filterSorted(isAscending)"
              (apply)="filterApply(filteringColumn.searchValue)"
              (cancel)="filterHide()"
              [template]="customFilterTemp"
              #ddsFilter></dds-filter>
</ng-template>

<ng-template #customFilterTemp let-ctx="ctx">
  <span class="dds-filter__title dds-filter__section">Sorting</span>
  <div class="dds-filter__container dds-filter__container_sorting">
    <button class="dds-filter__item dds-filter__section dds-filter__section_sorting" (click)="filterSorted(true)">
      <span class="dds-filter__icon dds-icon dds-icon_sort-up"></span>
      <span class="dds-filter__text">Sort A–Z</span>
    </button>
    <button class="dds-filter__item dds-filter__section dds-filter__section_sorting" (click)="filterSorted(false)">
      <span class="dds-filter__icon dds-icon dds-icon_sort-down"></span>
      <span class="dds-filter__text">Sort Z–A</span>
    </button>
  </div>
  <ng-container *ngIf="filteringColumn.name !== 'openOpportunities' && filteringColumn.name !== 'priorFyClosedWBS' && filteringColumn.name !== 'daysPendingForCCCompletion'">
    <span class="dds-filter__title dds-filter__section">Filter</span>
    <dds-search *ngIf="filteringColumn.dataType !== 'date' && filteringColumn.dataType !== 'number'"
                class="dds-filter__search dds-filter__section"
                [isInverse]="ctx.isInverse"
                (keydown)="ctx.tabOut(searchField, $event)"
                [(ngModel)]="filteringColumn.searchValue"
                #searchField></dds-search>

    <dds-daterangepicker class="dds-filter__section" *ngIf="filteringColumn.dataType === 'date'"
                         [size]="dateRangePickerOptions.size ?? ''"
                         [placeholder]="dateRangePickerOptions.placeholder ?? []"
                         [format]="dateRangePickerOptions.format ?? ''"
                         [isManualInput]="dateRangePickerOptions.isManualInput ?? false"
                         [stickerPosition]="dateRangePickerOptions.stickerPosition ?? ''"
                         [(ngModel)]="filteringColumn.searchValue"></dds-daterangepicker>

    <div *ngIf="filteringColumn.dataType === 'number'" class="dds-filter__section">
      <input type="number" class="dds-filter__input" [(ngModel)]="fromValue" placeholder="From" />
      <span class="dds-filter__separator">-</span>
      <input type="number" class="dds-filter__input" [(ngModel)]="toValue" placeholder="To" />
    </div>

    <div class="dds-filter__footer dds-filter__section">
      <dds-button width="full"
                  [kind]="ctx.cancelBtnOptions.kind"
                  [theme]="ctx.theme"
                  [isInverse]="ctx.isInverse"
                  class="dds-filter__btn dds-filter__item"
                  (clicked)="filterHide()"
                  #cancelBtn>Cancel</dds-button>
      <dds-button width="full"
                  [theme]="ctx.theme"
                  [isInverse]="ctx.isInverse"
                  class="dds-filter__btn dds-filter__item"
                  (clicked)="filterApply(filteringColumn.searchValue)"
                  (keydown)="ctx.tabOut(lastBtn, $event)"
                  [disabled]="!filteringColumn.filterable && (!filteringColumn.searchValue && (filteringColumn.dataType !== 'number' || (!fromValue || !toValue)))"
                  #lastBtn>Apply</dds-button>
    </div>
  </ng-container>
</ng-template>
<ng-template #noFilter></ng-template>
