import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { ParsedRules } from '../../common/models/common-models';
import { externalCommPermissions } from '../../common/constants/security-matrix';
@Injectable({
  providedIn: 'root',
})
export class SecurityWebapiService {
  readonly behaviorSubject: BehaviorSubject<any>;
  public data: Observable<any>;
  constructor(private readonly httpService: HttpClient) {
    const initialState: any = { empty: true };
    this.behaviorSubject = new BehaviorSubject(initialState);
    this.data = this.behaviorSubject.asObservable();
  }
  private permissionObject: ParsedRules = {};
  apiUrl: string = environment.apiUrl;

  public getPermissions(clientNumber: string) {
    this.httpService
      .get(
        this.apiUrl +
          'Admin/Permission/GetPermissions?clientNumber=' +
          clientNumber
      )
      .subscribe((res: any) => {
        this.setPermissions(res);
      });
  }

  public getPermissionsByEmployeeId(entityId: number, entityTypeId: number) {
    const endpoint = 'Admin/Permission/GetPermissionsByEmployeeId';
    this.httpService
      .get(
        `${this.apiUrl}${endpoint}?entityId=${entityId}&entityTypeId=${entityTypeId}`
      )
      .subscribe((res: any) => {
        const parsedPermission = this.parseRules(res);
        this.permissionObject = parsedPermission;
        this.behaviorSubject.next(parsedPermission);
      });
  }

  public setPermissions(permissions: string[]) {
    this.permissionObject = this.parseRules(permissions);
    this.behaviorSubject.next(this.permissionObject);
  }

  parseRules(result: string[]) {
    let newRules: ParsedRules = {};
    for (let permissionFullName of result) {
      let elementId = permissionFullName.substring(
        0,
        permissionFullName.lastIndexOf('.')
      );
      let permission = permissionFullName.substring(
        permissionFullName.lastIndexOf('.') + 1
      );
      if (!newRules[elementId]) {
        newRules[elementId] = {};
      }
      newRules[elementId][permission] = true;
    }
    return newRules;
  }

  isPermissionPresent(fieldKey: string, permissionType: string): boolean {
    return this.permissionObject?.[fieldKey]?.[permissionType] ?? false;
  }
}
