import { AuditTrailComponent } from './audit-trail/audit-trail.component';

export const AUDIT_TRAIL_ROUTES = [
  {
    path: '',
    children: [
      {
        path: '',
        component: AuditTrailComponent,
      },
    ],
  },
];
