export const convertDate = (data: string): string => {
  const dateObj: Date = new Date(data);
  const day: string = String(dateObj.getDate()).padStart(2, '0');
  const month: string = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year: number = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
};

export const convertTime = (data: string): string => {
  const dateObj: Date = new Date(data);
  const hours: string = String(dateObj.getHours()).padStart(2, '0');
  const minutes: string = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds: string = String(dateObj.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

export function isBusinessDay(date: Date): boolean {
  const day: number = date.getDay();
  const isWeekend = day === 0 || day === 6; // Sunday = 0, Saturday = 6
  return !isWeekend;
}

  export const convertAuditDate = (data: string): string => {
    const [datePart] = data.split(' ');
    const [month, day, year] = datePart.split('/');
    return `${month}/${day}/${year}`;
  };
  
  export const convertAuditTime = (data: string): string => {
    const [, timePart, period] = data.split(' ');
    const [hours, minutes] = timePart.split(':');
    return `${hours}:${minutes} ${period}`;
  };