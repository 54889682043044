import { BehaviorManager } from "../services/BehaviorManager";

export class AutosaverGlobalOptions {
    public autosaverState: BehaviorManager<number> = new BehaviorManager<number>();
    private _numberOfSavers: number = 0; //Used to Indicate situation when user changed/updated value and we have timer to save it to server.    
    public get numberOfSavers(): number { return this._numberOfSavers; }
    public set numberOfSavers(v: number) {
        if (this._numberOfSavers !== v) {
            this._numberOfSavers = v;
            if (v < 1) {
                this.setDisplaySaveStatus('COMPLETED');
                this.setAutosaverTimestamp();
            } else {
                this.setDisplaySaveStatus('PENDING');
            }
        }
    }

    public async initSaverData() {
        this.createDisplaySaveObject();
    }

    //can be removed if its not used to show on UI
    public setDisplaySaveStatus(status: 'COMPLETED' | 'PENDING'): void {
        let wnd = window as any;
        wnd.DAP.components.displaySave.module.status = status;

    }

    //can be removed if its not used
    public setAutosaverTimestamp(): void {
        let wnd = window as any;
        wnd.DAP.components.displaySave.module.savedTimeStamp = new Date();
    }

    private createDisplaySaveObject(): void {
        let wnd = window as any;
        wnd.DAP = wnd.DAP || {};
        wnd.DAP.components = wnd.DAP.components || {};
        wnd.DAP.components.displaySave = {
            module: {
                status: 'COMPLETED',
                timestamp: null
            }
        }
    }
}