<div class="dds-modal-overlay" (click)="close()"></div>

<div
  ddsResize
  [ngClass]="{
    'dds-modal_lg': size === 'lg',
    'dds-modal_inverse': isInverse
  }"
  class="dds-modal"
>
  <div class="dds-modal__header">
    <span class="dds-modal__title">Move to complete</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="close()"
    ></dds-button>
  </div>

  <div class="dds-modal__body min-height-10 ">
    <div
      *ngIf="
        valueSelected === NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED ||
        NO_DOCUMENT_WILL_NOT_BE_EXECUTED
      "
    >
      If (i) we are ending this pursuit or (ii) the document will not be
      executed, move the submission to complete (no other fields are required).
    </div>
  </div>

  <div class="dds-modal__footer" *ngIf="isFooter">
    <div class="dds-modal__footer-content dds-modal__footer-content_left"></div>
    <div class="dds-modal__footer-content dds-modal__footer-content_right">
      <ng-container
        *ngIf="valueSelected === NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED"
      >
        <dds-button
          class="dds-modal__footer-item"
          [theme]="moveToCompleteBtnOption.theme ?? ''"
          [kind]="moveToCompleteBtnOption.kind ?? ''"
          [size]="moveToCompleteBtnOption.size ?? ''"
          [width]="moveToCompleteBtnOption.width ?? ''"
          [icon]="moveToCompleteBtnOption.icon ?? ''"
          [isIconLeft]="moveToCompleteBtnOption.isIconLeft ?? false"
          [role]="moveToCompleteBtnOption.role ?? ''"
          [isInverse]="moveToCompleteBtnOption.isInverse ?? false"
          [isColorBg]="moveToCompleteBtnOption.isColorBg ?? false"
          [isLoading]="moveToCompleteBtnOption.isLoading ?? false"
          [ariaLabel]="moveToCompleteBtnOption.ariaLabel ?? ''"
          [customClass]="moveToCompleteBtnOption.customClass ?? ''"
          [disabled]="false"
          (clicked)="onClickMoveToCompleteBtn()"
          ><span>{{ moveToCompleteBtnLabel }}</span></dds-button
        >
 
        <dds-button
          class="dds-modal__footer-item"
          [theme]="cancelBtnOption.theme ?? ''"
          [kind]="cancelBtnOption.kind ?? ''"
          [size]="cancelBtnOption.size ?? ''"
          [width]="cancelBtnOption.width ?? ''"
          [icon]="cancelBtnOption.icon ?? ''"
          [isIconLeft]="cancelBtnOption.isIconLeft ?? false"
          [role]="cancelBtnOption.role ?? ''"
          [isInverse]="cancelBtnOption.isInverse ?? false"
          [isColorBg]="cancelBtnOption.isColorBg ?? false"
          [isLoading]="cancelBtnOption.isLoading ?? false"
          [ariaLabel]="cancelBtnOption.ariaLabel ?? ''"
          [customClass]="cancelBtnOption.customClass ?? ''"
          [disabled]="false"
          (clicked)="onClickCancel()"
          ><span>{{ cancelBtnLabel }}</span></dds-button
        >
      </ng-container>
      <ng-container *ngIf="valueSelected === NO_DOCUMENT_WILL_NOT_BE_EXECUTED">
        <dds-button
          class="dds-modal__footer-item"
          [theme]="moveToCompleteBtnOption.theme ?? ''"
          [kind]="moveToCompleteBtnOption.kind ?? ''"
          [size]="moveToCompleteBtnOption.size ?? ''"
          [width]="moveToCompleteBtnOption.width ?? ''"
          [icon]="moveToCompleteBtnOption.icon ?? ''"
          [isIconLeft]="moveToCompleteBtnOption.isIconLeft ?? false"
          [role]="moveToCompleteBtnOption.role ?? ''"
          [isInverse]="moveToCompleteBtnOption.isInverse ?? false"
          [isColorBg]="moveToCompleteBtnOption.isColorBg ?? false"
          [isLoading]="moveToCompleteBtnOption.isLoading ?? false"
          [ariaLabel]="moveToCompleteBtnOption.ariaLabel ?? ''"
          [customClass]="moveToCompleteBtnOption.customClass ?? ''"
          [disabled]="false"
          (clicked)="onClickOtherMoveToCompleteBtn()"
          ><span>{{ moveToCompleteBtnLabel }}</span></dds-button
        >

        <dds-button
          class="dds-modal__footer-item"
          [theme]="cancelBtnOption.theme ?? ''"
          [kind]="cancelBtnOption.kind ?? ''"
          [size]="cancelBtnOption.size ?? ''"
          [width]="cancelBtnOption.width ?? ''"
          [icon]="cancelBtnOption.icon ?? ''"
          [isIconLeft]="cancelBtnOption.isIconLeft ?? false"
          [role]="cancelBtnOption.role ?? ''"
          [isInverse]="cancelBtnOption.isInverse ?? false"
          [isColorBg]="cancelBtnOption.isColorBg ?? false"
          [isLoading]="cancelBtnOption.isLoading ?? false"
          [ariaLabel]="cancelBtnOption.ariaLabel ?? ''"
          [customClass]="cancelBtnOption.customClass ?? ''"
          [disabled]="false"
          (clicked)="onClickOtherCancel()"
          ><span>{{ cancelBtnLabel }}</span></dds-button
        >
      </ng-container>
    </div>
  </div>
</div>
