import { DashboardData } from './start-page.model';

export interface ReviewerDashboardData extends DashboardData {
  clientNumber: string;
  continuanceExpiredDate: string;
  openOpportunities: string;
  priorFyClosedWBS?: string;
  netServiceRevenue: string;
  submittedDate: string;
  requestedReviewDate: string;
  rushRequest?: string;
  consultationRequested?: string;
  registeredForAdvisoryAndConsultingDate: string;
  registeredForDeloitteDate: string;
  slComments?: string;
  lastOpportunityWithClosedWonDate: string;
  lastInvoiceDate: string;
  programAccount: string;
  isGlobalCrownJewel: string;
  [key: string]: any;
  submissionTitle: string;
  focusUrl: string;
  jupiterId: string;
  lep: string;
  pendingReviewer: string;
  lastStatus: string;
  dateOfLastStatusChange: string;
  pendingRequiredReviewers: string | string[];
  pendingOptionalReviewers: string | string[];
}

export interface ReviewerDashboardTilesCount {
  pendingReviewsCount: number;
  pendingReviewsOthersCount: number;
  ncaPendingApprovalCount: number;
  ccDueCount: number;
  recentReviewsCount: number;
  selfReviewChangesCount: number;
}

export interface RecentlyViewedSubmissions {
  opportunityNumber: string,
  submissionId: number,
  submissionTitle: string,
  submissionType: string,
  submissionTypeCode: string,
  submissionSubType: string | null,
  submissionStatus: string
}

export interface RecentlyViewedSubmissionList{
  totalCount:number;
  submissionDetails: RecentlyViewedSubmissions[]
}

export enum ReviewerTileCode {
  PendingReviews = 'PendingReviews',
  PendingReviewsOthers = 'PendingReviewsOthers',
  NCAPendingApproval = 'NCAPendingApproval',
  CCDueNow = 'CCDueNow',
  RecentReviews = 'RecentReviews',
  SelfReviewChanges = 'SelfReviewChanges',
}

export const ReviewerTileCodeDetails = new Map<
  ReviewerTileCode,
  {
    title: string;
    countKey: keyof ReviewerDashboardTilesCount;
    tileCode: string;
  }
>([
  [
    ReviewerTileCode.PendingReviews,
    {
      title: 'Pending reviews',
      countKey: 'pendingReviewsCount',
      tileCode: 'PendingReviews',
    },
  ],
  [
    ReviewerTileCode.PendingReviewsOthers,
    {
      title: 'Pending reviews of others',
      countKey: 'pendingReviewsOthersCount',
      tileCode: 'PendingReviewsOthers',
    },
  ],
  [
    ReviewerTileCode.RecentReviews,
    {
      title: 'Recent reviews',
      countKey: 'recentReviewsCount',
      tileCode: 'RecentReviews',
    },
  ],
  [
    ReviewerTileCode.SelfReviewChanges,
    {
      title: 'Self-review changes',
      countKey: 'selfReviewChangesCount',
      tileCode: 'SelfReviewChanges',
    },
  ],
  [
    ReviewerTileCode.NCAPendingApproval,
    {
      title: 'NCAs pending approvals',
      countKey: 'ncaPendingApprovalCount',
      tileCode: 'NCAPendingApproval',
    },
  ],
  [
    ReviewerTileCode.CCDueNow,
    {
      title: 'Client continuances overview',
      countKey: 'ccDueCount',
      tileCode: 'CCDueNow',
    },
  ],
]);
