import { Column } from '@usitsdasdesign/dds-ng/shared';

export const opportunityTableColumns: Column[] = [
  {
    name: 'opportunityNumber',
    header: 'Opportunity Id:',
    dataType: 'string',
    minWidth: '6.25rem',
  },
  {
    name: 'opportunityName',
    header: 'Opportunity name:',
    dataType: 'string',
    minWidth: '8.75rem',
  },
  {
    name: 'stage',
    header: 'Opportunity Stage:',
    dataType: 'string',
    minWidth: '8.75rem',
  },
  {
    name: 'client',
    header: 'Client:',
    dataType: 'string',
    minWidth: '8.75rem',
  },
  {
    name: 'leader',
    header: 'Leader:',
    dataType: 'string',
    minWidth: '8.75rem',
  },
  {
    name: 'numberOfSplits',
    header: 'Number of splits:',
    dataType: 'number',
    minWidth: '8.75rem',
  },
  {
    name: 'totalGlobalOpportunityAmount',
    header: 'Total global opportunity amount $:',
    dataType: 'string',
    minWidth: '12.25rem',
  },
];
