import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { Subject, takeUntil } from 'rxjs';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { SaveSubmissionDocument } from '../../../../common/models/external-communication.model';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { CommonHelperService } from '../../../../common/services/common-helper.service';
import {
  ModalCloseButtonOptions,
  newDocumentIds,
  SecondaryLoudButtonOptions,
} from '../../../external-communications.helper';
@Component({
  selector: 'app-external-communication-document-link-upload',
  templateUrl: './external-communication-document-link-upload.component.html',
  styleUrl: './external-communication-document-link-upload.component.less',
})
export class ExternalCommunicationDocumentLinkUploadComponent {
  constructor(
    private readonly modalService: ModalService,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly progressIndicator: ProgressIndicatorService,
    private commonHelperService: CommonHelperService
  ) {}
  @Input() public documentInitiatedDetails: any = {};
  @Input() set documentStatusDropdown(value: SelectItemOptions[]) {
    this.documentStatusItemList = value;
  }
  @Input() set documentTypeDropdown(value: SelectItemOptions[]) {
    this.documentTypeItemsList = value;
  }
  @Input() addLinkGuidanceText: string =
    ExternalCommunicationConstant.EMPTY_SPACE;
  @Input() userHasAccess: boolean = false;

  documentLink: string = ExternalCommunicationConstant.EMPTY_SPACE;
  documentTitle: string = ExternalCommunicationConstant.EMPTY_SPACE;
  documentType: number = 0;
  documentStatus: number = 0;
  documentComment: string = ExternalCommunicationConstant.EMPTY_SPACE;
  unsubscriber$: Subject<void> = new Subject<void>();
  documentTypeItemsList: SelectItemOptions[] = [];
  documentStatusItemList: SelectItemOptions[] = [];

  allStatusAvailable: SelectItemOptions[] = [];
  otherStatus: number = ExternalCommunicationConstant.OTHER_STATUS;
  modalCloseButtonOptions: ButtonOptions = ModalCloseButtonOptions;
  secondaryLoudButtonOptions: ButtonOptions = SecondaryLoudButtonOptions;
  UI_Element_Size: string = ExternalCommunicationConstant.UI_ELEMENT_SIZE;
  emptyString: string = ExternalCommunicationConstant.EMPTY_SPACE;

  async saveDocumentLink(): Promise<void> {
    const postRequestData: SaveSubmissionDocument[] = [
      {
        SubmissionId: this.documentInitiatedDetails.submissionId,
        DocumentTypeId: this.documentType,
        DocumentStatusId: this.documentStatus,
        SubmissionDocumentTypeId: newDocumentIds.submissionDocumentId.link, // For new file-1, link/url -2
        DocumentTitle: this.commonHelperService.trimPrefixEmptySpace(this.documentTitle),
        DocumentUrl: this.documentLink,
        Comments: this.commonHelperService.trimPrefixEmptySpace(this.documentComment),
        AttachmentId: newDocumentIds.attachmentId.link, // For new file-0, link/url-1
      },
    ];
    return new Promise((resolve, reject) => {
      this.externalCommService
        .postDocumentSave(postRequestData)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            resolve();
          },
          error: (err) => {
            // TODO: Getting 200 status with parsing error of message. Check with backend
            if (err?.status === 200) {
              resolve();
            } else reject(err);
          },
        });
    });
  }

  isLinkValid(): boolean {
    // TODO: Need to add link validation logic
    return this.documentLink.length > 0;
  }

  formValid(): boolean {
    return (
      this.isLinkValid() &&
      this.documentTitle.length > 0 &&
      this.documentType != 0 &&
      this.documentStatus != 0
    );
  }

  async saveAll(): Promise<void> {
    try {
      this.progressIndicator.show();
      await this.saveDocumentLink();
      this.close();
    } catch (error) {
      console.error('Error saving document link:', error);
    } finally {
      this.progressIndicator.hide();
    }
  }

  close(): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
