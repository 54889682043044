import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { primaryButton } from './engagement-submission.helper';
import { copyToClipboard } from '../../../intake/common/intake.util';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import {
  getStatusEnumKeyByValue,
  GlobalEngagementsConstant,
} from '../../constants/global-engagements.constants';
import { GlobalEngagementService } from '../../../http/global-engagement/global-engagement.service';
import { Subject, takeUntil } from 'rxjs';
import {
  GlobalEngagementSubmissionDetailModel,
  GlobalEngagementSubmissionLevelModel,
  GlobalEngEfaModel,
} from '../../../common/models/global-engagement.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import {
  SelectItemOptions,
  SelectOptions,
} from '@usitsdasdesign/dds-ng/select';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { GlobalEngagementUrlConstants } from '../../constants/url.constants';
import { PermissionsObj } from '../../../common/models/common-models';
import { SubmissionActionHistoryModel } from '../../../common/models/submission-action.model';
import { SHOW_MORE_BTN_OPTIONS } from '../../global-engagement.helper';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { DiscontinuePopupComponent } from './discontinue-popup/discontinue-popup.component';
import { IntakeConstant } from '../../../intake/constants/intake.constant';
import { StatusEnum } from '../../../common/constants/status-enum';
import { CommonService } from '../../../http/intake/common.service';

@Component({
  selector: 'app-engagement-submission',
  templateUrl: './engagement-submission.component.html',
  styleUrl: './engagement-submission.component.less',
})
export class EngagementSubmissionComponent implements OnDestroy {
  @Input() submissionId: number | null = null;
  @Input() submissionStatus: string | null = null;
  @Input() submissionType: string | null = null;
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;
  @Input() engagementId: string | null = null;
  @Output() loadLeftMenu: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();
  // @Input() engagementId: string | null = GlobalEngagementsConstant.EMPTY_STRING;

  primaryLoudButton = primaryButton;
  htmlElementSize: Size = GlobalEngagementsConstant.UI_ELEMENT_SIZE;
  unsubscriber$: Subject<void> = new Subject<void>();
  submissionDetails: GlobalEngagementSubmissionDetailModel | null = null;
  engagementTitle: string | null | undefined = null;
  isEngagementTitleEditable: boolean = false;
  public savedetails!: Saver | null;
  notes: string | null | undefined = null;
  submissionTypeId: number | null | undefined = null;
  submissionStatusId: number | null | undefined = null;
  isWBSEditable: boolean = false;
  WBSChargeCode: string | null | undefined = null;
  tempWBSChargeCode: string | null | undefined = null;
  isWbsSelected: boolean = false;
  WBSDescription: string | null | undefined = null;
  chargeCode: string | null | undefined = null;
  archiveSelected: string | null | undefined = null;
  archiveSelectedId: string | null | undefined = null;
  archiveList: SelectItemOptions[] = [];
  isArchiveEditable: boolean = false;
  efaList: SelectItemOptions[] = [];
  efaSelected: string | undefined | null = null;
  isefaEdiitable: boolean = false;
  isWbsAvailable: boolean = false;
  emptyFeild = GlobalEngagementsConstant.DEFAULT_VALUE;
  // TODO: remove wbs static logic when wbs is ready
  wbsSearchList: string[] = GlobalEngagementsConstant.wbsSearchList;

  submissionActionHistory: SubmissionActionHistoryModel[] = [];
  showDiscontinueMessage: boolean = false;
  discontinueActionMessage?: SubmissionActionHistoryModel;
  isShowMoreDocumentNotes: boolean = false;
  showMoreOptions: any = SHOW_MORE_BTN_OPTIONS;
  private timeoutId: ReturnType<typeof setTimeout> | null = null;
  showMoreText: string = IntakeConstant.SHOW_MORE_TEXT;

  constructor(
    private readonly globalEngagementService: GlobalEngagementService,
    private readonly autosaverService: AutosaverService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly commonService: CommonService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.globalEngagementService.discontinueCloseModal$.subscribe((status) => {
      if (status) {
        this.openModalForDiscontinue();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.commonService.enableAuditTrail(true);
    if (changes['submissionId']) {
      this.getSubmissionDetails();
      this.getEFA();
      this.globalEngagementService.changeSubmissionId({
        opportunitynumber: this.engagementId!,
        submissionid: this.submissionId,
      });
    }

    let autoSaverOptions = {
      delay: 500,
      onSuccess: (
        saver: any,
        result: GlobalEngagementSubmissionLevelModel
      ) => {},
    } as IAutosaverOptions;

    this.savedetails = this.autosaverService.newSaver(
      'savedetails',
      this,
      async () => this.postEngagementDetails(),
      autoSaverOptions
    );
    this.savedetails.start();
  }

  openModalForDiscontinue(modalBtn?: ButtonComponent): void {
    let modalRef = this.modalService.open(DiscontinuePopupComponent, {
      isFooter: false,
      size: 'lg',
      isInverse: false,
      submissionId: this.submissionId,
    });
    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        if (this.submissionId) {
          this.timeoutId = setTimeout(() => {
            this.getSubmissionDetails();
          }, 1000);
        }
        this.globalEngagementService.openDiscontinueModal(false);
      });
  }

  getSubmissionDetails(): void {
    this.progressIndicator.show();
    this.globalEngagementService
      .getGlobalEngagementSubmissionDetails(Number(this.submissionId))
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: GlobalEngagementSubmissionDetailModel) => {
          this.submissionDetails = response;
          this.setVariables();
          this.progressIndicator.hide();
          if (this.submissionId !== null) {
            this.SubmissionActionHistoryForAdminComplete(this.submissionId);
          }
          this.globalEngagementService.enableDisconitnue(
            this.isStatusForDiscontinue()
          );
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  SubmissionActionHistoryForAdminComplete(id: number): void {
    this.globalEngagementService
      .getSubmissionActionHistory(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: SubmissionActionHistoryModel[]) => {
          this.submissionActionHistory = response;
          for (let i in this.submissionActionHistory) {
            if (
              this.submissionActionHistory[i].currentStatus ===
              StatusEnum.DISCONTINUED
            ) {
              this.showDiscontinueMessage = true;
              this.discontinueActionMessage = this.submissionActionHistory[i];
              this.loadLeftMenu.emit(this.engagementId)
              return;
            } else if (
              this.submissionActionHistory[i].currentStatus ===
              StatusEnum.COMPLETED_ADMIN
            ) {
              // TODO: For admin complete
            } else {
              this.showDiscontinueMessage = false;
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('Error fetching data', err);
        },
      });
  }

  isStatusForDiscontinue(): boolean {
    return this.submissionStatus === StatusEnum.Draft;
  }

  checkStatusDiscontinued(): boolean {
    return this.submissionStatus === StatusEnum.DISCONTINUED;
  }

  clickToShowDocumentMoreLepMessage(): void {
    this.isShowMoreDocumentNotes = !this.isShowMoreDocumentNotes;
    if (!this.isShowMoreDocumentNotes) {
      this.showMoreText = IntakeConstant.SHOW_MORE_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_DOWN;
    } else {
      this.showMoreText = IntakeConstant.SHOW_LESS_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_UP;
    }
  }

  setVariables(): void {
    this.engagementTitle = this.submissionDetails?.submissionTitle;
    this.notes = this.submissionDetails?.notes;
    this.submissionTypeId = this.submissionDetails?.submissionTypeId;
    this.submissionStatusId = this.submissionDetails?.submissionStatusId;
    this.WBSChargeCode = this.submissionDetails?.chargeCode;
    this.tempWBSChargeCode = this.submissionDetails?.chargeCode;
    this.WBSDescription = this.submissionDetails?.wbsDescription;
    this.archiveSelectedId = this.submissionDetails?.archiveNumber;
    this.efaSelected = this.submissionDetails?.efa;
    this.isWbsAvailable =
      this.submissionDetails?.isArchiveNotAvailable ?? false;
    this.submissionStatus =
      this.submissionStatusId !== undefined
        ? getStatusEnumKeyByValue(this.submissionStatusId) ?? null
        : null;
  }

  getEFA(): void {
    this.progressIndicator.show();
    this.globalEngagementService
      .getEFARoles()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: GlobalEngEfaModel[]) => {
          this.efaList = response.map((efa) => ({
            value: efa.name,
            label: efa.name,
          }));
          this.progressIndicator.hide();
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  copyToClipboard(value: string | null): void {
    if (value) {
      copyToClipboard(value);
    }
  }

  makeFeildEditable(field: string): void {
    switch (field) {
      case 'engagementTitle':
        if (
          this.permissionObj[
            'isGlobalEngSubmissionDetailsSubmissionTitleEnable'
          ]
        ) {
          this.isEngagementTitleEditable = true;
        }
        break;
      case 'WBSChargeCode':
        if (
          this.permissionObj['isGlobalEngSubmissionDetailsChangeCodeEnable']
        ) {
          this.isWBSEditable = true;
        }
        break;
      case 'archive':
        if (this.permissionObj['isGlobalEngSubmissionDetailsArchiveEnable']) {
          this.isArchiveEditable = true;
        }
        break;
      case 'efa':
        this.isefaEdiitable = true;
    }
  }

  postEngagementDetails(): void {
    const requestBody = {
      SubmissionId: this.submissionDetails?.submissionId,
      EngagementId: this.submissionDetails?.engagementId,
      SubmissionTypeId: this.submissionDetails?.submissionTypeId,
      SubmissionStatusId: this.submissionDetails?.submissionStatusId,
      SubmissionTitle: this.engagementTitle,
      ChargeCode: this.WBSChargeCode,
      WbsDescription: this.WBSDescription,
      Notes: this.notes,
      IsArchiveNotAvailable: this.isWbsAvailable,
      ArchiveNumber: this.submissionDetails?.archiveNumber,
      ArchiveDocumentId: this.submissionDetails?.archiveDocumentId,
      Efa: this.efaSelected,
    };

    this.globalEngagementService
      .putGlobalEngagementSubmission(requestBody)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  wbsCheckboxChanged(): void {
    this.WBSChargeCode = null;
    this.WBSDescription = GlobalEngagementsConstant.EMPTY_STRING;
    this.archiveSelectedId = null;
    this.isWbsAvailable = !this.isWbsAvailable;
    this.postEngagementDetails();
  }

  changeArchive(): void {
    if (this.permissionObj['isGlobalEngSubmissionDetailsArchiveEnable']) {
      this.isArchiveEditable = false;
      this.postEngagementDetails();
    }
  }

  efaseleced(): void {
    this.isefaEdiitable = false;
    this.postEngagementDetails();
  }

  // TODO remove logic when wbs ready
  changeWbs(): void {
    if (this.tempWBSChargeCode?.length === 6) {
      this.archiveSelected = '';
      this.archiveSelectedId = null;
      this.WBSChargeCode = this.tempWBSChargeCode;
      this.WBSDescription = GlobalEngagementsConstant.wbswithDec.find(
        (wbs) => wbs.wbsCode === this.WBSChargeCode
      )?.wbsDescription;
      if (this.WBSDescription) {
      }
      const archivelist = GlobalEngagementsConstant.wbsArchive.find(
        (wbs) => wbs.wbsCode === this.WBSChargeCode
      )?.archiveList;
      if (archivelist) {
        this.archiveList = archivelist;
        if (this.archiveList.length === 1) {
          this.archiveSelected = this.archiveList[0].heading;
          this.archiveSelectedId = this.archiveList[0].value;
        }
      }
      this.isWBSEditable = false;
      this.postEngagementDetails();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
