<div class="submission-type-section margin-bottom-s">
  <div class="d-flex">
    <span class="text-label font-bold-700 font-size-24 line-height-x"
      >Tables
    </span>
  </div>
  <div
    class="margin-top-m margin-bottom-x d-flex justify-content-between align-items-center"
  >
    <div class="dropdownWidth">
      <dds-select
        class="custom-select"
        [disabled]="selectOptions.disabled ?? false"
        [label]="selectOptions.label ?? 'Table name'"
        [labelPosition]="selectOptions.labelPosition ?? emptySpace"
        [list]="tableNameTypes"
        [placeholder]="selectOptions.placeholder ?? emptySpace"
        (valueChanged)="onTableNameSelected()"
        [(ngModel)]="tableNameSelected"
        [size]="selectOptions.size ?? 'md'"
      >
      </dds-select>
    </div>
    <div *ngIf="showAdminExtCommTrainingTable">
      <dds-button
        class="custom-button margin-top-m"
        [theme]="btnOptions.theme ?? emptySpace"
        [kind]="btnOptions.kind ?? emptySpace"
        [size]="btnOptions.size ?? emptySpace"
        [width]="btnOptions.width ?? emptySpace"
        [icon]="btnOptions.icon ?? emptySpace"
        [isIconLeft]="btnOptions.isIconLeft ?? false"
        [role]="btnOptions.role ?? emptySpace"
        [isInverse]="btnOptions.isInverse ?? false"
        [isColorBg]="btnOptions.isColorBg ?? false"
        [isLoading]="btnOptions.isLoading ?? false"
        [ariaLabel]="btnOptions.ariaLabel ?? emptySpace"
        [customClass]="btnOptions.customClass ?? emptySpace"
        [disabled]="btnOptions.disabled ?? false"
        (clicked)="emitAddRecordToExtTable($event)"
        >Add record
        <span class="margin-left-s">
          <ng-container>
            <img
              src="/assets/images/dds-icons/dds-icon-plus.svg"
              alt="Green Tick"
            />
          </ng-container>
        </span>
      </dds-button>
    </div>
  </div>
</div>

<div *ngIf="showMarketOfferingTable">
  <table class="dds-data-table fixed-width-table">
    <thead class="dds-data-table__header">
      <tr>
        <th
          *ngFor="let column of sampleColumns"
          [attr.aria-sort]="
            sortingState?.property === column.name
              ? sortingState?.descending
                ? 'descending'
                : 'ascending'
              : 'none'
          "
          class="dds-data-table__header-cell dds-data-table__header-cell_sorting"
          [ngClass]="{
            'dds-data-table__header-cell_sorted':
              sortingState?.property === column.name,
            'dds-data-table__header-cell_filter-shown':
              isShownFilter && column.name === filteringColumn.name
          }"
          [ddsSticker]="customFilter"
          [stickerPosition]="filterStickerOptions.stickerPosition ?? emptySpace"
          [stickerIsOutsideClick]="
            filterStickerOptions.stickerIsOutsideClick ?? true
          "
          [stickerIndent]="filterStickerOptions.stickerIndent ?? -1"
          [stickerWidth]="filterStickerOptions.stickerWidth ?? '280'"
          [attr.aria-expanded]="isShownFilter"
          aria-haspopup="listbox"
          (click)="toggleFilter(column)"
          (onHidden)="filterOnHidden()"
          #stickerDir="dds-sticker"
          [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
        >
          <div
            class="dds-data-table__content"
            *ngIf="column.name !== 'editIcon'"
            [ngClass]="{
              'padding-bottom-headers':
                column.name === 'offering' ||
                column.name === 'excludedFromSelfReview' ||
                column.name === 'marketOffering'
            }"
          >
            <span>{{ column.header || column.name }}</span>

            <span
              class="dds-icon dds-icon_chevron_down__l__stroke dds-data-table__icon"
              [ngClass]="{ setMargin: column.name !== 'activationStatus' }"
            ></span>

            <span
              *ngIf="sortingState?.property === column.name"
              class="dds-icon dds-data-table__icon"
              aria-label="sorting button"
              [ngClass]="
                sortingState?.descending
                  ? 'dds-icon_sort-down'
                  : 'dds-icon_sort-up'
              "
            >
            </span>

            <span *ngIf="column.filterable == true">
              <span class="dds-icon dds-icon_filter"></span>
            </span>
          </div>
        </th>
      </tr>
    </thead>

    <tbody *ngIf="sortingState !== null" class="dds-data-table__body">
      <ng-container
        *ngFor="
          let row of marketOfferingTableData | sort : sortingState;
          let i = index
        "
      >
        <tr class="dds-data-table__row">
          <td *ngFor="let column of sampleColumns" class="dds-data-table__cell">
            <ng-container *ngIf="column.name === 'editIcon'">
              <span
                #editButton
                class="dds-icon dds-icon_edit__s__filled edit-icon"
                (click)="onEditTypeClick($event, row)"
              ></span>
            </ng-container>
            <ng-container *ngIf="column.name === 'activationStatus'">
              <span
                [ngClass]="{
                  'active-status': row[column.name] === 'Active',
                  'inactive-status': row[column.name] === 'Inactive'
                }"
              >
                {{ row[column.name] }}
              </span>
            </ng-container>
            <ng-container *ngIf="column.name === 'excludedFromSelfReview'">
              <span
                *ngFor="let item of row[column.name]"
                class="containerForSubmissionType"
              >
                {{ item }}
              </span>
            </ng-container>
            <ng-container *ngIf="column.name === 'totalFeeThresholds'">
              <span [ngClass]="{ naType: !row[column.name] }">
                {{ row[column.name] || "N/A" }}
              </span>
            </ng-container>
            <ng-container *ngIf="column.name === 'reviewerAvailability'">
              <span>
                <ng-container *ngIf="row[column.name]; else dashIcon">
                  <img
                    src="/assets/images/dds-icons/dds-green-tick-icon.svg"
                    alt="Green Tick"
                  />
                </ng-container>
                <ng-template #dashIcon>
                  <img
                    src="/assets/images/dds-icons/dds-dash-icon.svg"
                    alt="Green Tick"
                  />
                </ng-template>
              </span>
            </ng-container>
            <ng-container *ngIf="shouldDisplayColumn(column.name)">
              {{ row[column.name] }}
            </ng-container>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <ng-template #customFilter>
    <dds-filter
      [items]="filterData"
      (sorted)="filterSorted(isAscending)"
      [theme]="filterOptions.theme ?? emptySpace"
      (cancel)="filterHide()"
      (apply)="filterApply(filteringColumn.searchValue)"
      [template]="customFilterTemp"
      #ddsFilter
    ></dds-filter>
  </ng-template>

  <ng-template #customFilterTemp let-ctx="ctx">
    <span class="dds-filter__title dds-filter__section">Sorting</span>
    <div class="dds-filter__container dds-filter__container_sorting">
      <button
        class="dds-filter__item dds-filter__section dds-filter__section_sorting"
        (click)="filterSorted(true)"
      >
        <span class="dds-filter__icon dds-icon dds-icon_sort-up"></span>
        <span class="dds-filter__text">Sort A–Z</span>
      </button>
      <button
        class="dds-filter__item dds-filter__section dds-filter__section_sorting"
        (click)="filterSorted(false)"
      >
        <span class="dds-filter__icon dds-icon dds-icon_sort-down"></span>
        <span class="dds-filter__text">Sort Z–A</span>
      </button>
    </div>

    <ng-container>
      <span class="dds-filter__title dds-filter__section">Filter</span>
      <dds-search
        class="dds-filter__search dds-filter__section"
        [isInverse]="ctx.isInverse"
        [ariaLabel]="searchOptions.ariaLabel ?? emptySpace"
        [(ngModel)]="filteringColumn.searchValue"
        (keydown)="ctx.tabOut(searchField, $event)"
        #searchField
      ></dds-search>

      <div class="dds-filter__footer dds-filter__section">
        <dds-button
          width="full"
          [kind]="ctx.cancelBtnOptions.kind"
          [theme]="ctx.theme"
          [isInverse]="ctx.isInverse"
          class="dds-filter__btn dds-filter__item"
          (clicked)="filterHide()"
          [ariaLabel]="options.ariaLabel ?? emptySpace"
          >Cancel</dds-button
        >
        <dds-button
          width="full"
          [theme]="ctx.theme"
          [isInverse]="ctx.isInverse"
          class="dds-filter__btn dds-filter__item"
          [(ngModel)]="filteringColumn.searchValue"
          (clicked)="filterApply(filteringColumn.searchValue)"
          (keydown)="ctx.tabOut(lastBtn, $event)"
          [disabled]="!filteringColumn.searchValue"
          #lastBtn
          >Apply</dds-button
        >
      </div>
    </ng-container>
  </ng-template>
  <ng-template #noFilter></ng-template>
</div>

<!-- Admin Ext Comm Table -->
<app-extcomm-training-table
  *ngIf="showAdminExtCommTrainingTable"
  [addRecordToTable]="addRecordToTable"
></app-extcomm-training-table>
