import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GLOBAL_ENGAGEMENTS_ROUTES } from './global-engagements.states';
import { RouterModule } from '@angular/router';
import { InitiateGlobalEngagementsComponent } from './initiate-global-engagements/initiate-global-engagements.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EclipseCommonModule } from '../common/common.module';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { GlobalEngagementComponent } from './global-engagement/global-engagement.component';
import { GlobalEngDetailsComponent } from './global-engagement/global-eng-details/global-eng-details.component';
import { EngagementSubmissionComponent } from './global-engagement/engagement-submission/engagement-submission.component';
import { EngagementDocumentsComponent } from './global-engagement/engagement-submission/engagement-documents/engagement-documents.component';
import { EngagementTableComponent } from './global-engagement/engagement-submission/engagement-table/engagement-table.component';
import { EngagementDocumentLinkComponent } from './global-engagement/engagement-submission/engagement-document-link/engagement-document-link.component';
import { EngagementDocumentEditComponent } from './global-engagement/engagement-submission/engagement-document-edit/engagement-document-edit.component';
import { IntakeModule } from '../intake/intake.module';
import { EngagementDocumentUploadComponent } from './global-engagement/engagement-submission/engagement-document-upload/engagement-document-upload.component';
import { DiscontinuePopupComponent } from './global-engagement/engagement-submission/discontinue-popup/discontinue-popup.component';
import { GlobalEngExportPdfComponent } from './global-engagement/global-eng-export-pdf/global-eng-export-pdf.component';


@NgModule({
  declarations: [
    InitiateGlobalEngagementsComponent,
    GlobalEngagementComponent,
    GlobalEngDetailsComponent,
    EngagementSubmissionComponent,
    EngagementDocumentsComponent,
    EngagementTableComponent,
    EngagementDocumentLinkComponent,
    EngagementDocumentEditComponent,
    EngagementDocumentUploadComponent,
    DiscontinuePopupComponent,
    GlobalEngExportPdfComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    EclipseCommonModule,
    ReactiveFormsModule,
    LibModule,
    RouterModule.forChild(GLOBAL_ENGAGEMENTS_ROUTES),
  ],
})

export class GlobalEngagementsModule {}