import { Component } from '@angular/core';
import {
  InitiateNavigation,
  tabContainerOptions,
  tabOptions1,
  tabOptions2,
} from '../opportunity-summary-settings';
import { SubmissionService } from '../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import {
  FeeType,
  OpportunityDetailsEdit,
} from '../../../common/models/opportunityDetailsEdit';
import { MultiSelectItem } from '@usitsdasdesign/dds-ng/multi-select';
import { DatePipe } from '@angular/common';
import { IntakeConstant } from '../../constants/intake.constant';
import { TabOptions, TabsOptions } from '@usitsdasdesign/dds-ng/tabs';
import {
  groupToolTipOptions,
  opportunityComplexityOptionsOriginal,
  productAndToolsOptionsOriginal,
  sensitiveDataOptionsOriginal,
  serviceInvolvementOptions,
} from '../../opportunity-details/complexity-questions/complexity-questions-setting';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ServiceDeliveryComponent } from '../../opportunity-details/oportunity-detail-right-panel/service-delivery/service-delivery.component';
import { OpportunityDetailsNavModel } from '../../../common/models/opportunity-details.model';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SummaryArchivePopupComponent } from './summary-archive-popup/summary-archive-popup.component';
import { StatusEnum } from '../../constants/status-enum';
import {
  ArchiveDetailsModel,
  PreviouslyConnectedArchiveCountResponse,
} from '../../../common/models/archive-details.model';
import { ArchiveClosedModel } from '../../../common/models/archive-save.model';
import { AdobeAnalyticsService } from '../../../common/services/adobe-analytics.service';
import { SecurityWebapiService } from '../../../http/security/security-webapi.service';
import { PermissionCheck } from '../../../common/validations/PermissionCheck';

@Component({
  selector: 'app-opportunity-summary',
  templateUrl: './opportunity-summary.component.html',
  styleUrl: './opportunity-summary.component.less',
  providers: [DatePipe],
})
export class OpportunitySummaryComponent extends PermissionCheck {
  opportunityDetailTitle: string | null = '';
  opportunityNumber: string = '';
  monthDuration: number = 0;
  tabContainerOptions: TabsOptions = tabContainerOptions;
  tabOptions1: TabOptions = tabOptions1;
  tabOptions2: TabOptions = tabOptions2;
  unsubscriber$ = new Subject();
  oportunityDetailsInfo: OpportunityDetailsEdit = {
    opportunityNumber: '',
    crossBusinessOpportunity: '',
    projectStartDate: '',
    projectEndDate: '',
    leadEngagementPartner: '',
    totalFees: '',
    feeType: [],
    clientNumber: '',
    opportunityDescription: '',
  };
  startDate: string | null = '';
  endDate: string | null = '';
  feeTypeList: MultiSelectItem[] = [];
  feeTypeLabel: string = IntakeConstant.FEE_TYPE_LABEL;
  defaultForEmptyFields: string = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
  durationLabel: string = IntakeConstant.DURATION_LABEL;
  businessLabel: string = IntakeConstant.CROSS_BUSINESS_LABEL;
  descriptionLabel: string = IntakeConstant.DESCRIPTION_LABEL;
  archiveLabel: string = IntakeConstant.ARCHIVE_LABEL;
  documentSubmissionLabel: string = IntakeConstant.DOCUMENT_SUBMISSION_LABEL;
  independenceLabel: string = IntakeConstant.INDEPENDENCE_LABEL;
  teamLabel: string = IntakeConstant.TEAM_LABEL;
  clientLabel: string = IntakeConstant.CLIENT_LABEL;
  dateFormat: string = IntakeConstant.DATE_FORMAT;
  clientNumber: string = '';
  greenLinedButton: ButtonOptions = InitiateNavigation;

  opportunityComplexityLabel: string =
    IntakeConstant.OPPORTUNITY_COMPLEXITY_LABEL;
  productAndToolsLabel: string = IntakeConstant.PRODUCTS_AND_TOOLS_LABEL;
  thirdPartyLabel: string = IntakeConstant.THIRD_PARTY_LABEL;
  sensitiveDataLabel: string = IntakeConstant.SENSITIVE_DATA_LABEL;
  dataInDEloitteLabel: string = IntakeConstant.DATA_INDELOITTE_LABEL;
  memberFirmInvolvementLabel: string =
    IntakeConstant.MEMBER_FIRM_INVOLVEMENT_LABEL;
  useOfExistingLabel: string = IntakeConstant.USE_OF_EXISTING_LABEL;
  deloitteSowLabel: string = IntakeConstant.DELOITTE_SOW_LABEL;
  opportunityComplexityOptionsOriginal = opportunityComplexityOptionsOriginal;
  regulatoryConsiderationTooltip =
    'Identify regulatory considerations such as HIPAA, GDPR, ADA, Export Controls';
  groupToolTipOptions = groupToolTipOptions;
  viewAndEditLabel: string = IntakeConstant.VIEW_EDIT_LABEL;
  productAndToolsOptionsOriginal = productAndToolsOptionsOriginal;
  selectedProductAndTools: string = '';
  selectedOpportunityComplexity: string = '';
  sensitiveDataOptionsOriginal = sensitiveDataOptionsOriginal;
  selectedSensitiveData: string = '';
  thirdPartyInvolvement: string = '';
  memberFirmInvolvement: string = '';
  dataInDeloittesOrDeloittesHostedEnvironment: string = '';
  useOfExistingMSAsOrContractMakerGBTs: string = '';
  deloitteStandardELSOWTemplateReferred: string = '';
  opportunityDescription: string = '';
  memberFirmTooltip: string = '';
  existingMSAsOrContractMakerGBTsTooltip: string = '';
  regulatoryText: string = IntakeConstant.REGULATORY_CONSIDERATION;
  documentSubmissionList: OpportunityDetailsNavModel[] = [];
  NO: string = IntakeConstant.NO;
  YES: string = IntakeConstant.YES;
  containCyberQuestions: boolean = false;
  deloitteIsrael: string | null | undefined = '';
  servicesDisruptClient: string | null | undefined = '';
  serviceInvolvement: string | null | undefined = '';
  deloitteIsraelLabel: string = IntakeConstant.ISREAL_INVOLVEMENT;
  servicesDisruptClientLabel: string = IntakeConstant.POTENTIAL_CLIENT_LABEL;
  serviceInvolvementLabel: string = IntakeConstant.SERVICE_INVOLVEMENT;
  serviceInvolvementOptions = serviceInvolvementOptions;
  deloitteIsraelExplain: string = '';
  hasERS230: string = '';
  infraSecurityconsult: string = '';
  archiveName: string = '';
  independanceService: string = '';
  independanceServiceDescription: string = '';
  independanceServiceSapdgmfId: string = '';
  archiveNumber: string = '';
  archiveDescription: string = '';
  archiveStatus: string = '';
  partner: string = '';
  previousConnectedArchiveCount: number = 0;
  previousConnectedArchiveLabel: string =
    IntakeConstant.ARCHIVE_CONNECTED_LABEL;
  wbsNumber: string = '';
  archiveUrl: string = '';
  swiftProjectName: string = '';
  swiftStartDate: string = '';
  swiftEndDate: string = '';
  swiftPPMD: string = '';
  opptyInvolvedInUS: string = '';
  requiredConflictProcBylep: string = '';
  requiredConflictProcedure: string = '';
  resultofConflictCheck: string = '';

  constructor(
    private readonly datePipe: DatePipe,
    private readonly intakeDetailService: SubmissionService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly modalService: ModalService,
    private readonly analytics: AdobeAnalyticsService,
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
  }

  ngOnInit(): void {
    this.intakeDetailService.enableAuditTrail(true);
    this.analytics.trackPage('Opportunity summary');
    this.route.queryParams.subscribe((params) => {
      this.opportunityNumber = params['id'];
      this.opportunityDetailTitle = params['title'];
    });
    if (this.opportunityNumber) {
      this.getOpportunityDetailsValues(this.opportunityNumber);
      this.getOpportunityDetails(this.opportunityNumber);
    }

    this.getComplexityData();
    this.getNavDetails(this.opportunityNumber);
  }

  getComplexityData(): void {
    this.intakeDetailService
      .getComplexityResponse(this.opportunityNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        if (data) {
          this.selectedOpportunityComplexity = data?.opportunityComplexity
            ? data?.opportunityComplexity
            : '';
          this.selectedProductAndTools = data?.productsAndTools
            ? data?.productsAndTools
            : '';
          this.selectedSensitiveData = data?.sensitiveData
            ? data?.sensitiveData
            : '';
          this.thirdPartyInvolvement = data?.thirdPartyInvolvement
            ? data?.thirdPartyInvolvement
            : '';
          this.memberFirmInvolvement = data?.memberFirmInvolvement
            ? data?.memberFirmInvolvement
            : '';
          this.dataInDeloittesOrDeloittesHostedEnvironment =
            data?.dataInDeloittesOrDeloittesHostedEnvironment
              ? data?.dataInDeloittesOrDeloittesHostedEnvironment
              : '';
          this.deloitteStandardELSOWTemplateReferred =
            data?.deloitteStandardELSOWTemplateReferred
              ? data?.deloitteStandardELSOWTemplateReferred
              : '';
          this.useOfExistingMSAsOrContractMakerGBTs =
            data?.useOfExistingMSAsOrContractMakerGBTs
              ? data?.useOfExistingMSAsOrContractMakerGBTs
              : '';
          this.memberFirmTooltip = data?.memberFirmNames ?? '';

          this.regulatoryConsiderationTooltip =
            data?.regulatoryConsiderations ?? '';

          this.existingMSAsOrContractMakerGBTsTooltip =
            data?.generalBusinessTerms ?? '';

          this.deloitteIsrael = data?.deloitteIsrael;

          this.servicesDisruptClient = data?.servicesDisruptClient;

          this.serviceInvolvement = data?.serviceInvolvement;

          this.deloitteIsraelExplain = String(data?.deloitteIsraelExplain);

          this.hasERS230 = String(data?.hasERS230);

          this.infraSecurityconsult = String(data?.infraSecurityconsult);
        }
      });
  }

  getOpportunityDetailsValues(id: string): void {
    this.intakeDetailService
      .getOpportunityDetailsInfo(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: OpportunityDetailsEdit) => {
          this.oportunityDetailsInfo = data;
          this.clientNumber = data.clientNumber;
          if (
            this.oportunityDetailsInfo?.feeType &&
            this.oportunityDetailsInfo?.feeType.length > 0 &&
            this.oportunityDetailsInfo?.feeType[0]?.feeTypeName
          )
            this.feeTypeList = this.oportunityDetailsInfo?.feeType.map(
              (feeType: FeeType) => ({
                label: feeType?.feeTypeName,
                value: true,
              })
            );
          this.startDate = this.oportunityDetailsInfo?.projectStartDate
            ? this.datePipe.transform(
                this.oportunityDetailsInfo?.projectStartDate,
                this.dateFormat
              )
            : '';
          this.endDate = this.oportunityDetailsInfo?.projectEndDate
            ? this.datePipe.transform(
                this.oportunityDetailsInfo?.projectEndDate,
                this.dateFormat
              )
            : '';
          this.monthDuration =
            this.endDate && this.startDate
              ? parseInt(this.endDate?.split('/')[0]) -
                parseInt(this.startDate?.split('/')[0])
              : 0;
          this.opptyInvolvedInUS = data?.opptyInvolvedInUS ?? '';
          this.requiredConflictProcBylep =
            data?.requiredConflictProcBylep ?? '';
          this.requiredConflictProcedure =
            data?.requiredConflictProcedure ?? '';
          this.resultofConflictCheck = data?.resultofConflictCheck ?? '';
        },
      });
  }

  moveBackToEdit(): void {
    this.router.navigate(
      ['/submission/opportunity-details', this.opportunityNumber],
      {}
    );
  }

  openDocumentLinkModel(modalBtn?: any): void {
    this.modalService.open(ServiceDeliveryComponent, {
      documentInitiatedDetails: {
        opportunityNumber: this.opportunityNumber,
      },
    });
  }

  getNavDetails(id: string): void {
    this.intakeDetailService
      .getIntakeDetails(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: OpportunityDetailsNavModel[]) => {
          this.documentSubmissionList = data;
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  makeGetCyberCall(): void {
    this.containCyberQuestions = true;
  }

  getOpportunityDetails(id: string): void {
    this.intakeDetailService
      .getOpportunityDetailForSummary(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: OpportunityDetailsEdit) => {
          this.opportunityDescription = String(data?.opportunityDescription);
          if (!this.opportunityDescription) {
            this.opportunityDescription =
              IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
          }
          this.archiveName = data?.archiveName ?? '';
          this.archiveNumber = data?.archiveNumber ?? '';
          this.partner = data?.partner ?? '';
          this.swiftProjectName = data?.swiftDetails?.projectName ?? '';
          this.swiftStartDate = data?.swiftDetails?.projectStartDate ?? '';
          this.swiftEndDate = data?.swiftDetails?.projectEndDate ?? '';
          this.swiftPPMD = data?.swiftDetails?.projectPPMD ?? '';
          if (this.archiveNumber) {
            this.getArchiveStatus();
          }
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  receiveIndependenceData(data: {
    services: string;
    servicesDescription: string;
    sapdgmfId: string;
  }): void {
    this.independanceService = data.services;
    this.independanceServiceDescription = data.servicesDescription;
    this.independanceServiceSapdgmfId = data.sapdgmfId;
  }

  independanceDescClicked(): void {
    const independanceServiceLink =
      IntakeConstant.INDEPENDENCE_DESC_LINK + this.independanceServiceSapdgmfId;
    if (this.independanceServiceSapdgmfId)
      window.open(independanceServiceLink, '_blank');
  }

  navigateToInitiateSubmission(): void {
    this.router.navigate(['submission']);
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  openArchivePopup(): void {
    let archivepopUpRef = this.modalService.open(SummaryArchivePopupComponent, {
      dataOpportunityId: this.opportunityNumber,
      wbsChargeCode: this.wbsNumber,
      initialSelectedArchive: this.archiveNumber,
    });
    archivepopUpRef.onClosed().subscribe((response: void) => {
      this.getOpportunityDetails(this.opportunityNumber);
    });
  }

  getPreviouslyConnectedArchive(): void {
    this.intakeDetailService
      .getPreviouslyConnectedArchiveCount(this.opportunityNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: PreviouslyConnectedArchiveCountResponse) => {
          this.previousConnectedArchiveCount =
            data?.previouslyConnectedArchiveCount ?? 0;
        },
      });
  }

  openArchiveUrl(): void {
    if (this.archiveUrl) {
      window.open(this.archiveUrl, '_blank');
    }
  }

  getArchiveStatus(): void {
    this.intakeDetailService
      .getArchiveDetails(this.archiveNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: ArchiveDetailsModel) => {
          this.archiveStatus = data?.archiveStatus ?? '';
          this.archiveDescription = data?.archiveDescription ?? '';
          this.archiveUrl = data?.archiveUrl ?? '';
          this.wbsNumber = data?.wbsNumber ?? '';
          if (data) {
            this.getPreviouslyConnectedArchive();
          }
        },
        error: (error) => {
          console.error('Error fetching archive details', error);
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
