import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StringGenerator {
    public allEmployeeRoles: any;
    public baseUrl: string = "";

    private letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    private lettersAndDigits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    private smallLetters = "abcdefghijklmnopqrstuvwxyz";

    constructor() { }

    public generateGuid() {
        return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
            this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }


    // type - type of source which we will use as default set of symbols.
    // if type:
    // - 1 - letters;
    // - 2 - lettersAndDigits;
    // - 3 - smallLetters.
    public generate(length: number, type: number | null) {
        var possible;

        if (type && type == 3) {
            possible = this.smallLetters;
        }
        else if (type && type === 1) {
            possible = this.letters;
        }
        else {
            possible = this.lettersAndDigits;
        }

        var text = "";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    private s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
}