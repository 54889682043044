<progress-indicator></progress-indicator>
<app-tiles-group (tileSelectedEvent)="onTileSelected($event)" 
                 [tileCodeDetails]="ncaTileCodeDetails" 
                 [tilesCount]="ncaDashboardTilesCountModel" 
                 [selectedTileCode]="selectedTileCode"
                 [dashboardType]="dashboard"></app-tiles-group>
<div class="start-page-tabs-nca" *ngIf="ncaDashboardTilesCountModel">
    <startpage-grid-area [currentDashboardTileCode]="currentDashboardTileCode"
                         [currentTileNameForGrid]="selectedTileCode"
                         [gridColumns]="gridColumns"
                         [model]="model"
                         [gridTitle]="gridTitle"
                         [dashboardfilters]="ncaDashboardfilters"
                         [dropdownItems]="dropdownItems"
                         [buttonOptionsList]="buttonOptionsList"
                         (buttonSelectedEvent)="buttonSelected($event)"
                         (gridUpdatedEvent)="gridUpdatedEvent($event)">
    </startpage-grid-area>
    <app-new-pagination-and-grid [tileCountforPage]="this.countforPagination" [pageUI]="this.gotoPage+1" (pageChanged)="onPageChanged($event)" (valueChanged)="onValueChanged($event)"></app-new-pagination-and-grid>
</div>
