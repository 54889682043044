import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  YES_NO_RELATED_CONTENT_RADIO_GROUP_VALUE,
  BENCHMARK_RELATED_CONTENT_RADIO_GROUP_VALUE,
  INTERNAL_EXTERNAL_CONTEST_RELATED_CONTENT_RADIO_GROUP_VALUE,
  RADIO_GROUP_OPTIONS_FOR_ALCOHOL_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_BENCHMARKS_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_DIVERSITY_EQUITY_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_EXTERNAL_CONTEST_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_INCENTIVE_SURVEY_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_INTERNAL_CONTEST_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_INTERNAL_EXTERNAL_CONTEST_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_LCSP_SURVEY_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_LIVE_STREAM_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_OTHER_PARTIES_PARTICIPATION_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_PODCAST_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_PUBLISHED_IN_THIRD_PARTY_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_SPONSORSHIP_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_SURVEY_EXTERNAL_PARTIES_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_SURVEY_TOOL_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_SWEEPSTAKES_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_TYPE_OF_SURVEY_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_EXTERNAL_SPEAKER_AGREEMENT_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_IS_RECORDED_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_CLIENT_USECASE_SUCCESS_STORIES_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_RELATED_TO_SPONSORSHIP_RELATED_CONTENT,
  RADIO_GROUP_OPTIONS_FOR_DEMO_RELATED_CONTENT,
  SPONSORSHIP_RELATED_CONTENT_RADIO_GROUP_VALUE,
  TYPE_OF_SURVEY_2_CONTENT_RADIO_GROUP_VALUE,
  TYPE_OF_SURVEY_CONTENT_RADIO_GROUP_VALUE,
  RADIO_GROUP_OPTIONS_FOR_SUBMISSION_RELATED_TO_CONFERENCE,
} from './communication-attributes.helper';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { Subject, takeUntil } from 'rxjs';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import {
  ExtCommAttributesDataTransformedModel
} from '../../../../common/models/external-communication.model';
import { IAutosaverOptions, Saver } from '../../../../common/autosaver/saver';
import { AutosaverService } from '../../../../common/autosaver/autosaver.service';
import { RadioButton } from '@usitsdasdesign/dds-ng/radio';

@Component({
  selector: 'app-communication-attributes',
  templateUrl: './communication-attributes.component.html',
  styleUrl: './communication-attributes.component.less',
})
export class CommunicationAttributesComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() isControlEnable: boolean = true;
  @Input() submissionId!: number;
  @Input()
  communicationAttributesInput: ExtCommAttributesDataTransformedModel =
    {} as ExtCommAttributesDataTransformedModel;
  @Output() commAttrRequiredFieldsFilled: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  showThirdPartyPublisedInput: boolean = false;
  showExternalPartiesSubQuestions: boolean = false;
  showSweepStakesSubQuestions: boolean = false;
  showInternalContestSubQuestions: boolean = false;
  showExternalContestSubQuestions: boolean = false;
  availableFields: any = {} as any;

  textareaOptions: any =
    ExternalCommunicationConstant.TEXT_AREA_SETTINGS_GLOBAL;

  alcohol_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_ALCOHOL_RELATED_CONTENT;

  yes_no_related_content_radio_values: any =
    YES_NO_RELATED_CONTENT_RADIO_GROUP_VALUE;

  podcast_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_PODCAST_RELATED_CONTENT;

  other_parties_participation_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_OTHER_PARTIES_PARTICIPATION_RELATED_CONTENT;

  live_stream_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_LIVE_STREAM_RELATED_CONTENT;

  published_in_third_party_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_PUBLISHED_IN_THIRD_PARTY_RELATED_CONTENT;

  submission_related_to_conference_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_SUBMISSION_RELATED_TO_CONFERENCE;

  sponsorship_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_SPONSORSHIP_RELATED_CONTENT;

  survey_external_parties_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_SURVEY_EXTERNAL_PARTIES_RELATED_CONTENT;

  type_of_survey_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_TYPE_OF_SURVEY_RELATED_CONTENT;

  type_of_survey_related_content_radio_values: any =
    TYPE_OF_SURVEY_CONTENT_RADIO_GROUP_VALUE;

  survey_tool_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_SURVEY_TOOL_RELATED_CONTENT;

  type_of_survey_2_related_content_radio_values: any =
    TYPE_OF_SURVEY_2_CONTENT_RADIO_GROUP_VALUE;

  lcsp_survey_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_LCSP_SURVEY_RELATED_CONTENT;

  benchmark_related_content_radio_values: any =
    BENCHMARK_RELATED_CONTENT_RADIO_GROUP_VALUE;

  benchmark_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_BENCHMARKS_RELATED_CONTENT;

  incentive_survey_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_INCENTIVE_SURVEY_RELATED_CONTENT;

  sweepstakes_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_SWEEPSTAKES_RELATED_CONTENT;

  diversity_equity_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_DIVERSITY_EQUITY_RELATED_CONTENT;

  internal_external_contest_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_INTERNAL_EXTERNAL_CONTEST_RELATED_CONTENT;

  internal_external_contest_radio_values: any =
    INTERNAL_EXTERNAL_CONTEST_RELATED_CONTENT_RADIO_GROUP_VALUE;

  internal_contest_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_INTERNAL_CONTEST_RELATED_CONTENT;

  external_contest_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_EXTERNAL_CONTEST_RELATED_CONTENT;

  external_speaker_agreement_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_EXTERNAL_SPEAKER_AGREEMENT_RELATED_CONTENT;

  isrecorded_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_IS_RECORDED_RELATED_CONTENT;

  client_usecase_success_stories_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_CLIENT_USECASE_SUCCESS_STORIES_RELATED_CONTENT;

  related_to_sponsorship_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_RELATED_TO_SPONSORSHIP_RELATED_CONTENT;

  demo_related_content_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_DEMO_RELATED_CONTENT;

  sponsorship_related_content_radio_values: RadioButton[] =
    SPONSORSHIP_RELATED_CONTENT_RADIO_GROUP_VALUE;

  internalContest = ExternalCommunicationConstant.INTERNALCONTEST;
  externalContest = ExternalCommunicationConstant.EXTERNALCONTEST;
  YES = ExternalCommunicationConstant.YES;
  NO = ExternalCommunicationConstant.NO;
  EMPTYSPACE = ExternalCommunicationConstant.EMPTY_SPACE;

  private readonly unsubscriber$: Subject<void> = new Subject<void>();

  public communicationAttributeSection!: Saver;

  constructor(
    private readonly externalCommService: ExternalCommunicationService,
    private readonly autosaverService: AutosaverService
  ) {}

  ngOnInit(): void {
    let autoSaverOptions = {
      delay: 500,
      onSuccess: (saver: any, result: any) => {},
    } as IAutosaverOptions;

    this.communicationAttributeSection = this.autosaverService.newSaver(
      'communicationAttributesInput',
      this,
      async (model) => this.saveAttributes(model),
      autoSaverOptions
    );
    this.communicationAttributeSection.start();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submissionId']) {
      this.getSubmissionCommunicationAttributes();
    }
  }


  getSubmissionCommunicationAttributes(): void {
    this.externalCommService
      .getSubmissionCommunicationAttributes(this.submissionId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: ExtCommAttributesDataTransformedModel) => {
          if (response) {
            this.communicationAttributesInput = response;
            this.checkRequiredFields();
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  saveAttributes(d: any): void {
    this.externalCommService
      .postSubmissionCommunicationAttributes(this.communicationAttributesInput)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {},
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  onPublishedInThirdPartyChange(event: any): void {
    if (event === this.NO) {
      this.communicationAttributesInput.publishedLocation = null;
      this.communicationAttributesInput.isRelatedToConference = null;
    }
    this.checkRequiredFields();
  }

  onSurveyExternalPartiesChange(event: any): void {
    if (event === this.NO) {
      this.communicationAttributesInput.typeOfSurvey = null;
      this.communicationAttributesInput.typeOfSurvey2 = null;
      this.communicationAttributesInput.lcspSurveyApproval = null;
      this.communicationAttributesInput.producingBenchMarksFor = null;
      this.communicationAttributesInput.isIncentiveForSurvey = null;
      this.communicationAttributesInput.surveyID = null;
    }
    this.checkRequiredFields();
  }

  onIsInvolvesSweepstakesChange(event: any): void {
    if (event === this.NO) {
      this.communicationAttributesInput.hasWorkedWithOGC = null;
      this.communicationAttributesInput.isLessThanPriceThreshold = null;
      this.communicationAttributesInput.isDiversityEquity = null;
      this.communicationAttributesInput.isInternalExternalContest = null;
    }
    this.checkRequiredFields();
  }

  onIsInternalExternalContestChange(event: any): void {
    if (event === this.internalContest) {
      this.communicationAttributesInput.hasWorkedWithOGC = null;
    } else {
      this.communicationAttributesInput.isLessThanPriceThreshold = null;
    }
    this.checkRequiredFields();
  }

  checkRequiredFields(): void {
    this.checkAvailableFields();
    if (this.communicationAttributesInput.isSurveyExternalParties === this.NO) {
      delete this.availableFields.typeOfSurvey;
      delete this.availableFields.typeOfSurvey2;
      delete this.availableFields.lcspSurveyApproval;
      delete this.availableFields.producingBenchMarksFor;
      delete this.availableFields.isIncentiveForSurvey;
      delete this.availableFields.surveyID;
    }
    if (this.communicationAttributesInput.isPublishedInThirdParty === this.NO) {
      delete this.availableFields.publishedLocation;
      delete this.availableFields.isRelatedToConference;
    }

    if (this.communicationAttributesInput.isInvolvesSweepstakes === this.NO) {
      delete this.availableFields.hasWorkedWithOGC;
      delete this.availableFields.isLessThanPriceThreshold;
      delete this.availableFields.isDiversityEquity;
      delete this.availableFields.isInternalExternalContest;
    }
    if (
      this.communicationAttributesInput.isInternalExternalContest ===
      this.internalContest
    ) {
      delete this.availableFields.hasWorkedWithOGC;
    } else {
      delete this.availableFields.isLessThanPriceThreshold;
    }
    const allrequiredFieldsFilled = Object.values(this.availableFields).some(
      (value: any) =>
        value === null || value === undefined || value.length === 0
    );
    this.commAttrRequiredFieldsFilled.emit(!allrequiredFieldsFilled);
  }

  checkAvailableFields(): void {
    if (
      this.communicationAttributesInput
        .isEnableIsIncludesAlcoholSweepstakesAwards
    ) {
      this.availableFields.isIncludesAlcoholSweepstakesAwards =
        this.communicationAttributesInput.isIncludesAlcoholSweepstakesAwards;
    }
    if (this.communicationAttributesInput.isEnablePodcastInvolvesOtherParties) {
      this.availableFields.podcastInvolvesOtherParties =
        this.communicationAttributesInput.podcastInvolvesOtherParties;
    }
    if (this.communicationAttributesInput.isEnableVideoInvolvesOtherParties) {
      this.availableFields.videoInvolvesOtherParties =
        this.communicationAttributesInput.videoInvolvesOtherParties;
    }
    if (this.communicationAttributesInput.isEnableIsLiveStreamed) {
      this.availableFields.isLiveStreamed =
        this.communicationAttributesInput.isLiveStreamed;
    }
    if (this.communicationAttributesInput.isEnableIsPublishedInThirdParty) {
      this.availableFields.isPublishedInThirdParty =
        this.communicationAttributesInput.isPublishedInThirdParty;
    }
    if (this.communicationAttributesInput.isEnablePublishedLocation) {
      this.availableFields.publishedLocation =
        this.communicationAttributesInput.publishedLocation;
    }
    if (
      this.communicationAttributesInput
        .isEnableIsUsingSponsorOrThirdPartyTemplate
    ) {
      this.availableFields.isUsingSponsorOrThirdPartyTemplate =
        this.communicationAttributesInput.isUsingSponsorOrThirdPartyTemplate;
    }
    if (this.communicationAttributesInput.isEnableIsSurveyExternalParties) {
      this.availableFields.isSurveyExternalParties =
        this.communicationAttributesInput.isSurveyExternalParties;
    }
    if (this.communicationAttributesInput.isEnableTypeOfSurvey) {
      this.availableFields.typeOfSurvey =
        this.communicationAttributesInput.typeOfSurvey;
    }
    if (this.communicationAttributesInput.isEnableTypeOfSurvey2) {
      this.availableFields.typeOfSurvey2 =
        this.communicationAttributesInput.typeOfSurvey2;
    }
    if (this.communicationAttributesInput.isEnableLCSPSurveyApproval) {
      this.availableFields.lcspSurveyApproval =
        this.communicationAttributesInput.lcspSurveyApproval;
    }
    if (this.communicationAttributesInput.isEnableProducingBenchMarksFor) {
      this.availableFields.producingBenchMarksFor =
        this.communicationAttributesInput.producingBenchMarksFor;
    }
    if (this.communicationAttributesInput.isEnableIsIncentiveForSurvey) {
      this.availableFields.isIncentiveForSurvey =
        this.communicationAttributesInput.isIncentiveForSurvey;
    }
    if (this.communicationAttributesInput.isEnableSurveyID) {
      this.availableFields.surveyID =
        this.communicationAttributesInput.surveyID;
    }
    if (this.communicationAttributesInput.isEnableIsInvolvesSweepstakes) {
      this.availableFields.isInvolvesSweepstakes =
        this.communicationAttributesInput.isInvolvesSweepstakes;
    }
    if (this.communicationAttributesInput.isEnableIsDiversityEquity) {
      this.availableFields.isDiversityEquity =
        this.communicationAttributesInput.isDiversityEquity;
    }
    if (this.communicationAttributesInput.isEnableIsInternalExternalContest) {
      this.availableFields.isInternalExternalContest =
        this.communicationAttributesInput.isInternalExternalContest;
    }
    if (this.communicationAttributesInput.isEnableIsLessThanPriceThreshold) {
      this.availableFields.isLessThanPriceThreshold =
        this.communicationAttributesInput.isLessThanPriceThreshold;
    }
    if (this.communicationAttributesInput.isEnableIsAskedSignSpeakerAgreement) {
      this.availableFields.isAskedSignSpeakerAgreement =
        this.communicationAttributesInput.isAskedSignSpeakerAgreement;
    }
    if (this.communicationAttributesInput.isEnableIsRecorded) {
      this.availableFields.isRecorded =
        this.communicationAttributesInput.isRecorded;
    }
    if (
      this.communicationAttributesInput.isEnableIsClientUseCaseAndSuccessStories
    ) {
      this.availableFields.isClientUseCaseAndSuccessStories =
        this.communicationAttributesInput.isClientUseCaseAndSuccessStories;
    }
    if (this.communicationAttributesInput.isEnableIsRelatedToSponsorship) {
      this.availableFields.isRelatedToSponsorship =
        this.communicationAttributesInput.isRelatedToSponsorship;
    }
    if (this.communicationAttributesInput.isEnableIsLabOrDemo) {
      this.availableFields.isLabOrDemo =
        this.communicationAttributesInput.isLabOrDemo;
    }
    if (this.communicationAttributesInput.isEnableIsRelatedToConference) {
      this.availableFields.isRelatedToConference =
        this.communicationAttributesInput.isRelatedToConference;
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
