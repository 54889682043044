<div class="dds-modal-overlay" (click)="closeRelinkModel(navInfo)"></div>
<div class="height-popup">
  <div ddsResize class="dds-modal dds-modal_lg height-popup">
    <div class="dds-modal__header">
      <span class="dds-modal__title">Relink submission</span>
      <dds-button
        size="sm"
        icon="dds-icon_close"
        kind="silent"
        theme="dark"
        ariaLabel="Close modal"
        class="dds-modal__close"
        (clicked)="closeRelinkModel(navInfo)"
      ></dds-button>
    </div>

    <div class="dds-modal__body">
      <div class="margin-top-m margin-bottom-m">
        Relink this submission to another opportunity.
      </div>

      <div class="">
        <div class="search-box-submission relative">
          <dds-search
            [size]="htmlElementSize"
            [customClass]="searchOptions.customClass ?? emptySpace"
            [icon]="searchOptions.icon || 'dds-icon_search'"
            (fieldCleared)="fieldCleared()"
          >
            <input
              ddsSearch
              [placeholder]="searchOptions.placeholder ?? emptySpace"
              [(ngModel)]="selectedOportunity"
              (ngModelChange)="selectOpportunityChanged($event)"
            />
          </dds-search>
          <div *ngIf="isSpinnerVisible" class="spinner-inside-input absolute">
            <dds-spinner
              [size]="spinnerOptions.size ?? emptySpace"
              [theme]="spinnerOptions.theme ?? emptySpace"
              [customClass]="spinnerOptions.customClass ?? emptySpace"
              [isInverse]="spinnerOptions.isInverse ?? false"
              [thickness]="spinnerOptions.thickness ?? emptySpace"
            ></dds-spinner>
          </div>
        </div>
        <div
          *ngIf="showDropdown || notFound"
          class="list-of-oppportunities d-flex flex-row-column flex-row-column padding-left-xs padding-right-xs margin-top-xs"
          (scroll)="fetchOpportunitySearchResultMore($event)"
        >
          <div *ngIf="!notFound" class="tabs-container">
            <dds-tabs
              [theme]="tabContainerOptions.theme ?? 'primary'"
              [themeType]="tabContainerOptions.themeType ?? 'primary'"
              [size]="tabContainerOptions.size ?? 'md'"
              [isResponsive]="tabContainerOptions.isResponsive ?? true"
              [disabled]="tabContainerOptions.disabled ?? false"
              [ariaLabel]="tabContainerOptions.ariaLabel ?? 'Tabs'"
              [activeIndex]="selectTabIndex"
              class="text-align-left"
            >
              <span *ngFor="let tab of tabs">
                <dds-tab
                  class="padding-right-xs"
                  [label]="tab.label ?? ''"
                  [counter]="tab.count ?? 0"
                  (tabChanged)="tabChanged(tab.code)"
                >
                </dds-tab>
              </span>
            </dds-tabs>
          </div>
          <div *ngFor="let item of opportunityList">
            <div
              class="opportunity-items d-flex flex-row-column"
              (click)="fetchOpportunityDetails(item, 'searchOpportunity')"
            >
              <div
                class="opportunity-header font-size-14 font-bold-600 text-align-left"
              >
                {{ item?.opportunityName }}
                <span class="client-status font-bold-400" *ngIf="item?.stage == 'Closed Lost'">[Lost]</span>
                <span class="client-status font-bold-400" *ngIf="item?.stage == 'Closed Abandoned'">[Abandoned]</span>
              </div>
              <div
                class="opportunity-details font-size-12 text-align-left font-bold-400"
              >
                <span class="detail-heading font-bold-400">ID: </span>
                <span
                  class="details-values font-size-12 text-align-left font-bold-400"
                  >{{ item?.opportunityNumber }}
                </span>
                <span class="separator"> | </span>
                <span class="detail-heading font-bold-400"
                  >Opportunity stage:
                </span>
                <span
                  class="details-values font-size-12 text-align-left font-bold-400"
                  >{{ item?.stage }}</span
                >
                <span class="separator"> | </span>
                <span class="detail-heading font-bold-400">Leader: </span>
                <span
                  class="details-values font-size-12 text-align-left font-bold-400"
                  >{{ item?.leader }}</span
                >
                <span class="separator"> | </span>
                <span class="detail-heading font-bold-400">Client: </span>
                <span
                  class="details-values font-size-12 text-align-left font-bold-400"
                  >{{ item?.client }}</span
                >

                <span
                  *ngIf="item?.isMarkedForDeletion; else swiftClientStatus"
                  class="client-status font-size-12 text-align-left font-bold-400"
                >
                  [{{
                    item?.swiftClientStatus == "Blocked" ||
                    item?.swiftClientStatus == "Duplicate"
                      ? item?.swiftClientStatus + " - " + markForDeletionText
                      : markForDeletionText
                  }}]
                </span>
                <ng-template #swiftClientStatus>
                  <span
                    *ngIf="
                      item?.swiftClientStatus == 'Blocked' ||
                      item?.swiftClientStatus == 'Duplicate'
                    "
                    class="client-status font-size-12 text-align-left font-bold-400"
                  >
                    [{{ item?.swiftClientStatus }}]
                  </span>
                </ng-template>
              </div>
            </div>
          </div>

          <div [innerHTML]="notFound" class="not-found"></div>
        </div>
      </div>
    </div>
    <div
      *ngIf="showOpportunityDetails"
      class="opportunity-details-table margin-top-m"
    >
      <table class="dds-data-table dds-data-table_fixed-header">
        <thead class="dds-data-table__header">
          <tr>
            <th
              *ngFor="let column of opportunityTableColumns"
              class="dds-data-table__header-cell"
              [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
            >
              <div>
                <span>{{ column.header }}</span>
              </div>
              <div class="dds-data-table__content">
                <span> </span>
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="dds-data-table__body">
          <tr
            *ngFor="let row of opportunityDetailsList; index as index"
            class="dds-data-table__row font-size-14 font-bold-400 line-height-sm"
          >
            <td
              *ngFor="let column of opportunityTableColumns"
              class="table-cell"
            >
              <span
                *ngIf="column.name === 'numberOfSplits'"
                class="icon-hover"
                [ddsTooltip]="row[column.name]"
                [tooltipWidth]="tooltipWidth"
                >{{ row[column.name] }}</span
              >
              <span
                *ngIf="column.name !== 'numberOfSplits'"
                class="text-block"
                [ddsTooltip]="row[column.name]"
                >{{ row[column.name] }}</span
              >
            </td>
            <td class="relative cross-btn-top margin-right-s float-right margin-top-minus-m">
              <dds-button
                class="custom-icon"
                [theme]="closeBtnOptions.theme ?? emptySpace"
                [kind]="closeBtnOptions.kind ?? emptySpace"
                [size]="closeBtnOptions.size ?? emptySpace"
                [width]="closeBtnOptions.width ?? emptySpace"
                [icon]="closeBtnOptions.icon ?? emptySpace"
                [isIconLeft]="closeBtnOptions.isIconLeft ?? false"
                [role]="closeBtnOptions.role ?? emptySpace"
                [isInverse]="closeBtnOptions.isInverse ?? false"
                [isColorBg]="closeBtnOptions.isColorBg ?? false"
                [isLoading]="closeBtnOptions.isLoading ?? false"
                [ariaLabel]="'Close'"
                [customClass]="closeBtnOptions.customClass ?? emptySpace"
                (clicked)="removeOpportunity(nonChangeOrder)"
              ></dds-button>
            </td>
          </tr>
          <tr
            *ngFor="let row of opportunityDetailsList; index as index"
            class="dds-data-table__row"
          >
            <td *ngFor="let column of opportunityTableColumns">
              <span
                *ngIf="
                  column.name == 'client' &&
                  (row['watchlistStatus'] === 'Serve with conditions' ||
                    row['watchlistStatus'] === 'Do not serve')
                "
                [ngClass]="{
                  'serve-with-conditions-container':
                    row['watchlistStatus'] === 'Serve with conditions',
                  'do-not-serve-container':
                    row['watchlistStatus'] === 'Do not serve'
                }"
              >
                {{ row["watchlistStatus"] }}
              </span>
              <span
                class="client-status-grid"
                *ngIf="
                  column.name == 'client' &&
                  (row['swiftClientStatus'] === 'Blocked' ||
                    row['swiftClientStatus'] === 'Duplicate')
                "
              >
                [{{ row["swiftClientStatus"] }}]
              </span>

              <span
                *ngIf="
                  column.name == 'client' && row.isMarkedForDeletion;
                  else rowSwiftClientStatus
                "
                class="client-status-grid"
              >
                [{{
                  row["swiftClientStatus"] === "Blocked" ||
                  row["swiftClientStatus"] === "Duplicate"
                    ? row["swiftClientStatus"] + " - " + markForDeletionText
                    : markForDeletionText
                }}]
              </span>
              <ng-template #rowSwiftClientStatus>
                <span
                  *ngIf="
                    column.name == 'client' &&
                    (row['swiftClientStatus'] === 'Blocked' ||
                      row['swiftClientStatus'] === 'Duplicate')
                  "
                  class="client-status-grid"
                >
                  [{{ row["swiftClientStatus"] }}]
                </span>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="dds-modal__footer">
      <div class="dds-modal__footer-content dds-modal__footer-content_left">
        <dds-button
          class="dds-modal__footer-item custom-button"
          [theme]="relinkBtn.theme ?? ''"
          [kind]="relinkBtn.kind"
          [size]="relinkBtn.size"
          [width]="relinkBtn.width ?? ''"
          [icon]="relinkBtn.icon"
          [isIconLeft]="relinkBtn.isIconLeft ?? false"
          [role]="relinkBtn.role ?? ''"
          [isInverse]="relinkBtn.isInverse ?? false"
          [isColorBg]="relinkBtn.isColorBg ?? false"
          [isLoading]="relinkBtn.isLoading ?? false"
          [ariaLabel]="relinkBtn.ariaLabel"
          [customClass]="relinkBtn.customClass"
          (clicked)="save()"
          [disabled]="!opportunityDetailsList.length"
          (keyboardClicked)="save()"
          >Link submission</dds-button
        >

        <dds-button
          class="dds-modal__footer-item custom-button"
          [theme]="cancelBtn.theme ?? ''"
          [kind]="cancelBtn.kind"
          [size]="cancelBtn.size"
          [width]="cancelBtn.width ?? ''"
          [icon]="cancelBtn.icon"
          [isIconLeft]="cancelBtn.isIconLeft ?? false"
          [role]="cancelBtn.role ?? ''"
          [isInverse]="cancelBtn.isInverse ?? false"
          [isColorBg]="cancelBtn.isColorBg ?? false"
          [isLoading]="cancelBtn.isLoading ?? false"
          [ariaLabel]="cancelBtn.ariaLabel"
          [customClass]="cancelBtn.customClass"
          (clicked)="closeRelinkModel(navInfo)"
          (keyboardClicked)="closeRelinkModel(navInfo)"
          >Close</dds-button
        >
      </div>
    </div>
  </div>
</div>
