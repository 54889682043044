import { DashboardData } from './start-page.model';

export interface ReviewerDashboardData extends DashboardData {
  clientNumber: string;
  continuanceExpiredDate: string;
  openOpportunities: string;
  priorFyClosedWBS?: string;
  netServiceRevenue: string;
  submittedDate: string;
  requestedReviewDate: string;
  rushRequest?: string;
  consultationRequested?: string;
  registeredForAdvisoryAndConsultingDate: string;
  registeredForDeloitteDate: string;
  slComments?: string;
  lastOpportunityWithClosedWonDate: string;
  lastInvoiceDate: string;
  programAccount: string;
  isGlobalCrownJewel: string;
  [key: string]: any;
  submissionTitle: string;

  jupiterId: string;
  lep: string;
  pendingReviewer: string;
  lastStatus: string;
  dateOfLastStatusChange: string;
}

export interface ReviewerDashboardTilesCount {
  pendingReviewsCount: number;
  pendingReviewsOthersCount: number;
  ncaPendingApprovalCount: number;
  ccDueCount: number;
  recentReviewsCount: number;
  selfReviewChangesCount: number;
}

export enum ReviewerTileCode {
  PendingReviews = 'PendingReviews',
  PendingReviewsOthers = 'PendingReviewsOthers',
  NCAPendingApproval = 'NCAPendingApproval',
  CCDueNow = 'CCDueNow',
  RecentReviews = 'RecentReviews',
  SelfReviewChanges = 'SelfReviewChanges',
}

export const ReviewerTileCodeDetails = new Map<
  ReviewerTileCode,
  {
    title: string;
    countKey: keyof ReviewerDashboardTilesCount;
    tileCode: string;
  }
>([
  [
    ReviewerTileCode.PendingReviews,
    {
      title: 'Pending reviews',
      countKey: 'pendingReviewsCount',
      tileCode: 'PendingReviews',
    },
  ],
  [
    ReviewerTileCode.PendingReviewsOthers,
    {
      title: 'Pending reviews of others',
      countKey: 'pendingReviewsOthersCount',
      tileCode: 'PendingReviewsOthers',
    },
  ],
  [
    ReviewerTileCode.RecentReviews,
    {
      title: 'Recent Reviews',
      countKey: 'recentReviewsCount',
      tileCode: 'RecentReviews',
    },
  ],
  [
    ReviewerTileCode.SelfReviewChanges,
    {
      title: 'Self-review changes',
      countKey: 'selfReviewChangesCount',
      tileCode: 'SelfReviewChanges',
    },
  ],
  [
    ReviewerTileCode.NCAPendingApproval,
    {
      title: 'NCAs pending approvals',
      countKey: 'ncaPendingApprovalCount',
      tileCode: 'NCAPendingApproval',
    },
  ],
  [
    ReviewerTileCode.CCDueNow,
    {
      title: 'Client continuances due',
      countKey: 'ccDueCount',
      tileCode: 'CCDueNow',
    },
  ],
]);

export enum GridColumns {
  OpenOpportunities = 'openOpportunities',
  Actions = 'actions',
  ClientName = 'clientName',
  ParentClientName = 'parentClientName',
  RecommendedClientGroupNumber = 'recommendedClientGroupNumber',
  DescDesignation = 'descDesignation',
  ActionByName = 'actionByName',
  NCASpecialistName = 'ncaSpecialistName',
  Focus = 'focus',
  SubmissionTitle = 'submissionTitle',
  PriorFyClosedWBS = 'priorFyClosedWBS',
  DaysPendingForCCCompletion = 'daysPendingForCCCompletion',
  SubmittedDate = 'submittedDate',
  NetServiceRevenue = 'netServiceRevenue',
  LastStatus = 'lastStatus',
  DateOfLastStatusChange = 'dateOfLastStatusChange',
  PendingReviewer = 'pendingReviewer'

}
