import { ErrorState, Themes } from '@usitsdasdesign/dds-ng/shared';
import { ToastOptions } from '@usitsdasdesign/dds-ng/toast';
import { IntakeConstant } from '../../../../../constants/intake.constant';
import { RoleEnum } from '../../../../../constants/Role.enum';

export const ToggleGroupOptions = {
  title: '',
  isInverse: false,
  isRequired: false,
};

export const ToggleOptions = {
  theme: Themes.green,
  label: 'Hide all draft documents (e.g. drafts and supporting documents)',
  ariaLabel: '',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isProcessingStatusActive: false,
  isRequired: false,
  customClass: '',
};

export const Column = [
  { name: 'Title', header: 'Title', dataType: 'string' },
  { name: '', header: '', dataType: 'string' },
  { name: 'Type', header: 'Type', dataType: 'string' },
  {
    name: 'Status',
    header: 'Status',
    dataType: 'string',
  },
  { name: 'Uploaded Date', header: 'Uploaded Date', dataType: 'string' },
  { name: 'Uploaded By', header: 'Uploaded By', dataType: 'string' },
  { name: 'Actions', header: 'Actions', dataType: 'string' },
];

export const toast: ToastOptions = {
  title:
    'Upload another RM Reviewed/Archive ready - final version document before deleting this one.',
  message: '',
  lifeTime: 3000,
  withProgressBar: false,
  position: 'bottom-center',
  closeBtnIcon: 'dds-icon_close',
  isCloseIcon: false,
  customClass: 'toast-align simple-toast',
  isError: false,
  isInverse: false,
  isLoading: false,
  limit: 5,
  theme: Themes.dark,
};

export const AttachDocumentBtn = {
  heading: IntakeConstant.EMPTY_SPACE,
  theme: Themes.green,
  btnLabel: 'Attach Document(s)',
  chooseFileBtnKind: 'primary',
  isError: false,
  disabled: false,
  isInverse: false,
  isMultiple: true,
};

export const DOWNLOAD_DISABLED_USER_ROLES_FOR_RMREVIEWED_DOCUMENT = [
  RoleEnum.ENGAGEMENT_TEAM_MEMBER,
  RoleEnum.LEAD_ENGAGEMENT_PARTNER
];