import { Size, Themes } from '@usitsdasdesign/dds-ng/shared';

export const AccordionOptions = {
  size: Size.lg,
  disabled: false,
  isOpen: false,
  isMulti: false,
  isInverse: false,
  customClass: '',
};

export const Filter = {
  theme: Themes.green,
  isInverse: false,
  propName: 'name',
  itemPropNameValue: 'value',
};

export const Tableheader = [
  {
    name: 'splitId',
    header: 'Split ID',
    dataType: 'string',
  },
  {
    name: 'fss',
    header: 'FSS',
    dataType: 'string',
  },
  {
    name: 'location',
    header: 'Location',
    dataType: 'string',
  },
  {
    name: 'opportunityLeader',
    header: 'Opportunity Leader',
    dataType: 'string',
  },
  {
    name: 'clientServiceLevel',
    header: 'Client Service Level',
    dataType: 'string',
  },
  {
    name: 'amount',
    header: 'Split amount( $ )',
    dataType: 'number',
  },
];

export const TooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'top',
  tooltipIndent: 15,
  tooltipIsOutsideClick: false,
  tooltipHasBeak: false,
  tooltipIsDisabled: false,
  tooltipIsInverse: false,
  tooltipIsDynamic: false,
  tooltipCustomClass: '',
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipShowDelay: 0,
  tooltipHideDelay: 0,
  tooltipTheme: Themes.dark,
};
