import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment';
import { ExternalCommUrlConstants } from '../../external-communications/constants/url.constants';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  EditSubmissionDocument,
  ExtCommAttributesDataTransformedModel,
  ExtCommAttributesRequestModel,
  ExtCommAttributesSendModel,
  ExtCommLeftNavModel,
  ExtCommSubmissionDetailsModel,
  GenericResponse,
  IndustrySectorModel,
  InitiateExternalCommunicationDropdownModel,
  InitiateExternalCommunicationRequestModel,
  InitiateExternalCommunicationResponseModel,
  InitiateSubmissionExternalCommunicationModel,
  MarketOfferingModel,
  OfferingModel,
  OfferingPortfolioModel,
  SaveSubmissionDocument,
  SubmissionDocumentDetails,
} from '../../common/models/external-communication.model';
import { RiskAssessmentFormData } from '../../common/models/external-communication-submission-details-common-section.model';
import {
  DocumentStatus,
  DocumentType,
} from '../../common/models/document-dropdown.models';

@Injectable({
  providedIn: 'root',
})
export class ExternalCommunicationService {
  constructor(private readonly http: HttpClient) {}
  apiUrl: string = environment.apiUrl;
  // apiUrl: string = 'https://dapi.eclipse.deloitte.com/api/'; //dev
  // apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa

  readonly #communicationTypeSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(-1);
  communicationType$ = this.#communicationTypeSubject.asObservable();

  readonly #resetDatePickerSubject: BehaviorSubject<void> =
    new BehaviorSubject<void>(undefined);
  resetDatePicker$ = this.#resetDatePickerSubject.asObservable();

  readonly #textareaValueSource: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  textareaValue$: Observable<string> = this.#textareaValueSource.asObservable();

  emitTextareaValue(value: string): void {
    this.#textareaValueSource.next(value);
  }

  emitResetDatePickerEvent(): void {
    this.#resetDatePickerSubject.next();
  }

  setCommunicationType(type: number): void {
    this.#communicationTypeSubject.next(type);
  }

  getSubmissionTypes(): Observable<
    InitiateExternalCommunicationDropdownModel[]
  > {
    return this.http.get<InitiateExternalCommunicationDropdownModel[]>(
      this.apiUrl + ExternalCommUrlConstants.GET_COMMUNICATION_TYPE
    );
  }

  postInitiateExternalCommunication(
    data: InitiateSubmissionExternalCommunicationModel
  ): Observable<InitiateExternalCommunicationResponseModel> {
    return this.http.post<InitiateExternalCommunicationResponseModel>(
      this.apiUrl + ExternalCommUrlConstants.POST_INITIATE_EXT_COMM,
      data
    );
  }

  getExtCommSubmissionDetails(
    submissionId: number
  ): Observable<ExtCommSubmissionDetailsModel> {
    return this.http.get<ExtCommSubmissionDetailsModel>(
      this.apiUrl +
        ExternalCommUrlConstants.GET_EXT_COMM_SUBMISSION_DETAILS +
        submissionId
    );
  }

  putExtCommSubmissionDetails(
    data: InitiateExternalCommunicationRequestModel
  ): Observable<GenericResponse> {
    return this.http.put<GenericResponse>(
      this.apiUrl + ExternalCommUrlConstants.PUT_EXT_COMM_SUBMISSION_DETAILS,
      data
    );
  }

  getExtCommLeftNav(
    communicationTypeId: number
  ): Observable<ExtCommLeftNavModel[]> {
    return this.http.get<ExtCommLeftNavModel[]>(
      this.apiUrl +
        ExternalCommUrlConstants.GET_EXT_COMM_LEFTNAV +
        communicationTypeId
    );
  }

  getExtCommIndustrySector(): Observable<IndustrySectorModel[]> {
    return this.http.get<IndustrySectorModel[]>(
      this.apiUrl + ExternalCommUrlConstants.GET_EXT_COMM_INDUSTRY_SECTOR
    );
  }

  getExtCommMarketOffering(): Observable<MarketOfferingModel[]> {
    return this.http.get<MarketOfferingModel[]>(
      this.apiUrl + ExternalCommUrlConstants.GET_MARKET_OFFERING
    );
  }

  getExtCommOffering(): Observable<OfferingModel[]> {
    return this.http.get<OfferingModel[]>(
      this.apiUrl + ExternalCommUrlConstants.GET_OFFERING
    );
  }

  getExtCommOfferingPortfolio(): Observable<OfferingPortfolioModel[]> {
    return this.http.get<OfferingPortfolioModel[]>(
      this.apiUrl + ExternalCommUrlConstants.GET_OFF_PORTFOLIO
    );
  }

  getSubmissionCommunicationAttributes(
    submissionId: number
  ): Observable<ExtCommAttributesDataTransformedModel> {
    return this.http
      .get<ExtCommAttributesRequestModel>(
        this.apiUrl +
          ExternalCommUrlConstants.GET_SUBMISSION_COMMUNICATION_ATTRIBUTES +
          submissionId
      )
      .pipe(
        map((response: ExtCommAttributesRequestModel) => {
          const transformedResponse: ExtCommAttributesDataTransformedModel = {
            ...response,
            isIncludesAlcoholSweepstakesAwards: this.transformBooleanToYesNo(
              response.isIncludesAlcoholSweepstakesAwards
            ),
            podcastInvolvesOtherParties: this.transformBooleanToYesNo(
              response.podcastInvolvesOtherParties
            ),
            videoInvolvesOtherParties: this.transformBooleanToYesNo(
              response.videoInvolvesOtherParties
            ),
            isLiveStreamed: this.transformBooleanToYesNo(
              response.isLiveStreamed
            ),
            isPublishedInThirdParty: this.transformBooleanToYesNo(
              response.isPublishedInThirdParty
            ),
            isSurveyExternalParties: this.transformBooleanToYesNo(
              response.isSurveyExternalParties
            ),
            lcspSurveyApproval: this.transformBooleanToYesNo(
              response.lcspSurveyApproval
            ),
            isIncentiveForSurvey: this.transformBooleanToYesNo(
              response.isIncentiveForSurvey
            ),
            isInvolvesSweepstakes: this.transformBooleanToYesNo(
              response.isInvolvesSweepstakes
            ),
            isDiversityEquity: this.transformBooleanToYesNo(
              response.isDiversityEquity
            ),
            isLessThanPriceThreshold: this.transformBooleanToYesNo(
              response.isLessThanPriceThreshold
            ),
            hasWorkedWithOGC: this.transformBooleanToYesNo(
              response.hasWorkedWithOGC
            ),
            isAskedSignSpeakerAgreement: this.transformBooleanToYesNo(
              response.isAskedSignSpeakerAgreement
            ),
            isRecorded: this.transformBooleanToYesNo(response.isRecorded),
            isClientUseCaseAndSuccessStories: this.transformBooleanToYesNo(
              response.isClientUseCaseAndSuccessStories
            ),
            isRelatedToSponsorship: this.transformBooleanToYesNo(
              response.isRelatedToSponsorship
            ),
            isRelatedToConference: this.transformBooleanToYesNo(
              response.isRelatedToConference
            ),
            isLabOrDemo: this.transformBooleanToYesNo(response.isLabOrDemo),
          };
          return transformedResponse;
        })
      );
  }

  postSubmissionCommunicationAttributes(
    data: ExtCommAttributesDataTransformedModel
  ): Observable<GenericResponse> {
    //TODO: remove any from type after backend changes
    const sendData: ExtCommAttributesSendModel | any = {
      submissionId: data.submissionId,
      communicationTypeId: data.communicationTypeId,
      isIncludesAlcoholSweepstakesAwards: this.transformYesNoToBoolean(
        data.isIncludesAlcoholSweepstakesAwards
      ),
      podcastInvolvesOtherParties: this.transformYesNoToBoolean(
        data.podcastInvolvesOtherParties
      ),
      videoInvolvesOtherParties: this.transformYesNoToBoolean(
        data.videoInvolvesOtherParties
      ),
      isLiveStreamed: this.transformYesNoToBoolean(data.isLiveStreamed),
      isPublishedInThirdParty: this.transformYesNoToBoolean(
        data.isPublishedInThirdParty
      ),
      publishedLocation: data.publishedLocation,
      isUsingSponsorOrThirdPartyTemplate:
        data.isUsingSponsorOrThirdPartyTemplate,
      isSurveyExternalParties: this.transformYesNoToBoolean(
        data.isSurveyExternalParties
      ),
      typeOfSurvey: data.typeOfSurvey,
      typeOfSurvey2: data.typeOfSurvey2,
      lcspSurveyApproval: this.transformYesNoToBoolean(data.lcspSurveyApproval),
      producingBenchMarksFor: data.producingBenchMarksFor,
      isIncentiveForSurvey: this.transformYesNoToBoolean(
        data.isIncentiveForSurvey
      ),
      surveyID: data.surveyID,
      isInvolvesSweepstakes: this.transformYesNoToBoolean(
        data.isInvolvesSweepstakes
      ),
      isDiversityEquity: this.transformYesNoToBoolean(data.isDiversityEquity),
      isInternalExternalContest: data.isInternalExternalContest,
      isLessThanPriceThreshold: this.transformYesNoToBoolean(
        data.isLessThanPriceThreshold
      ),
      hasWorkedWithOGC: this.transformYesNoToBoolean(data.hasWorkedWithOGC),
      isAskedSignSpeakerAgreement: this.transformYesNoToBoolean(
        data.isAskedSignSpeakerAgreement
      ),
      isRecorded: this.transformYesNoToBoolean(data.isRecorded),
      isClientUseCaseAndSuccessStories: this.transformYesNoToBoolean(
        data.isClientUseCaseAndSuccessStories
      ),
      isRelatedToSponsorship: this.transformYesNoToBoolean(
        data.isRelatedToSponsorship
      ),
      isRelatedToConference: this.transformBooleanToYesNo(
        data.isRelatedToConference
      ),
      isLabOrDemo: this.transformYesNoToBoolean(data.isLabOrDemo),
      additionalComments: data.additionalComments,
    };
    return this.http.post<GenericResponse>(
      this.apiUrl +
        ExternalCommUrlConstants.POST_SUBMISSION_COMMUNICATION_ATTRIBUTES,
      sendData
    );
  }

  transformBooleanToYesNo(value: boolean | string | null): string | null {
    if (value === true) return 'yes';
    if (value === false) return 'no';
    if (typeof value === 'string') return value.toLowerCase();
    return value;
  }

  // TODO: remove string form return type after backend changes
  transformYesNoToBoolean(value: string | null): string | boolean | null {
    // TODO: remove "return value" after backend changes
    // return value;
    if (value === 'yes') return true;
    if (value === 'no') return false;
    return null;
  }

  saveRiskAssessmentData(data: RiskAssessmentFormData): Observable<any> {
    return this.http.post<any>(
      this.apiUrl + ExternalCommUrlConstants.SAVE_RISK_ASSESSMENT_DATA,
      data
    );
  }

  getExtCommRiskAssessment(submissionId: number): Observable<any> {
    return this.http.get<any>(
      this.apiUrl +
        ExternalCommUrlConstants.GET_EXT_RISK_ASSESSMENT_DETAILS +
        submissionId
    );
  }

  getDocumentStatusDropdown(
    submissionStatusId: number
  ): Observable<DocumentStatus[]> {
    return this.http.get<DocumentStatus[]>(
      this.apiUrl +
        ExternalCommUrlConstants.GET_DOCUMENT_STATUS_DROPDOWN +
        submissionStatusId
    );
  }

  getDocumentTypeDropdown(): Observable<DocumentType[]> {
    return this.http.get<DocumentType[]>(
      this.apiUrl + ExternalCommUrlConstants.GET_DOCUMENT_TYPE_DROPDOWN
    );
  }

  getDocumentDetails(
    submissionId: number
  ): Observable<SubmissionDocumentDetails[]> {
    return this.http
      .get<SubmissionDocumentDetails[]>(
        this.apiUrl + ExternalCommUrlConstants.GET_DOCUMENT_LIST + submissionId
      )
      .pipe(
        map((data) =>
          data.map((item) => ({
            ...item,
            documentStatusId: Number(item.documentStatusId) ?? null,
            documentTypeId: Number(item.documentTypeId) ?? null,
          }))
        )
      );
  }

  editDocumentDetails(data: EditSubmissionDocument): Observable<any> {
    // TODO: remove any from type after checking response
    return this.http.post<any>(
      this.apiUrl + ExternalCommUrlConstants.EDIT_DOCUMENT_DETAILS,
      data
    );
  }

  deleteDocumentDetails(id: number): Observable<void> {
    // TODO: remove any from type after checking response
    return this.http.delete<void>(
      this.apiUrl + ExternalCommUrlConstants.DELETE_DOCUMENT_DETAILS + id
    );
  }

  postDocumentSave(data: SaveSubmissionDocument[]): Observable<any> {
    // TODO: remove any from type after checking response
    return this.http.post<any>(
      this.apiUrl + ExternalCommUrlConstants.POST_DOCUMENT_SAVE,
      data
    );
  }
}
