import { Submission } from './../../common/models/common-section.model';
import {
  ButtonKind,
  ErrorState,
  ExtThemes,
  Size,
  TabThemeType,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  VerticalTabOptions,
  VerticalTabsOptions,
} from '@usitsdasdesign/dds-ng/vertical-tabs';
import { MultiSelectOptions, SelectControlTypes, SelectType } from '@usitsdasdesign/dds-ng/multi-select';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';

export class AdminConstant {
  public static readonly GREEN_THEME: ExtThemes = ExtThemes.green;
  public static readonly Label: string = 'Refresh application with changes';
  public static readonly dropdownSelectedValue: string =
    'marketOfferingConfiguration';
  public static readonly adminExtCommTrainingTableItem: string =
    'adminExtCommTrainingTable';
  public static readonly UI_ELEMENT_SIZE: Size = Size.lg;
  public static readonly DDS_ICON_CLOSE: string = 'dds-icon_close';
  public static readonly EMPTY_SPACE: string = '';
  public static readonly DEFAULT_ACTIVE_STATUS: string = 'active';
  public static readonly DEFAULT_INACTIVE_STATUS: string = 'inactive';
  public static readonly COMMENTS_HEADER: string = 'comments';
  public static readonly EDITICON: string = 'editIcon';
  public static readonly DOLLAR: string = '$';
  public static readonly TIMEONESEC: number = 1000;
  public static readonly RISKMGRROLEVALUE: string = "703";
  public static readonly REFRESH_TOAST_MESSAGE: string = 'Application refreshed. Please allow up to 2 minutes for all changes to propagate'
  public static readonly DATE_FORMAT: string = 'MM/dd/yyyy';
  public static readonly TIMEZEROSEC: number = 0;



  public static readonly Button_Options: ButtonOptions = {
    theme: AdminConstant.GREEN_THEME,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: WidthState.fixed,
    isLoading: false,
    icon: '',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: this.Label,
    customClass: '',
    role: 'button',
  };

  public static readonly TabContainerOptions: VerticalTabsOptions = {
    theme: AdminConstant.GREEN_THEME,
    themeType: TabThemeType.border,
    size: Size.lg,
    ariaLabel: 'Vertical tabs',
    disabled: false,
    isInverse: false,
    customClass: '',
  };

  public static readonly VerticalTabOptionsOne: VerticalTabOptions = {
    label: 'Global configuration',
    disabled: false,
    customClass: '',
    isActive: false,
  };

  public static readonly VerticalTabOptionsTwo: VerticalTabOptions = {
    label: 'Roles list',
    isActive: false,
  };

  public static readonly VerticalTabOptionsThree: VerticalTabOptions = {
    label: 'Tables',
    isActive: false,
  };

  public static readonly Initiation = 'Initiation';
  public static readonly Opportunity = 'Opportunity';
  public static readonly Submission = 'Submission';
  public static readonly ExternalCommunication = 'externalCommunication';
  public static readonly IntakeCategoryID = 1;
  public static readonly ExternalCommunicationCategoryID  = 3;
  public static readonly Initiation_Sub_Category_ID = 1;
  public static readonly Opportunity_Sub_Category_ID = 2;
  public static readonly Submission_Sub_Category_ID = 3;
  public static readonly Null = null;

  public static readonly MultiSelectOptions: MultiSelectOptions = {
      label: '',
      size: Size.lg,
      description: '',
      placeholder: 'Select',
      type: SelectType.counter,
      controlType: SelectControlTypes.checkbox,
      theme: 'green',
      isInverse: false,
      disabled: false,
      isResponsive: false,
      isError: false,
      isRequired: false,
      displayTickAllBtn: false,
      errorMessage: '',
      errorState: ErrorState.default,
      customClass: '',
      stickerWidth: 0,
      stickerShift: 0,
      stickerMaxHeight: "",
      stickerIsDisabled: false,
      stickerPosition: "bottom-left",
      stickerIndent: 0,
      stickerCustomClass: "",
      counterTypeText: '',
    };

    public static readonly TagOptions: TagOptions = {
        theme: Themes.dark,
        isRemovable: true,
        disabled: true,
        isError: false,
        isInverse: false,
        size: Size.lg,
        customClass: ''
      };
}
