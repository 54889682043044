import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { DashboardGridLevelFilter, OpportunityData } from '../../common/models/start-page/start-page.model';
import { FilterItem } from '@usitsdasdesign/dds-ng/filter';

@Injectable({
  providedIn: 'root',
})
export class NcaSummaryWebapiService {
  constructor(private readonly httpService: HttpClient) { }

  apiUrl: string = environment.apiUrl;

  getActiveNcaSummary(
    pageNumber: number,
    pageSize: number,
    sortParameterCode: number|null,
    isAscending: boolean,
    tabCode: string,
    filterData: DashboardGridLevelFilter[]
  ): Observable<ActiveNcaSummaryCollection> {
     let params = new HttpParams()
    .set('tabCode', tabCode)
    .set('pageNumber', pageNumber.toString())
    .set('pageSize', pageSize.toString())
    .set('isAscending', isAscending.toString());
  if (sortParameterCode !== null) {
    params = params.set('sortParameterCode', sortParameterCode.toString());
  }

    return this.httpService.post<ActiveNcaSummaryCollection>(
      this.apiUrl + 'NCA/NcaSummary/GetActiveNcaSummary',
      filterData,
      { params }
    );
  }

  getNcaSummarySpecialistStatus(
    tabCode: string,
    filterData: DashboardGridLevelFilter[])
    : Observable<ActiveNcaSummarySpecialistStatus[]> {
      const params = new HttpParams()
      .set('tabcode', tabCode);
    return this.httpService.post<ActiveNcaSummarySpecialistStatus[]>(
      this.apiUrl + 'NCA/NcaSummary/GetNcaSummarySpecialistStatus',
      filterData,
      { params }
    );
  }

  getNCAFormPeriod(): Observable<NCASummaryFormPeriod> {
    return this.httpService.get<NCASummaryFormPeriod>(
      this.apiUrl + 'NCA/NcaSummary/GetNcaFormPeriod'
    );
  }

  GetCompletedNCASummaryByStatus(periodCode : any): Observable<any> {
    return this.httpService.get<any>(
      this.apiUrl + 'NCA/NcaSummary/GetCompletedNCASummaryByStatus?selectedPeriodCode=' + periodCode
    );
  }

  GetNcaCompletedSummaryBySpecialist(periodCode: any): Observable<any> {
    return this.httpService.get<any>(
      this.apiUrl + 'NCA/NcaSummary/GetNcaCompletedSummaryBySpecialist?selectedPeriodCode=' + periodCode
    );
  }

  ExportNCASummary(
    filterData: NCASummaryGridLevelFilter[]
  ): Observable<{ Base64String: string, FileName: string }> {

    return this.httpService.post<{ Base64String: string, FileName: string }>(
      this.apiUrl + 'NCA/NcaSummary/ExportNCASummary',filterData
    );
  }
}

export interface ActiveNcaSummaryCollection {
  activeNcaSummary: any[];
  totalNcasCount: number;
  notAssignedNcasCount: number;
  notStartedNcasCount: number;
  inProcessNcasCount: number;
  pendingApprovalsNcasCount: number;
  onHoldNcasCount: number;
  ncaSpecialistsFilterData: FilterItem[];
}

export interface ActiveNcaSummary {
  clientMilestoneStatusId: number;
  clientMilestoneStatus: string;
  clientMilestoneSubStatusId: number;
  clientMilestoneSubStatus: string;
  clientNumber: string;
  clientName: string;
  clientMilestoneId: number;
  clientMilestoneProcessTypeId: number;
  industry: string;
  ncaSpecialistPreferredName: string;
  sectorLeaderDisplayName: string;
  ncaIrmDisplayName: string;
  marsQrmDisplayName: string;
  overallAging: number;
  ncaType: string;
  acceptedBy: string;
  opportunityListData: string;
  opportunityList: OpportunityData[];
  totalNcasCount: number;
  notAssignedNcasCount: number;
  notStartedNcasCount: number;
  inProcessNcasCount: number;
  pendingApprovalsNcasCount: number;
  onHoldNcasCount: number;
}

export enum NCASummarySortParameterCode {
  clientmilestoneid = 1,
  ncarequestperiod = 2
}

export interface NCASummaryGridLevelFilter {
  filtertypecode: number;
  filtercriteria: string;
}
export interface NCASummaryFormPeriod { 
  ncaFormPeriodid: number;
  ncaFormPeriodCode: string;
  ncaFormPeriodName: string;
  ncaFormPeriodDescription: string;  
}
export interface ActiveNcaSummarySpecialistStatus {
  ncaSpecialistPreferredName: string;
  acceptedBy: string;
  notStartedNcasCount: number;
  inProcessNcasCount: number;
  pendingApprovalsNcasCount: number;
  onHoldNcasCount: number;
}
