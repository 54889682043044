import { ExternalCommunicationConstant } from './external_communication.constant';
import { ExternalCommSubmissionStatus } from './submission-status.enum';

export enum StatusEnum {
  Pending = 'Pending',  // TODO: Update/Remove once we get confirmation is not in use
  DRAFT = ExternalCommSubmissionStatus.DRAFT,
  RETURN_FOR_REWORK = ExternalCommSubmissionStatus.RETURN_FOR_REWORK,
  SUBMITTED = ExternalCommSubmissionStatus.SUBMITTED,
  RESUBMITTED = ExternalCommSubmissionStatus.RESUBMITTED,
  RMReviewed = 'RM Reviewed', // TODO: Update/Remove once we get confirmation is not in use
  RM_REVIEWED_PENDING_CLOSEOUT = ExternalCommSubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT,
  SELF_REVIEWED_PENDING_CLOSEOUT = ExternalCommSubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT,
  SELF_REVIEWED_PENDING_PPMD_CONFIRMATION = ExternalCommSubmissionStatus.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION,
  RM_REVIEWED_PENDING_PPMD_CONFIRMATION = ExternalCommSubmissionStatus.RM_REVIEWED_PENDING_PPMD_CONFIRMATION,
  COMPLETED = ExternalCommSubmissionStatus.COMPLETED,
  DISCONTINUED = ExternalCommSubmissionStatus.DISCONTINUED,
  COMPLETED_ADMIN = ExternalCommSubmissionStatus.ADMIN_COMPLETE,
}

export const StatusEnumIdMap: { [key in StatusEnum]: number } = {
  [StatusEnum.DRAFT]:
    ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
      StatusEnum.DRAFT
    ],
  [StatusEnum.RETURN_FOR_REWORK]:
    ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
      StatusEnum.RETURN_FOR_REWORK
    ],
  [StatusEnum.SUBMITTED]:
    ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
      StatusEnum.SUBMITTED
    ],
  [StatusEnum.RESUBMITTED]:
    ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
      StatusEnum.RESUBMITTED
    ],
  [StatusEnum.RM_REVIEWED_PENDING_CLOSEOUT]:
    ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
      StatusEnum.RM_REVIEWED_PENDING_CLOSEOUT
    ],
  [StatusEnum.SELF_REVIEWED_PENDING_CLOSEOUT]:
    ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
      StatusEnum.SELF_REVIEWED_PENDING_CLOSEOUT
    ],
  [StatusEnum.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION]:
    ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
      StatusEnum.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION
    ],
  [StatusEnum.RM_REVIEWED_PENDING_PPMD_CONFIRMATION]:
    ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
      StatusEnum.RM_REVIEWED_PENDING_PPMD_CONFIRMATION
    ],
  [StatusEnum.COMPLETED]:
    ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
      StatusEnum.COMPLETED
    ],
  [StatusEnum.Pending]: -1, // TODO: Update/Remove once we get from backend
  [StatusEnum.RMReviewed]: -1, // TODO: Update/Remove once we get from backend
  [StatusEnum.DISCONTINUED]:
    ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
      StatusEnum.DISCONTINUED
    ],
  [StatusEnum.COMPLETED_ADMIN]:
    ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
      StatusEnum.COMPLETED_ADMIN
    ],
};

export const getStatusEnumKeyByValue = (
  value: number
): StatusEnum | undefined => {
  const keys = Object.keys(StatusEnumIdMap)
    .filter((key: string) => StatusEnumIdMap[key as StatusEnum] === value)
    .map((key: string) => key as StatusEnum);

  return keys.length > 0 ? keys[0] : undefined;
};
