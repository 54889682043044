<div id="nca-tiles-container" class="tile-row" *ngIf="tilesCount">
  <div class="row flex-container">
    <div
      class="col-3"
      *ngFor="let tile of tileCodeDetails; let i = index"
      [hidden]="i >= 4"
      [ngStyle]="{
        flex: tileCodeDetails.length === 3 ? '0 0 33%' : '0 0 0.313rem'
      }"
    >
      <div
        id="{{ tile.tileCode }}"
        class="dds-card ds-card_has-image dds-card_clickable {{
          tile.tileCode
        }}"
        (click)="tileSelected(tile.tileCode)"
        [ngClass]="{ active: selectedTileCode === tile.tileCode }"
      >
        <div class="dds-card__content">
          <h1 class="dds-card__title">
            {{ tilesCount[tile.countKey] ?? 0 }}
            <span
              *ngIf="
                dashboardType === REVIEWER_DASHBOARD &&
                tile.tileCode === 'CCDueNow'
              "
            >
              due</span
            >
            <span
              class="title-subtext"
              *ngIf="
                dashboardType === REVIEWER_DASHBOARD &&
                tile.tileCode === enumReviewerTileCode.ExtComm &&
                unassignedReviewerExtCommVisible
              "
            >
              ({{ tilesCount["unassignedECCount"] ?? 0 }} unassigned)</span
            >

            <span
              class="title-subtext"
              *ngIf="tile.tileCode === enumReviewerTileCode.GlobalEng"
            >
              (in Draft)</span
            >
          </h1>
          <p class="dds-card__text">
            {{ tile.title }}
            <span
              *ngIf="
                dashboardType === 'NCADashboard' &&
                tile.tileCode === 'CompleteMarsNcaForm'
              "
            >
              ({{ tilesCount[MARSNcasDraftCount] ?? 0 }} <br />
              draft)</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row flex-container" *ngIf="tileCodeDetails.length > 3">
    <div
      class="col-3"
      *ngFor="let tile of tileCodeDetails; let i = index"
      [hidden]="i < 4"
      [ngStyle]="{ flex: '0 0 0.313rem' }"
    >
      <div
        id="{{ tile.tileCode }}"
        class="dds-card ds-card_has-image dds-card_clickable {{
          tile.tileCode
        }}"
        (click)="tileSelected(tile.tileCode)"
        [ngClass]="{ active: selectedTileCode === tile.tileCode }"
      >
        <div class="dds-card__content">
          <h1 class="dds-card__title">
            {{ tilesCount[tile.countKey] ?? 0 }}
            <span
              *ngIf="
                dashboardType === REVIEWER_DASHBOARD &&
                tile.tileCode === 'CCDueNow'
              "
            >
              due</span
            >
            <span
              class="title-subtext"
              *ngIf="
                dashboardType === REVIEWER_DASHBOARD &&
                tile.tileCode === enumReviewerTileCode.ExtComm &&
                unassignedReviewerExtCommVisible
              "
            >
              ({{ tilesCount["unassignedECCount"] ?? 0 }} unassigned)</span
            >

            <span
              class="title-subtext"
              *ngIf="tile.tileCode === enumReviewerTileCode.GlobalEng"
            >
              (in Draft)</span
            >
          </h1>
          <p class="dds-card__text">
            {{ tile.title }}
            <span
              *ngIf="
                dashboardType === 'NCADashboard' &&
                tile.tileCode === 'CompleteMarsNcaForm'
              "
            >
              ({{ tilesCount[MARSNcasDraftCount] ?? 0 }} <br />
              draft)</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
