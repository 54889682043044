import { Component, OnInit, Input } from '@angular/core';
import { NcaSummaryWebapiService, NCASummaryFormPeriod } from '../../http/dashboard/nca-summary-webapi.service';
import { debounceTime, filter, Subject, switchMap, takeUntil, map } from 'rxjs';
import { DropdownItem } from '../../common/services/dropdowns';
import { SelectOptions, SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { ChartEmitData, ChartItem } from '../../common/models/chart-item.model';

@Component({
  selector: 'app-nca-summary-footer',
  templateUrl: './nca-summary-footer.component.html',
  styleUrl: './nca-summary-footer.component.less'
})
export class NcaSummaryFooterComponent implements OnInit  {

  @Input() periodLookupList: SelectItemOptions[] = [];
  periodList: any = [];
  @Input() periodID: number = 0;

  grandTotal: number = 0;
  totalActive: number = 0;
  totalCompleted: number = 0;

  completedAccepted: number = 0;
  declined: number = 0;
  disContinued: number = 0;
  serveWithConditions: number = 0;
  convertedToCC: number = 0;
  deFactoAcceptedBySystem: number = 0;
  deFactoAcceptedByNCA: number = 0;
  notRequired: number = 0;

  private itemColor: string[] = ['#009A44', '#000000', '#C4D600', '#62B5E5', '#FFCD00'];
  private itemLabel: string[] = ['Completed', 'Discontinued', 'Converted to CC', 'De-facto accepted', 'NCA Procedures Not Required/Accepted'];
  private itemLabelClickSendValues = ['completed', 'discontinued', 'convertedToCC', 'deFactoAccepted', 'notRequired'];
  barChartTitle: string = '';
  bars: ChartItem[] = [];
  isChartDataLoaded: boolean = false;

  selectPeriodOptions: SelectOptions = {
    placeholder: "Select",
    size: Size.md,
    disabled: false
  };
  constructor(private readonly ncaSummaryWebapiService : NcaSummaryWebapiService) { }

  ngOnInit(): void {
    this.getLookupData();
  }

  getLookupData() {   
    this.ncaSummaryWebapiService.getNCAFormPeriod().subscribe({
      next: (response: any) => {
        this.periodList = response;
        response.forEach((lookupData: NCASummaryFormPeriod) => {
          this.periodLookupList.push({
            value: lookupData.ncaFormPeriodid,
            heading: lookupData.ncaFormPeriodName,
            key: lookupData.ncaFormPeriodCode
          } as SelectItemOptions);
        });
        this.periodID = 1;
        this.getNCASummarybyStatus("CurrentFY");
        this.GetNcaCompletedSummaryBySpecialist("CurrentFY");
      },
      error: (err) => {
        //Handle error
      }
    });
  }

  periodChangeEvent($event: any) {
    let periodCode = this.periodList.find((item : any) => item.ncaFormPeriodid === $event).ncaFormPeriodCode;
    periodCode = periodCode.replace(' ','');
    this.getNCASummarybyStatus(periodCode);
    this.GetNcaCompletedSummaryBySpecialist(periodCode);
  }

  getNCASummarybyStatus(periodCode : any) {
    this.ncaSummaryWebapiService.GetCompletedNCASummaryByStatus(periodCode).subscribe({
      next: (response: any) => {
        if (response.ncaCompletedSummaryByStatusItems.length > 0) {
          this.grandTotal = response.ncaCompletedSummaryByStatusItems.
            find((item: any) => item.caption === "Grand total")?.captionValue;
          this.totalActive = response.ncaCompletedSummaryByStatusItems.
            find((item: any) => item.caption === "Total active")?.captionValue;
          this.totalCompleted = response.ncaCompletedSummaryByStatusItems.
            find((item: any) => item.caption.trimEnd() === "Total completed")?.captionValue;

          this.completedAccepted = response.ncaCompletedSummaryByStatusItems.
            find((item: any) => item.caption === "Completed - accepted")?.captionValue;
          this.declined = response.ncaCompletedSummaryByStatusItems.
            find((item: any) => item.caption === "Completed - do not serve")?.captionValue;
          this.serveWithConditions = response.ncaCompletedSummaryByStatusItems.
            find((item: any) => item.caption === "Completed - serve with conditions")?.captionValue;
          this.disContinued = response.ncaCompletedSummaryByStatusItems.
            find((item: any) => item.caption === "Discontinued")?.captionValue;
          this.convertedToCC = response.ncaCompletedSummaryByStatusItems.
            find((item: any) => item.caption === "Converted to CC")?.captionValue;
          this.deFactoAcceptedBySystem = response.ncaCompletedSummaryByStatusItems.
            find((item: any) => item.caption === "De-facto accepted [by System]")?.captionValue;
          this.deFactoAcceptedByNCA = response.ncaCompletedSummaryByStatusItems.
            find((item: any) => item.caption === "De-facto accepted [by NCA Team]")?.captionValue;
          this.notRequired = response.ncaCompletedSummaryByStatusItems.
            find((item: any) => item.caption === "NCA procedures not required/accepted")?.captionValue;
        }
      },
      error: (err) => {
        //Handle error
      }
    });
  }
  
  GetNcaCompletedSummaryBySpecialist(periodCode: any) {
    this.ncaSummaryWebapiService.GetNcaCompletedSummaryBySpecialist(periodCode).subscribe({
      next: (response: any) => {
        this.bars = [];
        response.forEach((value: any) => {
          let index = 0;
          this.bars.push({
            isLabelVisible: false,
            itemColor: this.itemColor,
            itemLabel: this.itemLabel,
            itemTooltip: this.itemLabel,
            itemValues: [Number(value.ncaSpecialistAssignmentByStatus.find((item: any) => item.clientMilestoneStatusCode === "Accepted").assignmentsNumber),
              Number(value.ncaSpecialistAssignmentByStatus.find((item: any) => item.clientMilestoneStatusCode === "Discontinue").assignmentsNumber),
              Number(value.ncaSpecialistAssignmentByStatus.find((item: any) => item.clientMilestoneStatusCode === "CcDueDate").assignmentsNumber),
              Number(value.ncaSpecialistAssignmentByStatus.find((item: any) => item.clientMilestoneStatusCode === "DefactoAccepted").assignmentsNumber),
              Number(value.ncaSpecialistAssignmentByStatus.find((item: any) => item.clientMilestoneStatusCode === "NotRequired").assignmentsNumber)],
            label: value.ncaSpecialistOutlookDisplayName ?? '',
            itemLabelClickSendValues: [],
            legendItemClickSendValues: this.itemLabelClickSendValues,
            //itemValuesClickSendValues: [value.acceptedBy]
            itemValuesClickSendValues: []
          });
        });
        this.isChartDataLoaded = true;
      },
      error: (err) => {
        //Handle error
      }
    });
  }

  barChartClick(event: ChartEmitData[]) {

  }
}
