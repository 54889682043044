import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Saver } from './saver';
import { AutosaverService } from './autosaver.service';
import { StringGenerator } from '../services/string-generator.service';

@Directive({
    selector: '[autosave-module]'
})
export class AutosaveModuleDirective implements OnInit {
    @Input('autosave-module') saver: Saver | null = null;

    constructor(
        private autosaverService: AutosaverService,
        private renderer: Renderer2,
        private element: ElementRef,
        private stringGenerator: StringGenerator) {
    }

    ngOnInit() {
        const setSaver = () => {
            let guid = this.stringGenerator.generateGuid();
            this.renderer.setAttribute(this.element.nativeElement, 'data-autosaver-module-id', guid);
            this.autosaverService.autosaveModules = this.autosaverService.autosaveModules || {} as any;
            this.autosaverService.autosaveModules[guid] = this.saver; //register saver in global scope
        }

        const checkSaver = () => {
            if (this.saver)
                setSaver();
            else setTimeout(() => checkSaver(), 100);
        }

        checkSaver();
    }
}