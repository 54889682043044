<div class="dds-modal-overlay" (click)="close()"></div>

<div ddsResize class="dds-modal dds-modal_lg">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Add link</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      (clicked)="close()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div
      *ngIf="showRMReviewedGuidanceNote"
      class="sub-text-section d-flex margin-top-xs relative"
    >
      <span class="icon-s inline-block relative icon-pinned"></span>
      <span>
        <div class="eclipse-header-grey padding-left-xs">
          {{ rmReviewedGuidanceNoteHeader }}
        </div>
        <div
          class="vertical-line-green absolute"
          *ngIf="showRMReviewedGuidanceNote"
        ></div>
      </span>
    </div>
    <div class="padding-left-l padding-bottom-m">
      <span>
        <div class="margin-top-xs text-gray" *ngIf="showRMReviewedGuidanceNote">
          <div class="eclipse-content-grey">
            {{ rmReviewedGuidanceNoteBody }}
          </div>
        </div>
      </span>
    </div>
    <p class="dec font-size-14">
      Uploading a file is preferred. In certain circumstances (videos, files
      >50MB, agreement with RM required) providing a hyperlink to an approved
      collaboration site may be permitted.
    </p>

    <div class="sub-info font-size-14 margin-bottom-l">
      <span class="eclipse-icon icon-s icon-bullet-star-green"></span>Grant Risk
      Manager access to the file being submitted.
    </div>

    <div class="inputs-container">
      <div class="margin-bottom-l">
        <p class="input-label font-size-12">
          <span *ngIf="documentLink.length === 0"
            ><img src="\assets\images\draftIcon.svg"
          /></span>
          Add link (videos, files > 50MB, agreement with RM required)
        </p>
        <dds-input
          [(ngModel)]="documentLink"
          [placeholder]="'Add document link'"
        >
        </dds-input>
      </div>

      <div class="margin-bottom-l">
        <p class="input-label font-size-12">
          <span *ngIf="documentTitle.length === 0"
            ><img src="\assets\images\draftIcon.svg" /></span
          >File title
        </p>
        <dds-input [(ngModel)]="documentTitle" [placeholder]="'Add file title'">
        </dds-input>
      </div>

      <div class="row margin-bottom-l">
        <div class="col-6">
          <p class="input-label font-size-12">
            <span *ngIf="documentType === 0"
              ><img src="\assets\images\draftIcon.svg" /></span
            >Type
          </p>
          <dds-select
            [list]="documentTypeItemsList"
            [(ngModel)]="documentType"
            (ngModelChange)="updateStatusDropdown()"
          ></dds-select>
        </div>
        <div class="col-6">
          <p class="input-label font-size-12">
            <span *ngIf="documentStatus === 0"
              ><img src="\assets\images\draftIcon.svg" /></span
            >Status
          </p>
          <dds-select
            [list]="documentStatusItemList"
            [(ngModel)]="documentStatus"
            (ngModelChange)="updateDocStatusDropdown()"
          ></dds-select>
        </div>
      </div>

      <div class="comment margin-bottom-l">
        <p class="input-label font-size-12">
          <span *ngIf="documentType === otherStatus && !documentComment"
            ><img src="\assets\images\draftIcon.svg" />
          </span>
          <span
            >Comment
            <span *ngIf="documentType != otherStatus && !documentComment"
              >(optional)</span
            ></span
          >
        </p>

        <dds-textarea [(ngModel)]="documentComment" [placeholder]="'Text'">
        </dds-textarea>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item"
        (clicked)="closeAll()"
        (keyboardClicked)="closeAll()"
        [disabled]="!formValid()"
        >Save</dds-button
      >

      <dds-button
        class="dds-modal__footer-item"
        kind="secondaryLoud"
        (clicked)="close()"
        (keyboardClicked)="close()"
        >Close</dds-button
      >
    </div>
  </div>
</div>
