import { UploaderOptions, UploadFile, UploadFileStatus } from '@usitsdasdesign/dds-ng/uploader';
import { ButtonKind, Column, ExtThemes, LabelPosition, Size, Themes, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { DropdownOptions } from '@usitsdasdesign/dds-ng/dropdown';
import { ProgressOptions } from '@usitsdasdesign/dds-ng/progress';
import { SelectItemOptions, SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';

export const uploadAreaOptions: UploaderOptions = {
  heading: '',
  dragAndDropText: 'Drag and drop file(s)',
  description: 'DOCX, PPTX, PDF, JPG, PNG or ZIP. Max size of 50MB.',
  isDragAndDrop: true,
  isMultiple: true,
  acceptFormats: '',
  acceptExtensions: '',
  restrictedFormats: '',
  restrictedExtensions: '',
  exceptSymbols: '',
  btnLabel: 'Select file(s)',
  chooseFileBtnKind: 'primary',
  isDownloadable: false,
  allowFilesWithoutType: false,
  disabled: false,
  isInverse: false
};
export const textFileOptions: TextareaOptions = {
  placeholder: '',
  minHeight: 100,
  maxLength: 400,
  maxLengthPosition: 'bottom-right',
  size: Size.lg
};
export const textareaOptions: TextareaOptions = {
  placeholder: 'Enter Comment',
  minHeight: 100,
  maxLength: 400,
  maxLengthPosition: 'bottom-right',
  size: Size.lg
};
export const selectDocumentTypeOptions: SelectOptions = {
  label: '',
  labelPosition: LabelPosition.external,
  description: '',
  placeholder: 'Select  type',
  size: Size.lg,
  disabled: false,
  isResponsive: false,
  isRequired: true,
  isInverse: false,
  customClass: '',
  stickerWidth: 0,
  stickerShift: 0,
  stickerMaxHeight: "",
  stickerIsDisabled: false,
  stickerPosition: "bottom-left",
  stickerIndent: 0,
  stickerCustomClass: "",
  stickerIsDynamic: true
};
export const options: ProgressOptions = {
  size: Size.md,
  theme: 'green',
  isInverse: false,
  label: '',
  description: '',
  isVertical: false,
};
export const DocumentColumns: Column[] = [
  { name: 'Title', header: 'Title', dataType: 'string' },
  { name: 'Document type', header: 'Document type', dataType: 'string' },
  { name: 'Comment(optional)', header: 'Comment (optional)', dataType: 'string' },
];
export const fileTooltipOptions: TooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'bottom-left',
  tooltipIndent: 1,
  tooltipHasBeak: true,
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipTheme: Themes.dark
};


