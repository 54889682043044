import { DashboardData } from './start-page.model';

export interface ReviewerDashboardData extends DashboardData {
  clientNumber: string;
  continuanceExpiredDate: string;
  openOpportunities: string;
  priorFyClosedWBS?: string;
  netServiceRevenue: string;
  submittedDate: string;
  requestedReviewDate: string;
  rushRequest?: string;
  consultationRequested?: string;
  registeredForAdvisoryAndConsultingDate: string;
  registeredForDeloitteDate: string;
  slComments?: string;
  lastOpportunityWithClosedWonDate: string;
  lastInvoiceDate: string;
  programAccount: string;
  isGlobalCrownJewel: string;
  [key: string]: any;
  submissionTitle: string;
  focusUrl: string;
  jupiterId: string;
  lep: string;
  pendingReviewer: string;
  lastStatus: string;
  dateOfLastStatusChange: string;
  pendingRequiredReviewers: string | string[];
  pendingOptionalReviewers: string | string[];
  isRevisionRequested: boolean;
  submissionStatus?: string;
  submissionId?: number;
  reviewedDate?: string;
  submissionStatusId?: number;
  typeOfCommunication?: string;
  responsiblePractitioner?: string;
  enableAssignToMe?: boolean;
  enableStartReview?: boolean;
  riskManager?: string;
  rmSupport?: string;
  communicationTypeId?: number;
  globalEngagementStatus: string;
  deliveryCenter: string;
  memberFirm: string;
  wbs: string;
  offering: string;
  responsibleDeliveryCenterPPMD: string;
  engagementId: number;
}

export interface ReviewerDashboardTilesCount {
  pendingReviewsCount: number;
  pendingReviewsOthersCount: number;
  ncaPendingApprovalCount: number;
  ccDueCount: number;
  recentReviewsCount: number;
  selfReviewChangesCount: number;
  externalCommunicationsCount: number;
  pendingECCount: number;
  unassignedECCount: number;
  allSubmissionsECCount: number;
  globalEngagementSubmissionCount: number;
}

export interface RecentlyViewedSubmissions {
  opportunityNumber: string;
  submissionId: number;
  submissionTitle: string;
  submissionType: string;
  submissionTypeCode: string;
  submissionSubType: string | null;
  submissionStatus: string;
}

export interface RecentlyViewedSubmissionList {
  totalCount: number;
  submissionDetails: RecentlyViewedSubmissions[];
}

export interface ReviewerTileCodeDetail {
  title: string;
  countKey: keyof ReviewerDashboardTilesCount;
  tileCode: string;
}

export enum ReviewerTileCode {
  PendingReviews = 'PendingReviews',
  PendingReviewsOthers = 'PendingReviewsOthers',
  NCAPendingApproval = 'NCAPendingApproval',
  CCDueNow = 'CCDueNow',
  RecentReviews = 'RecentReviews',
  SelfReviewChanges = 'SelfReviewChanges',
  ExtComm = 'ExternalCommunications',
  GlobalEng = 'GlobalEngagement',
}

export const ReviewerTileCodeDetails: Map<
  ReviewerTileCode,
  ReviewerTileCodeDetail
> = new Map([
  [
    ReviewerTileCode.PendingReviews,
    {
      title: 'Pending reviews',
      countKey: 'pendingReviewsCount',
      tileCode: ReviewerTileCode.PendingReviews,
    },
  ],
  [
    ReviewerTileCode.PendingReviewsOthers,
    {
      title: 'Pending reviews of others',
      countKey: 'pendingReviewsOthersCount',
      tileCode: ReviewerTileCode.PendingReviewsOthers,
    },
  ],
  [
    ReviewerTileCode.RecentReviews,
    {
      title: 'Recent reviews',
      countKey: 'recentReviewsCount',
      tileCode: ReviewerTileCode.RecentReviews,
    },
  ],
  [
    ReviewerTileCode.SelfReviewChanges,
    {
      title: 'Self-review changes',
      countKey: 'selfReviewChangesCount',
      tileCode: ReviewerTileCode.SelfReviewChanges,
    },
  ],
  [
    ReviewerTileCode.NCAPendingApproval,
    {
      title: 'NCAs pending approvals',
      countKey: 'ncaPendingApprovalCount',
      tileCode: ReviewerTileCode.NCAPendingApproval,
    },
  ],
  [
    ReviewerTileCode.CCDueNow,
    {
      title: 'Client continuances overview',
      countKey: 'ccDueCount',
      tileCode: ReviewerTileCode.CCDueNow,
    },
  ],
  [
    ReviewerTileCode.ExtComm,
    {
      title: 'External communications',
      countKey: 'externalCommunicationsCount',
      tileCode: ReviewerTileCode.ExtComm,
    },
  ],
  [
    ReviewerTileCode.GlobalEng,
    {
      title: 'Global engagement submissions',
      countKey: 'globalEngagementSubmissionCount',
      tileCode: ReviewerTileCode.GlobalEng,
    },
  ],
]);
