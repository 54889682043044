export class ExternalCommUrlConstants {
  public static readonly GET_COMMUNICATION_TYPE =
    'ExternalComm/ExternalComm/GetCommunicationType';
  public static readonly POST_INITIATE_EXT_COMM =
    'ExternalComm/Submission/InitiateSubmission';
  public static readonly GET_EXT_COMM_SUBMISSION_DETAILS =
    'ExternalComm/Submission/GetSubmissionDetails?submissionId=';
  public static readonly PUT_EXT_COMM_SUBMISSION_DETAILS =
    'ExternalComm/Submission/UpdateSubmission';
  public static readonly GET_EXT_COMM_LEFTNAV =
    'ExternalComm/Submission/GetLeftMenuSubmissionDetails?communicationTypeId=';
  public static readonly GET_EXT_COMM_INDUSTRY_SECTOR =
    'ExternalComm/ExternalComm/GetIndustrySector'
  public static readonly GET_MARKET_OFFERING =
  'ExternalComm/ExternalComm/GetMarketOffering'
  public static readonly GET_OFFERING =
  'ExternalComm/ExternalComm/GetOffering'
  public static readonly GET_OFF_PORTFOLIO =
  'ExternalComm/ExternalComm/GetOfferingPortfolio'

  public static readonly GET_SUBMISSION_COMMUNICATION_ATTRIBUTES =
    'ExternalComm/Submission/GetSubmissionCommunicationAttribute?submissionId=';
  public static readonly POST_SUBMISSION_COMMUNICATION_ATTRIBUTES =
    'ExternalComm/Submission/SaveSubmissionCommunicationAttribute';
  public static readonly GET_EXT_RISK_ASSESSMENT_DETAILS =
    'ExternalComm/Submission/GetSubmissionRiskAssessment?submissionid=';
  public static readonly SAVE_RISK_ASSESSMENT_DATA =
    'ExternalComm/Submission/SaveSubmissionRiskAssessment';

  public static readonly GET_DOCUMENT_TYPE_DROPDOWN =
    'ExternalComm/SubmissionDocument/GetDocumentType';
  public static readonly GET_DOCUMENT_STATUS_DROPDOWN =
    'ExternalComm/SubmissionDocument/GetDocumentStatus?submissionstatusid=';
  public static readonly POST_DOCUMENT_SAVE =
    'ExternalComm/SubmissionDocument/SaveSubmissionDocument';
  public static readonly GET_DOCUMENT_LIST =
    'ExternalComm/SubmissionDocument/GetSubmissionDocumentDetails?submissionid=';
  public static readonly EDIT_DOCUMENT_DETAILS =
    'ExternalComm/SubmissionDocument/EditSubmissionDocument';
  public static readonly DELETE_DOCUMENT_DETAILS =
    'ExternalComm/SubmissionDocument/DeleteSubmissionDocument?submissiondocumentid=';
}
