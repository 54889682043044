<div *ngIf="docMetaData.length > 0" class="table-with-data">
  <div class="toggle-data d-flex justify-content-between align-items-center">
    <span>
      <dds-toggle-group
        [title]="groupOptions.title"
        [isInverse]="groupOptions.isInverse"
        [isRequired]="groupOptions.isRequired"
      >
        <dds-toggle
          [(ngModel)]="toggleOpen"
          [disabled]="options.disabled"
          [isProcessingStatusActive]="options.isProcessingStatusActive"
          [label]="options.label"
          [ariaLabel]="options.ariaLabel"
          [theme]="options.theme"
          [isError]="options.isError"
          [isRequired]="options.isRequired"
          [errorState]="options.errorState"
          [customClass]="options.customClass"
          (stateChanged)="stateChanged($event)"
          (processingStateActivated)="updateServerValue($event)"
          #toggle
        ></dds-toggle>
      </dds-toggle-group>
    </span>

    <span>
      <dds-uploader
        *ngIf="
          hideAttachDocumentButton &&
          isSubmissionStatusRMorSelfReviewed &&
          permissionObj['isIntakeSubmissionAttachDocumentVisible']
        "
        [heading]="attachDocumentBtn.heading"
        [chooseFileBtnKind]="attachDocumentBtn.chooseFileBtnKind"
        [theme]="attachDocumentBtn.theme"
        [btnLabel]="attachDocumentBtn.btnLabel"
        [isInverse]="attachDocumentBtn.isInverse"
        [isMultiple]="attachDocumentBtn.isMultiple"
        [disabled]="!permissionObj['isIntakeSubmissionAttachDocumentEnable']"
        (uploadedItems)="openUploadModel($event)"
      ></dds-uploader>
    </span>
  </div>

  <div class="div-headers">
    <table class="dds-data-table dds-data-table_selection">
      <thead class="dds-data-table__header header-style">
        <tr>
          <th
            *ngIf="showArchiveIt"
            class="dds-data-table__header-cell archive-Header"
          >
            <div class="dds-data-table__content"></div>
            <span>{{ archiveItHeader }} </span>
          </th>
          <th
            *ngFor="let column of sampleColumns"
            class="dds-data-table__header-cell"
          >
            <div class="dds-data-table__content">
              <span>{{ column.header || column.name }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="dds-data-table__body">
        <tr
          *ngFor="let row of docToShow; index as i"
          class="dds-data-table__row"
        >
          <td *ngIf="showArchiveIt" class="dds-data-table__cell">
            <dds-checkbox
              *ngIf="row.isVisible"
              (click)="updateRow(row)"
              [value]="row.isChecked ?? false"
              [theme]="checkBoxOptions.theme ?? 'green'"
              [ariaLabel]="row.ariaLabel ?? ''"
              [disabled]="row.isDisabled ?? false"
              [disabled]="
                !permissionObj['isIntakeSubmissionDocumentTableEnable']
              "
            ></dds-checkbox>
            <span
              class="d-flex align-items-center"
              *ngIf="
                row.archivalStatus !== null &&
                row.archivalStatus !== '' &&
                row.isArchiveReady
              "
            >
              <span
                class="dds-icon dds-icon_select_all__l__stroke tick-icon-green font-size-20 line-height-sm font-bold-400"
              ></span>
              <span>{{ row.archivalStatus }}</span>
            </span>
            <span
              class="d-flex align-items-center col-md-1"
              *ngIf="row.archivalStatus === null && row.isArchiveReady"
            >
              <span>Pending</span>
            </span>
          </td>
          <td class="dds-data-table__cell">
            <div class="flex-container">
              <div class="width100 long-title margin-left-xs">
                <span
                  [ddsTooltip]="row.documentTitle"
                  [tooltipInvokeType]="optionsToolTip.tooltipInvokeType ?? ''"
                  [tooltipType]="optionsToolTip.tooltipType ?? ''"
                  [tooltipSize]="optionsToolTip.tooltipSize ?? ''"
                  [tooltipPosition]="optionsToolTip.tooltipPosition ?? ''"
                  [tooltipIndent]="optionsToolTip.tooltipIndent ?? 0"
                  [tooltipHasBeak]="optionsToolTip.tooltipHasBeak ?? false"
                  [tooltipIsDisabled]="
                    optionsToolTip.tooltipIsDisabled ?? false
                  "
                  [tooltipIsOutsideClick]="
                    optionsToolTip.tooltipIsOutsideClick ?? false
                  "
                  [tooltipIsDynamic]="true"
                  [tooltipTheme]="optionsToolTip.tooltipTheme ?? ''"
                  [tooltipShowDelay]="optionsToolTip.tooltipShowDelay ?? 0"
                  [tooltipHideDelay]="optionsToolTip.tooltipHideDelay ?? 0"
                  ddsFocus
                >
                  <div
                    class="d-flex align-items-center"
                    *ngIf="row.submissionDocumentTypeId === 1"
                  >
                    <div
                      class="dds-card__image-block txt-Style margin-right-xs"
                    ></div>
                    <div
                      class="green-color long-title"
                      (click)="onDownload(row)"
                      *ngIf="
                        permissionObj[
                          'isIntakeSubmissionDocumentDownloadEnable'
                        ] && !checkDocumentStatusEligiblity(row.submissionDocumentId);
                        else noDownload
                      "
                    >
                      <u class="long-title">
                        {{ row.documentTitle }}
                      </u>
                    </div>
                    <ng-template #noDownload>
                      <span (click) = "disabledDownloadClickHandler(row.submissionDocumentId)">
                        {{ row.documentTitle }}
                      </span>
                    </ng-template>
                  </div>
                  <div
                    class="green-color d-flex align-items-center"
                    (click)="openLink(row.documentUrl)"
                    *ngIf="row.submissionDocumentTypeId === 2"
                  >
                    <div>
                      <img
                        class="margin-right-xxs"
                        src="\assets\dds-icons\linkIcon.svg"
                      />
                    </div>
                    <u class="long-title">
                      {{ row.documentTitle }}
                    </u>
                  </div>
                </span>
              </div>
            </div>
          </td>
          <td class="dds-data-table__cell padding-zero">
            <div
              *ngIf="row.comments"
              [ddsTooltip]="row.comments"
              class="dds-card__image-block msg-style"
            ></div>
          </td>
          <td class="dds-data-table__cell col-md-2">
            <span>{{ row.documentType }}</span>
          </td>
          <td class="dds-data-table__cell col-md-2">
            <span
              class="padding-status"
              [style.color]="
                transformStatus(row.documentStatus) | statusStyle : 'color'
              "
              [style.backgroundColor]="
                transformStatus(row.documentStatus)
                  | statusStyle : 'backgroundColor'
              "
            >
              <ng-container
                *ngIf="
                  row.isArchiveReady && row.archivalStatus != null;
                  else normalDocstatus
                "
              >
                {{ row.archivalStatus }}
              </ng-container>

              <ng-template #normalDocstatus>
                {{ row.documentStatus }}</ng-template
              >
            </span>
          </td>
          <td class="dds-data-table__cell">
            <span>{{ row.createdDate }}</span>
          </td>
          <td class="dds-data-table__cell">
            <span>{{ row.createdBy }}</span>
          </td>
          <td class="dds-data-table__cell actions-icons">
            <dds-button
              *ngIf="
                isDeleteEditBtnVisible() &&
                permissionObj['isIntakeSubmissionDocumentEditEnable']
              "
              [ddsTooltip]="'Edit'"
              ddsVariant="icon"
              class="dds-card__image-block action-items"
              (click)="onEdit(row)"
              [theme]="actionButtonOptions.theme ?? ''"
              [kind]="actionButtonOptions.kind ?? ''"
              icon="dds-icon_edit"
            >
            </dds-button>
            <dds-button
              *ngIf="
                isDeleteEditBtnVisible() &&
                permissionObj['isIntakeSubmissionDocumentDeleteEnable']
              "
              [ddsTooltip]="'Delete'"
              class="dds-card__image-block action-items"
              (click)="onDelete(row)"
              ddsVariant="icon"
              [theme]="actionButtonOptions.theme ?? ''"
              [kind]="actionButtonOptions.kind ?? ''"
              icon="dds-icon_delete"
            >
            </dds-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
