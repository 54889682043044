<div class="dds-modal-overlay" (click)="closeModal()"></div>

<div ddsResize class="dds-modal dds-modal_lg modalWidth">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Modify market offering configuration</span>
    <dds-button
      [size]="modalCloseButtonOptions.size ?? emptyString"
      [icon]="modalCloseButtonOptions.icon ?? emptyString"
      [kind]="modalCloseButtonOptions.kind ?? emptyString"
      [theme]="modalCloseButtonOptions.theme ?? emptyString"
      [ariaLabel]="modalCloseButtonOptions.ariaLabel ?? emptyString"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="closeModal()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div>
      <div class="comments-text-label eclipse-small-header-grey">
        <p>{{ rowData?.offeringPortfolio }} / {{ rowData?.offering }}</p>
      </div>
      <div>
        <p>
          <span class="font-bold-600 font-size-16 margin-right-xxs">
            {{ rowData?.marketOffering }}
          </span>
          <span
            [ngClass]="{
              'active-status': rowData.activationStatus === 'Active',
              'inactive-status': rowData.activationStatus === 'Inactive'
            }"
          >
            {{ rowData.activationStatus }}
          </span>
        </p>
      </div>

      <!-- multiselectdropdown -->
      <form [formGroup]="form">
        <div class="margin-top-s">
          <span
            class="text-label comments-text-label eclipse-small-header-grey"
          >
            Submission types excluded from Self-review
          </span>
          <span
            class="icon-s inline-block icon-info relative position-alignment"
            [ddsTooltip]="
              'These submission types will always be excluded from self-review under this market offering'
            "
            [tooltipInvokeType]="groupToolTipOptions.tooltipInvokeType ?? ''"
            [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
            [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
            [tooltipPosition]="groupToolTipOptions.tooltipPosition ?? ''"
            [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
            [tooltipHasBeak]="groupToolTipOptions.tooltipHasBeak ?? false"
            [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
            ddsFocus
            [tooltipMaxWidth]="groupToolTipOptions.tooltipMaxWidth ?? 0"
          >
          </span>
          <div class="padding-top-xs value-box d-flex">
            <dds-multi-select
              class="box-size"
              [isResponsive]="multiSelectOptions.isResponsive ?? false"
              [isRequired]="multiSelectOptions.isRequired ?? false"
              [label]="multiSelectOptions.label ?? ''"
              [placeholder]="multiSelectOptions.placeholder ?? ''"
              [type]="multiSelectOptions.type ?? ''"
              [size]="htmlElementSize"
              [controlType]="multiSelectOptions.controlType ?? ''"
              [theme]="multiSelectOptions.theme ?? ''"
              [isInverse]="multiSelectOptions.isInverse ?? false"
              [list]="dropdownList"
              formControlName="dropdownCtrl"
            >
            </dds-multi-select>
            <div *ngIf="showSelectedTags">
              <span class="comments-text-label eclipse-small-header-grey"
                >Selected values:</span
              >
              <dds-tags>
                <dds-tag
                  class="tag-width"
                  *ngFor="let item of this.form.get('dropdownCtrl')?.value"
                  [theme]="options.theme ?? ''"
                  [isRemovable]="options.isRemovable ?? false"
                  [disabled]="options.disabled ?? false"
                  [customClass]="options.customClass ?? ''"
                  [size]="htmlElementSize"
                  [isError]="options.isError ?? false"
                  [isInverse]="options.isInverse ?? false"
                  (removed)="removeMultiSelectItemCommContain(item)"
                  #tagItems
                >
                  {{ item.label }}
                </dds-tag>
              </dds-tags>
            </div>
          </div>
        </div>
      </form>

      <!-- input control for fee -->
      <div class="margin-top-s">
        <span class="text-label comments-text-label eclipse-small-header-grey">
          Total Fees Threshold for Proposal
        </span>
        <span
          class="icon-s inline-block icon-info relative position-alignment"
          [ddsTooltip]="
            'Total fees ABOVE this value will cause any Proposal to be Self-review ineligible under this market offering.'
          "
          [tooltipInvokeType]="groupToolTipOptions.tooltipInvokeType ?? ''"
          [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
          [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
          [tooltipPosition]="groupToolTipOptions.tooltipPosition ?? ''"
          [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
          [tooltipHasBeak]="groupToolTipOptions.tooltipHasBeak ?? false"
          [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
          ddsFocus
          [tooltipMaxWidth]="groupToolTipOptions.tooltipMaxWidth ?? 0"
        >
        </span>
        <div class="padding-top-xs value-box d-flex">
          <dds-input
            class="box-size"
            [size]="inputOptions.size ?? ''"
            [isInverse]="inputOptions.isInverse ?? false"
            [errorState]="inputOptions.errorState ?? ''"
            [placeholder]="inputOptions.placeholder ?? ''"
            [tooltipIcon]="inputOptions.tooltipIcon ?? ''"
            [tooltipType]="inputOptions.tooltipType ?? ''"
            [tooltipSize]="inputOptions.tooltipSize ?? ''"
            [tooltipPosition]="inputOptions.tooltipPosition ?? ''"
            [tooltipIndent]="inputOptions.tooltipIndent ?? 20"
            [tooltipHasBeak]="inputOptions.tooltipHasBeak ?? false"
            [tooltipTheme]="inputOptions.tooltipTheme ?? ''"
            [(ngModel)]="totalFeeThresholds"
          >
          </dds-input>
        </div>
      </div>

      <!-- toggle control -->
      <div class="margin-top-s">
        <span class="text-label comments-text-label eclipse-small-header-grey">
          Technical/Independent Reviewer" availability on Deliverable
        </span>
        <span
          class="icon-s inline-block icon-info relative position-alignment"
          [ddsTooltip]="
            'Enables the Technical/Independent Reviewer field in the workflow table of any Deliverable under this market offering'
          "
          [tooltipInvokeType]="groupToolTipOptions.tooltipInvokeType ?? ''"
          [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
          [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
          [tooltipPosition]="groupToolTipOptions.tooltipPosition ?? ''"
          [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
          [tooltipHasBeak]="groupToolTipOptions.tooltipHasBeak ?? false"
          [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
          ddsFocus
          [tooltipMaxWidth]="groupToolTipOptions.tooltipMaxWidth ?? 0"
        >
        </span>
        <div class="padding-top-xs value-box d-flex">
          <dds-toggle-group
            [isInverse]="groupOptions.isInverse ?? false"
            [isRequired]="groupOptions.isRequired ?? false"
          >
            <dds-toggle
              [disabled]="toggleOptions.disabled ?? false"
              [isProcessingStatusActive]="
                toggleOptions.isProcessingStatusActive ?? false
              "
              [label]="toggleOptions.label ?? ''"
              [ariaLabel]="toggleOptions.ariaLabel ?? ''"
              [theme]="toggleOptions.theme ?? ''"
              [isInverse]="toggleOptions.isInverse ?? false"
              [isError]="toggleOptions.isError ?? false"
              [isRequired]="toggleOptions.isRequired ?? false"
              [errorState]="toggleOptions.errorState ?? ''"
              [customClass]="toggleOptions.customClass ?? ''"
              (stateChanged)="toggleStateChanged()"
              [value]="reviewerAvailability"
              #toggle
            ></dds-toggle>
          </dds-toggle-group>
        </div>
      </div>

      <div class="margin-top-s" *ngIf="rowData.modifiedDate && rowData.modifiedBy">
        <span class="text-label comments-text-label eclipse-small-header-grey">
          Last modified {{ rowData.modifiedDate | date : dateFormat }} by
          {{ rowData.modifiedBy }}
        </span>
      </div>

      <div class="horizontal-divider"></div>

      <div>
        <div class="d-flex justify-content-left padding-top-m">
          <div class="margin-right-s">
            <dds-button class="custom-button" (click)="save()">Save</dds-button>
          </div>
          <div>
            <dds-button
              class="custom-button"
              [theme]="buttonOptions.theme ?? ''"
              [kind]="buttonOptions.kind ?? ''"
              [size]="buttonOptions.size ?? ''"
              [width]="buttonOptions.width ?? ''"
              [icon]="buttonOptions.icon ?? ''"
              [isIconLeft]="buttonOptions.isIconLeft ?? false"
              [role]="buttonOptions.role ?? ''"
              [isInverse]="buttonOptions.isInverse ?? false"
              [isColorBg]="buttonOptions.isColorBg ?? false"
              [isLoading]="buttonOptions.isLoading ?? false"
              [ariaLabel]="buttonOptions.ariaLabel ?? ''"
              [customClass]="buttonOptions.customClass ?? ''"
              [disabled]="buttonOptions.disabled ?? false"
              (clicked)="closeModal()"
              >Cancel</dds-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
