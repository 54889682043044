export class AdminUrlConstants {
  private static readonly BASE_URL = 'Admin/Intake';

  public static readonly GET_ADMIN_EXTCOMM_TRAINING_TABLE_DATA =
    'Admin/ExternalCommunication/GetExtCommTraining';
  public static readonly GET_ADMIN_EXTCOMM_RISK_MANAGER_TABLE_DATA =
    'Admin/ExternalCommunication/GetRMConfig?searchItem=&isActive=';
  public static readonly GET_MARKET_OFFERING_TABLE_DATA = `${AdminUrlConstants.BASE_URL}/GetMarketOfferingData`;
  public static readonly SAVE_MARKET_OFFERING_DATA = `${AdminUrlConstants.BASE_URL}/UpdateMarketOffering`;
  public static readonly GET_GLOBAL_CONFIGURATION_TABLE_DATA = `${AdminUrlConstants.BASE_URL}/GetAppConfigurationLabel`;
  public static readonly UPDATE_GLOBAL_CONFIGURATION_LABEL = `${AdminUrlConstants.BASE_URL}/UpdateConfigurationLabel`;
  public static readonly SAVE_AND_UPDATE_ADMIN_EXTCOMM_TRAINING_TABLE_DATA =
    'Admin/ExternalCommunication/UpdateExtCommTraining';
  public static readonly GET_GLOBAL_SEARCH_DATA = 'Admin/GlobalSearch/Search';
  public static readonly GET_LAST_SEARCH_GLOBAL_SEARCH_DATA =
    'Admin/GlobalSearch/GetLastSearch';
  public static readonly ADD_LAST_SEARCH_GLOBAL_SEARCH_DATA =
    'Admin/GlobalSearch/AddLastSearchItem';
  public static readonly SAVE_AND_UPDATE_ADMIN_EXTCOMM_RISK_MANAGER_TABLE_DATA =
    'Admin/ExternalCommunication/SaveRMConfig';
  public static readonly ADMIN_PAGE_REFRESH = 'Admin/Admin/RefreshApplication';
  public static readonly GET_ROLES = 'Admin/RoleAssignment/GetRoles';
  public static readonly GET_LIST_BY_ROLE =
    'Admin/RoleAssignment/GetListByRole';
  public static readonly GET_ROLE_ATTRIBUTE_VALUES = 
    'Admin/RoleAssignment/GetRoleAttributeValues';
  public static readonly GET_GLOBAL_ENG_ROLE_CONFIG =
    'Admin/GlobalEngagement/GetGlobalEngRoleConfig?roleId=';
  public static readonly POST_GLOBAL_ENG_ROLE_CONFIG =
    'Admin/GlobalEngagement/SaveGlobalEngRoleConfig';
  public static readonly GET_EMPLOYEES = 'Admin/RoleAssignment/GetEmployees';
  public static readonly UPSERT_ADMIN_ROLE_ASSIGNMENT =
    'Admin/RoleAssignment/UpsertAdminRole';
  public static readonly GET_GLOBAL_CONFIGURATION =
    'Admin/Admin/GetGlobalConfigurations';
  public static readonly SAVE_GLOBAL_CONFIGURATION =
    'Admin/Admin/UpdateGlobalConfiguration';
  public static readonly GET_CONFIGURATION_CATEGORY =
    'Admin/Admin/GetAppConfigurationCategories';

    public static readonly GET_ALL_ASSIGNED_INDUSTRIES =
    'Admin/RoleAssignment/GetAllAssignedIndustries';
}
