<div
  class="uploader-class"
  [ngClass]="{
    'hide-uploader-button':
      !permissionObj['isIntakeSubmissionAttachDocumentVisible'],
    'hide-uploader':
      !permissionObj['isIntakeSubmissionDocumentDragAndDropVisible']
  }"
  *ngIf="!isDisabledDocuments"
>
  <dds-uploader
    id="intake-uploader"
    *ngIf="shouldShowUploader"
    [heading]="uploadAreaOptions.heading"
    [description]="uploadAreaOptions.description"
    [dragAndDropText]="uploadAreaOptions.dragAndDropText"
    [isDragAndDrop]="uploadAreaOptions.isDragAndDrop"
    [isMultiple]="uploadAreaOptions.isMultiple"
    [acceptFormats]="uploadAreaOptions.acceptFormats"
    [acceptExtensions]="uploadAreaOptions.acceptExtensions"
    [restrictedFormats]="uploadAreaOptions.restrictedFormats"
    [restrictedExtensions]="uploadAreaOptions.restrictedExtensions"
    [exceptSymbols]="uploadAreaOptions.exceptSymbols"
    [btnLabel]="
      isSubmissionStatusRMorSelfReviewed
        ? 'Attach Document(s)'
        : uploadAreaOptions.btnLabel
    "
    [isInverse]="uploadAreaOptions.isInverse"
    [disabled]="!permissionObj['isIntakeSubmissionDocumentDragAndDropEnable']"
    [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind"
    (uploadedItems)="openUploadModel($event)"
  ></dds-uploader>

  <dds-button
    *ngIf="isAddLinkButtonVisible"
    class="button-style"
    [theme]="options.theme"
    [kind]="options.kind"
    [size]="options.size"
    [width]="options.width"
    [disabled]="!permissionObj['isIntakeSubmissionAddDocumentLinkEnable']"
    (click)="openDocumentLinkModel()"
    >Add link</dds-button
  >
</div>

<!-- closeout form /finalize form -->
<div [ngClass]="{ hidden: !showDocumentTable }">
  <app-document-table
    *ngIf="permissionObj['isIntakeSubmissionDocumentTableVisible']"
    [submissionId]="submissionId"
    [submissionTypeId]="submissionTypeId"
    [submissionStatusId]="submissionStatusId"
    [progressiveStatus]="progressiveStatus"
    [submissionTypeCode]="submissionTypeCode"
    [permissionObj]="permissionObj"
    (documentTableData)="documentTableDataValue($event)"
    (uploadDocument)="openUploadModel($event)"
    [isEditable]="isDisabledDocuments"
    [type]="type"
    [submissionStatus]="submissionStatus"
    [hideAttachDocumentButton]="hideDocumentAttachBtn"
    [opportunityDocs]="opportunityDocs"
    [submissionTypeSubId]="submissionTypeSubId"
    [rmReviewedGuidanceNoteHeader]="rmReviewedGuidanceNoteHeader"
    [rmReviewedGuidanceNoteBody]="rmReviewedGuidanceNoteBody"
  ></app-document-table>
</div>
