<div class="dds-inline-flex">
  <button class="dds-btn">
    <div class="dds-btn__content">
      <span class="dds-btn__text">Open modal</span>
    </div>
  </button>
</div>
<div class="dds-modal-init dds-modal-open">
  <div class="dds-modal-overlay"></div>
  <div class="dds-modal">
    <div class="dds-modal__header">
      <!--to add dynamic client name-->
      <span class="dds-modal__title">{{resolve.labelHeader}}</span>
      <button class="dds-modal__close dds-icon dds-icon_close" (click)="cancel()" aria-label="Close modal"></button>
    </div>
    <div class="dds-modal__body">

      <div class="margin-top-s" *ngIf="resolve.ncaProcessSubStatus_Label">
        <span *ngIf="subStatusID == 0" class="icon-required-yellow"></span>
        <span class="text-label">
          {{resolve.ncaProcessSubStatus_Label}}
        </span>

        <div class="padding-top-xs">
          <div class="padding-top-xs">
            <dds-select [list]="resolve.optionsClientMilestoneSubStatus"
                        [(ngModel)]="subStatusID">
            </dds-select>
          </div>
        </div>
      </div>

      <div class="margin-top-s">
        <span *ngIf="!reason.trim()" class="icon-required-yellow"></span>
        <span class="text-label">
          {{resolve.labelBody}}
        </span>

        <div class="padding-top-xs">
          <div>
            <dds-textarea [placeholder]="'Enter Reason'"
                          [minHeight]="textareaOptions.minHeight ?? 0"
                          [maxLength]="textareaOptions.maxLength ?? 0"
                          [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
                          [size]="textareaOptions.size ?? ''"
                          [(ngModel)]="reason">
            </dds-textarea>
          </div>
        </div>
      </div>


    </div>
    <div class="dds-modal__footer">
      <div class="dds-modal__footer-content dds-modal__footer-content_left"></div>
      <div class="dds-modal__footer-content dds-modal__footer-content_right">
        <div class="dds-modal__footer-item dds-inline-flex">
          <button class="dds-btn dds-btn_secondary-loud dds-btn_fluid-width" (click)="cancel()">
            <div class="dds-btn__content">
              <span class="dds-btn__text">Close</span>
            </div>
          </button>
        </div>
        <div class="dds-modal__footer-item dds-inline-flex">
          <button class="dds-btn dds-btn_fluid-width"
                  (click)="confirm()"
                  [disabled] = "!reason || reason.trim().length < 5 ||
                     (resolve.ncaProcessSubStatus_Label && subStatusID===0)">
            <div class="dds-btn__content">
              <span class="dds-btn__text">Confirm</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
