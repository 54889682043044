import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ClientStatusWebapiService } from '../../http/client/client-status-webapi.service';

@Component({
  selector: 'app-mars-access-dialog',
  templateUrl: './mars-access-dialog.component.html',
  styleUrl: './mars-access-dialog.component.less'
})
export class MarsAccessDialogComponent implements OnInit {
  @Input() resolve: any;
  public isMarsAccessEnabled: boolean = false;
  private initialMarsAccessState: boolean = false;

  constructor(
    private modalService: ModalService,
    private clientStatusWebapiService: ClientStatusWebapiService
  ) {}

  ngOnInit() {
    const employeeId = this.resolve.employeeId;
    this.clientStatusWebapiService.isMarsNcaAccessEnabled(employeeId).subscribe(isEnabled => {
      this.isMarsAccessEnabled = isEnabled;
      this.initialMarsAccessState = isEnabled;
    });
  }

  toggleMarsAccess() {
    this.isMarsAccessEnabled = !this.isMarsAccessEnabled;
  }

  save() {
    const employeeId = this.resolve.employeeId;
    this.clientStatusWebapiService.enableMarsAccessToggle(employeeId, this.isMarsAccessEnabled).subscribe(response => {
      if (response?.isSuccess) {
        this.close();
      }
    });
  }

  close() {
    this.modalService.close();
  }

  isSaveDisabled(): boolean {
    return this.isMarsAccessEnabled === this.initialMarsAccessState;
  }
}
