import { Component, Input, OnInit } from '@angular/core';
import { ClientStatusWebapiService } from '../../../http/client/client-status-webapi.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { OpportunityData } from '../../../common/models/start-page/start-page.model';

@Component({
  selector: 'app-opportunity-list-information',
  templateUrl: './opportunity-list-information.component.html',
  styleUrl: './opportunity-list-information.component.less'
})
export class OpportunityListInformationComponent implements OnInit {
  @Input() resolve: any;
  public clientOpportunityList: OpportunityData[] = [];;
  
  
  constructor(public clientstatuswebapiservice: ClientStatusWebapiService,public modalService: ModalService) {  
  }

  public async ngOnInit(){
    this.clientOpportunityList = this.resolve.MilestoneTriggerListInput || [];
  }
  close() {
    this.modalService.close();
  }
}
