import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReviewerRole } from '../../common/models/reviwer-role.model';
import { UrlConstant } from '../../intake/constants/url-constant';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private readonly httpClient: HttpClient) {}

  getReviewerRolesDetails(): Observable<ReviewerRole> {
    return this.httpClient.get<ReviewerRole>(
      UrlConstant.GET_REVIEWERS_ROLES_PATH
    );
  }

  
}
