<div class="d-flex justify-content-center align-items-center error-page">
  <div class="d-flex justify-content-center align-items-center">
    <img
      src="assets\images\extcommError.svg"
      alt="Not Found"
      class="not-found-image"
    />
    <div class="margin-left-m">
      <div class="margin-bottom-m font-size-32">Something went wrong</div>
      <div class="font-size-14 margin-bottom-m">
        An issue appeared. Please try again later.
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <dds-button
          class="custom-button margin-right-m"
          [theme]="secondaryButton.theme"
          [kind]="secondaryButton.kind"
          [size]="htmlElementSize"
          [width]="secondaryButton.width"
          [ariaLabel]="secondaryButton.ariaLabel"
          (clicked)="navigateToDashboard()"
          >Return to dashboard</dds-button
        >
        <dds-button
          class="custom-button"
          [theme]="secondaryButton.theme"
          [kind]="secondaryButton.kind"
          [size]="htmlElementSize"
          [width]="secondaryButton.width"
          [ariaLabel]="secondaryButton.ariaLabel"
          (clicked)="navigateToSubmission()"
          >Open submission</dds-button
        >
      </div>
    </div>
  </div>
</div>
