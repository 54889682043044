<div class="dds-modal-init dds-modal-open">
<div class="dds-modal-overlay"
    (click)="close()">
</div>
<div class="dds-modal">
  <div class="dds-modal__header">
      <span class="dds-modal__title">Warning</span>
      <dds-button size="sm"
                  icon="padding-left-xs dds-icon_close"
                  kind="silent"
                  theme="dark"
                  ariaLabel="Close modal"
                  class="dds-modal__close"
                  (clicked)="close()"></dds-button>
  </div>
  <div class="dds-modal__body">
    <span>{{warningMessage}}</span>
  </div>
  <div class="dds-modal__footer" *ngIf="isFooter">
    <div class="dds-modal__footer-content dds-modal__footer-content_right">
      <dds-button class="dds-modal__footer-item"
                  kind="secondaryLoud"
                  (clicked)="close()">Cancel</dds-button>
      <dds-button class="dds-modal__footer-item"
                  (clicked)="confirmDelete()">Ok</dds-button>
    </div>
  </div>
</div>
</div>
