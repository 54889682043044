<progress-indicator></progress-indicator>

<div class="tpa-header row">
  <div class="col-9">
    <p class="eclipse-main-tab-header">{{title}}</p>
  </div>
  <div class="col-1 text-align-right" *ngIf ="!controlsVisibility.btnEditVisible"></div>
  <div class="col-2 text-align-right" *ngIf ="!controlsVisibility.btnEditVisible">
    <dds-button class="padding-right-s" (clicked)="onCancelEvent()" (keyboardClicked)="onCancelEvent()"
                [disabled]="controlsVisibility.btnCancelDisabled">Cancel</dds-button>

    <dds-button class=""
                (clicked)="onSaveEvent()" (keyboardClicked)="onSaveEvent()"
                [disabled]="controlsVisibility.btnSaveDisabled" *ngIf="isThirdPartyAgreementRepositoryVisible">{{btnSaveName}}</dds-button>
  </div>
  <div class="col-3 text-align-right" *ngIf ="controlsVisibility.btnEditVisible">
    <dds-button class="padding-right-s" (clicked)="onEditEvent()" (keyboardClicked)="onEditEvent()"
                *ngIf="isThirdPartyAgreementRepositoryVisible">Edit</dds-button>
    <dds-button class="padding-right-s" (clicked)="onDeleteEvent()" (keyboardClicked)="onDeleteEvent()"
                *ngIf="isThirdPartyAgreementRepositoryVisible">Delete agreement</dds-button>
  </div>
</div>
<div class="margin-bottom-xs bottom-border"></div>
<div class="tpa-container">
  <div class="row"></div>

  <div class="row margin-bottom-m">
    <!--<p class="eclipse-content-grey">Agreement details</p>-->
    <p class="eclipse-header">Agreement details</p>
    <div class="col-4 right-border padding-right-m">

      <div class="margin-bottom-s">
        <span class="icon-required-yellow"></span>
        <span class="eclipse-content-grey">Agreement name:</span>
        <div class="margin-left-s">
          <dds-input [placeholder]="textAgreementNameOptions.placeholder ?? ''"
                     [maxLengthPosition]="textAgreementNameOptions.maxLengthPosition ?? ''"
                     [maxLength]="textAgreementNameOptions.maxLength ?? 0"
                     [disabled]="controlsVisibility.disableAgreementName"
                     (valueChanged)="agreementNameChangedEvent()"
                     [size]="textAgreementNameOptions.size ?? ''" [(ngModel)]="model.agreementName">
          </dds-input>
        </div>
      </div>
      <div class="margin-bottom-s">
        <span class="icon-required-yellow"></span> 
        <span class="eclipse-content-grey">Third party name:</span>
        <div *ngIf="!isClientNotListed; else ClientNotListedElseBlock">
          <div ngbDropdown #clientdropdown="ngbDropdown" class="client-picker">
            <div class="margin-left-s" ngbDropdownAnchor>
              <dds-search [formControl]="clientSearchFormCtrl" [size]="searchThirdPartyNameOptions.size ?? ''"
                [customClass]="searchThirdPartyNameOptions.customClass ?? ''"
                [disabled]="controlsVisibility.disableThirdPartyName" (valueChanged)="thirdPartyNameSearchEvent()"
                (fieldCleared)="thirdPartyNameClearEvent()">
                <input ddsSearch [placeholder]="clientSelectedItem ? '' : searchThirdPartyNameOptions.placeholder ?? ''"
                  [(ngModel)]="clientSearchKey" (input)="thirdPartyNameSearchEvent()" (click)="thirdPartyInputClick()"
                  [ngClass]="{'hidden': clientSelectedItem}">
              </dds-search>
              <div *ngIf="clientSelectedItem" class="selected-item-display" [ngClass]="{'disabled': controlsVisibility.disableThirdPartyName}">
                <span class="padding-left-xl">{{ clientSelectedItem.clientName }}</span>
                <span class="dds-icon dds-icon_close" (click)="thirdPartyNameClearEvent()"></span>
              </div>
            </div>
            <div #menu ngbDropdownMenu role="menu" aria-labelledby="single-button" class="items-dropdown">
              <div class="search-list-label padding-bottom-none" [hidden]="!isLoading">Searching...</div>
              <div [hidden]="isLoading">
                <div class="no-matches" [hidden]="clientFilteredItems.length != 0">No matches found</div>
              </div>
              <button *ngFor="let item of clientFilteredItems" ngbDropdownItem (click)="selectItem(item)">
                <div class="dropdown-item-content">
                  <span class="client-number padding-right-xxs">{{ item.clientName }}</span>
                  <span *ngIf="item.globalClientCode" class="sap-id padding-right-xxs">| ID: {{ item.globalClientCode }}</span>
                  <div *ngIf="item.address" class="sap-id padding-right-xxs">Address: {{ item.address }}</div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <ng-template #ClientNotListedElseBlock class="client-picker">
          <div class="margin-left-s">
            <dds-input [placeholder]="enterThirdPartyNameOptions.placeholder?? ''"
              [disabled]="controlsVisibility.disableThirdPartyName" [size]="searchThirdPartyNameOptions.size ?? ''"
              [maxLength]="searchThirdPartyNameOptions.maxLength ?? 0"
              [maxLengthPosition]="searchThirdPartyNameOptions.maxLengthPosition ?? ''"
              [(ngModel)]="model.thirdPartyName"></dds-input>
          </div>
        </ng-template>
        
        
        <dds-checkbox class="padding-xs margin-left-xs" [ngClass]="{'client-not-listed-margin': isClientNotListed}"
          [disabled]="controlsVisibility.disableThirdPartyName" [formControl]="checkboxFormCtrl" [label]="options.label ?? ''"
          [theme]="options.theme ?? ''" (stateChanged)="stateChanged()">
        </dds-checkbox>
        </div>
      <div class="margin-bottom-s">
        <span class="icon-required-yellow"></span>
        <span class="eclipse-content-grey">Type of agreement:</span>
        <div class="margin-left-s">
          <dds-select [list]="lookup.agreementTypeList" [placeholder]="selectAgreementTypeOptions.placeholder ?? ''"
                      [size]="selectAgreementTypeOptions.size ?? ''" (valueChanged)="selectAgreementTypeList($event)"
                      [disabled]="controlsVisibility.disableAgreementType"
                      [(ngModel)]="model.agreementTypeId">
          </dds-select>
        </div>
      </div>
      <div class="margin-bottom-s" *ngIf="controlsVisibility.contractTypeVisible">
        <span class="icon-required-yellow"></span>
        <span class="eclipse-content-grey">Type of Contractor:</span>
        <div class="margin-left-s">
          <dds-select [list]="lookup.contractorTypeList" [placeholder]="selectContractorTypeOptions.placeholder ?? ''"
                      [disabled]="controlsVisibility.disableContractorType"
                      [size]="selectContractorTypeOptions.size ?? ''"
                      (valueChanged)="selectContractorType($event)"
                      [(ngModel)]="model.contractorTypeId">
          </dds-select>
        </div>
      </div>
      <div>
        <span class="icon-required-yellow"></span>
        <span class="eclipse-content-grey">Agreement status:</span>
        <div class="margin-left-s">
          <dds-select [list]="lookup.agreementStatusList" [placeholder]="selectAgreementStatusOptions.placeholder ?? ''"
                      [size]="selectAgreementStatusOptions.size ?? ''"
                      [disabled]="controlsVisibility.disableAgreementStatus"
                      (valueChanged)="selectAgreementStatus($event)"
                      [(ngModel)]="model.agreementStatusId">
          </dds-select>
        </div>
      </div>
    </div>



    <div class="col-4 padding-left-m">

      <div class="margin-bottom-s margin-right-s">
        <span class="eclipse-content-grey">Effective date:</span>
        <dds-datepicker [size]="effectiveDateOptions.size ?? ''"
                        [placeholder]="effectiveDateOptions.placeholder ?? ''" [format]="effectiveDateOptions.format ?? ''"
                        [minMode]="effectiveDateOptions.minMode ?? ''"
                        [isManualInput]="effectiveDateOptions.isManualInput ?? false"
                        [disabled]="controlsVisibility.disableEffectiveDate"
                        [isResponsive]="effectiveDateOptions.isResponsive ?? false" [(ngModel)]="model.effectiveDate"
                        (dateChanged)="onEffectiveDateChanged($event)">
        </dds-datepicker>

      </div>
      <div class="margin-bottom-m margin-right-s">
        <span class="eclipse-content-grey">Auto-renew:</span>
        <dds-radio-group name="autoRenew"
                         [(ngModel)]="model.isAutoRenew"
                         [disabled]="controlsVisibility.disableAutoRenew" (stateChanged)="onAutoRenewChangedEvent()">

          <ng-container *ngFor="let radio of auotRenewRadioBtn">
            <dds-radio class="radio-btn" [value]="radio.value" [label]="radio.options.label"
                       [theme]="radio.options.theme ?? ''" style="margin-right: 12px"></dds-radio>
          </ng-container>
        </dds-radio-group>
      </div>
      <div class="margin-bottom-s margin-right-s">
        <span class="eclipse-content-grey">Termination date:</span>
        <dds-datepicker [size]="terminationDateOptions.size ?? ''"
                        [disabled]="controlsVisibility.disableTerminationDate"
                        [placeholder]="terminationDateOptions.placeholder ?? ''" [format]="terminationDateOptions.format ?? ''"
                        [minMode]="terminationDateOptions.minMode ?? ''"
                        [isManualInput]="terminationDateOptions.isManualInput ?? false"
                        [isResponsive]="terminationDateOptions.isResponsive ?? false" [(ngModel)]="model.terminationDate"
                        (dateChanged)="onTerminationDateChanged($event)">
        </dds-datepicker>
      </div>
      <div class="margin-right-s">
        <span class="eclipse-content-grey">Termination notice due date:</span>
        <dds-datepicker [size]="terminationNoticeDueDateOptions.size ?? ''"
                        [disabled]="controlsVisibility.disableTerminationNoticeDueDate"
                        [placeholder]="terminationNoticeDueDateOptions.placeholder ?? ''" [format]="terminationNoticeDueDateOptions.format ?? ''"
                        [minMode]="terminationNoticeDueDateOptions.minMode ?? ''"
                        [isManualInput]="terminationNoticeDueDateOptions.isManualInput ?? false"
                        [isResponsive]="terminationNoticeDueDateOptions.isResponsive ?? false" [(ngModel)]="model.terminationNoticeDueDate"
                        (dateChanged)="onTerminationDueDateChanged($event)">
        </dds-datepicker>
      </div>

    </div>
  </div>

  <div class="row marigin-top-m margin-bottom-s top-border">
    <div class="col-8 margin-bottom-s margin-top-s margin-left-s padding-right-l">
      <span class="eclipse-content-grey">Notes:</span>
      <div class="padding-right-xs">
        <dds-input [placeholder]="textNotesOptions.placeholder ?? ''"
                   [disabled]="controlsVisibility.disableNotes"
                   [maxLengthPosition]="textNotesOptions.maxLengthPosition ?? ''"
                   [maxLength]="textNotesOptions.maxLength ?? 0"
                   [size]="textNotesOptions.size ?? ''" [(ngModel)]="model.notes">
        </dds-input>
      </div>
    </div>
  </div>

  <div class="row marigin-top-m margin-bottom-s top-border">
    <div class="col-8 margin-bottom-s margin-top-s margin-left-s padding-right-l">
      <span class="eclipse-content-grey">Covered Deloitte entity:</span>
      <div class="padding-right-xs">
        <dds-multi-select class="box-size"
                          [disabled]="controlsVisibility.disableCoveredDeloitteEntity"
                          [displayTickAllBtn]="multiSelectCoveredDeloitteEntityOptions.displayTickAllBtn ?? false"
                          [isResponsive]="multiSelectCoveredDeloitteEntityOptions.isResponsive ?? false"
                          [isRequired]="multiSelectCoveredDeloitteEntityOptions.isRequired ?? false"
                          [label]="multiSelectCoveredDeloitteEntityOptions.label ?? ''"
                          [size]="multiSelectCoveredDeloitteEntityOptions.size ?? ''"
                          [placeholder]="multiSelectCoveredDeloitteEntityOptions.placeholder ?? ''"
                          [type]="multiSelectCoveredDeloitteEntityOptions.type ?? ''"
                          [controlType]="multiSelectCoveredDeloitteEntityOptions.controlType ?? ''"
                          [theme]="multiSelectCoveredDeloitteEntityOptions.theme ?? ''"
                          [isInverse]="multiSelectCoveredDeloitteEntityOptions.isInverse ?? false"
                          [list]="lookup.coveredDeloitteEntityList" (valueChanged)="coveredDeloitteEntityChangedEvent()"
                          [formControl]="coveredDeloitteEntityMultiSelectFormCtrl">
        </dds-multi-select>
      </div>
      <div *ngIf="(coveredDeloitteEntityMultiSelectFormCtrl?.value?.length ?? 0) > 0"
           class="multiselectddl">
        <span class="comments-text-label eclipse-small-header-grey">Selected values:</span>
        <dds-tags>
          <dds-tag class="tag-width"
                   *ngFor="let item of coveredDeloitteEntityMultiSelectFormCtrl.value"
                   [theme]="multiOptions.theme ?? ''"
                   [isRemovable]="multiOptions.isRemovable ?? false"
                   [disabled]="controlsVisibility.isCoveredDeloitteEntitySelectedValues &&
                   controlsVisibility.disableCoveredDeloitteEntity"
                   [customClass]="multiOptions.customClass ?? ''"
                   [size]="multiOptions.size ?? ''" [isError]="multiOptions.isError ?? false"
                   [isInverse]="multiOptions.isInverse ?? false"
                   (removed)="removecoveredDeloitteEntity(item)" #tagItems>
            {{ item.label }}
          </dds-tag>
        </dds-tags>
      </div>
    </div>
  </div>

  <div class="row marigin-top-m margin-bottom-s top-border">
    <div class="col-8 margin-bottom-s margin-top-s margin-left-s padding-right-l">
      <span class="eclipse-content-grey">OGC Attorney Involved:</span>
      <div class="padding-right-xs">
        <div autosave ngbDropdown #ogcdropdown="ngbDropdown"
             class="client-picker mars-nca-flex-sub-container">
          <div class="search-container" ngbDropdownAnchor>
            <dds-search [formControl]="ogcSearchFormCtrl"
                        [size]="ogcSearchOptions.size ?? ''"
                        [icon]="!ogcSelectedItem ? (ogcSearchOptions.icon || 'dds-icon_search') : ''"
                        (valueChanged)="ogcSearchEvent()" (fieldCleared)="ogcClearSearchEvent()"
                        [disabled]="controlsVisibility.disableOGCEmployee">
              <input ddsSearch
                     [placeholder]="ogcSelectedItem ? '' : ogcSearchOptions.placeholder ?? ''"
                     [(ngModel)]="ogcSearchKey" (input)="ogcSearchEvent()"
                     (click)="ogcInputClickEvent()"
                     [ngClass]="{'hidden': ogcSelectedItem}" />
            </dds-search>
            <div *ngIf="ogcSelectedItem" class="selected-item-display">
              <span class="client-number padding-right-xxs"
                    [class.disabled]="controlsVisibility.disableOGCEmployee">{{ogcSelectedItem.outlookDisplayName }}</span>
              <span class="dds-icon dds-icon_close" (click)="ogcClearSearchEvent()"
                    [class.disabled]="controlsVisibility.disableOGCEmployee"></span>
            </div>
          </div>
          <div #menu ngbDropdownMenu role="menu" aria-labelledby="single-button"
               class="items-dropdown">
            <div class="search-list-label padding-bottom-none" [hidden]="!controlsVisibility.isOGCLoading">
              Searching...
            </div>
            <div [hidden]="controlsVisibility.isOGCLoading">
              <div class="no-matches" [hidden]="ogcFilteredItems.length != 0">
                No
                matches found
              </div>
            </div>
            <button *ngFor="let item of ogcFilteredItems" ngbDropdownItem
                    (click)="ogcSelectItemEvent(item)">
              <div class="dropdown-item-content">
                <span class="client-number padding-right-xxs">
                  {{
                    item.outlookDisplayName
                  }}
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row marigin-top-m margin-bottom-s top-border">
    <div class="col-8 margin-bottom-s margin-top-s margin-left-s padding-right-l">
      <span class="eclipse-content-grey">Risk Manager POC:</span>
      <div class="padding-right-xs">
        <div autosave ngbDropdown #riskMgrdropdown="ngbDropdown"
             class="client-picker mars-nca-flex-sub-container">
          <div class="search-container" ngbDropdownAnchor>
            <dds-search [formControl]="riskManagerSearchFormCtrl"
                        [size]="riskManagerSearchOptions.size ?? ''"
                        [icon]="!riskManagerSelectedItem ? (riskManagerSearchOptions.icon || 'dds-icon_search') : ''"
                        (valueChanged)="riskManagerSearchEvent()" (fieldCleared)="riskManagerClearSearchEvent()"
                        [disabled]="controlsVisibility.disableRiskManager">
              <input ddsSearch
                     [placeholder]="riskManagerSelectedItem ? '' : riskManagerSearchOptions.placeholder ?? ''"
                     [(ngModel)]="riskManagerSearchKey" (input)="riskManagerSearchEvent()"
                     (click)="riskManagerInputClickEvent()"
                     [ngClass]="{'hidden': riskManagerSelectedItem}" />
            </dds-search>
            <div *ngIf="riskManagerSelectedItem" class="selected-item-display">
              <span class="client-number padding-right-xxs"
                    [class.disabled]="controlsVisibility.disableRiskManager">{{riskManagerSelectedItem.outlookDisplayName }}</span>
              <span class="dds-icon dds-icon_close" (click)="riskManagerClearSearchEvent()"
                    [class.disabled]="controlsVisibility.disableRiskManager"></span>
            </div>
          </div>
          <div #menu ngbDropdownMenu role="menu" aria-labelledby="single-button"
               class="items-dropdown">
            <div class="search-list-label padding-bottom-none" [hidden]="!controlsVisibility.isRiskManagerLoading">
              Searching...
            </div>
            <div [hidden]="controlsVisibility.isRiskManagerLoading">
              <div class="no-matches" [hidden]="riskManagerFilteredItems.length != 0">
                No
                matches found
              </div>
            </div>
            <button *ngFor="let item of riskManagerFilteredItems" ngbDropdownItem
                    (click)="riskManagerSelectItemEvent(item)">
              <div class="dropdown-item-content">
                <span class="client-number padding-right-xxs">
                  {{
            item.outlookDisplayName
                  }}
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row marigin-top-m margin-bottom-s top-border" *ngIf="controlsVisibility.uploadDocumentVisibility">
    <div class="col-8 margin-bottom-s margin-top-s margin-left-s padding-right-l">
      <span class="eclipse-content-grey">Document:</span>
      <div class="dds-modal__body">
        <dds-uploader class="dds-uploader" style="padding: 14px 0"
                      [heading]="uploadAreaOptions.heading??''"
                      [description]="uploadAreaOptions.description??''"
                      [dragAndDropText]="uploadAreaOptions.dragAndDropText??''"
                      [isDragAndDrop]="uploadAreaOptions.isDragAndDrop??false"
                      [isMultiple]="uploadAreaOptions.isMultiple??false"
                      [acceptFormats]="uploadAreaOptions.acceptFormats??''"
                      [acceptExtensions]="uploadAreaOptions.acceptExtensions??''"
                      [exceptSymbols]="uploadAreaOptions.exceptSymbols??''"
                      [btnLabel]="uploadAreaOptions.btnLabel??''"
                      [isInverse]="uploadAreaOptions.isInverse??false"
                      [disabled]="uploadAreaOptions.disabled??true"
                      [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind??''"
                      (uploadedItems)="uploadedItems($event)">
        </dds-uploader>
      </div>
      <!--[ngStyle]="{'padding': popupType === popupTypeEnum.ClientAgreement ? '8px 0' : '14px 0'}"-->

    </div>
  </div>

  <div class="row marigin-top-m margin-bottom-s top-border">
    <div class="col-12 margin-bottom-s margin-top-s margin-left-s padding-right-l">
      <table *ngIf="documentList && documentList.length > 0" class="dds-data-table">
        <thead class="dds-data-table__header margin-top-s">
          <tr>
            <th *ngFor="let column of documentColumns"
                class="dds-data-table__header-cell"
                [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
                ddsFocus
                tabindex="0">
              <div class="dds-data-table__content">
                <span>{{column.header || column.name}}</span>
                <div *ngIf="column.name === 'effectiveDate' || column.name === 'terminationDate' || column.name === 'modifiedDate'" class="sort-icons-container">
                  <span class="dds-icon dds-data-table__icon"
                        aria-label="sort"
                        [ngClass]="{
              'dds-icon_sort-down': documentSortingState.property !== column.name || (documentSortingState.property === column.name && !documentSortingState.ascending),
              'dds-icon_sort-up': documentSortingState.property === column.name && documentSortingState.ascending,
              'dds-icon_sort-active': documentSortingState.property === column.name,
              'dds-icon_sort-disabled': documentSortingState.property !== column.name
            }"
                        (click)="sortColumn(column, $event)">
                  </span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="dds-data-table__body">
          <ng-container *ngFor="let row of documentList">
            <tr [ngClass]="{'dds-data-table__row': true }">
              <td *ngFor="let column of documentColumns" class="dds-data-table__cell" [ngStyle]="{ width: column.width, minWidth: column.minWidth }">
                <!--TODO : add redirection logic once documents section is added-->
                <div *ngIf="column.name == 'fileName'" class="agreement-name-container">
                  <!--<div class="dds-card__image-block txt-Style"></div>-->
                  <a class="agreement-link padding-bottom-xxs" (click)="onDownload(row)">
                    {{ row[column.name] ? row[column.name] : '-' }}
                  </a>
                </div>
                <div *ngIf="column.name == 'effectiveDate' || column.name == 'terminationDate' || column.name == 'modifiedDate'">
                  <span class="padding-bottom-xxs">
                    {{ row[column.name] ? (row[column.name] | date: 'MM/dd/yyyy') : '-' }}
                  </span>
                </div>
                <div *ngIf="column.name != 'fileName' && column.name != 'effectiveDate' && column.name != 'terminationDate' &&
                     column.name != 'modifiedDate'">
                  <span class="padding-bottom-xxs">
                    {{ row[column.name] ? row[column.name] : '-' }}
                  </span>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>





