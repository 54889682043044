import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  LepValue,
  OpportunityDetailsNavModel,
} from '../../../common/models/opportunity-details.model';
import { SubmissionService } from '../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { AccordionOptions } from './service-delivery/service-delivery-settings';
import { lepDataValue } from '../opportunity-details-settings';
@Component({
  selector: 'app-oportunity-detail-right-panel',
  templateUrl: './oportunity-detail-right-panel.component.html',
  styleUrl: './oportunity-detail-right-panel.component.less',
})

export class OportunityDetailRightPanelComponent implements OnInit {
  constructor(private intakeDetailService: SubmissionService) {}
  @Input() opportunityNumber: string = '';
  @Input() tabTitle: string | null = '';
  @Input() submissionDetails: OpportunityDetailsNavModel[] = [];
  accordionOptions = AccordionOptions;
  unsubscriber$ = new Subject();
  opportunityData: any[] = [];

  ngOnInit(): void {
    this.getServiceData();
  }

  lepValue: LepValue = lepDataValue;

  @Output() lepValueChange: EventEmitter<LepValue> =
    new EventEmitter<LepValue>();
  getServiceData(): void {
    this.intakeDetailService
      .getDeliveryServiceTable(this.opportunityNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.opportunityData = data;
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  shouldShowOfferingQuestions(): boolean {
    if (!this.opportunityData || this.opportunityData.length === 0) {
      return false;
    }
    const hasInvalidStatus = this.opportunityData.some(
      (submission) => submission.offeringPortfolio === 'CYBER & STRATEGIC RISK'
    );
    if (hasInvalidStatus) {
      return true;
    }
    this.intakeDetailService.updateComplexQuestionCyberStatus(true);
    return false;
  }

  onLepValueChange(updatedLepValue: LepValue): void {
    this.lepValue = updatedLepValue;
    this.lepValueChange.emit(this.lepValue);
  }
}
