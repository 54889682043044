

export class FileValidator {

  public static validFileExtensions: any = "doc,docx,msg,pptx,pdf,ppt,xls,xlsb,xlsx,xlsm,jpg,jpeg,png,zip"; //Valid File Extensions
  public static maxFileSizeAllowed: any = 50 * 1024 * 1024; // 50 MB in bytes

  public static isFileExtensionValid_SingleFile(fileSource : any) {
    if (this.validFileExtensions.toString()
      .toLowerCase().includes(fileSource[0].fileExtenstion.toString().toLowerCase()))
      return true;
    else
      return false;
  }

  public static isSigleFileMaxSizeExceed(fileSource: any) {
    if (fileSource[0].file.size > this.maxFileSizeAllowed)
      return true;
    else
      return false;
  }
}
