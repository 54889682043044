import {
  ButtonKind,
  ExtThemes,
  Size,
  TabThemeType,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  VerticalTabOptions,
  VerticalTabsOptions,
} from '@usitsdasdesign/dds-ng/vertical-tabs';

export class AdminConstant {
  public static readonly Label: string = 'Refresh application with changes';

  public static readonly Button_Options: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: WidthState.fixed,
    isLoading: false,
    icon: '',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: this.Label,
    customClass: '',
    role: 'button',
  };

  public static readonly TabContainerOptions: VerticalTabsOptions = {
    theme: Themes.green,
    themeType: TabThemeType.border,
    size: Size.lg,
    ariaLabel: 'Vertical tabs',
    disabled: false,
    isInverse: false,
    customClass: '',
  };

  public static readonly VerticalTabOptionsOne: VerticalTabOptions = {
    label: 'Global configuration',
    disabled: false,
    customClass: '',
    isActive: false,
  };

  public static readonly VerticalTabOptionsTwo: VerticalTabOptions = {
    label: 'Roles list',
    isActive: false,
  };

  public static readonly VerticalTabOptionsThree: VerticalTabOptions = {
    label: 'Tables',
    isActive: false,
  };
}
