import { Themes } from '@usitsdasdesign/dds-ng/shared';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { IntakeConstant } from '../constants/intake.constant';
import { IntakeOppertunitySubmissionStatus } from '../constants/intake_oppertunity_submission_status.enum';

export const toastWithAction: ToastOptions = {
  title: 'Opportunity details complete.',
  message: IntakeConstant.EMPTY_SPACE,
  action: {
    title: 'Proceed to document submission',
    actionFunc: () => {},
  },
  lifeTime: 0,
  withProgressBar: false,
  position: 'bottom-center',
  closeBtnIcon: 'dds-icon_close',
  isCloseIcon: true,
  customClass: 'action-toast custom-toast-animation closing-toast-message-submission',
  isError: false,
  isInverse: false,
  isLoading: false,
  limit: 1,
  theme: Themes.dark,
};

export const simpleToast: ToastOptions = {
  title: 'Opportunity details complete.',
  message: IntakeConstant.EMPTY_SPACE,
  lifeTime: 5000,
  withProgressBar: false,
  position: 'bottom-center',
  isCloseIcon: false,
  customClass: 'simple-toast custom-toast-animation',
  isError: false,
  isInverse: false,
  isLoading: false,
  limit: 1,
  theme: Themes.dark,
};

export function showCompletionToast(
  toastService: ToastService,
  setActiveIndex: (index: number) => void,
  submissitionDetails: any[]
) {
  const draftSubmissions = submissitionDetails.filter(
    (submission) => submission.submissionStatusName === IntakeOppertunitySubmissionStatus.DRAFT
  );
  if (draftSubmissions.length > 0) {
    const latestDraft = draftSubmissions.reduce((latest, current) => {
      return latest.submissionId > current.submissionId ? latest : current;
    });

    if (toastWithAction?.action) {
      toastWithAction.action.actionFunc = () => {
        const activeIndex = submissitionDetails.findIndex(
          (item: any) => item.submissionId === latestDraft.submissionId
        );
        setActiveIndex(activeIndex);
        toastService.closeAll();
      };
    }
    toastService.createToast(toastWithAction);
  } else {
    toastService.createToast(simpleToast);
  }
}
