import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { IntakeConstant } from '../../../constants/intake.constant';
import {
  ButtonKind,
  ExtThemes,
  Size,
  SpinnerThemes,
  TabThemeType,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import {
  OpportunityDetails,
  OpportunityList,
} from '../../../../common/models/opportunity-details.model';
import { debounceTime, filter, Subject, takeUntil } from 'rxjs';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SpinnerOptions } from '@usitsdasdesign/dds-ng/spinner';
import {
  opportunityTableColumns,
  options,
  optionsShowMore,
  tabContainerOptions,
} from '../../../initiate-submission/initiate-submission-helper';
import { ClientDetailsConstant } from '../../../../client/constants/client-details-constant';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationInfoRelinkModel } from '../../../../common/models/initiateSubmission.model';
import {
  CANCEL_BTN_OPTIONS,
  closeBtnOptions,
  RELINK_BTN_OPTIONS,
  searchOptions,
  spinnerOptions,
} from './relink-opp-popup.helper';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { TabsOptions } from '@usitsdasdesign/dds-ng/tabs';
@Component({
  selector: 'app-relink-opp-popup',
  templateUrl: './relink-opp-popup.component.html',
  styleUrl: './relink-opp-popup.component.less',
})
export class RelinkOppPopupComponent {
  @Input() documentInitiatedDetails: {
    submissionId: string;
    opportunityId: string;
  } = { submissionId: '', opportunityId: '' };
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  emptySpace: string = IntakeConstant.EMPTY_SPACE;
  public selectedOportunity = this.emptySpace;
  showDropdown: boolean = false;
  notFound: string = this.emptySpace;
  totalCount: number = 0;
  pageNumber: number = 0;
  isSpinnerVisible: boolean = false;
  readonly #unsubscriber$: Subject<void> = new Subject();
  pageSize: number = IntakeConstant.PAGE_NUMBER;
  showOpportunityDetails: boolean = false;
  public opportunityDetailsList: any[] = [];
  navInfo: NavigationInfoRelinkModel | null = null;
  spinnerOptions: SpinnerOptions = spinnerOptions;
  closeBtnOptions: ButtonOptions = closeBtnOptions;
  tooltipWidth: number = IntakeConstant.TOOLTIP_WIDTH;
  nonChangeOrder: string = IntakeConstant.NON_CHANGE_ORDER;
  opportunityTableColumns = opportunityTableColumns;
  markForDeletionText: string = ClientDetailsConstant.MarkForDeletionText;
  searchOptions: SearchOptions = searchOptions;
  opportunityList: OpportunityDetails[] = [];
  totalOpportunityCount: number = 0;
  activeOpportunityCount: number = 0;
  selectedTabCode: string = 'active';
  selectTabIndex:number = 0;
  tabContainerOptions = tabContainerOptions;
  relinkBtn = RELINK_BTN_OPTIONS;
  cancelBtn= CANCEL_BTN_OPTIONS;

  public tabs = [
    {
      label: 'Active',
      code: 'active',
      count: this.activeOpportunityCount,
    },
    {
      label: 'All opportunities',
      code: 'all',
      count: this.totalOpportunityCount,
    },
  ];
  private readonly searchOpportunityQuery$: Subject<string> =
    new Subject<string>();
  constructor(
    private readonly submissionService: SubmissionService,
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly progressIndicator: ProgressIndicatorService
  ) {}

  ngOnInit(): void {
    this.searchOpportunityQuery$
      .pipe(
        debounceTime(IntakeConstant.SEARCH_DEBOUNCE_TIME),
        filter(
          (key) => !!key && key.length > IntakeConstant.SEARCH_STRING_LIMIT
        ),
        takeUntil(this.#unsubscriber$)
      )
      .subscribe(() => {
        this.fetchOpportunitySearchResult(1, this.selectedTabCode);
      });
    this.submissionService.setRelinkModel(false);
  }

  save(): void {
    this.progressIndicator.show();
    this.submissionService
      .relinkSubmission(
        this.opportunityDetailsList[0].opportunityNumber,
        this.documentInitiatedDetails.submissionId
      )
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: string) => {
          const data = {
            submissionId: this.documentInitiatedDetails.submissionId,
            opportunityId: this.opportunityDetailsList[0].opportunityNumber,
          };
          this.progressIndicator.hide();
          this.closeRelinkModel(data);
        },
        error: (error: HttpErrorResponse) => {
          console.error('Error fetching submission types', error);
        },
      });
    this.progressIndicator.hide();
  }

  fetchOpportunitySearchResult(pageNumber: number, tabCode: string): void {
    this.isSpinnerVisible = true;
    this.submissionService
      .getOpportunityDetails(
        this.selectedOportunity,
        pageNumber,
        this.pageSize,
        tabCode
      )
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (opportunity: OpportunityList) => {
          if (opportunity?.totalOpportunityCount > 0) {
            this.notFound = this.emptySpace;
            if (pageNumber === 1) {
              this.opportunityList = opportunity?.opportunities;
            } else {
              this.opportunityList = [
                ...this.opportunityList,
                ...opportunity?.opportunities,
              ];
            }
            this.totalCount = opportunity?.totalOpportunityCount;
            this.tabs[0].count = opportunity?.activeOpportunityCount;
            this.tabs[1].count = opportunity?.totalOpportunityCount;
            if(opportunity.activeOpportunityCount == 0){
              if(this.selectTabIndex == 0){
                this.selectTabIndex = 1;
               this.tabChanged(this.tabs[1].code);
              } 
            }else {
              if(this.selectTabIndex == 1){
                this.selectTabIndex = 0;
                this.tabChanged(this.tabs[0].code);
              }
            }
            if (this.opportunityList.length > 0) this.showDropdown = true;
          } else {
            this.notFound = this.findText(opportunity);
            this.opportunityList = [];
            this.totalCount = 0;
            this.showDropdown = false;
          }
          this.isSpinnerVisible = false;
        },
        error: (err: HttpErrorResponse) => {
          this.isSpinnerVisible = false;
          console.error('Error fetching submission types', err);
        },
      });
  }

  findText(opportunity: OpportunityList): string {
    const notFoundtext = 'Not Found';
    return notFoundtext;
  }

  fetchOpportunitySearchResultMore(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      if (this.pageNumber < this.totalCount / this.pageSize) {
        this.pageNumber++;
        this.fetchOpportunitySearchResult(
          this.pageNumber,
          this.selectedTabCode
        );
      }
    }
  }

  removeOpportunity(removeType: string): void {
    this.showOpportunityDetails = false;
    this.selectedOportunity = this.emptySpace;
    this.opportunityList = [];
    this.opportunityDetailsList = [];
    this.showDropdown = false;
    this.opportunityDetailsList = [];
  }

  fetchOpportunityDetails(
    opportunity: OpportunityDetails,
    fetchType: string
  ): void {
    this.opportunityDetailsList = [opportunity];
    this.opportunityList = [];
    this.showDropdown = false;
    this.showOpportunityDetails = true;
  }

  selectOpportunityChanged(event: string): void {
    this.totalCount = 0;
    this.pageNumber = 1;
    if (event.length > 2 && event !== this.emptySpace) {
      this.searchOpportunityQuery$.next(event);
    } else {
      this.notFound = this.emptySpace;
      this.opportunityList = [];
      this.totalCount = 0;
      this.showDropdown = false;
    }
  }

  getNumberOfSplits(fetchType: string): string {
    let splits = this.emptySpace;

    for (let offering of this.opportunityDetailsList[0]?.offering) {
      splits += `${offering} \n`;
    }
    return splits;
  }

  fieldCleared(): void {
    this.opportunityList = [];
    this.selectedOportunity = this.emptySpace;
    this.showDropdown = false;
    this.notFound = this.emptySpace;
  }

  closeRelinkModel(data: NavigationInfoRelinkModel | null): void {
    this.modalService.close(data);
  }

  tabChanged(event: string): void {
    this.selectedTabCode = event;
    this.fetchOpportunitySearchResult(1, this.selectedTabCode);
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
