import { Component, Input, OnInit } from '@angular/core';
import { TreeOptions, TreeNodeOptions } from '@usitsdasdesign/dds-ng/tree';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Themes } from '@usitsdasdesign/dds-ng/shared';
import { ExtThemes, Size, WidthState, ButtonKind } from '@usitsdasdesign/dds-ng/shared';
import { WatchlistEraseItems } from '../../http/watchlist/watchlist-api.service';
import { ButtonOptions } from "@usitsdasdesign/dds-ng/button";
import { WatchlistApiService } from '../../http/watchlist/watchlist-api.service'
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service'
import { Router } from '@angular/router';
import { SharedService } from '../../common/services/shared-service.service';

@Component({
  selector: 'app-erase-client-record',
  templateUrl: './erase-client-record.component.html',
  styleUrl: './erase-client-record.component.less'
})
export class EraseClientRecordComponent implements OnInit {

  public watchlistItemForErase: WatchlistEraseItems[] = [];
  @Input() labelValues: any;
  selectedWatchlistIds: number[] = [];

  constructor(
    private modalService: ModalService,
    private watchlistapiservice: WatchlistApiService,
    private progressindicatorservice: ProgressIndicatorService,
    private router: Router,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.watchlistItemForErase = this.labelValues.watchlistEraseList;
    this.updateTreeItems();
  }

  toggleSelection(item: TreeNodeOptions) {
    item.isChecked = !item.isChecked;
    this.updateSelectedWatchlistIds();
  }

  getTreeChildrenHeight(childrenCount: number): string {
    const baseHeightPerChild = 40; // Base height per child in pixels
    const totalHeight = childrenCount * baseHeightPerChild;
    const adjustedHeight = totalHeight - 20; // Subtract 11px
    return `${adjustedHeight}px`;
  }

  toggleChildSelection(parent: any, child: any) {
    child.isChecked = !child.isChecked;
    this.updateSelectedWatchlistIds();
  }

  updateSelectedWatchlistIds() {
    this.selectedWatchlistIds = [];
    this.treeItems.forEach(item => {
      if (item.isChecked) {
        this.selectedWatchlistIds.push(item['watchlistId']);
      }
      if (item.children) {
        item.children.forEach(child => {
          if (child.isChecked) {
            this.selectedWatchlistIds.push(child['watchlistId']);
          }
        });
      }
    });

  }

  selectAll() {
    this.treeItems.forEach(item => {
      item.isChecked = true;
      if (item.children) {
        item.children.forEach(child => {
          child.isChecked = true;
        });
      }
    });
    this.updateSelectedWatchlistIds();
  }

  treeOptions: TreeOptions = {
    theme: Themes.green,
    size: 'lg',
    isCheckboxVisible: true,
    isInverse: false,
    customClass: ''
  };

  options: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.md,
    width: WidthState.fixed,
    isLoading: false,
    role: 'button'
  };

  eraseoptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primaryLoud,
    size: Size.lg,
    width: WidthState.fixed,
    disabled: false,
    role: 'button'
  }

  canceloptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.secondaryLoud,
    size: Size.lg,
    width: WidthState.fixed,
    disabled: false,
    role: 'button'
  }

  treeItems: TreeNodeOptions[] = [];

  updateTreeItems(): void {
    const parentItem = this.watchlistItemForErase.find(item => item.isParentClient);
    if (parentItem) {
      const childrenItems = this.watchlistItemForErase.filter(item => !item.isParentClient);
      this.treeItems = [
        {
          icon: '',
          isExpanded: true,
          disabled: false,
          customClass: '',
          isChecked: false,
          label: parentItem.clientName ?? 'Unknown Client',
          labelid: parentItem.clientNumber ?? 'Unknown ID',
          watchlistId: parentItem.watchlistId ?? 'Unknown ID',
          children: childrenItems.map(child => ({
            label: child.clientName ?? 'Unknown Client',
            labelid: child.clientNumber ?? 'Unknown ID',
            watchlistId: child.watchlistId ?? 'Unknown ID',
            isChecked: false,
            isExpanded: false,
          }))
        }
      ];
    }
    else {
      this.treeItems = this.watchlistItemForErase.map(item => ({
        icon: '',
        isExpanded: false,
        disabled: false,
        customClass: '',
        isChecked: false,
        label: item.clientName ?? 'Unknown Client',
        labelid: item.clientNumber ?? 'Unknown ID',
        watchlistId: item.watchlistId ?? 'Unknown ID',
        children: []
      }));
    }
  }

  close() {
    this.modalService.close();
  }

  cancel() {
    this.modalService.close();
  }

  eraseRecords() {
    this.progressindicatorservice.show()
    this.watchlistapiservice.eraseClientRecords(this.selectedWatchlistIds).subscribe({
      next: (data: any) => {
        this.sharedService.showToastNotification('Record deleted');
        this.modalService.close();
        this.progressindicatorservice.hide();
        this.router.navigate(['/watchlist']);
      },
      error: (error) => {
        console.error('Error erasing watchlist data:', error);
      }
    });
  }
}
