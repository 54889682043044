import { ErrorPageComponent } from "./error-page/error-page.component";
import { ExternalCommunicationsComponent } from "./external-communications/external-communications.component";
import { InitiateExternalCommunicationComponent } from "./initiate-external-communication/initiate-external-communication.component";
import { SuccessPageComponent } from "./success-page/success-page.component";
import { ValidateRedirectComponent } from "./validate-redirect/validate-redirect.component";

export const EXTERNAL_COMMUNICATIONS_ROUTES = [
    {
        path : '',
        children : [
            {
                path : 'create',
                component : InitiateExternalCommunicationComponent,
            },
            {
                path : 'details/:id',
                component : ExternalCommunicationsComponent,
            },
            {
                path : 'externalCommunications/success',
                component : SuccessPageComponent,
            },
            {
                path : 'externalCommunications/error',
                component : ErrorPageComponent,
            },
            {
                path : 'validate-redirect',
                component: ValidateRedirectComponent,
            }
        ],
    },
]