export class ExternalCommUrlConstants {
  static readonly #COMMON_EXTERNAL_COMM_PATH = 'ExternalComm/ExternalComm/';
  static readonly #COMMON_EXTERNAL_COMM_SUBMISSION_PATH =
    'ExternalComm/Submission/';
  static readonly #COMMON_EXTERNAL_COMM_SUBMISSION_DOCUMENT_PATH =
    'ExternalComm/SubmissionDocument/';

  public static readonly GET_COMMUNICATION_TYPE =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_PATH +
    'GetCommunicationType';
  public static readonly GET_COMMUNICATION_TYPE_LOGIN_USER =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_PATH +
    'GetActiveCommunicationType';
  public static readonly GET_EXT_COMM_INDUSTRY_SECTOR =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_PATH + 'GetIndustrySector';
  public static readonly GET_MARKET_OFFERING =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_PATH + 'GetMarketOffering';
  public static readonly GET_OFFERING =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_PATH + 'GetOffering';
  public static readonly GET_OFF_PORTFOLIO =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_PATH +
    'GetOfferingPortfolio';
  public static readonly UPDATE_PPMD_STATUS =
    'ExternalComm/Submission/UpdatePPMDStatus';

  public static readonly GET_RM_CONFIG_USER =
    'Admin/ExternalCommunication/GetRMConfig?searchItem=';
  public static readonly VALIDATE_SUBMISSION_TO_COMPLETE =
    'ExternalComm/ExternalComm/ValidateSubmissionToComplete?submissionId=';

  //EXT COMM RESET SUBMISSION STATUS URL
  public static readonly GET_SUBMISSION_STATUS_BY_ACTION_ID = `${
    this.#COMMON_EXTERNAL_COMM_PATH
  }GetSubmissionStatusByAction?submissionactionid=`;
  public static readonly GET_ACTIONS_REASONS_API = `${
    this.#COMMON_EXTERNAL_COMM_PATH
  }GetActionReason?submissionActionId=`;
  public static readonly POST_SUBMISSION_HISTORY_API = `${
    this.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH
  }SaveSubmissionActionHistory`;
  public static readonly GET_SUBMISSION_HISTORY = `${
    this.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH
  }GetSubmissionActionHistory?submissionid=`;

  public static readonly GET_ACTIONS_REASONS_DROPDOWN =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_PATH +
    'GetActionReason?submissionActionId=';
  public static readonly SAVE_ACTION_HISTORY =
    'ExternalComm/Submission/SaveSubmissionActionHistory';
  public static readonly GET_ACTION_HISTORY =
    'ExternalComm/Submission/GetSubmissionActionHistory?submissionid=';

  // ___________________________ Submission related URL ___________________________
  public static readonly POST_INITIATE_EXT_COMM =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'InitiateSubmission';
  public static readonly GET_EXT_COMM_SUBMISSION_DETAILS =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'GetSubmissionDetails?submissionId=';
  public static readonly PUT_EXT_COMM_SUBMISSION_DETAILS =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'UpdateSubmission';
  public static readonly GET_EXT_COMM_LEFTNAV =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'GetLeftMenuSubmissionDetails?communicationTypeId=';
  public static readonly GET_SUBMISSION_COMMUNICATION_ATTRIBUTES =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'GetSubmissionCommunicationAttribute?submissionId=';
  public static readonly POST_SUBMISSION_COMMUNICATION_ATTRIBUTES =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'SaveSubmissionCommunicationAttribute';
  public static readonly GET_EXT_RISK_ASSESSMENT_DETAILS =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'GetSubmissionRiskAssessment?submissionid=';
  public static readonly SAVE_RISK_ASSESSMENT_DATA =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'SaveSubmissionRiskAssessment';
  public static readonly GET_REVIEWERS_DATA =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'GetReviewers?submissionId=';
  public static readonly UPDATE_REVIEWERS_DATA =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'UpdateReviewers';
  public static readonly UPDATE_SUBMISSION_STATUS_URL: string =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'UpdateSubmissionStatus';
  public static readonly UPDATE_REASON_OPT_OUT_SELF_REVIEW_URL: string =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_PATH +
    'UpdateReasonForOptOutSelfReview';
  // ___________________________ Document related URL ___________________________
  public static readonly GET_DOCUMENT_TYPE_DROPDOWN =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_DOCUMENT_PATH +
    'GetDocumentType';
  public static readonly GET_DOCUMENT_STATUS_DROPDOWN =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_DOCUMENT_PATH +
    'GetDocumentStatus?submissionstatusid=';
  public static readonly POST_DOCUMENT_SAVE =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_DOCUMENT_PATH +
    'SaveSubmissionDocument';
  public static readonly GET_DOCUMENT_LIST =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_DOCUMENT_PATH +
    'GetSubmissionDocumentDetails?submissionid=';
  public static readonly EDIT_DOCUMENT_DETAILS =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_DOCUMENT_PATH +
    'EditSubmissionDocument';
  public static readonly DELETE_DOCUMENT_DETAILS =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_DOCUMENT_PATH +
    'DeleteSubmissionDocument?submissiondocumentid=';
  public static readonly UPDATE_REVIEWER_STATUS: string =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_SUBMISSION_DOCUMENT_PATH +
    'UpdateReviewerStatus';
  //_________________________________Workflow related URL_____________________________________
  public static readonly SEARCH_RM_SUPPORT =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_PATH +
    'SearchRMSupport?searchItem=';
  // ___________________________ Audit trail related URL ___________________________
  public static readonly GET_AUDIT_TRAIL_DATA =
    ExternalCommUrlConstants.#COMMON_EXTERNAL_COMM_PATH + 'GetAuditTrail?submissionId=';
}
