<progress-indicator></progress-indicator>
<div class="watchlist-header-container">
  <div class="back-to-dashboard" (click)="backToHome()">
    <img class="margin-right-xs margin-left-s back-icon" src="../../assets/images/dds-icons/back-icon_white.svg" />
    <span>Back to watchlist dashboard</span>
  </div>
</div>
<div class="watchlist-container">
  <div class="row top-margin">
    <div class="col-10" *ngIf="isEdit==false">
      <p class="eclipse-main-tab-header">Add client to watchlist</p>
      <p class="eclipse-content-grey">Adding the client to the watchlist with "Do not serve" status will disable their ability to create new submissions.</p>
    </div>
    <div class="col-10" *ngIf="isEdit==true">
      <p class="eclipse-main-tab-header">Watchlist record details</p>
    </div>
    <div class="col-2 buttons-flex-container">
      <div id="grid-action-buttons-container" class="flex-container">
        <div id="grid-action-button">
          <dds-button class="margin-right-xs" [theme]="addToWatchlistButtonOptions.theme ?? ''"
                                [kind]="addToWatchlistButtonOptions.kind ?? ''"
                                [size]="addToWatchlistButtonOptions.size ?? ''"
                                [width]="addToWatchlistButtonOptions.width ?? ''"
                                [disabled]="!isFormValid()"
                                (clicked)="upsertWatchlistData()">
            <!--Add to watchlist-->
            {{addToWatchlistButtonText}}
          </dds-button>
          <dds-button [theme]="cancelButtonOptions.theme ?? ''"
                                [kind]="cancelButtonOptions.kind ?? ''"
                                [size]="cancelButtonOptions.size ?? ''"
                                [width]="cancelButtonOptions.width ?? ''"
                                (clicked)="cancel()">
            Cancel
          </dds-button>
        </div>
        <div id="grid-action-button-dropdown" class="action-button-dropdown" *ngIf="isEdit">
          <dds-dropdown [theme]="dropdownOptions.theme ?? ''"
                                      [kind]="dropdownOptions.kind ?? ''"
                                      [size]="dropdownOptions.size ?? ''"
                                      [label]="dropdownOptions.label ?? ''"
                                      [width]="dropdownOptions.width ?? ''"
                                      [icon]="dropdownOptions.icon ?? ''"
                                      [disabled]="dropdownOptions.disabled ?? false"
                                      [stickerWidth]="dropdownOptions.stickerWidth ?? 0">
            <ng-container *ngFor="let item of dropdownItems">
              <dds-dropdown-item [value]="item.value"
                                 [counter]="item.counter ?? 0"
                                 (click)="eraseClientWatchlist()"
                                 [disabled]="item.disabled ?? false">{{item.heading}}</dds-dropdown-item>
            </ng-container>
          </dds-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="section-ending"></div>
  <div>
    <div id="new-client-acceptance-container">
      <p class="col eclipse-section-header">Client</p>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <span class="icon-required-yellow" *ngIf="!clientSearchWatchlistmodel.clientName"></span>
      <span class="margin-bottom-s table-header"> Client name </span>
      <div class="padding-top-xs">
        <div *ngIf="isEdit==true && clientSearchWatchlistmodel?.clientName">
          <span class="client-number padding-right-xxs">{{ clientSearchWatchlistmodel.clientName }}</span>
          <span class="sap-id padding-right-xxs">(ID: {{ clientSearchWatchlistmodel.clientNumber }})</span>
          <span [ddsTooltip]="'NCA never evaluated'" *ngIf="clientSearchWatchlistmodel.isNCANeverEvaluated == true"
                [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
                [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
                ddsFocus>
            <span class="padding-left-m client-icon"></span>
          </span>
        </div>
        <div ngbDropdown #dropdown="ngbDropdown" class="search-box" *ngIf="isEdit==false">
          <div class="search-container color-placeholder-text" ngbDropdownAnchor>
            <dds-search [formControl]="searchFormCtrl"
                        [size]="searchOptions.size ?? ''"
                        [customClass]="searchOptions.customClass ?? ''"
                        [icon]="!selectedItem ? (searchOptions.icon || 'dds-icon_search') : ''"
                        (valueChanged)="clientSearch()"
                        (fieldCleared)="clearSearch()">
              <input ddsSearch
                     [placeholder]="selectedItem ? '' : searchOptions.placeholder ?? ''"
                     [(ngModel)]="searchKey"
                     (input)="clientSearch()"
                     (click)="inputClick()"
                     [ngClass]="{'hidden': selectedItem}" [disabled]="!isEditEnabled" />
            </dds-search>
       
            <div *ngIf="selectedItem" class="selected-item-display">
              <span class="client-number padding-right-xxs">{{ selectedItem.clientName }}</span>
              <span class="sap-id padding-right-xxs">(ID: {{ selectedItem.clientNumber }})</span>
              <span *ngIf="selectedItem.watchlistStatus"
                    [ngClass]="{'serve-with-conditions-container': selectedItem.watchlistStatusId == enumWatchlistStatus.ServeWithConditions,
          'do-not-serve-container': selectedItem.watchlistStatusId == enumWatchlistStatus.DoNotServe}">
                <span *ngIf="selectedItem.watchlistStatusId !== enumWatchlistStatus.Removed">
                  {{selectedItem.watchlistStatus}}
                </span>
              </span>
              <span [ddsTooltip]="'NCA never evaluated'"
                    [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
                    [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                    [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                    [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                    [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                    [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                    [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
                    ddsFocus>
                <span class="padding-left-m client-icon"></span>
              </span>
              <span class="dds-icon dds-icon_close" (click)="clearSearch()"></span>
            </div>
          </div>
          <div #menu ngbDropdownMenu role="menu" aria-labelledby="single-button" class="items-dropdown">
            <div class="search-list-label padding-bottom-none" [hidden]="!isLoading">Searching...</div>
            <div [hidden]="isLoading">
              <div class="no-matches" [hidden]="filteredItems.length != 0">No matches found</div>
            </div>
            <button *ngFor="let item of filteredItems" ngbDropdownItem (click)="selectItem(item)">
              <div class="dropdown-item-content">
                <span class="client-number padding-right-xxs">{{ item.clientName }}</span>
                <span class="sap-id padding-right-xxs">(ID: {{ item.clientNumber }})</span>
                <span *ngIf="item.watchlistStatus padding-right-xxs"
                      [ngClass]="{'serve-with-conditions-container': item.watchlistStatusId == enumWatchlistStatus.ServeWithConditions,
                      'do-not-serve-container': item.watchlistStatusId == enumWatchlistStatus.DoNotServe}">
                  <span *ngIf="item.watchlistStatusId !== enumWatchlistStatus.Removed" class="text-word-wrap" [innerHtml]= "item.watchlistStatus">
                  </span>
                </span>
              </div>
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="col-2 top-margin" *ngIf="clientSearchWatchlistmodel.watchlistStatus && isEdit==false">
      <div class="padding-top-xs">
        <dds-button [theme]="editButtonOptions.theme ?? ''"
                    [kind]="editButtonOptions.kind ?? ''"
                    [size]="editButtonOptions.size ?? ''"
                    [width]="editButtonOptions.width ?? ''"
                    (clicked)="editWatchlist(clientSearchWatchlistmodel.clientNumber ?? '',clientSearchWatchlistmodel.isParentClient)">
          Edit watchlist record
        </dds-button>
      </div>
    </div>
    <div class="col-2" *ngIf="(clientSearchWatchlistmodel.clientName && !clientSearchWatchlistmodel.watchlistStatus) || isEdit==true">
      <span class="icon-required-yellow" *ngIf="!watchlistStatusId"></span>
      <span class="required margin-bottom-s table-header"> Client status </span>
      <div class="padding-top-xs">
        <dds-select [list]="watchlistStatusSelectItems"
                    [placeholder]="clientStatusSelectOptions.placeholder ?? ''"
                    [size]="clientStatusSelectOptions.size ?? ''"
                    [(ngModel)]="watchlistStatusId"
                    [optionsTemplate]="optionsTemp"
                    [selectedItemTemplate]="selectedItemTemp"
                    (valueChanged)="changeClientWatchlistStatus()">
        </dds-select>

        <ng-template #selectedItemTemp
                     let-item>
          <span class="padding-right-xxs"
                [ngClass]="{'serve-with-conditions-container': item.value == enumWatchlistStatus.ServeWithConditions,
            'do-not-serve-container': item.value == enumWatchlistStatus.DoNotServe}">
            {{item.heading }}
          </span>
        </ng-template>

        <ng-template #optionsTemp
                     let-ctx="ctx">
          <dds-context-menu [size]="ctx.size"
                            [label]="ctx.label"
                            [id]="ctx.id"
                            class="dds-select__list">
            @for (item of ctx.list; track item) {
            <dds-context-menu-item [disabled]="item.disabled"
                                   [customClass]="item.customClass"
                                   (selectedItem)="ctx.changeItem(item)">
              <span class="padding-right-xxs"
                    [ngClass]="{'serve-with-conditions-container': item.value == enumWatchlistStatus.ServeWithConditions,
                                'do-not-serve-container': item.value == enumWatchlistStatus.DoNotServe}">
                {{item.heading }}
              </span>
            </dds-context-menu-item>
            }
          </dds-context-menu>
        </ng-template>
      </div>
      <div class="padding-top-xs">
        <button aria-disabled="false" aria-label="Button" [disabled]="!watchlistStatusId" *ngIf="clientSearchWatchlistmodel.isParentClient && selectedSubsidiaries.length > 0"
                class="dds-btn dds-btn_silent" (click)="applyWatchlistStatusToSubsidiaries()">
          <div class="dds-btn__content">
            <span class="dds-btn__text">Apply to added subsidiaries</span>
          </div>
        </button>
      </div>
    </div>
    <div [ngClass]="isEdit ? 'custom-col-5' : 'col-6'" *ngIf="(clientSearchWatchlistmodel.clientName && !clientSearchWatchlistmodel.watchlistStatus) || isEdit==true">
      <span class="icon-required-yellow" *ngIf="!clientWatchlistStatusComment"></span>
      <span class="text-label table-header"> Status comment </span>
      <div class="padding-top-xxs">
        <dds-textarea [placeholder]="textareaOptions.placeholder ?? ''"
                      [minHeight]="textareaOptions.minHeight ?? 0" [maxLength]="textareaOptions.maxLength ?? 0"
                      [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''" [size]="textareaOptions.size ?? ''"
                      [(ngModel)]="clientWatchlistStatusComment"></dds-textarea>
      </div>
      <div>
        <button aria-disabled="false" aria-label="Button" [disabled]="!clientWatchlistStatusComment" *ngIf="clientSearchWatchlistmodel.isParentClient && selectedSubsidiaries.length > 0"
                class="dds-btn dds-btn_silent" (click)="applyStatusCommentToSubsidiaries()">
          <div class="dds-btn__content">
            <span class="dds-btn__text">Apply to added subsidiaries</span>
          </div>
        </button>
      </div>
    </div>
    <div class="custom-col-1 center-align" *ngIf="isEdit">
      <span class="required margin-bottom-s table-header"> Action </span>
      <div class="padding-top-xxs">
        <span [ddsTooltip]="'Audit Trail/View history'" *ngIf="clientSearchWatchlistmodel.clientNumber"
              [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
              [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
              [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
              [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
              [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
              [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
              [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
              ddsFocus>
          <span>
            <img class="icon-box" (click)="goToAuditTrail(clientSearchWatchlistmodel.clientName, clientSearchWatchlistmodel.clientNumber, clientSearchWatchlistmodel.watchlistId)" src="\assets\images\delete__l__stroke.png" />
          </span>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="isEdit && clientSearchWatchlistmodel.prevWatchlistStatus">
    <div class="row top-margin" *ngIf="showPreviousWatchlistStatus">
      <div class="col-4"></div>
      <div class="col-2 margin-top-s">
        <span [ngClass]="{'serve-with-conditions-container': clientSearchWatchlistmodel.prevWatchlistStatusId == enumWatchlistStatus.ServeWithConditions,
          'do-not-serve-container': clientSearchWatchlistmodel.prevWatchlistStatusId == enumWatchlistStatus.DoNotServe}">{{clientSearchWatchlistmodel.prevWatchlistStatus}}</span>
      </div>
      <div class="custom-col-5">
        <div class="gray-box">
          <span class="text-word-wrap" [innerHtml]= "clientSearchWatchlistmodel.prevStatusComment">
          </span>
        </div>
      </div>
    </div>
    <div class="row top-margin">
      <div class="col-4"></div>
      <div class="col-2">
        <button aria-disabled="false" aria-label="Button"
                class="dds-btn dds-btn_silent" (click)="onShowPreviousWatchlistStatus()">
          <div class="dds-btn__content">
            <span class="dds-btn__text">{{ showPreviousWatchlistStatus ? 'Hide previous status' : 'Show previous status' }}</span>
            <span class="dds-btn__icon dds-icon" [ngClass]="showPreviousWatchlistStatus ? 'dds-icon_arrow-up' : 'dds-icon_arrow-down'"></span>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="clientSearchWatchlistmodel.clientWatchlistCreatedDate && clientSearchWatchlistmodel.watchlistStatusId != enumWatchlistStatus.Removed && isEdit == false">
    <div class="col-7">
      <div class="container margin-top-s margin-bottom-s">
        <div class="top-header">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="text-container">
            <div class="initial-text">
              <span>Added to the watchlist</span>
            </div>
            <div class="vertical-line" *ngIf="isshowmorevisible"></div>
            <dds-button *ngIf="!isshowmorevisible" [theme]="options.theme ?? ''"
                        [kind]="optionsShowMore.kind ?? ''"
                        [size]="optionsShowMore.size ?? ''"
                        [width]="optionsShowMore.width ?? ''"
                        [icon]="optionsShowMore.icon ??''"
                        [isIconLeft]="optionsShowMore.isIconLeft ?? false"
                        [role]="optionsShowMore.role ?? ''"
                        [ariaLabel]="optionsShowMore.ariaLabel ?? ''"
                        [customClass]="optionsShowMore.customClass ?? ''"
                        (clicked)="clickclientshowmore($event)">Show more</dds-button>
          </div>
        </div>
        <div class="moreText margin-top-xs" *ngIf="isshowmorevisible">
          <div class="not-required">
            <span>Added by {{clientSearchWatchlistmodel.clientWatchlistCreatedBy}} on {{clientSearchWatchlistmodel.clientWatchlistCreatedDate}}</span>
          </div>
          <div class="reason-header margin-top-xs">Status comment:</div>
          <div class="margin-top-xxs" >
            <span class="text-word-wrap" [innerHtml]= "clientSearchWatchlistmodel.statusComment"></span>
          </div>
          <div class="margin-top-s">
            <dds-button [theme]="options.theme ?? ''"
                        [kind]="options.kind ?? ''"
                        [size]="options.size ?? ''"
                        [width]="options.width ?? ''"
                        [icon]="options.icon ??''"
                        [isIconLeft]="options.isIconLeft ?? false"
                        [role]="options.role ?? ''"
                        [ariaLabel]="options.ariaLabel ?? ''"
                        [customClass]="options.customClass ?? ''"
                        (clicked)="clickclient($event)">Show less</dds-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="clientSearchWatchlistmodel.clientWatchlistCreatedDate && clientSearchWatchlistmodel.watchlistStatusId == enumWatchlistStatus.Removed && isEdit == false">
    <div class="col-7">
      <div class="container margin-top-s margin-bottom-s">
        <div class="top-header">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="text-container">
            <div class="initial-text">
              <span>Prior watchlist client</span>
            </div>
            <div class="vertical-line" *ngIf="isshowmorevisiblePriorWatchlist"></div>
            <dds-button *ngIf="!isshowmorevisiblePriorWatchlist" [theme]="optionsShowMore.theme ?? ''"
                        [kind]="optionsShowMore.kind ?? ''"
                        [size]="optionsShowMore.size ?? ''"
                        [width]="optionsShowMore.width ?? ''"
                        [icon]="optionsShowMore.icon ??'dds-icon_arrow-down'"
                        [isIconLeft]="optionsShowMore.isIconLeft ?? false"
                        [role]="optionsShowMore.role ?? ''"
                        [ariaLabel]="optionsShowMore.ariaLabel ?? ''"
                        [customClass]="optionsShowMore.customClass ?? ''"
                        (clicked)="clickpriorWatchlistshowmore($event)">Show more</dds-button>
          </div>
        </div>
        <div class="moreText margin-top-xs" *ngIf="isshowmorevisiblePriorWatchlist">
          <div class="not-required">
            <span>Removed by {{clientSearchWatchlistmodel.clientWatchlistCreatedBy}} on {{clientSearchWatchlistmodel.clientWatchlistCreatedDate}}</span>
          </div>
          <div class="reason-header margin-top-xs">Status comment:</div>
          <div class="margin-top-xxs">
            <span class="text-word-wrap" [innerHtml]= "clientSearchWatchlistmodel.statusComment"></span>
          </div>
          <div class="not-required margin-top-s">
            <span>Added/Updated to the watchlist with {{clientSearchWatchlistmodel.prevWatchlistStatus}} {{clientSearchWatchlistmodel.prevStatusModifiedDetail}}</span>
          </div>
          <div class="reason-header margin-top-xs">Status comment:</div>
          <div class="margin-top-xxs">
            <span class="text-word-wrap" [innerHtml]= "clientSearchWatchlistmodel.prevStatusComment"></span>
          </div>
          <div class="margin-top-s">
            <dds-button [theme]="options.theme ?? ''"
                        [kind]="options.kind ?? ''"
                        [size]="options.size ?? ''"
                        [width]="options.width ?? ''"
                        [icon]="options.icon ??''"
                        [isIconLeft]="options.isIconLeft ?? false"
                        [role]="options.role ?? ''"
                        [ariaLabel]="options.ariaLabel ?? ''"
                        [customClass]="options.customClass ?? ''"
                        (clicked)="clickpriorWatchlist($event)">Show less</dds-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="isEdit == false">
    <div class="col-7">
      <div class="container margin-top-s margin-bottom-s" *ngIf="alreadyAddedSubsidiaryCount > 0 && clientSearchWatchlistmodel.isParentClient == true">
        <div class="top-header">
          <div class="icon-alert-logo-container">
            <div class="icon-green-pin"></div>
          </div>
          <div class="text-container">
            <div class="initial-text">
              <span>Some subsidiaries of this client are already on the watchlist.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container margin-top-s margin-bottom-s" *ngIf="!clientSearchWatchlistmodel.parentClientWatchlistCreatedDate && clientSearchWatchlistmodel.parentClientName">
        <div class="top-header">
          <div class="icon-alert-logo-container">
            <div class="icon-green-pin"></div>
          </div>
          <div class="text-container">
            <div class="initial-text">
              <!--TODO : add redirection logic once client details page is added-->
              <span>This is a subsidiary of <a href="/client/{{clientSearchWatchlistmodel.parentClientNumber}}/status" aria-disabled="false" target="_blank" ddsFocus class="dds-link dds-link_silent">{{clientSearchWatchlistmodel.parentClientName}}</a> client</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container margin-top-s margin-bottom-s" *ngIf="clientSearchWatchlistmodel.parentClientWatchlistCreatedDate">
        <div class="top-header">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="text-container">
            <div class="initial-text">
              <span>This is a subsidiary of <a href="/client/{{clientSearchWatchlistmodel.parentClientNumber}}/status" aria-disabled="false" target="_blank" ddsFocus class="dds-link dds-link_silent">{{clientSearchWatchlistmodel.parentClientName}}</a> client </span>
            </div>
            <div class="vertical-line" *ngIf="isshowmoreparentvisible"></div>
            <dds-button *ngIf="!isshowmoreparentvisible" [theme]="optionsShowMore.theme ?? ''"
                        [kind]="optionsShowMore.kind ?? ''"
                        [size]="optionsShowMore.size ?? ''"
                        [width]="optionsShowMore.width ?? ''"
                        [icon]="optionsShowMore.icon ??''"
                        [isIconLeft]="optionsShowMore.isIconLeft ?? false"
                        [role]="optionsShowMore.role ?? ''"
                        [ariaLabel]="optionsShowMore.ariaLabel ?? ''"
                        [customClass]="optionsShowMore.customClass ?? ''"
                        (clicked)="clickparentclientshowmore($event)">Show more</dds-button>
          </div>
        </div>
        <div class="moreText margin-top-xs" *ngIf="isshowmoreparentvisible">
          <div class="not-required">
            <span>{{clientSearchWatchlistmodel.parentClientName}} was added to the watchlist with the "{{clientSearchWatchlistmodel.parentClientWatchlistStatus}}" by {{clientSearchWatchlistmodel.parentClientWatchlistCreatedBy}} on {{clientSearchWatchlistmodel.parentClientWatchlistCreatedDate}}</span>
          </div>
          <div class="reason-header margin-top-xs">Status comment:</div>
          <div class="margin-top-xxs">
            <span class="text-word-wrap" [innerHtml]="clientSearchWatchlistmodel.parentClientStatusComment"></span>
          </div>
          <div class="margin-top-s">
            <dds-button [theme]="options.theme ?? ''"
                        [kind]="options.kind ?? ''"
                        [size]="options.size ?? ''"
                        [width]="options.width ?? ''"
                        [icon]="options.icon ??''"
                        [isIconLeft]="options.isIconLeft ?? false"
                        [role]="options.role ?? ''"
                        [ariaLabel]="options.ariaLabel ?? ''"
                        [customClass]="options.customClass ?? ''"
                        (clicked)="clickparentclient($event)">Show less</dds-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="subsidiary-client" *ngIf="isSubsidiaryBlockVisible">
    <div class="section-ending"></div>
    <div class="row">
      <div class="col-10">
        <p class="eclipse-section-header">Subsidiaries</p>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div>
          <dds-multi-select [displayTickAllBtn]="multiSelectOptions.displayTickAllBtn ?? true"
                            [label]="multiSelectOptions.label ?? ''"
                            [size]="multiSelectOptions.size ?? ''"
                            [placeholder]="multiSelectOptions.placeholder ?? ''"
                            [type]="multiSelectOptions.type ?? '' "
                            [controlType]="multiSelectOptions.controlType ?? ''"
                            [theme]="multiSelectOptions.theme ?? ''"
                            [list]="subsidiaryClientList"
                            [disabled]="multiSelectOptions.disabled ?? true"
                            [(ngModel)]="selectedSubsidiaries">
          </dds-multi-select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="selectedSubsidiaries.length > 0">
      <div class="col-12 table-header subsidiary-table-header">
        <div class="row margin-top-m">
          <div class="col-4">Client name</div>
          <div class="col-2">Status</div>
          <div class="custom-col-5">Status comment</div>
          <div class="custom-col-1 center-align">Actions</div>
        </div>
      </div>
      <div class="section-ending"></div>
      <div class="row row-left-margine" *ngFor="let client of selectedSubsidiaries">
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <span class="client-number padding-right-xxs">{{ client.clientName }}</span>
              <span class="sap-id padding-right-xxs">(ID: {{ client.clientNumber }})</span>
              <span [ddsTooltip]="'NCA never evaluated'" *ngIf="client.isNCANeverEvaluated == true"
                    [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
                    [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                    [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                    [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                    [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                    [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                    [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
                    ddsFocus>
                <span class="padding-left-m client-icon"></span>
              </span>
            </div>
            <div class="col-2">
              <div class="icon-textarea-container">
                <span class="icon-required-yellow status-comment" *ngIf="!client.watchlistStatusId"></span>

                <dds-select [list]="client.watchlistStatuses"
                            [placeholder]="clientStatusSelectOptions.placeholder ?? ''"
                            [size]="clientStatusSelectOptions.size ?? ''"
                            [(ngModel)]="client.watchlistStatusId"
                            [optionsTemplate]="optionsTemp"
                            [selectedItemTemplate]="selectedItemTemp"
                            (valueChanged)="onSubsidiaryStatusChange(client)">
                </dds-select>

                <ng-template #selectedItemTemp let-item>
                  <span class="padding-right-xxs"
                        [ngClass]="{'serve-with-conditions-container': item.value == enumWatchlistStatus.ServeWithConditions,
'do-not-serve-container': item.value == enumWatchlistStatus.DoNotServe}">
                    {{ item.heading }}
                  </span>
                </ng-template>

                <ng-template #optionsTemp let-ctx="ctx">
                  <dds-context-menu [size]="ctx.size"
                                    [label]="ctx.label"
                                    [id]="ctx.id"
                                    class="dds-select__list">
                    @for (item of ctx.list; track item) {
                    <dds-context-menu-item [disabled]="item.disabled"
                                           [customClass]="item.customClass"
                                           (selectedItem)="ctx.changeItem(item)">
                      <span class="padding-right-xxs"
                            [ngClass]="{'serve-with-conditions-container': item.value == enumWatchlistStatus.ServeWithConditions,
'do-not-serve-container': item.value == enumWatchlistStatus.DoNotServe}">
                        {{ item.heading }}
                      </span>
                    </dds-context-menu-item>
                    }
                  </dds-context-menu>
                </ng-template>
              </div>
            </div>
            <div class="custom-col-5">
              <div class="icon-textarea-container">
                <span class="icon-required-yellow status-comment" *ngIf="!client.statusComment"></span>
                <dds-textarea [(ngModel)]="client.statusComment"
                              [placeholder]="textareaOptions.placeholder ?? ''"
                              [minHeight]="textareaOptions.minHeight ?? 0"
                              [maxLength]="textareaOptions.maxLength ?? 0"
                              [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
                              [size]="textareaOptions.size ?? ''"
                              class="flex-grow">
                </dds-textarea>
              </div>
            </div>
            <div class="custom-col-1 center-align">
              <span class="dds-icon dds-icon_close" (click)="removeSubsidiary(client.clientNumber)" *ngIf="!client.watchlistId"></span>
              <span [ddsTooltip]="'Audit Trail/View history'" *ngIf="client.watchlistId"
                    [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
                    [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                    [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                    [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                    [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                    [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                    [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
                    ddsFocus>
                <span>
                  <img class="icon-box" (click)="goToAuditTrail(client.clientName, client.clientNumber, client.watchlistId)" src="\assets\images\delete__l__stroke.png" />
                </span>
              </span>
            </div>
          </div>
          <div class="row margin-top-s" *ngIf="client.prevWatchlistStatus">
            <div class="col-4"></div>
            <div class="col-2">
              <div *ngIf="client.prevWatchlistStatus">
                <div class="row margin-top-xs" *ngIf="client.isPreviousStatusShow">
                  <div class="col-12">
                    <span [ngClass]="{'serve-with-conditions-container': client.prevWatchlistStatusId == enumWatchlistStatus.ServeWithConditions,
'do-not-serve-container': client.prevWatchlistStatusId == enumWatchlistStatus.DoNotServe}">{{client.prevWatchlistStatus}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-col-5">
              <div class="row" *ngIf="client.isPreviousStatusShow">
                <div class="col-12">
                  <div class="gray-box">
                    <span class="text-word-wrap">
                      {{client.prevStatusComment}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-1"></div>
          </div>
          <div class="row" *ngIf="client.prevWatchlistStatus">
            <div class="col-4"></div>
            <div class="col-2">
              <button aria-disabled="false" aria-label="Button"
                      class="dds-btn dds-btn_silent" (click)="onShowPreviousWatchlistSubsidiaryStatus(client)">
                <div class="dds-btn__content center-align">
                  <span class="dds-btn__text">{{ client.isPreviousStatusShow ? 'Hide previous status' : 'Show previous status' }}</span>
                  <span class="dds-btn__icon dds-icon" [ngClass]="client.isPreviousStatusShow ? 'dds-icon_arrow-up' : 'dds-icon_arrow-down'"></span>
                </div>
              </button>
            </div>
            <div class="col-6"></div>
          </div>
        </div>
        <div class="section-ending"></div>
      </div>
    </div>
  </div>
</div>
