import { Component, Input } from '@angular/core';
import { DocumentService } from '../../http/document/document.service';
import {
  ClientWebapiService,
  DocumentTypeData,
} from '../../http/client/client-webapi.service';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { NotifyPopupComponent } from '../../common/notify-popup/notify-popup.component';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { UploadFile } from '@usitsdasdesign/dds-ng/uploader';
import {
  uploadAreaOptions,
  textFileOptions,
  textareaOptions,
  textfileNameOptions,
  selectDocumentTypeOptions,
  options,
  DocumentColumns,
  fileTooltipOptions,
  inputOptions,
  datepickerOptions,
  checkboxOptions,
  radioGroupOptions,
} from './document-upload-popup-settings';
import { PopupType } from '../../common/utils/pop-up-constants';
import { RadioButton } from '@usitsdasdesign/dds-ng/radio';
import { Themes } from '@usitsdasdesign/dds-ng/shared';
import { DocumentType } from '../../common/models/document-type.model';
import { ClientAgreementRequest } from '../../common/models/client-agreement-request.model';
import { ClientAgreementDocument } from '../../common/models/client-agreement-document.model';
import { SharedService } from '../../common/services/shared-service.service';

@Component({
  selector: 'app-document-upload-popup',
  templateUrl: './document-upload-popup.component.html',
  styleUrl: './document-upload-popup.component.less',
})
export class DocumentUploadPopupComponent {
  constructor(
    private docService: DocumentService,
    private ncaDocService: ClientWebapiService,
    private modalService: ModalService,
    private progressIndicator: ProgressIndicatorService,
    private commonHelperService: CommonHelperService,
    private sharedService: SharedService
  ) {}

  maxFileLength: any = 5; //Max Files allowing
  maxFilesAllowedToUploadInNCA: any = 32; //Max Files allowed to upload in NCA
  maxFileSizeAllowed: any = 50 * 1024 * 1024; // 50 MB in bytes
  maxFileSizeAllowedUpto: any = 100 * 1024 * 1024; // 100 MB in bytes
  ellipsisAppliedDocLength = 16;
  validFileExtensions: any =
    'doc,docx,msg,pptx,pdf,ppt,xls,xlsb,xlsx,xlsm,jpg,jpeg,png,zip'; //Valid File Extensions

  displayStyle: any = 'none';
  isFileSize100MBExceed: boolean = false;
  notifyReason: any = '';
  fileName: any = '';
  filePath: any = '';
  uploadCount: any = 0;
  uploadFileSourceList: any = [];
  fileSourceList: any = [];
  removeUploadedDocumentFromList: any = [];
  documentTypeSelectItems: any = [];
  documentAllSectionsMetaData: any = [];
  documentUploadData: any = [];
  documentUploadedData: any = [];
  documentUnUploadData: any = [];
  fileUploadPercentage: any = '';
  isEncrypted: boolean = true;
  isFileUploaded: boolean = false;
  isDocumentExists: boolean = false;
  fileList: any = [];
  loadUserTriggeredFilesLength: number = 0; //To track the user triggered files length

  @Input() isFooter: boolean = false;
  @Input() size: string = '';
  @Input() isInverse: boolean = false;
  @Input() public documentInitiatedDetails: any = {};
  @Input() popupType: string = PopupType.DocumentUpload;

  uploadAreaOptions = uploadAreaOptions;
  textFileOptions = textFileOptions;
  textareaOptions = textareaOptions;
  textfileNameOptions = textfileNameOptions;
  selectDocumentTypeOptions = selectDocumentTypeOptions;
  options = options;
  DocumentColumns = DocumentColumns;
  fileTooltipOptions = fileTooltipOptions;
  isEditMode: boolean = false;
  clientAgreementDocument: ClientAgreementDocument | null = null;

  ngOnInit(): void {
    this.popupTitle =
      this.documentInitiatedDetails?.popupTitle ??
      (this.popupType === PopupType.DocumentUpload
        ? 'Attach document(s)'
        : this.popupTitle);
    if (this.popupType === PopupType.ClientAgreement) {
      uploadAreaOptions.isMultiple = false;
      this.descriptionTextareaOptions = { ...textareaOptions, maxLength: 100 };
      this.descriptionTextareaOptions.placeholder = 'Enter text';
      this.descriptionTextareaOptions.isRequired = true;
      this.selectDocumentTypeOptions.placeholder = 'Select document type';
      this.selectDocumentTypeOptions.isRequired = true;
      this.commentsTextareaOptions = { ...textareaOptions, maxLength: 1000 };
      this.commentsTextareaOptions.placeholder = 'Enter text';
      this.datepickerOptions.isRequired = true;
      this.inputOptions.placeholder = 'Filename';
      uploadAreaOptions.btnLabel = 'Select file';
      this.maxFileLength = 1;
      this.clientNumber = this.documentInitiatedDetails.clientNumber;
      this.datepickerOptions.disabled = false;

      this.clientAgreementDocument =
        this.documentInitiatedDetails.clientAgreementDocument;
      this.isEditMode = this.documentInitiatedDetails.isEditMode;
      this.progressIndicator.show();
      if (this.isEditMode) {
        this.selectDocumentTypeOptions.isRequired = false;
        this.descriptionTextareaOptions.isRequired = false;
        this.datepickerOptions.isRequired = false;
        this.uploadedDocumentFilename =
          this.clientAgreementDocument?.title ?? '';
        this.description = this.commonHelperService.trimPrefixEmptySpace(this.clientAgreementDocument?.description) ?? '';
        this.comments = this.commonHelperService.trimPrefixEmptySpace(this.clientAgreementDocument?.comments) ?? '';
        this.documentExpirationDate = this.clientAgreementDocument
          ?.expirationDate
          ? new Date(this.clientAgreementDocument.expirationDate)
          : null;
        this.noExpirationDate =
          this.clientAgreementDocument?.isNoExpiration ?? false;
        this.documentVisibility = this.clientAgreementDocument
          ?.isVisibleToAllClientSubsidiaries
          ? 'yes'
          : 'no';
        this.comments = this.commonHelperService.trimPrefixEmptySpace(this.clientAgreementDocument?.comments) ?? '';
        this.onNoExpirationStateChanged();
      }
      this.docService.getDocumentTypes().subscribe((result: DocumentType[]) => {
        this.selectItems = result.map((d: DocumentType) => {
          return {
            value: d.documentTypeId,
            heading: d.documentTypeName,
          };
        });
        if (this.isEditMode) {
          const editDocumentType = this.selectItems.filter(
            (doc) =>
              doc.value ==
              this.clientAgreementDocument?.documentTypeId.toString()
          );
          if (editDocumentType.length > 0) {
            this.selectedDocumentType = editDocumentType[0].value ?? '';
            this.validateDocumentDetails();
          }
        }
        this.progressIndicator.hide();
      });
    } else {
      this.getDocumentTypeDropdownData();
      this.getDocumentUploadData(
        this.documentInitiatedDetails.docSectionId,
        this.documentInitiatedDetails.clientmilestoneId
      );
    }
    this.filePath = this.commonHelperService.getDocumentFolderPath(
      this.documentInitiatedDetails.moduleName
    );
  }
  getDocumentUploadData(docSectionId: any, clientmilestoneId: any) {
    this.progressIndicator.show();
    this.ncaDocService
      .getNCADocumentUploadData(docSectionId, clientmilestoneId)
      .subscribe({
        next: (result: any) => {
          if (docSectionId == 'All') {
            this.documentAllSectionsMetaData = result;
            result = result.filter(
              (element: any) =>
                element.clientMilestoneDocumentSectionID ==
                this.documentInitiatedDetails.docSectionId
            );
            this.progressIndicator.hide();
            this.closePopup();
          } else {
            this.progressIndicator.hide();
            this.documentUploadedData = result;
          }
        },
        error: (err: any) => {
          this.progressIndicator.hide();
        },
      });
  }
  getDocumentTypeDropdownData() {
    this.ncaDocService.getDocumentTypeData().subscribe({
      next: (result: any) => {
        const convertedArray =
          this.commonHelperService.loadFormWiseDocumentTypes(
            result,
            this.documentInitiatedDetails.moduleName,
            this.documentInitiatedDetails.docSectionId
          );
        this.documentTypeItemsList = convertedArray;
        //this.enableDisableUIDocumentDetails();
        this.progressIndicator.hide();
      },
      error: (err: any) => {
        this.progressIndicator.hide();
      },
    });
  }

  removeDocument(row: any) {
    const index = this.documentUploadData.indexOf(row);
    if (index === -1) {
      return;
    }
    this.documentUploadData.splice(index, 1);
    this.fileList.forEach((element: any, index: any) => {
      if (element.fileName === row.fileName) {
        element.remove();
      }
    });
    this.documentUploadData = this.documentUploadData.filter(
      (item: any) => item.fileName !== row.fileName
    );
    this.uploadFileSourceList = this.uploadFileSourceList.filter(
      (element: any) => element.fileSource.name !== row.fileName
    );
    this.removeUploadedDocumentFromList =
      this.removeUploadedDocumentFromList.filter(
        (element: any) => element !== row.fileName
      );
    this.fileSourceList = this.fileSourceList.filter(
      (element: any, index: any) => element.file.name !== row.fileName
    );
    //this.loadFileSourceData(this.fileSourceList);
    this.isDocumentExists = this.documentUploadData.length > 0 ? true : false;
  }
  onSave() {
    if (!this.isEditMode) {
      this.uploadFiletoS3();
    } else {
      this.onClientAgreementDocumentUpload(
        this.documentInitiatedDetails.clientAgreementDocument.attachmentId
      );
    }
    this.applyBodyOverflow();
  }
  uploadDraggedAndSelectedDocument() {
    this.fileSourceList.forEach((element: any) => {
      if (
        !this.uploadFileSourceList.some(
          (item: any) => item.fileSource.name === element.file.name
        )
      ) {
        this.uploadFileSourceList.push({ fileSource: element.file });
      }
      if (
        !this.documentUploadData.some(
          (item: any) => item.fileName === element.file.name
        )
      ) {
        this.documentUploadData.push({
          fileName: element.file.name,
          clientMilestoneDocumentTypeID: 0,
          fileComments: '',
          isUserClickedEdit: false,
        });
      }
    });
    let tempData = this.documentUploadData.some(
      (obj: any) =>
        obj.fileName.length == 0 ||
        obj.clientMilestoneDocumentTypeID.toString() == 0
    );
    this.isDocumentExists = tempData === true ? false : true;
    if (this.popupType === PopupType.ClientAgreement) {
      this.uploadedDocumentFilename =
        this.documentUploadData.length > 0
          ? this.documentUploadData[0].fileName
          : '';

          this.validateDocumentDetails();
    }
  }
  uploadFiletoS3() {
    this.progressIndicator.show();
    for (const fileSource of this.uploadFileSourceList) {
      this.docService
        .getUploadUrlForDCRMValidation(fileSource.fileSource.name)
        .subscribe({
          next: (presignedUrlData: any) => {
            this.docService
              .uploadFileToS3UsingPresignedUrl(
                presignedUrlData.resultData,
                fileSource.fileSource
              )
              .subscribe(
                () => {
                  this.docService
                    .uploadDocument(
                      fileSource.fileSource.name,
                      presignedUrlData.resultData.documentKey,
                      this.filePath
                    )
                    .subscribe({
                      next: (result: any) => {
                        if (result.isDocumentUploaded == false) {
                          if (result.isDocumentProtected == true) {
                            this.notifyReason = 'DocumentProtected';
                            this.documentUnUploadData.push(result.documentName);
                          }
                          if (
                            this.uploadFileSourceList.length - 1 ==
                            this.uploadCount
                          ) {
                            this.fileSourceList = [];
                            this.progressIndicator.hide();
                            this.showMsg();
                          } else {
                            this.uploadCount++;
                          }
                        } else {
                          if (this.popupType === PopupType.ClientAgreement) {
                            this.onClientAgreementDocumentUpload(
                              result.attachmentID
                            );
                          } else {
                            this.saveNCADocumentMetaData(
                              result.documentName,
                              result.attachmentID
                            );
                          }
                        }
                      },
                      error: (err: any) => {
                        this.progressIndicator.hide();
                      },
                      complete: () => {
                        this.progressIndicator.hide();
                      },
                    });
                },
                (error) => {
                  console.error('Error uploading file:', error);
                  this.progressIndicator.hide();
                }
              );
          },
          error: (error: any) => {
            console.error('Error getting presigned URL:', error);
            this.progressIndicator.hide();
          },
        });
    }
  }
  prepareNCADocumentMetaDataRequest(fileName: any, attachmentID: any) {
    let documentMetaDataRequest: any;
    this.fileSourceList.forEach((element: any, index: any) => {
      if (element.file.name.toString().includes(fileName)) {
        let fileData = this.documentUploadData.filter(
          (item: any) => item.fileName.toLowerCase() == fileName.toLowerCase()
        );
        documentMetaDataRequest = {
          AttachmentID: attachmentID,
          isActive: true,
          FileComments: element.fileComments,
          ClientMilestoneID: Number(
            this.documentInitiatedDetails.clientmilestoneId
          ),
          ClientMilestoneDocumentSectionID: Number(
            this.documentInitiatedDetails.docSectionId
          ),
          fileComments:
            fileData.length > 0 && fileData[0].fileComments != undefined
              ? fileData[0].fileComments
              : '',
          ClientMilestoneDocumentTypeID:
            fileData.length > 0 &&
            fileData[0].clientMilestoneDocumentTypeID != undefined
              ? fileData[0].clientMilestoneDocumentTypeID
              : 0,
        };
      }
    });
    return documentMetaDataRequest;
  }
  saveNCADocumentMetaData(fileName: any, attachmentID: any) {
    this.progressIndicator.show();
    let documentMetaDataRequest = this.prepareNCADocumentMetaDataRequest(
      fileName,
      attachmentID
    );
    this.ncaDocService
      .saveNCADocumentMetaData(documentMetaDataRequest)
      .subscribe({
        next: (result: any) => {
          if (this.uploadFileSourceList.length - 1 == this.uploadCount) {
            if (this.notifyReason == 'DocumentProtected') {
              this.progressIndicator.hide();
              this.showMsg();
              this.fileSourceList = this.fileSourceList.filter((item: any) =>
                this.documentUnUploadData.includes(item.fileName)
              );
              this.documentUploadData = this.documentUploadData.filter(
                (item: any) => this.documentUnUploadData.includes(item.fileName)
              );
            } else {
              this.fileSourceList = [];
              this.getDocumentUploadData(
                'All',
                this.documentInitiatedDetails.clientmilestoneId
              );
            }
          } else {
            this.uploadCount++;
          }
          if (this.isEditMode) {
            this.sharedService.showToastNotification('Document(s) added');
          } else {
            this.sharedService.showToastNotification('Document(s) updated');
          }
        },
        error: (err: any) => {
          this.progressIndicator.hide();
          console.error('Error saving MetaData', err);
        },
        complete: () => {
          this.progressIndicator.hide();
        },
      });
  }
  applyBodyOverflow() {
    document.body.style.overflow = 'auto';
  }
  openModal() {
    this.modalService.open(DocumentUploadPopupComponent);
  }
  closeModal() {
    if (this.popupType === PopupType.ClientAgreement) {
      this.closePopup();
    } else {
      this.getDocumentUploadData(
        'All',
        this.documentInitiatedDetails.clientmilestoneId
      );
    }
  }
  validateElipsisAppliedDocLength(fileName: any) {
    return fileName.length > this.ellipsisAppliedDocLength;
  }
  closePopup() {
    this.applyBodyOverflow();
    this.modalService.close(this.documentAllSectionsMetaData);
  }
  isFileNameValidLength() {
    let isFileValidLength = true;
    this.fileSourceList.forEach((element: any) => {
      if (element.file.name.length > 150) {
        isFileValidLength = false;
      }
    });
    let rslt = this.fileSourceList.filter(
      (item: any) => item.file.name.length <= 150
    );
    if (rslt.length != this.fileSourceList.length) {
      isFileValidLength = false;
      this.fileSourceList = rslt;
    }
    return isFileValidLength;
  }
  isFileExtensionValid() {
    let isFileExtensionValid = true;
    let rslt = this.fileSourceList.filter((item: any) =>
      this.validFileExtensions
        .toString()
        .toLowerCase()
        .includes(item.fileExtenstion.toString().toLowerCase())
    );
    if (rslt.length != this.fileSourceList.length) {
      isFileExtensionValid = false;
      this.fileSourceList = rslt;
    }
    return isFileExtensionValid;
  }
  isFileNameExist() {
    let isFileExist = false;
    let fileNameList: any = [];
    this.documentUploadedData.forEach((element: any) => {
      fileNameList.push(element.fileName);
    });
    let rslt = this.fileSourceList.filter(
      (item: any) => !fileNameList.includes(item.fileName)
    );
    if (rslt.length != this.fileSourceList.length) {
      isFileExist = true;
      this.fileSourceList = rslt;
    }
    return isFileExist;
  }
  isFileSizeExceed() {
    let isFileSizeExceed = false;
    this.isFileSize100MBExceed = false;
    let sum: number = 0;
    this.fileSourceList.forEach((element: any) => (sum += element.file.size));
    if (this.maxFileSizeAllowedUpto < sum) isFileSizeExceed = true;
    return isFileSizeExceed;
  }
  isSigleFileMaxSizeExceed() {
    let isFileSizeExceed = false;
    this.fileSourceList.forEach((element: any) => {
      if (element.file.size > this.maxFileSizeAllowed) {
        isFileSizeExceed = true;
      }
    });
    return isFileSizeExceed;
  }
  isMaxFileSelectionExceed() {
    let isMaxFileSelectionExceed = false;
    let selectedFileCount = this.fileSourceList.length;
    if (selectedFileCount > this.maxFileLength) {
      isMaxFileSelectionExceed = true;
    }
    return isMaxFileSelectionExceed;
  }
  isMaxFileUploadExceed() {
    let isMaxFileUploadExceed = false;
    let selectedFileCount =
      this.fileSourceList.length + this.documentUploadedData.length;
    if (selectedFileCount > this.maxFilesAllowedToUploadInNCA) {
      isMaxFileUploadExceed = true;
    }
    return isMaxFileUploadExceed;
  }

  onFocusOut(row: any, index: number) {
    row.isUserClickedEdit = false;
    this.valueChange(row);
    if (this.uploadFileSourceList.length > 0) {
      let file = this.uploadFileSourceList[index].fileSource;
      const newFile = new File([file], row.fileName, {
        type: file.type,
        lastModified: file.lastModified,
      });
      this.uploadFileSourceList[index].fileSource = newFile;
    }
    if (this.fileSourceList.length > 0) {
      let file = this.fileSourceList[index].file;
      const newFile = new File([file], row.fileName, {
        type: file.type,
        lastModified: file.lastModified,
      });
      this.fileSourceList[index].file = newFile;
      this.fileSourceList[index].fileName = row.fileName;
    }
    if (this.removeUploadedDocumentFromList.length > 0) {
      this.removeUploadedDocumentFromList[index] = row.fileName;
    }
  }
  onEditDocName(row: any) {
    row.isUserClickedEdit = true;
  }
  removeBodyOverflow() {
    document.body.style.overflow = 'hidden';
  }
  showMsg(modalBtn?: ButtonComponent, title: string = 'Warning') {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(NotifyPopupComponent, {
      isFooter: true,
      size: 'md',
      isInverse: false,
      notifyReason: this.notifyReason,
      footerButtonText:
        this.popupType === PopupType.ClientAgreement ? 'OK' : 'Dismiss',
      title,
    });
    modalRef.onClosed().subscribe(() => {
      if (modalBtn) {
        modalBtn.focus();
      }
      this.removeBodyOverflow();
    });
  }
  uploadedItems(selectedFilesList: UploadFile[]) {
    if (this.popupType === PopupType.ClientAgreement) {
      this.documentUploadData = []; // Clear the existing data
      this.removeUploadedDocumentFromList = []; // Clear the existing data
    }
    if (selectedFilesList.length > 0) {
      this.fileList = selectedFilesList;
      this.loadUserTriggeredFilesLength =
        this.loadUserTriggeredFilesLength == 0
          ? selectedFilesList.length
          : this.loadUserTriggeredFilesLength;
      this.removePreviousUploadDocument(selectedFilesList); //Due to DDS Upload component issue, we are removing the already uploaded files from the list
      if (
        this.fileSourceList.length > 0 &&
        this.loadUserTriggeredFilesLength == selectedFilesList.length
      ) {
        if (this.isMaxFileUploadExceed()) {
          //Validate Max File Selection - Exceeds 32 File
          this.fileSourceList = [];
          this.removeUploadedDocumentFromList =
            this.removeUploadedDocumentFromList.filter(
              (item: any) =>
                !selectedFilesList.some((file: any) => file.fileName === item)
            );
          selectedFilesList.forEach((element: any, index: any) => {
            element.remove();
          });
          this.resetFiles();
          this.notifyReason = 'FileCount_ClientMilestoneDocument';
          this.showMsg();
        } else if (this.isMaxFileSelectionExceed()) {
          // Validate Max File Selection - Exceeds 5 File
          selectedFilesList.forEach((element: any, index: any) => {
            element.remove();
          });
          this.fileSourceList = [];
          this.removeUploadedDocumentFromList =
            this.removeUploadedDocumentFromList.filter(
              (item: any) =>
                !selectedFilesList.some((file: any) => file.fileName === item)
            );
          this.resetFiles();
          this.notifyReason = 'inValidFileSelection';
          this.showMsg();
        } else if (this.isFileSizeExceed()) {
          // Validate Total File Size Exceeds 100 MB
          this.fileSourceList = this.fileSourceList.filter(
            (item: any) =>
              !selectedFilesList.some(
                (file: any) => file.fileName === item.file.name
              )
          );
          this.notifyReason = 'FileSizeExceeds100MB';
          this.showMsg();
        } else {
          if (this.isSigleFileMaxSizeExceed()) {
            // Validate single file exceeds 50 MB (Allow)
            this.fileSourceList = this.fileSourceList.filter(
              (item: any) =>
                !selectedFilesList.some(
                  (file: any) => file.fileName === item.file.name
                )
            );
            this.notifyReason = 'inValidFileSize';
            this.showMsg();
          }
          if (!this.isFileNameValidLength()) {
            // Validate filename length Exceeds 150
            this.fileSourceList = this.fileSourceList.filter(
              (item: any) =>
                !selectedFilesList.some(
                  (file: any) => file.fileName === item.file.name
                )
            );
            this.notifyReason = 'FileNameExceeds150Char';
            this.showMsg();
          }
          if (!this.isFileExtensionValid()) {
            // Validate File Extension
            this.fileSourceList = this.fileSourceList.filter(
              (item: any) =>
                !selectedFilesList.some(
                  (file: any) => file.fileName === item.file.name
                )
            );
            this.notifyReason = 'inValidFileType';
            this.showMsg();
          }
          if (this.isFileNameExist()) {
            // Validate Duplicate File
            this.notifyReason = 'FileExist';
            this.showMsg();
          }
          if (this.fileSourceList.length > 0) {
            this.uploadDraggedAndSelectedDocument();
          }
        }
      }
      selectedFilesList.forEach((element: any, index: any) => {
        element.remove();
      });
      this.resetFiles();
    } else {
      this.loadUserTriggeredFilesLength = 0;
    }
  }
  loadFileSourceData(fileSource: any) {
    fileSource.forEach((element: any) => {
      this.removeUploadedDocumentFromList.push(element.fileName);
    });
  }
  removePreviousUploadDocument(selectedFilesList: any) {
    if (this.removeUploadedDocumentFromList.length == 0) {
      this.loadFileSourceData(selectedFilesList);
      this.fileSourceList = selectedFilesList;
    } else {
      var tempList = selectedFilesList.filter(
        (element: any, index: any) =>
          this.removeUploadedDocumentFromList.indexOf(element.fileName) === -1
      );
      tempList.forEach((element: any) => {
        this.fileSourceList.push(element);
      });
      if (
        this.loadUserTriggeredFilesLength == selectedFilesList.length &&
        tempList.length != selectedFilesList.length
      ) {
        this.notifyReason = 'FileExist';
        this.showMsg();
      }
      this.loadFileSourceData(tempList);
    }
  }
  valueChange(row: any) {
    let tempData = this.documentUploadData.some(
      (obj: any) =>
        obj.fileName.length == 0 ||
        obj.clientMilestoneDocumentTypeID.toString() == 0
    );
    this.isDocumentExists = tempData === true ? false : true;
  }
  onFileNameEdit(row: any) {
    row.isFileNameInEditMode = true;
  }

  resetFiles() {
    this.fileList.forEach((element: any, index: any) => {
      element.remove();
    });
  }

  onClientAgreementDocumentUpload(attachmentId: number): void {
    this.progressIndicator.show();
    var agreementRequest: ClientAgreementRequest = {
      clientNumber: this.clientNumber,
      attachmentId: attachmentId,
      description: this.commonHelperService.trimPrefixEmptySpace(this.description),
      documentTypeId: Number(this.selectedDocumentType),
      documentName: this.uploadedDocumentFilename,
      documentUrl: '',
      comments: this.commonHelperService.trimPrefixEmptySpace(this.comments),
      expirationDate: this.documentExpirationDate?.toDateString() ?? null,
      isNoExpiration: this.noExpirationDate,
      isVisibleToAllClientSubsidiaries: this.documentVisibility === 'yes',
      clientDocumentId: 0,
    };
    if (this.isEditMode) {
      agreementRequest.clientDocumentId =
        this.clientAgreementDocument?.clientDocumentId ?? 0;
      this.ncaDocService
        .updateClientAgreementDocument(agreementRequest)
        .subscribe(
          (result: any) => {
            if (result.isSuccess) {
              this.closeModal();
              this.sharedService.showToastNotification('Record updated');
            } else {
              this.notifyReason = result.resultData;
              this.showMsg(undefined, 'Error');
            }
            this.progressIndicator.hide();
          },
          (error: any) => {
            this.notifyReason = error?.message;
            this.showMsg(undefined, 'Error');
            this.progressIndicator.hide();
          }
        );
    } else {
      this.ncaDocService.addClientAgreementDocument(agreementRequest).subscribe(
        (result: any) => {
          this.progressIndicator.hide();
          this.closeModal();
          this.sharedService.showToastNotification('Record added');
        },
        (error: any) => {
          this.notifyReason = error?.error;
          if (this.notifyReason === 'Duplicate document name') {
            this.notifyReason = `Document "${agreementRequest.documentName}" already exists. Visit the <a href="client/${agreementRequest.clientNumber}/parent">Parent Client tab</a>  to verify it is the correct document.`;
          }
          this.showMsg();
          this.progressIndicator.hide();
        }
      );
    }
  }

  // Client Agreement Attachment Popup

  selectOptions = selectDocumentTypeOptions;
  tooltipOptions = fileTooltipOptions;
  inputOptions = inputOptions;
  descriptionTextareaOptions = textareaOptions;
  popupTypeEnum = PopupType;
  popupTitle: string = '';
  documentTypeItemsList: SelectItemOptions[] = [];

  datepickerOptions = datepickerOptions;
  checkboxOptions = checkboxOptions;
  radioGroupOptions = radioGroupOptions;
  commentsTextareaOptions = textareaOptions;

  radioBtns: RadioButton[] = [
    {
      options: {
        label: 'Yes',
        theme: Themes.green,
      },
      value: 'yes',
    },
    {
      options: {
        label: 'No',
        theme: Themes.green,
      },
      value: 'no',
    },
  ];

  selectedDocumentType: string = '';
  description: string = '';
  comments: string = '';
  uploadedDocumentFilename: string = '';
  noExpirationDate: boolean = false;
  documentVisibility: string = 'yes';
  documentExpirationDate: Date | null = null;
  isDocumentDetailsValid: boolean = false;
  canUpload: boolean = true;
  clientNumber: string = '';
  selectedDocumentTypeName: string = '';

  documentTypeSelectionTooltip: string =
    'Select the type of document being uploaded from the dropdown';
  documentVisibilityTitle: string =
    'This document applies to all client subsidiaries';

  selectItems: SelectItemOptions[] = [];

  onDocumentDescriptionChanged(): void {
    if (!this.description) {
      this.descriptionTextareaOptions.isRequired = true;
    } else {
      this.descriptionTextareaOptions.isRequired = false;
    }
    this.validateDocumentDetails();
  }

  onDocumentTypeChanged(): void {
    this.progressIndicator.show();
    if (!this.selectedDocumentType) {
      this.selectDocumentTypeOptions.isRequired = true;
    } else {
      this.selectDocumentTypeOptions.isRequired = false;
    }
    this.ncaDocService
      .getClientAgreementDocumentCount(
        this.clientNumber,
        Number(this.selectedDocumentType)
      )
      .subscribe({
        next: (result: number) => {
          this.canUpload = result === 0;
          this.selectedDocumentTypeName =
            this.selectItems.find(
              (item: SelectItemOptions) =>
                item.value === this.selectedDocumentType
            )?.heading ?? '';
        },
        error: (err: any) => {
          this.progressIndicator.hide();
        },
        complete: () => {
          this.progressIndicator.hide();
        },
      });
    this.validateDocumentDetails();
  }

  onNoExpirationStateChanged(): void {
    this.datepickerOptions.disabled = this.noExpirationDate;
    this.documentExpirationDate = this.noExpirationDate
      ? null
      : this.documentExpirationDate;
    this.datepickerOptions.isRequired =
      !this.noExpirationDate && !this.documentExpirationDate;
    this.validateDocumentDetails();
  }

  onDoucmentVisibilityStateChanged(): void {
    this.validateDocumentDetails();
  }

  onFilenameValueChange(): void {
    this.validateFileExtensionOnDocumentFilename();
    this.validateDocumentDetails();
  }

  onFilenameValueBlur(): void {
    this.validateFileExtensionOnDocumentFilename();
    this.validateDocumentDetails();
  }

  onDateChanged(selectedDate: Date | string): void {
    this.documentExpirationDate = selectedDate as Date;
    if (!this.documentExpirationDate) {
      this.datepickerOptions.isRequired = true;
    } else {
      this.datepickerOptions.isRequired = false;
    }
    this.validateDocumentDetails();
  }

  validateFileExtensionOnDocumentFilename(): void {
    var extension = this.uploadedDocumentFilename.split('.').pop();
    if (
      extension &&
      !this.validFileExtensions
        .toString()
        .toLowerCase()
        .includes(extension.toString().toLowerCase())
    ) {
      // Validate File Extension
      this.notifyReason = 'inValidFileType';
      this.showMsg();
    }
  }

  validateDocumentDetails(): void {
    const isFilenameValid = this.isEditMode ? this.uploadedDocumentFilename.length > 0 : this.uploadFileSourceList.length>0 && this.uploadedDocumentFilename.length > 0;
    const isDocumentTypeValid = Number(this.selectedDocumentType) > 0;
    const isDescriptionValid = this.description.length > 0;
    const isExpirationValid =
      this.noExpirationDate || this.documentExpirationDate !== null;
    const isVisibilityValid =
      this.documentVisibility === 'yes' || this.documentVisibility === 'no';
    this.isDocumentDetailsValid =
      isFilenameValid &&
      isDocumentTypeValid &&
      isDescriptionValid &&
      isExpirationValid &&
      isVisibilityValid;
  }
  //Client Agreement Attachment Popup Ends
}
