<div class="margin-top-xxs relative">
  <label
    *ngIf="showLabel"
    class="label comments-text-label eclipse-small-header-grey"
    >{{ labelText }}</label
  >
  <div class="search-box" *ngIf="!showSelected">
    <ng-container *ngIf="maxLength>0; else searchInput">
      <dds-search
          [size]="searchOptions.size ?? emptyString"
          [customClass]="searchOptions.customClass ?? emptyString"
          [icon]="searchOptions.icon || 'dds-icon_search'"
          [disabled]="isDisabled"
          (fieldCleared)="fieldCleared()"
          [placeholder]="searchOptions.placeholder ?? emptyString"
          [(ngModel)]="searchedUser"
          (ngModelChange)="findUsers($event)"
          [maxLength]="maxLength"
    > </dds-search>
    </ng-container>
    <ng-template #searchInput>
      <dds-search
        [size]="searchOptions.size ?? emptyString"
        [customClass]="searchOptions.customClass ?? emptyString"
        [icon]="searchOptions.icon || 'dds-icon_search'"
        [disabled]="isDisabled"
        (fieldCleared)="fieldCleared()"
        [maxLength]="maxLength"
      >
        <input
          ddsSearch
          [placeholder]="searchOptions.placeholder ?? emptyString"
          [(ngModel)]="searchedUser"
          (ngModelChange)="findUsers($event)"
          [disabled]="isDisabled"
        />
      </dds-search>
    </ng-template>
  </div>
  <div
    *ngIf="showdropDown"
    class="list-of-oppportunities d-flex flex-column padding-xs margin-top-xs absolute box-shadow-xs"
    (scroll)="fetchOpportunitySearchResultMore($event)"
    >
    <div *ngIf="userList.length > 0; else noUsers">
      <div *ngFor="let item of userList">
        <div
          class="opportunity-items d-flex flex-column"
          (click)="fetchUserDetails(item)">
          <div class="opportunity-details text-align-left" >
            <span class="opportunity-details-name"
              >{{ item?.displayName ?? item?.fullName ?? item?.outlookDisplayName ?? item?.name }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noUsers>
      <div class="no-users-found">
        {{ notFound }}
      </div>
    </ng-template>
  </div>
</div>
