import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import { ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { capitalizeFirstLetter } from '../../../../intake/common/intake.util';
import { ExternalCommunicationValues } from '../../../constants/external_communication_communication_attributes_enum';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';

export const RADIO_GROUP_OPTIONS_FOR_ALCOHOL_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'AlcoholRelatedContent',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Does this communication include:\n
- Alcohol related content\n
- Mentions of sweepstakes\n
- Award announcements`,
    isRequired: true,
    isInverse: false,
  };

export const YES_NO_RELATED_CONTENT_RADIO_GROUP_VALUE: RadioButton[] = [
  {
    options: {
      label: capitalizeFirstLetter(ExternalCommunicationValues.YES),
      theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
      disabled: undefined,
    },
    value: ExternalCommunicationValues.YES,
  },
  {
    options: {
      label: capitalizeFirstLetter(ExternalCommunicationValues.NO),
      theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: ExternalCommunicationValues.NO,
  },
];

export const RADIO_GROUP_OPTIONS_FOR_PODCAST_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'PodcastRelatedContent',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Does the podcast involve participation by parties other than Deloitte?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_OTHER_PARTIES_PARTICIPATION_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'videoInvolvesOtherParties',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Does the video involve participation by parties other than Deloitte?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_LIVE_STREAM_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isLiveStreamed',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Will the video be live streamed?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_PUBLISHED_IN_THIRD_PARTY_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isPublishedInThirdParty',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Will this communication be published in a third-party publication or website (not including social media)?`,
    isRequired: true,
    isInverse: false,
  };

  export const RADIO_GROUP_OPTIONS_FOR_SUBMISSION_RELATED_TO_CONFERENCE: RadioGroupOptions =
  {
    name: 'isrelatedtoconference',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Does this submission relate to a conference?`,
    isRequired: false,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_SPONSORSHIP_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isUsingSponsorOrThirdPartyTemplate',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Are you using the Deloitte sponsorship template or a third-party agreement?`,
    isRequired: true,
    isInverse: false,
  };

export const SPONSORSHIP_RELATED_CONTENT_RADIO_GROUP_VALUE: RadioButton[] = [
  {
    options: {
      label: 'Deloitte template',
      theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: 'Deloitte template',
  },
  {
    options: {
      label: 'Third Party Agreement',
      theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: 'Third Party Agreement',
  },
];

export const RADIO_GROUP_OPTIONS_FOR_SURVEY_EXTERNAL_PARTIES_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isSurveyExternalParties',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Does this communication involve conducting a survey of external parties?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_TYPE_OF_SURVEY_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'typeOfSurvey',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Is this a Deloitte-owned survey or will we be conducting a joint survey with a third party?  If a third party is involved, please include the OGC-approved survey agreement.`,
    isRequired: true,
    isInverse: false,
  };

export const TYPE_OF_SURVEY_CONTENT_RADIO_GROUP_VALUE: RadioButton[] = [
  {
    options: {
      label: 'Deloitte-owned survey',
      theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: 'Deloitte-owned survey',
  },
  {
    options: {
      label:
        'Third-party join survey (Attach the OGC-approved survey agreement)',
      theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: 'Third-party join survey (Attach the OGC-approved survey agreement)',
  },
];

export const RADIO_GROUP_OPTIONS_FOR_SURVEY_TOOL_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'typeOfSurvey2',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Are you using a Deloitte survey tool or a third party survey tool?`,
    isRequired: true,
    isInverse: false,
  };

export const TYPE_OF_SURVEY_2_CONTENT_RADIO_GROUP_VALUE: RadioButton[] = [
  {
    options: {
      label: 'Deloitte survey tool',
      theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: 'Deloitte survey tool',
  },
  {
    options: {
      label: 'Third Party survey tool',
      theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: 'Third Party survey tool',
  },
];

export const RADIO_GROUP_OPTIONS_FOR_LCSP_SURVEY_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'lcspSurveyApproval',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Have all LCSPs provided approval for their clients to be surveyed?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_BENCHMARKS_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'producingBenchMarksFor',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Are you producing individual benchmark reports for surveyed companies or will the final be published on deloitte.com only?`,
    isRequired: true,
    isInverse: false,
  };

export const BENCHMARK_RELATED_CONTENT_RADIO_GROUP_VALUE: RadioButton[] = [
  {
    options: {
      label: 'Individual benchmarks reports',
      theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
      disabled: undefined,
    },
    value: 'Individual benchmarks reports',
  },
  {
    options: {
      label: 'Only published on Deloitte.com',
      theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: 'Only published on Deloitte.com',
  },
];

export const RADIO_GROUP_OPTIONS_FOR_INCENTIVE_SURVEY_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isIncentiveForSurvey',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Are you offering an incentive for survey participation?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_SWEEPSTAKES_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isInvolvesSweepstakes',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Does this communication involve sweepstakes or a contest (e.g., giveaway, raffles)?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_DIVERSITY_EQUITY_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isDiversityEquity',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Is the contest a Diversity and Inclusion event?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_INTERNAL_EXTERNAL_CONTEST_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isInternalExternalContest',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Is the sweepstakes an internal or external contest?`,
    isRequired: true,
    isInverse: false,
  };

export const INTERNAL_EXTERNAL_CONTEST_RELATED_CONTENT_RADIO_GROUP_VALUE: RadioButton[] =
  [
    {
      options: {
        label: ' Internal contest',
        theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
        disabled: undefined,
      },
      value: 'Internal contest',
    },
    {
      options: {
        label: 'External contest',
        theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
      },
      value: 'External contest',
    },
  ];

export const RADIO_GROUP_OPTIONS_FOR_INTERNAL_CONTEST_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isLessThanPriceThreshold',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Is the value of the individual prize less than $500 in total?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_EXTERNAL_CONTEST_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'hasWorkedWithOGC',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Have you worked with OGC on contest rules?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_EXTERNAL_SPEAKER_AGREEMENT_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isAskedSignSpeakerAgreement', //TODO: Modify this name once the db name is available
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Have you been asked to sign an external speaker's agreement or publication consent form?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_IS_RECORDED_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isRecorded',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Will this communication be audio and/or video recorded?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_CLIENT_USECASE_SUCCESS_STORIES_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isClientUseCaseAndSuccessStories',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Does this communication involve client use cases or success stories?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_RELATED_TO_SPONSORSHIP_RELATED_CONTENT: RadioGroupOptions =
  {
    name: 'isRelatedToSponsorship',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: `Does this communication relate to a Sponsorship?`,
    isRequired: true,
    isInverse: false,
  };

export const RADIO_GROUP_OPTIONS_FOR_DEMO_RELATED_CONTENT: RadioGroupOptions = {
  name: 'isLabOrDemo',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  title: `Does this communication involve a Lab or a demo of a product or tool?`,
  isRequired: true,
  isInverse: false,
};
