import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  docTableColumns,
  documentTitleTooltipOptions,
} from '../engagement-submission.helper';
import { GlobalDocumentTableModel } from '../../../../common/models/global-engagement.model';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { GlobalEngagementService } from '../../../../http/global-engagement/global-engagement.service';
import { DocumentService } from '../../../../http/document/document.service';
import { Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { EngagementDocumentEditComponent } from '../engagement-document-edit/engagement-document-edit.component';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { GlobalEngagementsConstant } from '../../../constants/global-engagements.constants';
import { PermissionsObj } from '../../../../common/models/common-models';

@Component({
  selector: 'app-engagement-table',
  templateUrl: './engagement-table.component.html',
  styleUrl: './engagement-table.component.less',
})
export class EngagementTableComponent {
  @Input() submissionId: number | null | undefined = null;
  @Input() submissionTypeId: number | null | undefined = null;
  @Input() submissionStatusId: number = 0;
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;

  @Output() onDocumentChange = new EventEmitter<boolean>();

  tableColumns = docTableColumns;
  tooltipOptions: TooltipOptions = documentTitleTooltipOptions;
  readonly #unsubscriber$: Subject<void> = new Subject<void>();

  docMetaData: GlobalDocumentTableModel[] = [];
  constructor(
    private readonly globalEngagementService: GlobalEngagementService,
    private readonly documentService: DocumentService,
    private readonly modalService: ModalService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submissionId']) {
      this.getDocumentMetaData();
    }
  }

  getDocumentMetaData(): void {
    this.globalEngagementService
      .getGlobalDocumentTable(Number(this.submissionId))
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (data: GlobalDocumentTableModel[]) => {
          this.docMetaData = data;
          this.checkValidDocument();
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error in fetching document data', err);
        },
      });
  }

  onDownload(event: GlobalDocumentTableModel): void {
    if (
      this.permissionObj['isGlobalEngSubmissionDetailsDocumentsTableEnable']
    ) {
      let downloadRequest = {
        DocumentName: event.documentTitle,
        DocumentKey: event.attachmentGuid,
        FileFolderPath: 'Intake',
      };
      this.documentService.getDownloadUrl(downloadRequest).subscribe({
        next: (response: any) => {
          if (response != null && response.documentDownloadUrl != null) {
            window.open(response.documentDownloadUrl, '_blank');
          }
        },
        error: (err: any) => {},
      });
    }
  }

  onOpenLink(item: GlobalDocumentTableModel): void {
    if (
      this.permissionObj['isGlobalEngSubmissionDetailsDocumentsTableEnable'] &&
      item
    ) {
      window.open(String(item.documentUrl), '_blank');
    }
  }

  onEdit(item: GlobalDocumentTableModel, modalBtn?: any): void {
    if (
      this.permissionObj['isGlobalEngSubmissionDetailsDocumentsTableEnable']
    ) {
      let modelRef = this.modalService.open(EngagementDocumentEditComponent, {
        documentInitiatedDetails: {
          documentTitle: item.documentTitle,
          comments: item.comments,
          documentTypeId: item.documentTypeId,
          documentStatusId: item.documentStatusId,
          submissionTypeId: this.submissionTypeId,
          submissionStatusId: this.submissionStatusId,
          attachmenyId: item.attachmentGuid,
          submissionDocumentTypeId: item.submissionDocumentTypeId,
        },
      });

      modelRef
        .onClosed()
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe(() => {
          this.getDocumentMetaData();
          if (modalBtn) {
            modalBtn.focus();
          }
        });
    }
  }

  onDelete(item: any): void {
    if (
      this.permissionObj['isGlobalEngSubmissionDetailsDocumentsTableEnable']
    ) {
      this.globalEngagementService
        .deleteGlobalDocument(item.submissionDocumentId)
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe({
          next: () => {
            this.getDocumentMetaData();
          },
          error: (err: HttpErrorResponse) => {
            // TODO: Getting 200 status with parsing error of message. Check with backend
            if (err?.status === 200) {
              this.getDocumentMetaData();
            } else console.error('Error in delete document data', err);
          },
        });
    }
  }

  checkValidDocument(): void {
    const validDoc = this.docMetaData.some(
      (item: GlobalDocumentTableModel) =>
        item.documentStatusId == GlobalEngagementsConstant.archivedStatus
    );
    this.onDocumentChange.emit(validDoc);
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
