<div [autosave-module]="saveComplexityModule">
  <form
    [formGroup]="riskAssessmentForm"
    class="complexity-question-container clear-both margin-top-m margin-bottom-es text-align-left"
  >
    <div class="margin-top-m">
      <span
        *ngIf="isFieldFilled('IsCrossBusinessGlobal')"
        class="icon-required-yellow inline-block"
      ></span>
      <span class="text-label">
        Does this communication involve other Deloitte entities, either
        cross-business or global?
      </span>

      <div class="padding-top-xs value-box d-flex">
        <dds-radio-group
          [name]="isCrossBusinessGlobalGroupOptions.name ?? emptyString"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? emptyString"
          formControlName="IsCrossBusinessGlobal"
          (stateChanged)="checkRequiredFields()"
          [disabled]="true"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? emptyString"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? emptyString"
              [theme]="radio.options.theme ?? emptyString"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <div class="margin-top-s" *ngIf="showMultiSelect">
      <span
        *ngIf="isFieldFilled('BusinessOrMemberFirm')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        Specify the business or other Member firm (e.g. Audit & Assurance, Tax,
        Deloitte UK):
      </span>
      <div class="padding-top-xs value-box d-flex">
        <dds-multi-select
          class="box-size"
          [disabled]="multiSelectOptions.disabled || !isControlEnable"
          [isResponsive]="multiSelectOptions.isResponsive ?? false"
          [isRequired]="multiSelectOptions.isRequired ?? false"
          [label]="multiSelectOptions.label ?? emptyString"
          [size]="htmlElementSize"
          [placeholder]="multiSelectOptions.placeholder ?? emptyString"
          [type]="multiSelectOptions.type ?? emptyString"
          [controlType]="multiSelectOptions.controlType ?? emptyString"
          [theme]="multiSelectOptions.theme ?? emptyString"
          [isInverse]="multiSelectOptions.isInverse ?? false"
          [list]="memberFirm"
          formControlName="BusinessOrMemberFirm"
        >
        </dds-multi-select>
        <div *ngIf="showBusinessOrMemberFirm">
          <span class="comments-text-label eclipse-small-header-grey"
            >Selected values:</span
          >
          <dds-tags>
            <dds-tag
              class="tag-width eclipse-header-grey"
              *ngFor="
                let item of this.riskAssessmentForm.get('BusinessOrMemberFirm')
                  ?.value
              "
              [theme]="options.theme ?? emptyString"
              [isRemovable]="options.isRemovable ?? false"
              [disabled]="options.disabled || !isControlEnable"
              [customClass]="options.customClass ?? emptyString"
              [size]="htmlElementSize"
              [isError]="options.isError ?? false"
              [isInverse]="options.isInverse ?? false"
              (removed)="removeMultiSelectItemBusinessOrMemberFirm(item)"
              #tagItems
            >
              {{ item.label }}
            </dds-tag>
          </dds-tags>
        </div>
      </div>
    </div>

    <div class="margin-top-s" *ngIf="showTextAreaForm">
      <span
        *ngIf="isFieldFilled('MemberFirmOther')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label"> Please specify the Member Firm(s): </span>
      <div class="padding-top-xs value-box d-flex">
        <dds-input
        [disabled]= "!isControlEnable"
          formControlName="MemberFirmOther"
          [placeholder]="textareaOptions.placeholder ?? emptyString"
          [maxLength]="textareaOptions.maxLength ?? 0"
          [maxLengthPosition]="textareaOptions.maxLengthPosition ?? emptyString"
          [size]="htmlElementSize"
          onchange="checkRequiredFields()"
        >
        </dds-input>
      </div>
    </div>

    <div class="margin-top-m">
      <span
        *ngIf="isFieldFilled('IsGenAI')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        Was this communication created using Generative (Gen) AI?
      </span>

      <div class="padding-top-xs value-box d-flex">
        <dds-radio-group
          [disabled]= "!isControlEnable"
          [name]="isGenAIGroupOptions.name ?? emptyString"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? emptyString"
          formControlName="IsGenAI"
          (stateChanged)="checkRequiredFields()"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? emptyString"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? emptyString"
              [theme]="radio.options.theme ?? emptyString"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <div class="margin-top-s">
      <span
        *ngIf="isFieldFilled('CommunicationContains')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        Does this communication contain any content that includes:
      </span>
      <div class="padding-top-xs value-box d-flex">
        <dds-multi-select
          class="box-size"
          [isResponsive]="multiSelectOptions.isResponsive ?? false"
          [isRequired]="multiSelectOptions.isRequired ?? false"
          [label]="multiSelectOptions.label ?? emptyString"
          [size]="htmlElementSize"
          [placeholder]="multiSelectOptions.placeholder ?? emptyString"
          [type]="multiSelectOptions.type ?? emptyString"
          [controlType]="multiSelectOptions.controlType ?? emptyString"
          [theme]="multiSelectOptions.theme ?? emptyString"
          [isInverse]="multiSelectOptions.isInverse ?? false"
          [list]="CommunicationContains"
          formControlName="CommunicationContains"
          [disabled]= "!isControlEnable"
          [stickerMaxHeight]="'50vh'"
        >
        </dds-multi-select>
        <div *ngIf="showCommunicationContains">
          <span class="comments-text-label eclipse-small-header-grey"
            >Selected values:</span
          >
          <dds-tags>
            <dds-tag
              class="tag-width"
              *ngFor="
                let item of this.riskAssessmentForm.get('CommunicationContains')
                  ?.value
              "
              [theme]="options.theme ?? emptyString"
              [isRemovable]="options.isRemovable ?? false"
              [disabled]="options.disabled || !isControlEnable"
              [customClass]="options.customClass ?? emptyString"
              [size]="htmlElementSize"
              [isError]="options.isError ?? false"
              [isInverse]="options.isInverse ?? false"
              (removed)="removeMultiSelectItemCommContain(item)"
              #tagItems
            >
              {{ item.label }}
            </dds-tag>
          </dds-tags>
        </div>
      </div>
    </div>

    <div class="margin-top-m">
      <span
        *ngIf="isFieldFilled('IsNewNascentEvolvProducts')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        Does this communication involve new products, tools, platforms or
        frameworks?
      </span>

      <div class="padding-top-xs value-box d-flex">
        <dds-radio-group
          [name]="isNewNascentEvolvProductsGroupOptions.name ?? emptyString"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? emptyString"
          formControlName="IsNewNascentEvolvProducts"
          (stateChanged)="checkRequiredFields()"
          [disabled]= "!isControlEnable"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? emptyString"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? emptyString"
              [theme]="radio.options.theme ?? emptyString"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <div class="margin-top-m">
      <span
        *ngIf="isFieldFilled('IsSensitiveMatter')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        Does this communication involve a sensitive matter (e.g., politics,
        immigration, diversity, equity, & inclusion imperatives, racial
        injustice, social movement matters)?
      </span>

      <div class="padding-top-xs value-box d-flex">
        <dds-radio-group
          [name]="isSensitiveMatterGroupOptions.name ?? emptyString"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? emptyString"
          formControlName="IsSensitiveMatter"
          (stateChanged)="checkRequiredFields()"
          [disabled]= "!isControlEnable"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? emptyString"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? emptyString"
              [theme]="radio.options.theme ?? emptyString"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <div class="margin-top-m">
      <span
        *ngIf="isFieldFilled('IsThirdPartyInvolved')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        Is there a third party involved (e.g., as an external speaker, involved
        in the creation of this communication, or co-branded)?
      </span>

      <div class="padding-top-xs value-box d-flex">
        <dds-radio-group
          [name]="isThirdPartyInvolvedGroupOptions.name ?? emptyString"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? emptyString"
          formControlName="IsThirdPartyInvolved"
          (stateChanged)="checkRequiredFields()"
          [disabled]= "!isControlEnable"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? emptyString"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? emptyString"
              [theme]="radio.options.theme ?? emptyString"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <div class="margin-top-m" *ngIf="showThirdPartyInvolved">
      <span
        *ngIf="isFieldFilled('IsTrpmApprovalEmailIncluded')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        Have you included the approval email from the Third Party Risk
        Management (TPRM) Gateway?
      </span>

      <div class="padding-top-xs value-box d-flex">
        <dds-radio-group
          [name]="IsTPRMApprovalEmailIncludedOptions.name ?? emptyString"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? emptyString"
          formControlName="IsTrpmApprovalEmailIncluded"
          (stateChanged)="checkRequiredFields()"
          [disabled]= "!isControlEnable"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? emptyString"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? emptyString"
              [theme]="radio.options.theme ?? emptyString"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <div class="margin-top-m">
      <span
        *ngIf="isFieldFilled('IsGovernmentRegulationInvolved')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        Does this communication involve government regulation, guidance, or
        rulemaking?
      </span>

      <div class="padding-top-xs value-box d-flex">
        <dds-radio-group
          [name]="isGovernmentRegulationInvolvedGroupOptions.name ?? emptyString"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? emptyString"
          formControlName="IsGovernmentRegulationInvolved"
          (stateChanged)="checkRequiredFields()"
          [disabled]= "!isControlEnable"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? emptyString"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? emptyString"
              [theme]="radio.options.theme ?? emptyString"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <div class="margin-top-m" *ngIf="showAnalayzeRegulation">
      <span
        *ngIf="isFieldFilled('IsAnalyzeRegulation')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        Does the communication go beyond a summarization of the regulation and
        analyze the impacts of or make recommendations about the effects of the
        new or updated regulation?
      </span>

      <div class="padding-top-xs value-box d-flex">
        <dds-radio-group
          [name]="IsAnalayzeRegulationOptions.name ?? emptyString"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? emptyString"
          formControlName="IsAnalyzeRegulation"
          (stateChanged)="checkRequiredFields()"
          [disabled]= "!isControlEnable"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? emptyString"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? emptyString"
              [theme]="radio.options.theme ?? emptyString"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <div class="margin-top-m" *ngIf="showDiscussServicesToAssistWithReg">
      <span
        *ngIf="isFieldFilled('IsDiscussServicesToAssistWithReg')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        Does this communication discuss services that we could provide to assist
        with the new or updated regulation?
      </span>
      <div class="padding-top-xs value-box d-flex">
        <dds-radio-group
          [name]="IsDiscussServicesToAssistWithRegOptions.name ?? emptyString"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? emptyString"
          formControlName="IsDiscussServicesToAssistWithReg"
          (stateChanged)="checkRequiredFields()"
          [disabled]= "!isControlEnable"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? emptyString"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? emptyString"
              [theme]="radio.options.theme ?? emptyString"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <div class="margin-top-m">
      <span
        *ngIf="isFieldFilled('IsSpeakingOnPanel')"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        Does this submission involve speaking on a panel?
      </span>

      <div class="padding-top-xs value-box d-flex">
        <dds-radio-group
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? emptyString"
          formControlName="IsSpeakingOnPanel"
          (stateChanged)="checkRequiredFields()"
          [disabled]= "!isControlEnable"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? emptyString"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? emptyString"
              [theme]="radio.options.theme ?? emptyString"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>
  </form>
</div>
