import { FilterItem } from '@usitsdasdesign/dds-ng/filter';
import {
  SelectItemOptions,
  SelectOptions,
} from '@usitsdasdesign/dds-ng/select';
import {
  ButtonKind,
  Column,
  ErrorState,
  ExtThemes,
  LabelPosition,
  Size,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { AdminConstant } from '../../constants/admin-constants';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';

export const selectOptions: SelectOptions = {
  label: 'Table name',
  labelPosition: LabelPosition.external,
  size: AdminConstant.UI_ELEMENT_SIZE,
  placeholder: AdminConstant.EMPTY_SPACE,
  disabled: false,
  description: AdminConstant.EMPTY_SPACE,
};

export const options: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  size: Size.md,
  width: WidthState.fixed,
  isLoading: false,
  icon: AdminConstant.EMPTY_SPACE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'CancelButton',
  customClass: AdminConstant.EMPTY_SPACE,
  role: 'button',
};

export const searchOptionsCtrl: SearchOptions = {
  placeholder: 'Placeholder',
  ariaLabel: 'searchInput',
  size: Size.md,
  isInverse: false,
  customClass: AdminConstant.EMPTY_SPACE,
  isTrimValue: false,
  readonly: false,
  maxLength: 0,
};

export const selectItems: SelectItemOptions[] = [
  {
    heading: 'Market Offering Configuration',
    value: 'marketOfferingConfiguration',
  },
  {
    heading: 'Splash Page',
    value: 'splashPage',
  },
  {
    heading: 'ExtComm Training',
    value: 'adminExtCommTrainingTable',
  }
];

export const sampleColumns: GridColumn[] = [
  {
    name: 'editIcon',
    header: AdminConstant.EMPTY_SPACE,
    dataType: 'string',
    minWidth: '2.75rem',
    filterable: false,
    width: '2.75rem',
  }, 
  {
    name: 'activationStatus',
    header: 'Activation status',
    dataType: 'string',
    minWidth: '6.9375rem',
    filterable: false,
    width: '6.9375rem',
  },
  {
    name: 'offeringPortfolio',
    header: 'Offering portfolio',
    dataType: 'string',
    filterable: false,
    minWidth: '7.4375rem',
  },
  {
    name: 'offering',
    header: 'Offering',
    dataType: 'string',
    filterable: false,
    minWidth: '6.125rem',
  },
  {
    name: 'marketOffering',
    header: 'Market offering',
    dataType: 'Offering',
    filterable: false,
    minWidth: '8.75rem',
  },
  {
    name: 'excludedFromSelfReview',
    header: 'Excluded from self-review',
    dataType: 'string',
    minWidth: '3rem',
    filterable: false,
    width: '10rem',
  },
  {
    name: 'totalFeeThresholds',
    header: 'Total Fees threshold, $',
    dataType: 'string',
    filterable: false,
    minWidth: '8.75rem',
  },
  {
    name: 'reviewerAvailability',
    header: 'Tech Reviewer availability',
    dataType: 'string',
    filterable: false,
    minWidth: '8.1875rem',
  },
];

export const filterData: FilterItem[] = [
  {
    title: 'Activation Status',
    name: 'Activation Status',
  },
  {
    title: 'Offering Portfolio',
    name: 'Offering Portfolio',
  },
  {
    title: 'Offering',
    name: 'Offering',
  },
  {
    title: 'Market Offering',
    name: 'Market Offering',
  },
  {
    title: 'Excluded From Self-review',
    name: 'Excluded From Self-review',
  },
  {
    title: 'Total Fee threshholds,$',
    name: 'Total Fee threshholds,$',
  },
  {
    title: 'Reviewer availability',
    name: 'Reviewer availability',
  },
];
export interface GridColumn extends Column {
  filterable?: boolean;
  searchValue?: any;
}

export const btnOptions: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  size: AdminConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: AdminConstant.EMPTY_SPACE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Add Record',
  customClass: AdminConstant.EMPTY_SPACE,
  role: 'button',
};