import {
  Component,
  ViewChildren,
  QueryList,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  Column,
  SortOptions,
  SortingStates,
} from '@usitsdasdesign/dds-ng/shared';
import {
  StickerDirective,
  StickerOptions,
} from '@usitsdasdesign/dds-ng/sticker';
import { DaterangepickerOptions } from '@usitsdasdesign/dds-ng/datepicker';
import {
  dateRangePickerOptions,
  filterStickerOptions,
  auditTrailTableColumns,
} from './audit-trail-table.helper';
import { BrowserTabConstant } from '../../../common/constants/browser-tab-constant';
import { AuditTrailService } from '../../../http/audit-trail/audit-trail.service';
import {
  AuditTrailResponseDataModel,
  AuditTrailResponseModel,
  FilterType,
  HeaderDescriptionDataModel,
} from '../../../common/models/audit-trail.model';
import { Subject, takeUntil } from 'rxjs';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { IntakeConstant } from '../../../intake/constants/intake.constant';
import { HttpErrorResponse } from '@angular/common/http';
import { convertDate, convertTime } from '../../../intake/common/intake.util';
import { AuditTrailTableHeaderSearchParameter } from '../../constants/audit-trail.enum';
import { ExternalCommunicationConstant } from '../../../external-communications/constants/external_communication.constant';
import { GlobalEngagementService } from '../../../http/global-engagement/global-engagement.service';

export interface GridColumn extends Column {
  filterable?: boolean;
  searchValue?: any;
}

@Component({
  selector: 'app-audit-trail-table',
  templateUrl: './audit-trail-table.component.html',
  styleUrl: './audit-trail-table.component.less',
})
export class AuditTrailTableComponent {
  @ViewChildren('stickerDir') sticker!: QueryList<StickerDirective>;

  @Input() tableData!: AuditTrailResponseDataModel[];
  @Input() opportunityNumber!: string;
  @Input() submissionId!: number | null;
  @Input() isExtCommSubmission!: boolean;
  @Input() isGlobalEngagement!: boolean;
  @Output() headerData: EventEmitter<HeaderDescriptionDataModel> =
    new EventEmitter<HeaderDescriptionDataModel>();

  private readonly destroy$: Subject<void> = new Subject<void>();

  sampleColumns: Column[] = auditTrailTableColumns;

  constructor(
    private readonly auditService: AuditTrailService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly globalEngagementService: GlobalEngagementService
  ) {}

  dateRangePickerOptions: DaterangepickerOptions = dateRangePickerOptions;

  filterStickerOptions: StickerOptions = filterStickerOptions;

  pageNumber: number = 0;
  filterColumnCode: number = 3;
  isAscending: boolean = false;
  filterArray: FilterType[] = [];
  totalDataCount: number = 0;

  sortingState: SortOptions | null = {
    property: this.sampleColumns[2].name,
    descending: false,
    dataType: this.sampleColumns[2].dataType,
  };

  itemsPerPage: number = 5;
  itemsPerPageList: number[] = [5, 10, 25, 50, 100];
  isShownFilter!: boolean;
  filteringColumn!: GridColumn;

  public auditTrailTableCode = AuditTrailTableHeaderSearchParameter;

  descriptionLabel: string = IntakeConstant.DESCRIPTION_LABEL;

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_AuditTrail;
    this.refreshTable();
  }

  pageChanged(value: number): void {
    this.pageNumber = value;
    this.refreshTable();
  }

  toggleFilter(column: Column): void {
    this.filteringColumn = column;
    this.isShownFilter = true;
  }

  filterSorted(sortedState: string): void {
    this.sortingState = {
      dataType: this.filteringColumn.dataType,
      descending: sortedState === SortingStates.descending,
      property: this.filteringColumn.name,
    };
    this.isAscending = sortedState === SortingStates.ascending;
    this.filterColumnCode =
      this.auditTrailTableCode[
        this.filteringColumn
          .name as keyof typeof AuditTrailTableHeaderSearchParameter
      ];
    this.filterHide();
    this.refreshTable();
  }

  filterApply(searchValue: string | [Date, Date]): void {
    this.filterColumnCode =
      this.auditTrailTableCode[
        this.filteringColumn
          .name as keyof typeof AuditTrailTableHeaderSearchParameter
      ];
    let formattedCriteria = '';

    if (Array.isArray(searchValue)) {
      const [fromDate, toDate] = searchValue;
      const fromDateString = fromDate.toLocaleDateString(
        ExternalCommunicationConstant.DATESTRING_FORMAT_US
      );
      const toDateString = toDate.toLocaleDateString(
        ExternalCommunicationConstant.DATESTRING_FORMAT_US
      );
      formattedCriteria = `${fromDateString},${toDateString}`;
    } else if (typeof searchValue === 'string') {
      formattedCriteria = searchValue;
    }
    const existingFilterIndex = this.filterArray.findIndex(
      (filter) => filter.filtertypecode === this.filterColumnCode
    );
    if (
      typeof searchValue === 'string' &&
      searchValue.trim() === ExternalCommunicationConstant.EMPTY_SPACE
    ) {
      if (existingFilterIndex !== -1) {
        this.filterArray.splice(existingFilterIndex, 1);
      }
    } else {
      if (existingFilterIndex !== -1) {
        this.filterArray[existingFilterIndex].filtercriteria =
          formattedCriteria;
      } else {
        this.filterArray.push({
          filtertypecode: this.filterColumnCode,
          filtercriteria: formattedCriteria,
        });
      }
    }

    this.refreshTable();
    this.filterHide();
  }

  filterHide(): void {
    this.sticker.forEach((item) => {
      if (item.isActive) {
        item.hide();
      }
    });
  }

  filterOnHidden(): void {
    this.isShownFilter = false;
  }

  itemsPerPageChanged(value: number): void {
    this.pageNumber = 0;
    this.itemsPerPage = value;
    this.refreshTable();
  }

  filterSearchHandler(): void {
    if (this.filteringColumn.searchValue.length === 0) {
      this.filterApply(IntakeConstant.EMPTY_SPACE);
    }
  }

  refreshTable(): void {
    this.progressIndicator.show();
    const auditRequestParams = {
      PageNumber: this.pageNumber,
      pageSize: this.itemsPerPage,
      SortParameterCode: this.filterColumnCode,
      IsAscending: this.isAscending,
      FilterData: this.filterArray,
    };
    if (this.isExtCommSubmission) {
      if (this.submissionId !== null) {
        this.auditService
          .getExtCommAuditTrailTableData(this.submissionId, auditRequestParams)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (response: AuditTrailResponseModel) => {
              this.tableData = response.dataList;
              const headerDescription = {
                submissionTypeCode:
                  ExternalCommunicationConstant.EXT_COMM_SUBMISSION_TYPE_CODE,
                clientName: response?.dataList[0]?.clientName,
                submissionTitle: response?.dataList[0]?.submissionTitle,
                opportunityName: null,
              };
              this.headerData.emit(headerDescription);
              this.totalDataCount = response.totalDataCount;
              this.progressIndicator.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.progressIndicator.hide();
              console.error('Error fetching audit trail data', err);
            },
          });
      } else {
        console.error('submissionId is null');
      }
    } else if (this.isGlobalEngagement) {
      this.fetchGlobalEngagementAuditTrailData();
    } else {
      this.auditService
        .getAuditTrailTableData(
          this.opportunityNumber,
          this.submissionId,
          auditRequestParams
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: AuditTrailResponseModel) => {
            this.tableData = response.dataList;
            const headerDescription = {
              submissionTypeCode: response?.dataList[0].submissionTypeCode,
              clientName: response?.dataList[0].clientName,
              opportunityName: response?.dataList[0].opportunityName,
              submissionTitle: response?.dataList[0].submissionTitle,
            };
            this.headerData.emit(headerDescription);
            this.totalDataCount = response.totalDataCount;
            this.progressIndicator.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.progressIndicator.hide();
            console.error('Error fetching audit trail data', err);
          },
        });
    }
  }

  fetchGlobalEngagementAuditTrailData(): void {
    const auditRequestParams = {
      PageNumber: this.pageNumber,
      pageSize: this.itemsPerPage,
      SortParameterCode: this.filterColumnCode,
      IsAscending: this.isAscending,
      FilterData: this.filterArray,
    };
    this.globalEngagementService
      .getAuditTrailData(
        this.opportunityNumber,
        this.submissionId,
        auditRequestParams
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: AuditTrailResponseModel) => {
          this.tableData = response.dataList;
          const headerDescription = {
            submissionTypeCode: response?.dataList[0].submissionTypeCode,
            clientName: response?.dataList[0].clientName,
            opportunityName: response?.dataList[0].opportunityName,
            submissionTitle: response?.dataList[0].submissionTitle,
          };
          this.headerData.emit(headerDescription);
          this.totalDataCount = response.totalDataCount;
          this.progressIndicator.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('Error fetching audit trail data', err);
        },
      });
  }

  convertDate(data: string): string {
    return convertDate(data);
  }

  convertTime(data: string): string {
    return convertTime(data);
  }
}
