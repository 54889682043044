export class URLConstant{
    public static readonly COMMON_MARS_PATH: string = 'MARS/';
    public static readonly COMMON_NCA_PATH: string = 'NCA/';
    public static readonly COMMON_TPA_PATH: string = 'ThirdPartyAgreement/';

    public static readonly CONCAT_ADD_REMOVE_TEAMMEMBER_PARAM: string = '&teamMemberId=';
    public static readonly GET_MARS_NCA_FORM_DATA: string = URLConstant.COMMON_MARS_PATH+'GetMarsNCAFormData?clientMilestoneId=';
    public static readonly UPDATE_MARS_NCA_FORM_DATA: string = URLConstant.COMMON_MARS_PATH+'UpdateMarsNCAFormData';
    public static readonly ADD_OWNERSHIP_INTEREST: string = URLConstant.COMMON_MARS_PATH+'AddOwnershipInterest?clientMilestoneMarsNcaFormId=';
    public static readonly UPDATE_OWNERSHIP_INTEREST: string = URLConstant.COMMON_MARS_PATH+'UpdateOwnershipInterest';
    public static readonly DELETE_OWNERSHIP_INTEREST: string = URLConstant.COMMON_MARS_PATH+'DeleteOwnershipInterest?clientMilestoneMarsOwnershipInterestId=';
    public static readonly SUBMIT_TO_BUSINESS_LEADERSHIP: string = URLConstant.COMMON_NCA_PATH+'ClientStatus/SetNCAReset';
    public static readonly ADD_MARS_NCA_TEAM_MEMBER: string = URLConstant.COMMON_MARS_PATH+'AddMarsNcaTeamMember?clientMilestoneMarsNcaFormId=';
    public static readonly REMOVE_MARS_NCA_TEAM_MEMBER: string = URLConstant.COMMON_MARS_PATH+'RemoveMarsNcaTeamMember?clientMilestoneMarsNcaFormId=';
    public static readonly ADD_NCA_NOTIFICATION_TEAM_MEMBER: string = URLConstant.COMMON_NCA_PATH+'ClientStatus/AddNCANotificationTeamMember?clientMilestoneId=';
    public static readonly REMOVE_NCA_NOTIFICATION_TEAM_MEMBER: string = URLConstant.COMMON_NCA_PATH + 'ClientStatus/RemoveNCANotificationTeamMember?clientMilestoneId=';

    // Client Agreement Endpoints
    public static readonly COMMON_CLIENT_AGREEMENT_PATH: string = 'ClientAgreement/';
    public static readonly GET_CLIENT_AGREEMENT_DOCUMENTS: string = URLConstant.COMMON_CLIENT_AGREEMENT_PATH+'GetDocuments?clientNumber=#clientNumber#&hideExpiredDocuments=#hideExpiredDocuments#&pageNumber=#pageNumber#&pageSize=#pageSize#';
    public static readonly GET_CLIENT_AGREEMENT_DOCUMENT_COUNT: string = URLConstant.COMMON_CLIENT_AGREEMENT_PATH+'GetDocumentCount?clientNumber=#clientNumber#&documentTypeId=#documentTypeId#';
    public static readonly ADD_CLIENT_AGREEMENT: string = URLConstant.COMMON_CLIENT_AGREEMENT_PATH+'AddDocument';
    public static readonly UPDATE_CLIENT_AGREEMENT: string = URLConstant.COMMON_CLIENT_AGREEMENT_PATH+'UpdateDocument?documentId=';
    public static readonly DELETE_CLIENT_AGREEMENT: string = URLConstant.COMMON_CLIENT_AGREEMENT_PATH+'DeleteDocument?documentId=';
    public static readonly GET_CLIENT_AGREEMENT_DOCUMENT_UPLOAD_REQUEST_TEMPLATE: string = URLConstant.COMMON_CLIENT_AGREEMENT_PATH+'GetUploadRequestNotificationTemplate?clientNumber=#clientNumber#';

  //TPA Endpoint
  public static readonly GET_THIRD_PARTY_AGREEMENT_DATA: string = URLConstant.COMMON_TPA_PATH + 'GetThirdPartyAgreementData?thirdPartyAgreementID=';
  public static readonly GET_THIRD_PARTY_AGREEMENT_DOCUMENT_DATA: string = URLConstant.COMMON_TPA_PATH + 'GetThirdPartyAgreementDocumentData?thirdPartyAgreementID=';
  public static readonly GET_DROPDOWN_DATA: string = URLConstant.COMMON_TPA_PATH + 'GetDropDownData';
  public static readonly GET_DOCUMENTTYPE_BY_AGREEMENTTYPE_ID: string = URLConstant.COMMON_TPA_PATH + 'GetDocumentTypebyAgreementTypeId';
  public static readonly SAVE_AND_UPDATE_THIRD_PARTY_AGREEMENT: string = URLConstant.COMMON_TPA_PATH + 'SaveAndUpdateThirdPartyAgreement';
  public static readonly SAVE_AND_UPDATE_TPA_DOCUMENT_META_DATA: string = URLConstant.COMMON_TPA_PATH + 'SaveAndUpdateTPADocumentMetaData';
  public static readonly DELETE_THIRDPARTY_AGREEMENT_DATA: string = URLConstant.COMMON_TPA_PATH + 'DeleteThirdPartyAgreementData?thirdPartyAgreementID=';
  public static readonly DELETE_TPA_DOCUMENT_META_DATA: string = URLConstant.COMMON_TPA_PATH + 'DeleteThirdPartyAgreementDocumentData';
  public static readonly SEARCH_THIRD_PARTY_NAME: string = URLConstant.COMMON_TPA_PATH + 'SearchThirdPartyName?searchTerm=';
  public static readonly GET_APP_CONFIGURATION_LABEL: String = URLConstant.COMMON_TPA_PATH + 'GetAppConfigurationLabelData';

  //Client Opportunity Library
  public static readonly GET_CLIENT_OPPORTUNITY_LIBRARY_DATA: string =
    'Intake/Opportunity/GetOpportunityByClientSummary?clientnumber=#clientNumber#&tabCode=#tabCode#';
}
