import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RushRequestPopupComponent } from './rush-request-popup/rush-request-popup.component';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import {
  DatepickerOptions,
  DatepickerStates,
} from '@usitsdasdesign/dds-ng/datepicker';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import { PermissionCheck } from '../../../../common/validations/PermissionCheck';
import { SecurityWebapiService } from '../../../../http/security/security-webapi.service';

@Component({
  selector: 'app-external-communication-submission-detail',
  templateUrl: './external-communication-submission-detail.component.html',
  styleUrl: './external-communication-submission-detail.component.less',
})
export class ExternalCommunicationSubmissionDetailComponent
  extends PermissionCheck
  implements OnInit
{
  @Input() submissionDetailModel: any;
  @Output() businessDaysChange = new EventEmitter<number>();

  dateFormater: string = ExternalCommunicationConstant.DATE_FORMAT;
  selectedDate!: Date;
  dateForm!: FormGroup;
  businessDays: number = 0;
  htmlElementSize: Size = ExternalCommunicationConstant.UI_ELEMENT_SIZE;
  private readonly unsubscriber$ = new Subject<void>();
  numberOfBusinessDays: number = ExternalCommunicationConstant.TEXTAREA_LENGTH;

  datePickerOptions: DatepickerOptions = {
    size: this.htmlElementSize,
    placeholder: 'Select date',
    format: this.dateFormater,
    minMode: DatepickerStates.day,
    isResponsive: true,
    isManualInput: false,
  };
  isRequestedReviewDateVisible: boolean = false;
  isRequestedReviewDateEnable: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly modalService: ModalService,
    private readonly externalCommunicationService: ExternalCommunicationService,
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
  }

  ngOnInit(): void {
    this.isRequestedReviewDateVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsRequestedReviewDate,
      this.permissionType.Visible
    );
    this.isRequestedReviewDateEnable = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionDetailsRequestedReviewDate,
      this.permissionType.Enable
    );
    this.dateFormInitialize();

    this.externalCommunicationService.resetDatePicker$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.openDatepicker();
      });
  }

  openDatepicker(): void {
    const datepickerElement = document.getElementById(
      'requestedReviewDate-datepicker'
    );
    const datepickerButton =
      datepickerElement?.querySelector<HTMLButtonElement>(
        '.dds-datepicker__btn'
      );
    datepickerButton?.click();
  }

  openModal(): void {
    let modalRef = this.modalService.open(RushRequestPopupComponent);
    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          const requestData = {
            SubmissionId: this.submissionDetailModel.submissionId,
            CommunicationTypeId: this.submissionDetailModel.communicationTypeId,
            RequestedReviewDate: this.submissionDetailModel.requestedReviewDate,
            AudiencePublication: this.submissionDetailModel.audiencePublication,
            PrimaryIndustrySectorId:
              this.submissionDetailModel.primaryIndustrySectorId,
            IsDCF: this.submissionDetailModel.isDCF,
            IsGPS: this.submissionDetailModel.isGPS,
            OfferingPortfolioId: this.submissionDetailModel.offeringPortfolioId,
            OfferingId: this.submissionDetailModel.offeringId,
            MarketOfferingId: this.submissionDetailModel.marketOfferingId,
            PrimaryContact: this.submissionDetailModel.primaryContact,
            SecondaryContact: this.submissionDetailModel.secondaryContact,
            MarketingTalentPRContact:
              this.submissionDetailModel.marketingTalentPRContact,
            AdditionalComments: this.submissionDetailModel.additionalComments,
            NotesAndFeedback: this.submissionDetailModel.notesAndFeedback,
            IsRushRequest: ExternalCommunicationConstant.RUSH_REQUEST_VALUE,
            InternalQRM: this.submissionDetailModel.internalQRM,
            SubmissionTitle: this.submissionDetailModel.submissionTitle,
          };

          this.externalCommunicationService
            .putExtCommSubmissionDetails(requestData)
            .pipe(takeUntil(this.unsubscriber$))
            .subscribe({
              error: (err) => {
                console.error('API call error:', err);
              },
            });
        },
        error: (err) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  dateFormInitialize() {
    this.dateForm = this.fb.group({
      selectedDateCtrl: [''],
    });

    this.dateForm.get('selectedDateCtrl')?.valueChanges.subscribe((value) => {
      this.selectedDate = new Date(value);
      this.displayBusinessDays();
      this.businessDaysChange.emit(this.businessDays);
    });
  }

  displayBusinessDays(): void {
    const currentDate = new Date();
    const selectedDate = this.selectedDate;

    this.businessDays = this.calculateBusinessDays(currentDate, selectedDate);
    if (this.businessDays < this.numberOfBusinessDays) {
      this.openModal();
    }
  }

  calculateBusinessDays(currentDate: Date, selectedDate: Date): number {
    this.businessDays = 0;
    let date = new Date(currentDate);

    while (date <= selectedDate) {
      if (this.isBusinessDay(date)) {
        this.businessDays++;
      }
      date.setDate(date.getDate() + 1);
    }

    return this.businessDays;
  }

  isBusinessDay(date: Date): boolean {
    const day = date.getDay();
    const isWeekend = day === 0 || day === 6; // Sunday = 0, Saturday = 6
    return !isWeekend;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
