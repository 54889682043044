import { Component, OnInit, ViewChild } from '@angular/core';
import { ThirdPartyRepositorySettings } from './third-party-repository-add-settings';
import { TPADocumentUploadPopupComponent } from '../tpa-document-upload-popup/tpa-document-upload-popup.component';
import {
  ThirdPartyAgreementModel, TPAControlVisiblity, Lookup, LookupData
} from '../../common/models/third-party-repository/third-party-agreement.model';
import { FormBuilder, FormControl } from '@angular/forms';
import { EmployeeView } from '../../common/models/common-models';
import { debounceTime, filter, Subject, switchMap, takeUntil, map } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { InitiationApiService } from '../../http/initiation/initiation-api.service';
import { TPAWebApiService, LookupTableName } from '../../http/thirdpartyagreement/tpa.webapi.service';
import { DropdownItem } from '../../common/services/dropdowns';
import { MultiSelectItem } from '@usitsdasdesign/dds-ng/multi-select';
import { UploaderOptions, UploadFile, UploadFileStatus } from '@usitsdasdesign/dds-ng/uploader';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { SingleFileValidator } from "../../common/validations/single-file-validation";
import { Column, Themes } from '@usitsdasdesign/dds-ng/shared';
import { DocumentService } from '../../http/document/document.service';
import { NotifyPopupComponent } from '../../common/notify-popup/notify-popup.component';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { PopupType } from '../../common/utils/pop-up-constants';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ThirdPartyName } from '../../common/models/third-party-repository/third-party-name.model';
import { CheckboxOptions } from '@usitsdasdesign/dds-ng/checkbox';
import { SharedService } from '../../common/services/shared-service.service';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { EntityDetails, EntityType } from '../../http/user.service';
import { EclipseHeaderService } from '../../http/eclipse-header.service';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { AuditTrailService } from '../../http/audit-trail/audit-trail.service';
@Component({
  selector: 'app-third-party-repository-add',
  templateUrl: './third-party-repository-add.component.html',
  styleUrl: './third-party-repository-add.component.less'
})
export class ThirdPartyRepositoryAddComponent implements OnInit {

  model: ThirdPartyAgreementModel = {} as ThirdPartyAgreementModel;
  nameModel: ThirdPartyName = {} as ThirdPartyName;
  controlsVisibility: TPAControlVisiblity = {} as TPAControlVisiblity;
  btnSaveName = "Create";
  title = "Create new third party agreement";

  lookup: Lookup = {} as Lookup;
  lookupList: any = [];
  public lookupTable = LookupTableName;
  private securityThirdPartyAgreementRepositoryVisible: string = 'Application.ThirdPartyAgreementRepository';
  public isThirdPartyAgreementRepositoryVisible: boolean = false;
  coveredDeloitteEntityMultiSelectFormCtrl: any = new FormControl<MultiSelectItem[]>({ value: [], disabled: false });
  selectedCoveredDeloitteEntityList: any[] = [];

  thirdPartyAgreementData: any = {};

  documentList: any = [];
  //docMetaData: any = [];
  alertMessage: string = '';
  canUpload: boolean = true;

  ogcSearchFormCtrl = new FormControl({ value: '', disabled: false });
  ogcFilteredItems: EmployeeView[] = [];
  ogcSelectedItem?: EmployeeView;
  public ogcSearchKey: string = '';
  private ogcSearchKey$ = new Subject<string>();
  @ViewChild('ogcdropdown', { static: false }) ogcdropdown?: NgbDropdown;

  riskManagerSearchFormCtrl = new FormControl({ value: '', disabled: false });
  riskManagerFilteredItems: EmployeeView[] = [];
  riskManagerSelectedItem?: EmployeeView;
  public riskManagerSearchKey: string = '';
  private riskManagerSearchKey$ = new Subject<string>();
  @ViewChild('riskMgrdropdown', { static: false }) riskMgrdropdown?: NgbDropdown;

  textAgreementNameOptions: any = ThirdPartyRepositorySettings.textAgreementNameOptions;
  searchThirdPartyNameOptions: any = ThirdPartyRepositorySettings.searchThirdPartyNameOptions;
  enterThirdPartyNameOptions: any = ThirdPartyRepositorySettings.enterThirdPartyNameOptions;
  selectAgreementStatusOptions: any = ThirdPartyRepositorySettings.selectAgreementStatusOptions;
  selectAgreementTypeOptions: any = ThirdPartyRepositorySettings.selectAgreementTypeOptions;
  selectContractorTypeOptions: any = ThirdPartyRepositorySettings.selectContractorTypeOptions;
  effectiveDateOptions: any = ThirdPartyRepositorySettings.effectiveDateOptions;
  terminationDateOptions: any = ThirdPartyRepositorySettings.terminationDateOptions;
  terminationNoticeDueDateOptions: any = ThirdPartyRepositorySettings.terminationNoticeDueDate;
  auotRenewRadioBtn: any = ThirdPartyRepositorySettings.auotRenewRadioBtn;
  textNotesOptions: any = ThirdPartyRepositorySettings.textNotesOptions;
  multiSelectCoveredDeloitteEntityOptions: any = ThirdPartyRepositorySettings.multiSelectCoveredDeloitteEntityOptions;
  ogcSearchOptions: any = ThirdPartyRepositorySettings.ogcSearchOptions;
  riskManagerSearchOptions: any = ThirdPartyRepositorySettings.riskManagerSearchOptions;
  multiOptions: any = ThirdPartyRepositorySettings.multiOptions;
  uploadAreaOptions: any = ThirdPartyRepositorySettings.uploadAreaOptions;
  documentColumns: any = ThirdPartyRepositorySettings.documentColumns;
  textAgreementTypeOptions: any = ThirdPartyRepositorySettings.textAgreementTypeOptions;
  fileTooltipOptions: any = ThirdPartyRepositorySettings.fileTooltipOptions;

  backBtnTooltip: any = 'Return to the Third Party Agreements Repository.';
  effectiveDate: Date | null = null;
  terminationDate: Date | null = null;
  terminationNoticeDueDate: Date | null = null;

  @ViewChild('clientdropdown', { static: false }) clientdropdown?: NgbDropdown;
  public clientSearchKey: string = '';
  private clientSearchKey$ = new Subject<string>();
  clientFilteredItems: ThirdPartyName[] = [];
  clientSelectedItem?: ThirdPartyName;
  clientSearchFormCtrl = new FormControl({ value: '', disabled: false });
  public clientSearchmodel: ThirdPartyName = {} as ThirdPartyName;
  public isLoading: boolean = false;
  public isClientNotListed: boolean = false;
  public checkboxFormCtrl = new FormControl({ value: '', disabled: false });
  public isClientNameExists: boolean = false;
  unsubscriber$ = new Subject();
  isHeaderBlackAndSticky: boolean = false;

  actionType: string = 'add';
  constructor(private initiationApiService: InitiationApiService,
    private tpaApiService: TPAWebApiService,
    private modalService: ModalService,
    private documentService: DocumentService,
    private securityWebapiService: SecurityWebapiService,
    private router: Router,
    private location: Location,
    private readonly route: ActivatedRoute,
    private readonly sharedService: SharedService,
    private readonly commonHelperService: CommonHelperService,
    private progressIndicator: ProgressIndicatorService,
    private readonly headerService: EclipseHeaderService,
    private readonly auditService: AuditTrailService
  ) { }
  ngOnInit(): void {
    this.progressIndicator.show();
    document.title = BrowserTabConstant.Browser_TabName_TPA;
    this.controlsVisibility.visibleAgreementTypeText = false;
   
    this.checkUserPermissions();
    this.getLookupData();
    this.onSaveVisibility();    
    this.model.thirdPartyAgreementId = Number(this.route.snapshot.paramMap.get('id'));
    if (this.model.thirdPartyAgreementId != undefined && this.model.thirdPartyAgreementId > 0) {
      this.enableAuditTrial();
      this.controlsVisibility.btnEditVisible = true;
      this.getDocumentList();
      this.btnSaveName = 'Save';
      this.title = "Third party agreement details";
      this.actionType = 'edit';
    }
    else {
      this.model.thirdPartyAgreementId = 0;
      this.controlsVisibility.btnEditVisible = false;
      this.btnSaveName = 'Create';
      this.title = "Create new third party agreement";
      this.actionType = 'add';
    }

    this.headerService.showHeader$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((show: boolean | null) => {
        this.isHeaderBlackAndSticky = show ?? false;
      });
  }

  checkUserPermissions() {
    this.securityWebapiService.getPermissionsByEmployeeId(EntityDetails.None, EntityType.None);
    this.securityWebapiService.data.subscribe((rules: any) => {
      if (!rules.empty) {
        this.isThirdPartyAgreementRepositoryVisible = rules[this.securityThirdPartyAgreementRepositoryVisible] && rules[this.securityThirdPartyAgreementRepositoryVisible].Visible !== undefined ? rules[this.securityThirdPartyAgreementRepositoryVisible].Visible : false;
        if (!this.isThirdPartyAgreementRepositoryVisible) {
          this.router.navigate(['/']);
        }
      }
    });
  }
  enableAuditTrial() {
    this.auditService.enableAuditTrail(true);
    this.auditService.changeClientNumber({
      moduleName: 'TPA',
      moduleId: this.model.thirdPartyAgreementId
    });
  }
  disableAuditTrial() {
    this.auditService.enableAuditTrail(false);
  }
  ngOnDestroy(): void {
    this.disableAuditTrial();
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
  getThirdPartyAgreementData() {
    this.tpaApiService.getThirdPartyAgreementData(this.model.thirdPartyAgreementId).subscribe({
      next: (response: any) => {
        this.thirdPartyAgreementData = response[0];
        this.setThirdPartyAgreementModelData();
        this.disableUIControls();
        this.progressIndicator.hide();
      },
      error: (err: any) => {
        this.progressIndicator.hide();
      }
    })
  }
  getDocumentList() {
    this.tpaApiService.getTPADocList(this.model.thirdPartyAgreementId).subscribe({
      next: (response: any) => {
        this.documentList = response;
        this.documentList.forEach((element: any) => {
          if (element.modifiedDate == '0001-01-01T00:00:00') {
            element.modifiedDate = '';
          }
          if (element.isAutoRenew != null && element.isAutoRenew != undefined) {
            if (element.isAutoRenew.toString() == "true") {
              //element.isAutoRenewal = "Yes";
              Object.defineProperty(element, "isAutoRenewal", { value: "Yes" });
            }
            else {
              //element.isAutoRenewal = "No";
              Object.defineProperty(element, "isAutoRenewal", { value: "No" });
            }
          }
        });
      },
      error: (err: any) => {
      }
    });
  }
  setThirdPartyAgreementModelData() {
    this.model.agreementName = this.commonHelperService.trimPrefixEmptySpace(this.thirdPartyAgreementData.agreementName);
    this.model.thirdPartyName = this.commonHelperService.trimPrefixEmptySpace(this.thirdPartyAgreementData.thirdPartyName);
    this.model.agreementTypeId = this.thirdPartyAgreementData.agreementTypeId;
    if (this.thirdPartyAgreementData.otherAgreementTypeName != null && this.thirdPartyAgreementData.otherAgreementTypeName != undefined) {
      this.model.otherAgreementTypeName = this.thirdPartyAgreementData.otherAgreementTypeName;
    }
    this.model.contractorTypeId = this.thirdPartyAgreementData.contractorTypeId;
    this.model.agreementStatusId = this.thirdPartyAgreementData.agreementStatusId;
    if (this.model.agreementTypeId === 1) {
      this.controlsVisibility.contractTypeVisible = true;
    }
    else {
      this.controlsVisibility.contractTypeVisible = false;
    }
    if (this.model.thirdPartyName != null) {
      this.tpaApiService.searchThirdPartyName(this.model.thirdPartyName).subscribe((response: any) => {
        if (response != null) {
          var tempList = response;
          if (tempList.find((data: any) => data.clientName == this.model.thirdPartyName)) {
            this.checkboxFormCtrl.setValue('');
            this.isClientNotListed = false;
          }
          else {
            this.checkboxFormCtrl.setValue('true');
            this.isClientNotListed = true;
          }
        }
        else {
          this.checkboxFormCtrl.setValue('true');
        }
      });
    }
    
    if (this.thirdPartyAgreementData.effectiveDate != null && this.thirdPartyAgreementData.effectiveDate != undefined) {
      this.effectiveDate = new Date(this.thirdPartyAgreementData.effectiveDate);
    }
    this.model.isAutoRenew = this.thirdPartyAgreementData.isAutoRenew?.toString();
    if (this.thirdPartyAgreementData.terminationDate != null && this.thirdPartyAgreementData.terminationDate != undefined) {
      this.terminationDate = new Date(this.thirdPartyAgreementData.terminationDate);
    }
    if (this.thirdPartyAgreementData.terminationNoticeDueDate != null && this.thirdPartyAgreementData.terminationNoticeDueDate != undefined) {
      this.terminationNoticeDueDate = new Date(this.thirdPartyAgreementData.terminationNoticeDueDate);
    }
    this.model.notes = this.commonHelperService.trimPrefixEmptySpace(this.thirdPartyAgreementData.notes);
    this.model.ogcEmpId = this.thirdPartyAgreementData.ogcEmpID;

    if (this.thirdPartyAgreementData) {
      if (this.thirdPartyAgreementData.ogcEmpID) {
        this.ogcSelectedItem = { empID: this.thirdPartyAgreementData.ogcEmpID, outlookDisplayName: this.thirdPartyAgreementData.ogcEmpName } as EmployeeView;
      }
      if (this.thirdPartyAgreementData.riskManagerEmpID) {
        this.riskManagerSelectedItem = { empID: this.thirdPartyAgreementData.riskManagerEmpID, outlookDisplayName: this.thirdPartyAgreementData.riskManagerEmpName } as EmployeeView;
      }
    }
    
    this.model.riskManagerEmpId = this.thirdPartyAgreementData.riskManagerEmpID;
    
    this.model.coveredDeloitteEntityID = this.thirdPartyAgreementData.coveredDeloitteEntityId;
    this.setCoveredDeloitteEntityData();
  }
  setCoveredDeloitteEntityData() {
    if (this.thirdPartyAgreementData.coveredDeloitteEntityId != undefined && this.thirdPartyAgreementData.coveredDeloitteEntityId != null) {
      const coveredDeloitteEntityArray = this.thirdPartyAgreementData.coveredDeloitteEntityId.split(',');
      const tempList : MultiSelectItem[] = [];
      coveredDeloitteEntityArray.forEach((element: any, index: any) => {
        if (this.lookup != undefined && this.lookup.coveredDeloitteEntityList != null) {
          var selectedItem = this.lookup.coveredDeloitteEntityList.find((data: any) => data.itemId == element);
          if (selectedItem != undefined) {
            this.selectedCoveredDeloitteEntityList.push(element);
            tempList.push(selectedItem);

            if (this.model.coveredDeloitteEntityName == undefined || this.model.coveredDeloitteEntityName.length == 0) {
              this.model.coveredDeloitteEntityName = selectedItem.label == undefined ? '' : selectedItem.label;
            }
            else {
              this.model.coveredDeloitteEntityName = this.model.coveredDeloitteEntityName + ',' + selectedItem.label;
            }
          }          
        }
      });
      if (tempList.length > 0) {        
        this.coveredDeloitteEntityMultiSelectFormCtrl?.setValue(tempList);
      }
    }
  }
  getLookupData() {
    this.tpaApiService.getThirdPartyAgreementLookup().pipe(
      map((response: any) => {
        this.lookupList = response;
        const agreementTypeList = response.find((data: any) => data.tableName == this.lookupTable.AgreementType).lookupItem.map((lookupData: LookupData) => ({
          value: lookupData.id,
          itemCode: lookupData.code,
          heading: lookupData.name,
          isItemActive: true
        } as DropdownItem));

        const contractorTypeList = response.find((data: any) => data.tableName == this.lookupTable.ContractType).lookupItem.map((lookupData: LookupData) => ({
          value: lookupData.id,
          itemCode: lookupData.code,
          heading: lookupData.name,
          isItemActive: true
        } as DropdownItem));

        const agreementStatusList = response.find((data: any) => data.tableName == this.lookupTable.AgreementStatus).lookupItem.map((lookupData: LookupData) => ({
          value: lookupData.id,
          itemCode: lookupData.code,
          heading: lookupData.name,
          isItemActive: true
        } as DropdownItem));

        const coveredDeloitteEntityList = response.find((data: any) => data.tableName == this.lookupTable.CoveredDeloitteEntity).lookupItem.map((lookupData: LookupData) => ({
          description: "",
          disabled: false,
          globalClientCode: null,
          heading: null,
          isItemActive: true,
          itemCode: lookupData.code,
          itemId: lookupData.id,
          label: lookupData.name,
          value: true

        } as MultiSelectItem));

        return { agreementTypeList, contractorTypeList, agreementStatusList, coveredDeloitteEntityList };
      })
    ).subscribe({
      next: (response: any) => {
        this.lookup = response;
        if (this.model.thirdPartyAgreementId > 0) {
          //this.setCoveredDeloitteEntityData();
          this.getThirdPartyAgreementData();
        }
        else {
          this.progressIndicator.hide();
        }
      },
      error: (err) => {
        // Handle error
        this.progressIndicator.hide();
      }
    });
  }

  public sortingState = {
    property: this.documentColumns[0].name,
    ascending: true,
    dataType: this.documentColumns[0].dataType,
  };
  public documentSortingState = {
    property: this.documentColumns[0].name,
    ascending: true,
    dataType: this.documentColumns[0].dataType,
  };
  public sortColumn(column: Column, event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.documentSortingState && this.documentSortingState.property === column.name) {
      if (this.documentSortingState.ascending) {
        this.documentSortingState = { ...this.documentSortingState, ascending: false };
      } else {
        this.documentSortingState = { ...this.documentSortingState, ascending: true };
      }
    } else {
      this.documentSortingState = {
        property: column.name,
        ascending: false,
        dataType: column.dataType,
      };
    }

    this.documentList.sort((a: any, b: any) => {
      let valueA = a[column.name];
      let valueB = b[column.name];

      if (column.dataType === 'date') {
        valueA = new Date(valueA).getTime();
        valueB = new Date(valueB).getTime();
      }

      if (valueA < valueB) {
        return this.documentSortingState.ascending ? -1 : 1;
      } else if (valueA > valueB) {
        return this.documentSortingState.ascending ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  openNotifyPopup(notifyReason: string, title: string, isDeletePopup: boolean = false, confirmButtonText: string = 'Yes', cancelButtonText: string = 'No'): any {
    return this.modalService.open(NotifyPopupComponent, { isFooter: true, size: 'md', isInverse: false, isDeletePopup, notifyReason, title, confirmButtonText, cancelButtonText });
  }

  onFileDelete(row: any) {
    let modalRef = this.openNotifyPopup("Are you sure you want to permanently delete this document?", "Confirmation", true, "Delete", "Cancel");
    modalRef.onClosed().subscribe((userAction: any) => {
      if (userAction == "Yes") {
        this.progressIndicator.show();
        var payLoad = {
          thirdPartyAgreementID: this.model.thirdPartyAgreementId,
          documentID: row.tpaDocumentId,
          fileName: row.fileName
        };
        this.tpaApiService.deleteTPADocumentData(payLoad).subscribe({
          next: (response: any) => {
            this.progressIndicator.hide();
            if (response) {
              this.sharedService.showToastNotification('Document(s) deleted');
              this.getDocumentList();
            } else {
              //this.openNotifyPopup(response.resultData, "Error");
            }
          },
          error: (err: any) => {
            this.progressIndicator.hide();
            //this.openNotifyPopup(err?.message, "Error");
          }
        });
      }
    });

  }
  onFileEdit(row: any) {
    let modalRef = this.modalService.open(TPADocumentUploadPopupComponent, {
      isInverse: false,
      thirdPartyAgreementId: this.model.thirdPartyAgreementId,
      documentData: row,
      isEditble: true,
      agreementTypeId: this.model.agreementTypeId
      //thirdPartyAgreementId: 1 //Testing Purpose
    });
    modalRef.onClosed().subscribe((response: any) => {
      this.getDocumentList();
    });
  }
  onDownload(row: any) {
    let downloadRequest = { DocumentName: row.documentTitle, DocumentKey: row.attachmentguid, FileFolderPath: row.folderFilePath };
    this.documentService.getDownloadUrl(downloadRequest).subscribe
      ({
        next: (response: any) => {
          if (response != null && response.documentDownloadUrl != null) {
            window.open(response.documentDownloadUrl, "_blank");
          }
        },
        error: (err: any) => {
        }
      });
  }
  removeBodyOverflow() {
    document.body.style.overflow = 'hidden';
  }
  validateFile(selectedFilesList: any) {
    if (!SingleFileValidator.isFileExtensionValid(selectedFilesList)) {
      this.alertMessage = "inValidFileType";
      return false;
    }
    if (SingleFileValidator.isFileMaxSizeExceed(selectedFilesList)) {
      this.alertMessage = "inValidFileSize";
      return false;
    }
    if (this.documentList.length > 0) {
      let uploadedFiles: any = [];
      this.documentList.forEach((element: any) => {
        uploadedFiles.push(element.fileName);
      });
      if (SingleFileValidator.isFileExist(selectedFilesList[0].file.name, uploadedFiles)) {
        this.alertMessage = "FileExist";
        return false;
      }
    }
    if (!SingleFileValidator.isFileNameValidLength(selectedFilesList)) {
      this.alertMessage = "FileNameExceeds150Char";
      return false;
    }
    return true;
  }
  uploadedItems(selectedFilesList: UploadFile[]) {
    if (selectedFilesList.length > 0) {
      if (this.validateFile(selectedFilesList)) {
        this.removeBodyOverflow();
        let modalRef = this.modalService.open(TPADocumentUploadPopupComponent, {
          isInverse: false,
          fileList: selectedFilesList,
          thirdPartyAgreementId: this.model.thirdPartyAgreementId,
          uploadedDocuments: this.documentList,
          effectiveDate: this.effectiveDate,
          agreementTypeId: this.model.agreementTypeId
          //thirdPartyAgreementId: 1 //Testing Purpose
        });
        modalRef.onClosed().subscribe((response: any) => {
          selectedFilesList.forEach((element: any, index: any) => {
            element.remove();
          });
          this.getDocumentList();
        });
      }
      else {
        this.showMsg();
        selectedFilesList.forEach((element: any, index: any) => {
          element.remove();
        });
      }
    }
    else {

    }
  }
  showMsg(modalBtn?: ButtonComponent) {
    this.removeBodyOverflow();
    if (this.alertMessage == "deleteAgreement") {
      let modalRef = this.modalService.open(NotifyPopupComponent, {
        isFooter: true, size: 'md', isInverse: false,
        isDeletePopup: true, notifyReason: this.alertMessage, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
      });
      modalRef.onClosed().subscribe((userAction: any) => {
        if (userAction === "Yes") {
          this.onConfirmDelete();
        }
        if (modalBtn) {
          modalBtn.focus();
        }
        this.removeBodyOverflow();
      });
    }
    else {
      let modalRef = this.modalService.open(NotifyPopupComponent, {
        isFooter: true, size: 'md', isInverse: false,
        notifyReason: this.alertMessage, footerButtonText: 'OK'
      });
      modalRef.onClosed().subscribe(() => {
        if (modalBtn) {
          modalBtn.focus();
        }
        this.removeBodyOverflow();
      });
    }

  }
  CheckRequiredFields() {
    if (this.model.agreementName != undefined && this.model.agreementName.length > 0
      //&& this.model.thirdPartyName.length > 0
      && this.model.agreementTypeId != undefined && this.model.agreementTypeId > 0
      //&& this.model.contractorTypeId > 0
      && this.model.agreementStatusId != undefined && this.model.agreementStatusId > 0
      && this.selectedCoveredDeloitteEntityList.length > 0) {
      if (this.model.agreementTypeId === 1 && this.model.contractorTypeId != undefined && this.model.contractorTypeId > 0) {
        return true;
      }
      else if (this.model.agreementTypeId === 4 && this.model.otherAgreementTypeName != undefined && this.model.otherAgreementTypeName != null) {
        return true;
      }
      else if (this.model.agreementTypeId > 0 && this.model.agreementTypeId != 4 && this.model.agreementTypeId != 1)
        return true;
    }
   return false;

  }

  onSaveVisibility() {
    this.controlsVisibility.btnSaveDisabled = !this.CheckRequiredFields();    
  }
  onEditVisibility() {
    if (this.compareDataModels()) {
      this.controlsVisibility.btnSaveDisabled = !this.CheckRequiredFields();
    }
    else {
      this.controlsVisibility.btnSaveDisabled = true;
    }
  }
  disableSaveButton() {
    return this.controlsVisibility.btnSaveDisabled;
  }
  disableUIControls() {
    this.controlsVisibility.disableAgreementName = true;
    this.controlsVisibility.disableThirdPartyName = true;
    this.controlsVisibility.disableAgreementType = true;
    this.controlsVisibility.disableAgreementTypeText = true;
    this.controlsVisibility.disableContractorType = true;
    this.controlsVisibility.disableAgreementStatus = true;
    this.controlsVisibility.disableEffectiveDate = true;
    this.controlsVisibility.disableAutoRenew = true;
    this.controlsVisibility.disableTerminationDate = true;
    this.controlsVisibility.disableTerminationNoticeDueDate = true;
    this.controlsVisibility.disableNotes = true;
    this.controlsVisibility.disableOGCEmployee = true;
    this.controlsVisibility.disableRiskManager = true;
    this.controlsVisibility.disableCoveredDeloitteEntity = true;
    this.controlsVisibility.isCoveredDeloitteEntitySelectedValues = true;
    //this.controlsVisibility.disableContractorType = true;
  }
  enableUIControls() {
    this.controlsVisibility.disableAgreementName = false;
    this.controlsVisibility.disableThirdPartyName = false;
    this.controlsVisibility.disableAgreementType = false;
    this.controlsVisibility.disableAgreementTypeText = false;
    this.controlsVisibility.disableContractorType = false;
    this.controlsVisibility.disableAgreementStatus = false;
    this.controlsVisibility.disableEffectiveDate = false;
    this.controlsVisibility.disableAutoRenew = false;
    this.controlsVisibility.disableTerminationDate = false;
    this.controlsVisibility.disableTerminationNoticeDueDate = false;
    this.controlsVisibility.disableNotes = false;
    this.controlsVisibility.disableOGCEmployee = false;
    this.controlsVisibility.disableRiskManager = false;
    this.controlsVisibility.disableCoveredDeloitteEntity = false;
    this.controlsVisibility.isCoveredDeloitteEntitySelectedValues = true;
    //this.controlsVisibility.disableContractorType = false;
  }
  compareDataModels() {
    if (this.model.agreementName != this.thirdPartyAgreementData.agreementName) {
      return true;
    }
    else if (this.model.thirdPartyName != this.thirdPartyAgreementData.thirdPartyName) {
      return true;
    }
    else if (this.model.agreementTypeId != this.thirdPartyAgreementData.agreementTypeId) {
      return true;
    }
    else if (this.model.otherAgreementTypeName != this.thirdPartyAgreementData.otherAgreementTypeName) {
      return true;
    }
    else if (this.model.contractorTypeId != this.thirdPartyAgreementData.contractorTypeId) {
      return true;
    }
    else if (this.model.agreementStatusId != this.thirdPartyAgreementData.agreementStatusId) {
      return true;
    }
    else if (this.effectiveDate?.toDateString() != (this.thirdPartyAgreementData.effectiveDate == null ?
      undefined
      : (new Date(this.thirdPartyAgreementData.effectiveDate)).toDateString())) {
      return true;
    }
    else if (this.model.isAutoRenew?.toString() != this.thirdPartyAgreementData.isAutoRenew?.toString()) {
      return true;
    }
    else if (this.terminationDate?.toDateString() != (this.thirdPartyAgreementData.terminationDate == null ?
      undefined
      : (new Date(this.thirdPartyAgreementData.terminationDate)).toDateString())) {
      return true;
    }
    else if (this.terminationNoticeDueDate?.toDateString() != (this.thirdPartyAgreementData.terminationNoticeDueDate == null ?
      undefined :
      new Date(this.thirdPartyAgreementData.terminationNoticeDueDate).toDateString())) {
      return true;
    }
    else if (this.model.notes != this.thirdPartyAgreementData.notes) {
      return true;
    }
    else if (this.model.ogcEmpId != this.thirdPartyAgreementData.ogcEmpID) {
      return true;
    }
    else if (this.model.riskManagerEmpId != this.thirdPartyAgreementData.riskManagerEmpID) {
      return true;
    }
    else if (this.model.coveredDeloitteEntityID != this.thirdPartyAgreementData.coveredDeloitteEntityId) {
      return true;
    }
    return false;
  }

  prepareSaveData() {
    this.model.coveredDeloitteEntityID = this.selectedCoveredDeloitteEntityList.join(',');
    this.model.effectiveDate = this.effectiveDate?.toDateString() ?? null;
    this.model.terminationDate = this.terminationDate?.toDateString() ?? null;
    this.model.terminationNoticeDueDate = this.terminationNoticeDueDate?.toDateString() ?? null;

    const payload: any = {
      thirdPartyAgreementId : this.model.thirdPartyAgreementId,
      agreementName: this.model.agreementName,
      thirdPartyName: this.model.thirdPartyName ?? '',
      agreementTypeId: this.model.agreementTypeId,
      agreementTypeName: this.model.agreementTypeName ?? '',
      otherAgreementTypeName: this.model.otherAgreementTypeName ?? '',
      contractorTypeId: this.model.contractorTypeId ?? null,
      contractorTypeName: this.model.contractorTypeName ?? '',
      agreementStatusId: this.model.agreementStatusId,
      agreementStatusName: this.model.agreementStatusName ?? '',
      coveredDeloitteEntityID: this.model.coveredDeloitteEntityID,
      coveredDeloitteEntityName: this.model.coveredDeloitteEntityName,
      effectiveDate: this.model.effectiveDate,
      terminationDate: this.model.terminationDate,
      terminationNoticeDueDate: this.model.terminationNoticeDueDate,
      isAutoRenew: this.model.isAutoRenew ?? null,
      notes: this.model.notes ?? '',
      ogcEmpId: this.model.ogcEmpId ?? '',
      ogcEmpName : this.model.ogcEmpName ?? '',
      riskManagerEmpId: this.model.riskManagerEmpId ?? '',
      riskManagerEmpName: this.model.riskManagerEmpName ?? '',
      createdBy : "0",
      modifiedBy : ""
    };
    return payload;
  }
  
  saveTPAData() {
    const payLoad = this.prepareSaveData();
    this.tpaApiService.saveThirdPartyAgreement(payLoad).subscribe({
      next: (response: any) => {
        this.disableUIControls();
        if (this.actionType == 'add') {
          this.sharedService.showToastNotification('Record added');
        }
        else {
          this.sharedService.showToastNotification('Record updated');
        }
        if (this.model.thirdPartyAgreementId > 0) {         
          this.controlsVisibility.btnEditVisible = true;
          this.actionType == 'edit';
          this.getThirdPartyAgreementData();
        }
        else {
          this.model.thirdPartyAgreementId = response;
          this.controlsVisibility.btnEditVisible = true;
          this.enableAuditTrial();
        }
        this.controlsVisibility.uploadDocumentVisibility = true;
        this.progressIndicator.hide();
      },
      error: (err: any) => {
        // Handle error
        this.progressIndicator.hide();
      }
    });
  }

  backToDashboardEvent() {
    this.navigateBacktoTPADashboard();
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  navigateBacktoTPADashboard() {
    this.router.navigate(['../thirdpartyrepository']);
  }
  onSaveEvent() {
    this.progressIndicator.show();    
    this.saveTPAData();
  }
  onCancelEvent() {
    this.navigateBacktoTPADashboard();
  }
  onEditEvent() {
    this.controlsVisibility.btnSaveDisabled = true;
    this.controlsVisibility.btnEditVisible = !this.controlsVisibility.btnEditVisible;
    this.controlsVisibility.uploadDocumentVisibility = true;
    this.enableUIControls();
   
    this.btnSaveName = 'Save';
    this.actionType = 'edit';   
  }
  onDeleteEvent() {
    this.alertMessage = "deleteAgreement";
    this.showMsg();
  }
  onConfirmDelete() {
    this.tpaApiService.deleteThirdPartyAgreementData(this.model.thirdPartyAgreementId).subscribe({
      next: (response: any) => {
        this.sharedService.showToastNotification('Record deleted');
        this.navigateBacktoTPADashboard();
      },
      error: (err: any) => {
        // Handle error
      }
    });
  }
  onAutoRenewChangedEvent() {
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
  }
  agreementNameChangedEvent() {
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
    else if (this.model.thirdPartyAgreementId === 0) {
      this.onSaveVisibility();
    }
  }
  coveredDeloitteEntityChangedEvent() {
    this.selectedCoveredDeloitteEntityList = [];
    this.model.coveredDeloitteEntityName = '';
    if ((this.coveredDeloitteEntityMultiSelectFormCtrl?.value?.length ?? 0) > 0) {
      this.coveredDeloitteEntityMultiSelectFormCtrl.value?.forEach((item: any) => {
        this.selectedCoveredDeloitteEntityList.push(item.itemId);
        if (this.model.coveredDeloitteEntityName == undefined || this.model.coveredDeloitteEntityName.length == 0) {
          this.model.coveredDeloitteEntityName = item.label;
        }
        else {
          this.model.coveredDeloitteEntityName = this.model.coveredDeloitteEntityName + ',' + item.label;
        }
      });
      this.model.coveredDeloitteEntityID = this.selectedCoveredDeloitteEntityList.join(',');
      if (this.model.thirdPartyAgreementId > 0) {
        this.onEditVisibility();
      }
      if (this.model.thirdPartyAgreementId === 0) {
        this.onSaveVisibility();
      }
    }    
  }

  removecoveredDeloitteEntity(item: any) { 
    const current = this.coveredDeloitteEntityMultiSelectFormCtrl?.value as MultiSelectItem[];
    const updated: any = current.filter((i) => i !== item);
    this.coveredDeloitteEntityMultiSelectFormCtrl?.setValue(updated);
    this.selectedCoveredDeloitteEntityList = [];
    this.model.coveredDeloitteEntityName = '';
    if ((this.coveredDeloitteEntityMultiSelectFormCtrl?.value?.length ?? 0) > 0) {
      this.coveredDeloitteEntityMultiSelectFormCtrl.value?.forEach((item: any) => {
        this.selectedCoveredDeloitteEntityList.push(item.itemId);
        if (this.model.coveredDeloitteEntityName == undefined || this.model.coveredDeloitteEntityName.length == 0) {
          this.model.coveredDeloitteEntityName = item.label;
        }
        else {
          this.model.coveredDeloitteEntityName = this.model.coveredDeloitteEntityName + ',' + item.label;
        }
      });
    }
    this.model.coveredDeloitteEntityID = this.selectedCoveredDeloitteEntityList.join(',');
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
    if (this.model.thirdPartyAgreementId === 0) {
      this.onSaveVisibility();
    }
  }
  public selectAgreementTypeList($event: any): void {
    let agreementList = this.lookupList.find((data: any) => data.tableName == this.lookupTable.AgreementType).lookupItem;
    var rslt = agreementList.find((x: any) => x.id === $event);
    this.model.agreementTypeName = rslt.name;
    if (rslt.name === "Master SUBCON") {
      this.controlsVisibility.contractTypeVisible = true;
    }
    else {
      this.controlsVisibility.contractTypeVisible = false;
    }
    if (rslt.name === "Other") {
      this.controlsVisibility.visibleAgreementTypeText = true;
    }
    else {
      this.controlsVisibility.visibleAgreementTypeText = false;
    }
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
    if (this.model.thirdPartyAgreementId === 0) {
      this.onSaveVisibility();
    }
  }
  public agreementTypeTextChangedEvent() {
    
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
    if (this.model.thirdPartyAgreementId === 0) {
      this.onSaveVisibility();
    }
  }
  public notesChangedEvent() {
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
  }
  public selectContractorType($event: any): void {
    let contractorList = this.lookupList.find((data: any) => data.tableName == this.lookupTable.ContractType).lookupItem;
    var rslt = contractorList.find((x: any) => x.id === $event);
    this.model.contractorTypeName = rslt.name;
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
    if (this.model.thirdPartyAgreementId === 0) {
      this.onSaveVisibility();
    }
  }
  public selectAgreementStatus($event: any): void {
    let agreementStatusList = this.lookupList.find((data: any) => data.tableName == this.lookupTable.AgreementStatus).lookupItem;
    var rslt = agreementStatusList.find((x: any) => x.id === $event);
    this.model.agreementStatusName = rslt.name;
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
    if (this.model.thirdPartyAgreementId === 0) {
      this.onSaveVisibility();
    }
  }
  onEffectiveDateChanged($event: any) {
    this.effectiveDate = $event;;
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
  }
  onTerminationDateChanged($event: any) {
    this.terminationDate = $event;
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
  }
  onTerminationDueDateChanged($event: any) {
    this.terminationNoticeDueDate = $event;
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
  }
  ogcSearchEvent() {
    if (this.ogcSearchKey && this.ogcSearchKey.length > 2) {
      this.ogcSearchKey$.next(this.ogcSearchKey.trim());
    }
    else {
      this.ogcFilteredItems = [];
      if (!this.ogcSearchKey) {
        this.controlsVisibility.isOGCLoading = false;
        if (this.ogcdropdown && this.ogcdropdown.isOpen()) {
          this.ogcdropdown.close();
        }
      }
    }
  }
  ogcClearSearchEvent() {
    this.ogcSearchKey = '';
    this.ogcSearchKey$.next(this.ogcSearchKey);
    this.ogcFilteredItems = [];
    this.ogcSelectedItem = undefined;

    this.model.ogcEmpId = "0";
    this.model.ogcEmpName = '';

    if (this.ogcdropdown && this.ogcdropdown.isOpen()) {
      this.ogcdropdown.close();
    }
    this.onEditVisibility();
  }
  ogcInputClickEvent() {
    this.ogcSearchKey$.pipe(
      debounceTime(500),
      filter(key => !!key && key.length > 2),
      switchMap(key => this.ogcsearchItems(key))
    ).subscribe({
      next: (response: any) => {
        this.ogcFilteredItems = response;
        this.controlsVisibility.isOGCLoading = false;
        //this.isLoading = false;
      },
      error: (err) => {
        this.ogcFilteredItems = [];
      }
    });

    this.ogcSearchKey$.next(this.ogcSearchKey);
    if (this.ogcdropdown && !this.ogcdropdown.isOpen() && this.ogcSearchKey.length > 2) {
      this.ogcdropdown.open();
    }    
  }
  private ogcsearchItems(key: string) {
    if (this.ogcdropdown && !this.ogcdropdown.isOpen()) {
      this.ogcdropdown.open();
    }
    this.controlsVisibility.isOGCLoading = true;
    return this.initiationApiService.searchPPMD(key);
  }
  ogcSelectItemEvent(item: any) {
    this.ogcSearchKey = '';
    this.ogcSearchFormCtrl.setValue(this.ogcSearchKey, { emitEvent: false });
    this.ogcFilteredItems = [];
    this.ogcSelectedItem = item;

    this.model.ogcEmpId = item.empID;
    this.model.ogcEmpName = item.outlookDisplayName;
    this.onEditVisibility();
  }

  riskManagerSearchEvent() {
    if (this.riskManagerSearchKey && this.riskManagerSearchKey.length > 2) {
      this.riskManagerSearchKey$.next(this.riskManagerSearchKey.trim());
    }
    else {
      this.riskManagerFilteredItems = [];
      if (!this.riskManagerSearchKey) {
        this.controlsVisibility.isRiskManagerLoading = false;
        if (this.riskMgrdropdown && this.riskMgrdropdown.isOpen()) {
          this.riskMgrdropdown.close();
        }
      }
    }
  }
  riskManagerClearSearchEvent() {
    this.riskManagerSearchKey = '';
    this.riskManagerSearchKey$.next(this.riskManagerSearchKey);
    this.riskManagerFilteredItems = [];
    this.riskManagerSelectedItem = undefined;

    this.model.riskManagerEmpId = "0";
    this.model.riskManagerEmpName = '';

    if (this.riskMgrdropdown && this.riskMgrdropdown.isOpen()) {
      this.riskMgrdropdown.close();
    }
    this.onEditVisibility();
  }
  riskManagerInputClickEvent() {
    this.riskManagerSearchKey$.pipe(
      debounceTime(500),
      filter(key => !!key && key.length > 2),
      switchMap(key => this.riskManagerSearchItems(key))
    ).subscribe({
      next: (response: any) => {
        this.riskManagerFilteredItems = response;
        this.controlsVisibility.isRiskManagerLoading = false;
        //this.isLoading = false;
      },
      error: (err) => {
        this.riskManagerFilteredItems = [];
      }
    });

    this.riskManagerSearchKey$.next(this.riskManagerSearchKey);
    if (this.riskMgrdropdown && !this.riskMgrdropdown.isOpen() && this.riskManagerSearchKey.length > 2) {
      this.riskMgrdropdown.open();
    }
  }
  private riskManagerSearchItems(key: string) {
    if (this.riskMgrdropdown && !this.riskMgrdropdown.isOpen()) {
      this.riskMgrdropdown.open();
    }
    this.controlsVisibility.isRiskManagerLoading = true;
    return this.initiationApiService.searchPPMD(key);
  }
  riskManagerSelectItemEvent(item: any) {
    this.riskManagerSearchKey = '';
    this.riskManagerSearchFormCtrl.setValue(this.riskManagerSearchKey, { emitEvent: false });
    this.riskManagerFilteredItems = [];
    this.riskManagerSelectedItem = item;

    this.model.riskManagerEmpId = item.empID;
    this.model.riskManagerEmpName = item.outlookDisplayName;
    this.onEditVisibility();
  }

  public thirdPartyNameClearEvent() {    
    this.clientSearchKey = '';
    this.clientSearchKey$.next(this.clientSearchKey);
    this.clientFilteredItems = [];
    this.clientSelectedItem = undefined;

    this.model.thirdPartyName = null;
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
    if (this.clientdropdown && this.clientdropdown.isOpen()) {
      this.clientdropdown.close();
    }
  }
  public thirdPartyNameTextChangedEvent() {
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
  }

  public thirdPartyNameSearchEvent() {
    if (this.model.thirdPartyAgreementId > 0) {
      this.onEditVisibility();
    }
    if (this.clientSearchKey && this.clientSearchKey.length > 2) {
      this.clientSearchKey$.next(this.clientSearchKey.trim());
    }
    else {
      this.clientFilteredItems = [];
      if (this.clientdropdown && this.clientdropdown.isOpen()) {
        this.clientdropdown.close();
      }
      this.isLoading = true;
    }
  }

  selectItem(item: ThirdPartyName) {
    this.clientSearchKey = '';
    this.clientSearchFormCtrl.setValue(this.clientSearchKey, { emitEvent: false });
    this.clientFilteredItems = [];
    this.clientSelectedItem = item;
    this.clientSearchmodel = item;
    this.model.thirdPartyName = item.clientName;
  }

  public thirdPartyInputClick() {
    this.isLoading = true;
    this.clientSearchKey$.pipe(
      debounceTime(500),
      filter(key => !!key && key.length > 2),
      switchMap(key => this.searchItems(key))
    ).subscribe({
      next: (response: any) => {
        this.clientFilteredItems = response;
        this.isLoading = false;
      },
      error: (err) => {
        this.clientFilteredItems = [];
      }
    });

    this.clientSearchKey$.next(this.clientSearchKey);
    if (this.clientdropdown && !this.clientdropdown.isOpen() && this.clientSearchKey.length > 2) {
      this.clientdropdown.open();
    }
  }

  private searchItems(key: string) {
    if (this.clientdropdown && !this.clientdropdown.isOpen()) {
      this.clientdropdown.open();
    }

    return this.tpaApiService.searchThirdPartyName(key);
  }

  public stateChanged() {
    if (this.checkboxFormCtrl.value) {
      this.isClientNotListed = Boolean(this.checkboxFormCtrl.value);

      this.thirdPartyNameClearEvent();
      this.isClientNameExists = false;
    }
    else {
      this.isClientNotListed = Boolean(this.checkboxFormCtrl.value);
      this.model.thirdPartyName = null;
    }
  }

  options: CheckboxOptions = {
    label: 'Client not listed',
    theme: Themes.green,
    isRequired: false,
  };

}
