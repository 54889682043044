import { Component, Input } from '@angular/core';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import {
  CompleteReviewPopUpTableHeader,
  SecondaryButton,
} from '../submission-common-section.helper';
import { SubmissionApprovers } from '../../../../common/models/intake-submission-details-common-section.model';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { IntakeSubmissionDeatilsCommonSectionWorkflowSelectedDocumentsModal } from '../../../../common/models/intake-submission-details-common-section.model';
import {
  IntakeDocumentTableModel,
  IntakeSelectableDocumentTableModel,
} from '../../../../common/models/intake-document-table.model';

@Component({
  selector: 'app-document-selection-popup',
  templateUrl: './document-selection-popup.component.html',
  styleUrl: './document-selection-popup.component.less',
})
export class DocumentSelectionPopupComponent {
  @Input() submissionID: string = '';
  @Input() rowDetails!: SubmissionApprovers;

  constructor(
    public modalService: ModalService,
    private submissionService: SubmissionService,
    private progressIndicatorService: ProgressIndicatorService
  ) {}
  public sampleColumns: Column[] = CompleteReviewPopUpTableHeader;

  isAllSelected: boolean = false;
  selectedRowCount: number = 0;
  submissionIdList: number[] = [];
  cancelButton: ButtonOptions = SecondaryButton;
  unsubscriber$: Subject<any> = new Subject();
  docToShow: IntakeSelectableDocumentTableModel[] = [];
  public docMetaData: IntakeDocumentTableModel[] = [];

  ngOnInit(): void {
    this.loadDocumentTableData();
  }
  public toggleRow(rowData: IntakeSelectableDocumentTableModel): void {
    
    rowData.selected = !rowData.selected;
    if (rowData.selected) {
      this.submissionIdList.push(rowData.submissionDocumentId);
    } else {
      this.submissionIdList = this.submissionIdList.filter(
        (doc: number) => doc !== rowData.submissionDocumentId
      );
    }
    this.#checkSelectedRow(this.docToShow);
  }

  public selectAllRows(
    status: boolean,
    data: IntakeSelectableDocumentTableModel[]
  ): void {
    data.forEach((item) => (item.selected = status));
    this.#checkSelectedRow(this.docToShow);
    if (status) {
      this.submissionIdList = [];
      for (let i in this.docToShow) {
        this.submissionIdList.push(this.docToShow[i].submissionDocumentId);
      }
    } else this.submissionIdList = [];
  }

  #checkSelectedRow(data: IntakeSelectableDocumentTableModel[]): void {
    this.selectedRowCount = data.filter((row) => row.selected).length;
    this.isAllSelected = data.length === this.selectedRowCount;
  }

  close(): void {
    this.modalService.close();
  }

  submitSelectedDoc(): void {
    const saveSelectedDocuments: IntakeSubmissionDeatilsCommonSectionWorkflowSelectedDocumentsModal =
      {
        SubmissionDocumentId: this.submissionIdList,
        RMEmployeeid: this.rowDetails.reviewerId,
        Email: this.rowDetails.emailId,
        SubmissionId: +this.submissionID,
        DocumentStatusId: 2,
      };
    this.progressIndicatorService.show();
    this.submissionService
      .updateRMStatus(saveSelectedDocuments)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicatorService.hide();
        },
        // TODO: Handle error as response is not correct
        // error: (err) => {
        //   this.progressIndicatorService.hide();
        //   console.error('An error occurred while sending the data ', err);
        // },
      });
    this.submissionIdList = [];
    this.close();
  }

  loadDocumentTableData(): void {
    this.submissionService
      .getDocumentTableData(+this.submissionID)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.docMetaData = data;
          this.docToShow = data;
          for (let i in this.docToShow) this.docToShow[i].selected = false;
        },
        error: (err) => {
          console.error('An error occurred during fetching the data ', err);
        },
      });
  }
}
