<div class="margin-bottom-s">
    <div class="d-flex padding-bottom-m">
      <span class="text-label font-bold-700 font-size-24 line-height-x">Roles list
      </span>
    </div>
    <div class="d-flex align-items-center margin-bottom-x" *ngIf="isFssTogglesVisible">
        <span class="eclipse-content">Show FSS:</span>
        <dds-toggle-group class="d-flex mx-2">
            <dds-toggle class="padding-left-s ddsToggle"
              [label]="toggleConsOptions.label ?? ''" [theme]="toggleConsOptions.theme ?? ''"
              (stateChanged)="filterByFSS(1)"
            ></dds-toggle>
            <dds-toggle class="padding-left-s ddsToggle"
            [label]="tableMarsToggleOptions.label ?? ''" [theme]="tableMarsToggleOptions.theme ?? ''"
              (stateChanged)="filterByFSS(2)"
            ></dds-toggle>
        </dds-toggle-group>
    </div>
    <div class="d-flex margin-bottom-x align-items-center">
      <div class="dropdownWidth">
        <dds-dropdown [theme]="dropdownOptions.theme ?? ''"
        [kind]="dropdownOptions.kind ?? ''"
        [label]="selectedRoleName"
        [size]="dropdownOptions.size ?? ''"
        [label]="dropdownOptions.label ?? ''"
        [width]="dropdownOptions.width ?? ''"
        [disabled]="dropdownOptions.disabled ?? false"
        [stickerPosition]="dropdownStickerPosition"
        [stickerWidth]="200"
        (itemSelected)="onRoleSelected($event)"
      >
        <ng-container *ngFor="let item of rolesDropdownList">
            <dds-dropdown-item [value]="item.value"
                                [disabled]="item.disabled ?? false">{{item.heading}}</dds-dropdown-item>
          </ng-container>
        </dds-dropdown>
      </div>
      <div class="searchWidth mx-2">
        <dds-search [size]="searchOptions.size ?? ''" [customClass]="searchOptions.customClass ?? ''"
        [icon]="searchOptions.icon || 'dds-icon_search'" (valueChanged)="searchByName($event)"
        (fieldCleared)="clearSearch()">
        <input ddsSearch [placeholder]="searchOptions.placeholder ?? ''" [(ngModel)]="searchKey"
        [maxLength]="searchOptions.maxLength"
        (keyup)="onKeyUp($event)"
      />
</dds-search>
      </div>
      <div class="ml-auto" *ngIf="!showExtCommRiskMgrTable">
        <dds-button
          [theme]="buttonOptions.theme ?? ''"
          [kind]="buttonOptions.kind ?? ''"
          [size]="buttonOptions.size ?? ''"
          [width]="buttonOptions.width ?? ''"
          [icon]="buttonOptions.icon ?? ''"
          [isIconLeft]="buttonOptions.isIconLeft ?? false"
          [role]="buttonOptions.role ?? ''"
          [disabled]="!isAddEditEnable"
          (clicked)="addNewUser()"
        >Add new user</dds-button>
      </div>

      <div class="ml-auto" *ngIf="showExtCommRiskMgrTable">
        <dds-button
          [theme]="buttonOptions.theme ?? ''"
          [kind]="buttonOptions.kind ?? ''"
          [size]="buttonOptions.size ?? ''"
          [width]="buttonOptions.width ?? ''"
          [icon]="buttonOptions.icon ?? ''"
          [isIconLeft]="buttonOptions.isIconLeft ?? false"
          [role]="buttonOptions.role ?? ''"
          (clicked)="addNewRecord()"
        >Add record</dds-button>
      </div>
    </div>
    <div  *ngIf="!showExtCommRiskMgrTable">
      <table class="dds-data-table fixed-width-table">
        <thead class="dds-data-table__header">
          <tr>
            <th
              *ngFor="let column of rolesColumns"
              class="dds-data-table__header-cell top-aligned-header"
              [ngStyle]="{ width: column.width, minWidth: column.minWidth }">
              <div class="dds-data-table__content"
                *ngIf="column.name !== 'editIcon'">
                <span class="padding-right-xxs">{{ column.header || column.name }}</span>
                <span *ngIf="column.name === 'modifiedDate' || column.name === 'outlookDisplayName'"
                class="dds-icon dds-data-table__icon"
                aria-label="sort"
                [ngClass]="{
                  'dds-icon_sort-up': sortingState.property !== column.name || (sortingState.property === column.name && !sortingState.descending),
                  'dds-icon_sort-down': sortingState.property === column.name && sortingState.descending,
                  'dds-icon_sort-active': sortingState.property === column.name,
                  'dds-icon_sort-disabled': sortingState.property !== column.name}"
                (click)="sortColumn(column, $event)"></span>
              </div>
            </th>
          </tr>
        </thead>
    
        <tbody *ngIf="sortingState !== null" class="dds-data-table__body">
          <ng-container
            *ngFor="let row of roleAssignmentGridData | sort : getSortingState();
              let i = index">
            <tr class="dds-data-table__row">
              <td *ngFor="let column of rolesColumns" class="dds-data-table__cell" [ngClass]="{'center-icon': column.name == 'roleAssignmentComment'}"
              [ngStyle]="{ width: column.width, minWidth: column.minWidth }">
                <ng-container *ngIf="column.name === 'editIcon'">
                  <span *ngIf="isAddEditEnable"
                    #editButton
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                    (click)="onEditTypeClick($event, row)"
                  ></span>
                </ng-container>
                <ng-container *ngIf="column.name === 'activationStatus'">
                  <dds-toggle-group class="d-flex mx-2">
                    <dds-toggle class="padding-left-s"
                      [label]="toggleActivationOptions.label ?? ''" [theme]="toggleActivationOptions.theme ?? ''"
                      (stateChanged)="updateRoleActivationStatus(row)" [value]="row.isActive ?? true"
                    ></dds-toggle>
                  </dds-toggle-group>
                </ng-container>
                <ng-container *ngIf="column.name == 'isNcaManager'">
                    <span>{{ row[column.name] ? 'Yes' : 'No' }}</span>
                </ng-container>
                <ng-container *ngIf="column.name == 'roleAssignmentComment' || column.name == 'industryLabel' || column.name == 'sectorLabel'">
                  <div style="text-align: justify;">
                    <span *ngIf = "!!row[column.name]" class="icon-s icon-info" 
                    [ddsTooltip]="row[column.name]"
                    [tooltipInvokeType]="toolTipoptions.tooltipInvokeType ?? ''" [tooltipType]="toolTipoptions.tooltipType ?? ''"
                    [tooltipSize]="toolTipoptions.tooltipSize ?? ''" [tooltipPosition]="toolTipoptions.tooltipPosition ?? ''"
                    [tooltipIndent]="toolTipoptions.tooltipIndent ?? 0"
                    [tooltipHasBeak]="toolTipoptions.tooltipHasBeak ?? false" [tooltipTheme]="toolTipoptions.tooltipTheme ?? ''"
                    ddsFocus
                    [tooltipMaxWidth]="toolTipoptions.tooltipMaxWidth ?? 0" (click)="stopEventPropagation($event)">
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="column.name == 'outlookDisplayName' || column.name == 'modifiedDate' || column.name == 'applicableFssLabel'">
                  <span>{{ row[column.name] }}</span>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <!-- Admin Ext Comm Risk Manager Table -->
    <div *ngIf="showExtCommRiskMgrTable">
        <app-external-comm-risk-manager-table
        ></app-external-comm-risk-manager-table>
    </div>

  </div>