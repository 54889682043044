import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from '../http/document/document.service';

@Component({
  selector: 'app-download-redirect',
  template:''
})
export class DownloadRedirectComponent implements OnInit {

  constructor(
    private readonly documentWebapiService: DocumentService,
    private readonly route: ActivatedRoute
  ) { }


    ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
        const attachmentId = params['attachmentId'];
        if (attachmentId) {
          this.documentWebapiService.getArchivedDocDownloadUrl(attachmentId).subscribe((response) => {
            window.location.href = response?.documentDownloadUrl;
          });
        }
      });
    }

  
}
