import { RTEOptions } from '@usitsdasdesign/dds-ng/rich-text-editor';
import {
  SelectItemOptions,
  SelectOptions,
} from '@usitsdasdesign/dds-ng/select';
import {
  Column,
  ErrorState,
  LabelPosition,
  Size,
} from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../intake/constants/intake.constant';

export const globalConfigColumns: Column[] = [
  { name: 'editIcon', header: IntakeConstant.EMPTY_SPACE, dataType: IntakeConstant.EMPTY_SPACE, minWidth: '2.75rem' },
  {
    name: 'appConfigurationDisplayName',
    header: 'Key display',
    dataType: 'string',
    minWidth: '15rem',
  },
  {
    name: 'appConfigurationLabelValue',
    header: 'Value',
    dataType: 'string',
    minWidth: IntakeConstant.EMPTY_SPACE,
  },
];

export const selectDropdownOptions: SelectOptions = {
  label: 'Configurations',
  labelPosition: LabelPosition.external,
  description: IntakeConstant.EMPTY_SPACE,
  placeholder: IntakeConstant.EMPTY_SPACE,
  size: Size.lg,
  disabled: false,
  isResponsive: true,
  isRequired: false,
  isInverse: false,
  isError: false,
  errorMessage: IntakeConstant.EMPTY_SPACE,
  errorState: ErrorState.default,
  customClass: IntakeConstant.EMPTY_SPACE,
  stickerWidth: 0,
  stickerShift: 0,
  stickerMaxHeight: IntakeConstant.EMPTY_SPACE,
  stickerIsDisabled: false,
  stickerPosition: 'bottom-left',
  stickerIndent: 0,
  stickerCustomClass: IntakeConstant.EMPTY_SPACE,
  stickerIsDynamic: true,
};

export const options: RTEOptions = {
  size: Size.md,
  theme: 'dark',
  panelPosition: 'top',
  disabled: false,
  maxHeight: 200,
  isViewSourceActive: false,
  isResponsive: true,
  isError: false,
  isInverse: false,
  errorState: ErrorState.default,
  customClass: IntakeConstant.EMPTY_SPACE,
  placeholder: IntakeConstant.EMPTY_SPACE,
  buttonStatuses: {
    boldActive: true,
    italicActive: true,
    underlineActive: true,
    crossedActive: true,
    textColorActive: true,
    bgColorActive: true,
    header1Active: true,
    header2Active: true,
    header3Active: true,
    superscriptActive: true,
    subscriptActive: true,
    orderedListActive: true,
    numeralListActive: true,
    alignLeftActive: true,
    alignCenterActive: true,
    alignRightActive: true,
    hyperLinkActive: true,
    blockQuoteActive: true,
    removeFormatActive: true,
  },
  stickerWidth: 200,
  stickerIsOutsideClick: false,
  stickerShift: 0,
  stickerMaxHeight: IntakeConstant.EMPTY_SPACE,
  stickerIsDisabled: false,
  stickerPosition: 'bottom-left',
  stickerIndent: 0,
  stickerCustomClass: IntakeConstant.EMPTY_SPACE,
  stickerIsDynamic: true,
};

export const selectItems: SelectItemOptions[] = [
  {
    heading: 'Intake: Initiation',
    value: 'Initiation',
  },
  {
    heading: 'Intake: Opportunity',
    value: 'Opportunity',
  },
  {
    heading: 'Intake: Submission',
    value: 'Submission',
  },
  {
    heading: 'On hover guidance',
    value: 'onHoverGuidance',
  },
  {
    heading: 'Ext Comm',
    value: 'externalCommunication',
  }
];
