import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  LepValue,
  OpportunityDetailsNavModel,
} from '../../../common/models/opportunity-details.model';
import { SubmissionService } from '../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { AccordionOptions } from './service-delivery/service-delivery-settings';
import { lepDataValue } from '../opportunity-details-settings';
import {
  configurationType,
  ParsedRules,
  PermissionsObj,
} from '../../../common/models/common-models';
import { BrowserTabConstant } from '../../../common/constants/browser-tab-constant';
import { EclipseHeaderService } from '../../../http/eclipse-header.service';
@Component({
  selector: 'app-oportunity-detail-right-panel',
  templateUrl: './oportunity-detail-right-panel.component.html',
  styleUrl: './oportunity-detail-right-panel.component.less',
})
export class OportunityDetailRightPanelComponent implements OnInit, OnDestroy {
  @Input() opportunityNumber: string = '';
  @Input() tabTitle: string | null = '';
  @Input() submissionDetails: OpportunityDetailsNavModel[] = [];
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;

  accordionOptions = AccordionOptions;
  unsubscriber$: Subject<void> = new Subject<void>();
  opportunityData: any[] = [];
  lepValue: LepValue = lepDataValue;
  @Output() lepValueChange: EventEmitter<LepValue> =
    new EventEmitter<LepValue>();
  configLabels: configurationType[] = [];

  constructor(
    private readonly submissionService: SubmissionService,
    private readonly headerService: EclipseHeaderService
  ) {}

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_Opportunity;
    this.getServiceData();
    this.getLabelConfiguration();
  }

  ngAfterViewInit(): void {
    this.updateStickyTopPosition();
    this.scrollToTop();
  }

  getServiceData(): void {
    this.submissionService
      .getDeliveryServiceTable(this.opportunityNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.opportunityData = data;
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  shouldShowOfferingQuestions(): boolean {
    if (
      !this.permissionObj[
        'isIntakeOpportunityOfferingServiceQuestionsVisible'
      ] ||
      this.opportunityData?.length === 0
    ) {
      return false;
    }
    const hasInvalidStatus = this.opportunityData.some((submission) =>
      submission.offeringPortfolio?.toLowerCase().includes('cyber')
    );
    const hasValidLocation = this.opportunityData.some((submission) =>
      submission.location?.toLowerCase().includes('united states')
    );
    if (hasInvalidStatus && hasValidLocation) {
      return true;
    }
    this.submissionService.updateComplexQuestionCyberStatus(true);
    return false;
  }

  onLepValueChange(updatedLepValue: LepValue): void {
    this.lepValue = updatedLepValue;
    this.lepValueChange.emit(this.lepValue);
  }

  getLabelConfiguration(): void {
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response && response.length > 0) {
            this.configLabels = response;
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  updateStickyTopPosition(): void {
    this.headerService.updateStickyTopPosition();
  }

  scrollToTop(): void {
    this.headerService.scrollToTop();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
