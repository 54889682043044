import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonHelperService {

  constructor() { }
  getDocumentFolderPath(moduleName:any) {
    let filePath = "";
    if (moduleName == "NCA") {
      filePath= environment.docAPIConfig.ncaFolderPath;
    }
    else if (moduleName == "CC") {
      filePath = environment.docAPIConfig.ccFolderPath;
    }
    return filePath;
  }
}
