import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  ButtonKind,
  ExtThemes,
  Size,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { GridColumn } from '../http/watchlist/watchlist-api.service';
import { DropdownOptions } from '@usitsdasdesign/dds-ng/dropdown';
import { DashboardGridSortParameter } from '../common/models/start-page/start-page.model';

export class DashboardConstant {
  public static readonly NCA_SPECIALIST: string = 'NCA specialist';
  public static readonly REVIEWER: string = 'Reviewer';
  public static readonly ENGAGEMENT_TEAM: string = 'Engagement team';
  public static readonly RISK_MANAGER: string = 'Risk manager oversight';
  public static readonly TEXTAREA_CHAR_LIMIT_BIGGEST: number = 5000;
}

// Define the initial sort column number for each tile
export const reviewerSortParamaterCode: {
  [key: string]: number;
} = {
  PendingReviews: DashboardGridSortParameter.clientName,
  PendingReviewsOthers: DashboardGridSortParameter.clientName,
  NCAPendingApproval: DashboardGridSortParameter.submittedDate,
  CCDueNow: DashboardGridSortParameter.continuanceExpiredDate,
  RecentReviews: DashboardGridSortParameter.clientName,
  SelfReviewChanges: DashboardGridSortParameter.clientName,
};

export const engagementSortParamaterCode: {
  [key: string]: number;
} = {
  initiated: DashboardGridSortParameter.clientName,
  submitted: DashboardGridSortParameter.clientName,
  reviewed: DashboardGridSortParameter.submissionTitle,
  completed: DashboardGridSortParameter.submissionTitle,
};

export const riskaManagerSortParamaterCode: {
  [key: string]: number;
} = {
  inProcess: DashboardGridSortParameter.jupiterId,
  completed: DashboardGridSortParameter.jupiterId,
  discontinued: DashboardGridSortParameter.jupiterId,
};

export const ncaSortParamaterCode: number = 1;

export const buttonOptions: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.silent,
  size: Size.md,
  width: WidthState.fixed,
  isLoading: false,
  icon: 'dds-icon_arrow-right',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Show recently viewed submissions',
  customClass: '',
  role: 'button',
};

export const dropdownOptions: DropdownOptions = {
  label: 'Initiate new',
  ariaLabel: '',
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  size: Size.lg,
  width: WidthState.fixed,
  stickerWidth: 'fit-content',
  stickerPosition: 'bottom-left',
};

export enum GridColumns {
  ClientName = 'clientName',
  ParentClientName = 'parentClientName',
  DescDesignation = 'descDesignation',
  ActionByName = 'actionByName',
  NCASpecialistName = 'ncaSpecialistName',
  Industry = 'industry',
  DaysPendingForCCCompletion = 'daysPendingForCCCompletion',
  ContinuanceExpiredDate = 'continuanceExpiredDate',
  ApproverName = 'approverName',
  PriorFyClosedWBS = 'priorFyClosedWBS',
  NetServiceRevenue = 'netServiceRevenue',
  RecommendedClientGroupNumber = 'recommendedClientGroupNumber',
  OpportunityExpectedCloseDate = 'opportunityExpectedCloseDate',
  NCATriggeredDate = 'ncaTriggeredDate',
  SubmissionTitle = 'submissionTitle',
  Focus = 'focus',
  JupiterID = 'jupiterId',
  LEP = 'lep',
  SubmittedDate = 'submittedDate',
  Actions = 'actions',
  PendingReviewer = 'pendingReviewer',
  LastStatus = 'lastStatus',
  DateOfLastStatusChange = 'dateOfLastStatusChange',
  Status = 'status',
  SubmissionStatusName = 'submissionStatusName',
  PendingRequiredReviewers = 'pendingRequiredReviewer',
  PendingOptionalReviewers = 'pendingOptionalReviewer',
  SelfReviewEligibilityChangeDate = 'selfReviewEligibilityChangeDate',
  OpenOpportunities = 'openOpportunities',
  RequestReviewDate = 'requestedReviewDate',
  OpenWBS = 'openWBS',
  ContinuanceStatus = 'continuanceStatus',
  OpportunityIcon = 'opportunityIcon',
  PrimaryRiskManager = 'primaryRiskManager',
  Reviewer = 'reviewer',
  Business = 'business',
  CompletedDate = 'completedSubmissionDate',
  JupiterStage = 'jupiterStage',
  Offering = 'offering',
  StatusDate = 'statusDate',
  RiskManager = 'riskManager',
  OpportunityName = 'opportunityName',
}

export const defaultGridColumns: GridColumn[] = [
  {
    name: GridColumns.ClientName,
    header: 'Client',
    dataType: 'string',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.OpportunityName,
    header: 'Opportunity Name',
    dataType: 'string',
    minWidth: '10rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.JupiterStage,
    header: 'Jupiter Stage',
    dataType: 'string',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Offering,
    header: 'Offering',
    dataType: 'string',
    minWidth: '6rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.ParentClientName,
    header: 'Parent client',
    dataType: 'string',
    minWidth: '10rem',
    width: '11rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.DescDesignation,
    header: 'DESC designation (Services)',
    dataType: 'string',
    minWidth: '8.75rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.ActionByName,
    header: 'Action by',
    dataType: 'string',
    width: '11.1%',
    minWidth: '7rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.NCASpecialistName,
    header: 'NCA specialist',
    dataType: 'string',
    minWidth: '7rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Industry,
    header: 'Industry',
    dataType: 'string',
    minWidth: '5rem',
    width: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.DaysPendingForCCCompletion,
    header: 'Days pending for CC completion',
    dataType: 'number',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.ContinuanceExpiredDate,
    header: 'Expiration date',
    dataType: 'date',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.ApproverName,
    header: 'LBP / SL name',
    dataType: 'string',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.PriorFyClosedWBS,
    header: 'Closed WBSs (prior 13 periods)',
    dataType: 'number',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.NetServiceRevenue,
    header: 'Net services revenue',
    dataType: 'number',
    minWidth: '8.75rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.RecommendedClientGroupNumber,
    header: 'Recommended group',
    dataType: 'string',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.OpportunityExpectedCloseDate,
    header: 'Expected close date',
    dataType: 'date',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.NCATriggeredDate,
    header: 'NCA triggered date',
    dataType: 'date',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.SubmissionTitle,
    header: 'Submission title',
    dataType: 'string',
    minWidth: '10rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Focus,
    header: 'Focus',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.JupiterID,
    header: 'Jupiter ID',
    dataType: 'string',
    minWidth: '7rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.LEP,
    header: 'LEP',
    dataType: 'string',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.SubmittedDate,
    header: 'Date submitted',
    dataType: 'date',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Actions,
    header: 'Actions',
    dataType: '',
    minWidth: '8.75rem',
    width: '11.8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.PendingReviewer,
    header: 'Pending Reviewer',
    dataType: 'string',
    minWidth: '5rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.LastStatus,
    header: 'Last status',
    dataType: 'string',
    minWidth: '5rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.DateOfLastStatusChange,
    header: 'Date of last status change',
    dataType: 'string',
    minWidth: '5rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.StatusDate,
    header: 'Date of last status change',
    dataType: 'date',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.SubmissionStatusName,
    header: 'Status',
    dataType: 'string',
    minWidth: '3rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.PendingRequiredReviewers,
    header: 'Pending required reviewer(s)',
    dataType: 'string',
    minWidth: '3rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.PendingOptionalReviewers,
    header: 'Pending optional Reviewer(s)',
    dataType: 'string',
    minWidth: '3rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.SelfReviewEligibilityChangeDate,
    header: 'Date of self review eligibility change',
    dataType: 'string',
    minWidth: '3rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.OpenOpportunities,
    header: 'Open opportunities',
    dataType: 'string',
    minWidth: '8rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.RequestReviewDate,
    header: 'Requested review date',
    dataType: 'date',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.OpenWBS,
    header: 'Open WBSs',
    dataType: 'number',
    minWidth: '5rem',
    width: '5rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.ContinuanceStatus,
    header: 'Continuance status',
    dataType: 'string',
    minWidth: '10.75rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.OpportunityIcon,
    header: '',
    dataType: 'string',
    minWidth: '3rem',
    width: '3rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Business,
    header: 'Business',
    dataType: 'string',
    minWidth: '10.75rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.PrimaryRiskManager,
    header: 'Primary Risk Manager',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.RiskManager,
    header: 'Risk Manager',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Reviewer,
    header: 'Reviewer',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.CompletedDate,
    header: 'Completed date',
    dataType: 'date',
    minWidth: '3rem',
    width: '5rem',
    filterable: false,
    searchValue: '',
  },
];

export const RecentlyReviewColumn = [
  {
    name: 'Type',
    header: 'Type',
    dataType: 'string',
    width: '1rem',
    minWidth: '',
  },
  {
    name: 'Status',
    header: 'Status',
    dataType: 'string',
    width: '17rem',
    minWidth: '17rem',
  },
  {
    name: 'Title',
    header: 'Title',
    dataType: 'title',
    width: '10rem',
    minWidth: '',
  },
];

export const PendingReviewsColumns: string[] = [
  GridColumns.SubmissionTitle,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.SubmittedDate,
  GridColumns.Actions,
];

export const PendingReviewsOtherColumns: string[] = [
  GridColumns.SubmissionTitle,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.PendingRequiredReviewers,
  GridColumns.PendingOptionalReviewers,
];

export const RecentReviewsColumns: string[] = [
  GridColumns.SubmissionTitle,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.SubmissionStatusName,
  GridColumns.SubmittedDate,
];

export const SelfReviewsColumns: string[] = [
  GridColumns.SubmissionTitle,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.SubmissionStatusName,
  //For now set to submittedDate, once availabe form backend need ot change to SelfReviewEligibilityChangeDate
  GridColumns.SubmittedDate,
];

export const NCAPendingApprovalColumns: string[] = [
  GridColumns.ClientName,
  GridColumns.ParentClientName,
  GridColumns.Industry,
  GridColumns.DescDesignation,
  GridColumns.SubmittedDate,
  GridColumns.RequestReviewDate,
  GridColumns.Business,
  GridColumns.Actions,
];

export const CCOverviewColumns: string[] = [
  GridColumns.ClientName,
  GridColumns.ParentClientName,
  GridColumns.Business,
  GridColumns.ContinuanceStatus,
  GridColumns.ContinuanceExpiredDate,
  GridColumns.Industry,
  GridColumns.OpenOpportunities,
  GridColumns.PriorFyClosedWBS,
  GridColumns.OpenWBS,
  GridColumns.NetServiceRevenue,
  GridColumns.Actions,
];

export const EngagementInitiatedColumns: string[] = [
  GridColumns.SubmissionTitle,
  GridColumns.OpportunityIcon,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.Actions,
];

export const EngagementSubmittedColumns: string[] = [
  GridColumns.SubmissionTitle,
  GridColumns.OpportunityIcon,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.PrimaryRiskManager,
];

export const EngagementReviewedColumns: string[] = [
  GridColumns.SubmissionTitle,
  GridColumns.OpportunityIcon,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.Reviewer,
  GridColumns.Actions,
];

export const EngagementCompletedColumns: string[] = [
  GridColumns.SubmissionTitle,
  GridColumns.OpportunityIcon,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.CompletedDate,
];

export const RiskManagerInprocessColumns: string[] = [
  GridColumns.ClientName,
  GridColumns.OpportunityName,
  GridColumns.OpportunityIcon,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.JupiterStage,
  GridColumns.LEP,
  GridColumns.Offering,
  GridColumns.Industry,
  GridColumns.StatusDate,
  GridColumns.RiskManager,
];

export const RiskManagercompletedColumns: string[] = [
  GridColumns.ClientName,
  GridColumns.OpportunityName,
  GridColumns.OpportunityIcon,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.JupiterStage,
  GridColumns.LEP,
  GridColumns.Offering,
  GridColumns.Industry,
  GridColumns.StatusDate,
  GridColumns.RiskManager,
];

export const RiskManagerdiscontinuedColumns: string[] = [
  GridColumns.ClientName,
  GridColumns.OpportunityName,
  GridColumns.OpportunityIcon,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.JupiterStage,
  GridColumns.LEP,
  GridColumns.Offering,
  GridColumns.Industry,
  GridColumns.StatusDate,
  GridColumns.RiskManager,
];
