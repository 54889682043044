import { Component } from '@angular/core';
import { secondaryButton } from '../external-communications.helper';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { GlobalEngagementsConstant } from '../../global-engagements/constants/global-engagements.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.less'
})
export class ErrorPageComponent {
  secondaryButton = secondaryButton;
  submissionId: number | null = null;
  submissionTypeId: number | null = null;
  htmlElementSize: Size = GlobalEngagementsConstant.UI_ELEMENT_SIZE;
  
  constructor(private route: ActivatedRoute,
      private router: Router
    ) { }
    ngOnInit(): void {
      this.route.queryParams.subscribe((params) => {
        this.submissionId = params['submissionId'];
        this.submissionTypeId= params['submissionTypeId'];
        console.log(this.submissionId,this.submissionTypeId)
    })}
  
    navigateToDashboard(): void{
      this.router.navigate(['/'])
    }
  
    navigateToSubmission(): void{
      this.router.navigate(['/externalCommunications/details', this.submissionTypeId], {
        queryParams: { submissionId: this.submissionId }
       });
    }
}
