<span content>
  <div class="accordion-content relative">
    <div
      *ngIf="submissionStatus === SELF_REVIEWED_PENDING_PPMD_CONFIRMATION"
      class="padding-right-m relative info font-size-14 line-height-sm margin-top-xs"
    >
      <div class="container">
        <div class="eclipse-header-grey">Responsible Practitioner Guidance</div>
        <p class="eclipse-content-grey font-size-11">
          By confirming the acceptance, the Responsible Practitioner (PPMD)
          acknowledges the communication will not be reviewed by QRM and they
          are responsible for performing the risk review.
        </p>
        <p class="eclipse-content-grey font-size-11 padding-bottom-xxs">
          Do not share until the Responsible Practitioner confirms and the
          status changes to Completed.
        </p>
      </div>
    </div>

    <div class="div-headers">
      <table class="dds-data-table dds-data-table_selection">
        <thead class="dds-data-table__header">
          <tr>
            <th
              *ngIf="
                permissionObj['isExtCommRequiredCheckboxVisible'] &&
                !isWorkflowDisabled()
              "
              class="dds-data-table__header-cell"
              [ngStyle]="{
                width: '150px',
                minWidth: '100px'
              }"
            >
              Required
            </th>
            <th class="dds-data-table__header-cell">
              <div class="dds-data-table__content">
                <span>Role/Name</span>
              </div>
            </th>
            <th class="dds-data-table__header-cell">
              <!--TODO: Remove span if not needed-->
              <span *ngIf="false">Actions</span>
            </th>
            <th class="dds-data-table__header-cell"></th>
          </tr>
        </thead>
        <tbody class="dds-data-table__body">
          <ng-container
            *ngFor="let reviewer of updatedReviewerList; index as index"
          >
            <!-- Need to use a variable instead of function in ngIf -->
            <tr class="dds-data-table__row">
              <td
                class="dds-data-table__cell table-checkbox-cell"
                *ngIf="!isWorkflowDisabled()"
              >
                <dds-checkbox
                  *ngIf="
                    permissionObj['isExtCommRequiredCheckboxVisible'] &&
                    !isWorkflowDisabled()
                  "
                  theme="green"
                  [disabled]="
                    isWorkflowCheckboxDisabled(
                      reviewer.reviewerRoleId,
                      reviewer.roleName
                    ) || (reviewer?.isInEditMode ?? false)
                  "
                  [value]="
                    reviewer?.isInEditMode
                      ? false
                      : reviewer.isRequired ?? false
                  "
                  (click)="toggleRow(reviewer, index)"
                ></dds-checkbox>
              </td>
              <td class="dds-data-table__cell">
                <ng-container *ngIf="!reviewer.isInEditMode; else editMode">
                  <span
                    class="comments-text-label eclipse-small-header-grey d-block"
                    >{{ reviewer.roleName }}
                  </span>
                  <span class="profile-name">{{
                    reviewer.displayName ?? reviewer.fullName
                  }}</span>

                  <div *ngIf="!isWorkflowDisabled()" class="d-inline-block">
                    <span
                      class="dds-icon dds-icon_edit__s__filled edit-icon"
                      [ngClass]="{
                        'pe-none opacity-50': isEditDeleteRowDisabled(reviewer)
                      }"
                      (click)="
                        $event.stopPropagation(); editReviewer(reviewer, index)
                      "
                    ></span>
                  </div>
                </ng-container>
                <ng-template #editMode>
                  <div class="people-picker-container">
                    <app-people-picker
                      [id]="'picker-' + reviewer.reviewerRoleId + '-' + index"
                      [showLabel]="true"
                      [labelText]="reviewer.roleName ?? _BLANK"
                      [isRMConfig]="getValueOfRMConfig(reviewer.roleName)"
                      [isRMSupport]="getValueOfRMSupport(reviewer.roleName)"
                      [isDisabled]="
                        reviewer.isPeoplePickerDisabled || isWorkflowDisabled()
                      "
                      [isTechinalReviewerSearch]="
                        getValueOfPpmd(reviewer.roleName)
                      "
                      [isLep]="getValueOfPpmd(reviewer.roleName)"
                      [isEmpFetch]="getValueOfReviewer(reviewer.reviewerRoleId)"
                      (userSelected)="
                        onMemberSelected(
                          $event,
                          reviewer.reviewerRoleId,
                          reviewer.roleName ?? _BLANK,
                          reviewer.submissionApproverId,
                          index
                        )
                      "
                    ></app-people-picker>
                  </div>
                </ng-template>
              </td>
              <td class="dds-data-table__cell">
                <div
                  class="d-flex"
                  *ngIf="reviewer.reviewerId === currentLoggedInUser"
                >
                  <dds-button
                    *ngIf="isPrescreenVisible && !isWorkflowDisabled()"
                    class="custom-button padding-right-xs"
                    [theme]="prescreenBtnOptions.theme ?? _BLANK"
                    [kind]="prescreenBtnOptions.kind ?? _BLANK"
                    [size]="prescreenBtnOptions.size ?? defaultUiElementSize"
                    [width]="prescreenBtnOptions.width ?? _BLANK"
                    [ariaLabel]="prescreenBtnOptions.ariaLabel ?? _BLANK"
                    (click)="preScreenBtnClick(reviewer)"
                    >{{ prescreenBtnOptions.ariaLabel }}</dds-button
                  >
                  <dds-button
                    *ngIf="isCompleteReviewVisible && !isWorkflowDisabled()"
                    class="custom-button padding-right-xs"
                    [theme]="completeReviewBtnOptions.theme ?? _BLANK"
                    [kind]="completeReviewBtnOptions.kind ?? _BLANK"
                    [size]="
                      completeReviewBtnOptions.size ?? defaultUiElementSize
                    "
                    [width]="completeReviewBtnOptions.width ?? _BLANK"
                    [ariaLabel]="completeReviewBtnOptions.ariaLabel ?? _BLANK"
                    (click)="completeReviewBtnClick(reviewer)"
                    >{{ completeReviewBtnOptions.ariaLabel }}</dds-button
                  >
                  <dds-button
                    *ngIf="isConfirmButtonVisible && !isWorkflowDisabled()"
                    class="custom-button padding-right-xs"
                    [theme]="confirmBtnOptions.theme ?? _BLANK"
                    [kind]="confirmBtnOptions.kind ?? _BLANK"
                    [size]="confirmBtnOptions.size ?? defaultUiElementSize"
                    [width]="confirmBtnOptions.width ?? _BLANK"
                    [ariaLabel]="confirmBtnOptions.ariaLabel ?? _BLANK"
                    (click)="confirmBtnClick(reviewer)"
                    >{{ confirmBtnOptions.ariaLabel }}</dds-button
                  >
                  <dds-button
                    *ngIf="
                      isReturnForSelfReviewVisible && !isWorkflowDisabled()
                    "
                    class="custom-button padding-right-xs"
                    [theme]="returnForSelfReviewBtnOptions.theme ?? _BLANK"
                    [kind]="returnForSelfReviewBtnOptions.kind ?? _BLANK"
                    [size]="
                      returnForSelfReviewBtnOptions.size ?? defaultUiElementSize
                    "
                    [width]="returnForSelfReviewBtnOptions.width ?? _BLANK"
                    [ariaLabel]="
                      returnForSelfReviewBtnOptions.ariaLabel ?? _BLANK
                    "
                    (click)="returnForSelfReviewBtnClick()"
                    >{{ returnForSelfReviewBtnOptions.ariaLabel }}</dds-button
                  >
                  <dds-button
                    *ngIf="isReturnToReworkVisible && !isWorkflowDisabled()"
                    class="custom-button"
                    [theme]="returnToReworkBtnOptions.theme ?? _BLANK"
                    [kind]="returnToReworkBtnOptions.kind ?? _BLANK"
                    [size]="
                      returnToReworkBtnOptions.size ?? defaultUiElementSize
                    "
                    [width]="returnToReworkBtnOptions.width ?? _BLANK"
                    [ariaLabel]="returnToReworkBtnOptions.ariaLabel ?? _BLANK"
                    (click)="returnToReworkBtnClick()"
                    >{{ returnToReworkBtnOptions.ariaLabel }}</dds-button
                  >
                </div>
              </td>
              <td class="dds-data-table__cell">
                <div
                  *ngIf="
                    isWorkflowPractitionerCompleteMsg &&
                    reviewer.roleName === reviewerRole.RESPONSIBLE_PRACTITIONER
                  "
                >
                  <p
                    class="text-green-2"
                    [ddsTooltip]="
                      'I have reviewed the information contained herein, including attachments in the document repository. All information is accurate, reviews are complete, and associated recommendations from these reviews have either been incorporated or addressed. I have complied with the requirements of U.S. DPM 10640, External Communications.'
                    "
                    [tooltipType]="tooltipOption.tooltipType ?? _BLANK"
                    [tooltipSize]="tooltipOption.tooltipSize ?? _BLANK"
                    [tooltipPosition]="tooltipOption.tooltipPosition ?? _BLANK"
                    [tooltipIndent]="tooltipOption.tooltipIndent ?? 20"
                    [tooltipHasBeak]="true"
                    [tooltipTheme]="tooltipOption.tooltipTheme ?? _BLANK"
                    [tooltipMaxWidth]="tooltipOption.tooltipMaxWidth ?? 500"
                    ddsFocus
                  >
                    <u> Confirmed</u>
                  </p>
                  <p class="margin-bottom-none">
                    Confirmed in accordance with
                    <a
                      href="https://techlib.deloitte.com/default.aspx?view=content&id=2_81382"
                      target="_blank"
                    >
                      U.S. DPM 10640
                    </a>
                  </p>
                  <p class="margin-bottom-none">
                    {{ reviewer.statusReviewedDate }}
                  </p>
                </div>
                <div
                  *ngIf="
                    reviewer.statusReviewedDate !== null &&
                    reviewer.roleName !== reviewerRole.RESPONSIBLE_PRACTITIONER
                  "
                >
                  <div class="flex-row-col">
                    <ng-container
                      *ngIf="
                        reviewer.roleName !==
                          reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL;
                        else showPrescreened
                      "
                    >
                      <p class="text-green-2">Review Completed</p>
                      <p>{{ reviewer?.statusReviewedDate }}</p>
                    </ng-container>
                    <ng-template #showPrescreened>
                      <p class="text-green-2">Prescreened</p>
                      <p>{{ reviewer?.statusReviewedDate }}</p>
                    </ng-template>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>

        <dds-button
          *ngIf="permissionObj['isExtCommAddReviewerVisible']"
          (click)="$event.stopPropagation()"
          [theme]="options.theme ?? _BLANK"
          [kind]="options.kind ?? _BLANK"
          [size]="options.size ?? _BLANK"
          [width]="options.width ?? _BLANK"
          [icon]="options.icon ?? _BLANK"
          [isIconLeft]="options.isIconLeft ?? false"
          [role]="options.role ?? _BLANK"
          [isInverse]="options.isInverse ?? false"
          [isColorBg]="options.isColorBg ?? false"
          [isLoading]="options.isLoading ?? false"
          [ariaLabel]="options.ariaLabel ?? _BLANK"
          [customClass]="options.customClass ?? _BLANK"
          (clicked)="addReviewer(reviewerRole.EXT_COMM_REVIEWER)"
          [disabled]="
            isAddReviewerDisabled ||
            showReviewerPeoplePicker ||
            isWorkflowDisabled()
          "
          >Add Reviewer</dds-button
        >
      </table>
    </div>
  </div></span
>
