<div class="uploader-class">
  <dds-uploader
    [heading]="uploadAreaOptions.heading"
    [description]="uploadAreaOptions.description"
    [dragAndDropText]="uploadAreaOptions.dragAndDropText"
    [isDragAndDrop]="uploadAreaOptions.isDragAndDrop"
    [isMultiple]="uploadAreaOptions.isMultiple"
    [acceptFormats]="uploadAreaOptions.acceptFormats"
    [acceptExtensions]="uploadAreaOptions.acceptExtensions"
    [restrictedFormats]="uploadAreaOptions.restrictedFormats"
    [restrictedExtensions]="uploadAreaOptions.restrictedExtensions"
    [exceptSymbols]="uploadAreaOptions.exceptSymbols"
    [btnLabel]="uploadAreaOptions.btnLabel"
    [isInverse]="uploadAreaOptions.isInverse"
    [disabled]="uploadAreaOptions.disabled"
    [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind"
    (uploadedItems)="openUploadModel($event)"
  ></dds-uploader>
  
  <dds-button
    class="button-style"
    [theme]="options.theme"
    [kind]="options.kind"
    [size]="options.size"
    [width]="options.width"
    (click)="openDocumentLinkModel()"
    >Add link</dds-button
  >
</div>


<app-document-table
  [submissionId]="submissionId"
  [submissionTypeId]="submissionTypeId"
  [submissionStatusId]="submissionStatusId"
  [progressiveStatus]="progressiveStatus"
  (documentTableData)="documentTableDataValue($event)"
  [type]="type"
></app-document-table>
