export enum MatrixKeys {
  // Intake keys
  Intake_Nav_Submission_Reset = 'Intake.Submission.Reset',
  Intake_Nav_Submission_Admin = 'Intake.Submission.Admin',
  Intake_Nav_Submission_Export = 'Intake.Submission.Export',
  Intake_Nav_Submission_Discontinue = 'Intake.Submission.Discontinue',
  Intake_Header_MyDashboard = 'Intake.Header.MyDashboard',
  Intake_Header_OpportunityTitle = 'Intake.Header.OpportunityTitle',
  Intake_Header_GoToJupiter = 'Intake.Header.GoToJupiter',
  Intake_Header_GoToFocus = 'Intake.Header.GoToFocus',
  Intake_LeftHandNavigation = 'Intake.LeftHandNavigation',
  Intake_Submission_Title = 'Intake.Submission.Title',
  Intake_Submission_SubmissionType = 'Intake.Submission.SubmissionType',
  Intake_Submission_SubmissionStatus = 'Intake.Submission.SubmissionStatus',
  Intake_Submission_SubmissionId = 'Intake.Submission.SubmissionId',
  Intake_Submission_ELSOWOpportunityID = 'Intake.Submission.EL/SOWOpportunityID',
  Intake_Submission_ReSubmitBtn = 'Intake.Submission.ReSubmit',
  Intake_Submission_ArchiveAndCompleteBtn = 'Intake.Submission.ArchiveAndComplete',
  Intake_Submission_SubmitBtn = 'Intake.Submission.Submit',
  Intake_Submission_ReturnForReworkBtn = 'Intake.Submission.ReturnForRework',
  Intake_Submission_CompleteReviewBtn = 'Intake.Submission.CompleteReview',
  Intake_Submission_EligibleForSelfReview = 'Intake.Submission.EligibleForSelfReview',
  Intake_Submission_SubmissionTitle = 'Intake.Submission.SubmissionTitle',
  Intake_Submission_Deliverable = 'Intake.Submission.Deliverable',
  Intake_Submission_ChangeOrder = 'Intake.Submission.ChangeOrder',
  Intake_Submission_Other = 'Intake.Submission.Other',
  Intake_Submission_Proposal = 'Intake.Submission.Proposal',
  Intake_Submission_ELSOW = 'Intake.Submission.ELSOW',
  Intake_Submission_LEPConfirmation = 'Intake.Submission.LEPConfirmation',
  Intake_Submission_DocumentTable = 'Intake.Submission.DocumentTable',
  Intake_Submission_DocumentDownload = 'Intake.Submission.DocumentDownload',
  Intake_Submission_DocumentEdit = 'Intake.Submission.DocumentEdit',
  Intake_Submission_DocumentDelete = 'Intake.Submission.DocumentDelete',
  Intake_Submission_DocumentDragAndDrop = 'Intake.Submission.DocumentDragAndDrop',
  Intake_Submission_AttachDocument = 'Intake.Submission.AttachDocument',
  Intake_Submission_AddDocumentLink = 'Intake.Submission.AddDocumentLink',
  Intake_Submission_NotesAndFeedback = 'Intake.Submission.NotesAndFeedback',
  Intake_Submission_WorkflowCheckbox = 'Intake.Submission.WorkflowCheckbox',
  Intake_Submission_WorkflowRoleAndName = 'Intake.Submission.WorkflowRoleAndName',
  Intake_Submission_WorkflowAction = 'Intake.Submission.WorkflowAction',
  Intake_Submission_WorkflowAddReviewer = 'Intake.Submission.WorkflowAddReviewer',
  Intake_Submission_InternalQRM = 'Intake.Submission.InternalQRM',
  Intake_Submission_WBS = 'Intake.Submission.WBS',
  Intake_Submission_WBSDescription = 'Intake.Submission.WBSDescription',
  Intake_ServicesAndDelivery = 'Intake.ServicesAndDelivery',
  Intake_Complexity_DescriptionGreyGuidance = 'Intake.Complexity.DescriptionGreyGuidance',
  Intake_Opportunity_Title = 'Intake.Opportunity.Title',
  Intake_Opportunity_Description = 'Intake.Opportunity.Description',
  Intake_Opportunity_FieldsOutOfSync = 'Intake.Opportunity.FieldsOutOfSync',
  Intake_Opportunity_LinkedOpportunities = 'Intake.Opportunity.LinkedOpportunities',
  Intake_Opportunity_Complexity = 'Intake.Opportunity.Complexity',
  Intake_Opportunity_OfferingServiceQuestions = 'Intake.Opportunity.Offering/ServiceQuestions',
  Intake_Opportunity_ChangeArchive = 'Intake.Opportunity.ChangeArchive',
  Intake_OpportunityDetails_OpportunityNumber = 'Intake.OpportunityDetails.OpportunityNumber',
  Intake_OpportunityDetails_LEP = 'Intake.OpportunityDetails.LEP',
  Intake_OpportunityDetails_StartDate = 'Intake.OpportunityDetails.StartDate',
  Intake_OpportunityDetails_EndDate = 'Intake.OpportunityDetails.EndDate',
  Intake_OpportunityDetails_TotalFees = 'Intake.OpportunityDetails.TotalFees',
  Intake_OpportunityDetails_FeeType = 'Intake.OpportunityDetails.FeeType',
  Intake_OpportunityDetails_OpportunityDescription = 'Intake.OpportunityDetails.OpportunityDescription',
  Intake_Workflow_Technical = 'Workflow.AddTechnicalReviewer',
  Intake_AllSubmissionTypes_CloseoutForm = 'Intake.AllSubmissionTypes.CloseoutForm',
  Intake_Submission_RelinkSubmission = 'Intake.Submission.RelinkSubmission',
  // Ext comm keys
  EXT_COM_NavigationBarAuditTrail = 'ExternalComm.NavigationBar.AuditTrail',
  EXT_COM_Discontinue = 'ExternalComm.Discontinue',
  EXT_COM_Reset = 'ExternalComm.Reset',
  EXT_COM_BlackHeader = 'ExternalComm.BlackHeader',
  EXT_COM_CommunicationTypeToggle = 'ExternalComm.CommunicationType.Toggle',
  EXT_COM_SubmissionListAllFields = 'ExternalComm.SubmissionList.AllFields',
  EXT_COM_TitleAreaSubmissionTitle = 'ExternalComm.TitleArea.SubmissionTitle',
  EXT_COM_TitleAreaEXTCOMM = 'ExternalComm.TitleArea.EXTCOMM',
  EXT_COM_TitleAreaSubmissionStatus = 'ExternalComm.TitleArea.SubmissionStatus',
  EXT_COM_TitleAreaSubmissionId = 'ExternalComm.TitleArea.SubmissionId',
  EXT_COM_TitleAreaRushRequestBadge = 'ExternalComm.TitleArea.RushRequestBadge',
  EXT_COM_SubmitResubmit = 'ExternalComm.Submit/Resubmit',
  EXT_COM_CompleteReview = 'ExternalComm.CompleteReview',
  EXT_COM_Confirm = 'ExternalComm.Confirm',
  EXT_COM_ReturnForRework = 'ExternalComm.ReturnForRework',
  EXT_COM_SelfReview = 'ExternalComm.SelfReview',
  EXT_COM_OptOutOfSelfReview = 'ExternalComm.OptOutOfSelfReview',
  EXT_COM_ForceSelfReview = 'ExternalComm.ForceSelfReview',
  EXT_COM_SubmissionDetailsSubmissionDetailsTitle = 'ExternalComm.SubmissionDetails.SubmissionDetailsTitle',
  EXT_COM_SubmissionDetailsSelfReviewEligibilty = 'ExternalComm.SubmissionDetails.SelfReviewEligibilty',
  EXT_COM_SubmissionDetailsCommunicationType = 'ExternalComm.SubmissionDetails.CommunicationType',
  EXT_COM_SubmissionDetailsPPMDTrainingCompleted = 'ExternalComm.SubmissionDetails.PPMDTrainingCompleted',
  EXT_COM_SubmissionDetailsUpdatedSelfReviewGuidance = 'ExternalComm.SubmissionDetails.UpdatedSelfReviewGuidance',
  EXT_COM_SubmissionDetailsSubmissionTitle = 'ExternalComm.SubmissionDetails.SubmissionTitle',
  EXT_COM_SubmissionDetailsRequestedReviewDate = 'ExternalComm.SubmissionDetails.RequestedReviewDate',
  EXT_COM_SubmissionDetailsAudiencePublications = 'ExternalComm.SubmissionDetails.Audience/Publications',
  EXT_COM_SubmissionDetailsRelatedToGovernmentandPublicServices = 'ExternalComm.SubmissionDetails.RelatedToGovernmentandPublicServices',
  EXT_COM_SubmissionDetailsIndustrySector = 'ExternalComm.SubmissionDetails.IndustrySector',
  EXT_COM_SubmissionDetailsDeloitteCorporateFinance = 'ExternalComm.SubmissionDetails.DeloitteCorporateFinance',
  EXT_COM_SubmissionDetailsOffering = 'ExternalComm.SubmissionDetails.Offering',
  EXT_COM_SubmissionDetailsSolution = 'ExternalComm.SubmissionDetails.Solution',
  EXT_COM_SubmissionDetailsMarketOffering = 'ExternalComm.SubmissionDetails.MarketOffering',
  EXT_COM_SubmissionDetailsPrimaryContact = 'ExternalComm.SubmissionDetails.PrimaryContact',
  EXT_COM_SubmissionDetailsSecondaryContact = 'ExternalComm.SubmissionDetails.SecondaryContact',
  EXT_COM_SubmissionDetailsMarketingTalentPRContact = 'ExternalComm.SubmissionDetails.MarketingTalentPRContact',
  EXT_COM_RiskAssessment = 'ExternalComm.RiskAssessment',
  EXT_COM_CommunicationAttributes = 'ExternalComm.CommunicationAttributes',
  EXT_COM_DocumentsGuidanceEligibleForSelfReview = 'ExternalComm.Documents.GuidanceEligibleForSelfReview',
  EXT_COM_DocumentsGuidanceUploadDocumentForReview = 'ExternalComm.Documents.GuidanceUploadDocumentForReview',
  EXT_COM_DocumentsDragAndDrop = 'ExternalComm.Documents.DragAndDrop',
  EXT_COM_DocumentsAttachDocuments = 'ExternalComm.Documents.AttachDocuments',
  EXT_COM_DocumentsAddLink = 'ExternalComm.Documents.AddLink',
  EXT_COM_NotesAndFeedback = 'ExternalComm.NotesAndFeedback',
  EXT_COM_InternalQRM = 'ExternalComm.InternalQRM',
  EXT_COM_WorkflowRequiredCheckbox = 'ExternalComm.Workflow.RequiredCheckbox',
  EXT_COM_WorkflowRiskManager = 'ExternalComm.Workflow.RiskManager',
  EXT_COM_WorkflowSupportingRiskManager = 'ExternalComm.Workflow.SupportingRiskManager',
  EXT_COM_WorkflowRMSupport = 'ExternalComm.Workflow.RMSupport',
  EXT_COM_WorkflowReviewer = 'ExternalComm.Workflow.Reviewer',
  EXT_COM_WorkflowAddReviewer = 'ExternalComm.Workflow.AddReviewer',
  EXT_COM_WorkflowPpmd = 'ExternalComm.Workflow.PPMD',
  EXT_COM_ThirdPartyAgreementRepository = 'Application.ThirdPartyAgreementRepository',
  EXT_COM_AdminComplete = 'ExternalComm.Admin',
  EXT_COM_ReviewerDashboard = 'ExternalComm.ReviewerDashboard',
  EXT_COM_Unassigned_ReviewerDashboard = 'ExternalComm.ReviewerDashboard.Unassigned',
  EXT_COM_AllSubmissions_ReviewerDashboard = 'ExternalComm.ReviewerDashboard.AllSubmissions',
  EXT_COM_EngagementTeamDashboard = 'ExternalComm.EngagementTeamDashboard',
  // GlobalEngagement keys
  GLOBAL_ENG_Initiation_SubmissionType = 'GlobalEng.Initiation.SubmissionType',
  GLOBAL_ENG_DefineEngagement_SelectExisting = 'GlobalEng.DefineEngagement.SelectExisting',
  GLOBAL_ENG_DefineEngagement_SearchByEngagement = 'GlobalEng.DefineEngagement.SearchByEngagement',
  GLOBAL_ENG_DefineEngagement_SelectMemberFirm = 'GlobalEng.DefineEngagement.SelectMemberFirm',
  GLOBAL_ENG_DefineEngagement_SelectDeliveryCenter = 'GlobalEng.DefineEngagement.SelectDeliveryCenter',
  GLOBAL_ENG_DefineEngagement_EnterMemberFirmName = 'GlobalEng.DefineEngagement.EnterMemberFirmName',
  GLOBAL_ENG_DefineEngagement_SelectOpportunity = 'GlobalEng.DefineEngagement.SelectOpportunity',
  GLOBAL_ENG_DefineEngagement_SelectClient = 'GlobalEng.DefineEngagement.SelectClient',
  GLOBAL_ENG_DefineEngagement_EnterClientName = 'GlobalEng.DefineEngagement.EnterClientName',
  GLOBAL_ENG_DefineEngagement_ConfirmAndProceed = 'GlobalEng.DefineEngagement.ConfirmAndProceed',
  GLOBAL_ENG_DefineEngagement_Cancel = 'GlobalEng.DefineEngagement.Cancel',
  GLOBAL_ENG_EngagementDetails_EngagementId = 'GlobalEng.EngagementDetails.EngagementId',
  GLOBAL_ENG_EngagementDetails_MemberFirm = 'GlobalEng.EngagementDetails.MemberFirm',
  GLOBAL_ENG_EngagementDetails_EngagementTitle = 'GlobalEng.EngagementDetails.EngagementTitle',
  GLOBAL_ENG_EngagementDetails_EngagementStartDate = 'GlobalEng.EngagementDetails.EngagementStartDate',
  GLOBAL_ENG_EngagementDetails_EngagementEndDate = 'GlobalEng.EngagementDetails.EngagementEndDate',
  GLOBAL_ENG_EngagementDetails_JupiterOpportunityId = 'GlobalEng.EngagementDetails.JupiterOpportunityId',
  GLOBAL_ENG_EngagementDetails_OfferingPortfolio = 'GlobalEng.EngagementDetails.OfferingPortfolio',
  GLOBAL_ENG_EngagementDetails_Offering = 'GlobalEng.EngagementDetails.Offering',
  GLOBAL_ENG_EngagementDetails_EngagementDescription = 'GlobalEng.EngagementDetails.EngagementDescription',
  GLOBAL_ENG_EngagementDetails_PPMD = 'GlobalEng.EngagementDetails.PPMD',
  GLOBAL_ENG_EngagementDetails_MemberFirmPartner = 'GlobalEng.EngagementDetails.MemeberFirmPartner',
  GLOBAL_ENG_EngagementDetails_PRRID = 'GlobalEng.EngagementDetails.PRRID',
  GLOBAL_ENG_SubmissionDetails_SubmissionTitle = 'GlobalEng.SubmissionDetails.SubmissionTitle',
  GLOBAL_ENG_SubmissionDetails_ChangeCode = 'GlobalEng.SubmissionDetails.ChangeCode(WBS)',
  GLOBAL_ENG_SubmissionDetails_WBSDescription = 'GlobalEng.SubmissionDetails.WBSDescription',
  GLOBAL_ENG_SubmissionDetails_Archive = 'GlobalEng.SubmissionDetails.Archive',
  GLOBAL_ENG_SubmissionDetails_DocumentsTable = 'GlobalEng.SubmissionDetails.DocumentsTable',
  GLOBAL_ENG_SubmissionDetails_DragAndDrop = 'GlobalEng.SubmissionDetails.DragAndDrop',
  GLOBAL_ENG_SubmissionDetails_ArchiveAndComplete = 'GlobalEng.SubmissionDetails.ArchiveAndComplete',
  GLOBAL_ENG_GearIcon_GlobalEngagements = 'GlobalEng.GearIcon.GlobalEngagements',
  GLOBAL_ENG_SubmitButton = 'GlobalEng.SubmitButton',
  GLOBAL_ENG_Discontinue = 'GlobalEng.Discontinue',
  GLOBAL_ENG_Reset = 'GlobalEng.Reset',
  GLOBAL_ENG_ReviewerDashboard = 'GlobalEng.ReviewerDashboard',
  GLOBAL_ENG_EngagementTeamDashboard = 'GlobalEng.EngagementTeamDashboard',
}

export enum PermissionTypes {
  Visible = 'Visible',
  Enable = 'Enable',
}

// TODO: Remove after testing the globaleng permissions
export const globalEngagementPermissions: string[] = [
  // `${MatrixKeys.GLOBAL_ENG_Initiation_SubmissionType}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_Initiation_SubmissionType}.${PermissionTypes.Enable}`,
  // `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SelectExisting}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SelectExisting}.${PermissionTypes.Enable}`,
  // `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SearchByEngagement}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SearchByEngagement}.${PermissionTypes.Enable}`,
  // `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SelectMemberFirm}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SelectMemberFirm}.${PermissionTypes.Enable}`,
  // `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SelectDeliveryCenter}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SelectDeliveryCenter}.${PermissionTypes.Enable}`,
  // `${MatrixKeys.GLOBAL_ENG_DefineEngagement_EnterMemberFirmName}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_DefineEngagement_EnterMemberFirmName}.${PermissionTypes.Enable}`,
  // `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SelectOpportunity}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SelectOpportunity}.${PermissionTypes.Enable}`,
  // `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SelectClient}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_DefineEngagement_SelectClient}.${PermissionTypes.Enable}`,
  // `${MatrixKeys.GLOBAL_ENG_DefineEngagement_EnterClientName}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_DefineEngagement_EnterClientName}.${PermissionTypes.Enable}`,
  // `${MatrixKeys.GLOBAL_ENG_DefineEngagement_ConfirmAndProceed}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_DefineEngagement_ConfirmAndProceed}.${PermissionTypes.Enable}`,
  // `${MatrixKeys.GLOBAL_ENG_DefineEngagement_Cancel}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_DefineEngagement_Cancel}.${PermissionTypes.Enable}`,

  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_EngagementId}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_EngagementId}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_MemberFirm}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_MemberFirm}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_EngagementTitle}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_EngagementTitle}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_EngagementStartDate}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_EngagementStartDate}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_EngagementEndDate}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_EngagementEndDate}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_JupiterOpportunityId}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_JupiterOpportunityId}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_OfferingPortfolio}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_OfferingPortfolio}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_Offering}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_Offering}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_EngagementDescription}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_EngagementDescription}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_PPMD}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_PPMD}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_MemberFirmPartner}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_MemberFirmPartner}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_PRRID}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_EngagementDetails_PRRID}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_SubmissionTitle}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_SubmissionTitle}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_ChangeCode}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_ChangeCode}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_WBSDescription}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_WBSDescription}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_Archive}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_Archive}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_DocumentsTable}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_DocumentsTable}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_DragAndDrop}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_DragAndDrop}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_ArchiveAndComplete}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_SubmissionDetails_ArchiveAndComplete}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_GearIcon_GlobalEngagements}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_GearIcon_GlobalEngagements}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_SubmitButton}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_SubmitButton}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_Discontinue}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_Discontinue}.${PermissionTypes.Enable}`,
  `${MatrixKeys.GLOBAL_ENG_Reset}.${PermissionTypes.Visible}`,
  `${MatrixKeys.GLOBAL_ENG_Reset}.${PermissionTypes.Enable}`,
];
