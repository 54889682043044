<ng-container *ngIf="submissionData">
  <div
    *ngIf="!isOpportunitySelected"
    class="main-container padding-right-m"
    [autosave-module]="commonSectionFormModule"
  >
    <div
      class="header-container position-top-intake sticky-element-after-black-header padding-bottom-16 section-ending"
    >
      <div
        class="title-container align-items-center padding-top-es padding-bottom-16"
      >
        <h4
          class="title text-align-left font-size-16 font-bold-700 padding-right-s"
          *ngIf="permissionObj['isIntakeSubmissionTitleVisible']"
        >
          {{ titleValue }}
        </h4>
        <span>
          <ng-container
            class="d-flex no-break"
            *ngIf="isSelfReviewButtonVisible"
          >
            <div class="no-break">
              <dds-button
                class="custom-button margin-left-xs margin-bottom-xs"
                [theme]="selfReviewBtnOption.theme ?? _EMPTY"
                [kind]="selfReviewBtnOption.kind ?? _EMPTY"
                [size]="selfReviewBtnOption.size ?? _EMPTY"
                [width]="selfReviewBtnOption.width ?? _EMPTY"
                [icon]="selfReviewBtnOption.icon ?? _EMPTY"
                [isIconLeft]="selfReviewBtnOption.isIconLeft ?? false"
                [role]="selfReviewBtnOption.role ?? _EMPTY"
                [isInverse]="selfReviewBtnOption.isInverse ?? false"
                [isColorBg]="selfReviewBtnOption.isColorBg ?? false"
                [isLoading]="selfReviewBtnOption.isLoading ?? false"
                [ariaLabel]="selfReviewBtnLabel"
                [customClass]="selfReviewBtnOption.customClass ?? _EMPTY"
                [disabled]="
                  updateSubmitButtonStatus() || !isOpporunityLevelFormCompleted
                "
                (clicked)="onclickSelfReviewBtn()"
                ><span>{{ selfReviewBtnLabel }}</span></dds-button
              >
              <dds-button
                class="custom-button margin-left-xs"
                [theme]="optOutOfSelfReviewBtnOption.theme ?? _EMPTY"
                [kind]="optOutOfSelfReviewBtnOption.kind ?? _EMPTY"
                [size]="optOutOfSelfReviewBtnOption.size ?? _EMPTY"
                [width]="optOutOfSelfReviewBtnOption.width ?? _EMPTY"
                [icon]="optOutOfSelfReviewBtnOption.icon ?? _EMPTY"
                [isIconLeft]="optOutOfSelfReviewBtnOption.isIconLeft ?? false"
                [role]="optOutOfSelfReviewBtnOption.role ?? _EMPTY"
                [isInverse]="optOutOfSelfReviewBtnOption.isInverse ?? false"
                [isColorBg]="optOutOfSelfReviewBtnOption.isColorBg ?? false"
                [isLoading]="optOutOfSelfReviewBtnOption.isLoading ?? false"
                [ariaLabel]="optOutOfSelfReviewBtnLabel"
                [customClass]="
                  optOutOfSelfReviewBtnOption.customClass ?? _EMPTY
                "
                [disabled]="
                  updateSubmitButtonStatus() || !isOpporunityLevelFormCompleted
                "
                (clicked)="onclickOptOutOfSelfReviewBtn()"
                ><span>{{ optOutOfSelfReviewBtnLabel }}</span></dds-button
              >
            </div>
          </ng-container>
          <dds-button
            *ngIf="isSubmitBtnVisible()"
            class="custom-button"
            [theme]="submitButtonOptions.theme ?? _EMPTY"
            [kind]="submitButtonOptions.kind ?? _EMPTY"
            [size]="submitButtonOptions.size ?? _EMPTY"
            [width]="submitButtonOptions.width ?? _EMPTY"
            [icon]="submitButtonOptions.icon ?? _EMPTY"
            [isIconLeft]="submitButtonOptions.isIconLeft ?? false"
            [role]="submitButtonOptions.role ?? _EMPTY"
            [isInverse]="submitButtonOptions.isInverse ?? false"
            [isColorBg]="submitButtonOptions.isColorBg ?? false"
            [isLoading]="submitButtonOptions.isLoading ?? false"
            [ariaLabel]="submitLabel"
            [customClass]="submitButtonOptions.customClass ?? _EMPTY"
            [disabled]="
              !permissionObj['isIntakeSubmissionSubmitBtnEnable'] ||
              updateSubmitButtonStatus() ||
              allDocTypeSelectedIsSupporting ||
              !isOpporunityLevelFormCompleted
            "
            (clicked)="submitCommonSectionData(docStatusSubmitted)"
            ><span>{{ submitLabel }}</span></dds-button
          >

          <div class="no-break">
            <dds-button
              *ngIf="isArchiveAndCompleteBtnVisible()"
              class="custom-button margin-left-xs margin-bottom-xs"
              [theme]="archiveAndCompleteBtnOption.theme ?? _EMPTY"
              [kind]="archiveAndCompleteBtnOption.kind ?? _EMPTY"
              [size]="archiveAndCompleteBtnOption.size ?? _EMPTY"
              [width]="archiveAndCompleteBtnOption.width ?? _EMPTY"
              [icon]="archiveAndCompleteBtnOption.icon ?? _EMPTY"
              [isIconLeft]="archiveAndCompleteBtnOption.isIconLeft ?? false"
              [role]="archiveAndCompleteBtnOption.role ?? _EMPTY"
              [isInverse]="archiveAndCompleteBtnOption.isInverse ?? false"
              [isColorBg]="archiveAndCompleteBtnOption.isColorBg ?? false"
              [isLoading]="archiveAndCompleteBtnOption.isLoading ?? false"
              [ariaLabel]="archiveAndCompleteBtnLabel"
              [customClass]="archiveAndCompleteBtnOption.customClass ?? _EMPTY"
              [disabled]="
                !permissionObj[
                  'isIntakeSubmissionArchiveAndCompleteBtnEnable'
                ] ||
                isArchiveAndCompleteBtnDisabled ||
                !isOpporunityLevelFormCompleted
              "
              (clicked)="onclickArchiveAndCompleteBtn()"
              ><span>{{ archiveAndCompleteBtnLabel }}</span></dds-button
            >

            <!--TODO: Resubmit for review and resubmit call different function but does the same thing.
         Need to fix-->
            <dds-button
              *ngIf="isSubmitForReviewBtnVisible()"
              class="custom-button margin-left-xs"
              [theme]="resubmitForReviewBtnOption.theme ?? _EMPTY"
              [kind]="resubmitForReviewBtnOption.kind ?? _EMPTY"
              [size]="resubmitForReviewBtnOption.size ?? _EMPTY"
              [width]="resubmitForReviewBtnOption.width ?? _EMPTY"
              [icon]="resubmitForReviewBtnOption.icon ?? _EMPTY"
              [isIconLeft]="resubmitForReviewBtnOption.isIconLeft ?? false"
              [role]="resubmitForReviewBtnOption.role ?? _EMPTY"
              [isInverse]="resubmitForReviewBtnOption.isInverse ?? false"
              [isColorBg]="resubmitForReviewBtnOption.isColorBg ?? false"
              [isLoading]="resubmitForReviewBtnOption.isLoading ?? false"
              [ariaLabel]="reSubmitForReviewLabel"
              [customClass]="resubmitForReviewBtnOption.customClass ?? _EMPTY"
              [disabled]="
                !permissionObj['isIntakeSubmissionReSubmitBtnEnable'] ||
                isResubmitForReviewBtnDisabled ||
                !isOpporunityLevelFormCompleted
              "
              (clicked)="onclickResubmitForReviewBtn()"
              ><span>{{ reSubmitForReviewLabel }}</span></dds-button
            >
          </div>
          <dds-button
            *ngIf="isResubmitBtnVisible() && !isSelfReviewButtonVisible"
            class="custom-button"
            [theme]="submitButtonOptions.theme ?? _EMPTY"
            [kind]="submitButtonOptions.kind ?? _EMPTY"
            [size]="submitButtonOptions.size ?? _EMPTY"
            [width]="submitButtonOptions.width ?? _EMPTY"
            [icon]="submitButtonOptions.icon ?? _EMPTY"
            [isIconLeft]="submitButtonOptions.isIconLeft ?? false"
            [role]="submitButtonOptions.role ?? _EMPTY"
            [isInverse]="submitButtonOptions.isInverse ?? false"
            [isColorBg]="submitButtonOptions.isColorBg ?? false"
            [isLoading]="submitButtonOptions.isLoading ?? false"
            [ariaLabel]="'Resubmit'"
            [customClass]="submitButtonOptions.customClass ?? _EMPTY"
            [disabled]="
              !permissionObj['isIntakeSubmissionReSubmitBtnEnable'] ||
              updateSubmitButtonStatus() ||
              !isOpporunityLevelFormCompleted
            "
            (clicked)="resubmitCommonSectionData()"
            ><span>Resubmit</span></dds-button
          >
        </span>
        <!-- TODO: Showing and hiding of the buttons to be implemented once we have the employee id -->
        <div class="d-flex no-break" *ngIf="showReviewAndReturnBtn">
          <dds-button
            *ngIf="
              permissionObj['isIntakeSubmissionCompleteReviewBtnVisible'] &&
              showReviewBtn
            "
            class="custom-button padding-right-xs"
            [disabled]="
              !permissionObj['isIntakeSubmissionCompleteReviewBtnEnable'] ||
              !isOpporunityLevelFormCompleted
            "
            (clicked)="handleCompleteReviewFromHeader()"
            >Complete review</dds-button
          >
          <dds-button
            *ngIf="permissionObj['isIntakeSubmissionReturnForReworkBtnVisible']"
            class="custom-button"
            [kind]="reworkButton.kind ?? _EMPTY"
            [disabled]="
              !permissionObj['isIntakeSubmissionReturnForReworkBtnEnable'] ||
              !isOpporunityLevelFormCompleted
            "
            (clicked)="handleReturnForReworkFromHeader()"
            >Return for revisions</dds-button
          >
        </div>
      </div>
      <div class="sub-header">
        <div
          *ngIf="
            permissionObj['isIntakeSubmissionSubmissionTypeVisible'] &&
            submissionTypeCode
          "
          class="submission-type font-size-12 font-bold-600 line-height-s width-padding"
        >
          {{ submissionTypeCode }}
        </div>
        <div
          *ngIf="
            permissionObj['isIntakeSubmissionSubmissionStatusVisible'] &&
            submissionStatus
          "
          [style.color]="
            transformStatusStyle(submissionStatus) | statusStyle : 'color'
          "
          [style.backgroundColor]="
            transformStatusStyle(submissionStatus)
              | statusStyle : 'backgroundColor'
          "
          class="custom-pad margin-left-xxs font-size-12 font-bold-600 line-height-s"
        >
          {{ submissionStatus }}
        </div>
        <div
          class="id-container eclipse-content"
          *ngIf="permissionObj['isIntakeSubmissionSubmissionIdVisible']"
        >
          <ng-container *ngIf="submissionId && submissionId != 0">
            <span class="id-title">ID:</span>
            <span #textToCopy class="eclipse-content">{{ submissionId }}</span>
            <span
              class="dds-icon dds-icon_copy__l__stroke icon relative"
              (click)="copyToClipboard(textToCopy.textContent)"
            ></span>
          </ng-container>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        coHasParentOpportunityNumber &&
        permissionObj['isIntakeSubmissionELSOWOpportunityIDVisible']
      "
      class="search-elsow-section section-ending"
    >
      <app-search-elsow
        [permissionObj]="permissionObj"
        [dgmfId]="primaryDgmfId"
        [gmdmId]="primaryGmdmId"
        [swiftId]="primarySwiftId"
        [coOppDonotMatchMessage]="coOppDonotMatchMessage"
        [opportunityNumber]="opportunityNumber"
        [submissionId]="submissionId"
      ></app-search-elsow>
    </div>
    <div>
      <div
        class="styled-box-2"
        *ngIf="
          submissionStatus === RMReviewedPendingCloseout &&
          !removeBannerIfFinalizeFormAnswered
        "
      >
        <span>{{ pendingCloseOutNonSelfReviewMsg }}</span>
      </div>

      <div
        class="styled-box-2"
        *ngIf="
          submissionStatus === SelfReviewedPendingCloseout &&
          !removeBannerIfFinalizeFormAnswered
        "
      >
        <span>{{ pendingCloseOutSelfReviewMsg }}</span>
      </div>
      <div class="section-ending"></div>

      <dds-accordion [isMulti]="true">
        <ng-container
          *ngIf="
            submissionStatus === SelfReviewedPendingCloseout ||
            submissionStatus === RMReviewedPendingCloseout
          "
        >
          <ng-container *ngTemplateOutlet="NotesFeedbackSection"></ng-container>
        </ng-container>

        <ng-container
          *ngIf="
            submissionStatus === SelfReviewedPendingCloseout ||
            submissionStatus === RMReviewedPendingCloseout
          "
        >
          <ng-container *ngTemplateOutlet="DocumentsSection"></ng-container>
        </ng-container>

        <dds-accordion-item
          [isOpen]="true"
          *ngIf="isCloseOutAccordionVisible()"
        >
          <span header class="accordion-header">
            <div class="section-header">
              <span class="eclipse-section-header">{{
                isSubmissionStatusRMorSelfReviewed
                  ? "Finalize Submission"
                  : "Closeout form"
              }}</span>
            </div>
          </span>
          <dds-list-item header [isClickable]="false" [hasBorder]="false">
          </dds-list-item>
          <span content>
            <div
              class="styled-box"
              *ngIf="
                isSubmissionStatusRMorSelfReviewed &&
                !removeBannerIfFinalizeFormAnswered
              "
            >
              <span
                >After receiving feedback from the client, proceed to complete
                the submission
              </span>
            </div>
            <div class="accordion-content">
              <div class="row">
                <ng-container
                  *ngIf="
                    submissionTypeCode === intakeDocumentSubmissionTypeCodePro
                  "
                >
                  <app-close-out-proposal
                    (winningProposalChange)="
                      onChangeWinningProposalInCloseOut($event, 0)
                    "
                    [winningProposal]="model.winningProposal ?? _EMPTY"
                    [permissionObj]="permissionObj"
                    (ProposalFormYesOrNoSelected)="
                      ProposalFormYesOrNoSelected($event)
                    "
                    (ProposalFinalizeFormSelectedValue)="
                      FinalizeFormSelectedValue($event)
                    "
                    (ProposalFinalizeFormAnswered)="
                      FinalizeFormAnswered($event)
                    "
                  ></app-close-out-proposal>

                  <div
                    class="styled-box margin-top-m margin-left-vertical-s"
                    *ngIf="ProposalShowDocUploaderInFinalizeForm"
                  >
                    <span
                      >Upload the executed version of the Engagement
                      Letter/Statement of work or Change Order(i.e., the final
                      document)</span
                    >
                  </div>

                  <app-document
                    [submissionTypeId]="submissionTypeId"
                    [submissionStatusId]="submissionStatusId"
                    [submissionId]="submissionId"
                    [submissionTypeSubId]="submissionTypeSubId"
                    [progressiveStatus]="progressiveStatus"
                    [submissionTypeCode]="submissionTypeCode"
                    [submissionStatus]="submissionStatus"
                    [type]="submissionTypeCode"
                    [isDisabledDocuments]="
                      isStatusCompleted() || isDisContinued()
                    "
                    [permissionObj]="permissionObj"
                    (documentTableData)="handleDocumentTableData($event)"
                    [isDraftForSelfReview]="isSelfReviewButtonVisible"
                    [showDocumentTable]="false"
                    [ProposalShowDocUploaderInFinalizeForm]="
                      ProposalShowDocUploaderInFinalizeForm
                    "
                    [rmReviewedGuidanceNoteHeader]="
                      rmReviewedGuidanceNoteHeader
                    "
                    [rmReviewedGuidanceNoteBody]="rmReviewedGuidanceNoteBody"
                    (docUploadedInFinalizeForm)="
                      docUploadedInFinalizeForm($event)
                    "
                  ></app-document>
                </ng-container>
                <ng-container
                  *ngIf="
                    submissionTypeCode === intakeDocumentSubmissionTypeCodeCO ||
                    submissionTypeCode === intakeDocumentSubmissionTypeCodeELSOW
                  "
                >
                  <app-close-out-change-order
                    *ngIf="showCloseOutForm"
                    (coAndElSowWithWbsArchiveEventEmitter)="
                      onChangeCoAndElSowWithWbsArchiveCloseOut($event, 0)
                    "
                    [contractSignedExecuted]="
                      model.contractSignedExecuted ?? _EMPTY
                    "
                    [wbsChargeCode]="model.wbsChargeCode ?? _EMPTY"
                    [numberOfOpenArchieve]="model.numberOfOpenArchieve ?? null"
                    [wbsDescription]="model.wbsDescription ?? _EMPTY"
                    [dataSubmissionId]="submissionId"
                    [dataOpportunityId]="dataOpportunityId"
                    [archiveDetails]="archiveDetails"
                    [swiftDetails]="swiftDetails"
                    [submissionData]="submissionData"
                    [permissionObj]="permissionObj"
                    (FinalizeFormAnswered)="FinalizeFormAnswered($event)"
                    (closeOutFormYesOrNoSelected)="
                      closeOutFormYesOrNoSelected($event)
                    "
                    (COorElsowFinalizeFormSelectedValue)="
                      FinalizeFormSelectedValue($event)
                    "
                  ></app-close-out-change-order>

                  <div
                    class="styled-box margin-top-m margin-left-vertical-s"
                    *ngIf="showDocUploaderInFinalizeForm"
                  >
                    <span
                      >Upload the executed version of the Engagement
                      Letter/Statement of work or Change Order(i.e., the final
                      document)</span
                    >
                  </div>

                  <app-document
                    [submissionTypeId]="submissionTypeId"
                    [submissionTypeSubId]="submissionTypeSubId"
                    [submissionStatusId]="submissionStatusId"
                    [submissionId]="submissionId"
                    [progressiveStatus]="progressiveStatus"
                    [submissionTypeCode]="submissionTypeCode"
                    [submissionStatus]="submissionStatus"
                    [type]="submissionTypeCode"
                    [isDisabledDocuments]="
                      isStatusCompleted() || isDisContinued()
                    "
                    [permissionObj]="permissionObj"
                    (documentTableData)="handleDocumentTableData($event)"
                    [isDraftForSelfReview]="isSelfReviewButtonVisible"
                    [showDocumentTable]="false"
                    [showDocUploaderInFinalizeForm]="
                      showDocUploaderInFinalizeForm
                    "
                    [rmReviewedGuidanceNoteHeader]="
                      rmReviewedGuidanceNoteHeader
                    "
                    [rmReviewedGuidanceNoteBody]="rmReviewedGuidanceNoteBody"
                    (docUploadedInFinalizeForm)="
                      docUploadedInFinalizeForm($event)
                    "
                  ></app-document>
                </ng-container>
                <ng-container
                  *ngIf="
                    submissionTypeCode ===
                    intakeDocumentSubmissionTypeCodeDelivery
                  "
                >
                  <app-close-out-deliverable
                    (deliverableWithWbsArchiveChange)="
                      onChangeDeliverableWithWbsArchiveCloseOut($event, 0)
                    "
                    [deliverable]="
                      model.updatedVersionForReview
                        ? model.updatedVersionForReview
                        : _EMPTY
                    "
                    [wbsChargeCode]="model.wbsChargeCode ?? _EMPTY"
                    [wbsDescription]="model.wbsDescription ?? _EMPTY"
                    [dataSubmissionId]="submissionId"
                    [dataOpportunityId]="dataOpportunityId"
                    [archiveDetails]="archiveDetails"
                    [swiftDetails]="swiftDetails"
                    [submissionData]="submissionData"
                    [permissionObj]="permissionObj"
                    (deliverableFinalizeFormAnswered)="
                      FinalizeFormAnswered($event)
                    "
                    (DeliverableCloseOutFormYesOrNoSelected)="
                      DeliverableCloseOutFormYesOrNoSelected($event)
                    "
                    (DeliverableFinalizeFormSelectedValue)="
                      FinalizeFormSelectedValue($event)
                    "
                  ></app-close-out-deliverable>

                  <div
                    class="styled-box margin-top-m margin-left-vertical-s"
                    *ngIf="DeliverableShowDocUploaderInFinalizeForm"
                  >
                    <span
                      >Upload the executed version of the Engagement
                      Letter/Statement of work or Change Order(i.e., the final
                      document)</span
                    >
                  </div>

                  <app-document
                    [submissionTypeId]="submissionTypeId"
                    [submissionStatusId]="submissionStatusId"
                    [submissionTypeSubId]="submissionTypeSubId"
                    [submissionId]="submissionId"
                    [progressiveStatus]="progressiveStatus"
                    [submissionTypeCode]="submissionTypeCode"
                    [submissionStatus]="submissionStatus"
                    [type]="submissionTypeCode"
                    [isDisabledDocuments]="
                      isStatusCompleted() || isDisContinued()
                    "
                    [permissionObj]="permissionObj"
                    (documentTableData)="handleDocumentTableData($event)"
                    [isDraftForSelfReview]="isSelfReviewButtonVisible"
                    [showDocumentTable]="false"
                    [DeliverableShowDocUploaderInFinalizeForm]="
                      DeliverableShowDocUploaderInFinalizeForm
                    "
                    [rmReviewedGuidanceNoteHeader]="
                      rmReviewedGuidanceNoteHeader
                    "
                    [rmReviewedGuidanceNoteBody]="rmReviewedGuidanceNoteBody"
                    (docUploadedInFinalizeForm)="
                      docUploadedInFinalizeForm($event)
                    "
                  ></app-document>
                </ng-container>
                <ng-container
                  *ngIf="
                    submissionTypeCode === intakeDocumentSubmissionTypeCodeOther
                  "
                >
                  <app-close-out-other
                    (otherWithWbsArchiveValueChange)="
                      onChangeOtherWithWbsArchiveInCloseOut($event, 0)
                    "
                    [other]="otherSubmissionValueSelected"
                    [wbsChargeCode]="model.wbsChargeCode ?? _EMPTY"
                    [wbsDescription]="model.wbsDescription ?? _EMPTY"
                    [dataSubmissionId]="submissionId"
                    [dataOpportunityId]="dataOpportunityId"
                    [archiveDetails]="archiveDetails"
                    [swiftDetails]="swiftDetails"
                    [submissionData]="submissionData"
                    [permissionObj]="permissionObj"
                    (otherFinalizeFormAnswered)="FinalizeFormAnswered($event)"
                    (OtherCloseOutFormYesOrNoSelected)="
                      OtherCloseOutFormYesOrNoSelected($event)
                    "
                    (OtherFinalizeFormSelectedValue)="
                      FinalizeFormSelectedValue($event)
                    "
                  ></app-close-out-other>
                  <div
                    class="styled-box margin-top-m margin-left-vertical-s"
                    *ngIf="OtherShowDocUploaderInFinalizeForm"
                  >
                    <span
                      >Upload the executed version of the Engagement
                      Letter/Statement of work or Change Order(i.e., the final
                      document)</span
                    >
                  </div>

                  <app-document
                    [submissionTypeId]="submissionTypeId"
                    [submissionStatusId]="submissionStatusId"
                    [submissionId]="submissionId"
                    [progressiveStatus]="progressiveStatus"
                    [submissionTypeSubId]="submissionTypeSubId"
                    [submissionTypeCode]="submissionTypeCode"
                    [submissionStatus]="submissionStatus"
                    [type]="submissionTypeCode"
                    [isDisabledDocuments]="
                      isStatusCompleted() || isDisContinued()
                    "
                    [permissionObj]="permissionObj"
                    (documentTableData)="handleDocumentTableData($event)"
                    [isDraftForSelfReview]="isSelfReviewButtonVisible"
                    [showDocumentTable]="false"
                    [OtherShowDocUploaderInFinalizeForm]="
                      OtherShowDocUploaderInFinalizeForm
                    "
                    [rmReviewedGuidanceNoteHeader]="
                      rmReviewedGuidanceNoteHeader
                    "
                    [rmReviewedGuidanceNoteBody]="rmReviewedGuidanceNoteBody"
                    (docUploadedInFinalizeForm)="
                      docUploadedInFinalizeForm($event)
                    "
                  ></app-document>
                </ng-container>
              </div></div
          ></span>
        </dds-accordion-item>

        <dds-accordion-item
          [isOpen]="isCOAccordionOpen() || collapseAccordion"
          *ngIf="
            isAccordionVisible(intakeDocumentSubmissionTypeCodeCO) &&
            permissionObj['isIntakeSubmissionChangeOrderVisible']
          "
        >
          <span header class="accordion-header">
            <div class="section-header">
              <span class="eclipse-section-header">Change Order</span>
            </div>
          </span>
          <dds-list-item header [isClickable]="false" [hasBorder]="false">
          </dds-list-item>
          <span content>
            <div class="accordion-content">
              <div>
                <div class="row">
                  <div>
                    <span
                      *ngIf="!model.coChangeScope"
                      class="icon-required-yellow"
                    ></span>
                    <span>{{ COChangeScope_radioGroupOptions.title }}</span>
                  </div>

                  <div class="col-12">
                    <div class="textarea-container">
                      <div class="row padding-top-xs">
                        <div class="col-12">
                          <dds-radio-group
                            autosave
                            [disabled]="
                              isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                              !permissionObj[
                                'isIntakeSubmissionChangeOrderEnable'
                              ]
                            "
                            [(ngModel)]="model.coChangeScope"
                            [name]="COChangeScope_radioGroupOptions.name"
                            [isUncheckable]="
                              COChangeScope_radioGroupOptions.isUncheckable
                            "
                            [isRequired]="
                              COChangeScope_radioGroupOptions.isRequired
                            "
                            [errorState]="
                              COChangeScope_radioGroupOptions.errorState
                            "
                            (stateChanged)="stateHandling()"
                          >
                            <div class="d-flex">
                              <ng-container
                                *ngFor="let radio of COChangeScope_radio_values"
                              >
                                <dds-radio
                                  [value]="radio.value"
                                  [customClass]="
                                    'padding-right-half-rem padding-right-vs'
                                  "
                                  [label]="radio.options.label"
                                  [theme]="radio.options.theme"
                                ></dds-radio>
                                <br />
                              </ng-container>
                            </div>
                          </dds-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div>
                    <span
                      *ngIf="!model.coDescription && !disableFieldOnComplete()"
                      class="icon-required-yellow"
                    ></span>
                    <span>Provide Change Order description</span>
                  </div>

                  <div class="col-12">
                    <div class="textarea-container">
                      <div class="row padding-top-xs">
                        <div class="col-12">
                          <dds-textarea
                            autosave
                            name="coDescription"
                            [(ngModel)]="model.coDescription"
                            [label]="textareaOptions.label"
                            [labelPosition]="textareaOptions.labelPosition"
                            [placeholder]="textareaOptions.placeholder"
                            [description]="textareaOptions.description"
                            [minHeight]="textareaOptions.minHeight"
                            [isRequired]="textareaOptions.isRequired"
                            [customClass]="textareaOptions.customClass"
                            [isError]="textareaOptions.isError"
                            [errorMessage]="textareaOptions.errorMessage"
                            [errorState]="textareaOptions.errorState"
                            [maxLength]="textareaOptions.maxLength"
                            [readonly]="
                              isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                              !permissionObj[
                                'isIntakeSubmissionChangeOrderEnable'
                              ]
                            "
                            [maxLengthPosition]="
                              textareaOptions.maxLengthPosition
                            "
                            [size]="textareaOptions.size"
                            (valueChanged)="CODescriptionValueChanged()"
                          ></dds-textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div></div
          ></span>
        </dds-accordion-item>
        <dds-accordion-item [isOpen]="collapseSelfReviewAccordion">
          <span header class="accordion-header">
            <div class="section-header">
              <span class="eclipse-section-header"
                >Self-review eligibility</span
              >
            </div>
          </span>
          <dds-list-item header [isClickable]="false" [hasBorder]="false">
          </dds-list-item>
          <span content>
            <div class="accordion-content">
              <div class="accordion-container">
                <div
                  class="self-review-div"
                  *ngIf="
                    permissionObj[
                      'isIntakeSubmissionEligibleForSelfReviewVisible'
                    ]
                  "
                >
                  <div
                    class="comments-text-label eclipse-small-header-grey font-size-11"
                  >
                    Eligibile for self-review
                  </div>
                  <div class="value-font">{{ selfReviewEligibility }}</div>
                </div>
                <div
                  class="submission-title-container self-review-div"
                  *ngIf="
                    permissionObj['isIntakeSubmissionSubmissionTitleVisible']
                  "
                >
                  <div class="d-flex align-items-center">
                    <span
                      *ngIf="!model.submissionTitle"
                      class="icon-required-yellow"
                    ></span>
                    <div
                      class="comments-text-label eclipse-small-header-grey font-size-11"
                    >
                      Submission title
                    </div>
                  </div>

                  <div
                    class="input-container"
                    (click)="$event.stopPropagation()"
                  >
                    <ng-container *ngIf="isEditingTitle; else displayTitle">
                      <div class="col-12">
                        <dds-input
                          autosave
                          [placeholder]="enter_text_placeholder"
                          [(ngModel)]="model.submissionTitle"
                          id="title-editor"
                        >
                        </dds-input>
                      </div>
                    </ng-container>
                    <ng-template #displayTitle>
                      <span class="title-value value-font">{{
                        titleValue
                      }}</span>
                      <span
                        *ngIf="
                          !isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() &&
                          permissionObj[
                            'isIntakeSubmissionSubmissionTitleEnable'
                          ]
                        "
                        class="dds-icon dds-icon_edit__s__filled edit-icon"
                        (click)="editTitle()"
                      ></span>
                    </ng-template>
                  </div>
                </div>
                <div *ngIf="optOutOfSelfReviewReason" class="self-review-div">
                  <div
                    class="comments-text-label eclipse-small-header-grey font-size-11"
                  >
                    Reason for opt-out self-review:
                  </div>
                  <div>
                    {{ optOutOfSelfReviewReason }}
                  </div>
                </div>
              </div>
            </div></span
          >
        </dds-accordion-item>

        <dds-accordion-item
          *ngIf="isAccordionVisible(intakeDocumentSubmissionTypeCodeOther)"
          [isOpen]="
            (isOtherAccordionOpen() &&
              permissionObj['isIntakeSubmissionOtherVisible']) ||
            collapseAccordion
          "
        >
          <span header class="accordion-header">
            <div class="section-header">
              <span class="eclipse-section-header">Other</span>
            </div>
          </span>
          <dds-list-item header [isClickable]="false" [hasBorder]="false">
          </dds-list-item>
          <span content>
            <div class="accordion-content">
              <div class="row">
                <div>
                  <span
                    *ngIf="!model.otherDescription"
                    class="icon-required-yellow"
                  ></span>
                  <span>Submission description</span>
                </div>

                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row padding-top-xs">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="otherDescription"
                          [disabled]="disableFieldOnComplete()"
                          [(ngModel)]="model.otherDescription"
                          [label]="textareaOptions.label"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="textareaOptions.placeholder"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="textareaOptions.maxLength"
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          [readonly]="
                            isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                            !permissionObj['isIntakeSubmissionOtherEnable']
                          "
                          (valueChanged)="OtherDescriptionValueChanged()"
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="others-subtype">
                <div>
                  <span
                    *ngIf="
                      !model.submissionSubTypeId && !disableFieldOnComplete()
                    "
                    class="icon-required-yellow"
                  ></span>
                  <span class="eclipse-small-header-grey font-size-11">{{
                    other_subtype_radioGroupOptions.title
                  }}</span>
                </div>
                <div class="value-font" *ngIf="otherSubTypes$ | async">
                  {{ getSubmissionSubType(model.submissionSubTypeId) }}
                </div>
              </div>
            </div></span
          >
        </dds-accordion-item>

        <dds-accordion-item
          *ngIf="
            isAccordionVisible(intakeDocumentSubmissionTypeCodePro) &&
            permissionObj['isIntakeSubmissionProposalVisible']
          "
          [isOpen]="isProposalAccordionOpen()"
        >
          <span header class="accordion-header">
            <div class="section-header">
              <span class="eclipse-section-header">Proposal</span>
            </div>
          </span>
          <dds-list-item header [isClickable]="false" [hasBorder]="false">
          </dds-list-item>
          <span content>
            <div class="accordion-content">
              <div class="row">
                <div>
                  <span
                    *ngIf="
                      !model.proposalDescription && !disableFieldOnComplete()
                    "
                    class="icon-required-yellow"
                  ></span>
                  <span>Describe Proposal</span>
                </div>

                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row padding-top-xs">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="proposalDescription"
                          [(ngModel)]="model.proposalDescription"
                          [label]="textareaOptions.label"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="textareaOptions.placeholder"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="textareaOptions.maxLength"
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          [readonly]="
                            isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                            !permissionObj['isIntakeSubmissionProposalEnable']
                          "
                          (valueChanged)="proposalDescriptionValueChanged()"
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div>
                  <span
                    *ngIf="!model.relatedToRFP && !disableFieldOnComplete()"
                    class="icon-required-yellow"
                  ></span>
                  <span>{{ RFP_radioGroupOptions.title }}</span>
                </div>

                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row padding-top-xs">
                      <div class="col-12">
                        <dds-radio-group
                          autosave
                          [disabled]="disableFieldOnComplete()"
                          [(ngModel)]="model.relatedToRFP"
                          [name]="RFP_radioGroupOptions.name"
                          [isUncheckable]="RFP_radioGroupOptions.isUncheckable"
                          [isRequired]="RFP_radioGroupOptions.isRequired"
                          [errorState]="RFP_radioGroupOptions.errorState"
                          (stateChanged)="RelatedtoRFPstateChanged()"
                          [disabled]="
                            isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                            !permissionObj['isIntakeSubmissionProposalEnable']
                          "
                        >
                          <ng-container *ngFor="let radio of REP_radio_values">
                            <dds-radio
                              [value]="radio.value"
                              [label]="radio.options.label"
                              [theme]="radio.options.theme"
                            ></dds-radio>
                            <br />
                          </ng-container>
                        </dds-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div></div
          ></span>
        </dds-accordion-item>
        <dds-accordion-item
          [isOpen]="isDeliverableAccordionOpen() || collapseAccordion"
          *ngIf="
            isAccordionVisible(intakeDocumentSubmissionTypeCodeDelivery) &&
            permissionObj['isIntakeSubmissionDeliverableVisible']
          "
        >
          <span header class="accordion-header">
            <div class="section-header">
              <span class="eclipse-section-header">Deliverable</span>
            </div>
          </span>
          <dds-list-item header [isClickable]="false" [hasBorder]="false">
          </dds-list-item>
          <span content>
            <div class="accordion-content">
              <div>
                <span
                  *ngIf="!model.delDescription && !disableFieldOnComplete()"
                  class="icon-required-yellow"
                ></span>
                <span>Provide deliverable description</span>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row padding-top-xs">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          name="delDescription"
                          [disabled]="disableFieldOnComplete()"
                          [(ngModel)]="model.delDescription"
                          [label]="textareaOptions.label"
                          [labelPosition]="textareaOptions.labelPosition"
                          [placeholder]="textareaOptions.placeholder"
                          [description]="textareaOptions.description"
                          [minHeight]="textareaOptions.minHeight"
                          [isRequired]="textareaOptions.isRequired"
                          [customClass]="textareaOptions.customClass"
                          [isError]="textareaOptions.isError"
                          [errorMessage]="textareaOptions.errorMessage"
                          [errorState]="textareaOptions.errorState"
                          [maxLength]="textareaOptions.maxLength"
                          [maxLengthPosition]="
                            textareaOptions.maxLengthPosition
                          "
                          [size]="textareaOptions.size"
                          [readonly]="
                            isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued() ||
                            !permissionObj[
                              'isIntakeSubmissionDeliverableEnable'
                            ]
                          "
                          (valueChanged)="deliverableDescValueChanged()"
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div></div
          ></span>
        </dds-accordion-item>
        <ng-container
          *ngIf="
            !(
              submissionStatus === SelfReviewedPendingCloseout ||
              submissionStatus === RMReviewedPendingCloseout
            )
          "
        >
          <ng-container *ngTemplateOutlet="DocumentsSection"></ng-container>
        </ng-container>

        <ng-template #DocumentsSection>
          <dds-accordion-item [isOpen]="true">
            <span header class="accordion-header">
              <div class="section-header">
                <span class="eclipse-section-header">Documents</span>
              </div>
            </span>
            <dds-list-item header [isClickable]="false" [hasBorder]="false">
            </dds-list-item>

            <span content>
              <div class="accordion-content">
                <div *ngIf="isSelfReviewButtonVisible" class="styled-box-green">
                  <img
                    src="\assets\images\green-pin-icon.svg"
                    alt="lock-icon"
                  />
                  {{ selfReviewDocBanner }}
                </div>
                <div
                  *ngIf="
                    submissionTypeCode === intakeDocumentSubmissionTypeCodeCO ||
                    submissionTypeCode === intakeDocumentSubmissionTypeCodeELSOW
                  "
                >
                  <div
                    class="styled-box"
                    *ngIf="showUploadGuidanceYellowMsgForCoAndElSowOnYes"
                  >
                    <span
                      >Upload the executed version of the Engagement
                      Letter/Statement of Work or Change Order (i.e., the final
                      document).</span
                    >
                  </div>
                  <div
                    class="margin-bottom-xs"
                    *ngIf="showUploadGuidanceInfoMsgForCoAndElSowOnYes"
                  >
                    <span
                      class="eclipse-icon icon-s icon-bullet-star-green"
                    ></span>
                    <span>
                      All archive ready documents will be moved to Archive It
                      after submission completion.</span
                    >
                  </div>

                  <div
                    class="styled-box padding-top-s"
                    *ngIf="showInfoMsgForCoElSowOnSelectNoResubmit"
                  >
                    <span
                      >Upload the updated document that requires additional Risk
                      Manager review</span
                    >
                  </div>
                </div>

                <div
                  *ngIf="
                    isDocumentRequireMsgShow &&
                    submissionTypeCode === intakeDocumentSubmissionTypeCodePro
                  "
                >
                  <div
                    class="styled-box"
                    *ngIf="isFinalDocumentAcceptedMsgShow"
                  >
                    <span
                      >Upload the final document presented to/accepted by the
                      client</span
                    >
                  </div>
                  <div class="styled-box padding-top-s">
                    <span
                      class="eclipse-icon icon-s icon-bullet-star-green"
                    ></span>
                    <span>
                      All archive ready documents will be moved to Archive It
                      after submission completion.</span
                    >
                  </div>
                </div>

                <div
                  *ngIf="
                    showUploadFinalVersionMessage &&
                    (submissionTypeCode != intakeDocumentSubmissionTypeCodeCO ||
                      submissionTypeCode !=
                        intakeDocumentSubmissionTypeCodeELSOW)
                  "
                >
                  <div class="styled-box">
                    <span>Upload the final version of the document</span>
                  </div>
                  <div class="styled-box padding-top-s">
                    <span
                      class="eclipse-icon icon-s icon-bullet-star-green"
                    ></span>
                    <span>
                      All archive ready documents will be moved to Archive It
                      after submission completion.</span
                    >
                  </div>
                </div>

                <div *ngIf="showInformationalGuidanceForDeliverable">
                  <div class="styled-box">
                    <span
                      >Upload the final document presented to/accepted by the
                      client.</span
                    >
                  </div>
                  <div class="styled-box padding-top-s">
                    <span
                      class="eclipse-icon icon-s icon-bullet-star-green"
                    ></span>
                    <span>
                      All archive ready documents will be transferred to Archive
                      It once the submission status is updated to
                      completed</span
                    >
                  </div>
                </div>

                <div *ngIf="showUpdatedDocumentMessage">
                  <div class="styled-box">
                    <span
                      >Upload the updated document that requires additional Risk
                      Manager review</span
                    >
                  </div>
                </div>

                <div
                  *ngIf="
                    !(
                      submissionStatus === SelfReviewedPendingCloseout ||
                      submissionStatus === RMReviewedPendingCloseout
                    )
                  "
                >
                  <ng-container
                    *ngIf="
                      permissionObj['isIntakeSubmissionLEPConfirmationVisible']
                    "
                  >
                    <div
                      *ngIf="showLepConfirmationMessage; else showLEPMessage"
                      class="styled-box d-flex"
                    >
                      <span class="lep-message-padding">
                        Confirm the LEP
                        {{
                          this.dataLepValue.lepName !== null
                            ? this.dataLepValue.lepName
                            : ""
                        }}, has completed their review of the document(s), prior
                        to uploading and submitting for review
                      </span>
                      <dds-button
                        class="button"
                        (clicked)="handleLEPCompletion()"
                        >Confirm LEP review completion</dds-button
                      >
                    </div>
                  </ng-container>
                  <ng-template #showLEPMessage>
                    <div>
                      <div *ngIf="!isValidDocumentUploaded" class="styled-box">
                        {{ configLableDocument }}
                      </div>
                      <div
                        class="comments-text-label eclipse-small-header-grey font-size-11"
                      >
                        LEP review:
                      </div>
                      <div class="col-12">
                        <p class="margin-bottom-none">
                          Acknowledged by {{ lepAcknowledgedBy }}:
                          {{ lepAcknowledgementDate }},
                        </p>
                        <p>
                          confirming that {{ lepReviewedBy }} has reviewed the
                          document.
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div *ngIf="!showLepConfirmationMessage">
                  <app-document
                    [submissionTypeId]="submissionTypeId"
                    [submissionStatusId]="submissionStatusId"
                    [submissionTypeSubId]="submissionTypeSubId"
                    [submissionId]="submissionId"
                    [progressiveStatus]="progressiveStatus"
                    [submissionTypeCode]="submissionTypeCode"
                    [submissionStatus]="submissionStatus"
                    [type]="submissionTypeCode"
                    [isDisabledDocuments]="
                      isStatusCompleted() || isDisContinued()
                    "
                    [permissionObj]="permissionObj"
                    [isDraftForSelfReview]="isSelfReviewButtonVisible"
                    [showDocumentTable]="true"
                    [opportunityDocs]="opportunityDocs"
                    [rmReviewedGuidanceNoteHeader]="
                      rmReviewedGuidanceNoteHeader
                    "
                    [rmReviewedGuidanceNoteBody]="rmReviewedGuidanceNoteBody"
                    (documentTableData)="handleDocumentTableData($event)"
                  ></app-document>
                </div>
                <div
                  class="row"
                  *ngIf="
                    isAccordionVisible(intakeDocumentSubmissionTypeCodeDelivery)
                  "
                >
                  <div class="col-12">
                    <div class="sub-text-section relative">
                      <span class="icon-s icon-pinned"></span>
                      <span>
                        <div class="text-label font-bold-600">
                          The LEP is ultimately responsible for the quality and
                          risk assessment regarding the deliverable.
                        </div>
                        <div
                          class="vertical-line-green absolute"
                          *ngIf="isShowMoreDocumentNotes"
                        ></div>
                      </span>
                    </div>
                    <div class="padding-left-s">
                      <span>
                        <div
                          class="margin-top-xs text-gray"
                          *ngIf="isShowMoreDocumentNotes"
                        >
                          <div class="padding-bottom-s">
                            Deliverables requiring Risk Manager review may be
                            submitted.
                          </div>
                          <ul>
                            <li class="padding-bottom-s">
                              <div
                                class="submission-type font-size-12 font-bold-600 line-height-s"
                              >
                                Sufficient documentation:
                              </div>
                              Prepare and maintain sufficient documentation to
                              support the results or information in the report
                              or deliverables.
                            </li>
                            <li class="padding-bottom-s">
                              <div
                                class="submission-type font-size-12 font-bold-600 line-height-s"
                              >
                                Primary and overriding reviews:
                              </div>
                              Conduct reviews of supporting documentation before
                              submitting deliverables for Risk Manager review.
                            </li>
                            <li class="padding-bottom-s">
                              <div
                                class="submission-type font-size-12 font-bold-600 line-height-s"
                              >
                                Deliverable modification consultation:
                              </div>
                              Consult with the Risk Manager if (i) the client or
                              their advisor disputes our findings, or (ii) The
                              client requests changes that could soften or
                              diminish our findings.
                            </li>
                          </ul>
                        </div>
                      </span>
                      <dds-button
                        class="showMoreCls"
                        [theme]="showMoreOptions.theme ?? _EMPTY"
                        [kind]="showMoreOptions.kind ?? _EMPTY"
                        [size]="showMoreOptions.size ?? _EMPTY"
                        [width]="showMoreOptions.width ?? _EMPTY"
                        [icon]="showMoreOptions.icon ?? _EMPTY"
                        [isIconLeft]="showMoreOptions.isIconLeft ?? false"
                        [role]="showMoreOptions.role ?? _EMPTY"
                        [ariaLabel]="showMoreOptions.ariaLabel ?? _EMPTY"
                        [customClass]="showMoreOptions.customClass ?? _EMPTY"
                        (clicked)="clickToShowDocumentMoreLepMessage()"
                        >{{ showMoreText }}</dds-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </dds-accordion-item>
        </ng-template>

        <ng-container
          *ngIf="
            !(
              submissionStatus === SelfReviewedPendingCloseout ||
              submissionStatus === RMReviewedPendingCloseout
            )
          "
        >
          <ng-container *ngTemplateOutlet="NotesFeedbackSection"></ng-container>
        </ng-container>

        <ng-template #NotesFeedbackSection>
          <dds-accordion-item [isOpen]="true">
            <span header class="accordion-header">
              <div class="section-header">
                <span class="eclipse-section-header">Notes/Feedback</span>
              </div>
            </span>
            <dds-list-item header [isClickable]="false" [hasBorder]="false">
            </dds-list-item>
            <span content>
              <div class="accordion-content">
                <div
                  class="notes-paragraph eclipse-content-grey padding-bottom-16"
                  *ngIf="!isSubmissionStatusRMorSelfReviewed"
                >
                  Use this field to (i) provide notes about any relevant
                  history, unusual aspects, or unique characteristics of the
                  engagement that reviewers should be aware of or (ii) view
                  feedback from reviewers on the document
                </div>
                <span
                  *ngIf="
                    checkStatusCompletedByAdmin() || checkStatusDiscontinued()
                  "
                  class="padding-bottom-m"
                >
                  <div class="sub-text-section margin-top-xs">
                    <span class="icon-s icon-alert"></span>
                    <span>
                      <div
                        *ngIf="checkStatusCompletedByAdmin()"
                        class="eclipse-header-grey"
                      >
                        Submission admin closed
                      </div>
                      <div
                        *ngIf="checkStatusDiscontinued()"
                        class="eclipse-header-grey"
                      >
                        Submission Discontinued
                      </div>
                      <div
                        class="vertical-line-green-s absolute padding-bottom-xxs"
                        *ngIf="isShowMoreDocumentNotes"
                      ></div>
                    </span>
                  </div>
                  <div class="padding-left-m">
                    <span>
                      <div
                        class="margin-top-xs"
                        *ngIf="isShowMoreDocumentNotes"
                      >
                        <div *ngFor="let history of submissionActionHistory">
                          <div
                            class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                          >
                            {{ history.resetedOn }} by {{ history.resetedBy }}
                          </div>
                          <div
                            class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                          >
                            Reason: {{ history.reason }}
                          </div>
                          <div
                            *ngIf="checkStatusCompletedByAdmin()"
                            class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                          >
                            Additional details: {{ history.additionalDetails }}
                          </div>
                        </div>
                      </div>
                    </span>
                    <dds-button
                      class="showMoreCls"
                      [theme]="showMoreOptions.theme ?? _EMPTY"
                      [kind]="showMoreOptions.kind ?? _EMPTY"
                      [size]="showMoreOptions.size ?? _EMPTY"
                      [width]="showMoreOptions.width ?? _EMPTY"
                      [icon]="showMoreOptions.icon ?? _EMPTY"
                      [isIconLeft]="showMoreOptions.isIconLeft ?? false"
                      [role]="showMoreOptions.role ?? _EMPTY"
                      [ariaLabel]="showMoreText"
                      [customClass]="showMoreOptions.customClass ?? _EMPTY"
                      (clicked)="clickToShowDocumentMoreLepMessage()"
                      >{{ showMoreText }}</dds-button
                    >
                  </div>
                </span>
                <span *ngIf="showResetActionMessage" class="padding-bottom-m">
                  <div>
                    <span>
                      <div class="margin-top-xs">
                        <div>
                          <div
                            class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                          >
                            <div>
                              Submission reset from
                              {{ resetActionMessage?.previousStatus }} to
                              {{ resetActionMessage?.currentStatus }} on
                              {{ resetActionMessage?.resetedOn }} by
                              {{ resetActionMessage?.resetedBy }}.
                            </div>
                            <div>
                              <span
                                >Reason: {{ resetActionMessage?.reason }}</span
                              >
                              <span
                                *ngIf="
                                  resetActionMessage?.otherReasonDescription
                                "
                                class="padding-left-xs"
                                >{{
                                  resetActionMessage?.otherReasonDescription
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div></span
                    >
                  </div>
                </span>
                <div>
                  <div class="row">
                    <div class="col-12">
                      <div class="textarea-container">
                        <div class="row">
                          <div class="col-12">
                            <dds-textarea
                              autosave
                              name="notesAndFeedback"
                              [(ngModel)]="model.notesAndFeedback"
                              [labelPosition]="textareaOptions.labelPosition"
                              [placeholder]="enter_text_placeholder"
                              [description]="textareaOptions.description"
                              [minHeight]="textareaOptions.minHeight"
                              [isRequired]="textareaOptions.isRequired"
                              [customClass]="textareaOptions.customClass"
                              [isError]="textareaOptions.isError"
                              [errorMessage]="textareaOptions.errorMessage"
                              [errorState]="textareaOptions.errorState"
                              [maxLength]="
                                internalQrmCommunicationTextareaMaxlength
                              "
                              [maxLengthPosition]="
                                textareaOptions.maxLengthPosition
                              "
                              [size]="textareaOptions.size"
                              [readonly]="
                                isStatusCompleted() ||
                                !permissionObj[
                                  'isIntakeSubmissionNotesAndFeedbackEnable'
                                ]
                              "
                              (valueChanged)="feedbackInputValueChanged()"
                            ></dds-textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div></div
            ></span>
          </dds-accordion-item>
        </ng-template>
        <dds-accordion-item
          [isOpen]="!isSelfReviewButtonVisible || collapseAccordion"
        >
          <span header class="accordion-header">
            <div class="section-header">
              <span class="eclipse-section-header">Workflow</span>
            </div>
          </span>
          <dds-list-item header [isClickable]="false" [hasBorder]="false">
          </dds-list-item>
          <span content>
            <div class="accordion-content relative">
              <div class="div-headers">
                <table class="dds-data-table dds-data-table_selection">
                  <thead class="dds-data-table__header">
                    <tr>
                      <th
                        class="dds-data-table__header-cell"
                        *ngIf="
                          !isStatusCompleted() &&
                          !isDisContinued() &&
                          permissionObj[
                            'isIntakeSubmissionWorkflowCheckboxVisible'
                          ]
                        "
                      >
                        Required
                      </th>
                      <th
                        *ngFor="let column of riskManagerTableHeader"
                        class="dds-data-table__header-cell"
                        [ngStyle]="{
                          width: column.width,
                          minWidth: column.minWidth
                        }"
                      >
                        <div class="dds-data-table__content">
                          <span>{{ column.header || column.name }}</span>
                        </div>
                      </th>
                      <th class="dds-data-table__header-cell">
                        <span [hidden]="!showActionsHeader">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="dds-data-table__body">
                    <tr
                      *ngFor="
                        let row of updatedRiskManagerDetails;
                        index as index
                      "
                      class="dds-data-table__row"
                    >
                      <td
                        class="dds-data-table__cell table-checkbox-cell"
                        *ngIf="
                          !isStatusCompleted() &&
                          !isDisContinued() &&
                          permissionObj[
                            'isIntakeSubmissionWorkflowCheckboxVisible'
                          ]
                        "
                      >
                        <dds-checkbox
                          *ngIf="
                            (row.roleName === roleRiskManager &&
                              !showRiskManagerLabel) ||
                            (row.roleName === roleSupportingRiskManager &&
                              !showSupportingRiskManagerLabel) ||
                            (row.roleName === roleSpecializedRiskManager &&
                              !showSpecializedRiskManagerLabel) ||
                            row.roleName === roleTechnicalOrIndependentRM ||
                            row.roleName === roleAdditionalReviewer
                          "
                          class="custom-checkbox-class"
                          [ariaLabel]="'row ' + (index + 1)"
                          [value]="
                            isReviewerCheckboxChecked(row.isRequired, index)
                          "
                          theme="green"
                          [disabled]="
                            isReviewerCheckboxDisabled(row.emailId, index)
                          "
                          (click)="toggleRow(row.emailId, index)"
                        ></dds-checkbox>
                      </td>
                      <td class="dds-data-table__cell">
                        <div
                          *ngIf="
                            !row.isEditable ||
                              (row.isEditable && !row.isEditing);
                            else displayPeoplePicker
                          "
                        >
                          <span
                            class="comments-text-label eclipse-small-header-grey font-size-11 d-block"
                            >{{ row.roleName }}
                          </span>

                          <span class="profile-name d-inline-block">{{
                            row.displayName || row?.fullName
                          }}</span>

                          <ng-container *ngIf="row.isEditable">
                            <span
                              *ngIf="!disableFieldOnComplete()"
                              class="dds-icon dds-icon_edit__s__filled edit-icon"
                              [ngClass]="{
                                'pe-none opacity-50': isEditDeleteRowDisabled(
                                  row.roleName
                                )
                              }"
                              (click)="
                                $event.stopPropagation();
                                editReviewer(
                                  row.emailId,
                                  row.submissionApproverId ?? -1,
                                  row.isForTechnicalReviewer ?? false
                                )
                              "
                            ></span>
                            <span
                              class="margin-left-s dds-icon dds-icon_delete__l__stroke"
                              [ngClass]="{
                                'pe-none opacity-50': isEditDeleteRowDisabled(
                                  row.roleName
                                )
                              }"
                              (click)="
                                $event.stopPropagation();
                                clearReviewer(
                                  row.emailId,
                                  row.isForTechnicalReviewer
                                )
                              "
                            ></span
                          ></ng-container>
                        </div>
                        <ng-template #displayPeoplePicker>
                          <ng-container
                            *ngIf="
                              isEditingReviewer(row) ||
                              isEditingTechnicalReviewer(row)
                            "
                          >
                            <app-people-picker
                              [showLabel]="true"
                              [labelText]="
                                isEditingTechnicalReviewer(row)
                                  ? roleTechnicalOrIndependentRM
                                  : roleAdditionalReviewer
                              "
                              [isTechinalReviewerSearch]="
                                isEditingTechnicalReviewer(row) ? true : false
                              "
                              (userSelected)="
                                onMemberSelected(
                                  row.emailId,
                                  row.reviewerId,
                                  $event
                                )
                              "
                              [id]="'picker-' + index"
                            ></app-people-picker>
                          </ng-container>
                        </ng-template>
                      </td>
                      <td class="dds-data-table__cell">
                        <!-- 
                      TODO: Once Employee ID is implemented this needs to be checked
                      <div
                        *ngIf="
                          loggedInEmployeeId === row.reviewerId &&
                          submissionStatus === 'Submitted' &&
                          row.statusReviewedDate === null
                        "
                        class="d-flex"
                      > -->
                        <div
                          *ngIf="
                            showReviewAndReturnBtn &&
                            row.statusReviewedDate === null &&
                            row.reviewerId === currentLoggedInUser &&
                            permissionObj[
                              'isIntakeSubmissionWorkflowActionVisible'
                            ]
                          "
                          class="d-flex"
                        >
                          <dds-button
                            class="custom-button padding-right-xs"
                            [disabled]="
                              !permissionObj[
                                'isIntakeSubmissionWorkflowActionEnable'
                              ]
                            "
                            (clicked)="handleCompleteReview(row)"
                            >Complete review</dds-button
                          >

                          <dds-button
                            class="custom-button"
                            [kind]="reworkButton.kind ?? _EMPTY"
                            [disabled]="
                              !permissionObj[
                                'isIntakeSubmissionWorkflowActionEnable'
                              ]
                            "
                            (clicked)="handleReturnforRework(row)"
                            >Return for revisions</dds-button
                          >
                        </div>
                        <div *ngIf="row.statusReviewedDate !== null">
                          <div class="flex-row-col">
                            <p class="text-green-2">Review Completed</p>
                            <p>{{ row.statusReviewedDate }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <!-- TODO: *ngIf="isEmployeePresent" once employee id is integrated -->
                  <dds-button
                    *ngIf="
                      (!isStatusCompleted() || !isDisContinued()) &&
                      permissionObj[
                        'isIntakeSubmissionWorkflowAddReviewerVisible'
                      ]
                    "
                    [theme]="options.theme ?? _EMPTY"
                    [kind]="options.kind ?? _EMPTY"
                    [size]="options.size ?? _EMPTY"
                    [width]="options.width ?? _EMPTY"
                    [icon]="options.icon ?? _EMPTY"
                    [isIconLeft]="options.isIconLeft ?? false"
                    [role]="options.role ?? _EMPTY"
                    [isInverse]="options.isInverse ?? false"
                    [isColorBg]="options.isColorBg ?? false"
                    [isLoading]="options.isLoading ?? false"
                    [ariaLabel]="options.ariaLabel ?? _EMPTY"
                    [customClass]="options.customClass ?? _EMPTY"
                    [disabled]="isAddReviewerButtonDisabled"
                    (clicked)="
                      $event.stopPropagation();
                      addReviewer(roleAdditionalReviewer)
                    "
                    >Add reviewer</dds-button
                  >
                </table>
              </div>
            </div></span
          >
        </dds-accordion-item>
        <dds-accordion-item *ngIf="isInternalQRMVisible" [isOpen]="true">
          <span header class="accordion-header">
            <div class="section-header margin-bottom-s">
              <span class="eclipse-section-header"
                >Internal QRM team communication</span
              >
            </div>
          </span>
          <dds-list-item header [isClickable]="false" [hasBorder]="false">
          </dds-list-item>
          <span content>
            <div class="accordion-content">
              <div>
                <div class="row">
                  <div class="col-12">
                    <div
                      class="text-label eclipse-content-grey padding-bottom-s"
                    >
                      Viewable by QRM team only, purged 1 year after "Complete"
                      status
                    </div>
                    <div class="textarea-container">
                      <div class="row">
                        <div class="col-12">
                          <dds-textarea
                            autosave
                            name="internalQRMTeamCommunication"
                            [(ngModel)]="model.internalQRMTeamCommunication"
                            [labelPosition]="textareaOptions.labelPosition"
                            [placeholder]="'Enter your comments'"
                            [description]="textareaOptions.description"
                            [minHeight]="textareaOptions.minHeight"
                            [isRequired]="textareaOptions.isRequired"
                            [customClass]="textareaOptions.customClass"
                            [isError]="textareaOptions.isError"
                            [errorMessage]="textareaOptions.errorMessage"
                            [errorState]="textareaOptions.errorState"
                            [maxLength]="
                              internalQrmCommunicationTextareaMaxlength
                            "
                            [maxLengthPosition]="
                              textareaOptions.maxLengthPosition
                            "
                            [size]="textareaOptions.size"
                            [readonly]="isInternalQRMDisabled"
                            (valueChanged)="
                              internalQrmCommunicationInputValueChanged()
                            "
                          ></dds-textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div></div
          ></span>
        </dds-accordion-item>
      </dds-accordion>
    </div>
  </div>
</ng-container>
