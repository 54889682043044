import { WatchlistGridComponent } from '../watchlist/watchlist-grid/watchlist-grid.component';
import { WatchlistAddComponent } from './watchlist-add/watchlist-add.component';
import { WatchlistAuditComponent } from '../watchlist/watchlist-audit/watchlist-audit.component'

export const Watchlist_ROUTES = [
  {
    path: '',
    children: [
      {
        path: '',
        component: WatchlistGridComponent,
      },
      {
        path: 'watchlist/add',
        component: WatchlistAddComponent,
      },
      {
        path: 'watchlist/audit-trail/watchlistId/:watchlistId',
        component: WatchlistAuditComponent,
      }
    ],
  },
];
