import { Component, Input, OnInit } from '@angular/core';
import { OpportunityDetailsNavModel } from '../../../../common/models/opportunity-details.model';
import { Router } from '@angular/router';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { IntakeDocumentSubmissionTypeCode } from '../../../constants/intake_submission_type_code.enum';
import { OptOutPopupComponent } from '../../../intake-common-popups/opt-out-popup/opt-out-popup.component';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { InitiateNavigation } from '../../opportunity-summary-settings';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  IntakeConstant,
  VALID_DOCUMENT_TYPES,
} from '../../../constants/intake.constant';
import { IntakeOppertunitySubmissionStatus } from '../../../constants/intake_oppertunity_submission_status.enum';
import { StatusEnum } from '../../../../common/constants/status-enum';
import { IntakeDocumentTableModel } from '../../../../common/models/intake-document-table.model';
import { OppertunitySubmissionStatusCode } from '../../../../common/models/oppertunity-submission-statusCode.model';
import { CommonService } from '../../../../http/intake/common.service';
import { WinningProposalOptionValues } from '../../../constants/intake_document_close_out_winning_proposal_option_values.enum';
import { CoElSowOptionValues } from '../../../constants/intake_document_close_out_co_elsow_option_values.enum';
import { DeliverableOptionValues } from '../../../constants/intake_document_close_out_deliverable_option_values.enum';
import { OtherOptionValues } from '../../../constants/intake_document_close_out_other_option_values.enum';
import {
  IntakeSubmissionDetailsCommonSectionDataSendingModal,
  SubmissionApprovers,
} from '../../../../common/models/intake-submission-details-common-section.model';
import { IntakeOptOutPopUpDataModel } from '../../../../common/models/initiateSubmission.model';
import { UserService } from '../../../../http/user.service';
import { PermissionsObj } from '../../../../common/models/common-models';
import { PermissionCheckService } from '../../../../common/validations/permission-check.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-document-submission',
  templateUrl: './document-submission.component.html',
  styleUrl: './document-submission.component.less',
})
export class DocumentSubmissionComponent implements OnInit {
  _EMPTY_SPACE = IntakeConstant.EMPTY_SPACE;
  @Input() item!: OpportunityDetailsNavModel;
  @Input() opportunityNumber: string = this._EMPTY_SPACE;
  @Input() archiveStatus: string = this._EMPTY_SPACE;
  @Input() opportunityDocs!: OpportunityDetailsNavModel[];

  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  submissionData!: any;
  docMetaData!: IntakeDocumentTableModel[];
  submissionStatus: string | undefined = undefined;
  coChangeScope: string | undefined = undefined;
  coDescription: string | undefined = undefined;
  delDescription: string | undefined = undefined;
  internalQRMTeamCommunication: string | undefined = undefined;
  notesAndFeedback: string | null | undefined = null;
  otherDescription: string | undefined = undefined;
  proposalDescription: string | undefined = undefined;
  relatedToRFP: string | undefined = undefined;
  submissionSubTypeId: string | undefined | null = undefined;
  lepReviewedBy: string | null = null;
  submissionTypeCode: string | undefined = undefined;
  isValidDocUploaded: boolean = false;
  isElSowForCoPresent: boolean = false;
  selfReviewEligibilityStatus: string | undefined = this._EMPTY_SPACE;
  smallButton: ButtonOptions = InitiateNavigation;
  lepAcknowledgedBy: string | null = this._EMPTY_SPACE;
  lepAcknowledgementDate: string | null = this._EMPTY_SPACE;
  showLepConfirmationMessage: boolean = false;
  oppertunityStatusCodes!: OppertunitySubmissionStatusCode[];
  updatedVersionForReview!: string | null | undefined;
  winningProposal: string | null | undefined = this._EMPTY_SPACE;
  contractSignedExecuted: string | null = this._EMPTY_SPACE;
  wbsChargeCode: string | null = this._EMPTY_SPACE;
  otherUpdatedVersionForReview: string | null | undefined = this._EMPTY_SPACE;
  isValidArchiveDocUploaded: boolean = false;
  isValidReturnToReworkDocUploaded: boolean = false;
  userRolesAccess: string[] = [];
  currentLoggedInUser: string = IntakeConstant.EMPTY_SPACE;
  isUserPresentInReviewerTable: boolean = false;
  permissionObj: PermissionsObj = {} as PermissionsObj;

  public model: IntakeSubmissionDetailsCommonSectionDataSendingModal =
    {} as IntakeSubmissionDetailsCommonSectionDataSendingModal;
  constructor(
    private readonly router: Router,
    private readonly intakeService: SubmissionService,
    private readonly modalService: ModalService,
    private readonly commonService: CommonService,
    private readonly userService: UserService,
    private readonly permissionService: PermissionCheckService
  ) {}

  ngOnInit(): void {
    this.commonService
      .getOppertunityStatusCode()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[]) => {
          this.oppertunityStatusCodes = response;
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching submission status', err);
        },
      });

    this.permissionObj = this.permissionService.getIntakePermissionsObj;

    this.userService.currentLoggedInUser$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((user: any) => {
        this.currentLoggedInUser = user?.employeeId;
        this.userRolesAccess = user?.employeeRoles ?? [];
      });
    this.getSubmissionDetailsInfo();
  }

  getSubmissionDetailsInfo(): void {
    this.intakeService
      .getSubmissionBySubmissionId(
        this.opportunityNumber,
        String(this.item.submissionId)
      )
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.submissionData = data;
          if (this.submissionData) {
            this.updateVariables();
          }
          this.getDocumentMetaData();
          if (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO) {
            this.getChangeOrderStatus();
          }
          this.intakeService
            .getSubmissionReviewers(this.item.submissionId.toString())
            .subscribe((response: SubmissionApprovers[]) => {
              if (response.length > 0) {
                this.isUserPresentInReviewerTable = response.some(
                  (item) => item.reviewerId === this.currentLoggedInUser
                );
              }
            });
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  #updateStatus(): void {
    const validTypes =
      VALID_DOCUMENT_TYPES[
        this.submissionTypeCode as IntakeDocumentSubmissionTypeCode
      ];
    if (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO) {
      const status = this.docMetaData.some(
        (item: IntakeDocumentTableModel) =>
          validTypes.includes(item.documentType) &&
          item.documentStatus === IntakeConstant.FINAL_ARCHIVE_READY_VERSION
      );
      this.isValidDocUploaded = status;
    } else {
      const status = this.docMetaData.some((item: IntakeDocumentTableModel) =>
        validTypes.includes(item.documentType)
      );
      this.isValidDocUploaded = status;
    }
  }

  updateSubmitButtonStatus(): boolean {
    if (
      (this.isElSowForCoPresent && this.coChangeScope && this.coDescription) ||
      (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
        this.coChangeScope != null &&
        this.coDescription &&
        this.isValidDocUploaded &&
        !this.showLepConfirmationMessage) ||
      (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.DEL &&
        this.delDescription &&
        this.isValidDocUploaded &&
        !this.showLepConfirmationMessage) ||
      (this.isValidDocUploaded &&
        this.otherDescription &&
        this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.OTH &&
        !this.showLepConfirmationMessage) ||
      (this.isValidDocUploaded &&
        this.relatedToRFP != null &&
        this.proposalDescription &&
        this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.PRO &&
        !this.showLepConfirmationMessage) ||
      (this.isValidDocUploaded &&
        this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.EL_SOW &&
        !this.showLepConfirmationMessage)
    ) {
      return false;
    }
    return true;
  }

  getDocumentMetaData(): void {
    if (this.item?.submissionId && this.item.submissionId !== 0) {
      this.intakeService
        .getDocumentTableData(this.item.submissionId)
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe({
          next: (data: any) => {
            this.docMetaData = data;
            if (this.docMetaData.length > 0) {
              this.isValidArchiveDocUploaded = this.docMetaData.some(
                (item: IntakeDocumentTableModel) =>
                  item.documentStatus ===
                  IntakeConstant.FINAL_ARCHIVE_READY_VERSION
              );
              this.isValidReturnToReworkDocUploaded = this.docMetaData.some(
                (item: IntakeDocumentTableModel) =>
                  item.documentStatus ===
                  IntakeOppertunitySubmissionStatus.DRAFT
              );
              this.#updateStatus();
            }
          },
          error: (err: HttpErrorResponse) => {
            console.error('Error fetching submission', err);
          },
        });
    }
  }

  onShowMore(): void {
    this.router.navigate(
      ['/submission/opportunity-details', this.opportunityNumber],
      {
        state: {
          submissionID: this.item.submissionId,
          navigatedFromSummary: true,
        },
      }
    );
  }

  isSubmitBtnVisible(): boolean {
    return this.submissionStatus === IntakeOppertunitySubmissionStatus.DRAFT;
  }

  isResubmitBtnVisibleForSummary(): boolean {
    return (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK
    );
  }

  isSubmittedResubmittedSectionVisible(): boolean {
    return (
      (this.submissionStatus === IntakeOppertunitySubmissionStatus.SUBMITTED ||
        this.submissionStatus ===
          IntakeOppertunitySubmissionStatus.RESUBMITTED) &&
      this.isUserPresentInReviewerTable &&
      this.permissionObj['isIntakeSubmissionCompleteReviewBtnVisible']
    );
  }

  isPendingCloseoutVisible(): boolean {
    return (
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
    );
  }

  getChangeOrderStatus(): void {
    const submissionStatusId = Number(
      this.getSubmissionStatusCode(IntakeOppertunitySubmissionStatus.SUBMITTED)
    );
    this.intakeService
      .getChangeOrderStatus(this.opportunityNumber, submissionStatusId)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: any) => {
          this.isElSowForCoPresent = response;
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  submitCommonSectionDataForSummary(): void {
    let submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.SUBMITTED
    );

    if (this.selfReviewEligibilityStatus === IntakeConstant.YES) {
      submissionStatusId = this.getSubmissionStatusCode(
        IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
      );
    }
    const IsValidArchive = IntakeConstant.VALID_ARCHIVE_LIST.includes(
      this.archiveStatus
    );
    this.intakeService
      .submitCommonSectionData(
        this.item.submissionId,
        Number(submissionStatusId),
        null,
        this.item.submissionTypeId,
        IsValidArchive
      )
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: any) => {
          if (response) {
            this.getSubmissionDetailsInfo();
            this.item.submissionStatusName = response.submissionStatusName;
            if (this.selfReviewEligibilityStatus === IntakeConstant.YES) {
              this.item.isFormCompleted = false;
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error('submission error', error);
        },
      });
  }

  onclickResubmitForReviewBtnForSummary(): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.RESUBMITTED
    );
    this.intakeService
      .submitCommonSectionData(
        this.item.submissionId,
        Number(submissionStatusId)
      )
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: any) => {
          this.getSubmissionDetailsInfo();
          this.item.submissionStatusName = response.submissionStatusName;
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  openDocumentEditModel(modalBtn?: any): void {
    const optOutModelRef = this.modalService.open(OptOutPopupComponent);
    optOutModelRef
      .onClosed()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (data: IntakeOptOutPopUpDataModel) => {
          if (data.submissionLevelUpdateSubmission) {
            this.model.reasonForOptOutSelfReview = data.optOutReason;
            this.#saveCommonSectionDetails();
            this.selfReviewEligibilityStatus = IntakeConstant.NO;
          }
        },
        error: (err: Error) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  updateVariables(): void {
    this.submissionTypeCode = this.submissionData.submissionTypeCode;
    this.submissionStatus = this.submissionData.submissionStatusName;
    this.coChangeScope = this.submissionData.coChangeScope;
    this.coDescription = this.submissionData.coDescription;
    this.delDescription = this.submissionData.delDescription;
    this.internalQRMTeamCommunication =
      this.submissionData.internalQRMTeamCommunication;
    this.notesAndFeedback = this.submissionData.notesAndFeedback;
    this.otherDescription = this.submissionData.otherDescription;
    this.proposalDescription = this.submissionData.proposalDescription;
    this.relatedToRFP = this.submissionData.relatedToRFP;
    this.submissionSubTypeId = this.submissionData.submissionSubTypeId;
    this.lepReviewedBy = this.submissionData.lepReviewedBy;
    this.lepAcknowledgedBy = this.submissionData.lepAcknowledgedBy;
    this.lepAcknowledgementDate = this.submissionData.lepAcknowledgementDate;
    this.updatedVersionForReview = this.submissionData.updatedVersionForReview;
    this.selfReviewEligibilityStatus =
      this.submissionData.selfReviewEligibilityStatus;
    this.winningProposal = this.submissionData.winningProposal;
    this.contractSignedExecuted = this.submissionData.contractSignedExecuted;
    this.wbsChargeCode = this.submissionData.wbsChargeCode;
    this.otherUpdatedVersionForReview =
      this.submissionData.otherUpdatedVersionForReview;
    if (
      this.lepAcknowledgedBy === null &&
      this.lepAcknowledgementDate === null &&
      this.lepReviewedBy === null
    ) {
      this.showLepConfirmationMessage = true;
    } else {
      this.showLepConfirmationMessage = false;
    }

    this.model = {
      submissionId: this.item.submissionId,
      parentOpportunityNumber: this.opportunityNumber,
      coChangeScope: this.coChangeScope,
      coDescription: this.coDescription,
      submissionTitle: this.item.submissionTitle,
      otherDescription: this.otherDescription,
      proposalDescription: this.proposalDescription,
      relatedToRFP: this.relatedToRFP,
      delDescription: this.delDescription,
      notesAndFeedback: this.notesAndFeedback,
      internalQRMTeamCommunication: this.internalQRMTeamCommunication,
      winningProposal: this.winningProposal,
      otherUpdatedVersionForReview: this.otherUpdatedVersionForReview,
      submissionSubTypeId: this.submissionSubTypeId,
      updatedVersionForReview: this.updatedVersionForReview,
      contractSignedExecuted: this.contractSignedExecuted,
      wbsChargeCode: this.wbsChargeCode,
      wbsDescription: this.wbsChargeCode,
      reasonForOptOutSelfReview: this.submissionData.reasonForOptOutSelfReview,
      archiveID: this.submissionData.archiveDocumentId,
      archiveNumber: this.submissionData.archiveNumber,
      archiveName: this.submissionData.archiveName,
      archiveFoldersArray: this.submissionData.archiveFoldersArray,
      archiveFolder: this.submissionData.archiveFolder,
      business: this.submissionData.business,
      clientNumber: this.submissionData.clientNumber,
      clientName: this.submissionData.clientName,
      partner: this.submissionData.partner,
      archiveDescription: this.submissionData.archiveDescription,
      archiveStatus: this.submissionData.archiveStatus,
      isWBSPending: this.submissionData.isWBSPending,
    };
  }

  onclickArchiveAndCompleteBtnForSummary(): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.COMPLETED
    );
    const IsValidArchive = IntakeConstant.VALID_ARCHIVE_LIST.includes(
      this.archiveStatus
    );
    if (submissionStatusId) {
      this.intakeService
        .submitCommonSectionData(
          this.item.submissionId,
          submissionStatusId,
          null,
          this.item.submissionTypeId,
          IsValidArchive
        )
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe({
          next: (response: any) => {
            this.item.submissionStatusName = response.submissionStatusName;
            this.getSubmissionDetailsInfo();
          },
          error: (error: HttpErrorResponse) => {
            console.error(error);
          },
        });
    }
  }

  handleReturnforReworkForSummary(): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK
    );
    if (submissionStatusId) {
      this.intakeService
        .submitCommonSectionData(this.item.submissionId, submissionStatusId)
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe({
          next: (response: any) => {
            this.getSubmissionDetailsInfo();
            this.item.submissionStatusName = response.submissionStatusName;
          },
          error: (err: HttpErrorResponse) => {
            console.error('An error occurred during submission: ' + err);
          },
        });
    }
  }

  getSubmissionStatusCode(statusName: string): number | undefined {
    return this.oppertunityStatusCodes?.find(
      (status: OppertunitySubmissionStatusCode) =>
        status.statusName === statusName
    )?.id;
  }

  showArchiveButtonForSummary(): boolean {
    if (
      this.isValidArchiveDocUploaded &&
      ((this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.PRO &&
        this.winningProposal?.toLowerCase() ===
          WinningProposalOptionValues.YES.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
          this.contractSignedExecuted?.toLowerCase() ===
            CoElSowOptionValues.YES.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.DEL &&
          this.updatedVersionForReview?.toLowerCase() ===
            DeliverableOptionValues.NO.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.OTH &&
          this.otherUpdatedVersionForReview?.toLowerCase() ===
            OtherOptionValues.YES.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.EL_SOW &&
          this.contractSignedExecuted?.toLowerCase() ===
            CoElSowOptionValues.YES.toLowerCase()))
    ) {
      return true;
    }
    return false;
  }

  showReturnToReworkButtonForSummary(): boolean {
    if (
      this.isValidReturnToReworkDocUploaded &&
      ((this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.PRO &&
        this.winningProposal?.toLowerCase() ===
          WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
          this.contractSignedExecuted?.toLowerCase() ===
            CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.DEL &&
          this.delDescription &&
          this.updatedVersionForReview?.toLowerCase() ===
            DeliverableOptionValues.YES_RESUBMIT_FOR_REVIEW.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.OTH &&
          this.otherDescription &&
          this.otherUpdatedVersionForReview?.toLowerCase() ===
            OtherOptionValues.NO_RESUBMIT_FOR_REVIEW.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.EL_SOW &&
          this.contractSignedExecuted?.toLowerCase() ===
            CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW.toLowerCase()))
    ) {
      return true;
    }
    return false;
  }

  checkForSelfReviewEligibilityStatus(): boolean {
    return this.selfReviewEligibilityStatus === IntakeConstant.YES;
  }

  #saveCommonSectionDetails() {
    let commonSectionData = { ...this.model };
    commonSectionData.submissionId = this.item.submissionId;
    commonSectionData.parentOpportunityNumber = this._EMPTY_SPACE;
    // TODO: add value from search field for CO, or add from existing value

    if (commonSectionData.coChangeScope != null) {
      commonSectionData.coChangeScope =
        IntakeConstant.YES_NO_CHECKBOX_VALUE_TO_BOOLEAN.get(
          commonSectionData.coChangeScope
        );
    }

    if (commonSectionData.relatedToRFP != null) {
      commonSectionData.relatedToRFP =
        IntakeConstant.YES_NO_CHECKBOX_VALUE_TO_BOOLEAN.get(
          commonSectionData.relatedToRFP
        );
    }

    if (
      this.model.submissionSubTypeId &&
      this.model.submissionSubTypeId != this._EMPTY_SPACE
    ) {
      commonSectionData.submissionSubTypeId = Number(
        this.model.submissionSubTypeId
      );
    }

    return this.intakeService
      .saveIntakeDetailsCommonData(commonSectionData)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        error: (err: HttpErrorResponse) => {
          console.error('Error saving data', err);
        },
      });
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
