import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartEmitData, ChartItem } from '../../../models/chart-item.model';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.less'
})
export class BarChartComponent {
  @Input() barChartTitle: string = 'Bar Chart';
  @Input() isHorizontal: boolean = true;
  @Input() bars: ChartItem[] = [];
  @Input() legendBar: ChartItem = {} as ChartItem;
  @Input() isChartDataLoaded: boolean = false;
  @Output() public barChartClick: EventEmitter<ChartEmitData[]> = new EventEmitter<ChartEmitData[]>();

  chartClick(event: ChartEmitData[]) {
    this.barChartClick.emit(event);
  }
}
