import { Size } from '@usitsdasdesign/dds-ng/shared';

export class GlobalEngagementsConstant {
  public static readonly UI_ELEMENT_SIZE: Size = Size.lg;
  public static readonly TEXTAREA_SIZE: string =
    GlobalEngagementsConstant.UI_ELEMENT_SIZE;
  public static readonly DDS_ICON_ARROW_UP: string = 'dds-icon_arrow-up';
  public static readonly DDS_ICON_ARROW_DOWN: string = 'dds-icon_arrow-down';
  public static readonly DDS_ICON_CLOSE: string = 'dds-icon_close';
  public static readonly SELECT_EXISTING_LABEL: string = 'Select existing';
  public static readonly CREATE_NEW_LABEL: string = 'Create new';
  public static readonly NON_CHANGE_ORDER: string = 'nonChangeOrder';
  public static readonly EMPTY_STRING: string = '';  
  public static readonly MEMBER_FIRM: string = 'memberFirm';
  public static readonly OFFERING: string = 'offering';
  public static readonly OFFERING_PORTFOLIO: string = 'offeringPortfolio';
  public static readonly START_DATE: string = 'startDate';
  public static readonly END_DATE: string = 'endDate';
  public static readonly ENGAGEMENT_TITLE: string = 'engagementTitle';
  public static readonly OPPORTUNITY: string = 'opportunity';
  public static readonly DEFAULT_VALUE: string = '-';  
  public static readonly undefinedString: string = 'undefined';
  public static readonly nullString: string = 'null';  
  public static readonly validationConstant: string = 'Validation';
  public static readonly PresignedURL: string = 'PresignedURL';
  public static readonly PresignedURLUpdated: string = 'PresignedURLUpdated';
  public static readonly uploaded: string = 'uploaded';
  public static readonly DATE_FORMAT: string = 'MM/dd/yyyy';

  public static readonly PAGE_NUMBER: number = 15;
  public static readonly TOOLTIP_WIDTH: number = 450;
  public static readonly memberFirmOther = 27;
  public static readonly longOldDate: number = -5000000000000;
  public static readonly longNewDate: number = 5000000000000;
  public static readonly SEARCH_LIMIT: number = 3;
  public static readonly TEXT_LENGTH_1000: number = 1000;
  public static readonly TEXT_LENGTH_100: number = 100;
  public static readonly TIMEOUT_TIMER: number = 2500;
  public static readonly otherType: number = 4;
  public static readonly archivedStatus: number = 1;
  public static readonly DELAY_TIMER: number = 3000;

  // TODO : remove it when wbs is ready
  public static readonly wbsSearchList: string[] = [
    '563215',
    '563216',
    '563217',
    '563218',
    '563219',
  ];
  public static readonly wbswithDec = [
    { wbsCode: '563215', wbsDescription: 'WDS 563215' },
    { wbsCode: '563216', wbsDescription: 'WDS 563216' },
    { wbsCode: '563217', wbsDescription: 'WDS 563217' },
    { wbsCode: '563218', wbsDescription: 'WDS 563218' },
    { wbsCode: '563219', wbsDescription: 'WDS 563219' },
  ];
  public static readonly wbsArchive = [
    { wbsCode: '563215', archiveList: [{ heading: 'archive1', value: 1 }] },
    {
      wbsCode: '563216',
      archiveList: [
        { heading: 'archive2', value: 2 },
        { heading: 'archive21', value: 21 },
      ],
    },
    { wbsCode: '563217', archiveList: [{ heading: 'archive3', value: 3 }] },
    { wbsCode: '563218', archiveList: [{ heading: 'archive4', value: 4 }] },
    {
      wbsCode: '563219',
      archiveList: [
        { heading: 'archive5', value: 5 },
        { heading: 'archive51', value: 51 },
      ],
    },
  ];
}

export enum EngagementStatus {
  Draft = 1,
  Completed = 2,
  Discontinued = 3,
  CompletedAdmin = 4,
}

export function getStatusEnumKeyByValue(value: number): string | undefined {
  return Object.keys(EngagementStatus).find(
    (key) => EngagementStatus[key as keyof typeof EngagementStatus] === value
  );
}
