import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { WinningProposalOptionValues } from '../../../constants/intake_document_close_out_winning_proposal_option_values.enum';
import {
  ButtonKind,
  ExtThemes,
  Size,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { OtherOptionValues } from '../../../constants/intake_document_close_out_other_option_values.enum';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { ConfirmPopModel } from '../../../../common/models/show-all-popup-model';

@Component({
  selector: 'app-confirm-pop-modal',
  templateUrl: './confirm-pop-modal.component.html',
  styleUrl: './confirm-pop-modal.component.less',
})
export class ConfirmPopModalComponent {
  modalTitle: string = '';
  valueSelected: string = '';
  moveToCompleteBtnLabel = 'Move to complete';
  cancelBtnLabel = 'Cancel';
  movetoComplete: ConfirmPopModel = {
    moveToCompleteOther: false,
    moveToCompleteProposal: false,
  };
  static readonly MODAL_COMMON_BTN_OPTIONS: ButtonOptions = {
    theme: ExtThemes.green,
    size: Size.lg,
    width: WidthState.fixed,
    isLoading: false,
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: true,
    customClass: '',
    role: 'button',
  };
  NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED =
    WinningProposalOptionValues.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED;

  NO_DOCUMENT_WILL_NOT_BE_EXECUTED =
    OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED;

  moveToCompleteBtnOption: ButtonOptions = {
    ...ConfirmPopModalComponent.MODAL_COMMON_BTN_OPTIONS,
    icon: '',
    customClass: '',
    kind: ButtonKind.primaryLoud,
    ariaLabel: this.moveToCompleteBtnLabel,
  };
  cancelBtnOption: ButtonOptions = {
    ...ConfirmPopModalComponent.MODAL_COMMON_BTN_OPTIONS,
    icon: '',
    kind: ButtonKind.secondaryLoud,
    customClass: '',
    ariaLabel: this.cancelBtnLabel,
  };

  constructor(
    public modalService: ModalService,
    private submissionService: SubmissionService
  ) {}

  @Input() isFooter!: boolean;
  @Input() size!: string;
  @Input() isInverse!: boolean;
  @Input() set modalSetting(setting: any) {
    this.setModalValue(setting);
  }

  open(): void {
    this.modalService.open(ConfirmPopModalComponent);
  }

  close(data: ConfirmPopModel | null = null): void {
    this.modalService.close(data);
  }

  setModalValue(setting: any): void {
    if (setting?.value === this.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED) {
      this.modalTitle = 'Move to complete';
      this.valueSelected = this.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED;
    }
    if (setting?.value === this.NO_DOCUMENT_WILL_NOT_BE_EXECUTED) {
      this.modalTitle = 'Move to complete';
      this.valueSelected = this.NO_DOCUMENT_WILL_NOT_BE_EXECUTED;
    }
  }

  onClickMoveToCompleteBtn(): void {
    this.movetoComplete.moveToCompleteProposal = true;
    this.close(this.movetoComplete);
  }

  onClickCancel(): void {
    this.close();
  }

  onClickOtherMoveToCompleteBtn(): void {
    this.movetoComplete.moveToCompleteOther = true;
    this.close(this.movetoComplete);
  }

  onClickOtherCancel(): void {
    this.submissionService.updateOtherSubmissionValue('');
    this.close();
  }
}
