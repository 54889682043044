import { Component, OnInit } from '@angular/core';
import { ExternalCommunicationService } from '../../http/external-communication/external-communication.service';
import {
  ExtCommLeftNavModel,
  InitiateExternalCommunicationDropdownModel,
} from '../../common/models/external-communication.model';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { StatusEnum } from '../constants/status-enum';
import { PermissionCheck } from '../../common/validations/PermissionCheck';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';

@Component({
  selector: 'app-external-communications',
  templateUrl: './external-communications.component.html',
  styleUrl: './external-communications.component.less',
})
export class ExternalCommunicationsComponent
  extends PermissionCheck
  implements OnInit
{
  unsubscriber$: Subject<void> = new Subject<void>();
  communicationType: string = '';
  communicationTypeId: number = 0;
  activeIndex: number = 0;
  extCommBoxValue: string = 'EXT COMM';
  communicationTypeList: string[] = [];
  currentActiveSubmissionId: number = 0;
  communicationTypeListWithId: InitiateExternalCommunicationDropdownModel[] =
    [];
  submittedExternalCommunications: ExtCommLeftNavModel[] = [];
  requiredFieldsFilled: boolean = false;
  isAllFieldsVisible: boolean = false;

  constructor(
    private readonly externalCommService: ExternalCommunicationService,
    private readonly route: ActivatedRoute,
    private readonly progressIndicator: ProgressIndicatorService,
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
  }

  ngOnInit(): void {
    this.communicationTypeId = Number(this.route.snapshot.paramMap.get('id'));
    this.isAllFieldsVisible = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_SubmissionListAllFields,
      this.permissionType.Visible
    );
    this.getCommunicationType();
    if (this.isAllFieldsVisible) {
      this.getLeftNavDetails(this.communicationTypeId);
    }
  }

  getCommunicationType(): void {
    this.progressIndicator.show();
    this.externalCommService
      .getSubmissionTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: InitiateExternalCommunicationDropdownModel[]) => {
          this.communicationTypeListWithId = response;
          this.communicationTypeList = response.map(
            (item: InitiateExternalCommunicationDropdownModel) =>
              item.communicationTypeName
          );
          this.setCommunicationTypeById(this.communicationTypeId);
          this.progressIndicator.hide();
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  setCommunicationTypeById(id: number): void {
    const foundType = this.communicationTypeListWithId.find(
      (item) => item.communicationTypeId === id
    );
    if (foundType) {
      this.communicationType = foundType.communicationTypeName;
    }
  }

  getCommunicationTypeIdByName(name: string): number | undefined {
    const foundType = this.communicationTypeListWithId.find(
      (item) => item.communicationTypeName === name
    );
    return foundType ? foundType.communicationTypeId : undefined;
  }

  getLeftNavDetails(id: number): void {
    this.progressIndicator.show();
    this.externalCommService
      .getExtCommLeftNav(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: ExtCommLeftNavModel[]) => {
          if (response) {
            this.submittedExternalCommunications = response;
            this.currentActiveSubmissionId = response[0].submissionId;
            this.progressIndicator.hide();
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  onSubmissionTitleChange(newTitle: string | null): void {
    this.submittedExternalCommunications.forEach(
      (submission: ExtCommLeftNavModel) => {
        if (submission.submissionId === this.currentActiveSubmissionId) {
          submission.submissionTitle = newTitle;
        }
      }
    );
  }

  getExternalCommunications(): void {
    const id = Number(
      this.getCommunicationTypeIdByName(this.communicationType)
    );
    this.getLeftNavDetails(id);
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find(
        (enumValue: string) => enumValue === status
      ) || StatusEnum.Draft
    );
  }

  setActive(index: number): void {
    this.activeIndex = index;
    console.log(
      'this.submittedExternalCommunications[index].submissionId',
      this.submittedExternalCommunications[index].submissionId
    );
    this.currentActiveSubmissionId =
      this.submittedExternalCommunications[index].submissionId;
  }

  getRoleWidth(): string {
    const length = this.communicationType.length;
    return length > 16 ? 16 + 'ch' : length + 2 + 'ch';
  }

  onSubmitButtonStateChange(submitButtonEnabled: boolean): void {
    this.requiredFieldsFilled = submitButtonEnabled;
    if (this.requiredFieldsFilled) {
      this.getLeftNavDetails(this.communicationTypeId);
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
