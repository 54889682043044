import { Component, OnInit, Input } from '@angular/core';
import { NcaSummaryWebapiService, NCASummaryFormPeriod } from '../../http/dashboard/nca-summary-webapi.service';
import { debounceTime, filter, Subject, switchMap, takeUntil, map } from 'rxjs';
import { DropdownItem } from '../../common/services/dropdowns';
import { SelectOptions, SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { ChartEmitData, ChartItem } from '../../common/models/chart-item.model';

@Component({
  selector: 'app-nca-summary-footer',
  templateUrl: './nca-summary-footer.component.html',
  styleUrl: './nca-summary-footer.component.less'
})
export class NcaSummaryFooterComponent implements OnInit  {

  @Input() periodLookupList: SelectItemOptions[] = [];
  @Input() periodID: number = 0;

  private itemColor: string[] = ['#BBBCBC', '#00A3E0', '#86BC25', '#FFFFFF'];
  private itemLabel: string[] = ['Not started', 'In process', 'Pending approvals', 'On hold'];
  private itemLabelClickSendValues = ['notstarted', 'inprogress', 'pendingapprovals', 'onhold'];
  barChartTitle: string = 'NCA specialist assignment by status section';
  bars: ChartItem[] = [];
  isChartDataLoaded: boolean = false;

  selectPeriodOptions: SelectOptions = {
    placeholder: "Select",
    size: Size.md,
    disabled: false
  };
  constructor(private readonly ncaSummaryWebapiService : NcaSummaryWebapiService) { }

  ngOnInit(): void {
    this.getLookupData();
  }

  getLookupData() {
   
    this.ncaSummaryWebapiService.getNCAFormPeriod().subscribe({
      next: (response: any) => {
        response.forEach((lookupData: NCASummaryFormPeriod) => {
          console.log(lookupData.ncaFormPeriodid + " " + lookupData.ncaFormPeriodCode + " " + lookupData.ncaFormPeriodName);
          this.periodLookupList.push({
            value: lookupData.ncaFormPeriodid,
            //itemCode: lookupData.ncaFormPeriodCode,
            heading: lookupData.ncaFormPeriodName
            //,isItemActive: true
          } as SelectItemOptions);
        });    
      },
      error: (err) => {
        //Handle error
      }
    });
  }

  periodChangeEvent($event : any) {

  }

  barChartClick(event: ChartEmitData[]) {

  }
}
