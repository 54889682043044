import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartEmitData, ChartItem } from '../../../models/chart-item.model';
import { ConsultingNCAConstants } from '../../../../client/constants/consulting-nca-constant.';
import { ClientDetailsConstant } from '../../../../client/constants/client-details-constant';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';

@Component({
  selector: 'app-bar-chart-item',
  templateUrl: './bar-chart-item.component.html',
  styleUrl: './bar-chart-item.component.less'
})
export class BarChartItemComponent implements AfterViewInit {
  @Input() barItem: ChartItem = {} as ChartItem;
  @Output() public barItemClick: EventEmitter<ChartEmitData[]> = new EventEmitter<ChartEmitData[]>();
  itemWidth: string[] = [];
  total: number = 0;
  public descTooltipHoverText: string = ClientDetailsConstant.descTooltipHoverText;
  public tooltipOptions: TooltipOptions = ClientDetailsConstant.tooltipOptions;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.total = this.barItem.itemValues?.reduce((i, value) => i + value, 0);

    this.barItem.itemValues?.forEach((value, index) => {
      this.itemWidth.push(this.calcWidth(value));
    });

    // Mark the component for change detection
    this.cdr.detectChanges();
  }

  calcWidth(itemValue: number): string {
    var width = ((itemValue * 100) / this.total);
    if (width > 0) {
      return width + '%';
    }
    else {
      return '0.125rem';
    }
  }

  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  getFrameColor(index: number): string {
    if (this.barItem.itemColor[index]) {
      return this.barItem.itemColor[index];
    }
    return this.getRandomColor();
  }

  itemClick(index: number) {
    var chartClick: ChartEmitData[] = [{
      type: 'legend',
      value: this.barItem.legendItemClickSendValues[index]
    }, {
      type: 'bar',
      value: this.barItem.itemValuesClickSendValues[index] ?? this.barItem.itemValuesClickSendValues[0]
    }];
    this.barItemClick.emit(chartClick);
  }

  labelClick(){
    var chartClick: ChartEmitData[] = [ {
      type: 'barlabel',
      value: this.barItem.itemValuesClickSendValues[0]
    }];
    this.barItemClick.emit(chartClick);
  }
}
