import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { CommonService } from '../../../../http/intake/common.service';
import { CommonHelperService } from '../../../../common/services/common-helper.service';
import { Subject, takeUntil } from 'rxjs';
import { configurationType } from '../../../../common/models/common-models';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { SHOW_MORE_BTN_OPTIONS } from '../../../external-communications.helper';
import { IntakeConstant } from '../../../../intake/constants/intake.constant';
import { OppertunitySubmissionStatusCode } from '../../../../common/models/oppertunity-submission-statusCode.model';
import { IntakeSubmissionActionReasonDropdown } from '../../../../common/models/submission-action.model';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import { IntakeOppertunitySubmissionStatus } from '../../../../intake/constants/intake_oppertunity_submission_status.enum';
import { selectOptions } from './adminCompletepopus.helper';
import { StatusEnum, StatusEnumIdMap } from '../../../constants/status-enum';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { ExternalCommSubmissionStatus } from '../../../constants/submission-status.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-admin-complete-popup',
  templateUrl: './admin-complete-popup.component.html',
  styleUrl: './admin-complete-popup.component.less'
})
export class AdminCompletePopupComponent {
  constructor(
    private readonly modalService: ModalService,
    private readonly externalServive: ExternalCommunicationService,
    private readonly intakeDetailService: SubmissionService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly commonServive: CommonService,
    private readonly commonHelperService: CommonHelperService
  ) {}

  @Input() isFooter: boolean = false;
  @Input() size: string = IntakeConstant.EMPTY_SPACE;
  @Input() isInverse: boolean = false;
  @Input() submissionId: number = 0;
  emptySpace = IntakeConstant.EMPTY_SPACE;
  isShowMoreDocumentNotes: boolean = true;
  showMoreText: string = IntakeConstant.SHOW_LESS_TEXT;
  showMoreOptions: any = SHOW_MORE_BTN_OPTIONS;
  actionDropdownList: SelectItemOptions[] = [];
  selectedReason!: number;
  additionDetail: string = IntakeConstant.EMPTY_SPACE;
  configData: configurationType[] = [];
  configLableDescription: string = IntakeConstant.EMPTY_SPACE;
  configLableGuidance: string = IntakeConstant.EMPTY_SPACE;
  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  textareaOptions = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  selectOptions = selectOptions;
  statusCodeList!: OppertunitySubmissionStatusCode[];

  ngOnInit(): void {
    this.getActionDropdownData(IntakeConstant.SubmissionActionID);
    this.getLabelConfiguration();
    this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_UP;
    this.commonServive
      .getOppertunityStatusCode()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[]) => {
          this.statusCodeList = response;
        },
        error: (err:HttpErrorResponse) => {
          console.error('An error occurred during fetching data: ', err);
        },
      });
  }

  getLabelConfiguration(): void {
    this.intakeDetailService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response) {
            this.configData = response;
            this.configLableGuidance =
              this.configData.find(
                (config: configurationType) =>
                  config.appConfigurationLabelKey ===
                  'ADMINCOMPLETE_SUBMISSION_GUIDANCE_EXT_COMM'
              )?.appConfigurationLabelValue || this.emptySpace;

            this.configLableDescription =
              this.configData.find(
                (config: configurationType) =>
                  config.appConfigurationLabelKey ===
                  'ADMINCOMPLETE_SUBMISSION_DESCRIPTION'
              )?.appConfigurationLabelValue || this.emptySpace;
          }
        },
        error: (err:HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getActionDropdownData(id: number): void {
    this.externalServive.getActionReasons(id).subscribe({
      next: (result: IntakeSubmissionActionReasonDropdown[]) => {
        const convertedArray = result.map((item: IntakeSubmissionActionReasonDropdown) => ({
          value: item.reasonId,
          heading: item.reason,
        }));
        this.actionDropdownList = convertedArray;
      },
      error: (err: HttpErrorResponse) => {
        this.progressIndicator.hide();
      },
    });
  }

  clickToShowDocumentMoreLepMessage(): void {
    this.isShowMoreDocumentNotes = !this.isShowMoreDocumentNotes;
    if (!this.isShowMoreDocumentNotes) {
      this.showMoreText = IntakeConstant.SHOW_MORE_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_DOWN;
    } else {
      this.showMoreText = IntakeConstant.SHOW_LESS_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_UP;
    }
  }

  async saveSubmissionHistory(): Promise<void> {
    const postRequestData = {
      submissionId: this.submissionId,
      currentStatusId: ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
                ExternalCommSubmissionStatus.ADMIN_COMPLETE],
      submissionActionId: IntakeConstant.SubmissionActionID,
      reasonId: this.selectedReason,
      additionalDetails: this.commonHelperService.trimPrefixEmptySpace(this.additionDetail),
    };
    try {
         await this.externalServive
        .saveSubmissionHistory(postRequestData)
        .pipe(takeUntil(this.unsubscriber$))
        .toPromise();
      this.closePopUp();
        } catch (err: any) {
      console.error('An error occurred while saving the data: ', err);
    }
    this.closePopUp();
  }

  formValid(): boolean {
    return this.selectedReason != 0 && this.additionDetail != this.emptySpace;
  }

  closePopUp(): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
