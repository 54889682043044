<div class="dds-modal-overlay" (click)="close()"></div>

<div ddsResize class="dds-modal dds-modal_md">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Edit document</span>
    <dds-button
      [size]="modalCloseButtonOptions.size ?? emptyString"
      [icon]="modalCloseButtonOptions.icon ?? emptyString"
      [kind]="modalCloseButtonOptions.kind ?? emptyString"
      [theme]="modalCloseButtonOptions.theme ?? emptyString"
      [ariaLabel]="modalCloseButtonOptions.ariaLabel ?? emptyString"
      class="dds-modal__close"
      (clicked)="close()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div class="inputs-container">
      <div class="margin-bottom-l">
        <p
          class="input-label text-align-left font-bold-400 line-height-s text-cool-gray font-size-12"
        >
          <span *ngIf="documentTitle.length === 0"
            ><img src="\assets\images\draftIcon.svg"
          /></span>
          File title
        </p>

        <dds-input [(ngModel)]="documentTitle" [placeholder]="'Add file title'">
        </dds-input>
      </div>

      <div class="row margin-bottom-l">
        <p
          class="input-label text-align-left font-bold-400 line-height-s text-cool-gray font-size-12"
        >
          Type
        </p>
        <dds-select
          [list]="documentTypeItemsList"
          [(ngModel)]="selectedDocumentType"
          (ngModelChange)="updateTypeId($event)"
        ></dds-select>
      </div>
      <div class="row row margin-bottom-l">
        <p
          class="input-label text-align-left font-bold-400 line-height-s text-cool-gray font-size-12"
        >
          Status
        </p>
        <dds-select
          [list]="documentStatusItemList"
          [(ngModel)]="selectedDocumentStatus"
          (ngModelChange)="updateStatusId($event)"
        ></dds-select>
      </div>

      <div class="comment margin-bottom-l">
        <p
          class="input-label text-align-left font-bold-400 line-height-s text-cool-gray font-size-12"
        >
          Comment (optional)
        </p>
        <dds-textarea [placeholder]="'Text'" [(ngModel)]="documentComment">
        </dds-textarea>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item"
        [size]="UI_Element_Size"
        ariaLabel="Save"
        (clicked)="saveAll()"
        (keyboardClicked)="saveAll()"
        [disabled]="!isFormValid()"
        >Save</dds-button
      >
      <dds-button
        class="dds-modal__footer-item"
        [kind]="secondaryLoudButtonOptions.kind ?? emptyString"
        [size]="secondaryLoudButtonOptions.size ?? emptyString"
        ariaLabel="Close"
        (clicked)="close()"
        (keyboardClicked)="close()"
        >Close</dds-button
      >
    </div>
  </div>
</div>
