import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { CommonService } from '../../../../http/intake/common.service';
import { CommonHelperService } from '../../../../common/services/common-helper.service';
import { ExternalCommUrlConstants } from '../../../constants/url.constants';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { ExternalCommSubmissionStatus, SubmissionActionId } from '../../../constants/submission-status.enum';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import {
  RADIO_GROUP_OPTIONS_FOR_STATUS,
  selectOptions,
  textAreaOptions,
} from './ext-comm-reset-submission-status.helper';
import {
  SelectItemOptions,
  SelectOptions,
} from '@usitsdasdesign/dds-ng/select';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { Subject, takeUntil } from 'rxjs';
import {
  SaveSubmissionResetRequest,
  SubmissionActionReasonDropdown,
  SubmissionStatus,
} from '../../../../common/models/external-communication.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-ext-comm-reset-submission-status',
  templateUrl: './ext-comm-reset-submission-status.component.html',
  styleUrl: './ext-comm-reset-submission-status.component.less',
})
export class ExtCommResetSubmissionStatusComponent {
  constructor(
    private readonly modalService: ModalService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly commonService: CommonService,
    private readonly commonHelperService: CommonHelperService
  ) {}

  @Input() isFooter: boolean = false;
  @Input() size: string = ExternalCommunicationConstant.EMPTY_SPACE;
  @Input() isInverse: boolean = false;
  @Input() submissionId: number = 0;
  @Input() submissionStatus: string = ExternalCommunicationConstant.EMPTY_SPACE;
  status: string = ExternalCommunicationConstant.EMPTY_SPACE;
  status_radioGroupOptions: RadioGroupOptions = RADIO_GROUP_OPTIONS_FOR_STATUS;
  statusRadioValues: RadioButton[] = [];
  actionDropdownList: SelectItemOptions[] = [];
  selectedReason: number = 0;
  otherReasonDescription: string = ExternalCommunicationConstant.EMPTY_SPACE;
  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  textareaOptions: TextareaOptions = textAreaOptions;
  selectOptions: SelectOptions = selectOptions;
  showReasonTextBox: boolean = false;
  dropdownOptions: SubmissionActionReasonDropdown[] = [];
  resetButtonSelected: boolean = false;
  emptySpace : string = ExternalCommunicationConstant.EMPTY_SPACE;

  ngOnInit(): void {
    this.getActionDropdownData();
    this.loadStatusResetModalRadioButtons();
  }

  loadStatusResetModalRadioButtons(): void {
    this.progressIndicator.show();
    this.externalCommService
      .getSubmissionStatusByActionId(SubmissionActionId.Reset)
      .subscribe({
        next: (result: SubmissionStatus[]) => {
          const convertedArray = result.map((item: SubmissionStatus) => ({
            options: {
              label: item.submissionStatusName || this.emptySpace,
              theme: ExternalCommunicationConstant.INTAKE_CHECKBOX_THEME_COLOR,
            },
            value: item.submissionStatusId
              ? item.submissionStatusId.toString()
              : this.emptySpace,
          }));
          this.statusRadioValues = convertedArray;
          this.progressIndicator.hide();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          this.progressIndicator.hide();
        },
      });
  }

  getActionDropdownData(): void {
    this.progressIndicator.show();
    this.externalCommService
      .getActionReasonsResetStatus(SubmissionActionId.Reset)
      .subscribe({
        next: (result: SubmissionActionReasonDropdown[]) => {
          const convertedArray: SelectItemOptions[] = result.map(
            (item: SubmissionActionReasonDropdown) => ({
              value: item.reasonId,
              heading: item.reason,
            })
          );
          this.actionDropdownList = convertedArray;
          this.dropdownOptions = result;
          this.progressIndicator.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
        },
      });
  }

  handleDropDownSelected(data: number): void {
    data === ExternalCommunicationConstant.OTHER_REASONID
      ? (this.showReasonTextBox = true)
      : (this.showReasonTextBox = false);
  }

  saveSubmissionHistoryResetStatus(): void {
    const selectedRadioStatus = this.statusRadioValues.filter(
      (radio : RadioButton) => radio.value === this.status.toString()
    );
    const previousStatusId = ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[ExternalCommSubmissionStatus.DRAFT];
    const postRequestData : SaveSubmissionResetRequest = {
      submissionId: this.submissionId,
      currentStatusId: Number(selectedRadioStatus[0].value),
      previousStatusId: previousStatusId,
      submissionActionId: SubmissionActionId.Reset,
      reasonId: this.selectedReason,
      otherReasonDescription: this.commonHelperService.trimPrefixEmptySpace(
        this.otherReasonDescription
      ),
      additionalDetails: null,
    };
    this.externalCommService
      .saveSubmissionHistoryResetStatus(postRequestData)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: () => {},
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred while saving the data: ', err);
        },
      });
    this.resetButtonSelected = true;
    this.closeResetSubmissionStatusModal();
  }

  formValid(): boolean {
    if (
      this.selectedReason != 0 &&
      this.status != ExternalCommunicationConstant.EMPTY_SPACE
    )
      if (
       (this.showReasonTextBox &&
        this.otherReasonDescription != ExternalCommunicationConstant.EMPTY_SPACE) || (!this.showReasonTextBox) 
      )
        {return true;}
    return false;
  }

  closeResetSubmissionStatusModal(): void {
    this.modalService.close(this.resetButtonSelected);
  }
}
