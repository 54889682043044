export enum StatusEnum {
  NCANeverStarted = 'NCA Never Started',
  NCAInProgress = 'NCA In Progress',
  NCAApprovedWithConditions = 'NCA Approved (Serve with Conditions)',
  NCAApprovedUpToDate = 'NCA Approved (Continuance Requirements up to date)',
  NCAApprovedPending = 'NCA Approved (Continuance Pending)',
  ContinuanceExpired = 'Continuance Expired',
  DoNotServe = 'Do Not Serve',
  NCAOnHold = 'NCA On Hold',
  NCADeFactoAccepted = 'NCA De facto Accepted',
  Pending = 'Pending',
  RMReviewed = 'RM Reviewed',
  Draft = 'Draft',
  Submitted = 'Submitted',
  RMReviewedPendingCloseout = "RM reviewed - Pending closeout"
}
