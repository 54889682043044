import {
  ErrorState,
  LabelPosition,
  Size,
  Themes,
} from '@usitsdasdesign/dds-ng/shared';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';

export class ExternalCommunicationConstant {
  public static readonly ENTER_TEXT_PLACEHOLDER: string = 'Enter text';
  public static readonly EMPTY_SPACE: string = '';
  public static readonly TEXTAREA_MIN_HEIGHT: number = 70;
  public static readonly TEXTAREA_LENGTH: number = 2;
  public static readonly ADD_REVIEWER_BTN_LABEL: string = 'Add reviewer';

  public static readonly COMMUNICATION_TYPE_ID: number = 3;
  public static readonly TEXTAREA_CHAR_LIMIT: number = 1000;
  public static readonly TEXTAREA_CHAR_LIMIT_BIGGEST: number = 5000;
  public static readonly TWO_BUSINESSDAY_SELECTED: number = 2;
  public static readonly YES: string = 'yes';
  public static readonly NO: string = 'no';
  public static readonly INTERNALCONTEST: string = 'Internal contest';
  public static readonly EXTERNALCONTEST: string = 'External contest';

  public static readonly TEXTAREA_CHAR_COUNT_POSTION: string = 'bottom-right';
  public static readonly DATE_FORMAT: string = 'dd/MM/yyyy';
  public static readonly RUSH_REQUEST_VALUE: string = 'Yes';

  public static readonly UI_ELEMENT_SIZE: Size = Size.lg;
  public static readonly TEXTAREA_SIZE: string =
    ExternalCommunicationConstant.UI_ELEMENT_SIZE;

  public static readonly INTAKE_CHECKBOX_THEME_COLOR = Themes.green;
  public static readonly INTAKE_INFO_TOOLTIP_THEME_COLOR = Themes.dark;

  public static readonly SHOW_MORE_TEXT: string = 'Show more';
  public static readonly SHOW_LESS_TEXT: string = 'Show less';

  public static readonly DDS_ICON_ARROW_UP: string = 'dds-icon_arrow-up';
  public static readonly DDS_ICON_ARROW_DOWN: string = 'dds-icon_arrow-down';
  public static readonly DDS_ICON_CLOSE: string = 'dds-icon_close';

  public static readonly OTHER_STATUS: number = 4; //TODO: Update the number

  public static readonly TEXT_AREA_SETTINGS_GLOBAL: TextareaOptions = {
    label: '',
    labelPosition: LabelPosition.external,
    placeholder: ExternalCommunicationConstant.ENTER_TEXT_PLACEHOLDER,
    minHeight: ExternalCommunicationConstant.TEXTAREA_MIN_HEIGHT,
    isRequired: false,
    isInverse: false,
    readonly: false,
    isError: false,
    errorState: ErrorState.default,
    description: '',
    customClass: '',
    errorMessage: '',
    maxLength: ExternalCommunicationConstant.TEXTAREA_CHAR_LIMIT,
    maxLengthPosition:
      ExternalCommunicationConstant.TEXTAREA_CHAR_COUNT_POSTION,
    size: ExternalCommunicationConstant.TEXTAREA_SIZE,
  };
}
