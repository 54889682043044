<progress-indicator></progress-indicator>
<div
  [autosave-module]="saveopportunityDetailsModule"
  class="d-flex flex-column"
>
  <div class="guidance-note">{{ updateLabel }}</div>
  <div class="d-flex flex-row row-distribution">
    <div class="d-flex flex-column">
      <div>
        <div class="eclipse-small-header-grey text-align-left">
          Opportunity number:
        </div>
        <div class="eclipse-content text-align-left">
          {{
            oportunityDetailsInfo.opportunityNumber
              ? oportunityDetailsInfo.opportunityNumber
              : defaultForEmptyFields
          }}
        </div>
      </div>
      <div class="margin-top">
        <div class="d-flex flex-column">
          <div class="eclipse-small-header-grey text-align-left">
            Lead Engagement Partner:
          </div>
          <div
            class="input-container text-align-left"
            (click)="$event.stopPropagation()"
          >
            <ng-container *ngIf="isEditingLEP; else displayLEP">
              <div class="col-12">
                <dds-input
                  autosave
                  [size]="htmlElementSize"
                  [placeholder]="commonPlaceholder"
                  [(ngModel)]="tempLepValue"
                  id="Lep-editor"
                >
                </dds-input>
              </div>
            </ng-container>
            <ng-template #displayLEP>
              <span class="title-value value-font">{{
                lepValue ? lepValue : defaultForEmptyFields
              }}</span>
              <span
                class="dds-icon dds-icon_edit__s__filled edit-icon"
                (click)="editLep()"
              ></span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div>
        <div class="eclipse-small-header-grey text-align-left">Start date:</div>
        <div
          class="input-container text-align-left"
          (click)="$event.stopPropagation()"
        >
          <ng-container *ngIf="isEditingStartDate; else displayStartDate">
            <div class="col-12">
              <dds-datepicker
                autosave
                [size]="htmlElementSize"
                [placeholder]="datePickerOptions.placeholder ?? ''"
                [format]="datePickerOptions.format ?? ''"
                [minMode]="datePickerOptions.minMode ?? ''"
                [disabledDates]="datePickerOptions.disabledDates ?? {}"
                [isResponsive]="datePickerOptions.isResponsive ?? false"
                [isManualInput]="datePickerOptions.isManualInput ?? false"
                [(ngModel)]="tempStartDate"
                id="StartDate-editor"
              >
              </dds-datepicker>
            </div>
          </ng-container>
          <ng-template #displayStartDate>
            <span class="title-value value-font">{{
              startDate ? startDate : defaultForEmptyFields
            }}</span>
            <span
              class="dds-icon dds-icon_edit__s__filled edit-icon"
              (click)="editStartDate()"
            ></span>
          </ng-template>
        </div>
      </div>
      <div class="margin-top">
        <div class="d-flex flex-column">
          <div class="eclipse-small-header-grey text-align-left">
            Total fees $:
          </div>
          <div
            class="input-container text-align-left"
            (click)="$event.stopPropagation()"
          >
            <ng-container *ngIf="isEditingTotalFees; else displayTotalFees">
              <div class="col-12">
                <dds-input
                  autosave
                  [placeholder]="commonPlaceholder"
                  [(ngModel)]="tempTotalFees"
                  id="TotalFees-editor"
                >
                </dds-input>
              </div>
            </ng-container>
            <ng-template #displayTotalFees>
              <span class="title-value value-font">{{
                totalFees ? totalFees : defaultForEmptyFields
              }}</span>
              <span
                class="dds-icon dds-icon_edit__s__filled edit-icon"
                (click)="editTotalFees()"
              ></span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div>
        <div class="eclipse-small-header-grey text-align-left">End date:</div>
        <div
          class="input-container text-align-left"
          (click)="$event.stopPropagation()"
        >
          <ng-container *ngIf="isEditingEndDate; else displayEndDate">
            <div class="col-12">
              <dds-datepicker
                autosave
                [size]="htmlElementSize"
                [placeholder]="datePickerOptions.placeholder ?? ''"
                [format]="datePickerOptions.format ?? ''"
                [minMode]="datePickerOptions.minMode ?? ''"
                [disabledDates]="datePickerOptions.disabledDates ?? {}"
                [isResponsive]="datePickerOptions.isResponsive ?? false"
                [(ngModel)]="tempEndDate"
                id="EndDate-editor"
              >
              </dds-datepicker>
            </div>
          </ng-container>
          <ng-template #displayEndDate>
            <span class="title-value value-font">{{
              endDate ? endDate : defaultForEmptyFields
            }}</span>
            <span
              class="dds-icon dds-icon_edit__s__filled edit-icon"
              (click)="editEndDate()"
            ></span>
          </ng-template>
        </div>
      </div>
      <div class="d-flex flex-column margin-top">
        <div>
          <div
            *ngIf="isEditingFeeType"
            class="eclipse-small-header-grey text-align-left"
          >
            Fee type:
            <span
              class="dds-icon dds-icon_edit__s__filled edit-icon"
              (click)="editFeeType()"
            ></span>
          </div>
          <div
            class="input-container text-align-left"
            (click)="$event.stopPropagation()"
          >
            <ng-container *ngIf="isEditingFeeType; else displayFeeType">
              <div class="col-12">
                <dds-multi-select
                  class="box-size"
                  [disabled]="multiSelectOptions.disabled ?? false"
                  [displayTickAllBtn]="
                    multiSelectOptions.displayTickAllBtn ?? false
                  "
                  [isResponsive]="multiSelectOptions.isResponsive ?? false"
                  [isRequired]="multiSelectOptions.isRequired ?? false"
                  [label]="multiSelectOptions.label ?? ''"
                  [size]="htmlElementSize"
                  [placeholder]="multiSelectOptions.placeholder ?? ''"
                  [type]="multiSelectOptions.type ?? ''"
                  [controlType]="multiSelectOptions.controlType ?? ''"
                  [theme]="multiSelectOptions.theme ?? ''"
                  [isInverse]="multiSelectOptions.isInverse ?? false"
                  [list]="multiselectFeeTypesOptions"
                  (valueChanged)="feeTypeChanged()"
                  [(ngModel)]="feeTypeList"
                  id="Fee-Type"
                >
                </dds-multi-select>
              </div>
            </ng-container>
            <ng-template #displayFeeType>
              <div class="d-flex">
                <div class="eclipse-small-header-grey text-align-left">
                  Fee type:
                </div>
                <span
                  class="dds-icon dds-icon_edit__s__filled edit-icon"
                  (click)="editFeeType()"
                ></span>
              </div>
              <div *ngFor="let feeType of feeTypeList">
                <div
                  class="eclipse-content text-align-left margin-top-fee-type"
                >
                  {{ feeType?.label ? feeType?.label : defaultForEmptyFields }}
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row margin-top">
    <div class="d-flex flex-column">
      <div class="eclipse-content text-align-left">
        <span
          *ngIf="isOpportunityDescriptionRequiredIconVisible()"
          class="icon-required-yellow"
        ></span
        >Opportunity description
      </div>
      <div class="textarea-container">
        <dds-textarea
          autosave
          name="opportunityDescription"
          [(ngModel)]="opportunityDescription"
          [label]="textareaOptions.label"
          [labelPosition]="textareaOptions.labelPosition"
          [placeholder]="textareaOptions.placeholder"
          [description]="textareaOptions.description"
          [minHeight]="textareaOptions.minHeight"
          [isRequired]="textareaOptions.isRequired"
          [customClass]="textareaOptions.customClass"
          [isError]="textareaOptions.isError"
          [errorMessage]="textareaOptions.errorMessage"
          [errorState]="textareaOptions.errorState"
          [maxLength]="textareaOptions.maxLength"
          [maxLengthPosition]="textareaOptions.maxLengthPosition"
          [size]="htmlElementSize"
          [readonly]="textareaOptions.readonly"
          [readonly]="textareaOptions.readonly"
          (ngModelChange)="valueChanged()"
        ></dds-textarea>
      </div>
    </div>
  </div>
</div>
