<div class="bar-item">
    <div class="axis-title" (click)="labelClick()">
        <span>{{barItem.label}}</span>
    </div>
    <div class="frame">
        <ng-container *ngFor="let item of barItem.itemValues; let index = index;">
            <div *ngIf="!barItem.isLabelVisible" class="frame-inner" (click)="itemClick(index)" [ngStyle]="{				
                    'width': itemWidth[index] ? itemWidth[index] : '0.125rem', 				
                    'background-color': getFrameColor(index)				
                }" [ddsTooltip]="barItem.itemTooltip[index] + ': '+ item"
                [tooltipInvokeType]="tooltipOptions.tooltipInvokeType ?? ''"
                [tooltipType]="tooltipOptions.tooltipType ?? ''" [tooltipSize]="tooltipOptions.tooltipSize  ?? ''"
                [tooltipPosition]="tooltipOptions.tooltipPosition ?? ''"
                [tooltipIndent]="tooltipOptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="tooltipOptions.tooltipHasBeak ?? false"
                [tooltipTheme]="tooltipOptions.tooltipTheme ?? ''" ddsFocus
                [tooltipMaxWidth]="tooltipOptions.tooltipMaxWidth ?? 0">

            </div>

            <div *ngIf="barItem.isLabelVisible" class="frame-inner" (click)="itemClick(index)" [ngStyle]="{				
                    'width': itemWidth[index] ? itemWidth[index] : '0.125rem', 				
                    'background-color': getFrameColor(index)				
                }" [ddsTooltip]="barItem.itemTooltip[index] + ': '+ item"
                [tooltipInvokeType]="tooltipOptions.tooltipInvokeType ?? ''"
                [tooltipType]="tooltipOptions.tooltipType ?? ''" [tooltipSize]="tooltipOptions.tooltipSize  ?? ''"
                [tooltipPosition]="tooltipOptions.tooltipPosition ?? ''"
                [tooltipIndent]="tooltipOptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="tooltipOptions.tooltipHasBeak ?? false"
                [tooltipTheme]="tooltipOptions.tooltipTheme ?? ''" ddsFocus
                [tooltipMaxWidth]="tooltipOptions.tooltipMaxWidth ?? 0">

                {{barItem.itemLabel[index]}}
            </div>
        </ng-container>
    </div>
</div>