import { Component, Input } from '@angular/core';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { UploaderOptions, UploadFile } from '@usitsdasdesign/dds-ng/uploader';
import { Column, LabelPosition, Size } from '@usitsdasdesign/dds-ng/shared';
import { ProgressOptions } from '@usitsdasdesign/dds-ng/progress';
import {
  SelectItemOptions,
  SelectOptions,
} from '@usitsdasdesign/dds-ng/select';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { DocumentService } from '../../../../../../http/document/document.service';
import { ProgressIndicatorService } from '../../../../../../common/services/progress-indicator.service';
import { CommonHelperService } from '../../../../../../common/services/common-helper.service';
import { NotifyPopupComponent } from '../../../../../../common/notify-popup/notify-popup.component';
import { SubmissionService } from '../../../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { IntakeConstant } from '../../../../../constants/intake-constant';

@Component({
  selector: 'app-intake-document-upload',
  templateUrl: './intake-document-upload.component.html',
  styleUrl: './intake-document-upload.component.less',
})
export class IntakeDocumentUploadComponents {
  constructor(
    private intakeDetailService: SubmissionService,
    private docService: DocumentService,
    private modalService: ModalService,
    private progressIndicator: ProgressIndicatorService,
    private commonHelperService: CommonHelperService
  ) {}
  unsubscriber$ = new Subject();
  displayStyle: any = 'none';
  fileName: any = '';
  @Input() public documentInitiatedDetails: any = {};
  filePath: any = '';
  uploadFileSourceList: any = [];
  maxFileLength: any = 5;
  notifyReason: any = '';
  uploadRequestList: any = [];
  maxFileSizeAllowed: any = 50 * 1024 * 1024; // 50 MB in bytes
  maxFileSizeAllowedUpto: any = 100 * 1024 * 1024; // 100 MB in bytes
  isFileSize100MBExceed: boolean = false;
  validFileExtensions: any =
    'doc,docx,msg,pptx,pdf,ppt,xls,xlsb,xlsx,xlsm,jpg,jpeg,png,zip'; //Valid File Extensions
  fileSourceList: any = [];
  removeUploadedDocumentFromList: any = [];
  fileUploadPercentage: any = '';
  isEncrypted: boolean = true;
  isUploadingInProgress: boolean = false;
  isFileUploaded: boolean = false;
  isDocumentExists: boolean = false;
  documentUploadData: any = [];
  documentAllSectionsMetaData: any = [];
  documentUploadInProgressData: any = [];
  @Input() isFooter: boolean = false;
  @Input() size: string = '';
  @Input() isInverse: boolean = false;

  uploadAreaOptions: UploaderOptions = {
    heading: '',
    dragAndDropText: 'Drag and drop file(s)',
    description:
      'All common file types are supported including: docx, pptx, pdf, .zip, etc. Max size of 50MB per file',
    isDragAndDrop: true,
    isMultiple: true,
    acceptFormats: '',
    acceptExtensions: '',
    restrictedFormats: '',
    restrictedExtensions: '',
    exceptSymbols: '',
    btnLabel: 'Select file(s)',
    chooseFileBtnKind: 'primary',
    isDownloadable: false,
    allowFilesWithoutType: false,
    disabled: false,
    isInverse: false,
  };
  textFileOptions: TextareaOptions = {
    placeholder: '',
    minHeight: 100,
    maxLength: 400,
    maxLengthPosition: 'bottom-right',
    size: Size.lg,
  };
  textareaOptions: TextareaOptions = {
    placeholder: 'Enter Comment',
    minHeight: 100,
    maxLength: 400,
    maxLengthPosition: 'bottom-right',
    size: Size.lg,
  };
  selectDocumentTypeOptions: SelectOptions = {
    label: '',
    labelPosition: LabelPosition.external,
    description: '',
    placeholder: 'Select  type',
    size: Size.lg,
    disabled: false,
    isResponsive: false,
    isRequired: true,
    isInverse: false,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: '',
    stickerIsDisabled: false,
    stickerPosition: 'bottom-left',
    stickerIndent: 0,
    stickerCustomClass: '',
    stickerIsDynamic: true,
  };

  selectOptionsStatus: SelectOptions = {
    label: '',
    labelPosition: LabelPosition.external,
    description: '',
    placeholder: 'Select type',
    size: Size.lg,
    disabled: false,
    isResponsive: false,
    isRequired: true,
    isInverse: false,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: '',
    stickerIsDisabled: false,
    stickerPosition: 'bottom-left',
    stickerIndent: 0,
    stickerCustomClass: '',
    stickerIsDynamic: true,
  };
  documentTypeItemsList: SelectItemOptions[] = [];
  documentStatusItemList: SelectItemOptions[] = [];
  allStatusAvailable: SelectItemOptions[] = [];
  options: ProgressOptions = {
    size: Size.md,
    theme: 'green',
    isInverse: false,
    label: '',
    description: '',
    isVertical: false,
  };
  public DocumentColumns: Column[] = [
    { name: 'Title', header: 'Title', dataType: 'string' },
    { name: 'Document type', header: 'Document type', dataType: 'string' },
    { name: 'Status', header: 'Status', dataType: 'string' },
    {
      name: 'Comment(optional)',
      header: 'Comment (optional)',
      dataType: 'string',
    },
  ];

  ngOnInit(): void {
    this.getDocumentTypeDropdownData(
      this.documentInitiatedDetails.submissionTypeId
    );
    this.getDocumentStatusDropdownData(
      this.documentInitiatedDetails.submissionStatusId,
      this.documentInitiatedDetails.submissionTypeId 
    );

    this.filePath = this.commonHelperService.getDocumentFolderPath(
      this.documentInitiatedDetails.moduleName
    );
    this.uploadedItems(this.documentInitiatedDetails.file);
  }

  getDocumentStatusDropdownData(id: number, typeId: number): void {
    this.intakeDetailService.getDocumentStatusDropdown(id,typeId).subscribe({
      next: (result: any) => {
        const convertedArray = result.map((item: any) => ({
          value: item.documentStatusId,
          heading: item.documentStatusName,
        }));
        this.documentStatusItemList = convertedArray;
        this.allStatusAvailable = convertedArray;
      },
      error: (err: any) => {
        this.progressIndicator.hide();
      },
    });
  }

  getDocumentTypeDropdownData(id: number): void {
    this.intakeDetailService.getDocumentTypeDropdown(id).subscribe({
      next: (result: any) => {
        const convertedArray = result.map((item: any) => ({
          value: item.documentTypeId,
          heading: item.documentTypeName,
        }));
        this.documentTypeItemsList = convertedArray;
      },
      error: (err: any) => {
        this.progressIndicator.hide();
      },
    });
  }

  updateStatusDropdown(item: any): void {
    if (
      this.documentInitiatedDetails.type ===
      IntakeConstant.CHANGE_ORDER_LABEL_CODE
    ) {
      if (
        item.documentTypeId === IntakeConstant.ENGAGEMENT_LETTER_CODE ||
        item.documentTypeId ===
          IntakeConstant.ORIGNAL_Letter_SOW_Contract_CODE ||
        item.documentTypeId === IntakeConstant.ORIGNAL_Letter_CODE
      ) {
        this.documentStatusItemList = this.allStatusAvailable;
      } else {
        this.documentStatusItemList = this.documentStatusItemList.filter(
          (item: SelectItemOptions) =>
            item.heading !== IntakeConstant.FINAL_ARCHIVE_READY_VERSION
        );
        if (item.documentStatusId === IntakeConstant.FINAL_ARCHIVE_READY_ID) {
          item.documentStatusId = 0;
        }
      }
    }
  }

  removeDocument(row: any): void {
    const index = this.documentUploadData.indexOf(row);
    if (index === -1) {
      return;
    }
    this.documentUploadData.splice(index, 1);
    this.fileSourceList = this.fileSourceList.filter(
      (element: any, index: any) =>
        this.removeUploadedDocumentFromList.indexOf(element.fileName) === -1
    );
    this.loadFileSourceData(this.fileSourceList);
  }

  async onSave(): Promise<void> {
    try {
      this.progressIndicator.show();
      await this.UploadFiletoS3();
      await this.saveDocumentMetaData();
      this.progressIndicator.hide();
    } catch (error) {
      console.log(error);
    }
    this.closeModal();
  }

  async saveDocumentMetaData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.intakeDetailService
        .postDocumentSave(this.documentUploadData)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            resolve();
          },
          error: (err) => {
            console.error('An error occurred during metadata save:', err);
            reject(err);
          },
        });
    });
  }

  isSaveButtonDisabled(): boolean {
    if (this.documentUploadData.length === 0) {
      return false;
    }
    let tempData = this.documentUploadData.some(
      (obj: any) =>
        obj.documentTitle.length == 0 ||
        obj.documentTypeId.toString() == 0 ||
        obj.documentStatusId.toString() == 0
    );
    return tempData === true ? false : true;
  }

  loadFileSourceList(): void {
    this.uploadFileSourceList = [];
    this.documentUploadInProgressData = [];
    this.uploadRequestList = [];
    this.fileSourceList.forEach((element: any) => {
      this.uploadFileSourceList.push({ fileSource: element.file });
      this.documentUploadInProgressData.push({ fileName: element.file.name });
      let uploadRequest = {
        FileFolderPath: this.filePath,
        DocumentName: element.file.name,
      };

      this.uploadRequestList.push(uploadRequest);
      this.documentUploadData.push({
        submissionId: this.documentInitiatedDetails.submissionId,
        documentTypeId: 0,
        documentStatusId: 0,
        submissionDocumentTypeId: 1,
        documentTitle: element.file.name,
        documentUrl: '',
        comments: '',
      });
    });
    this.updateProgressBar('PresignedURLUpdated');
  }

  UploadDraggedAndSelectedDocument(): void {
    this.updateProgressBar('Validation');
    this.loadFileSourceList();
    this.updateProgressBar('uploaded');
  }

  updateProgressBar(event: any): void {
    if (event == 'Validation') {
      this.fileUploadPercentage = '50';
    } else if (event == 'PresignedURL') {
      this.fileUploadPercentage = '75';
    } else if (event == 'PresignedURLUpdated') {
      this.fileUploadPercentage = '95';
    } else if (event == 'uploaded') {
      this.fileUploadPercentage = '100';
    }
  }

  async UploadFiletoS3(): Promise<void> {
    const uploadPromises = this.uploadFileSourceList.map(
      (fileSource: any, index: number) => {
        return new Promise<void>((resolve, reject) => {
          this.docService
            .uploadDocument(
              fileSource,
              this.documentInitiatedDetails.moduleName
            )
            .subscribe({
              next: (result: any) => {
                this.updateProgressBar('PresignedURLUpdated');
                if (this.documentUploadData[index]) {
                  this.documentUploadData[index].attachmentId =
                    result.attachmentID;
                }
                resolve();
              },
              error: (err: any) => {
                console.error('Error uploading file:', err);
                reject(err);
              },
            });
        });
      }
    );

    await Promise.all(uploadPromises);
  }

  enableDisableUIDocumentDetails(): void {
    this.isUploadingInProgress = false;
    this.isFileUploaded = false;
    if (this.documentUploadData.length > 0) {
      this.isFileUploaded = true;
    }
    if (this.fileSourceList.length > 0) {
      this.isUploadingInProgress = true;
    }
  }

  applyBodyOverflow(): void {
    document.body.style.overflow = 'auto';
  }

  openModal(): void {
    this.modalService.open(IntakeDocumentUploadComponents);
  }

  closeModal(): void {
    this.applyBodyOverflow();
    this.uploadFileSourceList = [];
    this.documentUploadInProgressData = [];
    this.uploadRequestList = [];
    this.documentInitiatedDetails.file = [];
    this.documentUploadData = [];

    this.modalService.close(this.documentAllSectionsMetaData);
  }

  isFileExtensionValid(): boolean {
    let isFileExtensionValid = true;
    this.fileSourceList.forEach((element: any) => {
      if (
        !this.validFileExtensions
          .toString()
          .toLowerCase()
          .includes(element.fileExtenstion.toString().toLowerCase())
      ) {
        isFileExtensionValid = false;
      }
    });
    return isFileExtensionValid;
  }

  isFileSizeExceed(): boolean {
    let isFileSizeExceed = false;
    this.isFileSize100MBExceed = false;
    this.fileSourceList.forEach((element: any) => {
      if (
        element.file.size > this.maxFileSizeAllowed &&
        element.file.size <= this.maxFileSizeAllowedUpto
      ) {
        isFileSizeExceed = true;
      } else if (element.file.size > this.maxFileSizeAllowedUpto) {
        this.isFileSize100MBExceed = true;
      }
    });
    return isFileSizeExceed;
  }

  isMaxFileSelectionExceed(): boolean {
    let isMaxFileSelectionExceed = false;
    let selectedFileCount =
      this.fileSourceList.length + this.documentUploadData.length;
    if (selectedFileCount > this.maxFileLength) {
      isMaxFileSelectionExceed = true;
    }
    return isMaxFileSelectionExceed;
  }

  removeBodyOverflow(): void {
    document.body.style.overflow = 'hidden';
  }

  showMsg(modalBtn?: ButtonComponent) {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(NotifyPopupComponent, {
      isFooter: true,
      size: 'md',
      isInverse: false,
      notifyReason: this.notifyReason,
    });

    modalRef.onClosed().subscribe(() => {
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  uploadedItems(filesList: UploadFile[]): void {
    if (filesList.length > 0) {
      this.removePreviousUploadDocument(filesList);
      if (this.fileSourceList.length > 0) {
        if (this.isFileSizeExceed()) {
          this.notifyReason = 'inValidFileSize';
          this.showMsg();
          this.IsDocumentProtected();
        } else if (this.isMaxFileSelectionExceed()) {
          this.notifyReason = 'inValidFileSelection';
          this.showMsg();
        } else if (!this.isFileExtensionValid()) {
          this.notifyReason = 'inValidFileType';
          this.showMsg();
        } else {
          this.IsDocumentProtected();
        }
      }
    }
  }

  IsDocumentProtected(): void {
    this.UploadDraggedAndSelectedDocument();
  }

  loadFileSourceData(fileSource: any): void {
    fileSource.forEach((element: any) => {
      this.removeUploadedDocumentFromList.push(element.fileName);
    });
  }

  removePreviousUploadDocument(filesListSource: any): void {
    if (this.removeUploadedDocumentFromList.length == 0) {
      this.loadFileSourceData(filesListSource);
      this.fileSourceList = filesListSource;
    } else {
      this.fileSourceList = filesListSource.filter(
        (element: any, index: any) =>
          this.removeUploadedDocumentFromList.indexOf(element.fileName) === -1
      );
      this.loadFileSourceData(this.fileSourceList);
    }
  }

  onFileNameEdit(row: any): void {
    row.isFileNameInEditMode = true;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
