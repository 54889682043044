import { Component } from '@angular/core';
import { secondaryButton } from '../external-communications.helper';
import { GlobalEngagementsConstant } from '../../global-engagements/constants/global-engagements.constants';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrl: './success-page.component.less'
})
export class SuccessPageComponent {
  secondaryButton = secondaryButton;
  submissionId: number | null = null;
  submissionTypeId: number | null = null;
  htmlElementSize: Size = GlobalEngagementsConstant.UI_ELEMENT_SIZE;
  constructor(private route: ActivatedRoute,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.submissionId = params['submissionId'];
      this.submissionTypeId= params['submissionTypeId'];
      console.log(this.submissionId,this.submissionTypeId)
  })}

  navigateToDashboard(): void{
    this.router.navigate(['/'])
  }

  navigateToSubmission(): void{
    this.router.navigate(['/externalCommunications/details', this.submissionTypeId], {
      queryParams: { submissionId: this.submissionId }
     });
  }
}
