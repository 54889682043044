import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EclipseHeaderComponent } from './eclipse-header.component';
import { LibModule } from '@usitsdasdesign/dds-ng';

@NgModule({
  declarations: [EclipseHeaderComponent],
  imports: [CommonModule, LibModule],
  exports: [EclipseHeaderComponent],
})
export class EclipseHeaderModule {}
