import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { FeeType, SubmissionType } from '../../common/models/common-models';
import { SubmissionSubType } from '../../common/models/common-models';
import {
  IntakeOpportunitySummaryTeam,
  OpportunityDetailsNavModel,
  OpportunityList,
} from '../../common/models/opportunity-details.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environment/environment';
import { configurationType } from '../../common/models/common-models';
import { InitiateSubmissionRequestDataModel } from '../../common/models/initiateSubmission.model';
import { ServiceDeliveryModel } from '../../common/models/serviceDelivery.model';
import { latestOpportunityDetails } from '../../common/models/latestOpportunityDetails';
import { toolTipConfig } from '../../common/models/common-models';
import { LinkedOpportunity } from '../../common/models/opportunity-details.model';
import { OpportunityDetailsEdit } from '../../common/models/opportunityDetailsEdit';
import { SaveOpportunityDetailsSection } from '../../common/models/saveOpportunityDetails';
import { UrlConstant } from '../../intake/constants/url-constant';
import {
  EditIntakeDocumentModel,
  IntakeDocumentStatusDropdownModel,
  IntakeDocumentTableModel,
  IntakeDocumentTypeDropdownModel,
  SaveIntakeDocumentModel,
} from '../../common/models/intake-document-table.model';
import { complexityQuestionsData } from '../../common/models/complexity-question-data.model';
import {
  IntakeSubmissionDeatilsCommonSectionWorkflowSelectedDocumentsModal,
  IntakeSubmissionDetailsCommonSectionDataReceivedModal,
  IntakeSubmissionDetailsCommonSectionDataSendingModal,
  SubmissionApprovers,
} from '../../common/models/intake-submission-details-common-section.model';
import { IntakeClientSummaryModel } from '../../common/models/intake-client-summary.model';
import { IntakeConstant } from '../../intake/constants/intake-constant';

@Injectable({
  providedIn: 'root',
})
export class SubmissionService {
  //TODO: Hardcoded employee ID need to remove
  //employeeId = '1234';
  employeeId = '00100015';
  constructor(private http: HttpClient) {}
  apiUrl: string = environment.apiUrl;

  public statusMap = new Map<number | null, BehaviorSubject<boolean>>();

  public docISValid = new BehaviorSubject<boolean>(false);
  public new$ = this.docISValid.asObservable();

  private complexQuestionSubject = new BehaviorSubject<boolean>(false);
  private detailSubject = new BehaviorSubject<boolean>(false);
  private complexQuestionCyberSubject = new BehaviorSubject<boolean>(false);
  #otherValueSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  otherValue$: Observable<string> = this.#otherValueSubject.asObservable();
  complexQuestionStatus$ = this.complexQuestionSubject.asObservable();
  detailStatus$ = this.detailSubject.asObservable();

  complexQuestionCyberStatus$ = this.complexQuestionCyberSubject.asObservable();

  updateComplexQuestionStatus(isValid: boolean): void {
    this.complexQuestionSubject.next(isValid);
  }
  updateDetailStatus(isValid: boolean): void {
    this.detailSubject.next(isValid);
  }
  updateComplexQuestionCyberStatus(isValid: boolean): void {
    this.complexQuestionCyberSubject.next(isValid);
  }
  getIntakeConfigurations(): Observable<configurationType[]> {
    return this.http.get<configurationType[]>(
      this.apiUrl + UrlConstant.GET_INTAKE_CONFIGARATIONS_API
    );
  }

  getIntakeTooltips(): Observable<toolTipConfig[]> {
    return this.http.get<toolTipConfig[]>(
      this.apiUrl + UrlConstant.GET_INTAKE_TOOLTIPS_API
    );
  }

  getOpportunitySelected(
    opportunityId: string
  ): Observable<latestOpportunityDetails> {
    return this.http.get<latestOpportunityDetails>(
      this.apiUrl + UrlConstant.GET_SELECTED_OPPERTUNITY_API + opportunityId
    );
  }

  getSubmissionTypes(): Observable<SubmissionType[]> {
    return this.http.get<SubmissionType[]>(
      this.apiUrl + UrlConstant.GET_SUBMISSION_TYPES_API
    );
  }

  getSubmissionSubTypes(
    submissionId: number | undefined
  ): Observable<SubmissionSubType[]> {
    return this.http.get<SubmissionSubType[]>(
      this.apiUrl + UrlConstant.GET_SUBMISSION_SUBTYPES_API + submissionId
    );
  }

  getOpportunityDetails(
    searchItem: string,
    pageNumber: number,
    pageSize: number
  ): Observable<OpportunityList> {
    return this.http.get<OpportunityList>(
      this.apiUrl +
        UrlConstant.GET_OPPORTUNITY_DETAILS_API +
        searchItem +
        '&pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize
    );
  }

  postSubmissionRequest(
    data: InitiateSubmissionRequestDataModel
  ): Observable<any> {
    const url = `${this.apiUrl}${UrlConstant.POST_SUBMISSION_REQUEST_API}`;
    return this.http.post<any>(url, data);
  }

  getIntakeDetails(
    opportunityNumber: string
  ): Observable<OpportunityDetailsNavModel[]> {
    return this.http.get<OpportunityDetailsNavModel[]>(
      this.apiUrl + UrlConstant.GET_INTAKE_DETAILS_API + opportunityNumber
    );
  }

  saveComplexitydata(data: complexityQuestionsData): Observable<any> {
    const url = `${this.apiUrl}${UrlConstant?.POST_COMPLEXITY_DATA_API}`;
    return this.http.post<any>(url, data);
  }

  getComplexityResponse(
    opportunityID: string | null
  ): Observable<complexityQuestionsData> {
    return this.http.get<complexityQuestionsData>(
      this.apiUrl + UrlConstant?.GET_COMPLEXITY_DATA_API + opportunityID
    );
  }

  getDeliveryServiceTable(
    opportunityNumber: string
  ): Observable<ServiceDeliveryModel[]> {
    return this.http.get<ServiceDeliveryModel[]>(
      this.apiUrl + UrlConstant.GET_DELIVERY_SERVICE_API + opportunityNumber
    );
  }

  getLinkedOpportunityDetails(
    opportunityNumber: string
  ): Observable<LinkedOpportunity[]> {
    const url = `${this.apiUrl}${UrlConstant.GET_LINKED_OPPORTUNITIES_API}${opportunityNumber}`;
    return this.http.get<LinkedOpportunity[]>(url);
  }

  getSubmissionBySubmissionId(
    opportunityNumber: string,
    submissionId: string
  ): Observable<IntakeSubmissionDetailsCommonSectionDataReceivedModal> {
    const url = `${this.apiUrl}${UrlConstant.GET_SUBMISSION_DETAILS_API}${opportunityNumber}&submissionId=${submissionId}`;
    return this.http
      .get<IntakeSubmissionDetailsCommonSectionDataReceivedModal>(url)
      .pipe(map(this.#transformSubmissionDetails));
  }

  getDocumentStatusDropdown(
    id: number,
    typeId: number
  ): Observable<IntakeDocumentStatusDropdownModel[]> {
    const url = `${this.apiUrl}${
      UrlConstant.getDocumentStatusDropdownurl
    }${id}${'&submissionTypeId=' + typeId}`;
    return this.http.get<IntakeDocumentStatusDropdownModel[]>(url);
  }

  getDocumentTypeDropdown(
    id: number
  ): Observable<IntakeDocumentTypeDropdownModel[]> {
    return this.http.get<IntakeDocumentTypeDropdownModel[]>(
      this.apiUrl + UrlConstant?.getDocumentTypeDropdownurl + id
    );
  }

  getDocumentTableData(
    id: number | null
  ): Observable<IntakeDocumentTableModel> {
    return this.http.get<IntakeDocumentTableModel>(
      this.apiUrl + UrlConstant?.getDocumentTableData + id
    );
  }
  getOpportunityDetailsInfo(
    opportunityNumber: string
  ): Observable<OpportunityDetailsEdit> {
    const url = `${this.apiUrl}${UrlConstant.GET_OPPORTUNITY_DETAILS_INFO_API}${opportunityNumber}`;
    return this.http.get<OpportunityDetailsEdit>(url);
  }

  updateOpportunityDetails(
    data: SaveOpportunityDetailsSection
  ): Observable<SaveOpportunityDetailsSection> {
    const url = `${this.apiUrl}${UrlConstant.POST_OPPORTUNITY_DETAILS_REQUEST_API}`;
    return this.http.post<SaveOpportunityDetailsSection>(url, data);
  }

  getFeeTypes(): Observable<FeeType[]> {
    return this.http.get<FeeType[]>(this.apiUrl + UrlConstant.GET_FEE_TYPE_API);
  }

  postDocumentSave(
    data: SaveIntakeDocumentModel[]
  ): Observable<SaveIntakeDocumentModel[]> {
    const url = `${this.apiUrl}${UrlConstant.postDocumentData}`;
    return this.http.post<any>(url, data);
  }

  postDocumentEdit(
    data: EditIntakeDocumentModel
  ): Observable<EditIntakeDocumentModel> {
    const url = `${this.apiUrl}${UrlConstant.PUT_DOCUMENT_EDIT_URL}`;
    return this.http.post<any>(url, data);
  }

  deleteDocument(id: number): Observable<number> {
    const url = `${this.apiUrl}${UrlConstant.Delete_DOCUMENT_URL}${id}`;
    return this.http.delete<any>(url);
  }

  putLepStatus(
    submissionId: number | null,
    lepReviewedBy: string
  ): Observable<string> {
    const url = `${this.apiUrl}${UrlConstant.PUT_LEP_URL}`;
    const data: any = {
      submissionID: submissionId,
      lepAcknowledgedBy: this.employeeId,
      lepReviewedBy: lepReviewedBy,
    };
    return this.http.put<string>(url, data);
  }

  saveIntakeDetailsCommonData(
    dataModel: IntakeSubmissionDetailsCommonSectionDataSendingModal
  ): Observable<IntakeSubmissionDetailsCommonSectionDataSendingModal> {
    return this.http.put<IntakeSubmissionDetailsCommonSectionDataSendingModal>(
      this.apiUrl + UrlConstant.SAVE_INTAKE_DESC_COMMON_DETAILS,
      dataModel
    );
  }

  getIntakeClientSummary(
    clientNumber: string
  ): Observable<IntakeClientSummaryModel> {
    const url = `${this.apiUrl}${UrlConstant.INTAKE_CLIENT_SUMMARY_API}${clientNumber}`;
    return this.http.get<IntakeClientSummaryModel>(url);
  }

  updateSubmissionReviewers(
    approverList: SubmissionApprovers[],
    submissionId: number
  ): Observable<any> {
    const requestBody = {
      submissionId: submissionId,
      submissionApprovers: approverList,
    };
    return this.http.post<SubmissionApprovers[]>(
      this.apiUrl + UrlConstant.UPDATE_INTAKE_SUBMISSION_REVIEWERS,
      requestBody
    );
  }

  getSubmissionReviewers(
    submissionId: string
  ): Observable<SubmissionApprovers[]> {
    return this.http.get<SubmissionApprovers[]>(
      this.apiUrl + UrlConstant.GET_INTAKE_SUBMISSION_REVIEWERS + submissionId
    );
  }

  getOpportunitySummaryTeamData(
    opportunityNumber: string
  ): Observable<IntakeOpportunitySummaryTeam> {
    const url = `${this.apiUrl}${UrlConstant.GET_OPPORTUNITY_SUMMARY_TEAM_DATA}${opportunityNumber}`;
    return this.http.get<IntakeOpportunitySummaryTeam>(url);
  }

  getTechnicalReviewers(
    searchItem: string,
    pageNumber: number,
    pageSize: number = IntakeConstant.PAGE_NUMBER
  ): Observable<SubmissionApprovers[]> {
    return this.http.get<SubmissionApprovers[]>(
      this.apiUrl +
        UrlConstant.GET_TECHNICAL_REVIEWERS +
        searchItem +
        '&pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize
    );
  }

  updateOtherSubmissionValue(value: string): void {
    this.#otherValueSubject.next(value);
  }
  getChangeOrderStatus(id: string): Observable<boolean> {
    const params = new HttpParams()
      .set('opportunityNumber', id)
      .set('submissionTypeId', 3);

    const url = this.apiUrl + UrlConstant.GET_CO_DRAFT_STATUS;
    return this.http.get<boolean>(url, { params });
  }

  submitCommonSectionData(
    submissionId: number,
    submissionStatusId: number
  ): Observable<string> {
    const body = {
      submissionId: submissionId,
      submissionStatusId: submissionStatusId,
    };

    const url = this.apiUrl + UrlConstant.PUT_SUBMIT_COMMON_SECTION_DATA;
    return this.http.put<string>(url, body);
  }

  updateRMStatus(
    data: IntakeSubmissionDeatilsCommonSectionWorkflowSelectedDocumentsModal
  ): Observable<string> {
    const url = `${this.apiUrl}${UrlConstant.UPDATE_RM_STATUS}`;
    return this.http.post<string>(url, data);
  }

  #transformSubmissionDetails(
    response: any
  ): IntakeSubmissionDetailsCommonSectionDataReceivedModal {
    return {
      submissionId: response?.submissionId,
      submissionTitle: response?.submissionTitle,
      selfReviewEligibilityStatusId: response?.selfReviewEligibilityStatusId,
      selfReviewEligibilityStatus: response.selfReviewEligibilityStatus,
      submissionTypeCode: response?.submissionTypeCode,
      note: response?.note,
      submissionStatusName: response?.submissionStatusName,
      submissionApprovers: response.submissionApprovers,
      proposalDescription: response?.proposalDescription,
      coDescription: response?.coDescription,
      delDescription: response?.delDescription,
      otherDescription: response?.otherDescription,
      relatedToRFP: response?.relatedToRFP,
      coChangeScope: response?.coChangeScope,
      internalQRMTeamCommunication: response?.internalQRMTeamCommunication,
      submissionStatusId: response.submissionStatusId,
      submissionTypeId: response.submissionTypeId,
      submissionSubTypeId: response?.submissionSubTypeId,
      customSubmissionType: response?.customSubmissionType,
      notesAndFeedback: response.notesAndFeedback
        ? response?.notesAndFeedback
        : '',
      lepAcknowledgedBy: response?.lepAcknowledgedBy,
      lepAcknowledgementDate: response?.lepAcknowledgementDate,
      lepReviewedBy: response?.lepReviewedBy,
      isEligibleTechnicalReviewer: response?.isEligibleTechnicalReviewer
        ? response.isEligibleTechnicalReviewer
        : false,
      contractSignedExecuted: response.contractSignedExecuted,
      chargeCodeWBS: response.chargeCodeWBS,
      wbsDescription: response.wbsDescription,
      numberOfOpenArchieve: response.numberOfOpenArchieve,
    };
  }
}
