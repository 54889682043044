import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SecurityWebapiService } from '../http/security/security-webapi.service';
import {
  DropdownItemOptions,
  DropdownOptions,
} from '@usitsdasdesign/dds-ng/dropdown';
import { DashboardConstant, dropdownOptions } from './dashboard.helper';
import { PositionState } from '@usitsdasdesign/dds-ng/shared';
import { DashboardRolesForOptions } from '../common/models/start-page/start-page.model';
import { CommonService } from '../http/intake/common.service';
import { AdobeAnalyticsService } from '../common/services/adobe-analytics.service';
import { WINDOW_SCROLL_SIZE } from '../app.constants';
import { RoleService } from '../http/intake/role.service';
import { takeUntil, Subject, take } from 'rxjs';
import { EntityDetails, EntityType } from '../http/user.service';
import { IntakeConstant } from '../intake/constants/intake.constant';
import { MatrixKeys } from '../common/constants/security-matrix';
import { RoleEnum } from '../intake/constants/Role.enum';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.less'],
})
export class StartPageComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  public Nca_Specialist: string = DashboardConstant.NCA_SPECIALIST;
  public Reviewer: string = DashboardConstant.REVIEWER;
  public Engagement_Team: string = DashboardConstant.ENGAGEMENT_TEAM;
  public Risk_Manager: string = DashboardConstant.RISK_MANAGER;

  selectedRole: string = '';
  roles: string[] = [];

  dropdownOptions: DropdownOptions = dropdownOptions;
  dropdownItems: DropdownItemOptions[] = [
    {
      heading: 'Contract/Proposal/Eng. Related Review',
      value: 'Contract/Proposal/Eng. Related Review',
    },
    {
      heading: 'Global Engagements',
      value: 'Global Engagements',
    },
  ];
  dropdownStickerPosition: PositionState = PositionState.bottomLeft;

  public clientNumber: string = '';
  private securityNcaDashboard: string = 'ClientStatus.NcaDashboard';
  private isDashboardSecurity: boolean = false;
  private isReviewerPermission: boolean = false;
  private isRiskManagerPermission: boolean = false;
  private isEngagementPermission: boolean = false;
  private isPermissionsEmpty: boolean = true;

  dropdownWidth: string = '';
  loggedInUserRoles: string[] | undefined;

  // Mapping from API role names to DashboardRolesForOptions
  private readonly ROLE_DASHBOARD_MAP: { [key: string | RoleEnum]: string } = {
    [RoleEnum.REVIEWER]: DashboardRolesForOptions.Reviewer,
    [RoleEnum.EXT_COMM_REVIEWER]: DashboardRolesForOptions.Reviewer,
    [RoleEnum.RISK_MANAGER]: DashboardRolesForOptions.RiskManager,
    [RoleEnum.EXT_COMM_RISK_MANAGER]: DashboardRolesForOptions.RiskManager,
    [RoleEnum.ENGAGEMENT_TEAM_MEMBER]: DashboardRolesForOptions.EngagementTeam,
    [RoleEnum.EXT_COMM_ENGAGEMENT_TEAM_MEMBER]:
      DashboardRolesForOptions.EngagementTeam,
    'Sector leader': DashboardRolesForOptions.Reviewer,
    'QRM (Quality Risk Manager)': DashboardRolesForOptions.Reviewer,
    Approver: DashboardRolesForOptions.Reviewer,
    'Industry NCA Risk Manager': DashboardRolesForOptions.Reviewer,
    'Lead Business Partner': DashboardRolesForOptions.Reviewer,
    [RoleEnum.SPECIALIZED_OFFERING_RISK_LEADER]:
      DashboardRolesForOptions.RiskManager,
    // Add more mappings as needed
  };

  constructor(
    private route: ActivatedRoute,
    private securityWebapiService: SecurityWebapiService,
    private readonly router: Router,
    private readonly commonService: CommonService,
    private analytics: AdobeAnalyticsService,
    private roleService: RoleService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // Subscribe to route parameters if needed
    this.route.params.subscribe((params: Params) => {
      this.securityWebapiService.getPermissionsByEmployeeId(
        EntityDetails.None,
        EntityType.None
      );
    });

    // Subscribe to user roles from the RoleService
    this.roleService.currentUserRole$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: string[]) => {
          this.loggedInUserRoles = response;
          this.updateRoles();
        },
        error: (error) => {
          console.error('Error fetching user roles:', error);
        },
      });

    // Subscribe to security API data to check NCA permissions
    this.securityWebapiService.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (rules) => {
          if (!rules.empty) {
            this.isDashboardSecurity =
              rules[this.securityNcaDashboard] &&
              rules[this.securityNcaDashboard].Visible;

            this.isReviewerPermission =
              rules['Intake.ReviewerDashboard']?.Visible ||
              rules[MatrixKeys.EXT_COM_Unassigned_ReviewerDashboard]?.Visible ||
              rules[MatrixKeys.EXT_COM_ReviewerDashboard]?.Visible ||
              rules[MatrixKeys.EXT_COM_AllSubmissions_ReviewerDashboard]
                ?.Visible ||
              rules[MatrixKeys.GLOBAL_ENG_ReviewerDashboard]?.Visible;

            this.isRiskManagerPermission =
              rules['Intake.RiskManagerOversightDashboard']?.Visible;

            this.isEngagementPermission =
              rules['Intake.EngagementTeamDashboard']?.Visible ||
              rules[MatrixKeys.EXT_COM_EngagementTeamDashboard]?.Visible ||
              rules[MatrixKeys.GLOBAL_ENG_EngagementTeamDashboard]?.Visible;

            const isEngagementExtCommPermission =
              rules[MatrixKeys.EXT_COM_EngagementTeamDashboard]?.Visible;
            if (
              isEngagementExtCommPermission &&
              !this.dropdownItems.some(
                (item) => item.heading === 'External Communication'
              )
            ) {
              this.dropdownItems.splice(1, 0, {
                heading: 'External Communication',
                value: 'External Communication',
              });
            }
            this.commonService.setSelectedDashboard(this.selectedRole);
            localStorage.setItem('currentDashboard', this.selectedRole);
            this.updateRoles();
          } else {
            this.isPermissionsEmpty = true;
          }
        },
        error: (error) => {
          console.error('Error fetching security rules:', error);
        },
      });

    if (localStorage.getItem('currentDashboard')) {
      this.selectedRole = localStorage.getItem('currentDashboard')!;
      this.onRoleChange();
    }
  }

  private updateRoles(): void {
    const updatedRoles = new Set<string>();
    if (this.isDashboardSecurity) {
      updatedRoles.add(DashboardRolesForOptions.NCASpecialist);
    }
    if (this.isReviewerPermission) {
      updatedRoles.add(DashboardRolesForOptions.Reviewer);
    }
    if (this.isRiskManagerPermission) {
      updatedRoles.add(DashboardRolesForOptions.RiskManager);
    }
    if (this.isEngagementPermission) {
      updatedRoles.add(DashboardRolesForOptions.EngagementTeam);
    }

    if (this.loggedInUserRoles && this.loggedInUserRoles.length > 0) {
      this.loggedInUserRoles.forEach((role) => {
        if (role === 'System Administrator') {
          updatedRoles.add(DashboardRolesForOptions.NCASpecialist);
          updatedRoles.add(DashboardRolesForOptions.Reviewer);
          updatedRoles.add(DashboardRolesForOptions.EngagementTeam);
          updatedRoles.add(DashboardRolesForOptions.RiskManager);
        } else {
          const dashboard = this.ROLE_DASHBOARD_MAP[role];
          if (dashboard) {
            updatedRoles.add(dashboard);
          } else {
            console.warn(`No dashboard mapping found for role: ${role}`);
          }
        }
      });
    } else if (
      this.loggedInUserRoles?.length === 0 &&
      this.isPermissionsEmpty
    ) {
      updatedRoles.add(DashboardRolesForOptions.EngagementTeam);
    }

    this.roles = Array.from(updatedRoles);

    if (this.roles.length > 0) {
      this.commonService.dashboardSelected$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((currentDashboard) => {
          if (currentDashboard) {
            this.selectedRole = currentDashboard;
          } else {
            this.selectedRole = this.roles[0];
          }
          this.analytics.trackPage(`${this.selectedRole} dashboard`);
          this.getRoleWidth();
        });
    } else {
      this.selectedRole = IntakeConstant.EMPTY_SPACE;
    }
  }

  ngAfterViewInit(): void {
    this.getRoleWidth();
  }
  onRoleChange(event?: any): void {
    this.commonService.setSelectedDashboard(this.selectedRole);
    localStorage.setItem('currentDashboard', this.selectedRole);
    this.getRoleWidth();
    this.analytics.trackPage(`${this.selectedRole} dashboard`);
  }

  getRoleWidth(additionalWidth: number = 0) {
    this.dropdownWidth =
      this.selectedRole === DashboardRolesForOptions.Reviewer ||
      this.selectedRole === DashboardRolesForOptions.EngagementTeam
        ? `${this.selectedRole.length + 3 + additionalWidth}ch`
        : `${this.selectedRole.length + additionalWidth}ch`;
    this.cdr.detectChanges();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    if (window.scrollY > WINDOW_SCROLL_SIZE) {
      this.getRoleWidth(2);
    } else {
      this.getRoleWidth(0);
    }
  }

  initiateNewItemSelected(event: string): void {
    if (event === 'Contract/Proposal/Eng. Related Review') {
      this.router.navigate(['/submission']);
    } else if (event === 'Global Engagements') {
      this.router.navigate(['/globalEngagements/create']);
    } else if (event === 'External Communication') {
      this.router.navigate(['/externalCommunications/create']);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
