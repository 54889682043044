import { Component, Input } from '@angular/core';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { IntakeConstant } from '../../../constants/intake.constant';
import { Size, TabThemeType, Themes } from '@usitsdasdesign/dds-ng/shared';

import {
  OpportunityDetails,
  OpportunityList,
} from '../../../../common/models/opportunity-details.model';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import {
  configurationType,
  PermissionsObj,
  toolTipConfig,
} from '../../../../common/models/common-models';
import {
  opportunityTableColumns,
  tabContainerOptions,
} from '../../../initiate-submission/initiate-submission-helper';
import { TabsOptions } from '@usitsdasdesign/dds-ng/tabs';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { ToastMessageOptions } from './search-elsow.helper';
import { Router } from '@angular/router';
import { GenericResponse } from '../../../../common/models/external-communication.model';

@Component({
  selector: 'app-search-elsow',
  templateUrl: './search-elsow.component.html',
  styleUrl: './search-elsow.component.less',
})
export class SearchELSOWComponent {
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;
  @Input() dgmfId: string | null | undefined;
  @Input() gmdmId: string | null | undefined;
  @Input() swiftId: string | null | undefined;
  @Input() coOppDonotMatchMessage: string = '';
  @Input() opportunityNumber: string | null | undefined;
  @Input() submissionId: number | null | undefined;
  uiElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  searchOptions: SearchOptions = {
    placeholder: IntakeConstant.SEARCH_OPTION_PLACEHOLDER,
    size: this.uiElementSize,
    customClass: '',
  };
  opportunityTableColumns = opportunityTableColumns;
  opportunityList: OpportunityDetails[] = [];
  selectedOportunity: string = '';
  showDropdown: boolean = false;
  notFound: string = '';
  notAvailable: boolean = true;
  totalCount: number = 0;
  pageNumber: number = 0;
  pageSize: number = IntakeConstant.PAGE_NUMBER;
  unsubscriber$: Subject<any> = new Subject<any>();
  configurationsIntake: configurationType[] = [];
  tooltipIntake: toolTipConfig[] = [];
  showOpportunityDetails: boolean = false;
  changeOrderList: any[] = [];
  showWarningMessage: boolean = false;
  totalOpportunityCount: number = 0;
  activeOpportunityCount: number = 0;
  selectedTabCode: string = 'active';
  selectTabIndex:number = 0;
  tabContainerOptions = tabContainerOptions;
  toastMessage: ToastOptions = ToastMessageOptions;

  public tabs = [
    {
      label: 'Active',
      code: 'active',
      count: this.totalOpportunityCount,
    },
    {
      label: 'All opportunities',
      code: 'all',
      count: this.activeOpportunityCount,
    },
  ];

  constructor(
    private readonly submissionService: SubmissionService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly toastService: ToastService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response && response.length > 0) {
            this.configurationsIntake = response;
          }
        },
        error: (err) => {
          console.error('An error occurred during get config details: ', err);
        },
      });
    this.gethangeOrderTooltip();
    this.getIntakeTooltips();
  }

  getIntakeTooltips(): void {
    this.submissionService
      .getIntakeTooltips()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: toolTipConfig[]) => {
          if (response && response.length > 0) {
            this.tooltipIntake = response;
          }
        },
        error: (err) => {
          console.error(
            'An error occurred during tooltips details fetch: ',
            err
          );
        },
      });
  }

  fieldCleared(): void {
    this.opportunityList = [];
    this.selectedOportunity = '';
    this.showDropdown = false;
    this.notFound = '';
  }

  selectOpportunityChanged(): void {
    if (!this.isFormDisabled) {
      this.totalCount = 0;
      this.pageNumber = 1;
      if (
        this.selectedOportunity.length > 2 &&
        this.selectedOportunity !== ''
      ) {
        this.fetchOpportunitySearchResult(1, this.selectedTabCode);
      } else {
        this.notFound = '';
        this.opportunityList = [];
        this.totalCount = 0;
        this.showDropdown = false;
      }
    }
  }

  fetchOpportunitySearchResult(pageNumber: number, tabCode: string): void {
    this.submissionService
      .getOpportunityDetails(
        this.selectedOportunity,
        pageNumber,
        this.pageSize,
        tabCode
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (opportunity: OpportunityList) => {
          if (opportunity?.totalOpportunityCount > 0) {
            this.notFound = '';
            if (pageNumber === 1) {
              this.opportunityList = opportunity?.opportunities;
            } else {
              this.opportunityList = [
                ...this.opportunityList,
                ...(opportunity?.opportunities ?? []),
              ];
            }
            this.totalCount = opportunity?.totalOpportunityCount;
            this.tabs[0].count = opportunity?.activeOpportunityCount;
            this.tabs[1].count = opportunity?.totalOpportunityCount;
            if(opportunity.activeOpportunityCount == 0){
              if(this.selectTabIndex == 0){
                this.selectTabIndex = 1;
               this.tabChanged(this.tabs[1].code);
              } 
            }else {
              if(this.selectTabIndex == 1){
                this.selectTabIndex = 0;
                this.tabChanged(this.tabs[0].code);
              }
            }
            if (this.opportunityList.length > 0) this.showDropdown = true;
          } else {
            this.notFound = this.findText(opportunity);
            this.opportunityList = [];
            this.totalCount = 0;
            this.showDropdown = false;
          }
        },
        error: (err) => {
          console.error('Error fetching submission details', err);
        },
      });
  }

  findText(opportunity: OpportunityList): string {
    const notFoundtext = this.configurationsIntake.find(
      (config) => config.appConfigurationLabelKey === opportunity?.notFoundLabel
    )?.appConfigurationLabelValue;
    return notFoundtext ?? '';
  }

  gethangeOrderTooltip(): string {
    return (
      this.tooltipIntake.find(
        (config) => config.appConfigurationLabelKey === 'CHANGE_ORDER_TOOL_TIP'
      )?.appConfigurationLabelValue ?? ''
    );
  }

  fetchOpportunitySearchResultMore(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      if (this.pageNumber < this.totalCount / this.pageSize) {
        this.pageNumber++;
        this.fetchOpportunitySearchResult(
          this.pageNumber,
          this.selectedTabCode
        );
      }
    }
  }

  fetchOpportunityDetails(opportunity: OpportunityDetails): void {
    if (
      this.dgmfId === opportunity?.dgmfId ||
      this.swiftId === opportunity?.swiftId ||
      this.gmdmId === opportunity?.gmdmId
    ) {
      this.showWarningMessage = false;
    } else {
      this.showWarningMessage = true;
    }
    this.opportunityList = [];
    this.showDropdown = false;
    this.showOpportunityDetails = true;
    this.changeOrderList = [opportunity];
    this.selectedOportunity = '';
    this.linkChangeOrderAndNavigate();
  }

  removeOpportunity(): void {
    this.changeOrderList = [];
    this.showWarningMessage = false;
  }

  tabChanged(event: string): void {
    this.selectedTabCode = event;
    this.fetchOpportunitySearchResult(1, this.selectedTabCode);
  }

  get isFormDisabled(): boolean {
    return !this.permissionObj['isIntakeSubmissionELSOWOpportunityIDEnable'];
  }

  linkChangeOrderAndNavigate(): void {
    this.progressIndicator.show();
    this.submissionService
      .linkChangeOrder(
        String(this.opportunityNumber),
        this.changeOrderList[0].opportunityNumber,
        String(this.submissionId)
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: GenericResponse) => {
          if (response.isSuccess) {
            this.progressIndicator.hide();
            this.setToast({
              ...this.toastMessage,
              title:
                IntakeConstant.LINK_CHANGE_ORDER_TOAST_MESSAGE +
                this.changeOrderList[0].opportunityNumber,
            });
            setTimeout(() => {
              this.reloadCurrentRoute();
            }, IntakeConstant.TIMEOUT_TIMER_BIGGEST);
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error('Error fetching submission types', error);
          this.progressIndicator.hide();
        },
      });
  }

  setToast(toast: ToastOptions): void {
    this.toastService.createToast(toast);
  }

  async reloadCurrentRoute(): Promise<void> {
    const link = `submission/opportunity-details/${this.changeOrderList[0].opportunityNumber}`;
    const queryParams = {
      submissionId: this.submissionId,
    };
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    await this.router.navigate([link], { queryParams: queryParams });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
