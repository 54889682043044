import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AutosaverGlobalOptions } from './autosaver-global-options';
import { AutosaverOptions, IAutosaverOptions, Saver } from './saver';


@Injectable({
    providedIn: 'root'
})
export class AutosaverService {
    private globalOptions: AutosaverGlobalOptions = new AutosaverGlobalOptions();
    private defaultOptions = {
        delay: 1900,
        onError: null,
        onSuccess: null,
        onAutoSaveStarts: null,
        reloadTnpOnSuccess: false
    } as unknown as IAutosaverOptions;

    public autosaveModules: any; //all registered autosave modules
    constructor(
        // private $rootScope: RootScopeService,
        // private tnpDataService: TopNavigationPanelService,
        // private dapConfig: DapConfig,
    ) {
        this.globalOptions.initSaverData();
    }

    public getSaverState(): Observable<number> {
        return this.globalOptions.autosaverState.valueChanged;
    }

    public getSaverCurrentState(): any {
        return this.globalOptions.autosaverState.getValue();
    }

    public newSaver(modelName: string,
        context: any,
        saveMethod: (model: any) => Promise<any>,
        options: IAutosaverOptions): Saver {
        if (!modelName || !context || !saveMethod) {
            throw 'AutosaverService thrown an error: model or saveMethod are undefined.';
        }
        var opt = new AutosaverOptions(this.defaultOptions.delay, this.defaultOptions.onError, this.defaultOptions.onSuccess, this.defaultOptions.onAutoSaveStarts, this.defaultOptions.reloadTnpOnSuccess);

        if (options) {
            opt.delay = options.delay || this.defaultOptions.delay;
            opt.onError = options.onError || this.defaultOptions.onError;
            opt.onSuccess = options.onSuccess || this.defaultOptions.onSuccess;
            opt.onAutoSaveStarts = options.onAutoSaveStarts || this.defaultOptions.onAutoSaveStarts;
            opt.reloadTnpOnSuccess = options.reloadTnpOnSuccess || this.defaultOptions.reloadTnpOnSuccess;
        }

        return new Saver(modelName, context,
            saveMethod, opt || this.defaultOptions,
            this.globalOptions);
    }
}


