
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';

@Component({
  selector: 'warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.less']
})
export class WarningPopupComponent { 
  constructor(public modalService: ModalService) {}

  @Input() isFooter: boolean=false;
  @Input() size: string = '';
  @Input() warningMessage: boolean=false;
  @Output() confirmDeleteEvent = new EventEmitter<void>();


  close() {
    //This will enable the scroll when the pop-up is closed
    document.body.classList.remove('no-scroll');
    this.modalService.close();
  }

  confirmDelete() {
    //This will enable the scroll when the pop-up is closed
    document.body.classList.remove('no-scroll');
    this.confirmDeleteEvent.emit();
    this.modalService.close();
  }
}