<div class="search-box-container">
  <div>
    <dds-search class="search-box"
      [size]="searchOptions.size ?? ''" [icon]="'dds-icon_search'"
      [isInverse]="searchOptions.isInverse ?? false"
      (fieldCleared)="clearSearchResults()">
      <input ddsSearch
        [(ngModel)]="searchKey"
        [placeholder]="searchOptions.placeholder ?? ''"
        (input)="onSearchKeyChange()"
        (click)="inputClick()" />
    </dds-search>
  </div>

  <div class="searchdata eclipse-content" *ngIf="isResultContainerVisible">
      <div class="text-gray" *ngIf="isLoading">Searching...</div>
      <div class="text-align-left" *ngIf="!isLoading && isSearchItemsVisible && items.length == 0">No matches found</div>
      <div class="text-align-left text-gray " *ngIf="isLastSearchItemsVisible && !isLoading">Last search</div>
      <div class="text-align-left" *ngIf="isNoLastSearchItemsMessageVisible && !isLoading">No recent search</div>
      <ng-container *ngIf="!isLoading && isSearchItemsVisible">
      <dds-tabs
        [theme]="'primary'"
        [themeType]="'primary'"
        [size]="'md'"
        [isResponsive]="true"
        [disabled]="false"
        [ariaLabel]="'Tabs'"
        class="text-align-left"
      >
        <dds-tab
          *ngFor="let tab of subTabs"
          [label]="tab.searchBoxSubTabNameCount"
          [counter]="tab.count"
          [customClass]="tab.searchBoxSubTabCode === tabSetCurrentId ? 'active' : ''"
          (tabChanged)="tabsetChange(tab.searchBoxSubTabCode)"
        >
        </dds-tab>
      </dds-tabs>
      <div class="extra-border"></div>
    </ng-container>
    <div class="text-align-left padding-top-s padding-bottom-xs" *ngIf="isMoreItemsMessageVisible && !isLoading">There are more search results, Please refine your criteria to narrow down your search results</div>
      <div class="search-results-container" *ngIf="(!isLoading && isSearchItemsVisible && items.length > 0) || isLastSearchItemsVisible">
        <div *ngFor="let item of tempitems" class="search-result-item" (click)="itemClick(item)">
          <div class="search-result-header" [innerHTML]="highlightSearchKey(item.entityName, searchKey)">
          </div>
          <div class="search-result-details">
            <div *ngIf="item.entityTypeId === 4">
              <span class="font-bold-400">SAP Client No: </span>
              <span class="font-bold-400">{{ item.entityId }}</span>
            </div>
            <div *ngIf="item.entityTypeId === 0">
              <span class="font-bold-400">Opportunity ID: </span>
              <span class="font-bold-400">{{ item.entityId }}</span>
              <span class="separator"> | </span>
              <span class="font-bold-400">Stage: </span>
              <span class="font-bold-400">{{ item.opportunityStageName }}</span>
              <span class="separator"> | </span>
              <span class="font-bold-400">WBS: </span>
              <span class="font-bold-400">{{ item.wbsCode }}</span>
            </div>
            <div *ngIf="item.entityTypeId === 1">
              <span class="font-bold-400 submission-type-code">{{item.submissionTypeCode}} </span>
              <span class="separator"> | </span>
              <span
                class="padding-xxs"
                [style.color]="
                          transformStatus(item.submissionStatusName)
                            | statusStyle : 'color'
                        "
                        [style.backgroundColor]="
                          transformStatus(item.submissionStatusName)
                            | statusStyle : 'backgroundColor'"
              >{{ item.submissionStatusName }}</span>
              <span class="separator"> | </span>
              <span class="font-bold-400">Submission ID: </span>
              <span class="font-bold-400">{{ item.entityId }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
