import { Component, OnInit } from '@angular/core';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { Size, Themes, ErrorState, Column } from '@usitsdasdesign/dds-ng/shared';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { DatepickerOptions, DatepickerStates } from '@usitsdasdesign/dds-ng/datepicker';
import { RadioButton } from '@usitsdasdesign/dds-ng/radio';
import { MultiSelectOptions, SelectControlTypes, SelectType } from "@usitsdasdesign/dds-ng/multi-select";
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';
import { UploaderOptions } from '@usitsdasdesign/dds-ng/uploader';
import { GridColumn } from '../../http/third-party-repository/third-party-repository-api.service';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';

export class ThirdPartyRepositorySettings {

  //Constants
  private static readonly Text_Area_Options_Placeholder = 'Enter text';
  private static readonly Third_Party_Name_Placeholder = 'Search by name';
  private static readonly Third_Party_Name_Manual_Placeholder = 'Enter name';
  private static readonly Agreement_Status_Placeholder = 'Select status';
  private static readonly Agreement_Type_Placeholder = 'Select type';
  private static readonly Contractor_Type_Placeholder = 'Select type';
  public static readonly CoveredDeloitteEntity_Placeholder = 'Select';
  public static readonly People_Placeholder = 'Select';
  private static readonly Agreement_Name_Max_Length = 100;
  private static readonly Notes_Max_Length = 4000;


  private static readonly Datepicker_Placeholder = 'Select date';
  private static readonly Datepicker_DateFormat = 'MM/dd/yyyy';

  //TPA Control Settings
  public static readonly textAgreementNameOptions: TextareaOptions = {
    placeholder: this.Text_Area_Options_Placeholder,
    minHeight: 100,
    maxLength: this.Agreement_Name_Max_Length,
    maxLengthPosition: 'bottom-right',
    size: Size.md
  };

  public static readonly textNotesOptions: TextareaOptions = {
    placeholder: this.Text_Area_Options_Placeholder,
    minHeight: 100,
    maxLength: this.Notes_Max_Length,
    maxLengthPosition: 'bottom-right',
    size: Size.md
  };

  public static readonly textAgreementTypeOptions: TextareaOptions = {
    placeholder: this.Text_Area_Options_Placeholder,
    minHeight: 100,
    maxLength: this.Agreement_Name_Max_Length,
    maxLengthPosition: 'bottom-right',
    size: Size.md
  };

  public static readonly searchThirdPartyNameOptions: SearchOptions = {
    placeholder: this.Third_Party_Name_Placeholder,
    maxLength: this.Agreement_Name_Max_Length,
    size: Size.md,
    customClass: '',
  };

  public static readonly enterThirdPartyNameOptions: SearchOptions = {
    placeholder: this.Third_Party_Name_Manual_Placeholder,
    maxLength: this.Agreement_Name_Max_Length,
    size: Size.md,
    customClass: '',
  };

  public static readonly selectAgreementStatusOptions: SelectOptions = {
    placeholder: this.Agreement_Status_Placeholder,
    size: Size.md,
    disabled: false
  };

  public static readonly selectAgreementTypeOptions: SelectOptions = {
    placeholder: this.Agreement_Type_Placeholder,
    size: Size.md,
    disabled: false
  };

  public static readonly selectContractorTypeOptions: SelectOptions = {
    placeholder: this.Contractor_Type_Placeholder,
    size: Size.md,
    disabled: false
  };

  public static readonly effectiveDateOptions: DatepickerOptions = {
    size: Size.md,
    placeholder: this.Datepicker_Placeholder,
    format: this.Datepicker_DateFormat,
    minMode: DatepickerStates.day,
    isResponsive: true,
    isManualInput: false
    //,disabledDates: { before: new Date() } // This disables all dates before the current date
  };

  public static readonly terminationDateOptions: DatepickerOptions = {
    size: Size.md,
    placeholder: this.Datepicker_Placeholder,
    format: this.Datepicker_DateFormat,
    minMode: DatepickerStates.day,
    isResponsive: true,
    isManualInput: false
    //,disabledDates: { before: new Date() } // This disables all dates before the current date
  };

  public static readonly terminationNoticeDueDate: DatepickerOptions = {
    size: Size.md,
    placeholder: this.Datepicker_Placeholder,
    format: this.Datepicker_DateFormat,
    minMode: DatepickerStates.day,
    isResponsive: true,
    isManualInput: false
    //,disabledDates: { before: new Date() } // This disables all dates before the current date
  };

  public static readonly auotRenewRadioBtn: RadioButton[] = [
  {
    options: {
      label: 'Yes',
      theme: Themes.green,
    },
    value: 'true',
    },
  {
    options: {
      label: 'No',
      theme: Themes.green,
    },
    value: 'false',
    }];

  public static readonly multiSelectCoveredDeloitteEntityOptions: MultiSelectOptions = {
    label: '',
    size: Size.lg,
    description: '',
    placeholder: this.CoveredDeloitteEntity_Placeholder,
    type: SelectType.counter,
    controlType: SelectControlTypes.checkbox,
    theme: 'green',
    isInverse: false,
    disabled: false,
    isResponsive: false,
    isError: false,
    isRequired: false,
    displayTickAllBtn: true,
    errorMessage: '',
    errorState: ErrorState.default,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: "",
    stickerIsDisabled: false,
    stickerPosition: "bottom-left",
    stickerIndent: 0,
    stickerCustomClass: "",
    counterTypeText: '',
  };

  public static readonly ogcSearchOptions: SearchOptions = {
    placeholder: this.People_Placeholder,
    size: Size.lg,
    customClass: '',
  };

  public static readonly riskManagerSearchOptions: SearchOptions = {
    placeholder: this.People_Placeholder,
    size: Size.lg,
    customClass: '',
  };

  public static readonly multiOptions: TagOptions = {
    theme: Themes.dark,
    isRemovable: true,
    disabled: true,
    isError: false,
    isInverse: false,
    size: Size.lg,
    customClass: ''
  };

  public static readonly uploadAreaOptions: UploaderOptions = {
    heading: '',
    dragAndDropText: 'Drag and drop file(s)',
    description: 'DOCX, PPTX, PDF, JPG, PNG or ZIP. Max size of 50MB.',
    isDragAndDrop: true,
    isMultiple: false,
    acceptFormats: '',
    acceptExtensions: '',
    restrictedFormats: '',
    restrictedExtensions: '',
    exceptSymbols: '',
    btnLabel: 'Attach document(s)',
    chooseFileBtnKind: 'primary',
    isDownloadable: false,
    allowFilesWithoutType: false,
    disabled: false,
    isInverse: false
  };

  public static readonly documentColumns: GridColumn[] = [
    { name: 'fileName', header: 'Title', dataType: 'string', minWidth: '12rem' },
    { name: 'documentTypeName', header: 'Type', dataType: 'string', minWidth: '12rem' },
    { name: 'effectiveDate', header: 'Effective date', dataType: 'date', minWidth: '7rem' },
    { name: 'terminationDate', header: 'Termination date', dataType: 'date', minWidth: '7rem' },
    { name: 'isAutoRenewal', header: 'Auto renew', dataType: 'string', minWidth: '7rem' },
    { name: 'modifiedDate', header: 'Modified date', dataType: 'date', minWidth: '7rem' },
    { name: 'modifiedBy', header: 'Modified by', dataType: 'date', minWidth: '7rem' },
    { name: 'action', header: 'Action', dataType: 'date', minWidth: '7rem' }
  ];

  public static readonly fileTooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'bottom-left',
    tooltipIndent: 1,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
    tooltipMaxWidth: 300
  };
}
