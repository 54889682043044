import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { THIRD_PARTY_REPOSITORY_ROUTES } from '../third-party-repository/third-party-repository.states'
import { THIRD_PARTY_REPOSITORY_COMPONENTS } from '.';
import { FormsModule } from '@angular/forms';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { RouterModule } from '@angular/router';
import { EclipseCommonModule } from '../common/common.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { EclipseHeaderModule } from '../eclipse-header/eclipse-header.module';


@NgModule({
  declarations: [
    THIRD_PARTY_REPOSITORY_COMPONENTS
  ],
  imports: [
    CommonModule,
    LibModule,
    FormsModule,
    EclipseCommonModule,
    EclipseHeaderModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    RouterModule.forChild(THIRD_PARTY_REPOSITORY_ROUTES),
  ]
})
export class ThirdPartyRepositoryModule { }
