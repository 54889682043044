import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environment/environment";
import { DocumentMetaDataDetail } from "../document/document.service";
import { Client } from "../../common/models/client.model";

@Injectable({
  providedIn: 'root'
})

export class ClientWebapiService {
  constructor(private httpService: HttpClient) { }
  private header = new HttpHeaders({ 'content-type': 'application/json' });

  clientApiUrl: string = environment.apiUrl;

  getClientInfo(clientNumber: string): Observable<Client> {
    return this.httpService.get<Client>(this.clientApiUrl + 'Client/GetClientInfo?clientNumber=' + clientNumber);
  }

  saveNCADocumentMetaData(data: any): Observable<any> {
    var baseURL = environment.apiConfig.redirectUri;//For non-Local
    //var baseURL = "https://localhost:56325";//For local
    const url = this.clientApiUrl + 'NCA/ClientStatus/SaveNCADocumentMetaData';
    var finalData = JSON.stringify(data);
    return this.httpService.post(url, finalData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  updateNCADocumentMetaData(data: any): Observable<any> {
    var baseURL = environment.apiConfig.redirectUri;//For non-Local
    //var baseURL = "https://localhost:56325";//For local
    const url = this.clientApiUrl + 'NCA/ClientStatus/UpdateNCADocumentMetaData';
    var finalData = JSON.stringify(data);
    return this.httpService.post(url, finalData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  getDocumentTypeData(): Observable<any> {
    var baseURL = environment.apiConfig.redirectUri;//For non-Local
    //var baseURL = "https://localhost:56325";//For local
    const url = this.clientApiUrl + 'NCA/ClientStatus/GetDocumentType';
    return this.httpService.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  getNCADocumentUploadData(documentSectionId: string, clientmilestoneId: string): Observable<DocumentTypeData[]> {
    const url = this.clientApiUrl + 'NCA/ClientStatus/GetNCADocumentMetaData?clientMilestonedocumentSectionId=' + documentSectionId + '&clientMilestoneId=' + clientmilestoneId;
    return this.httpService.get<DocumentTypeData[]>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
}

export interface ClientNCADocumentDetail extends DocumentMetaDataDetail {
  clientMilestoneId: number;
  clientMilestoneDocumentSectionId: number;
  clientMilestoneDocumentTypeId: number;
}

export interface DocumentTypeData extends DocumentMetaDataDetail {
  clientMilestoneDocumentTypeName: string;
  clientMilestoneDocumentTypeId: number;
  clientMilestoneDocumentSectionId: number;
}
