<div class="dds-modal-overlay" (click)="closeConfirmModal()"></div>

<div ddsResize class="dds-modal dds-modal_lg">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Confirm Submission</span>
    <dds-button
      [size]="'sm'"
      [icon]="modalCloseButtonOptions.icon ?? _blank"
      [kind]="modalCloseButtonOptions.kind ?? _blank"
      [theme]="modalCloseButtonOptions.theme ?? _blank"
      [ariaLabel]="modalCloseButtonOptions.ariaLabel ?? _blank"
      class="dds-modal__close"
      (clicked)="closeConfirmModal()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div
      class="padding-right-m padding-left-s relative info font-size-14 line-height-sm"
    >
      <div class="font-bold-600">
        Confirming submission, I acknowledge that: 
      </div>
      <div class="margin-top-s">
        <p>
          I have reviewed the information contained herein, including
          attachments in the document repository. All information is accurate,
          reviews are complete, and associated recommendations from these
          reviews have either been incorporated or addressed. I have complied
          with the requirements of
          <a
            href="https://researchportal.deloitte.com/us/en-US/auditing/manual/U.S.%20Deloitte%20Policies%20Manual/GUID-E0AC41D5-8121-4347-9D95-6A4E04C89AE1/10640%20%E2%80%94%20External%20Communications%20Manual/GUID-33DDC9FF-9F8F-4D8A-AE9A-6883D7746EC3/GUID-A1B87D4C-8423-46E5-AA7C-1AD73641C27C#2_81382"
            target="_blank"
            >U.S. DPM 10640</a
          >, External Communications.
        </p>

        <p>
          I have obtained all required conditional reviews and any necessary
          LCSP or client pre-approvals for client references and logo use.
        </p>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item"
        [size]="UI_Element_Size"
        ariaLabel="Confirm"
        (clicked)="confirmSubmission()"
        (keyboardClicked)="confirmSubmission()"
        >Confirm</dds-button
      >
      <dds-button
        class="dds-modal__footer-item"
        [kind]="secondaryLoudButtonOptions"
        [size]="UI_Element_Size"
        ariaLabel="Cancel"
        (clicked)="closeConfirmModal()"
        (keyboardClicked)="closeConfirmModal()"
        >Cancel</dds-button
      >
    </div>
  </div>
</div>
