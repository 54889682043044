<progress-indicator></progress-indicator>

<div *ngIf="!isLoading">
  <div class="audit-container">
    <div class="title inline-block font-size-32">Watchlist audit trail</div>
  </div>

  <div class="eclipse-main-tab-header text-left margin-top-xl margin-bottom-l">
  <span class="padding-right-xxs">{{clientName}}</span>
  <span class="sap-id">(ID: {{clientNumber}})</span>
  </div>

  <div class="row flex-container">
    <div class="col-3">
      <div class="eclipse-small-header-grey text-left margin-bottom-xxs">User</div>
      <dds-select [list]="selectItems"
                  [placeholder]="selectOptions.placeholder ?? ''"
                  [size]="selectOptions.size ?? ''"
                  [stickerWidth]="selectOptions.stickerWidth ?? ''"
                  [stickerPosition]="selectOptions.stickerPosition ?? ''"
                  [(ngModel)]="selectedUser"
                  class="full-width">
      </dds-select>

      <div class="eclipse-small-header-grey text-left margin-top-l margin-bottom-xxs">Period</div>
      <dds-daterangepicker class="dds-filter__section full-width"
                           [size]="dateRangePickerOptions.size ?? ''"
                           [placeholder]="dateRangePickerOptions.placeholder ?? []"
                           [format]="dateRangePickerOptions.format ?? ''"
                           [isManualInput]="dateRangePickerOptions.isManualInput ?? false"
                           [(ngModel)]="selectedDate"
                           [stickerPosition]="dateRangePickerOptions.stickerPosition ?? ''"></dds-daterangepicker>

      <dds-button class="full-width margin-top-l"
                  [theme]="buttonOptions.theme ?? ''"
                  [kind]="buttonOptions.kind ?? ''"
                  [size]="buttonOptions.size ?? ''"
                  [width]="buttonOptions.width ?? ''"
                  (click)="applyfilter()"
                  [ariaLabel]="buttonOptions.ariaLabel ?? ''">Apply filter</dds-button>

    </div>

    <div class="main-block tabsection col-9">

      <div class="image-section" *ngIf="auditItems && auditItems.length === 0">
        <div class="empty-state-container">
          <img src="assets/images/no_watchlist_clients.svg" alt="empty-state">
          <div class="empty-state-text">There is no data to display</div>
        </div>
      </div>

      <div class="table-data" *ngIf="auditItems && auditItems.length > 0">
        <table class="dds-data-table">

          <thead class="dds-data-table__header">
            <tr>
              <th *ngFor="let column of gridColumns"
                  class="dds-data-table__header-cell"
                  [ngStyle]="{ width: column.width, minWidth: column.minWidth }">

                <div class="dds-data-table__content">
                  <span>{{column.header || column.name}}</span>
                </div>

              </th>
            </tr>
          </thead>

          <tbody class="dds-data-table__body">
            <ng-container *ngFor="let row of auditItems; let i = index">
              <tr class="dds-data-table__row">
                <td *ngFor="let column of gridColumns" class="dds-data-table__cell"
                    [ngStyle]="{width: column.width, minWidth: column.minWidth }">

                  <!--TODO : add redirection logic once client details page is added-->
                  <div *ngIf="column.name == 'name'" class="client-name-container">
                    <span class="padding-bottom-xxs">{{ row.userName ? row.userName : '-' }}</span>
                  </div>

                  <!--TODO : add redirection logic once client details page is added-->
                  <div *ngIf="column.name == 'date/Time'">
                    <div>{{ row.loggedDate ? row.loggedDate : '-' }}</div>
                    <div>{{ row.loggedTime ? row.loggedTime : '-' }}</div>
                  </div>

                  <div *ngIf="column.name === 'Description'" class="status-comment-container">
                    <div class="user-action padding-bottom-s">
                      <span class="eclipse-header">User action: </span>
                      <span class="">{{row.userAction}}</span>
                    </div>

                    <div class="padding-bottom-s" *ngIf="row.statusChangeDescription">
                      <span class="eclipse-header">{{row.statusChangeDescription}}</span>
                      <div class="">
                        <span class="eclipse-content-grey">From: </span>
                        <span class="">{{row.fromStatus ? row.fromStatus : '[None]' }}</span>
                      </div>
                      <div class="">
                        <span class="eclipse-content-grey">To: </span>
                        <span class="">{{row.toStatus ? row.toStatus : '[None]'}}</span>
                      </div>
                    </div>

                    <div class="padding-bottom-s" *ngIf="row.commentChangeDescription">
                      <span class="eclipse-header">{{row.commentChangeDescription}}</span>
                      <div class="note-field text-ellipsis" #fromCommentText data-id="{{i}}" [class.show-more]="showMore[i]?.from">
                        <span class="eclipse-content-grey">From: </span>
                        <span>{{row.fromComment ? row.fromComment : '[None]'}}</span>
                      </div>
                      <dds-button *ngIf="shouldShowButton[i]?.from && !showMore[i]?.from" [theme]="options.theme ?? ''"
                                  [kind]="options.kind ?? ''"
                                  [size]="options.size ?? ''"
                                  [width]="options.width ?? ''"
                                  [icon]="options.icon ??''"
                                  (clicked)="toggleShowMore(i, 'from')">Show more</dds-button>
                      <dds-button *ngIf="showMore[i]?.from" [theme]="options.theme ?? ''"
                                  [kind]="options.kind ?? ''"
                                  [size]="options.size ?? ''"
                                  [width]="options.width ?? ''"
                                  [icon]="options.icon ??''"
                                  (clicked)="toggleShowMore(i, 'from')">Show less</dds-button>

                      <div class="note-field text-ellipsis" #toCommentText data-id="{{i}}" [class.show-more]="showMore[i]?.to">
                        <span class="eclipse-content-grey">To: </span>
                        <span>{{row.toComment ? row.toComment : '[None]'}}</span>
                      </div>

                      <dds-button *ngIf="shouldShowButton[i]?.to && !showMore[i]?.to" [theme]="tooptions.theme ?? ''"
                                  [kind]="tooptions.kind ?? ''"
                                  [size]="tooptions.size ?? ''"
                                  [width]="tooptions.width ?? ''"
                                  [icon]="tooptions.icon ??''"
                                  (clicked)="toggleShowMore(i, 'to')">Show more</dds-button>
                      <dds-button *ngIf="showMore[i]?.to" [theme]="tooptions.theme ?? ''"
                                  [kind]="tooptions.kind ?? ''"
                                  [size]="tooptions.size ?? ''"
                                  [width]="tooptions.width ?? ''"
                                  [icon]="tooptions.icon ??''"
                                  (clicked)="toggleShowMore(i, 'to')">Show less</dds-button>

                    </div>

                    <div class="padding-bottom-s" *ngIf="row.parentClientChangeDescription">
                      <span class="eclipse-header">{{row.parentClientChangeDescription}}</span>
                      <div class="">
                        <span class="eclipse-content-grey">From: </span>
                        <span class="">{{row.fromParentClient ? row.fromParentClient : '[None]' }}</span>
                      </div>
                      <div class="">
                        <span class="eclipse-content-grey">To: </span>
                        <span class="">{{row.toParentClient ? row.toParentClient : '[None]'}}</span>
                      </div>
                    </div>

                  </div>
                </td>

              </tr>
            </ng-container>
          </tbody>

        </table>

        <app-new-pagination-and-grid [tileCountforPage]="this.countforPagination" [pageUI]="this.gotoPage+1" (pageChanged)="onPageChanged($event)" (valueChanged)="onValueChanged($event)"></app-new-pagination-and-grid>
        <div class="padding-bottom-s"></div>
      </div>

    </div>
  </div>
</div>


