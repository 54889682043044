<div class="tiles-container">
  <div class="page-header">
    <h2 class="title inline-block font-size-32">My dashboard</h2>
    <span class="seperator inline-block"></span>
    <select class="role-dropdown" [(ngModel)]="selectedRole" (change)="onRoleChange($event)" [ngStyle]="{'width': getRoleWidth()}">
      <option *ngFor="let role of roles" [value]="role" [ngClass]="{'title inline-block font-size-32': role === selectedRole}">{{ role }}</option>
    </select>
  </div>
  <app-nca-specialist-dashboard *ngIf="selectedRole === 'NCA specialist'"></app-nca-specialist-dashboard>
  <app-reviewer-dashboard *ngIf="selectedRole === 'Reviewer'"></app-reviewer-dashboard>
</div>
