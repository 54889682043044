import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import {
  CommunicationContains,
  communicationContainsGroupOptions,
  isAnalayzeRegulationGroupOptions,
  IsAnalayzeRegulationOptions,
  isCrossBusinessGlobalGroupOptions,
  isDiscussServicesToAssistWithRegGroupOptions,
  IsDiscussServicesToAssistWithRegOptions,
  isGenAIGroupOptions,
  isGovernmentRegulationInvolvedGroupOptions,
  isNewNascentEvolvProductsGroupOptions,
  isSensitiveMatterGroupOptions,
  isSpeakingOnPanelGroupOptions,
  isThirdPartyInvolvedGroupOptions,
  IsTPRMApprovalEmailIncludedOptions,
  multiSelectOptions,
  options,
  otherMemberFirm,
  radioBtnsOriginal,
  radioGroupOptions,
  tags,
  textareaOptions,
} from './risk-assessment-helper';
import { MultiSelectItem } from '@usitsdasdesign/dds-ng/multi-select';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../../intake/constants/intake.constant';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { IAutosaverOptions, Saver } from '../../../../common/autosaver/saver';
import { AutosaverService } from '../../../../common/autosaver/autosaver.service';
import { RiskAssessmentFormData } from '../../../../common/models/external-communication-submission-details-common-section.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';

@Component({
  selector: 'app-risk-assessment',
  templateUrl: './risk-assessment.component.html',
  styleUrl: './risk-assessment.component.less',
})
export class RiskAssessmentComponent implements OnInit, OnDestroy {
  @Input() submissionDetailModel: any;
  @Output() riskAssessmentRequiredFieldsFilled: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() isControlEnable!: boolean;
  @Input() isDisabledOnReadOnlyStatus!: boolean;
  @Input() submissionId!: number;

  @Output() getSubmissionDetails: EventEmitter<void> = new EventEmitter<void>();
  saveComplexityModule!: Saver | null;
  model: RiskAssessmentFormData = {} as RiskAssessmentFormData;

  riskAssessmentForm!: FormGroup;
  radioBtns: RadioButton[] = radioBtnsOriginal;
  radioGroupOptions: RadioGroupOptions = radioGroupOptions;
  multiSelectOptions = multiSelectOptions;
  CommunicationContains: MultiSelectItem[] = CommunicationContains;
  memberFirm: MultiSelectItem[] = otherMemberFirm;
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  Tags = tags;
  options: TagOptions = options;
  showMultiSelect: boolean = false;
  showTextAreaForm: boolean = false;
  textareaOptions: TextareaOptions = textareaOptions;
  showThirdPartyInvolved: boolean = false;
  showAnalayzeRegulation: boolean = false;
  showDiscussServicesToAssistWithReg: boolean = false;
  isCrossBusinessGlobalGroupOptions = isCrossBusinessGlobalGroupOptions;
  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  riskAssessmentDetails: any;
  showCommunicationContains: boolean = false;
  showBusinessOrMemberFirm: boolean = false;
  emptyString: string = IntakeConstant.EMPTY_SPACE;

  isGenAIGroupOptions = isGenAIGroupOptions;
  communicationContainsGroupOptions = communicationContainsGroupOptions;
  isNewNascentEvolvProductsGroupOptions = isNewNascentEvolvProductsGroupOptions;
  isSensitiveMatterGroupOptions = isSensitiveMatterGroupOptions;
  isAnalayzeRegulationGroupOptions = isAnalayzeRegulationGroupOptions;
  isThirdPartyInvolvedGroupOptions = isThirdPartyInvolvedGroupOptions;
  isDiscussServicesToAssistWithRegGroupOptions =
    isDiscussServicesToAssistWithRegGroupOptions;
  isGovernmentRegulationInvolvedGroupOptions =
    isGovernmentRegulationInvolvedGroupOptions;
  isSpeakingOnPanelGroupOptions = isSpeakingOnPanelGroupOptions;
  IsTPRMApprovalEmailIncludedOptions = IsTPRMApprovalEmailIncludedOptions;
  IsAnalayzeRegulationOptions = IsAnalayzeRegulationOptions;
  IsDiscussServicesToAssistWithRegOptions =
    IsDiscussServicesToAssistWithRegOptions;

  constructor(
    private readonly fb: FormBuilder,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly autosaverService: AutosaverService
  ) {
    this.initializeForm();
  }

  ngOnInit(): void {
    let autoSaverOptions = {
      onSuccess: (saver: any, result: RiskAssessmentFormData) => {
        return this.autoSaveHandler();
      },
    } as IAutosaverOptions;
    this.saveComplexityModule = this.autosaverService.newSaver(
      'model',
      this,
      (model) => this.saveDetails(model),
      autoSaverOptions
    );

    this.saveComplexityModule.start();

    this.riskAssessmentForm.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.emitFormData();
        this.saveComplexityModule?.saveNow();
      });

    this.riskAssessmentForm
      .get('IsThirdPartyInvolved')
      ?.valueChanges.subscribe((value) => {
        if (value == ExternalCommunicationConstant.YES) {
          this.showThirdPartyInvolved = true;
        } else {
          this.showThirdPartyInvolved = false;
        }
      });

    this.riskAssessmentForm
      .get('IsCrossBusinessGlobal')
      ?.valueChanges.subscribe((value) => {
        if (value == ExternalCommunicationConstant.YES) {
          this.showMultiSelect = true;
        } else {
          this.showMultiSelect = false;
        }
      });

    this.riskAssessmentForm
      .get('BusinessOrMemberFirm')
      ?.valueChanges.subscribe((value) => {
        if (Array.isArray(value)) {
          this.showTextAreaForm = value.some(
            (item) => item.label === 'Other Member Firm'
          );
        }
        this.showBusinessOrMemberFirm =
          this.riskAssessmentForm.get('BusinessOrMemberFirm')?.value.length > 0;
      });

    this.riskAssessmentForm
      .get('CommunicationContains')
      ?.valueChanges.subscribe((value) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            if (item.label == 'No, none of the above apply') {
              this.riskAssessmentForm
                .get('CommunicationContains')
                ?.setValue([]);
            }
          });
        }
        this.showCommunicationContains =
          this.riskAssessmentForm.get('CommunicationContains')?.value.length >
          0;
      });

    this.riskAssessmentForm
      .get('IsGovernmentRegulationInvolved')
      ?.valueChanges.subscribe((value: string) => {
        if (value == ExternalCommunicationConstant.YES) {
          this.showAnalayzeRegulation = true;
          this.showDiscussServicesToAssistWithReg = true;
        } else {
          this.showAnalayzeRegulation = false;
          this.showDiscussServicesToAssistWithReg = false;
          this.riskAssessmentForm.get('IsAnalyzeRegulation')?.setValue(null);
          this.riskAssessmentForm.get('IsDiscussServicesToAssistWithReg')?.setValue(null);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submissionId']?.currentValue) {
      this.getRiskAssessmentDetails();
    }

    const changesIsControlEnable = changes['isControlEnable']?.currentValue;

    if (
      changesIsControlEnable !== null &&
      changesIsControlEnable !== undefined
    ) {
      this.disableForm(changesIsControlEnable);
    }
  }

  disableForm(controlEnable: boolean): void {
    if (controlEnable) {
      this.riskAssessmentForm.enable();
    } else {
      this.riskAssessmentForm.disable();
    }
  }

  async saveDetails(model: RiskAssessmentFormData): Promise<any> {
    return this.externalCommService
      .saveRiskAssessmentData(model)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.getSubmissionDetails.emit();
          // TODO: If we are using checkRequiredFields() here, please remove usage from html.
          this.checkRequiredFields();
        },
        error: (error: HttpErrorResponse) => {
          console.error(
            'An error occurred during saving riskAssessmentDetails: ',
            error
          );
        },
      });
  }

  autoSaveHandler(): void {
    //if refresh is needed after save API, can be done here;
  }

  removeMultiSelectItemBusinessOrMemberFirm(item: MultiSelectItem): void {
    const current = this.riskAssessmentForm.get('BusinessOrMemberFirm')?.value;
    const updated = current.filter((i: MultiSelectItem) => i !== item);
    this.riskAssessmentForm.get('BusinessOrMemberFirm')?.setValue(updated);
  }

  getRiskAssessmentDetails(): void {
    if (this.submissionId) {
      this.externalCommService
        .getExtCommRiskAssessment(this.submissionId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response) => {
            this.riskAssessmentDetails = response;
            this.updateRiskForm(this.riskAssessmentDetails);
          },
          error: (err: HttpErrorResponse) => {
            console.error(
              'An error occurred during fetching riskAssessmentDetails: ',
              err
            );
          },
        });
    }
  }

  emitFormData(): void {
    const formData = this.riskAssessmentForm.value;
    const transformArrayToString = (array: any[]) =>
      array?.map((item) => item.label).join(';');
    const transformBoolean = (value: string | null): boolean | null => {
      if (value === null) {
        return null;
      }
      return value === ExternalCommunicationConstant.YES;
    };
    this.model = {
      SubmissionId: this.submissionId,
      IsCrossBusinessGlobal: formData.IsCrossBusinessGlobal,
      BusinessOrMemberFirm: transformArrayToString(
        formData.BusinessOrMemberFirm
      ),
      MemberFirmOther: formData.MemberFirmOther,
      IsGenAI: transformBoolean(formData.IsGenAI),
      CommunicationContains: transformArrayToString(
        formData.CommunicationContains
      ),
      IsNewNascentEvolvProducts: transformBoolean(
        formData.IsNewNascentEvolvProducts
      ),
      IsSensitiveMatter: transformBoolean(formData.IsSensitiveMatter),
      IsThirdPartyInvolved: transformBoolean(formData.IsThirdPartyInvolved),
      IsTrpmApprovalEmailIncluded: transformBoolean(
        formData.IsTrpmApprovalEmailIncluded
      ),
      IsGovernmentRegulationInvolved: transformBoolean(
        formData.IsGovernmentRegulationInvolved
      ),
      IsAnalyzeRegulation: transformBoolean(formData.IsAnalyzeRegulation),
      IsDiscussServicesToAssistWithReg: transformBoolean(
        formData.IsDiscussServicesToAssistWithReg
      ),
      IsSpeakingOnPanel: transformBoolean(formData.IsSpeakingOnPanel),
    };
  }

  updateRiskForm(riskAssessmentDetails: any): void {
    const transformBooleanToString = (
      value: boolean | undefined | null
    ): string | null => {
      if (value === null || value === undefined) {
        return null;
      }
      return value
        ? ExternalCommunicationConstant.YES
        : ExternalCommunicationConstant.NO;
    };

    const convertStringToArrayOfObjects = (
      str: string
    ): { label: string; value: boolean }[] => {
      if (!str?.trim()) {
        return [];
      } else {
        return str
          ?.split(';')
          .map((item) => ({ label: item?.trim(), value: true }));
      }
    };

    this.riskAssessmentForm.patchValue({
      IsCrossBusinessGlobal:
        riskAssessmentDetails?.isCrossBusinessGlobal ?? null,
      BusinessOrMemberFirm: convertStringToArrayOfObjects(
        riskAssessmentDetails?.businessOrMemberFirm
      ),
      MemberFirmOther: riskAssessmentDetails?.memberFirmOther,
      IsGenAI: transformBooleanToString(riskAssessmentDetails?.isGenAI),
      CommunicationContains: convertStringToArrayOfObjects(
        riskAssessmentDetails?.communicationContains
      ),
      IsNewNascentEvolvProducts: transformBooleanToString(
        riskAssessmentDetails?.isNewNascentEvolvProducts
      ),
      IsSensitiveMatter: transformBooleanToString(
        riskAssessmentDetails?.isSensitiveMatter
      ),
      IsAnalyzeRegulation: transformBooleanToString(
        riskAssessmentDetails?.isAnalayzeRegulation
      ),
      IsThirdPartyInvolved: transformBooleanToString(
        riskAssessmentDetails?.isThirdPartyInvolved
      ),
      IsTrpmApprovalEmailIncluded: transformBooleanToString(
        riskAssessmentDetails?.isTrpmApprovalEmailIncluded
      ),
      IsDiscussServicesToAssistWithReg: transformBooleanToString(
        riskAssessmentDetails?.isDiscussServicesToAssistWithReg
      ),
      IsGovernmentRegulationInvolved: transformBooleanToString(
        riskAssessmentDetails?.isGovernmentRegulationInvolved
      ),
      IsSpeakingOnPanel: transformBooleanToString(
        riskAssessmentDetails?.isSpeakingOnPanel
      ),
    });
  }

  initializeForm(): void {
    this.riskAssessmentForm = this.fb.group({
      IsCrossBusinessGlobal: [
        { value: this.emptyString, disabled: !this.isControlEnable },
      ],
      BusinessOrMemberFirm: [{ value: [], disabled: !this.isControlEnable }],
      MemberFirmOther: [
        { value: this.emptyString, disabled: !this.isControlEnable },
      ],
      IsGenAI: [{ value: this.emptyString, disabled: !this.isControlEnable }],
      CommunicationContains: [[{ value: [], disabled: !this.isControlEnable }]],
      IsNewNascentEvolvProducts: [
        { value: this.emptyString, disabled: !this.isControlEnable },
      ],
      IsSensitiveMatter: [
        { value: this.emptyString, disabled: !this.isControlEnable },
      ],
      IsAnalyzeRegulation: [
        { value: this.emptyString, disabled: !this.isControlEnable },
      ],
      IsThirdPartyInvolved: [
        { value: this.emptyString, disabled: !this.isControlEnable },
      ],
      IsTrpmApprovalEmailIncluded: [
        { value: this.emptyString, disabled: !this.isControlEnable },
      ],
      IsDiscussServicesToAssistWithReg: [
        { value: this.emptyString, disabled: !this.isControlEnable },
      ],
      IsGovernmentRegulationInvolved: [
        { value: this.emptyString, disabled: !this.isControlEnable },
      ],
      IsSpeakingOnPanel: [
        { value: this.emptyString, disabled: !this.isControlEnable },
      ],
    });
  }

  isFieldFilled(controlName: string): boolean {
    if (this.isDisabledOnReadOnlyStatus) return false;
    const control = this.riskAssessmentForm.get(controlName);
    if (Array.isArray(control?.value)) return control?.value?.length < 1; // For array
    return !(
      control !== null &&
      control.value !== null &&
      control.value !== this.emptyString
    );
  }

  removeMultiSelectItemCommContain(item: MultiSelectItem): void {
    const current = this.riskAssessmentForm.get('CommunicationContains')?.value;
    const updated = current.filter((i: MultiSelectItem) => i !== item);
    this.riskAssessmentForm.get('CommunicationContains')?.setValue(updated);
  }

  checkRequiredFields(): void {
    const formData: RiskAssessmentFormData = this.riskAssessmentForm.value;
    let requiredFieldsFilled: boolean =
      this.isNotNullAndNotEmpty(formData.IsCrossBusinessGlobal ?? null) &&
      formData?.IsGenAI !== null &&
      this.isNotNullAndNotEmpty(formData.CommunicationContains ?? null) &&
      formData?.IsNewNascentEvolvProducts !== null &&
      formData?.IsSensitiveMatter !== null &&
      formData?.IsThirdPartyInvolved !== null &&
      formData?.IsGovernmentRegulationInvolved !== null &&
      formData?.IsSpeakingOnPanel !== null;

    if (this.showAnalayzeRegulation) {
      requiredFieldsFilled =
        requiredFieldsFilled && formData?.IsAnalyzeRegulation !== null;
    }

    if (this.showThirdPartyInvolved) {
      requiredFieldsFilled =
        requiredFieldsFilled && formData?.IsTrpmApprovalEmailIncluded !== null;
    }

    if (this.showDiscussServicesToAssistWithReg) {
      requiredFieldsFilled =
        requiredFieldsFilled &&
        formData?.IsDiscussServicesToAssistWithReg !== null;
    }

    if (this.showTextAreaForm) {
      requiredFieldsFilled =
        requiredFieldsFilled &&
        this.isNotNullAndNotEmpty(formData.MemberFirmOther ?? null);
    }

    if (this.showMultiSelect) {
      requiredFieldsFilled =
        requiredFieldsFilled &&
        this.isNotNullAndNotEmpty(formData.BusinessOrMemberFirm ?? null);
    }

    this.riskAssessmentRequiredFieldsFilled.emit(requiredFieldsFilled);
  }

  isNotNullAndNotEmpty(value: any): boolean {
    return value !== null && value.length > 0;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
