<div>
  <div
    class="header-container row"
    *ngIf="currentDashboardTileCode != dashboardTileCode.NcaSummary"
  >
    <div class="col-5">
      <p class="padding-left-xs tile-name eclipse-main-tab-header">
        {{ gridTitle }}
      </p>
    </div>
    <div class="col-7 header-actions">
      <dds-search
        *ngIf="
          currentDashboardTileCode == 'NCASpecialist' &&
          gridTitle != 'Client continuances due' &&
          currentTileNameForGrid != 'CompleteMarsNcaForm' &&
          ((model && model.length > 0) || (!model && !!jupiterFilterValue))
        "
        search
        class="jupiter-search"
        [size]="searchOptions.size ?? ''"
        (valueChanged)="onSearchInput($event)"
        (fieldCleared)="clearAllFilter()"
      >
        <input
          class="jupiter-search-input"
          ddsSearch
          [(ngModel)]="jupiterFilterValue"
          [placeholder]="searchOptions.placeholder"
          [maxLength]="searchOptions.maxLength"
          (click)="inputClick()"
          (keydown)="validateKeypress($event)"
          (keydown.enter)="onSearchInput($event)"
        />
      </dds-search>
      <span
        class="clear-filters"
        *ngIf="
          selectedTileCount > 0 ||
          (currentDashboardTileCode == 'NCASpecialist' &&
            currentTileNameForGrid == 'CompleteMarsNcaForm') ||
          currentDashboardTileCode == 'Reviewer' ||
          currentDashboardTileCode == 'EngagementTeam'
        "
        (click)="clearAllFilter()"
        >Clear filters</span
      >
      <dds-button
        [theme]="downlaodbuttonoptions.theme ?? ''"
        [kind]="downlaodbuttonoptions.kind ?? ''"
        [size]="downlaodbuttonoptions.size ?? ''"
        [icon]="downlaodbuttonoptions.icon ?? ''"
        *ngIf="
          model &&
          model.length > 0 &&
          currentDashboardTileCode != dashboardTileCode.EngagementTeam &&
          (currentTileNameForGrid == reviewerTileCodes.CCDueNow ||
            currentDashboardTileCode === dashboardTileCode.RiskManager ||
            (currentDashboardTileCode === dashboardTileCode.Reviewer &&
              currentTileNameForGrid == reviewerTileCodes.ExtComm))
        "
        (click)="downloadData()"
      ></dds-button>
    </div>
  </div>

  <app-tabs-gridarea
    *ngIf="
      currentTileNameForGrid === engagementTileCodes.ExtComm &&
      (currentDashboardTileCode === dashboardTileCode.EngagementTeam ||
        currentDashboardTileCode === dashboardTileCode.Reviewer)
    "
    [tabs]="tabsList"
    [selectedTabCode]="selectedTabCode"
    (selectedTabChange)="onTabChange($event)"
  ></app-tabs-gridarea>

  <table class="dds-data-table">
    <thead class="dds-data-table__header">
      <tr>
        <th
          *ngFor="let column of gridColumns; let i = index"
          class="dds-data-table__header-cell top-aligned-header"
          [ngClass]="{
            'dds-data-table__header-cell_sorting_custom':
              currentDashboardTileCode === 'NcaSummary',
            'dds-data-table__header-cell_sorting':
              currentDashboardTileCode !== 'NcaSummary',
            'highlighted-column': isShownFilter && filteringColumn === column
          }"
          [ddsSticker]="
            isSortFilterPresent(column.name) ? customFilter : noFilter
          "
          [stickerPosition]="
            column.name === 'openOpportunities' ||
            column.name === 'priorFyClosedWBS' ||
            column.name === 'daysPendingForCCCompletion'
              ? filterStickerOptionsOnlySort.stickerPosition ?? ''
              : filterStickerOptions.stickerPosition ?? ''
          "
          [stickerIsOutsideClick]="
            column.name === 'openOpportunities' ||
            column.name === 'priorFyClosedWBS' ||
            column.name === 'daysPendingForCCCompletion'
              ? filterStickerOptionsOnlySort.stickerIsOutsideClick ?? false
              : filterStickerOptions.stickerIsOutsideClick ?? false
          "
          [stickerIndent]="
            column.name === 'openOpportunities' ||
            column.name === 'priorFyClosedWBS' ||
            column.name === 'daysPendingForCCCompletion'
              ? filterStickerOptionsOnlySort.stickerIndent ?? 0
              : filterStickerOptions.stickerIndent ?? 0
          "
          [stickerWidth]="
            column.name === 'openOpportunities' ||
            column.name === 'priorFyClosedWBS' ||
            column.name === 'daysPendingForCCCompletion'
              ? filterStickerOptionsOnlySort.stickerWidth ?? ''
              : filterStickerOptions.stickerWidth ?? ''
          "
          [stickerIsDisabled]="!isSortFilterPresent(column.name)"
          [attr.aria-expanded]="isShownFilter"
          aria-haspopup="listbox"
          (click)="toggleFilter(column)"
          (onHidden)="filterOnHidden()"
          #stickerDir="dds-sticker"
          [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
        >
          <div class="dds-data-table__content">
            <span
              *ngIf="
                column.name != columnName.NetServiceRevenue &&
                column.name != columnName.OpportunityIcon
              "
              class="padding-right-xxs"
              >{{ column.header || column.name }}</span
            >
            <div
              *ngIf="
                column.name === columnName.NetServiceRevenue &&
                (currentDashboardTileCode == dashboardTileCode.Reviewer ||
                  currentTileNameForGrid == reviewerTileCodes.CCDueNow)
              "
              class="group-container"
            >
              <span class="group-text-label"
                >Net services revenue
                <span class="icon-expand-grey"></span>
              </span>
              <span
                class="icon-s icon-info"
                [ddsTooltip]="
                  isMarsNCASpecialist &&
                  currentDashboardTileCode == dashboardTileCode.NCASpecialist
                    ? marsCCNetServicesRevenueToolTip
                    : netServicesRevenueToolTip
                "
                [tooltipInvokeType]="
                  groupToolTipoptions.tooltipInvokeType ?? ''
                "
                [tooltipType]="groupToolTipoptions.tooltipType ?? ''"
                [tooltipSize]="groupToolTipoptions.tooltipSize ?? ''"
                [tooltipPosition]="groupToolTipoptions.tooltipPosition ?? ''"
                [tooltipIndent]="groupToolTipoptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="groupToolTipoptions.tooltipHasBeak ?? false"
                [tooltipTheme]="groupToolTipoptions.tooltipTheme ?? ''"
                ddsFocus
                [tooltipMaxWidth]="groupToolTipoptions.tooltipMaxWidth ?? 0"
                (click)="stopEventPropagation($event)"
              >
              </span>
            </div>
            <span
              *ngIf="
                isSortFilterPresent(column.name) &&
                column.name != columnName.NetServiceRevenue
              "
              class="icon-expand-grey"
            >
            </span>
            <span
              *ngIf="sortingState.property === column.name"
              class="dds-icon dds-data-table__icon"
              [ngClass]="
                sortingState.ascending
                  ? 'dds-icon_sort-up'
                  : 'dds-icon_sort-down'
              "
            >
            </span>

            <span *ngIf="column.filterable">
              <span
                *ngIf="isSortFilterPresent(column.name)"
                class="dds-icon dds-icon_filter"
              ></span>
            </span>
          </div>
        </th>
        <th *ngIf="haveRowExpand()" class="dds-data-table__header-cell"></th>
      </tr>
    </thead>

    <tbody class="dds-data-table__body" *ngIf="model && model.length > 0">
      <ng-container *ngFor="let row of model; index as rowIndex">
        <tr
          class="dds-data-table__row"
          (click)="rowClicked(row)"
          [ddsTooltip]="
            currentDashboardTileCode === dashboardTileCode.Reviewer ||
            dashboardTileCode.EngagementTeam
              ? 'Open submission'
              : 'Open opportunity summary'
          "
          [tooltipInvokeType]="groupToolTipoptions.tooltipInvokeType ?? ''"
          [tooltipType]="groupToolTipoptions.tooltipType ?? ''"
          [tooltipSize]="groupToolTipoptions.tooltipSize ?? ''"
          [tooltipPosition]="groupToolTipoptions.tooltipPosition ?? ''"
          [tooltipIndent]="groupToolTipoptions.tooltipIndent ?? 0"
          [tooltipHasBeak]="groupToolTipoptions.tooltipHasBeak ?? false"
          [tooltipTheme]="groupToolTipoptions.tooltipTheme ?? ''"
          [tooltipIsDisabled]="
            currentDashboardTileCode === dashboardTileCode.NCASpecialist ||
            (currentDashboardTileCode === dashboardTileCode.Reviewer &&
              (currentTileNameForGrid === reviewerTileCodes.CCDueNow ||
                currentTileNameForGrid ===
                  reviewerTileCodes.NCAPendingApproval)) ||
            currentDashboardTileCode === dashboardTileCode.NcaSummary
          "
          [tooltipCustomClass]="'tableRowTooltip'"
          ddsFocus
          [tooltipMaxWidth]="groupToolTipoptions.tooltipMaxWidth ?? 0"
        >
          <td
            *ngFor="let column of gridColumns"
            class="dds-data-table__cell"
            (click)="
              (column.name === columnName.Actions ||
                column.name === columnName.Focus ||
                column.name === columnName.OpportunityIcon) &&
                stopEventPropagation($event)
            "
            [ngClass]="{
              'desc-designation-cell':
                column.name === columnName.DescDesignation
            }"
            [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
          >
            <div
              *ngIf="
                currentTileNameForGrid !== reviewerTileCodes.PendingReviews &&
                currentTileNameForGrid !==
                  reviewerTileCodes.PendingReviewsOthers &&
                currentTileNameForGrid !== reviewerTileCodes.RecentReviews &&
                currentTileNameForGrid !==
                  reviewerTileCodes.SelfReviewChanges &&
                currentDashboardTileCode !== dashboardTileCode.EngagementTeam &&
                currentDashboardTileCode !== dashboardTileCode.RiskManager &&
                column.name == columnName.ClientName &&
                currentDashboardTileCode !== dashboardTileCode.NcaSummary
              "
              class="client-container"
            >
              <span
                *ngIf="row.clientIconCode != 'Black'"
                [ngClass]="
                  'padding-left-s client-icon-2 client-icon-code_' +
                  row.clientIconCode
                "
                [ddsTooltip]="row.clientIconHover"
                [tooltipInvokeType]="
                  clientTooltipOptions.tooltipInvokeType ?? ''
                "
                [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
                ddsFocus
                [tooltipMaxWidth]="clientTooltipOptions.tooltipMaxWidth ?? 0"
              ></span>
              <span
                *ngIf="row.clientIconCode == 'Black'"
                [ngClass]="
                  'padding-left-s client-icon-2 client-icon-code_' +
                  row.clientIconCode
                "
              ></span>

              <span class="client-name-2">{{
                row[column.name] | ifEmpty : defaultEmptyFields
              }}</span>
              <div
                class="watchlist-container margin-top-xxs"
                style="display: inline-block"
                *ngIf="
                  row.watchlistStatusId &&
                  (currentDashboardTileCode ==
                    dashboardTileCode.NCASpecialist ||
                    currentDashboardTileCode == dashboardTileCode.Reviewer)
                "
              >
                <span
                  [ngClass]="{
                    'serve-with-conditions-container padding-left-m':
                      row.watchlistStatusId ==
                      enumWatchlistStatus.ServeWithConditions,
                    'do-not-serve-container padding-left-m':
                      row.watchlistStatusId == enumWatchlistStatus.DoNotServe,
                    'eclipse-content-grey':
                      row.watchlistStatusId == enumWatchlistStatus.Removed
                  }"
                >
                  {{ getWatchlistStatus(row) }}
                </span>
                <span
                  (click)="onShowWatchlistDetail($event, row, false)"
                  class="padding-left-m margin-left-xxs margin-top-xxs info-icon-green"
                ></span>
              </div>
              <div
                class="watchlist-container margin-top-xxs"
                style="display: inline-block"
                *ngIf="row.isRevisionRequested"
              >
                <span class="serve-with-conditions-container padding-left-m"
                  >Revision requested</span
                >
              </div>

              <div
                *ngIf="
                  currentTileNameForGrid == ncaTileCodes.OnHold &&
                  row.isNewJoAppearedForOnHoldMilestone
                "
                class="padding-left-m padding-top-xxs"
              >
                <span class="do-not-serve-container">New JO</span>
              </div>
            </div>
            <div
              *ngIf="
                currentDashboardTileCode == dashboardTileCode.NcaSummary &&
                column.name == 'clientName'
              "
              class="client-name-container"
              (click)="gotoClientStatus(row['clientNumber'])"
            >
              <a class="client-link padding-bottom-xxs">
                {{ row[column.name] ? row[column.name] : "-" }}
              </a>
            </div>
            <div
              class="submission-title"
              *ngIf="
                column.name == columnName.ClientName &&
                currentDashboardTileCode === dashboardTileCode.RiskManager
              "
            >
              <h6
                [ddsTooltip]="row.clientName"
                [tooltipInvokeType]="
                  groupToolTipoptions.tooltipInvokeType ?? ''
                "
                [tooltipType]="groupToolTipoptions.tooltipType ?? ''"
                [tooltipSize]="groupToolTipoptions.tooltipSize ?? ''"
                [tooltipPosition]="groupToolTipoptions.tooltipPosition ?? ''"
                [tooltipIndent]="groupToolTipoptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="groupToolTipoptions.tooltipHasBeak ?? false"
                [tooltipIsDisabled]="
                  groupToolTipoptions.tooltipIsDisabled ?? false
                "
                [tooltipIsOutsideClick]="
                  groupToolTipoptions.tooltipIsOutsideClick ?? false
                "
                [tooltipIsDynamic]="
                  groupToolTipoptions.tooltipIsDynamic ?? false
                "
                [tooltipTheme]="groupToolTipoptions.tooltipTheme ?? ''"
                [tooltipShowDelay]="groupToolTipoptions.tooltipShowDelay ?? 0"
                [tooltipHideDelay]="groupToolTipoptions.tooltipHideDelay ?? 0"
                ddsFocus
                [tooltipMaxWidth]="groupToolTipoptions.tooltipMaxWidth ?? 0"
              >
                {{ row[column.name] | ifEmpty : defaultEmptyFields }}
              </h6>
            </div>
            <div
              class="submission-title"
              *ngIf="column.name == columnName.OpportunityName"
            >
              <h6
                [ddsTooltip]="row.opportunityName"
                [tooltipInvokeType]="
                  groupToolTipoptions.tooltipInvokeType ?? ''
                "
                [tooltipType]="groupToolTipoptions.tooltipType ?? ''"
                [tooltipSize]="groupToolTipoptions.tooltipSize ?? ''"
                [tooltipPosition]="groupToolTipoptions.tooltipPosition ?? ''"
                [tooltipIndent]="groupToolTipoptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="groupToolTipoptions.tooltipHasBeak ?? false"
                [tooltipIsDisabled]="
                  groupToolTipoptions.tooltipIsDisabled ?? false
                "
                [tooltipIsOutsideClick]="
                  groupToolTipoptions.tooltipIsOutsideClick ?? false
                "
                [tooltipIsDynamic]="
                  groupToolTipoptions.tooltipIsDynamic ?? false
                "
                [tooltipTheme]="groupToolTipoptions.tooltipTheme ?? ''"
                [tooltipShowDelay]="groupToolTipoptions.tooltipShowDelay ?? 0"
                [tooltipHideDelay]="groupToolTipoptions.tooltipHideDelay ?? 0"
                ddsFocus
                [tooltipMaxWidth]="groupToolTipoptions.tooltipMaxWidth ?? 0"
              >
                {{ row[column.name] | ifEmpty : defaultEmptyFields }}
              </h6>
            </div>
            <div
              *ngIf="column.name == 'parentClientName'"
              class="client-container"
            >
              <span
                *ngIf="
                  row.parentClientName && row.parentClientIconCode != 'Black'
                "
                [ngClass]="
                  'padding-left-s parent-client-icon client-icon-code_' +
                  row.parentClientIconCode
                "
                [ddsTooltip]="row.parentClientIconHover"
                [tooltipInvokeType]="
                  clientTooltipOptions.tooltipInvokeType ?? ''
                "
                [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''"
                ddsFocus
                [tooltipMaxWidth]="clientTooltipOptions.tooltipMaxWidth ?? 0"
              ></span>
              <span
                *ngIf="
                  row.parentClientName && row.parentClientIconCode == 'Black'
                "
                [ngClass]="
                  'padding-left-s parent-client-icon client-icon-code_' +
                  row.parentClientIconCode
                "
              ></span>
              <span class="client-name-2">{{
                row[column.name] | ifEmpty : defaultEmptyFields
              }}</span>
              <div
                class="watchlist-container margin-top-xxs"
                style="display: inline-block"
                *ngIf="
                  row.pcWatchlistStatusId &&
                  (currentDashboardTileCode ==
                    dashboardTileCode.NCASpecialist ||
                    currentDashboardTileCode == dashboardTileCode.Reviewer)
                "
              >
                <span
                  [ngClass]="{
                    'serve-with-conditions-container padding-left-m':
                      row.pcWatchlistStatusId ==
                      enumWatchlistStatus.ServeWithConditions,
                    'do-not-serve-container padding-left-m':
                      row.pcWatchlistStatusId == enumWatchlistStatus.DoNotServe,
                    'eclipse-content-grey':
                      row.pcWatchlistStatusId == enumWatchlistStatus.Removed
                  }"
                >
                  {{ getParentWatchlistStatus(row) }}
                </span>
                <span
                  (click)="onShowWatchlistDetail($event, row, true)"
                  class="padding-left-m margin-left-xxs margin-top-xxs info-icon-green"
                ></span>
              </div>
            </div>
            <div *ngIf="column.name === 'continuanceStatus'">
              <span
                class="cm-status-name"
                [ngClass]="{
                  'accepted-container-2':
                    row.clientMilestoneStatusId ===
                    enumClientMilestoneStatus.Accepted,
                  'serve-with-conditions-container-2':
                    row.clientMilestoneStatusId ===
                    enumClientMilestoneStatus.ServeWithConditions,
                  'do-not-serve-container-2':
                    row.clientMilestoneStatusId ===
                    enumClientMilestoneStatus.DoNotServe
                }"
                [innerHtml]="row.continuanceStatus"
              ></span>
            </div>
            <div *ngIf="column.name === 'clientMilestoneStatus'">
              <span
                class="cm-status-name"
                [ngClass]="{
                  'notstarted-container':
                    row.clientMilestoneStatusId ===
                    enumClientMilestoneStatus.NotStarted,
                  'onhold-container':
                    row.clientMilestoneStatusId ===
                    enumClientMilestoneStatus.OnHold,
                  'pending-approvals-container':
                    row.clientMilestoneStatusId ===
                    enumClientMilestoneStatus.PendingApprovals,
                  'inprocess-container':
                    row.clientMilestoneStatusId ===
                    enumClientMilestoneStatus.InProcess
                }"
                [innerHtml]="row.clientMilestoneStatus"
              ></span>
            </div>
            <div *ngIf="column.name === 'continuanceExpiredDate'">
              <span
                class="noWordWrap"
                [ngClass]="{
                  'text-red': row.continuanceColorCode === 'Red',
                  'text-orange': row.continuanceColorCode === 'Orange'
                }"
                >{{ row.continuanceExpiredDate | ifEmpty : "-" }}</span
              >
            </div>
            <div *ngIf="column.name === columnName.DescDesignation">
              <span>{{ row[column.name] | ifEmpty : "Not in DESC" }}</span>
            </div>
            <div
              *ngIf="
                column.name === columnName.NCASpecialistName &&
                currentDashboardTileCode == dashboardTileCode.NCASpecialist
              "
            >
              <span>{{ row.actionBy.outlookDisplayName }}</span>
            </div>
            <div
              (click)="stopEventPropagation($event)"
              *ngIf="
                column.name === columnName.OpenOpportunities &&
                currentDashboardTileCode == dashboardTileCode.Reviewer
              "
            >
              <span
                *ngIf="row.openOpportunities != 0"
                class="open-opps-hyperlink"
                (click)="showOpenOpps(row, $event)"
                >{{ row[column.name] | ifEmpty : defaultEmptyFields }}</span
              >
              <span *ngIf="row.openOpportunities == 0">{{
                row[column.name] | ifEmpty : defaultEmptyFields
              }}</span>
            </div>
            <div
              *ngIf="
                column.name === columnName.ActionByName &&
                currentDashboardTileCode == dashboardTileCode.NCASpecialist
              "
            >
              <div class="group-container no-ellipsis">
                <p class="group-text-label">Accepted by:</p>
                <span
                  class="icon-s icon-info"
                  [ddsTooltip]="row.notRequiredReason"
                  [tooltipInvokeType]="
                    actionbyToolTipoptions.tooltipInvokeType ?? ''
                  "
                  [tooltipType]="actionbyToolTipoptions.tooltipType ?? ''"
                  [tooltipSize]="actionbyToolTipoptions.tooltipSize ?? ''"
                  [tooltipPosition]="
                    actionbyToolTipoptions.tooltipPosition ?? ''
                  "
                  [tooltipIndent]="actionbyToolTipoptions.tooltipIndent ?? 0"
                  [tooltipHasBeak]="
                    actionbyToolTipoptions.tooltipHasBeak ?? false
                  "
                  [tooltipTheme]="actionbyToolTipoptions.tooltipTheme ?? ''"
                  ddsFocus
                  [tooltipMaxWidth]="
                    actionbyToolTipoptions.tooltipMaxWidth ?? 0
                  "
                >
                </span>
              </div>
              <div>{{ row.actionBy.outlookDisplayName }}</div>
            </div>

            <div
              *ngIf="
                column.name === columnName.RecommendedClientGroupNumber &&
                currentDashboardTileCode == dashboardTileCode.NCASpecialist
              "
              class="group-container"
            >
              <p class="group-text-label">
                Group {{ row.recommendedClientGroupNumber }}
              </p>
              <span
                class="icon-s icon-info"
                [ddsTooltip]="row.recommendedClientGroupName"
                [tooltipInvokeType]="
                  groupToolTipoptions.tooltipInvokeType ?? ''
                "
                [tooltipType]="groupToolTipoptions.tooltipType ?? ''"
                [tooltipSize]="groupToolTipoptions.tooltipSize ?? ''"
                [tooltipPosition]="groupToolTipoptions.tooltipPosition ?? ''"
                [tooltipIndent]="groupToolTipoptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="groupToolTipoptions.tooltipHasBeak ?? false"
                [tooltipTheme]="groupToolTipoptions.tooltipTheme ?? ''"
                ddsFocus
                [tooltipMaxWidth]="groupToolTipoptions.tooltipMaxWidth ?? 0"
                (click)="stopEventPropagation($event)"
              >
              </span>
            </div>

            <div
              class="submission-title"
              *ngIf="
                column.name === columnName.SubmissionTypeCode ||
                column.name === columnName.SubmissionType
              "
            >
              <div class="sub-info d-flex font-size-12 font-bold-600">
                <span class="item-type">{{ row[column.name] }}</span>
              </div>
            </div>

            <div
              class="submission-title font-size-14"
              *ngIf="column.name === columnName.SubmissionTitle"
            >
              <h6
                [ddsTooltip]="row.submissionTitle"
                [tooltipInvokeType]="
                  groupToolTipoptions.tooltipInvokeType ?? ''
                "
                [tooltipType]="groupToolTipoptions.tooltipType ?? ''"
                [tooltipSize]="groupToolTipoptions.tooltipSize ?? ''"
                [tooltipPosition]="groupToolTipoptions.tooltipPosition ?? ''"
                [tooltipIndent]="groupToolTipoptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="groupToolTipoptions.tooltipHasBeak ?? false"
                [tooltipIsDisabled]="
                  groupToolTipoptions.tooltipIsDisabled ?? false
                "
                [tooltipIsOutsideClick]="
                  groupToolTipoptions.tooltipIsOutsideClick ?? false
                "
                [tooltipIsDynamic]="
                  groupToolTipoptions.tooltipIsDynamic ?? false
                "
                [tooltipTheme]="groupToolTipoptions.tooltipTheme ?? ''"
                [tooltipShowDelay]="groupToolTipoptions.tooltipShowDelay ?? 0"
                [tooltipHideDelay]="groupToolTipoptions.tooltipHideDelay ?? 0"
                ddsFocus
                [tooltipMaxWidth]="groupToolTipoptions.tooltipMaxWidth ?? 0"
              >
                {{ row.submissionTitle }}
              </h6>
              <div
                class="sub-info d-flex font-size-12 font-bold-600"
                *ngIf="
                  currentTileNameForGrid !== engagementTileCodes.ExtComm &&
                  currentTileNameForGrid !== engagementTileCodes.GlobalEng
                "
              >
                <!-- <span class="item-type">{{ row.submissionTypeCode }}</span> -->
                <span
                  *ngIf="
                    row.submissionStatus === 'Re-submitted' ||
                    row.submissionStatus === 'Resubmitted'
                  "
                  class="status-danger"
                  >{{ row.submissionStatus }}</span
                >
                <span
                  *ngIf="row.submissionStatusId && row.submissionStatusId === 2"
                  class="status-danger"
                  >{{ "Returned for revisions" }}</span
                >
                <span class="font-size-14 font-bold-400">{{
                  row.submissionId
                }}</span>
              </div>
            </div>
            <div
              *ngIf="
                column.name === columnName.SubmissionStatusName ||
                column.name === columnName.GlobalEngagementStatus
              "
            >
              <span
                class="padding-status"
                [style.color]="
                  transformStatus(row[column.name]) | statusTranform : 'color'
                "
                [style.backgroundColor]="
                  transformStatus(row[column.name])
                    | statusTranform : 'backgroundColor'
                "
                >{{ row[column.name] | ifEmpty : defaultEmptyFields }}</span
              >
            </div>
            <span *ngIf="column.name === columnName.NetServiceRevenue">
              {{
                parseNetServiceRevenueToNumber(row)
                  | currency : "USD" : "symbol" : "1.0-2"
              }}
            </span>
            <span *ngIf="column.name === columnName.SubmissionId">
              <a
                class="hyperlink-green"
                (click)="$event.stopPropagation(); openSubmission(row)"
              >
                {{ row[column.name] }}
              </a>
            </span>
            <span
              *ngIf="
                (column.name !== columnName.NetServiceRevenue &&
                  column.name !== columnName.Actions &&
                  column.name != columnName.ClientName &&
                  column.name != columnName.ParentClientName &&
                  column.name != columnName.RecommendedClientGroupNumber &&
                  column.name != columnName.DescDesignation &&
                  column.name != columnName.ActionByName &&
                  column.name != columnName.NCASpecialistName &&
                  column.name != columnName.OpenOpportunities &&
                  column.name != columnName.Focus &&
                  column.name != columnName.OpportunityIcon &&
                  column.name != columnName.SubmissionTitle &&
                  column.name != columnName.SubmissionTypeCode &&
                  column.name != columnName.SubmissionType &&
                  column.name != columnName.SubmissionStatusName &&
                  column.name != columnName.GlobalEngagementStatus &&
                  column.name != columnName.PendingOptionalReviewers &&
                  column.name != columnName.OpportunityName &&
                  column.name != columnName.ContinuanceStatus &&
                  column.name != 'clientMilestoneStatus' &&
                  column.name != columnName.ContinuanceExpiredDate &&
                  column.name != columnName.PendingRequiredReviewers &&
                  column.name != columnName.SubmissionId) ||
                ((currentTileNameForGrid === reviewerTileCodes.PendingReviews ||
                  currentTileNameForGrid ===
                    reviewerTileCodes.PendingReviewsOthers ||
                  currentTileNameForGrid === reviewerTileCodes.RecentReviews ||
                  currentTileNameForGrid ===
                    reviewerTileCodes.SelfReviewChanges ||
                  currentDashboardTileCode ===
                    dashboardTileCode.EngagementTeam) &&
                  column.name === columnName.ClientName)
              "
              >{{ row[column.name] | ifEmpty : defaultEmptyFields }}</span
            >

            <span
              *ngIf="
                column.name === columnName.PendingOptionalReviewers ||
                column.name === columnName.PendingRequiredReviewers
              "
            >
              {{
                splitArrayValues(row[column.name], "; ")
                  | ifEmpty : defaultEmptyFields
              }}
            </span>

            <span
              *ngIf="
                column.name === columnName.Actions &&
                (currentDashboardTileCode == dashboardTileCode.NCASpecialist ||
                  currentDashboardTileCode == dashboardTileCode.Reviewer ||
                  currentDashboardTileCode == dashboardTileCode.EngagementTeam)
              "
            >
              <div id="grid-action-buttons-container" class="flex-container">
                <div
                  id="grid-action-button"
                  *ngIf="showActionButton(buttonOptionsList, rowIndex)"
                >
                  <ng-container *ngFor="let button of buttonOptionsList">
                    <div
                      *ngIf="
                        showActionButtons(
                          button.condition,
                          button.action,
                          rowIndex
                        )
                      "
                      class="d-inline-block margin-right-xxs"
                      [ddsTooltip]="button?.tooltip"
                      [tooltipInvokeType]="
                        groupToolTipoptions.tooltipInvokeType ?? ''
                      "
                      [tooltipType]="groupToolTipoptions.tooltipType ?? ''"
                      [tooltipSize]="groupToolTipoptions.tooltipSize ?? ''"
                      [tooltipPosition]="
                        groupToolTipoptions.tooltipPosition ?? ''
                      "
                      [tooltipIndent]="groupToolTipoptions.tooltipIndent ?? 0"
                      [tooltipHasBeak]="
                        groupToolTipoptions.tooltipHasBeak ?? false
                      "
                      [tooltipTheme]="groupToolTipoptions.tooltipTheme ?? ''"
                      [tooltipIsDisabled]="!button?.tooltip"
                      ddsFocus
                      [tooltipMaxWidth]="
                        groupToolTipoptions.tooltipMaxWidth ?? 0
                      "
                    >
                      <dds-button
                        [theme]="buttonOptions.theme ?? ''"
                        [kind]="button.buttonType ?? buttonOptions.kind ?? ''"
                        [size]="buttonOptions.size ?? ''"
                        [width]="buttonOptions.width ?? ''"
                        (mouseenter)="rowTooltipDisabled(true)"
                        (mouseleave)="rowTooltipDisabled(false)"
                        (clicked)="buttonSelected(button.action, row)"
                      >
                        <span>
                          {{ button.label }}
                        </span>
                      </dds-button>
                    </div>
                  </ng-container>
                </div>
                <div
                  id="grid-action-button-dropdown"
                  class="action-button-dropdown"
                  *ngIf="
                    showActionButtonDeopdown() &&
                    showellipseForCCDueNowAndMars(rowIndex)
                  "
                >
                  <dds-dropdown
                    [theme]="dropdownOptions.theme ?? ''"
                    [kind]="dropdownOptions.kind ?? ''"
                    [size]="dropdownOptions.size ?? ''"
                    [label]="dropdownOptions.label ?? ''"
                    [width]="dropdownOptions.width ?? ''"
                    [icon]="dropdownOptions.icon ?? ''"
                    [disabled]="dropdownOptions.disabled ?? false"
                    [stickerWidth]="dropdownOptions.stickerWidth ?? 0"
                    [stickerPosition]="dropdownStickerPosition"
                    (itemSelected)="buttonSelected($event, row)"
                  >
                    <ng-container *ngFor="let item of dropdownItems">
                      <dds-dropdown-item
                        *ngIf="isVisibleDropdownItem(item, row)"
                        [value]="item.value"
                        [counter]="item.counter ?? 0"
                        [disabled]="item.disabled ?? false"
                        >{{ item.heading }}</dds-dropdown-item
                      >
                    </ng-container>
                  </dds-dropdown>
                </div>
                <div
                  *ngIf="isMarsDraftRecord(rowIndex)"
                  class="mars-draft-span"
                >
                  <span>Draft</span>
                </div>
              </div>
            </span>
            <span *ngIf="column.name == columnName.Focus">
              <img
                src="\assets\dds-icons\openBlackIcon.svg"
                (mouseenter)="rowTooltipDisabled(true)"
                (mouseleave)="rowTooltipDisabled(false)"
                (click)="openFocusLink(row?.focusUrl)"
            /></span>
            <span *ngIf="column.name == columnName.OpportunityIcon">
              <img
                src="\assets\images\dds-icons\dds-icon-link-icon.svg"
                (mouseenter)="rowTooltipDisabled(true)"
                (mouseleave)="rowTooltipDisabled(false)"
                (click)="
                  navigateToSummary(
                    row?.jupiterId,
                    row?.submissionTitle || row?.opportunityName
                  )
                "
                [ddsTooltip]="'Opportunity Summary'"
                [tooltipInvokeType]="
                  groupToolTipoptions.tooltipInvokeType ?? ''
                "
                [tooltipType]="groupToolTipoptions.tooltipType ?? ''"
                [tooltipSize]="groupToolTipoptions.tooltipSize ?? ''"
                [tooltipPosition]="groupToolTipoptions.tooltipPosition ?? ''"
                [tooltipIndent]="groupToolTipoptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="groupToolTipoptions.tooltipHasBeak ?? false"
                [tooltipTheme]="groupToolTipoptions.tooltipTheme ?? ''"
                [tooltipCustomClass]="'tableRowTooltip'"
                ddsFocus
                [tooltipMaxWidth]="groupToolTipoptions.tooltipMaxWidth ?? 0"
            /></span>
            <span
              *ngIf="
                column.name === 'actions' &&
                currentDashboardTileCode === 'Reviewer' &&
                currentTileNameForGrid === 'CCDueNow'
              "
            >
              <div id="grid-action-buttons-container" class="flex-container">
                <div id="grid-action-button">
                  <dds-button
                    *ngIf="
                      currentTileNameForGrid === 'CCDueNow' &&
                      row.clientMilestoneStatusId ===
                        enumClientMilestoneStatus.CcDueDate
                    "
                    [theme]="buttonOptions.theme ?? ''"
                    [kind]="buttonOptions.kind ?? ''"
                    [size]="buttonOptions.size ?? ''"
                    [width]="buttonOptions.width ?? ''"
                    (clicked)="gotoClientStatus(row.clientNumber)"
                    >Complete continuance
                  </dds-button>
                </div>
              </div>
            </span>
            <span
              *ngIf="
                column.name === 'actions' &&
                currentDashboardTileCode === 'Reviewer' &&
                currentTileNameForGrid === 'NCAPendingApproval'
              "
            >
              <div
                id="reviewer-grid-action-buttons-container"
                class="flex-container"
              >
                <div id="reviewer-grid-action-button">
                  <dds-button
                    [theme]="buttonOptions.theme ?? ''"
                    [kind]="buttonOptions.kind ?? ''"
                    [size]="buttonOptions.size ?? ''"
                    [width]="buttonOptions.width ?? ''"
                    (clicked)="gotoClientStatus(row.clientNumber)"
                    >Start review
                  </dds-button>
                </div>
              </div>
            </span>
            <span
              class="client-block-status"
              *ngIf="
                column.name == columnName.ClientName &&
                  currentDashboardTileCode ===
                    dashboardTileCode.NCASpecialist &&
                  row.isMarkedForDeletion;
                else swiftClientStatus
              "
            >
              [{{
                row.swiftClientStatus === "Blocked" ||
                row.swiftClientStatus === "Duplicate"
                  ? row.swiftClientStatus + " - " + markForDeletionText
                  : markForDeletionText
              }}]
            </span>
            <ng-template #swiftClientStatus>
              <span
                class="client-block-status"
                *ngIf="
                  column.name == columnName.ClientName &&
                  currentDashboardTileCode ===
                    dashboardTileCode.NCASpecialist &&
                  (row.swiftClientStatus == 'Blocked' ||
                    row.swiftClientStatus == 'Duplicate')
                "
                >[{{ row.swiftClientStatus }}]</span
              >
            </ng-template>
          </td>
          <td
            *ngIf="haveRowExpand()"
            class="dds-data-table__cell"
            (click)="stopEventPropagation($event)"
          >
            <span
              class="expand-collapse-icon"
              [ngStyle]="getAccordianStyles()"
              (click)="itemExpand(row, $event)"
            >
              <div
                [ngClass]="
                  row.expandStatus ? 'icon-collapse-black' : 'icon-expand-black'
                "
                class="icon-width"
              ></div>
            </span>
          </td>
        </tr>
        <tr *ngIf="row.expandStatus" class="second-block">
          <ng-container>
            <td [attr.colspan]="gridColumns.length + 1">
              <div class="opp-grid">
                <ng-container
                  *ngIf="
                    currentDashboardTileCode != dashboardTileCode.NcaSummary
                  "
                >
                  <p class="text-label">Client info</p>
                  <div>
                    <div class="item-info-container">
                      <div
                        class="item-info-column"
                        *ngIf="
                          (currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                            currentTileNameForGrid !=
                              reviewerTileCodes.CCDueNow &&
                            this.currentTileNameForGrid !=
                              this.ncaTileCodes.CompleteMarsNcaForm) ||
                          (currentDashboardTileCode ==
                            dashboardTileCode.Reviewer &&
                            currentTileNameForGrid ==
                              reviewerTileCodes.NCAPendingApproval)
                        "
                      >
                        <span class="item-info-label">Industry:</span>
                        <span class="item-info-value">{{
                          row.industry | ifEmpty : defaultEmptyFields
                        }}</span>
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          (currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                            currentTileNameForGrid !=
                              reviewerTileCodes.CCDueNow &&
                            this.currentTileNameForGrid !=
                              this.ncaTileCodes.CompleteMarsNcaForm) ||
                          (currentDashboardTileCode ==
                            dashboardTileCode.Reviewer &&
                            currentTileNameForGrid ==
                              reviewerTileCodes.NCAPendingApproval)
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-industrysector-label"
                          >Industry sector:</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-industrysector-value"
                          >{{
                            row.industrySector | ifEmpty : defaultEmptyFields
                          }}</span
                        >
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                          currentTileNameForGrid !=
                            reviewerTileCodes.CCDueNow &&
                          this.currentTileNameForGrid !=
                            this.ncaTileCodes.CompleteMarsNcaForm &&
                          (row.recommendedClientGroupNumber === '4' ||
                            row.isActiveMsaExists ||
                            true)
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-isactivemsaexists-label"
                          >Active MSA(s):</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-isactivemsaexists-value"
                          >{{ row.isActiveMsaExists ? "Yes" : "No" }}</span
                        >
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          currentDashboardTileCode ==
                            dashboardTileCode.Reviewer &&
                          currentTileNameForGrid == reviewerTileCodes.CCDueNow
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-lastoppwithclosedwon-label"
                          >Last opportunity with closed won:</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-lastoppwithclosedwon-value"
                          >{{
                            row.lastOpportunityWithClosedWonDate
                              | ifEmpty : defaultEmptyFields
                          }}</span
                        >
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                          currentTileNameForGrid == reviewerTileCodes.CCDueNow
                        "
                      >
                        <div class="d-flex">
                          <span
                            class="item-info-label"
                            id="startpage-gridarea-netServiceRevenue-label"
                            >Net service revenue:</span
                          >
                        </div>
                        <div class="d-flex">
                          <span
                            class="item-info-value"
                            id="startpage-gridarea-netServiceRevenue-value"
                            >{{
                              parseNetServiceRevenueToNumber(row)
                                | currency : "USD" : "symbol" : "1.0-2"
                            }}</span
                          >
                          <span
                            class="icon-s icon-info icon-margin"
                            *ngIf="showNetServiceRevenueTooltip(row)"
                            [ddsTooltip]="netServicesRevenueTooltipInfo"
                            [tooltipInvokeType]="
                              groupToolTipoptions.tooltipInvokeType ?? ''
                            "
                            [tooltipType]="
                              groupToolTipoptions.tooltipType ?? ''
                            "
                            [tooltipSize]="
                              groupToolTipoptions.tooltipSize ?? ''
                            "
                            [tooltipPosition]="
                              groupToolTipoptions.tooltipPosition ?? ''
                            "
                            [tooltipIndent]="
                              groupToolTipoptions.tooltipIndent ?? 0
                            "
                            [tooltipHasBeak]="
                              groupToolTipoptions.tooltipHasBeak ?? false
                            "
                            [tooltipTheme]="
                              groupToolTipoptions.tooltipTheme ?? ''
                            "
                            ddsFocus
                            [tooltipMaxWidth]="
                              groupToolTipoptions.tooltipMaxWidth ?? 0
                            "
                            (click)="stopEventPropagation($event)"
                          >
                          </span>
                        </div>
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          (currentDashboardTileCode ==
                            dashboardTileCode.Reviewer &&
                            currentTileNameForGrid ==
                              reviewerTileCodes.CCDueNow) ||
                          (currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                            currentTileNameForGrid ==
                              reviewerTileCodes.CCDueNow)
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-lastinvoicedate-label"
                          >Last invoice date:</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-lastinvoicedate-value"
                          >{{
                            row.lastInvoiceDate | ifEmpty : defaultEmptyFields
                          }}</span
                        >
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          (currentDashboardTileCode ==
                            dashboardTileCode.Reviewer &&
                            currentTileNameForGrid ==
                              reviewerTileCodes.CCDueNow) ||
                          (currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                            currentTileNameForGrid ==
                              reviewerTileCodes.CCDueNow)
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-advclientportfolio-label"
                          >Program account:</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-advclientportfolio-value"
                          >{{
                            row.programAccount | ifEmpty : defaultEmptyFields
                          }}</span
                        >
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          (currentDashboardTileCode ==
                            dashboardTileCode.Reviewer &&
                            currentTileNameForGrid ==
                              reviewerTileCodes.CCDueNow) ||
                          (currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                            currentTileNameForGrid ==
                              reviewerTileCodes.CCDueNow &&
                            !this.isMarsNCASpecialist)
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-advclientportfolio-label"
                          >Global crown jewel:</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-advclientportfolio-value"
                          >{{ row.isGlobalCrownJewel ? "Yes" : "No" }}</span
                        >
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                          currentTileNameForGrid ==
                            reviewerTileCodes.CCDueNow &&
                          this.isMarsNCASpecialist
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-advclientportfolio-label"
                          >Global program:</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-advclientportfolio-value"
                          >{{
                            row.grobalProgram | ifEmpty : defaultEmptyFields
                          }}</span
                        >
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                          this.currentTileNameForGrid ===
                            this.ncaTileCodes.CompleteMarsNcaForm
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-advclientportfolio-label"
                          >Opportunity id:</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-advclientportfolio-value"
                          >{{
                            bindMarsClientInfo(row, "opportunityId")
                              | ifEmpty : defaultEmptyFields
                          }}</span
                        >
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                          this.currentTileNameForGrid ===
                            this.ncaTileCodes.CompleteMarsNcaForm
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-advclientportfolio-label"
                          >Opportunity name:</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-advclientportfolio-value"
                          >{{
                            bindMarsClientInfo(row, "opportunityName")
                              | ifEmpty : defaultEmptyFields
                          }}</span
                        >
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                          this.currentTileNameForGrid ===
                            this.ncaTileCodes.CompleteMarsNcaForm
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-advclientportfolio-label"
                          >Opportunity expected closed date:</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-advclientportfolio-value"
                          >{{
                            row.opportunityExpectedCloseDate
                              | ifEmpty : defaultEmptyFields
                          }}</span
                        >
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          (currentDashboardTileCode ==
                            dashboardTileCode.Reviewer &&
                            currentTileNameForGrid ==
                              reviewerTileCodes.NCAPendingApproval) ||
                          (currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                            currentTileNameForGrid !=
                              reviewerTileCodes.CCDueNow)
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-advclientportfolio-label"
                          >Date became a client for A&C:</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-advclientportfolio-value"
                          >{{
                            row.registeredForAdvisoryAndConsultingDate
                              | ifEmpty : defaultEmptyFields
                          }}</span
                        >
                      </div>
                      <div
                        class="item-info-column"
                        *ngIf="
                          (currentDashboardTileCode ==
                            dashboardTileCode.Reviewer &&
                            currentTileNameForGrid ==
                              reviewerTileCodes.NCAPendingApproval) ||
                          (currentDashboardTileCode ==
                            dashboardTileCode.NCASpecialist &&
                            currentTileNameForGrid !=
                              reviewerTileCodes.CCDueNow)
                        "
                      >
                        <span
                          class="item-info-label"
                          id="startpage-gridarea-advclientportfolio-label"
                          >Date became a client for Deloitte:</span
                        >
                        <span
                          class="item-info-value"
                          id="startpage-gridarea-advclientportfolio-value"
                          >{{
                            row.registeredForDeloitteDate
                              | ifEmpty : defaultEmptyFields
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    currentDashboardTileCode ==
                      dashboardTileCode.NCASpecialist ||
                    (currentDashboardTileCode == dashboardTileCode.Reviewer &&
                      currentTileNameForGrid ==
                        reviewerTileCodes.NCAPendingApproval) ||
                    currentDashboardTileCode == dashboardTileCode.NcaSummary
                  "
                >
                  <span class="text-label">Related opportunities</span>
                  <div class="inner-container">
                    <nca-specialist-page-grid-area-item
                      [list]="row.opportunityList"
                      [currentTileNameForGrid]="currentTileNameForGrid"
                    ></nca-specialist-page-grid-area-item>
                  </div>
                </ng-container>
              </div>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="image-section" *ngIf="!model || model?.length === 0">
    <div class="empty-state-container">
      <img
        src="assets/images/no_watchlist_clients.svg"
        alt="empty-state"
        class="empty-state-image"
      />
      <div class="empty-state-text">There are no records to display</div>
    </div>
  </div>
</div>

<ng-template #customFilter>
  <dds-filter
    [items]="filterData"
    [theme]="filterOptions.theme ?? ''"
    (sorted)="filterSorted(isAscending)"
    (apply)="filterApply(filteringColumn.searchValue)"
    (cancel)="filterHide()"
    [template]="customFilterTemp"
    #ddsFilter
  ></dds-filter>
</ng-template>

<ng-template #customFilterTemp let-ctx="ctx">
  <span class="dds-filter__title dds-filter__section">Sorting</span>
  <div class="dds-filter__container dds-filter__container_sorting">
    <button
      class="dds-filter__item dds-filter__section dds-filter__section_sorting"
      (click)="filterSorted(true)"
    >
      <span class="dds-filter__icon dds-icon dds-icon_sort-up"></span>
      <span class="dds-filter__text">Sort A–Z</span>
    </button>
    <button
      class="dds-filter__item dds-filter__section dds-filter__section_sorting"
      (click)="filterSorted(false)"
    >
      <span class="dds-filter__icon dds-icon dds-icon_sort-down"></span>
      <span class="dds-filter__text">Sort Z–A</span>
    </button>
  </div>
  <ng-container
    *ngIf="
      filteringColumn.name !== 'openOpportunities' &&
      filteringColumn.name !== 'priorFyClosedWBS' &&
      filteringColumn.name !== 'daysPendingForCCCompletion'
    "
  >
    <span class="dds-filter__title dds-filter__section">Filter</span>
    <dds-search
      *ngIf="
        filteringColumn.dataType !== 'date' &&
        filteringColumn.dataType !== 'number' &&
        filteringColumn.name !== 'continuanceStatus' &&
        filteringColumn.name !== 'clientMilestoneStatus' &&
        filteringColumn.name !== 'clientMilestoneSubStatus' &&
        filteringColumn.name !== 'ncaType' &&
        filteringColumn.name !== 'ncaSpecialistPreferredName' &&
        filteringColumn.name !== 'submissionTypeCode'
      "
      class="dds-filter__search dds-filter__section"
      [isInverse]="ctx.isInverse"
      (keydown)="ctx.tabOut(searchField, $event)"
      [(ngModel)]="filteringColumn.searchValue"
      #searchField
    ></dds-search>

    <dds-daterangepicker
      class="dds-filter__section"
      *ngIf="filteringColumn.dataType === 'date'"
      [size]="dateRangePickerOptions.size ?? ''"
      [placeholder]="dateRangePickerOptions.placeholder ?? []"
      [format]="dateRangePickerOptions.format ?? ''"
      [isManualInput]="dateRangePickerOptions.isManualInput ?? false"
      [stickerPosition]="dateRangePickerOptions.stickerPosition ?? ''"
      [(ngModel)]="filteringColumn.searchValue"
    ></dds-daterangepicker>

    <div
      *ngIf="filteringColumn.dataType === 'number'"
      class="dds-filter__section"
    >
      <input
        type="number"
        class="dds-filter__input"
        [(ngModel)]="fromValue"
        placeholder="From"
      />
      <span class="dds-filter__separator">-</span>
      <input
        type="number"
        class="dds-filter__input"
        [(ngModel)]="toValue"
        placeholder="To"
      />
    </div>

    <div
      class=""
      *ngIf="
        filteringColumn.name === 'continuanceStatus' ||
        filteringColumn.name === 'clientMilestoneStatus' ||
        filteringColumn.name === 'clientMilestoneSubStatus' ||
        filteringColumn.name === 'ncaType' ||
        filteringColumn.name === 'ncaSpecialistPreferredName' ||
        filteringColumn.name === 'submissionTypeCode'
      "
    >
      <div class="dds-filter__container dds-filter__container_checkbox" #wrap>
        <dds-checkbox
          class="dds-filter__section dds-filter__section_checkbox"
          [label]="ctx.allBtnLabel"
          [isIndeterminate]="!isAllFilterSelected"
          [value]="isAllFilterSelected"
          [theme]="ctx.theme"
          (keydown)="ctx.tabOut(checkbox, $event)"
          (stateChanged)="selectAllFilter($event)"
          #checkbox
        ></dds-checkbox>
        <dds-checkbox
          *ngFor="
            let filterItem of getFilterItemList();
            let index = index;
            trackBy: ctx.trackByFn
          "
          class="dds-filter__section dds-filter__section_checkbox"
          [ngClass]="{ 'dds-filter__section_hidden': filterItem.hidden }"
          [value]="filterItem['isChecked']"
          [label]="filterItem[ctx.propName]"
          [theme]="ctx.theme"
          (keydown)="ctx.tabOut(checkbox, $event)"
          (stateChanged)="selectFilterItem($event, filterItem)"
          #checkbox
        ></dds-checkbox>
      </div>
    </div>

    <div class="dds-filter__footer dds-filter__section">
      <dds-button
        width="full"
        [kind]="ctx.cancelBtnOptions.kind"
        [theme]="ctx.theme"
        [isInverse]="ctx.isInverse"
        class="dds-filter__btn dds-filter__item"
        (clicked)="filterHide()"
        #cancelBtn
        >Cancel</dds-button
      >
      <dds-button
        width="full"
        [theme]="ctx.theme"
        [isInverse]="ctx.isInverse"
        class="dds-filter__btn dds-filter__item"
        (clicked)="filterApply(filteringColumn.searchValue)"
        (keydown)="ctx.tabOut(lastBtn, $event)"
        [disabled]="
          !filteringColumn.filterable &&
          !filteringColumn.searchValue &&
          (filteringColumn.dataType !== 'number' || !fromValue || !toValue)
        "
        #lastBtn
        >Apply</dds-button
      >
    </div>
  </ng-container>
</ng-template>
<ng-template #noFilter></ng-template>
