import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EclipseHeaderComponent } from './eclipse-header.component';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { ExportPdfPopupComponent } from './export-pdf-popup/export-pdf-popup.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { FormsModule } from '@angular/forms';
import { EclipseCommonModule } from "../common/common.module";
import { MarsAccessDialogComponent } from './mars-access-dialog/mars-access-dialog.component';

@NgModule({
  declarations: [EclipseHeaderComponent, ExportPdfPopupComponent, PageHeaderComponent, SearchBoxComponent, MarsAccessDialogComponent],
  imports: [CommonModule, LibModule, FormsModule, EclipseCommonModule],
  exports: [EclipseHeaderComponent, PageHeaderComponent],
})
export class EclipseHeaderModule { }
