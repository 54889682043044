import { InitiateReviewComponent } from "./initiate-review/initiate-review.component";

export const INITIATION_ROUTES = [
    {
        path: '',
        children: [
            {
                path: '',
                component: InitiateReviewComponent
            }
        ]
    }
]
