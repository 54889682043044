import { AccordionOptions } from "@usitsdasdesign/dds-ng/accordion";
import { Size, Themes } from "@usitsdasdesign/dds-ng/shared";
import { ToggleOptions } from "@usitsdasdesign/dds-ng/toggle";
import { TooltipOptions } from "@usitsdasdesign/dds-ng/tooltip";

export class ClientDetailsConstant {

  public static readonly descTooltipHoverText: string = "Service restrictions apply with respect to the services that can be provided to this entity without impairing independence.If an entity is restricted, additional requirements might exist with respect to fee arrangements, pre - approvals, communications with Those Charged with Governance / Audit Committee and identifying, evaluating and addressing threats to independence.";
  public static readonly leadRMTooltipHoverText: string = "Primary point of contact for Account LCSP and MSA related issues; strategic advisor to account leadership on large scale strategic initiatives, pricing strategies, risk mitigation, global operational challenges, etc.";
  public static readonly supportRMTooltipHoverText: string = "Primary point of contact for that business’ LBP; supports account leadership with a focus on pipeline management and proactive consultation for their business.";

  public static readonly accordionOptions: AccordionOptions = {
    size: Size.md,
    disabled: false,
    isOpen: true,
    isMulti: true,
    isInverse: false,
    customClass: '',
  };

  public static readonly tooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top-right',
    tooltipIndent: 1,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
    tooltipMaxWidth: 300
  };

  public static readonly toggleOptions: ToggleOptions = {
    theme: Themes.green,
    label: 'Show source of attributes'
  };


}
