import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LepValue,
  OpportunityDetailsNavModel,
} from '../../common/models/opportunity-details.model';
import { SubmissionService } from '../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { AccordionOptions } from '@usitsdasdesign/dds-ng/accordion';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import {
  lepDataValue,
  tooltipOptionsData,
} from './opportunity-details-settings';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { OpportunityDetailsEdit } from '../../common/models/opportunityDetailsEdit';
import { IntakeDocumentSubmissionTypeCode } from '../constants/intake_submission_type_code.enum';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { EmitUpdatedTitleModal } from '../../common/models/intake-submission-details-common-section.model';
import { commonSectionForSingleCoCheck } from '../common/intake.util';
@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
  styleUrl: './opportunity-details.component.less',
})
export class OpportunityDetailsComponent implements OnInit, OnDestroy {
  constructor(
    private readonly intakeDetailService: SubmissionService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly progressIndicator: ProgressIndicatorService
  ) {}
  updateTitleDetails!: EmitUpdatedTitleModal;
  activeIndex: number = -1;
  opportunityId: string = '';
  opportunityDetailTitle: string | null = '';
  opportunityID: string | null = '';
  requiredCheckForIdList: number[] = [];
  dataSubmissionTypeCode: string = '';
  intakeDocumentSubmissionTypeCodeELSOW =
    IntakeDocumentSubmissionTypeCode.EL_SOW;
  lepValue: LepValue = lepDataValue;

  oportunityDetailsInfo: OpportunityDetailsEdit = {
    opportunityNumber: '',
    projectStartDate: '',
    projectEndDate: '',
    leadEngagementPartner: '',
    totalFees: null,
    feeType: [],
    clientNumber: '',
    opportunityDescription: '',
  };
  unsubscriber$ = new Subject();
  submissitionDetails: OpportunityDetailsNavModel[] = [];
  accordionOptions: AccordionOptions = {
    size: Size.lg,
    disabled: false,
    isOpen: true,
    isMulti: false,
    isInverse: false,
    customClass: '',
  };
  opportunityComplexQuestionStatus: boolean = true;
  opportunityDetailStatus: boolean = true;
  opportunityComplexCyberQuestionStatus: boolean = true;
  submissionTypeSelected: string = '';
  currentSubmissionId: string = '';

  selectedSubmissionId: number | null = null;
  options: TooltipOptions = tooltipOptionsData;

  ngOnInit(): void {
    this.progressIndicator.show();
    const id = this.route.snapshot.paramMap.get('id');
    this.opportunityID = id;
    const statedata = history.state;
    if (statedata) {
      this.opportunityDetailTitle = statedata.name;
      this.submissionTypeSelected = statedata.submissionTypeCode;
      this.currentSubmissionId = statedata.submissionID;
    }
    if (id) {
      this.opportunityId = id;
      this.getNavDetails(id);
    }

    this.intakeDetailService.complexQuestionStatus$.subscribe((status) => {
      this.opportunityComplexQuestionStatus = !status;
    });
    this.intakeDetailService.detailStatus$.subscribe((status) => {
      this.opportunityDetailStatus = !status;
    });
    this.intakeDetailService.complexQuestionCyberStatus$.subscribe((status) => {
      this.opportunityComplexCyberQuestionStatus = !status;
    });
    this.progressIndicator.hide();
  }

  setActive(index: number): void {
    this.activeIndex = index;
    this.activeIndex !== -1
      ? (this.selectedSubmissionId =
          this.submissitionDetails[index].submissionId)
      : (this.selectedSubmissionId = null);
    this.dataSubmissionTypeCode =
      this.submissitionDetails[index].submissionTypeCode;
  }

  getNavDetails(id: string): void {
    this.intakeDetailService
      .getIntakeDetails(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.submissitionDetails = data;
          this.opportunityDetailTitle = data[0].submissionTitle.split(' - ')[0];
          if (
            this.submissionTypeSelected === IntakeDocumentSubmissionTypeCode.CO
          ) {
            const activeIndex = this.submissitionDetails.findIndex(
              (item) =>
                item.submissionId.toString() ===
                this.currentSubmissionId.toString()
            );
            this.setActive(activeIndex);
            this.scrollToActiveIndex();
          }
        },
      });
  }

  scrollToActiveIndex(): void {
    const element = document.getElementById(
      this.submissitionDetails[this.activeIndex].submissionId.toString()
    );
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  checkRequired(requiredCheck: boolean): void {
    if (requiredCheck) {
      if (
        !this.requiredCheckForIdList.includes(
          this.submissitionDetails[this.activeIndex]?.submissionId
        )
      ) {
        this.requiredCheckForIdList.push(
          this.submissitionDetails[this.activeIndex]?.submissionId
        );
      }
    } else
      this.requiredCheckForIdList.splice(
        this.requiredCheckForIdList.indexOf(
          this.submissitionDetails[this.activeIndex]?.submissionId
        ),
        1
      );
  }

  navigateToSummary(): void {
    this.router.navigate(['opportunity-summmary'], {
      queryParams: {
        id: this.opportunityId,
        title: this.opportunityDetailTitle,
      },
    });
  }

  updateDocumentTitle(event: EmitUpdatedTitleModal): void {
    this.updateTitleDetails = event;
    if (event?.submissionId != null) {
      let findIndex = this.submissitionDetails.findIndex(
        (submissionDetail) =>
          submissionDetail.submissionId === event.submissionId
      );
      this.submissitionDetails[findIndex].submissionTitle = event.updatedTitle;
    }
  }

  showDocFiledRequiredIndicator(item: OpportunityDetailsNavModel): boolean {
    if (
      !this.requiredCheckForIdList.includes(item.submissionId) &&
      item?.submissionTypeCode !== this.intakeDocumentSubmissionTypeCodeELSOW
    ) {
      if (
        item.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
        this.submissitionDetails.length === 1
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  isOverAllRequired(): boolean {
    if (this.submissitionDetails.length === 0) {
      return false;
    }
    if (commonSectionForSingleCoCheck(this.submissitionDetails)) {
      return false;
    } else {
      return (
        this.opportunityComplexQuestionStatus ||
        this.opportunityDetailStatus ||
        this.opportunityComplexCyberQuestionStatus
      );
    }
  }

  backToHome(): void {
    this.router.navigate(['/']);
  }

  onLepValueChange(data: LepValue): void {
    this.lepValue = data;
  }
  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
