import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ButtonKind, ExtThemes, Size, WidthState } from '@usitsdasdesign/dds-ng/shared';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.less']
})
export class ErrorPageComponent {
  
  errorMessage: string = 'You are currently not associated with this client/opportunity. Reach out to the Pursuit Team to be added to the opportunity';

  returnToHomePageButtonoptions: ButtonOptions = {
      theme: ExtThemes.green,
      kind: ButtonKind.primary,
      size: Size.md,
      width: WidthState.fixed,
      disabled: false,
    }

  constructor(private router: Router) { }
  
  public returnToHomePage(){
    this.router.navigate(['/']);
  }

}
