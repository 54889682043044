import { RiskmanagerDashboardWebapiService } from './../../http/dashboard/riskmanager-dashboard-webapi.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RecentlyViewedComponent } from './../recently-viewed/recently-viewed.component';
import { ReviewerDashboardWebapiService } from '../../http/dashboard/reviewer-webapi.service';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { environment } from '../../../environment/environment';
import {
  DashboardDataCollection,
  DashboardGridLevelFilter,
  DashboardTileCode,
  GridColumn,
} from '../../common/models/start-page/start-page.model';
import {
  buttonOptions,
  RiskManagerInprocessColumns,
  RiskManagercompletedColumns,
  RiskManagerdiscontinuedColumns,
  GridColumns,
  RiskManagerexternalCommColumns,
} from '../dashboard.helper';

import { Router } from '@angular/router';
import {
  defaultGridColumns,
  riskManagerSortParamaterCode,
} from '../dashboard.helper';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Subject, takeUntil } from 'rxjs';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import { EngagementDashboardWebapiService } from '../../http/dashboard/engagement-dashboard-webapi.service';
import { ButtonKind } from '@usitsdasdesign/dds-ng/shared';
import { OptOutPopupComponent } from '../../intake/intake-common-popups/opt-out-popup/opt-out-popup.component';
import { IntakeOptOutPopUpDataModel } from '../../common/models/initiateSubmission.model';
import {
  optedOutSelfReviewToastMessage,
  resubmittedToastMessage,
  submittedMessage,
} from '../../intake/opportunity-details/submission-common-section/submission-common-section.helper';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { SubmissionService } from '../../http/intake/submission.service';
import { CommonService } from '../../http/intake/common.service';
import { OppertunitySubmissionStatusCode } from '../../common/models/oppertunity-submission-statusCode.model';
import { IntakeOppertunitySubmissionStatus } from '../../intake/constants/intake_oppertunity_submission_status.enum';
import { IntakeSubmissionDetailsCommonSectionDataSendingModal } from '../../common/models/intake-submission-details-common-section.model';
import {
  EngagementTileCode,
  RiskManagerTileCode,
} from '../../intake/constants/dashboard-tiles.enum';
import { EngagementDashboardTilesCount } from '../../common/models/start-page/engagement-dashboard.model';
import {
  RiskManagerDashboardData,
  RiskManagerDashboardTilesCount,
} from '../../common/models/start-page/riskmanager-dashboard.model';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';

@Component({
  selector: 'app-riskmanager-dashboard',
  templateUrl: './riskmanager-dashboard.component.html',
  styleUrl: './riskmanager-dashboard.component.less',
})
export class RiskmanagerDashboardComponent implements OnInit, OnDestroy {
  public riskManagerTiles = RiskManagerTileCode;
  public riskManagerTileCodeDetails = Array.from(
    IntakeConstant.RiskManagerTileCodeDetails.values()
  );
  public selectedTileCode: string = '';
  public dashboard: string = IntakeConstant.RISK_MANAGER_INSIGHT_DASHBOARD;
  public riskManagerDashboardTilesCountModel:
    | RiskManagerDashboardTilesCount
    | any;
  public sortParamaterCode: number | null = 1;
  public isAscending: boolean = true;
  public countforPagination: number = 0;
  public gotoPage: number = 1;
  public itemsPerPage: number = IntakeConstant.PAGE_NUMBER_10;
  public itemsPerPageOptions: number[] = [10, 25, 50];
  public gridTitle: string = '';
  public model: RiskManagerDashboardData[] = [];
  public riskManagerDashboardfilters: DashboardGridLevelFilter[] = [];
  public currentDashboardTileCode = DashboardTileCode.RiskManager;
  public gridColumns: GridColumn[] = [];
  options: ButtonOptions = buttonOptions;
  public buttonOptionsList: {
    label: string;
    action: string;
    buttonType: ButtonKind;
    condition: boolean | string;
  }[] = [];
  public defaultGridColumns: GridColumn[] = defaultGridColumns;
  public columnName = GridColumns;
  unsubscriber$: Subject<void> = new Subject<void>();
  optedOutToastOption: ToastOptions = optedOutSelfReviewToastMessage;
  opportunityStatusCodes: OppertunitySubmissionStatusCode[] = [];
  commonSectionDetails: IntakeSubmissionDetailsCommonSectionDataSendingModal =
    {} as IntakeSubmissionDetailsCommonSectionDataSendingModal;

  constructor(
    private readonly modalService: ModalService,
    private readonly riskmanagerWebapiService: RiskmanagerDashboardWebapiService,
    private readonly progressIndicatorService: ProgressIndicatorService,
    private readonly commonService: CommonService,
    private readonly router: Router
  ) {}
  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_Dashboard;
    this.progressIndicatorService.show();
    this.riskManagerDashboardTilesCountModel = {
      inProcess: 0,
      completed: 0,
      discontinued: 0,
    };
    this.getOpportunityStatusCodes();
    this.selectedTileCode = this.riskManagerTiles.Inprocess;

    this.gridTitle =
      this.riskManagerTileCodeDetails.find(
        (x) => x.tileCode === this.selectedTileCode
      )?.title || '';

    this.getTilesCount();
    this.sortParamaterCode =
      riskManagerSortParamaterCode[this.selectedTileCode];
    this.gotoPage = 1;
    this.onPageChanged(this.gotoPage);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  onTileSelected(selectedTileCode: string): void {
    this.selectedTileCode = selectedTileCode;
    this.gridTitle =
      this.riskManagerTileCodeDetails.find(
        (x) => x.tileCode === this.selectedTileCode
      )?.title || '';

    // Reset the filters and sorting state
    this.sortParamaterCode =
      riskManagerSortParamaterCode[this.selectedTileCode];
    this.isAscending = true;
    this.riskManagerDashboardfilters = [];
    // Reset the page number
    this.gotoPage = 1;
    this.onPageChanged(this.gotoPage);
  }

  getTilesCount(): void {
    this.riskmanagerWebapiService
      .getRiskManagerDashboardTilesCount()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: RiskManagerDashboardTilesCount) => {
          this.riskManagerDashboardTilesCountModel = response;
        },
        error: (err) => {
          console.error('Error fetching tiles count:', err);
        },
      });
  }

  refreshGrid(): void {
    // TODO: Need to set model as [] to remove the existing data when we switch tab
    this.progressIndicatorService.show();
    this.riskmanagerWebapiService
      .getRiskManagerDashBoardGridData(
        this.selectedTileCode,
        this.gotoPage,
        this.itemsPerPage,
        this.sortParamaterCode,
        this.isAscending,
        this.riskManagerDashboardfilters
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: DashboardDataCollection<RiskManagerDashboardData>) => {
          this.model = response.dataList;
          this.countforPagination = response.totalDataCount;
          this.progressIndicatorService.hide();
        },
        error: (err) => {
          console.error('Error fetching data', err);
          this.progressIndicatorService.hide();
        },
      });

    this.gridColumns = [...this.defaultGridColumns];

    if (this.selectedTileCode === this.riskManagerTiles.Inprocess) {
      this.gridColumns = RiskManagerInprocessColumns.map(
        (tableColumn: string) =>
          this.gridColumns.find(
            (column: GridColumn) => column.name === tableColumn
          )
      ).filter((col) => col !== undefined);
    }
    if (this.selectedTileCode === this.riskManagerTiles.Completed) {
      this.gridColumns = RiskManagercompletedColumns.map(
        (tableColumn: string) =>
          this.gridColumns.find(
            (column: GridColumn) => column.name === tableColumn
          )
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.riskManagerTiles.Discontinued) {
      this.gridColumns = RiskManagerdiscontinuedColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (
      this.selectedTileCode === this.riskManagerTiles.ExternalCommunications
    ) {
      this.gridColumns = RiskManagerexternalCommColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    // Adjust column widths dynamically excluding the expand-collapse icon column
    const totalColumns = this.gridColumns.length;
    const baseWidth = 105 / totalColumns; // 87% to leave space for the expand-collapse icon column
    this.gridColumns.forEach((column: GridColumn) => {
      if (
        column.name !== this.columnName.ActionByName &&
        column.name != this.columnName.SubmissionTitle &&
        column.name != this.columnName.OpportunityIcon &&
        column.name !== this.columnName.Actions &&
        column.name !== this.columnName.JupiterID &&
        column.name != this.columnName.Focus &&
        column.name != this.columnName.ClientName &&
        column.name != this.columnName.OpportunityName
      ) {
        column.width = `${baseWidth}%`;
      }

      if (
        column.name === this.columnName.OpportunityIcon ||
        column.name === this.columnName.JupiterID
      )
        column.width = column.minWidth;

      if (column.name === this.columnName.Actions) {
        column.minWidth = column.width = '21rem';
      }

      if (column.name == this.columnName.Focus) {
        column.width = '4.5rem';
      }

      if (column.name === this.columnName.SubmissionTitle) {
        column.minWidth = column.width = '18rem';
      }
    });
  }

  onPageChanged(value: number): void {
    this.gotoPage = value - 1;
    this.refreshGrid();
  }

  onValueChanged(item: number): void {
    this.itemsPerPage = item;
    this.gotoPage = 0;
    this.refreshGrid();
  }

  gridUpdatedEvent(event: {
    sortParamaterCode: number | null;
    isAscending: boolean;
    dashboardfilters: any[];
  }): void {
    const shouldRefreshGrid =
      this.sortParamaterCode !== event.sortParamaterCode ||
      this.isAscending !== event.isAscending ||
      JSON.stringify(this.riskManagerDashboardfilters) !==
        JSON.stringify(event.dashboardfilters);
    this.sortParamaterCode = event.sortParamaterCode;
    this.isAscending = event.isAscending;
    this.riskManagerDashboardfilters = event.dashboardfilters;
    if (shouldRefreshGrid) {
      this.refreshGrid();
    }
  }

  public goToSubmission(jupiterId: string, submissionId: number): void {
    this.router.navigate(['/submission/opportunity-details', jupiterId], {
      state: {
        navigatingFromDashboard: true,
        submissionID: submissionId,
      },
    });
  }

  openModalForRecentlyViewed(modalBtn?: ButtonComponent): void {
    let openRecentViewModal = this.modalService.open(RecentlyViewedComponent, {
      isFooter: false,
      size: IntakeConstant.UI_ELEMENT_SIZE,
      isInverse: false,
    });
    openRecentViewModal
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          if (modalBtn) {
            modalBtn.focus();
          }
        },
        error: (err) => {
          console.error('An error occurred:', err);
        },
      });
  }

  getOpportunityStatusCodes(): void {
    this.commonService
      .getOppertunityStatusCode()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[]) => {
          if (response) {
            this.opportunityStatusCodes = response;
          }
        },
        error: (err) => {
          console.error('Error fetching intake status codes', err);
        },
      });
  }

  getSubmissionStatusId(statusName: string): number | undefined {
    return this.opportunityStatusCodes?.find(
      (status: OppertunitySubmissionStatusCode) =>
        status.statusName === statusName
    )?.id;
  }

  getSubmissionStatusName(id: number): string | undefined {
    return this.opportunityStatusCodes?.find(
      (status: OppertunitySubmissionStatusCode) => status.id === id
    )?.statusName;
  }
}
