export enum IntakeOppertunitySubmissionStatus {
  DRAFT = 'Draft',
  RETURN_FOR_REWORK = 'Returned for revisions',
  SUBMITTED = 'Submitted',
  RESUBMITTED = 'Re-submitted',
  RM_REVIEWED_PENDING_CLOSEOUT = 'RM reviewed - Pending closeout',
  SELF_REVIEWED_PENDING_CLOSEOUT = 'Self reviewed - Pending closeout',
  COMPLETED = 'Completed',
  DISCONTINUED = 'Discontinued',
  COMPLETED_ADMIN = 'Completed (Admin)',
}

export const DOCUMENT_SUBMISSION_STATUS_ID_MAPPER: Record<
  IntakeOppertunitySubmissionStatus,
  number
> = {
  [IntakeOppertunitySubmissionStatus.DRAFT]: 1,
  [IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK]: 2,
  [IntakeOppertunitySubmissionStatus.SUBMITTED]: 3,
  [IntakeOppertunitySubmissionStatus.RESUBMITTED]: 4,
  [IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT]: 5,
  [IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT]: 6,
  [IntakeOppertunitySubmissionStatus.COMPLETED]: 7,
  [IntakeOppertunitySubmissionStatus.DISCONTINUED]: 8,
  [IntakeOppertunitySubmissionStatus.COMPLETED_ADMIN]: 9,
};
