<div class="dds-modal-overlay"
     (click)="close()"></div>

<div ddsResize
     class="dds-modal dds-modal_lg">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Watchlist info</span>
    <button class="dds-modal__close dds-icon dds-icon_close" (click)="cancel()" aria-label="Close modal"></button>
  </div>
  <div class="dds-modal__body">
    <div class="margin-bottom-xxs">
      <span class="eclipse-small-header-grey">Client name</span>
    </div>
    <div class="moreText margin-bottom-s">
      <span class="padding-right-xxs">{{isParentClient ? clientWatchlistDetail.parentClientName : clientWatchlistDetail.clientName}}</span>
    </div>

    <div *ngIf="!isPriorBlockVisible">
      <div class="margin-bottom-xxs">
        <span class="eclipse-small-header-grey">Client status</span>
      </div>
      <div class="moreText margin-bottom-s">
        <span [ngClass]="{
'serve-with-conditions-container': (isParentClient ? clientWatchlistDetail.pcWatchlistStatusId :clientWatchlistDetail.watchlistStatusId) === enumWatchlistStatus.ServeWithConditions,
'do-not-serve-container': (isParentClient ? clientWatchlistDetail.pcWatchlistStatusId :clientWatchlistDetail.watchlistStatusId) === enumWatchlistStatus.DoNotServe
              }">
          {{ isParentClient ? clientWatchlistDetail.pcWatchlistStatus : clientWatchlistDetail.watchlistStatus}}
        </span>
      </div>
      <div class="margin-bottom-xxs">
        <span class="eclipse-small-header-grey">
          {{isParentClient ? clientWatchlistDetail.pcWatchlistChangeStatus : clientWatchlistDetail.watchlistChangeStatus}}
        </span>
      </div>
      <div class="moreText margin-bottom-s">
        <p>
          <ng-container>by {{isParentClient ? clientWatchlistDetail.pClientWatchlistCreatedBy: clientWatchlistDetail.clientWatchlistCreatedBy}} on {{isParentClient ? clientWatchlistDetail.pClientWatchlistCreatedDate : clientWatchlistDetail.clientWatchlistCreatedDate}}</ng-container>
        </p>
      </div>
      <div class="margin-bottom-xxs">
        <span class="eclipse-small-header-grey">Comment</span>
      </div>
      <div class="moreText bottom-top-s">
        <p><ng-container>{{isParentClient ? clientWatchlistDetail.pcStatusComment : clientWatchlistDetail.statusComment}}</ng-container></p>
      </div>
    </div>


    <div *ngIf="isPriorBlockVisible">
      <div class="margin-bottom-xxs">
        <span class="eclipse-small-header-grey">Removed</span>
      </div>
      <div class="moreText margin-bottom-s">
        <p>
          <ng-container>by {{isParentClient ? clientWatchlistDetail.pClientWatchlistCreatedBy : clientWatchlistDetail.clientWatchlistCreatedBy}} on {{isParentClient ? clientWatchlistDetail.pClientWatchlistCreatedDate : clientWatchlistDetail.clientWatchlistCreatedDate}}</ng-container>
        </p>
      </div>
      <div class="margin-bottom-xxs">
        <span class="eclipse-small-header-grey">Comment</span>
      </div>
      <div class="moreText bottom-top-s">
        <p><ng-container>{{isParentClient ? clientWatchlistDetail.pcStatusComment : clientWatchlistDetail.statusComment}}</ng-container></p>
      </div>
      <div class="margin-bottom-xxs">
        <span class="eclipse-small-header-grey">Previous client status</span>
      </div>
      <div class="moreText margin-bottom-s">
        <span [ngClass]="{
'serve-with-conditions-container': (isParentClient ? clientWatchlistDetail.prevPcWatchlistStatusId : clientWatchlistDetail.prevWatchlistStatusId) === enumWatchlistStatus.ServeWithConditions,
'do-not-serve-container': (isParentClient ? clientWatchlistDetail.prevPcWatchlistStatusId : clientWatchlistDetail.prevWatchlistStatusId) === enumWatchlistStatus.DoNotServe}">
          {{ isParentClient ? clientWatchlistDetail.prevPcWatchlistStatus: clientWatchlistDetail.prevWatchlistStatus}}
        </span>
      </div>
      <div class="margin-bottom-xxs">
        <span class="eclipse-small-header-grey">{{isParentClient ? clientWatchlistDetail.prevPcWatchlistChangeStatus : clientWatchlistDetail.prevWatchlistChangeStatus}}</span>
      </div>
      <div class="moreText margin-bottom-s">
        <p>
          <ng-container>by {{isParentClient ? clientWatchlistDetail.prevPcModifiedBy : clientWatchlistDetail.prevModifiedBy}} on {{isParentClient ? clientWatchlistDetail.prevPcModifiedDate : clientWatchlistDetail.prevModifiedDate}}</ng-container>
        </p>
      </div>

      <div class="margin-bottom-xxs">
        <span class="eclipse-small-header-grey">Previous comment</span>
      </div>
      <div class="moreText bottom-top-s">
        <p><ng-container>{{isParentClient ? clientWatchlistDetail.prevPcStatusComment : clientWatchlistDetail.prevStatusComment}}</ng-container></p>
      </div>
    </div>
  </div>
 </div>
