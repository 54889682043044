import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { Client } from '../models/client.model';
import { ClientWebapiService } from '../../http/client/client-webapi.service';
import { ParentClient } from '../models/parent-client.model';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { Themes } from '@usitsdasdesign/dds-ng/shared';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private dataSource = new BehaviorSubject<State>({
    isHasNotConnectedActiveOpps: false,
    isCustomNotRequiredForm: false,
    segmentationTypeID: 0,
    segmentationTypeName: '',
    updateClientMilestoneDetails: false,
    isSupplementalApproval: false
  });


  private _getCalculateNCAFormRequiredFields = new BehaviorSubject<CalculateNCAFormRequiredFields>({
    isNCAForm: false,
    isAllNcaFormRequiredFieldsFilled: false,
    isAllApprovalRequiredFieldsFilled: false,
    isAllQRMCommunicationRequiredFieldsFilled: false,
    isSupplementalApprovalFieldsFilled:false
  });
  public setCalculateNCAFormRequiredFields(value: CalculateNCAFormRequiredFields) {
    this._getCalculateNCAFormRequiredFields.next(value);
  }
  public getCalculateNCAFormRequiredFields$ = this._getCalculateNCAFormRequiredFields.asObservable();

  //CC form required fields calculation
  private _getCalculateCCFormRequiredFields = new BehaviorSubject<CalculateCCFormRequiredFields>({
    isCCForm: false,
    isAllCCFormRequiredFieldsFilled: false
  });
  public setCalculateCCFormRequiredFields(value: CalculateCCFormRequiredFields) {
    this._getCalculateCCFormRequiredFields.next(value);
  }
  public getCalculateCCFormRequiredFields$ = this._getCalculateCCFormRequiredFields.asObservable();

  currentState = this.dataSource.asObservable();

  private clientDataSubject = new BehaviorSubject<Client | null>(null);
  clientData$ = this.clientDataSubject.asObservable();

  private parentClientDataSubject = new BehaviorSubject<ParentClient | null>(null);
  parentClientData$ = this.parentClientDataSubject.asObservable();

  constructor(private clientWebapiService: ClientWebapiService, private toastService: ToastService) { }
  //TODO: Check if we can do this in a better way, cases where multiple  different parameters updates are happening from different components.
  updateState(newState: Partial<State>) {
    const currentState = this.dataSource.getValue();
    this.dataSource.next({ ...currentState, ...newState });
  }

  fetchClientData(clientNumber: string): void {
    this.clientWebapiService.getClientInfo(clientNumber).subscribe(
      (data: Client) => {
        this.clientDataSubject.next(data);
      },
      (error) => {
        console.error('Error fetching client data', error);
        //For parent client of subsidiary client is accessed from Add watch list modal window
        //If parent client data is not present, empty object is passed to avoid UI disturbance
        var data = <Client>{};
        this.clientDataSubject.next(data);
      }
    );
  }

  fetchParentClientData(clientNumber: string): void {
    this.clientWebapiService.getParentClientInfo(clientNumber).subscribe(
      (data: ParentClient) => {
        this.parentClientDataSubject.next(data);
      },
      (error) => {
        console.error('Error fetching client data', error);
      }
    );
  }

  showToastNotification(toastMessage: string, isActionBtn: boolean = false, actionTitle?: string, actionCallbackFn?: any) {
    if (isActionBtn) {
      var options: ToastOptions = {
        title: toastMessage,
        message: '',
        action: { title: actionTitle ?? '', actionFunc: ($event: Event) => actionCallbackFn($event) },
        lifeTime: 0,
        withProgressBar: false,
        position: 'bottom-center',
        closeBtnIcon: 'dds-icon_close',
        isCloseIcon: true,
        customClass: 'action-toast',
        isError: false,
        isInverse: false,
        isLoading: false,
        limit: 1,
        theme: Themes.dark
      };
    }
    else {
      var options: ToastOptions = {
        title: toastMessage,
        message: '',
        lifeTime: 5000,
        withProgressBar: false,
        position: 'bottom-center',
        closeBtnIcon: 'dds-icon_close',
        isCloseIcon: false,
        customClass: 'simple-toast',
        isError: false,
        isInverse: false,
        isLoading: false,
        limit: 1,
        theme: Themes.dark
      };
    }
    this.toastService.createToast(options);
  }
}

interface State {
  isHasNotConnectedActiveOpps: boolean;
  isCustomNotRequiredForm: boolean;
  segmentationTypeID: number;
  segmentationTypeName: string | null;
  updateClientMilestoneDetails: boolean;
  isSupplementalApproval: boolean;
}

interface CalculateNCAFormRequiredFields {
  isNCAForm: boolean;
  isAllNcaFormRequiredFieldsFilled: boolean;
  isAllApprovalRequiredFieldsFilled: boolean;
  isAllQRMCommunicationRequiredFieldsFilled: boolean;
  isSupplementalApprovalFieldsFilled: boolean;
}

interface CalculateCCFormRequiredFields {
  isCCForm: boolean;
  isAllCCFormRequiredFieldsFilled: boolean;
}
