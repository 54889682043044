import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { IntakeConstant } from '../../constants/intake.constant';
import { Size, Themes } from '@usitsdasdesign/dds-ng/shared';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';

export const textareaOptions: TextareaOptions = {
  placeholder: IntakeConstant.ENTER_TEXT_PLACEHOLDER,
  minHeight: 100,
  maxLength: 250,
  size: IntakeConstant.UI_ELEMENT_SIZE,
  maxLengthPosition: 'bottom-right',
};

export const options: TagOptions = {
  theme: Themes.dark,
  isRemovable: true,
  disabled: false,
  isError: false,
  isInverse: false,
  size:Size.lg,
  customClass: '',
};
