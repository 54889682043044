import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, ElementRef, Renderer2, NgZone } from '@angular/core';
import { ProgressIndicatorService } from '../common/services/progress-indicator.service';
import { ActiveNcaSummary, ActiveNcaSummaryCollection, ActiveNcaSummarySpecialistStatus } from '../http/dashboard/nca-summary-webapi.service';
import { environment } from '../../environment/environment';
import { DashboardGridLevelFilter, DashboardTileCode, GridColumn } from '../common/models/start-page/start-page.model';
import { Subject } from 'rxjs';
import { TabComponent, TabsOptions } from '@usitsdasdesign/dds-ng/tabs';
import { ButtonKind, ExtThemes, Size, TabThemeType, Themes, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { StartPageGridAreaComponent } from '../start-page/startpage-gridarea/startpage-gridarea.component';
import { FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { NcaSummaryWebapiService } from '../http/dashboard/nca-summary-webapi.service';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ChartEmitData, ChartItem } from '../common/models/chart-item.model';
import { NcaSummaryFooterComponent } from '../nca-summary/nca-summary-footer/nca-summary-footer.component';
@Component({
  selector: 'app-nca-summary',
  templateUrl: './nca-summary.component.html',
  styleUrl: './nca-summary.component.less'
})
export class NcaSummaryComponent implements OnInit, OnDestroy {
  @ViewChild(StartPageGridAreaComponent) startPageGrid!: StartPageGridAreaComponent;
  @ViewChildren('tabButton') tabButtons!: QueryList<TabComponent>;
  jupiterFilterValue: string = '';
  public sortParamaterCode: number = 1;
  public isAscending: boolean = true;
  public countforPagination: number = 0;
  public gotoPage: number = 1;
  public itemsPerPage: number = 10;
  public itemsPerPageOptions: number[] = [10, 25, 50];
  public ActiveNcaSummaryCollectionModel: ActiveNcaSummaryCollection = {
    activeNcaSummary: [],
    totalNcasCount: 0,
    notAssignedNcasCount: 0,
    notStartedNcasCount: 0,
    inProcessNcasCount: 0,
    pendingApprovalsNcasCount: 0,
    onHoldNcasCount: 0,
    ncaSpecialistsFilterData: []
  };
  public model: ActiveNcaSummary[] = [];
  public ncaSpecialistStatusModel: ActiveNcaSummarySpecialistStatus[] = [];
  public ncaDashboardfilters: DashboardGridLevelFilter[] = [];
  public currentDashboardTileCode = DashboardTileCode.NcaSummary;
  public rootUrl: string = environment.rootUrl;
  public gridColumns: GridColumn[] = [];

  public defaultGridColumns: GridColumn[] = [
    { name: 'clientMilestoneStatus', header: 'Status', dataType: 'string', filterable: true, sortable: true },
    { name: 'clientMilestoneSubStatus', header: 'Sub-status', dataType: 'string', filterable: true, sortable: true },
    { name: 'clientName', header: 'Client name', dataType: 'string', width: '12rem', filterable: true, sortable: true },
    { name: 'industry', header: 'Industry-Sector', dataType: 'string', width: '10rem', filterable: true, sortable: true },
    { name: 'ncaSpecialistPreferredName', header: 'Specialist', dataType: 'string', filterable: true, sortable: true },
    { name: 'sectorLeaderDisplayName', header: 'Sector Leader', dataType: 'string', filterable: true, sortable: true },
    { name: 'ncaIrmDisplayName', header: 'Industry Risk manager', dataType: 'string', filterable: true, sortable: true },
    { name: 'marsQrmDisplayName', header: 'MARS QRM', dataType: 'string', filterable: true, sortable: true },
    { name: 'overallAging', header: 'Overall aging', dataType: 'number', width: '6rem', filterable: true, sortable: true },
    { name: 'ncaType', header: 'NCA type', dataType: 'string', width: '6rem', filterable: true, sortable: true }
  ];

  clearButtonoptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.md,
    width: WidthState.fixed,
    disabled: false,
  }

  tabContainerOptions: TabsOptions = {
    theme: Themes.green,
    themeType: TabThemeType.border,
    size: Size.lg,
    ariaLabel: 'Horizontal tabs',
    disabled: false,
    isResponsive: false
  }
  searchOptions: SearchOptions = {
    placeholder: 'Search by Jupiter id',
    size: Size.lg,
    maxLength: 10,
  };

  public tabs = [
    {
      label: 'Total',
      code: 'ncaall',
      count: this.ActiveNcaSummaryCollectionModel.totalNcasCount,
    },
    {
      label: 'Not assigned',
      code: 'notassigned',
      count: this.ActiveNcaSummaryCollectionModel.notAssignedNcasCount,
    },
    {
      label: 'Not started',
      code: 'notstarted',
      count: this.ActiveNcaSummaryCollectionModel.notStartedNcasCount
    },
    {
      label: 'In process',
      code: 'inprogress',
      count: this.ActiveNcaSummaryCollectionModel.inProcessNcasCount
    },
    {
      label: 'Pending approvals',
      code: 'pendingapprovals',
      count: this.ActiveNcaSummaryCollectionModel.pendingApprovalsNcasCount
    },
    {
      label: 'On hold',
      code: 'onhold',
      count: this.ActiveNcaSummaryCollectionModel.onHoldNcasCount
    },
  ];

  public filterDataList: { name: string, data: FilterItem[] }[] = [];

  public selectedTabCode: string = this.tabs[0].code;

  private unsubscriber$ = new Subject<void>();

  private itemColor: string[] = ['#BBBCBC', '#00A3E0', '#86BC25', '#FFFFFF'];
  private itemLabel: string[] = ['Not started', 'In process', 'Pending approvals', 'On hold'];
  private itemLabelClickSendValues = ['notstarted', 'inprogress', 'pendingapprovals', 'onhold'];
  barChartTitle: string = 'NCA specialist assignment by status section';
  bars: ChartItem[] = [];
  isChartDataLoaded: boolean = false;
  isNameStatusFilter: boolean = false;
  defaultBar: ChartItem = {
    isLabelVisible: false,
    itemColor: this.itemColor,
    itemLabel: this.itemLabel,
    itemTooltip: [],
    itemValues: [],
    label: '',
    itemLabelClickSendValues: [],
    itemValuesClickSendValues: [],
    legendItemClickSendValues: this.itemLabelClickSendValues
  };
  isTabCodeChanged: boolean = false;
  isClearFilter: boolean = false;

  constructor(
    private readonly ncaSummaryWebapiService: NcaSummaryWebapiService,
    private readonly progressIndicatorService: ProgressIndicatorService,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.progressIndicatorService.show();
    this.sortParamaterCode = 1;
    this.gotoPage = 0;
    this.refreshNcaSummarySpecialistStatus();
    this.refreshGrid();
    this.setFilterData();
  }

  ngOnDestroy(): void {
    this.progressIndicatorService.hide();
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  refreshNcaSummarySpecialistStatus() {
    this.progressIndicatorService.show();
    this.isChartDataLoaded = false;

    this.ncaSummaryWebapiService.getNcaSummarySpecialistStatus(
      this.selectedTabCode,
      this.ncaDashboardfilters
    ).subscribe(response => {
      this.bars = [];
      this.ncaSpecialistStatusModel = response;
      if (this.ncaSpecialistStatusModel.length > 0) {
        this.ncaSpecialistStatusModel.forEach((value, index) => {
          this.bars.push({
            isLabelVisible: false,
            itemColor: this.itemColor,
            itemLabel: this.itemLabel,
            itemTooltip: this.itemLabel,
            itemValues: [value.notStartedNcasCount, value.inProcessNcasCount, value.pendingApprovalsNcasCount, value.onHoldNcasCount],
            label: value.ncaSpecialistPreferredName ?? 'Not assigned',
            itemLabelClickSendValues: [],
            legendItemClickSendValues: this.itemLabelClickSendValues,
            itemValuesClickSendValues: [value.acceptedBy]
          });
        });
      }
      else {
        this.bars.push(this.defaultBar);
      }
      this.isChartDataLoaded = true;
      this.progressIndicatorService.hide();
    });
  }

  refreshGrid(): void {
    this.progressIndicatorService.show();
    this.ncaSummaryWebapiService.getActiveNcaSummary(
      this.gotoPage,
      this.itemsPerPage,
      this.sortParamaterCode,
      this.isAscending,
      this.selectedTabCode,
      this.ncaDashboardfilters
    ).subscribe(response => {
      this.ActiveNcaSummaryCollectionModel = response;
      this.model = response.activeNcaSummary;

      // Mapping object for tab counts
      const countMapping = {
        ncaall: response.totalNcasCount,
        notassigned: response.notAssignedNcasCount,
        notstarted: response.notStartedNcasCount,
        inprogress: response.inProcessNcasCount,
        pendingapprovals: response.pendingApprovalsNcasCount,
        onhold: response.onHoldNcasCount
      };

      this.countforPagination = countMapping[this.selectedTabCode as keyof typeof countMapping] || response.totalNcasCount;
      this.tabs.forEach(tab => {
        if (countMapping[tab.code as keyof typeof countMapping] !== undefined) {
          tab.count = countMapping[tab.code as keyof typeof countMapping];
        }
      });

      this.progressIndicatorService.hide();
    });

    this.gridColumns = [...this.defaultGridColumns];
    this.adjustColumnWidths();
  }

  onPageChanged(value: number): void {
    this.gotoPage = value - 1;
    this.refreshGrid();
  }

  onValueChanged(item: number): void {
    this.itemsPerPage = item;
    this.gotoPage = 0;
    this.refreshGrid();
  }

  public onTabChange(tabCode: string): void {
    this.selectedTabCode = tabCode;
    this.setActiveTab(tabCode);
    if (this.ncaDashboardfilters.length > 0) {
      this.isNameStatusFilter = true;
    }
    this.refreshGrid();
    this.setFilterData();
    this.refreshNcaSummarySpecialistStatus();
  }

  barFilterSet(value: any): DashboardGridLevelFilter[] {
    let barFilter: DashboardGridLevelFilter[] = [];
    if (value == null) {
      this.selectedTabCode = 'notassigned';
    }
    else {
      barFilter.push(
        {
          "filtertypecode": 36,  // Specialist filter
          "filtercriteria": value
        });
    }
    return barFilter;
  }

  barChartClick(event: ChartEmitData[]) {
    const prevSelectedTabCode = this.selectedTabCode;

    this.isNameStatusFilter = false;
    var barFilter: DashboardGridLevelFilter[] = [];
    for (var v of event) {
      if (v.type == "legend") {
        this.selectedTabCode = v.value;
      }
      else if (v.type == "barlabel") {
        this.selectedTabCode = 'ncaall';
        barFilter = this.barFilterSet(v.value);
        var gridEvent = {
          sortParamaterCode: this.sortParamaterCode,
          isAscending: this.isAscending,
          dashboardfilters: barFilter
        };
        this.gridUpdatedEvent(gridEvent);
        break;
      }
      else if (v.type == "bar") {
        barFilter = this.barFilterSet(v.value);
        this.isNameStatusFilter = true;
      }
    }

    this.ncaDashboardfilters = barFilter;
    if (!this.isNameStatusFilter) {
      this.onTabChange(this.selectedTabCode);
    }

    this.setActiveTab(this.selectedTabCode);
    this.refreshGrid();
    this.refreshNcaSummarySpecialistStatus();
  }

  gridUpdatedEvent(event: { sortParamaterCode: number; isAscending: boolean; dashboardfilters: any[] }): void {
    const shouldRefreshGrid =
      this.sortParamaterCode !== event.sortParamaterCode ||
      this.isAscending !== event.isAscending ||
      JSON.stringify(this.ncaDashboardfilters) !== JSON.stringify(event.dashboardfilters);

    const shouldRefreshSpecialistStatusChart =
      JSON.stringify(this.ncaDashboardfilters) !== JSON.stringify(event.dashboardfilters);

    this.sortParamaterCode = event.sortParamaterCode;
    this.isAscending = event.isAscending;
    if (!this.isNameStatusFilter) {
      this.ncaDashboardfilters = event.dashboardfilters;
      this.isNameStatusFilter = false;
    }

    if (shouldRefreshGrid || this.isClearFilter) {
      this.refreshGrid();
    }
    if (shouldRefreshSpecialistStatusChart || this.isClearFilter) {
      this.isNameStatusFilter = false;
      this.refreshNcaSummarySpecialistStatus();
    }
    this.isClearFilter = false;
  }

  setFilterData(): void {
    //TODO: Get this data from backend if needed, Note: This name parameter will be used in like search in the backend
    this.filterDataList = [
      {
        name: 'clientMilestoneStatusFilterData',
        data: [
          { title: 'Not started', name: 'NotStarted', isChecked: false },
          { title: 'In process', name: 'InProcess', isChecked: false },
          { title: 'On hold', name: 'OnHold', isChecked: false },
          { title: 'Pending approvals', name: 'PendingApprovals', isChecked: false }
        ]
      },
      {
        name: 'clientMilestoneSubStatusFilterData',
        data: [
          { title: 'Pending CIS Results', name: 'PendingCISResults', isChecked: true },
          { title: 'Pending LCSP/LBP Recommendation', name: 'PendingLCSPLBPRecommendation', isChecked: true },
          { title: 'Finding Consultation', name: 'FindingsConsultation', isChecked: true },
          { title: 'NCA Team', name: 'NCATeam', isChecked: true },
          { title: 'Query/Discussion - Others', name: 'QueryDiscussionOthers', isChecked: true },
          { title: 'Query/Discussion - Engagement Team', name: 'QueryDiscussionEngagementTeam', isChecked: true },
          { title: 'Acknowledged by NCA Team', name: 'AcknowledgedByNCATeam', isChecked: true },
          { title: 'Query/Discussion - Jupiter/SWIFT Team', name: 'QueryDiscussionJupiterSWIFTTeam', isChecked: true },
          { title: 'Pending initiation by NCA Team', name: 'PendingInitiationByNCATeam', isChecked: true },
          { title: 'On hold', name: 'OnHold', isChecked: true },
          { title: 'Pending Approvals: Industry NCA Risk Manager', name: 'IndustryNCARM', isChecked: true },
          { title: 'Pending Approvals: Sector Leader', name: 'SectorLeader', isChecked: true }
        ]
      },
      {
        name: 'ncaTypeFilterData',
        data: [
          { title: 'Consulting', name: 'DC_Nca', isChecked: true },
          { title: 'MARS', name: 'NonAttest_MARSNCA', isChecked: true }
        ]
      },
      {
        name: 'ncaSpecialistsFilterData',
        data: this.ActiveNcaSummaryCollectionModel.ncaSpecialistsFilterData
      }
    ];

    // Update isChecked based on the selected tab
    const selectedTab = this.tabs.find(tab => tab.code === this.selectedTabCode);
    if (selectedTab) {
      if (selectedTab.code === 'ncaall') {
        this.filterDataList.forEach(filter => {
          if (filter.name === 'clientMilestoneStatusFilterData') {
            filter.data.forEach(item => {
              item['isChecked'] = true;
            });
          }
        });
      }
      else if (selectedTab.code === 'notassigned') {
        this.filterDataList.forEach(filter => {
          if (filter.name === 'ncaSpecialistsFilterData') {
            filter.data.forEach(item => {
              item['isChecked'] = item['title'] === 'BLANK';
            });
          }
        });
      }
      else {
        this.filterDataList.forEach(filter => {
          if (filter.name === 'clientMilestoneStatusFilterData') {
            filter.data.forEach(item => {
              item['isChecked'] = item['title'] === selectedTab.label;
            });
          }
        });
      }
    }
  }

  onSearchInput(event: any) {
    this.startPageGrid.onSearchInput(event);
  }

  clearAllFilter() {
    this.selectedTabCode = 'ncaall';
    this.ncaDashboardfilters = [];
    this.isNameStatusFilter = false;
    this.isClearFilter = true;
    this.setActiveTab(this.selectedTabCode);
    this.clearJupiterSearch();
    this.jupiterFilterValue = '';
    this.startPageGrid.clearAllFilter();
    this.setFilterData();
  }

  // TODO: See if we can use the below methods from the start page grid component
  // #region Jupiter Search
  public inputClick() {
    if (
      !this.jupiterFilterValue ||
      this.jupiterFilterValue.indexOf('JO-') != 0
    ) {
      this.jupiterFilterValue = 'JO-';
    }
  }

  public validateKeypress(event: KeyboardEvent) {
    const keyPressed = event.key;
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;

    // Prevent deletion of "JO-"
    if (this.isDeletionKey(keyPressed)) {
      if (currentValue.startsWith('JO-') && inputElement.selectionStart! <= 3) {
        event.preventDefault();
      }
      return;
    }

    // Prevent overwriting "JO-"
    if (currentValue.startsWith('JO-') && inputElement.selectionStart! < 3) {
      event.preventDefault();
      return;
    }

    // Ensure numeric entry
    if (!this.isValidKey(event)) {
      event.preventDefault();
    }
  }

  private isDeletionKey(key: string): boolean {
    return key === 'Backspace' || key === 'Delete';
  }

  private isValidKey(event: KeyboardEvent): boolean {
    const key = event.key;
    const validKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']; //allow arrow navigation
    const isCtrlKey = event.ctrlKey && (key === 'c' || key === 'v'); //allow pasting
    const isNumeric = key >= '0' && key <= '9'; // check if the key is a number


    return validKeys.includes(key) || isCtrlKey || isNumeric;
  }

  //TODO: Implement jupiter Search Clear on click outside of search box
  // @HostListener('document:click', ['$event'])
  // onDocumentClick(event: MouseEvent) {
  //   if (event.target instanceof Element) {
  //     const isClickedOutsideSearchBox = !event.target.closest('.jupiter-search');

  //     if (
  //       isClickedOutsideSearchBox &&
  //       (!this.jupiterFilterValue || this.jupiterFilterValue.length < 10)
  //     ) {
  //       this.clearJupiterSearch();
  //     }
  //   }
  // }

  private clearJupiterSearch() {
    const activeElement = document.activeElement as HTMLInputElement;
    if (
      activeElement &&
      activeElement.classList.contains('jupiter-search-input')
    ) {
      this.jupiterFilterValue = 'JO-';
      activeElement.value = 'JO-';
    } else {
      const searchInput = document.querySelector(
        '.jupiter-search-input'
      ) as HTMLInputElement;
      this.jupiterFilterValue = '';
      if (searchInput) searchInput.value = '';
    }
    this.startPageGrid.clearAllFilter();
    this.updateClearButtonVisibility();
  }

  private updateClearButtonVisibility() {
    const searchInput = document.querySelector(
      '.jupiter-search-input'
    ) as HTMLInputElement;
    if (searchInput) {
      const clearButton = searchInput.nextElementSibling
        ?.nextElementSibling as HTMLElement;
      if (clearButton) {
        clearButton.style.display =
          searchInput && searchInput.value.length > 3 ? 'block' : 'none';
      }
    }
  }

  // #endregion

  public gotoClientStatus(clientNumber: string): void {
    let url = this.rootUrl + 'client/' + clientNumber + '/status';
    window.open(url, '_blank');
  }


  private adjustColumnWidths(): void {
    const totalColumns = this.gridColumns.length;
    const baseWidth = 95 / totalColumns;
    this.gridColumns.forEach(column => {
      if (column.name !== 'clientName' && column.name !== 'industry' && column.name !== 'overallAging'
        && column.name !== 'ncaType') {
        column.width = `${baseWidth}%`;
      }
    });
  }



  setActiveTab(selectItem: string) {
    const tabButtons = this.el.nativeElement.querySelectorAll('.dds-tabs__header-item');
    const selectedTab = this.tabs.find(x => x.code === selectItem);

    tabButtons.forEach((button: Element) => {
      const buttonText = button.querySelector('.dds-tabs__header-text')?.textContent;
      button.classList.remove('dds-tabs__header-item_active');
      if (buttonText?.toLowerCase() === selectedTab?.label?.toLowerCase()) {
        button.classList.add('dds-tabs__header-item_active');
      }
    });
  }
}
