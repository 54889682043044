<div class="tiles-container">
  <div class="page-header">
    <h2 class="title inline-block font-size-32">Audit Trail</h2>
    <span class="seperator inline-block"></span>
  </div>
  <div class="row padding-top-l">
    <div class="title-container">
      <h4 class="eclipse-main-tab-header text-align-left">{{ titleValue }}</h4>
    </div>
    <div *ngIf="submissionId !== null; else opportunityHeader" class="d-flex">
      <div class="font-size-14">
        <span class="eclipse-content-grey padding-right-xxs"
          >Submission ID:</span
        >
        <span class="padding-right-vs">{{ submissionId }}</span>
      </div>
      <div class="vertical-pipe"></div>
      <div>
        <span class="eclipse-content-grey padding-right-xxs padding-left-vs"
          >Submission type:</span
        >
        <span
          class="submission-type font-size-12 font-bold margin-right-vs padding-xxs"
          >{{ submissionTypeValue }}</span
        >
      </div>
      <div class="vertical-pipe"></div>
      <div *ngIf="!isExtCommSubmission">
        <span class="eclipse-content-grey padding-right-xxs padding-left-vs"
          >Client name:</span
        >
        <span class="padding-right-vs">{{ clientNameValue }}</span>
      </div>
      <div class="vertical-pipe"></div>
      <div *ngIf="!isExtCommSubmission">
        <span class="eclipse-content-grey padding-right-xxs padding-left-vs"
          >Opportunity name:</span
        >
        <span class="padding-right-vs">{{ opportunityNameValue }}</span>
      </div>
    </div>
    <ng-template #opportunityHeader>
      <div>
        <span class="eclipse-content-grey padding-right-xxs">Client name:</span>
        <span class="padding-right-vs">{{ clientNameValue }}</span>
      </div>
    </ng-template>
    <div class="row padding-top-s">
      <app-audit-trail-table
        [opportunityNumber]="opportunityNumber"
        [submissionId]="submissionId"
        [isExtCommSubmission]="isExtCommSubmission"
        [isGlobalEngagement]="isGlobalEngagement"
        (headerData)="headerData($event)"
      />
    </div>
  </div>
</div>
