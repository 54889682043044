import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Size, Themes } from '@usitsdasdesign/dds-ng/shared';
import { ClientStatusWebapiService } from '../../../http/client/client-status-webapi.service';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';

@Component({
  selector: 'app-discontinue-nca-dialog',
  templateUrl: './discontinue-nca-dialog.component.html',
  styleUrl: './discontinue-nca-dialog.component.less'
})
export class DiscontinueNcaDialogComponent {
  public reason: string = '';
  public subStatusID: number = 0;
  public substatuscustom: string = '';
  @Input() resolve: any;

  constructor(private modalService: ModalService,
              private clientstatuswebapiservice: ClientStatusWebapiService,
              private progressIndicator: ProgressIndicatorService
  ) {
  }

  public ngOnInit() {
  }
  public discontinue() {
    this.progressIndicator.show();
    this.substatuscustom = this.resolve.optionsClientMilestoneSubStatus
      .filter((item: any) => item.value == this.subStatusID)
      .map((item: any) => item.heading);
    this.clientstatuswebapiservice
      .updateNCAStatus(this.resolve.clientNumber, this.reason,
        this.resolve.clientMilestoneId, this.substatuscustom,
        this.resolve.clientMilestoneStatusID /*10 Discontinue*/, this.subStatusID)
      .subscribe((data: any) => {
        if (data.isSuccess) {
          location.reload();
        }
        this.progressIndicator.hide();
      });

    this.modalService.close({
      reason: this.reason,
      subStatusID: this.subStatusID
    });
  }
  public cancel() {
    //This will disable the scroll when the pop-up is open
    document.body.classList.remove('no-scroll');
    this.modalService.close();
  }

  textareaOptions: TextareaOptions = {
    placeholder: 'Enter reason',
    minHeight: 100,
    maxLength: 1000,
    maxLengthPosition: 'bottom-right',
    size: Size.md
  };

}
export class IDropDownItemInt {
  ItemID?: number;
  ItemName?: string;
}
