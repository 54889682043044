import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SelectOptions } from '@usitsdasdesign/dds-ng/select';
import {
  ButtonKind,
  ExtThemes,
  Size,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
export const selectOptions: SelectOptions = {
  placeholder: 'Select Reason',
};

export const SHOW_MORE_BTN_OPTIONS: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.silent,
  size: Size.lg,
  width: WidthState.fixed,
  isLoading: false,
  icon: 'dds-icon_arrow-down',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Show Less',
  customClass: '',
  role: 'button',
};
