<div class="dds-modal-overlay" (click)="close()"></div>

<div ddsResize class="dds-modal dds-modal_lg">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Add link</span>
    <dds-button
      [size]="modalCloseButtonOptions.size ?? emptyString"
      [icon]="modalCloseButtonOptions.icon ?? emptyString"
      [kind]="modalCloseButtonOptions.kind ?? emptyString"
      [theme]="modalCloseButtonOptions.theme ?? emptyString"
      [ariaLabel]="modalCloseButtonOptions.ariaLabel ?? emptyString"
      class="dds-modal__close"
      (clicked)="close()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <p
      class="upload-description text-align-left text-black font-bold-400 line-height-sm font-size-14"
    >
      Uploading a file is preferred. In certain circumstances (videos, files
      >100MB, agreement with RM required) providing a hyperlink to an approved
      collaboration site may be permitted.
    </p>

    <div
      class="d-flex font-bold-400 line-height-sm text-cool-gray font-size-14 margin-bottom-l"
    >
      <span class="eclipse-icon icon-s icon-bullet-star-green"></span
      >{{ addLinkGuidanceText }}
    </div>

    <div class="inputs-container">
      <div class="margin-bottom-l">
        <p
          class="input-label text-align-left font-bold-400 line-height-s text-cool-gray font-size-12"
        >
          <span *ngIf="!isLinkValid()"
            ><img src="\assets\images\draftIcon.svg"
          /></span>
          Add link (videos, files > 100MB, agreement with RM required)
        </p>
        <dds-input
          [(ngModel)]="documentLink"
          [placeholder]="'Add document link'"
          (ngModelChange)="isLinkValid()"
        >
        </dds-input>
      </div>

      <div class="margin-bottom-l">
        <p
          class="input-label text-align-left font-bold-400 line-height-s text-cool-gray font-size-12"
        >
          <span *ngIf="documentTitle.length === 0"
            ><img src="\assets\images\draftIcon.svg" /></span
          >File title
        </p>
        <dds-input [(ngModel)]="documentTitle" [placeholder]="'Add file title'">
        </dds-input>
      </div>

      <div class="row margin-bottom-l">
        <div class="col-6">
          <p
            class="input-label text-align-left font-bold-400 line-height-s text-cool-gray font-size-12"
          >
            <span *ngIf="documentType === 0"
              ><img src="\assets\images\draftIcon.svg" /></span
            >Type
          </p>
          <dds-select
            [list]="documentTypeItemsList"
            [(ngModel)]="documentType"
          ></dds-select>
        </div>
        <div class="col-6">
          <p
            class="input-label text-align-left font-bold-400 line-height-s text-cool-gray font-size-12"
          >
            <span *ngIf="documentStatus === 0"
              ><img src="\assets\images\draftIcon.svg" /></span
            >Status
          </p>
          <dds-select
            [list]="documentStatusItemList"
            [(ngModel)]="documentStatus"
          ></dds-select>
        </div>
      </div>

      <div class="comment margin-bottom-l">
        <p
          class="input-label text-align-left font-bold-400 line-height-s text-cool-gray font-size-12"
        >
          <span *ngIf="documentType === otherStatus && !documentComment"
            ><img src="\assets\images\draftIcon.svg" />
          </span>
          <span
            >Comment
            <span *ngIf="documentType != otherStatus && !documentComment"
              >(optional)</span
            ></span
          >
        </p>

        <dds-textarea [(ngModel)]="documentComment" [placeholder]="'Text'">
        </dds-textarea>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item"
        [size]="UI_Element_Size"
        ariaLabel="Save"
        (clicked)="saveAll()"
        (keyboardClicked)="saveAll()"
        [disabled]="!formValid()"
        >Save</dds-button
      >

      <dds-button
        class="dds-modal__footer-item"
        [kind]="secondaryLoudButtonOptions.kind ?? emptyString"
        [size]="secondaryLoudButtonOptions.size ?? emptyString"
        ariaLabel="Close"
        (clicked)="close()"
        (keyboardClicked)="close()"
        >Close</dds-button
      >
    </div>
  </div>
</div>
