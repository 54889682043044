import { Component, Input, Output,EventEmitter } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';

@Component({
  selector: 'app-notify-popup',
  templateUrl: './notify-popup.component.html',
  styleUrl: './notify-popup.component.less'
})
export class NotifyPopupComponent {
  constructor(private modalService:ModalService) { }
  notifyMessage:any;
  @Input() isFooter: boolean=false;
  @Input() size: string="";
  @Input() isInverse: boolean=false;
  @Input() public notifyReason: any="";
  @Input() public isDeletePopup: any=false;
  ngOnInit(): void {
    this.showNotifyMessage();
  }
  showNotifyMessage(){
    if(this.notifyReason=="inValidFileSize"){
    this.notifyMessage="The file selected exceeds our maximum file size of 50MB. Consider compressing the file and trying again";
    }
    else if(this.notifyReason=="inValidFileType"){
      this.notifyMessage="The file selected is not a supported file type. Supported file types: .doc, .docx, .msg, .pdf, .ppt, .pptx, .xls, .xlsb, .xlsm, .xlsx, .zip, .png, .jpeg";
    }
    else if (this.notifyReason == "FileExist") {
      this.notifyMessage = "You can't upload same file";
    }
    else if(this.notifyReason=="inValidFileSelection"){
      this.notifyMessage="Max File Selection 5 Exceed, Please select the files within the limit";
    }
    else if (this.notifyReason == "FileCount_ClientMilestoneDocument") {
      this.notifyMessage = "Max File Selection 32 Exceed, Please select the files within the limit";
    }
    else if (this.notifyReason == "FileNameExceeds150Char") {
      this.notifyMessage = "File Name should be less than 150 char, Please select the files within the limit";
    }
    else if(this.notifyReason=="FileSizeExceeds100MB"){
      this.notifyMessage="System does not support file size greater than 100MB, Please select the files within the limit";
    }
    else if(this.notifyReason=="deleteConfirmation"){    
      this.notifyMessage="Are you sure you want to permanently delete this document?";
    }
    else if (this.notifyReason == "DocumentProtected") {
      this.notifyMessage = "Document rights management is enabled for the document being uploaded, either within the document itself or via Outlook permission controls. Please have protection removed and try againYou can't upload protected file";
    }
  }
  closeModal() {
    this.applyBodyOverflow();
    this.modalService.close();
  }
  onConfirm(){
    this.applyBodyOverflow();
    this.modalService.close("Yes");
  }
  onCancel(){
    this.applyBodyOverflow();
    this.modalService.close("No");
  }
  applyBodyOverflow(){
    //document.body.style.overflow = 'auto';
    document.body.style.overflow = 'hidden';
  }
  openModal() {
    this.modalService.open(NotifyPopupComponent);
  }
  getNotifyPopupStyles(){
    var height='35vh';
    if(this.notifyReason=="FileExist"){
      height='25vh';
    }
    else if(this.notifyReason=="deleteConfirmation" || this.notifyReason=="inValidFileSelection" || this.notifyReason=="FileCount_ClientMilestoneDocument" || this.notifyReason=="FileNameExceeds150Char" || this.notifyReason=="FileSizeExceeds100MB"){ 
      height='28vh';
    }
    else if(this.notifyReason=="inValidFileSize" || this.notifyReason=="inValidFileType"){
      height='30vh';
    }
    return{'height':height};
  }
}
