import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { DropdownItem } from '../../common/services/dropdowns';
import { BehaviorSubject } from 'rxjs';
import { CCFormData, ClientApprovers } from '../../common/models/client-continuance.model';
import { EmployeeView } from '../../common/models/common-models';
import { TeamComments } from '../../common/components/comments/comments.component';
import { OpportunityData } from '../../common/models/start-page/start-page.model';
import { URLConstant } from '../../client/constants/url-constant';

@Injectable({
  providedIn: 'root'
})

export class ClientStatusWebapiService {
  private dropdowncommondata = new BehaviorSubject<any>(null);
  public dropdowncommondata$ = this.dropdowncommondata.asObservable();
  constructor(private httpService: HttpClient) { }

  apiUrl: string = environment.apiUrl;

  
  getDropdownCommonData()
  {
  let dropdown=this.dropdowncommondata.getValue();
    if(dropdown)
    {
      this.dropdowncommondata.next(dropdown);}
      else{
        this.getClientDictionary().subscribe((data)=> {
          this.dropdowncommondata.next(data);
        })
      }
  }
  getClientDictionary(): Observable<Array<DropdownItem>> {
    return this.httpService.get<Array<DropdownItem>>(this.apiUrl + 'NCA/Config/GetProfileCommonData');
  }

  getIRMApproverList(clientNumber: string): Observable<Array<DropdownItem>> {
    return this.httpService.get<Array<DropdownItem>>(this.apiUrl + 'NCA/ClientStatus/GetIRMApproversList?clientNumber=' + clientNumber);
  }

  //TODO: Move to a common service
  getNcaCcConfigurations(): Observable<configurationType[]> {
    return this.httpService.get<configurationType[]>(
      this.apiUrl + 'NCA/Config/GetNcaCcConfigurations'
    );
  }

  getNcaForm(clientMilestoneID: number): Observable<ClientMilestoneNcaProcess> {
    return this.httpService.get<ClientMilestoneNcaProcess>(this.apiUrl + 'NCA/ClientStatus/GetNcaForm?clientMilestoneID=' + clientMilestoneID);
  }

  getCCForm(clientMilestoneID: number): Observable<CCFormData> {
      return this.httpService.get<CCFormData>(this.apiUrl + 'CC/GetCCForm?clientMilestoneId=' + clientMilestoneID);
  }

  updateCCForm(clientMilestoneID: number, model: CCFormData): Observable<any> {
    return this.httpService.post<any>(this.apiUrl + 'CC/UpdateCCForm?clientMilestoneId='+clientMilestoneID, model);
  }
  
  updateCCApprovalComments(clientMilestoneID: number, approvalActionCommentReason: string | null): Observable<any> {
    return this.httpService.post<any>(this.apiUrl + 'CC/UpdateApprovalActionComment?clientMilestoneId='+clientMilestoneID+'&approvalActionCommentReason='+approvalActionCommentReason, null);
  }
  
  public GetNCASpecialists(isFromStartPage: boolean): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
      return this.httpService.get<any>(this.apiUrl + 'NCA/ClientStatus/GetNCASpecialists?isFromStartPage='+isFromStartPage,{headers});
  }

  public AssignNCASpecialist(ncaEmployeeId:string,clientMilestoneId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
      return this.httpService.post<any>(this.apiUrl + 'NCA/ClientStatus/AssignNCASpecialist?ncaEmployeeId='+ncaEmployeeId+'&clientMilestoneId='+clientMilestoneId,{headers});
  }


  updateNCAForm(clientMilestoneID: number, model: ClientMilestoneNcaProcess): Observable<any> {
    return this.httpService.post<any>(this.apiUrl + 'NCA/ClientStatus/UpdateNcaForm?clientMilestoneID=' + clientMilestoneID, model);
  }

  updateApprovalAction(clientMilestoneID: number, model: ApprovalActionUpdate): Observable<any> {
    return this.httpService.post<any>(this.apiUrl + 'NCA/ClientStatus/UpdateApprovalAction?clientMilestoneID=' + clientMilestoneID, model);
  }

  AddNCANotificationTeamMember(clientMilestoneId: number, teammemberID: string): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.httpService.post<any>(this.apiUrl +URLConstant.ADD_NCA_NOTIFICATION_TEAM_MEMBER + clientMilestoneId + URLConstant.CONCAT_ADD_REMOVE_TEAMMEMBER_PARAM + teammemberID, { headers });
    }
  RemoveNCANotificationTeamMember(clientMilestoneId: number, teammemberID: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpService.post<any>(this.apiUrl + URLConstant.REMOVE_NCA_NOTIFICATION_TEAM_MEMBER + clientMilestoneId + URLConstant.CONCAT_ADD_REMOVE_TEAMMEMBER_PARAM + teammemberID, { headers });
  }

  updateNCAStatus(
    clientNumber: string,
    Reason: string | null,
    clientMilestoneId: number,
    clientMilestoneSubStatusCustom: string | null,
    clientMilestoneStatusId: number,
    subStatusID: number | null,
  ): Observable<any> {
    let params = new HttpParams()
      .set('clientNumber', clientNumber)
      .set('clientMilestoneStatusId', clientMilestoneStatusId);
    if (subStatusID !== null) {
      params = params.set('subStatusID', subStatusID);
    }

    if (clientMilestoneSubStatusCustom !== null) {
      params = params.set('clientMilestoneSubStatusCustom', clientMilestoneSubStatusCustom);
    }

    if (clientMilestoneId !== null) {
      params = params.set('clientMilestoneId', clientMilestoneId);
    }
    const body = {
      reason: Reason
    };

    return this.httpService.post(this.apiUrl + 'NCA/ClientStatus/UpdateNCAStatus', body, { params });
  }

  convertNCAToCC(
    clientNumber: string,
    clientMilestoneId: string,
    convertNcaToCcReason: string,
  ): Observable<any> {  
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = new HttpParams()
      .set('clientMilestoneId', clientMilestoneId)
      .set('clientNumber', clientNumber);
    const options = { headers, params };
    return this.httpService.post(this.apiUrl + 'NCA/ClientStatus/ConvertNCAToCC', JSON.stringify(convertNcaToCcReason), options);
  }

  convertCCtoNCA(
    clientMilestoneID: string,
    convertCcToNcaReason:string
  ): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = new HttpParams()
      .set('clientMilestoneID', clientMilestoneID);
    const options = { headers, params };
    return this.httpService.post(this.apiUrl + 'CC/ConvertCCtoNCA', JSON.stringify(convertCcToNcaReason), options);
  }

  queueBotDetails(
    clientNumber: string,
    model: ClientDataForNcaBot,
  ): Observable<any>{
    return this.httpService.post(this.apiUrl + 'NCA/ClientStatus/SetReadyForProcessing?clientNumber=' + clientNumber, model);
  }

  getNCABotQueueDetails(clientNumber: string): Observable<any>{
    return this.httpService.get<string>(this.apiUrl + 'NCA/ClientStatus/GetNCABotQueueDetails?clientNumber=' + clientNumber);
  }

  setNCAResume(
    EmployeeId: string,
    clientNumber: string,
    clientMilestoneId: number,
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpService.post(this.apiUrl + 'NCA/ClientStatus/SetNCAResume?EmployeeId='+EmployeeId+'&clientNumber='+clientNumber+'&clientMilestoneId=' + clientMilestoneId, {headers});
  }


  getClientGeneralInformation(clientNumber: string): Observable<ClientGeneralInformation> {
    return this.httpService.get<ClientGeneralInformation>(this.apiUrl + 'NCA/ClientStatus/GetClientGeneralInformation?clientNumber=' + clientNumber);
  }

  updateClientGeneralInformation(clientNumber: string, model: ClientGeneralInformation): Observable<ClientGeneralInformation> {
    return this.httpService.post<ClientGeneralInformation>(this.apiUrl + 'NCA/ClientStatus/UpdateClientGeneralInformation?clientNumber=' + clientNumber, model);
  }

  clientGenInfoAddRole(clientNumber: string, roleName: string, roleValue: string): Observable<ClientGeneralInformation> {
    const roleDto = { roleName, roleValue };
    return this.httpService.post<ClientGeneralInformation>(this.apiUrl + 'NCA/ClientStatus/ClientGenInfoAddRole?clientNumber=' + clientNumber, roleDto);
  }

  clientGenInfoUpdateRole(clientGenInfoRoleID: number, roleName: string, roleValue: string): Observable<clientInformationRole> {
    const roleDto = { clientGenInfoRoleID, roleName, roleValue };
    return this.httpService.put<clientInformationRole>(this.apiUrl + 'NCA/ClientStatus/ClientGenInfoUpdateRole?clientGenInfoRoleID='+ clientGenInfoRoleID, roleDto);
  }
  clientGenInfoDeleteRole(clientGenInfoRoleID: number, roleName: string, roleValue: string): Observable<clientInformationRole> {
    const roleDto = { clientGenInfoRoleID, roleName, roleValue };
    return this.httpService.post<clientInformationRole>(this.apiUrl + 'NCA/ClientStatus/ClientGenInfoDeleteRole?clientGenInfoRoleID='+ clientGenInfoRoleID, roleDto);
  }

  getClientMilestoneDetails(clientNumber: string,filterDetails:any=null): Observable<Array<ClientMilestone>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if(filterDetails!=null){
      return this.httpService.get<Array<ClientMilestone>>(this.apiUrl + 'NCA/ClientStatus/GetClientMilestoneDetails?clientNumber=' + clientNumber+'&filterOption='+filterDetails[0]?.filtercriteria, { headers });
    }
    else{
    return this.httpService.get<Array<ClientMilestone>>(this.apiUrl + 'NCA/ClientStatus/GetClientMilestoneDetails?clientNumber=' + clientNumber, { headers });
    }
  }
  getClientMilestoneApprovals(clientMilestoneId: number): Observable<ClientMilestoneApproval[]> {
    const url = `${this.apiUrl}NCA/ClientStatus/GetClientMilestoneApprovals?clientMilestoneId=${clientMilestoneId}`;
    return this.httpService.get<ClientMilestoneApproval[]>(url);
  }

  initiateNCAForm(clientNumber: string, clientMilestoneID: number): Observable<any> {
    return this.httpService.post<any>(this.apiUrl + 'NCA/ClientStatus/InitiateNcaForm?clientNumber=' + clientNumber + '&clientMilestoneID=' + clientMilestoneID, null);
    }


  setNCAReset(clientMilestoneId: number, clientNumber: string): Observable<any> {
    let params = new HttpParams()
      .set('clientMilestoneId', clientMilestoneId)
      .set('clientNumber', clientNumber);
    return this.httpService.post<any>(this.apiUrl + 'NCA/ClientStatus/SetNCAReset', params);
  }

  getOpportunityList(clientMilestoneId: number): Observable<Array<OpportunityData>> {
    return this.httpService.get<Array<OpportunityData>>(this.apiUrl + 'NCA/ClientStatus/GetRelatedOpportunities?clientMilestoneId=' + clientMilestoneId);
  }

  setNCARework(clientMilestoneID: number, reworkReason: string): Observable<operationResult> {
    return this.httpService.post<any>(
      this.apiUrl + 'NCA/ClientStatus/SetNCARework?clientMilestoneID=' + clientMilestoneID + '&reworkReason=' + reworkReason, null
    );
  }
  setCCStatusAndSubStatus(request: CCStatusSubStatusRequest): Observable<operationResult> {
    return this.httpService.post<operationResult>(
      `${this.apiUrl}CC/SetCCStatusAndSubStatus`, request
    );
  }

  setMARSStatusAndSubStatus(request: MARSStatusSubStatusRequest): Observable<operationResult> {
    return this.httpService.post<operationResult>(
      `${this.apiUrl}CC/SetCCStatusAndSubStatus`, request
    );
  }

  getComments(clientMilestoneId: number): Observable<Array<TeamComments>> {
    return this.httpService.get<Array<TeamComments>>(this.apiUrl + 'CC/GetComments?clientMilestoneId=' + clientMilestoneId);
  }

  addComment(clientMilestoneId: number, text: string): Observable<Array<TeamComments>> {
    const params = new HttpParams()
    .set('clientMilestoneId', clientMilestoneId);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    
    return this.httpService.post<Array<TeamComments>>(this.apiUrl + 'CC/AddComment', JSON.stringify(text), { headers, params });
  }

  updateComment(commentId: number, text: string): Observable<Array<TeamComments>> {
    const params = new HttpParams()
    .set('commentId', commentId.toString());
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpService.post<Array<TeamComments>>(this.apiUrl + 'CC/UpdateComment', JSON.stringify(text), { headers, params });
  }

  deleteComment(commentId: number): Observable<any> {
    const params = new HttpParams()
    .set('commentId', commentId.toString());
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpService.post<any>(this.apiUrl + 'CC/DeleteComment', null, { headers, params });
  }
  checkNcaProcess(clientNumberOrOpportunityNumber: string): Observable<checkNcaProcessResult> {
    return this.httpService.get<checkNcaProcessResult>(this.apiUrl + 'NCA/ClientStatus/CheckNcaProcess?clientNumberOrOpportunityNumber=' + clientNumberOrOpportunityNumber);
  }

  startNcaProcessManually(clientNumber: string | null, functionToStartNca: number, opportunityNumber: string | null): Observable<operationResult> {
    return this.httpService.post<any>(this.apiUrl + 'NCA/ClientStatus/StartNcaProcessManually' + '?clientNumber=' + clientNumber + '&functionToStartNca=' + functionToStartNca + '&opportunityNumber=' + opportunityNumber, null);
  }

  updateCCApprover(ccapprover : ClientApprovers): Observable<any> { 
    return this.httpService.post<any>(this.apiUrl + 'CC/UpdateCCApprover', ccapprover);
  }

  getClientInformation(clientNumber: string): Observable<ClientInformation> {
    return this.httpService.get<ClientInformation>(this.apiUrl + 'NCA/ClientStatus/GetClientInformation?clientNumber=' + clientNumber);
  }

  searchCcApprovers(approverSearchkey: string): Observable<Array<EmployeeView>> {
    return this.httpService.get<Array<EmployeeView>>(this.apiUrl + 'CC/GetApprovers?approverSearchkey=' + approverSearchkey
    );
  }

  addCCApprovers(ccapprover: string, clientMilestoneId: number): Observable<any> {
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  const params = new HttpParams().set('clientMilestoneId', clientMilestoneId.toString());
  return this.httpService.post<any>(this.apiUrl + 'CC/AddCCApprovers', JSON.stringify(ccapprover), { headers, params });  
  }

  deleteCCApprovers(ccapprover: string, clientMilestoneId: number): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams().set('clientMilestoneId', clientMilestoneId.toString());
    return this.httpService.post<any>(this.apiUrl + 'CC/DeleteCCApprovers', JSON.stringify(ccapprover), { headers, params });
  }

  GetFinanceInfo(clientMileStoneID: number): Observable<CCFinancialInfo> {
    return this.httpService.get<CCFinancialInfo>(this.apiUrl + 'CC/GetFinanceInfo?clientMileStoneID=' + clientMileStoneID);
  }

  UpdateApprovalActionComment(clientmilestoneId: number, clientMilestoneRoleCode : string, comment: string | null): Observable<operationResult> {
    return this.httpService.post<any>(this.apiUrl + 'NCA/ClientStatus/UpdateApprovalActionComment' + '?clientmilestoneId=' + clientmilestoneId + '&clientMilestoneRoleCode='  + clientMilestoneRoleCode + '&comment=' + comment, null);
  }

  convertToSupplementalApproval(clientMilestoneId: number, reason: string): Observable<operationResult> {
    return this.httpService.post<any>(this.apiUrl + 'NCA/ClientStatus/ConvertToSupplementalApproval'+ '?clientMilestoneId=' + clientMilestoneId + '&supplementalApprovalReason=' + reason, null);
  }

}

export interface CCFinancialInfo {
  clientNumber: number
  clientMilestoneID: number
  netRevenue: number
  closedWBSCodeCount: number
  openOpportunityCount: number
  segmentationTypeName: string
  isGlobalCrownJewel: boolean
  isGuidanceDisplay: boolean
  globalProgramName: string
}
export interface ClientDataForNcaBot{
  ClientMilestoneId: number
  Industry: string
  IndustryCode: string
  IndustrySector: string
  IndustrySectorCode: string
  DUNSNumber: string
  RecommendedGroup: number
  IndustrySectorLeader: EmployeeView
}

  export interface ClientMilestoneNcaProcess {
    clientMilestoneId: number;
    groupId: number | null;
    referralSourceCategoryId: number | null;
    referralSourceCategoryExplain: string;
    groupChangeReason: string;
    isProspectiveClientBusiness: string;
    strategicAlignmentComments: string;
    isRegulatoryAgenciesRecordsExists: string;
    isAdverseMediaArticlesExists: string;
    integrityAndReputationalRisksComments: string;
    isClientsFinanciallyViable: string;
    financialViabilityComments: string;
    areAnySanctionsMatchesExist: string;
    areAnyAttestNameMatchesExist: string;
    areAnyBusinessRelationshipMatchesExist: string;
    sanctionsCheckComments: string;
    evaluationSummary: string;
    serveWithConditionsReason: string | null;
    systemRecommendedGroupId?: number;
    ncaTeamRecommendationId: number | null;
    requestedReviewDate: Date | null;
    approverReviewerComments: string | null;
    areAnyTeamMembersForNcaAlertsAdded: string;
    areAnyTeamMembersForNcaAlertsAddedTeam: string | null;
    ncaNotificationTeamMembers: EmployeeView[];
    clientMilestoneSubStatusId: number | null;
    ncaClientMilestoneSubStatusCustom: string | null;
    clientMilestoneSubStatusReason: string | null;
    isRushRequest: string;
    isRushRequestAdditionalComments: string | null;
    isFormCompleted: boolean;
    isHasNotConnectedActiveOpps: boolean;
    isCustomNotRequiredForm: boolean;
    segmentationTypeID: number;
    segmentationTypeName: string | null;
    isAllNcaFormRequiredFieldsFilled: boolean;
    isAllApprovalRequiredFieldsFilled:boolean;
    isAllQRMCommunicationRequiredFieldsFilled:boolean;
    isSupplementalApproval: boolean;
  }

export interface ClientGeneralInformation extends clientInformationRole, operationResult {
  clientNumber: string;
  clientID: number;
  segmentationTypeId: number;
  segmentationTypeCode: string;
  segmentationTypeName: string;
  independenceStatus: string;
  lcsp: string;
  businessDescription: string;
  periodOfTimeInBusiness: string;
  entityPrincipalCompetitors: string;
  entityPrincipalCustomers: string;
  formerClientName: string;
  clientWebSite: string;
  chiefExecutiveOfficer: string;
  chiefFinancialOfficer: string;
  additionalInformation: string;
  additionalRoles: Array<clientInformationRole>;
}

export interface clientInformationRole {
  clientGenInfoRoleID: number;
  roleName: string;
  roleValue: string;

  //only Ui 
  isEditMode: boolean;
  isEdited: boolean;
}

export interface operationResult {
  isSuccess: boolean;
  resultCode?: number | undefined;
  resultText?: string | undefined;
  resultData?: any | undefined;
}
export enum clientAcceptanceHistoryFilterSortParameterCode {
  clientMilestoneID=1,
  acceptanceType=2,
  business=3,
  status=4,
  lastStatusChangeDate=5,
  expirationDate=6,
  watchlistStatus=7,
  clientMilestoneProcessTypeId=8
}
export interface clientAcceptanceHistoryGridLevelFilter {
  filtertypecode: number;
  filtercriteria: string;
}

export interface ClientMilestone {
  clientName: string | null;
  clientNumber: string | null;
  clientMilestoneId: number;
  clientMilestoneStatusId: number | null;
  prevClientMilestoneStatusID: number | null;
  clientMilestoneStatusCode: string;
  clientMilestoneStatusName: string;
  prevClientMilestoneStatusCode: string;
  startsAt: string | null;
  processDate: string | null;
  isCurrent: boolean;
  clientMilestoneProcessTypeId: number;
  ncaClientMilestoneSubStatusId: number;
  ncaClientMilestoneSubStatusCustom: string | null;
  actionButtonsVisibility: ActionButtonsVisibility;
  doesNcaFormExists: boolean;
  marsPrevNotStarted: boolean;
  onHoldReason: string | null;
  notRequiredReason: string | null;
  discontinueReason: string | null;
  expiredReason: string | null;
  notRequiredByDisplayName: string;
  ccClientMilestoneSubStatusId: number;
  ccClientMilestoneSubstatusModifiedBy: string | null;
  ccClientMilestoneSubstatusModifiedDate: Date | null;
  clientMilestoneCCSubStatusName: string | null;
  clientMilestoneCCSubStatusCode: string | null;
  isCovertedToNCA: boolean;
  ccToNCAConvertedBy: string | null;
  ccToNCAConvertedDate: string | null;
  ccToNCAConvertedReason: string | null; 
  ccToNCAConvertedPreTextMsg: string | null;
  lastStatusChangeDate: string | null;
  expirationDate: string | null; // only for CC Clients
  watchlistStatus: string | null;
  acceptanceType: string | null;
  business: string | null;
  isfakeclient: boolean;
  isMarsMilestoneVisible: boolean;
  convertedToSupplementalApprovalPreTextMsg: string | null;
  isSupplementalApproval: boolean;
}

export interface ActionButtonsVisibility {
  isDiscontinueButtonVisible: boolean;
  isResetNCAButtonVisible: boolean;
  isNotRequiredButtonVisible: boolean;
  isInitiateFormButtonVisible: boolean;
  isAssignToVisible: boolean;
  isOnHoldButtonVisible: boolean;
  isResumeButtonVisible: boolean;
  isReadyForProcessingButtonVisible: boolean;
  isReturnForReworkButtonVisible: boolean;
  isConvertNCAToCCButtonVisible: boolean;
  isDiscontinueCCButtonVisible: boolean;
  isResumeCCButtonVisible: boolean;
  isConvertToSupplementalApprovalButtonVisible: boolean;
}

export enum ClientMilestoneStatus {
  NotStarted = 1,
  InProcess = 2,
  OnHold = 3,
  NotRequired = 4,
  Accepted = 5,
  DoNotServe = 6,
  Attest = 7,
  StartsAt = 8,
  PendingApprovals = 9,
  Discontinue = 10,
  CcDeclined = 11,
  CcDueDate = 12,
  MARSDraft = 13,
  ServeWithConditions = 14,
  Expired = 15,
  Declined = 16
}

export interface ApprovalActionUpdate {
  assignedEmployeeId?: string | null;
  clientMilestoneId: number;
  clientMilestoneRoleCode?: string;
  isRequired?: boolean;
  isDecisionCleared: boolean;
  isNCAFormSubmitted: boolean | null;
  approvalActionId: number | null;
  approvalActionComment: string | null;
  isApproverAgreed: boolean | null; // Used for approvers who have Agree action
  isApproverAccepted: boolean | null; // Used for approvers who have Accept/Decline action 
}

export interface ClientMilestoneApproval {
  clientMilestoneRoleCode?: string;
  clientMilestoneRoleName?: string;
  isClientMilestoneRoleCheckBoxDisabled?: boolean;
  isRequired: boolean;
  employeeID?: string | null;
  employee?: EmployeeView;
  processedDate?: string;
  actionText?: string;
  confirmationText?: string;
  isActionEnabled?: boolean;
  isResetEnabled?: boolean;
  approvalActionId: number | null;
  sortOrder?: string;
  isEmployeeListPredefined?: boolean;
  predefinedEmployees?: EmployeeView[];
  inEditMode?: boolean;
  employeeOutlookDisplayName?: string;
  overallDecision?: number | null;
  ncaIrmApprovalActionComment: string |  null;
  ncaIrmApprovalActionCommentModifiedDate?: string;
  leadNCAQRMApprovalActionComment : string | null;
  leadNCAQRMApprovalActionCommentModifiedDate?: string;
  isNcaSpecialist?: boolean;
  declineReason ?: string;
}

export enum ClientMilestoneApprovalRoleCode{
  NCASpecialist = "NCASpecialist",
  ISL = "ISL",
  NCAIRM = "NCAIRM",
  LeadNCAQRM = "LeadNCAQRM"
}

export enum FormType{
  NCA = "New client acceptance",
  CC = "Client continuance",
  MARS = "New client acceptance-MARS"
}

export interface CCStatusSubStatusRequest{
  ClientMilestoneId: number;
  ClientMilestoneStatusId: number;
  ClientMilestoneCCSubStatusId: number | null;
  CCReason: string | null;
}

export interface MARSStatusSubStatusRequest{
  ClientMilestoneId: number;
  ClientMilestoneStatusId: number;
  ClientMilestoneMARSSubStatusId: number | null;
  MARSReason: string | null;
}

export interface checkNcaProcessResult {
  clientNumber: string | null;
  startNcaProcessMessage: string;
  isStartNcaProcessAvailable: boolean;
  opportunityNumber: string | null;
}

export interface ClientInformation {
  dateBecameClientForDeloitteUsFirms: string;
  dateBecameClientForAc: string;
  dateLastCompletedSubmission: string;
  lastInvoiceDateForDeloitteUsFirms: string;
  lastInvoiceForAc: string;
  swiftClientType: string;
  descDesignation: string;
  advantagedClientPortfolio: string;
  lcspName: string;
}

export interface configurationType {
  appConfigurationLabelId: number;
  appConfigurationLabelKey: string;
  appConfigurationLabelValue: string;
  isAdminConfigurable: boolean;
}
