import { Pipe, PipeTransform } from '@angular/core';
import { StatusEnum } from '../../common/constants/status-enum';

@Pipe({
  name: 'statusTranform'
})
export class StatusTranformPipe implements PipeTransform {

  private statusStylesMap: {
      [key in StatusEnum]?: { color: string; backgroundColor: string };
    } = {
      [StatusEnum.NCANeverStarted]: {
        color: '#808080',
        backgroundColor: '#f0f0f0',
      },
      [StatusEnum.NCAInProgress]: {
        color: '#808080',
        backgroundColor: '#f7f7f7',
      },
      [StatusEnum.NCAApprovedWithConditions]: {
        color: '#FFA500',
        backgroundColor: '#fff4e6',
      },
      [StatusEnum.NCAApprovedUpToDate]: {
        color: '#008000',
        backgroundColor: '#e6ffe6',
      },
      [StatusEnum.NCAApprovedPending]: {
        color: '#008000',
        backgroundColor: '#e6ffe6',
      },
      [StatusEnum.ContinuanceExpired]: {
        color: '#FF0000',
        backgroundColor: '#ffe6e6',
      },
      [StatusEnum.DoNotServe]: { color: '#FF0000', backgroundColor: '#ffe6e6' },
      [StatusEnum.NCAOnHold]: { color: '#808080', backgroundColor: '#f0f0f0' },
      [StatusEnum.NCADeFactoAccepted]: {
        color: '#008000',
        backgroundColor: '#e6ffe6',
      },
      [StatusEnum.Pending]: { color: '#FFA500', backgroundColor: '#fff4e6' },
      [StatusEnum.RMReviewed]: { color: '#008000', backgroundColor: '#e6ffe6' },
      [StatusEnum.Draft]: { color: '#63666a', backgroundColor: '#F2F2F2' },
      [StatusEnum.Submitted]: { color: '#007CB0', backgroundColor: '#EEF9FF' },
      [StatusEnum.RM_REVIEWED_PENDING_CLOSEOUT ]: { color: '#008000', backgroundColor: '#e6ffe6' },
      [StatusEnum.SELF_REVIEWED_PENDING_CLOSEOUT ]: { color: '#008000', backgroundColor: '#e6ffe6' },
      [StatusEnum.RETURN_FOR_REWORK]: { color: '#FF0000', backgroundColor: '#ffe6e6' },
      [StatusEnum.RESUBMITTED]: {color: '#FF0000', backgroundColor: '#ffe6e6'},
      [StatusEnum.COMPLETED]: {color: '#63666a', backgroundColor: '#F2F2F2'}
    };
  
    transform(
      status: StatusEnum,
      styleType: 'color' | 'backgroundColor'
    ): string {
      return (
        this.statusStylesMap[status]?.[styleType] ||
        (styleType === 'color' ? '#000000' : 'transparent')
      );
    }

}
