import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environment/environment";
import { ThirdPartyAgreementModel,  Lookup, LookupData, DropDownData } from "../../common/models/third-party-repository/third-party-agreement.model";
import { tpaDocumentModel } from "../../common/models/third-party-repository/tpa-document-model";
import { URLConstant } from "../../client/constants/url-constant";
@Injectable({
  providedIn: 'root'
})

export class TPAWebApiService {
  constructor(private httpService: HttpClient) { }
  private header = new HttpHeaders({ 'content-type': 'application/json' });

  tpaApiUrl: string = environment.apiUrl;

  getThirdPartyAgreementData(thirdPartyAgreementID : any): Observable<ThirdPartyAgreementModel> {    
    return this.httpService.get<ThirdPartyAgreementModel>(this.tpaApiUrl + URLConstant.GET_THIRD_PARTY_AGREEMENT_DATA + thirdPartyAgreementID);
  }

  getTPADocList(thirdPartyAgreementID: any): Observable<tpaDocumentModel> {
    return this.httpService.get<tpaDocumentModel>(this.tpaApiUrl + URLConstant.GET_THIRD_PARTY_AGREEMENT_DOCUMENT_DATA + thirdPartyAgreementID);
  }

  getThirdPartyAgreementLookup(tableName: any = ''): Observable<DropDownData[]> {
    let inputParam = tableName != '' ? "?table=" + tableName : "";
    return this.httpService.get<DropDownData[]>(this.tpaApiUrl + URLConstant.GET_DROPDOWN_DATA + inputParam);
  }

  searchThirdPartyName(searchTerm: string): Observable<any> {
    return this.httpService.get<any>(this.tpaApiUrl + URLConstant.SEARCH_THIRD_PARTY_NAME + searchTerm);
  }

  saveThirdPartyAgreement(data: any): Observable<any> {
    const url = this.tpaApiUrl + URLConstant.SAVE_AND_UPDATE_THIRD_PARTY_AGREEMENT;
    let tpaData = JSON.stringify(data);
    return this.httpService.post(url, tpaData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }

  saveTPADocument(data: any): Observable<any> {
    const url = this.tpaApiUrl + URLConstant.SAVE_AND_UPDATE_TPA_DOCUMENT_META_DATA;
    let tpaData = JSON.stringify(data);
    return this.httpService.post(url, tpaData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }

  deleteThirdPartyAgreementData(thirdPartyAgreementID: any): Observable<boolean> {
    return this.httpService.get<boolean>(this.tpaApiUrl + URLConstant.DELETE_TPA_DOCUMENT_META_DATA + thirdPartyAgreementID);
  }
 
}

export enum LookupTableName {
  AgreementType = "AgreementType",
  AgreementStatus = "AgreementStatus",
  ContractType = "ContractType",
  CoveredDeloitteEntity = "CoveredDeloitteEntity",
  DocumentType = "DocumentType"
}
