<div class="tiles-container">
  <app-page-header>
    <h2 class="title inline-block">My dashboard</h2>
    <span class="seperator inline-block"></span>
    <select
      class="role-dropdown"
      [(ngModel)]="selectedRole"
      (change)="onRoleChange($event)"
      [ngStyle]="{ width: dropdownWidth }"
    >
      <option
        *ngFor="let role of roles"
        [value]="role"
        [ngClass]="{ 'title inline-block font-size-32': role === selectedRole }"
      >
        {{ role }}
      </option>
    </select>
    <div class="pt-2 engage-dropdown" *ngIf="selectedRole === Engagement_Team">
      <dds-dropdown
        [theme]="dropdownOptions.theme ?? ''"
        [kind]="dropdownOptions.kind ?? ''"
        [size]="dropdownOptions.size ?? ''"
        [label]="dropdownOptions.label ?? ''"
        [width]="dropdownOptions.width ?? ''"
        [stickerWidth]="dropdownOptions.stickerWidth ?? 0"
        [stickerPosition]="dropdownStickerPosition"
        (itemSelected)="initiateNewItemSelected($event)"
      >
        <dds-dropdown-item
          *ngFor="let item of dropdownItems"
          [value]="item.value"
          [counter]="item.counter ?? 0"
          [disabled]="item.disabled ?? false"
          >{{ item.heading }}</dds-dropdown-item
        >
      </dds-dropdown>
    </div>
  </app-page-header>

  <app-nca-specialist-dashboard
    *ngIf="selectedRole === Nca_Specialist"
  ></app-nca-specialist-dashboard>
  <app-reviewer-dashboard
    *ngIf="selectedRole === Reviewer"
  ></app-reviewer-dashboard>
  <app-engagement-dashboard
    *ngIf="selectedRole === Engagement_Team"
  ></app-engagement-dashboard>
  <app-riskmanager-dashboard
    *ngIf="selectedRole === Risk_Manager"
  ></app-riskmanager-dashboard>
  <app-footer></app-footer>
</div>
