export enum EngagementTileCode {
  Initiated = 'initiated',
  Submitted = 'submitted',
  Reviewed = 'reviewed',
  Completed = 'completed',
  ExtComm = 'ExternalCommunications',
  GlobalEng = 'GlobalEngagement',
}

export enum RiskManagerTileCode {
  Inprocess = 'InProcess',
  Completed = 'Completed',
  Discontinued = 'Discontinued',
  ExternalCommunications = 'ExternalCommunications',
}
