import { RadioButton } from '@usitsdasdesign/dds-ng/radio';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { DatepickerOptions, DatepickerStates } from '@usitsdasdesign/dds-ng/datepicker';
import { SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { UploaderOptions, UploadFile, UploadFileStatus } from '@usitsdasdesign/dds-ng/uploader';
import { Size, Themes } from '@usitsdasdesign/dds-ng/shared';
export class TPADocSettings {
  public static readonly autoRenewRadioBtn: RadioButton[] = [
    {
      options: {
        label: 'Yes',
        theme: Themes.green
      },
      value: 'true'
    },
    {
      options: {
        label: 'No',
        theme: Themes.green
      },
      value: 'false'
    }];
  public static readonly effectiveDateOptions: DatepickerOptions = {
    size: Size.md,
    placeholder: 'Select date',
    format: 'MM/dd/yyyy',
    minMode: DatepickerStates.day,
    isResponsive: true,
    isManualInput: false
    //,disabledDates: { before: new Date() } // This disables all dates before the current date
  };
  public static readonly terminationDateOptions: DatepickerOptions = {
    size: Size.md,
    placeholder: 'Select date',
    format: 'MM/dd/yyyy',
    minMode: DatepickerStates.day,
    isResponsive: true,
    isManualInput: false
    //,disabledDates: { before: new Date() } // This disables all dates before the current date
  };
  public static readonly documentTypeOptions: SelectOptions = {
    placeholder: "",
    size: Size.md,
    disabled: false
  };
  public static readonly uploadAreaOptions: UploaderOptions = {
    heading: '',
    description: 'DOCX, PPTX, PDF, JPG, PNG or ZIP. Max size of 50MB.',
    dragAndDropText: 'Drag and drop file(s)',
    isDragAndDrop: true,
    isMultiple: false,
    acceptFormats: '',
    acceptExtensions: '',
    restrictedFormats: '',
    restrictedExtensions: '',
    exceptSymbols: '',
    btnLabel: 'Select file(s)',
    chooseFileBtnKind: 'primary',
    isDownloadable: false,
    allowFilesWithoutType: false,
    disabled: false,
    isInverse: false
  };
  public static readonly textTitle: TextareaOptions = {
    placeholder: 'FileName',
    minHeight: 100,
    maxLength: 150,
    maxLengthPosition: 'bottom-right',
    size: Size.md
  };

  public static readonly textDocumentTypeOptions: TextareaOptions = {
    placeholder: 'Enter Text',
    minHeight: 100,
    maxLength: 100,
    maxLengthPosition: 'bottom-right',
    size: Size.md
  };
}
