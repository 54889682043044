<div class="dds-modal-overlay" (click)="closeModal()"></div>

<div ddsResize [ngClass]="{
  'dds-modal_lg': size === 'lg',
  'dds-modal_inverse': isInverse
  }" class="dds-modal">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Attach document(s)</span>
    <dds-button size="sm" icon="dds-icon_close" kind="silent" theme="dark" ariaLabel="Close modal"
                class="dds-modal__close" [isInverse]="isInverse" (clicked)="closeModal()"></dds-button>
  </div>

  <div class="dds-modal__body">
    <dds-uploader class="dds-uploader" [heading]="uploadAreaOptions.heading??''"
                  [description]="uploadAreaOptions.description??''" [dragAndDropText]="uploadAreaOptions.dragAndDropText??''"
                  [isDragAndDrop]="uploadAreaOptions.isDragAndDrop??false" [isMultiple]="uploadAreaOptions.isMultiple??false"
                  [acceptFormats]="uploadAreaOptions.acceptFormats??''"
                  [acceptExtensions]="uploadAreaOptions.acceptExtensions??''"
                  [exceptSymbols]="uploadAreaOptions.exceptSymbols??''" [btnLabel]="uploadAreaOptions.btnLabel??''"
                  [isInverse]="uploadAreaOptions.isInverse??false" [disabled]="uploadAreaOptions.disabled??true"
                  [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind??''"
                  (uploadedItems)="uploadedItems($event)"></dds-uploader>
    <!-- Table logic -->
    <div class="div-headers">
      <div class="row table-header-margin">
        <div *ngFor="let column of DocumentColumns" class="table-column">
          <span class="div-headers-style">{{column.header}}</span>
        </div>
      </div>
      <div class="div-whole-data">
        <!--<span>-->
        <div class="row div-data" *ngFor="let row of documentUploadData">
          <div class="table-column2">
            <span class="div-data-style data-alignment ellipsis float-left"
                  [ddsTooltip]="row.fileName"
                  [tooltipInvokeType]="fileTooltipOptions.tooltipInvokeType ?? ''"
                  [tooltipType]="fileTooltipOptions.tooltipType ?? ''"
                  [tooltipSize]="fileTooltipOptions.tooltipSize  ?? ''"
                  [tooltipPosition]="fileTooltipOptions.tooltipPosition ?? ''"
                  [tooltipIndent]="fileTooltipOptions.tooltipIndent ?? 0"
                  [tooltipHasBeak]="fileTooltipOptions.tooltipHasBeak ?? false"
                  [tooltipTheme]="fileTooltipOptions.tooltipTheme ?? ''" ddsFocus>{{row.fileName}}</span>
          </div>  
          <div class="table-column1">
            <span *ngIf="row.clientMilestoneDocumentTypeID == 0" class="icon-required-yellow mandatory-margin"></span>
          </div>
          <div class="table-column">           
            <dds-select [isResponsive]="selectDocumentTypeOptions.isResponsive??false"
                        [isRequired]="selectDocumentTypeOptions.isRequired??false"
                        [isInverse]="selectDocumentTypeOptions.isInverse??false" [list]="documentTypeItemsList"
                        [label]="selectDocumentTypeOptions.label??''"
                        [labelPosition]="selectDocumentTypeOptions.labelPosition??''"
                        [customClass]="selectDocumentTypeOptions.customClass??''"
                        [placeholder]="selectDocumentTypeOptions.placeholder??''"
                        [size]="selectDocumentTypeOptions.size??''"
                        [stickerWidth]="selectDocumentTypeOptions.stickerWidth??''"
                        (valueChanged)="valueChange(row)" [(ngModel)]="row.clientMilestoneDocumentTypeID">
            </dds-select>
          </div>
          <div class="table-column">
            <dds-input [placeholder]="textareaOptions.placeholder ?? ''"
                       [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
                       [maxLength]="textareaOptions.maxLength ?? 0"
                       [size]="textareaOptions.size ?? ''" [(ngModel)]="row.fileComments">
            </dds-input>

          </div>
          <div class="table-column1">
            <dds-button size="sm" icon="dds-icon_close" kind="silent" theme="dark"
                        ariaLabel="Close modal" class="dds-modal__close margin-top" [isInverse]="isInverse"
                        (clicked)="removeDocument(row)"></dds-button>
          </div>
        </div>
        <!--</span>-->
      </div>
    </div>

  </div>
  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button class="dds-modal__footer-item" (clicked)="onSave()"
                  (keyboardClicked)="onSave()" [disabled]="!isDocumentExists">Save</dds-button>
      <dds-button class="dds-modal__footer-item" kind="secondaryLoud" [isInverse]="isInverse"
                  (clicked)="closeModal()" (keyboardClicked)="closeModal()">Cancel</dds-button>

    </div>
    <div class="dds-modal__footer-content dds-modal__footer-content_right"></div>
  </div>
</div>
