import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuditTrailUrlConstants } from '../../audit-trail/constants/url.constants';
import {
  AuditTrailRequestModel,
  AuditTrailResponseModel,
  CommonAuditTrailResponseModel,
} from '../../common/models/audit-trail.model';
import { UrlConstant } from '../../intake/constants/url.constant';
import { CommonUrlConstant } from '../../common/constants/url-constant';
import { AuditRequestParameter } from '../../common/models/audit-params';
import { ExternalCommUrlConstants } from '../../external-communications/constants/url.constants';

@Injectable({
  providedIn: 'root',
})
export class AuditTrailService {
  constructor(private readonly http: HttpClient) {}
  apiUrl: string = environment.apiUrl; 
  // apiUrl: string = 'https://dapi.eclipse.deloitte.com/api/'; //dev
  //apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa

  readonly #clientIdSource: BehaviorSubject<AuditRequestParameter> =
  new BehaviorSubject<AuditRequestParameter>({
    moduleName: '',
    moduleId: null,
  });
  readonly #auditTrailEnabler: BehaviorSubject<boolean> =
  new BehaviorSubject<boolean>(false);

  currentClientId$: Observable<AuditRequestParameter> =
  this.#clientIdSource.asObservable();

  auditTrailEnabler$: Observable<boolean> =
  this.#auditTrailEnabler.asObservable();
  
  changeClientNumber(request: any) {
    this.#clientIdSource.next(request);
  }
  enableAuditTrail(isValid: boolean): void {
    this.#auditTrailEnabler.next(isValid);
  }
  getAuditTrailTableData(
    opportunityId: string,
    submissionId: number | null = null,
    data: AuditTrailRequestModel
  ): Observable<AuditTrailResponseModel> {
    if (submissionId != null) {
      return this.http.post<AuditTrailResponseModel>(
        this.apiUrl +
          UrlConstant.GET_AUDIT_TRAIL_DATA +
          `${opportunityId}&submissionId=${submissionId?.toString()}`,
        data
      );
    } else {
      return this.http.post<AuditTrailResponseModel>(
        this.apiUrl + UrlConstant.GET_AUDIT_TRAIL_DATA + `${opportunityId}`,
        data
      );
    }
  }
  getAuditTrailDetails(
    moduleId: number | null = null,
    moduleName:string,
    data: AuditTrailRequestModel
  ): Observable<CommonAuditTrailResponseModel> {
    return this.http.post<CommonAuditTrailResponseModel>(
      this.apiUrl +
        CommonUrlConstant.GET_AUDIT_TRAIL_DATA +
        `${moduleId?.toString()}&moduleName=${moduleName?.toString()}`,
      data
    );
  }

  getExtCommAuditTrailTableData(
    submissionId: number,
    data: AuditTrailRequestModel
  ): Observable<any> {
    return this.http.post<any>(
      this.apiUrl +
        ExternalCommUrlConstants.GET_AUDIT_TRAIL_DATA +
        submissionId,
      data
    );
  }
}
