import { Component, Input } from '@angular/core';
import { ExternalCommunicationConstant } from '../../constants/external_communication.constant';
import { ButtonKind } from '@usitsdasdesign/dds-ng/shared';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ExternalCommunicationService } from '../../../http/external-communication/external-communication.service';
import { ExtCommUpdateReviewStatusRequestBodyModel } from '../../../common/models/external-communication.model';
import { Reviewers } from '../../../common/models/external-communication-submission-details-common-section.model';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-confirm-submission-popup-modal',
  templateUrl: './confirm-submission-popup-modal.component.html',
  styleUrl: './confirm-submission-popup-modal.component.less',
})
export class ConfirmSubmissionPopupModalComponent {
  @Input() submissionID: string = ExternalCommunicationConstant.EMPTY_SPACE;
  @Input() rowDetails!: Reviewers;
  _blank = ExternalCommunicationConstant.EMPTY_SPACE;
  UI_Element_Size = ExternalCommunicationConstant.UI_ELEMENT_SIZE;
  secondaryLoudButtonOptions = ButtonKind.secondaryLoud;
  modalCloseButtonOptions =
    ExternalCommunicationConstant.EXT_COMM_MODAL_CLOSE_BTN;
  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  constructor(
    public readonly modalService: ModalService,
    public readonly externalCommunicationSubmissionService: ExternalCommunicationService,
    public readonly progressIndicatorService: ProgressIndicatorService
  ) {}

  closeConfirmModal(): void {
    this.modalService.close(false);
  }

  confirmSubmission(): void {
    this.modalService.close(true);
  }
}
