import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { UrlConstant } from '../../intake/constants/url.constant';
import { IntakeOppertunitySubmissionStatus } from '../../intake/constants/intake_oppertunity_submission_status.enum';
import { IntakeAction } from '../../common/models/intake-action.model';
import { OppertunitySubmissionStatusCode } from '../../common/models/oppertunity-submission-statusCode.model';
import {
  ClientAcceptanceStatusAction,
  MarsClientExport,
} from '../../common/models/client-acceptance-status-action.model';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import { CommonUrlConstant } from '../../common/constants/url-constant';
import { environment } from '../../../environment/environment';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  readonly #resetEnabler: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly #openResetStatusModal: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  resetEnabler$ = this.#resetEnabler.asObservable();
  resetStatusModal$ = this.#openResetStatusModal.asObservable();

  readonly #openResetStatusModalForExtComm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  resetStatusModalForExtComm$ = this.#openResetStatusModalForExtComm.asObservable();

  readonly #auditTrailEnabler: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private readonly httpClient: HttpClient) {}
  readonly #discontinueAllowedSubmissionStatusSubject: BehaviorSubject<
    IntakeOppertunitySubmissionStatus[]
  > = new BehaviorSubject<IntakeOppertunitySubmissionStatus[]>([
    IntakeOppertunitySubmissionStatus.DRAFT,
    IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK,
    IntakeOppertunitySubmissionStatus.SUBMITTED,
    IntakeOppertunitySubmissionStatus.RESUBMITTED,
  ]);
  discontinueAllowedSubmissionStatus$: Observable<
    IntakeOppertunitySubmissionStatus[]
  > = this.#discontinueAllowedSubmissionStatusSubject.asObservable();

  readonly #intakeActionSubject: BehaviorSubject<IntakeAction[] | null> =
    new BehaviorSubject<IntakeAction[] | null>(null);
  intakeActionObserable$: Observable<IntakeAction[] | null> =
    this.#intakeActionSubject.asObservable();
  readonly #intakeDocSubmissionStatusSubject: BehaviorSubject<
    OppertunitySubmissionStatusCode[] | null
  > = new BehaviorSubject<OppertunitySubmissionStatusCode[] | null>(null);
  intakeDocSubmissionStatusObserable$: Observable<
    OppertunitySubmissionStatusCode[] | null
  > = this.#intakeDocSubmissionStatusSubject.asObservable();

  readonly #clientMilestoneActionSubject: BehaviorSubject<ClientAcceptanceStatusAction | null> =
    new BehaviorSubject<ClientAcceptanceStatusAction | null>(null);
  clientMilestoneActionObservable$: Observable<ClientAcceptanceStatusAction | null> =
    this.#clientMilestoneActionSubject.asObservable();

  readonly #MarsClientActionSubject: BehaviorSubject<MarsClientExport | null> =
    new BehaviorSubject<MarsClientExport | null>(null);
  MarsClientActionObservable$: Observable<MarsClientExport | null> =
    this.#MarsClientActionSubject.asObservable();

  private dashboardSelectedSubject = new BehaviorSubject<string | null>(null);
  public dashboardSelected$: Observable<string | null> =
    this.dashboardSelectedSubject.asObservable();
  
  private clientIdSubject = new BehaviorSubject<number | null>(null);
  public clientId$: Observable<number | null> = this.clientIdSubject.asObservable();

  auditTrailEnabler$: Observable<boolean> =
    this.#auditTrailEnabler.asObservable();

  apiUrl: string = environment.apiUrl;
  // apiUrl: string = 'https://dapi.eclipse.deloitte.com/api/'; //dev
  // apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa

  setClientId(clientId: number | null): void {
    this.clientIdSubject.next(clientId);
  }

  getOppertunityStatusCode(): Observable<OppertunitySubmissionStatusCode[]> {
    return this.httpClient.get<OppertunitySubmissionStatusCode[]>(
      UrlConstant.GET_INTAKE_OPPERTUNITY_STATUS
    );
  }

  setIntakeOppertunityDocStatusCode(
    intakeActions: OppertunitySubmissionStatusCode[]
  ): void {
    this.#intakeDocSubmissionStatusSubject.next(intakeActions);
  }

  getIntakeActions(): Observable<IntakeAction[]> {
    return this.httpClient.get<IntakeAction[]>(UrlConstant.GET_INTAKE_ACTIONS);
  }

  setIntakeActions(intakeActions: IntakeAction[]): void {
    this.#intakeActionSubject.next(intakeActions);
  }

  enableResetStatus(isValid: boolean): void {
    this.#resetEnabler.next(isValid);
  }

  openResetStatusModal(isValid: boolean): void {
    this.#openResetStatusModal.next(isValid);
  }

  openResetStatusModalForExtComm(isValid: boolean): void {
    this.#openResetStatusModalForExtComm.next(isValid);
  }

  setClientMilestoneActions(action: ClientAcceptanceStatusAction | null): void {
    this.#clientMilestoneActionSubject.next(action);
  }
  setMarsClientActions(action: MarsClientExport | null): void {
    this.#MarsClientActionSubject.next(action);
  }

  setSelectedDashboard(dashboard: string): void {
    this.dashboardSelectedSubject.next(dashboard);
  }

  enableAuditTrail(isValid: boolean): void {
    this.#auditTrailEnabler.next(isValid);
  }

  fetchEmployeesGeneral(
      searchItem: string,
      pageNumber: number,
      pageSize: number = IntakeConstant.PAGE_NUMBER_10
    ): Observable<any[]> {
      return this.httpClient.get<any[]>(
        this.apiUrl +
        CommonUrlConstant.GET_EMPLOYEES_LIST +
          searchItem +
          '&pageSize=' +
          pageSize +
          '&pageNumber='+
          pageNumber 
      );
    }
}
