<dds-header
  [name]="headerOptions.name ?? ''"
  [isResponsive]="headerOptions.isResponsive ?? true"
  [isInverse]="headerOptions.isInverse ?? false"
  [responsivePriority]="responsivePriority"
>
  <dds-logo logo></dds-logo>
  <ng-container navigation>
    <dds-search
    search
    [placeholder]="searchOptions.placeholder ?? ''"
    class="customSearch"
  >
  </dds-search>
  </ng-container>
 

  <ng-container icons>
    <dds-dropdown
      class="custom-dropdown"
      [theme]="headerButtonOptions.theme ?? ''"
      [icon]="dropdownOptions.icon ?? ''"
      [kind]="dropdownOptions.kind ?? ''"
      [size]="dropdownOptions.size ?? ''"
      [label]="dropdownOptions.label ?? ''"
      [width]="dropdownOptions.width ?? ''"
      [isInverse]="dropdownOptions.isInverse ?? false"
      [isColorBg]="dropdownOptions.isColorBg ?? false"
      [disabled]="dropdownOptions.disabled ?? false"
      [isResponsive]="dropdownOptions.isResponsive ?? false"
      [stickerWidth]="dropdownOptions.stickerWidth ?? ''"
      [stickerIsOutsideClick]="dropdownOptions.stickerIsOutsideClick ?? false"
      [stickerIndent]="dropdownOptions.stickerIndent ?? 0"
      [stickerCustomClass]="dropdownOptions.stickerCustomClass ?? ''"
      [stickerIsDynamic]="dropdownOptions.stickerIsDynamic ?? false"
      (itemSelected)="itemSelected($event)"
    >
      <!-- ToDO:- Need to fix aligment of the button and show dropdown sticker on bottom right -->

      <dds-dropdown-item
        *ngFor="let item of dropdownItems"
        [value]="item.value"
        >{{ item.heading }}</dds-dropdown-item
      >
    </dds-dropdown>

    <dds-dropdown
      class="custom-dropdown"
      [theme]="headerButtonOptions.theme ?? ''"
      [icon]="'dds-icon_setting__l__stroke'"
      [kind]="dropdownOptions.kind ?? ''"
      [size]="dropdownOptions.size ?? ''"
      [label]="dropdownOptions.label ?? ''"
      [width]="dropdownOptions.width ?? ''"
      [isInverse]="dropdownOptions.isInverse ?? false"
      [isColorBg]="dropdownOptions.isColorBg ?? false"
      [disabled]="dropdownOptions.disabled ?? false"
      [isResponsive]="dropdownOptions.isResponsive ?? false"
      [stickerWidth]="dropdownOptions.stickerWidth ?? ''"
      [stickerIsOutsideClick]="dropdownOptions.stickerIsOutsideClick ?? false"
      [stickerIndent]="dropdownOptions.stickerIndent ?? 0"
      [stickerCustomClass]="dropdownOptions.stickerCustomClass ?? ''"
      [stickerIsDynamic]="dropdownOptions.stickerIsDynamic ?? false"
      (itemSelected)="additionaloptionSelected($event)"
    >
      <!-- ToDO:- Need to fix aligment of the button and show dropdown sticker on bottom right -->

      <dds-dropdown-item
        *ngFor="let item of additionalOptions"
        [value]="item.value"
        >{{ item.heading }}</dds-dropdown-item
      >
    </dds-dropdown>
    <dds-button
      [theme]="headerButtonOptions.theme ?? ''"
      hostRole="menuitem"
      class="customHeaderButton"
    >
      <img src="../../assets/images/guidance icon.svg" />
    </dds-button>
  </ng-container>

  <ng-container profile>
    <dds-profile
      [username]="profileOptions.username ?? ''"
      [photoUrl]="profileOptions.photoUrl ?? ''"
      [userInfo]="userRoles"
      role="menuitem"
    >
    
      <dds-profile-item #logout>Log out
       </dds-profile-item>
    </dds-profile>
  </ng-container>
</dds-header>
