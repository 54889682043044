<div *ngIf="isRequestedReviewDateVisible || isRequestedReviewDateEnable">
  <span>Requested review date</span>
  <form [formGroup]="dateForm" >
    <div #datepicker class="datePicker font-size-12 margin-top-xxs" *ngIf="isRequestedReviewDateEnable">
      <dds-datepicker
        id="requestedReviewDate-datepicker"
        [size]="htmlElementSize"
        [placeholder]="datePickerOptions.placeholder ?? ''"
        [format]="datePickerOptions.format ?? ''"
        [minMode]="datePickerOptions.minMode ?? ''"
        [disabledDates]="datePickerOptions.disabledDates ?? {}"
        [isResponsive]="datePickerOptions.isResponsive ?? false"
        [isManualInput]="datePickerOptions.isManualInput ?? false"
        formControlName="selectedDateCtrl"
      >
      </dds-datepicker>      
    </div>
  </form>
  <ng-container *ngIf="!isRequestedReviewDateEnable"> {{dateForm.get('selectedDateCtrl')?.value | date: 'MM/dd/yyyy'}}</ng-container>
  <span class="datePickerMsg">in {{ businessDays }} business day(s)</span>
</div>
