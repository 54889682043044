<div class="select-opportunity-text-label eclipse-section-header">
  Enter the opportunity ID of the original EL/SOW or prior Change Order
</div>
<div class="select-opportunity-section">
  <div class="change-order-no-review">
    <div *ngIf="this.changeOrderList.length === 0">
      <div class="no-review-search">
        <div class="search-box">
          <dds-search
            [size]="searchOptions.size ?? ''"
            [customClass]="searchOptions.customClass ?? ''"
            [icon]="searchOptions.icon || 'dds-icon_search'"
            (fieldCleared)="fieldCleared()"
            [disabled]="notAvailable || isFormDisabled"
            [size]="uiElementSize"
          >
            <input
              ddsSearch
              [placeholder]="searchOptions.placeholder ?? ''"
              [(ngModel)]="selectedOportunity"
              (keyup)="selectOpportunityChanged()"
              [disabled]="notAvailable || isFormDisabled"
              [size]="uiElementSize"
            />
          </dds-search>
        </div>
        <dds-checkbox
          [value]="notAvailable"
          [(ngModel)]="notAvailable"
          [disabled]="isFormDisabled"
          theme="green"
          class="checkbox-custom"
        >
        </dds-checkbox>
        <span class="eclipse-header not-available-header">Not Available</span>
        <!-- ToDO: to be made common for initiateSubmission -->
        <!-- <span
          class="icon-tooltip"
          [ddsTooltip]="gethangeOrderTooltip()"
          [tooltipWidth]="450"
          >i</span
        > -->
      </div>
      <div
        *ngIf="showDropdown || notFound"
        class="list-of-oppportunities"
        (scroll)="fetchOpportunitySearchResultMore($event)"
      >
        <div *ngIf="!notFound" class="tabs-container">
          <dds-tabs
            [theme]="tabContainerOptions.theme ?? 'primary'"
            [themeType]="tabContainerOptions.themeType ?? 'primary'"
            [size]="tabContainerOptions.size ?? 'md'"
            [isResponsive]="tabContainerOptions.isResponsive ?? true"
            [disabled]="tabContainerOptions.disabled ?? false"
            [ariaLabel]="tabContainerOptions.ariaLabel ?? 'Tabs'"
            [activeIndex]="selectTabIndex"
            class="text-align-left"
          >
            <span *ngFor="let tab of tabs">
              <dds-tab
                class="padding-right-xs"
                [label]="tab.label ?? ''"
                [counter]="tab.count ?? 0"
                (tabChanged)="tabChanged(tab.code)"
              >
              </dds-tab>
            </span>
          </dds-tabs>
        </div>
        <div *ngFor="let item of opportunityList">
          <div
            class="opportunity-items"
            (click)="fetchOpportunityDetails(item)"
          >
            <div class="opportunity-header">{{ item?.opportunityName }}
              <span class="client-status font-bold-400" *ngIf="item?.stage == 'Closed Lost'">[Lost]</span>
              <span class="client-status font-bold-400" *ngIf="item?.stage == 'Closed Abandoned'">[Abandoned]</span>
            </div>
            <div class="opportunity-details">
              <span class="detail-heading font-size-12">ID: </span>
              <span class="details-values font-size-12">{{
                item?.opportunityNumber
              }}</span>
              <span class="detail-heading font-size-12"
                >| Opportunity stage:
              </span>
              <span class="details-values font-size-12">{{ item?.stage }}</span>
              <span class="detail-heading font-size-12">| Leader: </span>
              <span class="details-values font-size-12">{{
                item?.leader
              }}</span>
              <span class="detail-heading font-size-12"> | Client: </span>
              <span class="details-values font-size-12">{{
                item?.client
              }}</span>
            </div>
          </div>
        </div>
        <div [innerHTML]="notFound" class="not-found"></div>
      </div>
    </div>
  </div>
  <div *ngIf="this.changeOrderList.length > 0" class="change-order-details">
    <table class="dds-data-table dds-data-table_fixed-header">
      <thead class="dds-data-table__header">
        <tr>
          <th
            *ngFor="let column of opportunityTableColumns"
            class="dds-data-table__header-cell"
            [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
          >
            <div>
              <span>{{ column.header }}</span>
            </div>
            <div class="dds-data-table__content">
              <span> </span>
            </div>
          </th>
        </tr>
      </thead>

      <tbody class="dds-data-table__body">
        <tr
          *ngFor="let row of changeOrderList; index as index"
          class="dds-data-table__row"
        >
          <td *ngFor="let column of opportunityTableColumns">
            {{ row[column.name] }}
          </td>
          <td>
            <img
              class="remove-button"
              (click)="removeOpportunity()"
              src="assets/images/crossAction.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    *ngIf="showWarningMessage"
    class="container margin-top-s margin-bottom-s d-flex relative flex-column"
  >
    <div class="d-flex">
      <div class="icon-alert-logo-container">
        <div class="icon-alert-logo"></div>
      </div>
      <div class="d-flex flex-column">
        <div class="initial-text font-bold-600">
          <span>{{ coOppDonotMatchMessage }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
