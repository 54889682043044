import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  HostListener,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { copyToClipboard } from '../../../intake/common/intake.util';
import {
  datePickerOptions,
  tabContainerOptions,
  tabOptionsOne,
  tabOptionsTwo,
  tabOptionsThree,
  tabOptionsFour,
  tabOptionsFive,
  tabOptionsSeven,
  tabRisk,
  YES_NO,
  SUBMISSION_STATUS_ALLOW_FOR_WORKFLOW_RETURN_FOR_SELF_REVIEW,
  ROLES_ALLOW_FOR_WORKFLOW_RETURN_FOR_SELF_REVIEW,
  ROLES_ALLOW_FOR_RETURN_TO_REWORK,
} from './external-communication-submission.helper';
import { ExternalCommunicationService } from '../../../http/external-communication/external-communication.service';
import {
  primaryButton,
  PrimaryButtonOptions,
  radioBtns,
  radioGroupOptions,
  SHOW_MORE_BTN_OPTIONS,
  ToastMessageOptions,
  toastMessageTitle,
} from '../../external-communications.helper';
import { IntakeConstant } from '../../../intake/constants/intake.constant';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { Subject, takeUntil } from 'rxjs';
import {
  ExtCommSubmissionDetailsModel,
  ExtCommUpdateReviewStatusRequestBodyModel,
  GenericResponse,
  IndustrySectorModel,
  InitiateExternalCommunicationDropdownModel,
  InitiateExternalCommunicationRequestModel,
  MarketOfferingModel,
  OfferingModel,
  OfferingPortfolioModel,
  SubmissionActionHistoryModelResetStatus,
  updatePpmdStatusRequestModel,
  UpdateSubmission,
} from '../../../common/models/external-communication.model';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import { ExternalCommunicationConstant } from '../../constants/external_communication.constant';
import {
  getStatusEnumKeyByValue,
  StatusEnum,
  StatusEnumIdMap,
} from '../../constants/status-enum';
import { BrowserTabConstant } from '../../../common/constants/browser-tab-constant';
import {
  configurationType,
  PermissionsObj,
} from '../../../common/models/common-models';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonHelperService } from '../../../common/services/common-helper.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { OptOutPopupComponent } from '../../../intake/intake-common-popups/opt-out-popup/opt-out-popup.component';
import { IntakeOptOutPopUpDataModel } from '../../../common/models/initiateSubmission.model';
import { CommonService } from '../../../http/intake/common.service';
import { ExtCommResetSubmissionStatusComponent } from './ext-comm-reset-submission-status/ext-comm-reset-submission-status.component';
import { SelfReviewPopUpComponent } from './self-review-pop-up/self-review-pop-up.component';
import { ExternalCommSubmissionStatus } from '../../constants/submission-status.enum';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { DiscontinuePopupComponent } from './discontinue-popup/discontinue-popup.component';
import { SubmissionActionHistoryModel } from '../../../common/models/submission-action.model';
import { Reviewers } from '../../../common/models/external-communication-submission-details-common-section.model';
import { UserService } from '../../../http/user.service';
import { RoleEnum } from '../../../intake/constants/Role.enum';
import { RoleService } from '../../../http/intake/role.service';
import { ConfirmSubmissionPopupModalComponent } from '../confirm-submission-popup-modal/confirm-submission-popup-modal.component';
import { ExtCommDocSelectionPopupComponent } from './ext-comm-doc-selection-popup/ext-comm-doc-selection-popup.component';
import { ExternalCommunicationDocumentsComponent } from './external-communication-documents/external-communication-documents.component';
import { ExternalCommunicationWorkflowSubmissionComponent } from './external-communication-workflow-submission/external-communication-workflow-submission.component';
import { AdminCompletePopupComponent } from './admin-complete-popup/admin-complete-popup.component';
import { EclipseHeaderService } from '../../../http/eclipse-header.service';
import { SubmissionService } from '../../../http/intake/submission.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-external-communication-submission',
  templateUrl: './external-communication-submission.component.html',
  styleUrls: ['./external-communication-submission.component.less'],
})
export class ExternalCommunicationSubmissionComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() submissionId: number = 0;
  @Input() communicationType!: string;
  @Input() communicationTypeId!: number;
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;

  @Output() submissionTitleChange: EventEmitter<string | null> =
    new EventEmitter<string | null>();
  @Output() allRequiredFieldsFilled: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() updateLeftNav: EventEmitter<UpdateSubmission> =
    new EventEmitter<UpdateSubmission>();

  @ViewChild(ExternalCommunicationDocumentsComponent)
  documentComponent!: ExternalCommunicationDocumentsComponent;
  @ViewChild(ExternalCommunicationWorkflowSubmissionComponent)
  workflowComponent!: ExternalCommunicationWorkflowSubmissionComponent;
  emptySpace: string = IntakeConstant.EMPTY_SPACE;
  titleValue: string = 'External Communication Submission';
  submissionTypeCode: string = 'EXT COMM';
  submissionStatus: string = 'Submitted';
  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  isVisible: boolean = true;
  tabContainerOptions = tabContainerOptions;
  tabOptionsOne = tabOptionsOne;
  tabOptionsTwo = tabOptionsTwo;
  tabOptionsThree = tabOptionsThree;
  tabOptionsFour = tabOptionsFour;
  tabOptionsFive = tabOptionsFive;
  tabOptionsSix = tabRisk;
  tabOptionsSeven = tabOptionsSeven;
  primaryLoudButton = primaryButton;
  primaryButton = PrimaryButtonOptions;
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  yesValue = IntakeConstant.YES;
  noValue = IntakeConstant.NO;
  _blank = IntakeConstant.EMPTY_SPACE;
  submissionDetailModel!: ExtCommSubmissionDetailsModel;
  radioBtns = radioBtns;
  radioGroupOptions = radioGroupOptions;
  communicationTypeList: SelectItemOptions[] = [];
  industrySectorList: SelectItemOptions[] = [];
  marketOfferingList: SelectItemOptions[] = [];
  offeringList: SelectItemOptions[] = [];
  offeringPortfolioList: SelectItemOptions[] = [];
  isEditingType: boolean = false;
  public savedetails!: Saver | null;
  detailsRequestBody: InitiateExternalCommunicationRequestModel =
    {} as InitiateExternalCommunicationRequestModel;
  primaryPeople: string | null = null;
  primaryPeopleId: number | null = null;
  secondaryPeople: string = this._blank;
  secondaryPeopleId: number | null = null;
  marketContact: string = this._blank;
  marketContactId: number | null = null;
  isDCF: string | null = null;
  isGPS: string | null = null;
  datePickerOptions = datePickerOptions;
  isGPSDisabled: boolean = false;
  isDCFDisabled: boolean = false;
  governmentPublicServices: number[] = [41, 42, 43, 44];
  corporateFinance: string = 'CORPORATE FINANCE';
  textareaOptions: any =
    ExternalCommunicationConstant.TEXT_AREA_SETTINGS_GLOBAL;
  enter_text_placeholder = ExternalCommunicationConstant.ENTER_TEXT_PLACEHOLDER;
  internalQrmCommunicationTextareaMaxlength: number =
    ExternalCommunicationConstant.TEXTAREA_CHAR_LIMIT_BIGGEST;
  showRiskAssessment: boolean = true;
  ppmdName!: string;
  selectedTab!: string;
  yesNo = YES_NO;
  toastMessageOptions: ToastOptions = ToastMessageOptions;
  interviewWithMediaCommunicationTypeId: number = 9;
  haveAllMandatoryReviewers: boolean = false;

  businessdaysSelected!: number;
  showRushRequestHeading: boolean = false;
  riskAssessmentRequiredFieldsFilled: boolean = false;
  communicationAttributesRequiredFieldsFilled: boolean = false;
  showRespPractitionerConfirmationMessage: boolean = false;
  ppmdAcknowledgedBy: string | null = ExternalCommunicationConstant.EMPTY_SPACE;
  ppmdAcknowledgedDate: string | null =
    ExternalCommunicationConstant.EMPTY_SPACE;
  ppmdReviewedBy: string | null = ExternalCommunicationConstant.EMPTY_SPACE;
  dateFormat: string = ExternalCommunicationConstant.DATE_FORMAT_US;

  #previousSubmitButtonState: boolean = false;
  isSubmitButtonEnabled: boolean = false;
  isSubmitButtonVisible: boolean = false;
  isOptOutSelfReviewButtonVisible: boolean = false;
  isValidDocumentUploaded: boolean = false;
  isReturnToSelfReviewButtonVisible: boolean = false;
  submissionTypeStatus: string | null =
    ExternalCommunicationConstant.EMPTY_SPACE;
  submissionActionHistory: SubmissionActionHistoryModelResetStatus[] = [];
  isSelfReviewButtonVisible: boolean = false;
  prevDcfValue: string = ExternalCommunicationConstant.EMPTY_SPACE;
  isDCFValueChanged: boolean = false;
  showDiscontinueMessage: boolean = false;
  discontinueActionMessage?: SubmissionActionHistoryModel;
  showAdminCompleteMessage: boolean = false;
  adminCompleteActionMessage?: SubmissionActionHistoryModel;
  isShowMoreDocumentNotes: boolean = false;
  showMoreOptions: any = SHOW_MORE_BTN_OPTIONS;
  private timeoutId: ReturnType<typeof setTimeout> | null = null;
  showMoreText: string = IntakeConstant.SHOW_MORE_TEXT;

  currentLoggedInUser: string = ExternalCommunicationConstant.EMPTY_SPACE;
  loggedInUserRoles!: string[];
  updatedRiskManagerDetails: Reviewers[] = [];
  isPrescreenButtonVisible: boolean = false;
  isCompleteReviewButtonVisible: boolean = false;
  isReturnToReworkBtnVisible: boolean = false;
  SUBMISSION_STATUS_ALLOW_FOR_WORKFLOW_RETURN_FOR_SELF_REVIEW: string[] =
    SUBMISSION_STATUS_ALLOW_FOR_WORKFLOW_RETURN_FOR_SELF_REVIEW;
  ROLES_ALLOW_FOR_WORKFLOW_RETURN_FOR_SELF_REVIEW: string[] =
    ROLES_ALLOW_FOR_WORKFLOW_RETURN_FOR_SELF_REVIEW;
  isConfirmButtonVisible: boolean = false;
  isPrescreened: boolean = false;
  isRequiredReviewerCompleted: boolean = false;
  ROLES_ALLOW_FOR_RETURN_TO_REWORK: string[] = ROLES_ALLOW_FOR_RETURN_TO_REWORK;
  isWorkFlowTableReworkBtnVisible: boolean = false;
  isWorkflowPractitionerCompleteMsg: boolean = false;
  isResubmitButtonEnabled: boolean = false;

  configData: configurationType[] = [];
  configLableDescription: string = IntakeConstant.EMPTY_SPACE;
  completeReviewBtnLabel: String =
    ExternalCommunicationConstant.COMPLETE_REVIEW;

  constructor(
    private readonly externalCommService: ExternalCommunicationService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly autosaverService: AutosaverService,
    private readonly toastService: ToastService,
    private readonly commonHelperService: CommonHelperService,
    private readonly modalService: ModalService,
    private readonly commonService: CommonService,
    private readonly userService: UserService,
    private readonly roleService: RoleService,
    private readonly headerService: EclipseHeaderService,
    private readonly intakeDetailService: SubmissionService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.commonService.enableAuditTrail(true);
    document.title = BrowserTabConstant.Browser_TabName_ExternalCommunications;
    this.getCommunicationType();
    this.getIndustrySector();
    this.getOfferingPortfolio();
    this.getLabelConfiguration();

    // TODO: If data doesn't populate correctly, revert the changes
    // setTimeout(() => {
    //   this.getSubmissionDetails(this.submissionId);
    // }, 3000);

    let autoSaverOptions = {
      delay: 500,
      onSuccess: (
        saver: any,
        result: InitiateExternalCommunicationRequestModel
      ) => {},
    } as IAutosaverOptions;

    this.savedetails = this.autosaverService.newSaver(
      'savedetails',
      this,
      async () => this.#saveDetailsValues(),
      autoSaverOptions
    );
    this.savedetails.start();
    this.externalCommService.communicationType$.subscribe((type) => {
      this.showRiskAssessment =
        type !== ExternalCommunicationConstant.COMMUNICATION_TYPE_ID;
    });

    this.externalCommService.docIsValid$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((status) => {
        this.isValidDocumentUploaded = status;
        this.checkButtonVisibility();
        this.checkAllButtonsEnableState();
      });

    this.commonService.resetStatusModal$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((status: boolean) => {
        if (status) {
          this.openModalForResetStatus();
        }
      });

    this.externalCommService.adminCompleteModal$.subscribe(
      (status: boolean) => {
        if (status) {
          this.openModaladminComplete();
        }
      }
    );

    this.externalCommService.discontinueCloseModal$.subscribe((status) => {
      if (status) {
        this.openModalForDiscontinue();
      }
    });

    this.userService.currentLoggedInUser$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((user: any) => {
        this.currentLoggedInUser = user?.employeeId;
      });

    this.roleService.currentUserRole$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: string[]) => {
          this.loggedInUserRoles = response;
        },
      });

    this.externalCommService.setCurrentPage(
      ExternalCommunicationConstant.EXTCOMMSUBMISSIONLEVELPAGE
    );
    // this.externalCommService.setExtCommSubmissionId(this.submissionId);
    // this.externalCommService.changeSubmissionId(this.submissionId); // TODO: If data doesn't populate correctly, revert the changes
  }

  ngOnChanges(changes: SimpleChanges): void {
    // TODO: If data doesn't populate correctly, revert the changes
    // if (changes['submissionId'] && !changes['submissionId'].isFirstChange()) {
    //   this.externalCommService.changeSubmissionId(this.submissionId);
    //   this.getSubmissionDetails(changes['submissionId'].currentValue);
    //   this.scrollToTop();
    // }

    const changesSubmissionId = changes['submissionId'];
    if (changesSubmissionId?.currentValue) {
      this.externalCommService.changeSubmissionId(this.submissionId);
      this.externalCommService.setExtCommSubmissionId(this.submissionId);
      this.getSubmissionDetails(changesSubmissionId.currentValue);
      this.scrollToTop();
    }

    if (changes['permissionObj']?.currentValue) {
      this.checkButtonVisibility();
    }
  }

  ngAfterViewInit(): void {
    this.updateStickyTopPosition();
    this.scrollToTop();
  }

  updateStickyTopPosition(): void {
    this.headerService.updateStickyTopPosition();
  }

  scrollToTop(): void {
    this.headerService.scrollToTop();
  }

  transformStatus(status: string | undefined | null): StatusEnum {
    // TODO: Need to confirm color options for different docStatus
    if (!status) return StatusEnum.DRAFT ?? 'Draft';
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.DRAFT
    );
  }

  onBusinessDaysChange(value: number | null) {
    this.businessdaysSelected = value === null ? 0 : value;
    this.showRushRequestHeading =
      value === null
        ? false
        : this.businessdaysSelected <
          ExternalCommunicationConstant.TWO_BUSINESSDAY_SELECTED;
  }

  openModalForDiscontinue(modalBtn?: ButtonComponent): void {
    const openPopUp = this.modalService.open(DiscontinuePopupComponent, {
      isFooter: false,
      size: 'lg',
      isInverse: false,
      submissionId: this.submissionId,
    });
    openPopUp
      .onClosed()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe(() => {
        if (this.submissionId) {
          this.timeoutId = setTimeout(() => {
            this.getSubmissionDetails(this.submissionId);
          }, IntakeConstant.TIMOUT_TIMER_1000);
        }
        this.externalCommService.openDiscontinueModal(false);
      });
  }

  openModaladminComplete(modalBtn?: ButtonComponent): void {
    const openAdminPopUp = this.modalService.open(AdminCompletePopupComponent, {
      isFooter: false,
      size: 'lg',
      isInverse: false,
      submissionId: this.submissionId,
    });
    openAdminPopUp
      .onClosed()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe(() => {
        if (this.submissionId) {
          this.timeoutId = setTimeout(() => {
            this.getSubmissionDetails(this.submissionId);
          }, IntakeConstant.TIMOUT_TIMER_1000);
        }
        this.externalCommService.openAdminCompleteModal(false);
      });
  }

  getSubmissionDetails(
    id: number,
    updateSubmissionDetails: boolean = false
  ): void {
    if (!updateSubmissionDetails) {
      this.progressIndicator.show();
    }
    this.externalCommService
      .getExtCommSubmissionDetails(id)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: ExtCommSubmissionDetailsModel) => {
          if (response) {
            this.submissionDetailModel = response;
            // TODO : need to remove for this commented line, next pr will remove as testing going on
            //this.submissionDetailModel.selfReviewEligibility = 'yes';
            this.ppmdName = this.submissionDetailModel.ppmdName;
            this.submissionTypeStatus =
              this.submissionDetailModel.submissionStatus;
            this.externalCommService.updateSubmissionStatus(
              this.submissionTypeStatus
            );

            this.ppmdAcknowledgedBy =
              this.submissionDetailModel.ppmdAcknowledgedBy;
            this.ppmdAcknowledgedDate =
              this.submissionDetailModel.ppmdAcknowledgedDate;
            this.ppmdReviewedBy = this.submissionDetailModel.ppmdReviewedBy;
            this.showRespPractitionerConfirmationMessage =
              this.ppmdAcknowledgedBy == null &&
              this.ppmdAcknowledgedDate == null
                ? true
                : false;
            this.updateDetailsRequestBody();
            this.checkAllButtonsEnableState();
            this.checkButtonVisibility();
            this.progressIndicator.hide();
            this.SubmissionActionHistoryForAdminComplete(this.submissionId);
            this.externalCommService.enableDisconitnue(
              this.isStatusForDiscontinue()
            );
            this.externalCommService.enableAdminComplete(
              this.isStatusforAdminComplete()
            );
            this.updateLeftNav.emit({
              submissionId: this.submissionId,
              submissionStatus:
                this.submissionDetailModel.submissionStatus ??
                IntakeConstant.EMPTY_SPACE,
            });
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  openModalForResetStatus(modalBtn?: ButtonComponent): void {
    const resetStatusModalRef = this.modalService.open(
      ExtCommResetSubmissionStatusComponent,
      {
        isFooter: false,
        size: 'lg',
        isInverse: false,
        submissionId: this.submissionId,
        submissionStatus: this.submissionTypeStatus,
      }
    );
    resetStatusModalRef
      .onClosed()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((resetButtonSelected) => {
        this.submissionId && resetButtonSelected;
        if (this.submissionId && resetButtonSelected) {
          this.GetSubmissionActionHistory();
          setTimeout(() => {
            this.getSubmissionDetails(this.submissionId);
            this.updateLeftNav.emit({
              submissionId: this.submissionId,
              submissionStatus:
                this.submissionDetailModel.submissionStatus ??
                IntakeConstant.EMPTY_SPACE,
            });
          }, ExternalCommunicationConstant.SET_TIMEOUT_DURATION);
        }
        this.commonService.openResetStatusModalForExtComm(false);
      });
  }

  GetSubmissionActionHistory(): void {
    this.progressIndicator.show();
    this.externalCommService
      .getSubmissionActionHistoryResetStatus(this.submissionId)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: SubmissionActionHistoryModelResetStatus[]) => {
          if (response) {
            this.submissionActionHistory = response;
            this.bindSubmissionActionHistoryToNotesSection();
            this.progressIndicator.hide();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  bindSubmissionActionHistoryToNotesSection(): void {
    const previousStatus = this.submissionActionHistory[0]?.previousStatus;
    const newStatus = this.submissionActionHistory[0]?.currentStatus;
    const date = this.submissionActionHistory[0]?.resetedOn;
    const name = this.submissionActionHistory[0]?.resetedBy;
    const reasonForResettingStatus = this.submissionActionHistory[0]?.reason;
    const otherReasonDescription =
      this.submissionActionHistory[0]?.otherReasonDescription;

    const submissionActionHistory = `Submission reset from ${previousStatus} to ${newStatus} on ${date} by ${name}. Reason: ${reasonForResettingStatus} ${otherReasonDescription}`;
    this.detailsRequestBody.NotesAndFeedback = submissionActionHistory;
    this.updateSubmissionDetails(this.detailsRequestBody);
  }

  SubmissionActionHistoryForAdminComplete(id: number): void {
    this.progressIndicator.show();
    this.externalCommService
      .getSubmissionActionHistory(id)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: SubmissionActionHistoryModel[]) => {
          this.submissionActionHistory = response;
          for (let i in this.submissionActionHistory) {
            if (
              this.submissionActionHistory[i].submissionActionId ===
              IntakeConstant.DiscontinueActionID
            ) {
              this.showDiscontinueMessage = true;
              this.discontinueActionMessage = this.submissionActionHistory[i];
              return;
            } else if (
              this.submissionActionHistory[i].submissionActionId ===
              IntakeConstant.SubmissionActionID
            ) {
              this.showAdminCompleteMessage = true;
              this.adminCompleteActionMessage = this.submissionActionHistory[i];
            } else {
              this.showDiscontinueMessage = false;
              this.showAdminCompleteMessage = false;
            }
          }
          this.progressIndicator.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('Error fetching data', err);
        },
      });
  }

  isStatusForDiscontinue(): boolean {
    return (
      this.submissionDetailModel.submissionStatus ===
        ExternalCommSubmissionStatus.DRAFT ||
      this.submissionDetailModel.submissionStatus ===
        ExternalCommSubmissionStatus.RETURN_FOR_REWORK ||
      this.submissionDetailModel.submissionStatus ===
        ExternalCommSubmissionStatus.SUBMITTED ||
      this.submissionDetailModel.submissionStatus ===
        ExternalCommSubmissionStatus.RESUBMITTED
    );
  }

  isStatusforAdminComplete(): boolean {
    return (
      this.submissionDetailModel.submissionStatus ===
        ExternalCommSubmissionStatus.RM_REVIEWED_PENDING_PPMD_CONFIRMATION ||
      this.submissionDetailModel.submissionStatus ===
        ExternalCommSubmissionStatus.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION
    );
  }

  copyToClipboard(value: string | null): void {
    if (value) {
      copyToClipboard(value);
    }
  }

  tabChanged(id: string | undefined): void {
    const allHeaders = document.querySelectorAll('.dds-accordion__header');
    allHeaders.forEach((header) => {
      header.classList.remove('selected-tab');
    });
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        const sectionHeader = element.querySelector('.dds-accordion__header');
        if (sectionHeader) {
          sectionHeader.classList.add('selected-tab');
        }
        window.scrollTo({
          top: element.offsetTop - 100,
          behavior: 'smooth',
        });
      }
    }
  }
  getCommunicationType(): void {
    this.progressIndicator.show();
    this.externalCommService
      .getSubmissionTypes()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: InitiateExternalCommunicationDropdownModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: InitiateExternalCommunicationDropdownModel) => ({
                value: item.communicationTypeId,
                heading: item.communicationTypeName,
              })
            );
            this.communicationTypeList = convertedArray;
            this.progressIndicator.hide();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  getIndustrySector(): void {
    this.progressIndicator.show();
    this.externalCommService
      .getExtCommIndustrySector()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: IndustrySectorModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: IndustrySectorModel) => ({
                value: parseInt(item.industryId),
                heading: item.industrySectorName,
              })
            );
            this.industrySectorList = convertedArray;
            this.progressIndicator.hide();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  getMarketOffering(event: number | null): void {
    if (event && event != null) {
      this.detailsRequestBody.OfferingId = event;
      this.progressIndicator.show();
      this.externalCommService
        .getExtCommMarketOfferingBasedOnSelectedOffering(Number(event))
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe({
          next: (response: MarketOfferingModel[]) => {
            if (response) {
              const convertedArray = response.map(
                (item: MarketOfferingModel) => ({
                  value: parseInt(item.marketOfferingId),
                  heading: item.marketOfferingName,
                })
              );
              this.marketOfferingList = convertedArray;
              this.progressIndicator.hide();
            }
          },
          error: (err: HttpErrorResponse) => {
            console.error('An error occurred during submission: ', err);
            this.progressIndicator.hide();
          },
        });
    }
  }

  getOffering(event: number | null): void {
    if (event && event != null) {
      this.marketOfferingList = [];
      this.detailsRequestBody.MarketOfferingId = null;
      this.detailsRequestBody.OfferingPortfolioId = event;
      this.progressIndicator.show();
      this.externalCommService
        .getExtCommOfferingBasedOnOfferingPortfolio(Number(event))
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe({
          next: (response: OfferingModel[]) => {
            if (response) {
              const convertedArray = response.map((item: OfferingModel) => ({
                value: parseInt(item.offeringId),
                heading: item.offeringName,
              }));
              this.offeringList = convertedArray;
              this.progressIndicator.hide();
            }
          },
          error: (err: HttpErrorResponse) => {
            console.error('An error occurred during submission: ', err);
            this.progressIndicator.hide();
          },
        });
    }
  }

  getOfferingPortfolio(): void {
    this.progressIndicator.show();
    this.externalCommService
      .getExtCommOfferingPortfolio()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: OfferingPortfolioModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: OfferingPortfolioModel) => ({
                value: parseInt(item.offeringPortfolioId),
                heading: item.offeringPortfolioName,
              })
            );

            this.offeringPortfolioList = convertedArray;
            this.progressIndicator.hide();
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (this.isEditingType && !target.closest('#communicationType')) {
      this.editType();
    }

    // TODO: Remove uncommented code if onSubmissionTitleChange works fine
    // if (
    //   !target.closest('#updateSubmissionTitle')
    // ) {
    //   this.updateSubmissionTitle();
    //   this.submissionTitleChange.emit(
    //     this.submissionDetailModel.submissionTitle
    //   );
    // }
  }

  onSubmissionTitleChange(): void {
    // This changes the title in header/left nav realtime, if we want to update title oninputblur
    // change the valueChanged to onBlur in html
    // else we can also emit the signal once the value is updated through API success.
    this.updateSubmissionTitle();
    this.submissionTitleChange.emit(this.submissionDetailModel.submissionTitle);
  }

  updateSubmissionTitle(): void {
    this.submissionDetailModel.submissionTitle =
      this.detailsRequestBody.SubmissionTitle;
  }

  editType(): void {
    this.isEditingType = false;

    this.submissionDetailModel.communicationTypeName = String(
      this.communicationTypeList[
        Number(this.detailsRequestBody.CommunicationTypeId) - 1
      ].heading
    );
    this.#saveDetailsValues();
  }

  onEditTypeClick(event: Event): void {
    event.stopPropagation();
    this.isEditingType = true;
  }

  updateDetailsRequestBody(): void {
    this.getOffering(this.submissionDetailModel?.offeringPortfolioId);
    this.getMarketOffering(this.submissionDetailModel?.offeringId);

    this.detailsRequestBody = {
      SubmissionId: this.submissionDetailModel.submissionId,
      CommunicationTypeId: this.submissionDetailModel.communicationTypeId,
      RequestedReviewDate: this.submissionDetailModel.requestedReviewDate,
      AudiencePublication: this.commonHelperService.trimPrefixEmptySpace(
        this.submissionDetailModel.audiencePublication
      ),
      IsDCF: this.submissionDetailModel.isDCF,
      IsGPS: this.submissionDetailModel.isGPS,
      OfferingPortfolioId: this.submissionDetailModel.offeringPortfolioId,
      OfferingId: this.submissionDetailModel.offeringId,
      MarketOfferingId: this.submissionDetailModel.marketOfferingId,
      PrimaryContact: this.submissionDetailModel.primaryContact,
      SecondaryContact: this.submissionDetailModel.secondaryContact,
      MarketingTalentPRContact:
        this.submissionDetailModel.marketingTalentPRContact,
      AdditionalComments: this.commonHelperService.trimPrefixEmptySpace(
        this.submissionDetailModel.additionalComments
      ),
      NotesAndFeedback: this.commonHelperService.trimPrefixEmptySpace(
        this.submissionDetailModel.notesAndFeedback
      ),
      IsRushRequest: this.submissionDetailModel.isRushRequest,
      InternalQRM: this.commonHelperService.trimPrefixEmptySpace(
        this.submissionDetailModel.internalQRM
      ),
      PrimaryIndustrySectorId: this.submissionDetailModel.industryId,
      SubmissionTitle: this.commonHelperService.trimPrefixEmptySpace(
        this.submissionDetailModel.submissionTitle
      ),
    };
    this.primaryPeople = this.submissionDetailModel?.primaryContactName;
    this.secondaryPeople = String(
      this.submissionDetailModel.secondaryContactName
    );
    this.marketContact = String(
      this.submissionDetailModel.marketingTalentPRContactName
    );
    if (
      this.submissionDetailModel.isDCF == true ||
      this.submissionDetailModel.isDCF == false
    ) {
      if (this.submissionDetailModel.isDCF == true) {
        this.isDCF = this.yesValue;
      } else {
        this.isDCF = this.noValue;
      }
    }
    if (
      this.submissionDetailModel.isGPS == true ||
      this.submissionDetailModel.isGPS == false
    ) {
      if (this.submissionDetailModel.isGPS == true) {
        this.isGPS = this.yesValue;
      } else {
        this.isGPS = this.noValue;
      }
    }
    if (
      this.governmentPublicServices.includes(
        Number(this.detailsRequestBody.PrimaryIndustrySectorId)
      )
    ) {
      this.isGPSDisabled = true;
    }
    if (
      this.marketOfferingList
        .find(
          (offering: SelectItemOptions) =>
            offering.value === this.detailsRequestBody.OfferingId
        )
        ?.heading?.toUpperCase() === this.corporateFinance
    ) {
      this.isDCF = this.yesValue;
      this.isDCFDisabled = true;
    }
  }

  onIndustrySectorChange(id: number): void {
    if (this.governmentPublicServices.includes(id)) {
      this.isGPS = this.yesValue;
      this.isGPSDisabled = true;
      this.#saveDetailsValues();
    } else {
      this.isGPSDisabled = false;
    }
  }

  onMarketOfferingChange(id: number): void {
    const offering = this.marketOfferingList.find(
      (offering: SelectItemOptions) => offering.value === id
    );
    if (offering?.heading?.toUpperCase() === this.corporateFinance) {
      this.isDCF = this.yesValue;
      this.isDCFDisabled = true;
      this.#saveDetailsValues();
    } else {
      this.isDCFDisabled = false;
    }
  }

  onUpdateRequestedReviewDate(
    detailsRequestBody: any | InitiateExternalCommunicationRequestModel
  ): void {
    // TODO: We can just get fields which are getting updated in submission-details component
    // instead of creating request body there.
    this.updateSubmissionDetails(detailsRequestBody);
  }

  requiedIndicatorForMarketContact(): boolean {
    if (this.disableFieldOnReadOnlyStatus()) return false;
    if (this.isDCF && this.isDCF === this.yesValue) {
      return !this.detailsRequestBody.MarketingTalentPRContact;
    } else if (
      Number(this.detailsRequestBody.CommunicationTypeId) ===
      this.interviewWithMediaCommunicationTypeId
    ) {
      return !this.detailsRequestBody.MarketingTalentPRContact;
    }
    return false;
  }

  #saveDetailsValues(): void {
    if (this.isDCF) {
      if (this.isDCF !== this.prevDcfValue) {
        this.isDCFValueChanged = true;
        this.prevDcfValue = this.isDCF;
      }
      this.detailsRequestBody.IsDCF = this.isDCF === this.yesValue;
    }
    if (this.isGPS) {
      this.detailsRequestBody.IsGPS = this.isGPS === this.yesValue;
    }
    this.updateSubmissionDetails(this.detailsRequestBody);
  }

  updateSubmissionDetails(
    detailsRequestBody: InitiateExternalCommunicationRequestModel
  ): void {
    this.externalCommService
      .putExtCommSubmissionDetails(detailsRequestBody)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response) => {
          if (response.isSuccess) {
            if (this.isDCFValueChanged) {
              this.getSubmissionDetails(this.submissionId, true);
              this.isDCFValueChanged = false;
            } else {
              // TODO: Can either call getSubmissionDetails to update the submissionDetailModel or update the individual fields
              this.submissionDetailModel = {
                ...this.submissionDetailModel,
                requestedReviewDate: detailsRequestBody.RequestedReviewDate,
              };

              this.checkAllButtonsEnableState();
              this.updateLeftNav.emit({
                submissionId: this.submissionId,
                submissionStatus:
                  this.submissionDetailModel.submissionStatus ?? this._blank,
              });
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error while updating the submission details', err);
        },
      });
  }

  onSelectedPrimaryPeople(event: any): void {
    if (this.permissionObj['isExtCommPrimaryContactEnable']) {
      this.primaryPeople = event.displayName;
      this.primaryPeopleId = event.reviewerId;
      this.detailsRequestBody.PrimaryContact = String(this.primaryPeopleId);
      this.#saveDetailsValues();
    }
  }

  removePrimaryPeople(): void {
    if (this.permissionObj['isExtCommPrimaryContactEnable']) {
      this.primaryPeople = this._blank;
      this.primaryPeopleId = null;
      this.detailsRequestBody.PrimaryContact = this._blank;
      this.#saveDetailsValues();
    }
  }

  onSelectedSecondaryPeople(event: any): void {
    if (this.permissionObj['isExtCommSecondaryContactEnable']) {
      this.secondaryPeople = event.displayName;
      this.secondaryPeopleId = event.reviewerId;
      this.detailsRequestBody.SecondaryContact = String(this.secondaryPeopleId);
      this.#saveDetailsValues();
    }
  }

  removeSecondaryPeople(): void {
    if (this.permissionObj['isExtCommSecondaryContactEnable']) {
      this.secondaryPeople = this._blank;
      this.secondaryPeopleId = null;
      this.detailsRequestBody.SecondaryContact = null;
      this.#saveDetailsValues();
    }
  }

  onSelectedMarketContact(event: any): void {
    if (this.permissionObj['isExtCommMarketingTalentPRContactEnable']) {
      this.marketContact = event.displayName;
      this.marketContactId = event.reviewerId;
      this.detailsRequestBody.MarketingTalentPRContact = String(
        this.marketContactId
      );
      this.#saveDetailsValues();
    }
  }

  removeMarketContact(): void {
    if (this.permissionObj['isExtCommMarketingTalentPRContactEnable']) {
      this.marketContact = this._blank;
      this.marketContactId = null;
      this.detailsRequestBody.MarketingTalentPRContact = null;
      this.#saveDetailsValues();
    }
  }

  genericMandatoryFieldCheck(): boolean {
    return this.detailsRequestBody.CommunicationTypeId &&
      this.detailsRequestBody.SubmissionTitle &&
      this.detailsRequestBody.AudiencePublication &&
      this.detailsRequestBody.RequestedReviewDate &&
      this.isGPS &&
      this.isDCF &&
      this.detailsRequestBody.OfferingPortfolioId &&
      this.detailsRequestBody.PrimaryContact &&
      (this.showRiskAssessment
        ? this.riskAssessmentRequiredFieldsFilled
        : true) &&
      this.communicationAttributesRequiredFieldsFilled &&
      (this.isDCF === this.yesValue ||
      this.detailsRequestBody.CommunicationTypeId ===
        this.interviewWithMediaCommunicationTypeId
        ? this.detailsRequestBody.MarketingTalentPRContact
        : true) &&
      this.isValidDocumentUploaded
      ? true
      : false;
  }

  enableSubmitButton(): boolean {
    const isEnableSubmitButton =
      this.permissionObj['isExtCommSubmitResubmitButtonEnable'] &&
      this.genericMandatoryFieldCheck() &&
      this.haveAllMandatoryReviewers;

    this.isSubmitButtonEnabled = isEnableSubmitButton;

    // TODO: remove, if functionality not needed.
    // if(allFieldsfilled / buttonenabled) emit signal for yellow indicator
    if (isEnableSubmitButton !== this.#previousSubmitButtonState) {
      this.allRequiredFieldsFilled.emit(isEnableSubmitButton);
      this.#previousSubmitButtonState = isEnableSubmitButton;
    }
    return isEnableSubmitButton;
  }

  enableResubmitButton(): void {
    this.isResubmitButtonEnabled = this.enableSubmitButton();
  }

  checkButtonVisibility(): void {
    const selfReviewEligibility: string =
      this.submissionDetailModel?.selfReviewEligibility
        ?.toLowerCase()
        ?.trim() ?? this._blank;
    const yes: string =
      ExternalCommunicationConstant.YES?.toLocaleLowerCase()?.trim();

    this.isSubmitButtonVisible =
      this.permissionObj['isExtCommSubmitResubmitButtonVisible'] &&
      (selfReviewEligibility !== yes ||
        (selfReviewEligibility === yes &&
          this.submissionDetailModel.reasonForOptOutSelfReview !== null)) &&
      this.submissionDetailModel?.submissionStatus === StatusEnum.DRAFT; // TODO: Check if the status is submitted or remains in draftF

    this.isOptOutSelfReviewButtonVisible =
      selfReviewEligibility === yes && // TODO: Check if we need to check this condition
      this.permissionObj['isExtCommOptOutSelfReviewButtonVisible'] &&
      this.submissionDetailModel?.submissionStatus === StatusEnum.DRAFT && // TODO: Add resubmitted check if button will be visible in that status
      this.submissionDetailModel.reasonForOptOutSelfReview === null;

    this.isReturnToSelfReviewButtonVisible =
      this.permissionObj['isExtCommForcedSelfReviewVisible'] &&
      selfReviewEligibility === yes &&
      this.submissionDetailModel?.reasonForOptOutSelfReview !== null &&
      this.SUBMISSION_STATUS_ALLOW_FOR_WORKFLOW_RETURN_FOR_SELF_REVIEW.includes(
        this.submissionDetailModel?.submissionStatus as string
      ) &&
      this.isLogginUserExistInWorkflowTableAndNotPractitioner();
    this.isConfirmButtonVisible = this.checkIsConfirmBtnVisible();

    this.isSelfReviewButtonVisible =
      this.permissionObj['isExtCommSelfReviewEligibiltyVisible'] &&
      selfReviewEligibility === yes &&
      this.submissionDetailModel.reasonForOptOutSelfReview === null &&
      this.submissionDetailModel?.submissionStatus === StatusEnum.DRAFT;

    this.isPrescreenButtonVisible = this.checkPrescreenVisibility();

    this.isCompleteReviewButtonVisible = this.checkCompleteReviewVisibility();

    this.isReturnToReworkBtnVisible = this.checkReturnToReworkVisibility();

    const practioner = this.getWorkFlowTablePractioner();

    this.isWorkflowPractitionerCompleteMsg =
      practioner?.statusReviewedDate !== null &&
      this.submissionDetailModel?.submissionStatus === StatusEnum.COMPLETED;
  }

  isLogginUserExistInWorkflowTableAndNotPractitioner(): boolean {
    return this.updatedRiskManagerDetails.some(
      (workflowUser: Reviewers) =>
        workflowUser.reviewerId === this.currentLoggedInUser &&
        ROLES_ALLOW_FOR_WORKFLOW_RETURN_FOR_SELF_REVIEW.includes(
          workflowUser.roleName ?? this._blank
        )
    );
  }

  getWorkFlowTablePractioner(): Reviewers | undefined {
    return this.updatedRiskManagerDetails.find(
      (reviewer: Reviewers) =>
        reviewer.roleName === RoleEnum.RESPONSIBLE_PRACTITIONER
    );
  }

  checkIsConfirmBtnVisible(): boolean {
    const getWorkFlowTablePractioner: Reviewers | undefined =
      this.getWorkFlowTablePractioner();
    return (
      getWorkFlowTablePractioner?.reviewerId === this.currentLoggedInUser &&
      this.loggedInUserRoles?.includes(RoleEnum.RESPONSIBLE_PRACTITIONER) &&
      (this.submissionDetailModel?.submissionStatus
        ?.toLocaleLowerCase()
        .trim() ===
        ExternalCommSubmissionStatus.RM_REVIEWED_PENDING_PPMD_CONFIRMATION?.toLocaleLowerCase().trim() ||
        this.submissionDetailModel?.submissionStatus
          ?.toLocaleLowerCase()
          .trim() ===
          ExternalCommSubmissionStatus.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION?.toLocaleLowerCase().trim())
    );
  }

  checkAllButtonsEnableState(): void {
    this.enableSubmitButton();
    this.enableResubmitButton();
  }

  onRiskAssessmentRequiredFieldsFilled(value: boolean): void {
    this.riskAssessmentRequiredFieldsFilled = value;
    this.checkAllButtonsEnableState();
  }

  confirmRpReviewCompletion(): void {
    this.progressIndicator.show();
    this.showRespPractitionerConfirmationMessage = false;
    this.updateReviewCompletionStatus();
  }

  updateReviewCompletionStatus(): void {
    const requestBody: updatePpmdStatusRequestModel = {
      submissionId: this.submissionId,
      ppmdreviewedby: this.submissionDetailModel.ppmdEmployeeId,
    };

    this.progressIndicator.show();
    this.externalCommService
      .updatePpmdStatus(requestBody)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicator.hide();
          this.getSubmissionDetails(this.submissionId);
        },
        error: (err) => {
          this.progressIndicator.hide();
          console.error(
            'An error occurred while updating the review completion status',
            err
          );
        },
      });
  }

  onCommAttrRequiredFieldsFilled(value: boolean): void {
    this.communicationAttributesRequiredFieldsFilled = value;
    this.checkAllButtonsEnableState();
  }

  submitSubmission(): void {
    if (this.isSubmitButtonEnabled) {
      this.updateSubmissionStatus(StatusEnumIdMap[StatusEnum.SUBMITTED]);
    }
  }

  optOutSelfReview(): void {
    // Reusing the existing popup , if any change required can create new component
    if (this.permissionObj['isExtCommOptOutSelfReviewButtonEnable']) {
      const optOutSelfReviewModalRef =
        this.modalService.open(OptOutPopupComponent);
      optOutSelfReviewModalRef
        .onClosed()
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe({
          next: (popupData: IntakeOptOutPopUpDataModel) => {
            if (
              popupData.submissionLevelUpdateSubmission &&
              !!popupData.optOutReason
            ) {
              this.updateOptOutSelfReviewReason(popupData.optOutReason);
              // TODO: Add logic to hide self-review button
            }
          },
          error: (err: Error) => {
            console.error('An error occurred during opening modal: ', err);
          },
        });
    }
  }

  selfReviewHandler(): void {
    const selfReviewModalRef = this.modalService.open(SelfReviewPopUpComponent);
    selfReviewModalRef
      .onClosed()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (submissionLevelUpdateSubmission: boolean) => {
          if (submissionLevelUpdateSubmission) {
            this.updateSubmissionStatus(
              ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
                ExternalCommSubmissionStatus
                  .SELF_REVIEWED_PENDING_PPMD_CONFIRMATION
              ]
            );
          }
        },
      });
  }

  returnToSelfReview(): void {
    if (this.permissionObj['isExtCommForcedSelfReviewVisible']) {
      this.updateOptOutSelfReviewReason(null);
    }
  }

  updateSubmissionStatus(submissionStatusId: number): void {
    this.progressIndicator.show();
    this.externalCommService
      .updateExtCommSubmissionStatus(this.submissionId, submissionStatusId)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (res: GenericResponse) => {
          if (res.isSuccess) {
            this.progressIndicator.hide();
            this.onUpdateAPISuccess(
              toastMessageTitle[
                getStatusEnumKeyByValue(submissionStatusId) ?? this._blank
              ] ?? this._blank
            );
            this.timeoutId = setTimeout(() => {
              this.router.navigate(['/']);
            }, IntakeConstant.SET_TIMEOUT_DURATION);
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error while updating submission', err);
          this.progressIndicator.hide();
        },
      });
  }

  updateOptOutSelfReviewReason(optOutReason: string | null): void {
    this.progressIndicator.show();
    this.externalCommService
      .updateExtCommOptOutSelfReviewReason(this.submissionId, optOutReason)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (res: GenericResponse) => {
          if (res.isSuccess) {
            this.progressIndicator.hide();
            const toastMsg: string =
              optOutReason === null
                ? toastMessageTitle['ReturnedforSelfReview']
                : toastMessageTitle['OptOutSelfReview'];
            this.onUpdateAPISuccess(toastMsg ?? this._blank);
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error while updating submission', err);
          this.progressIndicator.hide();
        },
      });
  }

  ppmdTrainingLabelValue(data: string): void {
    const element = document.getElementById('ppmdTrainingNotCompletedLabel');
    if (element) {
      element.innerHTML = data;
    }
  }

  getLabelConfiguration(): void {
    this.progressIndicator.show();
    this.intakeDetailService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response) {
            this.configData = response;
            this.configLableDescription =
              this.configData.find(
                (config: configurationType) =>
                  config.appConfigurationLabelKey ===
                  'PPMD_TRAINING_NOT_COMPLETED_GUIDANCE'
              )?.appConfigurationLabelValue || this.emptySpace;

            this.progressIndicator.hide();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  checkReviewerList(data: Reviewers[]): void {
    this.updatedRiskManagerDetails = data;
    const rmSupport = this.updatedRiskManagerDetails.find(
      (reviewer: Reviewers) =>
        reviewer.roleName === RoleEnum.EXT_COMM_RM_SUPPORT_GLOBAL
    );
    rmSupport?.statusReviewedDate !== null
      ? (this.isPrescreened = true)
      : (this.isPrescreened = false);
    this.checkButtonVisibility();

    const requiredReviewers = this.updatedRiskManagerDetails.filter(
      (reviewer: Reviewers) =>
        reviewer.isRequired === true &&
        reviewer.roleName !== RoleEnum.RESPONSIBLE_PRACTITIONER
    );
    this.isRequiredReviewerCompleted = requiredReviewers.every(
      (reviewer: Reviewers) => reviewer.statusReviewedDate !== null
    );

    // Check if workflow table has Responsible Practitioner
    const hasResponsiblePractitioner = this.updatedRiskManagerDetails.some(
      (reviewer: Reviewers) =>
        reviewer.submissionApproverId !== null &&
        reviewer.roleName === RoleEnum.RESPONSIBLE_PRACTITIONER
    );
    this.haveAllMandatoryReviewers = hasResponsiblePractitioner;

    this.checkAllButtonsEnableState();
  }

  userInWorkflowTable(): boolean {
    return this.updatedRiskManagerDetails.some(
      (reviewer: Reviewers) => reviewer.reviewerId === this.currentLoggedInUser
    );
  }

  checkPrescreenVisibility(): boolean {
    const rmSupport: Reviewers | undefined =
      this.updatedRiskManagerDetails.find(
        (reviewer: Reviewers) =>
          reviewer.roleName === RoleEnum.EXT_COMM_RM_SUPPORT_GLOBAL
      );

    return (
      this.userInWorkflowTable() &&
      rmSupport?.reviewerId === this.currentLoggedInUser &&
      rmSupport?.statusReviewedDate === null &&
      (this.submissionDetailModel?.submissionStatus === StatusEnum.SUBMITTED ||
        this.submissionDetailModel?.submissionStatus === StatusEnum.RESUBMITTED)
    );
  }

  checkCompleteReviewVisibility(): boolean {
    return (
      this.permissionObj['isExtCommCompleteReviewVisible'] &&
      this.userInWorkflowTable() &&
      this.checkReviewStatus() &&
      this.isPrescreened &&
      this.isUserWorkFlowAllow() &&
      (this.submissionDetailModel.submissionStatus === StatusEnum.SUBMITTED ||
        this.submissionDetailModel.submissionStatus === StatusEnum.RESUBMITTED)
    );
  }

  isUserWorkFlowAllow(): boolean {
    const filteredUsers = this.updatedRiskManagerDetails.filter(
      (workflowUser: Reviewers) =>
        workflowUser.roleName !==
          (RoleEnum.EXT_COMM_RM_SUPPORT_GLOBAL ||
            RoleEnum.RESPONSIBLE_PRACTITIONER) &&
        workflowUser.reviewerId === this.currentLoggedInUser
    );
    return filteredUsers.length > 0;
  }

  checkReturnToReworkVisibility(): boolean {
    const RETURN_TO_REWORK_ALLOWED_STATUS = [
      StatusEnum.RM_REVIEWED_PENDING_PPMD_CONFIRMATION,
      StatusEnum.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION,
      StatusEnum.SUBMITTED,
      StatusEnum.RESUBMITTED,
    ];
    const isVisible: boolean =
      this.userInWorkflowTable() &&
      RETURN_TO_REWORK_ALLOWED_STATUS.includes(
        this.submissionDetailModel?.submissionStatus ?? (this._blank as any)
      );
    this.isWorkFlowTableReworkBtnVisible = isVisible;
    // && this.loggedInUserRoles?.includes(RoleEnum.RESPONSIBLE_PRACTITIONER); //TODO:
    return isVisible;
  }

  checkReviewStatus(): boolean {
    const data: Reviewers | undefined = this.updatedRiskManagerDetails.find(
      (reviewer: Reviewers) => reviewer.reviewerId === this.currentLoggedInUser
    );
    if (data) {
      return data.statusReviewedDate === null;
    }
    return false;
  }

  openCompleteReviewModal(
    rowDetails: Reviewers,
    isCompleteReview: boolean
  ): void {
    const reviewModal = this.modalService.open(
      ExtCommDocSelectionPopupComponent,
      {
        submissionID: this.submissionId,
        rowDetails: rowDetails,
        prescreen: this.checkPrescreenVisibility(),
      }
    );
    reviewModal
      .onClosed()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (showToastMessage: boolean) => {
          if (showToastMessage) {
            this.documentComponent.triggerDocumentTableDataApi();
            this.workflowComponent.getReviewerList();
            if (this.submissionId) {
              setTimeout(() => {
                if (this.isRequiredReviewerCompleted && isCompleteReview) {
                  this.updateSubmissionStatus(
                    StatusEnumIdMap[
                      StatusEnum.RM_REVIEWED_PENDING_PPMD_CONFIRMATION
                    ]
                  );
                  this.updateLeftNav.emit({
                    submissionId: this.submissionId,
                    submissionStatus:
                      this.submissionDetailModel.submissionStatus ??
                      this._blank,
                  });
                } else {
                  this.getSubmissionDetails(this.submissionId);
                  this.setToast({
                    ...this.toastMessageOptions,
                    title: isCompleteReview
                      ? 'Review completed'
                      : 'Submission prescreened',
                  });
                }
              }, IntakeConstant.SET_TIMEOUT_DURATION);
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  handleCompleteReview(isCompleteReview: boolean): void {
    const data: Reviewers | undefined = this.updatedRiskManagerDetails.find(
      (item) => item.reviewerId === this.currentLoggedInUser
    );

    if (data) {
      this.openCompleteReviewModal(data, isCompleteReview);
    }
  }

  handleReturnToRework(): void {
    this.updateSubmissionStatus(StatusEnumIdMap[StatusEnum.RETURN_FOR_REWORK]);
  }

  handleResubmit(): void {
    this.updateSubmissionStatus(StatusEnumIdMap[StatusEnum.RESUBMITTED]);
  }

  onUpdateAPISuccess(title: string): void {
    this.setToast({ ...this.toastMessageOptions, title: title });
    this.getSubmissionDetails(this.submissionId);
    //TODO: wait for getsubmission details and then emit the below signal
    //TODO: this signal is emitted in getSubmissionDetails, do we need it here?

    this.updateLeftNav.emit({
      submissionId: this.submissionId,
      submissionStatus:
        this.submissionDetailModel.submissionStatus ?? this._blank,
    });
  }

  checkStatusDiscontinued(): boolean {
    return (
      (this.submissionDetailModel
        ?.submissionStatus as ExternalCommSubmissionStatus) ===
      ExternalCommSubmissionStatus.DISCONTINUED
    );
  }

  checkStatusAdminCompleted(): boolean {
    return (
      (this.submissionDetailModel
        ?.submissionStatus as ExternalCommSubmissionStatus) ===
      ExternalCommSubmissionStatus.ADMIN_COMPLETE
    );
  }

  checkStatusCompleted(): boolean {
    return (
      (this.submissionDetailModel
        ?.submissionStatus as ExternalCommSubmissionStatus) ===
      ExternalCommSubmissionStatus.COMPLETED
    );
  }

  clickToShowDocumentMoreLepMessage(): void {
    this.isShowMoreDocumentNotes = !this.isShowMoreDocumentNotes;
    if (!this.isShowMoreDocumentNotes) {
      this.showMoreText = IntakeConstant.SHOW_MORE_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_DOWN;
    } else {
      this.showMoreText = IntakeConstant.SHOW_LESS_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_UP;
    }
  }

  setToast(toast: ToastOptions): void {
    this.toastService.createToast(toast);
  }

  submissionReload(message: string): void {
    if (message !== this._blank) {
      this.onUpdateAPISuccess(message);
    }
  }

  confirmSubmissionHeader(): void {
    const data: Reviewers | undefined = this.updatedRiskManagerDetails.find(
      (item) => item.reviewerId === this.currentLoggedInUser
    );

    if (data) {
      this.confirmSubmission(data);
    }
  }

  confirmSubmission(reviewer: Reviewers): void {
    const confirmSubmissionModalRef = this.modalService.open(
      ConfirmSubmissionPopupModalComponent,
      {
        rowDetails: reviewer,
        submissionID: this.submissionId,
        isFooter: true,
        size: 'lg',
        isInverse: false,
      }
    );

    confirmSubmissionModalRef
      .onClosed()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((response: boolean) => {
        if (response) {
          this.updateReviewerStatus(reviewer);
        }
      });
  }

  updateReviewerStatus(reviewerData: Reviewers): void {
    const saveSelectedDocuments: ExtCommUpdateReviewStatusRequestBodyModel = {
      SubmissionDocumentId: [],
      ReviewerEmployeeid: reviewerData.reviewerId,
      Email: reviewerData.emailId,
      SubmissionId: +this.submissionId,
      ReviewerRoleId: reviewerData.reviewerRoleId,
    };
    this.progressIndicator.show();
    this.externalCommService
      .updateReviewerStatus(saveSelectedDocuments)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicator.hide();
          this.updateSubmissionStatus(StatusEnumIdMap[StatusEnum.COMPLETED]);
          this.workflowComponent.getReviewerList();
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('An error occurred while sending the data ', err);
        },
      });
  }

  disableFieldOnReadOnlyStatus(): boolean {
    return (
      this.checkStatusAdminCompleted() ||
      this.checkStatusDiscontinued() ||
      this.checkStatusCompleted()
    );
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
