import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartPageComponent } from './start-page/start-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { NcaSummaryComponent } from './nca-summary/nca-summary.component';
import { ErrorPageComponent } from './common/components/error-page/error-page.component';
import { DownloadRedirectComponent } from './download-redirect/download-redirect.component';

const routes: Routes = [
  {
    path: '',
    component: StartPageComponent,
    pathMatch: 'full',
    canActivate: [MsalGuard]
  },
  {
    path: 'initiate',
    loadChildren: () => import('./initiation/initiation.module').then(m => m.InitiationModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'submission',
    loadChildren: () => import('./intake/intake.module').then(m => m.IntakeModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'watchlist',
    loadChildren: () => import('./watchlist/watchlist.module').then(m => m.WatchlistModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'thirdpartyrepository',
    loadChildren: () => import('./third-party-repository/third-party-repository.module').then(m => m.ThirdPartyRepositoryModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'externalCommunications',
    loadChildren: () => import('./external-communications/external-communications.module').then(m => m.ExternalCommunicationsModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'globalEngagements',
    loadChildren: () => import('./global-engagements/global-engagement.module').then(m => m.GlobalEngagementsModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'ncasummary',
    component: NcaSummaryComponent,
  },
  {
    path: 'audit-trail',
    loadChildren: () => import('./audit-trail/audit-trail.module').then(m => m.AuditTrailModule),
    canActivate: [MsalGuard]
  },
  { 
    path: 'error', 
    component: ErrorPageComponent 
  },
  {
    path: 'downloadarchive',
    component: DownloadRedirectComponent,
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation:
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? "enabledNonBlocking"
        : "disabled", // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
