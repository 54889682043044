import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { Filter, FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import {
  ButtonKind,
  Column,
  ExtThemes,
  Size,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { AdminConstant } from '../../../constants/admin-constants';

export const sampleColumns: Column[] = [
  { name: 'Activation status', header: '', dataType: 'boolean', minWidth: '4.375rem' },
  {
    name: 'name',
    header: 'Name',
    dataType: 'string',
    minWidth: '8.75rem',
  },
  {
    name: 'modifiedBy',
    header: 'Modified by',
    dataType: 'string',
    minWidth: '8.75rem',
  },
  {
    name: 'modifiedDate',
    header: 'Modified date',
    dataType: 'date',
    minWidth: '8.75rem',
  },
  {
    name: 'comments',
    header: 'Comments',
    dataType: 'string',
    minWidth: '8.75rem',
  },
  { name: 'editIcon', header: 'Actions', dataType: 'string', minWidth: '4.375rem' },
];

export const btnOptions: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  size: AdminConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: AdminConstant.EMPTY_SPACE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Add Record',
  customClass: AdminConstant.EMPTY_SPACE,
  role: 'button',
};

export const filterData: FilterItem[] = [
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Modified by',
    name: 'modifiedBy',
  },
  {
    title: 'Modified date',
    name: 'modifiedDate',
  },
  {
    title: 'Comments',
    name: 'comments',
  },
  {
    title: 'isActive',
    name: 'isActive',
  },
];

export const filter: Filter = {
  theme: Themes.green,
  propName: 'name',
  itemPropNameValue: 'value',
};

export const searchOptionsCtrl: SearchOptions = {
  placeholder: AdminConstant.EMPTY_SPACE,
  ariaLabel: 'searchInput',
  size: AdminConstant.UI_ELEMENT_SIZE,
  isInverse: false,
  customClass: AdminConstant.EMPTY_SPACE,
  isTrimValue: false,
  readonly: false,
  maxLength: 0,
};

export const options: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  size: AdminConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: AdminConstant.EMPTY_SPACE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'CancelButton',
  customClass: AdminConstant.EMPTY_SPACE,
  role: 'button',
};