import { Injectable } from '@angular/core';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { environment } from '../../../environment/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyRepositoryApiService {

  constructor(private httpService: HttpClient) { }

  apiUrl: string = environment.apiUrl;

  getTPAGridData(
    pageNumber: number,
    pageSize: number,
    sortParameterCode: number,
    isAscending: boolean,
    filterData: TPAGridLevelFilter[]
  ): Observable<Agreement[]> {
    const params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('sortParameterCode', sortParameterCode.toString())
      .set('isAscending', isAscending.toString());

    return this.httpService.post<Array<Agreement>>(
      this.apiUrl + 'ThirdPartyAgreement/GetTPAGridData',
      filterData,
      { params }
    );
  }

  ExportThirdPartyAgreementData(
  ): Observable<{ Base64String: string, FileName: string }> {

    return this.httpService.get<{ Base64String: string, FileName: string }>(
      this.apiUrl + 'ThirdPartyAgreement/ExportThirdPartyAgreementData'
    );
  }
}

export enum TPAFilterSortParameterCode {
  sourceId = 1,
  agreementName = 2,
  agreementType = 3,
  thirdPartyName = 4,
  effectiveDate = 5,
  terminationDate = 6,
  autoRenew = 7,
  agreementStatus = 8
}

export interface TPAGridLevelFilter {
  filtertypecode: number;
  filtercriteria: string;
}

export interface GridColumn extends Column {
  filterable?: boolean;
  searchValue?: any;
}

export interface Agreement {
  sourceId?: number;
  agreementName: string;
  agreementType: string;
  thirdPartyName: string;
  effectiveDate: string;
  terminationDate: string;
  autoRenew?: string;
  agreementStatus: string;
  unfilteredDataCount?: number;
  expanded:boolean
  documentList:any[]
  [key: string]: any;
}