import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReviewerTileCode } from '../../common/models/start-page/reviewer-dashboard.model';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { MARSConstant } from '../../client/constants/mars-constant';
import { EngagementTileCode } from '../../intake/constants/dashboard-tiles.enum';
import { DashboardTileCode } from '../../common/models/start-page/start-page.model';
import { MatrixKeys } from '../../common/constants/security-matrix';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import { NCATileCode } from '../../common/models/start-page/nca-specialist-dashboard.model';

@Component({
  selector: 'app-tiles-group',
  templateUrl: './tiles-group.component.html',
  styleUrl: './tiles-group.component.less',
})
export class TilesGroupComponent implements OnInit {
  @Input() selectedTileCode: string = '';
  @Input() tilesCount: any;
  @Input() tileCodeDetails: any[] = [];
  @Output() tileSelectedEvent = new EventEmitter<string>();
  @Input() public dashboardType: string = '';
  public tileCountforPagination = 0;
  public enumReviewerTileCode = ReviewerTileCode;

  public ncaSpecialistTileCode: any = NCATileCode;
  public securityMarsNCAsToBeSubmittedTile: string =
    'ClientStatus.MarsNCAsToBeSubmitted';
  public isMarsNCAsToBeSubmittedTileVisisble: boolean = false;
  roles: string[] = [];
  public MARSNcasDraftCount: any = MARSConstant.MARS_Dashboard_NcasDraftCount;
  unassignedReviewerExtCommVisible: boolean = false;
  REVIEWER_DASHBOARD: string = IntakeConstant.REVIEWER_DASHBOARD;

  constructor(private readonly securityWebapiService: SecurityWebapiService) {}

  ngOnInit(): void {
    // TODO: Move the permission based tile removal to individual dashboard
    // sometimes if we comeback to dashboard the tiles are not visible though we have the permissions
    this.securityWebapiService.data.subscribe((rules) => {
      if (!rules.empty) {
        this.isMarsNCAsToBeSubmittedTileVisisble =
          rules[this.securityMarsNCAsToBeSubmittedTile] &&
          rules[this.securityMarsNCAsToBeSubmittedTile].Visible;
        if (
          !this.isMarsNCAsToBeSubmittedTileVisisble &&
          this.dashboardType == 'NCADashboard'
        ) {
          this.removeTile(this.ncaSpecialistTileCode.CompleteMarsNcaForm);
        }

        this.unassignedReviewerExtCommVisible =
          rules[MatrixKeys.EXT_COM_Unassigned_ReviewerDashboard]?.Visible ??
          false;
      }
    });
  }

  tileSelected(selectedTile: any) {
    this.selectedTileCode = selectedTile;
    this.tileSelectedEvent.emit(this.selectedTileCode);
  }
  removeTile(tileCode: string): void {
    this.tileCodeDetails = this.tileCodeDetails.filter(
      (tile) => tile.tileCode !== tileCode
    );
  }
}
