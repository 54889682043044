import { Component } from '@angular/core';
import { ReviewerDashboardWebapiService } from '../../http/dashboard/reviewer-webapi.service';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { environment } from '../../../environment/environment';
import {
  DashboardGridLevelFilter,
  DashboardTileCode,
  GridColumn,
} from '../../common/models/start-page/start-page.model';
import {
  GridColumns,
  ReviewerDashboardData,
  ReviewerDashboardTilesCount,
  ReviewerTileCode,
  ReviewerTileCodeDetails,
} from '../../common/models/start-page/reviewer-dashboard.model';

@Component({
  selector: 'app-reviewer-dashboard',
  templateUrl: './reviewer-dashboard.component.html',
  styleUrl: './reviewer-dashboard.component.less',
})
export class ReviewerDashboardComponent {
  public reviewerTiles = ReviewerTileCode;
  public reviewerTileCodeDetails = Array.from(ReviewerTileCodeDetails.values());
  public selectedTileCode: string = '';
  public dashboard: string = 'ReviewerDashboard';
  public employeeId: string = '00000000';
  public reviewerDashboardTilesCountModel: ReviewerDashboardTilesCount | any;
  public sortParamaterCode: number = 1;
  public isAscending: boolean = true;
  public countforPagination: number = 0;
  public gotoPage: number = 1;
  public itemsPerPage: number = 5;
  public gridTitle: string = '';
  public model: ReviewerDashboardData[] = [];
  public reviewerDashboardfilters: DashboardGridLevelFilter[] = [];
  public currentDashboardTileCode = DashboardTileCode.Reviewer;
  public rootUrl: string = environment.rootUrl;
  public gridColumns: GridColumn[] = [];
  public buttonOptionsList: {
    label: string;
    action: string;
    condition: boolean;
  }[] = [];
  public defaultGridColumns: GridColumn[] = [
    {
      name: 'submissionTitle',
      header: 'Submission title',
      dataType: 'string',
      minWidth: '8rem',
      filterable: false,
      searchValue: '',
    },
    {
      name: 'focus',
      header: 'Focus',
      dataType: 'string',
      minWidth: '4rem',
      width: '4rem',
      filterable: false,
      searchValue: '',
    },
    {
      name: 'jupiterId',
      header: 'Jupiter ID',
      dataType: 'string',
      minWidth: '5rem',
      filterable: false,
      searchValue: '',
    },
    {
      name: 'clientName',
      header: 'Client',
      dataType: 'string',
      minWidth: '8rem',
      filterable: false,
      searchValue: '',
    },
    {
      name: 'lep',
      header: 'LEP',
      dataType: 'string',
      minWidth: '5rem',
      filterable: false,
      searchValue: '',
    },
    {
      name: 'submittedDate',
      header: 'Date submitted',
      dataType: 'date',
      minWidth: '5rem',
      filterable: false,
      searchValue: '',
    },
    {
      name: 'actions',
      header: 'Actions',
      dataType: 'string',
      minWidth: '5rem',
      filterable: false,
      searchValue: '',
    },
    {
      name: 'pendingReviewer',
      header: 'Pending Reviewer',
      dataType: 'string',
      minWidth: '5rem',
      filterable: false,
      searchValue: null,
    },
    {
      name: 'lastStatus',
      header: 'Last status',
      dataType: 'string',
      minWidth: '5rem',
      filterable: false,
      searchValue: null,
    },
    {
      name: 'dateOfLastStatusChange',
      header: 'Date of last status change',
      dataType: 'string',
      minWidth: '5rem',
      filterable: false,
      searchValue: null,
    },
  ];
  public columnName = GridColumns;

  constructor(
    private readonly reviewerWebapiService: ReviewerDashboardWebapiService,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  ngOnInit() {
    this.progressIndicatorService.show();
    this.selectedTileCode = this.reviewerTiles.PendingReviews;

    this.gridTitle =
      this.reviewerTileCodeDetails.find(
        (x) => x.tileCode === this.selectedTileCode
      )?.title || '';
    this.reviewerWebapiService
      .getReviewerDashboardTilesCount()
      .subscribe((response: ReviewerDashboardTilesCount) => {
        this.reviewerDashboardTilesCountModel = response;
      });
    this.gotoPage = 1;
    this.onPageChanged(this.gotoPage);
    this.refreshGrid();
  }

  onTileSelected(selectedTileCode: string) {
    this.selectedTileCode = selectedTileCode;
    this.gridTitle =
      this.reviewerTileCodeDetails.find(
        (x) => x.tileCode === this.selectedTileCode
      )?.title || '';

    // Reset the filters and sorting state
    this.sortParamaterCode = 1;
    this.isAscending = true;
    this.reviewerDashboardfilters = [];
    // Reset the page number
    this.gotoPage = 1;
    this.onPageChanged(this.gotoPage);
  }

  refreshGrid() {
    this.progressIndicatorService.show();
    this.reviewerWebapiService
      .getReviewerDashBoardGridData(
        this.selectedTileCode,
        this.gotoPage,
        this.itemsPerPage,
        this.sortParamaterCode,
        this.isAscending,
        this.reviewerDashboardfilters
      )
      .subscribe((response) => {
        this.model = response.dataList;
        this.countforPagination = response.totalDataCount;
        this.progressIndicatorService.hide();
      });
    this.gridColumns = [...this.defaultGridColumns];

    if (this.selectedTileCode === this.reviewerTiles.PendingReviews) {
      this.gridColumns = this.gridColumns.filter(
        (column) =>
          column.name !== this.columnName.PendingReviewer &&
          column.name !== this.columnName.LastStatus &&
          column.name !== this.columnName.DateOfLastStatusChange
      );
    }
    if (this.selectedTileCode === this.reviewerTiles.PendingReviewsOthers) {
      this.gridColumns = this.gridColumns.filter(
        (column) =>
          column.name !== this.columnName.SubmittedDate &&
          column.name !== this.columnName.Actions &&
          column.name !== this.columnName.LastStatus &&
          column.name !== this.columnName.DateOfLastStatusChange
      );
    }

    if (
      this.selectedTileCode === this.reviewerTiles.NCAPendingApproval ||
      this.selectedTileCode === this.reviewerTiles.CCDueNow
    ) {
      this.gridColumns = [];
    }

    if (this.selectedTileCode === this.reviewerTiles.RecentReviews) {
      this.gridColumns = this.gridColumns.filter(
        (column) =>
          column.name !== this.columnName.SubmittedDate &&
          column.name !== this.columnName.Actions &&
          column.name !== this.columnName.PendingReviewer
      );
    }

    // Adjust column widths dynamically excluding the expand-collapse icon column
    const totalColumns = this.gridColumns.length;
    const baseWidth = 87 / totalColumns; // 87% to leave space for the expand-collapse icon column
    this.gridColumns.forEach((column) => {
      if (
        column.name !== this.columnName.ActionByName &&
        column.name !== this.columnName.ClientName &&
        column.name !== this.columnName.ParentClientName &&
        column.name != this.columnName.SubmissionTitle &&
        column.name != this.columnName.Focus
      ) {
        column.width = `${baseWidth}%`;
      }

      if (column.name === this.columnName.Focus) column.width = column.minWidth;
    });

    this.getActionButtons();
  }

  onPageChanged(value: number) {
    this.gotoPage = value - 1;
    this.refreshGrid();
  }

  onValueChanged(item: number) {
    this.itemsPerPage = item;
    this.gotoPage = 0;
    this.refreshGrid();
  }

  gridUpdatedEvent(event: {
    sortParamaterCode: number;
    isAscending: boolean;
    dashboardfilters: any[];
  }) {
    const shouldRefreshGrid =
      this.sortParamaterCode !== event.sortParamaterCode ||
      this.isAscending !== event.isAscending ||
      JSON.stringify(this.reviewerDashboardfilters) !==
        JSON.stringify(event.dashboardfilters);

    this.sortParamaterCode = event.sortParamaterCode;
    this.isAscending = event.isAscending;
    this.reviewerDashboardfilters = event.dashboardfilters;
    if (shouldRefreshGrid) {
      this.refreshGrid();
    }
  }

  getActionButtons(): void {
    this.buttonOptionsList = [
      {
        label: 'Start Review',
        action: 'StartReview',
        condition: this.selectedTileCode === this.reviewerTiles.PendingReviews,
      },
      {
        label: 'Start Review',
        action: 'StartReview',
        condition:
          this.selectedTileCode === this.reviewerTiles.NCAPendingApproval,
      },
      {
        label: 'Start Review',
        action: 'StartReview',
        condition: this.selectedTileCode === this.reviewerTiles.CCDueNow,
      },
    ];
  }

  buttonSelected(event: { value: string; rowItem: any }) {
    const { value, rowItem } = event;

    if (value == 'StartReview') {
      this.goToSubmissionSummary(rowItem);
    }
  }

  public goToSubmissionSummary(id: string) {
    // let url = this.rootUrl + 'client/'+ clientNumber +'/status';
    // window.open(url, '_blank');
  }
}
