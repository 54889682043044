import {
  deloitteStandardELSOWTemplateReferredGroupOptions,
  gbtOptions,
  generalBusinessTermsGroupOptions,
  groupToolTipOptions,
  multiSelectOptions,
  opportunityComplexityOptionsOriginal,
  options,
  productAndToolsOptionsOriginal,
  radioBtnsOriginal,
  radioGroupOptions,
  selectOptions,
  sensitiveDataOptionsOriginal,
  textareaOptions,
  useOfExistingMSAsOrContractMakerGBTsGroupOptions,
} from './complexity-questions-setting';
import { SubmissionService } from './../../../http/intake/submission.service';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MultiSelectItem } from '@usitsdasdesign/dds-ng/multi-select';
import { Size, Themes } from '@usitsdasdesign/dds-ng/shared';
import { RadioButton } from '@usitsdasdesign/dds-ng/radio';
import { OpportunityDetailsNavModel } from '../../../common/models/opportunity-details.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import { IntakeConstant } from '../../constants/intake-constant';
import { complexityQuestionsData } from '../../../common/models/complexity-question-data.model';
import { commonSectionForSingleCoCheck } from '../../common/intake.util';
@Component({
  selector: 'app-complexity-questions',
  templateUrl: './complexity-questions.component.html',
  styleUrl: './complexity-questions.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ComplexityQuestionsComponent
  implements OnInit, OnChanges, OnDestroy
{
  model: complexityQuestionsData = {} as complexityQuestionsData;
  @Input() submissionData: OpportunityDetailsNavModel[] = [];
  @Input() OpportunityID: string | null = '';
  saveComplexityModule!: Saver | null;
  complexityForm!: FormGroup;
  #destroy$: Subject<any> = new Subject<any>();
  opportunityComplexityOptions: MultiSelectItem[] = [];
  productAndToolsOptions: MultiSelectItem[] = [];
  sensitiveDataOptions: MultiSelectItem[] = [];
  radioBtns: RadioButton[] = [];
  gbtOptions = gbtOptions;
  maxSizeMemberFirms = IntakeConstant.TEXTAREA_CHAR_LIMIT;
  opportunityComplexityOptionsOriginal = opportunityComplexityOptionsOriginal;
  productAndToolsOptionsOriginal = productAndToolsOptionsOriginal;
  sensitiveDataOptionsOriginal = sensitiveDataOptionsOriginal;
  multiSelectOptions = multiSelectOptions;
  selectOptions = selectOptions;
  options = options;
  groupToolTipOptions = groupToolTipOptions;
  radioGroupOptions = radioGroupOptions;
  textareaOptions = textareaOptions;
  deloitteStandardELSOWTemplateReferredGroupOptions =
    deloitteStandardELSOWTemplateReferredGroupOptions;
  useOfExistingMSAsOrContractMakerGBTsGroupOptions =
    useOfExistingMSAsOrContractMakerGBTsGroupOptions;
  generalBusinessTermsGroupOptions = generalBusinessTermsGroupOptions;
  radioBtnsOriginal = radioBtnsOriginal;
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  
  constructor(
    private readonly fb: FormBuilder,
    private readonly autosaverService: AutosaverService,
    private readonly submissionService: SubmissionService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.updateOptions();
    this.emitFormData();
    this.submissionService.updateComplexQuestionStatus(
      this.complexityForm.valid
    );
    this.complexityForm.valueChanges
      .pipe(takeUntil(this.#destroy$))
      .subscribe(() => {
        this.submissionService.updateComplexQuestionStatus(
          this.complexityForm.valid
        );
      });
    let autoSaverOptions = {
      onSuccess: (saver: any, result: complexityQuestionsData) => {
        return this.autoSaveHandler();
      },
    } as IAutosaverOptions;

    this.saveComplexityModule = this.autosaverService.newSaver(
      'model',
      this,
      (model) => this.saveDetails(model),
      autoSaverOptions
    );

    this.saveComplexityModule.start();

    this.complexityForm.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        takeUntil(this.#destroy$)
      )
      .subscribe((value) => {
        this.emitFormData();
        this.saveComplexityModule?.saveNow();
      });

    this.getExistingFormData(this.OpportunityID);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['submissionData'] &&
      !changes['submissionData'].isFirstChange()
    ) {
      this.updateOptions();
    }
  }

  ngOnDestroy(): void {
    this.#destroy$.next('');
    this.#destroy$.complete();
  }
  autoSaveHandler(): void {
    //if refresh is needed after save API, can be done here;
  }

  initializeForm(): void {
    this.complexityForm = this.fb.group({
      opportunityComplexity: [[], Validators.required],
      regulatoryConsiderations: ['', []],
      productAndTools: [[], Validators.required],
      thirdPartyInvolvement: ['', Validators.required],
      memberFirmInvolvement: ['', Validators.required],
      memberFirmNames: ['', []],
      sensitiveData: [[], Validators.required],
      dataInDeloittesOrDeloittesHostedEnvironment: ['', Validators.required],
      deloitteStandardELSOWTemplateReferred: ['', Validators.required],
      useOfExistingMSAsOrContractMakerGBTs: ['', Validators.required],
      generalBusinessTerms: ['', []],
    });

    this.complexityForm
      .get('opportunityComplexity')
      ?.valueChanges.pipe(takeUntil(this.#destroy$))
      .subscribe((selected) => {
        const hasRegulatory = selected.some(
          (item: MultiSelectItem) =>
            item.label === IntakeConstant.REGULATORY_CONSIDERATION
        );
        const regulatoryControl = this.complexityForm.get(
          'regulatoryConsiderations'
        );
        if (hasRegulatory) {
          regulatoryControl?.setValidators([Validators.required]);
        } else {
          regulatoryControl?.clearValidators();
          regulatoryControl?.setValue('');
        }
        regulatoryControl?.updateValueAndValidity();
      });

    this.complexityForm
      .get('memberFirmInvolvement')
      ?.valueChanges.pipe(takeUntil(this.#destroy$))
      .subscribe((value) => {
        const memberFirmNamesControl =
          this.complexityForm.get('memberFirmNames');
        if (value === IntakeConstant.YES) {
          memberFirmNamesControl?.setValidators([Validators.required]);
        } else {
          memberFirmNamesControl?.clearValidators();
          memberFirmNamesControl?.setValue('');
        }
        memberFirmNamesControl?.updateValueAndValidity();
      });

    this.complexityForm
      .get('useOfExistingMSAsOrContractMakerGBTs')
      ?.valueChanges.pipe(takeUntil(this.#destroy$))
      .subscribe((value) => {
        const generalBusinessTermsControl = this.complexityForm.get(
          'generalBusinessTerms'
        );
        if (value === IntakeConstant.NO) {
          generalBusinessTermsControl?.setValidators([Validators.required]);
        } else {
          generalBusinessTermsControl?.clearValidators();
          generalBusinessTermsControl?.setValue('');
        }
        generalBusinessTermsControl?.updateValueAndValidity();
      });
  }

  updateOptions(): void {
    const includeNotYetDetermined = this.shouldIncludeNotYetDetermined();
    this.opportunityComplexityOptions = [
      ...this.opportunityComplexityOptionsOriginal,
    ];
    if (includeNotYetDetermined) {
      this.opportunityComplexityOptions.push({
        label: IntakeConstant.NOT_YET_DETERMINED,
      });
    }
    this.productAndToolsOptions = [...this.productAndToolsOptionsOriginal];
    if (includeNotYetDetermined) {
      this.productAndToolsOptions.push({
        label: IntakeConstant.NOT_YET_DETERMINED,
      });
    }
    this.sensitiveDataOptions = [...this.sensitiveDataOptionsOriginal];
    if (includeNotYetDetermined) {
      this.sensitiveDataOptions.push({
        label: IntakeConstant.NOT_YET_DETERMINED,
      });
    }
    this.radioBtns = [...this.radioBtnsOriginal];
    if (includeNotYetDetermined) {
      this.radioBtns.push({
        options: {
          label: IntakeConstant.NOT_YET_DETERMINED,
          theme: Themes.green,
        },
        value: IntakeConstant.NOT_YET_DETERMINED,
      });
    }
  }

  shouldIncludeNotYetDetermined(): boolean {
    if (!this.submissionData || this.submissionData.length === 0) {
      return false;
    }
    const hasInvalidStatus = this.submissionData.some(
      (submission) =>
        submission.submissionStatusName === IntakeConstant.DISCONTINUED ||
        submission.submissionStatusName === IntakeConstant.PURGED
    );
    if (hasInvalidStatus) {
      return true;
    }
    const allPro = this.submissionData.every(
      (submission) => submission.submissionTypeCode === IntakeConstant.PROPOSAL
    );
    return allPro;
  }

  get hasRegulatoryConditions(): boolean {
    const selected = this.complexityForm.get('opportunityComplexity')?.value;
    return (
      selected?.some(
        (item: MultiSelectItem) =>
          item.label === IntakeConstant.REGULATORY_CONSIDERATION
      ) || false
    );
  }

  emitFormData(): void {
    const formData = this.complexityForm.value;
    const transformedData: complexityQuestionsData = {
      opportunityNumber: this.OpportunityID,
      opportunityComplexity:
        formData.opportunityComplexity
          ?.filter((item: MultiSelectItem) => item.value)
          .map((item: MultiSelectItem) => item.label)
          .join(';') || null,
      productsAndTools:
        formData.productAndTools
          ?.filter((item: MultiSelectItem) => item.value)
          .map((item: MultiSelectItem) => item.label)
          .join(';') || null,
      sensitiveData:
        formData.sensitiveData
          ?.filter((item: MultiSelectItem) => item.value)
          .map((item: MultiSelectItem) => item.label)
          .join(';') || null,
      regulatoryConsiderations: formData.regulatoryConsiderations || null,
      thirdPartyInvolvement: formData.thirdPartyInvolvement || null,
      memberFirmInvolvement: formData.memberFirmInvolvement || null,
      memberFirmNames: formData.memberFirmNames || null,
      dataInDeloittesOrDeloittesHostedEnvironment:
        formData.dataInDeloittesOrDeloittesHostedEnvironment || null,
      deloitteStandardELSOWTemplateReferred:
        formData.deloitteStandardELSOWTemplateReferred || null,
      useOfExistingMSAsOrContractMakerGBTs:
        formData.useOfExistingMSAsOrContractMakerGBTs || null,
      generalBusinessTerms: formData.generalBusinessTerms || null,
    };
    this.model = transformedData;
  }

  saveDetails(model: complexityQuestionsData) {
    return this.submissionService.saveComplexitydata(model).toPromise();
  }

  getExistingFormData(opportunityID: string | null): void {
    if (opportunityID) {
      this.submissionService
        .getComplexityResponse(opportunityID)
        .pipe(takeUntil(this.#destroy$))
        .subscribe((data) => {
          if (data) {
            this.model = data;
            this.patchFormWithData(data);
            this.emitFormData();
          }
        });
    }
  }

  patchFormWithData(data: complexityQuestionsData): void {
    const createMultiSelectItems = (
      dataString: string | null | undefined
    ): MultiSelectItem[] => {
      if (!dataString) return [];
      return dataString
        .split(';')
        .filter((label) => label.trim().length > 0)
        .map((label) => ({
          label: label.trim(),
          value: true,
        }));
    };

    const opportunityComplexityItems = createMultiSelectItems(
      data.opportunityComplexity
    );
    const productAndToolsItems = createMultiSelectItems(data.productsAndTools);
    const sensitiveDataItems = createMultiSelectItems(data.sensitiveData);

    const shouldResetOpportunityComplexity = opportunityComplexityItems.some(
      (item) => item.label === IntakeConstant.NOT_YET_DETERMINED
    );
    const shouldResetProductAndTools = productAndToolsItems.some(
      (item) => item.label === IntakeConstant.NOT_YET_DETERMINED
    );
    const shouldResetSensitiveData = sensitiveDataItems.some(
      (item) => item.label === IntakeConstant.NOT_YET_DETERMINED
    );

    const finalOpportunityComplexity =
      shouldResetOpportunityComplexity &&
      this.shouldIncludeNotYetDetermined() === false
        ? []
        : opportunityComplexityItems;
    const finalProductAndTools =
      shouldResetProductAndTools &&
      this.shouldIncludeNotYetDetermined() === false
        ? []
        : productAndToolsItems;
    const finalSensitiveData =
      shouldResetSensitiveData && this.shouldIncludeNotYetDetermined() === false
        ? []
        : sensitiveDataItems;

    if (
      shouldResetOpportunityComplexity &&
      this.shouldIncludeNotYetDetermined() === false
    ) {
      data.regulatoryConsiderations = '';
    }

    let thirdPartyInvolvement = data.thirdPartyInvolvement || null;
    const shouldResetThirdPartyInvolvement =
      thirdPartyInvolvement === IntakeConstant.NOT_YET_DETERMINED ||
      thirdPartyInvolvement === IntakeConstant.YES_TEAMING;
    if (
      shouldResetThirdPartyInvolvement &&
      this.shouldIncludeNotYetDetermined() === false
    ) {
      thirdPartyInvolvement = '';
    }

    let memberFirmInvolvement = data.memberFirmInvolvement || null;
    const shouldResetMemberFirmInvolvement =
      memberFirmInvolvement === IntakeConstant.NOT_YET_DETERMINED;
    if (
      shouldResetMemberFirmInvolvement &&
      this.shouldIncludeNotYetDetermined() === false
    ) {
      memberFirmInvolvement = '';
      data.memberFirmNames = '';
    }

    let dataInDeloittesOrDeloittesHostedEnvironment =
      data.dataInDeloittesOrDeloittesHostedEnvironment || null;
    const shouldResetDataInDeloittesOrDeloittesHostedEnvironment =
      dataInDeloittesOrDeloittesHostedEnvironment ===
      IntakeConstant.NOT_YET_DETERMINED;
    if (
      shouldResetDataInDeloittesOrDeloittesHostedEnvironment &&
      this.shouldIncludeNotYetDetermined() === false
    ) {
      dataInDeloittesOrDeloittesHostedEnvironment = '';
    }

    let deloitteStandardELSOWTemplateReferred =
      data.deloitteStandardELSOWTemplateReferred || null;
    const shouldResetDeloitteStandardELSOWTemplateReferred =
      deloitteStandardELSOWTemplateReferred ===
      IntakeConstant.NOT_YET_DETERMINED;
    if (
      shouldResetDeloitteStandardELSOWTemplateReferred &&
      this.shouldIncludeNotYetDetermined() === false
    ) {
      deloitteStandardELSOWTemplateReferred = '';
    }

    let useOfExistingMSAsOrContractMakerGBTs =
      data.useOfExistingMSAsOrContractMakerGBTs || null;
    const shouldResetUseOfExistingMSAsOrContractMakerGBTs =
      useOfExistingMSAsOrContractMakerGBTs ===
      IntakeConstant.NOT_YET_DETERMINED;
    if (
      shouldResetUseOfExistingMSAsOrContractMakerGBTs &&
      this.shouldIncludeNotYetDetermined() === false
    ) {
      useOfExistingMSAsOrContractMakerGBTs = '';
      data.generalBusinessTerms = '';
    }

    this.complexityForm.patchValue({
      opportunityComplexity: finalOpportunityComplexity,
      productAndTools: finalProductAndTools,
      sensitiveData: finalSensitiveData,
      regulatoryConsiderations: data.regulatoryConsiderations || '',
      thirdPartyInvolvement: thirdPartyInvolvement,
      memberFirmInvolvement: memberFirmInvolvement,
      memberFirmNames: data.memberFirmNames || '',
      dataInDeloittesOrDeloittesHostedEnvironment:
        dataInDeloittesOrDeloittesHostedEnvironment,
      deloitteStandardELSOWTemplateReferred:
        deloitteStandardELSOWTemplateReferred,
      useOfExistingMSAsOrContractMakerGBTs:
        useOfExistingMSAsOrContractMakerGBTs,
      generalBusinessTerms: data.generalBusinessTerms || '',
    });

    this.emitFormData();
  }

  removeOpportunityComplexity(item: MultiSelectItem): void {
    const current = this.complexityForm.get('opportunityComplexity')
      ?.value as MultiSelectItem[];
    const updated = current.filter((i) => i !== item);
    this.complexityForm.get('opportunityComplexity')?.setValue(updated);
  }

  removeProductAndTools(item: MultiSelectItem): void {
    const current = this.complexityForm.get('productAndTools')
      ?.value as MultiSelectItem[];
    const updated = current.filter((i) => i !== item);
    this.complexityForm.get('productAndTools')?.setValue(updated);
  }

  removeSensitiveData(item: MultiSelectItem): void {
    const current = this.complexityForm.get('sensitiveData')
      ?.value as MultiSelectItem[];
    const updated = current.filter((i) => i !== item);
    this.complexityForm.get('sensitiveData')?.setValue(updated);
  }

  isOpportunityComplexityRequired(): boolean {
    if (commonSectionForSingleCoCheck(this.submissionData)) {
      return false;
    } else if (
      this.complexityForm.get('opportunityComplexity')?.invalid ||
      (this.hasRegulatoryConditions &&
        this.complexityForm.get('regulatoryConsiderations')?.invalid)
    ) {
      return true;
    } else {
      return false;
    }
  }

  isFieldRequired(control: AbstractControl | null): boolean {
    if (commonSectionForSingleCoCheck(this.submissionData)) {
      return false;
    } else if (control != null && control?.invalid) {
      return true;
    } else {
      return false;
    }
  }

  isMemberFirmInvolvementRequired(): boolean {
    if (commonSectionForSingleCoCheck(this.submissionData)) {
      return false;
    } else if (
      this.complexityForm.get('memberFirmInvolvement')?.invalid ||
      (this.complexityForm.get('memberFirmInvolvement')?.value ===
        IntakeConstant.YES &&
        this.complexityForm.get('memberFirmNames')?.invalid)
    ) {
      return true;
    } else {
      return false;
    }
  }

  isUseOfExistingMSAsOrContractMakerGBTsRequired(): boolean {
    if (commonSectionForSingleCoCheck(this.submissionData)) {
      return false;
    } else if (
      this.complexityForm.get('useOfExistingMSAsOrContractMakerGBTs')?.invalid ||
          (this.complexityForm.get('useOfExistingMSAsOrContractMakerGBTs')?.value ===
          IntakeConstant.NO &&
            this.complexityForm.get('generalBusinessTerms')?.invalid)
    ) {
      return true;
    } else {
      return false;
    }
  }

}
