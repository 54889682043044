<progress-indicator></progress-indicator>

<div class="tiles-container" *ngIf="!isLoading">
  <app-page-header>
    <div class="margin-bottom-l">
      <div class="d-flex justify-content-between align-items-center">
        <div class="title inline-block font-size-32">Watchlist</div>
        <div class="button-section d-flex align-items-center text-right">

          <span class="clear-filters"
            [ngClass]="{'margin-right-s': isAddAndEditButtonVisible || isDownloadWatchlistButtonDisabled}"
            (click)="clearAllFilter()">Clear filters</span>

          <dds-button class="padding-right-s" [theme]="addwatchlistoptions.theme ?? ''" (click)="addNewWatchlist()"
            *ngIf="isAddAndEditButtonVisible" [kind]="addwatchlistoptions.kind ?? ''"
            [size]="addwatchlistoptions.size ?? ''" [width]="addwatchlistoptions.width ?? ''">Add client to
            watchlist</dds-button>

          <dds-button [theme]="downlaodbuttonoptions.theme ?? ''" [kind]="downlaodbuttonoptions.kind ?? ''"
            [size]="downlaodbuttonoptions.size ?? ''" [icon]="downlaodbuttonoptions.icon ?? ''"
            *ngIf="isDownloadWatchlistButtonDisabled" (click)="downloadWatchlistClientReport()"></dds-button>
        </div>
      </div>
    </div>
  </app-page-header>
  <div>

    <table class="dds-data-table">
      <thead class="dds-data-table__header">
        <tr>
          <th *ngFor="let column of defaultGridColumns"
            class="dds-data-table__header-cell dds-data-table__header-cell_sorting"
            [ddsSticker]="column.name != 'actions' ? customFilter : noFilter" [ngClass]="{
          'dds-data-table__header-cell_sorted': sortingState.property === column.name,
          'dds-data-table__header-cell_filter-shown': isShownFilter && column.name === filteringColumn.name
        }" [stickerPosition]="filterStickerOptions.stickerPosition ?? ''"
            [stickerIsOutsideClick]="filterStickerOptions.stickerIsOutsideClick ?? false"
            [stickerIndent]="filterStickerOptions.stickerIndent ?? 0"
            [stickerWidth]="filterStickerOptions.stickerWidth ?? 0" [attr.aria-expanded]="isShownFilter"
            aria-haspopup="listbox" (click)="toggleFilter(column)" (onHidden)="filterOnHidden()"
            #stickerDir="dds-sticker" [ngStyle]="{width: column.width, minWidth: column.minWidth }">
            <div class="dds-data-table__content">
              <span class="padding-right-xxs">{{column.header || column.name}}</span>
              <span *ngIf="column.name != 'actions'" class="icon-expand-grey"></span>
              <span *ngIf="sortingState.property === column.name && column.name != 'actions'"
                class="dds-icon dds-data-table__icon"
                [ngClass]="sortingState.ascending ? 'dds-icon_sort-up': 'dds-icon_sort-down' ">
              </span>
              <span *ngIf="column.filterable == true">
                <span *ngIf="column.name != 'actions'" class="dds-icon dds-icon_filter"></span>
              </span>
            </div>
          </th>
        </tr>
      </thead>

      <tbody class="dds-data-table__body">
        <ng-container *ngFor="let row of model">
          <tr class="dds-data-table__row" (click)="onRowClick(row, $event, row['clientNumber'])">
            <td *ngFor="let column of defaultGridColumns" class="dds-data-table__cell"
              [attr.data-column-name]="column.name" [ngStyle]="{width: column.width, minWidth: column.minWidth }"
              [ddsTooltip]="'Open record details'" [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
              [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
              [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
              [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
              [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
              [tooltipIsDisabled]="!isAddAndEditButtonVisible || (column.name === 'clientName' || column.name === 'parentclientName' || column.name === 'actions')"
              [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''" ddsFocus
              [tooltipMaxWidth]="clientTooltipOptions.tooltipMaxWidth ?? 0">
              <!--TODO : add redirection logic once client details page is added-->
              <div *ngIf="column.name == 'clientName'" class="client-name-container"
                (click)="gotoClientStatus(row['clientNumber'])">
                <a class="client-link padding-bottom-xxs"
                    [ddsTooltip]="row['isParentClient']? row['subsidiaryInWatchlistCount'] + ' of ' + row['subsidiaryCount'] + ' subsidiaries on the watchlist' :'Subsidiary client'"
                    [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
                    [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                    [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                    [tooltipPosition]="clientNumberTooltip.tooltipPosition"
                    [tooltipIndent]="clientNumberTooltip.tooltipIndent"
                    [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                    [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''" ddsFocus
                    [tooltipMaxWidth]="clientTooltipOptions.tooltipMaxWidth ?? 0">
                  {{ row[column.name] ? row[column.name] : '-' }}
                </a>
                <span *ngIf="row.isNCANeverEvaluated" [ddsTooltip]="'NCA never evaluated'"
                  [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? 'hover'"
                  [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                  [tooltipSize]="clientTooltipOptions.tooltipSize ?? ''"
                  [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                  [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                  [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                  [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''" ddsFocus
                  [tooltipMaxWidth]="clientTooltipOptions.tooltipMaxWidth ?? 0">
                  <span class="padding-left-m client-icon"></span>
                </span><br/>
                <span class="client-number" >SAP No: {{row['clientNumber']}}</span><br/>
                <span class="client-block-status" *ngIf="row['isMarkedForDeletion']; else swiftClientStatus">
                  [{{row['swiftClientStatus'] === 'Blocked' || row['swiftClientStatus'] === 'Duplicate' ? 
                  row['swiftClientStatus'] + ' - ' + markForDeletionText : 
                  markForDeletionText }}]
                </span>
                <ng-template #swiftClientStatus>
                  <span  class="client-block-status" *ngIf="row['swiftClientStatus'] == 'Blocked' || row['swiftClientStatus'] == 'Duplicate'" >
                    [{{row['swiftClientStatus']}}]
                  </span>
                </ng-template>
              </div>

              <!--TODO : add redirection logic once client details page is added-->
              <div *ngIf="column.name == 'parentclientName'"
                (click)="row[column.name] ? gotoParentClientTab(row['clientNumber']) : null">
                <a *ngIf="row[column.name]; else noClientName" class="client-link padding-bottom-xxs">
                  {{ row[column.name] }}
                </a><br/>
                <span *ngIf="row['parentClientNumber']" class="client-number" >SAP No: {{row['parentClientNumber']}}</span>
                <ng-template #noClientName>
                  <span>-</span>
                </ng-template>
              </div>

              <div *ngIf="column.name === 'watchlistStatusName'">
                <span [ngClass]="{
                      'serve-with-conditions-container': row['watchlistStatusName'] === 'Serve with conditions',
                      'do-not-serve-container': row['watchlistStatusName'] === 'Do not serve'}">
                  {{ row[column.name] ? row[column.name] : '-' }}
                </span>
              </div>

              <div *ngIf="column.name === 'statusComment'">
                <span class="text-ellipsis" #statusCommentText>
                  {{ row[column.name] ? row[column.name] : '-' }}
                </span>
                <span class="show-more-button" *ngIf="isTextOverflowing(statusCommentText)"
                  (click)="clickshowmore(row,$event)">Show more</span>
              </div>

              <div *ngIf="column.name === 'modifiedBy'">
                <span>{{ row.modifiedByName ? row.modifiedByName : handleHyphen(row,'modifiedBy') }}</span>
                <div>{{ row.modifiedDate ? row.modifiedDate : handleHyphen(row,'modifiedDate') }}</div>
              </div>

              <div *ngIf="column.name === 'industry'">
                <span>{{ row[column.name] ? row[column.name] : '-' }}</span>
              </div>

              <div *ngIf="column.name === 'sector'">
                <span>{{ row.industrySector ? row.industrySector : '-' }}</span>
              </div>

              <div *ngIf="column.name === 'actions'">
                <div id="grid-action-buttons-container" class="flex-container">
                  <div id="grid-action-button-dropdown" class="action-button-dropdown">
                    <dds-dropdown [theme]="dropdownOptions.theme ?? ''" [kind]="dropdownOptions.kind ?? ''"
                      [size]="dropdownOptions.size ?? ''" [label]="dropdownOptions.label ?? ''"
                      [width]="dropdownOptions.width ?? ''" [icon]="dropdownOptions.icon ?? ''"
                      [disabled]="dropdownOptions.disabled ?? false" [stickerWidth]="dropdownOptions.stickerWidth ?? 0"
                      (itemSelected)="actionButtonSelected($event,row['clientNumber'], row['watchlistId'], row['clientName'])">
                      <ng-container *ngFor="let item of dropdownItems">
                        <dds-dropdown-item [value]="item.value" [counter]="item.counter ?? 0"
                          [disabled]="item.disabled ?? false">{{item.heading}}</dds-dropdown-item>
                      </ng-container>
                    </dds-dropdown>
                  </div>
                </div>
              </div>

            </td>

          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <ng-template #customFilter>
    <dds-filter [items]="filterData" [theme]="filterOptions.theme ?? ''" [template]="customFilterTemp"
      [propName]="filterOptions.propName ?? ''" #ddsFilter></dds-filter>
  </ng-template>

  <ng-template #customFilterTemp let-ctx="ctx">

    <div class="" *ngIf="filteringColumn.name !== 'watchlistStatusName'">

      <span *ngIf="filteringColumn.name !== 'statusComment'">
        <span class="dds-filter__title dds-filter__section">Sorting</span>
        <div class="dds-filter__container dds-filter__container_sorting">
          <button class="dds-filter__item dds-filter__section dds-filter__section_sorting" (click)="filterSorted(true)">
            <span class="dds-filter__icon dds-icon dds-icon_sort-up"></span>
            <span class="dds-filter__text">Sort A–Z</span>
          </button>
          <button class="dds-filter__item dds-filter__section dds-filter__section_sorting"
            (click)="filterSorted(false)">
            <span class="dds-filter__icon dds-icon dds-icon_sort-down"></span>
            <span class="dds-filter__text">Sort Z–A</span>
          </button>
        </div>
      </span>
      <span class="dds-filter__title dds-filter__section">Filter</span>
      <dds-search class="dds-filter__search dds-filter__section" [isInverse]="ctx.isInverse"
        (keydown)="ctx.tabOut(searchField, $event)"
        [placeholder]="filteringColumn.name === 'clientName' || filteringColumn.name === 'parentclientName' ? 'Search by name or id' : 'Search'"
        [(ngModel)]="filteringColumn.searchValue" #searchField></dds-search>
    </div>

    <div class="" *ngIf="filteringColumn.name === 'watchlistStatusName'">
      <div class="dds-filter__container dds-filter__container_checkbox" #wrap>
        <dds-checkbox class="dds-filter__section dds-filter__section_checkbox" [label]="ctx.allBtnLabel"
          [isIndeterminate]="!isAllFilterSelected" [value]="isAllFilterSelected" [theme]="ctx.theme"
          (keydown)="ctx.tabOut(checkbox, $event);" (stateChanged)="selectAllFilter($event)" #checkbox></dds-checkbox>
        <dds-checkbox *ngFor="let filterItem of filterData; let index = index; trackBy: ctx.trackByFn;"
          class="dds-filter__section dds-filter__section_checkbox"
          [ngClass]="{ 'dds-filter__section_hidden': filterItem.hidden }" [value]="filterItem['isChecked']"
          [label]="filterItem[ctx.propName]" [theme]="ctx.theme" (keydown)="ctx.tabOut(checkbox, $event);"
          (stateChanged)="selectFilterItem($event, filterItem)" #checkbox></dds-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-5"></div>
      <div class="col-7">
        <div class="dds-filter__footer dds-filter__section dds-filter__footer--end"
          style="display: flex; justify-content: flex-end;">
          <dds-button width="full" [kind]="ctx.cancelBtnOptions.kind" [theme]="ctx.theme" [isInverse]="ctx.isInverse"
            class="dds-filter__btn dds-filter__item" (clicked)="cancel(filteringColumn.searchValue)"
            #cancelBtn>Cancel</dds-button>
          <dds-button width="full" [theme]="ctx.theme" [isInverse]="ctx.isInverse"
            class="dds-filter__btn dds-filter__item" (clicked)="filterApply(filteringColumn.searchValue)"
            (keydown)="ctx.tabOut(lastBtn, $event)" [disabled]="!isApplyButtonEnabled()" #lastBtn>Apply</dds-button>
        </div>
      </div>

    </div>

  </ng-template>

  <div class="image-section" *ngIf="model && model.length === 0">
    <div class="empty-state-container">
      <img src="assets/images/no_watchlist_clients.svg" alt="empty-state" class="empty-state-image">
      <div class="empty-state-text">There are no clients to display</div>
    </div>
  </div>

  <ng-template #noFilter></ng-template>
</div>

<div *ngIf="model && model.length > 0">
  <app-new-pagination-and-grid [tileCountforPage]="this.countforPagination" [pageUI]="this.gotoPage+1"
    (pageChanged)="onPageChanged($event)" (valueChanged)="onValueChanged($event)"></app-new-pagination-and-grid>
</div>
