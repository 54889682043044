import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FeeType,
  OpportunityDetailsEdit,
} from '../../../common/models/opportunityDetailsEdit';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import { ErrorState, Size } from '@usitsdasdesign/dds-ng/shared';
import { SubmissionService } from '../../../http/intake/submission.service';
import {
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  Subject,
  takeUntil,
} from 'rxjs';
import {
  configurationType,
  PermissionsObj,
} from '../../../common/models/common-models';
import { DatePipe } from '@angular/common';
import {
  DatepickerOptions,
  DatepickerStates,
} from '@usitsdasdesign/dds-ng/datepicker';
import { SaveOpportunityDetailsSection } from '../../../common/models/saveOpportunityDetails';

import {
  MultiSelectItem,
  MultiSelectOptions,
  SelectControlTypes,
  SelectType,
} from '@usitsdasdesign/dds-ng/multi-select';
import { IntakeConstant } from '../../constants/intake.constant';
import { lepDataValue } from '../opportunity-details-settings';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import {
  OpportunityDetailsNavModel,
  LepValue,
  LepName,
} from '../../../common/models/opportunity-details.model';
import { commonSectionForSingleCoCheck } from '../../common/intake.util';
import { Router } from '@angular/router';
import { BrowserTabConstant } from '../../../common/constants/browser-tab-constant';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CommonHelperService } from '../../../common/services/common-helper.service';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';
import { options } from './opportunity-details-section-helper';
import { CommonService } from '../../../http/intake/common.service';

@Component({
  selector: 'app-opportunity-details-section',
  templateUrl: './opportunity-details-section.component.html',
  styleUrl: './opportunity-details-section.component.less',
  providers: [DatePipe],
})
export class OpportunityDetailsSectionComponent implements OnInit, OnDestroy {
  feeTypeList: MultiSelectItem[] = [];
  feeTypeListData!: FeeType[];
  intakeOpportunityDetails!: OpportunityDetailsEdit;
  public saveopportunityDetailsModule!: Saver | null;
  isEditingLEP: boolean = false;
  isEditingTotalFees: boolean = false;
  textareaOptions: any = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  isEditingEndDate: boolean = false;
  isEditingStartDate: boolean = false;
  reviewerLepId: string = IntakeConstant.EMPTY_SPACE;
  lepValue: string = IntakeConstant.EMPTY_SPACE;
  tempLepValue: string = this.lepValue;
  tempStartDate: Date;
  totalFees: string = IntakeConstant.EMPTY_SPACE;
  isEditingFeeType: boolean = false;
  tempTotalFees: string = this.totalFees;
  defaultForEmptyFields = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
  commonPlaceholder = IntakeConstant.ENTER_TEXT_PLACEHOLDER;
  opportunityDescription: string = IntakeConstant.EMPTY_SPACE;
  tempEndDate: Date;
  startDate: string | null = IntakeConstant.EMPTY_SPACE;
  endDate: string | null = IntakeConstant.EMPTY_SPACE;
  updateLabel: string = IntakeConstant.EMPTY_SPACE;
  oportunityDetailsInfo: OpportunityDetailsEdit = {
    opportunityStatusName: IntakeConstant.EMPTY_SPACE,
    opportunityName: IntakeConstant.EMPTY_SPACE,
    opportunityNumber: IntakeConstant.EMPTY_SPACE,
    projectStartDate: IntakeConstant.EMPTY_SPACE,
    projectEndDate: IntakeConstant.EMPTY_SPACE,
    leadEngagementPartner: IntakeConstant.EMPTY_SPACE,
    totalFees: IntakeConstant.EMPTY_SPACE,
    feeType: [],
    clientNumber: IntakeConstant.EMPTY_SPACE,
    opportunityDescription: IntakeConstant.EMPTY_SPACE,
    jupiterURL: IntakeConstant.EMPTY_SPACE,
  };
  multiselectFeeTypesOptions: MultiSelectItem[] = [];
  lepData: LepValue = lepDataValue;
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  dateFormater: string = 'MM/dd/yyyy';
  opportunityDetailsFeeForm!: FormGroup;
  reloadMultiSelect: boolean = true;

  datePickerOptions: DatepickerOptions = {
    size: this.htmlElementSize,
    placeholder: 'Select date',
    format: this.dateFormater,
    minMode: DatepickerStates.day,
    isResponsive: true,
    isManualInput: false,
  };
  multiSelectOptions: MultiSelectOptions = {
    label: IntakeConstant.EMPTY_SPACE,
    size: this.htmlElementSize,
    description: IntakeConstant.EMPTY_SPACE,
    placeholder: 'Select Fee Types',
    type: SelectType.counter,
    controlType: SelectControlTypes.checkbox,
    theme: 'green',
    isInverse: false,
    disabled: false,
    isResponsive: false,
    isError: false,
    isRequired: false,
    displayTickAllBtn: false,
    errorMessage: IntakeConstant.EMPTY_SPACE,
    errorState: ErrorState.default,
    customClass: IntakeConstant.EMPTY_SPACE,
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: IntakeConstant.EMPTY_SPACE,
    stickerIsDisabled: false,
    stickerPosition: 'bottom-left',
    stickerIndent: 0,
    stickerCustomClass: IntakeConstant.EMPTY_SPACE,
  };

  // Flags to indicate if the field value was updated via Intake details (Eclipse)
  isLepUpdated: boolean = false;
  isStartDateUpdated: boolean = false;
  isEndDateUpdated: boolean = false;
  isTotalFeesUpdated: boolean = false;
  isFeeTypeUpdated: boolean = false;

  // New properties for holding the original values from Opportunity Details (Jupiter)
  originalLepValue: string = IntakeConstant.EMPTY_SPACE;
  originalStartDate: string | null = null;
  originalEndDate: string | null = null;
  originalTotalFees: string = IntakeConstant.EMPTY_SPACE;
  originalFeeTypes: FeeType[] = [];

  // Track unsynced fields for the warning message
  unsyncedFields: string[] = [];
  unsyncedWarningMessage: string = IntakeConstant.EMPTY_SPACE;

  options: TagOptions = options;
  emptyString: string = '';
  showJupiterFeeType: boolean = false;

  @Input() id: string = IntakeConstant.EMPTY_SPACE;
  @Input() submissionData: OpportunityDetailsNavModel[] = [];
  @Output() lepValueChange: EventEmitter<LepValue> =
    new EventEmitter<LepValue>();
  unsubscriber$: Subject<void> = new Subject<void>();
  @Input() configLabels: configurationType[] = [];
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;
  constructor(
    private readonly autosaverService: AutosaverService,
    private readonly submissionService: SubmissionService,
    private readonly datePipe: DatePipe,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly router: Router,
    private readonly commonHelperService: CommonHelperService,
    private readonly commonService: CommonService,
    private readonly fb: FormBuilder
  ) {
    this.tempStartDate = new Date();
    this.tempEndDate = new Date();
  }

  ngOnInit(): void {
    this.initializeForm();
    document.title = BrowserTabConstant.Browser_TabName_Default + this.id;
    this.commonService.enableAuditTrail(true);
    this.submissionService.changeSubmissionId({
      opportunitynumber: this.id,
      submissionid: null,
    });
    this.progressIndicator.show();
    this.submissionService.updateIsOpportunityDetailsCompletedStatus(true);
    forkJoin({
      opportunityDetails: this.submissionService.getOpportunityDetailsInfo(
        this.id
      ),
      intakeDetails: this.submissionService.getOpportunityDetailForSummary(
        this.id
      ),
    })
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: ({ opportunityDetails, intakeDetails }) => {
          this.oportunityDetailsInfo = opportunityDetails;
          this.submissionService.updateIsOpportunityDetailsCompletedStatus(
            this.oportunityDetailsInfo.isOpportunityDetailsCompleted || false
          );
          this.intakeOpportunityDetails = intakeDetails;
          this.oportunityDetailsInfo.jupiterURL =
            this.oportunityDetailsInfo.jupiterURL ?? IntakeConstant.EMPTY_SPACE;
          this.getFeeType();
          this.submissionService.updateDetailStatus(this.checkAllValuesValid());
          const autoSaverOptions = {
            onSuccess: (saver: any, result: SaveOpportunityDetailsSection) => {
              return this.autoSaveHandler();
            },
          } as IAutosaverOptions;

          this.saveopportunityDetailsModule = this.autosaverService.newSaver(
            'saveopportunitydetailsmodel',
            this,
            async () => this.saveOpportunityDetailsValues(this.reviewerLepId),
            autoSaverOptions
          );
          this.saveopportunityDetailsModule.start();

          this.opportunityDetailsFeeForm.valueChanges
            .pipe(
              debounceTime(300),
              distinctUntilChanged(
                (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
              ),
              takeUntil(this.unsubscriber$)
            )
            .subscribe(() => {
              this.emitFormData();
              this.saveopportunityDetailsModule?.saveNow();
            });

          this.progressIndicator.hide();
        },
        error: (err) => {
          this.progressIndicator.hide();
          console.error(
            'An error occurred while fetching opportunity details: ',
            err
          );
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['configLabels']) {
      this.getLabelConfiguration();
    }
  }

  initializeForm(): void {
    this.opportunityDetailsFeeForm = this.fb.group({
      feeType: [{ value: [], disabled: false }],
    });
  }

  emitFormData(): void {
    const formData = this.opportunityDetailsFeeForm.value;
    
    this.submissionService.updateDetailStatus(this.checkAllValuesValid());
  }

  removeMultiSelectItemFeeType(item: MultiSelectItem): void {
    const current = this.opportunityDetailsFeeForm.get('feeType')?.value;
    const updated = current.filter((i: MultiSelectItem) => i !== item);
    this.opportunityDetailsFeeForm.get('feeType')?.setValue(updated);
  }

  // TO DO: To be handled later
  public autoSaveHandler(): void { }

  getFeeType(): void {
    this.submissionService
      .getFeeTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: FeeType[]) => {
          if (response && response.length > 0) {
            this.feeTypeListData = response;
            this.multiselectFeeTypesOptions = response.map(
              (feeType: FeeType) => ({
                feeTypeId: feeType?.feeTypeId,
                label: feeType?.feeTypeName,
                value: false,
              })
            );
          }
          this.mergeOpportunityDetails();
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  private mergeOpportunityDetails(): void {
    const intake = this.intakeOpportunityDetails;
    const details = this.oportunityDetailsInfo;
    if (intake?.pursuitLeaderId) {
      this.reviewerLepId = intake.pursuitLeaderId;
    } else if (details?.pursuitLeaderId) {
      this.reviewerLepId = details.pursuitLeaderId;
    } else {
      this.reviewerLepId = IntakeConstant.EMPTY_SPACE;
    }
    // Opportunity Number
    this.id =
      intake?.opportunityNumber ||
      details?.opportunityNumber ||
      IntakeConstant.EMPTY_SPACE;
    // Start Date Processing
    const startDateIntake = intake?.projectStartDate
      ? new Date(intake.projectStartDate)
      : null;
    const startDateDetails = details?.projectStartDate
      ? new Date(details.projectStartDate)
      : null;
    // Use Intake if available; otherwise fall back to details.
    this.tempStartDate = startDateIntake || startDateDetails || new Date();
    this.startDate = this.tempStartDate
      ? this.datePipe.transform(this.tempStartDate, this.dateFormater)
      : IntakeConstant.EMPTY_SPACE;
    this.isStartDateUpdated = !!intake?.projectStartDate;
    if (
      startDateIntake &&
      startDateDetails &&
      startDateIntake.getTime() !== startDateDetails.getTime()
    ) {
      this.originalStartDate = startDateDetails
        ? this.datePipe.transform(startDateDetails, this.dateFormater)
        : IntakeConstant.EMPTY_SPACE;
      if (!this.unsyncedFields.includes('Start date')) {
        this.unsyncedFields.push('Start date');
      }
    }
    // End Date Processing
    const endDateIntake = intake?.projectEndDate
      ? new Date(intake.projectEndDate)
      : null;
    const endDateDetails = details?.projectEndDate
      ? new Date(details.projectEndDate)
      : null;
    this.tempEndDate = endDateIntake || endDateDetails || new Date();
    this.endDate = this.tempEndDate
      ? this.datePipe.transform(this.tempEndDate, this.dateFormater)
      : IntakeConstant.EMPTY_SPACE;
    this.isEndDateUpdated = !!intake?.projectEndDate;
    if (
      endDateIntake &&
      endDateDetails &&
      endDateIntake.getTime() !== endDateDetails.getTime()
    ) {
      this.originalEndDate = endDateDetails
        ? this.datePipe.transform(endDateDetails, this.dateFormater)
        : IntakeConstant.EMPTY_SPACE;
      if (!this.unsyncedFields.includes('End date')) {
        this.unsyncedFields.push('End date');
      }
    }
    // Lead Engagement Partner (LEP) Processing
    const lepIntake = intake?.leadEngagementPartner
      ? intake.leadEngagementPartner
      : null;
    const lepDetails = details?.leadEngagementPartner
      ? details.leadEngagementPartner
      : IntakeConstant.EMPTY_SPACE;
    if (lepIntake) {
      this.lepValue = lepIntake;
      this.isLepUpdated = true;
      if (lepDetails && lepIntake !== lepDetails) {
        this.originalLepValue = lepDetails;
        if (!this.unsyncedFields.includes('Lead Engagement Partner')) {
          this.unsyncedFields.push('Lead Engagement Partner');
        }
      }
    } else {
      this.lepValue = lepDetails;
      this.isLepUpdated = false;
    }
    this.lepData.lepReviewedBy =
      intake?.pursuitLeaderId ??
      details?.pursuitLeaderId ??
      IntakeConstant.EMPTY_SPACE;
    this.lepData.lepName = this.lepValue;
    this.lepValueChange.emit(this.lepData);

    // Total Fees Processing
    const totalFeesIntake = intake?.totalFees ? intake.totalFees : null;
    const totalFeesDetails = details?.totalFees
      ? details.totalFees
      : IntakeConstant.EMPTY_SPACE;
    if (totalFeesIntake) {
      this.totalFees = totalFeesIntake;
      this.isTotalFeesUpdated = true;
      if (totalFeesDetails && totalFeesIntake !== totalFeesDetails) {
        this.originalTotalFees = totalFeesDetails;
        if (!this.unsyncedFields.includes('Total Fees')) {
          this.unsyncedFields.push('Total Fees');
        }
      }
    } else {
      this.totalFees = totalFeesDetails;
      this.isTotalFeesUpdated = false;
    }
    this.tempTotalFees = this.totalFees;
    // For fee types, we compare arrays based on feeTypeId (if available) and label.
    let intakeFeeTypes: FeeType[] = [];
    let detailsFeeTypes: FeeType[] = [];
    if (intake?.feeType && intake.feeType.length > 0) {
      intakeFeeTypes = intake.feeType;
      this.feeTypeList = intakeFeeTypes.map((feeType: FeeType) => ({
        feeTypeId: feeType?.feeTypeId,
        label: feeType?.feeTypeName,
        value: true,
      }));
      this.isFeeTypeUpdated = true;
      this.opportunityDetailsFeeForm.patchValue({
        feeType: this.feeTypeList,
      });
    }
    if (details?.feeType && details.feeType.length > 0) {
      detailsFeeTypes = details.feeType;
      this.feeTypeList = detailsFeeTypes.map((feeType: FeeType) => ({
        feeTypeId: feeType?.feeTypeId,
        label: feeType?.feeTypeName,
        value: true,
      }));
      this.isFeeTypeUpdated = false;
    }
    const selectedOptions = this.multiselectFeeTypesOptions.filter(
      (option: any) => {
        return this.feeTypeList.some(
          (item: any) => item.feeTypeId === option.feeTypeId && item.value
        );
      }
    );
    selectedOptions.forEach((option: any) => {
      option.value = true;
    });
    this.multiselectFeeTypesOptions = this.multiselectFeeTypesOptions.map(
      (option) => {
        const selectedOption = selectedOptions.find(
          (selected) => selected['feeTypeId'] === option['feeTypeId']
        );
        return selectedOption ? selectedOption : option;
      }
    );
    this.reloadMultiSelect = !this.reloadMultiSelect;
    setTimeout(() => {
      this.reloadMultiSelect = !this.reloadMultiSelect;
    }, 300);
    // Compare fee types only if Intake has provided the fee types and details exists.
    this.originalFeeTypes = detailsFeeTypes;
    const feesDiffer =
      intakeFeeTypes.length !== detailsFeeTypes.length ||
      intakeFeeTypes.some((ft) => {
        return !detailsFeeTypes.find(
          (dft) =>
            dft.feeTypeId === ft.feeTypeId && dft.feeTypeName === ft.feeTypeName
        );
      });
    if (feesDiffer) {
      this.showJupiterFeeType = true;
      if (!this.unsyncedFields.includes('Fee Types')) {
        this.unsyncedFields.push('Fee Types');
      }
    }
    // Client Number & Opportunity Description
    this.oportunityDetailsInfo.clientNumber =
      intake?.clientNumber ??
      details?.clientNumber ??
      IntakeConstant.EMPTY_SPACE;
    this.opportunityDescription =
      intake?.opportunityDescription ??
      details?.opportunityDescription ??
      IntakeConstant.EMPTY_SPACE;
    // Build Warning Message if any fields are out-of-sync
    if (this.unsyncedFields.length > 0) {
      this.unsyncedWarningMessage =
        'The ' +
        this.unsyncedFields.join(', ') +
        (this.unsyncedFields.length === 1 ? ' is' : ' are') +
        ' different between Jupiter and Relay. To keep both systems in sync, either update Relay or edit the value(s) in Jupiter.';
    } else {
      this.unsyncedWarningMessage = IntakeConstant.EMPTY_SPACE;
    }
    this.submissionService.updateDetailStatus(this.checkAllValuesValid());
  }

  private checkAllValuesValid(): boolean {
    return (
      this.lepData.lepName !== IntakeConstant.EMPTY_SPACE &&
      this.opportunityDetailsFeeForm.value.feeType.length > 0 &&
      this.opportunityDescription !== IntakeConstant.EMPTY_SPACE
    );


  }

  getSelectedFeeTypeIds(): number[] {
    
    return this.feeTypeList
      .filter((selectedItem) => selectedItem.value)
      .map((selectedItem) => selectedItem['feeTypeId']);
  }

  onOpportunityNumberClick(): void {
    if (this.oportunityDetailsInfo?.jupiterURL) {
      window.open(this.oportunityDetailsInfo.jupiterURL, '_blank');
    }
  }

  async onLepEngagementPartnerSelected(event: LepName): Promise<void> {
    this.lepValue = event.displayName;
    this.reviewerLepId = event.reviewerId;
    await this.saveLEP();
    await this.saveOpportunityDetailsValues(this.reviewerLepId);
    await this.reloadCurrentRoute();
    this.submissionService.updateDetailStatus(this.checkAllValuesValid());
  }

  /**
   * Validates that the project start date is not later than the project end date.
   */
  private validateDates(): boolean {
    if (this.tempStartDate && this.tempEndDate) {
      return this.tempStartDate.getTime() <= this.tempEndDate.getTime();
    }
    return true;
  }

  async saveOpportunityDetailsValues(lepId: string): Promise<void> {
    // Validate dates
    if (!this.validateDates()) {
      this.progressIndicator.hide();
      return Promise.reject(
        'Validation Error: Project start date cannot be later than end date.'
      );
      return;
    }

    const adjustedStartDate = new Date(
      this.tempStartDate.getTime() -
      this.tempStartDate.getTimezoneOffset() * 60000
    );
    const adjustedEndDate = new Date(
      this.tempEndDate.getTime() - this.tempEndDate.getTimezoneOffset() * 60000
    );
    // TODO : TO be handled for other edits and autosave as a part of seperate story
    const feeType = this.opportunityDetailsFeeForm
      .get('feeType')
      ?.value?.map((item: any) => item.feeTypeId);
    const saveopportunitydetailsmodel = {
      opportunityNumber: this.id,
      pursuitLeaderId: lepId,
      projectStartDate: adjustedStartDate.toISOString(),
      projectEndDate: adjustedEndDate.toISOString(),
      totalFees: this.tempTotalFees,
      FeeTypeIds: feeType?.some((item: number) => item === null)? [] : feeType,
      opportunitydescription: this.commonHelperService.trimPrefixEmptySpace(
        this.opportunityDescription
      ),
    };

    const originalFeeTypeIds = this.originalFeeTypes.map(
      (item) => item.feeTypeId
    );

    const areFeeTypesSame =
      feeType.length === originalFeeTypeIds.length &&
      feeType.every((id: number) => originalFeeTypeIds.includes(id));

    if (areFeeTypesSame) {
      this.showJupiterFeeType = false;
    } else {
      this.showJupiterFeeType = true;
    }

    return new Promise<void>((resolve, reject) => {
      this.submissionService
        .updateOpportunityDetails(saveopportunitydetailsmodel)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: () => {
            this.progressIndicator.hide();
            resolve();
          },
          error: (err) => {
            this.progressIndicator.hide();
            console.error('An error occurred during submission: ', err);
            reject(err);
          },
        });
    });
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (this.isEditingLEP && !target.closest('#Lep-editor')) {
      this.saveLEP();
    }
    if (this.isEditingTotalFees && !target.closest('#TotalFees-editor')) {
      this.saveTotalFees();
    }
    if (this.isEditingEndDate && !target.closest('#EndDate-editor')) {
      this.saveEndDate();
    }
    if (this.isEditingStartDate && !target.closest('#StartDate-editor')) {
      this.saveStartDate();
    }
    if (this.isEditingFeeType && !target.closest('#Fee-Type-Options')) {
      this.saveFeeType();
    }
  }

  editLep(): void {
    this.isEditingLEP = true;
    this.tempLepValue = this.lepValue;
  }

  saveLEP(): void {
    this.isEditingLEP = false;
  }

  editTotalFees(): void {
    this.isEditingTotalFees = true;
    this.tempTotalFees = this.totalFees;
  }

  async saveTotalFees(): Promise<void> {
    this.totalFees = this.tempTotalFees;
    this.isEditingTotalFees = false;
    await this.saveOpportunityDetailsValues(this.reviewerLepId);
    await this.reloadCurrentRoute();
    this.submissionService.updateDetailStatus(this.checkAllValuesValid());
  }

  editEndDate(): void {
    this.isEditingEndDate = true;
  }

  editFeeType(): void {
    this.isEditingFeeType = true;
  }

  async saveEndDate(): Promise<void> {
    this.endDate = this.datePipe.transform(this.tempEndDate, this.dateFormater);
    this.isEditingEndDate = false;
    await this.saveOpportunityDetailsValues(this.reviewerLepId);
    await this.reloadCurrentRoute();
    this.submissionService.updateDetailStatus(this.checkAllValuesValid());
  }

  editStartDate(): void {
    this.isEditingStartDate = true;
  }

  async saveStartDate(): Promise<void> {
    this.startDate = this.datePipe.transform(
      this.tempStartDate,
      this.dateFormater
    );
    this.isEditingStartDate = false;
    await this.saveOpportunityDetailsValues(this.reviewerLepId);
    await this.reloadCurrentRoute();
    this.submissionService.updateDetailStatus(this.checkAllValuesValid());
  }

  async saveFeeType(): Promise<void> {
    this.isEditingFeeType = false;
    await this.saveOpportunityDetailsValues(this.reviewerLepId);
    await this.reloadCurrentRoute();
    this.submissionService.updateDetailStatus(this.checkAllValuesValid());
  }

  getLabelConfiguration(): void {
    this.updateLabel =
      this.configLabels.find(
        (item) =>
          item.appConfigurationLabelKey ===
          'UPDATE_JUPITER_OPPORTUNITY_INFORMATION'
      )?.appConfigurationLabelValue || IntakeConstant.EMPTY_SPACE;
  }

  isOpportunityDescriptionRequiredIconVisible() {
    if (
      this.submissionData.length === 0 ||
      commonSectionForSingleCoCheck(this.submissionData)
    ) {
      return false;
    } else if (this.opportunityDescription !== IntakeConstant.EMPTY_SPACE) {
      return false;
    } else {
      return true;
    }
  }

  valueChanged(): void {
    this.submissionService.updateDetailStatus(this.checkAllValuesValid());
  }

  async reloadCurrentRoute(): Promise<void> {
    const currentUrl = this.router.url;
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    await this.router.navigate([currentUrl]);
  }

  showRequiredIcon(key: string): boolean {
    if (key === IntakeConstant.FEE_TYPE_KEY) {

      return (
        this.opportunityDetailsFeeForm.get('feeType')?.value.length === 0
      );
    }
    return (

      (this.oportunityDetailsInfo[key as keyof OpportunityDetailsEdit] ===
        null ||
        this.oportunityDetailsInfo[key as keyof OpportunityDetailsEdit] ===
        IntakeConstant.EMPTY_SPACE) &&
      (!this.intakeOpportunityDetails?.[key as keyof OpportunityDetailsEdit] ||
        this.intakeOpportunityDetails[key as keyof OpportunityDetailsEdit] ===
        IntakeConstant.EMPTY_SPACE)
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  feeTypeClickHandler(): void {
    // TODO: Find an alternate solution for this
    document.querySelector('.popup')?.setAttribute('id', 'Fee-Type-Options');
  }
}
