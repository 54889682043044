import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import {
  ButtonKind,
  ExtThemes,
  Size,
  TabThemeType,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { TabsOptions } from '@usitsdasdesign/dds-ng/tabs';
import { Subject } from 'rxjs';
import { TabWithCounterType } from '../../common/models/start-page/start-page.model';

@Component({
  selector: 'app-tabs-gridarea',
  templateUrl: './tabs-gridarea.component.html',
  styleUrl: './tabs-gridarea.component.less',
})
export class TabsGridareaComponent implements OnInit {
  @Input() tabs: TabWithCounterType[] = [];
  @Input() selectedTabCode!: string;
  @Input() showClearFilterButton: boolean = false;
  @Input() showDownloadButton: boolean = false;
  @Output() selectedTabChange: EventEmitter<string> =
    new EventEmitter<string>();

  tabContainerOptions: TabsOptions = {
    theme: Themes.green,
    themeType: TabThemeType.border,
    size: Size.lg,
    ariaLabel: 'Horizontal tabs',
    disabled: false,
    isResponsive: false,
  };
  searchOptions: SearchOptions = {
    placeholder: 'Search by Jupiter id',
    size: Size.lg,
    maxLength: 10,
  };
  clearButtonoptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.md,
    width: WidthState.fixed,
    disabled: false,
  };
  downlaodbuttonoptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: 'fixed',
    icon: 'dds-icon_download',
    role: 'button',
  };

  private readonly unsubscriber$: Subject<void> = new Subject<void>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tabs']?.currentValue) {
      this.selectedTabCode = this.tabs.length > 0 ? this.tabs[0].tabCode : '';
    }
  }

  clearAllFilter() {
    // add clear logic
  }

  downloadData() {
    // add download logic
  }

  onTabChange(code: string) {
    this.selectedTabChange.emit(code);
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
