import { DatepickerStates } from '@usitsdasdesign/dds-ng/datepicker';
import { Size, TabThemeType, Themes } from '@usitsdasdesign/dds-ng/shared';
import { TabsOptions } from '@usitsdasdesign/dds-ng/tabs';

export const AccordionOptions = {
  size: Size.md,
  disabled: false,
  isOpen: false,
  isMulti: false,
  isInverse: false,
};

export const DatepickerOptions = {
  placeholder: 'Select date',
  minMode: DatepickerStates.day,
  isResponsive: true,
  isManualInput: false,
};

export const tabContainerOptions: TabsOptions = {
  theme: Themes.green,
  themeType: TabThemeType.border,
  size: Size.lg,
  ariaLabel: 'Horizontal tabs',
  disabled: false,
  isResponsive: false,
};
