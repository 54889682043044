import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Column, Size } from '@usitsdasdesign/dds-ng/shared';
import {
  ADD_REVIEWER_BTN_OPTIONS,
  COMPLETE_REVIEW_OPTIONS,
  CONFIRM_OPTIONS,
  PRESCREEN_OPTIONS,
  RETURN_TO_REWORK_OPTIONS,
  RETURN_FOR_SELF_REVIEW_OPTIONS,
  riskManagerTableHeaderData,
  TOOLTIPS_OPTIONS,
} from './external-communication-workflow-submission.helper';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import {
  ExternalCommunicationUpdateReviewerListRequestBody,
  Reviewers,
} from '../../../../common/models/external-communication-submission-details-common-section.model';
import { UserService } from '../../../../http/user.service';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { GenericResponse } from '../../../../common/models/external-communication.model';
import { RoleEnum, RoleIdMapper } from '../../../../intake/constants/Role.enum';
import { BrowserTabConstant } from '../../../../common/constants/browser-tab-constant';
import { HttpErrorResponse } from '@angular/common/http';
import { PermissionsObj } from '../../../../common/models/common-models';
import { ExternalCommSubmissionStatus } from '../../../constants/submission-status.enum';
import { toastMessageTitle } from '../../../external-communications.helper';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';

@Component({
  selector: 'app-external-communication-workflow-submission',
  templateUrl: './external-communication-workflow-submission.component.html',
  styleUrl: './external-communication-workflow-submission.component.less',
})
export class ExternalCommunicationWorkflowSubmissionComponent
  implements OnInit, OnChanges, OnDestroy
{
  _BLANK: string = ExternalCommunicationConstant.EMPTY_SPACE;
  riskManagerTableHeader: Column[] = riskManagerTableHeaderData;
  options: ButtonOptions = ADD_REVIEWER_BTN_OPTIONS;

  @Input() ppmdName!: string;
  @Input() submissionId!: number;
  @Input() submissionStatus: string | null = this._BLANK;
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;
  @Input() isPrescreenVisible: boolean = false;
  @Input() isCompleteReviewVisible: boolean = false;
  @Input() isReturnToReworkVisible: boolean = false;
  @Input() isReturnForSelfReviewVisible: boolean = false;
  @Input() isConfirmButtonVisible: boolean = false;
  @Input() isSelfReview: boolean = false;
  @Input() isWorkflowPractitionerCompleteMsg: boolean = false;
  @Input() confirmedDate: string = this._BLANK;
  @Input() isDisabled: boolean = false;

  @Output() submissionLoadConfirmMsg: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() reviewerListValue: EventEmitter<Reviewers[]> = new EventEmitter<
    Reviewers[]
  >();
  @Output() confirmBtnClickEventEmitter: EventEmitter<Reviewers> =
    new EventEmitter<Reviewers>();
  @Output() prescreenClickHandler: EventEmitter<Reviewers> =
    new EventEmitter<Reviewers>();
  @Output() completeReviewClickHandler: EventEmitter<Reviewers> =
    new EventEmitter<Reviewers>();
  @Output() returnToReworkEventEmitter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() triggerGetSubmissionDetails: EventEmitter<void> =
    new EventEmitter<void>();
  
  reviewerList: Reviewers[] = [];
  updatedReviewerList: Reviewers[] = [];
  reviewerRole = RoleEnum;
  showResponsiblePractitionerPicker: boolean = false; // only used to show disabled picker when we don't have value for it
  showRiskManagerPeoplePicker: boolean = false;
  showSupportingRiskManagerPeoplePicker: boolean = false;
  showRMSupportPeoplePicker: boolean = false;
  showReviewerPeoplePicker: boolean = false;
  userRoleNames: string[] = [];
  currentLoggedInUser: string = this._BLANK;
  responsiblePractitionerConfirmation: boolean = false;
  riskManagerConfirmation: boolean = false;
  supportingRiskManagerConfirmation: boolean = false;

  tooltipOption: TooltipOptions = TOOLTIPS_OPTIONS;

  private readonly unsubscriber$: Subject<void> = new Subject<void>();

  addReviewerAccess: string[] = [
    this.reviewerRole.EXT_COMM_RISK_MANAGER,
    this.reviewerRole.EXT_COMM_SUPPORTING_RISK_MANAGER,
    this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL,
    this.reviewerRole.SYSTEM_ADMINISTRATOR,
  ];
  responsiblePractitionerAccess: string[] = [
    this.reviewerRole.EXT_COMM_RISK_MANAGER, // Locally assigned
    this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL, // Global
    this.reviewerRole.RM_SUPPORT_LOCAL, // Local
    this.reviewerRole.SYSTEM_ADMINISTRATOR,
  ];
  riskSupportBothAccess: string[] = [
    // Themselves
    this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL, // Global
    this.reviewerRole.RM_SUPPORT_LOCAL, // Local
    this.reviewerRole.SYSTEM_ADMINISTRATOR,
  ];
  rmSupportEditSubmissionStatusAccess: string[] = [
    ExternalCommSubmissionStatus.DRAFT,
    ExternalCommSubmissionStatus.RETURN_FOR_REWORK,
    ExternalCommSubmissionStatus.SUBMITTED,
    ExternalCommSubmissionStatus.RESUBMITTED,
  ];
  rmSupportEditRoleAccess: string[] = [
    this.reviewerRole.SYSTEM_ADMINISTRATOR,
    // this.reviewerRole.EXT_COMM_RISK_MANAGER,
    // this.reviewerRole.EXT_COMM_SUPPORTING_RISK_MANAGER,
    this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL,
  ];

  returnForSelfReviewBtnOptions: ButtonOptions = RETURN_FOR_SELF_REVIEW_OPTIONS;
  completeReviewBtnOptions: ButtonOptions = COMPLETE_REVIEW_OPTIONS;
  returnToReworkBtnOptions: ButtonOptions = RETURN_TO_REWORK_OPTIONS;
  confirmBtnOptions: ButtonOptions = CONFIRM_OPTIONS;
  prescreenBtnOptions: ButtonOptions = PRESCREEN_OPTIONS;
  defaultUiElementSize: Size = ExternalCommunicationConstant.UI_ELEMENT_SIZE;
  SELF_REVIEWED_PENDING_PPMD_CONFIRMATION: ExternalCommSubmissionStatus =
    ExternalCommSubmissionStatus.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION;
  roleIdMapper: any = {
    ...RoleIdMapper,
    [RoleEnum.EXT_COMM_RM_SUPPORT_GLOBAL]: 700, // Overriding the id of Ext RM Support with Local role id/
  };
  addingRPToDBOnInitialize: boolean = false;
  private readonly submissionId$: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);
  private readonly submissionStatus$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(
    private readonly externalCommunicationService: ExternalCommunicationService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_ExternalCommunications;

    combineLatest([
      this.userService.currentLoggedInUser$,
      this.submissionId$,
      this.submissionStatus$,
    ])
      .pipe(
        filter(
          ([user, submissionId, submissionStatus]) =>
            !!user && !!submissionId && !!submissionStatus
        ),
        takeUntil(this.unsubscriber$)
      )
      .subscribe(([user, submissionId]) => {
        this.currentLoggedInUser = user?.employeeId;
        this.userRoleNames =
          user?.employeeRoles?.map((role: any) => role.roleName) ?? [];

        if (
          this.userRoleNames?.length > 0 &&
          submissionId &&
          this.permissionObj
        ) {
          this.getReviewerList();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submissionId']?.currentValue) {
      this.submissionId$.next(changes['submissionId'].currentValue);
    }
    if (changes['submissionStatus']?.currentValue) {
      this.submissionStatus$.next(changes['submissionStatus'].currentValue);
      // TODO: Remove if backend is setting the statusReviewedDate
      if (
        this.submissionStatus === ExternalCommSubmissionStatus.RETURN_FOR_REWORK
      ) {
        const riskManager: Reviewers | undefined =
          this.updatedReviewerList.find(
            (reviewer: Reviewers) =>
              reviewer.roleName === this.reviewerRole.EXT_COMM_RISK_MANAGER
          );
        const riskManagerIndex = this.updatedReviewerList.findIndex(
          (reviewer: Reviewers) =>
            reviewer.roleName === this.reviewerRole.EXT_COMM_RISK_MANAGER
        );
        // TODO: statusReviewDate is not sent in the payload, need to confirm and refactor
        const approverBody = {
          submissionApproverId: riskManager?.submissionApproverId ?? null,
          reviewerId: riskManager?.reviewerId ?? null,
          displayName: riskManager?.displayName ?? this._BLANK,
          fullName: riskManager?.fullName ?? this._BLANK,
          emailId: riskManager?.emailId ?? this._BLANK,
          location: riskManager?.location ?? this._BLANK,
          reviewerRoleId: riskManager?.reviewerRoleId ?? 0,
          isRequired: false,
          roleName: riskManager?.roleName ?? this._BLANK,
          isActive: true,
          statusReviewedDate: null,
          submissionApproverDocumentId: null,
          submissionApproverStatus: null,
          submissionStatusId: 0,
        };
        if (riskManager) {
          this.updatedReviewerList.splice(riskManagerIndex, 1, riskManager);
        }
        // TODO: confirm, If riskManager is not present do we still make this call ?
        this.#updateReviewerList(this.createRequestBody(approverBody));
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    // TODO: Check this condition and update it
    this.updatedReviewerList.forEach((item: Reviewers, index: number) => {
      /*
       * - If a reviewer item is in edit mode and either has a non-null `submissionApproverId`
       *   and the click event target is not within the corresponding picker element.
       *   submissionApproverId will be null for reviewer whose data is not coming from API
       *   like Risk Manager/Supporting Risk Manager or a new reviewer.
       *   Risk Manager/Supporting Risk Manager should not close if data is not available for them.
       *   submissionApproverId is null & role is reviewer, it will be last element(add reviewer) remove it.,
       */
      if (!target.closest(`#picker-${item.reviewerRoleId}-${index}`)) {
        if (item.isInEditMode && item.submissionApproverId !== null) {
          item.isInEditMode = false;
          this.showReviewerPeoplePicker = false;
        } else if (
          item.submissionApproverId === null &&
          item.roleName === this.reviewerRole.EXT_COMM_REVIEWER
        ) {
          this.updatedReviewerList.splice(index, 1);
          this.showReviewerPeoplePicker = false;
        }
      }
    });
  }

  getReviewerList(): void {
    this.progressIndicator.show();
    this.externalCommunicationService
      .getExtCommReviewersData(this.submissionId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: Reviewers[]) => {
          this.setReviewerData(response);
          this.progressIndicator.hide();
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  setReviewerData(response: Reviewers[]): void {
    // Setting value as [] if response is undefined/null/''
    if (!response) {
      response = [];
    }
    this.reviewerList =
      response?.map((item: Reviewers) => {
        const roleName: string | null =
          item.reviewerRoleId === 700
            ? this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL
            : item.roleName;

        return {
          ...item,
          roleName: roleName,
          isInEditMode: false,
          isPeoplePickerDisabled: this.isPeoplePickerDisabled({
            ...item,
            roleName: roleName,
          }),
        };
      }) ?? [];
    this.updatedReviewerList = this.reviewerList;
    this.checkReviewerList();
    // TODO: Remove the sorting function if backend is going to handle.
    this.sortAndUpdateReviewerList();
    this.reviewerListValue.emit(this.updatedReviewerList);
  }

  toggleRow(reviewerRow: Reviewers, index: number): void {
    if (
      this.permissionObj['isExtCommRequiredCheckboxEnable'] &&
      !this.isWorkflowCheckboxDisabled(
        reviewerRow.reviewerRoleId,
        reviewerRow.roleName
      ) &&
      !reviewerRow.isInEditMode
    ) {
      const currentReviewer = this.updatedReviewerList[index];
      currentReviewer.isRequired = !currentReviewer.isRequired;
      this.updatedReviewerList.splice(index, 1, currentReviewer);
      this.#updateReviewerList(this.createRequestBody(currentReviewer));
    }
  }

  checkReviewerList(): void {
    interface RoleCheck {
      role: string;
      showPicker: keyof ExternalCommunicationWorkflowSubmissionComponent;
    }
    // Add any local roles here
    const rolesToCheck: RoleCheck[] = [
      {
        role: this.reviewerRole.RESPONSIBLE_PRACTITIONER,
        showPicker: 'showResponsiblePractitionerPicker',
      },
      {
        role: this.reviewerRole.EXT_COMM_RISK_MANAGER,
        showPicker: 'showRiskManagerPeoplePicker',
      },
      {
        role: this.reviewerRole.EXT_COMM_SUPPORTING_RISK_MANAGER,
        showPicker: 'showSupportingRiskManagerPeoplePicker',
      },
      {
        role: this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL,
        showPicker: 'showRMSupportPeoplePicker',
      },
    ];

    rolesToCheck.forEach(({ role, showPicker }: RoleCheck) => {
      const roleExists =
        this.updatedReviewerList?.some(
          (reviewer: Reviewers) =>
            reviewer.reviewerRoleId &&
            reviewer.reviewerRoleId === this.roleIdMapper?.[role]
        ) ?? false;

      (this as any)[showPicker] = !roleExists;
      if (!roleExists) {
        const newReviewerBody = this.createNewReviewer(role);
        this.updatedReviewerList.push({
          ...newReviewerBody,
          reviewerRoleId: this.roleIdMapper?.[role],
          roleName: role,
          isPeoplePickerDisabled:
            showPicker === 'showResponsiblePractitionerPicker'
              ? true
              : newReviewerBody.isPeoplePickerDisabled,
        });
      }
    });
  }

  sortAndUpdateReviewerList(): void {
    const rolesOrder: string[] = [
      this.reviewerRole.RESPONSIBLE_PRACTITIONER,
      this.reviewerRole.EXT_COMM_RISK_MANAGER,
      this.reviewerRole.EXT_COMM_SUPPORTING_RISK_MANAGER,
      this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL,
    ];

    const sortedReviewers: Reviewers[] = rolesOrder.flatMap((role: string) =>
      this.reviewerList.filter(
        (reviewer: Reviewers) => reviewer.roleName === role
      )
    );

    const remainingReviewers: Reviewers[] = this.reviewerList.filter(
      (reviewer: Reviewers) =>
        reviewer.roleName !== null && !rolesOrder.includes(reviewer.roleName)
    );

    this.updatedReviewerList = [
      ...sortedReviewers,
      ...remainingReviewers.sort(
        (reviewer1: Reviewers, reviewer2: Reviewers) =>
          (reviewer1.submissionApproverId ?? 0) -
          (reviewer2.submissionApproverId ?? 0)
      ),
    ];
  }

  onMemberSelected(
    data: any,
    roleId: number,
    role: string,
    submissionApproverId: number | null,
    index: number
  ): void {
    const approverBody = {
      submissionApproverId: submissionApproverId,
      reviewerId: data.reviewerId,
      displayName: data.displayName,
      fullName: data.fullName,
      emailId: data.email,
      location: data.location,
      reviewerRoleId: roleId,
      isRequired: true,
      roleName: role,
      isActive: true,
      statusReviewedDate: null,
      submissionApproverDocumentId: null,
      submissionApproverStatus: null,
      submissionStatusId: 0,
    };
    this.updatedReviewerList[index] = {
      ...approverBody,
      isInEditMode: false,
    };
    if (role === this.reviewerRole.EXT_COMM_RISK_MANAGER) {
      this.showRiskManagerPeoplePicker = false;
    } else if (role === this.reviewerRole.EXT_COMM_SUPPORTING_RISK_MANAGER) {
      this.showSupportingRiskManagerPeoplePicker = false;
    } else if (role === this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL) {
      this.showReviewerPeoplePicker = false;
    } else if (role === this.reviewerRole.EXT_COMM_REVIEWER) {
      this.showReviewerPeoplePicker = false;
    }
    this.showReviewerPeoplePicker = false;
    this.#updateReviewerList(this.createRequestBody(approverBody));
  }

  #updateReviewerList(
    requestBody: ExternalCommunicationUpdateReviewerListRequestBody
  ): void {
    this.progressIndicator.show();
    this.externalCommunicationService
      .postReviewersData(requestBody)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (res: GenericResponse) => {
          if (res.isSuccess) {
            this.progressIndicator.hide();
            this.getReviewerList();
            this.triggerGetSubmissionDetails.emit();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
          this.getReviewerList();
        },
      });
  }

  addReviewer(role: string): void {
    this.updatedReviewerList.push({
      ...this.createNewReviewer(role),
      reviewerRoleId: this.roleIdMapper?.[this.reviewerRole.EXT_COMM_REVIEWER],
      roleName: role,
    });
    this.showReviewerPeoplePicker = true;
  }

  createNewReviewer(role: string): Reviewers {
    const roleId = this.roleIdMapper?.[role];
    const body = {
      submissionApproverId: null,
      reviewerId: null,
      displayName: this._BLANK,
      fullName: this._BLANK,
      emailId: this._BLANK,
      location: null,
      reviewerRoleId: roleId,
      isRequired: true, // Previous condition: role === this.reviewerRole.RESPONSIBLE_PRACTITIONER
      roleName: role,
      isActive: true,
      statusReviewedDate: null,
      submissionApproverDocumentId: null,
      submissionApproverStatus: null,
      submissionStatusId: 0,
      isInEditMode: true,
    };

    return {
      ...body,
      isPeoplePickerDisabled: this.isPeoplePickerDisabled(body),
    };
  }

  createRequestBody(
    reviewer: Reviewers
  ): ExternalCommunicationUpdateReviewerListRequestBody {
    return {
      submissionid: this.submissionId,
      submissionapprovers: [
        {
          submissionApproverId: reviewer.submissionApproverId,
          reviewerId: reviewer.reviewerId,
          displayName: reviewer.displayName,
          fullName: reviewer.fullName,
          emailId: reviewer.emailId,
          location: reviewer.location,
          reviewerRoleId: reviewer.reviewerRoleId,
          isRequired: reviewer.isRequired,
          roleName: reviewer.roleName,
          submissionstatusid: reviewer.submissionStatusId,
          isactive: reviewer.isActive,
        },
      ],
    };
  }

  get addUserEnabled(): boolean {
    return (
      this.permissionObj['isExtCommAddReviewerEnable'] &&
      (this.userRoleNames.includes(this.reviewerRole.EXT_COMM_RISK_MANAGER) ||
        this.userRoleNames.includes(
          this.reviewerRole.EXT_COMM_SUPPORTING_RISK_MANAGER
        ) ||
        this.userRoleNames.includes(
          this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL
        ) ||
        this.userRoleNames.includes(this.reviewerRole.RM_SUPPORT_LOCAL) ||
        this.userRoleNames.includes(this.reviewerRole.SYSTEM_ADMINISTRATOR))
    );
  }

  get isAddReviewerDisabled(): boolean {
    return !this.addUserEnabled;
  }

  // TODO: Not being used, can be removed
  checkIfRoleVisible(roleId: number): boolean {
    const roleVisibility: { [key: number]: boolean } = {
      [this.roleIdMapper?.[this.reviewerRole.EXT_COMM_REVIEWER]]:
        this.permissionObj['isExtCommReviewerVisible'],
      [this.roleIdMapper?.[this.reviewerRole.EXT_COMM_RISK_MANAGER]]:
        this.permissionObj['isExtCommRiskManagerVisible'],
      [this.roleIdMapper?.[this.reviewerRole.EXT_COMM_SUPPORTING_RISK_MANAGER]]:
        this.permissionObj['isExtCommSupportingRiskManagerVisible'],
      [this.roleIdMapper?.[this.reviewerRole.RESPONSIBLE_PRACTITIONER]]: true, // TODO: update condition once we get confirmation from backend
      [this.roleIdMapper?.[this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL]]:
        this.permissionObj['isExtCommRMSupportVisible'],
      // Add conditions for local role, if any
    };

    return roleVisibility[roleId] ?? true;
  }

  isEditDeleteRowDisabled(reviewer: Reviewers): boolean {
    if (this.showReviewerPeoplePicker) {
      return true;
    }

    const responsiblePractitionerCheckCondition =
      this.submissionStatus === ExternalCommSubmissionStatus.DRAFT &&
      this.isSelfReview;

    const rmSupportCheckCondition: boolean =
      this.rmSupportEditSubmissionStatusAccess.includes(
        this.submissionStatus as string
      );

    const roleName: string | null = reviewer.roleName;
    const isCurrentUser: boolean =
      this.currentLoggedInUser === reviewer.reviewerId;

    const hasAccess: (accessList: string[]) => boolean = (
      accessList: string[]
    ) => this.compareArray(this.userRoleNames, accessList);

    const isValidSubmissionStatus: boolean = [
      ExternalCommSubmissionStatus.DRAFT,
      ExternalCommSubmissionStatus.SUBMITTED,
      ExternalCommSubmissionStatus.RESUBMITTED,
      ExternalCommSubmissionStatus.RETURN_FOR_REWORK,
    ].includes(this.submissionStatus as ExternalCommSubmissionStatus);

    // TODO: instead of having separate variable for confirmation we can add in rowData itself.
    const rolePermissions: { [key: string]: boolean } = {
      [this.reviewerRole.EXT_COMM_REVIEWER]:
        this.permissionObj['isExtCommReviewerEnable'] &&
        (isCurrentUser || hasAccess(this.addReviewerAccess)),

      [this.reviewerRole.EXT_COMM_RISK_MANAGER]:
        this.permissionObj['isExtCommRiskManagerEnable'] &&
        (isCurrentUser || hasAccess(this.riskSupportBothAccess)) &&
        isValidSubmissionStatus &&
        !this.riskManagerConfirmation, // TODO: Confirmation not updated anywhere, remove if not needed

      [this.reviewerRole.EXT_COMM_SUPPORTING_RISK_MANAGER]:
        this.permissionObj['isExtCommSupportingRiskManagerEnable'] &&
        (isCurrentUser || hasAccess(this.riskSupportBothAccess)) &&
        isValidSubmissionStatus &&
        !this.supportingRiskManagerConfirmation, // TODO: Confirmation not updated anywhere, remove if not needed

      [this.reviewerRole.RESPONSIBLE_PRACTITIONER]:
        (isCurrentUser || hasAccess(this.responsiblePractitionerAccess)) &&
        this.permissionObj['isExtCommPpmdEnable'] &&
        !this.responsiblePractitionerConfirmation && // TODO: Confirmation not updated anywhere, remove if not needed
        !responsiblePractitionerCheckCondition,

      [this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL]:
        this.permissionObj['isExtCommRMSupportEnable'] &&
        rmSupportCheckCondition,
    };

    return !rolePermissions[roleName ?? this._BLANK];
  }

  isPeoplePickerDisabled(reviewer: Reviewers): boolean {
    return this.isEditDeleteRowDisabled(reviewer);
  }

  editReviewer(reviewer: Reviewers, index: number): void {
    this.updateReviewerStatus(reviewer, index, true);
  }

  clearReviewer(reviewer: Reviewers, index: number): void {
    this.updateReviewerStatus(reviewer, index, false);
  }

  updateReviewerStatus(
    reviewer: Reviewers,
    index: number,
    isEditMode: boolean
  ): void {
    const roleName: string | null = reviewer.roleName;
    const emailId: string | null = reviewer.emailId;
    const reviewerId: string | null = reviewer.reviewerId;

    if (!this.isEditDeleteRowDisabled(reviewer)) {
      const rowData = this.updatedReviewerList[index];
      if (
        (rowData.emailId === emailId || rowData.reviewerId === reviewerId) &&
        rowData.roleName === roleName
      ) {
        if (isEditMode) {
          this.updatedReviewerList[index].isInEditMode = true;
          this.showReviewerPeoplePicker = true;
        } else {
          rowData.isActive = false;
          this.#updateReviewerList(this.createRequestBody(rowData));
        }
      }
    }
  }
  isWorkflowDisabled(): boolean {
    // TODO: Implement functionality
    return this.isDisabled;
  }

  isWorkflowCheckboxDisabled(
    roleId: number | null,
    roleName: string | null
  ): boolean {
    if (this.isSelfReview) {
      return true;
    }
    return (
      !this.permissionObj['isExtCommRequiredCheckboxEnable'] ||
      roleId === this.roleIdMapper?.[this.reviewerRole.RESPONSIBLE_PRACTITIONER]
    );
  }

  getValueOfRMConfig(roleName: string | null): boolean {
    return (
      roleName === this.reviewerRole.EXT_COMM_RISK_MANAGER ||
      roleName === this.reviewerRole.EXT_COMM_SUPPORTING_RISK_MANAGER
    );
  }

  getValueOfRMSupport(roleName: string | null): boolean {
    return (
      roleName === this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL ||
      roleName === this.reviewerRole.RM_SUPPORT_LOCAL
    );
  }

  getValueOfPpmd(roleName: string | null): boolean {
    return roleName === this.reviewerRole.RESPONSIBLE_PRACTITIONER;
  }

  getValueOfReviewer(roleId: number | null): boolean {
    return (
      Number(roleId) === Number(this.roleIdMapper[RoleEnum.EXT_COMM_REVIEWER])
    );
  }

  isRowVisible(reviewer: Reviewers): boolean {
    // Addreviewer can add reviewer, reviewerenable can only edit himself not add
    const roleVisibilityMap: any = {
      [this.reviewerRole.RESPONSIBLE_PRACTITIONER]: true, // TODO: Need to update the condition once permission is created.
      [this.reviewerRole.EXT_COMM_RISK_MANAGER]:
        this.permissionObj['isExtCommRiskManagerEnable'] ||
        (this.permissionObj['isExtCommRiskManagerVisible'] &&
          !reviewer.isInEditMode),
      [this.reviewerRole.EXT_COMM_SUPPORTING_RISK_MANAGER]:
        this.permissionObj['isExtCommSupportingRiskManagerEnable'] ||
        (this.permissionObj['isExtCommSupportingRiskManagerVisible'] &&
          !reviewer.isInEditMode),
      [this.reviewerRole.EXT_COMM_RM_SUPPORT_GLOBAL]:
        this.permissionObj['isExtCommRMSupportEnable'] ||
        (this.permissionObj['isExtCommRMSupportVisible'] &&
          !reviewer.isInEditMode),
      [this.reviewerRole.EXT_COMM_REVIEWER]:
        this.permissionObj['isExtCommReviewerEnable'] ||
        (this.permissionObj['isExtCommReviewerVisible'] &&
          !reviewer.isInEditMode) ||
        (this.permissionObj['isExtCommAddReviewerEnable'] &&
          reviewer.submissionApproverId === null),
    };
    return roleVisibilityMap[reviewer.roleName ?? this._BLANK] ?? true;
  }

  compareArray(arr1: string[], arr2: string[]): boolean {
    return arr2.some((value) => arr1.includes(value));
  }

  returnToReworkBtnClick(): void {
    this.returnToReworkEventEmitter.emit(true);
  }

  completeReviewBtnClick(data: Reviewers): void {
    this.completeReviewClickHandler.emit(data);
  }

  preScreenBtnClick(data: Reviewers): void {
    this.prescreenClickHandler.emit(data);
  }

  returnForSelfReviewBtnClick(): void {
    this.progressIndicator.show();
    this.externalCommunicationService
      .updateExtCommSubmissionStatus(
        this.submissionId,
        ExternalCommunicationConstant.EXT_COMM_SUBMISSION_STATUS_MAPPER[
          this.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION
        ]
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (res: GenericResponse) => {
          this.progressIndicator.hide();
          if (res.isSuccess) {
            this.submissionLoadConfirmMsg.emit(
              toastMessageTitle[
                ExternalCommunicationConstant
                  .RETURN_TO_SELF_REVIEW_TOAST_MESSAGE
              ]
            );
          }
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error(
            'An error occurred while updating submission status: ',
            err
          );
        },
      });
  }

  isToShowResponSiblePractintionerMsg(): boolean {
    return (
      this.submissionStatus?.toLocaleLowerCase().trim() ==
      this.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION.toLocaleLowerCase().trim()
    );
  }

  confirmBtnClick(reviewer: Reviewers): void {
    this.confirmBtnClickEventEmitter.emit(reviewer);
  }

  ngOnDestroy(): void {
    this.submissionId$.next(null);
    this.submissionStatus$.next(null);
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
