<div
  *ngIf="unsyncedWarningMessage"
  class="padding-right-m padding-left-s info font-size-14 line-height-sm mb-3"
>
  <div class="font-bold-600 text-align-left">Action Required</div>
  <div class="margin-top-xs text-align-left">
    {{ unsyncedWarningMessage }}
  </div>
</div>

<div
  [autosave-module]="saveopportunityDetailsModule"
  class="d-flex flex-column"
>
  <div class="d-flex flex-row row-distribution">
    <div class="d-flex flex-column">
      <div
        *ngIf="
          permissionObj['isIntakeOpportunityDetailsOpportunityNumberVisible']
        "
      >
        <div class="d-flex flex-row">
          <div class="padding-right-m">
            <div class="eclipse-small-header-grey text-align-left">
              Opportunity number:
            </div>
            <div
              class="eclipse-content text-align-left cursor-clickable green-color"
              (click)="onOpportunityNumberClick()"
            >
              <u>
                {{
                  oportunityDetailsInfo.opportunityNumber
                    ? oportunityDetailsInfo.opportunityNumber
                    : defaultForEmptyFields
                }}
              </u>
            </div>
          </div>
          <div>
            <div class="eclipse-small-header-grey text-align-left">
              Jupiter Opportunity Stage:
            </div>
            <div class="eclipse-content text-align-left cursor-clickable">
              {{
                oportunityDetailsInfo.opportunityStatusName
                  ? oportunityDetailsInfo.opportunityStatusName
                  : defaultForEmptyFields
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          (originalStartDate || originalEndDate) &&
          permissionObj['isIntakeOpportunityFieldsOutOfSyncVisible']
        "
      >
        <div class="jupiter-placeholder"></div>
      </div>
      <!--TODO: If opportunity number permission is not present need to check align - margin-top -->
      <div
        class="margin-top"
        *ngIf="permissionObj['isIntakeOpportunityDetailsLEPVisible']"
      >
        <div class="d-flex flex-column">
          <div class="eclipse-small-header-grey text-align-left">
            <span
              *ngIf="showRequiredIcon('leadEngagementPartner')"
              class="icon-required-yellow padding-left-sm"
            ></span>
            <span>Lead Engagement Partner:</span>
          </div>
          <div
            class="input-container text-align-left"
            (click)="$event.stopPropagation()"
          >
            <ng-container *ngIf="isEditingLEP; else displayLEP">
              <div class="col-12">
                <app-people-picker
                  id="Lep-editor"
                  [isTechinalReviewerSearch]="true"
                  (userSelected)="onLepEngagementPartnerSelected($event)"
                  [isLep]="true"
                />
              </div>
            </ng-container>
            <ng-template #displayLEP>
              <span class="title-value value-font">{{
                lepValue ? lepValue : defaultForEmptyFields
              }}</span>
              <span
                *ngIf="permissionObj['isIntakeOpportunityDetailsLEPEnable']"
                class="dds-icon dds-icon_edit__s__filled edit-icon"
                (click)="editLep()"
              ></span>
            </ng-template>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          originalLepValue &&
          permissionObj['isIntakeOpportunityFieldsOutOfSyncVisible']
        "
        class="d-flex flex-column"
      >
        <div class="eclipse-small-header-grey text-align-left">
          Lead Engagement Partner in Jupiter:
        </div>
        <span class="eclipse-small-header-grey text-align-left">
          {{ originalLepValue }}</span
        >
      </div>
    </div>

    <div class="d-flex flex-column">
      <div *ngIf="permissionObj['isIntakeOpportunityDetailsStartDateVisible']">
        <div class="eclipse-small-header-grey text-align-left">
          <span
            *ngIf="showRequiredIcon('projectStartDate')"
            class="icon-required-yellow padding-left-sm"
          ></span>
          <span>Start date:</span>
        </div>
        <div
          class="input-container text-align-left"
          (click)="$event.stopPropagation()"
        >
          <ng-container *ngIf="isEditingStartDate; else displayStartDate">
            <div class="col-12">
              <dds-datepicker
                [size]="htmlElementSize"
                [placeholder]="datePickerOptions.placeholder ?? ''"
                [format]="datePickerOptions.format ?? ''"
                [minMode]="datePickerOptions.minMode ?? ''"
                [isResponsive]="datePickerOptions.isResponsive ?? false"
                [isManualInput]="datePickerOptions.isManualInput ?? false"
                [maxDate]="tempEndDate"
                [(ngModel)]="tempStartDate"
                id="StartDate-editor"
              >
              </dds-datepicker>
            </div>
          </ng-container>
          <ng-template #displayStartDate>
            <span class="title-value value-font">{{
              startDate ? startDate : defaultForEmptyFields
            }}</span>
            <span
              *ngIf="permissionObj['isIntakeOpportunityDetailsStartDateEnable']"
              class="dds-icon dds-icon_edit__s__filled edit-icon"
              (click)="editStartDate()"
            ></span>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="
          originalStartDate &&
          permissionObj['isIntakeOpportunityFieldsOutOfSyncVisible']
        "
        class="d-flex flex-column"
      >
        <div class="eclipse-small-header-grey text-align-left">
          Start date in Jupiter: {{ originalStartDate }}
        </div>
      </div>

      <!--TODO: If Startdate permission is not present need to check align - margin-top -->
      <div
        class="margin-top"
        *ngIf="permissionObj['isIntakeOpportunityDetailsTotalFeesVisible']"
      >
        <div class="d-flex flex-column">
          <div class="eclipse-small-header-grey text-align-left">
            <span
              *ngIf="showRequiredIcon('totalFees')"
              class="icon-required-yellow padding-left-sm"
            ></span>

            <span>Total fees $:</span>
          </div>
          <div
            class="input-container text-align-left"
            (click)="$event.stopPropagation()"
          >
            <ng-container *ngIf="isEditingTotalFees; else displayTotalFees">
              <div class="col-12">
                <dds-input
                  [placeholder]="commonPlaceholder"
                  [(ngModel)]="tempTotalFees"
                  id="TotalFees-editor"
                >
                </dds-input>
              </div>
            </ng-container>
            <ng-template #displayTotalFees>
              <span class="title-value value-font">{{
                totalFees ? totalFees : defaultForEmptyFields
              }}</span>
              <span
                *ngIf="
                  permissionObj['isIntakeOpportunityDetailsTotalFeesEnable']
                "
                class="dds-icon dds-icon_edit__s__filled edit-icon"
                (click)="editTotalFees()"
              ></span>
            </ng-template>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          originalTotalFees &&
          permissionObj['isIntakeOpportunityFieldsOutOfSyncVisible']
        "
        class="d-flex flex-column"
      >
        <div class="eclipse-small-header-grey text-align-left">
          Total fees in Jupiter $: {{ originalTotalFees }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div *ngIf="permissionObj['isIntakeOpportunityDetailsEndDateVisible']">
        <div class="eclipse-small-header-grey text-align-left">
          <span
            *ngIf="showRequiredIcon('projectEndDate')"
            class="icon-required-yellow padding-left-sm"
          ></span>
          <span>End date:</span>
        </div>
        <div
          class="input-container text-align-left"
          (click)="$event.stopPropagation()"
        >
          <ng-container *ngIf="isEditingEndDate; else displayEndDate">
            <div class="col-12">
              <dds-datepicker
                [size]="htmlElementSize"
                [placeholder]="datePickerOptions.placeholder ?? ''"
                [format]="datePickerOptions.format ?? ''"
                [minMode]="datePickerOptions.minMode ?? ''"
                [minDate]="tempStartDate"
                [isResponsive]="datePickerOptions.isResponsive ?? false"
                [(ngModel)]="tempEndDate"
                id="EndDate-editor"
              >
              </dds-datepicker>
            </div>
          </ng-container>
          <ng-template #displayEndDate>
            <span class="title-value value-font">{{
              endDate ? endDate : defaultForEmptyFields
            }}</span>
            <span
              *ngIf="permissionObj['isIntakeOpportunityDetailsEndDateEnable']"
              class="dds-icon dds-icon_edit__s__filled edit-icon"
              (click)="editEndDate()"
            ></span>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="
          originalEndDate &&
          permissionObj['isIntakeOpportunityFieldsOutOfSyncVisible']
        "
        class="d-flex flex-column"
      >
        <div class="eclipse-small-header-grey text-align-left">
          End date in Jupiter: {{ originalEndDate }}
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex margin-top feetype-container">
    <div
      class="d-flex flex-column margin-top"
      *ngIf="permissionObj['isIntakeOpportunityDetailsFeeTypeVisible']"
    >
      <form [formGroup]="opportunityDetailsFeeForm">
        <div class="d-flex">
          <div class="eclipse-small-header-grey text-align-left">
            <span
              *ngIf="showRequiredIcon('feeType')"
              class="icon-required-yellow padding-left-sm"
            ></span>
            <span>FeeType:</span>
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div *ngIf="reloadMultiSelect" class="d-flex flex-column">
            <dds-multi-select
              autosave
              class="box-size multi-select-width"
              [disabled]="
                !permissionObj['isIntakeOpportunityDetailsFeeTypeEnable']
              "
              [isResponsive]="multiSelectOptions.isResponsive ?? false"
              [isRequired]="multiSelectOptions.isRequired ?? false"
              [label]="multiSelectOptions.label ?? emptyString"
              [size]="htmlElementSize"
              [placeholder]="multiSelectOptions.placeholder ?? emptyString"
              [type]="multiSelectOptions.type ?? emptyString"
              [controlType]="multiSelectOptions.controlType ?? emptyString"
              [theme]="multiSelectOptions.theme ?? emptyString"
              [isInverse]="multiSelectOptions.isInverse ?? false"
              [list]="multiselectFeeTypesOptions"
              formControlName="feeType"
            >
            </dds-multi-select>
            <div
              *ngIf="
                originalFeeTypes.length !== 0 &&
                permissionObj['isIntakeOpportunityFieldsOutOfSyncVisible'] &&
                showJupiterFeeType
              "
            >
              <div
                class="eclipse-small-header-grey text-align-left padding-top-xs"
              >
                Fee type in Jupiter:
              </div>
              <div *ngFor="let feeType of originalFeeTypes">
                <div
                  class="text-align-left margin-top-fee-type eclipse-small-header-grey text-align-left"
                >
                  {{ feeType?.feeTypeName }}
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              this.opportunityDetailsFeeForm.get('feeType')?.value.length !== 0
            "
            class="padding-left-s multi-select-width"
          >
            <span class="comments-text-label eclipse-small-header-grey"
              >Selected values:</span
            >
            <dds-tags>
              <dds-tag
                class="tag-width eclipse-header-grey"
                *ngFor="
                  let item of this.opportunityDetailsFeeForm.get('feeType')
                    ?.value
                "
                [theme]="options.theme ?? emptyString"
                [isRemovable]="options.isRemovable ?? false"
                [disabled]="
                  !permissionObj['isIntakeOpportunityDetailsFeeTypeEnable']
                "
                [customClass]="options.customClass ?? emptyString"
                [size]="htmlElementSize"
                [isError]="options.isError ?? false"
                [isInverse]="options.isInverse ?? false"
                (removed)="removeMultiSelectItemFeeType(item)"
                #tagItems
              >
                {{ item.label }}
              </dds-tag>
            </dds-tags>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div
    class="d-flex flex-row margin-top"
    *ngIf="
      permissionObj['isIntakeOpportunityDetailsOpportunityDescriptionVisible']
    "
  >
    <div class="d-flex flex-column">
      <div class="eclipse-content text-align-left">
        <span
          *ngIf="isOpportunityDescriptionRequiredIconVisible()"
          class="icon-required-yellow"
        ></span
        >Provide opportunity description
      </div>
      <div class="textarea-container">
        <dds-textarea
          autosave
          name="opportunityDescription"
          [(ngModel)]="opportunityDescription"
          [label]="textareaOptions.label"
          [labelPosition]="textareaOptions.labelPosition"
          [placeholder]="textareaOptions.placeholder"
          [description]="textareaOptions.description"
          [minHeight]="textareaOptions.minHeight"
          [isRequired]="textareaOptions.isRequired"
          [customClass]="textareaOptions.customClass"
          [isError]="textareaOptions.isError"
          [errorMessage]="textareaOptions.errorMessage"
          [errorState]="textareaOptions.errorState"
          [maxLength]="textareaOptions.maxLength"
          [maxLengthPosition]="textareaOptions.maxLengthPosition"
          [size]="htmlElementSize"
          [readonly]="
            textareaOptions.readonly ||
            !permissionObj[
              'isIntakeOpportunityDetailsOpportunityDescriptionEnable'
            ]
          "
          (ngModelChange)="valueChanged()"
        ></dds-textarea>
      </div>
    </div>
  </div>
</div>
