export enum StatusEnum {
  NCANeverStarted = 'NCA Never Started',
  NCAInProgress = 'NCA In Progress',
  NCAApprovedWithConditions = 'NCA Approved (Serve with Conditions)',
  NCAApprovedUpToDate = 'NCA Approved (Continuance Requirements up to date)',
  NCAApprovedPending = 'NCA Approved (Continuance Pending)',
  ContinuanceExpired = 'Continuance Expired',
  DoNotServe = 'Do Not Serve',
  NCAOnHold = 'NCA On Hold',
  NCADeFactoAccepted = 'NCA De facto Accepted',
  Pending = 'Pending',
  RMReviewed = 'RM Reviewed',
  Draft = 'Draft',
  Submitted = 'Submitted',
  RM_REVIEWED_PENDING_CLOSEOUT = 'RM reviewed - Pending closeout',
  RETURN_FOR_REWORK = 'Returned for revisions',
  SUBMITTED = 'Submitted',
  RESUBMITTED = 'Re-submitted',
  SELF_REVIEWED_PENDING_CLOSEOUT = 'Self reviewed - Pending closeout',
  COMPLETED = 'Completed',
  DISCONTINUED = 'Discontinued',
  COMPLETED_ADMIN = 'Completed (Admin)',
  ARCHIVE_OPEN = 'Open',
  ARCHIVE_CLOSED = 'Closed',
  ARCHIVE_REOPENED = 'Reopened',
  ARCHIVE_DESTROYED = 'Destroyed',
  ARCHIVE_MIGREATED_RMS ='Migrated/RMS Created',
  ARCHIVE_UNMAPPED = '(Unmapped/default)',
  Default = '',
}
