<div *ngIf="docMetaData.length > 0" class="table-with-data">
  <div class="toggle-data">
    <span>
      <dds-toggle-group
        [title]="groupOptions.title"
        [isInverse]="groupOptions.isInverse"
        [isRequired]="groupOptions.isRequired"
      >
        <dds-toggle
          [(ngModel)]="toggleOpen"
          [disabled]="options.disabled"
          [isProcessingStatusActive]="options.isProcessingStatusActive"
          [label]="options.label"
          [ariaLabel]="options.ariaLabel"
          [theme]="options.theme"
          [isError]="options.isError"
          [isRequired]="options.isRequired"
          [errorState]="options.errorState"
          [customClass]="options.customClass"
          (stateChanged)="stateChanged($event)"
          (processingStateActivated)="updateServerValue($event)"
          #toggle
        ></dds-toggle>
      </dds-toggle-group>
    </span>
  </div>

  <div class="div-headers">
    <table class="dds-data-table dds-data-table_selection">
      <thead class="dds-data-table__header header-style">
        <tr>
          <th *ngIf="showArchiveIt" class="dds-data-table__header-cell">
            <div class="dds-data-table__content"></div>
            <span>{{ archiveItHeader }} </span>
          </th>
          <th
            *ngFor="let column of sampleColumns"
            class="dds-data-table__header-cell"
          >
            <div class="dds-data-table__content">
              <span>{{ column.header || column.name }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="dds-data-table__body">
        <tr
          *ngFor="let row of docToShow; index as i"
          class="dds-data-table__row"
        >
          <td *ngIf="showArchiveIt" class="dds-data-table__cell">
            <dds-checkbox
              *ngIf="row.isVisible"
              (click)="updateRow(row)"
              [value]="row.isChecked ?? false"
              [theme]="checkBoxOptions.theme ?? 'green'"
              [ariaLabel]="row.ariaLabel ?? ''"
              [disabled]="row.isDisabled ?? false"
            ></dds-checkbox>

            <span class="d-flex align-items-center">
              <span
                *ngIf="!row.isVisible"
                class="dds-icon dds-icon_select_all__l__stroke tick-icon-green font-size-20 line-height-sm font-bold-400"
              ></span>
              <span>{{ row.docArchiveStatus }}</span>
            </span>
          </td>
          <td class="dds-data-table__cell">
            <div class="flex-container">
              <div
                *ngIf="row.submissionDocumentTypeId === 1"
                class="dds-card__image-block txt-Style"
              ></div>
              <div *ngIf="row.submissionDocumentTypeId === 2">
                <img src="\assets\dds-icons\linkIcon.svg" />
              </div>

              <div class="width100 long-title margin-left-xs">
                <span
                  [ddsTooltip]="row.documentTitle"
                  [tooltipInvokeType]="optionsToolTip.tooltipInvokeType ?? ''"
                  [tooltipType]="optionsToolTip.tooltipType ?? ''"
                  [tooltipSize]="optionsToolTip.tooltipSize ?? ''"
                  [tooltipPosition]="optionsToolTip.tooltipPosition ?? ''"
                  [tooltipIndent]="optionsToolTip.tooltipIndent ?? 0"
                  [tooltipHasBeak]="optionsToolTip.tooltipHasBeak ?? false"
                  [tooltipIsDisabled]="
                    optionsToolTip.tooltipIsDisabled ?? false
                  "
                  [tooltipIsOutsideClick]="
                    optionsToolTip.tooltipIsOutsideClick ?? false
                  "
                  [tooltipIsDynamic]="true"
                  [tooltipTheme]="optionsToolTip.tooltipTheme ?? ''"
                  [tooltipShowDelay]="optionsToolTip.tooltipShowDelay ?? 0"
                  [tooltipHideDelay]="optionsToolTip.tooltipHideDelay ?? 0"
                  ddsFocus
                  >{{ row.documentTitle }}</span
                >
              </div>
            </div>
          </td>
          <td class="dds-data-table__cell padding-zero">
            <div
              *ngIf="row.comments"
              [ddsTooltip]="row.comments"
              class="dds-card__image-block msg-style"
            ></div>
          </td>
          <td class="dds-data-table__cell col-md-2">
            <span>{{ row.documentType }}</span>
          </td>
          <td class="dds-data-table__cell col-md-2">
            <span
              class="padding-status"
              [style.color]="
                transformStatus(row.documentStatus) | statusStyle : 'color'
              "
              [style.backgroundColor]="
                transformStatus(row.documentStatus)
                  | statusStyle : 'backgroundColor'
              "
              >{{ row.documentStatus }}</span
            >
          </td>
          <td class="dds-data-table__cell">
            <span>{{ row.createdDate }}</span>
          </td>
          <td class="dds-data-table__cell">
            <span>{{ row.createdBy }}</span>
          </td>
          <td class="dds-data-table__cell d-flex actions-icons">
            <div
              *ngIf="
                row.submissionDocumentTypeId === 1 &&
                isPermissionPresent(
                  permissionEnums.Intake_Submission_DocumentDownload,
                  permissionType.Enable
                )
              "
              class="dds-card__image-block action-items"
              (click)="onDownload(row)"
              [ddsTooltip]="'Download'"
            >
              <img src="\assets\dds-icons\download-icon.svg" />
            </div>
            <div
              *ngIf="row.submissionDocumentTypeId === 2"
              class="dds-card__image-block action-items"
              (click)="openLink(row.documentUrl)"
              [ddsTooltip]="'Open'"
            >
              <img src="\assets\dds-icons\openBlackIcon.svg" />
            </div>
            <div
              *ngIf="
                isDeleteEditBtnVisible() &&
                isPermissionPresent(
                  permissionEnums.Intake_Submission_DocumentEdit,
                  permissionType.Enable
                )
              "
              [ddsTooltip]="'Edit'"
              class="dds-card__image-block action-items"
              (click)="onEdit(row)"
            >
              <img src="\assets\dds-icons\pencil-icon.svg" />
            </div>
            <div
              *ngIf="
                isDeleteEditBtnVisible() &&
                isPermissionPresent(
                  permissionEnums.Intake_Submission_DocumentDelete,
                  permissionType.Enable
                )
              "
              [ddsTooltip]="'Delete'"
              class="dds-card__image-block action-items"
              (click)="onDelete(row)"
            >
              <img src="\assets\images\dds-icons\dds-bin-icon.svg" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
