<div class="dds-modal-overlay" (click)="closeModal()"></div>

<div class="dds-modal">
  <div class="dds-modal__header">
    <span class="dds-modal__title">{{popupTitle}}</span>
    <dds-button size="sm" icon="dds-icon_close" kind="silent" theme="dark" ariaLabel="Close modal"
                class="dds-modal__close" [isInverse]="isInverse" (clicked)="closeModal()"></dds-button>
  </div>

  <!--[ngStyle]="{'overflow': !canUpload  ? 'auto' : 'hidden'}"-->
  <div class="dds-modal__body">

    <div>
      <div class="padding-right-l">
        <div class="dds-modal__body">
          <dds-uploader class="dds-uploader" style="padding: 0 0"
                        [heading]="uploadAreaOptions.heading??''"
                        [description]="uploadAreaOptions.description??''"
                        [dragAndDropText]="uploadAreaOptions.dragAndDropText??''"
                        [isDragAndDrop]="uploadAreaOptions.isDragAndDrop??false"
                        [isMultiple]="uploadAreaOptions.isMultiple??false"
                        [acceptFormats]="uploadAreaOptions.acceptFormats??''"
                        [acceptExtensions]="uploadAreaOptions.acceptExtensions??''"
                        [exceptSymbols]="uploadAreaOptions.exceptSymbols??''"
                        [btnLabel]="uploadAreaOptions.btnLabel??''"
                        [isInverse]="uploadAreaOptions.isInverse??false"
                        [disabled]="disableUpload"
                        [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind??''"
                        (uploadedItems)="uploadedItems($event)">
          </dds-uploader>
        </div>
        <!--[ngStyle]="{'padding': popupType === popupTypeEnum.ClientAgreement ? '8px 0' : '14px 0'}"-->

      </div>

    </div>
    <div class="margin-bottom-s padding-right-l">
      <div role="list" class="dds-uploader__list1" *ngIf="fileList.length > 0">
        <div role="listitem" class="dds-uploader-item" *ngFor="let fileInfo of fileList">
          <div class="dds-uploader-item__head dds-uploader-item_success">
            <span class="dds-uploader-item__name padding-bottom-s">
              {{fileInfo.file.name}}
            </span>
            <div class="dds-uploader-item__wrap">
              <!--<button ddsfocus="" class="dds-uploader-item__btn" aria-label="Remove the file" (clicked)="clearList()">
                <span class="dds-icon dds-icon_close"></span>
              </button>-->
              <dds-button size="sm" icon="dds-icon_close" kind="silent" theme="dark" ariaLabel="Clear FileList"
                          class="dds-modal__close" [isInverse]="isInverse" (clicked)="clearList()"></dds-button>
            </div>
          </div>
          <div class="dds-uploader-item__footer">
            <div class="dds-uploader-item__info">
              <span class="dds-uploader-item__size">{{(fileInfo.file.size / (1024 * 1024)).toFixed(2)}} MB</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="margin-bottom-s padding-right-xl">
      <span class="eclipse-content-grey">Document Type:</span>
      <div class="padding-right-m">
        <dds-select [list]="documentTypeList" [placeholder]="documentTypeOptions.placeholder ?? ''"
                    [size]="documentTypeOptions.size ?? ''"
                    (valueChanged)="changeDocumentTypeOptions($event)"
                    [(ngModel)]="documentTypeId">
        </dds-select>
      </div>
    </div>
    <div class="margin-bottom-s margin-right-m padding-right-xl">
      <span class="eclipse-content-grey">Effective date:</span>
      <dds-datepicker [size]="effectiveDateOptions.size ?? ''"
                      [placeholder]="effectiveDateOptions.placeholder ?? ''" [format]="effectiveDateOptions.format ?? ''"
                      [minMode]="effectiveDateOptions.minMode ?? ''"
                      [isManualInput]="effectiveDateOptions.isManualInput ?? false"
                      (dateChanged)="changeEffectiveDateOptions($event)"
                      [isResponsive]="effectiveDateOptions.isResponsive ?? false" [(ngModel)]="effectiveDate" [disabled]="false">
      </dds-datepicker>

    </div>
    <div class="margin-bottom-s margin-right-m padding-right-xl">
      <span class="eclipse-content-grey">Termination date:</span>
      <dds-datepicker [size]="terminationDateOptions.size ?? ''"
                      [placeholder]="terminationDateOptions.placeholder ?? ''" [format]="terminationDateOptions.format ?? ''"
                      [minMode]="terminationDateOptions.minMode ?? ''"
                      [isManualInput]="terminationDateOptions.isManualInput ?? false"
                      [isResponsive]="terminationDateOptions.isResponsive ?? false" [(ngModel)]="terminationDate" [disabled]="false">
      </dds-datepicker>
    </div>
    <div class="margin-bottom-m margin-right-s padding-right-xl">
      <span class="eclipse-content-grey">Auto-renew:</span>
      <dds-radio-group name="autoRenew"
                       [(ngModel)]="isAutoRenew"
                       (stateChanged)="onAutoRenewChangedEvent()">
        <ng-container *ngFor="let radio of autoRenewRadioBtn">
          <dds-radio class="radio-btn" [value]="radio.value"
                     [label]="radio.options.label ?? ''"
                     [theme]="radio.options.theme ?? ''" style="margin-right: 12px"></dds-radio>
        </ng-container>
      </dds-radio-group>
    </div>

  </div>
  <div class="dds-modal__footer padding-right-m">
    <div class="dds-modal__footer-content dds-modal__footer-content_right">
      <dds-button class="dds-modal__footer-item" (clicked)="onSave()"
                  (keyboardClicked)="onSave()" [disabled]="isSaveDisabled">Save</dds-button>
      <dds-button class="dds-modal__footer-item" kind="secondaryLoud" [isInverse]="isInverse"
                  (clicked)="closeModal()" (keyboardClicked)="closeModal()">Cancel</dds-button>

    </div>
    <div class="dds-modal__footer-content dds-modal__footer-content_right"></div>
  </div>
</div>
