import { Component, ViewChildren, QueryList, ElementRef, ChangeDetectorRef } from '@angular/core';
import { LabelPosition, Size, PositionState, ButtonKind, ExtThemes, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { SelectOptions, SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { FormControl } from "@angular/forms";
import { DaterangepickerOptions } from '@usitsdasdesign/dds-ng/datepicker';
import { ButtonOptions } from "@usitsdasdesign/dds-ng/button";
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service'
import { WatchlistApiService } from '../../http/watchlist/watchlist-api.service'
import { AuditItem, AuditLogRequest } from '../../common/models/common-models'
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import { AdobeAnalyticsService } from '../../common/services/adobe-analytics.service';


@Component({
  selector: 'app-watchlist-audit',
  templateUrl: './watchlist-audit.component.html',
  styleUrl: './watchlist-audit.component.less'
})
export class WatchlistAuditComponent {

  public clientNumber: string = '';
  public watchlistId: number = 0;
  public clientName: string = '';
  public auditItems: AuditItem[] = [];
  private subscriptions: Subscription[] = [];
  public showMore: { [key: number]: ShowMoreState } = {};
  public shouldShowButton: { [key: number]: ShowMoreState } = {};
  public countforPagination: number = 5;
  public gotoPage: number = 0;
  public itemsPerPage: number = IntakeConstant.PAGE_NUMBER_10;
  public selectItems: SelectItemOptions[] = [];
  public isAscending: boolean = false;
  public selectedUser: any;
  public selectedDate: any;
  public isLoading: boolean = true;

  @ViewChildren('fromCommentText') fromCommentText!: QueryList<ElementRef>;
  @ViewChildren('toCommentText') toCommentText!: QueryList<ElementRef>;

  constructor(private watchlistApiService: WatchlistApiService,
    private progressIndicator: ProgressIndicatorService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private analytics: AdobeAnalyticsService) { }

  ngOnInit(): void {
    this.analytics.trackPage("Watchlist audit")
    this.route.params.subscribe((params: Params) => {
      this.watchlistId = params['watchlistId'];
    })

    let navigation = window.history.state;
    if (navigation && navigation.clientNumber) {
      this.clientNumber = navigation.clientNumber;
    }
    if (navigation && navigation.clientName) {
      this.clientName = navigation.clientName;
    }

    this.loadAuditLog();

    this.subscriptions.push(
      this.watchlistApiService.getWatchlistAuditlogUsers(this.watchlistId)
        .subscribe((data: any) => {
          this.selectItems = data.map((item: any) => ({
            heading: item.displayName,
            value: item.employeeID
          }));

          // Push the "All users" item with heading and value as specified
          this.selectItems.push({ heading: 'All users', value: null });
        })
    );
  }

  ngAfterViewChecked() {
    this.checkTextOverflow();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  checkTextOverflow(): void {
    this.auditItems.forEach((item, index) => {
      const fromElement = this.fromCommentText.toArray().find(el => el.nativeElement.id == index)?.nativeElement;
      const toElement = this.toCommentText.toArray().find(el => el.nativeElement.id == index)?.nativeElement;

      if (!this.shouldShowButton[index]) {
        this.shouldShowButton[index] = { from: false, to: false };
      }

      if (fromElement) {
        this.shouldShowButton[index].from = this.isTextOverflowing(fromElement);
      } else {
        this.shouldShowButton[index].from = false;
      }

      if (toElement) {
        this.shouldShowButton[index].to = this.isTextOverflowing(toElement);
      } else {
        this.shouldShowButton[index].to = false;
      }
    });
    this.cdr.detectChanges();
  }

  isTextOverflowing(element: HTMLElement): boolean {
   
    return element.scrollHeight > element.clientHeight;
  }

  toggleShowMore(index: number, type: 'from' | 'to'): void {
    if (!this.showMore[index]) {
      this.showMore[index] = { from: false, to: false };
    }
    this.showMore[index][type] = !this.showMore[index][type];

    this.options.icon = this.showMore[index]['from'] ? 'dds-icon_arrow-up' : 'dds-icon_arrow-down';
    this.tooptions.icon = this.showMore[index]['to'] ? 'dds-icon_arrow-up' : 'dds-icon_arrow-down';
    this.cdr.detectChanges();
  }

  loadAuditLog(): void {

    this.isLoading = true;
    const auditLogRequest: AuditLogRequest = {
      dateFrom: this.selectedDate ? this.selectedDate[0].toLocaleDateString('en-US') : null,
      dateTo: this.selectedDate ? this.selectedDate[1].toLocaleDateString('en-US') : null,
      empId: this.selectedUser,
      pageN: this.gotoPage,
      pageSize: this.itemsPerPage,
      isAscending: this.isAscending
    };

    this.progressIndicator.show();

    this.subscriptions.push(
      this.watchlistApiService.getWatchlistAuditLog(this.watchlistId, auditLogRequest)
        .subscribe((data: any) => {
        this.auditItems = data.auditItems;
          this.countforPagination = data.totalDataCount;
          this.isLoading = false;
        this.progressIndicator.hide();
      })
    );
  }

  onPageChanged(value: number) {
    this.gotoPage = value - 1;
    this.loadAuditLog();
  }

  onValueChanged(item: number) {
    this.itemsPerPage = item;
    this.gotoPage = 0;
    this.loadAuditLog();
  }

  public gridColumns: Column[] = [
    { name: 'name', header: 'Name', dataType: 'string', minWidth: '12rem' },
    { name: 'date/Time', header: 'Log date/Time', dataType: 'string', minWidth: '12rem' },
    { name: 'Description', header: 'Description', dataType: 'string', minWidth: '25rem' },
  ];

  public sortingState = {
    property: this.gridColumns[1].name,
    ascending: false,
    dataType: this.gridColumns[1].dataType,
  };

  filterSorted() {
    this.isAscending = !this.isAscending;
    this.sortingState.ascending = this.isAscending;
    this.loadAuditLog();
  }

  applyfilter() {
    this.loadAuditLog();
  }

  selectOptions: SelectOptions = {
    label: 'Label',
    description: '',
    placeholder: 'All users',
    size: Size.lg,
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: "",
    stickerIsDisabled: false,
    stickerPosition: "bottom-left"
  };

  dateRangePickerOptions: DaterangepickerOptions = {
    label: 'Date Range Picker',
    labelPosition: LabelPosition.external,
    size: Size.lg,
    placeholder: ['MM/DD/YYYY', 'MM/DD/YYYY'],
    description: '',
    format: 'MM/dd/yyyy',
    stickerPosition: PositionState.bottomLeft,
    stickerIndent: 0,
    stickerShift: 0,
    stickerMaxHeight: ''
  };

  buttonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: WidthState.fixed,
  }

  options: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.sm,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_arrow-down',
    role: 'button'
  }

  tooptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.sm,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_arrow-down',
    role: 'button'
  }
}

interface ShowMoreState {
  from: boolean;
  to: boolean;
}
