
<div class="gray-box">
  <div class="row left-align">
    <div class="col-2 zero-padding-right">
      <p class="eclipse-main-tab-header">NCA summary</p>
    </div>

    <div class="col-2" *ngIf="periodLookupList.length>0">
      <dds-select [list]="periodLookupList" [placeholder]="selectPeriodOptions.placeholder ?? ''"
                  [size]="selectPeriodOptions.size ?? ''" (valueChanged)="periodChangeEvent($event)"
                  [(ngModel)]="periodID">
      </dds-select>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row left-align">
    <div class="col-6 right-border">
      <p class="eclipse-header">By Specailist</p>
      <app-bar-chart [barChartTitle]="barChartTitle" [bars]="bars" [legendBar]="bars[0]" [isHorizontal]="false"
                     (barChartClick)="barChartClick($event)" [isChartDataLoaded]="isChartDataLoaded">

      </app-bar-chart>
    </div>
    <div class="col-6">
      <p class="eclipse-header left-align">By Status</p>
      <div class="row">
        <div class="col-4">
          <div class="section"><span>Grand total</span></div>
          <div class="eclipse-main-tab-header1"><span>{{grandTotal}}</span></div>
        </div>
        <div class="col-4">
          <div class="section"><span>Total Active</span></div>
          <div class="eclipse-main-tab-header1"><span>{{totalActive}}</span></div>
        </div>
        <div class="col-4">
          <div class="section"><span>Total Completed</span></div>
          <div class="eclipse-main-tab-header1"><span>{{totalCompleted}}</span></div>
        </div>
      </div>
      <div class="marigin-top-m margin-bottom-s top-border"></div>
      <div class="nca-summary-footer">
        <div>
          <span class="completed margin-right-s">{{completedAccepted}}</span>
          <span>Completed - accepted</span>
        </div>
        <div>
          <span class="discontinued margin-right-s margin-left-s">{{declined}}</span>
          <span>Completed - declined/Do not serve</span>
        </div>
      </div>
      <div class="nca-summary-footer">
        <div>
          <span class="serveConditions margin-right-s">{{serveWithConditions}}</span>
          <span>Completed - serve with conditions</span>
        </div>
        <div>
          <span class="discontinued margin-right-s margin-left-s">{{disContinued}}</span>
          <span>Discontinued</span>
        </div>
        <div>
          <span class="converted-to-cc margin-right-s margin-left-s">{{convertedToCC}}</span>
          <span>Converted to CC</span>
        </div>
      </div><div class="nca-summary-footer">
        
        <div>
          <span class="accepted-by-system margin-right-s">{{deFactoAcceptedBySystem}}</span>
          <span>De-facto accepted [by System]</span>
        </div>
        <div>
          <span class="accepted-by-nca margin-right-s margin-left-s">{{deFactoAcceptedByNCA}}</span>
          <span>De-facto accepted [by NCA team]</span>
        </div>
      </div><div class="nca-summary-footer">
        <div>
          <span class="not-accepted margin-right-s">{{notRequired}}</span>
          <span>NCA Required/Accepted</span>
        </div>
      </div>
    </div>
  </div>
</div>
