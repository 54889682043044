<div class="text-align-left padding-top-m">
  <div class="d-flex">
    <span
      *ngIf="clientSummaryData?.clientName"
      class="font-size-12 padding-top-xxs green-color margin-right-xxs cursor-clickable"
      (click)="clientNameClicked()"
      ><u>{{ clientSummaryData?.clientName }}</u></span
    >
    <span
      ><span class="eclipse-small-header-grey font-size-12 cursor-clickable"
        >SWIFT client ID:
      </span>
      <span class="eclipse-content">
        {{ " " + clientSummaryData?.clientNumber }}</span
      ></span
    >
  </div>
  <span
    *ngIf="clientSummaryData?.clientStatus"
    class="font-size-12 px-25"
    [style.color]="
      transformStatus(clientSummaryData!.clientStatus) | statusStyle : 'color'
    "
    [style.backgroundColor]="
      transformStatus(clientSummaryData!.clientStatus)
        | statusStyle : 'backgroundColor'
    "
    >{{ clientSummaryData?.clientStatus }}</span
  >
  <div class="d-flex gap-80 padding-top-m">
    <div class="client-details-wrapper industry">
      <p class="eclipse-small-header-grey font-size-12 font-bold-400">
        Industry
      </p>
      <p class="eclipse-content font-size-14 font-bold-400">
        {{
          clientSummaryData?.clientIndustry +
            ": " +
            clientSummaryData?.clientIndustrySector
        }}
      </p>
    </div>
    <div class="client-details-wrapper progran-account">
      <p class="eclipse-small-header-grey font-size-12 font-bold-400">
        Program account
      </p>
      <p class="eclipse-content font-size-14 font-bold-400">
        {{ clientSummaryData?.acpDesignation }}
      </p>
    </div>
  </div>
  <div class="global-program-wrapper">
    <p class="eclipse-small-header-grey">Global Program</p>
    <p class="eclipse-content">
      {{ globalProgram }}
    </p>
    <!-- TODO 5402524
 Indicates that the account is either a Global Crown Jewel or a Global Strategic account. If neither, select Non-Program Client) -->
  </div>
</div>
