import { ButtonOptions } from "@usitsdasdesign/dds-ng/button";
import { SearchOptions } from "@usitsdasdesign/dds-ng/search";
import { ButtonKind, ExtThemes, Size, Themes, WidthState } from "@usitsdasdesign/dds-ng/shared";
import { TagOptions } from "@usitsdasdesign/dds-ng/tags";
import { ToggleOptions } from "@usitsdasdesign/dds-ng/toggle";
import { TooltipOptions } from "@usitsdasdesign/dds-ng/tooltip";

export class ConsultingNCAConstants {
    public static readonly Search_MultiOptions_Placeholder: string = 'Add team member';
    public static readonly searchMultiOptions: SearchOptions = {
    placeholder: ConsultingNCAConstants.Search_MultiOptions_Placeholder,
    size: Size.lg,
    customClass: '',
  };
  public static readonly multiOptions: TagOptions = {
    theme: Themes.dark,
    isRemovable: true,
    disabled: true,
    isError: false,
    isInverse: false,
    size: Size.lg,
    customClass: ''
  };

  public static readonly toggleOptions: ToggleOptions = {
      theme: Themes.green,
      label: 'Show source of attributes'
    };

    public static readonly buttonOptions: ButtonOptions = {
        theme: ExtThemes.green,
        kind: ButtonKind.primary,
        size: Size.md,
        width: WidthState.fixed,
        isLoading: false,
        icon: '',
        isIconLeft: false,
        isColorBg: false,
        isInverse: false,
        disabled: false,
        ariaLabel: 'Button',
        customClass: '',
        role: 'button'
      };

    public static readonly tooltipOptions: TooltipOptions = {
      tooltipInvokeType: 'hover',
      tooltipPosition: 'top',
      tooltipIndent: 5,
      tooltipIsOutsideClick: false,
      tooltipHasBeak: true,
      tooltipIsDisabled: false,
      tooltipIsInverse: false,
      tooltipIsDynamic: false,
      tooltipCustomClass: 'responsive-tooltip',
      tooltipType: 'regular',
      tooltipSize: 'md',
      tooltipShowDelay: 0,
      tooltipHideDelay: 0,
      tooltipTheme: Themes.dark,
      tooltipMaxWidth:100,
      };

  public static  getIconByFileExtension(fileExtension: string): string {
    const extensionIconMap: { [key: string]: string } = {
      'pdf': 'dds-icon_file_pdf__l__filled',
      'doc': 'dds-icon_file_doc__l__filled',
      'docx': 'dds-icon_file_doc__l__filled',
      'xls': 'dds-icon_file_xls__l__filled',
      'xlsx': 'dds-icon_file_xls__l__filled',
      'ppt': 'dds-icon_file__l__stroke',
      'pptx': 'dds-icon_file__l__stroke',
      'jpg': 'dds-icon_file_jpg__l__filled',
      'jpeg': 'dds-icon_file_jpg__l__filled',
      'png': 'dds-icon_file_png__l__filled',
      'txt': 'dds-icon_file_txt__l__filled',
      'zip': 'dds-icon_file_zip__l__filled',
      'default': 'dds-icon_file_other__l__filled'
    };

    return extensionIconMap[fileExtension.toLowerCase()] || extensionIconMap['default'];
  }

  public static readonly copieToClipboardMessage = {
    title: '',
    message: 'Copied to clipboard',
    lifeTime: 3000,
    withProgressBar: false,
    position: 'bottom-center',
    isCloseIcon: false,
    customClass: 'toast-align-center',
    isError: false,
    isInverse: false,
    isLoading: false,
    limit: 1,
    theme: Themes.dark,
  };
  
  public static readonly COMPLETED_NCA_FORM_PDF_HEADING: string = 'Completed #clientMilestoneProcessType form PDF';
  public static readonly COMPLETED_NCA_FORM_PDF_VALUE: string = 'Completed#clientMilestoneProcessTypeformPDF';
  public static readonly EXPORT_NCA_TOOLTIP_INFO: string = 'The exported PDF will contain client information at the time NCA was approved';
  public static readonly EXPORT_MARS_NCA_FORM_PDF: string = 'MARSNCAFormPDF';
  public static readonly EXPORT_MARS_NCA_FORM_PDF_HEADING: string = 'MARS NCA Form PDF';
  public static readonly EXPORT_CC_TOOLTIP_INFO: string = 'The exported PDF will contain client information at the time Client continuance was confirmed';
  public static readonly NET_SERVICES_REVENUE_TOOLTIP_INFO: string = 'Includes staffed revenue for dedicated M&A practices, including Consulting M&A Offering, M&A and T&IS Tax, and A&A IPO/M&A Market Offerings.';
}
