import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LepValue,
  OpportunityDetailsNavModel,
} from '../../common/models/opportunity-details.model';
import { SubmissionService } from '../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { AccordionOptions } from '@usitsdasdesign/dds-ng/accordion';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import {
  lepDataValue,
  tooltipOptionsData,
} from './opportunity-details-settings';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { OpportunityDetailsEdit } from '../../common/models/opportunityDetailsEdit';
import { IntakeDocumentSubmissionTypeCode } from '../constants/intake_submission_type_code.enum';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import {
  EmitUpdatedTitleModal,
  IntakeSubmissionDetailsCommonSectionDataReceivedModal,
} from '../../common/models/intake-submission-details-common-section.model';
import { commonSectionForSingleCoCheck } from '../common/intake.util';
import { IntakeAction } from '../../common/models/intake-action.model';
import { OppertunitySubmissionStatusCode } from '../../common/models/oppertunity-submission-statusCode.model';
import { CommonService } from '../../http/intake/common.service';
import { StatusEnum } from '../constants/status-enum';
import { IntakeConstant } from '../constants/intake.constant';
import { AdobeAnalyticsService } from '../../common/services/adobe-analytics.service';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { PermissionCheck } from '../../common/validations/PermissionCheck';
@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
  styleUrl: './opportunity-details.component.less',
})
export class OpportunityDetailsComponent
  extends PermissionCheck
  implements OnInit, OnDestroy, AfterViewInit
{
  constructor(
    private readonly intakeDetailService: SubmissionService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly submissionService: SubmissionService,
    private readonly commonService: CommonService,
    private readonly analytics: AdobeAnalyticsService,
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
  }
  updateTitleDetails!: EmitUpdatedTitleModal;
  activeIndex: number = -1;
  opportunityId: string = '';
  opportunityDetailTitle: string | null = '';
  opportunityID: string | null = '';
  requiredCheckForIdList: number[] = [];
  dataSubmissionTypeCode: string = '';
  intakeDocumentSubmissionTypeCodeELSOW =
    IntakeDocumentSubmissionTypeCode.EL_SOW;
  lepValue: LepValue = lepDataValue;

  oportunityDetailsInfo: OpportunityDetailsEdit = {
    opportunityNumber: '',
    projectStartDate: '',
    projectEndDate: '',
    leadEngagementPartner: '',
    totalFees: null,
    feeType: [],
    clientNumber: '',
    opportunityDescription: '',
  };
  unsubscriber$ = new Subject();
  submissitionDetails: OpportunityDetailsNavModel[] = [];
  accordionOptions: AccordionOptions = {
    size: Size.lg,
    disabled: false,
    isOpen: true,
    isMulti: false,
    isInverse: false,
    customClass: '',
  };
  opportunityComplexQuestionStatus: boolean = true;
  opportunityDetailStatus: boolean = true;
  opportunityComplexCyberQuestionStatus: boolean = true;
  submissionTypeSelected: string = '';
  currentSubmissionId: string = '';
  isNavigationFromSummary: boolean = false;

  selectedSubmissionId: number | null = null;
  options: TooltipOptions = tooltipOptionsData;

  isNavigatingFromDashboard: boolean = false;
  clientNumber: string = '';

  ngOnInit(): void {
    this.analytics?.trackPage('Opportunity Details');
    this.progressIndicator.show();
    this.loadRolesStatusActions();
    const id = this.route.snapshot.paramMap.get('id');
    this.opportunityID = id;
    const statedata = history.state;

    if (statedata) {
      this.opportunityDetailTitle = statedata.name;
      this.submissionTypeSelected = statedata.submissionTypeCode;
      this.currentSubmissionId = statedata.submissionID;
      this.isNavigationFromSummary = statedata.navigatedFromSummary;
      this.isNavigatingFromDashboard = statedata.navigatingFromDashboard;
    }

    this.route.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((params) => {
        Object.keys(params).forEach((key) => {
          if (key?.toLowerCase() === 'submissionid') {
            if (params[key]) {
              this.currentSubmissionId = params[key];
            }
          }
        });
      });

    if (id) {
      this.opportunityId = id;
      this.getNavDetails(id);
      this.getOpportunityDetails(id);
    }

    this.intakeDetailService.complexQuestionStatus$.subscribe((status) => {
      this.opportunityComplexQuestionStatus = !status;
    });
    this.intakeDetailService.detailStatus$.subscribe((status) => {
      this.opportunityDetailStatus = !status;
    });
    this.intakeDetailService.complexQuestionCyberStatus$.subscribe((status) => {
      this.opportunityComplexCyberQuestionStatus = !status;
    });

    this.progressIndicator.hide();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollToActiveIndex();
    }, IntakeConstant.SET_TIMEOUT_DURATION);
  }

  setActive(index: number): void {
    this.activeIndex = index;
    this.activeIndex !== -1
      ? (this.selectedSubmissionId =
          this.submissitionDetails[index].submissionId)
      : (this.selectedSubmissionId = null);
    this.dataSubmissionTypeCode =
      this.submissitionDetails[index]?.submissionTypeCode;
    this.submissionService.updateCurrentSubmissionPageStatus(index !== -1);
  }

  selectNavItem(submissionId: number): void {
    this.selectedSubmissionId = submissionId;
  }

  getNavDetails(id: string): void {
    this.intakeDetailService
      .getIntakeDetails(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: OpportunityDetailsNavModel[]) => {
          this.submissitionDetails = data;
          this.opportunityDetailTitle = data[0].submissionTitle;

          this.submissitionDetails.forEach((item) => {
            if (item.isFormCompleted) {
              this.checkRequired(true, item.submissionId);
            }
          });

          if (
            this.isNavigationFromSummary ||
            this.submissionTypeSelected ===
              IntakeDocumentSubmissionTypeCode.CO ||
            this.isNavigatingFromDashboard ||
            (this.currentSubmissionId && !this.selectedSubmissionId)
          ) {
            const activeIndex = this.submissitionDetails.findIndex(
              (item: OpportunityDetailsNavModel) =>
                item.submissionId.toString() ===
                this.currentSubmissionId.toString()
            );
            this.setActive(activeIndex);
            this.scrollToActiveIndex();
          }
        },
      });
  }

  getOpportunityDetails(id: string): void {
    this.submissionService
      .getOpportunityDetailsInfo(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        if (data) {
          this.clientNumber = data.clientNumber ?? '';
        }
      });
  }

  scrollToActiveIndex(): void {
    if (this.activeIndex !== -1 && this.submissitionDetails[this.activeIndex]) {
      const elementId = `navItem-${this.submissitionDetails[
        this.activeIndex
      ].submissionId.toString()}`;
      const element = document.getElementById(elementId);
      if (element) {
        const leftMenu = document.getElementById('left-nav-items');
        if (leftMenu) {
          leftMenu.scrollTop = element.offsetTop - leftMenu.offsetTop;
        } else {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }

  checkRequired(requiredCheck: boolean, submissionId?: number): void {
    const activeSubmissionId =
      this.submissitionDetails[this.activeIndex]?.submissionId;
    if (requiredCheck) {
      if (
        !this.requiredCheckForIdList.includes(
          submissionId ?? activeSubmissionId
        )
      ) {
        this.requiredCheckForIdList.push(submissionId ?? activeSubmissionId);
      }
    } else {
      if (
        this.requiredCheckForIdList.includes(submissionId ?? activeSubmissionId)
      ) {
        this.requiredCheckForIdList = this.requiredCheckForIdList.filter(
          (item) => item !== (submissionId ?? activeSubmissionId)
        );
      }
    }
  }

  navigateToSummary(): void {
    this.progressIndicator.show();
    this.submissionService
      .updateDescDetails(this.opportunityId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: string) => {
          this.progressIndicator.hide();
          this.router.navigate(['opportunity-summary'], {
            queryParams: {
              id: this.opportunityId,
              title: this.opportunityDetailTitle,
            },
          });
        },
      });
    this.progressIndicator.hide();
  }

  updateDocumentTitle(event: EmitUpdatedTitleModal): void {
    this.updateTitleDetails = event;
    if (event?.submissionId != null) {
      let findIndex = this.submissitionDetails.findIndex(
        (submissionDetail) =>
          submissionDetail.submissionId === event.submissionId
      );
      this.submissitionDetails[findIndex].submissionTitle = event.updatedTitle;
    }
  }

  showDocFiledRequiredIndicator(item: OpportunityDetailsNavModel): boolean {
    if (
      !this.requiredCheckForIdList.includes(item.submissionId) &&
      item?.submissionTypeCode !== this.intakeDocumentSubmissionTypeCodeELSOW
    ) {
      if (
        item.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
        this.submissitionDetails.length === 1
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  isOverAllRequired(): boolean {
    if (this.submissitionDetails.length === 0) {
      return false;
    }
    if (commonSectionForSingleCoCheck(this.submissitionDetails)) {
      return false;
    } else {
      return (
        this.opportunityComplexQuestionStatus ||
        this.opportunityDetailStatus ||
        this.opportunityComplexCyberQuestionStatus
      );
    }
  }

  backToHome(): void {
    this.router.navigate(['/']);
  }

  onLepValueChange(data: LepValue): void {
    this.lepValue = data;
  }

  loadRolesStatusActions(): void {
    this.commonService
      .getIntakeActions()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: IntakeAction[]) => {
          this.commonService.setIntakeActions(response);
        },
        error: (err) => {
          console.error('Error fetching intake actions', err);
        },
      });

    this.commonService
      .getOppertunityStatusCode()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[]) => {
          this.commonService.setIntakeOppertunityDocStatusCode(response);
        },
        error: (err) => {
          console.error('Error fetching intake staus codes', err);
        },
      });
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
    this.submissionService.updateCurrentSubmissionPageStatus(false);
  }
}
