import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import {
  docTableColumns,
  documentTitleTooltipOptions,
  ToggleGroupOptions,
  ToggleOptions,
} from '../../../external-communications.helper';
import { ToggleComponent } from '@usitsdasdesign/dds-ng/toggle';
import { RoleEnum } from '../../../../intake/constants/Role.enum';
import { Themes } from '@usitsdasdesign/dds-ng/shared';
import { toast } from '../../../../intake/opportunity-details/submission-common-section/document/document-upload/document-table/document-table-settings';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { DocumentEditPopupComponent } from '../../../../intake/opportunity-details/submission-common-section/document/document-upload/document-edit-popup/document-edit-popup.component';
import { SubmissionDocumentDetails } from '../../../../common/models/external-communication.model';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { DocumentService } from '../../../../http/document/document.service';
import { ToastService } from '@usitsdasdesign/dds-ng/toast';
import { DocumentStatus } from '../../../constants/document-status.enum';
import { ExternalCommunicationDocumentEditPopupComponent } from '../external-communication-document-edit-popup/external-communication-document-edit-popup.component';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { UserService } from '../../../../http/user.service';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { StatusEnum } from '../../../constants/status-enum';

@Component({
  selector: 'app-external-communication-document-table',
  templateUrl: './external-communication-document-table.component.html',
  styleUrl: './external-communication-document-table.component.less',
})
export class ExternalCommunicationDocumentTableComponent
  implements OnChanges, OnDestroy
{
  @Input() submissionId!: number;
  @Input() submissionStatusId!: number;
  @Input() submissionStatus!: string | null;
  @Input() submissionTypeId: number = 0;
  @Input() isDisabledDocuments!: boolean;
  @Input() isDraftForSelfReview!: boolean;
  @Input() submissionTypeCode: string =
    ExternalCommunicationConstant.EMPTY_SPACE;
  @Input() isEditable: boolean = false;
  @Input() set progressiveStatus(status: boolean | null) {
    setTimeout(() => {
      if (status != null) {
        this.updateServerValue(status);
      }
    }, 150);
  }
  @Input() documentStatusDropdownData: SelectItemOptions[] = [];
  @Input() documentTypeDropdownData: SelectItemOptions[] = [];
  @Input() isStatusColumnVisible: boolean = false;
  @Input() hasDocumentViewAccess: boolean = false;

  @Output() documentTableData: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild('toggle', { static: false })
  toggle!: ToggleComponent;
  groupOptions = ToggleGroupOptions;
  options = ToggleOptions;
  toggleOpen: boolean = false;

  docToShow: any[] = [];
  docMetaData: any[] = [];

  checkboxTheme: string = Themes.green;
  toasteOption = toast;
  tableColumns = docTableColumns;
  tooltipOptions: TooltipOptions = documentTitleTooltipOptions;

  enumRoles: string[] = Object.values(RoleEnum);

  unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    private readonly modalService: ModalService,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly documentService: DocumentService,
    private readonly toastService: ToastService,
    private readonly userService: UserService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submissionId']?.currentValue || changes['submissionStatus']) {
      this.getDocumentMetaData();
      this.isEditable = false;
    }
    if (changes['isStatusColumnVisible']) {
      if (!this.isStatusColumnVisible) {
        this.tableColumns = docTableColumns.filter(
          (item) => item.name !== 'Status'
        );
      } else this.tableColumns = [...docTableColumns];
    }
  }

  private updateStatus(): void {
    //TODO: For later stories, if not needed remove
  }

  stateChanged(event: boolean): void {
    this.updateServerValue(event);
  }

  updateServerValue(event: boolean): void {
    this.filterDocumentData(event);
    if (this.toggleOpen !== event) {
      setTimeout(() => this.toggle?.toggleState(true), 100);
    }
  }

  filterDocumentData(draftHidden: boolean): void {
    if (draftHidden) {
      this.docToShow = this.docMetaData.filter(
        (doc: any) =>
          ![
            DocumentStatus.Draft,
            DocumentStatus.SupportingDocument,
            DocumentStatus.RMReviewed,
            DocumentStatus.SelfReviewed,
          ].includes(doc.documentStatus)
      );
    } else {
      this.docToShow = this.docMetaData;
    }
  }

  onDownload(event: SubmissionDocumentDetails): void {
    let downloadRequest = {
      DocumentName: event.documentTitle,
      DocumentKey: event.attachmentGuid,
      FileFolderPath: 'ExternalComm',
    };
    this.documentService.getDownloadUrl(downloadRequest).subscribe({
      next: (response: any) => {
        if (response != null && response.documentDownloadUrl != null) {
          window.open(response.documentDownloadUrl, '_blank');
        }
      },
      error: (err: any) => {},
    });
  }

  openLink(item: string): void {
    if (item) {
      window.open(item, '_blank');
    }
  }

  onEdit(item: SubmissionDocumentDetails, modalBtn?: any): void {
    let modalRef = this.modalService.open(
      ExternalCommunicationDocumentEditPopupComponent,
      {
        isFooter: true,
        size: 'lg',
        isInverse: false,
        selectedDocument: {
          moduleName: 'ExternalComm',
          submissionId: this.submissionId,
          submissionStatusId: this.submissionStatusId,
          submissionTypeId: this.submissionTypeId,
          submissionTypeCode: this.submissionTypeCode,
          submissionDocumentId: item.submissionDocumentId,
          submissionDocumentTypeId: item.submissionDocumentTypeId,
          documentTitle: item.documentTitle,
          documentTypeId: item.documentTypeId,
          documentType: item.documentType,
          documentStatusId: item.documentStatusId,
          documentStatus: item.documentStatus,
          attachmentId: item.attachmentId,
          comments: item.comments,
        },
        documentStatusData: this.documentStatusDropdownData,
        documentTypeData: this.documentTypeDropdownData,
      }
    );
    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((response: any) => {
        this.progressIndicator.hide();
        this.getDocumentMetaData();
        if (modalBtn) {
          modalBtn.focus();
        }
      });
  }

  onDelete(item: SubmissionDocumentDetails): void {
    this.progressIndicator.show();
    this.externalCommService
      .deleteDocumentDetails(item.submissionDocumentId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicator.hide();
          this.getDocumentMetaData();
        },
        error: (err: any) => {
          // TODO: Getting 200 status with parsing error of message. Check with backend
          if (err?.status === 200) {
            this.getDocumentMetaData();
          } else console.error('Error in delete document data', err);
          this.progressIndicator.hide();
        },
      });
  }

  getDocumentMetaData(): void {
    this.progressIndicator.show();
    this.externalCommService
      .getDocumentDetails(this.submissionId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: SubmissionDocumentDetails[]) => {
          this.docMetaData = data;
          this.updateStatus();
          this.filterDocumentData(this.toggleOpen);
          this.documentTableData.emit(this.docToShow);
          this.progressIndicator.hide();
        },
        error: (err: any) => {
          console.error('Error in fetching document data', err);
          this.progressIndicator.hide();
        },
      });
  }

  transformStatus(status: string | undefined | null): StatusEnum {
    // TODO: Need to confirm color options for different docStatus
    if (!status) return StatusEnum.Draft ?? 'Draft';
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  showToast(): void {
    this.toastService.createToast(this.toasteOption);
  }

  isDeleteEditBtnVisible(): boolean {
    return true;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
