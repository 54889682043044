import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminModuleService } from '../../admin-services/admin-module.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Subject, takeUntil } from 'rxjs';
import {
  ButtonKind,
  Column,
  ExtThemes,
  PositionState,
  Size,
  SortOptions,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import {
  DropdownItemOptions,
  DropdownOptions,
} from '@usitsdasdesign/dds-ng/dropdown';
import { ToggleOptions } from '@usitsdasdesign/dds-ng/toggle';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { AdminConstant } from '../../constants/admin-constants';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { ClientStatusWebapiService } from '../../../http/client/client-status-webapi.service';
import { MultiSelectItem } from '../../../common/services/dropdowns';
import {
  RoleAssignmentGridData,
  RoleAssignmentUpsertModel,
} from '../../../common/models/admin/roleassignment.model';
import { AdminUpsertDialogComponent } from '../admin-upsert-dialog/admin-upsert-dialog.component';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';
import { RoleEnum, RoleIdMapper } from '../../../intake/constants/Role.enum';

@Component({
  selector: 'app-admin-roles',
  templateUrl: './admin-roles.component.html',
  styleUrl: './admin-roles.component.less',
})
export class AdminRolesComponent implements OnInit, OnDestroy {
  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  rolesDropdownList: DropdownItemOptions[] = [];
  roleSelected?: DropdownItemOptions;
  selectedRoleName: string = '';
  selectedRoleId!: number;
  searchKey: string = '';
  rolesColumns: Column[] = [];
  roleAssignmentGridData: RoleAssignmentGridData[] = [];
  showMarsFssType: boolean = false;
  showConsultingFssType: boolean = false;
  isFssTogglesVisible: boolean = true;
  selectedFssType: number = 0;
  isAscending: boolean = false;
  sortParameter: string = '';
  showExtCommRiskMgrTable:boolean=false;
  public sortingState: SortOptions={}; 
  public clientDictionary: any = <any>{};
  public industrySectorItems: MultiSelectItem[] = [];
  public applicableFssItems: MultiSelectItem[] = [];
  public marsIndustryItems: MultiSelectItem[] = [];
  public industryItems: MultiSelectItem[] = [];
  watchlistAdminRoleColumns: string[] = [
    'editIcon',
    'activationStatus',
    'outlookDisplayName',
    'modifiedDate',
    'roleAssignmentComment',
  ];

  isAddEditEnable: boolean = false;
  deliveryCenterRoleId: number =
    RoleIdMapper[RoleEnum.DELIVERY_CENTER_RISK_MANAGER];
  globalEngagementRoleId: number = RoleIdMapper[RoleEnum.GLOBAL_ENGAGEMENT_EFA];
  private searchSubject = new Subject<string>();

  constructor(
    private adminModuleService: AdminModuleService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private clientstatuswebapiservice: ClientStatusWebapiService
  ) {}

  ngOnInit(): void {
    this.progressIndicatorService.show();
    this.clientstatuswebapiservice.getDropdownCommonData();
    this.clientstatuswebapiservice.dropdowncommondata$.subscribe((response) => {
      if (response) {
        this.clientDictionary = response;
        // Access specific dictionary entries if needed
        this.industrySectorItems =
          this.clientDictionary['optionsOpportunityIndustrySector']
            .resultData || [];
        this.applicableFssItems =
          this.clientDictionary['optionsApplicableFSS'].resultData || [];
        this.marsIndustryItems =
          this.clientDictionary['optionsMarsIndustry'].resultData || [];
        this.industryItems =
          this.clientDictionary['optionsOpportunityIndustry'].resultData || [];

        this.isAddEditEnable = true;
      }
    });
    this.adminModuleService.getRoles().subscribe((data) => {
      this.rolesDropdownList = data;
      this.selectedRoleId = Number(this.rolesDropdownList[0].value);
      this.selectedRoleName = this.rolesDropdownList[0].heading ?? '';
      this.showFssToggles();
      this.setRolesColumns();
      this.getRoleAssignmentGridData();
    });

    this.progressIndicatorService.hide();

    this.searchSubject.pipe(debounceTime(500)).subscribe(() => {
      if (this.searchKey.length === 0) {
        this.clearSearch();
      } else {
        this.getRoleAssignmentGridData();
      }
    });
  }

  showFssToggles() {
    if (
      this.selectedRoleId ===
        this.deliveryCenterRoleId /*Delivery Center Risk Manager*/ ||
      this.selectedRoleId ===
        this.globalEngagementRoleId /*Global Engagement EFA*/ ||
      this.selectedRoleId === 523 /*MARS QRM*/ ||
      this.selectedRoleId === 675 /*Watchlist Admin*/
    ) {
      this.isFssTogglesVisible = false;
    } else {
      this.isFssTogglesVisible = true;
    }
  }

  dropdownStickerPosition: PositionState = PositionState.bottom;
  dropdownOptions: DropdownOptions = {
    theme: ExtThemes.dark,
    kind: ButtonKind.silent,
    size: Size.lg,
    width: WidthState.fixed,
    disabled: false,
  };

  toggleConsOptions: ToggleOptions = {
    theme: Themes.green,
    label: 'Consulting',
  };

  tableMarsToggleOptions: ToggleOptions = {
    theme: Themes.green,
    label: 'MARS',
  };

  toggleActivationOptions: ToggleOptions = {
    theme: Themes.green,
    label: '',
  };

  searchOptions: SearchOptions = {
    placeholder: 'Search by name',
    size: Size.lg,
    customClass: '',
    maxLength: 70,
  };

  buttonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primaryLoud,
    size: 'lg',
    width: WidthState.fixed,
    icon: 'dds-icon_plus',
    isIconLeft: false,
    role: 'button',
    disabled: false,
  };

  allRolesColumns: Column[] = [
    {
      name: 'editIcon',
      header: AdminConstant.EMPTY_SPACE,
      dataType: 'string',
      minWidth: '3.5rem',
      width: '3.5rem',
    },
    {
      name: 'activationStatus',
      header: 'Activation status',
      dataType: 'string',
      minWidth: '8rem',
      width: '8rem',
    },
    {
      name: 'outlookDisplayName',
      header: 'Name',
      dataType: 'string',
      minWidth: '25rem',
      width: '25rem',
      sortable: true,
    },
    {
      name: 'modifiedByText',
      header: 'Modified by',
      dataType: 'string',
      minWidth: '5rem',
    },
    {
      name: 'modifiedDate',
      header: 'Modified date',
      dataType: 'date',
      minWidth: '7rem',
    },
    {
      name: 'isNcaManager',
      header: 'NCA manager',
      dataType: 'string',
      minWidth: '5rem',
    },
    {
      name: 'roleAssignmentComment',
      header: 'Comments',
      dataType: 'string',
      minWidth: '5rem',
    },
    {
      name: 'applicableFssLabel',
      header: 'FSS',
      dataType: 'string',
      minWidth: '10rem',
    },
    {
      name: 'sectorLabel',
      header: 'Designated industry sector',
      dataType: 'string',
      minWidth: '5rem',
    },
    {
      name: 'industryLabel',
      header: 'Designated industry',
      dataType: 'string',
      minWidth: '5rem',
    },
  ];

  toolTipoptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 15,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
    tooltipMaxWidth: 300,
  };

  public stopEventPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  getRoleAssignmentGridData(): void {
    if (
      this.selectedRoleId !== undefined &&
      this.selectedRoleId !== this.deliveryCenterRoleId &&
      this.selectedRoleId !== this.globalEngagementRoleId
    ) {
      this.progressIndicatorService.show();
      this.adminModuleService
        .getListbyRole(
          this.selectedRoleId,
          this.sortParameter,
          this.isAscending,
          this.selectedFssType ?? null,
          this.searchKey ?? null
        )
        .subscribe((data) => {
          this.roleAssignmentGridData = data;
          if (this.roleAssignmentGridData) {
            const marsIndustryLabels = this.createLabelMap(
              this.marsIndustryItems
            );

            this.roleAssignmentGridData.forEach(
              (role: RoleAssignmentGridData) => {
                const fssItems = this.applicableFssItems.filter((item) =>
                  role['applicableFss']?.includes(item.itemCode)
                );
                role['applicableFssLabel'] = fssItems
                  .map((item) => item.label)
                  .join(', ');

                if (this.selectedRoleId == 686 /*MARS Industry leader*/) {
                  role['industryLabel'] =
                    this.getLabelsFromMap(
                      role['marsAudit_Industry'],
                      marsIndustryLabels,
                      'MARS Audit'
                    ) +
                    this.getLabelsFromMap(
                      role['marsConsulting_Industry'],
                      marsIndustryLabels,
                      'MARS Consulting'
                    ) +
                    this.getLabelsFromMap(
                      role['marsTax_Industry'],
                      marsIndustryLabels,
                      'MARS Tax'
                    );
                }

                if (this.selectedRoleId == 536 /*Sector Leader*/) {
                  const dcIndustrySector = this.getIndustrySectorHeading(
                    role['dC_Industry_Sector']
                  );
                  const marsAuditIndustrySector = this.getIndustrySectorHeading(
                    role['marsAudit_Industry_Sector']
                  );
                  const marsConsultingIndustrySector =
                    this.getIndustrySectorHeading(
                      role['marsConsulting_Industry_Sector']
                    );
                  const marsTaxIndustrySector = this.getIndustrySectorHeading(
                    role['marsTax_Industry_Sector']
                  );
                  role['sectorLabel'] =
                    (dcIndustrySector
                      ? 'US-Deloitte Consulting LLP (Consulting): ' +
                        dcIndustrySector
                      : '') +
                    (marsAuditIndustrySector
                      ? 'MARS Audit: ' + marsAuditIndustrySector
                      : '') +
                    (marsConsultingIndustrySector
                      ? 'MARS Consulting: ' + marsConsultingIndustrySector
                      : '') +
                    (marsTaxIndustrySector
                      ? 'MARS Tax: ' + marsTaxIndustrySector
                      : '');
                }
              }
            );
          }
          this.progressIndicatorService.hide();
        });
    } else if (
      this.selectedRoleId === this.deliveryCenterRoleId ||
      this.selectedRoleId === this.globalEngagementRoleId
    ) {
      this.getGlobalEngRoleData();
    }
  }

  private getIndustrySectorHeading(list: string[]): string {
    return list
      .map(
        (item) =>
          (this.industrySectorItems.find((i) => i.itemCode === item) as any)
            ?.heading
      )
      .join(', ');
  }

  createLabelMap(items: MultiSelectItem[]): Map<number, string> {
    const map = new Map<number, string>();
    items.forEach((item) => {
      if (item.label !== undefined) {
        map.set(item.itemId, item.label);
      }
    });
    return map;
  }

  getLabelsFromMap(
    list: string[],
    map: Map<number, string>,
    prefix: string = ''
  ): string {
    const labels = list
      .map((item) => item?.split('_')[0])
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((prefixValue) => map.get(Number(prefixValue)) || '')
      .filter((label) => label !== '')
      .join(', ');
    return labels ? `${prefix}: ${labels}` : '';
  }

  setRolesColumns(): void {
    this.rolesColumns = [...this.allRolesColumns];
    if (this.selectedRoleId !== 536 /*Sector leader*/) {
      this.rolesColumns = this.rolesColumns.filter(
        (column) => column.name !== 'sectorLabel'
      );
    }
    if (this.selectedRoleId !== 686 /*MARS Industry leader*/) {
      this.rolesColumns = this.rolesColumns.filter(
        (column) => column.name !== 'industryLabel'
      );
    }
    if (this.selectedRoleId !== 651 /*NCA specialist*/) {
      this.rolesColumns = this.rolesColumns.filter(
        (column) => column.name !== 'isNcaManager'
      );
    }

    if (
      this.selectedRoleId !== this.deliveryCenterRoleId &&
      this.selectedRoleId !== this.globalEngagementRoleId
    ) {
      this.rolesColumns = this.rolesColumns.filter(
        (column) => column.name !== 'modifiedByText'
      );
    }

    if (
      this.selectedRoleId === this.deliveryCenterRoleId ||
      this.selectedRoleId === this.globalEngagementRoleId ||
      this.selectedRoleId === 523 /*MARS QRM*/ ||
      this.selectedRoleId === 675 /*Watchlist Admin*/
    ) {
      this.rolesColumns = this.rolesColumns.filter(
        (column) => column.name !== 'applicableFssLabel'
      );
    }

    // Set the default sorting state
    this.sortingState = {
      property: 'modifiedDate',
      descending: true,
      dataType: 'date',
    };

    this.sortParameter = this.sortingState.property ?? '';
    this.isAscending = !this.sortingState.descending;
  }

  onRoleSelected(role: string): void {
    role === AdminConstant.RISKMGRROLEVALUE ? this.showExtCommRiskMgrTable = true : this.showExtCommRiskMgrTable = false;
    this.selectedRoleName =
      this.rolesDropdownList.filter((item) => item.value === role)[0].heading ??
      '';
    this.selectedRoleId = Number(role);
    this.setRolesColumns();
    this.showFssToggles();
    this.getRoleAssignmentGridData();
  }

  addNewRecord(){
    this.adminModuleService.addRecordToTable(true);
  }

  filterByFSS(fssType: number): void {
    if (fssType === 1) {
      this.showConsultingFssType = !this.showConsultingFssType;
    } else if (fssType === 2) {
      this.showMarsFssType = !this.showMarsFssType;
    }

    let filterType = 0;
    if (this.showConsultingFssType && this.showMarsFssType) {
      filterType = 3;
    } else if (this.showConsultingFssType) {
      filterType = 1;
    } else if (this.showMarsFssType) {
      filterType = 2;
    }
    this.selectedFssType = filterType;
    this.getRoleAssignmentGridData();
  }

  searchByName(event: any) {
    this.searchKey = event.target.value;
    if (this.searchKey.length > 0) {
      this.getRoleAssignmentGridData();
    }
  }

  trackById(index: number, item: any): number {
    return item.id;
  }

  clearSearch() {
    this.searchKey = '';
    this.getRoleAssignmentGridData();
  }

  onKeyUp(event: KeyboardEvent) {
    this.searchSubject.next(this.searchKey);
  }

  addNewUser() {
    this.openAddUserModel('add');
  }

  onEditTypeClick(event: Event, row: any) {
    let r: RoleAssignmentUpsertModel = {} as RoleAssignmentUpsertModel;
    r.roleData = row;
    this.openAddUserModel('edit', r);
  }

  openAddUserModel(
    actionType: string,
    roleModel: RoleAssignmentUpsertModel = {} as RoleAssignmentUpsertModel
  ) {
    document.body.classList.add('no-scroll');

    roleModel.roleId = this.selectedRoleId;
    roleModel.roleName = this.selectedRoleName;

    let modalRef = this.modalService.open(AdminUpsertDialogComponent, {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      windowClass: 'center-modal modal-fade-in modal-adaptive',
    });
    modalRef.componentInstance.roleModel = roleModel;
    modalRef.componentInstance.clientDictionary = this.clientDictionary;
    modalRef.componentInstance.roleAssignmentGridData =
      this.roleAssignmentGridData;

    modalRef.componentInstance.confirmed.subscribe(
      (res: RoleAssignmentUpsertModel) => this.saveAdminRole(actionType, res)
    );
  }

  updateRoleActivationStatus(row: any) {
    let r: RoleAssignmentUpsertModel = {} as RoleAssignmentUpsertModel;
    r.roleData = row;
    r.roleData.isActive = !r.roleData.isActive;
    this.saveAdminRole('edit', r);
  }

  saveAdminRole(actionType: string, roleModel: RoleAssignmentUpsertModel) {
    if (this.selectedRoleId !== 701 && this.selectedRoleId !== 702) {
      this.adminModuleService.upsertAdminRole(roleModel.roleData).subscribe({
        next: () => {
          this.getRoleAssignmentGridData();
        },
        error: (err: Error) => {
          if ((actionType = 'add')) {
            console.error('An error occurred during add admin role: ', err);
          } else {
            console.error('An error occurred during update admin role: ', err);
          }
        },
      });
    } else {
      this.saveGlobalEngRole(actionType, roleModel.roleData);
    }
  }

  getGlobalEngRoleData(): void {
    if (this.selectedRoleId !== undefined) {
      this.progressIndicatorService.show();
      this.adminModuleService
        .getGlobalEngRoleConfigData(this.selectedRoleId)
        .subscribe({
          next: (data) => {
            if (data) {
              this.roleAssignmentGridData = data;
              this.progressIndicatorService.hide();
            }
          },
          error: (err: HttpErrorResponse) => {
            console.error('Error fetching the data', err);
            this.progressIndicatorService.hide();
          },
        });
    }
  }

  saveGlobalEngRole(
    actionType: string,
    roleData: RoleAssignmentGridData
  ): void {
    this.progressIndicatorService.show();
    this.adminModuleService
      .saveGlobalEngRoleConfig(roleData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.getGlobalEngRoleData();
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicatorService.hide();
          console.error(
            `An error occurred while ${
              actionType === 'add' ? 'adding' : 'updating'
            } admin role: `,
            err
          );
        },
      });
  }

  sortColumn(column: Column, event: MouseEvent) {
    if (this.sortingState && this.sortingState.property === column.name) {
      if (this.sortingState.descending) {
        this.sortingState = { ...this.sortingState, descending: false };
      } else {
        this.sortingState = { ...this.sortingState, descending: true };
      }
    } else {
      this.sortingState = {
        property: column.name,
        descending: false,
        dataType: column.dataType,
      };
    }
    this.sortParameter = this.sortingState.property ?? '';
    this.isAscending = !this.sortingState.descending;
    this.getRoleAssignmentGridData();
  }

  ///Setting Sort pipe value
  getSortingState(){
    //To avoid sorting for modifieddate as it sorts from API
    if(this.sortingState.property == "modifiedDate"){
      return {};
    }
    else{
      return this.sortingState;
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.searchSubject.unsubscribe();
  }
}
