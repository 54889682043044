import { Component, Input } from '@angular/core';
import { AdminModuleService } from '../../../admin-services/admin-module.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { CommonService } from '../../../../http/intake/common.service';
import { RTEOptions } from '@usitsdasdesign/dds-ng/rich-text-editor';
import { Size, ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { Subject, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { options } from '../global-configuration.helper';
import { updateLabelConfigurationBody } from '../../../../common/models/admin/global-configuration.model';
import { IntakeConstant } from '../../../../intake/constants/intake.constant';
import { CommonHelperService } from '../../../../common/services/common-helper.service';

@Component({
  selector: 'app-initiation-edit-popup',
  templateUrl: './initiation-edit-popup.component.html',
  styleUrl: './initiation-edit-popup.component.less',
})
export class InitiationEditPopupComponent {
  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  options: RTEOptions = options;

  constructor(
    private readonly modalService: ModalService,
    private readonly adminService: AdminModuleService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly commonServive: CommonService,
    private readonly commonHelperService: CommonHelperService
  ) {}

  @Input() isFooter: boolean = false;
  @Input() size: string = IntakeConstant.EMPTY_SPACE;
  @Input() isInverse: boolean = false;
  @Input() submissionId: number = 0;
  @Input() keyDetail: string = IntakeConstant.EMPTY_SPACE;
  @Input() value: string = IntakeConstant.EMPTY_SPACE;
  @Input() modifiedBy: string = IntakeConstant.EMPTY_SPACE;
  @Input() modifiedDate: string = IntakeConstant.EMPTY_SPACE;
  @Input() labelId: number = 0;
  @Input() categoryId: number = 0;

  async updateConfigurationLabel(): Promise<void> {
    this.progressIndicator.show();
    const postRequestData: updateLabelConfigurationBody = {
      AppConfigurationLabelValue: this.value,
      AppConfigurationDisplayName: this.keyDetail,
      AppConfigurationLabelId: this.labelId,
      CategoryId: this.categoryId,
    };
    if(this.categoryId === 5 /* On Hover guidances */ || this.categoryId === 2 /*NcaCc */){
      postRequestData.AppConfigurationLabelValue = this.commonHelperService.stripHtmlTags(postRequestData.AppConfigurationLabelValue);
      this.adminService.updateGlobalConfiguration(postRequestData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicator.hide();
          this.close();
        },
        error: (err) => {
          console.error('An error occurred while saving the data: ', err);
        },
      });
    } else{
      this.adminService
      .updateLabelConfiguration(postRequestData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicator.hide();
          this.close();
        },
        error: (err) => {
          console.error('An error occurred while saving the data: ', err);
        },
      });
    }
  }

  formValid(): boolean {
    return this.value.trim() !== IntakeConstant.EMPTY_SPACE;
  }

  close(): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
