import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { CommonHelperService } from '../../../../common/services/common-helper.service';
import { Subject, takeUntil } from 'rxjs';
import { GlobalEngagementsConstant } from '../../../constants/global-engagements.constants';
import { IntakeConstant } from '../../../../intake/constants/intake.constant';
import { RoleEnum } from '../../../../intake/constants/Role.enum';
import { RoleService } from '../../../../http/intake/role.service';
import { GlobalEngagementService } from '../../../../http/global-engagement/global-engagement.service';
import {
  GlobalDocumentStatusModel,
  GlobalDocumentTypeModel,
} from '../../../../common/models/global-engagement.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-engagement-document-edit',
  templateUrl: './engagement-document-edit.component.html',
  styleUrl: './engagement-document-edit.component.less',
})
export class EngagementDocumentEditComponent {
  @Input() public documentInitiatedDetails: any = {};
  private readonly unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    public readonly modalService: ModalService,
    private readonly roleService: RoleService,
    public readonly globalEngagementService: GlobalEngagementService,
    private readonly commonHelperService: CommonHelperService
  ) {}

  documentTitle: string = this.documentInitiatedDetails.documentTitle;
  documentComment: string = this.documentInitiatedDetails.comments;
  documentTypeId: number = 0;
  documentStatusId: number = 0;
  documentTypeItemsList: SelectItemOptions[] = [];
  documentStatusItemList: SelectItemOptions[] = [];
  allStatusAvailable: SelectItemOptions[] = [];
  documentTypeMap!: number;
  documeentStatusMap!: number;
  documentTypeDropdownData: string = GlobalEngagementsConstant.EMPTY_STRING;
  documentStatusDropdownData: string = GlobalEngagementsConstant.EMPTY_STRING;
  isSaveDisabled: boolean = false;
  otherStatus: number = GlobalEngagementsConstant.otherType;
  loggedInUserRoles: string[] = [];

  ngOnInit(): void {
    this.documentTitle = this.documentInitiatedDetails.documentTitle;
    this.documentComment = this.commonHelperService.trimPrefixEmptySpace(
      this.documentInitiatedDetails.comments
    );
    this.documentTypeMap = parseInt(
      this.documentInitiatedDetails.documentTypeId
    );
    this.documeentStatusMap = parseInt(
      this.documentInitiatedDetails.documentStatusId
    );
    this.roleService.currentUserRole$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: string[]) => {
          this.loggedInUserRoles = response;
        },
      });
    this.getDocumentTypeDropdownData(
      this.documentInitiatedDetails.submissionTypeId
    );
    this.getDocumentStatusDropdownData(
      this.documentInitiatedDetails.submissionStatusId,
      this.documentInitiatedDetails.submissionTypeId
    );
  }

  getDocumentStatusDropdownData(id: number, typeId: number): void {
    this.globalEngagementService.getGlobalDocumentStatus().subscribe({
      next: (result: GlobalDocumentStatusModel[]) => {
        const convertedArray = result.map(
          (item: GlobalDocumentStatusModel) => ({
            value: item.documentStatusName,
            heading: item.documentStatusName,
          })
        );
        this.documentStatusItemList = convertedArray;
        this.allStatusAvailable = convertedArray;
        if (this.documentStatusItemList) {
          this.prepopulateStatusDropdown();
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
      },
    });
  }

  getDocumentTypeDropdownData(id: number): void {
    this.globalEngagementService.getGlobalDocumentType(id).subscribe({
      next: (result: GlobalDocumentTypeModel[]) => {
        const convertedArray = result.map((item: GlobalDocumentTypeModel) => ({
          value: item.documentTypeName,
          heading: item.documentTypeName,
        }));
        this.documentTypeItemsList = convertedArray;
        if (this.documentTypeItemsList) this.prepopulateTypeDropdown();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
      },
    });
  }

  async editDocument(): Promise<void> {
    const postRequestData = {
      SubmissionDocumentId: this.documentInitiatedDetails.submissionDocumentId,
      SubmissionId: this.documentInitiatedDetails.submissionId,
      DocumentTitle: this.commonHelperService.trimPrefixEmptySpace(
        this.documentTitle
      ),
      DocumentTypeId: this.documentTypeMap,
      DocumentStatusId: this.documeentStatusMap,
      SubmissionDocumentTypeId:
        this.documentInitiatedDetails.submissionDocumentTypeId,
      AttachmentId: this.documentInitiatedDetails.attachmentId,
      Comments: this.commonHelperService.trimPrefixEmptySpace(
        this.documentComment
      ),
    };
    return new Promise((resolve, reject) => {
      this.globalEngagementService
        .editGlobalDocument(postRequestData)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: () => {
            resolve();
          },
          error: (err: HttpErrorResponse) => {
            console.error('An error occurred during document edit:', err);
            reject(new Error(err.message));
          },
        });
    });
  }

  async closeAll(): Promise<void> {
    try {
      await this.editDocument();
    } catch (error: any) {
      console.error(error);
    }
    this.close();
  }

  close(): void {
    this.modalService.close();
  }

  prepopulateTypeDropdown(): void {
    this.documentTypeDropdownData =
      this.documentInitiatedDetails.documentTypeId;
  }

  prepopulateStatusDropdown(): void {
    this.documentStatusDropdownData =
      this.documentInitiatedDetails.documentStatusId;
  }

  isSaveDisabledCheck(): boolean {
    if (this.documentTypeMap === this.otherStatus) {
      if (this.documentComment.length > 0 && !this.isSaveDisabled) {
        return false;
      }
      return true;
    }
    return this.isSaveDisabled;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
