<div
  class="d-flex"
  *ngIf="
    isPermissionPresent(
      permissionEnums.Intake_Submission_WBS,
      permissionType.Visible
    )
  "
>
  <div class="row margin-top-m">
    <div class="col-12 d-flex">
      <span
        class="icon-required-yellow"
        *ngIf="wbsWarning && showEditWbsChargeCode"
      ></span>
      <span class="eclipse-small-header-grey text-align-left"
        >Charge code (WBS):</span
      >
    </div>
    <div>
      <ng-container *ngIf="showEditWbsChargeCode; else wbsChargeCodeSelected">
        <div class="col-12 padding-top-xs">
          <dds-suggestions-tags-input
            *ngIf="reloadWbsChargeCode"
            [(ngModel)]="wbsChargeCode"
            [ariaLabel]="options.ariaLabel ?? ''"
            [labelPosition]="options.labelPosition ?? ''"
            [placeholder]="options.placeholder ?? ''"
            [searchList]="searchList"
            [disabled]="
              wbsInputDisabled ||
              !isPermissionPresent(
                permissionEnums.Intake_Submission_WBS,
                permissionType.Enable
              )
            "
            [isResponsive]="true"
            (ngModelChange)="onWbsChangeValue()"
          >
          </dds-suggestions-tags-input>
          <div class="d-flex padding-top-xs">
            <dds-checkbox
              [value]="wbsPendingCheckbox"
              [(ngModel)]="wbsPendingCheckbox"
              [theme]="checkboxThemeColor"
              [disabled]="
                !isPermissionPresent(
                  permissionEnums.Intake_Submission_WBS,
                  permissionType.Enable
                )
              "
              (ngModelChange)="wbsPendingCheckboxChange($event)"
            >
            </dds-checkbox>
            <span>WBS Pending</span>
          </div>
        </div>
      </ng-container>
      <ng-template class="col-8 padding-top-xs d-flex" #wbsChargeCodeSelected>
        <span>{{ wbsChargeCode }}</span>
        <span
          class="dds-icon dds-icon_copy__l__stroke icon margin-left-xs cursor-clickable"
          aria-label="Copy"
          aria-placeholder="Copy"
          title="Copy"
          (click)="clipboardCopy()"
        ></span>
        <span
          *ngIf="
            isPermissionPresent(
              permissionEnums.Intake_Submission_WBS,
              permissionType.Enable
            )
          "
          class="dds-icon dds-icon_edit__s__filled margin-left-xs cursor-clickable"
          aria-label="edit wbs charge code"
          aria-placeholder="edit wbs charge code"
          title="edit wbs charge code"
          (click)="editWBSChargeCode()"
        ></span>
      </ng-template>
    </div>
  </div>
  <div
    class="row margin-top-m"
    *ngIf="
      showDescriptionAndArchive() &&
      isPermissionPresent(
        permissionEnums.Intake_Submission_WBSDescription,
        permissionType.Visible
      )
    "
  >
    <div>
      <span class="eclipse-small-header-grey text-align-left"
        >WBS Description</span
      >
    </div>

    <div class="col-12">
      <div class="textarea-container">
        <div class="row padding-top-xs">
          <div class="col-12">
            {{ wbsDescription ? wbsDescription : "-" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="row margin-top-m" *ngIf="showDescriptionAndArchive()">
      <div class="col-12 d-flex">
        <span
          class="icon-required-yellow"
          *ngIf="selectedArchive === emptyString"
        ></span>
        <span class="eclipse-small-header-grey text-align-left">Archive:</span>
      </div>
      <div *ngIf="showArchiveDropdown; else showArchiveDetails" class="col-12">
        <dds-select
          [placeholder]="archiveSelectOptions.placeholder ?? ''"
          [list]="actionDropdownList"
          [stickerMaxHeight]="'40vh'"
          [(ngModel)]="selectedArchive"
          (ngModelChange)="onArchiveSelect()"
        ></dds-select>
      </div>
      <ng-template #showArchiveDetails>
        <div class="col-12">
          <div class="textarea-container">
            <div class="row padding-top-xs">
              <div>
                <span
                  [ddsTooltip]="wbsArchiveSwfidivetails"
                  [tooltipInvokeType]="tooltipOptions.tooltipInvokeType ?? ''"
                  [tooltipType]="tooltipOptions.tooltipType ?? ''"
                  [tooltipSize]="tooltipOptions.tooltipSize ?? ''"
                  [tooltipPosition]="tooltipOptions.tooltipPosition ?? ''"
                  [tooltipIndent]="tooltipOptions.tooltipIndent ?? 0"
                  [tooltipHasBeak]="tooltipOptions.tooltipHasBeak ?? false"
                  [tooltipIsDisabled]="
                    tooltipOptions.tooltipIsDisabled ?? false
                  "
                  [tooltipIsInverse]="tooltipOptions.tooltipIsInverse ?? false"
                  [tooltipTheme]="tooltipOptions.tooltipTheme ?? ''"
                  [tooltipCustomClass]="tooltipOptions.tooltipCustomClass ?? ''"
                  ddsFocus
                  [tooltipMaxWidth]="tooltipOptions.tooltipMaxWidth ?? 0"
                  class="dds-link"
                >
                  {{ archiveDescription }}</span
                >
                <span
                  class="dds-icon dds-icon_edit__s__filled margin-left-xs cursor-clickable"
                  aria-label="Copy"
                  aria-placeholder="Copy"
                  title="Copy"
                  (click)="editArchiveDetails()"
                ></span>
              </div>
            </div>
          </div></div
      ></ng-template>
    </div>
  </div>
</div>

<ng-template #wbsArchiveSwfidivetails>
  <div class="w-fit-content h-fit-content">
    <div class="d-flex">
      <div class="padding-xs">Archive name:</div>
      <div class="padding-xs font-bold-600">{{ archiveDescription }}</div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Archive description:</div>
      <div class="padding-xs font-bold-600">
        {{ archive?.archiveDescription }}
      </div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project name:</div>
      <div class="padding-xs font-bold-600">
        {{ swiftDetails?.projectName }}
      </div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project start:</div>
      <div class="padding-xs font-bold-600">
        {{ swiftDetails?.projectStartDate }}
      </div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project end:</div>
      <div class="padding-xs font-bold-600">
        {{ swiftDetails?.projectEndDate }}
      </div>
    </div>
    <div>
      <div class="padding-xs">Swift project PPMD:</div>
      <div class="padding-xs font-bold-600">
        {{ swiftDetails?.projectPPMD }}
      </div>
    </div>
  </div>
</ng-template>
