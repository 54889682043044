import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  LepValue,
  OpportunityDetailsNavModel,
} from '../../../common/models/opportunity-details.model';
import { SubmissionService } from '../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { AccordionOptions } from './service-delivery/service-delivery-settings';
import { lepDataValue } from '../opportunity-details-settings';
import {
  configurationType,
  ParsedRules,
} from '../../../common/models/common-models';
import { SecurityWebapiService } from '../../../http/security/security-webapi.service';
import { PermissionCheck } from '../../../common/validations/PermissionCheck';
@Component({
  selector: 'app-oportunity-detail-right-panel',
  templateUrl: './oportunity-detail-right-panel.component.html',
  styleUrl: './oportunity-detail-right-panel.component.less',
})
export class OportunityDetailRightPanelComponent
  extends PermissionCheck
  implements OnInit, OnDestroy
{
  @Input() opportunityNumber: string = '';
  @Input() tabTitle: string | null = '';
  @Input() submissionDetails: OpportunityDetailsNavModel[] = [];

  accordionOptions = AccordionOptions;
  unsubscriber$: Subject<void> = new Subject<void>();
  opportunityData: any[] = [];
  lepValue: LepValue = lepDataValue;
  @Output() lepValueChange: EventEmitter<LepValue> =
    new EventEmitter<LepValue>();
  configLables: configurationType[] = [];

  constructor(
    private readonly submissionService: SubmissionService,
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
  }

  ngOnInit(): void {
    this.getServiceData();
    this.getLabelConfiguration();
  }

  getServiceData(): void {
    this.submissionService
      .getDeliveryServiceTable(this.opportunityNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.opportunityData = data;
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  shouldShowOfferingQuestions(): boolean {
    if (
      !this.isPermissionPresent(
        this.permissionEnums.Intake_Opportunity_OfferingServiceQuestions,
        this.permissionType.Visible
      ) ||
      this.opportunityData?.length === 0
    ) {
      return false;
    }
    const hasInvalidStatus = this.opportunityData.some(
      (submission) => submission.offeringPortfolio === 'CYBER & STRATEGIC RISK'
    );
    if (hasInvalidStatus) {
      return true;
    }
    this.submissionService.updateComplexQuestionCyberStatus(true);
    return false;
  }

  onLepValueChange(updatedLepValue: LepValue): void {
    this.lepValue = updatedLepValue;
    this.lepValueChange.emit(this.lepValue);
  }

  getLabelConfiguration(): void {
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response && response.length > 0) {
            this.configLables = response;
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
