<div class="dds-modal-overlay" (click)="close()"></div>

<div
  ddsResize
  [ngClass]="{
    'dds-modal_lg': size === 'md',
    'dds-modal_inverse': isInverse
  }"
  class="dds-modal"
>
  <div class="dds-modal__header">
    <span class="dds-modal__title">Modify item</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="close()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div class="body-section">
      <div class="comment margin-top-xs">
        <span class="eclipse-small-header-grey">Key display:</span>
        <div [innerHTML]="keyDetail"></div>
      </div>
      <div class="comment margin-bottom-l margin-top-xs">
        <span class="eclipse-small-header-grey">Value</span>
        <dds-rich-text-editor
          [size]="options.size ?? ''"
          [(ngModel)]="value"
          [disabled]="options.disabled ?? false"
          [isResponsive]="options.isResponsive ?? false"
          [theme]="options.theme ?? ''"
          [isError]="options.isError ?? false"
          [errorState]="options.errorState ?? ''"
          [customClass]="options.customClass ?? ''"
          [placeholder]="options.placeholder ?? ''"
          [maxHeight]="options.maxHeight ?? 0"
          [panelPosition]="options.panelPosition ?? ''"
          [isInverse]="options.isInverse ?? false"
          [isViewSourceActive]="options.isViewSourceActive ?? false"
          [isBoldActive]="options.buttonStatuses?.boldActive ?? false"
          [isItalicActive]="options.buttonStatuses?.italicActive ?? false"
          [isUnderlineActive]="options.buttonStatuses?.underlineActive ?? false"
          [isCrossedActive]="options.buttonStatuses?.crossedActive ?? false"
          [isHeader1Active]="options.buttonStatuses?.header1Active ?? false"
          [isHeader2Active]="options.buttonStatuses?.header2Active ?? false"
          [isHeader3Active]="options.buttonStatuses?.header3Active ?? false"
          [isSuperscriptActive]="
            options.buttonStatuses?.superscriptActive ?? false
          "
          [isSubscriptActive]="options.buttonStatuses?.subscriptActive ?? false"
          [isOrderedListActive]="
            options.buttonStatuses?.orderedListActive ?? false
          "
          [isNumeralListActive]="
            options.buttonStatuses?.numeralListActive ?? false
          "
          [isAlignLeftActive]="options.buttonStatuses?.alignLeftActive ?? false"
          [isAlignCenterActive]="
            options.buttonStatuses?.alignCenterActive ?? false
          "
          [isAlignRightActive]="
            options.buttonStatuses?.alignRightActive ?? false
          "
          [isHyperLinkActive]="options.buttonStatuses?.hyperLinkActive ?? false"
          [isBlockQuoteActive]="
            options.buttonStatuses?.blockQuoteActive ?? false
          "
          [isRemoveFormatActive]="
            options.buttonStatuses?.removeFormatActive ?? false
          "
          [isTextColorActive]="options.buttonStatuses?.textColorActive ?? false"
          [isBgColorActive]="options.buttonStatuses?.bgColorActive ?? false"
          [stickerWidth]="options.stickerWidth ?? ''"
          [stickerIsOutsideClick]="options.stickerIsOutsideClick ?? false"
          [stickerShift]="options.stickerShift ?? 0"
          [stickerMaxHeight]="options.stickerMaxHeight ?? ''"
          [stickerIsDisabled]="options.stickerIsDisabled ?? false"
          [stickerIndent]="options.stickerIndent ?? 0"
          [stickerCustomClass]="options.stickerCustomClass ?? ''"
          [stickerIsDynamic]="options.stickerIsDynamic ?? false"
        >
        </dds-rich-text-editor>
        <span class="eclipse-small-header-grey"
          >Modified {{ modifiedDate | date : "MM/dd/yyyy" }} by {{ modifiedBy }}
        </span>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item custom-button"
        (clicked)="updateConfigurationLabel()"
        [disabled]="!formValid()"
        (keyboardClicked)="updateConfigurationLabel()"
        [ariaLabel]="'Additional close'"
        >Save</dds-button
      >
      <dds-button
        class="dds-modal__footer-item custom-button"
        kind="secondaryLoud"
        [isInverse]="isInverse"
        (clicked)="close()"
        (keyboardClicked)="close()"
        [ariaLabel]="'Cancel'"
        >Cancel</dds-button
      >
    </div>
  </div>
</div>
