import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { CommonService } from '../../../../http/intake/common.service';
import { Subject, takeUntil } from 'rxjs';
import { configurationType } from '../../../../common/models/common-models';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { IntakeConstant } from '../../../../intake/constants/intake.constant';
import { OppertunitySubmissionStatusCode } from '../../../../common/models/oppertunity-submission-statusCode.model';
import { IntakeSubmissionActionReasonDropdown } from '../../../../common/models/submission-action.model';
import { selectOptions, SHOW_MORE_BTN_OPTIONS } from './disontinuePopUp.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalEngagementService } from '../../../../http/global-engagement/global-engagement.service';

@Component({
  selector: 'app-discontinue-popup',
  templateUrl: './discontinue-popup.component.html',
  styleUrl: './discontinue-popup.component.less',
})
export class DiscontinuePopupComponent {
  constructor(
    private readonly modalService: ModalService,
    private readonly globalEngagementService: GlobalEngagementService,
    private readonly intakeDetailService: SubmissionService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly commonServive: CommonService
  ) {}

  @Input() isFooter: boolean = false;
  @Input() size: string = IntakeConstant.EMPTY_SPACE;
  @Input() isInverse: boolean = false;
  @Input() submissionId: number = 0;
  emptySpace = IntakeConstant.EMPTY_SPACE;
  isShowMoreDocumentNotes: boolean = true;
  showMoreText: string = IntakeConstant.SHOW_LESS_TEXT;
  showMoreOptions: any = SHOW_MORE_BTN_OPTIONS;
  actionDropdownList: SelectItemOptions[] = [];
  selectedReason!: number;
  additionDetail: string = IntakeConstant.EMPTY_SPACE;
  configData: configurationType[] = [];
  configLableDescription: string = IntakeConstant.EMPTY_SPACE;
  configLableGuidance: string = IntakeConstant.EMPTY_SPACE;
  readonly unsubscriber$: Subject<void> = new Subject<void>();
  textareaOptions = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  selectOptions = selectOptions;
  statusCodeList!: OppertunitySubmissionStatusCode[];

  ngOnInit(): void {
    this.getActionDropdownData(IntakeConstant.DiscontinueActionID);
    this.getLabelConfiguration();
    this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_UP;
    this.commonServive
      .getOppertunityStatusCode()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[]) => {
          this.statusCodeList = response;
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during fetching data: ', err);
        },
      });
  }

  getLabelConfiguration(): void {
    this.intakeDetailService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response) {
            this.configData = response;
            this.configLableDescription =
              this.configData.find(
                (config: configurationType) =>
                  config.appConfigurationLabelKey ===
                  'DISCONTINUE_SUBMISSION_GUIDANCE_EXT_COMM'
              )?.appConfigurationLabelValue || IntakeConstant.EMPTY_SPACE;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getActionDropdownData(id: number): void {
    this.globalEngagementService.getActionReasons(id).subscribe({
      next: (result: IntakeSubmissionActionReasonDropdown[]) => {
        const convertedArray = result.map(
          (item: IntakeSubmissionActionReasonDropdown) => ({
            value: item.reasonId,
            heading: item.reason,
          })
        );
        this.actionDropdownList = convertedArray;
      },
      error: (err: HttpErrorResponse) => {
        console.error(
          'Following error occurred during fetching action dropdown data: ',
          err
        );
        this.progressIndicator.hide();
      },
    });
  }

  clickToShowDocumentMoreLepMessage(): void {
    this.isShowMoreDocumentNotes = !this.isShowMoreDocumentNotes;
    if (!this.isShowMoreDocumentNotes) {
      this.showMoreText = IntakeConstant.SHOW_MORE_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_DOWN;
    } else {
      this.showMoreText = IntakeConstant.SHOW_LESS_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_UP;
    }
  }

  async saveSubmissionHistory(): Promise<void> {
    // TODO: Use constants instead of hardcode after reset popup is implemented
    const postRequestData = {
      submissionId: this.submissionId,
      currentStatusId: 3,
      previousStatusId: 1,
      submissionActionId: IntakeConstant.DiscontinueActionID, //2
      reasonId: this.selectedReason,
    };
    try {
      await this.globalEngagementService
        .saveSubmissionHistory(postRequestData)
        .pipe(takeUntil(this.unsubscriber$))
        .toPromise();
      this.closePopUp();
    } catch (err) {
      console.error('An error occurred while saving the data: ', err);
    }
    this.closePopUp();
  }

  formValid(): boolean {
    return this.selectedReason != undefined;
  }

  closePopUp(): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
