import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MsGraphService } from '../../../http/msgraph.service';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../intake/constants/intake.constant';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { SubmissionService } from '../../../http/intake/submission.service';
import { InitiationApiService } from '../../../http/initiation/initiation-api.service';

@Component({
  selector: 'app-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrl: './people-picker.component.less',
})
export class PeoplePickerComponent implements OnInit, OnDestroy {
  emptyString: string = IntakeConstant.EMPTY_SPACE;

  @Output() userSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() isTechinalReviewerSearch: boolean = false;
  @Input() tempLepName: string | null | undefined = '';
  @Input() isTeamMemberSearch: boolean = false;
  @Input() isLep: boolean = false;
  @Input() showLabel: boolean = false;
  @Input() labelText: string = this.emptyString;
  @Input() isPPMD: boolean = false;
  @Input() placeholder!: string;

  private readonly searchQuery$: Subject<string> = new Subject<string>();
  data: any = [];
  searchedUser: string = this.emptyString;
  unsubscriber$: Subject<any> = new Subject();
  userList: any = [];
  notFound: string = this.emptyString;
  selectedUser: any = {};
  pageNumber: number = 1;
  showSelected: boolean = false;
  searchOptions: SearchOptions = {
    placeholder: IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER,
    size: Size.md,
    customClass: this.emptyString,
  };
  showdropDown: boolean = false;

  constructor(
    private readonly msgraph: MsGraphService,
    private readonly submissionService: SubmissionService,
    private readonly initiationApiService: InitiationApiService
  ) {}

  ngOnInit(): void {
    this.searchQuery$
      .pipe(
        debounceTime(IntakeConstant.SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.unsubscriber$)
      )
      .subscribe((event) => {
        if (event.length > IntakeConstant.SEARCH_STRING_LIMIT) {
          if (this.isTechinalReviewerSearch) {
            this.fetchTechnicalUserUsers(event);
          } else if (this.isTeamMemberSearch) {
            this.fetchTeamMembers(event);
          } else if (this.isPPMD) {
            this.fetchPPMDUsers(event);
          } else {
            this.fetchUserSearchResultUsingGraphApi(event);
          }
          this.showdropDown = true;
        } else {
          this.notFound = this.emptyString;
          this.userList = [];
          this.showdropDown = false;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isTechinalReviewerSearch']?.currentValue) {
      this.searchOptions.placeholder =
        IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER_LEAD_ENGAGEMENT_PARTNER;
    } else if (this.isTeamMemberSearch) {
      this.searchOptions.placeholder =
        IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER_TEAM_MEMBER;
    } else {
      this.searchOptions.placeholder =
        IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER;
    }

    if (changes['placeholder']?.currentValue === IntakeConstant.PLACEHOLDER_TEXT_FOR_SECONDARY_CONTACT) {
      this.searchOptions.placeholder = this.placeholder;
    }
  }

  fieldCleared(): void {
    this.userList = [];
    this.searchedUser = this.emptyString;
    this.notFound = this.emptyString;
    this.showdropDown = false;
  }

  private fetchUserSearchResultUsingGraphApi(searchString: string): void {
    this.msgraph
      .searchUsers(searchString)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.transformUserDetails(users['value']);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  fetchTechnicalUserUsers(searchString: string): void {
    this.submissionService
      .getTechnicalReviewers(searchString, this.pageNumber, this.isLep)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.transformUserDetails(users['technicalReviewers']);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  fetchTeamMembers(searchString: string): void {
    this.submissionService
      .fetchEmployeesTeamMember(searchString, this.pageNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          console.log(users);
          this.transformUserDetails(users['employees']);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  fetchPPMDUsers(searchString: string): void {
    this.initiationApiService
      .searchPPMD(searchString)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.transformUserDetails(users);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  private readonly transformUserDetails = (users: any): any => {
    this.notFound = this.emptyString;
    const filteredUsers = users.filter((user: any) => user.fullName !== this.tempLepName);
    this.userList = filteredUsers;
    if (this.userList.length === 0) {
      this.notFound = IntakeConstant.NO_MATCHES_FOUND;
    } else {
      this.notFound = this.emptyString;
    }
  };

  fetchUserDetails(item: any): void {
    const transformdata: any = {
      displayName:
        item?.displayName ??
        item?.outlookDisplayName ??
        item?.fullName ??
        `${item?.givenName ?? this.emptyString} ${
          item?.surname ?? this.emptyString
        }`.trim(),
      email:
        item?.mail ?? item?.emailId ?? item?.emailAddress ?? this.emptyString,
      location: item?.officeLocation ?? item?.location ?? null,
      fullname:
        item?.fullName ??
        `${item?.givenName ?? this.emptyString} ${
          item?.surname ?? this.emptyString
        }`.trim(),
      reviewerId: item?.reviewerId ?? item?.employeeId ?? item?.empID ?? null,
    };
    this.userSelected.emit(transformdata);
    this.selectedUser = item;
    this.showdropDown = false;
    this.userList = [];
    this.searchedUser = this.emptyString;
  }

  resetUser(): void {
    this.selectedUser = this.emptyString;
    this.showSelected = false;
  }

  findUsers(event: string): void {
    this.searchQuery$.next(event);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next(this.emptyString);
    this.unsubscriber$.complete();
  }
}
