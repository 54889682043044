import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import {
  CommunicationContains,
  communicationContainsGroupOptions,
  isAnalayzeRegulationGroupOptions,
  IsAnalayzeRegulationOptions,
  isCrossBusinessGlobalGroupOptions,
  isDiscussServicesToAssistWithRegGroupOptions,
  IsDiscussServicesToAssistWithRegOptions,
  isGenAIGroupOptions,
  isGovernmentRegulationInvolvedGroupOptions,
  isNewNascentEvolvProductsGroupOptions,
  isSensitiveMatterGroupOptions,
  isSpeakingOnPanelGroupOptions,
  isThirdPartyInvolvedGroupOptions,
  IsTPRMApprovalEmailIncludedOptions,
  multiSelectOptions,
  options,
  otherMemberFirm,
  radioBtnsOriginal,
  radioGroupOptions,
  tags,
  textareaOptions,
} from './risk-assessment-helper';
import { MultiSelectItem } from '@usitsdasdesign/dds-ng/multi-select';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../../intake/constants/intake.constant';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { IAutosaverOptions, Saver } from '../../../../common/autosaver/saver';
import { AutosaverService } from '../../../../common/autosaver/autosaver.service';
import { RiskAssessmentFormData } from '../../../../common/models/external-communication-submission-details-common-section.model';

@Component({
  selector: 'app-risk-assessment',
  templateUrl: './risk-assessment.component.html',
  styleUrl: './risk-assessment.component.less',
})
export class RiskAssessmentComponent implements OnInit, OnDestroy {
  @Input() submissionDetailModel: any;
  @Output() riskAssessmentRequiredFieldsFilled: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() isControlEnable!: boolean;
  saveComplexityModule!: Saver | null;
  model: RiskAssessmentFormData = {} as RiskAssessmentFormData;

  riskAssessmentForm!: FormGroup;
  radioBtns: RadioButton[] = radioBtnsOriginal;
  radioGroupOptions: RadioGroupOptions = radioGroupOptions;
  multiSelectOptions = multiSelectOptions;
  CommunicationContains: MultiSelectItem[] = CommunicationContains;
  memberFirm: MultiSelectItem[] = otherMemberFirm;
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  Tags = tags;
  options: TagOptions = options;
  showMultiSelect: boolean = false;
  showTextAreaForm: boolean = false;
  textareaOptions: TextareaOptions = textareaOptions;
  showThirdPartyInvolved: boolean = false;
  showAnalayzeRegulation: boolean = false;
  showDiscussServicesToAssistWithReg: boolean = false;
  isCrossBusinessGlobalGroupOptions = isCrossBusinessGlobalGroupOptions;
  submissionId!: number;
  unsubscriber$: Subject<void> = new Subject<void>();
  riskAssessmentDetails: any;
  showCommunicationContains: boolean = false;
  showBusinessOrMemberFirm: boolean = false;

  isGenAIGroupOptions = isGenAIGroupOptions;
  communicationContainsGroupOptions = communicationContainsGroupOptions;
  isNewNascentEvolvProductsGroupOptions = isNewNascentEvolvProductsGroupOptions;
  isSensitiveMatterGroupOptions = isSensitiveMatterGroupOptions;
  isAnalayzeRegulationGroupOptions = isAnalayzeRegulationGroupOptions;
  isThirdPartyInvolvedGroupOptions = isThirdPartyInvolvedGroupOptions;
  isDiscussServicesToAssistWithRegGroupOptions =
    isDiscussServicesToAssistWithRegGroupOptions;
  isGovernmentRegulationInvolvedGroupOptions =
    isGovernmentRegulationInvolvedGroupOptions;
  isSpeakingOnPanelGroupOptions = isSpeakingOnPanelGroupOptions;
  IsTPRMApprovalEmailIncludedOptions = IsTPRMApprovalEmailIncludedOptions;
  IsAnalayzeRegulationOptions = IsAnalayzeRegulationOptions;
  IsDiscussServicesToAssistWithRegOptions =
    IsDiscussServicesToAssistWithRegOptions;

  constructor(
    private readonly fb: FormBuilder,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly autosaverService: AutosaverService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.getRiskAssessmentDetails();

    let autoSaverOptions = {
      onSuccess: (saver: any, result: RiskAssessmentFormData) => {
        return this.autoSaveHandler();
      },
    } as IAutosaverOptions;
    this.saveComplexityModule = this.autosaverService.newSaver(
      'model',
      this,
      (model) => this.saveDetails(model),
      autoSaverOptions
    );

    this.saveComplexityModule.start();

    this.riskAssessmentForm.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        takeUntil(this.unsubscriber$)
      )
      .subscribe((value) => {
        this.emitFormData();
        this.saveComplexityModule?.saveNow();
      });

    this.riskAssessmentForm
      .get('IsThirdPartyInvolved')
      ?.valueChanges.subscribe((value) => {
        if (value == 'yes') {
          this.showThirdPartyInvolved = true;
        } else {
          this.showThirdPartyInvolved = false;
        }
      });

    this.riskAssessmentForm
      .get('IsCrossBusinessGlobal')
      ?.valueChanges.subscribe((value) => {
        if (value == 'yes') {
          this.showMultiSelect = true;
        } else {
          this.showMultiSelect = false;
        }
      });

    this.riskAssessmentForm
      .get('BusinessOrMemberFirm')
      ?.valueChanges.subscribe((value) => {
        if (Array.isArray(value)) {
          this.showTextAreaForm = value.some(
            (item) => item.label === 'Other Member Firm'
          );
        }
        this.showBusinessOrMemberFirm =
          this.riskAssessmentForm.get('BusinessOrMemberFirm')?.value.length > 0;
      });

    this.riskAssessmentForm
      .get('CommunicationContains')
      ?.valueChanges.subscribe((value) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            if (item.label == 'No, none of the above apply') {
              this.riskAssessmentForm
                .get('CommunicationContains')
                ?.setValue([]);
            }
          });
        }
        this.showCommunicationContains =
          this.riskAssessmentForm.get('CommunicationContains')?.value.length >
          0;
      });

    this.riskAssessmentForm
      .get('IsGovernmentRegulationInvolved')
      ?.valueChanges.subscribe((value) => {
        if (value == 'yes') {
          this.showAnalayzeRegulation = true;
        } else {
          this.showAnalayzeRegulation = false;
        }
      });

    this.riskAssessmentForm
      .get('IsAnalyzeRegulation')
      ?.valueChanges.subscribe((value) => {
        if (value == 'yes') {
          this.showDiscussServicesToAssistWithReg = true;
        } else {
          this.showDiscussServicesToAssistWithReg = false;
        }
      });
  }

  async saveDetails(model: RiskAssessmentFormData): Promise<any> {
    return this.externalCommService
      .saveRiskAssessmentData(model)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (response) => {
          this.checkRequiredFields();
        },
        (error) => {
          console.error(
            'An error occurred during saving riskAssessmentDetails: ',
            error
          );
        }
      );
  }

  autoSaveHandler(): void {
    //if refresh is needed after save API, can be done here;
  }
  removeMultiSelectItemBusinessOrMemberFirm(item: MultiSelectItem): void {
    const current = this.riskAssessmentForm.get('BusinessOrMemberFirm')?.value;
    const updated = current.filter((i: MultiSelectItem) => i !== item);
    this.riskAssessmentForm.get('BusinessOrMemberFirm')?.setValue(updated);
  }
  getRiskAssessmentDetails(): any {
    this.submissionId = this.submissionDetailModel.submissionId;
    this.externalCommService
      .getExtCommRiskAssessment(this.submissionId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response) => {
          this.riskAssessmentDetails = response;
          this.updateRiskForm(this.riskAssessmentDetails);
        },
        error: (err) => {
          console.error(
            'An error occurred during fetching riskAssessmentDetails: ',
            err
          );
        },
      });
  }
  emitFormData(): void {
    const formData = this.riskAssessmentForm.value;
    const transformArrayToString = (array: any[]) =>
      array?.map((item) => item.label).join(';');
    const transformBoolean = (value: string) => value === 'yes';

    this.model = {
      SubmissionId: this.submissionId,
      IsCrossBusinessGlobal: formData.IsCrossBusinessGlobal,
      BusinessOrMemberFirm: transformArrayToString(
        formData.BusinessOrMemberFirm
      ),
      MemberFirmOther: formData.MemberFirmOther,
      IsGenAI: transformBoolean(formData.IsGenAI),
      CommunicationContains: transformArrayToString(
        formData.CommunicationContains
      ),
      IsNewNascentEvolvProducts: transformBoolean(
        formData.IsNewNascentEvolvProducts
      ),
      IsSensitiveMatter: transformBoolean(formData.IsSensitiveMatter),
      IsThirdPartyInvolved: transformBoolean(formData.IsThirdPartyInvolved),
      IsTrpmApprovalEmailIncluded: transformBoolean(
        formData.IsTPRMApprovalEmailIncluded
      ),
      IsGovernmentRegulationInvolved: transformBoolean(
        formData.IsGovernmentRegulationInvolved
      ),
      IsAnalyzeRegulation: transformBoolean(formData.IsAnalyzeRegulation),
      IsDiscussServicesToAssistWithReg: transformBoolean(
        formData.IsDiscussServicesToAssistWithReg
      ),
      IsSpeakingOnPanel: transformBoolean(formData.IsSpeakingOnPanel),
    };
  }
  updateRiskForm(riskAssessmentDetails: any): void {
    const transformBooleanToString = (value: boolean): string =>
      value ? 'yes' : 'no';
    const convertStringToArrayOfObjects = (
      str: string
    ): { label: string; value: boolean }[] => {
      if (!str.trim()) {
        return [];
      } else {
        return str
          ?.split(';')
          .map((item) => ({ label: item.trim(), value: true }));
      }
    };
    this.riskAssessmentForm.patchValue({
      IsCrossBusinessGlobal: riskAssessmentDetails?.isCrossBusinessGlobal,
      BusinessOrMemberFirm: convertStringToArrayOfObjects(
        riskAssessmentDetails?.businessOrMemberFirm
      ),
      MemberFirmOther: riskAssessmentDetails?.memberFirmOther,
      IsGenAI: transformBooleanToString(riskAssessmentDetails?.isGenAI),
      CommunicationContains: convertStringToArrayOfObjects(
        riskAssessmentDetails?.communicationContains
      ),
      IsNewNascentEvolvProducts: transformBooleanToString(
        riskAssessmentDetails?.isNewNascentEvolvProducts
      ),
      IsSensitiveMatter: transformBooleanToString(
        riskAssessmentDetails?.isSensitiveMatter
      ),
      IsAnalyzeRegulation: transformBooleanToString(
        riskAssessmentDetails?.isAnalayzeRegulation
      ),
      IsThirdPartyInvolved: transformBooleanToString(
        riskAssessmentDetails?.isThirdPartyInvolved
      ),
      IsTrpmApprovalEmailIncluded: transformBooleanToString(
        riskAssessmentDetails?.isTrpmApprovalEmailIncluded
      ),
      IsDiscussServicesToAssistWithReg: transformBooleanToString(
        riskAssessmentDetails?.isDiscussServicesToAssistWithReg
      ),
      IsGovernmentRegulationInvolved: transformBooleanToString(
        riskAssessmentDetails?.isGovernmentRegulationInvolved
      ),
      IsSpeakingOnPanel: transformBooleanToString(
        riskAssessmentDetails?.isSpeakingOnPanel
      ),
    });
  }
  
  initializeForm(): void {
    this.riskAssessmentForm = this.fb.group({
      IsCrossBusinessGlobal: [{value:IntakeConstant.EMPTY_SPACE, disabled: !this.isControlEnable}],
      BusinessOrMemberFirm: [{value:[], disabled: !this.isControlEnable}],
      MemberFirmOther: [{value:IntakeConstant.EMPTY_SPACE, disabled: !this.isControlEnable}],
      IsGenAI: [{value:IntakeConstant.EMPTY_SPACE, disabled: !this.isControlEnable}],
      CommunicationContains: [[]],
      IsNewNascentEvolvProducts: [{value:IntakeConstant.EMPTY_SPACE, disabled: !this.isControlEnable}],
      IsSensitiveMatter: [{value:IntakeConstant.EMPTY_SPACE, disabled: !this.isControlEnable}],
      IsAnalyzeRegulation: [{value:IntakeConstant.EMPTY_SPACE, disabled: !this.isControlEnable}],
      IsThirdPartyInvolved: [{value:IntakeConstant.EMPTY_SPACE, disabled: !this.isControlEnable}],
      IsTrpmApprovalEmailIncluded: [{value:IntakeConstant.EMPTY_SPACE, disabled: !this.isControlEnable}],
      IsDiscussServicesToAssistWithReg: [{value:IntakeConstant.EMPTY_SPACE, disabled: !this.isControlEnable}],
      IsGovernmentRegulationInvolved: [{value:IntakeConstant.EMPTY_SPACE, disabled: !this.isControlEnable}],
      IsSpeakingOnPanel: [{value:IntakeConstant.EMPTY_SPACE, disabled: !this.isControlEnable}],
    });
  }
  isFieldFilled(controlName: string): boolean {
    const control = this.riskAssessmentForm.get(controlName);
    return !(
      control !== null &&
      control.value !== null &&
      control.value !== IntakeConstant.EMPTY_SPACE
    );
  }

  removeMultiSelectItemCommContain(item: MultiSelectItem): void {
    const current = this.riskAssessmentForm.get('CommunicationContains')?.value;
    const updated = current.filter((i: MultiSelectItem) => i !== item);
    this.riskAssessmentForm.get('CommunicationContains')?.setValue(updated);
  }

  checkRequiredFields(): void {
    let requiredFieldsFilled = this.isNotNullAndNotEmpty(
      this.riskAssessmentDetails.isCrossBusinessGlobal
    );
    this.riskAssessmentDetails?.isGenAI !== null &&
      this.isNotNullAndNotEmpty(
        this.riskAssessmentDetails.communicationContains
      );
    this.riskAssessmentDetails?.isNewNascentEvolvProducts !== null &&
      this.riskAssessmentDetails?.isSensitiveMatter !== null &&
      this.riskAssessmentDetails?.isThirdPartyInvolved !== null &&
      this.riskAssessmentDetails?.isGovernmentRegulationInvolved !== null &&
      this.riskAssessmentDetails?.isSpeakingOnPanel !== null;

    if (this.showAnalayzeRegulation) {
      requiredFieldsFilled =
        requiredFieldsFilled &&
        this.riskAssessmentDetails?.isAnalayzeRegulation !== null;
    }
    if (this.showThirdPartyInvolved) {
      requiredFieldsFilled =
        requiredFieldsFilled &&
        this.riskAssessmentDetails?.isTrpmApprovalEmailIncluded !== null;
    }
    if (this.showDiscussServicesToAssistWithReg) {
      requiredFieldsFilled =
        requiredFieldsFilled &&
        this.riskAssessmentDetails?.isDiscussServicesToAssistWithReg !== null;
    }
    if (this.showTextAreaForm) {
      requiredFieldsFilled =
        requiredFieldsFilled &&
        this.isNotNullAndNotEmpty(this.riskAssessmentDetails.memberFirmOther);
    }
    if (this.showMultiSelect) {
      requiredFieldsFilled =
        requiredFieldsFilled &&
        this.isNotNullAndNotEmpty(
          this.riskAssessmentDetails.businessOrMemberFirm
        );
    }
    this.riskAssessmentRequiredFieldsFilled.emit(requiredFieldsFilled);
  }

  isNotNullAndNotEmpty(value: any): boolean {
    return value !== null && value.length > 0;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
