import { Component } from '@angular/core';
import {
  tabContainerOptions,
  tabOptions1,
  tabOptions2,
} from '../opportunity-summary-settings';
import { SubmissionService } from '../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import {
  FeeType,
  OpportunityDetailsEdit,
} from '../../../common/models/opportunityDetailsEdit';
import { MultiSelectItem } from '@usitsdasdesign/dds-ng/multi-select';
import { DatePipe } from '@angular/common';
import { IntakeConstant } from '../../constants/intake-constant';
import { TabOptions, TabsOptions } from '@usitsdasdesign/dds-ng/tabs';
import {
  groupToolTipOptions,
  opportunityComplexityOptionsOriginal,
  productAndToolsOptionsOriginal,
  sensitiveDataOptionsOriginal,
} from '../../opportunity-details/complexity-questions/complexity-questions-setting';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ServiceDeliveryComponent } from '../../opportunity-details/oportunity-detail-right-panel/service-delivery/service-delivery.component';
import { param } from 'jquery';
@Component({
  selector: 'app-opportunity-summary',
  templateUrl: './opportunity-summary.component.html',
  styleUrl: './opportunity-summary.component.less',
  providers: [DatePipe],
})
export class OpportunitySummaryComponent {
  opportunityDetailTitle: string | null = '';
  opportunityNumber: string | null = '';
  monthDuration: number = 0;
  tabContainerOptions: TabsOptions = tabContainerOptions;
  tabOptions1: TabOptions = tabOptions1;
  tabOptions2: TabOptions = tabOptions2;
  unsubscriber$ = new Subject();
  oportunityDetailsInfo: OpportunityDetailsEdit = {
    opportunityNumber: '',
    projectStartDate: '',
    projectEndDate: '',
    leadEngagementPartner: '',
    totalFees: '',
    feeType: [],
    clientNumber: '',
    opportunityDescription: '',
  };
  startDate: string | null = '';
  endDate: string | null = '';
  feeTypeList: MultiSelectItem[] = [];
  feeTypeLabel: string = IntakeConstant.FEE_TYPE_LABEL;
  defaultForEmptyFields: string = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
  durationLabel: string = IntakeConstant.DURATION_LABEL;
  businessLabel: string = IntakeConstant.CROSS_BUSINESS_LABEL;
  descriptionLabel: string = IntakeConstant.DESCRIPTION_LABEL;
  archiveLabel: string = IntakeConstant.ARCHIVE_LABEL;
  documentSubmissionLabel: string = IntakeConstant.DOCUMENT_SUBMISSION_LABEL;
  independenceLabel: string = IntakeConstant.INDEPENDENCE_LABEL;
  teamLabel: string = IntakeConstant.TEAM_LABEL;
  clientLabel: string = IntakeConstant.CLIENT_LABEL;
  dateFormat: string = IntakeConstant.DATE_FORMAT;
  clientNumber: string = '';

  opportunityComplexityLabel: string =
    IntakeConstant.OPPORTUNITY_COMPLEXITY_LABEL;
  productAndToolsLabel: string = IntakeConstant.PRODUCTS_AND_TOOLS_LABEL;
  thirdPartyLabel: string = IntakeConstant.THIRD_PARTY_LABEL;
  sensitiveDataLabel: string = IntakeConstant.SENSITIVE_DATA_LABEL;
  dataInDEloitteLabel: string = IntakeConstant.DATA_INDELOITTE_LABEL;
  memberFirmInvolvementLabel: string =
    IntakeConstant.MEMBER_FIRM_INVOLVEMENT_LABEL;
  useOfExistingLabel: string = IntakeConstant.USE_OF_EXISTING_LABEL;
  deloitteSowLabel: string = IntakeConstant.DELOITTE_SOW_LABEL;
  opportunityComplexityOptionsOriginal = opportunityComplexityOptionsOriginal;
  regulatoryConsiderationTooltip =
    'Identify regulatory considerations such as HIPAA, GDPR, ADA, Export Controls';
  groupToolTipOptions = groupToolTipOptions;
  viewAndEditLabel: string = IntakeConstant.VIEW_EDIT_LABEL;
  productAndToolsOptionsOriginal = productAndToolsOptionsOriginal;
  selectedProductAndTools: string = '';
  selectedOpportunityComplexity: string = '';
  sensitiveDataOptionsOriginal = sensitiveDataOptionsOriginal;
  selectedSensitiveData: string = '';
  thirdPartyInvolvement: string = '';
  memberFirmInvolvement: string = '';
  dataInDeloittesOrDeloittesHostedEnvironment: string = '';
  useOfExistingMSAsOrContractMakerGBTs: string = '';
  deloitteStandardELSOWTemplateReferred: string = '';
  opportunityDescription: string = '';
  memberFirmTooltip: string = '';
  existingMSAsOrContractMakerGBTsTooltip: string = '';
  regulatoryText: string = IntakeConstant.REGULATORY_CONSIDERATION;
  NO: string = 'No'
  YES: string = 'Yes'
  
  constructor(
    private readonly datePipe: DatePipe,
    private readonly intakeDetailService: SubmissionService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly modalService: ModalService
  ) {}
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.opportunityNumber = params['id'];
      this.opportunityDetailTitle = params['title'];
    });
    if (this.opportunityNumber)
      this.getOpportunityDetailsValues(this.opportunityNumber);
    this.getComplexityData();
  }
  getComplexityData(): void {
    this.intakeDetailService
      .getComplexityResponse(this.opportunityNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        if (data) {
          this.selectedOpportunityComplexity = data?.opportunityComplexity
            ? data?.opportunityComplexity
            : '';
          this.selectedProductAndTools = data?.productsAndTools
            ? data?.productsAndTools
            : '';
          this.selectedSensitiveData = data?.sensitiveData
            ? data?.sensitiveData
            : '';
          this.thirdPartyInvolvement = data?.thirdPartyInvolvement
            ? data?.thirdPartyInvolvement
            : '';
          this.memberFirmInvolvement = data?.memberFirmInvolvement
            ? data?.memberFirmInvolvement
            : '';
          this.dataInDeloittesOrDeloittesHostedEnvironment =
            data?.dataInDeloittesOrDeloittesHostedEnvironment
              ? data?.dataInDeloittesOrDeloittesHostedEnvironment
              : '';
          this.deloitteStandardELSOWTemplateReferred =
            data?.deloitteStandardELSOWTemplateReferred
              ? data?.deloitteStandardELSOWTemplateReferred
              : '';
          this.useOfExistingMSAsOrContractMakerGBTs =
            data?.useOfExistingMSAsOrContractMakerGBTs
              ? data?.useOfExistingMSAsOrContractMakerGBTs
              : '';
          this.memberFirmTooltip = data?.memberFirmNames ?? '';

          this.regulatoryConsiderationTooltip =
            data?.regulatoryConsiderations ?? '';

          this.existingMSAsOrContractMakerGBTsTooltip =
            data?.generalBusinessTerms ?? '';
        }
      });
  }

  getOpportunityDetailsValues(id: string): void {
    this.intakeDetailService
      .getOpportunityDetailsInfo(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: OpportunityDetailsEdit) => {
          this.oportunityDetailsInfo = data;
          this.clientNumber = data.clientNumber;
          if (
            this.oportunityDetailsInfo?.feeType &&
            this.oportunityDetailsInfo?.feeType.length > 0 &&
            this.oportunityDetailsInfo?.feeType[0]?.feeTypeName
          )
            this.feeTypeList = this.oportunityDetailsInfo?.feeType.map(
              (feeType: FeeType) => ({
                label: feeType?.feeTypeName,
                value: true,
              })
            );
          this.startDate = this.oportunityDetailsInfo?.projectStartDate
            ? this.datePipe.transform(
                this.oportunityDetailsInfo?.projectStartDate,
                this.dateFormat
              )
            : '';
          this.endDate = this.oportunityDetailsInfo?.projectEndDate
            ? this.datePipe.transform(
                this.oportunityDetailsInfo?.projectEndDate,
                this.dateFormat
              )
            : '';
          this.opportunityDescription = this.oportunityDetailsInfo
            ?.opportunityDescription
            ? this.oportunityDetailsInfo?.opportunityDescription
            : IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
          this.monthDuration =
            this.endDate && this.startDate
              ? parseInt(this.endDate?.split('/')[0]) -
                parseInt(this.startDate?.split('/')[0])
              : 0;
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }

  moveBackToEdit(): void {
    this.router.navigate(['/opportunity-details', this.opportunityNumber], {});
  }

  openDocumentLinkModel(modalBtn?: any): void {
    let modalRef = this.modalService.open(ServiceDeliveryComponent, {
      documentInitiatedDetails: {
        opportunityNumber: this.opportunityNumber,
      },
    });
  }
}
