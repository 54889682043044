import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReviewerTileCode } from '../../common/models/start-page/reviewer-dashboard.model';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { MARSConstant } from '../../client/constants/mars-constant';

@Component({
  selector: 'app-tiles-group',
  templateUrl: './tiles-group.component.html',
  styleUrl: './tiles-group.component.less',
})
export class TilesGroupComponent implements OnInit {
  @Input() selectedTileCode: string = '';
  @Input() tilesCount: any;
  @Input() tileCodeDetails: any[] = [];
  @Output() tileSelectedEvent = new EventEmitter<string>();
  @Input() public dashboardType: string = '';
  public tileCountforPagination = 0;
  public enumReviewerTileCode = ReviewerTileCode;
  private securityReviewerCCDueTile: string =
    'ClientStatus.ReviewerDashboardCCDueTile';
  public isReviewerCCDueTileVisible: boolean = false;
  roles: string[] = [];
  public MARSNcasDraftCount:any=MARSConstant.MARS_Dashboard_NcasDraftCount;
  constructor(private readonly securityWebapiService: SecurityWebapiService) { }

  ngOnInit(): void {
    this.securityWebapiService.data.subscribe((rules) => {
      if (!rules.empty) {
        this.isReviewerCCDueTileVisible =
          rules[this.securityReviewerCCDueTile] &&
          rules[this.securityReviewerCCDueTile].Visible;
        if (
          !this.isReviewerCCDueTileVisible &&
          this.dashboardType == 'ReviewerDashboard'
        ) {
          this.removeTile(this.enumReviewerTileCode.CCDueNow);
        }
      }
    });
  }

  tileSelected(selectedTile: any) {
    this.selectedTileCode = selectedTile;
    this.tileSelectedEvent.emit(this.selectedTileCode);
  }
  removeTile(tileCode: string): void {
    this.tileCodeDetails = this.tileCodeDetails.filter(
      (tile) => tile.tileCode !== tileCode
    );
  }
}
