export enum CommunicationType {
  Articles = 'Articles',
  AwardsWinnerAnnouncements = 'Awards/Winner Announcements',
  Bios = 'Bios',
  BlogsSocialMediaPosts = 'Blogs/Social Media Posts',
  ConferenceAbstractsSessionOverviews = 'Conference Abstracts/Session Overviews (excluding Dbriefs)',
  ContractsWithThirdParties = 'Contracts with third parties (e.g., speaker agreements, publishing agreements. etc.)',
  Dbriefs = 'Dbriefs (abstracts, presentations)',
  EventInvitations = 'Event Invitations',
  InterviewsWithMediaPressRelease = 'Interviews with media/Press release',
  MarketingMaterials = 'Marketing Materials',
  Newsletters = 'Newsletters',
  Podcasts = 'Podcasts (Files, Scripts)',
  PresentationsVideos = 'Presentations Videos (Files, Scripts)',
  SponsorshipMembershipAgreements = 'Sponsorship/Membership Agreements',
  Survey = 'Survey (Questions/Reports)',
  SweepstakesContestRules = 'Sweepstakes/Contest Rules',
  TalentRecruiting = 'Talent/ Recruiting',
  ThirdPartyEndorsementsTestimonials = 'Third-Party endorsements/Testimonials',
  ThoughtLeadershipWhitepapers = 'Thought leadership, Whitepapers',
  Videos = 'Videos(Files, Scripts)',
}

export const COMMUNICATION_TYPE_VALUE_MAPPER = new Map<
  CommunicationType,
  number
>([
  [CommunicationType.Articles, 1],
  [CommunicationType.AwardsWinnerAnnouncements, 2],
  [CommunicationType.Bios, 3],
  [CommunicationType.BlogsSocialMediaPosts, 4],
  [CommunicationType.ConferenceAbstractsSessionOverviews, 5],
  [CommunicationType.ContractsWithThirdParties, 6],
  [CommunicationType.Dbriefs, 7],
  [CommunicationType.EventInvitations, 8],
  [CommunicationType.InterviewsWithMediaPressRelease, 9],
  [CommunicationType.MarketingMaterials, 10],
  [CommunicationType.Newsletters, 11],
  [CommunicationType.Podcasts, 12],
  [CommunicationType.PresentationsVideos, 13],
  [CommunicationType.SponsorshipMembershipAgreements, 14],
  [CommunicationType.Survey, 15],
  [CommunicationType.SweepstakesContestRules, 16],
  [CommunicationType.TalentRecruiting, 17],
  [CommunicationType.ThirdPartyEndorsementsTestimonials, 18],
  [CommunicationType.ThoughtLeadershipWhitepapers, 19],
  [CommunicationType.Videos, 20],
]);
