<div>
  <table class="dds-data-table">
    <thead class="dds-data-table__header">
      <tr>
        <th *ngFor="let column of sampleColumns">
          <div
            *ngIf="column.name !== 'billableExpenses'"
            [attr.aria-sort]="
              sortingState?.property === column.name
                ? sortingState?.descending
                  ? 'descending'
                  : 'ascending'
                : 'none'
            "
            class="dds-data-table__header-cell dds-data-table__header-cell_sorting"
            [ngClass]="{
              'dds-data-table__header-cell_sorted':
                sortingState?.property === column.name,
              'dds-data-table__header-cell_filter-shown':
                isShownFilter && column.name === filteringColumn.name
            }"
            [ddsSticker]="filter"
            [stickerPosition]="filterStickerOptions.stickerPosition ?? ''"
            [stickerIsOutsideClick]="
              filterStickerOptions.stickerIsOutsideClick ?? false
            "
            [stickerIndent]="filterStickerOptions.stickerIndent ?? 0"
            [stickerWidth]="filterStickerOptions.stickerWidth ?? 0"
            [attr.aria-expanded]="isShownFilter"
            aria-haspopup="listbox"
            (click)="toggleFilter(column)"
            (onHidden)="filterOnHidden()"
            #stickerDir="dds-sticker"
            [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
          >
            <div class="dds-data-table__content">
              <span>{{ column.header || column.name }}</span>
              <span
                *ngIf="sortingState?.property === column.name"
                class="dds-icon dds-data-table__icon"
                aria-label="sorting button"
                [ngClass]="
                  sortingState?.descending
                    ? 'dds-icon_sort-down'
                    : 'dds-icon_sort-up'
                "
              >
              </span>
              <span
                class="dds-icon dds-icon_chevron_down__l__stroke dds-data-table__icon"
              ></span>
            </div>
          </div>
          <div
            *ngIf="column.name === 'billableExpenses'"
            class="dds-data-table__header-cell dds-data-table__header-cell_sorting"
            [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
          >
            <div class="dds-data-table__content">
              <span>{{ column.header || column.name }}</span>
            </div>
          </div>
        </th>
      </tr>
    </thead>

    <tbody *ngIf="sortingState !== null" class="dds-data-table__body">
      <ng-container *ngFor="let row of tableData | sort : sortingState">
        <tr class="dds-data-table__row">
          <td *ngFor="let column of sampleColumns" class="dds-data-table__cell">
            {{ row[column.name] }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <ng-template #filter>
    <dds-filter
      [items]="filterData"
      propName="name"
      (sorted)="filterSorted($event)"
      (apply)="filterApply(filteringColumn.searchValue)"
      (cancel)="filterHide()"
      [template]="customFilterTemp"
      #ddsFilter
    ></dds-filter>
  </ng-template>
  <ng-template #customFilterTemp let-ctx="ctx">
    <span class="dds-filter__title dds-filter__section">Sorting</span>
    <div class="dds-filter__container dds-filter__container_sorting">
      <button
        class="dds-filter__item dds-filter__section dds-filter__section_sorting"
        (click)="filterSorted('ascending')"
      >
        <span class="dds-filter__icon dds-icon dds-icon_sort-up"></span>
        <span class="dds-filter__text">Sort A–Z</span>
      </button>
      <button
        class="dds-filter__item dds-filter__section dds-filter__section_sorting"
        (click)="filterSorted('descending')"
      >
        <span class="dds-filter__icon dds-icon dds-icon_sort-down"></span>
        <span class="dds-filter__text">Sort Z–A</span>
      </button>
    </div>

    <span class="dds-filter__title dds-filter__section">Filter</span>
    <dds-search
      *ngIf="
        filteringColumn.dataType !== 'date' &&
        filteringColumn.dataType !== 'number' &&
        filteringColumn.name !== 'continuanceStatus'
      "
      class="dds-filter__search dds-filter__section"
      [isInverse]="ctx.isInverse"
      (keydown)="ctx.tabOut(searchField, $event)"
      [(ngModel)]="filteringColumn.searchValue"
      #searchField
    ></dds-search>

    <dds-daterangepicker
      class="dds-filter__section"
      *ngIf="filteringColumn.dataType === 'date'"
      [size]="dateRangePickerOptions.size ?? ''"
      [placeholder]="dateRangePickerOptions.placeholder ?? []"
      [format]="dateRangePickerOptions.format ?? ''"
      [isManualInput]="dateRangePickerOptions.isManualInput ?? false"
      [stickerPosition]="dateRangePickerOptions.stickerPosition ?? ''"
      [(ngModel)]="filteringColumn.searchValue"
    ></dds-daterangepicker>

    <div class="dds-filter__footer dds-filter__section">
      <dds-button
        width="full"
        [kind]="ctx.cancelBtnOptions.kind"
        [theme]="ctx.theme"
        [isInverse]="ctx.isInverse"
        class="dds-filter__btn dds-filter__item"
        (clicked)="filterHide()"
        #cancelBtn
        >Cancel</dds-button
      >
      <dds-button
        width="full"
        [theme]="ctx.theme"
        [isInverse]="ctx.isInverse"
        class="dds-filter__btn dds-filter__item"
        (clicked)="filterApply(filteringColumn.searchValue)"
        (keydown)="ctx.tabOut(lastBtn, $event)"
        [disabled]="!filteringColumn.filterable && !filteringColumn.searchValue"
        #lastBtn
        >Apply</dds-button
      >
    </div>
  </ng-template>
</div>
<app-new-pagination-and-grid
  *ngIf="sampleData && sampleData.length > 0"
  [tileCountforPage]="this.sampleData.length"
  [itemsPerPage]="itemsPerPage"
  [list]="itemsPerPageList"
  [pageUI]="1"
  (pageChanged)="pageChanged($event)"
  (valueChanged)="itemsPerPageChanged($event)"
></app-new-pagination-and-grid>
