import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-opportunity-not-found',
  templateUrl: './opportunity-not-found.component.html',
  styleUrl: './opportunity-not-found.component.less'
})
export class OpportunityNotFoundComponent {
  constructor(    private readonly router: Router,
  ) {}

  returnToInitiation(): void {
    this.router.navigate(['/submission']);
  }
}
