import {
  MultiSelectItem,
  MultiSelectOptions,
  SelectControlTypes,
  SelectType,
} from '@usitsdasdesign/dds-ng/multi-select';
import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import {
  SelectItemOptions,
  SelectOptions,
} from '@usitsdasdesign/dds-ng/select';
import { ErrorState, Themes } from '@usitsdasdesign/dds-ng/shared';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { IntakeConstant } from '../../constants/intake.constant';

export const gbtOptions: SelectItemOptions[] = [
  {
    heading: 'Modified GBTs',
    value: 'Modified GBTs',
  },
  {
    heading: 'GBTs previously negotiated',
    value: 'GBTs previously negotiated',
  },
  {
    heading: 'Client terms and conditions',
    value: 'Client Contract',
  },
];

export const opportunityComplexityOptionsOriginal: MultiSelectItem[] = [
  {
    label: 'New/emerging service or technology - limited prior experience',
  },
  {
    label: 'Holdbacks, penalties, investment/credit, complex or at-risk fee arrangements',
  },
  {
    label: 'Cloud Managed Services (CMS) – Deloitte hosted, client or client’s vendor hosted',
  },
  {
    label: 'Multi-year deal',
  },
  {
    label: 'SOW includes legal terms (e.g., Warranty, Indemnity, Liability)',
  },
  {
    label: 'Involves use or purchase of 3rd party data',
  },
  {
    label: 'Regulatory considerations',
  },
  {
    label: 'Gen AI',
  },
  {
    label:
      'Nature of opportunity is solely for loaned staff/staff augmentation services',
  },
  {
    label: 'None',
  },
];

export const productAndToolsOptionsOriginal: MultiSelectItem[] = [
  {
    label: 'Deloitte products or solutions in client environments',
  },
  {
    label: 'Client will access a Deloitte product or solution',
  },
  {
    label: 'Use/implementation of third-party tools',
  },
  {
    label: 'Use of GEN AI productivity tools',
  },
  {
    label: 'Reselling third-party technologies or products',
  },
  {
    label:
      'Access to EPIC software or Intellectual Property (e.g. Epic training materials, screenshots, reports, data structures)',
  },
  {
    label: 'None',
  },
];

export const sensitiveDataOptionsOriginal: MultiSelectItem[] = [
  {
    label: 'Personal data /PII /Payment Card Information (PCI)/Protected Health Information (PHI)',
  },
  {
    label: 'ITAR/Export Controlled Data highly sensitive CI',
  },
  {
    label: 'Other sensitive data (financial, trade secret, new product)',
  },
  {
    label: 'None',
  },
];

export const multiSelectOptions: MultiSelectOptions = {
  label: '',
  size: IntakeConstant.UI_ELEMENT_SIZE,
  description: '',
  placeholder: 'Select',
  type: SelectType.counter,
  controlType: SelectControlTypes.checkbox,
  theme: 'green',
  isInverse: false,
  disabled: false,
  isResponsive: false,
  isError: false,
  isRequired: false,
  displayTickAllBtn: true,
  errorMessage: '',
  errorState: ErrorState.default,
  customClass: '',
  stickerWidth: 0,
  stickerShift: 0,
  stickerMaxHeight: '',
  stickerIsDisabled: false,
  stickerPosition: 'bottom-left',
  stickerIndent: 0,
  stickerCustomClass: '',
  counterTypeText: '',
};

export const selectOptions: SelectOptions = {
  description: '',
  placeholder: 'Select',
  size: IntakeConstant.UI_ELEMENT_SIZE,
  disabled: false,
  isResponsive: false,
  isRequired: false,
  isInverse: false,
  isError: false,
  errorMessage: '',
  errorState: ErrorState.default,
  customClass: '',
  stickerWidth: 0,
  stickerShift: 0,
  stickerMaxHeight: '',
  stickerIsDisabled: false,
  stickerPosition: 'bottom-left',
  stickerIndent: 0,
  stickerCustomClass: '',
  stickerIsDynamic: true,
};

export const options: TagOptions = {
  theme: Themes.dark,
  isRemovable: true,
  disabled: false,
  isError: false,
  isInverse: false,
  size: IntakeConstant.UI_ELEMENT_SIZE,
  customClass: '',
};

// Tooltip Options
export const groupToolTipOptions: TooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'top',
  tooltipIndent: 15,
  tooltipHasBeak: true,
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipTheme: Themes.dark,
  tooltipMaxWidth: 300,
};

// Radio Group Options
export const radioGroupOptions: RadioGroupOptions = {
  name: 'group1',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  isRequired: false,
  isInverse: false,
};

export const textareaOptions: TextareaOptions = {
  placeholder: 'Enter regulatory considerations',
  minHeight: 100,
  maxLength: 4000,
  size: IntakeConstant.UI_ELEMENT_SIZE,
  maxLengthPosition: 'bottom-right',
};

export const deloitteStandardELSOWTemplateReferredGroupOptions: RadioGroupOptions =
  {
    name: 'deloitteStandardELSOWTemplateReferred',
  };

export const useOfExistingMSAsOrContractMakerGBTsGroupOptions: RadioGroupOptions =
  {
    name: 'useOfExistingMSAsOrContractMakerGBTs',
  };

export const generalBusinessTermsGroupOptions: RadioGroupOptions = {
  name: 'generalBusinessTerms',
};

export const deloitteIsraelGroupOptions: RadioGroupOptions = {
  name: 'deloitteIsrael',
};

export const servicesDisruptClientGroupOptions: RadioGroupOptions = {
  name: 'servicesDisruptClient',
};

export const hasERS230GroupOptions: RadioGroupOptions = {
  name: 'hasERS230',
};

export const infraSecurityconsultGroupOptions: RadioGroupOptions = {
  name: 'infraSecurityconsult',
};

export const radioBtnsOriginal: RadioButton[] = [
  {
    options: {
      label: 'Yes',
      theme: Themes.green,
    },
    value: 'Yes',
  },
  {
    options: {
      label: 'No',
      theme: Themes.green,
    },
    value: 'No',
  },
];

export const radioGroupOptionsForUSmemberFirmRole: RadioGroupOptions = {
  name: 'group2',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  isRequired: false,
  isInverse: false,
};
export const radioBtnsForSubContractor: RadioButton[] = [
  {
    options: {
      label: 'US Member Firm is Prime (i.e., referring MF)',
      theme: Themes.green,
    },
    value: 'US Member Firm is Prime (i.e., referring MF)',
  },
  {
    options: {
      label: 'US Member Firm is Subcontractor (i.e., participating MF)',
      theme: Themes.green,
    },
    value: 'US Member Firm is Subcontractor (i.e., participating MF)',
  },
];

export const serviceInvolvementOptions: MultiSelectItem[] = [
  {
    label:
      'Providing application services that require monitoring of applications or services (excluding security events)',
  },
  {
    label:
      'Providing services that require real-time monitoring of transactions or events at facilities such as Security Operations Centers (SOC)',
  },
  {
    label:
      'This is an implementation of a newer technology/tool/module (e.g., less than two years experience with the technology)',
  },
  {
    label:
      'This is a custom development engagement (e.g., Deloitte is developing software or writing code)',
  },
  {
    label:
      '[A+C] does not have an established relationship with the technology provider (e.g., MBR, dedicated account team)',
  },
  {
    label: 'None',
  },
];

export const radioBtns: RadioButton[] = [
  {
    options: {
      label: 'Yes',
      theme: Themes.green,
    },
    value: 'Yes',
  },
  {
    options: {
      label: 'No',
      theme: Themes.green,
    },
    value: 'No',
  },
];

export const deloitteIsraelBtns: RadioButton[] = [
  {
    options: {
      label: 'Onshore/Offshore',
      theme: Themes.green,
    },
    value: 'Onshore/Offshore',
  },
  {
    options: {
      label: 'None - not permitted to use offsite resources',
      theme: Themes.green,
    },
    value: 'None - not permitted to use offsite resources',
  },
  {
    options: {
      label: 'None - not permitted to use non-US personnel',
      theme: Themes.green,
    },
    value: 'None - not permitted to use non-US personnel',
  },
  {
    options: {
      label: 'None (Explain)',
      theme: Themes.green,
    },
    value: 'None (Explain)',
  },
];
