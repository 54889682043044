import { Submission } from './../common/models/common-section.model';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  ButtonKind,
  ExtThemes,
  Size,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { GridColumn } from '../http/watchlist/watchlist-api.service';
import { DropdownOptions } from '@usitsdasdesign/dds-ng/dropdown';
import {
  DashboardGridSortParameter,
  TabWithCounterType,
} from '../common/models/start-page/start-page.model';
import { ToastOptions } from '@usitsdasdesign/dds-ng/toast';
import { EngagementTileCode } from '../intake/constants/dashboard-tiles.enum';
import { ReviewerTileCode } from '../common/models/start-page/reviewer-dashboard.model';

export class DashboardConstant {
  public static readonly NCA_SPECIALIST: string = 'NCA specialist';
  public static readonly REVIEWER: string = 'Reviews';
  public static readonly ENGAGEMENT_TEAM: string = 'Submissions';
  public static readonly RISK_MANAGER: string = 'Risk manager oversight';
  public static readonly TEXTAREA_CHAR_LIMIT_BIGGEST: number = 5000;
}

// Define the initial sort column number for each tile
export const reviewerSortParamaterCode: {
  [key: string]: number | null;
} = {
  [ReviewerTileCode.PendingReviews]: null,
  [ReviewerTileCode.PendingReviewsOthers]: null,
  [ReviewerTileCode.NCAPendingApproval]:
    DashboardGridSortParameter.submittedDate,
  [ReviewerTileCode.CCDueNow]:
    DashboardGridSortParameter.continuanceExpiredDate,
  [ReviewerTileCode.RecentReviews]: null,
  [ReviewerTileCode.SelfReviewChanges]: null,
  [ReviewerTileCode.ExtComm]: null,
  [ReviewerTileCode.GlobalEng]: null,
};

export const engagementSortParamaterCode: {
  [key: string]: number | null;
} = {
  [EngagementTileCode.Initiated]: null,
  [EngagementTileCode.Submitted]: null,
  [EngagementTileCode.Reviewed]: null,
  [EngagementTileCode.Completed]: null,
  [EngagementTileCode.ExtComm]: null,
  [EngagementTileCode.GlobalEng]: null,
};

export const riskManagerSortParamaterCode: {
  [key: string]: number | null;
} = {
  InProcess: null,
  Completed: null,
  Discontinued: null,
  ExternalCommunications: null,
};

export const ncaSortParamaterCode: number = 1;

export const buttonOptions: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.silent,
  size: Size.md,
  width: WidthState.fixed,
  isLoading: false,
  icon: 'dds-icon_arrow-right',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Show recently viewed submissions',
  customClass: '',
  role: 'button',
};

export const dropdownOptions: DropdownOptions = {
  label: 'Initiate new',
  ariaLabel: '',
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  size: Size.lg,
  width: WidthState.fixed,
  stickerWidth: 'fit-content',
  stickerPosition: 'bottom-left',
};

export enum GridColumns {
  ClientName = 'clientName',
  ParentClientName = 'parentClientName',
  DescDesignation = 'descDesignation',
  ActionByName = 'actionByName',
  NCASpecialistName = 'ncaSpecialistName',
  Industry = 'industry',
  DaysPendingForCCCompletion = 'daysPendingForCCCompletion',
  ContinuanceExpiredDate = 'continuanceExpiredDate',
  ApproverName = 'approverName',
  PriorFyClosedWBS = 'priorFyClosedWBS',
  NetServiceRevenue = 'netServiceRevenue',
  RecommendedClientGroupNumber = 'recommendedClientGroupNumber',
  OpportunityExpectedCloseDate = 'opportunityExpectedCloseDate',
  NCATriggeredDate = 'ncaTriggeredDate',
  SubmissionTitle = 'submissionTitle',
  Focus = 'focus',
  JupiterID = 'jupiterId',
  LEP = 'lep',
  SubmittedDate = 'submittedDate',
  Actions = 'actions',
  PendingReviewer = 'pendingReviewer',
  LastStatus = 'lastStatus',
  DateOfLastStatusChange = 'dateOfLastStatusChange',
  Status = 'status',
  SubmissionStatusName = 'submissionStatusName',
  PendingRequiredReviewers = 'pendingRequiredReviewer',
  PendingOptionalReviewers = 'pendingOptionalReviewer',
  SelfReviewEligibilityChangeDate = 'selfReviewEligibilityChangeDate',
  OpenOpportunities = 'openOpportunities',
  RequestReviewDate = 'requestedReviewDate',
  OpenWBS = 'openWBS',
  ContinuanceStatus = 'continuanceStatus',
  OpportunityIcon = 'opportunityIcon',
  PrimaryRiskManager = 'primaryRiskManager',
  Reviewer = 'reviewer',
  Business = 'business',
  CompletedDate = 'completedSubmissionDate',
  JupiterStage = 'jupiterStage',
  Offering = 'offering',
  StatusDate = 'statusDate',
  RiskManager = 'riskManager',
  RMSupport = 'rmSupport',
  OpportunityName = 'opportunityName',
  CompleteReason = 'completeReason',
  SubmissionId = 'submissionId',
  SubmissionIdEC = 'submissionIdEC',
  TypeOfCommunication = 'typeOfCommunication',
  ResponsiblePractitioner = 'responsiblePractitioner',
  SubmissionTypeCode = 'submissionTypeCode',
  SubmissionType = 'submissionType',
  SubmissionStatus = 'submissionStatus',
  WBS = 'wbs',
  ResponsibleDeliveryCenterPPMD = 'responsibleDeliveryCenterPPMD',
  MemberFirm = 'memberFirm',
  DeliveryCenter = 'deliveryCenter',
  GlobalEngagementStatus = 'globalEngagementStatus',
}

export const defaultGridColumns: GridColumn[] = [
  {
    name: GridColumns.ClientName,
    header: 'Client',
    dataType: 'string',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.OpportunityName,
    header: 'Opportunity Name',
    dataType: 'string',
    minWidth: '10rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.JupiterStage,
    header: 'Jupiter Stage',
    dataType: 'string',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Offering,
    header: 'Offering',
    dataType: 'string',
    minWidth: '6rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.ParentClientName,
    header: 'Parent client',
    dataType: 'string',
    minWidth: '10rem',
    width: '11rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.DescDesignation,
    header: 'DESC designation (Services)',
    dataType: 'string',
    minWidth: '8.75rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.ActionByName,
    header: 'Action by',
    dataType: 'string',
    width: '11.1%',
    minWidth: '7rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.NCASpecialistName,
    header: 'NCA specialist',
    dataType: 'string',
    minWidth: '7rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Industry,
    header: 'Industry',
    dataType: 'string',
    minWidth: '5rem',
    width: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.DaysPendingForCCCompletion,
    header: 'Days pending for CC completion',
    dataType: 'number',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.ContinuanceExpiredDate,
    header: 'Expiration date',
    dataType: 'date',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.ApproverName,
    header: 'LBP / SL name',
    dataType: 'string',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.PriorFyClosedWBS,
    header: 'Closed WBSs (prior 13 periods)',
    dataType: 'number',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.NetServiceRevenue,
    header: 'Net services revenue',
    dataType: 'number',
    minWidth: '8.75rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.RecommendedClientGroupNumber,
    header: 'Recommended group',
    dataType: 'string',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.OpportunityExpectedCloseDate,
    header: 'Expected close date',
    dataType: 'date',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.NCATriggeredDate,
    header: 'NCA triggered date',
    dataType: 'date',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.SubmissionTitle,
    header: 'Submission title',
    dataType: 'string',
    minWidth: '10rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Focus,
    header: 'Focus',
    dataType: 'string',
    minWidth: '3rem',
    width: '3rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.JupiterID,
    header: 'Jupiter ID',
    dataType: 'string',
    minWidth: '7rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.LEP,
    header: 'LEP',
    dataType: 'string',
    minWidth: '6.5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.SubmittedDate,
    header: 'Date submitted',
    dataType: 'date',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Actions,
    header: 'Actions',
    dataType: '',
    minWidth: '7.5rem',
    width: '10.5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.PendingReviewer,
    header: 'Pending Reviewer',
    dataType: 'string',
    minWidth: '5rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.LastStatus,
    header: 'Last status',
    dataType: 'string',
    minWidth: '5rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.DateOfLastStatusChange,
    header: 'Date of last status change',
    dataType: 'string',
    minWidth: '5rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.StatusDate,
    header: 'Date of last status change',
    dataType: 'date',
    minWidth: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.SubmissionStatusName,
    header: 'Status',
    dataType: 'string',
    minWidth: '6rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.SubmissionStatus,
    header: 'Submission Status',
    dataType: 'string',
    minWidth: '3rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.PendingRequiredReviewers,
    header: 'Pending required reviewer(s)',
    dataType: 'string',
    minWidth: '3rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.PendingOptionalReviewers,
    header: 'Pending optional Reviewer(s)',
    dataType: 'string',
    minWidth: '3rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.SelfReviewEligibilityChangeDate,
    header: 'Date of self review eligibility change',
    dataType: 'string',
    minWidth: '3rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.OpenOpportunities,
    header: 'Open opportunities',
    dataType: 'string',
    minWidth: '8rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.RequestReviewDate,
    header: 'Requested review date',
    dataType: 'date',
    width: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.OpenWBS,
    header: 'Open WBSs',
    dataType: 'number',
    minWidth: '5rem',
    width: '5rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.ContinuanceStatus,
    header: 'Continuance status',
    dataType: 'string',
    minWidth: '10.75rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.OpportunityIcon,
    header: '',
    dataType: 'string',
    minWidth: '3rem',
    width: '3rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Business,
    header: 'Business',
    dataType: 'string',
    minWidth: '10.75rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.PrimaryRiskManager,
    header: 'Required Reviewer(s)',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.RiskManager,
    header: 'Risk Manager',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.Reviewer,
    header: 'Required Reviewer(s)',
    dataType: 'string',
    minWidth: '4.5rem',
    width: '4.5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.CompletedDate,
    header: 'Completed date',
    dataType: 'date',
    minWidth: '3rem',
    width: '5rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.CompleteReason,
    header: 'Complete Reason',
    dataType: 'string',
    minWidth: '10rem',
    filterable: false,
    searchValue: null,
  },
  {
    name: GridColumns.SubmissionId,
    header: 'Submission ID',
    dataType: 'string',
    width: '8rem',
    minWidth: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.SubmissionIdEC,
    header: 'Submission ID',
    dataType: 'string',
    width: '5rem',
    minWidth: '3rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.TypeOfCommunication,
    header: 'Type of communication',
    dataType: 'string',
    minWidth: '12rem',
    width: '12rem',
  },
  {
    name: GridColumns.ResponsiblePractitioner,
    header: 'Responsible Practitioner',
    dataType: 'string',
    width: '12rem',
    minWidth: '12rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.SubmissionTypeCode,
    header: 'Submission Type',
    dataType: 'string',
    minWidth: '3rem',
    width: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.SubmissionType,
    header: 'Submission Type',
    dataType: 'string',
    minWidth: '3rem',
    width: '8rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.RMSupport,
    header: 'RM Support',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.ResponsibleDeliveryCenterPPMD,
    header: 'Responsible Delivery Center PPMD',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.DeliveryCenter,
    header: 'Delivery Center',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.MemberFirm,
    header: 'Member Firm',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.WBS,
    header: 'WBS',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
  {
    name: GridColumns.GlobalEngagementStatus,
    header: 'Status',
    dataType: 'string',
    minWidth: '4rem',
    width: '4rem',
    filterable: false,
    searchValue: '',
  },
];

export const RecentlyReviewColumn = [
  {
    name: 'Type',
    header: 'Type',
    dataType: 'string',
    width: '1rem',
    minWidth: '',
  },
  {
    name: 'Status',
    header: 'Status',
    dataType: 'string',
    width: '17rem',
    minWidth: '17rem',
  },
  {
    name: 'Title',
    header: 'Title',
    dataType: 'title',
    width: '10rem',
    minWidth: '',
  },
];

export const PendingReviewsColumns: string[] = [
  GridColumns.SubmissionTypeCode,
  GridColumns.SubmissionTitle,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.SubmittedDate,
  GridColumns.Actions,
];

export const PendingReviewsOtherColumns: string[] = [
  GridColumns.SubmissionTypeCode,
  GridColumns.SubmissionTitle,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.PendingRequiredReviewers,
  GridColumns.PendingOptionalReviewers,
];

export const RecentReviewsColumns: string[] = [
  GridColumns.SubmissionTypeCode,
  GridColumns.SubmissionTitle,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.SubmissionStatus,
  GridColumns.SubmittedDate,
];

export const SelfReviewsColumns: string[] = [
  GridColumns.SubmissionTypeCode,
  GridColumns.SubmissionTitle,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.SubmissionStatusName,
  //For now set to submittedDate, once availabe form backend need ot change to SelfReviewEligibilityChangeDate
  GridColumns.DateOfLastStatusChange,
];

export const NCAPendingApprovalColumns: string[] = [
  GridColumns.ClientName,
  GridColumns.ParentClientName,
  GridColumns.Industry,
  GridColumns.DescDesignation,
  GridColumns.SubmittedDate,
  GridColumns.RequestReviewDate,
  GridColumns.Business,
  GridColumns.Actions,
];

export const CCOverviewColumns: string[] = [
  GridColumns.ClientName,
  GridColumns.ParentClientName,
  GridColumns.Business,
  GridColumns.ContinuanceStatus,
  GridColumns.ContinuanceExpiredDate,
  GridColumns.Industry,
  GridColumns.OpenOpportunities,
  GridColumns.PriorFyClosedWBS,
  GridColumns.OpenWBS,
  GridColumns.NetServiceRevenue,
  GridColumns.Actions,
];

export const ReviewerExtCommColumns: string[] = [
  GridColumns.SubmissionId,
  GridColumns.SubmissionStatus,
  GridColumns.SubmissionTitle,
  GridColumns.ResponsiblePractitioner,
  GridColumns.RiskManager,
  GridColumns.RMSupport,
  GridColumns.TypeOfCommunication,
  GridColumns.RequestReviewDate,
  GridColumns.Actions,
];

export const ReviewerGlobalEngColumns: string[] = [
  GridColumns.SubmissionId,
  GridColumns.SubmissionType,
  GridColumns.SubmissionTitle,
  GridColumns.GlobalEngagementStatus,
  GridColumns.DeliveryCenter,
  GridColumns.ClientName,
  GridColumns.MemberFirm,
  GridColumns.WBS,
  GridColumns.JupiterID,
  GridColumns.Offering,
  GridColumns.ResponsibleDeliveryCenterPPMD,
];

export const EngagementInitiatedColumns: string[] = [
  GridColumns.SubmissionTypeCode,
  GridColumns.SubmissionTitle,
  GridColumns.OpportunityIcon,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.Actions,
];

export const EngagementSubmittedColumns: string[] = [
  GridColumns.SubmissionTypeCode,
  GridColumns.SubmissionTitle,
  GridColumns.OpportunityIcon,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.PrimaryRiskManager,
];

export const EngagementReviewedColumns: string[] = [
  GridColumns.SubmissionTypeCode,
  GridColumns.SubmissionTitle,
  GridColumns.OpportunityIcon,
  GridColumns.JupiterID,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.Reviewer,
  GridColumns.Actions,
];

export const EngagementCompletedColumns: string[] = [
  GridColumns.SubmissionTypeCode,
  GridColumns.SubmissionTitle,
  GridColumns.OpportunityIcon,
  GridColumns.JupiterID,
  GridColumns.JupiterStage,
  GridColumns.ClientName,
  GridColumns.LEP,
  GridColumns.CompletedDate,
  GridColumns.CompleteReason,
];

export const EngagementExtCommColumns: string[] = [
  GridColumns.SubmissionId,
  GridColumns.SubmissionTitle,
  GridColumns.TypeOfCommunication,
  GridColumns.ResponsiblePractitioner,
  GridColumns.RequestReviewDate,
  GridColumns.Actions,
];

export const EngagementGlobalEngColumns: string[] = [
  GridColumns.SubmissionId,
  GridColumns.SubmissionType,
  GridColumns.SubmissionTitle,
  GridColumns.GlobalEngagementStatus,
  GridColumns.DeliveryCenter,
  GridColumns.ClientName,
  GridColumns.MemberFirm,
  GridColumns.WBS,
  GridColumns.JupiterID,
  GridColumns.Offering,
  GridColumns.ResponsibleDeliveryCenterPPMD,
];

export const RiskManagerInprocessColumns: string[] = [
  GridColumns.ClientName,
  GridColumns.OpportunityName,
  GridColumns.OpportunityIcon,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.JupiterStage,
  GridColumns.LEP,
  GridColumns.Offering,
  GridColumns.Industry,
  GridColumns.StatusDate,
  GridColumns.RiskManager,
];

export const RiskManagercompletedColumns: string[] = [
  GridColumns.ClientName,
  GridColumns.OpportunityName,
  GridColumns.OpportunityIcon,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.JupiterStage,
  GridColumns.LEP,
  GridColumns.Offering,
  GridColumns.Industry,
  GridColumns.StatusDate,
  GridColumns.RiskManager,
];

export const RiskManagerdiscontinuedColumns: string[] = [
  GridColumns.ClientName,
  GridColumns.OpportunityName,
  GridColumns.OpportunityIcon,
  GridColumns.Focus,
  GridColumns.JupiterID,
  GridColumns.JupiterStage,
  GridColumns.LEP,
  GridColumns.Offering,
  GridColumns.Industry,
  GridColumns.StatusDate,
  GridColumns.RiskManager,
];

export const RiskManagerexternalCommColumns: string[] = [
  GridColumns.SubmissionTitle,
  GridColumns.SubmissionIdEC,
  GridColumns.SubmissionStatusName,
  GridColumns.TypeOfCommunication,
  GridColumns.ResponsiblePractitioner,
  GridColumns.DateOfLastStatusChange,
  GridColumns.RiskManager,
];

export const downloadToastOptions: ToastOptions = {
  title: 'Exporting',
  lifeTime: undefined,
  position: 'bottom-center',
  closeBtnIcon: 'dds-icon_close',
  isLoading: true,
  limit: 1,
  theme: Themes.dark,
};

export const engagementExtCommTabs = {
  DRAFT: {
    tabCode: 'DraftEC',
    countCode: 'draftECCount',
  },
  REVIEWED: {
    tabCode: 'ReviewedEC',
    countCode: 'reviewedECCount',
  },
  SUBMITTED: {
    tabCode: 'SubmittedEC',
    countCode: 'submittedECCount',
  },
  COMPLETED: {
    tabCode: 'CompletedEC',
    countCode: 'completedECCount',
  },
};

export const reviewerExtCommTabs = {
  PENDING: {
    tabCode: 'PendingEC',
    countCode: 'pendingECCount',
  },
  UNASSIGNED: {
    tabCode: 'UnassignedEC',
    countCode: 'unassignedECCount',
  },
  ALLSUBMISSION: {
    tabCode: 'AllSubmissionsEC',
    countCode: 'allSubmissionsECCount',
  },
};

export const engagementExtcommTabsList: TabWithCounterType[] = [
  {
    label: 'Draft',
    tabCode: engagementExtCommTabs.DRAFT.tabCode,
    countCode: engagementExtCommTabs.DRAFT.countCode,
    count: 0,
  },
  {
    label: 'Submitted',
    tabCode: engagementExtCommTabs.SUBMITTED.tabCode,
    countCode: engagementExtCommTabs.SUBMITTED.countCode,
    count: 0,
  },
  {
    label: 'Reviewed',
    tabCode: engagementExtCommTabs.REVIEWED.tabCode,
    countCode: engagementExtCommTabs.REVIEWED.countCode,
    count: 0,
  },
  {
    label: 'Completed',
    tabCode: engagementExtCommTabs.COMPLETED.tabCode,
    countCode: engagementExtCommTabs.COMPLETED.countCode,
    count: 0,
  },
];
export const reviewerExtcommTabsList: TabWithCounterType[] = [
  {
    label: 'Pending reviews',
    tabCode: reviewerExtCommTabs.PENDING.tabCode,
    countCode: reviewerExtCommTabs.PENDING.countCode,
    count: 0,
  },
  {
    label: 'Unassigned',
    tabCode: reviewerExtCommTabs.UNASSIGNED.tabCode,
    countCode: reviewerExtCommTabs.UNASSIGNED.countCode,
    count: 0,
  },
  {
    label: 'All submissions',
    tabCode: reviewerExtCommTabs.ALLSUBMISSION.tabCode,
    countCode: reviewerExtCommTabs.ALLSUBMISSION.countCode,
    count: 0,
  },
];
