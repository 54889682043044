// this is submission status enum
export enum ExternalCommSubmissionStatus {
  DRAFT = 'Draft',
  RETURN_FOR_REWORK = 'Returned for rework',
  SUBMITTED = 'Submitted',
  RESUBMITTED = 'Re-submitted',
  RM_REVIEWED_PENDING_CLOSEOUT = 'RM reviewed - Pending closeout',
  SELF_REVIEWED_PENDING_CLOSEOUT = 'Self reviewed - Pending closeout',
  COMPLETED = 'Completed',
  SELF_REVIEWED_PENDING_PPMD_CONFIRMATION = 'Self-reviewed - Pending PPMD Confirmation',
  RM_REVIEWED_PENDING_PPMD_CONFIRMATION = 'RM reviewed - Pending PPMD Confirmation',
  DISCONTINUED = 'Discontinued',
  ADMIN_COMPLETE = 'Completed(Admin)',
}

export enum SubmissionActionId {
  Reset = 1,
  Discontinue = 2,
  AdminComplete = 3,
}
