import { Column } from '@usitsdasdesign/dds-ng/shared';

export const sampleColumns: Column[] = [
  {
    name: 'documentTitle',
    header: 'Title',
    dataType: 'string',
    minWidth: '5rem',
  },
  {
    name: 'documentType',
    header: 'Type',
    dataType: 'string',
    minWidth: '5rem',
  },
  {
    name: 'documentStatus',
    header: 'Status',
    dataType: 'string',
    minWidth: '5rem',
  },
  {
    name: 'createdDate',
    header: 'Uploaded Date',
    dataType: 'string',
    minWidth: '5rem',
  },
];
