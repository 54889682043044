import { Component, ViewChildren, QueryList, ElementRef, ChangeDetectorRef, HostListener, Renderer2 } from '@angular/core';
import { LabelPosition, Size, PositionState, ButtonKind, ExtThemes, WidthState, Themes } from '@usitsdasdesign/dds-ng/shared';
import { SelectOptions, SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { DaterangepickerOptions } from '@usitsdasdesign/dds-ng/datepicker';
import { ButtonOptions } from "@usitsdasdesign/dds-ng/button";
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service'
import { GridColumn, WatchlistApiService, WatchlistAuditLogGridFilter, WatchlistAuditLogGridSortParameterCode } from '../../http/watchlist/watchlist-api.service'
import { AuditItem, AuditLogRequest } from '../../common/models/common-models'
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import { AdobeAnalyticsService } from '../../common/services/adobe-analytics.service';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { Filter, FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { StickerDirective, StickerOptions } from '@usitsdasdesign/dds-ng/sticker';
import { EclipseHeaderService } from '../../http/eclipse-header.service';


@Component({
  selector: 'app-watchlist-audit',
  templateUrl: './watchlist-audit.component.html',
  styleUrl: './watchlist-audit.component.less'
})
export class WatchlistAuditComponent {

  public clientNumber: string = '';
  public watchlistId: number = 0;
  public clientName: string = '';
  public auditItems: AuditItem[] = [];
  private subscriptions: Subscription[] = [];
  public showMore: { [key: number]: ShowMoreState } = {};
  public shouldShowButton: { [key: number]: ShowMoreState } = {};
  public countforPagination: number = 5;
  public gotoPage: number = 0;
  public itemsPerPage: number = IntakeConstant.PAGE_NUMBER_10;
  public selectItems: SelectItemOptions[] = [];
  public isAscending: boolean = false;
  public selectedUser: any;
  public selectedDate: any;
  public isLoading: boolean = true;
  public enumFilterSortParameterCode = WatchlistAuditLogGridSortParameterCode;
  public filteringColumn!: GridColumn;
  public isShownFilter!: boolean;
  public watchlistAuditLogGridFilter: WatchlistAuditLogGridFilter[] = [];
  selectedItems: string[] = [];
  isAllFilterSelected: boolean = false;
  public sortParameterCode: number = 2;
  public filterData: FilterItem[] = [];
  isClearFilter: boolean = false;

  @ViewChildren('fromCommentText') fromCommentText!: QueryList<ElementRef>;
  @ViewChildren('toCommentText') toCommentText!: QueryList<ElementRef>;
  @ViewChildren('stickerDir') sticker: QueryList<StickerDirective> | undefined;

  filterOptions: Filter = {
    theme: Themes.green,
    isInverse: false,
    propName: 'name',
    itemPropNameValue: 'value'
  };

  clearButtonoptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.md,
    width: WidthState.fixed,
    disabled: false
  }

  public filterStickerOptions: StickerOptions = {
    stickerPosition: PositionState.bottomLeft,
    stickerIsOutsideClick: true,
    stickerIndent: -1,
    stickerWidth: 380,
  };

  pageHeaderVisible: boolean = false;

  constructor(private watchlistApiService: WatchlistApiService,
    private progressIndicator: ProgressIndicatorService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private analytics: AdobeAnalyticsService,
    private router: Router,
    private readonly eclipseHeaderService: EclipseHeaderService) { }

  ngOnInit(): void {

    document.title = BrowserTabConstant.Browser_TabName_AuditTrail;
    this.analytics.trackPage("Watchlist audit")
    this.route.params.subscribe((params: Params) => {
      this.watchlistId = params['watchlistId'];
    })

    this.eclipseHeaderService.showHeader$.subscribe((showHeader: boolean) => {
      this.pageHeaderVisible = showHeader;
    });

    this.loadAuditLog();

    this.subscriptions.push(
      this.watchlistApiService.getWatchlistAuditlogUsers(this.watchlistId)
        .subscribe((data: any) => {
          this.selectItems = data.map((item: any) => ({
            heading: item.displayName,
            value: item.employeeID
          }));

          // Push the "All users" item with heading and value as specified
          this.selectItems.push({ heading: 'All users', value: null });

          setTimeout(() => {
            let navigation = window.history.state;
            if (navigation && navigation.clientNumber) {
              this.clientNumber = navigation.clientNumber;
            }
            if (navigation && navigation.clientName) {
              this.clientName = navigation.clientName;
            }
          }, 500);
        })
    );
  }


  ngAfterViewChecked() {

    this.checkTextOverflow();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  checkTextOverflow(): void {
    this.auditItems.forEach((item, index) => {
      const fromElement = this.fromCommentText.toArray().find(el => el.nativeElement.id == index)?.nativeElement;
      const toElement = this.toCommentText.toArray().find(el => el.nativeElement.id == index)?.nativeElement;

      if (!this.shouldShowButton[index]) {
        this.shouldShowButton[index] = { from: false, to: false };
      }

      if (fromElement) {
        this.shouldShowButton[index].from = this.isTextOverflowing(fromElement);
      } else {
        this.shouldShowButton[index].from = false;
      }

      if (toElement) {
        this.shouldShowButton[index].to = this.isTextOverflowing(toElement);
      } else {
        this.shouldShowButton[index].to = false;
      }
    });
    this.cdr.detectChanges();
  }

  isTextOverflowing(element: HTMLElement): boolean {

    return element.scrollHeight > element.clientHeight;
  }

  toggleShowMore(index: number, type: 'from' | 'to'): void {
    if (!this.showMore[index]) {
      this.showMore[index] = { from: false, to: false };
    }
    this.showMore[index][type] = !this.showMore[index][type];

    this.options.icon = this.showMore[index]['from'] ? 'dds-icon_arrow-up' : 'dds-icon_arrow-down';
    this.tooptions.icon = this.showMore[index]['to'] ? 'dds-icon_arrow-up' : 'dds-icon_arrow-down';
    this.cdr.detectChanges();
  }

  public toggleFilter(column: GridColumn) {
    this.filteringColumn = column;
    this.isShownFilter = true;
  }

  public filterOnHidden(): void {
    this.isShownFilter = false;
  }

  loadAuditLog(): void {

    this.isLoading = true;

    const auditLogRequest: AuditLogRequest = {
      sortParameterCode: this.sortParameterCode,
      pageN: this.gotoPage,
      pageSize: this.itemsPerPage,
      isAscending: this.isAscending,
      filterdata: this.watchlistAuditLogGridFilter
    };

    this.progressIndicator.show();

    this.subscriptions.push(
      this.watchlistApiService.getWatchlistAuditLog(this.watchlistId, auditLogRequest)
        .subscribe((data: any) => {
          this.auditItems = data.auditItems;
          this.countforPagination = data.totalDataCount;
          this.isLoading = false;
          this.progressIndicator.hide();
        })
    );
  }

  onPageChanged(value: number) {
    this.gotoPage = value - 1;
    this.loadAuditLog();
  }

  onValueChanged(item: number) {
    this.itemsPerPage = item;
    this.gotoPage = 0;
    this.loadAuditLog();
  }

  public gridColumns: GridColumn[] = [
    { name: 'name', header: 'Name', dataType: 'string', width: '6rem', filterable: true },
    { name: 'logDate', header: 'Log date/Time', dataType: 'date', minWidth: '12rem',  sortable: true },
    { name: 'Description', header: 'Description', dataType: 'string', minWidth: '50rem' , sortable:false},
  ];

  public sortingState = {
    property: this.gridColumns[1].name,
    ascending: false,
    dataType: this.gridColumns[1].dataType,
  };

  public filterDataList: { name: string, data: FilterItem[] }[] = [];

  filterSorted(isAscending: boolean) {
    this.isAscending = isAscending;
    this.sortingState.ascending = this.isAscending;

    const parameterCode = this.enumFilterSortParameterCode[this.filteringColumn.name as keyof typeof WatchlistAuditLogGridSortParameterCode];
    this.sortParameterCode = parameterCode;

    this.sortingState = {
      property: this.filteringColumn.name,
      ascending: this.isAscending,
      dataType: this.filteringColumn.dataType,
    };

    this.filterHide();

    this.loadAuditLog();
  }

  applyfilter() {
    this.loadAuditLog();
  }

  public filterHide(): void {
    this.sticker?.forEach((item) => {
      if (item.isActive) {
        item.hide();
      }
    });
  }

  backToList() {
    this.router.navigate(['/watchlist']);
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  selectOptions: SelectOptions = {
    label: 'Label',
    description: '',
    placeholder: 'All users',
    size: Size.lg,
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: "",
    stickerIsDisabled: false,
    stickerPosition: "bottom-left"
  };

  dateRangePickerOptions: DaterangepickerOptions = {
    label: 'Date Range Picker',
    labelPosition: LabelPosition.external,
    size: Size.lg,
    placeholder: ['MM/DD/YYYY', 'MM/DD/YYYY'],
    description: '',
    format: 'MM/dd/yyyy',
    stickerPosition: PositionState.bottomLeft,
    stickerIndent: 0,
    stickerShift: 0,
    stickerMaxHeight: ''
  };

  buttonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: WidthState.fixed,
  }

  options: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.sm,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_arrow-down',
    role: 'button'
  }

  tooptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.sm,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_arrow-down',
    role: 'button'
  }

  public filterApply(searchValue: string | [Date, Date]) {
    const parameterCode = this.enumFilterSortParameterCode[this.filteringColumn.name as keyof typeof WatchlistAuditLogGridSortParameterCode];
    this.sortParameterCode = parameterCode;
    let filterCriteria: string;

    if (Array.isArray(searchValue)) {
      const [fromDate, toDate] = searchValue;
      const fromDateString = fromDate.toLocaleDateString('en-US');
      const toDateString = toDate.toLocaleDateString('en-US');
      filterCriteria = `${fromDateString},${toDateString}`;
    } else {
      filterCriteria = searchValue;
    }

    // Remove previous filter for the same column if it exists
    this.watchlistAuditLogGridFilter = this.watchlistAuditLogGridFilter.filter(
      (filter) => filter.filtertypecode !== parameterCode
    );

    this.filteringColumn.filterable = true;
    if (
      this.filteringColumn.name === 'name'
    ) {
      this.sortParameterCode = 2; //Applied sorting on Log Date after applying filter on name
      const values = (typeof searchValue === 'string' ? searchValue : '').split(
        ','
      );
      const newFilters = values.map((value) => ({
        filtertypecode: parameterCode,
        filtercriteria: value.trim(),
      }));

      this.watchlistAuditLogGridFilter = [...(this.watchlistAuditLogGridFilter || []), ...newFilters];
    } else {
      const newFilter = {
        filtertypecode: parameterCode,
        filtercriteria: filterCriteria,
      };
      this.watchlistAuditLogGridFilter = [...(this.watchlistAuditLogGridFilter || []), newFilter];
    }

    this.filterHide();
    this.loadAuditLog();
  }

  public clearAllFilter() {
    this.watchlistAuditLogGridFilter = [];
    this.filteringColumn.searchValue = '';
    this.sortParameterCode = 2;
    this.filterHide();
    this.loadAuditLog();
  }

  public cancel(value: number) {
    if (value != null && this.filteringColumn.filterable == false) {
      this.filteringColumn.searchValue = '';
    }
    this.filterHide();
  }
}

interface ShowMoreState {
  from: boolean;
  to: boolean;
}
