<div class="dds-modal-overlay" (click)="close()"></div>

<div ddsResize class="start-nca-process-modal dds-modal">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Start NCA Process</span>
    <dds-button size="sm"
    icon="dds-icon_close"
    kind="silent"
    theme="dark"
    ariaLabel="Close modal"
    class="dds-modal__close"
    (clicked)="close()"></dds-button>
  </div>
  <div class="dds-modal__body eclipse-content">
    <div class="row align-items-center">
      <div class="col-md-5">
        <span>Client Number/Jupiter Opportunity Number:</span>
      </div>
      <div class="col-md-5">
        <dds-input
          autosave
          [placeholder]="textareaOptions.placeholder ?? ''"
          [maxLength]="textareaOptions.maxLength ?? 0"
          [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
          [size]="textareaOptions.size ?? ''"
          [(ngModel)]="clientNumberOrOppurtunityNumber"
          (ngModelChange)="clientNumberOrOppurtunityNumberChange()"
        ></dds-input>
      </div>
      <div class="col-md-2 margin-top-xxs">
        <dds-button
          [theme]="buttonOptions.theme ?? ''"
          [kind]="buttonOptions.kind ?? ''"
          [size]="buttonOptions.size ?? ''"
          [width]="buttonOptions.width ?? ''"
          [ariaLabel]="buttonOptions.ariaLabel ?? ''"
          [disabled]="!clientNumberOrOppurtunityNumber.trim()"
          (clicked)="checkNcaProcess()"
        >Select</dds-button>
      </div>
    </div>
    <div class="row align-items-center margin-top-s">
      <div class="col-md-5">
        <span>Select the function to start the NCA process:</span>
      </div>
      <div class="col-md-5">
        <dds-select
          [list]="optionsStartNcaFunctions"
          [placeholder]="'Select Function'"
          [(ngModel)]="functionToStartNca"
          [disabled]="true"
          
        ></dds-select>
      </div>
    </div>
    <div class="row margin-top-s">
      <div [innerHTML]="checkNcaProcessResult.startNcaProcessMessage"></div>
    </div>
    
  </div>
  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left"></div>
    <div class="dds-modal__footer-content dds-modal__footer-content_right">
      <dds-button
        class="dds-modal__footer-item"
        kind="secondaryLoud"
        (click)="close()"
      >Cancel</dds-button>
      <dds-button
        class="dds-modal__footer-item"
        [disabled]="!checkNcaProcessResult.isStartNcaProcessAvailable"
        (click)="startNcaProcessManually()"
      >Start NCA Process</dds-button>
    </div>
  </div>
</div>
