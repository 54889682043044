import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environment/environment";
import { Client } from "../../common/models/client.model";
import { DocumentMetaDataDetail } from "../../common/models/documentMetaDataDetail.modal";
import { ParentClient } from "../../common/models/parent-client.model";
import { ClientAgreementRequest } from "../../common/models/client-agreement-request.model";
import { ClientAgreementDocumentResponse } from "../../common/models/client-agreement-document.model";
import { NotificationType } from "../../common/models/notification-type";
import { URLConstant } from "../../client/constants/url-constant";

@Injectable({
  providedIn: 'root'
})

export class ClientWebapiService {
  constructor(private httpService: HttpClient) { }
  private header = new HttpHeaders({ 'content-type': 'application/json' });

  clientApiUrl: string = environment.apiUrl;

  getClientInfo(clientNumber: string): Observable<Client> {
    return this.httpService.get<Client>(this.clientApiUrl + 'Client/GetClientInfo?clientNumber=' + clientNumber);
  }

  getParentClientInfo(clientNumber: string): Observable<ParentClient> {
    return this.httpService.get<ParentClient>(this.clientApiUrl + 'Client/GetParentClientInfo?clientNumber=' + clientNumber);
  }

  saveNCADocumentMetaData(data: any): Observable<any> {
    var baseURL = environment.apiConfig.redirectUri;//For non-Local
    //var baseURL = "https://localhost:56325";//For local
    const url = this.clientApiUrl + 'NCA/ClientStatus/SaveNCADocumentMetaData';
    var finalData = JSON.stringify(data);
    return this.httpService.post(url, finalData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  updateNCADocumentMetaData(data: any): Observable<any> {
    var baseURL = environment.apiConfig.redirectUri;//For non-Local
    //var baseURL = "https://localhost:56325";//For local
    const url = this.clientApiUrl + 'NCA/ClientStatus/UpdateNCADocumentMetaData';
    var finalData = JSON.stringify(data);
    return this.httpService.post(url, finalData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  getDocumentTypeData(): Observable<any> {
    var baseURL = environment.apiConfig.redirectUri;//For non-Local
    //var baseURL = "https://localhost:56325";//For local
    const url = this.clientApiUrl + 'NCA/ClientStatus/GetDocumentType';
    return this.httpService.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  getNCADocumentUploadData(documentSectionId: string, clientmilestoneId: string): Observable<DocumentTypeData[]> {
    const url = this.clientApiUrl + 'NCA/ClientStatus/GetNCADocumentMetaData?clientMilestonedocumentSectionId=' + documentSectionId + '&clientMilestoneId=' + clientmilestoneId;
    return this.httpService.get<DocumentTypeData[]>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }

  getClientAgreementDocumentCount(clientNumber: string, documentTypeId: number): Observable<number> {
    const url = this.clientApiUrl +  URLConstant.GET_CLIENT_AGREEMENT_DOCUMENT_COUNT.replace('#clientNumber#', clientNumber).replace('#documentTypeId#', documentTypeId.toString());
    return this.httpService.get<number>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }

  addClientAgreementDocument(data: ClientAgreementRequest): Observable<string> {
    const url = this.clientApiUrl + URLConstant.ADD_CLIENT_AGREEMENT;
    return this.httpService.post<string>(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }

  updateClientAgreementDocument(data: ClientAgreementRequest): Observable<string> {
    const url = this.clientApiUrl + URLConstant.UPDATE_CLIENT_AGREEMENT + data.clientDocumentId;
    return this.httpService.put<string>(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }

  deleteClientAgreementDocument(clientDocumentId: number): Observable<string> {
    const url = this.clientApiUrl + URLConstant.DELETE_CLIENT_AGREEMENT + clientDocumentId;
    return this.httpService.delete<string>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }

  putFakeClientwithValidClientInfo(
    fakeClientNumber: string,
    clientNumber: string
    ): Observable<string> {
      const url = `${this.clientApiUrl}Client/UpdateFakeClientwithValidClientInfo?fakeClientNumber=${fakeClientNumber}&clientNumber=${clientNumber}`;
      return this.httpService.put<string>(url,null);
    }

    getClientAgreementDocuments(clientNumber: string, hideExpiredDocuments: boolean, pageNumber: number = 1, pageSzie: number = 10): Observable<ClientAgreementDocumentResponse> {
      const url = this.clientApiUrl + URLConstant.GET_CLIENT_AGREEMENT_DOCUMENTS.replace('#clientNumber#', clientNumber).replace('#hideExpiredDocuments#', hideExpiredDocuments.toString()).replace('#pageNumber#', pageNumber.toString()).replace('#pageSize#', pageSzie.toString());
      return this.httpService.get<ClientAgreementDocumentResponse>(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("idToken")
        })
      })
    }

    getClientAgreementDocumentUploadRequestTemplate(clientNumber: string): Observable<NotificationType> {
      const url = this.clientApiUrl + URLConstant.GET_CLIENT_AGREEMENT_DOCUMENT_UPLOAD_REQUEST_TEMPLATE.replace('#clientNumber#', clientNumber);
      return this.httpService.get<NotificationType>(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("idToken")
        })
      })
    }
}

export interface ClientNCADocumentDetail extends DocumentMetaDataDetail {
  clientMilestoneId: number;
  clientMilestoneDocumentSectionId: number;
  clientMilestoneDocumentTypeId: number;
}

export interface DocumentTypeData extends DocumentMetaDataDetail {
  clientMilestoneDocumentTypeName: string;
  clientMilestoneDocumentTypeId: number;
  clientMilestoneDocumentSectionId: number;
  archiveStatusColor: string;
  archiveStatus: string;
  archiveStatusHover: string;
}
