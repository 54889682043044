import {
  ButtonKind,
  ErrorState,
  ExtThemes,
  LabelPosition,
  Size,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { ExternalCommSubmissionStatus } from './submission-status.enum';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { DocumentStatus } from './document-status.enum';

export class ExternalCommunicationConstant {
  public static readonly ENTER_TEXT_PLACEHOLDER: string = 'Enter text';
  public static readonly EMPTY_SPACE: string = '';
  public static readonly TEXTAREA_MIN_HEIGHT: number = 70;
  public static readonly TEXTAREA_LENGTH: number = 2;
  public static readonly ADD_REVIEWER_BTN_LABEL: string = 'Add reviewer';
  public static readonly COMPLETE_REVIEW: string = 'Complete review';
  public static readonly RETURN_TO_REWORK: string = 'Return to rework';
  public static readonly RETURN_FOR_SELF_REVIEW: string =
    'Return to self-review';
  public static readonly PRESCREEN: string = 'Prescreen';
  public static readonly CONFIRM_BTN_LABEL: string = 'Confirm';

  public static readonly COMMUNICATION_TYPE_ID: number = 3;
  public static readonly TEXTAREA_CHAR_LIMIT: number = 1000;
  public static readonly TEXTAREA_CHAR_LIMIT_BIGGEST: number = 5000;
  public static readonly TWO_BUSINESSDAY_SELECTED: number = 2;
  public static readonly YES: string = 'yes';
  public static readonly NO: string = 'no';
  public static readonly INTERNALCONTEST: string = 'Internal contest';
  public static readonly EXTERNALCONTEST: string = 'External contest';
  public static readonly COMMTYPEAWARD: string = 'Awards/Winner Announcements';
  public static readonly COMMTYPESPONSORSHIP: string = 'Sponsorship/Membership Agreements';
  public static readonly COMMTYPECONTRACTTHIRDPARTIES: string = "Contracts with third parties (e.g., speaker agreements, publishing agreements. etc.)";
  public static readonly EXTCOMMSUBMISSIONLEVELPAGE: string = 'extCommSubmissionLevelPage';

  public static readonly RESET_SUBMISSION_STATUS: string =
    'Reset submission status';
  public static readonly INTAKE_CHECKBOX_THEME_COLOR = Themes.green;
  public static readonly SET_TIMEOUT_DURATION = 2000;

  public static readonly TEXTAREA_CHAR_COUNT_POSTION: string = 'bottom-right';
  public static readonly DATE_FORMAT: string = 'dd/MM/yyyy';
  public static readonly DATE_FORMAT_US: string = 'MM/dd/yyyy';
  public static readonly DATESTRING_FORMAT_US: string = 'en-US';
  public static readonly RUSH_REQUEST_VALUE: string = 'Yes';

  public static readonly UI_ELEMENT_SIZE: Size = Size.lg;
  public static readonly TEXTAREA_SIZE: string =
    ExternalCommunicationConstant.UI_ELEMENT_SIZE;
  public static readonly INTAKE_INFO_TOOLTIP_THEME_COLOR = Themes.dark;

  public static readonly SHOW_MORE_TEXT: string = 'Show more';
  public static readonly SHOW_LESS_TEXT: string = 'Show less';

  public static readonly DDS_ICON_ARROW_UP: string = 'dds-icon_arrow-up';
  public static readonly DDS_ICON_ARROW_DOWN: string = 'dds-icon_arrow-down';
  public static readonly DDS_ICON_CLOSE: string = 'dds-icon_close';

  public static readonly OTHER_STATUS: number = 4; //TODO: Update the number
  public static readonly OTHER_REASONID: number = 15;
  public static readonly RETURN_TO_SELF_REVIEW_TOAST_MESSAGE: string =
    'ReturnedforSelfReview';

  public static readonly EXT_COMM_SUBMISSION_TYPE_CODE: string = 'EXT COMM';

  public static readonly DEFAULT_PAGE_NUMBER: number = 1;
  public static readonly DEFAULT_PAGE_SIZE: number = 15;
  public static readonly DEFAULT_PAGE_SIZE_10: number = 10;

  public static readonly TEXT_AREA_SETTINGS_GLOBAL: TextareaOptions = {
    label: '',
    labelPosition: LabelPosition.external,
    placeholder: ExternalCommunicationConstant.ENTER_TEXT_PLACEHOLDER,
    minHeight: ExternalCommunicationConstant.TEXTAREA_MIN_HEIGHT,
    isRequired: false,
    isInverse: false,
    readonly: false,
    isError: false,
    errorState: ErrorState.default,
    description: '',
    customClass: '',
    errorMessage: '',
    maxLength: ExternalCommunicationConstant.TEXTAREA_CHAR_LIMIT,
    maxLengthPosition:
      ExternalCommunicationConstant.TEXTAREA_CHAR_COUNT_POSTION,
    size: ExternalCommunicationConstant.TEXTAREA_SIZE,
  };

  //TODO: need to fix by Harsh as its not valid document status
  public static readonly EXT_COMM_VALID_DOCUMENT_TYPES: Record<
    ExternalCommSubmissionStatus,
    string[]
  > = {
    [ExternalCommSubmissionStatus.DRAFT]: [
      'External Communication (DPM10640 Communication)',
    ],
    [ExternalCommSubmissionStatus.RETURN_FOR_REWORK]: [
      'External Communication (DPM10640 Communication)',
    ],
    [ExternalCommSubmissionStatus.SUBMITTED]: [],
    [ExternalCommSubmissionStatus.RESUBMITTED]: [],
    [ExternalCommSubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT]: [],
    [ExternalCommSubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT]: [],
    [ExternalCommSubmissionStatus.COMPLETED]: [],
    [ExternalCommSubmissionStatus.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION]: [],
    [ExternalCommSubmissionStatus.RM_REVIEWED_PENDING_PPMD_CONFIRMATION]: [],
    [ExternalCommSubmissionStatus.DISCONTINUED]: [],
    [ExternalCommSubmissionStatus.ADMIN_COMPLETE]: [],
  };
  public static readonly EXT_COMM_SUBMISSION_STATUS_MAPPER: Record<
    ExternalCommSubmissionStatus,
    number
  > = {
    [ExternalCommSubmissionStatus.DRAFT]: 1,
    [ExternalCommSubmissionStatus.RETURN_FOR_REWORK]: 2,
    [ExternalCommSubmissionStatus.SUBMITTED]: 3,
    [ExternalCommSubmissionStatus.RESUBMITTED]: 4,
    [ExternalCommSubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT]: 5,
    [ExternalCommSubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT]: 6,
    [ExternalCommSubmissionStatus.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION]: 7,
    [ExternalCommSubmissionStatus.RM_REVIEWED_PENDING_PPMD_CONFIRMATION]: 8,
    [ExternalCommSubmissionStatus.COMPLETED]: 9,
    [ExternalCommSubmissionStatus.DISCONTINUED]: 10,
    [ExternalCommSubmissionStatus.ADMIN_COMPLETE]: 11,
  };
  public static readonly EXT_COMM_DOCUMENT_STATUS_MAPPER: Record<
    DocumentStatus,
    number
  > = {
    [DocumentStatus.Draft]: 1,
    [DocumentStatus.DOC_PRESCRENEED_INCLUDES_EDITS]: 2,
    [DocumentStatus.DOC_PRESCREENED_RM_REVIEW]: 3,
    [DocumentStatus.RMReviewed]: 4,
    [DocumentStatus.DOC_FINAL_VERSION]: 5,
    [DocumentStatus.SelfReviewed]: 6,
    [DocumentStatus.SupportingDocument]: 7,
    [DocumentStatus.FinalArchiveReadyVersion]: 8,
    [DocumentStatus.DOC_SELF_REVIEWED]: 0, //TODO: add the correct number once you receive from BE
    [DocumentStatus.DOC_RMReviewed]: 0, //TODO: add the correct number once you receive from BE
  };

  public static readonly EXT_COMM_MODAL_CLOSE_BTN: ButtonOptions = {
    theme: ExtThemes.dark,
    kind: ButtonKind.silent,
    size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
    width: WidthState.fixed,
    isLoading: false,
    icon: ExternalCommunicationConstant.DDS_ICON_CLOSE,
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: 'Close modal',
    customClass: ExternalCommunicationConstant.EMPTY_SPACE,
    role: 'button',
  };

  public static readonly EXT_COMM_VALID_DOCUMENT_STATUS: Record<
    ExternalCommSubmissionStatus,
    string[]
  > = {
    [ExternalCommSubmissionStatus.DRAFT]: [],
    [ExternalCommSubmissionStatus.RETURN_FOR_REWORK]: ['Draft'],
    [ExternalCommSubmissionStatus.SUBMITTED]: [],
    [ExternalCommSubmissionStatus.RESUBMITTED]: [],
    [ExternalCommSubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT]: [],
    [ExternalCommSubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT]: [],
    [ExternalCommSubmissionStatus.COMPLETED]: [],
    [ExternalCommSubmissionStatus.SELF_REVIEWED_PENDING_PPMD_CONFIRMATION]: [],
    [ExternalCommSubmissionStatus.RM_REVIEWED_PENDING_PPMD_CONFIRMATION]: [],
    [ExternalCommSubmissionStatus.DISCONTINUED]: [],
    [ExternalCommSubmissionStatus.ADMIN_COMPLETE]: [],
  };
}
