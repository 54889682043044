import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FeeType,
  OpportunityDetailsEdit,
} from '../../../common/models/opportunityDetailsEdit';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import { ErrorState, Size } from '@usitsdasdesign/dds-ng/shared';
import { SubmissionService } from '../../../http/intake/submission.service';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { configurationType } from '../../../common/models/common-models';
import { DatePipe } from '@angular/common';
import {
  DatepickerOptions,
  DatepickerStates,
} from '@usitsdasdesign/dds-ng/datepicker';
import { SaveOpportunityDetailsSection } from '../../../common/models/saveOpportunityDetails';

import {
  MultiSelectItem,
  MultiSelectOptions,
  SelectControlTypes,
  SelectType,
} from '@usitsdasdesign/dds-ng/multi-select';
import { IntakeConstant } from '../../constants/intake.constant';
import { lepDataValue } from '../opportunity-details-settings';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import {
  OpportunityDetailsNavModel,
  LepValue,
  LepName,
} from '../../../common/models/opportunity-details.model';
import { commonSectionForSingleCoCheck } from '../../common/intake.util';
import { Router } from '@angular/router';
import { SecurityWebapiService } from '../../../http/security/security-webapi.service';
import { PermissionCheck } from '../../../common/validations/PermissionCheck';

@Component({
  selector: 'app-opportunity-details-section',
  templateUrl: './opportunity-details-section.component.html',
  styleUrl: './opportunity-details-section.component.less',
  providers: [DatePipe],
})
export class OpportunityDetailsSectionComponent
  extends PermissionCheck
  implements OnInit, OnDestroy
{
  feeTypeList: MultiSelectItem[] = [];
  feeTypeListData!: FeeType[];
  intakeOpportunityDetails!: OpportunityDetailsEdit;
  public saveopportunityDetailsModule!: Saver | null;
  isEditingLEP: boolean = false;
  isEditingTotalFees: boolean = false;
  textareaOptions: any = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  isEditingEndDate: boolean = false;
  isEditingStartDate: boolean = false;
  reviewerLepId: string = '';
  lepValue: string = '';
  tempLepValue: string = this.lepValue;
  tempStartDate: Date;
  totalFees: string = '';
  isEditingFeeType: boolean = false;
  tempTotalFees: string = this.totalFees;
  defaultForEmptyFields = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
  commonPlaceholder = IntakeConstant.ENTER_TEXT_PLACEHOLDER;
  opportunityDescription: string = '';
  tempEndDate: Date;
  startDate: string | null = '';
  endDate: string | null = '';
  updateLabel: string = '';
  oportunityDetailsInfo: OpportunityDetailsEdit = {
    opportunityNumber: '',
    projectStartDate: '',
    projectEndDate: '',
    leadEngagementPartner: '',
    totalFees: '',
    feeType: [],
    clientNumber: '',
    opportunityDescription: '',
  };
  multiselectFeeTypesOptions: MultiSelectItem[] = [];
  lepData: LepValue = lepDataValue;
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  dateFormater: string = 'MM/dd/yyyy';

  datePickerOptions: DatepickerOptions = {
    size: this.htmlElementSize,
    placeholder: 'Select date',
    format: this.dateFormater,
    minMode: DatepickerStates.day,
    isResponsive: true,
    isManualInput: false,
  };
  multiSelectOptions: MultiSelectOptions = {
    label: '',
    size: this.htmlElementSize,
    description: '',
    placeholder: 'Select Fee Types',
    type: SelectType.text,
    controlType: SelectControlTypes.checkbox,
    theme: 'green',
    isInverse: false,
    disabled: false,
    isResponsive: false,
    isError: false,
    isRequired: false,
    displayTickAllBtn: false,
    errorMessage: '',
    errorState: ErrorState.default,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: '',
    stickerIsDisabled: false,
    stickerPosition: 'bottom-left',
    stickerIndent: 0,
    stickerCustomClass: '',
  };

  // Flags to indicate if the field value was updated via Intake details (Eclipse)
  isLepUpdated: boolean = false;
  isStartDateUpdated: boolean = false;
  isEndDateUpdated: boolean = false;
  isTotalFeesUpdated: boolean = false;
  isFeeTypeUpdated: boolean = false;

  // New properties for holding the original values from Opportunity Details (Jupiter)
  originalLepValue: string = '';
  originalStartDate: string | null = null;
  originalEndDate: string | null = null;
  originalTotalFees: string = '';
  originalFeeTypes: FeeType[] = [];

  // Track unsynced fields for the warning message
  unsyncedFields: string[] = [];
  unsyncedWarningMessage: string = '';

  @Input() id: string = '';
  @Input() submissionData: OpportunityDetailsNavModel[] = [];
  @Output() lepValueChange: EventEmitter<LepValue> =
    new EventEmitter<LepValue>();
  unsubscriber$: Subject<void> = new Subject<void>();
  @Input() configLables: configurationType[] = [];

  constructor(
    private readonly autosaverService: AutosaverService,
    private readonly submissionService: SubmissionService,
    private readonly datePipe: DatePipe,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly router: Router,
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
    this.tempStartDate = new Date();
    this.tempEndDate = new Date();
  }

  ngOnInit(): void {
    this.submissionService.enableAuditTrail(true);
    this.progressIndicator.show();
    this.getLabelConfiguration();

    forkJoin({
      opportunityDetails: this.submissionService.getOpportunityDetailsInfo(
        this.id
      ),
      intakeDetails: this.submissionService.getOpportunityDetailForSummary(
        this.id
      ),
    })
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: ({ opportunityDetails, intakeDetails }) => {
          this.oportunityDetailsInfo = opportunityDetails;
          this.intakeOpportunityDetails = intakeDetails;
          this.mergeOpportunityDetails();
          this.getFeeType();
          this.submissionService.updateDetailStatus(
            this.opportunityDescription.length !== 0
          );

          const autoSaverOptions = {
            onSuccess: (saver: any, result: SaveOpportunityDetailsSection) => {
              return this.autoSaveHandler();
            },
          } as IAutosaverOptions;

          this.saveopportunityDetailsModule = this.autosaverService.newSaver(
            'saveopportunitydetailsmodel',
            this,
            async () => this.saveOpportunityDetailsValues(),
            autoSaverOptions
          );
          this.saveopportunityDetailsModule.start();
          this.progressIndicator.hide();
        },
        error: (err) => {
          this.progressIndicator.hide();
          console.error(
            'An error occurred while fetching opportunity details: ',
            err
          );
        },
      });
  }

  // TO DO: To be handled later
  public autoSaveHandler(): void {}

  getFeeType(): void {
    this.submissionService
      .getFeeTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: FeeType[]) => {
          if (response && response.length > 0) {
            this.feeTypeListData = response;
            this.multiselectFeeTypesOptions = response.map(
              (feeType: FeeType) => ({
                feeTypeId: feeType?.feeTypeId,
                label: feeType?.feeTypeName,
                value: false,
              })
            );
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  private mergeOpportunityDetails(): void {
    const intake = this.intakeOpportunityDetails;
    const details = this.oportunityDetailsInfo;
    // Opportunity Number
    this.id = intake?.opportunityNumber || details?.opportunityNumber || '';
    // Start Date Processing
    const startDateIntake = intake?.projectStartDate
      ? new Date(intake.projectStartDate)
      : null;
    const startDateDetails = details?.projectStartDate
      ? new Date(details.projectStartDate)
      : null;
    // Use Intake if available; otherwise fall back to details.
    this.tempStartDate = startDateIntake || startDateDetails || new Date();
    this.startDate = this.tempStartDate
      ? this.datePipe.transform(this.tempStartDate, this.dateFormater)
      : '';
    this.isStartDateUpdated = !!intake?.projectStartDate;
    if (
      startDateIntake &&
      startDateDetails &&
      startDateIntake.getTime() !== startDateDetails.getTime()
    ) {
      this.originalStartDate = startDateDetails
        ? this.datePipe.transform(startDateDetails, this.dateFormater)
        : '';
      if (!this.unsyncedFields.includes('Start date')) {
        this.unsyncedFields.push('Start date');
      }
    }
    // End Date Processing
    const endDateIntake = intake?.projectEndDate
      ? new Date(intake.projectEndDate)
      : null;
    const endDateDetails = details?.projectEndDate
      ? new Date(details.projectEndDate)
      : null;
    this.tempEndDate = endDateIntake || endDateDetails || new Date();
    this.endDate = this.tempEndDate
      ? this.datePipe.transform(this.tempEndDate, this.dateFormater)
      : '';
    this.isEndDateUpdated = !!intake?.projectEndDate;
    if (
      endDateIntake &&
      endDateDetails &&
      endDateIntake.getTime() !== endDateDetails.getTime()
    ) {
      this.originalEndDate = endDateDetails
        ? this.datePipe.transform(endDateDetails, this.dateFormater)
        : '';
      if (!this.unsyncedFields.includes('End date')) {
        this.unsyncedFields.push('End date');
      }
    }
    // Lead Engagement Partner (LEP) Processing
    const lepIntake = intake?.leadEngagementPartner
      ? intake.leadEngagementPartner
      : null;
    const lepDetails = details?.leadEngagementPartner
      ? details.leadEngagementPartner
      : '';
    if (lepIntake) {
      this.lepValue = lepIntake;
      this.isLepUpdated = true;
      if (lepDetails && lepIntake !== lepDetails) {
        this.originalLepValue = lepDetails;
        if (!this.unsyncedFields.includes('Lead Engagement Partner')) {
          this.unsyncedFields.push('Lead Engagement Partner');
        }
      }
    } else {
      this.lepValue = lepDetails;
      this.isLepUpdated = false;
    }
    this.lepData.lepReviewedBy =
      intake?.pursuitLeaderId ?? details?.pursuitLeaderId ?? '';
    this.lepData.lepName = this.lepValue;
    this.lepValueChange.emit(this.lepData);

    // Total Fees Processing
    const totalFeesIntake = intake?.totalFees ? intake.totalFees : null;
    const totalFeesDetails = details?.totalFees ? details.totalFees : '';
    if (totalFeesIntake) {
      this.totalFees = totalFeesIntake;
      this.isTotalFeesUpdated = true;
      if (totalFeesDetails && totalFeesIntake !== totalFeesDetails) {
        this.originalTotalFees = totalFeesDetails;
        if (!this.unsyncedFields.includes('Total Fees')) {
          this.unsyncedFields.push('Total Fees');
        }
      }
    } else {
      this.totalFees = totalFeesDetails;
      this.isTotalFeesUpdated = false;
    }
    this.tempTotalFees = this.totalFees;
    // For fee types, we compare arrays based on feeTypeId (if available) and label.
    let intakeFeeTypes: FeeType[] = [];
    let detailsFeeTypes: FeeType[] = [];
    if (intake?.feeType && intake.feeType.length > 0) {
      intakeFeeTypes = intake.feeType;
      this.feeTypeList = intakeFeeTypes.map((feeType: FeeType) => ({
        feeTypeId: feeType?.feeTypeId,
        label: feeType?.feeTypeName,
        value: true,
      }));
      this.isFeeTypeUpdated = true;
    } else if (details?.feeType && details.feeType.length > 0) {
      detailsFeeTypes = details.feeType;
      this.feeTypeList = detailsFeeTypes.map((feeType: FeeType) => ({
        label: feeType?.feeTypeName,
        value: true,
      }));
      this.isFeeTypeUpdated = false;
    }
    // Compare fee types only if Intake has provided the fee types and details exists.
    if (intakeFeeTypes.length && detailsFeeTypes.length) {
      const feesDiffer =
        intakeFeeTypes.length !== detailsFeeTypes.length ||
        intakeFeeTypes.some((ft) => {
          return !detailsFeeTypes.find(
            (dft) =>
              dft.feeTypeId === ft.feeTypeId &&
              dft.feeTypeName === ft.feeTypeName
          );
        });
      if (feesDiffer) {
        this.originalFeeTypes = detailsFeeTypes;
        if (!this.unsyncedFields.includes('Fee Types')) {
          this.unsyncedFields.push('Fee Types');
        }
      }
    }
    // Client Number & Opportunity Description
    this.oportunityDetailsInfo.clientNumber =
      intake?.clientNumber ?? details?.clientNumber ?? '';
    this.opportunityDescription =
      intake?.opportunityDescription ?? details?.opportunityDescription ?? '';
    // Build Warning Message if any fields are out-of-sync
    if (this.unsyncedFields.length > 0) {
      this.unsyncedWarningMessage =
        'The ' +
        this.unsyncedFields.join(', ') +
        (this.unsyncedFields.length === 1 ? ' is' : ' are') +
        ' different between Jupiter and Eclipse. To keep both systems in sync, either update Eclipse or edit the value(s) in Jupiter.';
    } else {
      this.unsyncedWarningMessage = '';
    }
  }

  getSelectedFeeTypeIds(): number[] {
    return this.feeTypeList
      .filter((selectedItem) => selectedItem.value)
      .map((selectedItem) => selectedItem['feeTypeId']);
  }

  async onLepEngagementPartnerSelected(event: LepName): Promise<void> {
    this.lepValue = event.displayName;
    this.reviewerLepId = event.reviewerId;
    await this.saveLEP();
    await this.saveOpportunityDetailsValues();
    await this.reloadCurrentRoute();
  }

  /**
   * Validates that the project start date is not later than the project end date.
   */
  private validateDates(): boolean {
    if (this.tempStartDate && this.tempEndDate) {
      return this.tempStartDate.getTime() <= this.tempEndDate.getTime();
    }
    return true;
  }

  saveOpportunityDetailsValues(): void {
    // Validate dates
    if (!this.validateDates()) {
      this.progressIndicator.hide();
      console.error(
        'Validation Error: Project start date cannot be later than end date.'
      );
      return;
    }

    const adjustedStartDate = new Date(
      this.tempStartDate.getTime() -
        this.tempStartDate.getTimezoneOffset() * 60000
    );
    const adjustedEndDate = new Date(
      this.tempEndDate.getTime() - this.tempEndDate.getTimezoneOffset() * 60000
    );
    // TODO : TO be handled for other edits and autosave as a part of seperate story
    const saveopportunitydetailsmodel = {
      opportunityNumber: this.id,
      pursuitLeaderId: this.reviewerLepId,
      projectStartDate: adjustedStartDate.toISOString(),
      projectEndDate: adjustedEndDate.toISOString(),
      totalFees: this.tempTotalFees,
      FeeTypeIds: this.getSelectedFeeTypeIds(),
      opportunitydescription: this.opportunityDescription,
    };

    this.submissionService
      .updateOpportunityDetails(saveopportunitydetailsmodel)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicator.hide();
          // Optionally, display success notification here
        },
        error: (err) => {
          this.progressIndicator.hide();
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (this.isEditingLEP && !target.closest('#Lep-editor')) {
      this.saveLEP();
    }
    if (this.isEditingTotalFees && !target.closest('#TotalFees-editor')) {
      this.saveTotalFees();
    }
    if (this.isEditingEndDate && !target.closest('#EndDate-editor')) {
      this.saveEndDate();
    }
    if (this.isEditingStartDate && !target.closest('#StartDate-editor')) {
      this.saveStartDate();
    }
    if (this.isEditingFeeType && !target.closest('#Fee-Type')) {
      this.saveFeeType();
    }
  }

  editLep(): void {
    this.isEditingLEP = true;
    this.tempLepValue = this.lepValue;
  }

  saveLEP(): void {
    this.isEditingLEP = false;
  }

  editTotalFees(): void {
    this.isEditingTotalFees = true;
    this.tempTotalFees = this.totalFees;
  }

  saveTotalFees(): void {
    this.totalFees = this.tempTotalFees;
    this.isEditingTotalFees = false;
    this.saveOpportunityDetailsValues();
    this.reloadCurrentRoute();
  }

  editEndDate(): void {
    this.isEditingEndDate = true;
  }

  editFeeType(): void {
    this.isEditingFeeType = true;
  }

  saveEndDate(): void {
    this.endDate = this.datePipe.transform(this.tempEndDate, this.dateFormater);
    this.isEditingEndDate = false;
    this.saveOpportunityDetailsValues();
    this.reloadCurrentRoute();
  }

  editStartDate(): void {
    this.isEditingStartDate = true;
  }

  saveStartDate(): void {
    this.startDate = this.datePipe.transform(
      this.tempStartDate,
      this.dateFormater
    );
    this.isEditingStartDate = false;
    this.saveOpportunityDetailsValues();
    this.reloadCurrentRoute();
  }

  saveFeeType(): void {
    this.isEditingFeeType = false;
    this.saveOpportunityDetailsValues();
    this.reloadCurrentRoute();
  }

  getLabelConfiguration(): void {
    this.updateLabel =
      this.configLables.find(
        (item) =>
          item.appConfigurationLabelKey ===
          'UPDATE_JUPITER_OPPORTUNITY_INFORMATION'
      )?.appConfigurationLabelValue || '';
  }

  isOpportunityDescriptionRequiredIconVisible() {
    if (
      this.submissionData.length === 0 ||
      commonSectionForSingleCoCheck(this.submissionData)
    ) {
      return false;
    } else if (this.opportunityDescription !== '') {
      return false;
    } else {
      return true;
    }
  }

  valueChanged(): void {
    this.submissionService.updateDetailStatus(
      this.opportunityDescription.length !== 0
    );
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
