import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MsGraphService } from '../../../http/msgraph.service';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../intake/constants/intake.constant';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { SubmissionService } from '../../../http/intake/submission.service';
import { InitiationApiService } from '../../../http/initiation/initiation-api.service';
import { ExternalCommunicationService } from '../../../http/external-communication/external-communication.service';
import { AdminModuleService } from '../../../admin/admin-services/admin-module.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from '../../../http/intake/common.service';

@Component({
  selector: 'app-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrl: './people-picker.component.less',
})
export class PeoplePickerComponent implements OnInit, OnDestroy {
  emptyString: string = IntakeConstant.EMPTY_SPACE;

  @Output() userSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() isTechinalReviewerSearch: boolean = false;
  @Input() tempLepName: string | null | undefined = '';
  @Input() isTeamMemberSearch: boolean = false;
  @Input() isLep: boolean = false;
  @Input() showLabel: boolean = false;
  @Input() labelText: string = this.emptyString;
  @Input() isPPMD: boolean = false;
  @Input() placeholder!: string;
  @Input() isDisabled: boolean = false;
  @Input() isRMConfig: boolean = false;
  @Input() isEmployeeSearch: boolean = false;
  @Input() maxLength: number = 0;
  @Input() isRMSupport: boolean = false;
  @Input() isEmpFetch: boolean = false;
  private readonly searchQuery$: Subject<string> = new Subject<string>();
  data: any = [];
  searchedUser: string = this.emptyString;
  unsubscriber$: Subject<any> = new Subject();
  userList: any = [];
  notFound: string = this.emptyString;
  selectedUser: any = {};
  pageNumber: number = 1;
  showSelected: boolean = false;
  searchOptions: SearchOptions = {
    placeholder: IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER,
    size: Size.md,
    customClass: this.emptyString,
  };
  showdropDown: boolean = false;
  pageSize: number = IntakeConstant.PAGE_NUMBER_10;
  totalCount: number = 0;
  constructor(
    private readonly msgraph: MsGraphService,
    private readonly submissionService: SubmissionService,
    private readonly initiationApiService: InitiationApiService,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly adminService: AdminModuleService,
    private readonly common: CommonService
  ) {}

  ngOnInit(): void {
    this.searchQuery$
      .pipe(
        debounceTime(IntakeConstant.SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.unsubscriber$)
      )
      .subscribe((event) => {
        if (event.length > IntakeConstant.SEARCH_STRING_LIMIT) {
          if (this.isTechinalReviewerSearch) {
            this.fetchTechnicalUserUsers(event, this.pageNumber);
          } else if (this.isTeamMemberSearch) {
            this.fetchTeamMembers(event, this.pageNumber);
          } else if (this.isPPMD) {
            this.fetchPPMDUsers(event, this.pageNumber);
          } else if (this.isRMConfig) {
            this.fetchRMCongifUsers(event, this.pageNumber);
          } else if (this.isEmployeeSearch) {
            this.fetchEmployees(event, this.pageNumber);
          } else if (this.isRMSupport) {
            this.fetchRMSupportExtComm(event, this.pageNumber);
          } else if (this.isEmpFetch) {
            this.fetchReviewerExtComm(event, this.pageNumber);
          } else {
            this.fetchUserSearchResultUsingGeneral(event, this.pageNumber);
          }
          this.showdropDown = true;
        } else {
          this.notFound = this.emptyString;
          this.userList = [];
          this.showdropDown = false;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isTechinalReviewerSearch']?.currentValue) {
      this.searchOptions.placeholder =
        IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER_LEAD_ENGAGEMENT_PARTNER;
    } else if (this.isTeamMemberSearch) {
      this.searchOptions.placeholder =
        IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER_TEAM_MEMBER;
    } else {
      this.searchOptions.placeholder =
        IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER;
    }

    if (
      changes['placeholder']?.currentValue ===
      IntakeConstant.PLACEHOLDER_TEXT_FOR_SECONDARY_CONTACT
    ) {
      this.searchOptions.placeholder = this.placeholder;
    }
    if (this.isEmployeeSearch) {
      this.searchOptions.placeholder =
        IntakeConstant.PLACEHOLDER_TEXT_FOR_SECONDARY_CONTACT;
    }
  }

  fieldCleared(): void {
    this.userList = [];
    this.searchedUser = this.emptyString;
    this.notFound = this.emptyString;
    this.showdropDown = false;
    this.pageNumber = 1;
  }

  private fetchUserSearchResultUsingGeneral(
    searchString: string,
    pageNumber: number
  ): void {
    this.common
      .fetchEmployeesGeneral(searchString, pageNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.totalCount = users['totalCount'];
          this.transformUserDetails(users['employees'], pageNumber);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  fetchTechnicalUserUsers(searchString: string, pageNumber: number): void {
    this.submissionService
      .getTechnicalReviewers(searchString, pageNumber, this.isLep)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.totalCount = users['totalCount'];
          this.transformUserDetails(users['technicalReviewers'], pageNumber);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  fetchTeamMembers(searchString: string, pageNumber: number): void {
    this.submissionService
      .fetchEmployeesTeamMember(searchString, pageNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.totalCount = users['totalCount'];
          this.transformUserDetails(users['employees'], pageNumber);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  fetchPPMDUsers(searchString: string, pageNumber: number): void {
    this.initiationApiService
      .searchPPMD(searchString)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.transformUserDetails(users, pageNumber);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  fetchOpportunitySearchResultMore(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      if (this.pageNumber <= this.totalCount / this.pageSize) {
        this.pageNumber++;
        if (this.isTechinalReviewerSearch) {
          this.fetchTechnicalUserUsers(this.searchedUser, this.pageNumber);
        } else if (this.isTeamMemberSearch) {
          this.fetchTeamMembers(this.searchedUser, this.pageNumber);
        } else if (this.isPPMD) {
          this.fetchPPMDUsers(this.searchedUser, this.pageNumber);
        } else if (this.isRMConfig) {
          this.fetchRMCongifUsers(this.searchedUser, this.pageNumber);
        } else if (this.isEmployeeSearch) {
          this.fetchEmployees(this.searchedUser, this.pageNumber);
        } else if (this.isRMSupport) {
          this.fetchRMSupportExtComm(this.searchedUser, this.pageNumber);
        } else if (this.isEmpFetch) {
          this.fetchReviewerExtComm(this.searchedUser, this.pageNumber);
        } else {
          this.fetchUserSearchResultUsingGeneral(
            this.searchedUser,
            this.pageNumber
          );
        }
      }
    }
  }

  fetchEmployees(searchString: string, pageNumber: number): void {
    this.adminService
      .searchEmployees(searchString)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.transformUserDetails(users, pageNumber);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  private readonly transformUserDetails = (
    users: any,
    pageNumber: number
  ): any => {
    this.notFound = this.emptyString;
    const filteredUsers = users?.filter(
      (user: any) => user.fullName !== this.tempLepName
    );
    if (pageNumber === 1) {
      this.userList = filteredUsers;
    } else {
      this.userList = [...this.userList, ...filteredUsers];
    }
    if (this.userList.length === 0) {
      this.notFound = IntakeConstant.NO_MATCHES_FOUND;
    } else {
      this.notFound = this.emptyString;
    }
  };

  fetchUserDetails(item: any): void {
    const transformdata: any = {
      displayName:
        item?.displayName ??
        item?.outlookDisplayName ??
        item?.fullName ??
        item?.name ??
        `${item?.givenName ?? this.emptyString} ${
          item?.surname ?? this.emptyString
        }`.trim(),
      email:
        item?.mail ?? item?.emailId ?? item?.emailAddress ?? this.emptyString,
      location: item?.officeLocation ?? item?.location ?? null,
      fullname:
        item?.fullName ??
        item?.name ??
        `${item?.givenName ?? this.emptyString} ${
          item?.surname ?? this.emptyString
        }`.trim(),
      reviewerId:
        item?.reviewerId ??
        item?.employeeId ??
        item?.empID ??
        item?.employeeid ??
        null,
    };
    this.userSelected.emit(transformdata);
    this.selectedUser = item;
    this.showdropDown = false;
    this.userList = [];
    this.searchedUser = this.emptyString;
  }

  fetchRMCongifUsers(searchString: string, pageNumber: number): void {
    this.externalCommService
      .getRMConfigUser(searchString, pageNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.totalCount = users['totalCount'];
          this.transformUserDetails(users['ecrmConfig'], pageNumber);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  fetchRMSupportExtComm(searchString: string, pageNumber: number): void {
    this.externalCommService
      .searchRMSupport(searchString, pageNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.totalCount = users['totalCount'];
          this.transformUserDetails(users, pageNumber);
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error searching data', err);
        },
      });
  }

  resetUser(): void {
    this.selectedUser = this.emptyString;
    this.showSelected = false;
  }

  findUsers(event: string): void {
    if (!this.isDisabled) {
      this.searchQuery$.next(event);
    } else {
      this.fieldCleared();
    }
  }

  fetchReviewerExtComm(searchString: string, pageNumber: number): void {
    this.submissionService
      .fetchEmployeesTeamMember(searchString, pageNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.totalCount = users['totalCount'];
          this.transformUserDetails(users['employees'], pageNumber);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next(this.emptyString);
    this.unsubscriber$.complete();
  }
}
