import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AccordionOptions,
  Tableheader,
  TooltipOptions,
} from './service-delivery-settings';
import { Subject, takeUntil } from 'rxjs';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { IntakeConstant } from '../../../constants/intake-constant';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { ShowAllPopupComponent } from '../../../opportunity-summary/show-all-popup/show-all-popup/show-all-popup.component';
import { ShowAllModel } from '../../../../common/models/show-all-popup-model';

@Component({
  selector: 'app-service-delivery',
  templateUrl: './service-delivery.component.html',
  styleUrl: './service-delivery.component.less',
})
export class ServiceDeliveryComponent implements OnInit {
  @Input() opportunityNumber: string = '';
  @Input() summary: boolean = false;
  @Input() showAllSplits: boolean = false;
  @Input() public documentInitiatedDetails: ShowAllModel = {
    opportunityNumber: '',
    showAllSplits: false,
  };
  totalTableData: any[] = [];
  tableData: any[] = [];
  tableDataLength: number = 0;
  showAllColumns: boolean = false;
  amountFilter: boolean = false;
  fssFilter: boolean = false;
  searchQuery: string = '';
  unsubscriber$ = new Subject();
  AMOUNT: string = IntakeConstant.AMOUNT;
  FSS: string = IntakeConstant.FSS;
  ASCENDING: string = IntakeConstant.ASCENDING;
  DECENDING: string = IntakeConstant.DECENDING;
  CLIENTSERVICELEVEL: string = IntakeConstant.CLIENT_SERVICE_LEVEL;
  INITIALSERVICETABLECOLUMN: number =
    IntakeConstant.INITIAL_SERVICE_TABLE_COLUMN;
  constructor(
    private readonly intakeDetailService: SubmissionService,
    private readonly modalService: ModalService
  ) {}
  isAmountShorted: boolean = true;
  isFssShorted: boolean = true;

  ngOnInit(): void {
    if (this.documentInitiatedDetails.opportunityNumber) {
      this.opportunityNumber = this.documentInitiatedDetails.opportunityNumber;
    }
    this.getServiceData();
  }

  accordionOptions = AccordionOptions;

  public sampleColumns: Column[] = Tableheader;

  options = TooltipOptions;

  getServiceData(): void {
    this.intakeDetailService
      .getDeliveryServiceTable(this.opportunityNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.totalTableData = data;

          if (this.totalTableData.length > 3 && !this.showAllSplits) {
            this.tableData = this.totalTableData.slice(0, 3);
          } else {
            this.tableData = this.totalTableData;
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  showAll(): void {
    this.tableData = this.totalTableData;
    this.showAllColumns = true;
  }

  showLess(): void {
    this.tableData = this.totalTableData.slice(0, 3);
    this.showAllColumns = false;
  }

  public sorted(columnName: string): void {
    if(this.showAllSplits){
      this.showAllColumns = true;
    }
    if (columnName === IntakeConstant.AMOUNT) {
      if (this.isAmountShorted) {
        this.totalTableData = this.totalTableData.sort((a, b) => {
          return a[columnName] > b[columnName] ? 1 : -1;
        });
      } else {
        this.totalTableData = this.totalTableData.sort((a, b) => {
          return a[columnName] < b[columnName] ? 1 : -1;
        });
      }
      this.isAmountShorted = !this.isAmountShorted;
      this.showAllColumns
        ? (this.tableData = this.totalTableData)
        : (this.tableData = this.totalTableData.slice(0, 3));
    }
    if (columnName === IntakeConstant.FSS) {
      if (this.isAmountShorted) {
        this.totalTableData = this.totalTableData.sort((a, b) => {
          return a[columnName] > b[columnName] ? 1 : -1;
        });
      } else {
        this.totalTableData = this.totalTableData.sort((a, b) => {
          return a[columnName] < b[columnName] ? 1 : -1;
        });
      }
      this.isAmountShorted = !this.isAmountShorted;
      this.showAllColumns
        ? (this.tableData = this.totalTableData)
        : (this.tableData = this.totalTableData.slice(0, 3));
    }
  }

  public applyFilter(columnName: string): void {
    this.tableData = this.totalTableData.filter(
      (item) =>
        item[columnName] &&
        item[columnName]
          .toString()
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
    );
    this.fssFilter = false;
    this.amountFilter = false;
    if (this.tableData.length < 3) {
      this.showAllColumns = false;
    }
  }

  public cancel(): void {
    this.fssFilter = false;
    this.amountFilter = false;
  }

  openModal(): void {
    this.modalService.open(ShowAllPopupComponent, {
      documentInitiatedDetails: {
        opportunityNumber: this.opportunityNumber,
        showAllSplits: true,
      },
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
