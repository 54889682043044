import { InitiateSubmissionComponent } from './initiate-submission/initiate-submission.component';
import { OpportunityDetailsComponent } from './opportunity-details/opportunity-details.component';
import { OpportunityNotFoundComponent } from './opportunity-not-found/opportunity-not-found.component';
import { OpportunitySummaryComponent } from './opportunity-summary/opportunity-summary/opportunity-summary.component';

export const INTAKE_ROUTES = [
  {
    path: '',
    children: [
      {
        path: '',
        component: InitiateSubmissionComponent,
      },
      {
        path: 'opportunity-details/:id',
        component: OpportunityDetailsComponent,
      },
    ],
  },
  {
    path: 'opportunity-summary',
    component: OpportunitySummaryComponent,
  },
  {
    path: 'opportunity-not-found',
    component: OpportunityNotFoundComponent
  }
];
