import { Injectable } from '@angular/core';
import { environment } from '../../environment/environment';
import { BehaviorSubject, combineLatest, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EclipseHeaderService {

  rootUrl: string = environment.rootUrl;
  readonly #clientName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  readonly #clientNumber: BehaviorSubject<string> = new BehaviorSubject<string>('');
  readonly #watchlistId: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  readonly #showHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  readonly #isWatchlistAuditHistoryMenuVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  clientName$ = this.#clientName.asObservable();
  clientNumber$ = this.#clientNumber.asObservable();
  watchlistId$ = this.#watchlistId.asObservable();
  isWatchlistAuditHistoryMenuVisible$ = this.#isWatchlistAuditHistoryMenuVisible.asObservable();
  showHeader$ = this.#showHeader.asObservable();
  private topPosition: number = 0;

  constructor() { }

  setEclipseHeaderVisiblity(show: boolean) {
    this.#showHeader.next(show);
  }

  showWatchlistAuditHistoryMenu(show: boolean): void {
    this.#isWatchlistAuditHistoryMenuVisible.next(show);
  }

  navigateWatchlistAuditTrial(clientNumber?: string, watchlistId?: number | undefined, clientName?: string) {
    if (watchlistId == undefined) {
      combineLatest([
        this.clientName$,
        this.clientNumber$,
        this.watchlistId$
      ]).pipe(take(1))
        .subscribe(
          ([
            clientName,
            clientNumber,
            watchlistId
          ]) => {
            const url = this.rootUrl + 'watchlist/audit-trail/watchlistId/' + watchlistId;

            const newTab = window.open(url, '_blank');
            if (newTab) {
              newTab.onload = () => {
                newTab.history.replaceState({ clientNumber, clientName }, '');
                newTab.addEventListener('scroll', (event) => {
                });
              };
            }
          });
    } else {
      const url = this.rootUrl + 'watchlist/audit-trail/watchlistId/' + watchlistId;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.onload = () => {
          newTab.history.replaceState({ clientNumber, clientName }, '');
          newTab.addEventListener('scroll', (event) => {
          });
        };
      }
    }
  }

  setWatchlistAuditParams(clientName: string, clientNumber: string, watchlistId: number): void {
    this.#clientName.next(clientName);
    this.#clientNumber.next(clientNumber);
    this.#watchlistId.next(watchlistId);
  }

  getHeaderHeight(showHeader: boolean): void {
    let headerHeight: number = 0;
    const headerContainer: number =
      (document.querySelector('.header-custom-container') as HTMLElement)
        ?.offsetHeight ?? 0;

    if (!showHeader) {
      headerHeight = headerContainer;
    }
    if (this.topPosition != headerHeight) {
      this.topPosition = headerHeight;
      this.updateStickyTopPosition();
    }

    // TODO: Need for checking top position when header is shown, remove if not needed
    // let topPosition: number = 0;
    // const eclipseHeader = 80; // 5rem
    // const headerCustomContainer = (
    //   document.querySelector('.header-custom-container') as HTMLElement
    // )?.getBoundingClientRect();
    // const headerContainerHeight: number = headerCustomContainer?.height ?? 0;
    // const headerContainerBottom: number = headerCustomContainer?.bottom ?? 0;

    // const blackHeaderBottom: number =
    //   (
    //     document.querySelector('.header-section') as HTMLElement
    //   )?.getBoundingClientRect()?.bottom ?? 0;

    // if (!showHeader) {
    //   topPosition = headerContainerHeight;
    // } else {
    //   topPosition =
    //     eclipseHeader +
    //     headerContainerHeight +
    //     (blackHeaderBottom - headerContainerBottom);
    // }
    // if (this.topPosition != topPosition) {
    //   this.topPosition = topPosition;
    //   this.updateStickyTopPosition();
    // }
  }

  updateStickyTopPosition(): void {
    const targetElements = document.querySelectorAll(
      '.sticky-element-after-black-header'
    );
    if (targetElements.length > 0) {
      targetElements.forEach((element) => {
        (element as HTMLElement).style.top = `${this.topPosition}px`;
      });
    }
    // this.scrollToTop();
  }

  scrollToTop(): void {
    window.scrollTo({ top: this.topPosition, behavior: 'smooth' });
  }
}
