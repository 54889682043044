export enum RoleEnum {
  RISK_MANAGER = 'Risk Manager',
  RISK_MANAGER_READ_ONLY = 'Risk Manager Read-only',
  SUPPORTING_RISK_MANAGER = 'Supporting Risk Manager',
  SPECIALIZED_RISK_MANAGER = 'Specialized Risk Manager',
  REVIEWER = 'Reviewer',
  TECHNICAL_INDEPENDENT_RISK_MANAGER = 'Technical/Independent Risk Manager',
  SYSTEM_ADMINISTRATOR = 'System Administrator',
  ENGAGEMENT_TEAM_MEMBER = 'Engagement Team Member',
  LEAD_ENGAGEMENT_PARTNER = 'Lead Engagement Partner',
  RESPONSIBLE_PRACTITIONER = 'Responsible Practitioner',
  RM_SUPPORT_GLOBAL = 'RM Support',
  RM_SUPPORT_LOCAL = 'RM Support Local',
  INDUSTRY_RISK_LEADER_READ_ONLY = 'Industry Risk Leader Read-only',
  PRIMARY_CONTACT = 'Primary Contact',
  SECONDARY_CONTACT = 'Secondary Contact',
  OTHER_CONTACT = 'Other Contact',
  PERSUIT_TEAM = 'Persuit Team', // TODO: Remove, No such role exist
  SPECIALIZED_OFFERING_RISK_LEADER = 'Specialized Offering Risk Leader (SORL)',
  DELIVERY_CENTER_RISK_MANAGER = 'Delivery Center Risk Manager',
  GLOBAL_ENGAGEMENT_EFA = 'Global Engagement EFA',
  EXT_COMM_RISK_MANAGER = 'Risk Manager-Ext.Comm',
  EXT_COMM_SUPPORTING_RISK_MANAGER = 'Supporting Risk Manager-Ext.Comm',
  EXT_COMM_REVIEWER = 'Reviewer-Ext.Comm',
  EXT_COMM_RM_SUPPORT_GLOBAL = 'RM Support-Ext.Comm',
  EXT_COMM_ENGAGEMENT_TEAM_MEMBER = 'Engagement Team Member-Ext.Comm',
  WATCHLIST_ADMIN = 'Watchlist admin',
}

export const RoleIdMapper: { [key in RoleEnum]: number } = {
  [RoleEnum.PERSUIT_TEAM]: -1, // TODO: Remove, No such role exist
  [RoleEnum.SYSTEM_ADMINISTRATOR]: 521,
  [RoleEnum.REVIEWER]: 678,
  [RoleEnum.RISK_MANAGER]: 679,
  [RoleEnum.SUPPORTING_RISK_MANAGER]: 680,
  [RoleEnum.SPECIALIZED_RISK_MANAGER]: 681,
  [RoleEnum.LEAD_ENGAGEMENT_PARTNER]: 682,
  [RoleEnum.ENGAGEMENT_TEAM_MEMBER]: 683,
  [RoleEnum.TECHNICAL_INDEPENDENT_RISK_MANAGER]: 674,
  [RoleEnum.RISK_MANAGER_READ_ONLY]: 689,
  [RoleEnum.INDUSTRY_RISK_LEADER_READ_ONLY]: 690,
  [RoleEnum.RM_SUPPORT_GLOBAL]: 691,
  [RoleEnum.RESPONSIBLE_PRACTITIONER]: 692,
  [RoleEnum.PRIMARY_CONTACT]: 693,
  [RoleEnum.SECONDARY_CONTACT]: 694,
  [RoleEnum.OTHER_CONTACT]: 695,
  [RoleEnum.SPECIALIZED_OFFERING_RISK_LEADER]: 696,
  [RoleEnum.RM_SUPPORT_LOCAL]: 700,
  [RoleEnum.DELIVERY_CENTER_RISK_MANAGER]: 701,
  [RoleEnum.GLOBAL_ENGAGEMENT_EFA]: 702,
  [RoleEnum.EXT_COMM_RISK_MANAGER]: 703,
  [RoleEnum.EXT_COMM_SUPPORTING_RISK_MANAGER]: 704,
  [RoleEnum.EXT_COMM_REVIEWER]: 705,
  [RoleEnum.EXT_COMM_RM_SUPPORT_GLOBAL]: 706,
  [RoleEnum.EXT_COMM_ENGAGEMENT_TEAM_MEMBER]: 707,
  [RoleEnum.WATCHLIST_ADMIN]: 675,
};
