<div class="complexity-question-container margin-bottom-s text-align-left">
  <div class="initiate-page padding-bottom-s">
    <h3 class="font-size-24 font-bold no-break">Global configuration</h3>
    <div
      class="d-flex justify-content-between margin-top-m d-flex justify-content-between align-items-center"
    >
      <div class="select-submission">
        <dds-select
          [(ngModel)]="selectedElement.value"
          [isResponsive]="selectOptions.isResponsive ?? false"
          [isRequired]="selectOptions.isRequired ?? false"
          [isInverse]="selectOptions.isInverse ?? false"
          [list]="selectItems"
          [label]="selectOptions.label ?? ''"
          [labelPosition]="selectOptions.labelPosition ?? ''"
          [customClass]="selectOptions.customClass ?? ''"
          [placeholder]="selectOptions.placeholder ?? ''"
          [size]="htmlElementSize"
          [stickerWidth]="selectOptions.stickerWidth ?? ''"
          [stickerShift]="selectOptions.stickerShift ?? 0"
          [stickerMaxHeight]="selectOptions.stickerMaxHeight ?? 0"
          [stickerIsDisabled]="selectOptions.stickerIsDisabled ?? false"
          [stickerPosition]="selectOptions.stickerPosition ?? ''"
          [stickerIndent]="selectOptions.stickerIndent ?? 0"
          [stickerIsDynamic]="selectOptions.stickerIsDynamic ?? false"
          (valueChanged)="valueChanged($event)"
        ></dds-select>
      </div>
      <div class="margin-top-sm select-submission">
        <dds-search
          [size]="htmlElementSize"
          [customClass]="searchOptions.customClass ?? ''"
          [icon]="searchOptions.icon || 'dds-icon_search'"
          (fieldCleared)="fieldCleared()"
        >
          <input
            ddsSearch
            [placeholder]="searchOptions.placeholder ?? ''"
            [(ngModel)]="selectedOportunity"
            (ngModelChange)="selectOpportunityChanged($event)"
          />
        </dds-search>
      </div>
    </div>

    <div class="margin-top-m">
      <table class="dds-data-table dds-data-table_sm">
        <thead class="dds-data-table__header">
          <tr>
            <th
              *ngFor="let column of globalConfigColumns"
              class="dds-data-table__header-cell"
              [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
            >
              <div class="dds-data-table__content">
                <span>{{ column.header }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="dds-data-table__body">
          <tr
            *ngFor="let row of sampleData; index as index"
            class="dds-data-table__row"
          >
            <td
              *ngFor="let column of globalConfigColumns"
              class="dds-data-table__cell"
            >
              <ng-container *ngIf="column.name === 'editIcon'">
                <span
                  class="dds-icon dds-icon_edit__s__filled edit-icon"
                  (click)="openModalForUpdateLabel(row)"
                ></span>
              </ng-container>
              <ng-container
                *ngIf="column.name === 'appConfigurationLabelValue'"
              >
                <span [innerHTML]="getSafeHTML(row[column.name])"></span>
              </ng-container>
              <ng-container
                *ngIf="
                  column.name !== 'appConfigurationLabelValue' &&
                  column.name !== 'editIcon'
                "
              >
                <span [innerHTML]="row[column.name]"></span>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="image-section d-flex align-items-center justify-content-center"
        *ngIf="!sampleData || sampleData.length === 0"
      >
        <div class="d-flex align-items-center">
          <img
            src="assets/images/no_watchlist_clients.svg"
            alt="empty-state"
            class="margin-right-sm"
          />
          <div class="empty-state-text eclipse-main-tab-header">
            There are no records to display
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
