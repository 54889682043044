import { CommonModule } from "@angular/common";
import { SERVICE_PROVIDERS } from "./services";
import { AUTOSAVE_COMPONENTS } from "./autosaver";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { COMMON_COMPONENTS } from "./components";
import { LibModule } from '@usitsdasdesign/dds-ng';
import { COMMON_PIPES } from "./pipes";
import { NotifyPopupComponent } from './notify-popup/notify-popup.component';
import { NewPaginationAndGridComponent } from "./pagination-and-grids/new-pagination-and-grid/new-pagination-and-grid.component";
import { WarningPopupComponent } from "./components/comments/warning-popup/warning-popup.component";
import { PeoplePickerComponent } from "./components/people-picker/people-picker.component";
import { ClientPickerComponent } from './components/client-picker/client-picker.component';
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { BarChartComponent } from './components/graph/bar-chart/bar-chart.component';
import { BarChartItemComponent } from './components/graph/bar-chart-item/bar-chart-item.component';
import { ChartLegendComponent } from './components/graph/chart-legend/chart-legend.component';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { HtmlToTextPipe } from './pipes/html-to-text.pipe';
import { ErrorPageComponent } from './components/error-page/error-page.component';

@NgModule({
    declarations: [
       AUTOSAVE_COMPONENTS,
       COMMON_COMPONENTS,
       COMMON_PIPES,
       NewPaginationAndGridComponent,
       NotifyPopupComponent,
       WarningPopupComponent,
       PeoplePickerComponent,
       ClientPickerComponent,
       ConfirmPopupComponent,
       BarChartComponent,
       BarChartItemComponent,
       ChartLegendComponent,
       SentenceCasePipe,
       HtmlToTextPipe,
       ErrorPageComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        LibModule,
        NgbDropdownModule,
        ReactiveFormsModule,
    ],
    providers: [
        SERVICE_PROVIDERS
    ],
    exports: [
        AUTOSAVE_COMPONENTS,
        COMMON_COMPONENTS,
        COMMON_PIPES,
        FormsModule,
        NewPaginationAndGridComponent,
        PeoplePickerComponent,
        ClientPickerComponent,
        ConfirmPopupComponent,
        BarChartComponent,
       BarChartItemComponent,
       ChartLegendComponent
    ]
})
export class EclipseCommonModule { }
