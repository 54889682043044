<span content>
  <div class="accordion-content relative">
    <div class="div-headers">
      <table class="dds-data-table dds-data-table_selection">
        <thead class="dds-data-table__header">
          <tr>
            <th
              class="dds-data-table__header-cell"
              [ngStyle]="{
                width: '150px',
                minWidth: '100px'
              }"
            >
              Required
            </th>
            <th class="dds-data-table__header-cell">
              <div class="dds-data-table__content">
                <span>Role/Name</span>
              </div>
            </th>
            <th class="dds-data-table__header-cell">
              <span *ngIf="false">Actions</span>
            </th>
            <th class="dds-data-table__header-cell"></th>
          </tr>
        </thead>
        <tbody class="dds-data-table__body">
          <tr class="dds-data-table__row">
            <td class="dds-data-table__cell table-checkbox-cell">
              <dds-checkbox theme="green"></dds-checkbox>
            </td>
            <td class="dds-data-table__cell">
              <ng-container>
                <div>
                  <span class="comments-text-label eclipse-small-header-grey"
                    >Responsible Practioner (PPMD)
                  </span>
                </div>
                <div>
                  <span class="profile-name">{{ ppmdName }}</span>
                </div>
              </ng-container>
              <ng-container *ngIf="false">
                <ng-container>
                  <app-people-picker [showLabel]="true"></app-people-picker>
                </ng-container>

                <ng-template #displayDetails>
                  <span class="comments-text-label eclipse-small-header-grey"
                    >roleName</span
                  ><br />
                  <span class="profile-name">fullName & displayName</span>
                  <span
                    class="dds-icon dds-icon_edit__s__filled edit-icon cursor-clickable"
                  ></span>
                  <span
                    class="margin-left-s dds-icon dds-icon_delete__l__stroke"
                  ></span>
                </ng-template>
              </ng-container>
            </td>
            <td class="dds-data-table__cell">
              <div class="d-flex" *ngIf="false">
                <dds-button class="custom-button padding-right-xs"
                  >Complete review</dds-button
                >

                <dds-button class="custom-button">Return for rework</dds-button>
              </div>
            </td>
            <td class="dds-data-table__cell">
              <div *ngIf="false">
                <div class="flex-row-col">
                  <p class="text-green-2">Review Completed</p>
                  <p>TimestampDateToBeShown</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <dds-button
          [theme]="options.theme ?? ''"
          [kind]="options.kind ?? ''"
          [size]="options.size ?? ''"
          [width]="options.width ?? ''"
          [icon]="options.icon ?? ''"
          [isIconLeft]="options.isIconLeft ?? false"
          [role]="options.role ?? ''"
          [isInverse]="options.isInverse ?? false"
          [isColorBg]="options.isColorBg ?? false"
          [isLoading]="options.isLoading ?? false"
          [ariaLabel]="options.ariaLabel ?? ''"
          [customClass]="options.customClass ?? ''"
          >Add Reviewer</dds-button
        >
      </table>
    </div>
  </div></span
>
