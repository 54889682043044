import {
  MultiSelectItem,
  MultiSelectOptions,
  SelectControlTypes,
  SelectType,
} from '@usitsdasdesign/dds-ng/multi-select';
import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import { ErrorState, Themes } from '@usitsdasdesign/dds-ng/shared';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';

export const radioGroupOptions: RadioGroupOptions = {
  name: 'group1',
  disabled: false,
  isError: false,
  isUncheckable: false,
  isRequired: false,
  isInverse: false,
};

export const options: TagOptions = {
  theme: Themes.dark,
  isRemovable: true,
  disabled: false,
  isError: false,
  isInverse: false,
  size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  customClass: '',
};

export const textareaOptions: TextareaOptions = {
  placeholder: 'Enter member firm',
  minHeight: 100,
  maxLength: 100,
  size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  maxLengthPosition: 'bottom-right',
};

export const tags = [
  {
    title: 'Hoston Lin',
    imageUrl: 'assets/img/user.png',
  },
  {
    title: 'David Bon',
  },
  {
    title: 'David Bon',
    initials: 'db',
  },
];

export const CommunicationContains: MultiSelectItem[] = [
  {
    label: 'References to Generative AI',
  },
  {
    label: 'Criticism of a client, industry, or other third party',
  },
  {
    label: 'Mentions of negative client incidents (e.g., breaches, lawsuits)',
  },
  {
    label:
      'Advocating for talent strategies (e.g., pay equity) or other actions which may provoke inquiry into Deloitte’s internal processes',
  },
  {
    label:
      'Positions on public policy (e.g., politics, elections, advocating for policy changes)',
  },
  {
    label:
      'Polarizing topics such as vaccine management, controlled substances (e.g., cannabis, opioids), climate change, human trafficking',
  },
  {
    label: 'References to geopolitical issues or risks',
  },
  {
    label:
      'References to the International Olympic Committee or Deloitte’s sponsorship of the Olympics',
  },
  {
    label:
      'Reference to a Deloitte attest client that has not been cleared by the LCSP',
  },
  {
    label: 'No, none of the above apply',
  },
];
export const otherMemberFirm: MultiSelectItem[] = [
  {
    label: 'Consulting',
  },
  {
    label: 'Audit & Assurance',
  },
  {
    label: 'Tax',
  },
  {
    label: 'Other Member Firm',
  },
];

export const multiSelectOptions: MultiSelectOptions = {
  label: '',
  size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  description: '',
  placeholder: 'Select',
  type: SelectType.counter,
  controlType: SelectControlTypes.checkbox,
  theme: 'green',
  isInverse: false,
  disabled: false,
  isResponsive: false,
  isError: false,
  isRequired: false,
  displayTickAllBtn: true,
  errorMessage: '',
  errorState: ErrorState.default,
  customClass: '',
  stickerWidth: 0,
  stickerShift: 0,
  stickerMaxHeight: '',
  stickerIsDisabled: false,
  stickerPosition: 'bottom-left',
  stickerIndent: 0,
  stickerCustomClass: '',
  counterTypeText: '',
};
export const radioBtnsOriginal: RadioButton[] = [
  {
    options: {
      label: 'Yes',
      theme: Themes.green,
      disabled: undefined,
      customClass: 'button-spacing',
    },
    value: 'yes',
  },
  {
    options: {
      label: 'No',
      theme: Themes.green,
    },
    value: 'no',
  },
];

export const isCrossBusinessGlobalGroupOptions: RadioGroupOptions = {
  name: 'isCrossBusinessGlobal',
};

export const isGenAIGroupOptions: RadioGroupOptions = {
  name: 'isGenAI',
};

export const communicationContainsGroupOptions: RadioGroupOptions = {
  name: 'communicationContains',
};

export const isNewNascentEvolvProductsGroupOptions: RadioGroupOptions = {
  name: 'isNewNascentEvolvProducts',
};

export const isSensitiveMatterGroupOptions: RadioGroupOptions = {
  name: 'isSensitiveMatter',
};

export const isAnalayzeRegulationGroupOptions: RadioGroupOptions = {
  name: 'isAnalayzeRegulation',
};

export const isThirdPartyInvolvedGroupOptions: RadioGroupOptions = {
  name: 'isThirdPartyInvolved',
};

export const IsTPRMApprovalEmailIncludedOptions: RadioGroupOptions = {
  name: 'IsTPRMApprovalEmailIncluded',
};
export const isDiscussServicesToAssistWithRegGroupOptions: RadioGroupOptions = {
  name: 'isDiscussServicesToAssistWithReg',
};

export const isGovernmentRegulationInvolvedGroupOptions: RadioGroupOptions = {
  name: 'isGovernmentRegulationInvolved',
};

export const IsAnalayzeRegulationOptions: RadioGroupOptions = {
  name: 'IsAnalayzeRegulation',
};
export const IsDiscussServicesToAssistWithRegOptions: RadioGroupOptions = {
  name: 'IsDiscussServicesToAssistWithReg',
};

export const isSpeakingOnPanelGroupOptions: RadioGroupOptions = {
  name: 'isSpeakingOnPanel',
};
