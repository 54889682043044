import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import {
  LepValue,
  OpportunityDetailsNavModel,
} from '../../common/models/opportunity-details.model';
import { SubmissionService } from '../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { AccordionOptions } from '@usitsdasdesign/dds-ng/accordion';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import {
  lepDataValue,
  tooltipOptionsData,
} from './opportunity-details-settings';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { IntakeDocumentSubmissionTypeCode } from '../constants/intake_submission_type_code.enum';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { EmitUpdatedTitleModal } from '../../common/models/intake-submission-details-common-section.model';
import { commonSectionForSingleCoCheck } from '../common/intake.util';
import { IntakeAction } from '../../common/models/intake-action.model';
import { OppertunitySubmissionStatusCode } from '../../common/models/oppertunity-submission-statusCode.model';
import { CommonService } from '../../http/intake/common.service';
import { StatusEnum } from '../../common/constants/status-enum';
import { IntakeConstant } from '../constants/intake.constant';
import { AdobeAnalyticsService } from '../../common/services/adobe-analytics.service';
import { PermissionCheckService } from '../../common/validations/permission-check.service';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { PermissionsObj } from '../../common/models/common-models';
import { ToastService } from '@usitsdasdesign/dds-ng/toast';
import { showCompletionToast } from './opportunity-details.helper';
import {
  EntityDetails,
  EntityType,
  UserService,
} from '../../http/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { EclipseHeaderService } from '../../http/eclipse-header.service';
import { error } from 'jquery';

@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
  styleUrl: './opportunity-details.component.less',
})
export class OpportunityDetailsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  permissionObj: PermissionsObj = {} as PermissionsObj;
  private previousResult: boolean = true; // Initialize with true to track the first change
  isOpportunityDetailsCompleted: boolean = false;

  constructor(
    private readonly intakeDetailService: SubmissionService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly submissionService: SubmissionService,
    private readonly commonService: CommonService,
    private readonly analytics: AdobeAnalyticsService,
    private readonly permissionCheck: PermissionCheckService,
    private readonly toastService: ToastService, // Inject ToastService
    private readonly securityWebapiService: SecurityWebapiService,
    private readonly userService: UserService,
    private readonly headerService: EclipseHeaderService
  ) {}
  updateTitleDetails!: EmitUpdatedTitleModal;
  activeIndex: number = -1;
  opportunityId: string = IntakeConstant.EMPTY_SPACE;
  opportunityDetailTitle: string | null = IntakeConstant.EMPTY_SPACE;
  opportunityID: string | null = IntakeConstant.EMPTY_SPACE;
  requiredCheckForIdList: number[] = [];
  dataSubmissionTypeCode: string = IntakeConstant.EMPTY_SPACE;
  intakeDocumentSubmissionTypeCodeELSOW =
    IntakeDocumentSubmissionTypeCode.EL_SOW;
  lepValue: LepValue = lepDataValue;

  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  submissitionDetails: OpportunityDetailsNavModel[] = [];
  accordionOptions: AccordionOptions = {
    size: Size.lg,
    disabled: false,
    isOpen: true,
    isMulti: false,
    isInverse: false,
    customClass: IntakeConstant.EMPTY_SPACE,
  };
  opportunityComplexQuestionStatus: boolean = true;
  opportunityDetailStatus: boolean = true;
  opportunityComplexCyberQuestionStatus: boolean = true;
  submissionTypeSelected: string = IntakeConstant.EMPTY_SPACE;
  currentSubmissionId: string = IntakeConstant.EMPTY_SPACE;
  isNavigationFromSummary: boolean = false;
  queryParamSubmissionId: string = IntakeConstant.EMPTY_SPACE;

  selectedSubmissionId: number | null = null;
  options: TooltipOptions = tooltipOptionsData;

  isNavigatingFromDashboard: boolean = false;
  clientNumber: string = IntakeConstant.EMPTY_SPACE;
  onFocusURL: string = IntakeConstant.EMPTY_SPACE;
  jupiterURL: string = IntakeConstant.EMPTY_SPACE;

  ngOnInit(): void {
    this.analytics?.trackPage('Opportunity Details');
    this.progressIndicator.show();
    this.loadRolesStatusActions();
    this.permissionObj = this.permissionCheck.getIntakePermissionsObj;
    const id = this.route.snapshot.paramMap.get('id');
    this.opportunityID = id;
    document.title =
      BrowserTabConstant.Browser_TabName_Default + this.opportunityID;
    const statedata = history.state;

    if (statedata) {
      this.opportunityDetailTitle = statedata.name;
      this.submissionTypeSelected = statedata.submissionTypeCode;
      this.currentSubmissionId = statedata.submissionID;
      this.isNavigationFromSummary = statedata.navigatedFromSummary;
      this.isNavigatingFromDashboard = statedata.navigatingFromDashboard;
    }

    this.router.events
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.toastService.closeAll();
        }
      });

    if (id) {
      this.opportunityId = id;
      this.getNavDetails(id);
      this.getOpportunityDetails(id);
    }

    this.intakeDetailService.complexQuestionStatus$.subscribe((status) => {
      this.opportunityComplexQuestionStatus = !status;
    });
    this.intakeDetailService.detailStatus$.subscribe((status) => {
      this.opportunityDetailStatus = !status;
    });
    this.intakeDetailService.complexQuestionCyberStatus$.subscribe((status) => {
      this.opportunityComplexCyberQuestionStatus = !status;
    });
    this.intakeDetailService.isOpportunityDetailsCompletedStatus$.subscribe(
      (status) => {
        this.isOpportunityDetailsCompleted = status;
      }
    );

    this.progressIndicator.hide();
  }

  updateRoleAndPermissions(): void {
    const entityId: string | number =
      this.currentSubmissionId && this.activeIndex !== -1
        ? this.currentSubmissionId
        : this.submissitionDetails[0]?.submissionId ?? -1;

    const entityTypeId: number = EntityType.Submission;
    this.userService.setEntityDetails(entityId, entityTypeId);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollToActiveIndex();
    }, IntakeConstant.SET_TIMEOUT_DURATION);
  }

  setActive(index: number): void {
    this.activeIndex = index;
    this.activeIndex !== -1
      ? (this.selectedSubmissionId =
          this.submissitionDetails[index].submissionId)
      : (this.selectedSubmissionId = null);
    this.dataSubmissionTypeCode =
      this.submissitionDetails[index]?.submissionTypeCode;
    this.toastService.closeAll();

    /**
     * Reload should take to last active submission
     * It adds the queryParam for every submission selected
     */
    this.router.navigate(
      ['submission/opportunity-details', this.opportunityID],
      {
        queryParams: { submissionId: this.selectedSubmissionId },
        queryParamsHandling: 'merge',
        state: {
          submissionID: this.selectedSubmissionId,
          submissionTypeCode: this.dataSubmissionTypeCode,
          name: this.opportunityDetailTitle,
        },
        replaceUrl: true,
      }
    );
    this.submissionService.updateCurrentSubmissionPageStatus(index !== -1);

    if (index === -1) {
      this.updateRoleAndPermissions();
    }
  }

  selectNavItem(submissionId: number): void {
    this.selectedSubmissionId = submissionId;
  }

  getNavDetails(id: string): void {
    this.progressIndicator.show();
    this.intakeDetailService
      .getIntakeDetails(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: OpportunityDetailsNavModel[]) => {
          this.progressIndicator.hide();
          if (!data || data.length === 0) {
            this.router.navigate(['/submission/opportunity-not-found']);
          }
          this.submissitionDetails = data;
          this.submissitionDetails.forEach((item) => {
            if (item.isFormCompleted) {
              this.checkRequired(true, item.submissionId);
            }
          });

          this.queryParamSubmissionId =
            this.route.snapshot.queryParams['submissionId'];
          /**
           * If the navigation is from a summary, dashboard, initation(CO) or a specific submission ID is provided as queryParams,
           * submissionId is passed as queryParams for notification links and current active submission (page reload)
           * it sets the active submission and scrolls to its index.
           *
           */
          if (
            this.isNavigationFromSummary ||
            this.submissionTypeSelected ===
              IntakeDocumentSubmissionTypeCode.CO ||
            this.isNavigatingFromDashboard ||
            this.queryParamSubmissionId
          ) {
            if (this.queryParamSubmissionId) {
              this.currentSubmissionId = this.queryParamSubmissionId;
            }
            const activeIndex = this.submissitionDetails.findIndex(
              (item: OpportunityDetailsNavModel) =>
                item.submissionId.toString() ===
                this.currentSubmissionId.toString()
            );
            this.setActive(activeIndex);
            this.scrollToActiveIndex();
          } else {
            this.updateRoleAndPermissions();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error in fetching the data', err);
          this.progressIndicator.hide();
        },
      });
  }

  getOpportunityDetails(id: string): void {
    this.progressIndicator.show();
    this.submissionService
      .getOpportunityDetailsInfo(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data) => {
          if (data) {
            this.clientNumber = data.clientNumber ?? IntakeConstant.EMPTY_SPACE;
            this.opportunityDetailTitle = data?.opportunityName;
            this.onFocusURL = data?.focusURL ?? IntakeConstant.EMPTY_SPACE;
            this.jupiterURL = data?.jupiterURL ?? IntakeConstant.EMPTY_SPACE;
            this.isOpportunityDetailsCompleted =
              data?.isOpportunityDetailsCompleted ?? false;
            this.progressIndicator.hide();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error in fetching the data', err);
          this.progressIndicator.hide();
        },
      });
  }

  scrollToActiveIndex(): void {
    if (this.activeIndex !== -1 && this.submissitionDetails[this.activeIndex]) {
      const elementId = `navItem-${this.submissitionDetails[
        this.activeIndex
      ].submissionId.toString()}`;
      const element = document.getElementById(elementId);
      if (element) {
        const leftMenu = document.getElementById('left-nav-items');
        if (leftMenu) {
          leftMenu.scrollTop = element.offsetTop - leftMenu.offsetTop;
        } else {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }

  checkRequired(requiredCheck: boolean, submissionId?: number): void {
    const activeSubmissionId =
      this.submissitionDetails[this.activeIndex]?.submissionId;
    if (requiredCheck) {
      if (
        !this.requiredCheckForIdList.includes(
          submissionId ?? activeSubmissionId
        )
      ) {
        this.requiredCheckForIdList.push(submissionId ?? activeSubmissionId);
      }
    } else {
      if (
        this.requiredCheckForIdList.includes(submissionId ?? activeSubmissionId)
      ) {
        this.requiredCheckForIdList = this.requiredCheckForIdList.filter(
          (item) => item !== (submissionId ?? activeSubmissionId)
        );
      }
    }
  }

  navigateToSummary(): void {
    this.progressIndicator.show();
    this.submissionService
      .updateDescDetails(this.opportunityId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: string) => {
          this.progressIndicator.hide();
          this.router.navigate(['/submission/opportunity-summary'], {
            queryParams: {
              id: this.opportunityId,
            },
          });
        },
      });
    this.progressIndicator.hide();
  }

  updateDocumentTitle(event: EmitUpdatedTitleModal): void {
    this.updateTitleDetails = event;
    if (event?.submissionId != null) {
      let findIndex = this.submissitionDetails.findIndex(
        (submissionDetail) =>
          submissionDetail.submissionId === event.submissionId
      );
      this.submissitionDetails[findIndex].submissionTitle = event.updatedTitle;
    }
  }

  showDocFiledRequiredIndicator(item: OpportunityDetailsNavModel): boolean {
    if (
      !this.requiredCheckForIdList.includes(item.submissionId) &&
      item?.submissionTypeCode !== this.intakeDocumentSubmissionTypeCodeELSOW
    ) {
      if (
        item.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
        this.submissitionDetails.length === 1
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  isOverAllRequired(): boolean {
    if (
      this.submissitionDetails.length === 0 ||
      commonSectionForSingleCoCheck(this.submissitionDetails)
    ) {
      return false;
    }

    const result =
      this.opportunityComplexQuestionStatus ||
      this.opportunityDetailStatus ||
      this.opportunityComplexCyberQuestionStatus;

    if (this.isOpportunityDetailsCompleted) {
      if (!result) {
        this.previousResult = result;
        this.isOpportunityDetailsCompleted = false;
      }
      return false;
    }

    if (result) {
      const parentDiv = document.querySelector(
        'div.dds-toast-container.bottom-center'
      );
      if (parentDiv) {
        const closingToastMessage = parentDiv?.querySelector(
          '.closing-toast-message-submission'
        );
        if (closingToastMessage) {
          parentDiv.remove();
        }
      }
    } else if (
      this.previousResult &&
      !result &&
      !this.isOpportunityDetailsCompleted
    ) {
      showCompletionToast(
        this.toastService,
        this.setActive.bind(this),
        this.submissitionDetails
      );
    }

    this.previousResult = result;

    return result;
  }

  backToHome(): void {
    this.router.navigate(['/']);
  }

  onLepValueChange(data: LepValue): void {
    this.lepValue = data;
  }

  loadRolesStatusActions(): void {
    this.commonService
      .getIntakeActions()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: IntakeAction[]) => {
          this.commonService.setIntakeActions(response);
        },
        error: (err) => {
          console.error('Error fetching intake actions', err);
        },
      });

    this.commonService
      .getOppertunityStatusCode()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[]) => {
          this.commonService.setIntakeOppertunityDocStatusCode(response);
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching intake staus codes', err);
        },
      });
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  onNavigateToJupiterURL(): void {
    window.open(this.jupiterURL, '_blank');
  }

  onNavigateToFocusURL(): void {
    window.open(this.onFocusURL, '_blank');
  }

  updatePermissions(): void {
    this.securityWebapiService
      .getPermissionsByEmployeeId(EntityDetails.None, EntityType.Submission)
      .then(() => {
        this.permissionCheck.updateIntakePermissions();
        this.permissionObj = this.permissionCheck.getIntakePermissionsObj;
      })
      .catch((error: HttpErrorResponse) => {
        console.error('Error fetching permissions:', error);
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.submissionService.updateCurrentSubmissionPageStatus(false);
  }
}
