import { Component, OnInit, ViewChild } from '@angular/core';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { ButtonKind, ExtThemes, Size, Themes, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { FormControl } from '@angular/forms';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { ClientSearchWatchlistResult, ClientWatchlistDto, WatchlistApiService, WatchlistEraseItems, WatchlistStatus, WatchlistStatusDropDown, WatchlistRequest } from '../../http/watchlist/watchlist-api.service';
import { MultiSelectOptions, SelectControlTypes, SelectType } from '@usitsdasdesign/dds-ng/multi-select';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { DropdownItemOptions, DropdownOptions } from '@usitsdasdesign/dds-ng/dropdown';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { EraseClientRecordComponent } from '../erase-client-record/erase-client-record.component'
import { Router } from '@angular/router';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';

import { environment } from '../../../environment/environment';

@Component({
  selector: 'app-watchlist-add',
  templateUrl: './watchlist-add.component.html',
  styleUrl: './watchlist-add.component.less'
})
export class WatchlistAddComponent implements OnInit {
  @ViewChild('dropdown', { static: false }) dropdown?: NgbDropdown;
  public searchKey: string = '';
  private searchKey$ = new Subject<string>();
  filteredItems: ClientSearchWatchlistResult[] = [];
  selectedItem?: ClientSearchWatchlistResult;

  searchFormCtrl = new FormControl({ value: '', disabled: false });

  public clientSearchItems: Array<any> = [];
  public enumWatchlistStatus = WatchlistStatus;
  public watchlistStatusSelectItems: Array<WatchlistStatusDropDown> = [];
  public isshowmorevisible: boolean = true;
  public isshowmoreparentvisible: boolean = true;
  public isshowmorevisiblePriorWatchlist: boolean = true;
  public clientSearchWatchlistmodel: ClientSearchWatchlistResult = {} as ClientSearchWatchlistResult;
  public WatchlistUpsertModel: WatchlistRequest = {} as WatchlistRequest;
  public WatchlistUpsertModelList: Array<WatchlistRequest> = [];
  public isEdit: boolean = false;
  public watchlistStatusId: number = 0;
  public clientWatchlistStatusComment: string = '';
  public isLoading: boolean = true;
  public isSubsidiaryBlockVisible = false;
  public clientWatchlistDto: ClientWatchlistDto[] = [];

  public subsidiaryClientList: any[] = [];
  public selectedSubsidiaries: any[] = [];
  public alreadyAddedSubsidiaryCount: number = 0;
  public addToWatchlistButtonText: string = 'Add to watchlist';
  public dropdownItems: DropdownItemOptions[] = [];
  public watchlistItemForErase: WatchlistEraseItems = {} as WatchlistEraseItems;
  public employeeId: string = '91100054';//TODO: Remove hard coded value for employeeId
  public showPreviousWatchlistStatus: boolean = false;
  public orgWatchlistStatusId: number = 0;
  public orgStatusComment: string = '';
  private securityAddAndEditButton : string = 'Watchlist.AddAndEditButton';
  public isEditEnabled: boolean = false;
  constructor(private watchlistwebapiservice: WatchlistApiService,
    private progressIndicator: ProgressIndicatorService,
    private modalService: ModalService,
    private router: Router,
    private securityWebapiService: SecurityWebapiService) { }
    rootUrl: string = environment.rootUrl;
  ngOnInit(): void {

    this.dropdownItems = [
      {
        heading: 'Erase client record',
        value: 'Eraseclientrecord',
        disabled: false
      }
    ];

    let navigation = window.history.state;
    if (navigation && navigation.clientNumber) {
      this.editWatchlist(navigation.clientNumber, navigation.isParentClient);
      // Clear the state after using it
      window.history.replaceState({}, document.title);
    }


    this.securityWebapiService.getPermissions('');

    this.securityWebapiService.data.subscribe((rules:any) => {    
      if (!rules.empty) {
        this.isEditEnabled = (rules[this.securityAddAndEditButton] && rules[this.securityAddAndEditButton].Visible);        
      }
    });
  }

  backToHome() {
    this.router.navigate(['/watchlist']);
  }
  public clientSearch() {
    if (this.searchKey && this.searchKey.length > 2) {
      this.searchKey$.next(this.searchKey.trim());
    }
    else {
      this.filteredItems = [];
    }
  }

  selectItem(item: any) {
    this.searchKey = '';
    this.searchFormCtrl.setValue(this.searchKey, { emitEvent: false });
    this.filteredItems = [];
    this.selectedItem = item;
    this.clientSearchWatchlistmodel = item;
    this.watchlistStatusSelectItems = this.clientSearchWatchlistmodel.watchlistStatusList.map(status => ({
      value: status.value,
      heading: status.heading
    }));
    if (this.clientSearchWatchlistmodel.isParentClient) {
      if (this.clientSearchWatchlistmodel.clientNumber && !this.clientSearchWatchlistmodel.watchlistStatus) {
        this.getSubsidiaryDataByClientnumber();
      }
    }
  }

  public getSubsidiaryDataByClientnumber() {
    this.isSubsidiaryBlockVisible = false;
    if (this.clientSearchWatchlistmodel.isParentClient == false) { return; }
    this.progressIndicator.show();
    this.watchlistwebapiservice.getWatchlistdataByClientnumber(this.clientSearchWatchlistmodel.clientNumber ?? '', this.clientSearchWatchlistmodel.isParentClient).subscribe(
      {
        next: (result: ClientWatchlistDto[]) => {
          if (result) {
            this.clientWatchlistDto = result;
            if (this.clientWatchlistDto.length > 0) {
              this.isSubsidiaryBlockVisible = true;
            }
            //Check if any subsidiary already added to watchlist
            this.alreadyAddedSubsidiaryCount = this.clientWatchlistDto.filter(client => client.watchlistId !== null).length;

            // Create subsidiary client list for multiselect dropdown
            this.subsidiaryClientList = this.clientWatchlistDto.map((client: ClientWatchlistDto) => {
              return {
                value: client.clientNumber,
                label: `${client.clientName} (ID: ${client.clientNumber})`,
                clientId: client.clientId,
                clientNumber: client.clientNumber,
                watchlistStatuses: JSON.parse(client.watchlistStatuses).map((status: WatchlistStatusDropDown) => ({
                  value: status.value,
                  heading: status.heading
                })), // Add property for client status
                statusComment: client.statusComment, // Add property for status comment
                isNCANeverEvaluated: client.isNCANeverEvaluated,
                watchlistStatusId: client.watchlistStatusId,
                watchlistId: client.watchlistId,
                clientName: client.clientName,
                prevWatchlistStatusId: client.prevWatchlistStatusId,
                prevWatchlistStatus: client.prevWatchlistStatus,
                prevStatusComment: client.prevStatusComment,
                disabled: client.watchlistId !== null
              };
            });

            // Create selected subsidiary list for multiselect dropdown
            this.selectedSubsidiaries = this.clientWatchlistDto
              .filter(client => client.watchlistId)
              .map(client => ({
                value: true,
                label: `${client.clientName} (ID: ${client.clientNumber})`,
                clientId: client.clientId,
                clientNumber: client.clientNumber,
                watchlistStatuses: JSON.parse(client.watchlistStatuses).map((status: WatchlistStatusDropDown) => ({
                  value: status.value,
                  heading: status.heading
                })), // Add property for client status
                statusComment: client.statusComment, // Add property for status comment
                isNCANeverEvaluated: client.isNCANeverEvaluated,
                watchlistStatusId: client.watchlistStatusId,
                disabled: true,
                watchlistId: client.watchlistId,
                clientName: client.clientName,
                prevWatchlistStatusId: client.prevWatchlistStatusId,
                prevWatchlistStatus: client.prevWatchlistStatus,
                prevStatusComment: client.prevStatusComment,
                isPreviousStatusVisible: false,
                orgWatchlistStatusId: client.watchlistStatusId,
                orgStatusComment: client.statusComment
              }));
            if (this.alreadyAddedSubsidiaryCount > 0)
              this.multiSelectOptions.displayTickAllBtn = false;
            else
              this.multiSelectOptions.displayTickAllBtn = true;
            this.progressIndicator.hide();
          }
        },
        error: (err: any) => {
        }
      })
  }

  public inputClick() {
    this.searchKey$.pipe(
      debounceTime(500),
      filter(key => !!key && key.length > 2),
      switchMap(key => this.searchItems(key))
    ).subscribe({
      next: (response: any) => {
        this.filteredItems = response;
        this.isLoading = false;
      },
      error: (err) => {
        this.filteredItems = [];
      }
    });

    this.searchKey$.next(this.searchKey);
    if (this.dropdown && !this.dropdown.isOpen() && this.searchKey.length > 2) {
      this.dropdown.open();
    }
  }

  private searchItems(key: string) {
    this.clientSearchItems.length = 0;
    if (this.dropdown && !this.dropdown.isOpen()) {
      this.dropdown.open();
    }
    return this.watchlistwebapiservice.searchClients(key);
  }

  public clearSearch() {
    this.searchKey = '';
    this.searchKey$.next(this.searchKey);
    this.filteredItems = [];
    this.selectedItem = undefined;
    if (this.dropdown && this.dropdown.isOpen()) {
      this.dropdown.close();
    }
    this.clearFormValues();
  }


  isFormValid(): boolean {
    if (!this.clientSearchWatchlistmodel.clientName || !this.watchlistStatusId || !this.clientWatchlistStatusComment) {
      return false;
    }

    for (let subsidiary of this.selectedSubsidiaries) {
      if (!subsidiary.watchlistStatusId || !subsidiary.statusComment) {
        return false;
      }
    }
    return true;
  }

  upsertWatchlistData() {
    this.WatchlistUpsertModelList = [];
    this.progressIndicator.show();
    if (this.watchlistStatusId != this.orgWatchlistStatusId || this.clientWatchlistStatusComment?.trim() != this.orgStatusComment?.trim()) {
      this.WatchlistUpsertModel.clientNumber = this.clientSearchWatchlistmodel.clientNumber;
      this.WatchlistUpsertModel.watchlistStatusId = this.watchlistStatusId;
      this.WatchlistUpsertModel.statusComment = this.clientWatchlistStatusComment?.trim();
      this.WatchlistUpsertModel.isParentClient = this.clientSearchWatchlistmodel.isParentClient;
      this.WatchlistUpsertModel.isActive = true;
      this.WatchlistUpsertModel.employeeId = this.employeeId;

      // Insert WatchlistUpsertModel into WatchlistUpsertModelList
      this.WatchlistUpsertModelList.push({ ...this.WatchlistUpsertModel });
    }

    this.selectedSubsidiaries.forEach(subsidiary => {
      if (subsidiary.watchlistStatusId != subsidiary.orgWatchlistStatusId || subsidiary.statusComment?.trim() != subsidiary.orgStatusComment?.trim()) {
        const upsertModel: WatchlistRequest = {
          clientNumber: subsidiary.clientNumber,
          watchlistStatusId: subsidiary.watchlistStatusId,
          statusComment: subsidiary.statusComment,
          isParentClient: false, // Assuming subsidiaries are not parent clients
          isActive: true,
          employeeId: this.employeeId
        };
        this.WatchlistUpsertModelList.push(upsertModel);
      }
    });
    if (this.WatchlistUpsertModelList.length > 0) {
      return this.watchlistwebapiservice.upsertWatchlistData(this.WatchlistUpsertModelList).toPromise().then((result) => {
        if (result.isSuccess) {
          this.cancel();
        }
        this.progressIndicator.hide();
      });
    }
    else {
      this.progressIndicator.hide();
      this.cancel();
      return;
    }
  }

  cancel() {
    this.clearSearch()
    this.clearFormValues();
    this.addToWatchlistButtonText = 'Add to watchlist';
  }

  clearFormValues() {
    this.watchlistStatusId = 0;
    this.clientWatchlistStatusComment = '';
    this.clientSearchWatchlistmodel = {} as ClientSearchWatchlistResult;
    this.clientWatchlistDto = [];
    this.subsidiaryClientList = [];
    this.selectedSubsidiaries = [];
    this.isSubsidiaryBlockVisible = false;
    this.alreadyAddedSubsidiaryCount = 0;
    this.addToWatchlistButtonText = 'Add to watchlist';
    this.showPreviousWatchlistStatus = false;
    this.orgWatchlistStatusId = 0;
    this.orgStatusComment = '';
    this.isEdit = false;
  }
  /* Remove from row from subsidiary Client list */
  removeSubsidiary(clientNumber: string) {
    this.selectedSubsidiaries = this.selectedSubsidiaries.filter(subsidiary => subsidiary.clientNumber !== clientNumber);
  }

  //Apply watchlist status to all subsidiaries
  applyWatchlistStatusToSubsidiaries() {
    this.selectedSubsidiaries.forEach(subsidiary => {
      subsidiary.watchlistStatusId = this.watchlistStatusId;
      this.onSubsidiaryStatusChange(subsidiary);
    });
  }

  //Apply watchlist status comment to all subsidiaries
  applyStatusCommentToSubsidiaries() {

    this.selectedSubsidiaries.forEach(subsidiary => {
      subsidiary.statusComment = this.clientWatchlistStatusComment;
    });
  }

  editWatchlist(clientNumber: string, isParentClient: boolean) {
    this.clearFormValues();
    this.isEdit = true;
    this.progressIndicator.show();
    this.watchlistwebapiservice.searchClients(clientNumber).subscribe(
      (result: ClientSearchWatchlistResult[]) => {
        if (result && result.length > 0) {
          this.clientSearchWatchlistmodel = result[0];
          this.orgWatchlistStatusId = this.clientSearchWatchlistmodel.watchlistStatusId ?? 0;
          this.orgStatusComment = this.clientSearchWatchlistmodel.statusComment ?? '';
          if (this.watchlistStatusSelectItems.length == 0) {
            this.watchlistStatusSelectItems = this.clientSearchWatchlistmodel.watchlistStatusList.map(status => ({
              value: status.value,
              heading: status.heading
            }));
          }
          this.watchlistStatusId = this.clientSearchWatchlistmodel.watchlistStatusId ?? 0;
          this.clientWatchlistStatusComment = this.clientSearchWatchlistmodel.statusComment ?? '';
          this.getSubsidiaryDataByClientnumber();
          this.addToWatchlistButtonText = 'Save';
          this.progressIndicator.hide();
        }
      }
    );
  }
  changeClientWatchlistStatus() {
    if (this.watchlistStatusId === this.enumWatchlistStatus.Removed) {
      this.clientWatchlistStatusComment = '';
    }
  }
  //Apply all Subsidiary status changed for client
  onSubsidiaryStatusChange(client: any) {
    if (client.watchlistStatusId === this.enumWatchlistStatus.Removed) {
      client.statusComment = '';
    }
  }

  goToAuditTrail(clientName: string, clientNumber: string, watchlistId: number | undefined) {
    const url = this.rootUrl + 'watchlist/audit-trail/watchlistId/' + watchlistId;

    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.addEventListener = () => {
        newTab.history.replaceState({ clientNumber, clientName }, '');
      };
    }
  }

  eraseClientWatchlist() {
    let watchlistEraseList: Array<WatchlistEraseItems> = [];
    this.watchlistItemForErase.watchlistId = this.clientSearchWatchlistmodel.watchlistId;
    this.watchlistItemForErase.clientName = this.clientSearchWatchlistmodel.clientName;
    this.watchlistItemForErase.clientNumber = this.clientSearchWatchlistmodel.clientNumber;
    this.watchlistItemForErase.isParentClient = this.clientSearchWatchlistmodel.isParentClient;
    this.watchlistItemForErase.shortOrder = 1;

    watchlistEraseList.push({ ...this.watchlistItemForErase });

    if (this.clientSearchWatchlistmodel.isParentClient == true) {
      // Filter objects with watchlistId > 0 and add them to watchlistEraseList
      this.clientWatchlistDto
        .filter(client => client.watchlistId ?? 0 > 0)
        .forEach(client => {
          watchlistEraseList.push({
            watchlistId: client.watchlistId ?? 0,
            clientName: client.clientName,
            clientNumber: client.clientNumber,
            isParentClient: false,
            shortOrder: 2
          });
        });
    }
    let modalRef = this.modalService.open(EraseClientRecordComponent);
    modalRef.componentInstance.labelValues = {
      labelHeader: 'Clear client record(s) from watchlist',
      labeldisclaimer: 'This action will totally erase the information about the clients from watchlist, and this can\'t be restored',
      watchlistEraseList: watchlistEraseList
    };
  }

  clickclient(event: Event): void {
    this.isshowmorevisible = false;
  }

  clickclientshowmore(event: Event): void {
    this.isshowmorevisible = true;
  }

  clickparentclient(event: Event): void {
    this.isshowmoreparentvisible = false;
  }

  clickparentclientshowmore(event: Event): void {
    this.isshowmoreparentvisible = true;
  }

  clickpriorWatchlist(event: Event): void {
    this.isshowmorevisiblePriorWatchlist = false;
  }

  clickpriorWatchlistshowmore(event: Event): void {
    this.isshowmorevisiblePriorWatchlist = true;
  }

  onShowPreviousWatchlistStatus() {
    this.showPreviousWatchlistStatus = !this.showPreviousWatchlistStatus;
  }

  onShowPreviousWatchlistSubsidiaryStatus(client: ClientWatchlistDto) {
    client.isPreviousStatusShow = !client.isPreviousStatusShow;
  }

  addToWatchlistButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primaryLoud,
    size: Size.lg,
    width: WidthState.fixed,
    disabled: false,
    role: 'button'
  }

  cancelButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: WidthState.fixed,
    ariaLabel: 'Cancel',
    customClass: '',
    role: 'button'
  }

  editButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.md,
    width: WidthState.fixed,
    ariaLabel: 'Cancel',
    customClass: '',
    role: 'button'
  }

  searchOptions: SearchOptions = {
    placeholder: 'Search by name or ID',
    size: Size.md,
    customClass: '',
  };

  clientStatusSelectOptions: SelectOptions = {
    placeholder: 'Select status',
    size: Size.md,

    disabled: false
  };

  textareaOptions: TextareaOptions = {
    placeholder: 'Enter text',
    minHeight: 80,
    maxLength: 4000,
    size: Size.md,
    maxLengthPosition: 'bottom-right',
  };

  optionsShowMore: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.sm,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_arrow-down',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: 'Show Less',
    customClass: '',
    role: 'button'
  }

  options: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.sm,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_arrow-up',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: 'Show Less',
    customClass: '',
    role: 'button'
  }


  multiSelectOptions: MultiSelectOptions = {
    displayTickAllBtn: true,
    label: 'Subsidiary clients',
    placeholder: 'Select Client',
    type: SelectType.counter,
    controlType: SelectControlTypes.checkbox,
    theme: 'green',
    customClass: '',
    disabled: false
  };

  clientTooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 5,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
  };

  dropdownOptions: DropdownOptions = {
    label: '',
    ariaLabel: '',
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: WidthState.fixed,
    icon: 'dds-icon_dots-v',
    disabled: false,
    stickerWidth: 155,
  };
}
