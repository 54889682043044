import { Pipe, PipeTransform } from '@angular/core';
import { StatusEnum } from '../constants/status-enum';

@Pipe({
  name: 'statusStyle',
})
export class StatusStylePipeForExtComm implements PipeTransform {
  private statusStylesMap: {
    [key in StatusEnum]?: { color: string; backgroundColor: string };
  } = {
    [StatusEnum.Pending]: { color: '#FFA500', backgroundColor: '#fff4e6' },
    [StatusEnum.RMReviewed]: { color: '#008000', backgroundColor: '#e6ffe6' },
    [StatusEnum.Draft]: { color: '#63666A', backgroundColor: '#D0D0CE' },
    [StatusEnum.Submitted]: { color: '#007CB0', backgroundColor: '#EEF9FF' },
    [StatusEnum.RESUBMITTED]: { color: '#007CB0', backgroundColor: '#EEF9FF' },
    [StatusEnum.RMReviewedPendingCloseout]: {
      color: '#26890d',
      backgroundColor: '#f0f9ee',
    },
    [StatusEnum.SELF_REVIEWED_PENDING_CLOSEOUT]: {
      color: '#26890d',
      backgroundColor: '#f0f9ee',
    },
    [StatusEnum.DISCONTINUED]: { color: '#FFFFFF', backgroundColor: '#000000' },
    [StatusEnum.COMPLETED_ADMIN]: {
      color: '#000000',
      backgroundColor: '#F2F2F2',
    },
    [StatusEnum.COMPLETED]: { color: '#000000', backgroundColor: '#F2F2F2' },
    [StatusEnum.RETURN_FOR_REWORK]: {
      color: '#DA291C',
      backgroundColor: '#FEEDEB',
    },
  };

  transform(
    status: StatusEnum | undefined,
    styleType: 'color' | 'backgroundColor'
  ): string | undefined {
    if (status) {
      return (
        this.statusStylesMap[status]?.[styleType] ||
        (styleType === 'color' ? '#000000' : 'transparent')
      );
    } else {
      return '';
    }
  }
}
