<div class="dds-modal-overlay" (click)="close(false)"></div>

<div ddsResize class="dds-modal dds-modal_lg">
  <div class="dds-modal__header eclipse-header">Document Selection</div>

  <div class="dds-modal__body">
    <div>
      <div class="comments-text-label eclipse-small-header-grey">
        <p>
          Select the documents reviewed. The document status will change to RM -
          Reviewed.
        </p>
      </div>
      <div>
        <table class="dds-data-table dds-data-table_selection">
          <thead class="dds-data-table__header">
            <tr>
              <th class="dds-data-table__header-cell">
                <dds-checkbox
                  theme="green"
                  [value]="isAllSelected"
                  ariaLabel="select all rows"
                  (stateChanged)="selectAllRows($event, docToShow)"
                ></dds-checkbox>
              </th>
              <ng-container *ngIf="sampleColumns.length > 0">
                <th
                  *ngFor="let column of sampleColumns"
                  class="dds-data-table__header-cell"
                  [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
                >
                  <div class="dds-data-table__content">
                    <span>{{ column.header || column.name }}</span>
                  </div>
                </th>
              </ng-container>
            </tr>
          </thead>

          <tbody class="dds-data-table__body">
            <ng-container *ngIf="docToShow.length > 0">
              <tr
                *ngFor="let row of docToShow; index as index"
                class="dds-data-table__row"
                [ngClass]="{ 'dds-data-table__row_selected': row.selected }"
                (click)="toggleRow(row)"
              >
                <td class="dds-data-table__cell">
                  <dds-checkbox
                    [ariaLabel]="'row ' + (index + 1)"
                    [value]="row.selected"
                    theme="green"
                  ></dds-checkbox>
                </td>
                <td class="dds-data-table__cell">
                  {{ row.documentTitle }}
                </td>
                <td class="dds-data-table__cell">
                  {{ row.documentStatus }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="d-flex flex-right margin-top-s">
        <div class="margin-right-s">
          <dds-button
            class="custom-button"
            (click)="submitSelectedDoc()"
            [disabled]="selectedRowCount === 0"
            >{{ prescreen ? "Prescreen" : "Review" }}</dds-button
          >
        </div>
        <div>
          <dds-button
            class="custom-button"
            [kind]="cancelButton.kind ?? _blank"
            (click)="close(false)"
            >Cancel</dds-button
          >
        </div>
      </div>
    </div>
  </div>
</div>
