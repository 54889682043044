
export class BrowserTabConstant {
  public static readonly Browser_TabName_Dashboard: string = 'Relay - Homepage';
  public static readonly Browser_TabName_Client: string = 'Relay - Client';
  public static readonly Browser_TabName_NCASummary: string = 'Relay - NCA Summary';
  public static readonly Browser_TabName_Watchlist: string = 'Relay - Watchlist';
  public static readonly Browser_TabName_TPA: string = 'Relay - Third party agreements';
  public static readonly Browser_TabName_Admin: string = 'Relay - Admin';
  public static readonly Browser_TabName_AuditTrail: string = 'Relay - Audit trail';
  public static readonly Browser_TabName_Opportunity: string = 'Relay - Opportunity';
  public static readonly Browser_TabName_Default: string = 'Relay - ';
  public static readonly Browser_TabName_Submissions: string = 'Relay - Submissions';
  public static readonly Browser_TabName_ExternalCommunications: string = 'Relay - External Communications';
  public static readonly Browser_TabName_GlobalEngagements: string = 'Relay - Global engagements';
  public static readonly Browser_TabName_Initiation: string = 'Relay - Initiation';
}
