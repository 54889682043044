import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environment/environment';
import { Observable } from 'rxjs';
import {
  DashboardDataCollection,
  DashboardGridLevelFilter,
} from '../../common/models/start-page/start-page.model';
import {
  RecentlyViewedSubmissionList,
  ReviewerDashboardData,
  ReviewerDashboardTilesCount,
  ReviewerTileCode,
} from '../../common/models/start-page/reviewer-dashboard.model';
import { SubmissionType } from '../../common/models/common-models';
import { UrlConstant } from '../../intake/constants/url.constant';
import { DashboardExportData } from '../../common/models/start-page/riskmanager-dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class ReviewerDashboardWebapiService {
  constructor(private httpService: HttpClient) {}

  apiUrl: string = environment.apiUrl;
  // apiUrl: string = 'https://dapi.eclipse.deloitte.com/api/';
  // apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa

  getReviewerDashBoardGridData(
    tileCode: string,
    tabCode: string,
    pageNumber: number,
    pageSize: number,
    sortParameterCode: number | null,
    isAscending: boolean,
    filterData: DashboardGridLevelFilter[]
  ): Observable<DashboardDataCollection<ReviewerDashboardData>> {
    let params = new HttpParams()
      .set('tileCode', tileCode)
      .set('pageNumber', pageNumber?.toString())
      .set('pageSize', pageSize?.toString())
      .set('isAscending', isAscending?.toString());
    if (sortParameterCode !== null) {
      params = params.set('sortParameterCode', sortParameterCode?.toString());
    }
    if (tileCode === ReviewerTileCode.ExtComm) {
      params = params.set('subtileCode', tabCode);
    }
    return this.httpService.post<
      DashboardDataCollection<ReviewerDashboardData>
    >(
      this.apiUrl + 'ReviewerDashboard/GetReviewerDashBoardGridData',
      filterData,
      { params }
    );
  }

  getSubmissionTypes(): Observable<SubmissionType[]> {
    return this.httpService.get<SubmissionType[]>(
      this.apiUrl + UrlConstant.GET_SUBMISSION_TYPES_API
    );
  }

  getReviewerDashboardTilesCount(): Observable<ReviewerDashboardTilesCount> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<ReviewerDashboardTilesCount>(
      this.apiUrl + 'ReviewerDashboard/GetReviewerDashboardTilesCount',
      { headers }
    );
  }

  getRecentlyViewedSubmissions(
    pageNumber: number,
    pageSize: number
  ): Observable<RecentlyViewedSubmissionList> {
    return this.httpService.get<RecentlyViewedSubmissionList>(
      this.apiUrl +
        'ReviewerDashboard/GetRecentlyViewedSubmissions?pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize
    );
  }

  exportReviewerExtCommTileData(
    tabCode: string
  ): Observable<DashboardExportData> {
    const endPoint: string = `ReviewerDashboard/ExportExternalCommReviewerData?tileCode=${tabCode}`;
    return this.httpService.get<DashboardExportData>(
      `${this.apiUrl}${endPoint}`
    );
  }
}
