import { TextareaOptions } from "@usitsdasdesign/dds-ng/textarea";
import { ExternalCommunicationConstant } from "../../../../constants/external_communication.constant";
import { ButtonOptions } from "@usitsdasdesign/dds-ng/button";
import { ButtonKind, ExtThemes, WidthState } from "@usitsdasdesign/dds-ng/shared";

export const textareaOptions: TextareaOptions = {
    placeholder: '',
    minHeight: 150,
    maxLength: 1000,
    size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
    maxLengthPosition: 'bottom-right',
  };
  export const SecondaryButton: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.secondary,
    size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
    width: WidthState.fixed,
    isLoading: false,
    icon: '',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: true,
    ariaLabel: 'Button',
    customClass: '',
    role: 'button',
  };