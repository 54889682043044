<div id="nca-tiles-container" class="tile-row" *ngIf="tilesCount">
  <div class="row flex-container">
    <div class="col-3" *ngFor="let tile of tileCodeDetails; let i = index" [hidden]="i >= 4">
      <div id="{{tile.tileCode}}"
           class="dds-card ds-card_has-image dds-card_clickable {{tile.tileCode}}"
           (click)="tileSelected(tile.tileCode)"
           [ngClass]="{'active': selectedTileCode === tile.tileCode}">
        <div class="dds-card__content">
          <h1 class="dds-card__title">{{tilesCount[tile.countKey] ?? 0}}</h1>
          <p class="dds-card__text">{{tile.title}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row flex-container" *ngIf="tileCodeDetails.length > 3">
    <div class="col-3" *ngFor="let tile of tileCodeDetails; let i = index" [hidden]="i < 4">
      <div id="{{tile.tileCode}}"
           class="dds-card ds-card_has-image dds-card_clickable {{tile.tileCode}}"
           (click)="tileSelected(tile.tileCode)"
           [ngClass]="{'active': selectedTileCode === tile.tileCode}">
        <div class="dds-card__content">
          <h1 class="dds-card__title">{{tilesCount[tile.countKey] ?? 0}}</h1>
          <p class="dds-card__text">{{tile.title}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
