import {
  ButtonKind,
  Column,
  ExtThemes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';

export const riskManagerTableHeaderData = [
  {
    name: 'name',
    header: 'Role/Name',
    dataType: 'string',
    minWidth: '8.125rem',
  },
];

const COMMON_BTN_OPTIONS: any = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: true,
  customClass: '',
  role: 'button',
};

export const ADD_REVIEWER_BTN_OPTIONS: ButtonOptions = {
  ...COMMON_BTN_OPTIONS,
  kind: ButtonKind.silent,
  customClass: '',
  icon: 'dds-icon_plus',
  isIconLeft: false,
  ariaLabel: ExternalCommunicationConstant.ADD_REVIEWER_BTN_LABEL,
};
