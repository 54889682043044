<div class="dds-modal-overlay" (click)="closeSelfReviewPopup()"></div>

<div ddsResize class="dds-modal dds-modal_lg">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Self-review</span>
    <dds-button
      size="md"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      (clicked)="closeSelfReviewPopup()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div>
      <div class="text-cool-gray">
        <div class="relative sub-text-section margin-top-xs d-flex">
          <span class="inline-block relative icon-s icon-pinned"></span>
          <span class="padding-left-m">
              Submission is self-review eligible and will not be reviewed by a
              Risk Manager.Reach out to the External Communication Mailbox if you have any questions.
          </span>
          <span> <div class="vertical-line-green absolute"></div></span>
          <div class="padding-left-m">
            <p>
              Self-review eligible submissions are expected to contain accurate
              responses to the questions. In preparation for the Responsible
              Practitioner confirmation, the applicable Deloitte Policies (e.g.,
              <a
                href="https://researchportal.deloitte.com/us/en-US/auditing/manual/U.S.%20Deloitte%20Policies%20Manual/GUID-E0AC41D5-8121-4347-9D95-6A4E04C89AE1/10640-1%20%e2%80%94%20EXTERNAL%20COMMUNICATIONS%20%e2%80%94%20BUSINESS%20COMMUNICATIONS%2c%20INCLUDING%20ACTIVITIES%20WITH%20AN%20EXTERNAL%20BUSINESS%20COMMUNICATION%20COMPONENT/GUID-9283EC82-CAB6-45B8-B61A-276DCCFB733D/GUID-9283EC82-CAB6-45B8-B61A-276DCCFB733D?searchPhrase=DPM10640-1"
                class="text-underline text-green-2"
                target="_blank"
                >DPM10640-1</a
              >
              External Communications Business Communications;
              <a
                href="https://researchportal.deloitte.com/us/en-US/auditing/manual/U.S.%20Deloitte%20Policies%20Manual/GUID-E0AC41D5-8121-4347-9D95-6A4E04C89AE1/10640-2%20%e2%80%94%20External%20Communications%20%e2%80%94%20Personal%20Communications/GUID-05362E3C-9EBF-4AA7-B52D-78AB35719219/GUID-05362E3C-9EBF-4AA7-B52D-78AB35719219#2_320559"
                class="text-underline text-green-2"
                target="_blank"
                >DPM10640-2</a
              >
              External Communications Personal Communications;<a
                href="https://researchportal.deloitte.com/us/en-US/auditing/manual/U.S.%20Deloitte%20Policies%20Manual/GUID-E0AC41D5-8121-4347-9D95-6A4E04C89AE1/10644%20%e2%80%94%20Conduct%20of%20Surveys/GUID-1B4C4CE5-2F0F-4B70-8E2D-594BBD4EDB21/GUID-1B4C4CE5-2F0F-4B70-8E2D-594BBD4EDB21#2_320560"
                class="text-underline text-green-2"
                target="_blank"
              >
                DPM10644
              </a>
              Conduct of Surveys) should be read and understood.
            </p>
            <p>
              The final version of the document(s) should be uploaded before
              proceeding.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="custom-button"
        [ariaLabel]="'Submit'"
        class="dds-modal__footer-item"
        (clicked)="selfReviewSubmitHandler()"
        (keyboardClicked)="selfReviewSubmitHandler()"
        >Submit</dds-button
      >
      <dds-button
        class="custom-button"
        [ariaLabel]="'Close'"
        class="dds-modal__footer-item"
        kind="secondaryLoud"
        (clicked)="closeSelfReviewPopup()"
        (keyboardClicked)="closeSelfReviewPopup()"
        >Cancel</dds-button
      >
    </div>
  </div>
</div>
