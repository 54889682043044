import {
  ExtThemes,
  Size,
  WidthState,
  ButtonKind,
} from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
export const MANAGE_TEAM_MEMBERS_BTN_OPTIONS: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primary,
  size: Size.sm,
  width: WidthState.fixed,
  isLoading: false,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  customClass: '',
  role: 'button',
};
