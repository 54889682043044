<div class="dds-modal-overlay" (click)="closeModal()"></div>

<div
  ddsResize
  [ngClass]="{
    'dds-modal_lg': size === 'lg',
    'dds-modal_inverse': isInverse
  }"
  class="dds-modal"
>
  <div class="dds-modal__header">
    <span class="dds-modal__title">Attach document(s)</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="closeModal()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <dds-uploader
      class="dds-uploader"
      [heading]="uploadAreaOptions.heading ?? ''"
      [description]="uploadAreaOptions.description ?? ''"
      [dragAndDropText]="uploadAreaOptions.dragAndDropText ?? ''"
      [isDragAndDrop]="uploadAreaOptions.isDragAndDrop ?? false"
      [isMultiple]="uploadAreaOptions.isMultiple ?? false"
      [acceptFormats]="uploadAreaOptions.acceptFormats ?? ''"
      [acceptExtensions]="uploadAreaOptions.acceptExtensions ?? ''"
      [exceptSymbols]="uploadAreaOptions.exceptSymbols ?? ''"
      [btnLabel]="uploadAreaOptions.btnLabel ?? ''"
      [isInverse]="uploadAreaOptions.isInverse ?? false"
      [disabled]="uploadAreaOptions.disabled ?? true"
      [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind ?? ''"
      (uploadedItems)="uploadedItems($event)"
    ></dds-uploader>
    <!-- Table logic -->
    <div class="div-headers">
      <div class="row">
        <div class="col-md-3 column" *ngFor="let column of DocumentColumns">
          <span class="div-headers-style">{{ column.header }}</span>
        </div>
      </div>
      <div class="div-whole-data">
        <span *ngIf="isUploadingInProgress">
          <div
            class="row div-data"
            *ngFor="let row of documentUploadInProgressData"
          >
            <div class="col-md-2 column">
              <span class="div-data-style data-alignment">{{
                row.fileName
              }}</span>
            </div>
            <div class="col-md-3 column">
              <dds-progress
                class="data-alignment"
                [isInverse]="options.isInverse ?? false"
                [description]="options.description ?? ''"
                [isVertical]="options.isVertical ?? false"
                [label]="options.label ?? ''"
                [size]="options.size ?? ''"
                [theme]="options.theme ?? ''"
                [value]="fileUploadPercentage ?? ''"
              ></dds-progress>
            </div>
            <div class="col-md-5 column">
              <span class="uploading-color margin">Uploading in progress</span>
            </div>
            <div class="col-md-1 column">
              <dds-button
                size="sm"
                icon="dds-icon_close"
                kind="silent"
                theme="dark"
                ariaLabel="Close modal"
                class="dds-modal__close"
                [isInverse]="isInverse"
                (clicked)="removeDocument(row)"
              ></dds-button>
            </div>
          </div>
        </span>

        <span>
          <div class="row div-data mandatory-margin" *ngFor="let row of documentUploadData">
            <div class="title-edit">
              <span
                *ngIf="!row.isFileNameInEditMode"
                class="div-data-style edit-icon-alignment"
                >{{ row.documentTitle }}</span
              >
              <span
                *ngIf="!row.isFileNameInEditMode"
                class="dds-card__image-block edit-style"
                (click)="onFileNameEdit(row)"
              ></span>
              <dds-input
                *ngIf="row.isFileNameInEditMode"
                [placeholder]="textFileOptions.placeholder ?? ''"
                [maxLengthPosition]="textFileOptions.maxLengthPosition ?? ''"
                [size]="textFileOptions.size ?? ''"
                [(ngModel)]="row.documentTitle"
              >
              </dds-input>
            </div>

            <div class="column col-md-3">
              <div class="mandatory-margin">
                <span
              *ngIf="row.documentTypeId === 0"
              class="icon-required-yellow"
            ></span>
              <dds-select
                [isResponsive]="selectDocumentTypeOptions.isResponsive ?? false"
                [isRequired]="selectDocumentTypeOptions.isRequired ?? false"
                [isInverse]="selectDocumentTypeOptions.isInverse ?? false"
                [list]="documentTypeItemsList"
                [label]="selectDocumentTypeOptions.label ?? ''"
                [labelPosition]="selectDocumentTypeOptions.labelPosition ?? ''"
                [customClass]="selectDocumentTypeOptions.customClass ?? ''"
                [placeholder]="selectDocumentTypeOptions.placeholder ?? ''"
                [size]="selectDocumentTypeOptions.size ?? ''"
                [stickerWidth]="selectDocumentTypeOptions.stickerWidth ?? ''"
                [(ngModel)]="row.documentTypeId"
                (ngModelChange)="updateStatusDropdown(row)"
              >
              </dds-select>
            </div>
            </div>
            <div class="column col-md-3">
              <div class="mandatory-margin">
              <span
                *ngIf="row.documentStatusId == 0"
                class="icon-required-yellow col-md-1"
              ></span>
                <dds-select
                  [isResponsive]="selectOptionsStatus.isResponsive ?? false"
                  [isRequired]="selectOptionsStatus.isRequired ?? false"
                  [isInverse]="selectOptionsStatus.isInverse ?? false"
                  [list]="documentStatusItemList"
                  [label]="selectOptionsStatus.label ?? ''"
                  [labelPosition]="selectOptionsStatus.labelPosition ?? ''"
                  [customClass]="selectOptionsStatus.customClass ?? ''"
                  [placeholder]="selectOptionsStatus.placeholder ?? ''"
                  [size]="selectOptionsStatus.size ?? ''"
                  [stickerWidth]="selectOptionsStatus.stickerWidth ?? ''"
                  [(ngModel)]="row.documentStatusId"
                >
                </dds-select>
              </div>
            </div>
            <div class="column col-md-3">
              <dds-input
                [placeholder]="textareaOptions.placeholder ?? ''"
                [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
                [size]="textareaOptions.size ?? ''"
                [(ngModel)]="row.comments"
              >
              </dds-input>
            </div>
            <div class="col-md-1 column">
              <dds-button
                size="sm"
                icon="dds-icon_close"
                kind="silent"
                theme="dark"
                ariaLabel="Close modal"
                class="dds-modal__close margin-top"
                [isInverse]="isInverse"
                (clicked)="removeDocument(row)"
              ></dds-button>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item"
        (clicked)="onSave()"
        (keyboardClicked)="onSave()"
        [disabled]="!isSaveButtonDisabled()"
        >Save</dds-button
      >
      <dds-button
        class="dds-modal__footer-item"
        kind="secondaryLoud"
        [isInverse]="isInverse"
        (clicked)="closeModal()"
        (keyboardClicked)="closeModal()"
        >Cancel</dds-button
      >
    </div>
    <div
      class="dds-modal__footer-content dds-modal__footer-content_right"
    ></div>
  </div>
</div>
