import { Component, OnInit } from '@angular/core';
import { ExternalCommunicationService } from '../../http/external-communication/external-communication.service';
import {
  ExtCommLeftNavModel,
  InitiateExternalCommunicationDropdownModel,
  UpdateSubmission,
} from '../../common/models/external-communication.model';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { StatusEnum } from '../constants/status-enum';
import { PermissionCheckService } from '../../common/validations/permission-check.service';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { PermissionsObj } from '../../common/models/common-models';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { HttpErrorResponse } from '@angular/common/http';
import { ExternalCommunicationConstant } from '../constants/external_communication.constant';
import { EclipseHeaderService } from '../../http/eclipse-header.service';

@Component({
  selector: 'app-external-communications',
  templateUrl: './external-communications.component.html',
  styleUrl: './external-communications.component.less',
})
export class ExternalCommunicationsComponent implements OnInit {
  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  communicationType: string = ExternalCommunicationConstant.EMPTY_SPACE;
  communicationTypeId: number = 0;
  activeIndex: number = 0;
  extCommBoxValue: string = 'EXT COMM';
  communicationTypeList: string[] = [];
  currentActiveSubmissionId: number = 0;
  communicationTypeListWithId: InitiateExternalCommunicationDropdownModel[] =
    [];
  submittedExternalCommunications: ExtCommLeftNavModel[] = [];
  requiredFieldsFilled: boolean = false;
  permissionObj: PermissionsObj = {} as PermissionsObj;

  constructor(
    private readonly externalCommService: ExternalCommunicationService,
    private readonly route: ActivatedRoute,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly securityWebapiService: SecurityWebapiService,
    private readonly permissionCheck: PermissionCheckService,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly headerService: EclipseHeaderService
  ) {}

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_ExternalCommunications;
    this.communicationTypeId = Number(this.route.snapshot.paramMap.get('id'));
    const submissionIdParam = Number(
      this.route.snapshot.queryParams['submissionId']
    );
    this.currentActiveSubmissionId = isNaN(submissionIdParam)
      ? 0
      : submissionIdParam;

    this.externalCommService.setCommunicationType(this.communicationTypeId); // If we directly land on page, need to update the id
    this.securityWebapiService.data
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe(() => {
        this.permissionCheck.updateExtCommPermissions();
        this.permissionObj = this.permissionCheck.getExtCommPermissionsObj;
        if (this.permissionObj['isExtCommAllFieldsVisible']) {
          this.getLeftNavDetails(this.communicationTypeId);
        }
      });
    this.getCommunicationType();
  }

  backToHome() {
    this.router.navigate(['/']);
  }
  getCommunicationType(): void {
    this.progressIndicator.show();
    this.externalCommService
      .getSubmissionTypesAsPerLoggedinUser()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: InitiateExternalCommunicationDropdownModel[]) => {
          this.communicationTypeListWithId = response;
          this.communicationTypeList = response.map(
            (item: InitiateExternalCommunicationDropdownModel) =>
              item.communicationTypeName
          );
          this.setCommunicationTypeById(this.communicationTypeId);
          this.progressIndicator.hide();
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  setCommunicationTypeById(id: number): void {
    const foundType = this.communicationTypeListWithId.find(
      (item: InitiateExternalCommunicationDropdownModel) =>
        item.communicationTypeId === id
    );
    if (foundType) {
      this.communicationType = foundType.communicationTypeName;
    }
  }

  getCommunicationTypeIdByName(name: string): number | undefined {
    const foundType = this.communicationTypeListWithId.find(
      (item: InitiateExternalCommunicationDropdownModel) =>
        item.communicationTypeName === name
    );
    return foundType ? foundType.communicationTypeId : undefined;
  }

  getLeftNavDetails(id: number): void {
    this.progressIndicator.show();
    this.externalCommService
      .getExtCommLeftNav(id)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: ExtCommLeftNavModel[]) => {
          if (response) {
            this.submittedExternalCommunications = response;
            const selectedSubmission =
              this.currentActiveSubmissionId ?? history?.state?.submissionId;
            // TODO: Finding index since data is not sorted. Can remove once we get latest submission on top and then can directly use index 0.
            const index = this.submittedExternalCommunications.findIndex(
              (item: ExtCommLeftNavModel) =>
                item.submissionId === selectedSubmission
            );

            // TODO: Uncomment if we need to show toast for invalid id
            // if (
            //   index === -1 &&
            //   this.submittedExternalCommunications.length > 0
            // ) {
            //   this.setToast({
            //     ...ToastMessageOptions,
            //     title: 'Submission Id is invalid',
            //   });
            // }
            this.setActive(index !== -1 ? index : 0);
            this.progressIndicator.hide();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  onSubmissionTitleChange(newTitle: string | null): void {
    this.submittedExternalCommunications.forEach(
      (submission: ExtCommLeftNavModel) => {
        if (submission.submissionId === this.currentActiveSubmissionId) {
          submission.submissionTitle = newTitle;
        }
      }
    );
  }

  getExternalCommunications(): void {
    const CommunicationTypeIdName = Number(
      this.getCommunicationTypeIdByName(this.communicationType)
    );
    this.communicationTypeId = CommunicationTypeIdName;
    this.externalCommService.setCommunicationType(CommunicationTypeIdName);
    this.getLeftNavDetails(CommunicationTypeIdName);
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find(
        (enumValue: string) => enumValue === status
      ) || StatusEnum.DRAFT
    );
  }

  setActive(index: number): void {
    this.activeIndex = index;
    this.currentActiveSubmissionId =
      this.submittedExternalCommunications[index]?.submissionId;

    /**
     * Reload should take to last active submission
     * It adds the queryParam for every submission selected
     */
    this.router.navigate(
      ['externalCommunications/details/', this.communicationTypeId],
      {
        queryParams: { submissionId: this.currentActiveSubmissionId },
        queryParamsHandling: 'merge',
        state: {
          submissionID: this.currentActiveSubmissionId,
        },
      }
    );
  }

  getRoleWidth(): string {
    const length = this.communicationType.length;
    if(this.communicationType===ExternalCommunicationConstant.COMMTYPECONTRACTTHIRDPARTIES){
      return length - 9 + 'ch';  
    }
   if(this.communicationType===ExternalCommunicationConstant.COMMTYPEAWARD || this.communicationType===ExternalCommunicationConstant.COMMTYPESPONSORSHIP){
    return length + 4 + 'ch';
   }
   return length + 2 + 'ch';
  }

  onRequiredFieldsFilledChange(isFilled: boolean): void {
    this.requiredFieldsFilled = isFilled;
    if (this.requiredFieldsFilled) {
      this.getLeftNavDetails(this.communicationTypeId);
    }
  }

  updateLeftNav(status: UpdateSubmission): void {
    const id: number = Number(
      this.getCommunicationTypeIdByName(this.communicationType)
    );
    this.getLeftNavDetails(id);
  }

  trackByValue(index: number, item: ExtCommLeftNavModel): number {
      return item.submissionId;
    }

  setToast(toast: ToastOptions): void {
    this.toastService.createToast(toast);
  }

  ngOnDestroy(): void {
    this.externalCommService.setCurrentPage('');
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
