import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MsGraphService } from '../../../http/msgraph.service';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../intake/constants/intake-constant';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { SubmissionService } from '../../../http/intake/submission.service';

@Component({
  selector: 'app-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrl: './people-picker.component.less',
})
export class PeoplePickerComponent implements OnInit, OnDestroy {
  @Output() userSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() isTechinalReviewerSearch: boolean = false;
  private readonly searchQuery$: Subject<string> = new Subject<string>();
  data: any = [];
  searchedUser: string = '';
  unsubscriber$: Subject<any> = new Subject();
  userList: any = [];
  notFound: string = '';
  selectedUser: any = {};
  pageNumber: number = 1;
  showSelected: boolean = false;
  searchOptions: SearchOptions = {
    placeholder: IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER,
    size: Size.md,
    customClass: '',
  };
  showdropDown: boolean = false;
  constructor(
    private readonly msgraph: MsGraphService,
    private readonly submissionService: SubmissionService
  ) {}

  ngOnInit(): void {
    this.searchQuery$
      .pipe(
        debounceTime(IntakeConstant.SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.unsubscriber$)
      )
      .subscribe((event) => {
        if (event.length > IntakeConstant.SEARCH_STRING_LIMIT) {
          if (this.isTechinalReviewerSearch) {
            this.fetchTechnicalUserUsers(event);
          } else {
            this.fetchUserSearchResultUsingGraphApi(event);
          }
          this.showdropDown = true;
        } else {
          this.notFound = '';
          this.userList = [];
          this.showdropDown = false;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['isTechinalReviewerSearch'] &&
      changes['isTechinalReviewerSearch'].currentValue
    ) {
      this.searchOptions.placeholder =
        IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER_TECHNICAL_REVIEWER;
    } else {
      this.searchOptions.placeholder =
        IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER;
    }
  }

  fieldCleared(): void {
    this.userList = [];
    this.searchedUser = '';
    this.notFound = '';
    this.showdropDown = false;
  }

  private fetchUserSearchResultUsingGraphApi(searchString: string): void {
    this.msgraph
      .searchUsers(searchString)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.transformUserDetails(users['value']);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  fetchTechnicalUserUsers(searchString: string): void {
    this.submissionService
      .getTechnicalReviewers(searchString, this.pageNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (users: any) => {
          this.transformUserDetails(users['technicalReviewers']);
        },
        error: (err) => {
          console.error('Error searching data', err);
        },
      });
  }

  private readonly transformUserDetails = (users: any): any => {
    this.notFound = '';
    this.userList = users;
    if (this.userList.length === 0) {
      this.notFound = IntakeConstant.NO_MATCHES_FOUND;
    } else {
      this.notFound = '';
    }
  };

  // selectUserChanged(): void {
  //   if (this.searchedUser.length > 0 && this.searchedUser !== '') {
  //     this.fetchUserSearchResultUsingGraphApi();
  //     this.showdropDown = true;
  //   } else {
  //     this.notFound = '';
  //     this.userList = [];
  //     this.showdropDown = false;
  //   }
  // }

  fetchUserDetails(item: any): void {
    const transformdata: any = {
      displayName: item.displayName,
      email: item.mail,
      location: item.officeLocation,
      fullname: `${item.givenName} ${item.surname}`,
    };
    this.userSelected.emit(transformdata);
    this.selectedUser = item;
    this.showdropDown = false;
    this.userList = [];
    this.searchedUser = '';
  }

  resetUser(): void {
    this.selectedUser = '';
    this.showSelected = false;
    //this.selectUserChanged();
  }

  findUsers(event: string): void {
    this.searchQuery$.next(event);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
