import { Themes } from '@usitsdasdesign/dds-ng/shared';
export const TableHeaderData = [
  {
    name: 'submissionId',
    header: 'Submission ID',
    dataType: 'number',
    minWidth: '6.25rem',
  },
  {
    name: 'jupiterOpportunityID',
    header: 'Jupiter opportunity ID',
    dataType: 'string',
    minWidth: '7.25rem',
  },
  {
    name: 'jupiterOpportunityStage',
    header: 'Jupiter opportunity stage',
    dataType: 'string',
    minWidth: '7.25rem',
  },
  {
    name: 'marketOfferingSolution',
    header: 'Market offering solution',
    dataType: 'string',
    minWidth: '6.25rem',
  },
  {
    name: 'marketOfferingSubSolution',
    header: 'Market offering sub-solution',
    dataType: 'string',
    minWidth: '6.25rem',
  },
  {
    name: 'jupiterOpportunityEstimatedValue',
    header: 'Jupiter opportunity estimated value, $',
    dataType: 'string',
    minWidth: '6.25rem',
  },
];

export const tooltipOptionsData = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'top',
  tooltipIndent: 15,
  tooltipIsOutsideClick: false,
  tooltipHasBeak: true,
  tooltipIsDisabled: false,
  tooltipIsInverse: false,
  tooltipIsDynamic: false,
  tooltipCustomClass: '',
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipShowDelay: 0,
  tooltipHideDelay: 0,
  tooltipTheme: Themes.dark,
  tooltipMaxWidth: 300
};

export const riskManagerTableHeaderData = [
  {
    name: 'name',
    header: 'Role/Name',
    dataType: 'string',
    minWidth: '130px',
  },
];

export const lepDataValue = {
  lepName: '',
  lepReviewedBy: '',
};
