import { Size } from '@usitsdasdesign/dds-ng/shared';

export const AccordionOptions = {
  size: Size.lg,
  disabled: false,
  isOpen: false,
  isMulti: false,
  isInverse: false,
  customClass: '',
};
