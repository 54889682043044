import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Reviewers } from '../../../../common/models/external-communication-submission-details-common-section.model';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { CompleteReviewPopUpTableHeader } from '../external-communication-submission.helper';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalCloseButtonOptions } from '../../../external-communications.helper';
import { DocumentStatus } from '../../../constants/document-status.enum';
import {
  ExtCommSubmissionDocumentDetailsPopupModel,
  ExtCommUpdateReviewStatusRequestBodyModel
} from '../../../../common/models/external-communication.model';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';

@Component({
  selector: 'app-ext-comm-doc-selection-popup',
  templateUrl: './ext-comm-doc-selection-popup.component.html',
  styleUrl: './ext-comm-doc-selection-popup.component.less',
})
export class ExtCommDocSelectionPopupComponent {
  @Input() submissionID: string = ExternalCommunicationConstant.EMPTY_SPACE;
  @Input() rowDetails!: Reviewers;
  @Input() prescreen: boolean = false;

  public sampleColumns: Column[] = CompleteReviewPopUpTableHeader;

  _blank: string = ExternalCommunicationConstant.EMPTY_SPACE;
  isAllSelected: boolean = false;
  selectedRowCount: number = 0;
  submissionIdList: number[] = [];
  cancelButton: ButtonOptions = ModalCloseButtonOptions;
  docToShow: ExtCommSubmissionDocumentDetailsPopupModel[] = [];
  public docMetaData: ExtCommSubmissionDocumentDetailsPopupModel[] = [];
  readonly #unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    private readonly modalService: ModalService,
    private readonly externalCommunicationSubmissionService: ExternalCommunicationService,
    private readonly progressIndicatorService: ProgressIndicatorService
  ) {}

  ngOnInit(): void {
    this.loadDocumentTableData();
  }

  public toggleRow(rowData: ExtCommSubmissionDocumentDetailsPopupModel): void {
    if (!rowData) {
      return;
    }
    rowData.selected = !rowData.selected;
    if (rowData.selected) {
      this.submissionIdList.push(rowData.submissionDocumentId);
    } else {
      this.submissionIdList = this.submissionIdList.filter(
        (doc: number) => doc !== rowData.submissionDocumentId
      );
    }
    this.#checkSelectedRow(this.docToShow);
  }

  public selectAllRows(
    status: boolean,
    data: ExtCommSubmissionDocumentDetailsPopupModel[]
  ): void {
    data.forEach(
      (item: ExtCommSubmissionDocumentDetailsPopupModel) =>
        (item.selected = status)
    );
    this.#checkSelectedRow(this.docToShow);
    if (status) {
      this.submissionIdList = [];
      for (let i in this.docToShow) {
        this.submissionIdList.push(this.docToShow[i].submissionDocumentId);
      }
    } else {
      this.submissionIdList = [];
    }
  }

  #checkSelectedRow(data: ExtCommSubmissionDocumentDetailsPopupModel[]): void {
    this.selectedRowCount = data.filter(
      (row: ExtCommSubmissionDocumentDetailsPopupModel) => row.selected
    ).length;
    this.isAllSelected = data.length === this.selectedRowCount;
  }

  close(showToastMessage: boolean): void {
    this.modalService.close(showToastMessage);
  }

  submitSelectedDoc(): void {
    const submissionList: number[] = this.docToShow
      .filter(
        (doc) =>
          doc.selected &&
          doc.documentStatus !== DocumentStatus.FinalArchiveReadyVersion &&
          this.submissionIdList.includes(doc.submissionDocumentId)
      )
      .map((doc) => doc.submissionDocumentId);

    const saveSelectedDocuments: ExtCommUpdateReviewStatusRequestBodyModel = {
      SubmissionDocumentId: submissionList,
      ReviewerEmployeeid: this.rowDetails.reviewerId,
      Email: this.rowDetails.emailId,
      SubmissionId: +this.submissionID,
      DocumentStatusId: this.prescreen
        ? ExternalCommunicationConstant.EXT_COMM_DOCUMENT_STATUS_MAPPER[
            DocumentStatus.DOC_PRESCREENED_RM_REVIEW
          ]
        : ExternalCommunicationConstant.EXT_COMM_DOCUMENT_STATUS_MAPPER[
            DocumentStatus.RMReviewed
          ],
      ReviewerRoleId: this.rowDetails.reviewerRoleId
    };

    this.progressIndicatorService.show();
    this.externalCommunicationSubmissionService
      .updateReviewerStatus(saveSelectedDocuments)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicatorService.hide();
          this.submissionIdList = [];
          this.close(true);
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicatorService.hide();
          console.error('An error occurred while sending the data ', err);
        },
      });
  }

  loadDocumentTableData(): void {
    this.progressIndicatorService.show();
    this.externalCommunicationSubmissionService
      .getDocumentDetails(+this.submissionID)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.progressIndicatorService.hide();
          this.docMetaData = data;
          this.docToShow = data;
          for (let i in this.docToShow) this.docToShow[i].selected = false;
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicatorService.hide();
          console.error('Error in fetching document data', err);
        },
      });
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
