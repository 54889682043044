<div class="dds-modal-overlay" (click)="closeModal()"></div>

<div
  ddsResize
  [ngClass]="{
    'dds-modal_lg': size === 'lg',
    'dds-modal_inverse': isInverse
  }"
  class="dds-modal"
>
  <div class="dds-modal__header">
    <span class="dds-modal__title">Attach document(s)</span>
    <dds-button
      [size]="modalCloseButtonOptions.size ?? emptyString"
      [icon]="modalCloseButtonOptions.icon ?? emptyString"
      [kind]="modalCloseButtonOptions.kind ?? emptyString"
      [theme]="modalCloseButtonOptions.theme ?? emptyString"
      [ariaLabel]="modalCloseButtonOptions.ariaLabel ?? emptyString"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="closeModal()"
    ></dds-button>
  </div>
  <p *ngIf="validateDocumentAlreadyExists(false)" class="error-message">
    It is impossible to upload the same file {{validateDocumentAlreadyExists(true)}}. 
  </p>
  <div class="dds-modal__body">
    <dds-uploader
      class="dds-uploader"
      [heading]="uploadAreaOptions.heading ?? ''"
      [description]="uploadAreaOptions.description ?? ''"
      [dragAndDropText]="uploadAreaOptions.dragAndDropText ?? ''"
      [isDragAndDrop]="uploadAreaOptions.isDragAndDrop ?? false"
      [isMultiple]="uploadAreaOptions.isMultiple ?? false"
      [acceptFormats]="uploadAreaOptions.acceptFormats ?? ''"
      [acceptExtensions]="uploadAreaOptions.acceptExtensions ?? ''"
      [exceptSymbols]="uploadAreaOptions.exceptSymbols ?? ''"
      [btnLabel]="uploadAreaOptions.btnLabel ?? ''"
      [isInverse]="uploadAreaOptions.isInverse ?? false"
      [disabled]="uploadAreaOptions.disabled ?? true"
      [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind ?? ''"
      (uploadedItems)="uploadedItems($event)"
    ></dds-uploader>

    <div class="div-headers">
      <div class="row d-flex justify-content-evenly">
        <div class="col-md-3 column ">
          <span class="div-headers-style">Title</span>
        </div>
        <div class="col-md-2 column ">
          <span class="div-headers-style">Type</span>
        </div>
        <div class="col-md-2 column ">
          <span class="div-headers-style">Status</span>
        </div>
        <div class="col-md-3 column ">
          <span class="div-headers-style">Comments (Optional)</span>
        </div>
        <!-- <div class="col-md-1 column">
          <span class="div-headers-style"></span>
        </div> -->
      </div>

      <div class="div-whole-data">
        <span *ngIf="isUploadingInProgress">
          <div
            class="row div-data"
            *ngFor="let row of documentUploadInProgressData"
          >
            <div class="col-md-3 column">
              <span class="div-data-style data-alignment">{{
                row.fileName
              }}</span>
            </div>
            <div class="col-md-3 column">
              <dds-progress
                class="data-alignment"
                [isInverse]="options.isInverse ?? false"
                [description]="options.description ?? ''"
                [isVertical]="options.isVertical ?? false"
                [label]="options.label ?? ''"
                [size]="options.size ?? ''"
                [theme]="options.theme ?? ''"
                [value]="fileUploadPercentage ?? ''"
              ></dds-progress>
            </div>
            <div class="col-md-3 column">
              <span class="uploading-color margin">Uploading in progress</span>
            </div>
            <div class="col-md-1 column">
              <dds-button
                size="sm"
                icon="dds-icon_close"
                kind="silent"
                theme="dark"
                ariaLabel="Close modal"
                class="dds-modal__close"
                [isInverse]="isInverse"
                (clicked)="removeDocument(row)"
              ></dds-button>
            </div>
          </div>
        </span>
    
        <span>
          <div
            class="row div-data mandatory-margin"
            *ngFor="let row of documentUploadData"
          >
            <div class="file-upload-row d-flex justify-content-evenly">
              <div class="title-section align-items-center d-flex col-md-3">
                <div class="file-name">
                  <span
                    [ddsTooltip]="row.documentTitle"
                    *ngIf="!row.isFileNameInEditMode"
                    >{{ row.documentTitle }}</span
                  >
                  <dds-input
                    class="editing"
                    *ngIf="row.isFileNameInEditMode"
                    [placeholder]="textFileOptions.placeholder ?? ''"
                    [maxLengthPosition]="
                      textFileOptions.maxLengthPosition ?? ''
                    "
                    [size]="textFileOptions.size ?? ''"
                    [(ngModel)]="row.documentTitle"
                  >
                  </dds-input>
                </div>
                <div
                  class="cursor"
                  *ngIf="!row.isFileNameInEditMode"
                  (click)="onFileNameEdit($event, row)"
                >
                  <img src="assets/dds-icons/pencil-icon.svg" />
                </div>
              </div>
              <div
                [ngClass]="{
                  'dropdown-section-margin': row.documentTypeId !== 0
                }"
                class="dropdown-section align-items-center d-flex col-md-2"
              >
                <span class="icon-own-space">
                  <span
                    [ngClass]="{ 'icon-yellow-hide': row.documentTypeId !== 0 }"
                    class="icon-required-yellow"
                  ></span>
                </span>
                <dds-select
                  class="const-width"
                  [isResponsive]="
                  selectOptions.isResponsive ?? false
                  "
                  [isRequired]="selectOptions.isRequired ?? false"
                  [isInverse]="selectOptions.isInverse ?? false"
                  [list]="documentTypeItemsList"
                  [label]="selectOptions.label ?? ''"
                  [stickerMaxHeight]="'35vh'"
                  [labelPosition]="
                  selectOptions.labelPosition ?? ''
                  "
                  [customClass]="selectOptions.customClass ?? ''"
                  [placeholder]="'Select type'"
                  [size]="selectOptions.size ?? ''"
                 
                  [(ngModel)]="row.documentTypeId"
                  [stickerPosition]="
                  selectOptions.stickerPosition ?? ''
                  "
                 
                >
                </dds-select>
              </div>
    
              <div
                [ngClass]="{
                  'dropdown-section-margin': row.documentTypeId !== 0
                }"
                class="dropdown-section align-items-center d-flex col-md-2"
              >
                <span class="icon-own-space">
                  <span
                    [ngClass]="{
                      'icon-yellow-hide': row.documentStatusId !== 0
                    }"
                    class="icon-required-yellow col-md-1"
                  ></span
                ></span>
                <dds-select
                  class="const-width"
                  [isResponsive]="selectOptions.isResponsive ?? false"
                  [isRequired]="selectOptions.isRequired ?? false"
                  [isInverse]="selectOptions.isInverse ?? false"
                  [list]="documentStatusItemList"
                  [label]="selectOptions.label ?? ''"
                  [labelPosition]="selectOptions.labelPosition ?? ''"
                  [customClass]="selectOptions.customClass ?? ''"
                  [placeholder]="selectOptions.placeholder ?? ''"
                  [size]="selectOptions.size ?? ''"
                  [stickerMaxHeight]="'40vh'"
                  [placeholder]="'Select status'"
                  [stickerPosition]="selectOptions.stickerPosition ?? ''"
                  [(ngModel)]="row.documentStatusId"
                >
                </dds-select>
              </div>
    
              <div
                class="comment-section align-items-center d-flex padding-left-l col-md-2"
              >
                <span class="icon-own-space">
                  <span
                    [ngClass]="{
                      'icon-required-yellow':
                        row.documentTypeId === otherTypeSelected &&
                        row.comments.length === 0,
                      'icon-yellow-hide': !(
                        row.documentTypeId === otherTypeSelected &&
                        row.comments.length === 0
                      )
                    }"
                    class="col-md-1"
                  ></span
                ></span>
                <dds-input
                  [placeholder]="textareaOptions.placeholder ?? ''"
                  [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
                  [size]="textareaOptions.size ?? ''"
                  [(ngModel)]="row.comments"
                >
                </dds-input>
              </div>
    
              <div class="close-section">
                <dds-button
                  size="sm"
                  icon="dds-icon_close"
                  kind="silent"
                  theme="dark"
                  ariaLabel="Close modal"
                  class="dds-modal__close close-icon"
                  [isInverse]="isInverse"
                  (clicked)="removeDocument(row)"
                ></dds-button>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item"
        (clicked)="onSave()"
        (keyboardClicked)="onSave()"
        [disabled]="isSaveButtonDisabled()"
        >Save</dds-button
      >
      <dds-button
        class="dds-modal__footer-item"
        kind="secondaryLoud"
        [isInverse]="isInverse"
        (clicked)="closeModal()"
        (keyboardClicked)="closeModal()"
        >Cancel</dds-button
      >
    </div>
    <div
      class="dds-modal__footer-content dds-modal__footer-content_right"
    ></div>
  </div>
</div>
