<progress-indicator></progress-indicator>
<app-page-header>
  <div class="back-to-dashboard">
    <img class="margin-right-xs margin-bottom-xxxs back-icon" *ngIf="pageHeaderVisible"
      src="../../assets/images/dds-icons/back-icon_white.svg" (click)="backToList()"/>
    
    <span *ngIf="!pageHeaderVisible"
      class="margin-right-xs margin-top-xxs audit-watchlist back-icon  dds-icon dds-icon_home__l__stroke cursor-clickable"
      (click)="backToHome()"></span>
    <div class="inline-block">Watchlist audit trail</div>
  </div>
</app-page-header>
<div *ngIf="!isLoading">
  <div class="eclipse-main-tab-header text-left margin-top-s margin-bottom-l row flex-container d-flex">
    <div class="col-10">
      <span class="padding-right-xxs">{{clientName}}</span><br />
      <span class="eclipse-header-grey">Swift client ID: <span> {{clientNumber}}</span></span>
    </div>
    <div class="col-2 clear-filter" *ngIf="watchlistAuditLogGridFilter?.length">
      <dds-button [theme]="clearButtonoptions.theme ?? ''" [kind]="clearButtonoptions.kind ?? ''"
        [size]="clearButtonoptions.size ?? ''" [width]="clearButtonoptions.width ?? ''"
        (clicked)="clearAllFilter()">Clear
        filters
      </dds-button>
    </div>
  </div>

  <div class="row flex-container">
    <div class="main-block tabsection">
      <div class="table-data">
        <table class="dds-data-table">
          <thead class="dds-data-table__header">
            <tr>
              <th *ngFor="let column of gridColumns"
                class="dds-data-table__header-cell dds-data-table__header-cell_sorting"
                [ddsSticker]="column.name != 'Description' ? customFilter : noFilter" [ngClass]="{
              'dds-data-table__header-cell_sorted': sortingState.property === column.name,
              'dds-data-table__header-cell_filter-shown': isShownFilter && column.name === filteringColumn.name
            }" [stickerPosition]="filterStickerOptions.stickerPosition ?? ''"
                [stickerIsOutsideClick]="filterStickerOptions.stickerIsOutsideClick ?? false"
                [stickerIndent]="filterStickerOptions.stickerIndent ?? 0"
                [stickerWidth]="filterStickerOptions.stickerWidth ?? 0" [attr.aria-expanded]="isShownFilter"
                aria-haspopup="listbox" (click)="toggleFilter(column)" (onHidden)="filterOnHidden()"
                #stickerDir="dds-sticker" [ngStyle]="{width: column.width, minWidth: column.minWidth }">
                <div class="dds-data-table__content">
                  <span class="padding-right-xxs">{{column.header || column.name}}</span>
                  <span *ngIf="column.name != 'Description'" class="icon-expand-grey"></span>
                  <span *ngIf="sortingState.property === column.name && column.name != 'Description'"
                    class="dds-icon dds-data-table__icon"
                    [ngClass]="sortingState.ascending ? 'dds-icon_sort-up': 'dds-icon_sort-down' ">
                  </span>
                  <span *ngIf="column.filterable == true" class="filterIcon">
                    <span *ngIf="column.name != 'Description'" class="dds-icon dds-icon_filter"></span>
                  </span>
                </div>
              </th>

            </tr>
          </thead>
          <tbody class="dds-data-table__body">
            <ng-container *ngFor="let row of auditItems; let i = index">
              <tr class="dds-data-table__row">
                <td *ngFor="let column of gridColumns" class="dds-data-table__cell"
                  [ngStyle]="{width: column.width, minWidth: column.minWidth }">

                  <!--TODO : add redirection logic once client details page is added-->
                  <div *ngIf="column.name == 'name'" class="client-name-container">
                    <span class="padding-bottom-xxs">{{ row.userName ? row.userName : '-' }}</span>
                  </div>

                  <!--TODO : add redirection logic once client details page is added-->
                  <div *ngIf="column.name == 'logDate'">
                    <div>{{ row.loggedDate ? row.loggedDate : '-' }}</div>
                    <div>{{ row.loggedTime ? row.loggedTime : '-' }}</div>
                  </div>

                  <div *ngIf="column.name === 'Description'" class="status-comment-container">
                    <div class="user-action padding-bottom-s">
                      <span class="eclipse-header">User action: </span>
                      <span class="">{{row.userAction}}</span>
                    </div>

                    <div class="padding-bottom-s" *ngIf="row.statusChangeDescription">
                      <span class="eclipse-header">{{row.statusChangeDescription}}</span>
                      <div class="">
                        <span class="eclipse-content-grey">From: </span>
                        <span class="">{{row.fromStatus ? row.fromStatus : '[None]' }}</span>
                      </div>
                      <div class="">
                        <span class="eclipse-content-grey">To: </span>
                        <span class="">{{row.toStatus ? row.toStatus : '[None]'}}</span>
                      </div>
                    </div>

                    <div class="padding-bottom-s" *ngIf="row.commentChangeDescription">
                      <span class="eclipse-header">{{row.commentChangeDescription}}</span>
                      <div class="note-field text-ellipsis" #fromCommentText data-id="{{i}}"
                        [class.show-more]="showMore[i]?.from">
                        <span class="eclipse-content-grey">From: </span>
                        <span>{{row.fromComment ? row.fromComment : '[None]'}}</span>
                      </div>
                      <dds-button *ngIf="shouldShowButton[i]?.from && !showMore[i]?.from" [theme]="options.theme ?? ''"
                        [kind]="options.kind ?? ''" [size]="options.size ?? ''" [width]="options.width ?? ''"
                        [icon]="options.icon ??''" (clicked)="toggleShowMore(i, 'from')">Show more</dds-button>
                      <dds-button *ngIf="showMore[i]?.from" [theme]="options.theme ?? ''" [kind]="options.kind ?? ''"
                        [size]="options.size ?? ''" [width]="options.width ?? ''" [icon]="options.icon ??''"
                        (clicked)="toggleShowMore(i, 'from')">Show less</dds-button>

                      <div class="note-field text-ellipsis" #toCommentText data-id="{{i}}"
                        [class.show-more]="showMore[i]?.to">
                        <span class="eclipse-content-grey">To: </span>
                        <span>{{row.toComment ? row.toComment : '[None]'}}</span>
                      </div>

                      <dds-button *ngIf="shouldShowButton[i]?.to && !showMore[i]?.to" [theme]="tooptions.theme ?? ''"
                        [kind]="tooptions.kind ?? ''" [size]="tooptions.size ?? ''" [width]="tooptions.width ?? ''"
                        [icon]="tooptions.icon ??''" (clicked)="toggleShowMore(i, 'to')">Show more</dds-button>
                      <dds-button *ngIf="showMore[i]?.to" [theme]="tooptions.theme ?? ''" [kind]="tooptions.kind ?? ''"
                        [size]="tooptions.size ?? ''" [width]="tooptions.width ?? ''" [icon]="tooptions.icon ??''"
                        (clicked)="toggleShowMore(i, 'to')">Show less</dds-button>

                    </div>

                    <div class="padding-bottom-s" *ngIf="row.parentClientChangeDescription">
                      <span class="eclipse-header">{{row.parentClientChangeDescription}}</span>
                      <div class="">
                        <span class="eclipse-content-grey">From: </span>
                        <span class="">{{row.fromParentClient ? row.fromParentClient : '[None]' }}</span>
                      </div>
                      <div class="">
                        <span class="eclipse-content-grey">To: </span>
                        <span class="">{{row.toParentClient ? row.toParentClient : '[None]'}}</span>
                      </div>
                    </div>

                  </div>
                </td>

              </tr>
            </ng-container>

          </tbody>

        </table>
        <div class="image-section" *ngIf="!auditItems || auditItems?.length === 0">
          <div class="empty-state-container">
            <img src="assets/images/no_watchlist_clients.svg" alt="empty-state" class="empty-state-image" />
            <div class="empty-state-text">There are no records to display</div>
          </div>
        </div>
        <app-new-pagination-and-grid [tileCountforPage]="this.countforPagination" [pageUI]="this.gotoPage+1"
          (pageChanged)="onPageChanged($event)" (valueChanged)="onValueChanged($event)"></app-new-pagination-and-grid>
        <div class="padding-bottom-s"></div>
      </div>

    </div>
  </div>
</div>



<ng-template #customFilter>
  <dds-filter [items]="filterData" [theme]="filterOptions.theme ?? ''" [template]="customFilterTemp"
    [propName]="filterOptions.propName ?? ''" #ddsFilter></dds-filter>
</ng-template>


<ng-template #customFilterTemp let-ctx="ctx">

  <div>
    <span>
      <span class="dds-filter__title dds-filter__section">Sorting</span>
      <div class="dds-filter__container dds-filter__container_sorting">
        <button class="dds-filter__item dds-filter__section dds-filter__section_sorting" (click)="filterSorted(true)">
          <span class="dds-filter__icon dds-icon dds-icon_sort-up"></span>
          <span class="dds-filter__text">Sort A�Z</span>
        </button>
        <button class="dds-filter__item dds-filter__section dds-filter__section_sorting" (click)="filterSorted(false)">
          <span class="dds-filter__icon dds-icon dds-icon_sort-down"></span>
          <span class="dds-filter__text">Sort Z�A</span>
        </button>
      </div>
    </span>
    <span class="dds-filter__title dds-filter__section">Filter</span>
    <dds-search *ngIf="filteringColumn.dataType !== 'date'
                       && filteringColumn.dataType !== 'int'" class="dds-filter__search dds-filter__section"
      [isInverse]="ctx.isInverse" (keydown)="ctx.tabOut(searchField, $event)" [placeholder]="'Search'"
      [(ngModel)]="filteringColumn.searchValue" #searchField></dds-search>

    <dds-daterangepicker class="dds-filter__section" *ngIf="filteringColumn.dataType === 'date'"
      [size]="dateRangePickerOptions.size ?? ''" [placeholder]="dateRangePickerOptions.placeholder ?? []"
      [format]="dateRangePickerOptions.format ?? ''" [isManualInput]="dateRangePickerOptions.isManualInput ?? false"
      [stickerPosition]="dateRangePickerOptions.stickerPosition ?? ''"
      [(ngModel)]="filteringColumn.searchValue"></dds-daterangepicker>
  </div>


  <div class="row">
    <div class="col-5"></div>
    <div class="col-7">
      <div class="dds-filter__footer dds-filter__section dds-filter__footer--end"
        style="display: flex; justify-content: flex-end;">
        <dds-button width="full" [kind]="ctx.cancelBtnOptions.kind" [theme]="ctx.theme" [isInverse]="ctx.isInverse"
          class="dds-filter__btn dds-filter__item" (clicked)="cancel(filteringColumn.searchValue)"
          #cancelBtn>Cancel</dds-button>
        <dds-button width="full" [theme]="ctx.theme" [isInverse]="ctx.isInverse"
          class="dds-filter__btn dds-filter__item" (clicked)="filterApply(filteringColumn.searchValue)"
          (keydown)="ctx.tabOut(lastBtn, $event)" #lastBtn>Apply</dds-button>
      </div>
    </div>

  </div>

</ng-template>

<ng-template #noFilter></ng-template>