import { GlobalConfigurationComponent } from './admin-page/global-configuration/global-configuration.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { ADMIN_MODULE_ROUTES } from './admin-module.states';
import { RouterModule } from '@angular/router';
import { AdminTablesComponent } from './admin-page/admin-tables/admin-tables.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModifyMarketOfferingPopUpComponent } from './admin-page/admin-tables/modify-market-offering-pop-up/modify-market-offering-pop-up.component';
import { InitiationEditPopupComponent } from './admin-page/global-configuration/initiation-edit-popup/initiation-edit-popup.component';
import { ExtcommTrainingTableComponent } from './admin-page/admin-tables/extcomm-training-table/extcomm-training-table.component';
import { EditAddRecordPopupComponent } from './admin-page/admin-tables/extcomm-training-table/edit-add-record-popup/edit-add-record-popup.component';
import { EclipseCommonModule } from '../common/common.module';
import { ExternalCommRiskManagerTableComponent } from './admin-page/admin-tables/external-comm-risk-manager-table/external-comm-risk-manager-table.component';
import { EditAddRecordPopupExtCommRiskManagerComponent } from './admin-page/admin-tables/external-comm-risk-manager-table/edit-add-record-popup-extcomm-risk-manager/edit-add-record-popup-extcomm-risk-manager.component';
import { AdminRolesComponent } from './admin-page/admin-roles/admin-roles.component';
import { AdminUpsertDialogComponent } from './admin-page/admin-upsert-dialog/admin-upsert-dialog.component';
import { AdminNestedMultiSelectComponent } from './admin-page/admin-nested-multi-select/admin-nested-multi-select.component';

@NgModule({
  declarations: [
    AdminPageComponent,
    GlobalConfigurationComponent,
    AdminTablesComponent,
    AdminRolesComponent,
    InitiationEditPopupComponent,
    ModifyMarketOfferingPopUpComponent,
    ExtcommTrainingTableComponent,
    EditAddRecordPopupComponent,
    ExternalCommRiskManagerTableComponent,
    EditAddRecordPopupExtCommRiskManagerComponent,
    AdminUpsertDialogComponent,
    AdminNestedMultiSelectComponent,
  ],
  imports: [
    CommonModule,
    LibModule,
    FormsModule,
    ReactiveFormsModule,
    EclipseCommonModule,
    RouterModule.forChild(ADMIN_MODULE_ROUTES),
  ],
  providers: [DatePipe],
})
export class AdminModule {}
