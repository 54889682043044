import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DELIVERABLE_RADIO_GROUP_VALUE,
  RADIO_GROUP_OPTIONS_FOR_DELIVERABLE_SCOPE,
} from './close-out-deliverable-helper';
import {
  WbsArchiveReceivedModel,
  WbsWithArchiveDetailsModel,
} from '../../../../common/models/wbs-picker.model';
import {
  DeliverableWithWbsArchiveEventEmitterModel,
  IntakeSubmissionDetailsCommonSectionDataReceivedModal,
  SwiftDetails,
} from '../../../../common/models/intake-submission-details-common-section.model';
import { IntakeConstant } from '../../../constants/intake.constant';
@Component({
  selector: 'app-close-out-deliverable',
  templateUrl: './close-out-deliverable.component.html',
  styleUrl: './close-out-deliverable.component.less',
})
export class CloseOutDeliverableComponent {
  @Input() deliverable = IntakeConstant.EMPTY_SPACE;
  @Input() wbsChargeCode: string = IntakeConstant.EMPTY_SPACE;
  @Input() wbsDescription: string = IntakeConstant.EMPTY_SPACE;
  @Input() dataSubmissionId: number | null = null;
  @Input() dataOpportunityId: string = IntakeConstant.EMPTY_SPACE;
  @Input() archiveDetails: WbsArchiveReceivedModel | null = null;
  @Input() swiftDetails: SwiftDetails | null = null;
  @Input()
  submissionData: IntakeSubmissionDetailsCommonSectionDataReceivedModal | null =
    null;
  @Output()
  deliverableWithWbsArchiveChange: EventEmitter<DeliverableWithWbsArchiveEventEmitterModel> =
    new EventEmitter<DeliverableWithWbsArchiveEventEmitterModel>();
  deliverable_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_DELIVERABLE_SCOPE;
  deliverable_radio_values: any = DELIVERABLE_RADIO_GROUP_VALUE;

  deliverableStateChanged(event: string): void {
    this.deliverable = event;
    const deliverableWithWbsArchiveValue: DeliverableWithWbsArchiveEventEmitterModel =
      {
        deliverable: event
      };
    this.deliverableWithWbsArchiveChange.emit(deliverableWithWbsArchiveValue);
  }

  onWbsOrArchiveChange(
    wbsWithArchiveDetails: WbsWithArchiveDetailsModel
  ): void {
    const deliverableWithWbsArchiveValue: DeliverableWithWbsArchiveEventEmitterModel =
      {
        deliverable: this.deliverable ?? IntakeConstant.EMPTY_SPACE,
        ...wbsWithArchiveDetails,
      };
    this.deliverableWithWbsArchiveChange.emit(deliverableWithWbsArchiveValue);
  }
}
