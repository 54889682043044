import { Component, Input, OnInit, input } from '@angular/core';
import { CheckboxOptions, CheckboxGroupOptions } from '@usitsdasdesign/dds-ng/checkbox';
import { Themes, ErrorState, Size } from '@usitsdasdesign/dds-ng/shared';
import { FormControl } from "@angular/forms";
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { NCASummaryFormPeriod, NCASummaryGridLevelFilter, NCASummarySortParameterCode, NcaSummaryWebapiService } from '../../http/dashboard/nca-summary-webapi.service';
import { MultiSelectOptions, SelectControlTypes, SelectType } from "@usitsdasdesign/dds-ng/multi-select";
import { MultiSelectItem } from '@usitsdasdesign/dds-ng/multi-select';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';
import { from } from 'rxjs';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../common/services/shared-service.service';

@Component({
  selector: 'app-nca-summary-export-popup',
  templateUrl: './nca-summary-export-popup.component.html',
  styleUrl: './nca-summary-export-popup.component.less',
  providers: [DatePipe]
})
export class NcaSummaryExportPopupComponent implements OnInit {
  @Input() isInverse: boolean = false;
  isExportButtonDisabled: boolean = false;
  statusListGridfilter: NCASummaryGridLevelFilter[] = [];
  periodListGridfilter: NCASummaryGridLevelFilter[] = [];
  clientMilestoneParameterCode = NCASummarySortParameterCode.clientmilestoneid;
  ncaRequestPeriodParameterCode = NCASummarySortParameterCode.ncarequestperiod;
  popupTitle: string = 'Export NCA summary';
  periodList: any = [];
  statusList: any = [];
  constructor(
    private readonly modalService: ModalService,
    private readonly ncaSummaryWebapiService: NcaSummaryWebapiService,
    private datePipe: DatePipe,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getPeriodData();
    this.getStatusData();
    this.statusListGridfilter = this.statusList.map((element: any) => {
      return {
        filtertypecode: this.clientMilestoneParameterCode,
        filtercriteria: element.title
      }
    });
    
    this.updateExportButtonDisabledState();
  }
  getStatusData() {
    this.statusList.push({
      title: 'All',
      name: 'All',
      theme: Themes.green,
      isChecked: true,
      isIndeterminate : false
    });
    from(['Not started', 'In process', 'Pending approvals', 'Completed', 'On hold', 'Discontinued']).subscribe((data: any) => {
      this.statusList.push({
        title: data,
        name: data.trim(),
        theme: Themes.green,
        isChecked: true,
        isIndeterminate: false
      });
    });
  }
  getPeriodData() {
    this.periodList.push({
      title: 'All',
      name: 'All',
      theme: Themes.green,
      isChecked: true,
      isIndeterminate: false
    });
    from(['Last FY', 'Current FY', 'Last period', 'Current period', 'Last week', 'Current week']).subscribe((data: any) => {
      this.periodList.push({
        title: data,
        name: data.trim(),
        theme: Themes.green,
        isChecked: true,
        isIndeterminate: false
      });
    });
  }
  periodCheckBoxStateChanged($event: any, data: any) {
    if (data.title === 'All') {
      this.periodList.forEach((element: any) => {
        element.isChecked = $event;
        element.isIndeterminate = false;
      });
    }
    else {
      let selectedItem = this.periodList.find((element: any) => element.title === data.title);
      if (selectedItem) {
        selectedItem.isChecked = $event;
      }
      const allItem = this.periodList.find((element: any) => element.title === 'All');
      if ($event === false) {        
        if (allItem) {
          allItem.isChecked = false;
          allItem.isIndeterminate = this.periodList.some((element: any) => element.title !== 'All' && element.isChecked);
        }
      }
      else {        
        const allSelected = this.periodList.every((element: any) => element.title === 'All' || element.isChecked);
        if (allSelected) {         
          if (allItem) {
            allItem.isChecked = allItem;
            allItem.isIndeterminate = !allSelected && this.periodList.some((element: any) => element.title !== 'All' && element.isChecked);
          }
        }
      } 
      this.periodListGridfilter = this.periodList
      .filter((element: any) => element.isChecked)
      .map((element: any) => ({
        filtertypecode: this.ncaRequestPeriodParameterCode,
        filtercriteria: element.title
      })); 
    }

    this.updateExportButtonDisabledState();
  }
  statusCheckBoxStateChanged($event: any, data: any) {
    if (data.title === 'All') {
      this.statusList.forEach((element: any) => {
        element.isChecked = $event;
        element.isIndeterminate = false;
      });
    }
    else {
      let selectedItem = this.statusList.find((element: any) => element.title === data.title);
      if (selectedItem) {
        selectedItem.isChecked = $event;
      }
      const allItem = this.statusList.find((element: any) => element.title === 'All');
      if ($event === false) {        
        if (allItem) {
          allItem.isChecked = false;
          const temp = this.statusList.some((element: any) => element.title !== 'All' && element.isChecked);
          //allItem.isIndeterminate = ;
        }
      }
      else {
        const allSelected = this.statusList.every((element: any) => element.title === 'All' || element.isChecked);
        if (allSelected) {         
          if (allItem) {
            allItem.isChecked = allItem;
            allItem.isIndeterminate = !allSelected && this.statusList.some((element: any) => element.title !== 'All' && element.isChecked);
          }
        }
      }
    }
    this.statusListGridfilter = this.statusList
    .filter((element: any) => element.isChecked)
    .map((element: any) => ({
      filtertypecode: this.clientMilestoneParameterCode,
      filtercriteria: element.title
    })); 
    this.updateExportButtonDisabledState();
  }

  updateExportButtonDisabledState() {
    const isAnyPeriodChecked = this.periodList.some((element: any) => element.isChecked);
    const isAnyStatusChecked = this.statusList.some((element: any) => element.isChecked);
    this.isExportButtonDisabled = !(isAnyPeriodChecked && isAnyStatusChecked);
  }

  closeModal() {
    this.closePopup();
  }
  onDownload() {
    var combinedFilters = this.statusListGridfilter.concat(this.periodListGridfilter);
    const completedIndex = combinedFilters.findIndex(filter => filter.filtercriteria === 'Completed');
    if (completedIndex !== -1) {
      // Remove 'Completed' from combinedFilters
      combinedFilters.splice(completedIndex, 1);

      // Push new criteria
      combinedFilters.push(
        { filtertypecode: this.clientMilestoneParameterCode, filtercriteria: 'Accepted' },
        { filtertypecode: this.clientMilestoneParameterCode, filtercriteria: 'Serve with Conditions' },
        { filtertypecode: this.clientMilestoneParameterCode, filtercriteria: 'Do not serve' },
        { filtertypecode: this.clientMilestoneParameterCode, filtercriteria: 'Not required/Accepted' }
      );
    }
    this.sharedService.showToastNotification('Downloading will start in a few seconds. Please wait...');
    this.ncaSummaryWebapiService.ExportNCASummary(combinedFilters).subscribe((response: any) => {
      const base64Data = response.base64String;
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
      const downloadLink = document.createElement('a');
      const formattedDate = this.datePipe.transform(new Date(), 'MMddyyyy');
      const fileName = `${response.fileName} - ${formattedDate}.xlsx`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    );
  }
  applyBodyOverflow() {
    document.body.style.overflow = 'auto';
  }
  closePopup() {
    this.applyBodyOverflow();
    this.modalService.close();
  }
}
