import {
  ButtonKind,
  ExtThemes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';

export const riskManagerTableHeaderData = [
  {
    name: 'name',
    header: 'Role/Name',
    dataType: 'string',
    minWidth: '8.125rem',
  },
];

const COMMON_BTN_OPTIONS: any = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: true,
  customClass: ExternalCommunicationConstant.EMPTY_SPACE,
  role: 'button',
};

export const TOOLTIPS_OPTIONS: TooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'top',
  tooltipIndent: 15,
  tooltipIsOutsideClick: false,
  tooltipHasBeak: true,
  tooltipIsDisabled: false,
  tooltipIsInverse: false,
  tooltipIsDynamic: false,
  tooltipCustomClass: ExternalCommunicationConstant.EMPTY_SPACE,
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipShowDelay: 0,
  tooltipHideDelay: 0,
  tooltipMaxWidth: 500,
  tooltipTheme: ExternalCommunicationConstant.INTAKE_INFO_TOOLTIP_THEME_COLOR,
};

export const ADD_REVIEWER_BTN_OPTIONS: ButtonOptions = {
  ...COMMON_BTN_OPTIONS,
  kind: ButtonKind.silent,
  customClass: ExternalCommunicationConstant.EMPTY_SPACE,
  icon: 'dds-icon_plus',
  isIconLeft: false,
  ariaLabel: ExternalCommunicationConstant.ADD_REVIEWER_BTN_LABEL,
};

const WORKFLOW_BTN_COMMON_OPTIONS = {
  ...COMMON_BTN_OPTIONS,
};

export const RETURN_FOR_SELF_REVIEW_OPTIONS: ButtonOptions = {
  ...WORKFLOW_BTN_COMMON_OPTIONS,
  kind: ButtonKind.primary,
  ariaLabel: ExternalCommunicationConstant.RETURN_FOR_SELF_REVIEW,
};

export const COMPLETE_REVIEW_OPTIONS: ButtonOptions = {
  ...WORKFLOW_BTN_COMMON_OPTIONS,
  ariaLabel: ExternalCommunicationConstant.COMPLETE_REVIEW,
};

export const PRESCREEN_OPTIONS: ButtonOptions = {
  ...WORKFLOW_BTN_COMMON_OPTIONS,
  ariaLabel: ExternalCommunicationConstant.PRESCREEN,
};

export const RETURN_TO_REWORK_OPTIONS: ButtonOptions = {
  ...WORKFLOW_BTN_COMMON_OPTIONS,
  kind: ButtonKind.primary,
  ariaLabel: ExternalCommunicationConstant.RETURN_TO_REWORK,
};

export const CONFIRM_OPTIONS: ButtonOptions = {
  ...WORKFLOW_BTN_COMMON_OPTIONS,
  ariaLabel: ExternalCommunicationConstant.CONFIRM_BTN_LABEL,
};
