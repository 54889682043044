import { AfterViewInit, Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { Saver } from './saver';
import { AutosaverService } from './autosaver.service';

@Directive({
    selector: '[ngModel][autosave]'
})
export class AutosaveDirective implements OnInit, AfterViewInit, OnDestroy {
    private saverId: string = "";
    private module!: Saver;
    private subscriptions: any = {};


    constructor(
        private autosaverService: AutosaverService,
        private renderer: Renderer2,
        private element: ElementRef,
        private ngModel: NgModel
    ) { }

    ngOnInit() {
        const findParentModule = () => {
            let element = this.element.nativeElement;
            while (element) {
                if (element.attributes && element.attributes["data-autosaver-module-id"]) {
                    this.saverId = element.attributes["data-autosaver-module-id"].value;
                    this.module = this.autosaverService.autosaveModules[this.saverId] as Saver;
                    return true; // found
                }
                element = this.renderer.parentNode(element);
            }
            return false; // not found
        }

        const tryFindParentModule = () => {
            if (!findParentModule())
                setTimeout(() => tryFindParentModule(), 200);
        }

        setTimeout(() => tryFindParentModule(), 200);
    }

    ngOnDestroy() {
        for (let f in this.subscriptions) {
            if (this.subscriptions.hasOwnProperty(f)) {
                this.subscriptions[f].unsubscribe();
            }
        }
    }

    ngAfterViewInit() {
        //call API to check permissions and then call save method, setTimeout can be removed

        if (this.subscriptions.ngModelChanges) {
            this.subscriptions.ngModelChanges.unsubscribe();
        }
        this.subscriptions.ngModelChanges = this.ngModel?.valueChanges?.pipe(debounceTime(200))
            .subscribe((value: any) => {
                if (this.ngModel.dirty && this.module) {
                    this.module.save();
                }
            });
    }
}