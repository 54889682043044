<div class="engagement-container">
  <progress-indicator></progress-indicator>
  <div class="padding-bottom-s padding-top-xs sticy-container">
    <div class="d-flex justify-content-between">
      <h4 class="eclipse-main-tab-header text-align-left">
        <span> {{ submissionDetails?.submissionTitle }}</span>
      </h4>
      <div class="button-container">
        <dds-button
          *ngIf="permissionObj['isGlobalEngSubmitButtonVisible']"
          class="custom-button margin-right-m"
          [theme]="primaryLoudButton.theme"
          [kind]="primaryLoudButton.kind"
          [size]="htmlElementSize"
          [width]="primaryLoudButton.width"
          [disabled]="!permissionObj['isGlobalEngSubmitButtonEnable'] || true"
          ariaLabel="Submit"
          >Submit</dds-button
        >
      </div>
    </div>
    <div class="d-flex">
      <div
        class="submission-type font-size-12 font-bold item-type margin-right-xs"
      >
        {{ submissionType }}
      </div>
      <div
        class="custom-pad margin-left-xxs font-size-12 font-bold margin-top-xxs margin-right-s"
      >
        {{ submissionStatus }}
      </div>
      <div class="id-container eclipse-content margin-top-xxxs">
        <ng-container>
          <span class="id-title">ID:</span>
          <span #textToCopy class="eclipse-content"
            >{{ submissionDetails?.submissionId }}
          </span>
          <span
            class="dds-icon dds-icon_copy__l__stroke icon"
            (click)="copyToClipboard(textToCopy.textContent)"
          ></span>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="margin-top-m margin-right-m margin-bottom-m">
    <dds-accordion [isMulti]="true">
      <dds-accordion-item [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Submission details</span>
          </div>
        </span>

        <span class="text-align-left" [autosave-module]="savedetails" content>
          <div
            *ngIf="
              permissionObj[
                'isGlobalEngSubmissionDetailsSubmissionTitleVisible'
              ]
            "
            class="edit-title margin-bottom-m"
          >
            <div class="eclipse-small-header-grey text-align-left">
              <span *ngIf="!engagementTitle">
                <img
                  class="margin-right-xxs"
                  src="\assets\images\draftIcon.svg"
                  alt="yellow-icon" /></span
              >Engagement title:
            </div>
            <div *ngIf="isEngagementTitleEditable; else displayTitle">
              <dds-textarea
                autosave
                [(ngModel)]="engagementTitle"
                [disabled]="
                  !permissionObj[
                    'isGlobalEngSubmissionDetailsSubmissionTitleEnable'
                  ]
                "
              ></dds-textarea>
            </div>
            <ng-template #displayTitle>
              <span class="font-size-14">{{
                engagementTitle ?? emptyFeild
              }}</span>
              <span
                #editButton
                *ngIf="
                  permissionObj[
                    'isGlobalEngSubmissionDetailsSubmissionTitleEnable'
                  ]
                "
                (click)="makeFeildEditable('engagementTitle')"
                class="dds-icon dds-icon_edit__s__filled edit-icon"
              ></span>
            </ng-template>
          </div>

          <div class="grid-container">
            <div
              *ngIf="
                permissionObj['isGlobalEngSubmissionDetailsChangeCodeVisible']
              "
              class="grid-item"
            >
              <div>
                <div class="eclipse-small-header-grey text-align-left">
                  <span *ngIf="!WBSChargeCode || !isWbsAvailable">
                    <img
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                      alt="yellow-icon"
                  /></span>
                  Charge code (WBS):
                </div>
                <div
                  class="edit-title wbsWidth"
                  *ngIf="isWBSEditable; else displayWBS"
                >
                  <dds-suggestions-tags-input
                    [ariaLabel]="'Archive'"
                    [placeholder]="'Search for WBS'"
                    [searchList]="wbsSearchList"
                    (valueChanged)="changeWbs()"
                    [(ngModel)]="tempWBSChargeCode"
                    [disabled]="
                      !permissionObj[
                        'isGlobalEngSubmissionDetailsChangeCodeEnable'
                      ]
                    "
                  >
                  </dds-suggestions-tags-input>
                </div>
                <ng-template #displayWBS>
                  <span class="font-size-14">{{
                    WBSChargeCode ?? emptyFeild
                  }}</span>
                  <span
                    #editButton
                    *ngIf="
                      permissionObj[
                        'isGlobalEngSubmissionDetailsChangeCodeEnable'
                      ]
                    "
                    (click)="makeFeildEditable('WBSChargeCode')"
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                  ></span>
                  <span>
                    <dds-checkbox
                      [value]="isWbsAvailable"
                      (stateChanged)="wbsCheckboxChanged()"
                      class="margin-left-s"
                      [label]="'WBS Pending'"
                    ></dds-checkbox>
                  </span>
                </ng-template>
              </div>
            </div>
            <div
              *ngIf="
                permissionObj[
                  'isGlobalEngSubmissionDetailsWBSDescriptionVisible'
                ]
              "
              class="grid-item"
            >
              <div *ngIf="WBSDescription">
                <div class="eclipse-small-header-grey text-align-left">
                  WBS description
                </div>
                <span class="font-size-14">{{ WBSDescription }}</span>
              </div>
            </div>
            <div
              *ngIf="
                permissionObj['isGlobalEngSubmissionDetailsArchiveVisible']
              "
              class="grid-item"
            >
              <div *ngIf="WBSChargeCode">
                <div class="eclipse-small-header-grey text-align-left">
                  <span *ngIf="!archiveSelectedId">
                    <img
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                      alt="yellow-icon" /></span
                  >Archive:
                </div>
                <div
                  class="edit-title wbsWidth"
                  *ngIf="isArchiveEditable; else displayArchive"
                >
                  <dds-select
                    [list]="archiveList"
                    [(ngModel)]="archiveSelectedId"
                    [disabled]="
                      !permissionObj[
                        'isGlobalEngSubmissionDetailsArchiveEnable'
                      ]
                    "
                    (ngModelChange)="changeArchive()"
                  >
                  </dds-select>
                </div>
                <ng-template #displayArchive>
                  <span class="font-size-14">{{
                    archiveSelected ?? emptyFeild
                  }}</span>
                  <span
                    #editButton
                    *ngIf="
                      permissionObj['isGlobalEngSubmissionDetailsArchiveEnable']
                    "
                    (click)="makeFeildEditable('archive')"
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                  ></span>
                </ng-template>
              </div>
            </div>
            <div>
              <div class="eclipse-small-header-grey text-align-left">
                <span *ngIf="!efaSelected">
                  <img
                    class="margin-right-xxs"
                    src="\assets\images\draftIcon.svg"
                    alt="yellow-icon" /></span
                >EFA:
              </div>
              <div
                class="edit-title wbsWidth"
                *ngIf="isefaEdiitable; else displayEFA"
              >
                <dds-select
                  (ngModelChange)="efaseleced()"
                  [list]="efaList"
                  [(ngModel)]="efaSelected"
                >
                </dds-select>
              </div>
              <ng-template #displayEFA>
                <span class="font-size-14">{{
                  efaSelected ?? emptyFeild
                }}</span>
                <span
                  #editButton
                  (click)="makeFeildEditable('efa')"
                  class="dds-icon dds-icon_edit__s__filled edit-icon"
                ></span>
              </ng-template>
            </div>
          </div>
        </span>
      </dds-accordion-item>
      <dds-accordion-item [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Documents</span>
          </div>
        </span>
        <span content>
          <app-engagement-documents
            [submissionId]="submissionId"
            [submissionTypeId]="submissionTypeId"
            [permissionObj]="permissionObj"
          ></app-engagement-documents>
        </span>
      </dds-accordion-item>
      <dds-accordion-item [isOpen]="true">
        <span header class="accordion-header">
          <div class="section-header">
            <span class="eclipse-section-header">Notes</span>
          </div>
        </span>
        <span [autosave-module]="savedetails" content>
          <div class="accordion-content">
            <div
              class="text-align-left eclipse-content-grey margin-top-s margin-bottom-s"
            >
              Use this field to provide notes about any relevant history,
              unusual aspects, or unique characteristics of the engagement.
            </div>
            <span
              *ngIf="checkStatusDiscontinued() && showDiscontinueMessage"
              class="padding-bottom-m text-align-left"
            >
              <div class="sub-text-section margin-top-xs">
                <span class="icon-s icon-alert"></span>
                <span>
                  <div class="eclipse-header-grey">Submission Discontinued</div>
                  <div
                    class="vertical-line-green-s absolute padding-bottom-xxs"
                    *ngIf="isShowMoreDocumentNotes"
                  ></div>
                </span>
              </div>
              <div class="padding-left-m">
                <span>
                  <div class="margin-top-xs" *ngIf="isShowMoreDocumentNotes">
                    <div
                      class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                    >
                      {{ discontinueActionMessage?.resetedOn }} by
                      {{ discontinueActionMessage?.resetedBy }}
                    </div>
                    <div
                      class="eclipse-content-grey font-size-11 padding-bottom-xxs"
                    >
                      Reason: {{ discontinueActionMessage?.reason }}
                    </div>
                  </div>
                </span>
                <dds-button
                  class="showMoreCls"
                  [theme]="showMoreOptions.theme ?? ''"
                  [kind]="showMoreOptions.kind ?? ''"
                  [size]="showMoreOptions.size ?? ''"
                  [width]="showMoreOptions.width ?? ''"
                  [icon]="showMoreOptions.icon ?? ''"
                  [isIconLeft]="showMoreOptions.isIconLeft ?? false"
                  [role]="showMoreOptions.role ?? ''"
                  [ariaLabel]="showMoreText"
                  [customClass]="showMoreOptions.customClass ?? ''"
                  (clicked)="clickToShowDocumentMoreLepMessage()"
                  >{{ showMoreText }}</dds-button
                >
              </div>
            </span>
            <div>
              <div class="row margin-bottom-s">
                <div class="col-12">
                  <div class="textarea-container">
                    <div class="row">
                      <div class="col-12">
                        <dds-textarea
                          autosave
                          [(ngModel)]="notes"
                          [maxLength]="5000"
                          [maxLengthPosition]="'top-right'"
                          name="notesAndFeedback"
                        ></dds-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </dds-accordion-item>
    </dds-accordion>
  </div>
</div>
