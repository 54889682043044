<div class="styled-box" *ngIf="!isArchiveUploaded">
  <span>Upload the final document. </span>
</div>
<div
  class="uploader-class text-align-left"
  [ngClass]="{
    'hide-uploader':
      !permissionObj['isGlobalEngSubmissionDetailsDragAndDropVisible']
  }"
>
  <dds-uploader
    *ngIf="permissionObj['isGlobalEngSubmissionDetailsDragAndDropVisible']"
    id="global-eng-uploader"
    [heading]="uploadAreaOptions.heading ?? emptyString"
    [description]="uploadAreaOptions.description ?? emptyString"
    [dragAndDropText]="uploadAreaOptions.dragAndDropText ?? emptyString"
    [isDragAndDrop]="uploadAreaOptions.isDragAndDrop ?? false"
    [isMultiple]="uploadAreaOptions.isMultiple ?? true"
    [acceptFormats]="uploadAreaOptions.acceptFormats ?? emptyString"
    [acceptExtensions]="uploadAreaOptions.acceptExtensions ?? emptyString"
    [restrictedFormats]="uploadAreaOptions.restrictedFormats ?? emptyString"
    [restrictedExtensions]="
      uploadAreaOptions.restrictedExtensions ?? emptyString
    "
    [exceptSymbols]="uploadAreaOptions.exceptSymbols ?? emptyString"
    [btnLabel]="uploadAreaOptions.btnLabel ?? emptyString"
    [isInverse]="uploadAreaOptions.isInverse ?? false"
    [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind ?? emptyString"
    [disabled]="!permissionObj['isGlobalEngSubmissionDetailsDragAndDropEnable']"
    (uploadedItems)="openUploadModel($event)"
  ></dds-uploader>

  <dds-button
    class="absolute button-style margin-left-xs"
    [theme]="buttonOptions.theme ?? emptyString"
    [kind]="buttonOptions.kind ?? emptyString"
    [size]="buttonOptions.size ?? emptyString"
    [width]="buttonOptions.width ?? emptyString"
    (click)="openDocumentLinkModal()"
    >Add link</dds-button
  >
</div>

<app-engagement-table
  *ngIf="permissionObj['isGlobalEngSubmissionDetailsDocumentsTableVisible']"
  [submissionId]="submissionId"
  [submissionTypeId]="submissionTypeId"
  [permissionObj]="permissionObj"
  (onDocumentChange)="onDocumentChange($event)"
></app-engagement-table>
