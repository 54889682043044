<div class="dds-modal-overlay" (click)="close()"></div>

<div ddsResize class="dds-modal dds-modal_md">
    <div class="dds-modal__header">
        <span class="dds-modal__title">Edit document</span>
        <dds-button size="sm" icon="dds-icon_close" kind="silent" theme="dark" ariaLabel="Close modal"
            class="dds-modal__close" (clicked)="close()"></dds-button>
    </div>

    <div class="dds-modal__body">
        <div class="inputs-container">
            <div class="margin-bottom-l">
                <p class="input-label font-size-12">File title</p>
                <dds-input [(ngModel)]="documentTitle" [placeholder]="'Add file title'">
                </dds-input>
            </div>

            <div class="row margin-bottom-l">
                <p class="input-label font-size-12">Type</p>
                <dds-select [(ngModel)]="documentTypeDropdownData" [list]="documentTypeItemsList"
                    ></dds-select>
            </div>
            <div class="row row margin-bottom-l">
                <p class="input-label font-size-12">Status</p>
                <dds-select [list]="documentStatusItemList" [(ngModel)]="documentStatusDropdownData"
                    ></dds-select>
            </div>

            <div class="comment margin-bottom-l">
                <p class="input-label font-size-12">Comment (optional)</p>
                <dds-textarea [placeholder]="'Text'" [(ngModel)]="documentComment">
                </dds-textarea>
            </div>
        </div>
    </div>

    <div class="dds-modal__footer">
        <div class="dds-modal__footer-content dds-modal__footer-content_left">
            <dds-button class="dds-modal__footer-item" (clicked)="closeAll()" (keyboardClicked)="closeAll()"
                [disabled]="isSaveDisabledCheck() || !documentTitle">Save</dds-button>
            <dds-button class="dds-modal__footer-item" kind="secondaryLoud" (clicked)="close()"
                (keyboardClicked)="close()">Close</dds-button>
        </div>
    </div>
</div>