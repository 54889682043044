import { Component, OnInit } from '@angular/core';
import { SubmissionService } from '../../http/intake/submission.service';
import { AuditRequestParams } from '../../common/models/common-models';
import { Subject, takeUntil } from 'rxjs';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { HeaderDescriptionDataModel } from '../../common/models/audit-trail.model';
import { ExternalCommunicationService } from '../../http/external-communication/external-communication.service';
import { GlobalEngagementService } from '../../http/global-engagement/global-engagement.service';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrl: './audit-trail.component.less',
})
export class AuditTrailComponent implements OnInit {
  submissionId: number | null = null;
  opportunityNumber!: string;
  readonly #destroy$: Subject<void> = new Subject<void>();
  titleValue: string | null = null;
  submissionTypeValue: string | null = null;
  clientNameValue: string | null = null;
  opportunityNameValue: string | null = null;
  isExtCommSubmission: boolean = false;
  isGlobalEngagement: boolean = false;

  constructor(
    private readonly submissionService: SubmissionService,
    private readonly extCommService: ExternalCommunicationService,
    private readonly globalEngagementService: GlobalEngagementService
  ) {}
  ngOnInit(): void {
    history.state.extComm
      ? (this.isExtCommSubmission = true)
      : (this.isExtCommSubmission = false);
    history.state.globalEngagements
      ? (this.isGlobalEngagement = true)
      : (this.isGlobalEngagement = false);
    document.title = BrowserTabConstant.Browser_TabName_AuditTrail;
    if (this.isExtCommSubmission) {
      this.extCommService.currentSubmissionId$
        .pipe(takeUntil(this.#destroy$))
        .subscribe((request: number) => {
          this.submissionId = request;
        });
    } else if (this.isGlobalEngagement) {
      this.globalEngagementService.currentSubmissionId$
        .pipe(takeUntil(this.#destroy$))
        .subscribe((request: AuditRequestParams) => {
          this.submissionId = request.submissionid;
          this.opportunityNumber = request.opportunitynumber;
        });
    } else {
      this.submissionService.currentSubmissionId$
        .pipe(takeUntil(this.#destroy$))
        .subscribe((request: AuditRequestParams) => {
          this.submissionId = request.submissionid;
          this.opportunityNumber = request.opportunitynumber;
        });
    }
  }

  headerData(data: HeaderDescriptionDataModel): void {
    this.titleValue = data.submissionTitle;
    this.submissionTypeValue = data.submissionTypeCode;
    this.clientNameValue = data.clientName;
    this.opportunityNameValue = data.opportunityName;
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
