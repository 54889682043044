import { Component } from '@angular/core';
import { ExternalCommunicationService } from '../../http/external-communication/external-communication.service';
import { Subject, takeUntil } from 'rxjs';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidateSubmissionCompleteModel } from '../../common/models/external-communication.model';

@Component({
  selector: 'app-validate-redirect',
  templateUrl: './validate-redirect.component.html',
  styleUrl: './validate-redirect.component.less',
})
export class ValidateRedirectComponent {
  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  submissionId: number | null = null;
  constructor(
    private progressIndicator: ProgressIndicatorService,
    private externalCommService: ExternalCommunicationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.submissionId = params['submissionId'];
      if (this.submissionId) {
        this.progressIndicator.show();
        this.validateCompleteAndNavigate(this.submissionId);
      } else {
        this.router.navigate(['/externalCommunications/error']);
      }
    });
  }

  validateCompleteAndNavigate(id: number): void {
    this.externalCommService
      .validateSubmissionToComplete(id)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: ValidateSubmissionCompleteModel[]) => {
          this.progressIndicator.hide();
          if (response[0].isValidationSuccessful) {
            this.router.navigate(['externalCommunications/success'],
              {
                queryParams: {
                  submissionId: this.submissionId,
                  submissionTypeId: response[0].communicationTypeId,
                },
              }
            );
          } else {
            this.router.navigate(['externalCommunications/error'],
              {
                queryParams: {
                  submissionId: this.submissionId,
                  submissionTypeId: response[0].communicationTypeId,
                },
              }
            );
          }
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('Error fetching data', err);
        },
      });
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
