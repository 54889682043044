import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import {
  LEP_TABLE_COLUMNS,
  PocTooltipOptions,
  TEAM_MEMBER_COLUMN,
} from './manage-team-member-popup-helper';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import {
  IntakeOpportunitySummaryTeamManagement,
  leadEngagementPartnerTeamManagement,
  teamMembersTeamManagement,
} from '../../../../common/models/opportunity-details.model';
import { IntakeConstant } from '../../../constants/intake.constant';

@Component({
  selector: 'app-manage-team-member-popup',
  templateUrl: './manage-team-member-popup.component.html',
  styleUrl: './manage-team-member-popup.component.less',
})
export class ManageTeamMemberPopupComponent implements OnInit {
  public lepTableColumns: Column[] = LEP_TABLE_COLUMNS;
  public teamMembersColumns: Column[] = TEAM_MEMBER_COLUMN;

  @Input() dataOpportunityId: string = '';
  tooltipText: string =
    'Point of Contact (POC) is the key team member, who would be contacted for any questions regarding this submission. POC can be changed at any time.';
  showLepNameInputBox: boolean = false;
  showPeoplePicker: boolean = true;
  teamManagementData: IntakeOpportunitySummaryTeamManagement | null = null;
  lepTableData!: leadEngagementPartnerTeamManagement;
  teamMemberData: teamMembersTeamManagement[] | null = [];
  lepName: string | null | undefined = '';
  tempLepName: string | null | undefined = this.lepName;
  pocTooltip: TooltipOptions = PocTooltipOptions;
  selectedPoc: string = '';
  unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    private readonly modalService: ModalService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly submissionService: SubmissionService
  ) {}

  ngOnInit(): void {
    this.loadLepTableData();
    if (this.teamMemberData === null) {
      this.teamMemberData = [];
    }
  }

  close(): void {
    this.modalService.close();
  }

  editLepName(): void {
    this.showLepNameInputBox = true;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (this.showLepNameInputBox && !target.closest('#lep-name-editor')) {
      this.saveTitle();
    }
  }

  loadLepTableData(): void {
    this.progressIndicator.show();
    this.submissionService
      .getTeamMembers(this.dataOpportunityId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: IntakeOpportunitySummaryTeamManagement) => {
          {
            if (response) {
              this.teamManagementData = response;
              this.lepTableData = response.leadEngagementPartner;
              this.lepName = this.lepTableData?.fullName;
              this.tempLepName = this.lepName;
              this.teamMemberData =
                response.teamMembers === null ? [] : response.teamMembers;
            }
          }
          this.progressIndicator.hide();
        },
        error: (err) => {
          console.error('Error fetching submission status', err);
          this.progressIndicator.hide();
        },
      });
  }

  onLepSelected(event: any): void {
    this.submissionService
      .updateLepDataTeamManagement(
        this.dataOpportunityId,
        event.reviewerId,
        event.displayName
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          console.log('response', response);
        },
        error: (err) => {
          console.error('Error fetching submission status', err);
        },
      });
    this.tempLepName =
      event.fullname !== 'undefined undefined'
        ? event.fullname
        : event.displayName;
    this.saveTitle();
    setTimeout(() => {
      this.loadLepTableData(); //gives old data not the updated one in dev api
    }, 1000);
  }

  saveTitle(): void {
    this.showLepNameInputBox = false;
  }

  addTeamMember(member: any): void {
    this.showPeoplePicker = false;
    this.progressIndicator.show();
    setTimeout(() => {
      this.showPeoplePicker = true;
      this.progressIndicator.hide();
    }, 100);

    const newMember: teamMembersTeamManagement = {
      employeeId: member.reviewerId,
      fullName: member.fullname,
      primaryIndustry: null,
      offeringPortfolio: null,
      primaryOffering: null,
      isPOC: false,
      enableNotification: false,
      active: true,
    };
    if (this.teamMemberData !== null) {
      if (
        !this.teamMemberData.some(
          (member) => member.employeeId === newMember.employeeId
        )
      ) {
        this.teamMemberData.push(newMember);
        this.updateTeamMembers();
      }
    }
  }


  pocStateChanged(employeeId: string): void {
    this.selectedPoc = employeeId;
    if (this.teamMemberData !== null) {
      this.teamMemberData = this.teamMemberData.map((member) => {
        return {
          ...member,
          isPOC: member.employeeId === employeeId,
        };
      });
      this.updateTeamMembers();
    }
  }

  toggleRow(rowData: teamMembersTeamManagement): void {
    if (this.teamMemberData !== null) {
      this.teamMemberData = this.teamMemberData.map((member) => {
        if (member.employeeId === rowData.employeeId) {
          return {
            ...member,
            enableNotification: !member.enableNotification,
          };
        }
        return member;
      });
      this.updateTeamMembers();
    }
  }

  removeTeamMember(rowData: teamMembersTeamManagement) {
    if (this.teamMemberData !== null) {
      this.teamMemberData = this.teamMemberData.map((member) => {
        if (member.employeeId === rowData.employeeId) {
          return {
            ...member,
            active: false,
          };
        }
        return member;
      });
      this.updateTeamMembers();
    }
  }

  updateTeamMembers(): void {
    this.submissionService
      .updateTeamMembersTeamManagement(
        this.dataOpportunityId,
        this.teamMemberData
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          console.log(response);
        },
        error: (err) => {
          console.error('Error fetching submission status', err);
        },
      });
    setTimeout(() => this.loadLepTableData(), IntakeConstant.TIMEOUT_TIMER_BIGGEST);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
