import { RecentlyViewedComponent } from './../recently-viewed/recently-viewed.component';
import { Component } from '@angular/core';
import { ReviewerDashboardWebapiService } from '../../http/dashboard/reviewer-webapi.service';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { environment } from '../../../environment/environment';
import {
  ButtonOptionsType,
  DashboardDataCollection,
  DashboardGridLevelFilter,
  DashboardTileCode,
  GridColumn,
  TabWithCounterType,
} from '../../common/models/start-page/start-page.model';
import {
  ReviewerDashboardData,
  ReviewerDashboardTilesCount,
  ReviewerTileCode,
  ReviewerTileCodeDetail,
  ReviewerTileCodeDetails,
} from '../../common/models/start-page/reviewer-dashboard.model';
import {
  buttonOptions,
  CCOverviewColumns,
  DashboardConstant,
  GridColumns,
  NCAPendingApprovalColumns,
  PendingReviewsColumns,
  PendingReviewsOtherColumns,
  RecentReviewsColumns,
  ReviewerExtCommColumns,
  reviewerExtCommTabs,
  reviewerExtcommTabsList,
  ReviewerGlobalEngColumns,
  SelfReviewsColumns,
} from '../dashboard.helper';

import { Router } from '@angular/router';
import {
  defaultGridColumns,
  reviewerSortParamaterCode,
} from '../dashboard.helper';
import { StatusEnum } from '../../common/constants/status-enum';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Subject, takeUntil } from 'rxjs';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import { FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { ParsedRules, SubmissionType } from '../../common/models/common-models';
import { HttpBackend, HttpErrorResponse } from '@angular/common/http';
import { ToastOptions } from '@usitsdasdesign/dds-ng/toast';
import { ToastMessageOptions } from '../../external-communications/external-communications.helper';
import { ExternalCommunicationService } from '../../http/external-communication/external-communication.service';
import { UserService } from '../../http/user.service';
import { GenericResponse } from '../../common/models/external-communication.model';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import {
  MatrixKeys,
  PermissionTypes,
} from '../../common/constants/security-matrix';
import { RoleEnum, RoleIdMapper } from '../../intake/constants/Role.enum';

@Component({
  selector: 'app-reviewer-dashboard',
  templateUrl: './reviewer-dashboard.component.html',
  styleUrl: './reviewer-dashboard.component.less',
})
export class ReviewerDashboardComponent {
  public reviewerTiles = ReviewerTileCode;
  public originalReviewerTileCodeDetails: ReviewerTileCodeDetail[] = Array.from(
    ReviewerTileCodeDetails.values()
  );
  public reviewerTileCodeDetails: ReviewerTileCodeDetail[] = Array.from(
    ReviewerTileCodeDetails.values()
  );
  public selectedTileCode: string = '';
  public dashboard: string = IntakeConstant.REVIEWER_DASHBOARD;
  public employeeId: string = '00000000';
  public reviewerDashboardTilesCountModel: ReviewerDashboardTilesCount | any;
  public sortParamaterCode: number | null = 1;
  public isAscending: boolean = true;
  public countforPagination: number = 0;
  public gotoPage: number = 1;
  public itemsPerPage: number = 10;
  public itemsPerPageOptions: number[] = [10, 25, 50];
  public gridTitle: string = '';
  public model: ReviewerDashboardData[] = [];
  public isTileCountResponse: boolean = false;
  public reviewerDashboardfilters: DashboardGridLevelFilter[] = [];
  public currentDashboardTileCode = DashboardTileCode.Reviewer;
  public rootUrl: string = environment.rootUrl;
  public gridColumns: GridColumn[] = [];
  public submissionTypeData: FilterItem[] = [];
  options: ButtonOptions = buttonOptions;
  public buttonOptionsList: ButtonOptionsType[] = [];
  public defaultGridColumns: GridColumn[] = defaultGridColumns;
  public columnName = GridColumns;
  public filterDataList: { name: string; data: FilterItem[] }[] = [];
  unsubscriber$: Subject<void> = new Subject<void>();
  tabsList: TabWithCounterType[] = reviewerExtcommTabsList;
  selectedTabCode: string = this.tabsList[0]?.tabCode;
  toastMessageOptions: ToastOptions = ToastMessageOptions;
  _blank: string = '';
  currentLoggedInUserId: string = '';
  loggedInUserRoles: string[] = [];

  private securityReviewerCCDueTile: string =
    'ClientStatus.ReviewerDashboardCCDueTile';
  isUnassignedExtCommPermission: boolean = false;
  isAllSubmissionExtCommPermission: boolean = false;
  isReviewerExtCommTileVisibile: boolean = false;
  isReviewerCCDueTileVisible: boolean = false;
  isReviewerGlobalEngTileVisibile: boolean = false;

  constructor(
    private readonly modalService: ModalService,
    private readonly reviewerWebapiService: ReviewerDashboardWebapiService,
    private readonly securityWebapiService: SecurityWebapiService,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly progressIndicatorService: ProgressIndicatorService,
    private readonly router: Router,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_Dashboard;
    this.reviewerDashboardTilesCountModel = {
      pendingReviewsCount: 0,
      pendingReviewsOthersCount: 0,
      ncaPendingApprovalCount: 0,
      ccDueCount: 0,
      recentReviewsCount: 0,
      selfReviewChangesCount: 0,
      externalCommunicationsCount: 0,
      pendingECCount: 0,
      unassignedECCount: 0,
      allSubmissionsECCount: 0,
    };
    this.removeTilesWithoutPermission(); // To remove the tiles on initial load until we get permissions
    this.fetchSubmissionType();
    this.getRolesAndPermissions();

    this.progressIndicatorService.show();
    this.selectedTileCode = this.reviewerTiles.PendingReviews;

    this.gridTitle =
      this.reviewerTileCodeDetails.find(
        (tile: ReviewerTileCodeDetail) =>
          tile.tileCode === this.selectedTileCode
      )?.title || '';

    this.getTilesCount();

    this.sortParamaterCode = reviewerSortParamaterCode[this.selectedTileCode];
    this.gotoPage = 1;
    this.onPageChanged(this.gotoPage);
  }

  getRolesAndPermissions(): void {
    this.userService.currentLoggedInUser$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((user: any) => {
        if (user) {
          this.currentLoggedInUserId = user?.employeeId;
        }
      });

    this.securityWebapiService.data
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (rules) => {
          if (!rules.empty) {
            this.isUnassignedExtCommPermission =
              rules[MatrixKeys.EXT_COM_Unassigned_ReviewerDashboard]?.[
                PermissionTypes.Visible
              ] ?? false;

            this.isAllSubmissionExtCommPermission =
              rules[MatrixKeys.EXT_COM_AllSubmissions_ReviewerDashboard]?.[
                PermissionTypes.Visible
              ] ?? false;

            this.isReviewerExtCommTileVisibile =
              rules[MatrixKeys.EXT_COM_ReviewerDashboard]?.Visible ?? false;

            this.isReviewerCCDueTileVisible =
              rules[this.securityReviewerCCDueTile]?.Visible ?? false;

            this.isReviewerGlobalEngTileVisibile =
              rules[MatrixKeys.GLOBAL_ENG_ReviewerDashboard]?.Visible ?? false;

            this.removeTilesWithoutPermission();
            if (this.isReviewerExtCommTileVisibile) this.setExtCommTabs();
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error('Error fetching security rules:', error);
        },
      });
  }

  setExtCommTabs(): void {
    this.tabsList = reviewerExtcommTabsList.map((tab: TabWithCounterType) => ({
      ...tab,
      count:
        this.reviewerDashboardTilesCountModel?.[
          tab?.countCode ?? this._blank
        ] ?? 0,
    }));

    if (!this.isUnassignedExtCommPermission) {
      this.tabsList = this.tabsList.filter(
        (tab) => tab.tabCode !== reviewerExtCommTabs.UNASSIGNED.tabCode
      );
    }
    if (!this.isAllSubmissionExtCommPermission) {
      this.tabsList = this.tabsList.filter(
        (tab) => tab.tabCode !== reviewerExtCommTabs.ALLSUBMISSION.tabCode
      );
    }
  }

  getTilesCount(): void {
    this.reviewerWebapiService
      .getReviewerDashboardTilesCount()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: ReviewerDashboardTilesCount) => {
          this.isTileCountResponse = true;
          this.reviewerDashboardTilesCountModel = response;
          if (this.isReviewerExtCommTileVisibile) this.setExtCommTabs();
          this.progressIndicatorService.hide();
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error while fetching tile count', err);
        },
      });
  }

  fetchSubmissionType(): void {
    this.reviewerWebapiService
      .getSubmissionTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: SubmissionType[]) => {
          this.submissionTypeData = response.map((item: SubmissionType) => {
            return {
              title: item.submissionTypeCode || IntakeConstant.EMPTY_SPACE,
              name: item.submissionTypeCode || IntakeConstant.EMPTY_SPACE,
              isChecked: true,
            };
          });
          this.setFilterData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(
            'An error occurred while fetching submission types:',
            err
          );
        },
      });
  }

  onTileSelected(selectedTileCode: string) {
    const sameTileClicked: boolean = selectedTileCode === this.selectedTileCode;
    this.isTileCountResponse = true;
    this.selectedTileCode = selectedTileCode;
    this.gridTitle =
      this.reviewerTileCodeDetails.find(
        (tile: ReviewerTileCodeDetail) =>
          tile.tileCode === this.selectedTileCode
      )?.title || '';

    if (!sameTileClicked) {
      // Reset the filters and sorting state
      this.sortParamaterCode = reviewerSortParamaterCode[this.selectedTileCode];
      this.isAscending = true;
      this.reviewerDashboardfilters = [];
      // Reset the page number
      this.gotoPage = 1;
      this.onPageChanged(this.gotoPage);
    }
  }

  refreshGrid() {
    // TODO: Need to set model as [] to remove the existing data when we switch tab
    this.progressIndicatorService.show();
    this.reviewerWebapiService
      .getReviewerDashBoardGridData(
        this.selectedTileCode,
        this.selectedTabCode,
        this.gotoPage,
        this.itemsPerPage,
        this.sortParamaterCode,
        this.isAscending,
        this.reviewerDashboardfilters
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((response: DashboardDataCollection<ReviewerDashboardData>) => {
        this.model = response.dataList;
        this.countforPagination = response.totalDataCount;
        this.isTileCountResponse ? this.progressIndicatorService.hide() : true;
      });

    this.gridColumns = [...this.defaultGridColumns];

    if (this.selectedTileCode === this.reviewerTiles.PendingReviews) {
      this.gridColumns = PendingReviewsColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }
    if (this.selectedTileCode === this.reviewerTiles.PendingReviewsOthers) {
      this.gridColumns = PendingReviewsOtherColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.reviewerTiles.NCAPendingApproval) {
      this.gridColumns = NCAPendingApprovalColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.reviewerTiles.CCDueNow) {
      this.gridColumns = CCOverviewColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.reviewerTiles.RecentReviews) {
      this.gridColumns = RecentReviewsColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.reviewerTiles.SelfReviewChanges) {
      this.gridColumns = SelfReviewsColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.reviewerTiles.ExtComm) {
      this.gridColumns = ReviewerExtCommColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.reviewerTiles.GlobalEng) {
      this.gridColumns = ReviewerGlobalEngColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    // Adjust column widths dynamically excluding the expand-collapse icon column
    const totalColumns = this.gridColumns.length;
    let availableWidth;

    if (totalColumns <= 8) {
      availableWidth = 90;
    } else if (totalColumns <= 10) {
      availableWidth = 88;
    } else {
      availableWidth = 80;
    }

    let baseWidth = availableWidth / totalColumns;
    this.gridColumns.forEach((column: GridColumn) => {
      if (
        column.name !== this.columnName.ActionByName &&
        column.name !== this.columnName.ClientName &&
        column.name !== this.columnName.ParentClientName &&
        column.name != this.columnName.SubmissionTitle &&
        column.name != this.columnName.Focus &&
        column.name != this.columnName.SubmissionStatusName &&
        column.name != this.columnName.ContinuanceStatus &&
        column.name != this.columnName.OpenWBS &&
        column.name != this.columnName.Actions &&
        column.name !== this.columnName.LEP
      ) {
        column.width = `${baseWidth}%`;
      } else {
        column.width = column.minWidth;
      }

      if (column.name == this.columnName.Focus) {
        column.width = '4rem';
      }

      if (this.selectedTileCode == this.reviewerTiles.CCDueNow) {
        column.width =
          column.name == 'clientName' || column.name == 'continuanceStatus'
            ? '8rem'
            : column.name == 'openOpportunities'
            ? '8.90909%'
            : column.width;
      }
    });

    this.getActionButtons();
  }

  onPageChanged(value: number) {
    this.gotoPage = value - 1;
    this.refreshGrid();
  }

  onValueChanged(item: number) {
    this.isTileCountResponse = true;
    this.itemsPerPage = item;
    this.gotoPage = 0;
    this.refreshGrid();
  }

  gridUpdatedEvent(event: {
    sortParamaterCode: number | null;
    isAscending: boolean;
    dashboardfilters: any[];
  }): void {
    const shouldRefreshGrid =
      this.sortParamaterCode !== event.sortParamaterCode ||
      this.isAscending !== event.isAscending ||
      JSON.stringify(this.reviewerDashboardfilters) !==
        JSON.stringify(event.dashboardfilters);

    this.sortParamaterCode = event.sortParamaterCode;
    this.isAscending = event.isAscending;
    this.reviewerDashboardfilters = event.dashboardfilters;
    if (this.reviewerDashboardfilters.filter((x) => x.filtertypecode == 101)) {
      //do not offset if the filteration is based on jupiterid
      this.gotoPage = 0;
    }
    if (shouldRefreshGrid) {
      this.refreshGrid();
    }
  }

  getActionButtons(): void {
    this.buttonOptionsList = [
      {
        label: IntakeConstant.START_REVIEW,
        action: IntakeConstant.START_REVIEW_ACTION,
        condition: this.selectedTileCode === this.reviewerTiles.PendingReviews,
      },
      {
        label: 'Start Review',
        action: 'StartReview',
        condition: 'enableStartReview',
      },
      {
        label: 'Assign to me',
        action: 'AssignToMe',
        condition: 'enableAssignToMe',
      },
    ];
  }

  buttonSelected(event: { value: string; rowItem: any }): void {
    const { value, rowItem } = event;
    if (this.selectedTileCode !== this.reviewerTiles.ExtComm) {
      if (value === IntakeConstant.START_REVIEW_ACTION) {
        this.goToSubmission(rowItem?.jupiterId, rowItem?.submissionId);
      }
    } else {
      this.extCommButtonSelected(value, rowItem);
    }
  }

  extCommButtonSelected(value: string, rowItem: any) {
    if (value === IntakeConstant.START_REVIEW_ACTION) {
      if (rowItem?.communicationTypeId) {
        this.goToExtCommSubmission(
          rowItem?.communicationTypeId,
          rowItem?.submissionId
        );
      }
    } else {
      this.updateRMSupport(rowItem);
    }
  }

  public goToSubmission(jupiterId: string, submissionId: string): void {
    this.router.navigate(['/submission/opportunity-details', jupiterId], {
      state: {
        navigatingFromDashboard: true,
        submissionID: submissionId,
      },
    });
  }

  goToExtCommSubmission(
    communicationTypeId: number,
    submissionId: number
  ): void {
    this.router.navigate(
      ['/externalCommunications/details/', communicationTypeId],
      {
        queryParams: { submissionId: submissionId },
        state: {
          navigatingFromDashboard: true,
          submissionID: submissionId,
        },
      }
    );
  }

  openModalForRecentlyViewed(modalBtn?: ButtonComponent): void {
    let openRecentViewModal = this.modalService.open(RecentlyViewedComponent, {
      isFooter: false,
      size: 'lg',
      isInverse: false,
    });
    openRecentViewModal
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          if (modalBtn) {
            modalBtn.focus();
          }
        },
        error: (err: Error) => {
          console.error('An error occurred:', err);
        },
      });
  }

  setFilterData(): void {
    this.filterDataList = [
      {
        name: 'reviewerFilterData',
        data: [
          { title: 'Due Now', name: 'CcDueDate', isChecked: true },
          { title: 'Expired', name: 'Expired', isChecked: true },
          { title: 'Discontinued', name: 'Discontinue', isChecked: true },
          { title: 'Accepted', name: 'Accepted', isChecked: true },
          {
            title: 'Serve with conditions',
            name: 'ServeWithConditions',
            isChecked: true,
          },
          { title: 'Do not serve', name: 'DoNotServe', isChecked: true },
        ],
      },
      {
        name: 'submissionTypeFilterData',
        data: this.submissionTypeData,
      },
    ];
  }

  updateRMSupport(rowItem: any): void {
    // TODO: Remove localStorage usage once API is provided
    const requestBody = {
      submissionid: rowItem?.submissionId,
      submissionapprovers: [
        {
          submissionApproverId: null,
          reviewerId: this.currentLoggedInUserId ?? null,
          displayName: localStorage.getItem('userProfileName') ?? null,
          emailId: localStorage.getItem('userProfileId') ?? null,
          location: null,
          reviewerRoleId: RoleIdMapper[RoleEnum.RM_SUPPORT_LOCAL],
          isRequired: false,
          roleName: RoleEnum.RM_SUPPORT_LOCAL,
          submissionstatusid: 0,
          isactive: true,
        },
      ],
    };

    this.progressIndicatorService.show();
    this.externalCommService
      .postReviewersData(requestBody)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.getTilesCount();
          this.refreshGrid();
          this.progressIndicatorService.hide();
          if (rowItem?.communicationTypeId) {
            this.goToExtCommSubmission(
              rowItem?.communicationTypeId,
              rowItem?.submissionId
            );
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
          this.progressIndicatorService.hide();
        },
      });
  }

  tabChangeEvent(tabCode: string): void {
    this.selectedTabCode = tabCode;
    this.refreshGrid();
  }

  removeTilesWithoutPermission(): void {
    this.reviewerTileCodeDetails = this.originalReviewerTileCodeDetails;

    if (!this.isReviewerCCDueTileVisible) {
      this.reviewerTileCodeDetails = this.reviewerTileCodeDetails.filter(
        (tile: ReviewerTileCodeDetail) =>
          tile.tileCode !== this.reviewerTiles.CCDueNow
      );
    }

    if (!this.isReviewerExtCommTileVisibile) {
      this.reviewerTileCodeDetails = this.reviewerTileCodeDetails.filter(
        (tile: ReviewerTileCodeDetail) =>
          tile.tileCode !== this.reviewerTiles.ExtComm
      );
    }

    if (!this.isReviewerGlobalEngTileVisibile) {
      this.reviewerTileCodeDetails = this.reviewerTileCodeDetails.filter(
        (tile: ReviewerTileCodeDetail) =>
          tile.tileCode !== this.reviewerTiles.GlobalEng
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
