import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { EntityDetails, EntityType, UserService } from '../http/user.service';
import { MsGraphService } from '../http/msgraph.service';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Observable,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
import {
  WidthState,
  Themes,
  Size,
  ButtonKind,
  ExtThemes,
  PositionState,
} from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  ProfileItemComponent,
  ProfileOptions,
} from '@usitsdasdesign/dds-ng/profile';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { NavigationEnd, Router } from '@angular/router';
import {
  DropdownOptions,
  DropdownItemOptions,
} from '@usitsdasdesign/dds-ng/dropdown';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { StartNcaprocessDialogComponentComponent } from '../start-ncaprocess-dialog-component/start-ncaprocess-dialog-component.component';
import { SubmissionService } from '../http/intake/submission.service';
import { IntakeConstant } from '../intake/constants/intake.constant';
import { CommonService } from '../http/intake/common.service';
import { RoleService } from '../http/intake/role.service';
import { RoleEnum } from '../intake/constants/Role.enum';
import {
  ClientAcceptanceStatusAction,
  MarsClientExport,
} from '../common/models/client-acceptance-status-action.model';
import {
  ClientMilestoneStatus,
  ClientStatusWebapiService,
} from '../http/client/client-status-webapi.service';
import { ConsultingNCAConstants } from '../client/constants/consulting-nca-constant.';
import { DocumentService } from '../http/document/document.service';
import { ExportPdfPopupComponent } from './export-pdf-popup/export-pdf-popup.component';
import { MsalService } from '@azure/msal-angular';
import { DEFAULT_PROFILE_PIC } from '../app.constants';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environment/environment';
import { SecurityWebapiService } from '../http/security/security-webapi.service';
import { PermissionCheckService } from '../common/validations/permission-check.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AuditRequestParams,
  PermissionsObj,
} from '../common/models/common-models';
import { EclipseHeaderService } from '../http/eclipse-header.service';
import { ExternalCommunicationService } from '../http/external-communication/external-communication.service';
import { ExternalCommunicationConstant } from '../external-communications/constants/external_communication.constant';
import {
  StatusEnum,
  StatusEnumIdMap,
} from '../external-communications/constants/status-enum';
import { AuditTrailService } from '../http/audit-trail/audit-trail.service';
import { MatrixKeys } from '../common/constants/security-matrix';
import { MarsAccessDialogComponent } from './mars-access-dialog/mars-access-dialog.component';
import { DashboardRolesForOptions } from '../common/models/start-page/start-page.model';
import { ExternalCommunicationExportPdfComponent } from '../external-communications/external-communications/external-communication-export-pdf/external-communication-export-pdf.component';
import { AuditRequestParameter } from '../common/models/audit-params';
import { GlobalEngagementService } from '../http/global-engagement/global-engagement.service';
import { GlobalEngExportPdfComponent } from '../global-engagements/global-engagement/global-eng-export-pdf/global-eng-export-pdf.component';

@Component({
  selector: 'app-eclipse-header',
  templateUrl: './eclipse-header.component.html',
  styleUrl: './eclipse-header.component.less',
})
export class EclipseHeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('logout', { static: false }) logout?: ProfileItemComponent;
  public clientId: number = 0;
  public displayUserName: string | null = null;
  public userEmail: string | null = null;
  public cookieConsentDomainScript: string =
    environment.cookieConsentDomainScript;
  public cookieConsentEndPointUrl: string =
    environment.cookieConsentEndPointUrl;
  public imageUrl: any = '';
  public enumClientMilestoneStatus = ClientMilestoneStatus;
  private readonly securityWachlistDashboardVisible: string =
    'Watchlist.Dashboard';
  private readonly securityNcaSummaryDashboardVisible: string =
    'NcaSummary.Dashboard';
  public isWachlistDashboardVisible: boolean = false;
  public isNcaSummaryDashboardVisible: boolean = false;
  private readonly securityThirdPartyAgreementRepositoryVisible: string =
    'Application.ThirdPartyAgreementRepository';
  public isThirdPartyAgreementRepositoryVisible: boolean = false;
  private readonly securityStartNCAProcessVisible: string =
    'Application.StartNCAProcess';
  public isStartNCAProcessVisible: boolean = false;
  permissionObj: PermissionsObj = {} as PermissionsObj;
  public currentLoggedInUserEmployeeId: any;
  public currentDashboard: string | null = null;
  public isEngagementTeamMember: boolean = false;
  public isDefaultMARSUser: boolean = false;
  public isEnabledMARSUser: boolean = false;
  public hideGearIcon: boolean = true;
  moduleId: number | null = null;
  moduleName: string = '';
  isProficPicAvailable: boolean = false;
  isDropdownOpen: boolean = false;
  userRoles: string = '';
  isCommonAuditTrailVisible: boolean = false;
  isAdminCompleteVisible: boolean = false;
  public rootUrl: string = environment.rootUrl;
  isDiscontinueVisible: boolean = false;
  isDiscontinueExternalCommVisible: boolean = false;
  isDiscontinueGlobalEngagementVisible: boolean = false;
  isResetStatusVisible: boolean = false;
  isExportToPdfVisible: boolean = false;

  isClientMilestoneExportVisible: boolean = false;
  isMarsClientExportVisible: boolean = false;
  preveiousIsMarsClientExportVisible: boolean = false;
  isAuditTrailVisible: boolean = false;
  isWatchlistAuditVisible: boolean = false;
  clientNumber: string = '';
  clientMilestoneProcessType: string | null = null;
  isSystemAdministrator: boolean = false;
  isSystemAdminForExtComm: boolean = false;
  selectedClientMilestoneId: number = 0;
  externalCommunication: string = 'External Communication';
  extCommSubmissionStatus: string | null =
    ExternalCommunicationConstant.EMPTY_SPACE;
  isRelinkVisible: boolean = false;
  pageName: string = '';
  extCommSubmissionId: number = 0;
  currentSubmissionId: number | null = null;

  headerOptions: HeaderOptions = {
    name: 'Relay',
    width: WidthState.full,
    theme: Themes.green,
    isResponsive: false,
    isInverse: false,
    customClass: '',
  };

  responsivePriority: HeaderElmnts[] = [
    HeaderElmnts.logo,
    HeaderElmnts.projectName,
    HeaderElmnts.profile,
    HeaderElmnts.nav,
    HeaderElmnts.icons,
    HeaderElmnts.search,
  ];
  headerButtonOptions: ButtonOptions = {
    theme: Themes.dark,
  };
  chatButtonOptions: ButtonOptions = {
    theme: Themes.dark,
    icon: 'dds-icon_chat__s__stroke',
  };
  profileOptions: ProfileOptions = {
    username: '',
    photoUrl: '',
    stickerIsDynamic: false,
  };

  searchOptions: SearchOptions = {
    placeholder: 'Search clients, opportunities or submissions by name or id',
    ariaLabel: '',
    size: Size.md,
    isInverse: false,
    customClass: '',
    isTrimValue: false,
    readonly: false,
    maxLength: 100,
  };

  defaultDropdownStrickerPostion: PositionState =
    PositionState.bottomLeft ?? undefined;
  dropdownOptions: DropdownOptions = {
    label: '',
    ariaLabel: '',
    theme: ExtThemes.dark,
    kind: ButtonKind.primaryLoud,
    size: Size.md,
    width: WidthState.fixed,
    icon: 'dds-icon_plus',
    isColorBg: false,
    isInverse: false,
    customClass: '',
    disabled: false,
    isResponsive: false,
    stickerWidth: 300,
    stickerIsOutsideClick: false,
    stickerShift: 0,
    stickerMaxHeight: '',
    stickerIsDisabled: false,
    stickerPosition: PositionState.bottomRight,
    stickerIndent: 0,
    stickerCustomClass: '',
    stickerIsDynamic: true,
  };

  dropdownItems: DropdownItemOptions[] = [
    {
      heading: 'Contract/Proposal/Eng. Related Review',
      value: 'Contract/Proposal/Eng. Related Review',
    },
    {
      heading: 'Global Engagements',
      value: 'Global Engagements',
    },
    {
      heading: 'Start NCA process',
      value: 'StartNcaProcess',
    },
    {
      heading: 'MARS NCA request',
      value: 'MARS NCA Request',
    },
  ];
  initialDropdownItems: DropdownItemOptions[] = this.dropdownItems;

  defualtBaseOptions: DropdownItemOptions[] = [
    {
      heading: 'NCA summary dashboard',
      value: 'NcaSummary',
    },
    {
      heading: 'Third party agreements repository',
      value: 'Thirdpartyrepository',
    },
    {
      heading: 'Watchlist',
      value: 'Watchlist',
    },
  ];

  baseOptions: DropdownItemOptions[] = [...this.defualtBaseOptions];

  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  // Getter to dynamically include 'Admin Complete' based on the condition
  get additionalOptions(): DropdownItemOptions[] {
    if (!this.isClientMilestoneExportVisible) {
      this.baseOptions = [...this.defualtBaseOptions];
    }
    if (!this.isThirdPartyAgreementRepositoryVisible) {
      this.baseOptions = this.baseOptions.filter(
        (option) => option.value !== 'Thirdpartyrepository'
      );
    }
    if (!this.isWachlistDashboardVisible) {
      this.baseOptions = this.baseOptions.filter(
        (option) => option.value !== 'Watchlist'
      );
    }
    if (!this.isNcaSummaryDashboardVisible) {
      this.baseOptions = this.baseOptions.filter(
        (option) => option.value !== 'NcaSummary'
      );
    }
    if (
      this.isCommonAuditTrailVisible &&
      !this.baseOptions.some(
        (item: any) => item.value === IntakeConstant.COMMON_AUDIT_TRAIL
      )
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.AUDIT_TRAIL,
          value: IntakeConstant.COMMON_AUDIT_TRAIL,
        },
      ];
    }

    // TODO: Can Reuse the same Export to PDF option for Intake/Extcomm/Global
    if (
      this.isExportToPdfVisible &&
      this.permissionObj['isIntakeNavSubmissionExportEnable']
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.EXPORT_TO_PDF_HEADING,
          value: IntakeConstant.EXPORT_TO_PDF_VALUE,
        },
      ];
    } else {
      const existingOptionIndex = this.baseOptions.findIndex(
        (item: any) => item.value === IntakeConstant.EXPORT_TO_PDF_VALUE
      );
      if (existingOptionIndex !== -1) {
        this.baseOptions.splice(existingOptionIndex, 1);
      }
    }

    if (this.pageName === 'extCommSubmissionLevelPage') {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: 'Export to PDF',
          value: 'extCommExportToPdf',
        },
      ];
    } else {
      const existingOptionIndex = this.baseOptions.findIndex(
        (item: any) => item.value === 'extCommExportToPdf'
      );
      if (existingOptionIndex !== -1) {
        this.baseOptions.splice(existingOptionIndex, 1);
      }
    }

    if (this.pageName === 'global' && this.currentSubmissionId) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: 'Export to PDF',
          value: 'globalExportToPdf',
        },
      ];
    } else {
      const existingOptionIndex = this.baseOptions.findIndex(
        (item: any) => item.value === 'globalExportToPdf'
      );
      if (existingOptionIndex !== -1) {
        this.baseOptions.splice(existingOptionIndex, 1);
      }
    }

    // Condition need to be changed for external communication admin complete once backend add and provide the permission for admin complete for external communication.
    if (
      (this.isAdminCompleteVisible &&
        this.permissionObj['isIntakeNavSubmissionAdminEnable']) ||
      (this.isSystemAdminForExtComm && this.isSystemAdministrator)
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.ADMINISTRATEVELY_CLOSE_MENU_ITEM,
          value: IntakeConstant.ADMINISTRATEVELY_CLOSE_MENU_ITEM,
        },
      ];
    }
    if (
      (this.isDiscontinueVisible &&
        this.permissionObj['isIntakeNavSubmissionDiscontinueEnable']) ||
      (this.isDiscontinueExternalCommVisible &&
        this.permissionObj['isExtCommDiscontinueEnable']) ||
      (this.isDiscontinueGlobalEngagementVisible &&
        this.permissionObj['isGlobalEngDiscontinueEnable'])
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.DISCONTINUE_SUBMISSION_MENU_ITEM,
          value: IntakeConstant.DISCONTINUE_SUBMISSION_MENU_ITEM,
        },
      ];
    }
    if (
      this.isResetStatusVisible &&
      this.permissionObj['isIntakeNavSubmissionResetEnable']
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.RESET_SUBMISSION_STATUS,
          value: IntakeConstant.RESET_SUBMISSION_STATUS,
        },
      ];
    }

    if (
      this.extCommSubmissionStatus === StatusEnum.COMPLETED ||
      this.extCommSubmissionStatus === StatusEnum.COMPLETED_ADMIN ||
      this.extCommSubmissionStatus === StatusEnum.DISCONTINUED
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: ExternalCommunicationConstant.RESET_SUBMISSION_STATUS,
          value: ExternalCommunicationConstant.RESET_SUBMISSION_STATUS,
        },
      ];
    }

    if (this.isWatchlistAuditVisible) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: 'Audit trail',
          value: 'WatchlistAuditHistory',
        },
      ];
    } else {
      this.baseOptions = this.baseOptions.filter(
        (option) => option.value !== 'WatchlistAuditHistory'
      );
    }

    if (
      this.isSystemAdministrator &&
      !this.baseOptions.some(
        (x) => x.value === IntakeConstant.SYSTEM_ADMINISTRATOR
      )
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.SYSTEM_ADMINISTRATOR,
          value: IntakeConstant.SYSTEM_ADMINISTRATOR,
        },
      ];
    }

    if (this.isAuditTrailVisible) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.AUDIT_TRAIL,
          value: IntakeConstant.AUDIT_TRAIL,
        },
      ];
    }

    if (this.isMarsClientExportVisible) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF_HEADING,
          value: ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF,
        },
      ];
    }
    if (
      this.isRelinkVisible &&
      this.permissionObj['isIntakeNavSubmissionRelinkEnable']
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.RELINK_DROPDOWN_PLACEHOLDER,
          value: IntakeConstant.RELINK_DROPDOWN_PLACEHOLDER,
        },
      ];
    }
    if (!this.isDefaultMARSUser) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: 'MARS NCA Access',
          value: 'Marsncaaccess',
        },
      ];
    }

    return this.baseOptions;
  }

  trackByValue(index: number, item: DropdownItemOptions): string {
    return item.value;
  }

  constructor(
    private readonly userService: UserService,
    private readonly msGraphService: MsGraphService,
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly submissionService: SubmissionService,
    private readonly auditService: AuditTrailService,
    private readonly externalCommunicationService: ExternalCommunicationService,
    private readonly roleService: RoleService,
    private readonly commonService: CommonService,
    private readonly documentService: DocumentService,
    private readonly msalService: MsalService,
    private readonly securityWebapiService: SecurityWebapiService,
    private readonly permissionCheck: PermissionCheckService,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly zone: NgZone,
    private readonly clientStatusWebapiService: ClientStatusWebapiService,
    public headerService: EclipseHeaderService,
    public readonly globalEngagementService: GlobalEngagementService,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  ngOnInit(): void {
    this.securityWebapiService.data.subscribe((rules) => {
      this.dropdownItems = this.initialDropdownItems;
      if (!rules.empty) {
        this.isWachlistDashboardVisible =
          rules[this.securityWachlistDashboardVisible] &&
          rules[this.securityWachlistDashboardVisible].Visible !== undefined
            ? rules[this.securityWachlistDashboardVisible].Visible
            : false;
        this.isThirdPartyAgreementRepositoryVisible =
          rules[this.securityThirdPartyAgreementRepositoryVisible] &&
          rules[this.securityThirdPartyAgreementRepositoryVisible].Visible !==
            undefined
            ? rules[this.securityThirdPartyAgreementRepositoryVisible].Visible
            : false;
        this.isNcaSummaryDashboardVisible =
          rules[this.securityNcaSummaryDashboardVisible] &&
          rules[this.securityNcaSummaryDashboardVisible].Visible !== undefined
            ? rules[this.securityNcaSummaryDashboardVisible].Visible
            : false;
        this.isStartNCAProcessVisible =
          rules[this.securityStartNCAProcessVisible] &&
          rules[this.securityStartNCAProcessVisible].Visible !== undefined
            ? rules[this.securityStartNCAProcessVisible].Visible
            : false;
        if (!this.isStartNCAProcessVisible) {
          this.dropdownItems = this.dropdownItems.filter(
            (option) => option.value !== 'StartNcaProcess'
          );
        }

        const isEngagementExtCommPermission =
          rules[MatrixKeys.EXT_COM_EngagementTeamDashboard]?.Visible;
        // TODO: remove true once backend adds the permission
        if (
          (true || isEngagementExtCommPermission) &&
          !this.dropdownItems.some(
            (item) => item.heading === 'External Communication'
          )
        ) {
          this.dropdownItems.splice(1, 0, {
            heading: 'External Communication',
            value: 'External Communication',
          });
        }
      }
    });
    let isValidProilePic = false;
    let userProfilePic = localStorage.getItem('userProfilePic');
    let userPreferredName = localStorage.getItem('userPreferredName');
    this.displayUserName = userPreferredName;
    this.profileOptions.username = this.displayUserName ?? 'User';
    this.loadCookieConsentScript();

    this.submissionService.currentlyOnSubmissionPage$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (status) => {
          const isOnsubmissionLevelPage = status;
          this.isExportToPdfVisible = status;
          this.isRelinkVisible = status;
          this.commonService.resetEnabler$.subscribe((status: boolean) => {
            this.isResetStatusVisible = status && isOnsubmissionLevelPage;
          });
          this.additionalOptions;
        },
        error: (err) => {
          console.error('Error fetching submission page status:', err);
        },
      });

    this.externalCommunicationService.submissionStatus$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((status: string | null) => {
        this.extCommSubmissionStatus = status;
        this.additionalOptions;
      });

    // TODO: For Gear icon submission actions, can use a common observable
    this.externalCommunicationService.extCommSubmissionId$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((id: number) => {
        this.extCommSubmissionId = id;
      });

    this.externalCommunicationService.currentPage$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((pageName: string) => {
        this.pageName = pageName;
      });

    this.globalEngagementService.currentSubmissionId$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((data: AuditRequestParams) => {
        this.currentSubmissionId = data?.submissionid || null;
        // If on GlobalEng page - assign global,
        // else if pageName is already global set as '' else pageName itslef
        this.pageName = data?.opportunitynumber
          ? 'global'
          : this.pageName === 'global'
          ? ''
          : this.pageName;
      });

    if (!userPreferredName) {
      this.userService.userEmail$.subscribe((useremail) => {
        this.userEmail = localStorage.getItem('userProfileId');
        this.getUserPreferredName();
      });
    }

    if (userProfilePic) {
      this.isValidBase64Image(userProfilePic).subscribe((isValid) => {
        if (isValid) {
          isValidProilePic = true;
          this.isProficPicAvailable = true;
          this.imageUrl = userProfilePic;
          this.profileOptions.photoUrl = this.imageUrl;
        }
      });
    }
    if (!isValidProilePic) {
      this.userService.userEmail$.subscribe((useremail) => {
        this.userEmail = localStorage.getItem('userProfileId');
        this.getUserProfilePic();
      });
    }
    this.handleChangeRoute();

    this.submissionService.adminEnabler$.subscribe((status: boolean) => {
      this.isAdminCompleteVisible = status;
    });

    this.externalCommunicationService.discontinueEnabler$;
    this.externalCommunicationService.adminCompleteEnabler$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((status: boolean) => {
        this.isSystemAdminForExtComm = status;
      });

    this.externalCommunicationService.discontinueEnabler$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((status: boolean) => {
        this.isDiscontinueExternalCommVisible = status;
      });

    this.globalEngagementService.discontinueEnabler$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((status: boolean) => {
        this.isDiscontinueGlobalEngagementVisible = status;
      });

    combineLatest([
      this.submissionService.opportunitySelectedDocStatus$,
      this.commonService.discontinueAllowedSubmissionStatus$,
      this.roleService.currentUserRole$,
      this.roleService.submissionDiscontinueAllowedRoles$,
    ])
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe(
        ([
          selectedOpertunityDocStatus,
          allowedStatus,
          currentUseRole,
          allowedRoles,
        ]) => {
          this.userRoles = currentUseRole?.join('\n') ?? '';
          this.isDiscontinueVisible =
            selectedOpertunityDocStatus != null &&
            allowedStatus.includes(selectedOpertunityDocStatus) &&
            currentUseRole != null &&
            allowedRoles.some((role) => currentUseRole.includes(role));
        }
      );

    this.auditService.auditTrailEnabler$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((status: boolean) => {
        this.isCommonAuditTrailVisible = status;
      });
    //To Store the current form Data for Audit Trail
    this.auditService.currentClientId$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((request: AuditRequestParameter) => {
        this.moduleId = request.moduleId;
        this.moduleName = request.moduleName;
      });

    this.commonService.clientMilestoneActionObservable$.subscribe(
      (action: ClientAcceptanceStatusAction | null) => {
        if (action) {
          this.isClientMilestoneExportVisible = action.showExportButton;
          this.clientNumber = action.clientNumber;
          this.clientMilestoneProcessType = String(
            action.clientMilestoneProcessType
          );
          this.selectedClientMilestoneId = action.selectedClientMilestoneId;
          this.baseOptions = [
            ...this.defualtBaseOptions,
            {
              heading:
                ConsultingNCAConstants.COMPLETED_NCA_FORM_PDF_HEADING.replace(
                  '#clientMilestoneProcessType',
                  this.clientMilestoneProcessType
                ),
              value:
                ConsultingNCAConstants.COMPLETED_NCA_FORM_PDF_VALUE.replace(
                  '#clientMilestoneProcessType',
                  this.clientMilestoneProcessType
                ),
            },
          ];
        } else {
          this.baseOptions = [...this.defualtBaseOptions];
        }
      }
    );
    //this.commonService.MarsClientActionObservable$.pipe(
    //  takeUntil(this.#unsubscriber$)
    //).subscribe((action: MarsClientExport | null) => {
    //  if (action) {
    //    this.isMarsClientExportVisible = action.showExportButton;
    //    this.clientNumber = action.clientNumber;
    //  }
    //});

    this.commonService.auditTrailEnabler$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((status: boolean) => {
        this.isAuditTrailVisible = status;
      });

    this.commonService.clientId$.subscribe((clientId) => {
      this.clientId = Number(clientId);
    });

    this.headerService.isWatchlistAuditHistoryMenuVisible$
      // .pipe(take(1))
      .subscribe((res: boolean) => {
        this.isWatchlistAuditVisible = res;
      });

    this.userService.entityDetails$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((details) => {
        if (details) {
          this.updatePermissionAndRoles(details.entityId, details.entityTypeId);
        }
      });

    this.userService.currentLoggedInUser$.subscribe((user) => {
      this.currentLoggedInUserEmployeeId = user?.employeeId;
      if (this.currentLoggedInUserEmployeeId) {
        this.checkMarsNcaAccess();
      }
    });

    this.commonService.dashboardSelected$.subscribe((dashboard) => {
      this.currentDashboard = dashboard;
    });
  }

  ngAfterViewInit() {
    this.logout?.itemSelected?.subscribe(() => {
      localStorage.clear();
      this.msalService.instance.logout();
    });

    const clickableElement =
      this.el.nativeElement.querySelector('.dds-header__main');
    this.renderer?.listen(clickableElement, 'click', () => {
      this.zone.run(() => {
        this.auditService.enableAuditTrail(false);
        this.router.navigate(['/']);
      });
    });
  }

  itemSelected(value: string): void {
    this.commonService.enableAuditTrail(false);
    if (value === 'Contract/Proposal/Eng. Related Review') {
      this.router.navigate(['/submission']);
    } else if (value === 'MARS NCA Request') {
      this.router.navigate(['/initiate/mars-nca']);
    } else if (value === this.externalCommunication) {
      this.router.navigate(['/externalCommunications/create']);
    } else if (value === 'StartNcaProcess') {
      this.startNcaProcessManually();
      this.commonService.enableAuditTrail(true);
    } else if (value === 'Global Engagements') {
      this.router.navigate(['/globalEngagements/create']);
    }
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  navigateTo(path: string): void {
    this.router.navigate([path]);
    this.toggleDropdown();
  }

  setUserRoles(eventUrl: string) {
    let entity: string = eventUrl;
    let entities = entity.split('/');
    const urlParams = new URLSearchParams(eventUrl.split('?')[1]); // For query parameter
    let entityId, entityTypeId;

    if (entities.includes('client')) {
      let clientNumber: string = '';
      const clientIndex = entities.indexOf('client');
      if (clientIndex !== -1 && entities.length > clientIndex + 1) {
        clientNumber = entities[clientIndex + 1];
      }
      entityTypeId = EntityType.Client;
      entityId = clientNumber;
      this.getUserRoles(entityId, entityTypeId);
    } else if (entities.includes('submission')) {
      entityTypeId = EntityType.Submission;
      entityId = urlParams.get('submissionId') || EntityDetails.None;
      // For initiate page or submission page, for opportunity details we call permission from that component
      // as we need submissionId of latest submission
      if (
        (entities.includes('opportunity-details') && Number(entityId) > 0) ||
        !entities.includes('opportunity-details')
      ) {
        this.updatePermissionAndRoles(entityId, entityTypeId);
      }
    }
    //TODO: Can be added in submission if block
    else if (entity.includes('opportunity-summary')) {
      const id = urlParams.get('id'); // TODO: Remove if not being used
      entityTypeId = EntityType.Submission;
      entityId = EntityDetails.None;
      this.updatePermissionAndRoles(entityId, entityTypeId);
    } else if (entities.includes('externalCommunications')) {
      entityTypeId = EntityType.ExternalCommunication;
      entityId = urlParams.get('submissionId') || EntityDetails.None;
      this.updatePermissionAndRoles(entityId, entityTypeId);
    } else if (entities.includes('globalEngagements')) {
      entityTypeId = EntityType.GlobalEngagement;
      entityId = urlParams.get('submissionId') || EntityDetails.None;
      if (
        (entities.includes('details') && Number(entityId) > 0) ||
        !entities.includes('details')
      ) {
        this.updatePermissionAndRoles(entityId, entityTypeId);
      }
    } else {
      entityTypeId = EntityType.None;
      entityId = EntityDetails.None;
      this.getUserRoles(entityId, entityTypeId);
    }
  }

  handleChangeRoute() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.setUserRoles(event.url);
      });
  }

  getUserRoles(entityId: any, entityTypeId: any) {
    if (this.userEmail) {
      this.userService.getUserRoles(entityId, entityTypeId).subscribe({
        next: (data) => {
          if (data) {
            const loggedInUserRole: string[] = data?.employeeRoles?.map(
              (roles: any) => roles.roleName
            );

            // Check if user is a System Administrator and set flag accordingly
            this.isSystemAdministrator = data.employeeRoles.some(
              (role: any) => role.roleName === RoleEnum.SYSTEM_ADMINISTRATOR
            );
            this.isEngagementTeamMember = data.employeeRoles.some(
              (role: any) => role.roleName === RoleEnum.ENGAGEMENT_TEAM_MEMBER
            );
            this.roleService.setCurrentUserRoles(loggedInUserRole);
            this.roleService.setIsSystemAdministrator(
              this.isSystemAdministrator
            );
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error while fetching user roles', err);
          this.isSystemAdministrator = false;
          this.isEngagementTeamMember = false;
          this.roleService.setCurrentUserRoles([]);
          this.roleService.setIsSystemAdministrator(this.isSystemAdministrator);
        },
      });
    }
  }

  getPermissionByEmpId(entityId: string, entityTypeId: number): void {
    this.securityWebapiService
      .getPermissionsByEmployeeId(entityId, entityTypeId)
      .then(() => {
        if (entityTypeId === EntityType.Submission) {
          this.permissionCheck.updateIntakePermissions();
          this.permissionObj = this.permissionCheck.getIntakePermissionsObj;
        } else if (entityTypeId === EntityType.ExternalCommunication) {
          this.permissionCheck.updateExtCommPermissions();
          this.permissionObj = this.permissionCheck.getExtCommPermissionsObj;
        } else if (entityTypeId === EntityType.GlobalEngagement) {
          this.permissionCheck.updateGlobalEngPermissions();
          this.permissionObj = this.permissionCheck.getGlobalEngPermissionObj;
        }
        this.roleService.setPermissionObj(this.permissionObj);
      })
      .catch((error: HttpErrorResponse) => {
        console.error('Error fetching permissions:', error);
      });
  }

  updatePermissionAndRoles(entityId: string, entityTypeId: number) {
    this.getUserRoles(entityId, entityTypeId);
    this.getPermissionByEmpId(entityId, entityTypeId);
  }

  getUserPreferredName() {
    if (this.userEmail)
      this.msGraphService
        .searchUserByEmail(this.userEmail)
        .subscribe((data) => {
          if (data && data.value && data.value.length > 0) {
            const user = data.value[0];
            let firstName = (user.givenName ?? '') + ' ' + (user.surname ?? '');
            const displayName = user.displayName;

            if (!firstName || firstName.trim() === '') {
              firstName = displayName;
            }

            if (firstName) {
              localStorage.setItem('userPreferredName', firstName);
              this.displayUserName = firstName;
              this.profileOptions.username = this.displayUserName ?? 'User';
            } else {
              console.error(
                'First name and display name are both missing or invalid.'
              );
            }
          } else {
            console.error('No user data found or data structure is invalid.');
          }
        });
  }

  openModal() {
    let modalRef = this.modalService.open(ExportPdfPopupComponent, {
      isFooter: false,
      size: 'md',
      isInverse: false,
    });
  }

  additionaloptionSelected(value: string): void {
    if (value == 'Watchlist') {
      this.router.navigate(['/watchlist']);
      this.commonService.enableAuditTrail(false);
    }

    if (value == 'WatchlistAuditHistory') {
      this.headerService.navigateWatchlistAuditTrial();
    }

    if (value == 'Thirdpartyrepository') {
      this.router.navigate(['/thirdpartyrepository']);
      this.commonService.enableAuditTrail(false);
    }

    if (value == IntakeConstant.SYSTEM_ADMINISTRATOR) {
      this.router.navigate(['/admin']);
    }

    if (value == 'NcaSummary') {
      this.router.navigate(['/ncasummary']);
      this.commonService.enableAuditTrail(false);
    }

    if (value == 'ExportToPdf') {
      this.openModal();
    }
    if (value === IntakeConstant.COMMON_AUDIT_TRAIL) {
      localStorage.setItem("moduleName", this.moduleName);
      window.open(this.rootUrl + '/audit-trail/details/' + this.moduleId?.toString(), '_blank');
    }

    if (value === IntakeConstant.ADMINISTRATEVELY_CLOSE_MENU_ITEM) {
      if (this.isAdminCompleteVisible) {
        this.submissionService.openAdminCompleteModal(true);
      } else {
        this.externalCommunicationService.openAdminCompleteModal(true);
      }
    }
    if (value === IntakeConstant.DISCONTINUE_SUBMISSION_MENU_ITEM) {
      if (this.isDiscontinueVisible) {
        this.submissionService.openDiscontinueSubmissionModal(true);
      } else if (this.isDiscontinueExternalCommVisible) {
        this.externalCommunicationService.openDiscontinueModal(true);
      } else if (this.isDiscontinueGlobalEngagementVisible) {
        this.globalEngagementService.openDiscontinueModal(true);
      }
    }
    if (value === IntakeConstant.RESET_SUBMISSION_STATUS) {
      this.commonService.openResetStatusModal(true);
    }

    if (value === ExternalCommunicationConstant.RESET_SUBMISSION_STATUS) {
      this.commonService.openResetStatusModalForExtComm(true);
    }

    if (
      this.clientMilestoneProcessType &&
      value ===
        ConsultingNCAConstants.COMPLETED_NCA_FORM_PDF_VALUE.replace(
          '#clientMilestoneProcessType',
          this.clientMilestoneProcessType
        )
    ) {
      this.exportClientDataPdf(false);
    }
    if (value === ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF) {
      this.exportClientDataPdf(true);
    }
    if (value === IntakeConstant.AUDIT_TRAIL) {
      const currentURL = this.router.url;
      const extComm = currentURL.includes('externalCommunications');
      const globalEngagements = currentURL.includes('globalEngagements');
      this.router.navigate(['/audit-trail'], {
        state: { extComm, globalEngagements },
      });
    }
    if (value === IntakeConstant.RELINK_DROPDOWN_PLACEHOLDER) {
      this.submissionService.setRelinkModel(true);
    }
    if (value === 'Marsncaaccess') {
      this.marsncaaccesstoggle();
    }

    if (value == 'extCommExportToPdf') {
      this.openModalExternalCommunication();
    }
    if (value == 'globalExportToPdf') {
      this.openGlobalExportPDFModal();
    }
  }

  public async marsncaaccesstoggle() {
    this.removeBodyOverflow();
    this.modalService
      .open(MarsAccessDialogComponent, {
        resolve: {
          employeeId: this.currentLoggedInUserEmployeeId,
        },
      })
      .onClosed()
      .subscribe(() => {
        // Re-check the MARS NCA access status after the modal is closed
        this.checkMarsNcaAccess();
      });
  }

  private checkMarsNcaAccess(): void {
    combineLatest([
      this.clientStatusWebapiService.isMarsNcaAccessEnabled(
        this.currentLoggedInUserEmployeeId
      ),
      this.clientStatusWebapiService.isPrivilegedMarsUser(
        this.currentLoggedInUserEmployeeId
      ),
    ]).subscribe(([isEnabled, isPrivileged]) => {
      this.isEnabledMARSUser = isEnabled;
      this.isDefaultMARSUser = isPrivileged;

      if (!this.isEnabledMARSUser && !this.isDefaultMARSUser) {
        this.dropdownItems = this.dropdownItems.filter(
          (item) => item.value !== 'MARS NCA Request'
        );
      } else {
        // Optionally, add the item back if it was removed previously
        if (
          !this.dropdownItems.some((item) => item.value === 'MARS NCA Request')
        ) {
          this.dropdownItems.push({
            heading: 'MARS NCA request',
            value: 'MARS NCA Request',
          });
        }
      }
    });
  }

  openModalExternalCommunication() {
    let modalRef = this.modalService.open(
      ExternalCommunicationExportPdfComponent,
      {
        isFooter: false,
        size: 'md',
        isInverse: false,
        submissionId: this.extCommSubmissionId,
      }
    );
  }

  openGlobalExportPDFModal(): void {
    document.body.classList.add('no-scroll');
    this.modalService
      .open(GlobalEngExportPdfComponent, {
        isFooter: false,
        size: 'md',
        isInverse: false,
        submissionId: this.currentSubmissionId,
      })
      .onClosed()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe(() => {
        document.body.classList.remove('no-scroll');
      });
  }

  removeBodyOverflow() {
    document.body.style.overflowY = 'hidden';
  }
  public async startNcaProcessManually() {
    this.removeBodyOverflow();
    this.modalService.open(StartNcaprocessDialogComponentComponent);
  }

  getUserProfilePic() {
    if (this.userEmail)
      this.msGraphService
        .GetProfileImage(this.userEmail)
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe(
          (data) => {
            try {
              if (data != null) {
                var imgUrl = data;

                this.isValidBase64Image(imgUrl).subscribe((isValid) => {
                  if (isValid) {
                    this.imageUrl = imgUrl;
                    this.profileOptions.photoUrl = this.imageUrl;
                    this.isProficPicAvailable = true;
                    localStorage.setItem('userProfilePic', data);
                  }
                });
              }
            } catch (error: any) {
              console.error('Error fetching profile image', error);
            }
          },
          (error) => {
            if (error.status == 404) {
              var imgUrl = DEFAULT_PROFILE_PIC;

              this.isValidBase64Image(imgUrl).subscribe((isValid) => {
                if (isValid) {
                  this.imageUrl = imgUrl;
                  this.profileOptions.photoUrl = this.imageUrl;
                  this.isProficPicAvailable = true;
                  localStorage.setItem('userProfilePic', imgUrl);
                }
              });
            }
          }
        );
  }

  isValidBase64Image(imageUrl: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        observer.next(true);
        observer.complete();
      };

      img.onerror = () => {
        observer.next(false);
        observer.complete();
      };
    });
  }

  exportClientDataPdf(isMars: boolean) {
    this.documentService.exportClientDataPDF(
      this.clientNumber,
      this.clientMilestoneProcessType === 'NCA',
      this.clientMilestoneProcessType === 'CC',
      isMars,
      true,
      this.selectedClientMilestoneId
    );
  }

  loadCookieConsentScript() {
    let script = this.renderer.createElement('script');
    script.type = `text/javascript`;
    script.src = this.cookieConsentEndPointUrl;
    script.charset = `UTF-8`;
    script.setAttribute('data-domain-script', this.cookieConsentDomainScript);

    let optanonScript = this.renderer.createElement('script');
    optanonScript.type = `text/javascript`;
    optanonScript.innerHTML = `function OptanonWrapper() { }`;

    this.renderer.appendChild(this._document.body, script);
    this.renderer.appendChild(this._document.body, optanonScript);
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
