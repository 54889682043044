import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import { ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../constants/intake-constant';
import { OtherOptionValues } from '../../../constants/intake_document_close_out_other_option_values.enum';
import { capitalizeFirstLetter } from '../../../common/intake.util';

export const RADIO_GROUP_OPTIONS_FOR_OTHER_SCOPE: RadioGroupOptions =
  {
    name: 'other',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: 'Is there a signed/final version of the document that was reviewed?',
    isRequired: true,
    isInverse: false,
  };

export const OTHER_RADIO_GROUP_VALUE: RadioButton[] = [
  {
    options: {
      label: capitalizeFirstLetter(OtherOptionValues.YES),
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
      disabled: undefined,
    },
    value: OtherOptionValues.YES,
  },
  {
    options: {
      label: capitalizeFirstLetter(OtherOptionValues.NO_RESUBMIT_FOR_REVIEW),
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: OtherOptionValues.NO_RESUBMIT_FOR_REVIEW,
  },
  {
    options: {
      label: capitalizeFirstLetter(OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED),
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED,
  },
];
