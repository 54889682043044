import { BehaviorSubject, Observable } from "rxjs";

export class BehaviorManager<T> {
    private value_!: T;
    private valueManager: BehaviorSubject<T> = new BehaviorSubject<T>(this.value_);
    public valueChanged: Observable<T>;

    constructor() {
        this.valueChanged = this.valueManager.asObservable();
    }

    public setValue(value: T): void {
        this.value_ = value;
        this.valueManager.next(value);
    }

    public getValue(): T {
        return this.value_ || {} as T;
    }
}