import { operationResult } from './../../http/client/client-status-webapi.service';
import {
  Component,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { environment } from '../../../environment/environment';
import {
  PositionState,
  Themes,
  ExtThemes,
  ButtonKind,
  Size,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import {
  StickerDirective,
  StickerOptions,
} from '@usitsdasdesign/dds-ng/sticker';
import { Filter, FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import {
  DropdownItemOptions,
  DropdownOptions,
} from '@usitsdasdesign/dds-ng/dropdown';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { DaterangepickerOptions } from '@usitsdasdesign/dds-ng/datepicker';
import { WatchlistStatus } from '../../http/watchlist/watchlist-api.service';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { NCASpecialistWebapiService } from '../../http/dashboard/nca-specialist-webapi.service';
import { WatchlistDetailModalDialogComponent } from '../../watchlist/watchlist-detail-modal-dialog/watchlist-detail-modal-dialog.component';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import {
  DashboardData,
  DashboardGridSortParameter,
  GridColumn,
  DashboardTileCode,
  GridUpdateEvent,
} from '../../common/models/start-page/start-page.model';
import {
  NCATileCode,
  NCASpecialistDashboardModel,
} from '../../common/models/start-page/nca-specialist-dashboard.model';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { OpportunityListInformationComponent } from '../../client/client-status/opportunity-list-information/opportunity-list-information.component';
import { ReviewerTileCode } from '../../common/models/start-page/reviewer-dashboard.model';
import { Router } from '@angular/router';
import {
  engagementSortParamaterCode,
  GridColumns,
  ncaSortParamaterCode,
  reviewerSortParamaterCode,
} from '../dashboard.helper';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import { StatusEnum } from '../../intake/constants/status-enum';

import { ClientMilestoneStatus } from '../../http/client/client-status-webapi.service';
import { DatePipe } from '@angular/common';
import { EngagementTileCode } from '../../intake/constants/dashboard-tiles.enum';
import { ConsultingNCAConstants } from '../../client/constants/consulting-nca-constant.';

@Component({
  selector: 'startpage-grid-area',
  templateUrl: './startpage-gridarea.component.html',
  styleUrl: './startpage-gridarea.component.less',
  providers: [DatePipe],
})
export class StartPageGridAreaComponent implements OnInit {
  @ViewChildren('stickerDir') sticker: QueryList<StickerDirective> | undefined;
  @Input() currentDashboardTileCode: string = '';
  @Input() currentTileNameForGrid: string | any;
  @Input() gridColumns: GridColumn[] = [];
  @Input() model: any[] = [];
  @Input() gridTitle: string = '';
  @Input() selectedTileCount: number = 0;
  @Input() dashboardfilters!: any[];
  @Input() dropdownItems: DropdownItemOptions[] = [];
  @Input() buttonOptionsList: any[] = [];
  @Input() isMarsNCASpecialist: boolean = false;
  @Input() filterDataList: { name: string; data: FilterItem[] }[] = [];

  @Output() buttonSelectedEvent = new EventEmitter<{
    value: string;
    rowItem: any;
  }>();
  @Output() gridUpdatedEvent = new EventEmitter<GridUpdateEvent>();

  public searchValue: string = '';
  public dateRange = '';
  public isShownFilter!: boolean;
  public filteringColumn!: GridColumn;
  public isAscending: boolean = true;
  public sortParamaterCode: number = 1;
  public ncaTileCodes = NCATileCode;
  public reviewerTileCodes = ReviewerTileCode;
  public engagementTileCodes = EngagementTileCode;
  public rootUrl: string = environment.rootUrl;
  public netServicesRevenueToolTip = ConsultingNCAConstants.NET_SERVICES_REVENUE_TOOLTIP_INFO;
  public marsCCNetServicesRevenueToolTip = ConsultingNCAConstants.NET_SERVICES_REVENUE_TOOLTIP_INFO;
  public filterSortParameterCode = DashboardGridSortParameter;
  public filterData: FilterItem[] = []; //Added to fix console errors occuring due to DDS filter component
  public enumWatchlistStatus = WatchlistStatus;
  public jupiterFilterValue: string = '';
  public fromValue: number | null = null;
  public toValue: number | null = null;
  public dashboardTileCode = DashboardTileCode;
  public CCDue: string = 'Client continuances due';
  public columnName = GridColumns;
  public defaultEmptyFields: string = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
  public enumClientMilestoneStatus = ClientMilestoneStatus;
  isAllFilterSelected: boolean = false;
  public clientMilestoneStatusFilterData: FilterItem[] = [];
  public clientMilestoneSubStatusFilterData: FilterItem[] = [];
  public ncaTypeFilterData: FilterItem[] = [];
  public ncaSpecialistsFilterData: FilterItem[] = [];
  private selectedItemsMap: { [key: string]: string[] } = {};

  constructor(
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly ncaspecialistWebapiService: NCASpecialistWebapiService,
    private datePipe: DatePipe,
    private readonly progressIndicatorService: ProgressIndicatorService
  ) {}

  ngOnInit(): void {
    document.addEventListener('click', this.onDocumentClick.bind(this));
    if (this.currentDashboardTileCode === this.dashboardTileCode.Reviewer) {
      this.sortParamaterCode =
        reviewerSortParamaterCode[this.currentTileNameForGrid];
    }
    if (this.currentTileNameForGrid == this.ncaTileCodes.CompleteMarsNcaForm) {
      this.sortParamaterCode = this.filterSortParameterCode.marsActionSort;
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (event.target instanceof Element) {
      const isClickedOutsideSearchBox =
        !event.target.closest('.jupiter-search');
      if (
        isClickedOutsideSearchBox &&
        (!this.jupiterFilterValue || this.jupiterFilterValue.length < 10)
      ) {
        this.clearJupiterSearch();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentTileNameForGrid']) {
      // Reset the filters and sorting state
      this.isAscending = true;
      if (this.currentDashboardTileCode === this.dashboardTileCode.Reviewer) {
        this.sortParamaterCode =
          reviewerSortParamaterCode[this.currentTileNameForGrid];
        this.sortingState = {
          property: this.columnName.SubmittedDate,
          ascending: false,
          dataType: 'date',
        };
      } else if (
        this.currentDashboardTileCode === this.dashboardTileCode.EngagementTeam
      ) {
        this.sortParamaterCode =
          engagementSortParamaterCode[this.currentTileNameForGrid];
        this.sortingState = {
          property: this.columnName.SubmittedDate,
          ascending: false,
          dataType: 'date',
        };
      } else {
        this.sortParamaterCode =
          this.currentTileNameForGrid == this.ncaTileCodes.CompleteMarsNcaForm
            ? this.filterSortParameterCode.marsActionSort
            : ncaSortParamaterCode;
        this.sortingState = {
          property: this.gridColumns[0]?.name,
          ascending: true,
          dataType: this.gridColumns[0]?.dataType,
        };
      }
      this.clearAllFilter();
    }
    if (
      changes['currentDashboardTileCode'] &&
      this.currentDashboardTileCode === this.dashboardTileCode.Reviewer
    ) {
      this.sortParamaterCode =
        reviewerSortParamaterCode[this.currentTileNameForGrid];
      this.sortingState = {
        property: this.columnName.SubmittedDate,
        ascending: false,
        dataType: 'date',
      };
    }

    if (changes['filterDataList']) {
      if (this.currentDashboardTileCode === this.dashboardTileCode.NcaSummary) {
        this.clientMilestoneStatusFilterData =
          this.filterDataList.find(
            (item) => item.name == 'clientMilestoneStatusFilterData'
          )?.data || [];
        this.clientMilestoneSubStatusFilterData =
          this.filterDataList.find(
            (item) => item.name == 'clientMilestoneSubStatusFilterData'
          )?.data || [];
        this.ncaTypeFilterData =
          this.filterDataList.find((item) => item.name == 'ncaTypeFilterData')
            ?.data || [];
        this.ncaSpecialistsFilterData =
          this.filterDataList.find(
            (item) => item.name == 'ncaSpecialistsFilterData'
          )?.data || [];

        this.selectedItemsMap['clientMilestoneStatus'] =
          this.clientMilestoneStatusFilterData
            .filter((item) => item['isChecked'])
            .map((item) => item['name']);
      }
    }
  }

  public sortingState = {
    property: this.gridColumns[0]?.name,
    ascending: true,
    dataType: this.gridColumns[0]?.dataType,
  };

  public filterOptions: Filter = {
    theme: Themes.green,
    propName: 'name',
    itemPropNameValue: 'value',
  };

  public filterStickerOptions: StickerOptions = {
    stickerPosition: PositionState.bottomRight,
    stickerIsOutsideClick: true,
    stickerIndent: -1,
    stickerWidth: 280,
  };

  public filterStickerOptionsOnlySort: StickerOptions = {
    stickerPosition: PositionState.bottom,
    stickerIsOutsideClick: true,
    stickerIndent: -1,
    stickerWidth: 0,
  };

  public downlaodbuttonoptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: 'fixed',
    icon: 'dds-icon_download',
    role: 'button',
  };

  public gotoClientStatus(clientNumber: string): void {
    let url = this.rootUrl + 'client/' + clientNumber + '/status';
    window.open(url, '_blank');
  }

  public goToSubmission(opportunityNumber: string, submissionId: string): void {
    this.router.navigate(
      ['/submission/opportunity-details', opportunityNumber],
      {
        state: {
          navigatingFromDashboard: true,
          submissionID: submissionId,
        },
      }
    );
  }

  navigateToSummary(opportunityId: string, title: string): void {
    this.router.navigate(['opportunity-summary'], {
      queryParams: {
        id: opportunityId,
        title: title,
      },
    });
  }

  setEventPropagationForDropdown(event: any) {
    event.stopPropagation();
  }

  public toggleFilter(column: GridColumn) {
    this.filteringColumn = column;
    this.isShownFilter = true;
  }

  public filterHide(): void {
    this.sticker?.forEach((item) => {
      if (item.isActive) {
        item.hide();
      }
    });
  }

  public filterOnHidden(): void {
    this.isShownFilter = false;
  }

  public filterApply(searchValue: string | [Date, Date]) {
    if (
      (this.filteringColumn.dataType != 'number' &&
        (searchValue === '' || searchValue === null)) ||
      (this.filteringColumn.dataType == 'number' &&
        (this.fromValue === null || this.toValue === null))
    ) {
      this.clearFilter(this.filteringColumn.name);
      return;
    }
    const parameterCode =
      this.filterSortParameterCode[
        this.filteringColumn.name as keyof typeof DashboardGridSortParameter
      ];
    let filterCriteria: string;

    if (
      this.filteringColumn.dataType == 'number' &&
      this.fromValue !== null &&
      this.toValue !== null
    ) {
      filterCriteria = `${this.fromValue},${this.toValue}`;
    } else if (Array.isArray(searchValue)) {
      const [fromDate, toDate] = searchValue;
      const fromDateString = fromDate.toLocaleDateString('en-US');
      const toDateString = toDate.toLocaleDateString('en-US');
      filterCriteria = `${fromDateString},${toDateString}`;
    } else {
      filterCriteria = searchValue;
    }

    // Remove previous filter for the same column if it exists
    this.dashboardfilters = this.dashboardfilters.filter(
      (filter) => filter.filtertypecode !== parameterCode
    );

    this.filteringColumn.filterable = true;
    if (
      this.filteringColumn.name === 'continuanceStatus' ||
      this.filteringColumn.name === 'clientMilestoneStatus' ||
      this.filteringColumn.name === 'clientMilestoneSubStatus' ||
      this.filteringColumn.name === 'ncaType' ||
      this.filteringColumn.name === 'ncaSpecialistPreferredName'
    ) {
      this.sortParamaterCode = parameterCode;
      const values = (typeof searchValue === 'string' ? searchValue : '').split(
        ','
      );
      const newFilters = values.map((value) => ({
        filtertypecode: parameterCode,
        filtercriteria: value.trim(),
      }));

      this.dashboardfilters = [...(this.dashboardfilters || []), ...newFilters];
    } else {
      const newFilter = {
        filtertypecode: parameterCode,
        filtercriteria: filterCriteria,
      };
      this.dashboardfilters = [...(this.dashboardfilters || []), newFilter];
    }

    this.filterHide();
    this.gridUpdatedEvent.emit({
      sortParamaterCode: this.sortParamaterCode,
      isAscending: this.isAscending,
      dashboardfilters: this.dashboardfilters,
    });
  }

  public filterSorted(isAscending: boolean): void {
    const parameterCode =
      this.filterSortParameterCode[
        this.filteringColumn.name as keyof typeof DashboardGridSortParameter
      ];
    this.sortParamaterCode = parameterCode;
    this.isAscending = isAscending;

    this.sortingState = {
      property: this.filteringColumn.name,
      ascending: isAscending,
      dataType: this.filteringColumn.dataType,
    };

    this.filterHide();
    this.gridUpdatedEvent.emit({
      sortParamaterCode: this.sortParamaterCode,
      isAscending: this.isAscending,
      dashboardfilters: this.dashboardfilters,
    });
  }

  public handleFilterSelection(
    event: any,
    filterData: any[],
    filterName: string
  ): void {
    let selectedItems: string[] = this.getSelectedItemsArray(filterName);

    if (event) {
      selectedItems = filterData.map((item) => item['name']);
      filterData.forEach((item) => (item['isChecked'] = true));
      this.isAllFilterSelected = true;
    } else {
      selectedItems = [];
      filterData.forEach((item) => (item['isChecked'] = false));
      this.isAllFilterSelected = false;
    }

    this.filteringColumn.searchValue = selectedItems.join(', ');

    filterData.forEach((item) => {
      item['isChecked'] = selectedItems.includes(item['name']);
    });

    this.setSelectedItemsArray(filterName, selectedItems);
  }

  public selectAllFilter(event: any): void {
    if (this.currentDashboardTileCode === this.dashboardTileCode.NcaSummary) {
      switch (this.filteringColumn.name) {
        case 'clientMilestoneStatus':
          this.handleFilterSelection(
            event,
            this.clientMilestoneStatusFilterData,
            'clientMilestoneStatus'
          );
          break;
        case 'clientMilestoneSubStatus':
          this.handleFilterSelection(
            event,
            this.clientMilestoneSubStatusFilterData,
            'clientMilestoneSubStatus'
          );
          break;
        case 'ncaType':
          this.handleFilterSelection(event, this.ncaTypeFilterData, 'ncaType');
          break;
        case 'ncaSpecialistPreferredName':
          this.handleFilterSelection(
            event,
            this.ncaSpecialistsFilterData,
            'ncaSpecialistPreferredName'
          );
          break;
      }
    } else {
      this.handleFilterSelection(
        event,
        this.reviewerFilterData,
        'reviewerFilterData'
      );
    }
  }

  private updateFilterData(filterData: any[], filterName: string): void {
    let selectedItems: string[] = this.getSelectedItemsArray(filterName);

    this.isAllFilterSelected = selectedItems.length === filterData.length;
    this.filteringColumn.searchValue = selectedItems.join(', ');

    filterData.forEach((item) => {
      item['isChecked'] = selectedItems.includes(item['name']);
    });
  }

  public selectFilterItem(event: any, filterItem: any): void {
    let selectedItems: string[] = this.getSelectedItemsArray(
      this.filteringColumn.name
    );

    if (event) {
      if (!selectedItems.includes(filterItem.name)) {
        selectedItems.push(filterItem.name);
      }
    } else {
      selectedItems = selectedItems.filter((item) => item !== filterItem.name);
    }

    this.setSelectedItemsArray(this.filteringColumn.name, selectedItems);

    if (this.currentDashboardTileCode === this.dashboardTileCode.NcaSummary) {
      switch (this.filteringColumn.name) {
        case 'clientMilestoneStatus':
          this.updateFilterData(
            this.clientMilestoneStatusFilterData,
            'clientMilestoneStatus'
          );
          break;
        case 'clientMilestoneSubStatus':
          this.updateFilterData(
            this.clientMilestoneSubStatusFilterData,
            'clientMilestoneSubStatus'
          );
          break;
        case 'ncaType':
          this.updateFilterData(this.ncaTypeFilterData, 'ncaType');
          break;
        case 'ncaSpecialistPreferredName':
          this.updateFilterData(
            this.ncaSpecialistsFilterData,
            'ncaSpecialistPreferredName'
          );
          break;
      }
    } else {
      this.updateFilterData(this.reviewerFilterData, 'reviewerFilterData');
    }
  }

  private getSelectedItemsArray(filterName: string): string[] {
    return this.selectedItemsMap[filterName] || [];
  }

  private setSelectedItemsArray(
    filterName: string,
    selectedItems: string[]
  ): void {
    this.selectedItemsMap[filterName] = selectedItems;
  }

  public clearAllFilter() {
    this.dashboardfilters = [];
    this.fromValue = null;
    this.toValue = null;
    this.gridColumns.forEach((column) => {
      column.filterable = false;
      column.searchValue = '';
    });

    // Clear selections made in Continuance status column
    this.reviewerFilterData.forEach((item) => (item['isChecked'] = true));
    if (this.currentDashboardTileCode === this.dashboardTileCode.NcaSummary) {
      this.clientMilestoneStatusFilterData.forEach(
        (item) => (item['isChecked'] = true)
      );
      this.clientMilestoneSubStatusFilterData.forEach(
        (item) => (item['isChecked'] = true)
      );
      this.ncaSpecialistsFilterData.forEach(
        (item) => (item['isChecked'] = true)
      );
      this.ncaTypeFilterData.forEach((item) => (item['isChecked'] = true));
    }

    if (
      this.currentDashboardTileCode == 'NCASpecialist' &&
      this.gridTitle != 'Client continuances due'
    ) {
      this.clearJupiterSearch();
    }
    this.filterHide();
    this.gridUpdatedEvent.emit({
      sortParamaterCode: this.sortParamaterCode,
      isAscending: this.isAscending,
      dashboardfilters: this.dashboardfilters,
    });
  }

  public clearFilter(column: string) {
    // Remove the filter for the specified column from the list
    this.dashboardfilters = this.dashboardfilters.filter((filter) => {
      const parameterCode =
        this.filterSortParameterCode[
          column as keyof typeof this.filterSortParameterCode
        ];
      return filter.filtertypecode !== parameterCode;
    });

    // Reset the filterable and searchValue properties for the specified column
    this.filteringColumn.filterable = false;
    this.filteringColumn.searchValue = '';

    this.filterHide();
    this.gridUpdatedEvent.emit({
      sortParamaterCode: this.sortParamaterCode,
      isAscending: this.isAscending,
      dashboardfilters: this.dashboardfilters,
    });
  }

  public downloadCCDueClick() {
    this.progressIndicatorService.show();
    this.ncaspecialistWebapiService
      .ExportCCDueData()
      .subscribe((response: any) => {
        const base64Data = response.base64String;
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
        const downloadLink = document.createElement('a');
        const formattedDate = this.datePipe.transform(new Date(), 'MMddyyyy');
        const fileName = `${response.fileName} - ${formattedDate}.xlsx`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.progressIndicatorService.hide();
      });
  }

  public itemExpand(item: any, $event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    item.expandStatus = !item.expandStatus;
  }

  public stopEventPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
  getWatchlistStatus(row: any): string {
    return row.watchlistStatusId ==
      this.enumWatchlistStatus.ServeWithConditions ||
      row.watchlistStatusId == this.enumWatchlistStatus.DoNotServe
      ? row.watchlistStatus
      : 'Prior watchlist client';
  }

  getParentWatchlistStatus(row: any): string {
    return row.pcWatchlistStatusId ==
      this.enumWatchlistStatus.ServeWithConditions ||
      row.pcWatchlistStatusId == this.enumWatchlistStatus.DoNotServe
      ? row.pcWatchlistStatus
      : 'Prior watchlist client';
  }

  onShowWatchlistDetail(
    event: MouseEvent,
    rowItem: NCASpecialistDashboardModel,
    isParentClient: boolean
  ): void {
    this.stopEventPropagation(event);
    let modalRef = this.modalService.open(WatchlistDetailModalDialogComponent);
    modalRef.componentInstance.data = {
      watchlistDetail: rowItem,
      isPriorWatchlistStatusShow: true,
      isParentClient: isParentClient,
    };
  }
  searchOptions: SearchOptions = {
    placeholder: 'Search by Jupiter id',
    size: Size.lg,
    maxLength: 10,
  };

  showActionButtonDeopdown(): boolean {
    return (
      this.currentTileNameForGrid !== this.ncaTileCodes.NcaNotRequired &&
      this.currentTileNameForGrid !== this.reviewerTileCodes.PendingReviews &&
      this.currentTileNameForGrid !==
        this.reviewerTileCodes.PendingReviewsOthers &&
      this.currentTileNameForGrid !==
        this.reviewerTileCodes.SelfReviewChanges &&
      this.currentTileNameForGrid !== this.reviewerTileCodes.RecentReviews &&
      this.currentTileNameForGrid !== this.engagementTileCodes.Initiated &&
      this.currentTileNameForGrid !== this.engagementTileCodes.Reviewed &&
      this.currentTileNameForGrid !== this.reviewerTileCodes.NCAPendingApproval
    );
  }
  showellipseForCCDueNowAndMars(index: number): boolean {
    return this.currentTileNameForGrid == this.reviewerTileCodes.CCDueNow ||
      this.currentTileNameForGrid === this.ncaTileCodes.CompleteMarsNcaForm
      ? this.currentDashboardTileCode == this.dashboardTileCode.NCASpecialist &&
          (this.currentTileNameForGrid == this.reviewerTileCodes.CCDueNow ||
            (this.currentTileNameForGrid ===
              this.ncaTileCodes.CompleteMarsNcaForm &&
              this.model[index].clientMilestoneStatusId ==
                this.enumClientMilestoneStatus.NotStarted))
      : true;
  }
  bindMarsClientInfo(row: any, column: any) {
    return row.opportunityList == null
      ? ''
      : column == 'opportunityId'
      ? row.opportunityList[0]?.opportunityNumber
      : row.opportunityList[0]?.opportunityName;
  }
  isMarsDraftRecord(index: number): boolean {
    return (
      this.currentDashboardTileCode == this.dashboardTileCode.NCASpecialist &&
      this.currentTileNameForGrid === this.ncaTileCodes.CompleteMarsNcaForm &&
      this.model[index].clientMilestoneStatusId ==
        this.enumClientMilestoneStatus.MARSDraft
    );
  }
  public showOpenOpps(row: any, $event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    let modalRef = this.modalService.open(OpportunityListInformationComponent);
    modalRef.componentInstance.resolve = {
      title: 'Open Opportunities',
      MilestoneTriggerListInput: row.opportunityList,
    };

    modalRef.onClosed().subscribe(() => {});
  }

  //NCA Dashboard Dropdown options visibility
  isVisibleDropdownItem(
    item: any,
    rowItem: NCASpecialistDashboardModel
  ): boolean {
    if (item.value == 'NCANotRequired') {
      return rowItem.isNcaNotRequiredAvailable || false;
    } else if (item.value == 'OnHold') {
      return rowItem.isOnHoldOptionAvailable || false;
    } else if (item.value == 'ConvertToCC') {
      return this.currentTileNameForGrid ===
        this.ncaTileCodes.CompleteMarsNcaForm &&
        rowItem?.clientNumber?.startsWith('D00')
        ? false
        : rowItem.isConvertNca2CcAavailable || false;
    } else if (
      item.value == 'ConvertToNCA' &&
      this.currentTileNameForGrid == 'CCDueNow'
    ) {
      return true;
    } else if (item.value == 'AssignTo') {
      return (
        (this.currentDashboardTileCode === this.ncaTileCodes.OnHold &&
          rowItem.isAssignToAvailable) ||
        this.currentDashboardTileCode === this.ncaTileCodes.CompleteNcaForm ||
        this.currentDashboardTileCode === this.ncaTileCodes.PreScreenNcaForm ||
        this.currentTileNameForGrid === this.ncaTileCodes.CompleteMarsNcaForm ||
        this.currentTileNameForGrid === this.ncaTileCodes.CompleteNcaForm
      );
    } else if (item.value == 'Discontinue') {
      return this.currentTileNameForGrid == 'CCDueNow'
        ? true
        : rowItem.isDiscontinueAvailable || false;
    }
    return false;
  }

  public buttonSelected(value: string, rowItem: any) {
    this.buttonSelectedEvent.emit({ value, rowItem });
  }

  getFilterItemList() {
    switch (this.filteringColumn.name) {
      case 'continuanceStatus':
        return this.reviewerFilterData;
      case 'clientMilestoneStatus':
        return this.clientMilestoneStatusFilterData;
      case 'clientMilestoneSubStatus':
        return this.clientMilestoneSubStatusFilterData;
      case 'ncaType':
        return this.ncaTypeFilterData;
      case 'ncaSpecialistPreferredName':
        return this.ncaSpecialistsFilterData;
      default:
        return [];
    }
  }

  reviewerFilterData: FilterItem[] = [
    {
      title: 'Due Now',
      name: 'CcDueDate',
      isChecked: true,
    },
    {
      title: 'Expired',
      name: 'Expired',
      isChecked: true,
    },
    {
      title: 'Discontinued',
      name: 'Discontinue',
      isChecked: true,
    },
    {
      title: 'Accepted',
      name: 'Accepted',
      isChecked: true,
    },
    {
      title: 'Serve with conditions',
      name: 'ServeWithConditions',
      isChecked: true,
    },
    {
      title: 'Do not serve',
      name: 'DoNotServe',
      isChecked: true,
    },
  ];

  clientTooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'right-top',
    tooltipIndent: 5,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
    tooltipMaxWidth: 300,
  };

  groupToolTipoptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 15,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
    tooltipMaxWidth: 300,
  };

  actionbyToolTipoptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'left',
    tooltipIndent: 15,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark,
    tooltipMaxWidth: 300,
  };
  dateRangePickerOptions: DaterangepickerOptions = {
    size: Size.md,
    placeholder: ['MM/DD/YYYY', 'MM/DD/YYYY'],
    format: 'MM/dd/yyyy',
    isManualInput: false,
    stickerPosition: PositionState.bottomLeft,
  };

  dropdownOptions: DropdownOptions = {
    label: '',
    ariaLabel: '',
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.md,
    width: WidthState.fixed,
    icon: 'dds-icon_dots-v',
    disabled: false,
    stickerWidth: 200,
  };
  dropdownStickerPosition: PositionState = PositionState.bottomRight;

  buttonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.md,
    width: WidthState.fixed,
  };

  public inputClick() {
    if (
      !this.jupiterFilterValue ||
      this.jupiterFilterValue.indexOf('JO-') != 0
    ) {
      this.jupiterFilterValue = 'JO-';
    }
  }

  public validateKeypress(event: KeyboardEvent) {
    const keyPressed = event.key;
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;

    // Prevent deletion of "JO-"
    if (this.isDeletionKey(keyPressed)) {
      if (currentValue.startsWith('JO-') && inputElement.selectionStart! <= 3) {
        event.preventDefault();
      }
      return;
    }

    // Prevent overwriting "JO-"
    if (currentValue.startsWith('JO-') && inputElement.selectionStart! < 3) {
      event.preventDefault();
      return;
    }

    // Ensure numeric entry
    if (!this.isValidKey(event)) {
      event.preventDefault();
    }
  }

  private isDeletionKey(key: string): boolean {
    return key === 'Backspace' || key === 'Delete';
  }

  private isValidKey(event: KeyboardEvent): boolean {
    const key = event.key;
    const validKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']; //allow arrow navigation
    const isCtrlKey = event.ctrlKey && (key === 'c' || key === 'v'); //allow pasting
    const isNumeric = key >= '0' && key <= '9'; // check if the key is a number

    return validKeys.includes(key) || isCtrlKey || isNumeric;
  }

  private clearJupiterSearch() {
    const activeElement = document.activeElement as HTMLInputElement;
    if (
      activeElement &&
      activeElement.classList.contains('jupiter-search-input')
    ) {
      this.jupiterFilterValue = 'JO-';
      activeElement.value = 'JO-';
    } else {
      const searchInput = document.querySelector(
        '.jupiter-search-input'
      ) as HTMLInputElement;
      this.jupiterFilterValue = '';
      if (searchInput) searchInput.value = '';
    }
    this.updateClearButtonVisibility();
  }

  private updateClearButtonVisibility() {
    const searchInput = document.querySelector(
      '.jupiter-search-input'
    ) as HTMLInputElement;
    if (searchInput) {
      const clearButton = searchInput.nextElementSibling
        ?.nextElementSibling as HTMLElement;
      if (clearButton) {
        clearButton.style.display =
          searchInput && searchInput.value.length > 3 ? 'block' : 'none';
      }
    }
  }

  public onSearchInput(event: any): void {
    var inputValue = event.target.value;
    this.updateClearButtonVisibility();
    if (this.currentDashboardTileCode === this.dashboardTileCode.NcaSummary) {
      this.jupiterFilterValue = inputValue;
    }
    if (inputValue.length === 10) {
      const isNumeric = /^\d+$/.test(inputValue.split('JO-')[1]); //check when numbers are pasted
      if (!isNumeric) {
        event.preventDefault();
        return;
      }
      this.filteringColumn = { dataType: 'string', name: 'jupiterId' };
      this.filterApply(this.jupiterFilterValue);
    }
  }
  getAccordianStyles() {
    var paddingLeft = '0rem'; //defualt Padding for accordian
    if (this.currentTileNameForGrid != 'CCDueNow') {
      paddingLeft = '1.5rem'; //Padding for accordian
    }
    return { 'padding-left': paddingLeft };
  }

  public rowTooltipDisabled(value: boolean) {
    const tooltip = document.querySelector('.tableRowTooltip') as HTMLElement;

    if (value) {
      tooltip?.classList.add('d-none');
    } else {
      tooltip?.classList.remove('d-none');
    }
  }

  isSortFilterPresent(name: string): boolean {
    return ![
      this.columnName.Actions.valueOf(),
      this.columnName.Focus.valueOf(),
      this.columnName.SubmittedDate.valueOf(),
      this.columnName.PendingRequiredReviewers.valueOf(),
      this.columnName.PendingOptionalReviewers.valueOf(),
      this.columnName.SubmissionStatusName.valueOf(),
      this.columnName.DateOfLastStatusChange.valueOf(),
      this.columnName.LastStatus.valueOf(),
      this.columnName.SelfReviewEligibilityChangeDate.valueOf(),
      this.columnName.OpportunityIcon.valueOf(),
      this.columnName.PrimaryRiskManager.valueOf(),
    ].includes(name);
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  splitArrayValues(input: string | string[] | null, separator: string): string {
    if (input === null || input === undefined) {
      return '';
    } else if (typeof input === 'string') {
      return input;
    } else if (Array.isArray(input)) {
      return input.join(separator);
    } else {
      throw new Error('Invalid input type');
    }
  }

  haveRowExpand() {
    return !(
      this.currentDashboardTileCode === this.dashboardTileCode.Reviewer ||
      this.currentDashboardTileCode === this.dashboardTileCode.EngagementTeam ||
      this.currentDashboardTileCode === this.dashboardTileCode.RiskManager
    );
  }

  openFocusLink(url: any) {
    window.open(url, '_blank');
  }

  showActionButtons(
    condition: boolean | string,
    action: string,
    index: number
  ) {
    if (
      this.currentDashboardTileCode === this.dashboardTileCode.NCASpecialist &&
      this.currentTileNameForGrid === this.ncaTileCodes.CompleteMarsNcaForm &&
      action === 'InitiateForm'
    ) {
      return (
        this.model[index].clientMilestoneStatusId ==
        this.enumClientMilestoneStatus.NotStarted
      );
    } else if (typeof condition === 'boolean') {
      return condition;
    } else if (
      typeof condition === 'string' &&
      this.currentDashboardTileCode === this.dashboardTileCode.EngagementTeam
    ) {
      const row = this.model[index];

      if (action === 'CompleteRequiredAction') {
        return (
          !row['enableSubmit'] &&
          !row['enableArchiveAndComplete'] &&
          !row['enableResubmitForReview']
        );
      } else if (
        action === 'Submit' &&
        row['enableSubmit'] === true &&
        row['enableSelfReview'] === false &&
        row['submissionStatusId'] !== 2
      ) {
        return row[condition];
      } else if (
        action === 'Resubmit' &&
        row['enableSubmit'] === true &&
        row['enableSelfReview'] === false &&
        row['submissionStatusId'] === 2
      ) {
        return row[condition];
      } else if (
        (action === 'SelfReview' || action === 'OptOutSelfReview') &&
        row['enableSelfReview'] === true &&
        row['enableSubmit'] === true
      ) {
        return true;
      } else if (action === 'ArchiveComplete' || action === 'ResubmitReview') {
        return row[condition];
      } else {
        return false;
      }
    }
    return condition;
  }

  rowClicked(row: any): void {
    if (
      this.currentDashboardTileCode === this.dashboardTileCode.NCASpecialist
    ) {
      this.gotoClientStatus(row?.clientNumber);
    } else if (
      this.currentDashboardTileCode === this.dashboardTileCode.Reviewer
    ) {
      if (
        this.currentTileNameForGrid !== this.reviewerTileCodes.CCDueNow &&
        this.currentTileNameForGrid !==
          this.reviewerTileCodes.NCAPendingApproval
      ) {
        this.goToSubmission(row?.jupiterId, row?.submissionId);
      } else {
        this.gotoClientStatus(row?.clientNumber);
      }
    } else if (
      this.currentDashboardTileCode === this.dashboardTileCode.EngagementTeam
    ) {
      this.goToSubmission(row?.jupiterId, row?.submissionId);
    } else if (
      this.currentDashboardTileCode === this.dashboardTileCode.RiskManager
    ) {
      this.navigateToSummary(
        row?.jupiterId,
        row?.submissionTitle || row?.opportunityName
      );
    }
  }
}
