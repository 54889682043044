<div class="chart-container" *ngIf="isChartDataLoaded">
    <div class="chart-title">
        <div class="chart-title-heading">{{barChartTitle}}</div>
        <app-chart-legend [legendBar]="legendBar" (legendItemClick)="chartClick($event)"></app-chart-legend>
    </div>
    <div *ngIf="bars?.length && bars[0].itemValues.length">
        <div class="chart-body">
            <ng-container *ngFor="let item of bars; let index = index;">
                <app-bar-chart-item [barItem]="item" (barItemClick)="chartClick($event)"></app-bar-chart-item>
            </ng-container>
        </div>
    </div>
</div>