import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AdminUrlConstants } from '../constants/adminUrl-constants';
import {
  globalConfigurationRequestBody,
  globalConfigurationResponse,
  updateLabelConfigurationBody,
  configurationCategory,
} from '../../common/models/admin/global-configuration.model';
import {
  adminExtCommTrainingTableData,
  adminExtCommTrainingTableDataResponse,
  filterState,
  MarketOfferingRequest,
  MarketOfferingResponse,
  ModifyMarketOfferingRequest,
  RequestBodyEditAndAddRecord,
  updateResponse,
} from '../../common/models/admin-module.model';
import { DropdownItem } from '../../common/services/dropdowns';
import {
  RoleAssignedIndustryData,
  RoleAssignmentGridData,
  RoleAttributeData,
  SaveGlobalEngRoleConfigModel,
} from '../../common/models/admin/roleassignment.model';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AdminModuleService {
  constructor(
    private readonly http: HttpClient,
    private readonly datePipe: DatePipe
  ) {}
  apiUrl: string = environment.apiUrl;
  // apiUrl: string = 'https://dapi.eclipse.deloitte.com/api/'; //dev
  //apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa

  readonly #addRecordToRiskMgrTable: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

    addRecordToRiskTable$: Observable<boolean> =
    this.#addRecordToRiskMgrTable.asObservable();
    
    addRecordToTable(state: boolean): void {
      this.#addRecordToRiskMgrTable.next(state);
    }

  getMarketOfferingTableData(
    requestBody: MarketOfferingRequest
  ): Observable<MarketOfferingResponse> {
    return this.http.post<MarketOfferingResponse>(
      this.apiUrl + AdminUrlConstants.GET_MARKET_OFFERING_TABLE_DATA,
      requestBody
    );
  }

  updateMarketOfferingData(
    requestBody: ModifyMarketOfferingRequest
  ): Observable<updateResponse> {
    return this.http.post<updateResponse>(
      this.apiUrl + AdminUrlConstants.SAVE_MARKET_OFFERING_DATA,
      requestBody
    );
  }

  getExtCommTrainingData(
    requestBody: filterState[],
    sortParamaterCode: number | null,
    isAscending: boolean | null
  ): Observable<adminExtCommTrainingTableData[]> {
    let params = new HttpParams();

    if (sortParamaterCode !== null) {
      params = params.set('sortParamaterCode', sortParamaterCode.toString());
    }

    if (isAscending !== null) {
      params = params.set('isAscending', isAscending.toString());
    }

    return this.http.post<adminExtCommTrainingTableData[]>(
      `${this.apiUrl}${AdminUrlConstants.GET_ADMIN_EXTCOMM_TRAINING_TABLE_DATA}`,
      requestBody,
      { params }
    );
  }

  getExtCommRiskManagerData(): Observable<adminExtCommTrainingTableDataResponse> {
    return this.http.get<adminExtCommTrainingTableDataResponse>(
      `${this.apiUrl}${AdminUrlConstants.GET_ADMIN_EXTCOMM_RISK_MANAGER_TABLE_DATA}`
    );
  }

  saveAndUpdateExtCommTrainingData(
    requestBody: RequestBodyEditAndAddRecord
  ): Observable<void> {
    return this.http.post<void>(
      this.apiUrl +
        AdminUrlConstants.SAVE_AND_UPDATE_ADMIN_EXTCOMM_TRAINING_TABLE_DATA,
      requestBody
    );
  }

  saveAndUpdateExtCommRiskManagerData(
    requestBody: RequestBodyEditAndAddRecord
  ): Observable<void> {
    return this.http.post<void>(
      this.apiUrl +
        AdminUrlConstants.SAVE_AND_UPDATE_ADMIN_EXTCOMM_RISK_MANAGER_TABLE_DATA,
      requestBody
    );
  }

  getGlobalConfigurationTableData(
    data: globalConfigurationRequestBody
  ): Observable<globalConfigurationResponse> {
    return this.http.post<globalConfigurationResponse>(
      this.apiUrl + AdminUrlConstants.GET_GLOBAL_CONFIGURATION_TABLE_DATA,
      data
    );
  }

  getGlobalConfigurations(
    categoryId: number,
    searchTerm: string
  ): Observable<globalConfigurationResponse> {
    return this.http.get<globalConfigurationResponse>(
      this.apiUrl +
        AdminUrlConstants.GET_GLOBAL_CONFIGURATION +
        `?configurationType=${categoryId}&searchTerm=${searchTerm}`
    );
  }

  updateLabelConfiguration(
    data: updateLabelConfigurationBody
  ): Observable<updateLabelConfigurationBody> {
    return this.http.post<updateLabelConfigurationBody>(
      this.apiUrl + AdminUrlConstants.UPDATE_GLOBAL_CONFIGURATION_LABEL,
      data
    );
  }

  updateGlobalConfiguration(
    data: updateLabelConfigurationBody
  ): Observable<updateLabelConfigurationBody> {
    return this.http.patch<updateLabelConfigurationBody>(
      this.apiUrl +
        AdminUrlConstants.SAVE_GLOBAL_CONFIGURATION +
        `?configurationType=${data.CategoryId}`,
      data
    );
  }

  adminPageRefresh(): Observable<void> {
    return this.http.get<void>(
      this.apiUrl + AdminUrlConstants.ADMIN_PAGE_REFRESH
    );
  }

  getRoles(): Observable<Array<DropdownItem>> {
    return this.http.get<Array<DropdownItem>>(
      this.apiUrl + AdminUrlConstants.GET_ROLES
    );
  }

  getListbyRole(roleid: number,sortParameter: string, isAscending: boolean, fssType?: number, searchTerm?: string): Observable<RoleAssignmentGridData[]> {
    var url = this.apiUrl + AdminUrlConstants.GET_LIST_BY_ROLE + `?roleid=${roleid}` + `&sortParameter=${sortParameter}` + `&isAscending=${isAscending}`;
    if(fssType){
      url += `&fssType=${fssType}`;
    }
    if(searchTerm){
      url += `&searchTerm=${searchTerm}`;
    }
    return this.http.get<RoleAssignmentGridData[]>(
      url
    );
  }

  getRoleAttributeValues(roleid: number): Observable<Array<RoleAttributeData>> {
    return this.http.get<Array<RoleAttributeData>>(
      this.apiUrl + AdminUrlConstants.GET_ROLE_ATTRIBUTE_VALUES + `?roleid=${roleid}`
    );
  }

  getGlobalEngRoleConfigData(
    roleId: number
  ): Observable<RoleAssignmentGridData[]> {
    return this.http
      .get<RoleAssignmentGridData[]>(
        `${this.apiUrl}${AdminUrlConstants.GET_GLOBAL_ENG_ROLE_CONFIG}${roleId}`
      )
      .pipe(
        map((data: any[]) => {
          return data.map((item) => {
            const modifiedDate: string =
              this.datePipe.transform(item.modifiedDate, 'MM/dd/yyyy') ?? '';

            return {
              roleId: item.roleId,
              outlookDisplayName: item.name,
              roleAssignmentComment: item.comments,
              modifiedByText: item.modifiedBy,
              modifiedDate: modifiedDate,
              empId: item.employeeId,
              isActive: item.isActive,
            } as RoleAssignmentGridData;
          });
        })
      );
  }

  saveGlobalEngRoleConfig(
    requestBody: RoleAssignmentGridData
  ): Observable<void> {
    const body: SaveGlobalEngRoleConfigModel = {
      employeeId: requestBody.empId,
      isactive: requestBody.isActive,
      comments: requestBody.roleAssignmentComment,
      roleid: requestBody.roleId,
    };
    return this.http.post<void>(
      this.apiUrl + AdminUrlConstants.POST_GLOBAL_ENG_ROLE_CONFIG,
      body
    );
  }

  searchEmployees(searchKey: string): Observable<any> {
    return this.http.get<RoleAssignmentGridData[]>(
      this.apiUrl + AdminUrlConstants.GET_EMPLOYEES + `?searchKey=${searchKey}`
    );
  }

  upsertAdminRole(
    requestBody: RoleAssignmentGridData
  ): Observable<updateResponse> {
    return this.http.post<updateResponse>(
      this.apiUrl + AdminUrlConstants.UPSERT_ADMIN_ROLE_ASSIGNMENT,
      requestBody
    );
  }

  getConfigurationCategories(): Observable<configurationCategory[]> {
    return this.http.get<configurationCategory[]>(
      this.apiUrl + AdminUrlConstants.GET_CONFIGURATION_CATEGORY
    );
  }

  getAllAssignedIndustries(roleid: number,empId: string): Observable<RoleAssignedIndustryData[]> {
    var url = this.apiUrl + AdminUrlConstants.GET_ALL_ASSIGNED_INDUSTRIES + `?roleid=${roleid}` + `&empId=${empId}`;
    return this.http.get<RoleAssignmentGridData[]>(
      url
    );
  }
}
