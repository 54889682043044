import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  ButtonKind,
  ErrorState,
  ExtThemes,
  Size,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { ExternalCommunicationConstant } from '../../../../external-communications/constants/external_communication.constant';
import { AdminConstant } from '../../../constants/admin-constants';
import {
  MultiSelectItem,
  MultiSelectOptions,
  SelectControlTypes,
  SelectType,
} from '@usitsdasdesign/dds-ng/multi-select';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { InputOptions } from '@usitsdasdesign/dds-ng/input';
import {
  ToggleGroupOptions,
  ToggleOptions,
} from '@usitsdasdesign/dds-ng/toggle';

export const ModalCloseButtonOptions: ButtonOptions = {
  theme: ExtThemes.dark,
  kind: ButtonKind.silent,
  size: AdminConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: AdminConstant.DDS_ICON_CLOSE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Close modal',
  customClass: AdminConstant.EMPTY_SPACE,
  role: 'button',
};
export const multiSelectOptions: MultiSelectOptions = {
  label: AdminConstant.EMPTY_SPACE,
  size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  description: AdminConstant.EMPTY_SPACE,
  placeholder: 'Select',
  type: SelectType.counter,
  controlType: SelectControlTypes.checkbox,
  theme: 'green',
  isInverse: false,
  disabled: false,
  isResponsive: true,
  isError: false,
  isRequired: false,
  displayTickAllBtn: true,
  errorMessage: AdminConstant.EMPTY_SPACE,
  errorState: ErrorState.default,
  customClass: AdminConstant.EMPTY_SPACE,
  stickerWidth: 0,
  ariaLabel: 'dropdownOptions',
  stickerShift: 0,
  stickerMaxHeight: AdminConstant.EMPTY_SPACE,
  stickerIsDisabled: false,
  stickerPosition: 'bottom-left',
  stickerIndent: 0,
  stickerCustomClass: AdminConstant.EMPTY_SPACE,
  counterTypeText: AdminConstant.EMPTY_SPACE,
};
// TODO: to be removed hardcoded values once api ready for this
export const dropdownItems: MultiSelectItem[] = [
  {
    label: 'PRO',
  },
  {
    label: 'EL/SOW',
  },
  {
    label: 'CO',
  },
  {
    label: 'DEL',
  },
  {
    label: 'OTH',
  },
];

export const groupToolTipOptions: TooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'top',
  tooltipIndent: 15,
  tooltipHasBeak: true,
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipTheme: Themes.dark,
  tooltipMaxWidth: 300,
};

export const inputOptions: InputOptions = {
  size: AdminConstant.UI_ELEMENT_SIZE,
  isInverse: false,
  errorState: 'default',
  placeholder: 'Placeholder',
  tooltipIcon: 'dds-icon_info',
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipPosition: 'right',
  tooltipIndent: 20,
  tooltipHasBeak: true,
  tooltipTheme: 'dark',
};

// TODO: to be removed hardcoded values once api ready for this
export const dropdownItemsCodes: MultiSelectItem[] = [
  {
    label: 'PRO',
    code: 1,
  },
  {
    label: 'EL/SOW',
    code: 2,
  },
  {
    label: 'CO',
    code: 3,
  },
  {
    label: 'DEL',
    code: 4,
  },
  {
    label: 'OTHER',
    code: 5,
  },
];
export const options: TagOptions = {
  theme: Themes.dark,
  isRemovable: true,
  disabled: false,
  isError: false,
  isInverse: false,
  size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  customClass: AdminConstant.EMPTY_SPACE,
};

export const groupOptions: ToggleGroupOptions = {
  title: 'Title',
  isInverse: false,
  isRequired: false,
};

export const toggleOptions: ToggleOptions = {
  theme: Themes.green,
  label: AdminConstant.EMPTY_SPACE,
  ariaLabel: 'toggleOptions',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isProcessingStatusActive: false,
  isRequired: false,
  customClass: AdminConstant.EMPTY_SPACE,
};

export const buttonOptions: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.secondaryLoud,
  size: Size.lg,
  width: WidthState.fixed,
  isLoading: false,
  icon: AdminConstant.EMPTY_SPACE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'closeButton',
  customClass: AdminConstant.EMPTY_SPACE,
  role: 'button',
};

export const labelToCodeMapping: { [key: string]: number } = {
  PRO: 1,
  'EL/SOW': 2,
  CO: 3,
  DEL: 4,
  OTH: 5,
};
