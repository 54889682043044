import { Size, TabThemeType, Themes } from '@usitsdasdesign/dds-ng/shared';
import { TabOptions, TabsOptions } from '@usitsdasdesign/dds-ng/tabs';

export const tabContainerOptions: TabsOptions = {
  theme: Themes.green,
  themeType: TabThemeType.border,
  size: Size.lg,
  ariaLabel: 'Horizontal tabs',
  disabled: false,
  isInverse: false,
  customClass: '',
  isResponsive: false,
};

export const tabOptions1: TabOptions = {
  label: 'Details',
};
export const tabOptions2: TabOptions = {
  label: 'Complexity',
};
