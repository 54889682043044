export class CommonUrlConstant {
  static readonly #COMMON_ADMIN_AUDIT_PATH = 'Admin/AuditTrail/';
  static readonly #COMMON_ADMIN_PATH = 'Admin/Admin/';
  // -------------------------------- Audit Trail --------------------------------
  public static readonly GET_AUDIT_TRAIL_DATA: string = `${
    this.#COMMON_ADMIN_AUDIT_PATH
  }GetAuditTrail?moduleId=`;
  public static readonly GET_EMPLOYEES_LIST: string =
    CommonUrlConstant.#COMMON_ADMIN_PATH + 'GetEmployeeInfo?searchKey=';
}
