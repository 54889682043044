import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { EntityDetails, EntityType, UserService } from '../http/user.service';
import { MsGraphService } from '../http/msgraph.service';
import { combineLatest, filter, Observable, Subject, takeUntil } from 'rxjs';
import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
import {
  WidthState,
  Themes,
  Size,
  ButtonKind,
  ExtThemes,
  PositionState,
} from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  ProfileItemComponent,
  ProfileOptions,
} from '@usitsdasdesign/dds-ng/profile';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { NavigationEnd, Router } from '@angular/router';
import {
  DropdownOptions,
  DropdownItemOptions,
} from '@usitsdasdesign/dds-ng/dropdown';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { StartNcaprocessDialogComponentComponent } from '../start-ncaprocess-dialog-component/start-ncaprocess-dialog-component.component';
import { SubmissionService } from '../http/intake/submission.service';
import { IntakeConstant } from '../intake/constants/intake.constant';
import { CommonService } from '../http/intake/common.service';
import { RoleService } from '../http/intake/role.service';
import { RoleEnum } from '../intake/constants/Role.enum';
import {
  ClientAcceptanceStatusAction,
  MarsClientExport,
} from '../common/models/client-acceptance-status-action.model';
import { ClientMilestoneStatus } from '../http/client/client-status-webapi.service';
import { ConsultingNCAConstants } from '../client/constants/consulting-nca-constant.';
import { DocumentService } from '../http/document/document.service';
import { ExportPdfPopupComponent } from './export-pdf-popup/export-pdf-popup.component';
import { MsalService } from '@azure/msal-angular';
import { DEFAULT_PROFILE_PIC } from '../app.constants';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environment/environment';
import { SecurityWebapiService } from '../http/security/security-webapi.service';
import { MatrixKeys } from '../common/constants/security-matrix';
import { PermissionCheck } from '../common/validations/PermissionCheck';

@Component({
  selector: 'app-eclipse-header',
  templateUrl: './eclipse-header.component.html',
  styleUrl: './eclipse-header.component.less',
})
export class EclipseHeaderComponent
  extends PermissionCheck
  implements OnInit, AfterViewInit
{
  @ViewChild('logout', { static: false }) logout?: ProfileItemComponent;
  public clientId: number = 0;
  public displayUserName: string | null = null;
  public userEmail: string | null = null;
  public cookieConsentDomainScript: string =
    environment.cookieConsentDomainScript;
  public cookieConsentEndPointUrl: string =
    environment.cookieConsentEndPointUrl;
  public imageUrl: any = '';
  public enumClientMilestoneStatus = ClientMilestoneStatus;
  private readonly securityWachlistDashboardVisible: string =
    'Watchlist.Dashboard';
  public isWachlistDashboardVisible: boolean = false;
  private readonly securityThirdPartyAgreementRepositoryVisible: string = 'Application.ThirdPartyAgreementRepository';
  public isThirdPartyAgreementRepositoryVisible: boolean = false;

  isProficPicAvailable: boolean = false;
  isDropdownOpen: boolean = false;
  userRoles: string = '';
  isAdminCompleteVisible: boolean = false;
  isDiscontinueVisible: boolean = false;
  isResetStatusVisible: boolean = false;
  isExportToPdfVisible: boolean = false;

  isClientMilestoneExportVisible: boolean = false;
  isMarsClientExportVisible: boolean = false;
  preveiousIsMarsClientExportVisible: boolean = false;
  isAuditTrailVisible: boolean = false;
  clientNumber: string = '';
  clientMilestoneProcessType: string | null  = null;
  isSystemAdministrator: boolean = false;
  selectedClientMilestoneId: number = 0;
  externalCommunication: string = 'External Communication';
  headerOptions: HeaderOptions = {
    name: 'Eclipse',
    width: WidthState.full,
    theme: Themes.green,
    isResponsive: false,
    isInverse: false,
    customClass: '',
  };

  responsivePriority: HeaderElmnts[] = [
    HeaderElmnts.logo,
    HeaderElmnts.projectName,
    HeaderElmnts.profile,
    HeaderElmnts.nav,
    HeaderElmnts.icons,
    HeaderElmnts.search,
  ];
  headerButtonOptions: ButtonOptions = {
    theme: Themes.dark,
  };
  chatButtonOptions: ButtonOptions = {
    theme: Themes.dark,
    icon: 'dds-icon_chat__s__stroke',
  };
  profileOptions: ProfileOptions = {
    username: '',
    photoUrl: '',
  };

  searchOptions: SearchOptions = {
    placeholder: 'Search clients, opportunities or submissions by name or id',
    ariaLabel: '',
    size: Size.md,
    isInverse: false,
    customClass: '',
    isTrimValue: false,
    readonly: false,
    maxLength: 100,
  };

  defaultDropdownStrickerPostion: PositionState =
    PositionState.bottomLeft ?? undefined;
  dropdownOptions: DropdownOptions = {
    label: '',
    ariaLabel: '',
    theme: ExtThemes.dark,
    kind: ButtonKind.primaryLoud,
    size: Size.md,
    width: WidthState.fixed,
    icon: 'dds-icon_plus',
    isColorBg: false,
    isInverse: false,
    customClass: '',
    disabled: false,
    isResponsive: false,
    stickerWidth: 300,
    stickerIsOutsideClick: false,
    stickerShift: 0,
    stickerMaxHeight: '',
    stickerIsDisabled: false,
    stickerPosition: PositionState.bottomRight,
    stickerIndent: 0,
    stickerCustomClass: '',
    stickerIsDynamic: true,
  };

  dropdownItems: DropdownItemOptions[] = [
    {
      heading: 'Contract/Proposal/Eng. Related Review',
      value: 'Contract/Proposal/Eng. Related Review',
    },
    {
      heading: 'External communication',
      value: 'External Communication',
    },
    {
      heading: 'Start NCA process',
      value: 'StartNcaProcess',
    },
    {
      heading: 'MARS NCA request',
      value: 'MARS NCA Request',
    },
  ];

  defualtBaseOptions: DropdownItemOptions[] = [
    {
      heading: 'NCA summary dashboard',
      value: 'NcaSummary',
    },
    {
      heading: 'Third party agreements repository',
      value: 'Thirdpartyrepository',
    },
    {
      heading: 'Watchlist',
      value: 'Watchlist',
    },
  ];

  baseOptions: DropdownItemOptions[] = [...this.defualtBaseOptions];

  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  // Getter to dynamically include 'Admin Complete' based on the condition
  get additionalOptions(): DropdownItemOptions[] {
    if (
      !this.isClientMilestoneExportVisible &&
      !this.isMarsClientExportVisible
    ) {
      this.baseOptions = [...this.defualtBaseOptions];
    }
    if (!this.isThirdPartyAgreementRepositoryVisible) {
      this.baseOptions = this.baseOptions.filter(
        (option) => option.value !== 'Thirdpartyrepository'
      );
    }
    if (!this.isWachlistDashboardVisible) {
      this.baseOptions = this.baseOptions.filter(
        (option) => option.value !== 'Watchlist'
      );
    }
    if (
      this.isExportToPdfVisible &&
      this.isPermissionPresent(
        this.permissionEnums.Intake_Nav_Submission_Export,
        this.permissionType.Enable
      )
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.EXPORT_TO_PDF_HEADING,
          value: IntakeConstant.EXPORT_TO_PDF_VALUE,
        },
      ];
    }
    if (
      this.isAdminCompleteVisible &&
      this.isPermissionPresent(
        this.permissionEnums.Intake_Nav_Submission_Admin,
        this.permissionType.Enable
      )
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.ADMINISTRATEVELY_CLOSE_MENU_ITEM,
          value: IntakeConstant.ADMINISTRATEVELY_CLOSE_MENU_ITEM,
        },
      ];
    }
    if (
      this.isDiscontinueVisible &&
      this.isPermissionPresent(
        this.permissionEnums.Intake_Nav_Submission_Discontinue,
        this.permissionType.Enable
      )
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.DISCONTINUE_SUBMISSION_MENU_ITEM,
          value: IntakeConstant.DISCONTINUE_SUBMISSION_MENU_ITEM,
        },
      ];
    }
    if (
      this.isResetStatusVisible &&
      this.isPermissionPresent(
        this.permissionEnums.Intake_Nav_Submission_Reset,
        this.permissionType.Enable
      )
    ) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.RESET_SUBMISSION_STATUS,
          value: IntakeConstant.RESET_SUBMISSION_STATUS,
        },
      ];
    }
    if (this.isSystemAdministrator) {
      return [
        ...this.baseOptions,
        {
          heading: IntakeConstant.SYSTEM_ADMINISTRATOR,
          value: IntakeConstant.SYSTEM_ADMINISTRATOR,
        },
      ];
    }

    if (this.isAuditTrailVisible) {
      this.baseOptions = [
        ...this.baseOptions,
        {
          heading: IntakeConstant.AUDIT_TRAIL,
          value: IntakeConstant.AUDIT_TRAIL,
        },
      ];
    }
    //if (
    //  this.isMarsClientExportVisible != this.preveiousIsMarsClientExportVisible
    //) {
    //  if (this.isMarsClientExportVisible) {
    //    this.preveiousIsMarsClientExportVisible =
    //      this.isMarsClientExportVisible;
    //    this.baseOptions = [
    //      ...this.baseOptions,
    //      {
    //        heading: ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF_HEADING,
    //        value: ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF,
    //      },
    //    ];
    //  } else if (
    //    this.baseOptions.find(
    //      (obj) =>
    //        obj.heading ===
    //        ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF_HEADING
    //    )
    //  ) {
    //    this.baseOptions = this.baseOptions.filter(
    //      (obj) =>
    //        obj.heading !==
    //        ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF_HEADING
    //    );
    //  }

    //  return this.baseOptions;
    //}

    return this.baseOptions;
  }

  constructor(
    private readonly userService: UserService,
    private readonly msGraphService: MsGraphService,
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly submissionService: SubmissionService,
    private readonly roleService: RoleService,
    private readonly commonService: CommonService,
    private readonly documentService: DocumentService,
    private readonly msalService: MsalService,
    protected override readonly securityWebapiService: SecurityWebapiService,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly zone: NgZone,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    super(securityWebapiService);
  }

  ngOnInit(): void {
    this.securityWebapiService.data.subscribe((rules) => {
      if (!rules.empty) {
        this.isWachlistDashboardVisible =
          rules[this.securityWachlistDashboardVisible] &&
          rules[this.securityWachlistDashboardVisible].Visible !== undefined
            ? rules[this.securityWachlistDashboardVisible].Visible
            : false;
        this.isThirdPartyAgreementRepositoryVisible =
          rules[this.securityThirdPartyAgreementRepositoryVisible] &&
          rules[this.securityThirdPartyAgreementRepositoryVisible].Visible !==
            undefined
            ? rules[this.securityThirdPartyAgreementRepositoryVisible].Visible
            : false;
      }
    });
    let isValidProilePic = false;
    let userProfilePic = localStorage.getItem('userProfilePic');
    let userPreferredName = localStorage.getItem('userPreferredName');
    this.displayUserName = userPreferredName;
    this.profileOptions.username = this.displayUserName ?? 'User';
    this.loadCookieConsentScript();

    this.submissionService.currentlyOnSubmissionPage$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (status) => {
          this.isExportToPdfVisible = status;
          this.additionalOptions;
        },
        error: (err) => {
          console.error('Error fetching submission page status:', err);
        },
      });

    if (!userPreferredName) {
      this.userService.userEmail$.subscribe((useremail) => {
        this.userEmail = localStorage.getItem('userProfileId');
        this.getUserPreferredName();
      });
    }

    if (userProfilePic) {
      this.isValidBase64Image(userProfilePic).subscribe((isValid) => {
        if (isValid) {
          isValidProilePic = true;
          this.isProficPicAvailable = true;
          this.imageUrl = userProfilePic;
          this.profileOptions.photoUrl = this.imageUrl;
        }
      });
    }
    if (!isValidProilePic) {
      this.userService.userEmail$.subscribe((useremail) => {
        this.userEmail = localStorage.getItem('userProfileId');
        this.getUserProfilePic();
      });
    }
    this.handleChangeRoute();

    this.submissionService.adminEnabler$.subscribe((status: boolean) => {
      this.isAdminCompleteVisible = status;
    });

    combineLatest([
      this.submissionService.opportunitySelectedDocStatus$,
      this.commonService.discontinueAllowedSubmissionStatus$,
      this.roleService.currentUserRole$,
      this.roleService.submissionDiscontinueAllowedRoles$,
    ])
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe(
        ([
          selectedOpertunityDocStatus,
          allowedStatus,
          currentUseRole,
          allowedRoles,
        ]) => {
          this.isDiscontinueVisible =
            selectedOpertunityDocStatus != null &&
            allowedStatus.includes(selectedOpertunityDocStatus) &&
            currentUseRole != null &&
            allowedRoles.some((role) => currentUseRole.includes(role));
        }
      );
    this.commonService.resetEnabler$.subscribe((status: boolean) => {
      this.isResetStatusVisible = status;
    });
    this.commonService.clientMilestoneActionObservable$.subscribe(
      (action: ClientAcceptanceStatusAction | null) => {
        if (action) {
          this.isClientMilestoneExportVisible = action.showExportButton;
          this.clientNumber = action.clientNumber;
          this.clientMilestoneProcessType = String(
            action.clientMilestoneProcessType
          );
          this.selectedClientMilestoneId = action.selectedClientMilestoneId;
          this.baseOptions = [
            ...this.defualtBaseOptions,
            {
              heading:
                ConsultingNCAConstants.COMPLETED_NCA_FORM_PDF_HEADING.replace(
                  '#clientMilestoneProcessType',
                  this.clientMilestoneProcessType
                ),
              value:
                ConsultingNCAConstants.COMPLETED_NCA_FORM_PDF_VALUE.replace(
                  '#clientMilestoneProcessType',
                  this.clientMilestoneProcessType
                ),
            },
          ];
        } else {
          this.baseOptions = [...this.defualtBaseOptions];
        }
      }
    );
    this.commonService.MarsClientActionObservable$.subscribe(
      (action: MarsClientExport | null) => {
        if (action) {
          this.isMarsClientExportVisible = action.showExportButton;
          this.clientNumber = action.clientNumber;
          this.baseOptions = [
            ...this.defualtBaseOptions,
            {
              heading: ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF_HEADING,
              value: ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF,
            },
          ];
        } else {
          this.baseOptions = [...this.defualtBaseOptions];
        }
      }
    );

    this.submissionService.auditTrailEnabler$
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe((status: boolean) => {
        this.isAuditTrailVisible = status;
      });

    this.commonService.clientId$.subscribe((clientId) => {
      this.clientId = Number(clientId);
    });
  }

  ngAfterViewInit() {
    this.logout?.itemSelected.subscribe(() => {
      localStorage.clear();
      this.msalService.instance.logout();
    });

    const clickableElement =
      this.el.nativeElement.querySelector('.dds-header__main');
    this.renderer.listen(clickableElement, 'click', () => {
      this.zone.run(() => {
        this.router.navigate(['/']);
      });
    });
  }

  itemSelected(value: string): void {
    this.submissionService.enableAuditTrail(false);
    if (value === 'Contract/Proposal/Eng. Related Review') {
      this.router.navigate(['/submission']);
    } else if (value === 'MARS NCA Request') {
      this.router.navigate(['/initiate/mars-nca']);
    } else if (value === this.externalCommunication) {
      this.router.navigate(['/externalCommunications/create']);
    } else if (value === 'StartNcaProcess') {
      this.startNcaProcessManually();
      this.submissionService.enableAuditTrail(true);
    }
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  navigateTo(path: string): void {
    this.router.navigate([path]);
    this.toggleDropdown();
  }

  handleChangeRoute() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let entity: string = event.url;
        let entities = entity.split('/');
        let entityId, entityTypeId;

        if (entities.includes('client')) {
          entityTypeId = EntityType.Client;
          entityId = this.clientId;
        }
        // TODO: Need to check for opportunity-summary page of intake module
        else if (entities.includes('submission')) {
          entityTypeId = EntityType.Submission;
          entityId = EntityDetails.Intake;
          this.getPermissionByEmpId(entityId, entityTypeId);
        } else if (entities.includes('externalCommunications')) {
          entityTypeId = EntityType.Submission;
          entityId = EntityDetails.ExternalCommunications;
          this.getPermissionByEmpId(EntityDetails.Intake, entityTypeId);
        }
        else {
          entityTypeId = EntityType.None;
          entityId = EntityDetails.None;
          this.getUserRoles(0, 1);
        }

        this.getUserRoles(entityId, entityTypeId);
      });
  }

  getUserRoles(entityId: any, entityTypeId: any) {
    if (this.userEmail) {
      this.userService
        .getUserRoles(entityId, entityTypeId)
        .subscribe((data) => {
          if (data) {
            const loggedInUserRole: string[] = data?.employeeRoles?.map(
              (roles: any) => roles.roleName
            );
            this.userRoles = loggedInUserRole.join('\n');
            // Check if user is a System Administrator and set flag accordingly
            this.isSystemAdministrator = data.employeeRoles.some(
              (role: any) => role.roleName === RoleEnum.SYSTEM_ADMIN
            );
            this.roleService.setCurrentUserRoles(loggedInUserRole);
          }
        });
    }
  }

  getPermissionByEmpId(entityId: number, entityTypeId: number): void {
    this.securityWebapiService.getPermissionsByEmployeeId(
      entityId,
      entityTypeId
    );
  }

  getUserPreferredName() {
    if (this.userEmail)
      this.msGraphService
        .searchUserByEmail(this.userEmail)
        .subscribe((data) => {
          if (data && data.value && data.value.length > 0) {
            const user = data.value[0];
            let firstName = (user.givenName ?? '') + ' ' + (user.surname ?? '');
            const displayName = user.displayName;

            if (!firstName || firstName.trim() === '') {
              firstName = displayName;
            }

            if (firstName) {
              localStorage.setItem('userPreferredName', firstName);
              this.displayUserName = firstName;
              this.profileOptions.username = this.displayUserName ?? 'User';
            } else {
              console.error(
                'First name and display name are both missing or invalid.'
              );
            }
          } else {
            console.error('No user data found or data structure is invalid.');
          }
        });
  }

  openModal() {
    let modalRef = this.modalService.open(ExportPdfPopupComponent, {
      isFooter: false,
      size: 'md',
      isInverse: false,
    });
  }

  additionaloptionSelected(value: string): void {
    if (value == 'Watchlist') {
      this.router.navigate(['/watchlist']);
      this.submissionService.enableAuditTrail(false);
    }

    if (value == 'Thirdpartyrepository') {
      this.router.navigate(['/thirdpartyrepository']);
      this.submissionService.enableAuditTrail(false);
    }

    if (value == IntakeConstant.SYSTEM_ADMINISTRATOR) {
      this.router.navigate(['/admin-page']);
    }

    if (value == 'NcaSummary') {
      this.router.navigate(['/ncasummary']);
      this.submissionService.enableAuditTrail(false);
    }

    if (value == 'ExportToPdf') {
      this.openModal();
    }

    if (value === IntakeConstant.ADMINISTRATEVELY_CLOSE_MENU_ITEM) {
      this.submissionService.openAdminCompleteModal(true);
    }
    if (value === IntakeConstant.DISCONTINUE_SUBMISSION_MENU_ITEM) {
      this.submissionService.openDiscontinueSubmissionModal(true);
    }
    if (value === IntakeConstant.RESET_SUBMISSION_STATUS) {
      this.commonService.openResetStatusModal(true);
    }
    if (
      this.clientMilestoneProcessType &&
      value ===
        ConsultingNCAConstants.COMPLETED_NCA_FORM_PDF_VALUE.replace(
          '#clientMilestoneProcessType',
          this.clientMilestoneProcessType
        )
    ) {
      this.exportClientDataPdf(false);
    }
    if (value === ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF) {
      this.exportClientDataPdf(true);
    }
    if (value === IntakeConstant.AUDIT_TRAIL) {
      this.router.navigate(['/audit-trail']);
    }
  }
  removeBodyOverflow() {
    document.body.style.overflowY = 'hidden';
  }
  public async startNcaProcessManually() {
    this.removeBodyOverflow();
    this.modalService.open(StartNcaprocessDialogComponentComponent);
  }

  getUserProfilePic() {
    if (this.userEmail)
      this.msGraphService
        .GetProfileImage(this.userEmail)
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe(
          (data) => {
            try {
              if (data != null) {
                var imgUrl = data;

                this.isValidBase64Image(imgUrl).subscribe((isValid) => {
                  if (isValid) {
                    this.imageUrl = imgUrl;
                    this.profileOptions.photoUrl = this.imageUrl;
                    this.isProficPicAvailable = true;
                    localStorage.setItem('userProfilePic', data);
                  }
                });
              }
            } catch (error: any) {
              console.error('Error fetching profile image', error);
            }
          },
          (error) => {
            if (error.status == 404) {
              var imgUrl = DEFAULT_PROFILE_PIC;

              this.isValidBase64Image(imgUrl).subscribe((isValid) => {
                if (isValid) {
                  this.imageUrl = imgUrl;
                  this.profileOptions.photoUrl = this.imageUrl;
                  this.isProficPicAvailable = true;
                  localStorage.setItem('userProfilePic', imgUrl);
                }
              });
            }
          }
        );
  }

  isValidBase64Image(imageUrl: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        observer.next(true);
        observer.complete();
      };

      img.onerror = () => {
        observer.next(false);
        observer.complete();
      };
    });
  }

  exportClientDataPdf(isMars: boolean) {
    this.documentService.exportClientDataPDF(
      this.clientNumber,
      this.clientMilestoneProcessType === 'NCA',
      this.clientMilestoneProcessType === 'CC',
      isMars,
      this.selectedClientMilestoneId
    );
  }

  loadCookieConsentScript() {
    let script = this.renderer.createElement('script');
    script.type = `text/javascript`;
    script.src = this.cookieConsentEndPointUrl;
    script.charset = `UTF-8`;
    script.setAttribute('data-domain-script', this.cookieConsentDomainScript);

    let optanonScript = this.renderer.createElement('script');
    optanonScript.type = `text/javascript`;
    optanonScript.innerHTML = `function OptanonWrapper() { }`;

    this.renderer.appendChild(this._document.body, script);
    this.renderer.appendChild(this._document.body, optanonScript);
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
