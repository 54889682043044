import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FeeType,
  OpportunityDetailsEdit,
} from '../../../common/models/opportunityDetailsEdit';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import { ErrorState, Size } from '@usitsdasdesign/dds-ng/shared';
import { SubmissionService } from '../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { configurationType } from '../../../common/models/common-models';
import { DatePipe } from '@angular/common';
import {
  DatepickerOptions,
  DatepickerStates,
} from '@usitsdasdesign/dds-ng/datepicker';
import { SaveOpportunityDetailsSection } from '../../../common/models/saveOpportunityDetails';

import {
  MultiSelectItem,
  MultiSelectOptions,
  SelectControlTypes,
  SelectType,
} from '@usitsdasdesign/dds-ng/multi-select';
import { IntakeConstant } from '../../constants/intake-constant';
import { LepValue } from '../../../common/models/opportunity-details.model';
import { lepDataValue } from '../opportunity-details-settings';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { OpportunityDetailsNavModel } from '../../../common/models/opportunity-details.model';
import { commonSectionForSingleCoCheck } from '../../common/intake.util';
@Component({
  selector: 'app-opportunity-details-section',
  templateUrl: './opportunity-details-section.component.html',
  styleUrl: './opportunity-details-section.component.less',
  providers: [DatePipe],
})

export class OpportunityDetailsSectionComponent implements OnInit {
  feeTypeList: MultiSelectItem[] = [];
  public saveopportunityDetailsModule!: Saver | null;
  isEditingLEP: boolean = false;
  isEditingTotalFees: boolean = false;
  textareaOptions: any = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  isEditingEndDate: boolean = false;
  isEditingStartDate: boolean = false;
  lepValue: string = '';
  tempLepValue: string = this.lepValue;
  tempStartDate: Date;
  totalFees: string = '';
  isEditingFeeType: boolean = false;
  tempTotalFees: string = this.totalFees;
  defaultForEmptyFields = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
  commonPlaceholder = IntakeConstant.ENTER_TEXT_PLACEHOLDER;
  opportunityDescription: string = '';
  tempEndDate: Date;
  startDate: string | null = '';
  endDate: string | null = '';
  updateLabel: string = '';
  oportunityDetailsInfo: OpportunityDetailsEdit = {
    opportunityNumber: '',
    projectStartDate: '',
    projectEndDate: '',
    leadEngagementPartner: '',
    totalFees: '',
    feeType: [],
    clientNumber:'',
    opportunityDescription: '' 
  };
  multiselectFeeTypesOptions: MultiSelectItem[] = [];
  lepData: LepValue = lepDataValue;
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;

  datePickerOptions: DatepickerOptions = {
    size: this.htmlElementSize,
    placeholder: 'Select date',
    format: 'dd/MM/yyyy',
    minMode: DatepickerStates.day,
    isResponsive: true,
    isManualInput: false,
  };
  multiSelectOptions: MultiSelectOptions = {
    label: '',
    size: this.htmlElementSize,
    description: '',
    placeholder: 'Select Fee Types',
    type: SelectType.text,
    controlType: SelectControlTypes.checkbox,
    theme: 'green',
    isInverse: false,
    disabled: false,
    isResponsive: false,
    isError: false,
    isRequired: false,
    displayTickAllBtn: false,
    errorMessage: '',
    errorState: ErrorState.default,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: '',
    stickerIsDisabled: false,
    stickerPosition: 'bottom-left',
    stickerIndent: 0,
    stickerCustomClass: '',
  };
  feeTypeChanged(): void {}

  @Input() id: string = '';
  @Input() submissionData: OpportunityDetailsNavModel[] = [];
  @Output() lepValueChange: EventEmitter<LepValue> =
    new EventEmitter<LepValue>();
    unsubscriber$: Subject<void>= new Subject<void>();
  
  constructor(
    private readonly autosaverService: AutosaverService,
    private readonly intakeDetailService: SubmissionService,
    private readonly submissionService: SubmissionService,
    private readonly datePipe: DatePipe,
    private readonly progressIndicator: ProgressIndicatorService
  ) {
    this.tempStartDate = new Date();
    this.tempEndDate = new Date();
  }

  ngOnInit(): void {
    this.progressIndicator.show();
    this.getLabelConfiguration();
    this.getOpportunityDetailsValues(this.id);
    this.getFeeType();
    this.intakeDetailService.updateDetailStatus(
      this.opportunityDescription.length !== 0
    );
    let autoSaverOptions = {
      onSuccess: (saver: any, result: SaveOpportunityDetailsSection) => {
        return this.autoSaveHandler();
      },
    } as IAutosaverOptions;
    this.saveopportunityDetailsModule = this.autosaverService.newSaver(
      'saveopportunitydetailsmodel',
      this,
      async () => this.saveOpportunityDetailsValues(),
      autoSaverOptions
    );
    this.saveopportunityDetailsModule.start();
    this.progressIndicator.hide();
  }

  //TO DO :To be handled later
  public autoSaveHandler(): void {}

  getFeeType(): void {
    this.submissionService
      .getFeeTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: FeeType[]) => {
          if (response && response.length > 0) {
            const feeTypeList = response;
            this.multiselectFeeTypesOptions = feeTypeList.map(
              (feeType: FeeType) => ({
                label: feeType?.feeTypeName,
                value: false,
              })
            );
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getOpportunityDetailsValues(id: string): void {
    this.intakeDetailService
      .getOpportunityDetailsInfo(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.oportunityDetailsInfo = data;
          if (
            this.oportunityDetailsInfo?.feeType &&
            this.oportunityDetailsInfo?.feeType.length > 0 &&
            this.oportunityDetailsInfo?.feeType[0]?.feeTypeName
          )
            this.feeTypeList = this.oportunityDetailsInfo?.feeType.map(
              (feeType: FeeType) => ({
                label: feeType?.feeTypeName,
                value: true,
              })
            );

          this.lepValue = this.oportunityDetailsInfo?.leadEngagementPartner
            ? this.oportunityDetailsInfo?.leadEngagementPartner
            : '';
          this.lepData.lepReviewedBy = data.pursuitLeaderId;
          this.lepData.lepName = this.lepValue;
          this.lepValueChange.emit(this.lepData);
          this.totalFees = this.oportunityDetailsInfo?.totalFees
            ? this.oportunityDetailsInfo?.totalFees
            : '';
          this.tempTotalFees = this.totalFees;
          this.startDate = this.oportunityDetailsInfo?.projectStartDate
            ? this.datePipe.transform(
                this.oportunityDetailsInfo?.projectStartDate,
                'dd/MM/yyyy'
              )
            : '';
          this.tempStartDate = new Date(
            this.oportunityDetailsInfo?.projectStartDate
          );
          this.endDate = this.oportunityDetailsInfo?.projectEndDate
            ? this.datePipe.transform(
                this.oportunityDetailsInfo?.projectEndDate,
                'dd/MM/yyyy'
              )
            : '';
          this.tempEndDate = new Date(
            this.oportunityDetailsInfo?.projectEndDate
          );
        },
      });
  }

  saveOpportunityDetailsValues() {
    // TODO : TO be handled for other edits and autosave as a part of seperate story
    const saveopportunitydetailsmodel = {
      opportunityNumber: this.id,
      opportunitydescription: this.opportunityDescription,
    };

    this.submissionService
      .updateOpportunityDetails(saveopportunitydetailsmodel)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicator.hide();
        },
        error: (err) => {
          this.progressIndicator.hide();
          console.error('An error occurred during submission: ', err);
        },
      });
    //ToDO: to add the save logic
    console.log('called', this.opportunityDescription);
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (this.isEditingLEP && !target.closest('#Lep-editor')) {
      this.saveLEP();
    }
    if (this.isEditingTotalFees && !target.closest('#TotalFees-editor')) {
      this.saveTotalFees();
    }
    if (this.isEditingEndDate && !target.closest('#EndDate-editor')) {
      this.saveEndDate();
    }
    if (this.isEditingStartDate && !target.closest('#StartDate-editor')) {
      this.saveStartDate();
    }
    if (this.isEditingFeeType && !target.closest('#Fee-Type')) {
      this.saveFeeType();
    }
  }

  editLep(): void {
    this.isEditingLEP = true;
    this.tempLepValue = this.tempLepValue;
  }

  saveLEP(): void {
    this.lepValue = this.tempLepValue;
    this.lepData.lepName = this.lepValue;
    this.lepValueChange.emit(this.lepData);
    this.isEditingLEP = false;
  }

  editTotalFees(): void {
    this.isEditingTotalFees = true;
    this.tempTotalFees = this.totalFees;
  }

  saveTotalFees(): void {
    this.totalFees = this.tempTotalFees;
    this.isEditingTotalFees = false;
  }

  editEndDate(): void {
    this.isEditingEndDate = true;
  }

  editFeeType(): void {
    this.isEditingFeeType = true;
  }

  saveEndDate(): void {
    this.endDate = this.datePipe.transform(this.tempEndDate, 'dd/MM/yyyy');
    this.isEditingEndDate = false;
  }

  editStartDate(): void {
    this.isEditingStartDate = true;
  }

  saveStartDate(): void {
    this.startDate = this.datePipe.transform(this.tempStartDate, 'dd/MM/yyyy');
    this.isEditingStartDate = false;
  }

  saveFeeType(): void {
    this.isEditingFeeType = false;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next(undefined);
    this.unsubscriber$.complete();
  }

  getLabelConfiguration(): void {
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response && response.length > 0) {
            this.updateLabel =
              response.find(
                (config) =>
                  config.appConfigurationLabelKey ===
                  'UPDATE_JUPITER_OPPORTUNITY_INFORMATION'
              )?.appConfigurationLabelValue || '';
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  isOpportunityDescriptionRequiredIconVisible() {
    if (this.submissionData.length === 0 || commonSectionForSingleCoCheck(
      this.submissionData)) {
      return false;
    }  else if (this.opportunityDescription != '') {
      return false;
    } else {
      return true;
    }
  }

  valueChanged(): void {
    this.intakeDetailService.updateDetailStatus(
      this.opportunityDescription.length !== 0
    );
  }
}
