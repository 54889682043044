import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminModuleService } from '../../../admin-services/admin-module.service';
import { Subject, takeUntil } from 'rxjs';
import {
  adminExtCommTrainingTableData,
  adminExtCommTrainingTableDataResponse,
  RequestBodyEditAndAddRecord,
} from '../../../../common/models/admin-module.model';
import {
  btnOptions,
  sampleColumns,
} from './external-comm-risk-manager-table-helper';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { EditAddRecordPopupExtCommRiskManagerComponent } from './edit-add-record-popup-extcomm-risk-manager/edit-add-record-popup-extcomm-risk-manager.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-external-comm-risk-manager-table',
  templateUrl: './external-comm-risk-manager-table.component.html',
  styleUrls: ['./external-comm-risk-manager-table.component.less'],
})
export class ExternalCommRiskManagerTableComponent implements OnInit,OnDestroy {
  sampleColumns: Column[] = sampleColumns;
  adminExtCommTrainingTableData: adminExtCommTrainingTableData[] = [];
  btnOptions: ButtonOptions = btnOptions;
  private readonly unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    private readonly adminModuleService: AdminModuleService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.fetchExtCommRiskManagerData();
    this.adminModuleService.addRecordToRiskTable$.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
      if(data){
        this.addRecordToTable();
      }
    });
  }

  onEditTypeClick(event: Event, row: adminExtCommTrainingTableData): void {
    event.stopPropagation();
    this.openModal(row, false);
  }

  openModal(
    row: adminExtCommTrainingTableData | null,
    showAddRecordPopup: boolean
  ): void {
    const adminExtTrainingTable = this.modalService.open(
      EditAddRecordPopupExtCommRiskManagerComponent
    );
    adminExtTrainingTable.componentInstance.rowData = row;
    adminExtTrainingTable.componentInstance.showAddRecordPopup =
      showAddRecordPopup;

    adminExtTrainingTable
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.fetchExtCommRiskManagerData();
          this.adminModuleService.addRecordToTable(false);
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  addRecordToTable(): void {
    this.openModal(null, true);
  }

  fetchExtCommRiskManagerData(): void {
    this.adminModuleService
      .getExtCommRiskManagerData()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: adminExtCommTrainingTableDataResponse) => {
          this.adminExtCommTrainingTableData = response.ecrmConfig;
        },
        error: (err: HttpErrorResponse) => {
          console.error(
            'An error occurred during fetching market offering table data: ',
            err
          );
        },
      });
  }

  toggleActiveStatus(row: adminExtCommTrainingTableData): void {
    const index = this.adminExtCommTrainingTableData.findIndex(
      (record) => record.employeeId === row.employeeId
    );
    if (index !== -1) {
      const updatedRecord = {
        ...this.adminExtCommTrainingTableData[index],
        isActive: !this.adminExtCommTrainingTableData[index].isActive,
      };

      const requestBody: RequestBodyEditAndAddRecord = {
        EmployeeId: updatedRecord.employeeId,
        Comments: updatedRecord.comments,
        IsActive: updatedRecord.isActive,
      };

      this.adminModuleService
        .saveAndUpdateExtCommRiskManagerData(requestBody)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: () => {
            this.fetchExtCommRiskManagerData();
          },
          error: (err: HttpErrorResponse) => {
            console.error('Error saving record:', err);
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
