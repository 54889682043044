import { Injectable } from '@angular/core';
import { WbsModel } from '../../common/models/wbs-picker.model';
import { Observable } from 'rxjs';
import { UrlConstant } from '../../intake/constants/url-constant';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class CloseOutService {
  constructor(private readonly httpClient: HttpClient) {}

  #apiUrl: string = environment.apiUrl;

  getWbsPickers(): Observable<WbsModel[]> {
    return this.httpClient.get<WbsModel[]>(
       UrlConstant.GET_WBS_CHARGECODES
    );
  }
}
