import { RadioButton, RadioGroupOptions } from "@usitsdasdesign/dds-ng/radio";
import { ErrorState } from "@usitsdasdesign/dds-ng/shared";
import { capitalizeFirstLetter } from "../../../common/intake.util";
import { IntakeConstant } from "../../../constants/intake-constant";
import { CoElSowOptionValues } from "../../../constants/intake_document_close_out_co_elsow_option_values.enum";

export const RADIO_GROUP_OPTIONS_FOR_CO_AND_ELSOW_SCOPE: RadioGroupOptions =
  {
    name: 'other',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: 'Has the contract/change order been signed/executed',
    isRequired: true,
    isInverse: false,
  };

  export const CO_AND_EL_SOW_RADIO_GROUP_VALUE: RadioButton[] = [
    {
      options: {
        label: capitalizeFirstLetter(CoElSowOptionValues.YES),
        theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
        disabled: undefined,
      },
      value: CoElSowOptionValues.YES,
    },
    {
      options: {
        label: capitalizeFirstLetter(CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW),
        theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
      },
      value: CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW,
    },
    {
      options: {
        label: capitalizeFirstLetter(CoElSowOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED),
        theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
      },
      value: CoElSowOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED,
    },
  ];