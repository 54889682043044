<progress-indicator></progress-indicator>
<app-tiles-group
  (tileSelectedEvent)="onTileSelected($event)"
  [tileCodeDetails]="reviewerTileCodeDetails"
  [tilesCount]="reviewerDashboardTilesCountModel"
  [selectedTileCode]="selectedTileCode"
  [dashboardType]="dashboard"
></app-tiles-group>
<div class="start-page-tabs" *ngIf="reviewerDashboardTilesCountModel">
  <app-reviewer-startpage-gridarea
    [currentDashboardTileCode]="currentDashboardTileCode"
    [currentTileNameForGrid]="selectedTileCode"
    [gridColumns]="gridColumns"
    [model]="model"
    [gridTitle]="gridTitle"
    [dashboardfilters]="reviewerDashboardfilters"
    [buttonOptionsList]="buttonOptionsList"
    (buttonSelectedEvent)="buttonSelected($event)"
    (gridUpdatedEvent)="gridUpdatedEvent($event)"
  >
  </app-reviewer-startpage-gridarea>
  <app-new-pagination-and-grid
    [tileCountforPage]="this.countforPagination"
    [pageUI]="this.gotoPage + 1"
    (pageChanged)="onPageChanged($event)"
    (valueChanged)="onValueChanged($event)"
  ></app-new-pagination-and-grid>
  <app-recently-viewed></app-recently-viewed>
</div>
<app-footer></app-footer>
