<div class="nca-container padding-top-xs text-align-left">
  <div class="row header-global-engagement padding-top-s margin-left-l">
    <span class="d-flex flex-row margin-left-l"
      ><span
        class="dds-icon cursor-clickable margin-right-s font-size-18 dds-icon_home__l__stroke"
        (click)="backToHome()"
      ></span>
      <span class="padding-right-s"> | </span>
      <span class="font-size-16 font-bold-600 padding-bottom-s">{{
        engagementTitle
      }}</span></span
    >
  </div>
</div>

<div class="width-93 detail-nav" style="display: flex">
  <ng-container>
    <div class="row padding-top-s width26pt">
      <div class="col-3 width-left-menu-panel">
        <div class="sidebar position-top-0">
          <div
            class="nav-item"
            [class.active]="activeIndex === -1"
            (click)="setActive(-1)"
          >
            <span
              class="status-indicator"
              [ngClass]="
                activeIndex === -1
                  ? 'status-active-indicator'
                  : 'status-inactive-indicator'
              "
            ></span>
            <div class="opportunity-detail-heading">
              <p class="margin-bottom-es margin-top-sm">Engagement details</p>
              <span class="margin-bottom-es margin-top-sm"
                ><img class="right-a" src="\assets\images\draftIcon.svg"
              /></span>
            </div>
          </div>

          <p class="doc-sub">Document submission(s):</p>
          <div class="nav-items" id="left-nav-items">
            <div
              *ngFor="let item of leftNavDetails; let i = index"
              class="nav-item"
              [id]="'navItem-' + item?.submissionId"
              [class.active]="i === activeIndex"
              (click)="setActive(i)"
            >
              <span
                class="status-indicator"
                [ngClass]="
                  i === activeIndex
                    ? 'status-active-indicator'
                    : 'status-inactive-indicator'
                "
              ></span>
              <div class="nav-item-content" id="item.submissionId">
                <div class="icon-info-display">
                  <img src="\assets\images\file-icon.svg" alt="file img" />
                  <div>
                    <h4
                      class="text-align-left"
                      [ddsTooltip]="item.submissionTitle"
                      ddsFocus
                    >
                      {{ item.submissionTitle }}
                    </h4>
                    <div class="sub-info">
                      <span class="item-type">{{ item.submissionType }}</span>
                      <span class="padding-xxs">{{
                        item.submissionsStatus
                      }}</span>
                    </div>
                  </div>
                </div>
                <span class="draft-status" *ngIf="true">
                  <img src="\assets\images\draftIcon.svg"
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabsection"></div>
  </ng-container>
  <div class="padding-left-m padding-top-s">
    <div *ngIf="activeIndex === -1" class="row">
      <app-global-eng-details
        [engagementId]="engagementId"
        [permissionObj]="permissionObj"
      ></app-global-eng-details>
    </div>
    <div *ngIf="activeIndex > -1" class="row">
      <app-engagement-submission
        [engagementId] ="engagementId"
        [submissionId]="activeSubmissionId"
        [submissionType]="activeSubmissionType"
        [submissionStatus]="activeSubmissionStatus"
        [permissionObj]="permissionObj"
        (loadLeftMenu)="getLeftNavDetails($event)"
        [engagementId]="engagementId"
      ></app-engagement-submission>
    </div>
  </div>
</div>
