import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DELIVERABLE_RADIO_GROUP_VALUE, RADIO_GROUP_OPTIONS_FOR_DELIVERABLE_SCOPE } from './close-out-deliverable-helper';
@Component({
  selector: 'app-close-out-deliverable',
  templateUrl: './close-out-deliverable.component.html',
  styleUrl: './close-out-deliverable.component.less',
})
export class CloseOutDeliverableComponent {
  @Input() deliverable = '';
  @Output() deliverableChange = new EventEmitter<string>();
  deliverable_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_DELIVERABLE_SCOPE;

  deliverable_radio_values: any = DELIVERABLE_RADIO_GROUP_VALUE;

  deliverableStateChanged(event: string): void {
    this.deliverableChange.emit(event);
  }
}
