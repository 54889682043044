<div class="row">
  <ng-container *ngIf="permissionObj['isIntakeCloseoutFormVisible']">
    <div>
      <span class="icon-required-yellow"></span>
      <span>{{ other_radioGroupOptions.title }}</span>
    </div>

    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [(ngModel)]="other"
            [name]="other"
            [isUncheckable]="other_radioGroupOptions.isUncheckable"
            [isRequired]="other_radioGroupOptions.isRequired"
            [errorState]="other_radioGroupOptions.errorState"
            [disabled]="!permissionObj['isIntakeCloseoutFormEnable']"
            (stateChanged)="otherStateChanged($event)"
            ngDefaultControl
          >
            <ng-container *ngFor="let radio of other_radio_values">
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
                [disabled]="!permissionObj['isIntakeCloseoutFormEnable']"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="other === wbs_chargecode_state">
    <app-wbs-charge-code
      [wbsChargeCode]="wbsChargeCode"
      [wbsDescription]="wbsDescription"
      [dataSubmissionId]="dataSubmissionId"
      [dataOpportunityId]="dataOpportunityId"
      [archiveDetails]="archiveDetails"
      [swiftDetails]="swiftDetails"
      [permissionObj]="permissionObj"
      [wbsPendingCheckbox]="submissionData?.isWBSPending ?? false"
      (wbsOrArchiveChange)="onWbsOrArchiveChange($event)"
    />
  </div>
</div>
