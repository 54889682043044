export enum MatrixKeys {
  Intake_Nav_Submission_Reset = 'Intake.Submission.Reset',
  Intake_Nav_Submission_Admin = 'Intake.Submission.Admin',
  Intake_Nav_Submission_Export = 'Intake.Submission.Export',
  Intake_Nav_Submission_Discontinue = 'Intake.Submission.Discontinue',
  Intake_Header_MyDashboard = 'Intake.Header.MyDashboard',
  Intake_Header_OpportunityTitle = 'Intake.Header.OpportunityTitle',
  Intake_Header_GoToJupiter = 'Intake.Header.GoToJupiter',
  Intake_Header_GoToFocus = 'Intake.Header.GoToFocus',
  Intake_LeftHandNavigation = 'Intake.LeftHandNavigation',
  Intake_Submission_Title = 'Intake.Submission.Title',
  Intake_Submission_SubmissionType = 'Intake.Submission.SubmissionType',
  Intake_Submission_SubmissionStatus = 'Intake.Submission.SubmissionStatus',
  Intake_Submission_SubmissionId = 'Intake.Submission.SubmissionId',
  Intake_Submission_ELSOWOpportunityID = 'Intake.Submission.EL/SOWOpportunityID',
  Intake_Submission_ReSubmitBtn = 'Intake.Submission.ReSubmit',
  Intake_Submission_ArchiveAndCompleteBtn = 'Intake.Submission.ArchiveAndComplete',
  Intake_Submission_SubmitBtn = 'Intake.Submission.Submit',
  Intake_Submission_ReturnForReworkBtn = 'Intake.Submission.ReturnForRework',
  Intake_Submission_EligibleForSelfReview = 'Intake.Submission.EligibleForSelfReview',
  Intake_Submission_SubmissionTitle = 'Intake.Submission.SubmissionTitle',
  Intake_Submission_Deliverable = 'Intake.Submission.Deliverable',
  Intake_Submission_ChangeOrder = 'Intake.Submission.ChangeOrder',
  Intake_Submission_Other = 'Intake.Submission.Other',
  Intake_Submission_Proposal = 'Intake.Submission.Proposal',
  Intake_Submission_ELSOW = 'Intake.Submission.ELSOW',
  Intake_Submission_LEPConfirmation = 'Intake.Submission.LEPConfirmation',
  Intake_Submission_DocumentTable = 'Intake.Submission.DocumentTable',
  Intake_Submission_DocumentDownload = 'Intake.Submission.DocumentDownload',
  Intake_Submission_DocumentEdit = 'Intake.Submission.DocumentEdit',
  Intake_Submission_DocumentDelete = 'Intake.Submission.DocumentDelete',
  Intake_Submission_DocumentDragAndDrop = 'Intake.Submission.DocumentDragAndDrop',
  Intake_Submission_AttachDocument = 'Intake.Submission.AttachDocument',
  Intake_Submission_AddDocumentLink = 'Intake.Submission.AddDocumentLink',
  Intake_Submission_NotesAndFeedback = 'Intake.Submission.NotesAndFeedback',
  Intake_Submission_WorkflowCheckbox = 'Intake.Submission.WorkflowCheckbox',
  Intake_Submission_WorkflowRoleAndName = 'Intake.Submission.WorkflowRoleAndName',
  Intake_Submission_WorkflowAction = 'Intake.Submission.WorkflowAction',
  Intake_Submission_WorkflowAddReviewer = 'Intake.Submission.WorkflowAddReviewer',
  Intake_Submission_InternalQRM = 'Intake.Submission.InternalQRM',
  Intake_Submission_WBS = 'Intake.Submission.WBS',
  Intake_Submission_WBSDescription = 'Intake.Submission.WBSDescription',
  Intake_ServicesAndDelivery = 'Intake.ServicesAndDelivery',
  Intake_Complexity_DescriptionGreyGuidance = 'Intake.Complexity.DescriptionGreyGuidance',
  Intake_Opportunity_Title = 'Intake.Opportunity.Title',
  Intake_Opportunity_Description = 'Intake.Opportunity.Description',
  Intake_Opportunity_FieldsOutOfSync = 'Intake.Opportunity.FieldsOutOfSync',
  Intake_Opportunity_LinkedOpportunities = 'Intake.Opportunity.LinkedOpportunities',
  Intake_Opportunity_Complexity = 'Intake.Opportunity.Complexity',
  Intake_Opportunity_OfferingServiceQuestions = 'Intake.Opportunity.Offering/ServiceQuestions',
  Intake_Opportunity_ChangeArchive = 'Intake.Opportunity.ChangeArchive',
  Intake_OpportunityDetails_OpportunityNumber = 'Intake.OpportunityDetails.OpportunityNumber',
  Intake_OpportunityDetails_LEP = 'Intake.OpportunityDetails.LEP',
  Intake_OpportunityDetails_StartDate = 'Intake.OpportunityDetails.StartDate',
  Intake_OpportunityDetails_EndDate = 'Intake.OpportunityDetails.EndDate',
  Intake_OpportunityDetails_TotalFees = 'Intake.OpportunityDetails.TotalFees',
  Intake_OpportunityDetails_FeeType = 'Intake.OpportunityDetails.FeeType',
  Intake_OpportunityDetails_OpportunityDescription = 'Intake.OpportunityDetails.OpportunityDescription',
  
  EXT_COM_NavigationBarAuditTrail = 'ExternalComm.NavigationBar.AuditTrail',
  EXT_COM_Discontinue = 'ExternalComm.Discontinue',
  EXT_COM_Reset = 'ExternalComm.Reset',
  EXT_COM_BlackHeader = 'ExternalComm.BlackHeader',
  EXT_COM_CommunicationTypeToggle = 'ExternalComm.CommunicationType.Toggle',
  EXT_COM_SubmissionListAllFields = 'ExternalComm.SubmissionList.AllFields',
  EXT_COM_TitleAreaSubmissionTitle = 'ExternalComm.TitleArea.SubmissionTitle',
  EXT_COM_TitleAreaEXTCOMM = 'ExternalComm.TitleArea.EXTCOMM',
  EXT_COM_TitleAreaSubmissionStatus = 'ExternalComm.TitleArea.SubmissionStatus',
  EXT_COM_TitleAreaSubmissionId = 'ExternalComm.TitleArea.SubmissionId',
  EXT_COM_TitleAreaRushRequestBadge = 'ExternalComm.TitleArea.RushRequestBadge',
  EXT_COM_SubmitResubmit = 'ExternalComm.Submit/Resubmit',
  EXT_COM_CompleteReview = 'ExternalComm.CompleteReview',
  EXT_COM_Confirm = 'ExternalComm.Confirm',
  EXT_COM_ReturnForRework = 'ExternalComm.ReturnForRework',
  EXT_COM_SelfReview = 'ExternalComm.SelfReview',
  EXT_COM_OptOutOfSelfReview = 'ExternalComm.OptOutOfSelfReview',
  EXT_COM_ForceSelfReview = 'ExternalComm.ForceSelfReview',
  EXT_COM_SubmissionDetailsSubmissionDetailsTitle = 'ExternalComm.SubmissionDetails.SubmissionDetailsTitle',
  EXT_COM_SubmissionDetailsSelfReviewEligibilty = 'ExternalComm.SubmissionDetails.SelfReviewEligibilty',
  EXT_COM_SubmissionDetailsCommunicationType = 'ExternalComm.SubmissionDetails.CommunicationType',
  EXT_COM_SubmissionDetailsPPMDTrainingCompleted = 'ExternalComm.SubmissionDetails.PPMDTrainingCompleted',
  EXT_COM_SubmissionDetailsUpdatedSelfReviewGuidance = 'ExternalComm.SubmissionDetails.UpdatedSelfReviewGuidance',
  EXT_COM_SubmissionDetailsSubmissionTitle = 'ExternalComm.SubmissionDetails.SubmissionTitle',
  EXT_COM_SubmissionDetailsRequestedReviewDate = 'ExternalComm.SubmissionDetails.RequestedReviewDate',
  EXT_COM_SubmissionDetailsAudiencePublications = 'ExternalComm.SubmissionDetails.Audience/Publications',
  EXT_COM_SubmissionDetailsRelatedToGovernmentandPublicServices = 'ExternalComm.SubmissionDetails.RelatedToGovernmentandPublicServices',
  EXT_COM_SubmissionDetailsIndustrySector = 'ExternalComm.SubmissionDetails.IndustrySector',
  EXT_COM_SubmissionDetailsDeloitteCorporateFinance = 'ExternalComm.SubmissionDetails.DeloitteCorporateFinance',
  EXT_COM_SubmissionDetailsOffering = 'ExternalComm.SubmissionDetails.Offering',
  EXT_COM_SubmissionDetailsSolution = 'ExternalComm.SubmissionDetails.Solution',
  EXT_COM_SubmissionDetailsMarketOffering = 'ExternalComm.SubmissionDetails.MarketOffering',
  EXT_COM_SubmissionDetailsPrimaryContact = 'ExternalComm.SubmissionDetails.PrimaryContact',
  EXT_COM_SubmissionDetailsSecondaryContact = 'ExternalComm.SubmissionDetails.SecondaryContact',
  EXT_COM_SubmissionDetailsMarketingTalentPRContact = 'ExternalComm.SubmissionDetails.MarketingTalentPRContact',
  EXT_COM_RiskAssessment = 'ExternalComm.RiskAssessment',
  EXT_COM_CommunicationAttributes = 'ExternalComm.CommunicationAttributes',
  EXT_COM_DocumentsGuidanceEligibleForSelfReview = 'ExternalComm.Documents.GuidanceEligibleForSelfReview',
  EXT_COM_DocumentsGuidanceUploadDocumentForReview = 'ExternalComm.Documents.GuidanceUploadDocumentForReview',
  EXT_COM_DocumentsDragAndDrop = 'ExternalComm.Documents.DragAndDrop',
  EXT_COM_DocumentsAttachDocuments = 'ExternalComm.Documents.AttachDocuments',
  EXT_COM_DocumentsAddLink = 'ExternalComm.Documents.AddLink',
  EXT_COM_NotesAndFeedback = 'ExternalComm.NotesAndFeedback',
  EXT_COM_WorkflowRequiredCheckbox = 'ExternalComm.Workflow.RequiredCheckbox',
  EXT_COM_WorkflowRiskManager = 'ExternalComm.Workflow.RiskManager',
  EXT_COM_WorkflowSupportingRiskManager = 'ExternalComm.Workflow.SupportingRiskManager',
  EXT_COM_WorkflowRMSupport = 'ExternalComm.Workflow.RMSupport',
  EXT_COM_WorkflowReviewer = 'ExternalComm.Workflow.Reviewer',
  EXT_COM_WorkflowAddReviewer = 'ExternalComm.Workflow.AddReviewer',
  EXT_COM_ThirdPartyAgreementRepository = 'Application.ThirdPartyAgreementRepository',
}

export enum PermissionTypes {
  Visible = 'Visible',
  Enable = 'Enable',
}

// ********************************************* need to remove after testing the remaining permissions **************************************************

// TODO: Need to remove after testing the remaining permissions
export const intakePermissions: string[] = [
  'Intake.Submission.EligibleForSelfReview.Visible',
  'Intake.Submission.SubmissionTitle.Visible',
  'Intake.Submission.SubmissionTitle.Enable',
  'Intake.Submission.Deliverable.Visible',
  'Intake.Submission.Deliverable.Enable',
  'Intake.Submission.ChangeOrder.Visible',
  'Intake.Submission.ChangeOrder.Enable',
  'Intake.Submission.Other.Visible',
  'Intake.Submission.Other.Enable',
  'Intake.Submission.Proposal.Visible',
  'Intake.Submission.Proposal.Enable',
  'Intake.Submission.ELSOW.Visible',
  'Intake.Submission.ELSOW.Enable',
  'Intake.Submission.LEPConfirmation.Visible',
  'Intake.Submission.LEPConfirmation.Enable',
  'Intake.Submission.DocumentTable.Enable',
  'Intake.Submission.DocumentDownload.Enable',
  'Intake.Submission.DocumentEdit.Enable',
  'Intake.Submission.DocumentDelete.Visible',
  'Intake.Submission.DocumentDragAndDrop.Enable',
  'Intake.Submission.AttachDocument.Visible',
  'Intake.Submission.AddDocumentLink.Enable',
  'Intake.Submission.NotesAndFeedback.Enable',
  'Intake.Submission.WorkflowCheckbox.Enable',
  'Intake.Submission.WorkflowCheckbox.Visible',
  'Intake.Submission.WorkflowRoleAndName.Enable',
  'Intake.Submission.WorkflowRoleAndName.Visible',
  'Intake.Submission.WorkflowAction.Visible',
  'Intake.Submission.WorkflowAction.Enable',
  'Intake.Submission.WorkflowAddReviewer.Visible',
  'Intake.Submission.WorkflowAddReviewer.Enable',
  'Intake.Submission.InternalQRM.Enable',
  'Intake.Submission.InternalQRM.Visible',
  'Intake.Submission.WBS.Visible',
  'Intake.Submission.WBS.Enable',
  'Intake.Submission.WBSDescription.Visible',
  'Intake.Opportunity.ChangeArchive.Visible',
  'Intake.Opportunity.ChangeArchive.Enable',
  'Intake.Submission.Reset.Enable',
  'Intake.Submission.Admin.Enable',
  'Intake.Submission.Export.Enable',
  'Intake.Header.MyDashboard.Visible',
  'Intake.Header.OpportunityTitle.Visible',
  'Intake.Header.GoToJupiter.Visible',
  'Intake.Header.GoToFocus.Visible',
  'Intake.LeftHandNavigation.Visible',
  'Intake.Opportunity.Title.Visible',
  'Intake.ServicesAndDelivery.Visible',
  'Intake.Opportunity.Description.Visible',
  'Intake.Opportunity.FieldsOutOfSync.Visible',
  'Intake.Opportunity.FieldsOutOfSync.Enable',
  'Intake.OpportunityDetails.OpportunityNumber.Visible',
  'Intake.OpportunityDetails.LEP.Enable',
  'Intake.OpportunityDetails.LEP.Visible',
  'Intake.OpportunityDetails.StartDate.Visible',
  'Intake.OpportunityDetails.StartDate.Enable',
  'Intake.OpportunityDetails.EndDate.Visible',
  'Intake.OpportunityDetails.EndDate.Enable',
  'Intake.OpportunityDetails.TotalFees.Visible',
  'Intake.OpportunityDetails.TotalFees.Enable',
  'Intake.OpportunityDetails.FeeType.Visible',
  'Intake.OpportunityDetails.FeeType.Enable',
  'Intake.OpportunityDetails.OpportunityDescription.Visible',
  'Intake.OpportunityDetails.OpportunityDescription.Enable',
  'Intake.Opportunity.LinkedOpportunities.Visible',
  'Intake.Complexity.DescriptionGreyGuidance.Visible',
  'Intake.Opportunity.Complexity.Visible',
  'Intake.Opportunity.Complexity.Enable',
  'Intake.Opportunity.Offering/ServiceQuestions.Enable',
  'Intake.Opportunity.Offering/ServiceQuestions.Visible',
  'Intake.Submission.Title.Visible',
  'Intake.Submission.SubmissionType.Visible',
  'Intake.Submission.SubmissionStatus.Visible',
  'Intake.Submission.SubmissionId.Visible',
  'Intake.Submission.Submit.Visible',
  'Intake.Submission.ReSubmit.Visible',
  'Intake.Submission.ArchiveAndComplete.Visible',
  'Intake.Submission.EL/SOWOpportunityID.Visible',
  'Intake.Submission.Submit.Enable',
  'Intake.Submission.ReSubmit.Enable',
  'Intake.Submission.ReturnForRework.Enable',
  'Intake.Submission.ArchiveAndComplete.Enable',
  'Intake.Submission.EL/SOWOpportunityID.Enable',
  'Intake.Submission.Discontinue.Enable',
];

export const externalCommPermissions: string[] = [ 
    "ExternalComm.NavigationBar.AuditTrail.Visible",
    "ExternalComm.Discontinue.Enable",
    "ExternalComm.Reset.Enable",
    "ExternalComm.BlackHeader.Visible",
    "ExternalComm.CommunicationType.Toggle.Visible",
    "ExternalComm.SubmissionList.AllFields.Visible",
    "ExternalComm.TitleArea.SubmissionTitle.Visible",
    "ExternalComm.TitleArea.EXTCOMM.Visible",
    "ExternalComm.TitleArea.SubmissionStatus.Visible",
    "ExternalComm.TitleArea.SubmissionId.Visible",
    "ExternalComm.TitleArea.RushRequestBadge.Visible",
    "ExternalComm.Submit/Resubmit.Enable",
    "ExternalComm.CompleteReview.Visible",
    "ExternalComm.CompleteReview.Enable",
    "ExternalComm.Confirm.Enable",
    "ExternalComm.ReturnForRework.Enable",
    "ExternalComm.ReturnForRework.Visible",
    "ExternalComm.SelfReview.Enable",
    "ExternalComm.SelfReview.Visible",
    "ExternalComm.OptOutOfSelfReview.Enable",
    "ExternalComm.OptOutOfSelfReview.Visible",
    "ExternalComm.ForceSelfReview.Enable",
    "ExternalComm.SubmissionDetails.SubmissionDetailsTitle.Visible",
    "ExternalComm.SubmissionDetails.SelfReviewEligibilty.Visible",
    "ExternalComm.SubmissionDetails.CommunicationType.Visible",
    "ExternalComm.SubmissionDetails.CommunicationType.Enable",
    "ExternalComm.SubmissionDetails.PPMDTrainingCompleted.Visible",
    "ExternalComm.SubmissionDetails.UpdatedSelfReviewGuidance.Visible",
    "ExternalComm.SubmissionDetails.SubmissionTitle.Visible",
    "ExternalComm.SubmissionDetails.SubmissionTitle.Enable",
    "ExternalComm.SubmissionDetails.RequestedReviewDate.Enable",
    "ExternalComm.SubmissionDetails.RequestedReviewDate.Visible",
    "ExternalComm.SubmissionDetails.Audience/Publications.Visible",
    "ExternalComm.SubmissionDetails.Audience/Publications.Enable",
    "ExternalComm.SubmissionDetails.RelatedToGovernmentandPublicServices.Visible",
    "ExternalComm.SubmissionDetails.RelatedToGovernmentandPublicServices.Enable",
    "ExternalComm.SubmissionDetails.IndustrySector.Enable",
    "ExternalComm.SubmissionDetails.IndustrySector.Visible",
    "ExternalComm.SubmissionDetails.DeloitteCorporateFinance.Visible",
    "ExternalComm.SubmissionDetails.DeloitteCorporateFinance.Enable",
    "ExternalComm.SubmissionDetails.Offering.Visible",
    "ExternalComm.SubmissionDetails.Offering.Enable",
    "ExternalComm.SubmissionDetails.Solution.Enable",
    "ExternalComm.SubmissionDetails.Solution.Visible",
    "ExternalComm.SubmissionDetails.MarketOffering.Visible",
    "ExternalComm.SubmissionDetails.MarketOffering.Enable",
    "ExternalComm.SubmissionDetails.PrimaryContact.Enable",
    "ExternalComm.SubmissionDetails.PrimaryContact.Visible",
    "ExternalComm.SubmissionDetails.SecondaryContact.Visible",
    "ExternalComm.SubmissionDetails.SecondaryContact.Enable",
    "ExternalComm.SubmissionDetails.MarketingTalentPRContact.Enable",
    "ExternalComm.SubmissionDetails.MarketingTalentPRContact.Visible",
    "ExternalComm.RiskAssessment.Visible",
    "ExternalComm.RiskAssessment.Enable",
    "ExternalComm.CommunicationAttributes.Visible",
    "ExternalComm.CommunicationAttributes.Enable",
    "ExternalComm.Documents.GuidanceEligibleForSelfReview.Visible",
    "ExternalComm.Documents.GuidanceUploadDocumentForReview.Visible",
    "ExternalComm.Documents.DragAndDrop.Visible",
    "ExternalComm.Documents.DragAndDrop.Enable",
    "ExternalComm.Documents.AttachDocuments.Visible",
    "ExternalComm.Documents.AttachDocuments.Enable",
    "ExternalComm.Documents.AddLink.Visible",
    "ExternalComm.Documents.AddLink.Enable",
    "ExternalComm.NotesAndFeedback.Enable",
    "ExternalComm.NotesAndFeedback.Visible",
    "ExternalComm.Workflow.RequiredCheckbox.Enable",
    "ExternalComm.Workflow.RequiredCheckbox.Visible",
    "ExternalComm.Workflow.RiskManager.Enable",
    "ExternalComm.Workflow.RiskManager.Visible",
    "ExternalComm.Workflow.SupportingRiskManager.Enable",
    "ExternalComm.Workflow.SupportingRiskManager.Visible",
    "ExternalComm.Workflow.RMSupport.Enable",
    "ExternalComm.Workflow.RMSupport.Visible",
    "ExternalComm.Workflow.Reviewer.Enable",
    "ExternalComm.Workflow.Reviewer.Visible",
    "ExternalComm.Workflow.AddReviewer.Enable",
    "ExternalComm.Workflow.AddReviewer.Visible",
    "Application.ThirdPartyAgreementRepository.Visible"
 ]

//  ********************************************* need to remove after testing the remaining permissions end **************************************************
