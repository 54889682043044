import { Component } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';

@Component({
  selector: 'app-self-review-pop-up',
  templateUrl: './self-review-pop-up.component.html',
  styleUrl: './self-review-pop-up.component.less',
})
export class SelfReviewPopUpComponent {
  submissionLevelUpdateSubmission: boolean = false;
  constructor(public readonly modalService: ModalService) {}

  selfReviewSubmitHandler(): void {
    this.submissionLevelUpdateSubmission = true;
    this.closeSelfReviewPopup();
  }

  closeSelfReviewPopup(): void {
    this.modalService.close(this.submissionLevelUpdateSubmission);
  }
}
