  <!-- TODO : Uncomment once API is ready to show progress indicator -->
<progress-indicator></progress-indicator>
<div class="overall-content">
  <div class="header-section" [style]="{'height': largeHeaderHeight}">
    <div class="header-content" [style]="{'width': layoutWidth}">
      <app-eclipse-header></app-eclipse-header>
    </div>
  </div>
  <main class="main-content eclipse-content" [style]="{'background': layoutColor}">

    <div class="content" *ngIf="isContentDivVisible" [style]="{'width': layoutWidth}">
      <div class="router-outlet-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>

    <div class="content-watchlist" *ngIf="!isContentDivVisible" [style]="{'width': layoutWidth}">
      <div class="router-outlet-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>

  </main>
</div>
