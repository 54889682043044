<div class="dds-modal-overlay" (click)="close()"></div>

<div ddsResize class="dds-modal dds-modal_lg">
  <div class="dds-modal__header">
    <span class="dds-modal__title">{{labelValues.labelHeader}}</span>
    <button class="dds-modal__close dds-icon dds-icon_close" (click)="cancel()" aria-label="Close modal"></button>
  </div>

  <div class="dds-modal__body">
    <div class="top-header margin-bottom-l">
      <div class="icon-alert-logo-container">
        <div class="icon-green-pin"></div>
      </div>
      <div class="text-container">
        <div class="eclipse-content-grey">
          <span>{{labelValues.labeldisclaimer}}</span>
        </div>
      </div>
    </div>

    <div class="dds-inline-flex justify-space-between">
      <div class="dds-modal__title">Select clients to erase their records</div>
      <div class="select-all" (click)="selectAll()">Select all</div>
    </div>

    <div role="tree" class="dds-tree dds-tree_with-checkbox dds-tree_lg">
      <div *ngFor="let item of treeItems" role="treeitem" [attr.aria-expanded]="item.isExpanded" class="dds-tree__item" [ngClass]="{'dds-tree__item_expanded': item.isExpanded}">
        <label class="dds-custom-control dds-tree__checkbox">
          <dds-checkbox [value]="item['isChecked'] ?? true"
                        theme="green"
                        (change)="toggleSelection(item)"
                        class="checkbox-custom">
          </dds-checkbox>

        </label>
        <button style="padding-left: 32px;" class="dds-tree__content dds-tree__content_has-children">
          <div class="dds-tree__title">
            <span class="dds-tree__text padding-right-xxs">{{item.label}}</span>
            <span class="dds-tree__text sap-id">(ID: {{item['labelid']}})</span>
          </div>
        </button>
        <div class="dds-tree__children" role="group" *ngIf="item.children" [ngStyle]="{'height': getTreeChildrenHeight(item.children.length)}">
          <div *ngFor="let child of item.children" role="treeitem" [attr.aria-expanded]="child.isExpanded" class="dds-tree__item" [ngClass]="{'dds-tree__item_expanded': child.isExpanded}">
            <label style="left: 20px;" class="dds-custom-control dds-tree__checkbox">
              <dds-checkbox [value]="child['isChecked'] ?? true"
                            theme="green"
                            (change)="toggleChildSelection(item, child)"
                            class="checkbox-custom">
              </dds-checkbox>
            </label>
            <button style="padding-left: 44px;" class="dds-tree__content dds-tree__content_has-children">
              <div class="dds-tree__title">
                <span class="dds-tree__text padding-right-xxs">{{child.label}}</span>
                <span class="dds-tree__text sap-id">(ID: {{child['labelid']}})</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_right"></div>
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <div class="dds-modal__footer-item dds-inline-flex">
        <dds-button [theme]="eraseoptions.theme ?? ''"
                    [disabled]="selectedWatchlistIds.length === 0"
                    (click)="eraseRecords()"
                    [kind]="eraseoptions.kind ?? ''"
                    [size]="eraseoptions.size ?? ''"
                    [width]="eraseoptions.width ?? ''">Erase selected record(s)</dds-button>

      </div>
      <div class="dds-modal__footer-item dds-inline-flex">
        <dds-button [theme]="options.theme ?? ''"
                    [kind]="canceloptions.kind ?? ''"
                    [size]="canceloptions.size ?? ''"
                    (click)="cancel()"
                    [width]="canceloptions.width ?? ''">Cancel</dds-button>
      </div>
    </div>
  </div>
</div>
