import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IDisplaySaveInfo } from './display-save-info';
import { AutosaverService } from '../autosaver.service';
import { environment } from '../../../../environment/environment';

@Component({
    selector: 'display-save',
    templateUrl: './display-save.component.html',
    styleUrls: ['./display-save.component.less']
})

export class DisplaySaveComponent implements AfterViewInit, OnDestroy {
    @Input() module: IDisplaySaveInfo | null = null;
    public url: string;
    public isSaving: boolean = false;
    private saverId: string = "";
    private subscription: Subscription = new Subscription();
    rootUrl: string = environment.rootUrl;
    constructor(
        private autosaverService: AutosaverService,
        private renderer: Renderer2,
        private element: ElementRef) {
          
      this.url = this.rootUrl+'assets/images/auto-save.gif';
    }

    async ngAfterViewInit() {

        const fun = () => {
            let element = this.element.nativeElement;
            while (element && !this.module) {
                if (element.attributes && element.attributes["data-autosaver-module-id"]) {
                    this.saverId = element.attributes["data-autosaver-module-id"].value;
                    this.module = this.autosaverService.autosaveModules[this.saverId] as IDisplaySaveInfo;
                    break;
                }
                element = this.renderer.parentNode(element);
            }
            if (this.module) {
                this.subscription = this.module.isSaving
                    .valueChanged
                    .pipe(debounceTime(100))
                    .subscribe((value: boolean) => this.isSaving = value);
            } else {
                setTimeout(() => fun(), 100);
                return;
            }
        }

        fun();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        // for AutoTests. Remove.
        let wnd = window as any;
        if (wnd.DAP && wnd.DAP.components && wnd.DAP.components.displaySave) {
            wnd.DAP.components.displaySave.module.timestamp = null;
        }
    }
}
