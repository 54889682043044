import { Component, OnInit } from '@angular/core';
import {
  primaryButton,
  secondaryButton,
} from '../external-communications.helper';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { ExternalCommunicationService } from '../../http/external-communication/external-communication.service';
import {
  GenericResponse,
  InitiateExternalCommunicationDropdownModel,
  InitiateExternalCommunicationResponseModel,
} from '../../common/models/external-communication.model';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ExternalCommunicationUpdateReviewerListRequestBody,
  Reviewers,
} from '../../common/models/external-communication-submission-details-common-section.model';
import { RoleEnum, RoleIdMapper } from '../../intake/constants/Role.enum';

@Component({
  selector: 'app-initiate-external-communication',
  templateUrl: './initiate-external-communication.component.html',
  styleUrl: './initiate-external-communication.component.less',
})
export class InitiateExternalCommunicationComponent implements OnInit {
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  primaryButton = primaryButton;
  secondaryButton = secondaryButton;
  communicationType: string = IntakeConstant.EMPTY_SPACE;
  communicationTypeId: number = 0;
  communicationTypeList: SelectItemOptions[] = [];
  communicationTitle: string = IntakeConstant.EMPTY_SPACE;
  ppmdId: number | null = null;
  searchedName: string = '';

  constructor(
    private readonly externalCommService: ExternalCommunicationService,
    private readonly router: Router,
    private readonly commonHelperService: CommonHelperService,
    private readonly progressIndicator: ProgressIndicatorService
  ) {}

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_ExternalCommunications;
    this.getCommunicationType();
  }

  getCommunicationType(): void {
    this.progressIndicator.show();
    this.externalCommService
      .getSubmissionTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: InitiateExternalCommunicationDropdownModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: InitiateExternalCommunicationDropdownModel) => ({
                value: item.communicationTypeId,
                heading: item.communicationTypeName,
              })
            );
            this.communicationTypeList = convertedArray;
            this.progressIndicator.hide();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  onSubmissionTypeChange($event: number): void {
    this.communicationTypeId = $event;
    this.externalCommService.setCommunicationType(this.communicationTypeId);
  }

  onCommunicationTitleInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value.length > 100) {
      inputElement.value = inputElement.value.substring(0, 100);
    }
    this.communicationTitle = this.commonHelperService.trimPrefixEmptySpace(
      inputElement.value
    );
  }

  onSubmit(): void {
    this.progressIndicator.show();
    const requestBody = {
      SubmissionTitle: this.commonHelperService.trimPrefixEmptySpace(
        this.communicationTitle
      ),
      CommunicationTypeId: this.communicationTypeId,
      PPMDId: String(this.ppmdId),
    };

    this.externalCommService
      .postInitiateExternalCommunication(requestBody)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (res: InitiateExternalCommunicationResponseModel) => {
          if (res) {
            this.progressIndicator.hide();
            this.getReviewer(res?.submissionId);
          }
        },
        error: (err: Error) => {
          console.error('Error initiating external communication', err);
          this.progressIndicator.hide();
        },
      });
  }

  getReviewer(submissionId: number): void {
    this.progressIndicator.show();
    this.externalCommService
      .getExtCommReviewersData(submissionId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: Reviewers[]) => {
          this.progressIndicator.hide();

          if (!response) {
            response = [];
          }
          /* Initially Responsible Pracitioner is coming from focus,
           * to update the data in backend need to make updateReviewer call
           */
          const rpIndex: number =
            response?.findIndex(
              (item: Reviewers) =>
                item?.submissionApproverId === 0 &&
                item?.roleName === RoleEnum.RESPONSIBLE_PRACTITIONER
            ) ?? -1;

          if (rpIndex !== -1) {
            const reviewer = response[rpIndex];
            this.updateRPInDB(submissionId, reviewer);
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred while fetching reviewers: ', err);
          this.progressIndicator.hide();
        },
      });
  }

  updateRPInDB(submissionId: number, reviewer: Reviewers): void {
    const requestBody: ExternalCommunicationUpdateReviewerListRequestBody = {
      submissionid: submissionId,
      submissionapprovers: [
        {
          submissionApproverId: reviewer.submissionApproverId,
          reviewerId: reviewer.reviewerId,
          displayName: reviewer.displayName,
          fullName: reviewer.fullName,
          emailId: reviewer.emailId,
          location: reviewer.location,
          reviewerRoleId: reviewer.reviewerRoleId,
          isRequired: reviewer.isRequired,
          roleName: reviewer.roleName,
          submissionstatusid: reviewer.submissionStatusId,
          isactive: reviewer.isActive,
        },
      ],
    };

    this.progressIndicator.show();
    this.externalCommService
      .postReviewersData(requestBody)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (res: GenericResponse) => {
          if (res.isSuccess) {
            this.progressIndicator.hide();

            this.router.navigate(
              ['/externalCommunications/details', this.communicationTypeId],
              {
                queryParams: { submissionId: submissionId },
                state: { submissionId: submissionId },
              }
            );
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error(
            'An error occurred while updating reviewer data: ',
            err
          );
          this.progressIndicator.hide();
        },
      });
  }

  onCancel(): void {
    this.router.navigate(['/']);
  }

  onSelected($event: any): void {
    this.ppmdId = $event.reviewerId;
    this.searchedName = $event.displayName;
  }

  removeSearchedPerson(): void {
    this.searchedName = '';
    this.ppmdId = null;
  }

  isConfirmationDisabled(): boolean {
    return !this.communicationType || !this.communicationTitle || !this.ppmdId;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
