<div class="margin-bottom-xs">
  <ng-container *ngFor="let boxItem of boxMessages; index as index">
    <div [ngClass]="boxItem?.class">
      <span
        *ngIf="boxItem?.icon"
        [ngClass]="boxItem?.icon"
        class="eclipse-icon icon-s"
      ></span>
      <span>{{ boxItem?.text }}</span>
    </div>
  </ng-container>
</div>

<div class="row" *ngIf="isGuidanceUploadDocumentForReviewVisible">
  <div class="col-9 text-cool-gray">
    <div class="d-flex align-items-center relative sub-text-section">
      <span class="inline-block icon-s icon-pinned"></span>
      <div class="text-label font-bold-600">
        Upload the document for review and any additional/supporting documents,
        if applicable:
      </div>
      <div
        class="vertical-line-green"
        id="ext-comm-doc-guideline-vertical-line"
        *ngIf="showMoreGuidance"
      ></div>
    </div>
    <div class="padding-left-s">
      <div
        [hidden]="!showMoreGuidance"
        id="guidanceExpandedSection"
        class="margin-top-xs"
      ></div>
      <dds-button
        [theme]="showMoreButtonOptions.theme ?? ''"
        [kind]="showMoreButtonOptions.kind ?? ''"
        [size]="showMoreButtonOptions.size ?? ''"
        [width]="showMoreButtonOptions.width ?? ''"
        [icon]="showMoreButtonOptions.icon ?? ''"
        [isIconLeft]="showMoreButtonOptions.isIconLeft ?? false"
        [role]="showMoreButtonOptions.role ?? ''"
        [ariaLabel]="showMoreButtonOptions.ariaLabel ?? ''"
        [customClass]="showMoreButtonOptions.customClass ?? ''"
        (clicked)="expandGuidance()"
        >{{ showMoreButtonText }}</dds-button
      >
    </div>
  </div>
</div>

<div class="relative">
  <dds-uploader
    *ngIf="isDragAndDropEnable"
    [heading]="uploadAreaOptions.heading ?? emptyString"
    [description]="uploadAreaOptions.description ?? emptyString"
    [dragAndDropText]="uploadAreaOptions.dragAndDropText ?? emptyString"
    [isDragAndDrop]="uploadAreaOptions.isDragAndDrop ?? false"
    [isMultiple]="uploadAreaOptions.isMultiple ?? true"
    [acceptFormats]="uploadAreaOptions.acceptFormats ?? emptyString"
    [acceptExtensions]="uploadAreaOptions.acceptExtensions ?? emptyString"
    [restrictedFormats]="uploadAreaOptions.restrictedFormats ?? emptyString"
    [restrictedExtensions]="
      uploadAreaOptions.restrictedExtensions ?? emptyString
    "
    [exceptSymbols]="uploadAreaOptions.exceptSymbols ?? emptyString"
    [btnLabel]="uploadAreaOptions.btnLabel ?? emptyString"
    [isInverse]="uploadAreaOptions.isInverse ?? false"
    [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind ?? emptyString"
    (uploadedItems)="openUploadModal($event)"
  ></dds-uploader>

  <dds-button
    *ngIf="isAddLinkButtonVisible()"
    class="absolute button-style"
    [theme]="buttonOptions.theme ?? emptyString"
    [kind]="buttonOptions.kind ?? emptyString"
    [size]="buttonOptions.size ?? emptyString"
    [width]="buttonOptions.width ?? emptyString"
    (click)="openDocumentLinkModal()"
    >Add link</dds-button
  >
</div>

<app-external-communication-document-table
  [submissionStatusId]="submissionStatusId"
  [submissionId]="submissionId"
  [progressiveStatus]="progressiveStatus"
  [submissionStatus]="submissionStatus"
  [documentStatusDropdownData]="documentStatusDropdownData"
  [documentTypeDropdownData]="documentTypeDropdownData"
  [isStatusColumnVisible]="isStatusColumnVisible"
  [hasDocumentViewAccess]="userHasAccess"
  [isDisabledDocuments]="isDocumentTableDisabled()"
  (documentTableData)="handleDocumentTableData($event)"
  [isDraftForSelfReview]="isSelfReviewButtonVisible"
></app-external-communication-document-table>
