<div class="dds-inline-flex">
  <button class="dds-btn">
    <div class="dds-btn__content">
      <span class="dds-btn__text">Open modal</span>
    </div>
  </button>
</div>
<div class="discontinue-nca-dialog-modal">
  <div class="dds-modal-init dds-modal-open">
    <div class="dds-modal-overlay"></div>
    <div class="dds-modal dds-modal_lg">
      <div class="dds-modal__header">
        <!--to add dynamic client name-->
        <span class="dds-modal__title">{{resolve.labelHeader}}</span>
        <button class="dds-modal__close dds-icon dds-icon_close" (click)="cancel()" aria-label="Close modal"></button>
      </div>
      <div class="acceptance-disclaimer" id="discontinue-nca-dialog-modal-disclaimer"
           *ngIf="resolve?.isMultiOpportunityDisclaimerVisible">
        <div class="disclaimer-text-container">
          <div class="disclaimer-text" [innerHtml]="resolve?.multiOpportunityDisclaimerText"></div>
        </div>
      </div>
      <div class="dds-modal__body">
        <div class="margin-top-s">
          <span *ngIf="subStatusID == 0" class="icon-required-yellow"></span>
          <span class="text-label">
            {{resolve.ncaProcessSubStatus_Label}}
          </span>
          <div class="padding-top-xs">
            <dds-select [list]="resolve.optionsClientMilestoneSubStatus"
                        [placeholder]="''"
                        [(ngModel)]="subStatusID">
            </dds-select>
          </div>
        </div>
        <div class="margin-top-s">
          <span *ngIf="!reason.trim()" class="icon-required-yellow"></span>
          <span class="text-label"> {{resolve.labelBody}} </span>
          <div class="padding-top-xs">
              <dds-textarea [placeholder]="textareaOptions.placeholder ?? ''"
                            [minHeight]="textareaOptions.minHeight ?? 0"
                            [maxLength]="textareaOptions.maxLength ?? 0"
                            [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
                            [size]="textareaOptions.size ?? ''"
                            [(ngModel)]="reason"></dds-textarea>
          </div>
        </div>
      </div>
      <div class="dds-modal__footer">
        <div class="dds-modal__footer-content dds-modal__footer-content_left"></div>
        <div class="dds-modal__footer-content dds-modal__footer-content_right">
          <div class="dds-modal__footer-item dds-inline-flex">
            <button class="dds-btn dds-btn_secondary-loud dds-btn_fluid-width" (click)="cancel()">
              <div class="dds-btn__content">
                <span class="dds-btn__text">Cancel</span>
              </div>
            </button>
          </div>
          <div class="dds-modal__footer-item dds-inline-flex">
            <button class="dds-btn dds-btn_fluid-width" (click)="discontinue()" [disabled]="!reason || !reason.trim() ||
                                    subStatusID ==0">
              <div class="dds-btn__content">
                <span class="dds-btn__text">Discontinue</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
