import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DocumentEditPopupComponent } from '../document-edit-popup/document-edit-popup.component';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import {
  AttachDocumentBtn,
  Column,
  DOWNLOAD_DISABLED_USER_ROLES_FOR_RMREVIEWED_DOCUMENT,
  toast,
  ToggleGroupOptions,
  ToggleOptions,
} from './document-table-settings';
import { ToggleComponent } from '@usitsdasdesign/dds-ng/toggle';
import { SubmissionService } from '../../../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { ProgressIndicatorService } from '../../../../../../common/services/progress-indicator.service';
import { IntakeDocumentTableModel } from '../../../../../../common/models/intake-document-table.model';
import { DocumentService } from '../../../../../../http/document/document.service';
import {
  IntakeConstant,
  VALID_DOCUMENT_TYPES,
} from '../../../../../constants/intake.constant';
import { IntakeDocumentSubmissionTypeCode } from '../../../../../constants/intake_submission_type_code.enum';
import { StatusEnum } from '../../../../../../common/constants/status-enum';
import { IntakeOppertunitySubmissionStatus } from '../../../../../constants/intake_oppertunity_submission_status.enum';
import { ToastService } from '@usitsdasdesign/dds-ng/toast';
import { RoleEnum } from '../../../../../constants/Role.enum';
import {
  DocumentArchivalStatus,
  DocumentStatus,
} from '../../../../../constants/document-status.enum';
import {
  CheckboxGroupOptions,
  CheckboxOptions,
} from '@usitsdasdesign/dds-ng/checkbox';
import {
  Themes,
  ErrorState,
  ButtonKind,
  ExtThemes,
} from '@usitsdasdesign/dds-ng/shared';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { tooltipOptionsData } from '../../../../opportunity-details-settings';
import { RoleService } from '../../../../../../http/intake/role.service';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { HttpErrorResponse } from '@angular/common/http';
import { PermissionsObj } from '../../../../../../common/models/common-models';
import { IntakeOppertunitySubmissionDocumentStatus } from '../../../../../constants/intake_oppertunity_submission_document_status.enum';
import { OpportunityDetailsNavModel } from '../../../../../../common/models/opportunity-details.model';
import { isTheDocumentHasCompletedElSOw } from '../../../../../common/intake.util';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrl: './document-table.component.less',
})
export class DocumentTableComponent implements OnChanges, OnDestroy, OnInit {
  @Input() submissionId: number = 0;
  @Input() submissionTypeId: number = 0;
  @Input() submissionStatusId: number = 0;
  @Input() type: string = IntakeConstant.EMPTY_SPACE;
  @Input() isEditable: boolean = false;
  @Input() submissionStatus: string | undefined = IntakeConstant.EMPTY_SPACE;
  @Input() submissionTypeCode: string | undefined = IntakeConstant.EMPTY_SPACE;
  @Input() hideAttachDocumentButton: boolean = true;
  @Input() opportunityDocs!: OpportunityDetailsNavModel[];
  @Input() submissionTypeSubId: number | null | undefined = null;
  @Input() rmReviewedGuidanceNoteHeader: string = IntakeConstant.EMPTY_SPACE;
  @Input() rmReviewedGuidanceNoteBody: string = IntakeConstant.EMPTY_SPACE;

  loggedInUserRoles: string[] = [];
  @Input() set progressiveStatus(status: boolean | null) {
    setTimeout(() => {
      if (status != null) {
        this.updateServerValue(status);
      }
    }, 150);
  }
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;
  @Output() documentTableData: EventEmitter<IntakeDocumentTableModel[]> =
    new EventEmitter<IntakeDocumentTableModel[]>();
  @Output() uploadDocument: EventEmitter<any> = new EventEmitter<any>();

  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  groupOptions = ToggleGroupOptions;
  @ViewChild('toggle', { static: false })
  toggle!: ToggleComponent;
  options = ToggleOptions;
  toggleOpen: boolean = false;
  docToShow: IntakeDocumentTableModel[] = [];
  public docMetaData: IntakeDocumentTableModel[] = [];
  toasteOption = toast;
  public sampleColumns = Column;
  optionsToolTip: TooltipOptions = tooltipOptionsData;
  public readonly INTAKE = 'Intake';
  attachDocumentBtn = AttachDocumentBtn;
  isSubmissionStatusRMorSelfReviewed: boolean = false;

  allSelectedDocTypeSupporting: boolean = true;
  downloadDisabledUserRolesForRMReviewedDocs: string[] =
    DOWNLOAD_DISABLED_USER_ROLES_FOR_RMREVIEWED_DOCUMENT;
  downloadButtonDisabled: boolean = false;
  disabledDocumentIdList: number[] = [];

  constructor(
    private readonly modalService: ModalService,
    private readonly intakeDetailService: SubmissionService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly documentService: DocumentService,
    private readonly toastService: ToastService,
    private readonly roleService: RoleService
  ) {}

  ngOnInit(): void {
    this.updateToggleOption();
    this.roleService.currentUserRole$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: string[]) => {
          this.loggedInUserRoles = response;
        },
      });

    this.isSubmissionStatusRMorSelfReviewed =
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT;
  }

  openUploadModel(event: any): void {
    this.uploadDocument.emit(event);
  }

  #updateStatus(): void {
    const validTypes =
      VALID_DOCUMENT_TYPES[this.type as IntakeDocumentSubmissionTypeCode];
    if (
      (this.type as IntakeDocumentSubmissionTypeCode) ===
      IntakeDocumentSubmissionTypeCode.CO
    ) {
      //TODO: Need to check using global constant issues
      const isELSOWCompletedExist: boolean = isTheDocumentHasCompletedElSOw(
        this.opportunityDocs
      );
      const co_doc_status = this.docMetaData.some(
        (item) =>
          item.documentType === 'Change Order' &&
          (item.documentStatus as IntakeOppertunitySubmissionDocumentStatus) ===
            IntakeOppertunitySubmissionDocumentStatus.DRAFT
      );
      this.intakeDetailService.co_draft_availability.next(co_doc_status);
      if (isELSOWCompletedExist) {
        const status = this.docMetaData.some(
          (item) =>
            item.documentType === 'Change Order' &&
            (item.documentStatus as IntakeOppertunitySubmissionDocumentStatus) ===
              IntakeOppertunitySubmissionDocumentStatus.DRAFT
        );
        this.intakeDetailService.docISValid.next(status);
      } else {
        const status = this.docMetaData.some(
          (item) =>
            validTypes.includes(item.documentType) &&
            item.documentStatus === IntakeConstant.FINAL_ARCHIVE_READY_VERSION
        );
        this.intakeDetailService.docISValid.next(status);
      }
    } else {
      const status = this.docMetaData.some((item) => {
        if (this.submissionStatusId === 1) {
          return (
            validTypes.includes(item.documentType) &&
            item.documentStatus === DocumentStatus.Draft
          );
        } else {
          return validTypes.includes(item.documentType);
        }
      });
      this.intakeDetailService.docISValid.next(status);
    }
  }
  archiveItHeader: string = IntakeConstant.DOCUMENT_TABLE_ARCHIVE_HEADING;
  showArchiveIt: boolean = false;

  checkBoxGroupOptions: CheckboxGroupOptions = {
    title: IntakeConstant.EMPTY_SPACE,
    isRequired: false,
    isInverse: false,
  };

  checkBoxOptions: CheckboxOptions = {
    label: IntakeConstant.EMPTY_SPACE,
    theme: Themes.green,
    isIndeterminate: false,
    isError: false,
    isRequired: false,
    errorState: ErrorState.default,
    ariaLabel: 'checkbox',
    customClass: IntakeConstant.EMPTY_SPACE,
  };

  actionButtonOptions: ButtonOptions = {
    theme: ExtThemes.dark,
    kind: ButtonKind.silent,
  };

  ngOnChanges(changes: SimpleChanges): void {
    this.showArchiveIt = false;
    this.getDocumentMetaData();
    if (
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT ||
      this.submissionStatus === IntakeOppertunitySubmissionStatus.COMPLETED
    ) {
      this.showArchiveIt = true;
    }
    this.isEditable = false;
    if (
      changes['submissionTypeSubId'] &&
      changes['submissionTypeSubId'].currentValue
    ) {
      this.submissionTypeSubId = changes['submissionTypeSubId'].currentValue;
    }
  }

  stateChanged(event: boolean): void {
    this.updateServerValue(event);
  }

  updateServerValue(event: boolean): void {
    this.filterDocumentData(event);
    if (this.toggleOpen !== event) {
      setTimeout(() => this.toggle?.toggleState(true), 100);
    }
  }

  updateToggleOption(): void {
    if (
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
    ) {
      this.toggleOpen = true;
    } else {
      this.toggleOpen = false;
    }
  }

  filterDocumentData(draftHidden: boolean): void {
    if (draftHidden) {
      this.docToShow = this.docMetaData.filter(
        (doc: any) =>
          ![DocumentStatus.Draft, DocumentStatus.SupportingDocument].includes(
            doc.documentStatus
          )
      );
    } else {
      this.docToShow = this.docMetaData;
    }
  }

  onDownload(event: IntakeDocumentTableModel): void {
    if (this.permissionObj['isIntakeSubmissionDocumentDownloadEnable']) {
      if (event.archivalStatus == DocumentArchivalStatus.ARCHIVED) {
        if (event.attachmentId) {
          this.documentService
            .getArchivedDocDownloadUrl(event.attachmentId)
            .subscribe({
              next: (response: any) => {
                if (response?.documentDownloadUrl) {
                  window.open(response.documentDownloadUrl, '_blank');
                }
              },
              error: (err: Error) => {
                console.error('Error in downloading archived document', err);
              },
            });
        }
      } else {
        let downloadRequest = {
          DocumentName: event.documentTitle,
          DocumentKey: event.attachmentGuid,
          FileFolderPath: this.INTAKE,
          archivalStatus:
            event.archivalStatus == DocumentArchivalStatus.ARCHIVED,
        };
        this.documentService.getDownloadUrl(downloadRequest).subscribe({
          next: (response: any) => {
            if (response?.documentDownloadUrl) {
              window.open(response.documentDownloadUrl, '_blank');
            }
          },
          error: (err: HttpErrorResponse) => {
            console.error('Error in downloading document', err);
          },
        });
      }
    }
  }

  openLink(item: string): void {
    if (
      item &&
      this.permissionObj['isIntakeSubmissionDocumentDownloadEnable']
    ) {
      window.open(item, '_blank');
    }
  }

  onEdit(item: IntakeDocumentTableModel, modalBtn?: any): void {
    const modalRef = this.modalService.open(DocumentEditPopupComponent, {
      isFooter: true,
      size: IntakeConstant.UI_ELEMENT_SIZE,
      isInverse: false,
      documentInitiatedDetails: {
        moduleName: this.INTAKE,
        submissionStatusId: this.submissionStatusId,
        submissionTypeId: this.submissionTypeId,
        comments: item.comments,
        submissionDocumentId: item.submissionDocumentId,
        documentTitle: item.documentTitle,
        submissionDocumentTypeId: item.submissionDocumentTypeId,
        submissionId: this.submissionId,
        documentTypeId: item.documentTypeId,
        documentStatusId: item.documentStatusId,
        type: this.type,
        attachmentId: item.attachmentId,
        documentTypeDropdownType: item.documentType,
        documentStatusDropdownType: item.documentStatus,
        submissionStatus: this.submissionStatus,
        submissionTypeCode: this.submissionTypeCode,
        submissionTypeSubId: this.submissionTypeSubId,
      },
      rmReviewedGuidanceNoteHeader: this.rmReviewedGuidanceNoteHeader,
      rmReviewedGuidanceNoteBody: this.rmReviewedGuidanceNoteBody,
    });
    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.getDocumentMetaData();
        if (modalBtn) {
          modalBtn.focus();
        }
      });
  }

  onDelete(item: IntakeDocumentTableModel): void {
    if (
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT &&
      !this.loggedInUserRoles.includes(RoleEnum.PERSUIT_TEAM) // TODO: Remove, We don't have Persuit team role anymore
    ) {
      let requiredStatusLength = 0;
      for (let i = 0; i < this.docMetaData.length; i++) {
        if (
          this.docMetaData[i].documentStatus === StatusEnum.RMReviewed ||
          this.docMetaData[i].documentStatus ===
            IntakeOppertunitySubmissionDocumentStatus.FINAL_ARCHIVE_READY_VERSION
        ) {
          requiredStatusLength++;
        }
      }
      if (requiredStatusLength === 1) {
        if (
          item.documentStatus === StatusEnum.RMReviewed ||
          item.documentStatus ===
            IntakeOppertunitySubmissionDocumentStatus.FINAL_ARCHIVE_READY_VERSION
        ) {
          this.showToast();
          return;
        }
      }
    }

    this.progressIndicator.show();
    this.intakeDetailService
      .deleteDocument(item.submissionDocumentId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          // TODO: document delete response are not calling so we have to call getDocumentMetaData here
          this.getDocumentMetaData();
          this.progressIndicator.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          if (err?.status === 200) {
            this.getDocumentMetaData();
          } else console.error('Error in delete document data', err);
        },
      });

    this.docMetaData = this.docMetaData.filter(
      (doc: { submissionDocumentId: number }) =>
        doc.submissionDocumentId !== item.submissionDocumentId
    );
    this.docToShow = this.docToShow.filter(
      (doc: { submissionDocumentId: number }) =>
        doc.submissionDocumentId !== item.submissionDocumentId
    );
    this.#updateStatus();
    this.documentTableData.emit(this.docToShow);
  }

  getDocumentMetaData(): void {
    if (this.submissionId && this.submissionId !== 0) {
      this.progressIndicator.show();
      this.intakeDetailService
        .getDocumentTableData(this.submissionId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (data: any) => {
            this.docMetaData = data.map((item: IntakeDocumentTableModel) => ({
              ...item,
              isChecked: this.setItemCheckedStatus(item),
              isDisabled: this.diableArchiveIt(item.documentStatus),
              ariaLabel: item.documentTitle + ' checkbox',
              isVisible: item.archivalStatus === null && !item.isArchiveReady,
              docArchiveStatus: this.getDocumentStatus(
                item.archivalStatus ?? IntakeConstant.EMPTY_SPACE
              ),
            }));
            this.#updateStatus();
            this.updateToggleOption();
            this.filterDocumentData(this.toggleOpen);
            this.checkDocumentStatus();
            this.disableDownloadButton();
            this.documentTableData.emit(this.docMetaData);
            this.progressIndicator.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.progressIndicator.hide();
            console.error('Error in fetching document data', err);
          },
        });
    }
  }

  checkDocumentStatus() {
    if (
      this.docToShow.every(
        (doc) => doc.documentStatus === IntakeConstant.DOCUMENT_STATUS
      )
    ) {
      this.allSelectedDocTypeSupporting = true; // Disable submit button
    } else {
      this.allSelectedDocTypeSupporting = false; // Enable submit button
    }
    this.intakeDetailService.updateIntakeSubmitBtnStatus(
      this.allSelectedDocTypeSupporting
    );
  }

  getDocumentStatus(archivalStatus: string): string {
    return archivalStatus != null
      ? String(archivalStatus).charAt(0).toUpperCase() +
          String(archivalStatus).slice(1)
      : IntakeConstant.EMPTY_SPACE;
  }

  setItemCheckedStatus(item: IntakeDocumentTableModel): boolean {
    if (
      item.isArchiveReady === null &&
      item.documentStatus === DocumentStatus.FinalArchiveReadyVersion
    ) {
      this.updateDocumentArchiveState(item, true);
    }
    return (
      item.isArchiveReady ||
      item.documentStatus === DocumentStatus.FinalArchiveReadyVersion ||
      !!item.isArchiveReady
    );
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  showToast(): void {
    this.toastService.createToast(this.toasteOption);
  }

  isDeleteEditBtnVisible(): boolean {
    if (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT
    ) {
      return (
        !this.loggedInUserRoles.includes(RoleEnum.PERSUIT_TEAM) && // TODO: Remove, We don't have Persuit team role anymore
        !this.isEditable
      );
    }
    return !this.isEditable;
  }

  // TODO: Impletement this function once checkbox for transfer to archive is available
  // public toggleRow(rowData: ExampleData): void {
  //   rowData.selected = !rowData.selected;
  // }

  diableArchiveIt(docStatus: string): boolean {
    return (
      docStatus ===
      IntakeOppertunitySubmissionDocumentStatus.FINAL_ARCHIVE_READY_VERSION
    );
  }

  updateRow(item: IntakeDocumentTableModel): void {
    if (!item.isDisabled) {
      this.docToShow = this.docToShow.map((doc) => ({
        ...doc,
        isChecked:
          doc.submissionDocumentId === item.submissionDocumentId
            ? (doc.isChecked = !doc.isChecked)
            : doc.isChecked,
      }));

      let selectedItem: IntakeDocumentTableModel | undefined =
        this.docToShow.find(
          (doc: IntakeDocumentTableModel) =>
            doc.submissionDocumentId === item.submissionDocumentId
        );
      if (selectedItem) {
        this.updateDocumentArchiveState(
          selectedItem,
          selectedItem.isChecked ?? false
        );
      }
    }
  }

  updateDocumentArchiveState(
    item: IntakeDocumentTableModel,
    isArchiveReady: boolean
  ): Promise<any> {
    const postRequestData = {
      submissionDocumentId: item.submissionDocumentId,
      submissionId: item.submissionId,
      documentTypeId: item.documentTypeId,
      documentStatusId: item.documentStatusId,
      submissionDocumentTypeId: item.submissionDocumentTypeId,
      documentTitle: item.documentTitle,
      comments: item.comments,
      isArchiveReady: isArchiveReady,
      modifiedBy: IntakeConstant.EMPTY_SPACE,
      attachmentId: item?.attachmentId,
    };
    return new Promise(() => {
      this.intakeDetailService
        .postDocumentEdit(postRequestData)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: () => {},
          error: (err: HttpErrorResponse) => {
            console.error(
              'An error occurred during update document archive status:',
              err
            );
          },
        });
    });
  }

  disableDownloadButton(): void {
    const submissionStatusCondition: boolean =
      this.submissionStatus === IntakeOppertunitySubmissionStatus.SUBMITTED ||
      this.submissionStatus === IntakeOppertunitySubmissionStatus.RESUBMITTED;
    const userRoleCondition: boolean = this.loggedInUserRoles.some((role) =>
      this.downloadDisabledUserRolesForRMReviewedDocs.includes(role)
    );
    if (submissionStatusCondition && userRoleCondition) {
      this.disabledDocumentIdList = this.docMetaData
        .filter((doc) => doc.documentStatus === 'RM Reviewed')
        .map((doc) => doc.submissionDocumentId);
    }
  }

  checkDocumentStatusEligiblity(submissionDocumentId: number): boolean {
    return this.disabledDocumentIdList.includes(submissionDocumentId);
  }

  disabledDownloadClickHandler(submissionDocumentId: number): void {
    if (this.disabledDocumentIdList.includes(submissionDocumentId)) {
      this.toastService.createToast({
        ...this.toasteOption,
        title:
          "Download functionality is not available during 'Submitted' status.",
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
