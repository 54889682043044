import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { ClientStatusWebapiService } from '../../../http/client/client-status-webapi.service';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { CommonHelperService } from '../../../common/services/common-helper.service';

@Component({
  selector: 'app-convert-nca-to-cc-dialog',
  templateUrl: './convert-nca-to-cc-dialog.component.html',
  styleUrl: './convert-nca-to-cc-dialog.component.less'
})
export class ConvertNcaToCcDialogComponent {
  public convertNcaToCcReason: string = '';
  public subStatusID: number = 0;
  public substatuscustom: string = '';
  @Input() resolve: any;

  constructor(private modalService: ModalService,
              private clientstatuswebapiservice: ClientStatusWebapiService,
              private commonHelperServive:CommonHelperService
  ) {}

  public ngOnInit() { }

  textareaOptions: TextareaOptions = {
    placeholder: 'Enter reason',
    minHeight: 100,
    maxLength: 4000,
    maxLengthPosition: 'bottom-right',
    size: Size.md
  };

  public confirm() {
    this.convertNcaToCcReason=this.commonHelperServive.trimPrefixEmptySpace(this.convertNcaToCcReason);
    this.clientstatuswebapiservice
      .convertNCAToCC(this.resolve.clientNumber, this.resolve.clientMilestoneId,this.convertNcaToCcReason)
        .subscribe({
                    next: (value) => {
                      window.location.reload();
                    },      
                    error: (error) => {
                    console.error(error);
                    },
                    complete: () => {
                      this.modalService.close();
                    }
                  });
  }
  public cancel() {
    //This will disable the scroll when the pop-up is open
    document.body.classList.remove('no-scroll');
    this.modalService.close();
  }
}