import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { AuditItem, AuditLogRequest, AuditUser } from '../../common/models/common-models'

@Injectable({
  providedIn: 'root'
})
export class WatchlistApiService {

  constructor(private httpService: HttpClient) { }

  apiUrl: string = environment.apiUrl;


  getClientWatchlistGridDetails(
    pageNumber: number,
    pageSize: number,
    sortParameterCode: number,
    isAscending: boolean,
    filterData: WatchlistDashboardGridLevelFilter[]
  ): Observable<Watchlist[]> {
    const params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('sortParameterCode', sortParameterCode.toString())
      .set('isAscending', isAscending.toString());

    return this.httpService.post<Array<Watchlist>>(
      this.apiUrl + 'ClientWatchlist/GetWatchlistClients',
      filterData,
      { params }
    );
  }
  getWatchlistClientsReport(
  ): Observable<{ Base64String: string , FileName: string}> {

    return this.httpService.get<{ Base64String: string, FileName: string }>(
      this.apiUrl + 'ExcelExport/ExportWatchlistClients',
    );
  }

  searchClients(clientSearchterm: string): Observable<Array<ClientSearchWatchlistResult>> {
    return this.httpService.get<Array<ClientSearchWatchlistResult>>(
      this.apiUrl + 'ClientWatchlist/SearchClients?clientSearchterm=' + clientSearchterm
    );
  }
  upsertWatchlistData(
    model: WatchlistRequest[],
  ): Observable<any> {
    return this.httpService.post(this.apiUrl + 'ClientWatchlist/UpsertWatchlistData', model);
  }
  getWatchlistdataByClientnumber(clientNumber: string, isParentClient: boolean): Observable<Array<ClientWatchlistDto>> {
    return this.httpService.get<Array<ClientWatchlistDto>>(
      this.apiUrl + 'ClientWatchlist/GetWatchlistdataByClientnumber?clientNumber=' + clientNumber + '&isParentClient=' + isParentClient
    );
  }
  eraseClientRecords(watchlistIds: number[]): Observable<any> {
    return this.httpService.post(this.apiUrl + 'ClientWatchlist/EraseClientRecords', watchlistIds);
  }

  getWatchlistAuditLog(watchlistId: number, auditLogRequest: AuditLogRequest): Observable<AuditItem[]> {
    const params = new HttpParams()
      .set('watchlistId', watchlistId);

    return this.httpService.post<AuditItem[]>(
      this.apiUrl + 'ClientWatchlist/GetWatchlistAuditLog',
      auditLogRequest,
      { params }
    );
  }

  getWatchlistAuditlogUsers(watchlistId: number): Observable<AuditUser[]> {
    const params = new HttpParams()
      .set('watchlistId', watchlistId);

    return this.httpService.get<AuditUser[]>(
      this.apiUrl + 'ClientWatchlist/GetWatchlistAuditlogUsers', { params } );
  }
}

export interface Watchlist {
  watchlistId?: number;
  clientId: number;
  clientNumber: string;
  clientName: string;
  industry: string;
  industrySector: string;
  parentclientName: string;
  watchlistStatusId: number;
  watchlistStatusName?: string;
  statusComment: string;
  createdBy?: string;
  createdDate?: Date;
  modifiedBy?: string;
  modifiedByName?: string;
  modifiedDate?: string;
  isActive?: boolean;
  isNCANeverEvaluated: boolean;
  [key: string]: any;
}

export interface WatchlistHistory {
  historyId: number;
  history: Watchlist;
  operationType: string;
  operationTimestamp: Date;
}

export interface ClientWatchlistDto {
  clientId: string;
  clientNumber: string;
  clientName: string;
  industrySector: string;
  isParentClient: boolean;
  watchlistId?: number;
  watchlistStatusId?: number;
  watchlistStatus: string;
  statusComment?: string;
  watchlistStatuses: string;
  isNCANeverEvaluated: boolean;
  prevWatchlistStatusId?: number;
  prevWatchlistStatus: string | null;
  prevStatusModifiedDetail: string | null;
  prevStatusComment: string | null;
  disabled?: boolean;
  isPreviousStatusShow: boolean;
  orgWatchlistStatusId?: number;
  orgStatusComment?: string;
}

export enum WatchlistStatus {
  ServeWithConditions = 1,
  DoNotServe = 2,
  Removed = 3
}
export interface ClientSearchWatchlistResult {
  watchlistId?: number;
  clientName: string;
  clientNumber: string;
  isParentClient: boolean;
  watchlistStatusId: number | null;
  watchlistStatus: string | null;
  statusComment: string | null;
  isNCANeverEvaluated: boolean;
  parentClientNumber: string | null;
  parentClientName: string | null;
  parentClientWatchlistStatus: string | null;
  parentClientStatusComment: string | null;
  employeeId: string | null;
  clientWatchlistCreatedBy: string | null;
  clientWatchlistCreatedDate: string | null;
  parentClientWatchlistCreatedBy: string | null;
  parentClientWatchlistCreatedDate: string | null;
  watchlistStatusList: WatchlistStatusDropDown[];
  prevWatchlistStatusId?: number;
  prevWatchlistStatus: string | null;
  prevStatusModifiedDetail: string | null;
  prevStatusComment: string | null;
  
}

export interface WatchlistRequest {
  clientNumber: string | null;
  isParentClient: boolean;
  watchlistStatusId: number | null;
  statusComment: string;
  employeeId: string | null;
  isActive: boolean;
}
export interface WatchlistStatusDropDown {
  value: number;
  heading: string;
  customClass?: string;
}

export enum WatchlistDashboardFilterSortParameterCode {
  clientName = 1,
  parentclientName = 2,
  watchlistStatusName = 3,
  statusComment = 4,
  industry = 5,
  sector = 6,
  modifiedBy = 7
}
export interface WatchlistDashboardGridLevelFilter {
  filtertypecode: number;
  filtercriteria: string;
}
export interface GridColumn extends Column {
  filterable?: boolean;
  searchValue?: any;
}
export interface WatchlistEraseItems {
  watchlistId?: number;
  clientName: string;
  clientNumber: string;
  isParentClient: boolean;
  shortOrder: number;
}
