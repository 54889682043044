import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { AdminConstant } from '../../../constants/admin-constants';
import {
  buttonOptions,
  dropdownItems,
  groupOptions,
  groupToolTipOptions,
  inputOptions,
  labelToCodeMapping,
  ModalCloseButtonOptions,
  multiSelectOptions,
  options,
  toggleOptions,
} from './modify-market-offering-pop-up-helper';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  MultiSelectItem,
  MultiSelectOptions,
} from '@usitsdasdesign/dds-ng/multi-select';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminModuleService } from '../../../admin-services/admin-module.service';
import { Subject, takeUntil } from 'rxjs';
import {
  MarketOfferingData,
  ModifyMarketOfferingRequest,
} from '../../../../common/models/admin-module.model';
import { InputOptions } from '@usitsdasdesign/dds-ng/input';
import {
  ToggleGroupOptions,
  ToggleOptions,
} from '@usitsdasdesign/dds-ng/toggle';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';
import { IntakeConstant } from '../../../../intake/constants/intake.constant';

@Component({
  selector: 'app-modify-market-offering-pop-up',
  templateUrl: './modify-market-offering-pop-up.component.html',
  styleUrl: './modify-market-offering-pop-up.component.less',
})
export class ModifyMarketOfferingPopUpComponent implements OnInit,OnDestroy {
  @Input() rowData!: MarketOfferingData;
  @Input() isInverse: boolean = false;
  emptyString: string = AdminConstant.EMPTY_SPACE;
  modalCloseButtonOptions: ButtonOptions = ModalCloseButtonOptions;
  multiSelectOptions: MultiSelectOptions = multiSelectOptions;
  dropdownItems: MultiSelectItem[] = dropdownItems;

  inputOptions: InputOptions = inputOptions;
  toggleOptions: ToggleOptions = toggleOptions;
  groupOptions: ToggleGroupOptions = groupOptions;
  buttonOptions: ButtonOptions = buttonOptions;
  dateFormat: string = IntakeConstant.DATE_FORMAT;

  selectedCodes: number[] = [];
  groupToolTipOptions = groupToolTipOptions;
  totalFeeThresholds: string = AdminConstant.EMPTY_SPACE;
  reviewerAvailability: boolean = false;

  showSelectedTags: boolean = false;
  options: TagOptions = options;
  htmlElementSize = AdminConstant.UI_ELEMENT_SIZE;

  dropdownList: MultiSelectItem[] = dropdownItems;
  form!: FormGroup;
  private readonly unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    private readonly modalService: ModalService,
    private readonly fb: FormBuilder,
    private readonly adminModuleService: AdminModuleService
  ) {}

  ngOnInit(): void {
    this.totalFeeThresholds = AdminConstant.DOLLAR+this.rowData.totalFeeThresholds;
    this.reviewerAvailability = this.rowData.reviewerAvailability;
    this.initializeForm();
    this.updateForm(this.rowData.excludedFromSelfReview);

    this.showSelectedTags = this.dropdownCtrlValue.length > 0;
    this.form.get('dropdownCtrl')?.valueChanges
    .pipe(takeUntil(this.unsubscriber$))
    .subscribe((value) => {
      this.showSelectedTags = this.dropdownCtrlValue.length > 0;
    });
  }
  get dropdownCtrlValue():[] {
    return this.form.get('dropdownCtrl')?.value || [];
  }

  initializeForm(): void {
    this.form = this.fb.group({
      dropdownCtrl: [[]],
    });
  }

  updateForm(excludedFromSelfReview: string[]): void {
    const selectedItems = excludedFromSelfReview.map((item: string) => ({
      label: item,
      value: true,
    }));
    this.form.patchValue({
      dropdownCtrl: selectedItems,
    });
  }

  removeMultiSelectItemCommContain(removalItem: MultiSelectItem): void {
    const current = this.dropdownCtrlValue;
    const updated = current.filter((item: MultiSelectItem) => item.label !== removalItem.label);
    this.form.get('dropdownCtrl')?.setValue(updated);
  }

  save(): void {
    const labelToCodeMap = labelToCodeMapping;
    const excludedFromSelfReview = this.form
      .get('dropdownCtrl')
      ?.value.filter((item: { label: string; value: boolean }) => item.value)
      .map(
        (item: { label: string; value: boolean }) => labelToCodeMap[item.label]
      )
      .filter((code: number | undefined) => code !== undefined);

    const requestBody: ModifyMarketOfferingRequest = {
      marketOfferingId: this.rowData.marketOfferingId,
      offeringPortfolioId: this.rowData.offeringPortfolioId,
      offeringId: this.rowData.offeringId,
      excludedFromSelfReview: excludedFromSelfReview,
      totalFeeThresholds: this.totalFeeThresholds.replace(AdminConstant.DOLLAR, AdminConstant.EMPTY_SPACE),
      reviewerAvailability: this.reviewerAvailability,
    };
    this.saveMarketOfferingData(requestBody);
    this.closeModal();
  }

  saveMarketOfferingData(requestBody: ModifyMarketOfferingRequest) {
    this.adminModuleService
      .updateMarketOfferingData(requestBody)
      .subscribe({
        error: (err: Error) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  toggleStateChanged(): void {
    this.reviewerAvailability = !this.reviewerAvailability;
  }

  closeModal(): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
