import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import {PagerOptions} from '@usitsdasdesign/dds-ng/pager';
import {Size, Themes} from '@usitsdasdesign/dds-ng/shared';
import {ItemsPerPageOptions} from '@usitsdasdesign/dds-ng/items-per-page';
import { PaginationOptions, Mode } from '@usitsdasdesign/dds-ng/pagination';

@Component({
  selector: 'app-new-pagination-and-grid',
  templateUrl: './new-pagination-and-grid.component.html',
  styleUrl: './new-pagination-and-grid.component.less'
})
export class NewPaginationAndGridComponent {
  @Input() tileCountforPage: number = 0;
  @Input() pageUI: number = 0;
  @Output() pageChanged = new EventEmitter<number>();
  @Output() valueChanged = new EventEmitter<number>();

  value: number = 0;
  item: number = 5;
  list: any[] = [5, 10, 25, 50];
  findPageLength: number = 0;

  paging: PagerOptions = {
    label: 'Go to page',
    size: Size.md,
    disabled: false,
    minValue: 1,
    maxValue: 5,
    customClass: ''
  };


itemPerPage: ItemsPerPageOptions = {
  title: 'Items per page',
  placeholder: 'Placeholder',
  size: Size.md,
  disabled: false,
  isResponsive: false,
  isInverse: false,
  icon: 'dds-icon_arrow-down',
  customClass: '',
  stickerWidth: 0,
  stickerShift: 0,
  stickerMaxHeight: "",
  stickerIsDisabled: false,
  stickerPosition: "bottom-left",
  stickerIndent: 0,
  stickerCustomClass: "",
  stickerIsDynamic: true
};

paginationOptions: PaginationOptions = {
  pageLength: 999,
  mode: Mode.text,
  pageNumberInSection: 5,
  size: 'md',
  theme: Themes.dark,
  disabled: false,
  isInverse: false,
  ariaLabel: 'pagination',
  customClass: '',
  isIndefinitePageLength: false,
  isResponsive: false
};

  public updatePaginationOptions() {
    const itemsPerPage = this.item;
    const pageLength = Math.ceil(this.tileCountforPage / itemsPerPage);
    this.paginationOptions.pageLength = pageLength;
    this.findPageLength = pageLength;
    this.paginationOptions.pageNumberInSection = itemsPerPage;
    this.paging.maxValue = pageLength == 0 ? 1 : pageLength;
  }

  onPageChanged(pageUI: number) {
    this.value = pageUI;
    this.pageChanged.emit(this.value);
    this.updatePaginationOptions();
  }

  onValueChanged(item: number) {
    this.item = item;
    this.valueChanged.emit(this.item);
    this.updatePaginationOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tileCountforPage']) {
      this.updatePaginationOptions();
    }
  }

  constructor(
   ) { }

}