import { Component, Input, OnInit } from '@angular/core';
import {
  AccordionOptions,
  DatepickerOptions,
  tabContainerOptions,
} from './global-eng-details.helper';
import { GlobalEngagementService } from '../../../http/global-engagement/global-engagement.service';
import { debounceTime, delay, filter, Subject, takeUntil } from 'rxjs';
import {
  GlobalEngagementDetailModel,
  GlobalEngagementDetailSubmissionModel,
  GlobalEngagementMemberFirmModel,
} from '../../../common/models/global-engagement.model';
import { ExternalCommunicationService } from '../../../http/external-communication/external-communication.service';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import {
  OfferingModel,
  OfferingPortfolioModel,
} from '../../../common/models/external-communication.model';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import { GlobalEngagementsConstant } from '../../constants/global-engagements.constants';
import { IntakeConstant } from '../../../intake/constants/intake.constant';
import { SubmissionService } from '../../../http/intake/submission.service';
import { spinnerOptions } from '../../global-engagement.helper';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { OpportunityDetails } from '../../../common/models/opportunity-details.model';
import { PermissionsObj } from '../../../common/models/common-models';
import { CommonService } from '../../../http/intake/common.service';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';

@Component({
  selector: 'app-global-eng-details',
  templateUrl: './global-eng-details.component.html',
  styleUrl: './global-eng-details.component.less',
})
export class GlobalEngDetailsComponent implements OnInit {
  emptyString: string = GlobalEngagementsConstant.EMPTY_STRING;
  accordionOptions = AccordionOptions;
  datePickerOptions = DatepickerOptions;
  @Input() engagementId: string | null = this.emptyString;
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;

  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  engagementDetails!: GlobalEngagementDetailModel;
  offeringPortfolioList: SelectItemOptions[] = [];
  offeringList: SelectItemOptions[] = [];
  memberFirmList: SelectItemOptions[] = [];
  public savedetails!: Saver | null;
  ppmdId: number | null = null;
  ppmdName: string = this.emptyString;
  firmPartner: string = this.emptyString;
  firmPartnerId: number | null = null;
  memberFirmId: number | null = null;
  memberFirmName: string = this.emptyString;
  isMemberFirmEditable: boolean = false;
  engagementTitle: string = this.emptyString;
  isEngagementTitleEditable: boolean = false;
  offeringPortfolioId: number | null = null;
  offeringId: number | null = null;
  offeringPortfolioName: string | null | undefined = this.emptyString;
  offeringName: string | null | undefined = this.emptyString;
  isofferingPortfolioEditable: boolean = false;
  isofferingEditable: boolean = false;
  startDate: string = this.emptyString;
  endDate: string = this.emptyString;
  isstartDateEditable: boolean = false;
  isendDateEditable: boolean = false;
  engagementDescription: string = this.emptyString;
  prrId: number | null = null;
  private readonly searchOpportunityQuery$: Subject<string> =
    new Subject<string>();
  isSpinnerVisible: boolean = false;
  totalCount: number = 0;
  pageNumber: number = 1;
  notFound: string = this.emptyString;
  opportunityList: OpportunityDetails[] = [];
  showDropdown: boolean = false;
  selectedOportunity: string = this.emptyString;
  pageSize: number = GlobalEngagementsConstant.PAGE_NUMBER;
  spinnerOptions = spinnerOptions;
  isOpportunityEditable: boolean = false;
  tempStartDate: Date | null = null;
  tempEndDate: Date | null = null;
  datePipe = new DatePipe('en-US');
  isPprNotAvailable: boolean = false;
  validStartDate!: Date;
  validEndDate!: Date;
  longOldDate: number = GlobalEngagementsConstant.longOldDate;
  longNewDate: number = GlobalEngagementsConstant.longNewDate;
  SEARCH_LIMIT: number = GlobalEngagementsConstant.SEARCH_LIMIT;
  memberFirmConstant: string = GlobalEngagementsConstant.MEMBER_FIRM;
  offeringPortfolioConstant: string =
    GlobalEngagementsConstant.OFFERING_PORTFOLIO;
  offeringConstant: string = GlobalEngagementsConstant.OFFERING;
  startDateConstant: string = GlobalEngagementsConstant.START_DATE;
  endDateConstant: string = GlobalEngagementsConstant.END_DATE;
  engagementTitleConstant: string = GlobalEngagementsConstant.ENGAGEMENT_TITLE;
  opportunityConstant: string = GlobalEngagementsConstant.OPPORTUNITY;
  defaultValue: string = GlobalEngagementsConstant.DEFAULT_VALUE;
  textLength1000: number = GlobalEngagementsConstant.TEXT_LENGTH_1000;
  textLength100: number = GlobalEngagementsConstant.TEXT_LENGTH_100;
  pauaeTime: number = GlobalEngagementsConstant.TIMEOUT_TIMER;
  undefinedString = GlobalEngagementsConstant.undefinedString;
  nullString = GlobalEngagementsConstant.nullString;
  totalOpportunityCount: number = 0;
  activeOpportunityCount: number = 0;
  selectedTabCode: string = 'active';
  selectTabIndex: number = 0;
  tabContainerOptions = tabContainerOptions;

  public tabs = [
    {
      label: 'Active',
      code: 'active',
      count: this.activeOpportunityCount,
    },
    {
      label: 'All opportunities',
      code: 'all',
      count: this.totalOpportunityCount,
    },
  ];

  constructor(
    private readonly globalEngagementService: GlobalEngagementService,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly autosaverService: AutosaverService,
    private readonly submissionService: SubmissionService,
    private readonly commonService: CommonService,
    private readonly progressIndicator: ProgressIndicatorService
  ) {}

  ngOnInit(): void {
    this.progressIndicator.show();
    this.globalEngagementService.changeSubmissionId({
      opportunitynumber: this.engagementId!,
      submissionid: null,
    });
    this.commonService.enableAuditTrail(true);
    this.getMemberFirmDetails();
    this.getOfferingPortfolio();
    this.globalEngagementService.enableDisconitnue(false);

    this.searchOpportunityQuery$
      .pipe(
        debounceTime(IntakeConstant.SEARCH_DEBOUNCE_TIME),
        filter(
          (key) => !!key && key.length > IntakeConstant.SEARCH_STRING_LIMIT
        ),
        takeUntil(this.#unsubscriber$)
      )
      .subscribe(() => {
        this.fetchOpportunitySearchResult(1, this.selectedTabCode);
      });

    setTimeout(() => {
      this.getSubmissionDetails();
    }, this.pauaeTime);

    let autoSaverOptions = {
      delay: 500,
      onSuccess: (
        saver: any,
        result: GlobalEngagementDetailSubmissionModel
      ) => {},
    } as IAutosaverOptions;

    this.savedetails = this.autosaverService.newSaver(
      'savedetails',
      this,
      async () => this.postEngagementDetails(),
      autoSaverOptions
    );
    this.savedetails.start();
  }

  getSubmissionDetails(): void {
    if (this.engagementId) {
      this.progressIndicator.show();
      this.globalEngagementService
        .getGlobalEngagementDetails(Number(this.engagementId))
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe({
          next: (response: GlobalEngagementDetailModel) => {
            this.engagementDetails = response;
            this.memberFirmId = response.memberFirmId;
            if (this.engagementDetails.offeringPortfolioId !== null) {
              this.getOffering(
                +this.engagementDetails.offeringPortfolioId,
                true
              );
              delay(GlobalEngagementsConstant.DELAY_TIMER);
            }
            this.progressIndicator.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.progressIndicator.hide();
            console.error('An error occurred during submission: ', err);
          },
        });
    }
  }

  setEngagementFormDetails(): void {
    this.memberFirmName =
      String(
        this.memberFirmList.find(
          (item: SelectItemOptions) => item.value === this.memberFirmId
        )?.heading
      ) ?? this.emptyString;
    this.engagementTitle =
      String(this.engagementDetails?.engagementTitle) ?? this.emptyString;
    this.offeringPortfolioId = this.engagementDetails.offeringPortfolioId;
    this.offeringId = this.engagementDetails.offeringId;
    this.offeringPortfolioName =
      String(
        this.offeringPortfolioList.find(
          (item: SelectItemOptions) => item.value === this.offeringPortfolioId
        )?.heading
      ) ?? this.emptyString;
    this.offeringName =
      String(
        this.offeringList.find(
          (item: SelectItemOptions) => item.value === this.offeringId
        )?.heading
      ) ?? this.emptyString;
    this.engagementDescription =
      String(
        this.engagementDetails.engagementDescription ?? this.emptyString
      ) ?? this.emptyString;
    this.prrId = this.engagementDetails?.prrId;
    this.ppmdName =
      String(this.engagementDetails.responsibleDeliveryCenterPpmd) ??
      this.emptyString;
    this.firmPartner =
      String(this.engagementDetails.memberFirmPartner) ?? this.emptyString;
    this.selectedOportunity =
      String(this.engagementDetails.jupiterOpportunityId) ?? this.emptyString;
    this.startDate =
      this.formatDate(this.engagementDetails.engagementStartDate) ??
      this.emptyString;
    this.endDate =
      this.formatDate(this.engagementDetails.engagementEndDate) ??
      this.emptyString;
    if (this.startDate) {
      this.validEndDate = new Date(this.startDate);
    } else {
      this.validEndDate = new Date(this.longOldDate);
    }
    if (this.endDate) {
      this.validStartDate = new Date(this.endDate);
    } else {
      this.validStartDate = new Date(this.longNewDate);
    }

    this.tempStartDate = this.formatToAmericanDate(this.startDate);
    this.tempEndDate = this.formatToAmericanDate(this.endDate);
    this.isPprNotAvailable = this.engagementDetails?.prrIdNotAvailable ?? false;
  }

  getOffering(offeringPortfolioId: number, isEdit: boolean): void {
    this.externalCommService
      .getExtCommOfferingBasedOnOfferingPortfolio(offeringPortfolioId)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: OfferingModel[]) => {
          if (response) {
            const convertedArray = response.map((item: OfferingModel) => ({
              value: parseInt(item.offeringId),
              heading: item.offeringName,
            }));
            this.offeringList = convertedArray;
          }
          if (isEdit) {
            this.setEngagementFormDetails();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getOfferingPortfolio(): void {
    this.externalCommService
      .getExtCommOfferingPortfolio()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: OfferingPortfolioModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: OfferingPortfolioModel) => ({
                value: parseInt(item.offeringPortfolioId),
                heading: item.offeringPortfolioName,
              })
            );
            this.offeringPortfolioList = convertedArray;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getMemberFirmDetails(): void {
    this.globalEngagementService
      .getMemberFirmDetails()
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: GlobalEngagementMemberFirmModel[]) => {
          if (response) {
            const convertedArray = response.map(
              (item: GlobalEngagementMemberFirmModel) => ({
                value: item.memberFirmId,
                heading: item.memberFirmName,
              })
            );
            this.memberFirmList = convertedArray;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  onMemberFirmChange(id: number): void {
    this.memberFirmName = String(
      this.memberFirmList.find((item) => item.value === id)?.heading
    );
    this.isMemberFirmEditable = false;
    this.postEngagementDetails();
  }

  postEngagementDetails(): void {
    const requestBody = {
      engagementId: this.engagementDetails.engagementId,
      memberFirmId: this.memberFirmId,
      engagementTitle: this.engagementTitle,
      engagementStartDate: this.formatDateForPost(this.startDate),
      engagementEndDate: this.formatDateForPost(this.endDate),
      deliveryCenterId: this.engagementDetails.deliveryCenterId,
      jupiterOpportunityId: this.selectedOportunity,
      offeringPortfolioId: this.offeringPortfolioId,
      offeringId: this.offeringId,
      engagementDescription: this.engagementDescription,
      responsibleDeliveryCenterPpmd: this.ppmdName,
      memberFirmPartner: this.firmPartner,
      prrId: this.prrId,
      prrIdNotAvailable: this.isPprNotAvailable,
      createdBy: this.engagementDetails.createdBy,
      createdDate: this.engagementDetails.createdDate,
      modifiedBy: this.engagementDetails.modifiedBy,
      modifiedDate: this.engagementDetails.modifiedDate,
      isActive: this.engagementDetails.isActive,
    };

    this.globalEngagementService
      .postGlobalEngagementDetails(requestBody)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  removePPMD(): void {
    if (this.permissionObj['isGlobalEngEngagementDetailsPPMDEnable']) {
      this.ppmdId = null;
      this.ppmdName = this.emptyString;
      this.postEngagementDetails();
    }
  }

  onSelectedPPMD($event: any): void {
    if (this.permissionObj['isGlobalEngEngagementDetailsPPMDEnable']) {
      this.ppmdId = $event.reviewerId;
      this.ppmdName = $event.displayName;
      this.postEngagementDetails();
    }
  }

  removeFirmPartner(): void {
    if (
      this.permissionObj['isGlobalEngEngagementDetailsMemberFirmPartnerEnable']
    ) {
      this.firmPartnerId = null;
      this.firmPartner = this.emptyString;
      this.postEngagementDetails();
    }
  }

  onSelectedFirmPartner($event: any): void {
    if (
      this.permissionObj['isGlobalEngEngagementDetailsMemberFirmPartnerEnable']
    ) {
      this.firmPartnerId = $event.reviewerId;
      this.firmPartner = $event.displayName;
      this.postEngagementDetails();
    }
  }

  onOfferingPortfolioChange(id: number): void {
    this.offeringPortfolioName = String(
      this.offeringPortfolioList.find((item) => item.value === id)?.heading
    );
    this.isofferingPortfolioEditable = false;
    this.offeringId = null;
    this.offeringName = this.nullString;
    this.isofferingEditable = true;
    this.getOffering(id, false);

    this.postEngagementDetails();
  }

  onOfferingChange(id: number): void {
    this.offeringName = String(
      this.offeringList.find((item) => item.value === id)?.heading
    );
    this.isofferingEditable = false;
    this.postEngagementDetails();
  }

  makeFeildEditable(feild: string): void {
    switch (feild) {
      case this.memberFirmConstant:
        if (
          this.permissionObj['isGlobalEngEngagementDetailsMemberFirmEnable']
        ) {
          this.isMemberFirmEditable = true;
        }
        break;
      case this.engagementTitleConstant:
        if (
          this.permissionObj[
            'isGlobalEngEngagementDetailsEngagementTitleEnable'
          ]
        ) {
          this.isEngagementTitleEditable = true;
        }
        break;
      case this.offeringPortfolioConstant:
        if (
          this.permissionObj[
            'isGlobalEngEngagementDetailsOfferingPortfolioEnable'
          ]
        ) {
          this.isofferingPortfolioEditable = true;
        }
        break;
      case this.offeringConstant:
        if (this.permissionObj['isGlobalEngEngagementDetailsOfferingEnable']) {
          this.isofferingEditable = true;
        }
        break;
      case this.startDateConstant:
        if (
          this.permissionObj[
            'isGlobalEngEngagementDetailsEngagementStartDateEnable'
          ]
        ) {
          this.isstartDateEditable = true;
        }
        break;
      case this.endDateConstant:
        if (
          this.permissionObj[
            'isGlobalEngEngagementDetailsEngagementEndDateEnable'
          ]
        ) {
          this.isendDateEditable = true;
        }
        break;
      case this.opportunityConstant:
        if (
          this.permissionObj[
            'isGlobalEngEngagementDetailsJupiterOpportunityIdEnable'
          ]
        ) {
          this.isOpportunityEditable = true;
        }
        break;
    }
  }

  fieldCleared(): void {
    this.showDropdown = false;
    this.opportunityList = [];
  }

  fetchOpportunityDetails(item: OpportunityDetails, opp: string): void {
    this.selectedOportunity = item.opportunityNumber;
    this.isOpportunityEditable = false;
    this.postEngagementDetails();
  }

  selectOpportunityChanged(event: any): void {
    this.totalCount = 0;
    this.pageNumber = 1;
    if (event.length > 2 && event !== IntakeConstant.EMPTY_SPACE) {
      this.searchOpportunityQuery$.next(event);
    } else {
      this.notFound = this.emptyString;
      this.opportunityList = [];
      this.totalCount = 0;
      this.showDropdown = false;
    }
  }

  fetchOpportunitySearchResult(pageNumber: number, tabCode: string): void {
    this.isSpinnerVisible = true;
    this.submissionService
      .getOpportunityDetails(
        this.selectedOportunity,
        pageNumber,
        this.pageSize,
        tabCode
      )
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (opportunity: any) => {
          if (opportunity?.totalOpportunityCount > 0) {
            this.notFound = this.emptyString;
            if (pageNumber === 1) {
              this.opportunityList = opportunity?.opportunities;
            } else {
              this.opportunityList = [
                ...this.opportunityList,
                ...opportunity?.opportunities,
              ];
            }
            this.totalCount = opportunity?.totalOpportunityCount;
            this.tabs[0].count = opportunity?.activeOpportunityCount;
            this.tabs[1].count = opportunity?.totalOpportunityCount;
            if (opportunity.activeOpportunityCount == 0) {
              if (this.selectTabIndex == 0) {
                this.selectTabIndex = 1;
                this.tabChanged(this.tabs[1].code);
              }
            } else {
              if (this.selectTabIndex == 1) {
                this.selectTabIndex = 0;
                this.tabChanged(this.tabs[0].code);
              }
            }
            if (this.opportunityList.length > 0) this.showDropdown = true;
          } else {
            this.opportunityList = [];
            this.totalCount = 0;
            this.showDropdown = false;
          }
          this.isSpinnerVisible = false;
        },
        error: (err: HttpErrorResponse) => {
          this.isSpinnerVisible = false;
          console.error('Error fetching submission types', err);
        },
      });
  }

  fetchOpportunitySearchResultMore(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      if (this.pageNumber < this.totalCount / this.pageSize) {
        this.pageNumber++;
        this.fetchOpportunitySearchResult(
          this.pageNumber,
          this.selectedTabCode
        );
      }
    }
  }

  formatToAmericanDate(dateStr: string | null): Date | null {
    if (!dateStr) return null;
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) return null;
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return new Date(`${month}/${day}/${year}`);
  }

  startDateChanged(): void {
    setTimeout(() => {
      this.startDate = String(this.tempStartDate?.toLocaleDateString());
      this.validEndDate = new Date(this.startDate);
      this.postEngagementDetails();
    }, 500);
    this.isstartDateEditable = false;
  }

  endDateChanged(): void {
    setTimeout(() => {
      this.endDate = String(this.tempEndDate?.toLocaleDateString());
      this.validStartDate = new Date(this.endDate);
      this.postEngagementDetails();
    }, 500);
    this.isendDateEditable = false;
  }

  formatDate(dateStr: string | null | undefined): string {
    if (!dateStr) {
      return this.emptyString;
    }
    const formattedDate = this.datePipe.transform(
      dateStr,
      GlobalEngagementsConstant.DATE_FORMAT
    );
    return formattedDate ?? this.emptyString;
  }

  formatDateForPost(dateStr: string | null | undefined): string | null {
    if (!dateStr) {
      return null;
    }
    const parts = dateStr.split('/');
    if (parts.length !== this.SEARCH_LIMIT) return null;
    const [month, day, year] = parts.map(Number);
    const date = new Date(year, month - 1, day);
    return this.datePipe.transform(date, "yyyy-MM-dd'T'HH:mm:ss") ?? null;
  }

  pprChanged(): void {
    this.isPprNotAvailable = !this.isPprNotAvailable;
    this.prrId = null;
    this.postEngagementDetails();
  }

  pprStatus(): boolean {
    if (this.prrId) {
      return false;
    } else if (this.isPprNotAvailable) {
      return false;
    } else {
      return true;
    }
  }

  tabChanged(event: string): void {
    this.selectedTabCode = event;
    this.fetchOpportunitySearchResult(1, this.selectedTabCode);
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
