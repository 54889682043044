<div class="display-save-container">
    <div class="display-save" [ngSwitch]="isSaving">
        <div *ngSwitchCase="true">
            <div class="label-loader">
                Auto-saving
            </div>

            <img src="{{url}}" />
        </div>
        <div *ngSwitchCase="false" class="timestamp"
             [hidden]="!module?.savedTimeStamp">
            <!-- In angularJs we have no comma after M/d/yy 
                'short': equivalent to 'M/d/yy, h:mm a' (6/15/15, 9:03 AM).-->
            Last Save: {{module?.savedTimeStamp | date: 'M/d/yy h:mm a'}}
        </div>
    </div>
</div>