import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import {
  InitiateNavigation,
  tabContainerOptions,
  tabOptions1,
  tabOptions2,
} from '../opportunity-summary-settings';
import { SubmissionService } from '../../../http/intake/submission.service';
import { forkJoin, map, Subject, takeUntil } from 'rxjs';
import {
  FeeType,
  OpportunityDetailsEdit,
} from '../../../common/models/opportunityDetailsEdit';
import { MultiSelectItem } from '@usitsdasdesign/dds-ng/multi-select';
import { DatePipe } from '@angular/common';
import { IntakeConstant } from '../../constants/intake.constant';
import { TabOptions, TabsOptions } from '@usitsdasdesign/dds-ng/tabs';
import {
  groupToolTipOptions,
  opportunityComplexityOptionsOriginal,
  productAndToolsOptionsOriginal,
  sensitiveDataOptionsOriginal,
  serviceInvolvementOptions,
} from '../../opportunity-details/complexity-questions/complexity-questions-setting';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ServiceDeliveryComponent } from '../../opportunity-details/oportunity-detail-right-panel/service-delivery/service-delivery.component';
import { OpportunityDetailsNavModel } from '../../../common/models/opportunity-details.model';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SummaryArchivePopupComponent } from './summary-archive-popup/summary-archive-popup.component';
import { StatusEnum } from '../../../common/constants/status-enum';
import {
  ArchiveDetailsModel,
  PreviouslyConnectedArchiveCountResponse,
} from '../../../common/models/archive-details.model';
import { ArchiveClosedModel } from '../../../common/models/archive-save.model';
import { AdobeAnalyticsService } from '../../../common/services/adobe-analytics.service';
import { PermissionCheckService } from '../../../common/validations/permission-check.service';
import { BrowserTabConstant } from '../../../common/constants/browser-tab-constant';
import { HttpErrorResponse } from '@angular/common/http';
import { PermissionsObj } from '../../../common/models/common-models';
import { EclipseHeaderService } from '../../../http/eclipse-header.service';
import { EntityType, UserService } from '../../../http/user.service';
import { CommonService } from '../../../http/intake/common.service';

@Component({
  selector: 'app-opportunity-summary',
  templateUrl: './opportunity-summary.component.html',
  styleUrl: './opportunity-summary.component.less',
  providers: [DatePipe],
})
export class OpportunitySummaryComponent {
  opportunityDetailTitle: string | null = IntakeConstant.EMPTY_SPACE;
  opportunityNumber: string = IntakeConstant.EMPTY_SPACE;
  monthDuration: number = 0;
  tabContainerOptions: TabsOptions = tabContainerOptions;
  tabOptions1: TabOptions = tabOptions1;
  tabOptions2: TabOptions = tabOptions2;
  unsubscriber$ = new Subject();
  oportunityDetailsInfo: OpportunityDetailsEdit = {
    opportunityStatusName: IntakeConstant.EMPTY_SPACE,
    opportunityName: IntakeConstant.EMPTY_SPACE,
    opportunityNumber: IntakeConstant.EMPTY_SPACE,
    crossBusinessOpportunity: IntakeConstant.EMPTY_SPACE,
    projectStartDate: IntakeConstant.EMPTY_SPACE,
    projectEndDate: IntakeConstant.EMPTY_SPACE,
    leadEngagementPartner: IntakeConstant.EMPTY_SPACE,
    totalFees: IntakeConstant.EMPTY_SPACE,
    feeType: [],
    clientNumber: IntakeConstant.EMPTY_SPACE,
    opportunityDescription: IntakeConstant.EMPTY_SPACE,
  };
  startDate: string | null = IntakeConstant.EMPTY_SPACE;
  endDate: string | null = IntakeConstant.EMPTY_SPACE;
  feeTypeList: MultiSelectItem[] = [];
  feeTypeLabel: string = IntakeConstant.FEE_TYPE_LABEL;
  defaultForEmptyFields: string = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
  durationLabel: string = IntakeConstant.DURATION_LABEL;
  businessLabel: string = IntakeConstant.CROSS_BUSINESS_LABEL;
  descriptionLabel: string = IntakeConstant.DESCRIPTION_LABEL;
  archiveLabel: string = IntakeConstant.ARCHIVE_LABEL;
  documentSubmissionLabel: string = IntakeConstant.DOCUMENT_SUBMISSION_LABEL;
  independenceLabel: string = IntakeConstant.INDEPENDENCE_LABEL;
  teamLabel: string = IntakeConstant.TEAM_LABEL;
  clientLabel: string = IntakeConstant.CLIENT_LABEL;
  dateFormat: string = IntakeConstant.DATE_FORMAT;
  clientNumber: string = IntakeConstant.EMPTY_SPACE;
  greenLinedButton: ButtonOptions = InitiateNavigation;
  opportunityStatusName: string = IntakeConstant.EMPTY_SPACE;

  opportunityComplexityLabel: string =
    IntakeConstant.OPPORTUNITY_COMPLEXITY_LABEL;
  productAndToolsLabel: string = IntakeConstant.PRODUCTS_AND_TOOLS_LABEL;
  thirdPartyLabel: string = IntakeConstant.THIRD_PARTY_LABEL;
  sensitiveDataLabel: string = IntakeConstant.SENSITIVE_DATA_LABEL;
  dataInDEloitteLabel: string = IntakeConstant.DATA_INDELOITTE_LABEL;
  memberFirmInvolvementLabel: string =
    IntakeConstant.MEMBER_FIRM_INVOLVEMENT_LABEL;
  useOfExistingLabel: string = IntakeConstant.USE_OF_EXISTING_LABEL;
  deloitteSowLabel: string = IntakeConstant.DELOITTE_SOW_LABEL;
  opportunityComplexityOptionsOriginal = opportunityComplexityOptionsOriginal;
  regulatoryConsiderationTooltip =
    'Identify regulatory considerations such as HIPAA, GDPR, ADA, Export Controls';
  groupToolTipOptions = groupToolTipOptions;
  viewAndEditLabel: string = IntakeConstant.VIEW_EDIT_LABEL;
  productAndToolsOptionsOriginal = productAndToolsOptionsOriginal;
  selectedProductAndTools: string = IntakeConstant.EMPTY_SPACE;
  selectedOpportunityComplexity: string = IntakeConstant.EMPTY_SPACE;
  sensitiveDataOptionsOriginal = sensitiveDataOptionsOriginal;
  selectedSensitiveData: string = IntakeConstant.EMPTY_SPACE;
  thirdPartyInvolvement: string = IntakeConstant.EMPTY_SPACE;
  memberFirmInvolvement: string = IntakeConstant.EMPTY_SPACE;
  dataInDeloittesOrDeloittesHostedEnvironment: string =
    IntakeConstant.EMPTY_SPACE;
  useOfExistingMSAsOrContractMakerGBTs: string = IntakeConstant.EMPTY_SPACE;
  deloitteStandardELSOWTemplateReferred: string = IntakeConstant.EMPTY_SPACE;
  opportunityDescription: string = IntakeConstant.EMPTY_SPACE;
  memberFirmTooltip: string = IntakeConstant.EMPTY_SPACE;
  existingMSAsOrContractMakerGBTsTooltip: string = IntakeConstant.EMPTY_SPACE;
  regulatoryText: string = IntakeConstant.REGULATORY_CONSIDERATION;
  documentSubmissionList: OpportunityDetailsNavModel[] = [];
  NO: string = IntakeConstant.NO;
  YES: string = IntakeConstant.YES;
  containCyberQuestions: boolean = false;
  deloitteIsrael: string | null | undefined = IntakeConstant.EMPTY_SPACE;
  servicesDisruptClient: string | null | undefined = IntakeConstant.EMPTY_SPACE;
  serviceInvolvement: string | null | undefined = IntakeConstant.EMPTY_SPACE;
  deloitteIsraelLabel: string = IntakeConstant.ISREAL_INVOLVEMENT;
  servicesDisruptClientLabel: string = IntakeConstant.POTENTIAL_CLIENT_LABEL;
  serviceInvolvementLabel: string = IntakeConstant.SERVICE_INVOLVEMENT;
  serviceInvolvementOptions = serviceInvolvementOptions;
  deloitteIsraelExplain: string = IntakeConstant.EMPTY_SPACE;
  hasERS230: string = IntakeConstant.EMPTY_SPACE;
  infraSecurityconsult: string = IntakeConstant.EMPTY_SPACE;
  archiveName: string = IntakeConstant.EMPTY_SPACE;
  independanceService: string = IntakeConstant.EMPTY_SPACE;
  independanceServiceDescription: string = IntakeConstant.EMPTY_SPACE;
  independanceServiceSapdgmfId: string = IntakeConstant.EMPTY_SPACE;
  archiveNumber: string = IntakeConstant.EMPTY_SPACE;
  archiveDescription: string = IntakeConstant.EMPTY_SPACE;
  archiveStatus: string = IntakeConstant.EMPTY_SPACE;
  partner: string = IntakeConstant.EMPTY_SPACE;
  previousConnectedArchiveCount: number = 0;
  previousConnectedArchiveLabel: string =
    IntakeConstant.ARCHIVE_CONNECTED_LABEL;
  wbsNumber: string = IntakeConstant.EMPTY_SPACE;
  archiveUrl: string = IntakeConstant.EMPTY_SPACE;
  swiftProjectName: string = IntakeConstant.EMPTY_SPACE;
  swiftStartDate: string = IntakeConstant.EMPTY_SPACE;
  swiftEndDate: string = IntakeConstant.EMPTY_SPACE;
  swiftPPMD: string = IntakeConstant.EMPTY_SPACE;
  opptyInvolvedInUS: string = IntakeConstant.EMPTY_SPACE;
  requiredConflictProcBylep: string = IntakeConstant.EMPTY_SPACE;
  requiredConflictProcedure: string = IntakeConstant.EMPTY_SPACE;
  resultofConflictCheck: string = IntakeConstant.EMPTY_SPACE;
  onFocusUrl: string = IntakeConstant.EMPTY_SPACE;
  jupiterURL: string = IntakeConstant.EMPTY_SPACE;
  public defaultEmptyFields: string = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
  permissionObj: PermissionsObj = {} as PermissionsObj;

  constructor(
    private readonly datePipe: DatePipe,
    private readonly intakeDetailService: SubmissionService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly modalService: ModalService,
    private readonly analytics: AdobeAnalyticsService,
    private readonly permissionCheck: PermissionCheckService,
    private readonly headerService: EclipseHeaderService,
    private readonly userService: UserService,
    private readonly commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.permissionObj = this.permissionCheck.getIntakePermissionsObj;
    this.commonService.enableAuditTrail(true);
    this.analytics.trackPage('Opportunity summary');
    this.route.queryParams.subscribe((params) => {
      this.opportunityNumber = params['id'];
      this.opportunityDetailTitle = params['title']; // This is getting replaced on getOpportunityDetails call
    });
    if (this.opportunityNumber) {
      this.getOpportunityDetailsValues(this.opportunityNumber);
      this.getOpportunityDetails(this.opportunityNumber);
      this.getDetailsValueFromOpportunity(this.opportunityNumber);
    }
    document.title =
      BrowserTabConstant.Browser_TabName_Default + this.opportunityNumber;
    this.getComplexityData();
    this.getNavDetails(this.opportunityNumber);
  }

  ngAfterViewInit(): void {
    this.headerService.updateStickyTopPosition();
    this.headerService.scrollToTop();
  }

  getComplexityData(): void {
    this.intakeDetailService
      .getComplexityResponse(this.opportunityNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        if (data) {
          this.selectedOpportunityComplexity = data?.opportunityComplexity
            ? data?.opportunityComplexity
            : IntakeConstant.EMPTY_SPACE;
          this.selectedProductAndTools = data?.productsAndTools
            ? data?.productsAndTools
            : IntakeConstant.EMPTY_SPACE;
          this.selectedSensitiveData = data?.sensitiveData
            ? data?.sensitiveData
            : IntakeConstant.EMPTY_SPACE;
          this.thirdPartyInvolvement = data?.thirdPartyInvolvement
            ? data?.thirdPartyInvolvement
            : IntakeConstant.EMPTY_SPACE;
          this.memberFirmInvolvement = data?.memberFirmInvolvement
            ? data?.memberFirmInvolvement
            : IntakeConstant.EMPTY_SPACE;
          this.dataInDeloittesOrDeloittesHostedEnvironment =
            data?.dataInDeloittesOrDeloittesHostedEnvironment
              ? data?.dataInDeloittesOrDeloittesHostedEnvironment
              : IntakeConstant.EMPTY_SPACE;
          this.deloitteStandardELSOWTemplateReferred =
            data?.deloitteStandardELSOWTemplateReferred
              ? data?.deloitteStandardELSOWTemplateReferred
              : IntakeConstant.EMPTY_SPACE;
          this.useOfExistingMSAsOrContractMakerGBTs =
            data?.useOfExistingMSAsOrContractMakerGBTs
              ? data?.useOfExistingMSAsOrContractMakerGBTs
              : IntakeConstant.EMPTY_SPACE;
          this.memberFirmTooltip =
            data?.memberFirmNames ?? IntakeConstant.EMPTY_SPACE;

          this.regulatoryConsiderationTooltip =
            data?.regulatoryConsiderations ?? IntakeConstant.EMPTY_SPACE;

          this.existingMSAsOrContractMakerGBTsTooltip =
            data?.generalBusinessTerms ?? IntakeConstant.EMPTY_SPACE;

          this.deloitteIsrael = data?.deloitteIsrael;

          this.servicesDisruptClient = data?.servicesDisruptClient;

          this.serviceInvolvement = data?.serviceInvolvement;

          this.deloitteIsraelExplain = String(data?.deloitteIsraelExplain);

          this.hasERS230 = String(data?.hasERS230);

          this.infraSecurityconsult = String(data?.infraSecurityconsult);
        }
      });
  }

  getOpportunityDetailsValues(id: string): void {
    forkJoin({
      base: this.intakeDetailService.getOpportunityDetailsInfo(id),
      updated: this.intakeDetailService.getOpportunityDetailForSummary(id),
    })
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: ({
          base,
          updated,
        }: {
          base: OpportunityDetailsEdit;
          updated: OpportunityDetailsEdit;
        }) => {
          this.oportunityDetailsInfo = {
            opportunityStatusName: base?.opportunityStatusName,
            opportunityName:
              updated?.opportunityName != null &&
              updated?.opportunityName !== IntakeConstant.EMPTY_SPACE
                ? updated?.opportunityName
                : base?.opportunityName,
            opportunityNumber:
              updated?.opportunityNumber != null &&
              updated?.opportunityNumber !== IntakeConstant.EMPTY_SPACE
                ? updated.opportunityNumber
                : base.opportunityNumber,
            crossBusinessOpportunity:
              updated.crossBusinessOpportunity != null &&
              updated.crossBusinessOpportunity !== IntakeConstant.EMPTY_SPACE
                ? updated.crossBusinessOpportunity
                : base.crossBusinessOpportunity,
            projectStartDate:
              updated.projectStartDate != null &&
              updated.projectStartDate !== IntakeConstant.EMPTY_SPACE
                ? updated.projectStartDate
                : base.projectStartDate,
            projectEndDate:
              updated.projectEndDate != null &&
              updated.projectEndDate !== IntakeConstant.EMPTY_SPACE
                ? updated.projectEndDate
                : base.projectEndDate,
            leadEngagementPartner:
              updated.leadEngagementPartner != null &&
              updated.leadEngagementPartner !== IntakeConstant.EMPTY_SPACE
                ? updated.leadEngagementPartner
                : base.leadEngagementPartner,
            totalFees:
              updated.totalFees != null &&
              updated.totalFees !== IntakeConstant.EMPTY_SPACE
                ? updated.totalFees
                : base.totalFees,
            feeType:
              updated?.feeType && updated?.feeType?.length > 0
                ? updated.feeType
                : base.feeType,
            clientNumber:
              updated.clientNumber != null &&
              updated.clientNumber !== IntakeConstant.EMPTY_SPACE
                ? updated.clientNumber
                : base.clientNumber,
            opportunityDescription:
              updated.opportunityDescription != null &&
              updated.opportunityDescription !== IntakeConstant.EMPTY_SPACE
                ? updated.opportunityDescription
                : base.opportunityDescription,
          };
          this.clientNumber = this.oportunityDetailsInfo.clientNumber;
          this.opportunityDetailTitle =
            this.oportunityDetailsInfo?.opportunityName;
          this.intakeDetailService.changeSubmissionId({
            opportunitynumber: id,
            submissionid: null,
          });
          if (
            this.oportunityDetailsInfo.projectStartDate != null &&
            this.oportunityDetailsInfo.projectStartDate !==
              IntakeConstant.EMPTY_SPACE
          ) {
            this.startDate = this.datePipe.transform(
              this.oportunityDetailsInfo.projectStartDate,
              this.dateFormat
            );
          } else {
            this.startDate = IntakeConstant.EMPTY_SPACE;
          }
          if (
            this.oportunityDetailsInfo.projectEndDate != null &&
            this.oportunityDetailsInfo.projectEndDate !==
              IntakeConstant.EMPTY_SPACE
          ) {
            this.endDate = this.datePipe.transform(
              this.oportunityDetailsInfo.projectEndDate,
              this.dateFormat
            );
          } else {
            this.endDate = IntakeConstant.EMPTY_SPACE;
          }
          if (this.startDate && this.endDate) {
            this.monthDuration =
              parseInt(this.endDate.split('/')[0]) -
              parseInt(this.startDate.split('/')[0]);
          } else {
            this.monthDuration = 0;
          }
          if (
            this.oportunityDetailsInfo.feeType &&
            this.oportunityDetailsInfo.feeType.length > 0
          ) {
            this.feeTypeList = this.oportunityDetailsInfo.feeType.map(
              (feeType: FeeType) => ({
                label: feeType.feeTypeName,
                value: true,
              })
            );
          }
          this.opptyInvolvedInUS =
            updated.opptyInvolvedInUS != null &&
            updated.opptyInvolvedInUS !== IntakeConstant.EMPTY_SPACE
              ? updated.opptyInvolvedInUS
              : base.opptyInvolvedInUS ?? IntakeConstant.EMPTY_SPACE;
          this.requiredConflictProcBylep =
            updated.requiredConflictProcBylep != null &&
            updated.requiredConflictProcBylep !== IntakeConstant.EMPTY_SPACE
              ? updated.requiredConflictProcBylep
              : base.requiredConflictProcBylep ?? IntakeConstant.EMPTY_SPACE;
          this.requiredConflictProcedure =
            updated.requiredConflictProcedure != null &&
            updated.requiredConflictProcedure !== IntakeConstant.EMPTY_SPACE
              ? updated.requiredConflictProcedure
              : base.requiredConflictProcedure ?? IntakeConstant.EMPTY_SPACE;
          this.resultofConflictCheck =
            updated.resultofConflictCheck != null &&
            updated.resultofConflictCheck !== IntakeConstant.EMPTY_SPACE
              ? updated.resultofConflictCheck
              : base.resultofConflictCheck ?? IntakeConstant.EMPTY_SPACE;
          this.onFocusUrl =
            updated.focusURL != null &&
            updated.focusURL !== IntakeConstant.EMPTY_SPACE
              ? updated.focusURL
              : base.focusURL ?? IntakeConstant.EMPTY_SPACE;
          this.jupiterURL =
            updated.jupiterURL != null &&
            updated.jupiterURL !== IntakeConstant.EMPTY_SPACE
              ? updated.jupiterURL
              : base.jupiterURL ?? IntakeConstant.EMPTY_SPACE;
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching opportunity details', err);
        },
      });
  }

  moveBackToEdit(): void {
    this.router.navigate(
      ['/submission/opportunity-details', this.opportunityNumber],
      {}
    );
  }

  openDocumentLinkModel(modalBtn?: any): void {
    this.modalService.open(ServiceDeliveryComponent, {
      documentInitiatedDetails: {
        opportunityNumber: this.opportunityNumber,
      },
    });
  }

  getNavDetails(id: string): void {
    this.intakeDetailService
      .getIntakeDetails(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: OpportunityDetailsNavModel[]) => {
          this.documentSubmissionList = data;
          this.updateRoleAndPermissions();
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  updateRoleAndPermissions() {
    const entityId: string | number =
      this.documentSubmissionList[0].submissionId ?? -1;
    const entityTypeId: number = EntityType.Submission;
    this.userService.setEntityDetails(entityId, entityTypeId);
  }

  makeGetCyberCall(): void {
    this.containCyberQuestions = true;
  }

  getDetailsValueFromOpportunity(id: string): void {
    this.intakeDetailService
      .getOpportunityDetailsInfo(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: OpportunityDetailsEdit) => {
          if (!data) {
            this.router.navigate(['/submission/opportunity-not-found']);
          }
          this.opportunityStatusName =
            data.opportunityStatusName ?? IntakeConstant.EMPTY_SPACE;
        },
        error: (err: HttpErrorResponse) => {
          this.router.navigate(['/submission/opportunity-not-found']);
          console.error('Error fetching submission', err);
        },
      });
  }

  getOpportunityDetails(id: string): void {
    this.intakeDetailService
      .getOpportunityDetailForSummary(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: OpportunityDetailsEdit) => {
          if (!data) {
            this.router.navigate(['/submission/opportunity-not-found']);
          }
          this.opportunityDescription = String(data?.opportunityDescription);
          if (!this.opportunityDescription) {
            this.opportunityDescription =
              IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
          }
          this.archiveName = data?.archiveName ?? IntakeConstant.EMPTY_SPACE;
          this.archiveNumber =
            data?.archiveNumber ?? IntakeConstant.EMPTY_SPACE;
          this.partner = data?.partner ?? IntakeConstant.EMPTY_SPACE;
          this.swiftProjectName =
            data?.swiftDetails?.projectName ?? IntakeConstant.EMPTY_SPACE;
          this.swiftStartDate =
            data?.swiftDetails?.projectStartDate ?? IntakeConstant.EMPTY_SPACE;
          this.swiftEndDate =
            data?.swiftDetails?.projectEndDate ?? IntakeConstant.EMPTY_SPACE;
          this.swiftPPMD =
            data?.swiftDetails?.projectPPMD ?? IntakeConstant.EMPTY_SPACE;
          if (this.archiveNumber) {
            this.getArchiveStatus();
          }
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  receiveIndependenceData(data: {
    services: string;
    servicesDescription: string;
    sapdgmfId: string;
  }): void {
    this.independanceService = data.services;
    this.independanceServiceDescription = data.servicesDescription;
    this.independanceServiceSapdgmfId = data.sapdgmfId;
  }

  independanceDescClicked(): void {
    const independanceServiceLink =
      IntakeConstant.INDEPENDENCE_DESC_LINK + this.independanceServiceSapdgmfId;
    if (this.independanceServiceSapdgmfId)
      window.open(independanceServiceLink, '_blank');
  }

  navigateToInitiateSubmission(): void {
    this.router.navigate(['submission']);
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  openArchivePopup(): void {
    let archivepopUpRef = this.modalService.open(SummaryArchivePopupComponent, {
      dataOpportunityId: this.opportunityNumber,
      wbsChargeCode: this.wbsNumber,
      initialSelectedArchive: this.archiveNumber,
    });
    archivepopUpRef.onClosed().subscribe((response: void) => {
      this.getOpportunityDetails(this.opportunityNumber);
    });
  }

  getPreviouslyConnectedArchive(): void {
    this.intakeDetailService
      .getPreviouslyConnectedArchiveCount(this.opportunityNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: PreviouslyConnectedArchiveCountResponse) => {
          this.previousConnectedArchiveCount =
            data?.previouslyConnectedArchiveCount ?? 0;
        },
      });
  }

  openArchiveUrl(): void {
    if (this.archiveUrl) {
      window.open(this.archiveUrl, '_blank');
    }
  }

  getArchiveStatus(): void {
    this.intakeDetailService
      .getArchiveDetails(this.archiveNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: ArchiveDetailsModel) => {
          this.archiveStatus =
            data?.archiveStatus ?? IntakeConstant.EMPTY_SPACE;
          this.archiveDescription =
            data?.archiveDescription ?? IntakeConstant.EMPTY_SPACE;
          this.archiveUrl = data?.archiveUrl ?? IntakeConstant.EMPTY_SPACE;
          this.wbsNumber = data?.wbsNumber ?? IntakeConstant.EMPTY_SPACE;
          if (data) {
            this.getPreviouslyConnectedArchive();
          }
        },
        error: (error) => {
          console.error('Error fetching archive details', error);
        },
      });
  }

  onNavigateToJupiterURL(): void {
    if (this.jupiterURL) {
      window.open(this.jupiterURL, '_blank');
    }
  }

  onFocusUrlClicked(): void {
    if (this.onFocusUrl) {
      window.open(this.onFocusUrl, '_blank');
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next(IntakeConstant.EMPTY_SPACE);
    this.unsubscriber$.complete();
  }
}
