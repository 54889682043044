<progress-indicator></progress-indicator>

<div class="tiles-container" *ngIf="!isLoading">
    <div class="page-header margin-bottom-l">
      <div class="d-flex justify-content-between align-items-center">
        <div class="title inline-block font-size-32">
          Third party agreements repository | 
          <span [ddsTooltip]="'This third-party agreement repository is managed by Risk Management. Agreements managed by other groups, such as Contingent Workforce Management (CWM), do not need to be uploaded here. The repository is searchable, and agreements that need future access can be uploaded here.'"
                      [tooltipInvokeType]="tpaTooltipOptions.tooltipInvokeType ?? 'hover'"
                      [tooltipType]="tpaTooltipOptions.tooltipType ?? ''"
                      [tooltipSize]="tpaTooltipOptions.tooltipSize ?? ''"
                      [tooltipPosition]="tpaTooltipOptions.tooltipPosition ?? ''"
                      [tooltipIndent]="tpaTooltipOptions.tooltipIndent ?? 0"
                      [tooltipHasBeak]="tpaTooltipOptions.tooltipHasBeak ?? false"
                      [tooltipTheme]="tpaTooltipOptions.tooltipTheme ?? ''"
                      ddsFocus
                      [tooltipMaxWidth]="tpaTooltipOptions.tooltipMaxWidth ?? 0">
                      <strong><u>Overview</u></strong>
        </span>
        </div>
        <div class="button-section d-flex align-items-center text-right">
  
          <span class="clear-filters"
                [ngClass]="{'padding-right-s': isExportButtonVisible}"
                (click)="clearAllFilter()">Clear filters</span>
  
          <dds-button class="padding-right-s" [theme]="addagreementoptions.theme ?? ''" (click)="addNewAgreement()" *ngIf="isThirdPartyAgreementRepositoryVisible"
                      [kind]="addagreementoptions.kind ?? ''"
                      [size]="addagreementoptions.size ?? ''"
                      [width]="addagreementoptions.width ?? ''">Add agreement</dds-button>
  
          <dds-button [theme]="exportbuttonoptions.theme ?? ''"
                      [kind]="exportbuttonoptions.kind ?? ''"
                      [size]="exportbuttonoptions.size ?? ''"
                      [icon]="exportbuttonoptions.icon ?? ''"
                      *ngIf="isExportButtonVisible"
                      (click)="downloadThirdPartyAgreementReport()"></dds-button>
        </div>
      </div>
    </div>
  
    <div>
  
      <table class="dds-data-table">
        <thead class="dds-data-table__header">
          <tr>
            <th *ngFor="let column of defaultGridColumns"
                class="dds-data-table__header-cell dds-data-table__header-cell_sorting"
                [ddsSticker]="customFilter"
                [ngClass]="{
            'dds-data-table__header-cell_sorted': sortingState.property === column.name,
            'dds-data-table__header-cell_filter-shown': isShownFilter && column.name === filteringColumn.name
          }"
                [stickerPosition]="filterStickerOptions.stickerPosition ?? ''"
                [stickerIsOutsideClick]="filterStickerOptions.stickerIsOutsideClick ?? false"
                [stickerIndent]="filterStickerOptions.stickerIndent ?? 0"
                [stickerWidth]="filterStickerOptions.stickerWidth ?? 0"
                [attr.aria-expanded]="isShownFilter"
                aria-haspopup="listbox"
                (click)="toggleFilter(column)"
                (onHidden)="filterOnHidden()"
                #stickerDir="dds-sticker"
                [ngStyle]="{width: column.width, minWidth: column.minWidth }">
              <div *ngIf="column.name" class="dds-data-table__content">
                <span class="padding-right-xxs">{{column.header || column.name}}</span>
                <span class="icon-expand-grey"></span>
                <span *ngIf="sortingState.property === column.name"
                      class="dds-icon dds-data-table__icon"
                      [ngClass]="sortingState.ascending ? 'dds-icon_sort-up': 'dds-icon_sort-down' ">
                </span>
                <span *ngIf="column.filterable == true">
                  <span class="dds-icon dds-icon_filter"></span>
                </span>
              </div>
            </th>
          </tr>
        </thead>
  
        <tbody class="dds-data-table__body">
          <ng-container *ngFor="let row of model">
            <tr class="dds-data-table__row" (click)="onRowClick(row, $event, row['sourceid'])">
              <td *ngFor="let column of defaultGridColumns" class="dds-data-table__cell"
                  [attr.data-column-name]="column.name"
                  [ngStyle]="{width: column.width, minWidth: column.minWidth }"
                  [ddsTooltip]="'Open third party agreement details'"
                  [tooltipType]="agreementTooltipOptions.tooltipType ?? ''"
                  [tooltipSize]="agreementTooltipOptions.tooltipSize ?? ''"
                  [tooltipPosition]="agreementTooltipOptions.tooltipPosition ?? ''"
                  [tooltipIndent]="agreementTooltipOptions.tooltipIndent ?? 0"
                  [tooltipHasBeak]="agreementTooltipOptions.tooltipHasBeak ?? false"
                  [tooltipIsDisabled]="!isAddNewAgreementButtonVisible"
                  [tooltipTheme]="agreementTooltipOptions.tooltipTheme ?? ''"
                  ddsFocus
                  [tooltipMaxWidth]="agreementTooltipOptions.tooltipMaxWidth ?? 0">
                
                
                <div *ngIf="column.name === 'sourceId'">
                  <span>{{ row[column.name] ? row[column.name] : '-' }}</span>
                </div>

                <div *ngIf="column.name === 'thirdPartyName'">
                  <span>{{ row[column.name] ? row[column.name] : '-' }}</span>
                </div>

                <!--TODO : add redirection logic once agreement details page is added-->
                <div *ngIf="column.name == 'agreementName'" class="agreement-name-container" (click)="gotoAgreement('')">
                  <a class="agreement-link padding-bottom-xxs">
                    {{ row[column.name] ? row[column.name] : '-' }}
                  </a>
                </div>

                <div *ngIf="column.name === 'agreementType'">
                  <span>{{ row[column.name] ? row[column.name] : '-' }}</span>
                </div>

                <div *ngIf="column.name === 'effectiveDate'">
                  <span>{{ row[column.name] ? row[column.name] : '-' }}</span>
                </div>

                <div *ngIf="column.name === 'terminationDate'">
                  <span>{{ row[column.name] ? row[column.name] : '-' }}</span>
                </div>

                <div *ngIf="column.name === 'autoRenew'">
                  <span>{{ row[column.name] ? row[column.name] : '-' }}</span>
                </div>

                <div *ngIf="column.name === 'agreementStatus'">
                  <span [ngClass]="{
                        'active-container': row['agreementStatus'] === 'Active',
                        'inactive-container': row['agreementStatus'] !== 'Active'}">
                    {{ row[column.name] ? row[column.name] : '-' }}
                  </span>
                </div>

                <div *ngIf="column.name === ''"
                  (click)="stopEventPropagation($event)">
                  <span class="expand-collapse-icon"
                    [ngStyle]="getAccordianStyles()"
                    (click)="itemExpand(row, $event)">
                    <div 
                      [ngClass]="row.expanded ? 'icon-collapse-black' : 'icon-expand-black'"
                      class="icon-width">
                    </div>
                  </span>
                </div>
              </td>
            </tr>
            <tr *ngIf="row.expanded" class="second-block">
              <ng-container>
                <td [attr.colspan]="defaultGridColumns.length + 1">
                  <div class="opp-grid">
                    <p class="text-label">Documents</p>
                    <div class="item-info-container">   
                      <table *ngIf="row.documentList && row.documentList.length > 0" class="dds-data-table">
                        <thead class="dds-data-table__header margin-top-s">
                         <tr>
                          <th *ngFor="let column of documentGridColumns"
                              class="dds-data-table__header-cell"
                              [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
                              ddsFocus
                              tabindex="0">
                            <div class="dds-data-table__content">
                              <span>{{column.header || column.name}}</span>
                              <div *ngIf="column.name === 'effectiveDate' || column.name === 'terminationDate' || column.name === 'modifiedDate'" class="sort-icons-container">
                                <span
                                  class="dds-icon dds-data-table__icon"
                                  aria-label="sort"
                                  [ngClass]="{
                                    'dds-icon_sort-down': documentSortingState.property !== column.name || (documentSortingState.property === column.name && !documentSortingState.ascending),
                                    'dds-icon_sort-up': documentSortingState.property === column.name && documentSortingState.ascending,
                                    'dds-icon_sort-active': documentSortingState.property === column.name,
                                    'dds-icon_sort-disabled': documentSortingState.property !== column.name
                                  }"
                                  (click)="sortColumn(column, $event, row)">
                                </span>
                              </div>
                            </div>
                          </th>
                         </tr>
                        </thead>
                        <tbody class="dds-data-table__body">
                          <ng-container *ngFor="let row of row.documentList">
                            <tr [ngClass]="{'dds-data-table__row': true }">     
                            <td *ngFor="let column of documentGridColumns" class="dds-data-table__cell" [ngStyle]="{ width: column.width, minWidth: column.minWidth }">
                              <!--TODO : add redirection logic once documents section is added-->
                              <div *ngIf="column.name == 'documentTitle'" class="agreement-name-container">
                                <div class="dds-card__image-block txt-Style"></div>
                                <a class="agreement-link padding-bottom-xxs" (click)="onDownload(row)">
                                  {{ row[column.name] ? row[column.name] : '-' }}
                                </a>
                              </div>
                              <div *ngIf="column.name != 'documentTitle'" >
                                <a  class="agreement-link padding-bottom-xxs" >
                                  {{ row[column.name] ? row[column.name] : '-' }}
                                </a>
                              </div>
                            </td>
                          </tr>
                          </ng-container>  
                        </tbody>
                      </table>  
                      <div *ngIf="(!row.documentList || row.documentList.length == 0)">
                        <div>
                            <span>No related documents available</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <ng-template #customFilter>
      <dds-filter 
                  [items]="filterData"
                  [theme]="filterOptions.theme ?? ''"
                  [template]="customFilterTemp"
                  [propName]="filterOptions.propName ?? ''" #ddsFilter></dds-filter>
    </ng-template>

    <ng-template #customFilterTemp let-ctx="ctx">
  
      <div>
        <span>
          <span class="dds-filter__title dds-filter__section">Sorting</span>
          <div class="dds-filter__container dds-filter__container_sorting">
            <button class="dds-filter__item dds-filter__section dds-filter__section_sorting" (click)="filterSorted(true)">
              <span class="dds-filter__icon dds-icon dds-icon_sort-up"></span>
              <span class="dds-filter__text">Sort A–Z</span>
            </button>
            <button class="dds-filter__item dds-filter__section dds-filter__section_sorting" (click)="filterSorted(false)">
              <span class="dds-filter__icon dds-icon dds-icon_sort-down"></span>
              <span class="dds-filter__text">Sort Z–A</span>
            </button>
          </div>
        </span>
        <span class="dds-filter__title dds-filter__section">Filter</span>
        <dds-search *ngIf="filteringColumn.dataType !== 'date'
                           && filteringColumn.dataType !== 'int' && filteringColumn.name !== 'autoRenew' && filteringColumn.name !== 'agreementStatus'" 
                    class="dds-filter__search dds-filter__section"
                    [isInverse]="ctx.isInverse"
                    (keydown)="ctx.tabOut(searchField, $event)"
                    [placeholder]="'Search'"
                    [(ngModel)]="filteringColumn.searchValue"
                    #searchField></dds-search>
        
        <dds-daterangepicker
        class="dds-filter__section"
        *ngIf="filteringColumn.dataType === 'date'"
        [size]="dateRangePickerOptions.size ?? ''"
        [placeholder]="dateRangePickerOptions.placeholder ?? []"
        [format]="dateRangePickerOptions.format ?? ''"
        [isManualInput]="dateRangePickerOptions.isManualInput ?? false"
        [stickerPosition]="dateRangePickerOptions.stickerPosition ?? ''"
        [(ngModel)]="filteringColumn.searchValue"></dds-daterangepicker>
      </div>

      <div class="dds-filter__container dds-filter__container_checkbox" *ngIf="filteringColumn.name==='autoRenew'"
            #wrap>
        <dds-checkbox class="dds-filter__section dds-filter__section_checkbox"
                      [label]="ctx.allBtnLabel"
                      [isIndeterminate]="!isAutoRenewAllFilterSelected"
                      [value]="isAutoRenewAllFilterSelected"
                      [theme]="ctx.theme"
                      (keydown)="ctx.tabOut(checkbox, $event);"
                      (stateChanged)="selectAllAutoRenewFilter($event)"
                      #checkbox></dds-checkbox>
        <dds-checkbox *ngFor="let filterItem of autoRenewFilterData; let index = index; trackBy: ctx.trackByFn;"
                      class="dds-filter__section dds-filter__section_checkbox"
                      [ngClass]="{ 'dds-filter__section_hidden': filterItem.hidden }"
                      [value]="filterItem['isChecked']"
                      [label]="filterItem[ctx.propName]"
                      [theme]="ctx.theme"
                      (keydown)="ctx.tabOut(checkbox, $event);"
                      (stateChanged)="selectAutoRenewFilterItem($event, filterItem)"
                      #checkbox></dds-checkbox>
      </div>

      
      <div class="dds-filter__container dds-filter__container_checkbox" *ngIf="filteringColumn.name==='agreementStatus'"
            #wrap>
        <dds-checkbox class="dds-filter__section dds-filter__section_checkbox"
                      [label]="ctx.allBtnLabel"
                      [isIndeterminate]="!isStatusAllFilterSelected"
                      [value]="isStatusAllFilterSelected"
                      [theme]="ctx.theme"
                      (keydown)="ctx.tabOut(checkbox, $event);"
                      (stateChanged)="selectAllStatusFilter($event)"
                      #checkbox></dds-checkbox>
        <dds-checkbox *ngFor="let filterItem of statusFilterData; let index = index; trackBy: ctx.trackByFn;"
                      class="dds-filter__section dds-filter__section_checkbox"
                      [ngClass]="{ 'dds-filter__section_hidden': filterItem.hidden }"
                      [value]="filterItem['isChecked']"
                      [label]="filterItem[ctx.propName]"
                      [theme]="ctx.theme"
                      (keydown)="ctx.tabOut(checkbox, $event);"
                      (stateChanged)="selectStatusFilterItem($event, filterItem)"
                      #checkbox></dds-checkbox>
      </div>
    
      <div class="row">
        <div class="col-5"></div>
        <div class="col-7">
          <div class="dds-filter__footer dds-filter__section dds-filter__footer--end" style="display: flex; justify-content: flex-end;">
            <dds-button width="full"
                        [kind]="ctx.cancelBtnOptions.kind"
                        [theme]="ctx.theme"
                        [isInverse]="ctx.isInverse"
                        class="dds-filter__btn dds-filter__item"
                        (clicked)="cancel(filteringColumn.searchValue)"
                        #cancelBtn>Cancel</dds-button>
            <dds-button width="full"
                        [theme]="ctx.theme"
                        [isInverse]="ctx.isInverse"
                        class="dds-filter__btn dds-filter__item"
                        (clicked)="filterApply(filteringColumn.searchValue)"
                        (keydown)="ctx.tabOut(lastBtn, $event)"
                        [disabled]="!isApplyButtonEnabled()"
                        #lastBtn>Apply</dds-button>
          </div>
        </div>
  
      </div>
  
    </ng-template>
  
    <div class="image-section" *ngIf="model && model.length === 0">
      <div class="empty-state-container">
        <img src="assets/images/no_watchlist_clients.svg" alt="empty-state" class="empty-state-image">
        <div class="empty-state-text">There are no agreements to display</div>
      </div>
    </div>
  
    <ng-template #noFilter></ng-template>
  </div>


<div *ngIf="model && model.length > 0">
    <app-new-pagination-and-grid [tileCountforPage]="this.countforPagination" [pageUI]="this.gotoPage+1" (pageChanged)="onPageChanged($event)" (valueChanged)="onValueChanged($event)"></app-new-pagination-and-grid>
  </div>
