import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpportunityComponent } from './opportunity/opportunity.component';
import { SubmissionComponent } from './submission/submission.component';
import { TopNavigationalPanelComponent } from './top-navigational-panel/top-navigational-panel.component';
import { LeftNavigationalPanelComponent } from './left-navigational-panel/left-navigational-panel.component';
import { RouterModule } from '@angular/router';
import { PROFILE_ROUTES } from './profile.states';



@NgModule({
  declarations: [
    OpportunityComponent,
    SubmissionComponent,
    TopNavigationalPanelComponent,
    LeftNavigationalPanelComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(PROFILE_ROUTES)
  ]
})
export class ProfileModule { }
