import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-initiate-review',
  templateUrl: './initiate-review.component.html',
  styleUrl: './initiate-review.component.less'
})
export class InitiateReviewComponent {
constructor(private router:Router){

}

submit() {
this.router.navigate(['profile/opportunity/2123456']);//TODO: pass opportunityID 
}
}
