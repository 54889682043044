import { ButtonKind, ExtThemes, Themes, WidthState } from "@usitsdasdesign/dds-ng/shared";

export const primaryButton = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  width: WidthState.fixed,
  isLoading: false,
  icon: '',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: true,
  customClass: '',
  role: 'button',
};

export const docTableColumns = [
  {name: 'Moved to Archive It', header: 'Moved to Archive It', dataType: 'string'},
  { name: 'Title', header: 'Title', dataType: 'string' },
  { name: '', header: '', dataType: 'string' },
  { name: 'Type', header: 'Type', dataType: 'string' },
  {
    name: 'Status',
    header: 'Status',
    dataType: 'string',
  },
  { name: 'Uploaded Date', header: 'Uploaded date', dataType: 'string' },
  { name: 'Uploaded By', header: 'Uploaded by', dataType: 'string' },
  { name: 'Actions', header: 'Actions', dataType: 'string' },
];

export const documentTitleTooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'top',
  tooltipIndent: 15,
  tooltipIsOutsideClick: false,
  tooltipHasBeak: true,
  tooltipIsDisabled: false,
  tooltipIsInverse: false,
  tooltipIsDynamic: false,
  tooltipCustomClass: '',
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipShowDelay: 0,
  tooltipHideDelay: 0,
  tooltipTheme: Themes.dark,
  tooltipMaxWidth: 300,
};