<div class="dds-modal-overlay" (click)="closePopUp()"></div>

<div
  ddsResize
  [ngClass]="{
    'dds-modal_lg': size === 'lg',
    'dds-modal_inverse': isInverse
  }"
  class="dds-modal"
>
  <div class="dds-modal__header">
    <span class="dds-modal__title">Discontinue submission</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="closePopUp()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div class="body-section">
      <div class="sub-text-section d-flex margin-top-xs relative">
        <span class="icon-s inline-block relative icon-pinned"></span>
        <span>
          <div class="eclipse-header-grey">
            Discontinuing this submission will update its status to Discontinued and make it inactive.
          </div>
          <div
            class="vertical-line-green absolute"
            *ngIf="isShowMoreDocumentNotes"
          ></div>
        </span>
      </div>
      <div class="padding-left-m">
        <span>
          <div class="margin-top-xs text-gray" *ngIf="isShowMoreDocumentNotes">
            <div class="eclipse-content-grey">
             {{ configLableDescription }}
            </div>
          </div>
        </span>
        <dds-button
          class="showMoreCls"
          [theme]="showMoreOptions.theme ?? emptySpace"
          [kind]="showMoreOptions.kind ?? emptySpace"
          [size]="showMoreOptions.size ?? emptySpace"
          [width]="showMoreOptions.width ?? emptySpace"
          [icon]="showMoreOptions.icon ?? emptySpace"
          [isIconLeft]="showMoreOptions.isIconLeft ?? false"
          [role]="showMoreOptions.role ?? emptySpace"
          [ariaLabel]="showMoreText"
          [customClass]="showMoreOptions.customClass ?? emptySpace"
          (clicked)="clickToShowDocumentMoreLepMessage()"
          >{{ showMoreText }}</dds-button
        >
      </div>
      <div class="comment margin-top-xs">
        <span class="eclipse-small-header-grey">Reason</span>
        <dds-select
          [placeholder]="selectOptions.placeholder ?? emptySpace"
          [list]="actionDropdownList"
          [(ngModel)]="selectedReason"
        ></dds-select>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item custom-button"
        (clicked)="saveSubmissionHistory()"
        [disabled]="!formValid()"
        (keyboardClicked)="saveSubmissionHistory()"
        [ariaLabel]="'Additional close'"
        >Confirm</dds-button
      >
      <dds-button
        class="dds-modal__footer-item custom-button"
        kind="secondaryLoud"
        [isInverse]="isInverse"
        (clicked)="closePopUp()"
        (keyboardClicked)="closePopUp()"
        [ariaLabel]="'Cancel'"
        >Cancel</dds-button
      >
    </div>
  </div>
</div>