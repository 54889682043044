import { UploaderOptions, UploadFile, UploadFileStatus } from '@usitsdasdesign/dds-ng/uploader';
import { ButtonKind, Column, ErrorState, ExtThemes, LabelPosition, PositionState, Size, Themes, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { DropdownOptions } from '@usitsdasdesign/dds-ng/dropdown';
import { ProgressOptions } from '@usitsdasdesign/dds-ng/progress';
import { SelectItemOptions, SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { DatepickerOptions, DatepickerStates } from '@usitsdasdesign/dds-ng/datepicker';
import { CheckboxOptions } from '@usitsdasdesign/dds-ng/checkbox';
import { RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import { DescriptionType, InputOptions, TextAlign } from '@usitsdasdesign/dds-ng/input';

export const uploadAreaOptions: UploaderOptions = {
  heading: '',
  dragAndDropText: 'Drag and drop file(s)',
  description: 'DOCX, PPTX, PDF, JPG, PNG or ZIP. Max size of 50MB.',
  isDragAndDrop: true,
  isMultiple: true,
  acceptFormats: '',
  acceptExtensions: '',
  restrictedFormats: '',
  restrictedExtensions: '',
  exceptSymbols: '',
  btnLabel: 'Select file(s)',
  chooseFileBtnKind: 'primary',
  isDownloadable: false,
  allowFilesWithoutType: false,
  disabled: false,
  isInverse: false
};

export const uploadAreaOptions1: UploaderOptions = {
  heading: '',
  dragAndDropText: 'Drag and drop file(s)',
  description: 'DOCX, PPTX, PDF, JPG, PNG or ZIP. Max size of 50MB.',
  isDragAndDrop: true,
  isMultiple: true,
  acceptFormats: '',
  acceptExtensions: '',
  restrictedFormats: '',
  restrictedExtensions: '',
  exceptSymbols: '',
  btnLabel: 'Select file(s)',
  chooseFileBtnKind: 'primary',
  isDownloadable: false,
  allowFilesWithoutType: false,
  disabled: false,
  isInverse: false
};

export const textFileOptions: TextareaOptions = {
  placeholder: '',
  minHeight: 100,
  maxLength: 400,
  maxLengthPosition: 'bottom-right',
  size: Size.md
};
export const textareaOptions: TextareaOptions = {
  placeholder: 'Enter Comment',
  minHeight: 100,
  maxLength: 400,
  maxLengthPosition: 'bottom-right',
  size: Size.md
};
export const textfileNameOptions: TextareaOptions = {
  placeholder: 'Enter Filename',
  minHeight: 100,
  maxLength: 150,
  maxLengthPosition: 'bottom-right',
  size: Size.lg
};
export const selectDocumentTypeOptions: SelectOptions = {
  label: '',
  labelPosition: LabelPosition.external,
  description: '',
  placeholder: 'Select  type',
  size: Size.md,
  disabled: false,
  isResponsive: false,
  isRequired: true,
  isInverse: false,
  customClass: '',
  stickerWidth: 0,
  stickerShift: 0,
  stickerMaxHeight: "",
  stickerIsDisabled: false,
  stickerPosition: "bottom-left",
  stickerIndent: 0,
  stickerCustomClass: "",
  stickerIsDynamic: true
};
export const options: ProgressOptions = {
  size: Size.md,
  theme: 'green',
  isInverse: false,
  label: '',
  description: '',
  isVertical: false,
};
export const DocumentColumns: Column[] = [
  { name: 'Title', header: 'Title', dataType: 'string' },
  { name: 'Document type', header: 'Document type', dataType: 'string' },
  { name: 'Comment(optional)', header: 'Comment (optional)', dataType: 'string' },
];
export const fileTooltipOptions: TooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'bottom-left',
  tooltipIndent: 1,
  tooltipHasBeak: true,
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipTheme: Themes.dark,
  tooltipMaxWidth: 300
};


export const datepickerOptions: DatepickerOptions = {
    size: Size.md,
    placeholder: 'Select date',
    description: '',
    format: 'MM/dd/yyyy',
    minMode: DatepickerStates.day,
    errorMessage: '',
    errorState: ErrorState.default,
    isManualInput: false,
    isRequired: true,
    isInverse: false,
    customClass: '',
    inputByFormatMask: false,
    stickerPosition: PositionState.bottom,
    stickerIndent: 0,
    stickerShift: 0,
    stickerMaxHeight: '',
    stickerCustomClass: '',
    stickerIsDisabled: false,
    stickerIsDynamic: false,
    isResponsive: true,
    disabled: false,
    readonly: false,
    minDate: new Date(),
  }

  export const checkboxOptions: CheckboxOptions = {
    label: '',
    theme: Themes.green,
    isIndeterminate: false,
    isError: false,
    isRequired: false,
    errorState: ErrorState.default,
    ariaLabel: '',
    customClass: 'no-expiarton-date'
  };

  export const radioGroupOptions: RadioGroupOptions = {
    name: '',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    isRequired: false,
    isInverse: false,
  };

  export const inputOptions: InputOptions = {
      label: '',
      labelPosition: LabelPosition.external,
      placeholder: 'Placeholder',
      isError: false,
      isRequired: false,
      isInverse: false,
      // isInverse: false,
      size: Size.md,
      ariaLabel: '',
      description: '',
      descriptionType: DescriptionType.text,
      customClass: '',
      maxLength: 100,
      maxLengthPosition: 'bottom',
      errorMessage: '',
      errorState: ErrorState.default,
      textAlign: TextAlign.left,
      type: 'text',
      accept: undefined,
      alt: undefined,
      autocomplete: undefined,
      autofocus: undefined,
      list: undefined,
      max: undefined,
      min: undefined,
      minlength: 10,
      multiple: false,
      name: 'filename',
      pattern: undefined,
      readonly: false,
      src: undefined,
      step: undefined,
      width: undefined,
      mask: '',
      prefix: '',
      suffix: '',
      thousandSeparator: ' ',
      decimalMarker: '.',
      allowNegativeNumbers: false,
      dropSpecialCharacters: false,
      showMaskTyped: false,
      tooltipIcon: 'dds-icon_info',
      tooltipType: 'regular',
      tooltipSize: 'md',
      tooltipPosition: 'right',
      tooltipIndent: 20,
      tooltipShift: 0,
      tooltipHasBeak: true,
      tooltipIsDisabled: false,
      tooltipCustomClass: '',
      tooltipShowDelay: 0,
      tooltipHideDelay: 0,
      tooltipTheme: 'dark'
    };
