<div class="d-flex justify-content-center align-items-center image-section margin-top-l">
  <div>
    <img
      src="assets/images/error-page-image.svg"
      alt="empty-state"
      class="empty-state-image"
    />
    <div class="margin-top-l margin-bottom-l empty-state-text">{{errorMessage}}</div>
    <dds-button
      [theme]="returnToHomePageButtonoptions.theme ?? ''"
      [kind]="returnToHomePageButtonoptions.kind ?? ''"
      [size]="returnToHomePageButtonoptions.size ?? ''"
      [width]="returnToHomePageButtonoptions.width ?? ''"
      (clicked)="returnToHomePage()"
    >
      Return to homepage
    </dds-button>
  </div>
</div>