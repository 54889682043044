import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MultiSelectItem, MultiSelectOptions } from '@usitsdasdesign/dds-ng/multi-select';
import { AdminConstant } from '../../constants/admin-constants';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';

@Component({
  selector: 'app-admin-nested-multi-select',
  templateUrl: './admin-nested-multi-select.component.html',
  styleUrl: './admin-nested-multi-select.component.less',
})
export class AdminNestedMultiSelectComponent implements OnInit, OnChanges {

  // Input properties to receive data from parent components
  @Input() applicableFssItems: MultiSelectItem[] = [];
  @Input() applicableIndustryItems: MultiSelectItem[] = [];
  @Input() applicableIndustrySectorItems: MultiSelectItem[] = [];
  @Input() excludedIndustrySectorCodes: { [key: string]: string[] } = {};
  @Input() isEditMode: boolean = false;
  @Input() selectedEmpId: string | null = null;
  @Input() selectedFSSTypes: string[] = [];
  @Input() selectedIndustrySectorCodes: { [key: string]: string[] } = {};
  @Output() selectedFssCodesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() selectedIndustryCodesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() selectedSectorCodesChange: EventEmitter<any> = new EventEmitter<any>();

  // Multi-select options for FSS, Industry, and Sector
  fssMultiSelectOptions: MultiSelectOptions = { ...AdminConstant.MultiSelectOptions };
  industryMultiSelectOptions: MultiSelectOptions = { ...AdminConstant.MultiSelectOptions };
  sectorMultiSelectOptions: MultiSelectOptions = { ...AdminConstant.MultiSelectOptions };
  tagOptions: TagOptions = { ...AdminConstant.TagOptions };

  // Dropdown options for FSS, Industry, and Sector
  fssOptions: MultiSelectItem[] = [];
  industryOptions: { [key: string]: AdminNestedData[] } = {};
  sectorOptions: { [key: string]: AdminNestedData[] } = {};

  // State management for selected items
  selectedFSS: AdminNestedData[] = [];
  selectedIndustries: { [key: string]: AdminNestedData[] } = {};
  selectedSectors: { [key: string]: AdminNestedData[] } = {};

  // Lifecycle hook: Called once the component is initialized
  ngOnInit(): void { }

  // Lifecycle hook: Called whenever input properties change
  ngOnChanges(changes: SimpleChanges): void {
    // Initialize FSS options
    this.fssOptions = this.applicableFssItems;
    this.industryOptions = {};
    this.sectorOptions = {};

    // Populate selected FSS items based on input
    if (this.selectedEmpId && this.isEditMode && this.selectedFSSTypes.length > 0) {
      this.selectedFSS = this.applicableFssItems
        .filter((fss) => this.selectedFSSTypes.includes(fss['itemCode']))
        .map((fss) => ({
          label: fss.label,
          val: fss['itemCode'] || '',
          value: fss.value,
          itemId: fss['itemId'],
          itemCode: fss['itemCode']
        } as AdminNestedData));
    }

    // Process applicable FSS items
    this.applicableFssItems.map((item: any) => {
      const selectedIndustrySectorCodes = this.selectedIndustrySectorCodes[item.itemCode] || [];
      selectedIndustrySectorCodes.map((code) => {
        if (code) {
          const sectorCode = code;
          const industryCode = code.split('_')[0];
          if (this.selectedEmpId && this.isEditMode) {
            // Handle industry changes
            this.onIndustryChange(
              this.applicableIndustryItems
                .filter((industry) => industry['itemId'] === Number(industryCode))
                .map((industry) => ({
                  label: industry.label,
                  val: industry['itemId'] || '',
                  itemId: industry['itemId'],
                  itemCode: item.itemCode,
                  value: true
                } as AdminNestedData)),
              item.itemCode
            );

            // Handle sector changes
            this.onSectorChange(
              this.applicableIndustrySectorItems
                .filter((sector) => sector['itemCode'] === sectorCode)
                .map((sector) => ({
                  label: sector['heading'],
                  val: sector['itemCode'] || '',
                  itemId: sector['itemId'],
                  itemCode: sector['itemCode'],
                  value: sector.value
                } as AdminNestedData)),
              industryCode,
              item.itemCode
            );
          }
        }
      });

      // Process excluded industry sector codes
      const excludedIndustrySectorCodes = this.excludedIndustrySectorCodes[item.itemCode] || [];
      excludedIndustrySectorCodes.map((code) => {
        if (code) {
          const sectorCode = code;
          const industryCode = code.split('_')[0];
          if (this.selectedEmpId && this.isEditMode) {
            // Remove excluded sectors if not selected by user
            if (this.selectedSectors[industryCode]?.some((sector) => sector.val !== sectorCode)) {
              this.applicableIndustrySectorItems = this.applicableIndustrySectorItems.filter((sector) => sector['itemCode'] !== sectorCode);
            }

            // Remove excluded industries if not selected by user
            if (this.selectedIndustries[industryCode]?.some((ind) => Number(ind.val) !== Number(industryCode))) {
              this.applicableIndustryItems = this.applicableIndustryItems.filter((industry) => industry['itemId'] !== Number(industryCode));
            }
          } else {
            // Remove excluded sectors
            this.applicableIndustrySectorItems = this.applicableIndustrySectorItems.filter((sector) => sector['itemCode'] !== sectorCode);
            // Remove excluded industries
            this.applicableIndustryItems = this.applicableIndustryItems.filter((industry) => industry['itemId'] !== Number(industryCode));
          }
        }
      });

      // Populate industry options
      this.industryOptions[String(item.itemCode)] = this.applicableIndustryItems.map((industry: any) => ({
        label: industry.label,
        itemCode: item.itemCode,
        itemId: industry.itemId,
        val: industry.itemId || ''
      } as AdminNestedData));

      // Populate sector options for each industry
      this.industryOptions[String(item.itemCode)].map((industry: any) => {
        this.sectorOptions[String(industry.itemId)] = this.applicableIndustrySectorItems
          .filter((x) => Number(x.label) === industry.itemId)
          .map((sector: any) => ({
            label: sector.heading,
            itemId: industry.itemId,
            val: sector.itemCode || ''
          } as AdminNestedData));
      });
    });

    // Trigger FSS change handler
    this.onFSSChange(this.selectedFSS);
  }

  // Handler for FSS changes
  onFSSChange(selected: any) {
    this.selectedFSS = selected;
    this.emitFssChange();
    // Handle unselected FSS items
    const unselectedFSS = this.fssOptions.filter(
      (fss) => !this.selectedFSS.some((selected) => selected.itemCode === fss['itemCode'])
    );
    unselectedFSS.map((fss) => {
      if (fss.value) {
        const industries = this.selectedIndustries[fss['itemCode'] ?? ''];
        industries?.map((industry) => this.removeIndustry(fss['itemCode'] ?? '', industry.val));
      }
    });
  }

  // Handler for industry changes
  onIndustryChange(selected: any, fssVal: string) {
    this.selectedIndustries[fssVal] = selected;
    this.emitIndustryChange();
  }

  // Handler for sector changes
  onSectorChange(selected: any, industryVal: string, fssVal: string) {
    this.selectedSectors[industryVal] = selected;
    this.emitSectorChange();
  }

  // Remove FSS item
  removeFss(fssVal: string) {
    this.selectedFSS = this.selectedFSS.filter((fss) => fss.itemCode !== fssVal);
    this.selectedIndustries[fssVal]?.map((industry) => this.removeIndustry(fssVal, industry.val));
    this.emitFssChange();
  }

  // Remove industry item
  removeIndustry(fssVal: string, industryVal: string) {
    this.selectedIndustries[fssVal] = this.selectedIndustries[fssVal]?.filter((industry) => industry.val !== industryVal);
    this.selectedSectors[industryVal]?.map((sector) => this.removeSector(industryVal, sector.val));
    this.emitIndustryChange();
  }

  // Remove sector item
  removeSector(industryVal: string, sectorVal: string) {
    this.selectedSectors[industryVal] = this.selectedSectors[industryVal]?.filter((sector) => sector.val !== sectorVal);
    this.emitSectorChange();
  }

  // Emit event for FSS change
  private emitFssChange() {
    const selectedFSSCodes = this.selectedFSS
      .map((fss: AdminNestedData) => fss.itemCode)
      .filter((code): code is string => code !== undefined);
    this.selectedFssCodesChange.emit(selectedFSSCodes);
  }

  // Emit event for industry change
  private emitIndustryChange() {
    let selectedIndustryCodes: string[] = [];
    selectedIndustryCodes = Object.values(this.selectedIndustries).reduce((acc: string[], industries: AdminNestedData[]) => {
      return acc.concat(industries.map((industry: AdminNestedData) => industry.val));
    }, []);
    this.selectedIndustryCodesChange.emit([...new Set(selectedIndustryCodes)]);
  }

  // Emit event for sector change
  private emitSectorChange() {
    let selectedSectorCodes: string[] = [];
    const nestedDetails: AdminNestedDataMap = {};

    // Process selected sectors
    this.selectedFSS.forEach((fss) => {
      if (fss.itemCode) {
        const industries = this.selectedIndustries[fss.itemCode] || [];
        nestedDetails[fss.itemCode] = { industries: {} };

        industries.forEach((industry) => {
          const sectors = this.selectedSectors[industry.val] || [];
          if (fss.itemCode && industry.val) {
            nestedDetails[fss.itemCode].industries[industry.val] = sectors.map((sector) => sector.val).filter(Boolean);
          }
          selectedSectorCodes.push(...sectors.map((sector) => sector.val).filter(Boolean));
        });
      }
    });

    // Emit event for sector change
    const industryAndSectorPerFSS = Object.entries(nestedDetails).map(([fssCode, data]) => ({
      fssCode,
      industries: Object.entries(data.industries).map(([industryCode, sectors]) => ({
      industryCode,
      sectors
      }))
    }));

    this.selectedSectorCodesChange.emit(industryAndSectorPerFSS);
  }
}

// Interface for nested data structure
export interface AdminNestedData {
  label: string;
  val: string;
  value?: boolean;
  itemId?: string;
  itemCode?: string;
}

export type AdminNestedDataMap = { [fssCode: string]: { industries: { [industryCode: string]: string[] } } };