import { Component, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { Filter, FilterItem } from '@usitsdasdesign/dds-ng/filter';
import {
  Column,
  SortOptions,
  PositionState,
  SortingStates,
  KeyCodes,
  Themes,
  Size,
} from '@usitsdasdesign/dds-ng/shared';
import {
  StickerDirective,
  StickerOptions,
} from '@usitsdasdesign/dds-ng/sticker';
import { PaginationOptions, Mode } from '@usitsdasdesign/dds-ng/pagination';
import { ItemsPerPageOptions } from '@usitsdasdesign/dds-ng/items-per-page';
import { PagerOptions } from '@usitsdasdesign/dds-ng/pager';
import { DaterangepickerOptions } from '@usitsdasdesign/dds-ng/datepicker';
import {
  dateRangePickerOptions,
  ExampleData,
  filterData,
  filterOptions,
  filterStickerOptions,
  itemsPerPageOptions,
  paginationOptions,
  paging,
  sampleColumns,
  sampleData,
} from './audit-trail-table.helper';

export interface GridColumn extends Column {
  filterable?: boolean;
  searchValue?: any;
}

@Component({
  selector: 'app-audit-trail-table',
  templateUrl: './audit-trail-table.component.html',
  styleUrl: './audit-trail-table.component.less',
})
export class AuditTrailTableComponent {
  @ViewChildren('stickerDir') sticker!: QueryList<StickerDirective>;

  sampleColumns: Column[] = sampleColumns;

  sampleData: ExampleData[] = sampleData;

  filterData: FilterItem[] = filterData;

  filterOptions: Filter = filterOptions;

  paging: PagerOptions = paging;

  paginationOptions: PaginationOptions = paginationOptions;

  itemsPerPageOptions: ItemsPerPageOptions = itemsPerPageOptions;

  dateRangePickerOptions: DaterangepickerOptions = dateRangePickerOptions;

  filterStickerOptions: StickerOptions = filterStickerOptions;

  sortingState: SortOptions | null = {
    property: this.sampleColumns[2].name,
    descending: false,
    dataType: this.sampleColumns[2].dataType,
  };

  pageValue: number = 1;
  filterPropName: string = 'name';
  filteredValue: string = 'value';
  pageLength: number = 0;
  itemsPerPage: number = 5;
  itemsPerPageList: number[] = [5, 10, 25, 50, 100];
  tableData: ExampleData[] = this.sampleData;
  isShownFilter!: boolean;
  filteringColumn!: GridColumn;

  ngOnInit(): void {
    this.pageLength = Math.ceil(this.sampleData.length / this.itemsPerPage);
    this.tableData = this.sampleData.slice(0, this.itemsPerPage);
  }

  pageChanged(value: number): void {
    const pageLength = this.pageLength;
    this.tableData = this.sampleData.slice(
      (value - 1) * this.itemsPerPage,
      value * this.itemsPerPage
    );
    this.paginationOptions.pageNumberInSection = this.itemsPerPage;
    this.paging.maxValue = pageLength == 0 ? 1 : pageLength;
    //TODO: Make an api call updating with the filters and page number
  }

  toggleFilter(column: Column): void {
    // This gives you the column details that you clicked on
    console.log('toggleFilter', column);
    this.filteringColumn = column;
    this.isShownFilter = true;
  }

  filterSorted(sortedState: string): void {
    // TODO: Make api call by sending sorting state
    this.sortingState = {
      property: this.filteringColumn.name,
      descending: sortedState === SortingStates.descending,
      dataType: this.filteringColumn.dataType,
    };

    this.filterHide();
  }

  filterApply(searchValue: string | [Date, Date]): void {
    //TODO: Make an api call sending the searched term
    console.log('filterApply', searchValue);
    this.refreshTable();
    this.filterHide();
  }

  filterHide(): void {
    this.sticker.forEach((item) => {
      if (item.isActive) {
        item.hide();
      }
    });
  }

  filterOnHidden(): void {
    this.isShownFilter = false;
  }

  itemsPerPageChanged(value: number): void {
    this.itemsPerPage = value;
    this.pageLength = Math.ceil(this.sampleData.length / this.itemsPerPage);
    this.pageChanged(1);
    //TODO: Make an api call updating with the filters and page number
  }

  refreshTable(): void {
    // TODO: Make an api call to refresh the table
  }
}
