import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment';
import { ExternalCommUrlConstants } from '../../external-communications/constants/url.constants';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  enagementDetailsRequestBody,
  ExistingEnagementModel,
  ExistingEngagementList,
  GlobalDocumentStatusModel,
  GlobalDocumentTableModel,
  GlobalDocumentTypeModel,
  GlobalEditDocumentModel,
  GlobalEngagementDeliveryCenterModel,
  GlobalEngagementDetailModel,
  GlobalEngagementDetailSubmissionModel,
  GlobalEngagementLeftNavModel,
  GlobalEngagementMemberFirmModel,
  GlobalEngagementSubmissionDetailModel,
  GlobalEngagementSubmissionLevelModel,
  GlobalEngagementSubmissionModel,
  GlobalEngagementSubmissionResponseModel,
  GlobalEngagementSubmissionTypeModel,
  GlobalEngEfaModel,
  GlobalSaveDocumentModel,
} from '../../common/models/global-engagement.model';
import { GlobalEngagementUrlConstants } from '../../global-engagements/constants/url.constants';
import {
  IntakeSubmissionActionReasonDropdown,
  SaveSubmissionHistoryModel,
  SubmissionActionHistoryModel,
} from '../../common/models/submission-action.model';
import { AuditRequestParams } from '../../common/models/common-models';
import {
  AuditTrailRequestModel,
  AuditTrailResponseModel,
} from '../../common/models/audit-trail.model';

@Injectable({
  providedIn: 'root',
})
export class GlobalEngagementService {
  constructor(private readonly http: HttpClient) {}

  apiUrl: string = environment.apiUrl;
  // apiUrl: string = 'https://dapi.eclipse.deloitte.com/api/'; //dev
  //apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa

  readonly #discontinueEnabler: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly #openDiscontinueModal: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  discontinueEnabler$: Observable<boolean> =
    this.#discontinueEnabler.asObservable();
  discontinueCloseModal$: Observable<boolean> =
    this.#openDiscontinueModal.asObservable();

  enableDisconitnue(isValid: boolean): void {
    this.#discontinueEnabler.next(isValid);
  }

  openDiscontinueModal(isValid: boolean): void {
    this.#openDiscontinueModal.next(isValid);
  }

  readonly #submissionIdSource: BehaviorSubject<AuditRequestParams> =
    new BehaviorSubject<AuditRequestParams>({
      opportunitynumber: '',
      submissionid: null,
    });

  currentSubmissionId$: Observable<AuditRequestParams> =
    this.#submissionIdSource.asObservable();

  changeSubmissionId(request: AuditRequestParams) {
    this.#submissionIdSource.next(request);
  }

  getExistingEnagementDetails(
    data: enagementDetailsRequestBody
  ): Observable<ExistingEngagementList> {
    return this.http.post<ExistingEngagementList>(
      this.apiUrl + GlobalEngagementUrlConstants.GET_ENGAGEMENT_DETAILS,
      data
    );
  }

  getSubmissionTypes(): Observable<GlobalEngagementSubmissionTypeModel[]> {
    return this.http.get<GlobalEngagementSubmissionTypeModel[]>(
      this.apiUrl + GlobalEngagementUrlConstants.GET_SUBMISSION_TYPE
    );
  }

  getDeliveryCenter(): Observable<GlobalEngagementDeliveryCenterModel[]> {
    return this.http.get<GlobalEngagementDeliveryCenterModel[]>(
      this.apiUrl + GlobalEngagementUrlConstants.GET_DELIVERY_CENTER
    );
  }

  getMemberFirmDetails(): Observable<GlobalEngagementMemberFirmModel[]> {
    return this.http.get<GlobalEngagementMemberFirmModel[]>(
      this.apiUrl + GlobalEngagementUrlConstants.GET_Member_Firm
    );
  }

  postInitiateGlobalEngagement(
    data: GlobalEngagementSubmissionModel
  ): Observable<GlobalEngagementSubmissionResponseModel> {
    return this.http.post<GlobalEngagementSubmissionResponseModel>(
      this.apiUrl +
        GlobalEngagementUrlConstants.POST_GLOBAL_ENGAGEMENT_SUBMISSION,
      data
    );
  }

  getLeftNavDetails(
    id: string | null
  ): Observable<GlobalEngagementLeftNavModel[]> {
    return this.http.get<GlobalEngagementLeftNavModel[]>(
      this.apiUrl +
        GlobalEngagementUrlConstants.GET_GLOBAL_ENGAGEMENT_LEFTNAV +
        id
    );
  }

  getGlobalEngagementDetails(
    id: number
  ): Observable<GlobalEngagementDetailModel> {
    return this.http.get<GlobalEngagementDetailModel>(
      this.apiUrl +
        GlobalEngagementUrlConstants.GET_GLOBAL_ENGAGEMENT_DETAILS +
        id
    );
  }

  postGlobalEngagementDetails(
    data: GlobalEngagementDetailSubmissionModel
  ): Observable<string> {
    return this.http.put<string>(
      this.apiUrl +
        GlobalEngagementUrlConstants.POST_GLOBAL_ENGAGEMENT_SUBMISSION_DETAILS,
      data
    );
  }

  getGlobalEngagementSubmissionDetails(
    id: number
  ): Observable<GlobalEngagementSubmissionDetailModel> {
    return this.http.get<GlobalEngagementSubmissionDetailModel>(
      this.apiUrl +
        GlobalEngagementUrlConstants.GET_GLOBAL_ENGAGEMENT_SUBMISSION_DETAILS +
        id
    );
  }

  putGlobalEngagementSubmission(
    data: GlobalEngagementSubmissionLevelModel
  ): Observable<string> {
    return this.http.put<string>(
      this.apiUrl +
        GlobalEngagementUrlConstants.PUT_GLOBAL_ENGAGEMENT_SUBMISSION_DETAILS,
      data
    );
  }

  getGlobalDocumentStatus(): Observable<GlobalDocumentStatusModel[]> {
    return this.http.get<GlobalDocumentStatusModel[]>(
      this.apiUrl + GlobalEngagementUrlConstants.GET_ENGAGEMENT_DOCUMENT_STATUS
    );
  }

  getGlobalDocumentType(id: number): Observable<GlobalDocumentTypeModel[]> {
    return this.http.get<GlobalDocumentTypeModel[]>(
      this.apiUrl +
        GlobalEngagementUrlConstants.GET_ENGAGEMENT_DOCUMENT_TYPE +
        id
    );
  }

  getGlobalDocumentTable(id: number): Observable<GlobalDocumentTableModel[]> {
    return this.http.get<GlobalDocumentTableModel[]>(
      this.apiUrl + GlobalEngagementUrlConstants.GET_ENGAGEMENT_DOCUMENT + id
    );
  }

  deleteGlobalDocument(id: number): Observable<string> {
    return this.http.delete<string>(
      this.apiUrl + GlobalEngagementUrlConstants.DELETE_ENGAGEMENT_DOCUMENT + id
    );
  }

  editGlobalDocument(data: GlobalEditDocumentModel): Observable<string> {
    return this.http.post<string>(
      this.apiUrl + GlobalEngagementUrlConstants.EDIT_ENGAGEMENT_DOCUMENT,
      data
    );
  }

  saveGlobalDocument(data: GlobalSaveDocumentModel[]): Observable<string> {
    return this.http.post<string>(
      this.apiUrl + GlobalEngagementUrlConstants.SAVE_ENGAGEMENT_DOCUMENT,
      data
    );
  }

  getEFARoles(): Observable<GlobalEngEfaModel[]> {
    return this.http.get<GlobalEngEfaModel[]>(
      this.apiUrl + GlobalEngagementUrlConstants.GET_EFA_ROLES
    );
  }

  getActionReasons(
    id: number
  ): Observable<IntakeSubmissionActionReasonDropdown[]> {
    return this.http.get<IntakeSubmissionActionReasonDropdown[]>(
      this.apiUrl +
        GlobalEngagementUrlConstants.GET_ACTIONS_REASONS_DROPDOWN +
        id
    );
  }

  saveSubmissionHistory(
    data: SaveSubmissionHistoryModel
  ): Observable<SaveSubmissionHistoryModel[]> {
    const url = `${this.apiUrl}${GlobalEngagementUrlConstants.SAVE_ACTION_HISTORY}`;
    return this.http.post<SaveSubmissionHistoryModel[]>(url, data);
  }

  getSubmissionActionHistory(
    id: number
  ): Observable<SubmissionActionHistoryModel[]> {
    return this.http.get<SubmissionActionHistoryModel[]>(
      this.apiUrl + GlobalEngagementUrlConstants.GET_ACTION_HISTORY + id
    );
  }

  getAuditTrailData(
    opportunityId: string,
    submissionId: number | null = null,
    data: AuditTrailRequestModel
  ): Observable<AuditTrailResponseModel> {
    if (submissionId != null) {
      return this.http.post<AuditTrailResponseModel>(
        this.apiUrl +
          GlobalEngagementUrlConstants.GET_AUDIT_TRAIL_DATA +
          `${opportunityId}&submissionId=${submissionId?.toString()}`,
        data
      );
    } else {
      return this.http.post<AuditTrailResponseModel>(
        this.apiUrl +
          GlobalEngagementUrlConstants.GET_AUDIT_TRAIL_DATA +
          `${opportunityId}`,
        data
      );
    }
  }
}
