import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  linkButton,
  PrimaryButtonOptions,
  UploaderAreaOptions,
} from '../../../global-engagement.helper';
import { UploaderOptions } from '@usitsdasdesign/dds-ng/uploader';
import { GlobalEngagementsConstant } from '../../../constants/global-engagements.constants';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { EngagementDocumentLinkComponent } from '../engagement-document-link/engagement-document-link.component';
import { EngagementDocumentUploadComponent } from '../engagement-document-upload/engagement-document-upload.component';
import { EngagementTableComponent } from '../engagement-table/engagement-table.component';
import {
  GlobalDocumentStatusModel,
  GlobalDocumentTableModel,
  GlobalDocumentTypeModel,
} from '../../../../common/models/global-engagement.model';
import { GlobalEngagementService } from '../../../../http/global-engagement/global-engagement.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { PermissionsObj } from '../../../../common/models/common-models';
@Component({
  selector: 'app-engagement-documents',
  templateUrl: './engagement-documents.component.html',
  styleUrl: './engagement-documents.component.less',
})
export class EngagementDocumentsComponent {
  @Input() submissionId: number | null = null;
  @Input() submissionStatusId: number | null = null;
  @Input() submissionTypeId: number | null | undefined = null;
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;

  uploadAreaOptions: UploaderOptions = UploaderAreaOptions;
  emptyString: string = GlobalEngagementsConstant.EMPTY_STRING;
  buttonOptions: ButtonOptions = linkButton;
  isArchiveUploaded: boolean = false;
  documentTypeItemsList: SelectItemOptions[] = [];
  documentStatusItemList: SelectItemOptions[] = [];
  @Output() documentTableData: EventEmitter<GlobalDocumentTableModel[]> =
    new EventEmitter<GlobalDocumentTableModel[]>();
  @ViewChild(EngagementTableComponent)
  tableComponent!: EngagementTableComponent;

  constructor(
    private readonly modalService: ModalService,
    public readonly globalEngagementService: GlobalEngagementService
  ) {}

  openUploadModel(event: any, modalBtn?: ButtonComponent): void {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(EngagementDocumentUploadComponent, {
      isFooter: true,
      size: 'lg',
      isInverse: false,
      documentInitiatedDetails: {
        moduleName: 'Intake',
        submissionStatusId: this.submissionStatusId,
        submissionTypeId: this.submissionTypeId,
        submissionId: this.submissionId,
        documentUploadCount: 1,
        file: event,
      },
    });

    modalRef.onClosed().subscribe(() => {
      this.triggerDocumentTableDataApi();
      event.splice(0, event.length);
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  openDocumentLinkModal(modalBtn?: any): void {
    console.log('submissiontypeiddoc', this.submissionTypeId);
    let modalRef = this.modalService.open(EngagementDocumentLinkComponent, {
      documentInitiatedDetails: {
        submissionId: this.submissionId,
        moduleName: 'Intake',
        submissionStatusId: this.submissionStatusId,
        submissionTypeId: this.submissionTypeId,
      },
    });
    modalRef.onClosed().subscribe(() => {
      this.triggerDocumentTableDataApi();
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  triggerDocumentTableDataApi(): void {
    this.tableComponent.getDocumentMetaData();
  }

  onDocumentChange(event: boolean): void {
    this.isArchiveUploaded = event;
  }

  removeBodyOverflow(): void {
    document.body.style.overflowY = 'hidden';
  }
}
