import { AdminConstant } from './../constants/admin-constants';
import { Component } from '@angular/core';
import { OnDestroy, OnInit } from '@angular/core';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { AdminModuleService } from '../admin-services/admin-module.service';
import { Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { ToastMessageOptions } from '../../external-communications/external-communications.helper';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { RoleService } from '../../http/intake/role.service';
import {
  EntityDetails,
  EntityType,
  UserService,
} from '../../http/user.service';
import { RoleEnum } from '../../intake/constants/Role.enum';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrl: './admin-page.component.less',
})
export class AdminPageComponent implements OnInit, OnDestroy {
  label = AdminConstant.Label;
  options = AdminConstant.Button_Options;
  tabContainerOptions = AdminConstant.TabContainerOptions;
  verticalTabOptionsOne = AdminConstant.VerticalTabOptionsOne;
  verticalTabOptionsTwo = AdminConstant.VerticalTabOptionsTwo;
  verticalTabOptionsThree = AdminConstant.VerticalTabOptionsThree;
  toastMessageOptions: ToastOptions = ToastMessageOptions;
  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  //
  constructor(
    private readonly adminService: AdminModuleService,
    private readonly toastService: ToastService,
    private readonly progressIndicatorService: ProgressIndicatorService,
    private readonly userService: UserService,
    private readonly roleService: RoleService
  ) {}

  ngOnInit(): void {
    this.verticalTabOptionsOne.isActive = true;
    document.title = BrowserTabConstant.Browser_TabName_Admin;
  }

  tabChanged(tabKey: string): void {
    this.verticalTabOptionsOne.isActive = tabKey === 'global configuration';
    this.verticalTabOptionsTwo.isActive = tabKey === 'roles list';
    this.verticalTabOptionsThree.isActive = tabKey === 'tables';
  }

  handleTabChange(event: Event): void {
    const customEvent = event as CustomEvent<string>;
    const tab: string = customEvent.detail;

    if (tab === 'roles list') {
      this.verticalTabOptionsOne.isActive = false;
      this.verticalTabOptionsTwo.isActive = true;
      this.verticalTabOptionsThree.isActive = false;
    }
  }

  setToast(toast: ToastOptions): void {
    this.toastService.createToast(toast);
  }

  refreshPage(): void {
    this.progressIndicatorService.show();
    this.adminService
      .adminPageRefresh()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.setToast({
            ...this.toastMessageOptions,
            title: AdminConstant.REFRESH_TOAST_MESSAGE,
          });
          this.getUserRoles();
          this.progressIndicatorService.hide();
        },
        error: (error: HttpErrorResponse) => {
          console.error('Error refreshing page:', error);
          this.progressIndicatorService.hide();
        },
      });
  }

  getUserRoles(): void {
    this.progressIndicatorService.show();
    const entityId: string = EntityDetails.None;
    const entityTypeId: number = EntityType.None;
    this.userService
      .getUserRoles(entityId, entityTypeId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          const loggedInUserRole: string[] = data?.employeeRoles?.map(
            (roles: any) => roles.roleName
          );
          // Check if user is a System Administrator and set flag accordingly
          const isSystemAdministrator = data?.employeeRoles.some(
            (role: any) => role.roleName === RoleEnum.SYSTEM_ADMINISTRATOR
          );
          this.roleService.setCurrentUserRoles(loggedInUserRole);
          this.roleService.setIsSystemAdministrator(isSystemAdministrator);
          this.progressIndicatorService.hide();
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching the roles', err);
          this.progressIndicatorService.hide();
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
