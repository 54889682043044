import { Component, OnDestroy, OnInit } from '@angular/core';
import { OpportunityDetailsNavModel } from '../../common/models/opportunity-details.model';
import { GlobalEngagementService } from '../../http/global-engagement/global-engagement.service';
import { Subject, takeUntil } from 'rxjs';
import { GlobalEngagementLeftNavModel } from '../../common/models/global-engagement.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsObj } from '../../common/models/common-models';
import { PermissionCheckService } from '../../common/validations/permission-check.service';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import {
  EntityDetails,
  EntityType,
  UserService,
} from '../../http/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IntakeConstant } from '../../intake/constants/intake.constant';

@Component({
  selector: 'app-global-engagement',
  templateUrl: './global-engagement.component.html',
  styleUrl: './global-engagement.component.less',
})
export class GlobalEngagementComponent implements OnInit, OnDestroy {
  activeIndex: number = -1;
  unsubscriber$: Subject<void> = new Subject<void>();
  leftNavDetails: GlobalEngagementLeftNavModel[] = [];
  _blank: string = IntakeConstant.EMPTY_SPACE;
  engagementTitle: string = this._blank;
  engagementId: string | null = this._blank;
  activeSubmissionId: number | null = null;
  activeSubmissionType: string = this._blank;
  activeSubmissionStatus: string = this._blank;
  permissionObj: PermissionsObj = {} as PermissionsObj;
  queryParamSubmissionId: string = this._blank;

  constructor(
    private readonly globalEngagementService: GlobalEngagementService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly permissionCheck: PermissionCheckService,
    private readonly securityWebapiService: SecurityWebapiService,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.permissionObj = this.permissionCheck.getGlobalEngPermissionObj;
    const id = this.route.snapshot.paramMap.get('id');
    this.engagementId = id;
    this.getLeftNavDetails(id);
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  setActive(index: number): void {
    this.activeIndex = index;
    if (this.activeIndex !== -1) {
      this.activeSubmissionId = this.leftNavDetails[index].submissionId;
      this.activeSubmissionType = this.leftNavDetails[index].submissionType;
      this.activeSubmissionStatus =
        this.leftNavDetails[index].submissionsStatus;
    } else {
      this.activeSubmissionId = null;
      this.activeSubmissionType = this._blank;
      this.activeSubmissionStatus = this._blank;
    }

    /**
     * Reload should take to last active submission
     * It adds the queryParam for every submission selected
     */
    this.router.navigate(['globalEngagements/details', this.engagementId], {
      queryParams: { submissionId: this.activeSubmissionId },
      queryParamsHandling: 'merge',
      state: {
        submissionID: this.activeSubmissionId,
        submissionTypeCode: this.activeSubmissionType,
        name: this.engagementTitle,
      },
      replaceUrl: true,
    });

    if (index === -1) {
      this.updateRoleAndPermissions();
    }
  }

  getLeftNavDetails(id: string | null): void {
    this.globalEngagementService
      .getLeftNavDetails(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: GlobalEngagementLeftNavModel[]) => {
          this.leftNavDetails = response;
          this.engagementTitle = response[0].engagementTitle;
          this.queryParamSubmissionId =
            this.route.snapshot.queryParams['submissionId'];
          if (this.queryParamSubmissionId) {
            if (this.queryParamSubmissionId) {
              this.activeSubmissionId = parseInt(this.queryParamSubmissionId);
            }
            const activeIndex = this.leftNavDetails.findIndex(
              (item: GlobalEngagementLeftNavModel) =>
                item.submissionId.toString() ===
                this.activeSubmissionId?.toString()
            );
            this.setActive(activeIndex);
            // this.scrollToActiveIndex(); //TODO: need to fix
          } else {
            this.updateRoleAndPermissions();
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  updatePermissions(): void {
    // TODO: Instead of this, check if we can reuse the updateRoleAndPermissions
    this.securityWebapiService
      .getPermissionsByEmployeeId(
        EntityDetails.None,
        EntityType.GlobalEngagement
      )
      .then(() => {
        this.permissionCheck.updateGlobalEngPermissions();
        this.permissionObj = this.permissionCheck.getGlobalEngPermissionObj;
      })
      .catch((error: HttpErrorResponse) => {
        console.error('Error fetching permissions:', error);
      });
  }

  scrollToActiveIndex(): void {
    if (this.activeIndex !== -1 && this.leftNavDetails[this.activeIndex]) {
      const elementId = `navItem-${this.leftNavDetails[
        this.activeIndex
      ].submissionId.toString()}`;
      const element = document.getElementById(elementId);
      if (element) {
        const leftMenu = document.getElementById('left-nav-items');
        if (leftMenu) {
          leftMenu.scrollTop = element.offsetTop - leftMenu.offsetTop;
        } else {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }

  updateRoleAndPermissions() {
    const entityId: string | number =
      this.activeSubmissionId && this.activeIndex !== -1
        ? this.activeSubmissionId
        : this.leftNavDetails[0].submissionId ?? -1;

    const entityTypeId: number = EntityType.GlobalEngagement;
    this.userService.setEntityDetails(entityId, entityTypeId);
  }

  ngOnDestroy(): void {
    this.globalEngagementService.changeSubmissionId({
      opportunitynumber: '',
      submissionid: null,
    });
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
