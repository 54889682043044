import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Column, Themes } from '@usitsdasdesign/dds-ng/shared';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { SubmissionService } from '../../../http/intake/submission.service';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import {
  lepDataValue,
  riskManagerTableHeaderData,
} from '../opportunity-details-settings';
import { IntakeConstant } from '../../constants/intake.constant';
import {
  ADD_REVIEWER_BTN_OPTIONS,
  ARCHIVE_AND_COMPLETE_BTN_OPTIONS,
  CO_CHANGE_SCOPE_RADIO_GROUP_VALUE,
  CO_SEARCH_OPTION,
  PrimaryButton,
  RADIO_GROUP_OPTIONS_FOR_CO_CHANGE_SCOPE,
  RADIO_GROUP_OPTIONS_FOR_OTHER_SUBTYPE,
  RADIO_GROUP_OPTIONS_FOR_RFP,
  REP_RADIO_GROUP_VALUE,
  RESUBMIT_FOR_REVIEW_BTN_OPTIONS,
  SHOW_MORE_BTN_OPTIONS,
  SUBMIT_BTN_OPTIONS,
  toastMessage,
  submittedMessage,
  returnForReworkMessage,
  resubmittedToastMessage,
  OPT_OUT_SELF_REVIEW_BTN_OPTIONS,
  SELF_REVIEW_BTN_OPTIONS,
  isSelfReviewButtonVisible,
  optedOutSelfReviewToastMessage,
  setWbsAndArchiveDetails,
  intakeToastOption,
  UpdateReviewerActions,
  completedToastMessage,
  withoutRmToastMessage,
} from './submission-common-section.helper';
import { IntakeOppertunitySubmissionStatus } from '../../constants/intake_oppertunity_submission_status.enum';
import { IntakeDocumentSubmissionTypeCode } from '../../constants/intake_submission_type_code.enum';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import { latestOpportunityDetails } from '../../../common/models/latestOpportunityDetails';
import {
  LepValue,
  OpportunityDetailsNavModel,
} from '../../../common/models/opportunity-details.model';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import {
  CoElSowWithWbsArchiveEventEmitterModel,
  DeliverableWithWbsArchiveEventEmitterModel,
  DiscontinuedRequestModel,
  EmitUpdatedTitleModal,
  IntakeSubmissionDeatilsCommonSectionWorkflowSelectedDocumentsModal,
  IntakeSubmissionDetailsCommonSectionDataReceivedModal,
  IntakeSubmissionDetailsCommonSectionDataSendingModal,
  OtherWithWbsArchiveEventEmitterModel,
  SubmissionApprovers,
  SwiftDetails,
} from '../../../common/models/intake-submission-details-common-section.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  configurationType,
  PermissionsObj,
  SubmissionSubType,
} from '../../../common/models/common-models';
import { RoleEnum, RoleIdMapper } from '../../constants/Role.enum';
import {
  copyToClipboard,
  isTheDocumentHasCompletedElSOw,
  randomString,
} from '../../common/intake.util';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';

import { DocumentSelectionPopupComponent } from './document-selection-popup/document-selection-popup.component';
import { DocumentComponent } from './document/document.component';
import { WinningProposalOptionValues } from '../../constants/intake_document_close_out_winning_proposal_option_values.enum';

import { ConfirmPopModalComponent } from './confirm-pop-modal/confirm-pop-modal.component';
import { OtherOptionValues } from '../../constants/intake_document_close_out_other_option_values.enum';
import { IntakeDocumentTableModel } from '../../../common/models/intake-document-table.model';
import { StatusEnum } from '../../../common/constants/status-enum';
import { AdministrativelyClosePopupComponent } from './administratively-close-popup/administratively-close-popup/administratively-close-popup.component';
import {
  CloseOutModalModel,
  DiscontinuedModalOnCloseRequestModel,
} from '../../../common/models/show-all-popup-model';
import { DeliverableOptionValues } from '../../constants/intake_document_close_out_deliverable_option_values.enum';
import { CoElSowOptionValues } from '../../constants/intake_document_close_out_co_elsow_option_values.enum';
import { CommonService } from '../../../http/intake/common.service';
import { OppertunitySubmissionStatusCode } from '../../../common/models/oppertunity-submission-statusCode.model';

import { SubmissionActionHistoryModel } from '../../../common/models/submission-action.model';
import { RoleService } from '../../../http/intake/role.service';
import { DiscontinueModalComponent } from './discontinue-modal/discontinue-modal.component';
import { ResetStatusPopupComponent } from './reset-status-popup/reset-status-popup.component';
import { SecurityWebapiService } from '../../../http/security/security-webapi.service';
import { OptOutPopupComponent } from '../../intake-common-popups/opt-out-popup/opt-out-popup.component';
import {
  IntakeOptOutPopUpDataModel,
  NavigationInfoRelinkModel,
} from '../../../common/models/initiateSubmission.model';
import { UserService } from '../../../http/user.service';
import { WbsArchiveReceivedModel } from '../../../common/models/wbs-picker.model';
import { DocumentService } from '../../../http/document/document.service';
import { BrowserTabConstant } from '../../../common/constants/browser-tab-constant';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonHelperService } from '../../../common/services/common-helper.service';
import { DocumentStatus } from '../../constants/document-status.enum';
import { RelinkOppPopupComponent } from './relink-opp-popup/relink-opp-popup.component';
import { EclipseHeaderService } from '../../../http/eclipse-header.service';
@Component({
  selector: 'app-submission-common-section',
  templateUrl: './submission-common-section.component.html',
  styleUrl: './submission-common-section.component.less',
})
export class SubmissionCommonSectionComponent
  implements OnInit, OnChanges, OnDestroy
{
  _EMPTY = IntakeConstant.EMPTY_SPACE;
  @Input() dataOpportunityId: string = this._EMPTY;
  @Input() dataSubmissionId: number | null = null;
  @Input() opportunityDocsCount: number = 0;
  @Input() set dataSubmissionTypeCode(submissionTypeCode: string) {
    this.setSubMissionCodeDetails(submissionTypeCode);
  }
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;
  @Input() topPosition!: number;
  @Input() dataLepValue: LepValue = lepDataValue;
  @Input() opportunityDocs!: OpportunityDetailsNavModel[];

  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateTitle: EventEmitter<EmitUpdatedTitleModal> =
    new EventEmitter<EmitUpdatedTitleModal>();
  @Output() updateLeftMenu: EventEmitter<string> = new EventEmitter<string>();
  @Output() updatePermissions: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(DocumentComponent) documentComponent!: DocumentComponent;

  isAllReviewerValid: boolean = true;
  modalSetting: any = null;
  progressiveStatus: boolean | null = null;
  isEligibleTechnicalReviewer: boolean = false;
  isDocumentRequireMsgShow: boolean = false;
  isFinalDocumentAcceptedMsgShow: boolean = true;
  oppertunityStatusCodes!: OppertunitySubmissionStatusCode[];
  selfReviewDocBanner: string = this._EMPTY;
  defaultEmptyFields: string = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
  archiveDetails!: WbsArchiveReceivedModel;
  swiftDetails!: SwiftDetails;
  apiCallHandling: boolean = false;
  isCloseOutMoveToCompleteModalOpen: boolean = false;
  isArchiveFeatureEnable: boolean = false;
  iswbsCheckboxSelected: boolean = false;
  archiveNumberSelected: string | null | undefined = IntakeConstant.EMPTY_SPACE;
  isArchiveNotListed: boolean = false;
  isCopied: boolean = false;
  UPLOAD_DOCUMENTS_LABEL_DRAFT!: string;
  submissionTypeCode!: string;
  submissionData!: IntakeSubmissionDetailsCommonSectionDataReceivedModal;
  titleValue: string = this._EMPTY;
  isEditingTitle: boolean = false;
  copySuccess: string | null = null;
  requiredCheckForCommonSection: boolean = false;
  submissionId: number = 0;
  submissionStatus: string | undefined = this._EMPTY;
  selfReviewEligibility: string | undefined = this._EMPTY;
  updatedRiskManagerDetails!: SubmissionApprovers[];
  tempUpdatedRiskManagerDetails!: SubmissionApprovers[];
  isEmployeePresent: boolean = false;
  showPeoplePicker: boolean = false;
  selectedRiskManagers: string[] = [];
  unsubscriber$: Subject<any> = new Subject<any>();
  isOpportunitySelected: boolean = true;
  textareaOptions: any = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  enter_text_placeholder = IntakeConstant.ENTER_TEXT_PLACEHOLDER;
  intakeDocumentSubmissionTypeCodePro = IntakeDocumentSubmissionTypeCode.PRO;
  intakeDocumentSubmissionTypeCodeDelivery =
    IntakeDocumentSubmissionTypeCode.DEL;
  intakeDocumentSubmissionTypeCodeOther = IntakeDocumentSubmissionTypeCode.OTH;
  intakeDocumentSubmissionTypeCodeCO = IntakeDocumentSubmissionTypeCode.CO;
  intakeDocumentSubmissionTypeCodeELSOW =
    IntakeDocumentSubmissionTypeCode.EL_SOW;
  IntakeDocumentSubmissionStatusSubmitted =
    IntakeOppertunitySubmissionStatus.SUBMITTED;
  RFP_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_RFP;
  REP_radio_values: any[] = REP_RADIO_GROUP_VALUE;
  other_subtype_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_OTHER_SUBTYPE;
  RADIO_THEME_COLOR: Themes = IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR;
  COChangeScope_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_CO_CHANGE_SCOPE;
  COChangeScope_radio_values: any[] = CO_CHANGE_SCOPE_RADIO_GROUP_VALUE;
  showMoreOptions: any = SHOW_MORE_BTN_OPTIONS;
  label: string = 'Update';
  roleAdditionalReviewer: string = RoleEnum.REVIEWER;
  roleTechnicalOrIndependentRM: string =
    RoleEnum.TECHNICAL_INDEPENDENT_RISK_MANAGER;
  roleRiskManager: string = RoleEnum.RISK_MANAGER;
  roleSupportingRiskManager: string = RoleEnum.SUPPORTING_RISK_MANAGER;
  roleSpecializedRiskManager: string = RoleEnum.SPECIALIZED_RISK_MANAGER;
  roleRMSupport: string = RoleEnum.RM_SUPPORT_GLOBAL;
  roleSystemAdministrator: string = RoleEnum.SYSTEM_ADMINISTRATOR;
  private timeoutId: ReturnType<typeof setTimeout> | null = null;
  updateLabel: string = this._EMPTY;
  riskManagerTableHeader: Column[] = riskManagerTableHeaderData;
  isValidDocumentUploaded: boolean = false;
  co_draft_Document_Uploaded: boolean = false;
  changeOrderStatus: boolean = false;
  loggedInEmployeeId: string = this._EMPTY;
  configLableDocument: string = this._EMPTY;
  configData: configurationType[] = [];
  submissionStatusCommonSection: string = this._EMPTY;
  showInformationalGuidanceForDeliverable: boolean = false;
  submissionActionHistory: SubmissionActionHistoryModel[] = [];
  showReviewAndReturnBtn: boolean = false;
  optOutOfSelfReviewReason: string | null | undefined = this._EMPTY;
  showSubmitBtnWhnDraft: boolean = false;
  showCloseOutForm: boolean = true;
  allDocTypeSelectedIsSupporting: boolean = true;
  coOppDonotMatchMessage: string = this._EMPTY;
  showReviewBtn: boolean = false;
  submitLabel: string = IntakeConstant.SUBMIT_BTN_LABEL;
  archiveAndCompleteBtnLabel: string =
    IntakeConstant.ARCHIVE_AND_COMPLETE_BTN_LABEL;
  reSubmitForReviewLabel: string = IntakeConstant.RESUBMIT_FOR_REVIEW_BTN_LABEL;
  selfReviewBtnLabel: string = IntakeConstant.SELF_REVIEW_BTN_LABEL;
  optOutOfSelfReviewBtnLabel: string =
    IntakeConstant.OPT_OUT_OF_SELF_REVIEW_BTN_LABEL;
  submitButtonOptions: ButtonOptions = SUBMIT_BTN_OPTIONS;
  options: ButtonOptions = ADD_REVIEWER_BTN_OPTIONS;
  archiveAndCompleteBtnOption = ARCHIVE_AND_COMPLETE_BTN_OPTIONS;
  resubmitForReviewBtnOption = RESUBMIT_FOR_REVIEW_BTN_OPTIONS;
  selfReviewBtnOption = SELF_REVIEW_BTN_OPTIONS;
  optOutOfSelfReviewBtnOption = OPT_OUT_SELF_REVIEW_BTN_OPTIONS;
  toastOptions: ToastOptions = toastMessage;
  submittedToastOption: ToastOptions = submittedMessage;
  returnForReworkToastOption: ToastOptions = returnForReworkMessage;
  resubmittedToastOption: ToastOptions = resubmittedToastMessage;
  optedOutToastOption: ToastOptions = optedOutSelfReviewToastMessage;
  compltedToastOption: ToastOptions = completedToastMessage;
  withoutRMToastOption: ToastOptions = withoutRmToastMessage;

  reworkButton: ButtonOptions = PrimaryButton;
  internalQrmCommunicationTextareaMaxlength: number =
    IntakeConstant.TEXTAREA_CHAR_LIMIT_BIGGEST;
  public model: IntakeSubmissionDetailsCommonSectionDataSendingModal =
    {} as IntakeSubmissionDetailsCommonSectionDataSendingModal;
  public commonSectionFormModule!: Saver;
  isShowMoreDocumentNotes: boolean = false;
  showMoreText: string = IntakeConstant.SHOW_MORE_TEXT;
  coHasParentOpportunityNumber: boolean = false;
  coSearchOption = CO_SEARCH_OPTION;
  selectedOportunityForCO = this._EMPTY;
  isCheckboxCheckedForNoEL_SOW: boolean = true;
  readonly #route = inject(ActivatedRoute);
  opportunityNumber!: string;
  otherSubTypes$!: Observable<SubmissionSubType[]>;
  submissionTypeId: number = 0;
  submissionStatusId: number = 0;
  lepAcknowledgedBy: null | string = null;
  lepAcknowledgementDate: null | string = null;
  lepReviewedBy: null | string = null;
  showLepConfirmationMessage: boolean = false;
  employeeIdNumber: number = 0;
  showActionsHeader: boolean = false;
  isArchiveAndCompleteBtnDisabled: boolean = true;
  isResubmitForReviewBtnDisabled: boolean = true;
  otherSubmissionValueSelected: string = this._EMPTY;
  documentTableData: IntakeDocumentTableModel[] = [];
  showUploadFinalVersionMessage: boolean = false;
  showUploadGuidanceYellowMsgForCoAndElSowOnYes: boolean = false;
  showUploadGuidanceInfoMsgForCoAndElSowOnYes: boolean = false;
  showInfoMsgForCoElSowOnSelectNoResubmit: boolean = false;
  showUpdatedDocumentMessage: boolean = false;
  DRAFT_STATUS: string = IntakeOppertunitySubmissionStatus.DRAFT;
  DOC_DRAFT_STATUS: string = DocumentStatus.Draft;
  RETURN_FOR_REWORK: string =
    IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK;
  roleIdMapper: any = RoleIdMapper;
  showResetActionMessage: boolean = false;
  resetActionMessage?: SubmissionActionHistoryModel;
  isValidTechnicalReviewer: boolean = false;
  isSelfReviewButtonVisible: boolean = false;
  valueYes: string = IntakeConstant.YES;
  docStatusSubmitted: IntakeOppertunitySubmissionStatus =
    IntakeOppertunitySubmissionStatus.SUBMITTED;
  docStausSelfReviewedPendingCloseout: IntakeOppertunitySubmissionStatus =
    IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT;
  showCompleteCloseoutFormMsg: boolean = true;
  RMReviewedPendingCloseout: string =
    IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT;
  SelfReviewedPendingCloseout: IntakeOppertunitySubmissionStatus =
    IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT;
  submissionStatusResubmitted: string =
    IntakeOppertunitySubmissionStatus.RESUBMITTED;
  currentLoggedInUser: string = this._EMPTY;
  otherSubTypes: SubmissionSubType[] = [];
  userRolesAccess: any = [];
  uploadedDocData: IntakeDocumentTableModel[] = [];
  YES_CONST: string = 'yes';
  RESUBMIT_CONST: string = 'resubmit';
  disableAddReviewerButton: boolean = false;
  reviewerDetailsFromReviewerApi: SubmissionApprovers[] = [];
  showRiskManagerLabel: boolean = false;
  showSupportingRiskManagerLabel: boolean = false;
  showSpecializedRiskManagerLabel: boolean = false;
  isSubmissionStatusRMorSelfReviewed: boolean = false;
  showDocUploaderInFinalizeForm: boolean = false;
  ProposalShowDocUploaderInFinalizeForm: boolean = false;
  DeliverableShowDocUploaderInFinalizeForm: boolean = false;
  OtherShowDocUploaderInFinalizeForm: boolean = false;
  removeBannerIfFinalizeFormAnswered: boolean = false;
  collapseAccordion: boolean = true;
  documentUploadedInFinalizeForm: boolean = false;
  collapseSelfReviewAccordion: boolean = true;
  isDocUploadedInFinalizeForm: boolean = false;
  pageFreshlyLoaded: boolean = true;

  showWarningMessage: boolean = false;
  primaryDgmfId: string | null | undefined = IntakeConstant.EMPTY_SPACE;
  primaryGmdmId: string | null | undefined = IntakeConstant.EMPTY_SPACE;
  primarySwiftId: string | null | undefined = IntakeConstant.EMPTY_SPACE;
  submissionTypeSubId: number | null | undefined = null;

  rmReviewedGuidanceNoteHeader: string = this._EMPTY;
  rmReviewedGuidanceNoteBody: string = this._EMPTY;

  discontinueModalOpen: boolean = false;

  isOpporunityLevelFormCompleted: boolean = false;
  // TODO: below 2 messages need to come from backend
  pendingCloseOutNonSelfReviewMsg =
    'Risk management has reviewed your submission. Download the risk management-reviewed document and incorporate any comments or feedback provided by risk management prior to sharing it with the client. Return to the application to finalize, archive and move submission to complete.';
  pendingCloseOutSelfReviewMsg =
    'This submission qualified for Self-review and has not been reviewed by risk management. The LEP should perform a final review of the document prior to sharing it with the client. Return to the application to finalize, archive and move submission to complete.';

  constructor(
    private readonly submissionService: SubmissionService,
    private readonly autosaverService: AutosaverService,
    private readonly modalService: ModalService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly toastService: ToastService,
    private readonly commonService: CommonService,
    private readonly roleService: RoleService,
    private readonly securityWebapiService: SecurityWebapiService,
    private readonly documentService: DocumentService,
    private readonly userService: UserService,
    private readonly commonHelperService: CommonHelperService,
    private readonly router: Router,
    private readonly headerService: EclipseHeaderService
  ) {
    this.model.submissionTitle = this.commonHelperService.trimPrefixEmptySpace(
      this.titleValue
    );
    this.#route.paramMap.subscribe((params) => {
      this.opportunityNumber = params.get('id')!;
    });
  }

  ngOnInit(): void {
    this.commonService.enableAuditTrail(true);
    this.getPermissions(); // TODO: Need to remove as permission are integrated

    this.userService.currentLoggedInUser$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((user: any) => {
        this.currentLoggedInUser = user?.employeeId;
        this.userRolesAccess = user?.employeeRoles ?? [];
      });

    this.commonService.intakeDocSubmissionStatusObserable$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[] | null) => {
          if (response && response != null) {
            this.oppertunityStatusCodes = response;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching submission status', err);
        },
      });
    this.loggedInEmployeeId = this.submissionService.employeeId;
    //Resetting the value on close of modal to null.
    this.submissionService.otherValue$.subscribe((value) => {
      this.otherSubmissionValueSelected = value;
    });
    this.loggedInEmployeeId = this.submissionService.employeeId;
    this.submissionService.updateOtherSubmissionValue(this._EMPTY);

    let autoSaverOptions = {
      delay: 500,
      onSuccess: (saver: any, result: any) => {
        return this.postAutoSaveHandler();
      },
    } as IAutosaverOptions;
    this.commonSectionFormModule = this.autosaverService.newSaver(
      'model',
      this,
      async (model) => this.#saveCommonSectionDetails(),
      autoSaverOptions
    );
    this.commonSectionFormModule.start();

    this.submissionService.documentIsValidObservable$.subscribe((status) => {
      this.isValidDocumentUploaded = status;
    });

    this.submissionService.co_draft$.subscribe((status: boolean) => {
      this.co_draft_Document_Uploaded = status;
    });

    this.submissionService.adminCloseModal$.subscribe((status) => {
      if (status) {
        this.openModalForAdminCloseOut();
      }
    });

    this.commonService.resetStatusModal$.subscribe((status: boolean) => {
      if (status) {
        this.openModalForResetStatus();
      }
    });
    if(!this.discontinueModalOpen){
      this.submissionService.openDiscontinueModalObservable$.subscribe(
        (status: boolean) => {
          if (status) {
            this.openDiscontinueModal();
          }
        }
      );
    }
    this.submissionService.IntakeSubmitBtnStatus$.pipe(
      takeUntil(this.unsubscriber$)
    ).subscribe((status: boolean) => {
      this.allDocTypeSelectedIsSupporting = status;
    });
    this.collapseAccordion = true;
    this.updateCloseoutBanner();

    this.submissionService.openRelinkModel$.subscribe((status: boolean) => {
      if (status) {
        this.openRelinkModel();
      }
    });
  }

  closeOutFormYesOrNoSelected(event: boolean): void {
    this.showDocUploaderInFinalizeForm = event;
  }

  ProposalFormYesOrNoSelected(event: boolean): void {
    this.ProposalShowDocUploaderInFinalizeForm = event;
  }

  DeliverableCloseOutFormYesOrNoSelected(event: boolean): void {
    this.DeliverableShowDocUploaderInFinalizeForm = event;
  }

  OtherCloseOutFormYesOrNoSelected(event: boolean): void {
    this.OtherShowDocUploaderInFinalizeForm = event;
  }

  FinalizeFormAnswered(event: boolean): void {
    this.removeBannerIfFinalizeFormAnswered = event;
  }

  FinalizeFormSelectedValue(event: string): void {
    if (
      this.isSubmissionStatusRMorSelfReviewed &&
      event === IntakeConstant.YES.toLowerCase()
    ) {
      this.collapseAccordion = false; //collapse accordion
    } else {
      this.collapseAccordion = true; //expand accordion
    }
    event === IntakeConstant.NO_RESUBMIT_FOR_REVIEW
      ? (this.collapseSelfReviewAccordion = true)
      : (this.collapseSelfReviewAccordion = false);
  }

  docUploadedInFinalizeForm(event: boolean): void {
    this.documentUploadedInFinalizeForm = event;
  }

  openModalForAdminCloseOut(modalBtn?: ButtonComponent): void {
    let modalRef = this.modalService.open(AdministrativelyClosePopupComponent, {
      isFooter: false,
      size: 'lg',
      isInverse: false,
      submissionId: this.submissionId,
    });
    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        if (this.submissionId) {
          this.timeoutId = setTimeout(() => {
            this.loadSubmission();
            this.updateLeftMenu.emit(this.opportunityNumber);
          }, IntakeConstant.SET_TIMEOUT_DURATION);
        }
        this.submissionService.openAdminCompleteModal(false);
      });
  }

  openDiscontinueModal(): void {
    let discontinueModalRef = this.modalService.open(
      DiscontinueModalComponent,
      {
        isFooter: true,
        size: 'lg',
        isInverse: false,
      }
    );
    this.discontinueModalOpen = true;
    discontinueModalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((response: DiscontinuedModalOnCloseRequestModel) => {
        this.discontinueModalOpen = false;
        if (this.submissionId) {
          let statusId =
            this.oppertunityStatusCodes.find(
              (status: OppertunitySubmissionStatusCode) =>
                status.statusName ===
                IntakeOppertunitySubmissionStatus.DISCONTINUED
            )?.id ?? 0;
          let discontinuReasonId = response.reasonId ?? 0;
          let discontinueSubmissionActionId = response.actionId ?? 0;
          if (
            statusId > 0 &&
            discontinuReasonId > 0 &&
            discontinueSubmissionActionId > 0
          ) {
            let discontinueDetails: DiscontinuedRequestModel = {
              submissionId: this.submissionId,
              currentStatusId: statusId,
              reasonId: discontinuReasonId,
              submissionActionId: discontinueSubmissionActionId,
            };
            this.submissionService
              .updateDiscontinueAsSubmissionStatus(discontinueDetails)
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe({
                next: (response) => {
                  //  TODO : as from backend no success response so page is not refreshing
                  this.loadSubmission();
                },
                error: (err: HttpErrorResponse) => {
                  console.error('discontinue save error', err);
                },
              });
            //  TODO : as from backend no success response come remove this force refresh one
            this.timeoutId = setTimeout(() => {
              location.reload();
            }, IntakeConstant.SET_TIMEOUT_DURATION);
          }
        }
        this.submissionService.openDiscontinueSubmissionModal(false);
      });
  }

  SubmissionActionHistoryForAdminComplete(id: string): void {
    this.progressIndicator.show();
    this.submissionService
      .getSubmissionActionHistory(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: SubmissionActionHistoryModel[]) => {
          this.submissionActionHistory = response;
          for (let i in this.submissionActionHistory) {
            if (
              this.submissionActionHistory[i].submissionActionId ===
              IntakeConstant.ResetActionID
            ) {
              this.showResetActionMessage = true;
              this.resetActionMessage = this.submissionActionHistory[i];
              return;
            } else {
              this.showResetActionMessage = false;
            }
          }
          this.progressIndicator.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('Error fetching data', err);
        },
      });
  }

  checkStatusCompletedByAdmin(): boolean {
    if (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.COMPLETED_ADMIN
    ) {
      return true;
    }
    return false;
  }

  checkStatusDiscontinued(): boolean {
    return (
      this.submissionStatus === IntakeOppertunitySubmissionStatus.DISCONTINUED
    );
  }

  disableFieldOnComplete(): boolean {
    return (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.COMPLETED_ADMIN
    );
  }

  openModalForResetStatus(modalBtn?: ButtonComponent): void {
    let resetStatusModalRef = this.modalService.open(
      ResetStatusPopupComponent,
      {
        isFooter: false,
        size: 'lg',
        isInverse: false,
        submissionId: this.submissionId,
        submissionStatus: this.submissionStatus,
      }
    );
    resetStatusModalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((resetButtonSelected) => {
        if (this.submissionId && resetButtonSelected) {
          this.timeoutId = setTimeout(() => {
            this.loadSubmission();
            this.updateLeftMenu.emit(this.opportunityNumber);
          }, IntakeConstant.SET_TIMEOUT_DURATION);
        }
        this.commonService.openResetStatusModal(false);
      });
  }

  getChangeOrderStatus(): void {
    const submissionStatusId = 3;
    if (submissionStatusId) {
      this.submissionService
        .getChangeOrderStatus(this.opportunityNumber, submissionStatusId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            this.changeOrderStatus = response;
          },
          error: (err: HttpErrorResponse) => {
            console.error('Error fetching submission', err);
          },
        });
    }
  }

  getSubmissionStatusCode(statusName: string): number | undefined {
    return this.oppertunityStatusCodes?.find(
      (status: OppertunitySubmissionStatusCode) =>
        status.statusName === statusName
    )?.id;
  }

  setSubMissionCodeDetails(submissionTypeCode: string): void {
    this.submissionTypeCode = submissionTypeCode;
    this.otherSubTypes$ = this.submissionService.getSubmissionSubTypes(5);
    this.otherSubTypes$.subscribe((response) => {
      this.otherSubTypes = response;
    });
  }

  postAutoSaveHandler() {
    //TODO: fetch the save details
  }

  #saveCommonSectionDetails() {
    // TODO: need to check condition for whether a field is disabled and if enabled from devtools we should handle it
    let commonSectionData = { ...this.model };
    commonSectionData.submissionId = this.submissionId;
    commonSectionData.parentOpportunityNumber = this._EMPTY;
    // TODO: add value from search field for CO, or add from existing value

    if (commonSectionData.coChangeScope != null) {
      commonSectionData.coChangeScope =
        IntakeConstant.YES_NO_CHECKBOX_VALUE_TO_BOOLEAN.get(
          commonSectionData.coChangeScope
        );
    }

    if (commonSectionData.relatedToRFP != null) {
      commonSectionData.relatedToRFP =
        IntakeConstant.YES_NO_CHECKBOX_VALUE_TO_BOOLEAN.get(
          commonSectionData.relatedToRFP
        );
    }

    if (
      this.model.submissionSubTypeId &&
      this.model.submissionSubTypeId != this._EMPTY
    ) {
      commonSectionData.submissionSubTypeId = Number(
        this.model.submissionSubTypeId
      );
    }

    this.submissionService.onLoadSubmissionPage$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((value: boolean) => {
        this.pageFreshlyLoaded = value;
      });

    if (this.pageFreshlyLoaded) {
      commonSectionData.archiveNumber = null;
      commonSectionData.archiveStatus = null;
      commonSectionData.archiveName = null;
    } else {
      commonSectionData.archiveNumber = this.model.archiveNumber;
      commonSectionData.archiveName = this.model.archiveName;
      commonSectionData.archiveStatus = this.model.archiveStatus;
    }

    return this.submissionService
      .saveIntakeDetailsCommonData(commonSectionData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicator.hide();
          if (this.apiCallHandling) {
            this.COChangeScopeStateChanged();
            this.apiCallHandling = false;
          }
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('Error saving data', err);
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['dataOpportunityId'] ||
      changes['dataSubmissionId'] ||
      changes['submissionTypeCode']
    ) {
      this.scrollToTop();
      this.submissionService.setOnLoadSubmissionPageData(true);
      this.submissionService.updateOtherSubmissionValue(this._EMPTY);
      this.submissionService.otherValue$.subscribe((value) => {
        this.otherSubmissionValueSelected = value;
      });
      this.isArchiveAndCompleteBtnDisabled = true;
      this.isResubmitForReviewBtnDisabled = true;
      this.showInformationalGuidanceForDeliverable = false;
      this.showSubmitBtnWhnDraft = false;
      this.loadSubmission();
      this.getLabelConfiguration();
      if (this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeCO) {
        this.getChangeOrderStatus();
      }
      this.showResetActionMessage = false;
    }
  }

  ngAfterViewInit(): void {
    this.updateStickyTopPosition();
    this.scrollToTop();
  }

  updateStickyTopPosition(): void {
    this.headerService.updateStickyTopPosition();
  }

  checkValidation(): void {
    this.valueChange.emit(this.checkValidationOfForm());
  }

  //selecting row of the riskmanager table
  toggleRow(rowEmail: string, index: number): void {
    if (rowEmail && !this.isReviewerCheckboxDisabled(rowEmail, index)) {
      let reviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (approver) => approver.emailId === rowEmail
      );
      this.updatedRiskManagerDetails[reviewerIndex].isRequired =
        !this.updatedRiskManagerDetails[reviewerIndex].isRequired;
      if (this.updatedRiskManagerDetails[reviewerIndex].isRequired) {
        this.pushInSelectedRiskManagers(
          this.updatedRiskManagerDetails[reviewerIndex].emailId
        );
      } else {
        this.selectedRiskManagers = this.selectedRiskManagers.filter(
          (element) =>
            element != this.updatedRiskManagerDetails[reviewerIndex].emailId
        );
      }
      this.tempUpdatedRiskManagerDetails = structuredClone(
        this.updatedRiskManagerDetails
      );
      this.#updateReviewers(
        UpdateReviewerActions.TOGGLE,
        this.updatedRiskManagerDetails[reviewerIndex].reviewerRoleId
      );
    }
  }

  #updateReviewers(actionPerfomed: string, roleId: number) {
    this.progressIndicator.show();

    // Empty placeholder for RM, Supporting/Specialised RM are removed here.
    // As we check for emailId, fullName or displayName
    const reviewerSet = new Set();
    let updatedArr = structuredClone(this.updatedRiskManagerDetails);
    updatedArr = updatedArr
      .filter(
        (reviewer) =>
          reviewer.emailId || reviewer.fullName || reviewer.displayName
      )
      // Filtering and adding in set to avoid data duplication based on emailid
      .filter((reviewer) => {
        const identifier = `${reviewer.emailId?.toLowerCase()}`;
        if (reviewerSet.has(identifier)) {
          return false;
        } else {
          reviewerSet.add(identifier);
          return true;
        }
      });

    updatedArr.forEach((reviewer) => {
      delete reviewer.isEditing;
      delete reviewer.isEditable;
      delete reviewer.isForTechnicalReviewer;
    });

    // For technical/reviewer add/remove toast msg
    //roleId === 674 - Technical RM & roleId === 678 - Independent RM
    const toastTitle: string =
      (roleId === 674 || roleId === 678) &&
      (actionPerfomed === UpdateReviewerActions.ADD ||
        actionPerfomed === UpdateReviewerActions.DELETE)
        ? `Reviewer ${
            actionPerfomed === UpdateReviewerActions.ADD ? 'added' : 'removed'
          }`
        : '';

    this.submissionService
      .updateSubmissionReviewers(updatedArr, Number(this.submissionId))
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          // do operation post updating the reviewers details
          if (response?.isSuccess) {
            if (toastTitle) {
              this.setToast({ ...intakeToastOption, title: toastTitle });
            }
            this.submissionService
              .getSubmissionReviewers(this.submissionId.toString())
              .subscribe((response: SubmissionApprovers[]) => {
                this.setWorkflowReviewersDetails(response);
                this.checkValidation();
                this.checkButtonVisibility();
                this.disableAddReviewerButton = false;

                this.progressIndicator.hide();
              });
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error updating submission', err);
          this.progressIndicator.hide();
          this.disableAddReviewerButton = false;
        },
      });
  }

  addReviewer(roleName: string): void {
    // this.isAllReviewerValid = roleName === this.roleTechnicalOrIndependentRM;
    // TODO: We can update the condition to use isAddReviewerButtonDisabled getter function

    // TODO: For adding a technicalreviewer do we need to check access for 'AddReviewer'?
    if (
      this.checkUserAccess('AddReviewer') && // TODO: Check the role to add technical reviewer
      !this.disableFieldOnComplete() &&
      !this.isWorkflowDisabled() &&
      ((roleName === this.roleAdditionalReviewer &&
        !this.disableAddReviewerButton) ||
        roleName !== this.roleAdditionalReviewer) &&
      // For checking permission - true if other than Technical RM or Technical RM with permission
      (roleName !== this.roleTechnicalOrIndependentRM ||
        (roleName === this.roleTechnicalOrIndependentRM &&
          // We only have Workflow.AddTechnicalReviewer.Enable'] which we were using previously.
          this.permissionObj['isIntakeWorkflowTechnicalEnable']))
    ) {
      const newReviewer: SubmissionApprovers = {
        submissionApproverId: 0, // 0 indicates a new reviewer to be saved
        reviewerId: randomString(20), // Unique identifier
        displayName: this._EMPTY,
        fullName: this._EMPTY,
        emailId: this._EMPTY,
        location: this._EMPTY,
        reviewerRoleId: this.roleIdMapper?.[roleName], // Assign and increment reviewerRoleId
        isRequired: false, // Assuming all additional reviewers are required; adjust as needed
        roleName: roleName,
        isEditing: true, // Start in editing mode to show People Picker,
        isEditable: true,
        isForTechnicalReviewer: roleName === this.roleTechnicalOrIndependentRM,
        submissionApproverStatus: null,
        submissionApproverDocumentId: null,
        statusReviewedDate: null,
      };

      let addReviewerPresent;
      if (roleName === this.roleAdditionalReviewer) {
        // Only checking for reviewer role  as technical check is present at caller function
        addReviewerPresent = this.updatedRiskManagerDetails.some(
          (reviewer) =>
            reviewer.roleName === this.roleAdditionalReviewer &&
            reviewer.submissionApproverId === 0
        );
      }
      if (!addReviewerPresent) {
        this.updatedRiskManagerDetails.push(newReviewer);
        this.tempUpdatedRiskManagerDetails = structuredClone(
          this.updatedRiskManagerDetails
        );
        if (roleName === this.roleAdditionalReviewer) {
          this.disableAddReviewerButton = true;
        }
      }
    }
  }

  onMemberSelected(
    reviewerEmail: string,
    reviewerId: number | string,
    member: any
  ): void {
    let reviewerIndex = -1;

    if (
      reviewerId &&
      reviewerId != undefined &&
      reviewerId != null &&
      reviewerId != this._EMPTY
    ) {
      reviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (r) => r.reviewerId === reviewerId
      );
    } else {
      reviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (r) => r.emailId === reviewerEmail
      );
    }
    if (reviewerIndex > -1) {
      this.isAllReviewerValid = true;
      this.updatedRiskManagerDetails[reviewerIndex].isEditing = false;
      this.updatedRiskManagerDetails[reviewerIndex].displayName =
        member.displayName;
      this.updatedRiskManagerDetails[reviewerIndex].fullName = member.fullname;
      this.updatedRiskManagerDetails[reviewerIndex].emailId = member.email;
      this.updatedRiskManagerDetails[reviewerIndex].location = member.location;
      this.updatedRiskManagerDetails[reviewerIndex].reviewerId =
        member?.reviewerId ?? this._EMPTY;
      this.updatedRiskManagerDetails[reviewerIndex].isBackUpRM = false;

      this.tempUpdatedRiskManagerDetails = structuredClone(
        this.updatedRiskManagerDetails
      );

      this.#updateReviewers(
        UpdateReviewerActions.ADD,
        this.updatedRiskManagerDetails[reviewerIndex].reviewerRoleId
      );
    }
  }

  editReviewer(
    reviewerEmailId: string,
    submissionApproverId: number,
    isTechnicalReviewer: boolean
  ): void {
    if (
      // Check if the workflow is not disabled and the "Add Reviewer" button is not disabled
      // and technicalreviewer has permission
      !this.isWorkflowDisabled() &&
      !this.disableAddReviewerButton &&
      ((!isTechnicalReviewer &&
        this.permissionObj['isIntakeSubmissionWorkflowAddReviewerEnable']) ||
        (isTechnicalReviewer &&
          this.permissionObj['isIntakeWorkflowTechnicalEnable']))
    ) {
      let editReviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (reviewer) => reviewer.emailId && reviewer.emailId === reviewerEmailId
      );
      // If email not present in the list, then check with submissionApproverId if present
      if (editReviewerIndex < 0 && submissionApproverId > -1) {
        editReviewerIndex = this.updatedRiskManagerDetails.findIndex(
          (reviewer) =>
            reviewer.submissionApproverId &&
            reviewer.submissionApproverId === submissionApproverId
        );
      }
      if (editReviewerIndex > -1) {
        this.disableAddReviewerButton = true;

        this.updatedRiskManagerDetails[editReviewerIndex].isEditing = true;
        this.updatedRiskManagerDetails[editReviewerIndex].displayName =
          this._EMPTY;
        this.updatedRiskManagerDetails[editReviewerIndex].fullName =
          this._EMPTY;
        this.updatedRiskManagerDetails[editReviewerIndex].emailId = this._EMPTY;
        this.updatedRiskManagerDetails[editReviewerIndex].location =
          this._EMPTY;
        this.updatedRiskManagerDetails[editReviewerIndex].reviewerId =
          randomString(20);
      }
    }
  }

  //save on clicking outside of the input box
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (this.isEditingTitle && !target.closest('#title-editor')) {
      this.saveTitle();
    }

    this.updatedRiskManagerDetails.forEach((reviewer, index) => {
      if (
        reviewer.isEditable &&
        reviewer.isEditing &&
        !target.closest(`#picker-${index}`)
      ) {
        // Resetting to the state before editing

        if (reviewer.submissionApproverId !== 0) {
          reviewer.displayName =
            this.tempUpdatedRiskManagerDetails[index].displayName;
          reviewer.fullName =
            this.tempUpdatedRiskManagerDetails[index].fullName;
          reviewer.emailId = this.tempUpdatedRiskManagerDetails[index].emailId;
          reviewer.location =
            this.tempUpdatedRiskManagerDetails[index].location;
          reviewer.reviewerId =
            this.tempUpdatedRiskManagerDetails[index].reviewerId;

          reviewer.isEditing = false;
          this.disableAddReviewerButton = false;
        } else if (
          reviewer.submissionApproverId === 0 &&
          reviewer.roleName !== this.roleTechnicalOrIndependentRM
        ) {
          this.updatedRiskManagerDetails.splice(index, 1);
          this.tempUpdatedRiskManagerDetails = structuredClone(
            this.updatedRiskManagerDetails
          );

          reviewer.isEditing = false;
          this.disableAddReviewerButton = false;
        }
      }
    });
  }

  //initiate title editing
  editTitle(): void {
    this.isEditingTitle = true;
    this.model.submissionTitle = this.commonHelperService.trimPrefixEmptySpace(
      this.titleValue
    );
  }

  //save the title and update header
  saveTitle(): void {
    this.titleValue = this.model.submissionTitle;
    this.isEditingTitle = false;
    this.updateTitle.emit({
      submissionId: this.dataSubmissionId,
      updatedTitle: this.titleValue,
    });
  }

  //copy the ID to clipboard
  copyToClipboard(value: string | null): void {
    if (value) {
      copyToClipboard(value);
    }
  }

  loadSubmission(isLoaderShow: boolean = true): void {
    if (isLoaderShow) {
      this.progressIndicator.show();
    }
    const submissionId: string =
      this.dataSubmissionId !== null
        ? this.dataSubmissionId.toString()
        : this._EMPTY;
    this.employeeIdNumber = Number(this.submissionService.employeeId);
    this.SubmissionActionHistoryForAdminComplete(submissionId);
    this.submissionService.changeSubmissionId({
      opportunitynumber: this.dataOpportunityId,
      submissionid: this.dataSubmissionId,
    });
    if (submissionId !== this._EMPTY) {
      document.title =
        BrowserTabConstant.Browser_TabName_Default + submissionId;
      this.isOpportunitySelected = false;
      this.updatedRiskManagerDetails = [];
      this.tempUpdatedRiskManagerDetails = [];
      this.selectedRiskManagers = [];
      this.coHasParentOpportunityNumber = false;
      this.showWarningMessage = false;
      forkJoin([
        this.submissionService.getSubmissionBySubmissionId(
          this.dataOpportunityId,
          submissionId
        ),
        this.submissionService.getSubmissionReviewers(submissionId),
      ])
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            const submissionDetails = response[0];
            const reviewerDetailsFromReviewerApi = response[1];

            if (Object.keys(submissionDetails).length !== 0) {
              this.submissionData = { ...submissionDetails };
              this.submissionTypeId = Number(
                this.submissionData.submissionTypeId
              );
              this.submissionTypeSubId = Number(
                this.submissionData?.submissionSubTypeId
              );
              this.submissionStatusId = Number(
                this.submissionData.submissionStatusId
              );
              this.primaryDgmfId = this.submissionData?.dgmfId;
              this.primaryGmdmId = this.submissionData?.gmdmId;
              this.primarySwiftId = this.submissionData?.swiftId;
              this.isArchiveFeatureEnable =
                this.submissionData.isArchiveItIntegrationEnabled;
              this.titleValue = this.submissionData.submissionTitle;
              this.submissionId = this.submissionData.submissionId;
              this.lepAcknowledgedBy = this.submissionData.lepAcknowledgedBy;
              this.lepAcknowledgementDate =
                this.submissionData.lepAcknowledgementDate;
              this.lepReviewedBy = this.submissionData.lepReviewedBy;
              this.submissionStatus = this.submissionData.submissionStatusName;
              this.archiveDetails = {
                archiveName: this.submissionData.archiveName,
                archiveNumber: this.submissionData.isArchiveNotAvailable
                  ? IntakeConstant.ARCHIVE_NOT_LISTED
                  : this.submissionData.archiveNumber,
                archiveDescription: this.submissionData.isArchiveNotAvailable
                  ? IntakeConstant.ARCHIVE_NOT_LISTED
                  : this.submissionData.archiveDescription,
              };
              this.model.wbsChargeCode = this.submissionData.wbsChargeCode;
              this.model.wbsDescription = this.submissionData.wbsDescription;
              this.model.isArchiveNotAvailable =
                this.submissionData.isArchiveNotAvailable;
              this.archiveNumberSelected =
                this.submissionData.archiveNumber ?? IntakeConstant.EMPTY_SPACE;
              this.swiftDetails = {
                projectName: this.submissionData.swiftDetails?.projectName,
                projectStartDate:
                  this.submissionData.swiftDetails?.projectStartDate,
                projectEndDate:
                  this.submissionData.swiftDetails?.projectEndDate,
                projectPPMD: this.submissionData.swiftDetails?.projectPPMD,
              };
              if (
                this.lepAcknowledgedBy === null &&
                this.lepAcknowledgementDate === null &&
                this.lepReviewedBy === null
              ) {
                this.showLepConfirmationMessage = true;
              } else {
                this.showLepConfirmationMessage = false;
              }

              if (
                this.submissionTypeCode ===
                this.intakeDocumentSubmissionTypeCodeOther
              ) {
                this.model.submissionSubTypeId =
                  this.submissionData.submissionSubTypeId?.toString() ??
                  this._EMPTY;
              }

              if (
                this.submissionTypeCode ===
                this.intakeDocumentSubmissionTypeCodeCO
              ) {
                this.checkCOhasParentOpportunityNoOrNot();
              }
              this.selfReviewAllOperationSet();
              this.submissionService.setSelectedOpportunityDocStatus(
                (this.submissionStatus as IntakeOppertunitySubmissionStatus) ??
                  null
              );

              this.selfReviewEligibility =
                this.submissionData.selfReviewEligibilityStatus;
              this.model.coChangeScope =
                IntakeConstant.BOOLEAN_CHECKBOX_VALUE_TO_YES_NO.get(
                  this.submissionData.coChangeScope
                );
              this.model.coDescription = this.submissionData.coDescription;
              this.model.submissionTitle =
                this.commonHelperService.trimPrefixEmptySpace(
                  this.submissionData.submissionTitle
                );
              this.model.otherDescription =
                this.submissionData.otherDescription;
              this.model.proposalDescription =
                this.submissionData.proposalDescription;
              this.model.relatedToRFP =
                IntakeConstant.BOOLEAN_CHECKBOX_VALUE_TO_YES_NO.get(
                  this.submissionData.relatedToRFP
                );
              this.model.delDescription = this.submissionData.delDescription;
              this.model.notesAndFeedback =
                this.submissionData.notesAndFeedback;
              this.model.internalQRMTeamCommunication =
                this.submissionData.internalQRMTeamCommunication;
              this.model.winningProposal = this.submissionData.winningProposal;
              this.isEligibleTechnicalReviewer =
                this.submissionData.isEligibleTechnicalReviewer;
              this.model.archiveName = this.submissionData.archiveName;

              this.model.archiveNumber = this.submissionData.archiveNumber;
              this.model.archiveDescription =
                this.submissionData.archiveDescription;
              this.model.archiveStatus = this.submissionData.archiveStatus;
              this.model.otherUpdatedVersionForReview =
                this.submissionData.otherUpdatedVersionForReview;
              this.showCloseOutForm = false;
              setTimeout(() => {
                this.showCloseOutForm = true;
              }, 100);
              this.reviewerDetailsFromReviewerApi =
                reviewerDetailsFromReviewerApi;
              this.setWorkflowReviewersDetails(
                this.reviewerDetailsFromReviewerApi
              );
              this.optOutOfSelfReviewReason =
                submissionDetails?.reasonForOptOutSelfReview;
              if (
                this.optOutOfSelfReviewReason &&
                this.submissionStatus ===
                  IntakeOppertunitySubmissionStatus.DRAFT
              ) {
                this.showSubmitBtnWhnDraft = true;
              } else {
                this.showSubmitBtnWhnDraft = false;
              }
              // --------------- close out change order section start ----------------
              this.setCoElSowCloseOutDetails();
              // --------------- close out change order section end ----------------

              // TODO: Once Employee ID is implemented
              // this.checkActionsColumn();

              this.isEmployeePresent = this.updatedRiskManagerDetails.some(
                (employee: SubmissionApprovers) =>
                  Number(employee.reviewerId) === this.employeeIdNumber
              );

              this.submissionService.enableAdminComplete(
                this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()
              );
              this.commonService.enableResetStatus(
                this.showResetStatusDropdown()
              );
              this.submissionService.disableDocumentButtonOnAdminComplte(
                this.checkStatusCompletedByAdmin()
              );
            }
            if (
              this.submissionData.submissionTypeCode ===
              this.intakeDocumentSubmissionTypeCodeCO
            ) {
              this.isOpporunityLevelFormCompleted = true;
            } else {
              this.isOpporunityLevelFormCompleted =
                this.submissionData.isOpportunityDetailsCompleted;
            }
            this.model.updatedVersionForReview =
              submissionDetails.updatedVersionForReview;
            this.otherSubmissionValueSelected =
              submissionDetails.otherUpdatedVersionForReview;
            this.isSubmissionStatusRMorSelfReviewed =
              this.submissionStatus ===
                IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT ||
              this.submissionStatus ===
                IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT;
            this.isSubmissionStatusRMorSelfReviewed
              ? (this.collapseSelfReviewAccordion = false)
              : (this.collapseSelfReviewAccordion = true);
            this.checkButtonVisibility();
            this.progressIndicator.hide();

            this.getUploadedDocumentData();
          },
          error: (err: HttpErrorResponse) => {
            this.progressIndicator.hide();
            console.error('Error fetching submission', err);
          },
        });
    } else {
      this.isOpportunitySelected = true;
    }
    if (isLoaderShow) {
      this.progressIndicator.hide();
    }
  }

  isProposalAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isDeliverableAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isOtherAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isCOAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isAccordianOpenCommon(): boolean {
    return (
      this.submissionStatus != this._EMPTY &&
      this.submissionStatus !==
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT &&
      this.submissionStatus !==
        IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
    );
  }

  isAccordionVisible(
    submissionCodeType: IntakeDocumentSubmissionTypeCode
  ): boolean {
    return (
      this.submissionTypeCode != this._EMPTY &&
      this.submissionTypeCode === submissionCodeType
    );
  }

  isCloseOutAccordionVisible(): boolean {
    return (
      this.submissionStatus != this._EMPTY &&
      this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()
    );
  }

  proposalDescriptionValueChanged(): void {
    this.checkValidation();
  }

  RelatedtoRFPstateChanged(): void {
    this.checkValidation();
  }

  deliverableDescValueChanged(): void {
    this.checkValidation();
  }

  internalQrmCommunicationInputValueChanged(): void {}
  feedbackInputValueChanged(): void {}
  OtherDescriptionValueChanged(): void {
    this.checkValidation();
  }

  stateHandling(): void {
    this.apiCallHandling = true;
  }

  COChangeScopeStateChanged(): void {
    if (this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeCO) {
      this.checkValidation();
    }
    this.submissionService
      .getSubmissionBySubmissionId(
        this.dataOpportunityId,
        this.submissionId.toString()
      )
      .subscribe({
        next: (
          response: IntakeSubmissionDetailsCommonSectionDataReceivedModal
        ) => {
          this.submissionData = { ...response };
          this.selfReviewEligibility =
            this.submissionData.selfReviewEligibilityStatus;
          this.isSelfReviewButtonVisible =
            this.selfReviewEligibility == 'Yes' ? true : false;
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  CODescriptionValueChanged(): void {
    this.checkValidation();
  }

  clickToShowDocumentMoreLepMessage(): void {
    this.isShowMoreDocumentNotes = !this.isShowMoreDocumentNotes;
    if (!this.isShowMoreDocumentNotes) {
      this.showMoreText = IntakeConstant.SHOW_MORE_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_DOWN;
    } else {
      this.showMoreText = IntakeConstant.SHOW_LESS_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_UP;
    }
  }

  checkCOhasParentOpportunityNoOrNot(): void {
    this.submissionService
      .getOpportunitySelected(this.dataOpportunityId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: latestOpportunityDetails) => {
          this.coHasParentOpportunityNumber =
            (data?.parentOpportunityNumber == null ||
              data?.parentOpportunityNumber == this._EMPTY) &&
            this.opportunityDocsCount === 1;
          this.isCheckboxCheckedForNoEL_SOW = this.coHasParentOpportunityNumber;
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  handleCompleteReviewFromHeader(): void {
    const data: SubmissionApprovers | undefined =
      this.updatedRiskManagerDetails.find(
        (item) => item.reviewerId === this.currentLoggedInUser
      );
    if (data) this.handleCompleteReview(data);
  }

  handleReturnForReworkFromHeader(): void {
    this.handleReturnforRework({} as SubmissionApprovers);
  }

  handleCompleteReview(data: SubmissionApprovers): void {
    const allDocumentsReviewed = this.documentTableData.every(
      (doc) => doc.documentStatus === 'RM Reviewed'
    );
    const requiredReviewers = this.updatedRiskManagerDetails.filter(
      (reviewer: SubmissionApprovers) => reviewer.isRequired
    );
    const pendingReviewers = requiredReviewers.filter(
      (reviewer) => !reviewer.statusReviewedDate
    ).length;
    if (!data.isRequired || pendingReviewers > 1 || (pendingReviewers === 1 && allDocumentsReviewed)) {
      this.updateRMStatusHandler(data);
    } else if (pendingReviewers === 1) {
      this.openModal(data);
    }
  }

  updateRMStatusHandler(data: SubmissionApprovers): void {
    const saveSelectedDocuments: IntakeSubmissionDeatilsCommonSectionWorkflowSelectedDocumentsModal =
      {
        SubmissionDocumentId: [],
        RMEmployeeid: data.reviewerId,
        Email: data.emailId,
        SubmissionId: +this.submissionId,
        DocumentStatusId: 2,
        SubmissionTypeId: this.submissionTypeId,
      };
    this.progressIndicator.show();
    this.submissionService
      .updateRMStatus(saveSelectedDocuments)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.progressIndicator.hide();
          this.loadSubmission();
          this.documentComponent.triggerDocumentTableDataApi();
          this.updateLeftMenu.emit(this.opportunityNumber);
          this.setToast(this.toastOptions);
          this.timeoutId = setTimeout(() => {
            this.router.navigate(['/']);
          }, IntakeConstant.SET_TIMEOUT_DURATION);
        },
        error: (err: HttpErrorResponse) => {
          this.progressIndicator.hide();
          console.error('An error occurred while sending the data ', err);
        },
      });
  }

  handleReturnforRework(data: SubmissionApprovers): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK
    );
    if (submissionStatusId) {
      this.progressIndicator.show();
      this.submissionService
        .submitCommonSectionData(this.submissionId, submissionStatusId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: string) => {
            this.progressIndicator.hide();
            this.submissionStatusCommonSection = response;
          },
          error: (err: HttpErrorResponse) => {
            this.progressIndicator.hide();
            console.error('An error occurred during submission: ', err);
          },
        });
      this.loadSubmission();
      this.documentComponent.triggerDocumentTableDataApi();
      this.updateLeftMenu.emit(this.opportunityNumber);
      this.setToast(this.returnForReworkToastOption);
      this.timeoutId = setTimeout(() => {
        this.router.navigate(['/']);
      }, IntakeConstant.SET_TIMEOUT_DURATION);
    }
  }

  resubmitCommonSectionData(): void {
    if (this.permissionObj['isIntakeSubmissionReSubmitBtnEnable']) {
      const submissionStatusId = this.getSubmissionStatusCode(
        IntakeOppertunitySubmissionStatus.RESUBMITTED
      );
      if (submissionStatusId) {
        this.progressIndicator.show();
        this.submissionService
          .submitCommonSectionData(this.submissionId, submissionStatusId)
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe({
            next: (response: string) => {
              this.submissionStatusCommonSection = response;
              this.progressIndicator.hide();
            },
            error: (err: HttpErrorResponse) => {
              console.error('An error occurred during submission: ', err);
              this.progressIndicator.hide();
            },
          });
        const rmSupport = this.updatedRiskManagerDetails.find(
          (reviewer) => reviewer.roleName === this.roleRiskManager
        );
        if (
          rmSupport &&
          (rmSupport.displayName !== this._EMPTY ||
            rmSupport.fullName !== this._EMPTY)
        ) {
          this.setToast(this.resubmittedToastOption);
        } else {
          this.setToast(this.withoutRMToastOption);
        }
        this.loadSubmission();
        this.documentComponent.triggerDocumentTableDataApi();
        this.updateLeftMenu.emit(this.opportunityNumber);

        this.timeoutId = setTimeout(() => {
          this.router.navigate(['/']);
        }, IntakeConstant.SET_TIMEOUT_DURATION);
      }
    }
  }

  openModal(rowDetails: SubmissionApprovers): void {
    const prescreenConfirmModalRef = this.modalService.open(
      DocumentSelectionPopupComponent,
      {
        submissionID: this.submissionId,
        rowDetails: rowDetails,
        submissionTypeId: this.submissionTypeId,
      }
    );
    prescreenConfirmModalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (showToastMessage: boolean) => {
          if (this.submissionId && showToastMessage) {
            this.loadSubmission();
            this.documentComponent.triggerDocumentTableDataApi();
            this.updateLeftMenu.emit(this.opportunityNumber);
            if (showToastMessage) {
              this.setToast(this.toastOptions);
              this.timeoutId = setTimeout(() => {
                this.router.navigate(['/']);
              }, IntakeConstant.SET_TIMEOUT_DURATION);
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  setWorkflowReviewersDetails(
    reviewerDetailsFromGetReviewerApi: SubmissionApprovers[]
  ): void {
    this.updatedRiskManagerDetails = [];
    this.tempUpdatedRiskManagerDetails = [];
    this.selectedRiskManagers = [];
    const reviewerDetails = reviewerDetailsFromGetReviewerApi;

    const addPlaceholder = (roleName: string) => {
      const newReviewer: SubmissionApprovers = {
        submissionApproverId: 0, // 0 indicates a new reviewer to be saved
        reviewerId: randomString(20), // Unique identifier
        displayName: this._EMPTY,
        fullName: this._EMPTY,
        emailId: this._EMPTY,
        location: this._EMPTY,
        reviewerRoleId: this.roleIdMapper?.[roleName], // Assign and increment reviewerRoleId
        isRequired: false,
        roleName: roleName,
        isEditing: false,
        isEditable: false,
        isForTechnicalReviewer: roleName === this.roleTechnicalOrIndependentRM,
        submissionApproverStatus: null,
        submissionApproverDocumentId: null,
        statusReviewedDate: null,
      };
      this.updatedRiskManagerDetails.push(newReviewer);
    };

    // Empty placeholder are removed in updatereviewer()
    this.showRiskManagerLabel = !reviewerDetails.some(
      (reviewer) => reviewer.roleName === this.roleRiskManager
    );
    if (this.showRiskManagerLabel) addPlaceholder(this.roleRiskManager);

    this.showSupportingRiskManagerLabel = !reviewerDetails.some(
      (reviewer) => reviewer.roleName === this.roleSupportingRiskManager
    );
    if (this.showSupportingRiskManagerLabel)
      addPlaceholder(this.roleSupportingRiskManager);

    this.showSpecializedRiskManagerLabel = !reviewerDetails.some(
      (reviewer) => reviewer.roleName === this.roleSpecializedRiskManager
    );
    if (this.showSpecializedRiskManagerLabel)
      addPlaceholder(this.roleSpecializedRiskManager);

    reviewerDetails.forEach((element) => {
      const findUserDetailsFromReviewerApi: SubmissionApprovers[] =
        this.findUserByEmailFromResponse(element.emailId);

      if (element.isRequired) {
        this.pushInSelectedRiskManagers(element.emailId);
      }

      const existingReviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (reviewer: SubmissionApprovers) => reviewer.emailId === element.emailId
      );

      // If reviewer is not present in the list, then add it
      if (
        existingReviewerIndex === -1 &&
        (element.roleName !== this.roleTechnicalOrIndependentRM ||
          (element.roleName === this.roleTechnicalOrIndependentRM &&
            this.permissionObj['isIntakeWorkflowTechnicalEnable']))
      ) {
        this.updatedRiskManagerDetails.push({
          ...element,
          isRequired: element.isRequired ?? false,
          reviewerId: this.getReviewerId(
            element.reviewerId,
            findUserDetailsFromReviewerApi[0]?.reviewerId
          ),
          isEditing: false,
          isEditable:
            element.roleName === this.roleRiskManager ||
            element.roleName === this.roleSupportingRiskManager ||
            element.roleName === this.roleSpecializedRiskManager
              ? false
              : true,
          isForTechnicalReviewer:
            element.roleName === this.roleTechnicalOrIndependentRM,
        });

        this.tempUpdatedRiskManagerDetails = structuredClone(
          this.updatedRiskManagerDetails
        );
      }
    });

    const technicalPresent = this.updatedRiskManagerDetails.some(
      (reviewer) => reviewer.roleName === this.roleTechnicalOrIndependentRM
    );

    if (this.isEligibleTechnicalReviewer && !technicalPresent) {
      this.addReviewer(this.roleTechnicalOrIndependentRM);
    }

    this.sortInOrderReviewerTable();
  }

  sortInOrderReviewerTable(): void {
    // Sorting the reviewers based on the specified order
    const rolesOrder = [
      this.roleRiskManager,
      this.roleSupportingRiskManager,
      this.roleSpecializedRiskManager,
      this.roleTechnicalOrIndependentRM,
    ];

    const updatedReviewerList = rolesOrder.flatMap((role: string) =>
      this.updatedRiskManagerDetails.filter(
        (reviewer: SubmissionApprovers) => reviewer.roleName === role
      )
    );

    const remainingReviewers: SubmissionApprovers[] =
      this.updatedRiskManagerDetails.filter(
        (reviewer: SubmissionApprovers) =>
          reviewer.roleName !== null && !rolesOrder.includes(reviewer.roleName)
      );

    this.updatedRiskManagerDetails = [
      ...updatedReviewerList,
      ...remainingReviewers.sort(
        (reviewer1: SubmissionApprovers, reviewer2: SubmissionApprovers) =>
          (reviewer1.submissionApproverId ?? 0) -
          (reviewer2.submissionApproverId ?? 0)
      ),
    ];
  }

  pushInSelectedRiskManagers(emailId: string): void {
    if (!this.selectedRiskManagers.includes(emailId)) {
      this.selectedRiskManagers.push(emailId);
    }
  }

  getReviewerId(
    reviewerIdFromGetReviewerApi: string | number,
    reviewerIdFromGetSubmissionApi: string | number
  ): string | number {
    if (
      reviewerIdFromGetReviewerApi &&
      reviewerIdFromGetReviewerApi != null &&
      reviewerIdFromGetReviewerApi != this._EMPTY
    ) {
      return reviewerIdFromGetReviewerApi;
    } else if (
      reviewerIdFromGetSubmissionApi &&
      reviewerIdFromGetSubmissionApi != null &&
      reviewerIdFromGetSubmissionApi != this._EMPTY
    ) {
      return reviewerIdFromGetSubmissionApi;
    } else {
      return this._EMPTY;
    }
  }

  // TODO: Remove if it's not necessary - As we have updated hte apis
  findUserByEmailFromResponse(reviewerMaiId: string): SubmissionApprovers[] {
    const submissionReviewerFromReviewersApi = [
      ...this.reviewerDetailsFromReviewerApi,
    ];
    let submissionReviewer = submissionReviewerFromReviewersApi.filter(
      (user: SubmissionApprovers) => user.emailId === reviewerMaiId
    );
    return submissionReviewer.length > 0 ? submissionReviewer : [];
  }

  handleLEPCompletion(): void {
    this.progressIndicator.show();
    this.submissionService
      .putLepStatus(
        this.dataSubmissionId,
        this.currentLoggedInUser,
        this.dataLepValue.lepReviewedBy
      )
      .subscribe(() => {
        this.progressIndicator.hide();
        this.loadSubmission();
      });
  }

  getLabelConfiguration(): void {
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response) {
            this.configData = response;
            this.UPLOAD_DOCUMENTS_LABEL_DRAFT =
              this.configData.find(
                (config: configurationType) =>
                  config.appConfigurationLabelKey === 'UPLOAD_DOCUMENTS'
              )?.appConfigurationLabelValue || this._EMPTY;
            if (
              this.submissionTypeCode ===
              this.intakeDocumentSubmissionTypeCodeCO
            ) {
              if (isTheDocumentHasCompletedElSOw(this.opportunityDocs)) {
                this.configLableDocument = this.UPLOAD_DOCUMENTS_LABEL_DRAFT;
              } else {
                this.configLableDocument =
                  this.configData.find(
                    (config: configurationType) =>
                      config.appConfigurationLabelKey === 'UPLOAD_CO_DOCUMENTS'
                  )?.appConfigurationLabelValue || this._EMPTY;
              }

              this.coOppDonotMatchMessage =
                this.configData.find(
                  (config: configurationType) =>
                    config.appConfigurationLabelKey ===
                    IntakeConstant.CO_OPP_DONOT_MATCH
                )?.appConfigurationLabelValue || this._EMPTY;
            } else {
              this.configLableDocument = this.UPLOAD_DOCUMENTS_LABEL_DRAFT;
            }

            this.rmReviewedGuidanceNoteHeader =
              this.configData.find(
                (config: configurationType) =>
                  config.appConfigurationLabelKey ===
                  IntakeConstant.RM_REVIEWED_GUIDANCE_NOTE_HEADER
              )?.appConfigurationLabelValue || this._EMPTY;

            this.rmReviewedGuidanceNoteBody =
              this.configData.find(
                (config: configurationType) =>
                  config.appConfigurationLabelKey ===
                  IntakeConstant.RM_REVIEWED_GUIDANCE_NOTE_HEADER
              )?.appConfigurationLabelValue || this._EMPTY;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  updateSubmitButtonStatus(): boolean {
    if (
      (this.changeOrderStatus &&
        this.co_draft_Document_Uploaded &&
        this.model.coChangeScope &&
        this.model.coDescription &&
        !this.showLepConfirmationMessage) ||
      (this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeCO &&
        this.model.coChangeScope &&
        this.model.coDescription &&
        this.isValidDocumentUploaded &&
        this.co_draft_Document_Uploaded &&
        !this.showLepConfirmationMessage) ||
      (this.submissionTypeCode ===
        this.intakeDocumentSubmissionTypeCodeDelivery &&
        this.model.delDescription &&
        this.isValidDocumentUploaded &&
        !this.showLepConfirmationMessage) ||
      (this.isValidDocumentUploaded &&
        this.model.otherDescription &&
        this.model.submissionSubTypeId &&
        this.intakeDocumentSubmissionTypeCodeOther) ||
      (this.isValidDocumentUploaded &&
        this.model.relatedToRFP &&
        this.model.proposalDescription &&
        !this.showLepConfirmationMessage) ||
      (this.isValidDocumentUploaded &&
        this.submissionTypeCode ===
          this.intakeDocumentSubmissionTypeCodeELSOW &&
        !this.showLepConfirmationMessage)
    ) {
      return false;
    }
    return true;
  }

  submitCommonSectionData(statusCode: IntakeOppertunitySubmissionStatus): void {
    const submittedubmissionStatusID = this.getSubmissionStatusCode(statusCode);
    const IsValidArchive = this.model.archiveStatus
      ? IntakeConstant.VALID_ARCHIVE_LIST.includes(this.model.archiveStatus)
      : false;
    if (submittedubmissionStatusID) {
      this.progressIndicator.show();

      this.submissionService
        .submitCommonSectionData(
          this.submissionId,
          submittedubmissionStatusID,
          null,
          this.submissionTypeId,
          IsValidArchive
        )
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: string) => {
            this.progressIndicator.hide();
            this.submissionStatusCommonSection = response;
            if (response) {
              this.updateAfterSubmit(true);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.progressIndicator.hide();
            this.updateAfterSubmit(false);
            console.error('An error occurred during submission: ', error);
          },
        });
    }
  }

  updateAfterSubmit(navigate: boolean): void {
    const rmSupport = this.updatedRiskManagerDetails.find(
      (reviewer) => reviewer.roleName === this.roleRiskManager
    );
    if (
      rmSupport &&
      (rmSupport.displayName !== this._EMPTY ||
        rmSupport.fullName !== this._EMPTY)
    ) {
      this.setToast(this.submittedToastOption);
    } else {
      this.setToast(this.withoutRMToastOption);
    }
    this.loadSubmission();

    this.updateLeftMenu.emit(this.opportunityNumber);

    if (navigate) {
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 3000);
    }
  }

  isSubmitBtnVisible(): boolean {
    return (
      this.permissionObj['isIntakeSubmissionSubmitBtnVisible'] &&
      ((this.submissionStatus === IntakeOppertunitySubmissionStatus.DRAFT &&
        this.selfReviewEligibility != IntakeConstant.YES) ||
        this.showSubmitBtnWhnDraft)
    );
  }

  isResubmitBtnVisible(): boolean {
    return (
      this.permissionObj['isIntakeSubmissionReSubmitBtnVisible'] &&
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK
    );
  }

  isSubmitForReviewBtnVisible(): boolean {
    return (
      this.permissionObj['isIntakeSubmissionReSubmitBtnVisible'] &&
      this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()
    );
  }

  isArchiveAndCompleteBtnVisible(): boolean {
    return (
      this.permissionObj['isIntakeSubmissionArchiveAndCompleteBtnVisible'] &&
      this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()
    );
  }

  isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout(): boolean {
    return (
      this.isSelfReviewPendingCloseout() || this.isRMReviewPendingCloseout()
    );
  }

  isSelfReviewPendingCloseout(): boolean {
    return (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
    );
  }

  isRMReviewPendingCloseout(): boolean {
    return (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT
    );
  }

  showResetStatusDropdown(): boolean {
    return (
      this.isStatusCompleted() ||
      this.submissionStatus === IntakeOppertunitySubmissionStatus.DISCONTINUED
    );
  }

  onclickArchiveAndCompleteBtn(): void {
    if (this.permissionObj['isIntakeSubmissionArchiveAndCompleteBtnEnable']) {
      const submissionStatusId = this.getSubmissionStatusCode(
        IntakeOppertunitySubmissionStatus.COMPLETED
      );
      const IsValidArchive = IntakeConstant.VALID_ARCHIVE_LIST.includes(
        this.model.archiveStatus ? this.model.archiveStatus : ''
      );
      this.progressIndicator.show();
      if (
        this.isArchiveFeatureEnable &&
        this.archiveNumberSelected != IntakeConstant.EMPTY_SPACE &&
        this.archiveNumberSelected != null &&
        this.archiveNumberSelected != undefined &&
        this.archiveNumberSelected != IntakeConstant.ARCHIVE_NOT_LISTED
      ) {
        this.documentService
          .generateArchiveSubmissionPDF(
            this.submissionId,
            this.opportunityNumber
          )
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe({
            next: () => {
              this.documentService
                .getDocumentArchiveId(
                  this.archiveNumberSelected ?? IntakeConstant.EMPTY_SPACE,
                  this.submissionId,
                  this.submissionTypeId,
                  this.model.submissionSubTypeId ?? null
                )
                .pipe(takeUntil(this.unsubscriber$))
                .subscribe({
                  next: () => {
                    const documentArchiveId: null = null;
                    this.submissionService
                      .submitCommonSectionData(
                        this.submissionId,
                        submissionStatusId,
                        documentArchiveId,
                        this.submissionTypeId
                      )
                      .pipe(takeUntil(this.unsubscriber$))
                      .subscribe({
                        next: (response: string) => {
                          this.submissionStatusCommonSection = response;
                          this.progressIndicator.hide();
                        },
                        error: (err: HttpErrorResponse) => {
                          this.progressIndicator.hide();
                          console.error(
                            'An error occurred updating document status as completed with archiveid : ',
                            err
                          );
                        },
                      });
                    this.updateLeftMenu.emit(this.opportunityNumber);
                    this.setToast(this.compltedToastOption);
                    this.timeoutId = setTimeout(() => {
                      this.router.navigate(['/']);
                    }, IntakeConstant.SET_TIMEOUT_DURATION_BIGGER);
                  },
                  error: (err: HttpErrorResponse) => {
                    this.progressIndicator.hide();
                    console.error(
                      'An error occurred getting document archive id: ',
                      err
                    );
                  },
                });
            },
            error: (err: HttpErrorResponse) => {
              this.progressIndicator.hide();
              console.error('An error on pdf generate api ', err);
            },
          });
      } else {
        const documentArchiveId: number = 0;
        this.submissionService
          .submitCommonSectionData(
            this.submissionId,
            submissionStatusId,
            documentArchiveId,
            this.submissionTypeId,
            IsValidArchive
          )
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe({
            next: (response: string) => {
              this.submissionStatusCommonSection = response;
            },
            error: (err: HttpErrorResponse) => {
              this.progressIndicator.hide();
              console.error(
                'An error occurred updating document status as completed with archiveid : ',
                err
              );
            },
          });
        this.updateLeftMenu.emit(this.opportunityNumber);
        this.progressIndicator.hide();
        this.setToast(this.compltedToastOption);
        this.timeoutId = setTimeout(() => {
          this.router.navigate(['/']);
        }, IntakeConstant.SET_TIMEOUT_DURATION_BIGGER);
      }
    }
  }

  onclickResubmitForReviewBtn(): void {
    if (this.permissionObj['isIntakeSubmissionReSubmitBtnEnable']) {
      const submissionStatusId = this.getSubmissionStatusCode(
        IntakeOppertunitySubmissionStatus.RESUBMITTED
      );
      if (submissionStatusId) {
        this.progressIndicator.show();
        this.submissionService
          .submitCommonSectionData(this.submissionId, submissionStatusId)
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe({
            next: (response: string) => {
              this.submissionStatusCommonSection = response;
              this.progressIndicator.hide();
            },
          });
        this.timeoutId = setTimeout(() => {
          this.loadSubmission();
          this.updateLeftMenu.emit(this.opportunityNumber);
          this.router.navigate(['/']);
        }, IntakeConstant.SET_TIMEOUT_DURATION);
      }
    }
  }

  onChangeWinningProposalInCloseOut(
    winningProposalValue: string,
    calledFrom: number
  ): void {
    this.model.winningProposal = winningProposalValue;
    this.modalSetting = null;
    this.showUpdatedDocumentMessage = false;
    this.isDocumentRequireMsgShow = false;
    if (calledFrom === 0) {
      this.documentComponent.triggerDocumentTableDataApi();
    }
    if (this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()) {
      if (winningProposalValue === WinningProposalOptionValues.YES) {
        this.progressiveStatus = true;
        this.isDocumentRequireMsgShow = true;

        const showArchiveButton = this.checkDocumentStatus(
          IntakeConstant.FINAL_ARCHIVE_READY_VERSION
        );
        if (showArchiveButton) {
          this.isArchiveAndCompleteBtnDisabled = false;
          this.isResubmitForReviewBtnDisabled = true;
          this.isFinalDocumentAcceptedMsgShow = false;
        } else {
          this.isFinalDocumentAcceptedMsgShow = true;
          this.isArchiveAndCompleteBtnDisabled = true;
          this.isResubmitForReviewBtnDisabled = true;
        }
      } else if (
        winningProposalValue ===
        WinningProposalOptionValues.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED
      ) {
        this.modalSetting = {
          selectedOption:
            WinningProposalOptionValues.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED,
        };
        this.#closeOutModalOpen();
      } else if (
        winningProposalValue ===
        WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW
      ) {
        this.progressiveStatus = false;
        this.isArchiveAndCompleteBtnDisabled = true;
        this.setDraftFlags();
      } else {
        this.model.winningProposal = null;
      }
    }
    this.updateCloseoutBanner();
  }

  setWbsAndArchiveValueBeforeSavingToBe(wbsArchiveValue: any): void {
    // TODO: Need to correct the length condition being checked
    //
    this.submissionService.onLoadSubmissionPage$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (status) => {
          this.pageFreshlyLoaded = status;
        },
        error: (err) => {
          console.error('Error fetching submission page status:', err);
        },
      });
    if (!this.pageFreshlyLoaded) {
      if (wbsArchiveValue.archiveNumber == IntakeConstant.ARCHIVE_NOT_LISTED) {
        this.model.archiveNumber = null;
        this.model.isArchiveNotAvailable = true;
      } else {
        this.model.archiveNumber = wbsArchiveValue.archiveNumber
          ? wbsArchiveValue.archiveNumber
          : this.submissionData?.archiveNumber;
        this.model.isArchiveNotAvailable = false;
      }
      this.model.archiveName = wbsArchiveValue.archiveName
        ? wbsArchiveValue.archiveName
        : this.submissionData?.archiveName;
      this.model.archiveID =
        wbsArchiveValue.archiveID?.length > 0
          ? wbsArchiveValue.archiveID
          : null;
      this.model.archiveFolder =
        wbsArchiveValue.archiveFolder?.length > 0
          ? wbsArchiveValue.archiveFolder
          : null;
      this.model.archiveDescription =
        wbsArchiveValue.archiveDescription?.length > 0
          ? wbsArchiveValue.archiveDescription
          : this.submissionData?.archiveDescription;
      this.model.archiveStatus = wbsArchiveValue.archiveStatus
        ? wbsArchiveValue.archiveStatus
        : this.submissionData?.archiveStatus;
      this.model.archiveFoldersArray =
        wbsArchiveValue.archiveFoldersArray?.length > 0
          ? wbsArchiveValue.archiveFoldersArray
          : null;
      this.model.business =
        wbsArchiveValue.business?.length > 0 ? wbsArchiveValue.business : null;
      this.model.clientNumber =
        wbsArchiveValue.clientNumber?.length > 0
          ? wbsArchiveValue.clientNumber
          : null;
      this.model.clientName =
        wbsArchiveValue.clientName?.length > 0
          ? wbsArchiveValue.clientName
          : null;
      this.model.partner =
        wbsArchiveValue.partner?.length > 0 ? wbsArchiveValue.partner : null;
    }

    this.model.isWBSPending = wbsArchiveValue.wbsPending;
    this.model.wbsChargeCode = wbsArchiveValue.wbsChargeCode
      ? wbsArchiveValue.wbsChargeCode
      : this.model.wbsChargeCode;
    this.model.wbsDescription = wbsArchiveValue.wbsDescription
      ? wbsArchiveValue.wbsDescription
      : this.model.wbsDescription;
  }

  onChangeOtherWithWbsArchiveInCloseOut(
    OtherWithWbsArchiveValue: OtherWithWbsArchiveEventEmitterModel,
    calledFrom: number
  ): void {
    const otherValue = OtherWithWbsArchiveValue.other;
    this.modalSetting = null;
    this.setWbsAndArchiveValueBeforeSavingToBe(OtherWithWbsArchiveValue);
    this.isArchiveNotListed =
      OtherWithWbsArchiveValue.archiveNumber ===
      IntakeConstant.ARCHIVE_NOT_LISTED;
    if (calledFrom === 0) {
      this.documentComponent?.triggerDocumentTableDataApi();
      this.model.otherUpdatedVersionForReview = OtherWithWbsArchiveValue.other;
      this.#saveCommonSectionDetails();
    }
    if (this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()) {
      if (otherValue === OtherOptionValues.YES) {
        this.submissionService.updateOtherSubmissionValue(
          OtherOptionValues.YES
        );

        const isArchiveReadyDocAvailable = this.checkDocumentStatus(
          IntakeConstant.FINAL_ARCHIVE_READY_VERSION
        );
        this.iswbsCheckboxSelected =
          OtherWithWbsArchiveValue.wbsPending ?? false;
        this.archiveNumberSelected = OtherWithWbsArchiveValue.archiveNumber;
        let showArchiveButton = false;
        if (
          this.iswbsCheckboxSelected ||
          (OtherWithWbsArchiveValue.wbsChargeCode !=
            IntakeConstant.EMPTY_SPACE &&
            OtherWithWbsArchiveValue.wbsChargeCode != null &&
            OtherWithWbsArchiveValue.archiveNumber !==
              IntakeConstant.EMPTY_SPACE &&
            OtherWithWbsArchiveValue.archiveNumber !== null &&
            OtherWithWbsArchiveValue.archiveNumber !== undefined)
        ) {
          showArchiveButton = isArchiveReadyDocAvailable;
        }

        this.showUploadFinalVersionMessage = !showArchiveButton;
        this.isArchiveAndCompleteBtnDisabled = !showArchiveButton;
        this.showUpdatedDocumentMessage = false;
        this.isResubmitForReviewBtnDisabled = true;
      } else if (otherValue === OtherOptionValues.NO_RESUBMIT_FOR_REVIEW) {
        this.submissionService.updateOtherSubmissionValue(
          OtherOptionValues.NO_RESUBMIT_FOR_REVIEW
        );
        const checkdraft = this.checkDocumentStatus(this.DOC_DRAFT_STATUS);

        this.isArchiveAndCompleteBtnDisabled = true;
        if (checkdraft) {
          this.showUpdatedDocumentMessage = false;
          this.isResubmitForReviewBtnDisabled = false;
        } else {
          this.showUpdatedDocumentMessage = true;
          this.isResubmitForReviewBtnDisabled = true;
        }

        this.showUploadFinalVersionMessage = false;
      } else if (
        otherValue === OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED
      ) {
        this.submissionService.updateOtherSubmissionValue(
          OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED
        );
        this.modalSetting = {
          selectedOption: OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED,
        };
        this.isArchiveAndCompleteBtnDisabled = true;
        this.isResubmitForReviewBtnDisabled = true;
        this.showUploadFinalVersionMessage = false;
        this.showUpdatedDocumentMessage = false;
        this.#closeOutModalOpen();
      } else {
        this.model.otherUpdatedVersionForReview = null;
        this.isArchiveAndCompleteBtnDisabled = true;
        this.isResubmitForReviewBtnDisabled = true;
        this.showUploadFinalVersionMessage = false;
        this.showUpdatedDocumentMessage = false;
      }
    }
  }

  onChangeDeliverableWithWbsArchiveCloseOut(
    deliverableValueWithWbsArchive: DeliverableWithWbsArchiveEventEmitterModel,
    callFrom: number
  ): void {
    const deliverableValue = deliverableValueWithWbsArchive.deliverable;
    this.modalSetting = null;
    this.setWbsAndArchiveValueBeforeSavingToBe(deliverableValueWithWbsArchive);
    this.isArchiveNotListed =
      deliverableValueWithWbsArchive.archiveNumber ===
      IntakeConstant.ARCHIVE_NOT_LISTED;

    if (callFrom === 0) {
      this.documentComponent?.triggerDocumentTableDataApi();
      this.model.updatedVersionForReview = deliverableValue;
      this.#saveCommonSectionDetails();
    }
    if (deliverableValue === DeliverableOptionValues.YES_RESUBMIT_FOR_REVIEW) {
      const showResubmitButton = this.checkDocumentStatus(
        this.DOC_DRAFT_STATUS
      );
      showResubmitButton
        ? (this.showUpdatedDocumentMessage = false)
        : (this.showUpdatedDocumentMessage = true);
      this.isResubmitForReviewBtnDisabled = !showResubmitButton;

      this.showInformationalGuidanceForDeliverable = false;
      this.isArchiveAndCompleteBtnDisabled = true;
    } else if (deliverableValue === DeliverableOptionValues.NO) {
      const isArchiveReadyDocAvailable = this.checkDocumentStatus(
        IntakeConstant.FINAL_ARCHIVE_READY_VERSION
      );
      this.iswbsCheckboxSelected =
        deliverableValueWithWbsArchive.wbsPending ?? false;
      this.archiveNumberSelected = deliverableValueWithWbsArchive.archiveNumber;
      let showArchiveButton = false;
      if (
        this.iswbsCheckboxSelected ||
        (deliverableValueWithWbsArchive.wbsChargeCode !=
          IntakeConstant.EMPTY_SPACE &&
          deliverableValueWithWbsArchive.wbsChargeCode != null &&
          deliverableValueWithWbsArchive.archiveNumber !==
            IntakeConstant.EMPTY_SPACE &&
          deliverableValueWithWbsArchive.archiveNumber !== null &&
          deliverableValueWithWbsArchive.archiveNumber !== undefined)
      ) {
        showArchiveButton = isArchiveReadyDocAvailable;
      }

      if (showArchiveButton) {
        this.showInformationalGuidanceForDeliverable = false;
      } else {
        this.showInformationalGuidanceForDeliverable = true;
      }
      this.isArchiveAndCompleteBtnDisabled = !showArchiveButton;
      this.isResubmitForReviewBtnDisabled = true;
      this.showUpdatedDocumentMessage = false;
    } else {
      this.model.updatedVersionForReview = null;
      this.isArchiveAndCompleteBtnDisabled = true;
      this.isResubmitForReviewBtnDisabled = true;
      this.showUpdatedDocumentMessage = false;
      this.showInformationalGuidanceForDeliverable = false;
    }
  }

  onChangeCoAndElSowWithWbsArchiveCloseOut(
    coElSowWithWdsArchiveValue: CoElSowWithWbsArchiveEventEmitterModel,
    callingFrom: number
  ): void {
    let selectedRadioValue = coElSowWithWdsArchiveValue?.contractSignedExecuted;
    this.setWbsAndArchiveValueBeforeSavingToBe(coElSowWithWdsArchiveValue);
    this.isArchiveNotListed =
      coElSowWithWdsArchiveValue.archiveNumber ===
      IntakeConstant.ARCHIVE_NOT_LISTED;
    if (callingFrom === 0) {
      this.documentComponent?.triggerDocumentTableDataApi();
      this.model.contractSignedExecuted =
        coElSowWithWdsArchiveValue?.contractSignedExecuted;
      this.#saveCommonSectionDetails();
    }

    this.iswbsCheckboxSelected = coElSowWithWdsArchiveValue.wbsPending ?? false;
    this.archiveNumberSelected =
      coElSowWithWdsArchiveValue.archiveNumber ??
      this.submissionData.archiveNumber;

    if (this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()) {
      this.modalSetting = null;
      if (selectedRadioValue === CoElSowOptionValues.YES) {
        const isArchiveReadyDocAvailable = this.checkDocumentStatus(
          IntakeConstant.FINAL_ARCHIVE_READY_VERSION
        );
        let showArchiveButton = false;
        if (
          this.iswbsCheckboxSelected ||
          (coElSowWithWdsArchiveValue.wbsChargeCode !=
            IntakeConstant.EMPTY_SPACE &&
            coElSowWithWdsArchiveValue.wbsChargeCode != null &&
            coElSowWithWdsArchiveValue.archiveNumber !==
              IntakeConstant.EMPTY_SPACE &&
            coElSowWithWdsArchiveValue.archiveNumber !== null &&
            coElSowWithWdsArchiveValue.archiveNumber !== undefined)
        ) {
          showArchiveButton = isArchiveReadyDocAvailable;
        }

        this.showUploadGuidanceYellowMsgForCoAndElSowOnYes = !showArchiveButton;
        this.isArchiveAndCompleteBtnDisabled = !showArchiveButton;
        this.showUploadGuidanceInfoMsgForCoAndElSowOnYes = true;
        this.showInfoMsgForCoElSowOnSelectNoResubmit = false;
        this.isResubmitForReviewBtnDisabled = true;
      } else if (
        selectedRadioValue === CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW
      ) {
        this.showUploadGuidanceInfoMsgForCoAndElSowOnYes = false;

        this.showUploadGuidanceYellowMsgForCoAndElSowOnYes = false;
        this.isArchiveAndCompleteBtnDisabled = true;
        const isAnyDocumentStausIsDraft = this.checkDocumentStatus(
          this.DOC_DRAFT_STATUS
        );
        this.isResubmitForReviewBtnDisabled = !isAnyDocumentStausIsDraft;
        this.showInfoMsgForCoElSowOnSelectNoResubmit =
          !isAnyDocumentStausIsDraft;
      } else if (
        selectedRadioValue ===
        CoElSowOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED
      ) {
        this.modalSetting = {
          selectedOption: selectedRadioValue,
        };
        this.isResubmitForReviewBtnDisabled = true;
        this.isArchiveAndCompleteBtnDisabled = true;
        this.showInfoMsgForCoElSowOnSelectNoResubmit = false;
        this.showUploadGuidanceInfoMsgForCoAndElSowOnYes = false;
        this.showUploadGuidanceYellowMsgForCoAndElSowOnYes = false;
        this.#closeOutModalOpen();
      }
    }
  }

  setCoElSowCloseOutDetails(): void {
    this.model.contractSignedExecuted =
      this.submissionData.contractSignedExecuted;
    this.model.wbsChargeCode = this.submissionData.wbsChargeCode;
    this.model.numberOfOpenArchieve = this.submissionData.numberOfOpenArchieve;
    this.model.wbsDescription = this.submissionData.wbsDescription;
  }

  handleDocumentTableData(data: IntakeDocumentTableModel[]): void {
    this.documentTableData = data;
    if (this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()) {
      if (
        this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodePro
      ) {
        this.onChangeWinningProposalInCloseOut(
          this.model.winningProposal ?? this._EMPTY,
          1
        );
      } else if (
        this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeOther
      ) {
        const wbsArchiveDetails = setWbsAndArchiveDetails(
          this.model,
          this.iswbsCheckboxSelected
        );
        const existingOtherCloseOutValue: OtherWithWbsArchiveEventEmitterModel =
          {
            ...wbsArchiveDetails,
            other:
              this.model.otherUpdatedVersionForReview ??
              IntakeConstant.EMPTY_SPACE,
          };
        this.onChangeOtherWithWbsArchiveInCloseOut(
          existingOtherCloseOutValue,
          1
        );
      } else if (
        this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeCO ||
        this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeELSOW
      ) {
        const wbsArchiveDetails = setWbsAndArchiveDetails(
          this.model,
          this.iswbsCheckboxSelected
        );
        const existingCoElSowCloseOutValue: CoElSowWithWbsArchiveEventEmitterModel =
          {
            ...wbsArchiveDetails,
            contractSignedExecuted:
              this.model.contractSignedExecuted ?? IntakeConstant.EMPTY_SPACE,
          };
        this.onChangeCoAndElSowWithWbsArchiveCloseOut(
          existingCoElSowCloseOutValue,
          1
        );
      } else if (
        this.submissionTypeCode ===
        this.intakeDocumentSubmissionTypeCodeDelivery
      ) {
        const wbsArchiveDetails = setWbsAndArchiveDetails(
          this.model,
          this.iswbsCheckboxSelected
        );
        const existingDeliverableCloseOutValue: DeliverableWithWbsArchiveEventEmitterModel =
          {
            ...wbsArchiveDetails,
            deliverable:
              this.model.updatedVersionForReview ?? IntakeConstant.EMPTY_SPACE,
          };

        this.onChangeDeliverableWithWbsArchiveCloseOut(
          existingDeliverableCloseOutValue,
          1
        );
      }
    }
    this.updateCloseoutBanner();
    this.checkValidation();
  }

  setDraftFlags(): void {
    this.updateFlags(
      this.checkDocumentStatus(this.DOC_DRAFT_STATUS),
      {
        isResubmitForReviewBtnDisabled: false,
        showUpdatedDocumentMessage: false,
      },
      { isResubmitForReviewBtnDisabled: true, showUpdatedDocumentMessage: true }
    );
  }

  isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued(): boolean {
    return (
      this.isStatusCompleted() ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.COMPLETED_ADMIN ||
      this.isDisContinued()
    );
  }

  isStatusCompleted(): boolean {
    return (
      this.submissionStatus === IntakeOppertunitySubmissionStatus.COMPLETED ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.COMPLETED_ADMIN ||
      this.submissionStatus === IntakeOppertunitySubmissionStatus.DISCONTINUED
    );
  }

  isDisContinued(): boolean {
    return (
      this.submissionStatus === IntakeOppertunitySubmissionStatus.DISCONTINUED
    );
  }

  updateFlags(
    condition: boolean,
    trueValues: {
      [key: string]: boolean;
    },
    falseValues: {
      [key: string]: boolean;
    }
  ): void {
    if (condition) {
      Object.keys(trueValues).forEach((key) => {
        (this as any)[key] = trueValues[key];
      });
    } else {
      Object.keys(falseValues).forEach((key) => {
        (this as any)[key] = falseValues[key];
      });
    }
  }

  checkDocumentStatus(status: string): boolean {
    return this.documentTableData.some((doc) => doc.documentStatus === status);
  }

  #closeOutModalOpen(modalBtn?: ButtonComponent): void {
    if (!this.isCloseOutMoveToCompleteModalOpen) {
      this.isCloseOutMoveToCompleteModalOpen = true;
      let closeOutModalRef = this.modalService.open(ConfirmPopModalComponent, {
        isFooter: true,
        size: IntakeConstant.MODAL_SIZE,
        isInverse: false,
        modalSetting: {
          ...this.modalSetting,
          selectedSubmissionType: this.submissionTypeCode,
        },
      });

      closeOutModalRef
        .onClosed()
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe((data: CloseOutModalModel) => {
          this.isCloseOutMoveToCompleteModalOpen = false;
          if (
            data !== null &&
            (data?.moveToCompleteOther ||
              data?.moveToCompleteProposal ||
              data?.moveToCompleteCoElSow)
          ) {
            this.onclickArchiveAndCompleteBtn();
          } else {
            this.model.winningProposal = null;
            this.model.contractSignedExecuted = null;
          }
          modalBtn?.focus();
        });
    }
  }

  clearReviewer(
    emailId: string,
    isForTechnicalReviewer: boolean | undefined
  ): void {
    /*
      1. If the workflow is disabled, then the user cannot be removed.
      2. If the required managers length is > 1, then the user can be removed.
      3. If the required manager length is = 1 and user is not required, then the user can be removed.
      4. If the required manager length is = 0 and number of managers are greater than 1, then the user can be removed.
      5. Whether we have R/W permission for role & name
      */
    const canRemoveUser =
      !this.isWorkflowDisabled() &&
      (this.selectedRiskManagers.length > 1 ||
        (this.selectedRiskManagers.length === 1 &&
          !this.selectedRiskManagers.includes(emailId)) ||
        (this.selectedRiskManagers.length === 0 &&
          this.updatedRiskManagerDetails.length > 1)) &&
      (!isForTechnicalReviewer ||
        (isForTechnicalReviewer &&
          this.permissionObj['isIntakeWorkflowTechnicalEnable']));

    if (canRemoveUser) {
      const index: number = this.updatedRiskManagerDetails.findIndex(
        (reviewer) => reviewer.emailId === emailId
      );
      if (index !== -1) {
        this.updatedRiskManagerDetails[index]['isActive'] = false;
        this.tempUpdatedRiskManagerDetails[index]['isActive'] = false;

        const technicalPresent = this.updatedRiskManagerDetails.some(
          (reviewer) => reviewer.roleName === this.roleTechnicalOrIndependentRM
        );

        if (
          isForTechnicalReviewer &&
          this.isEligibleTechnicalReviewer &&
          !technicalPresent
        ) {
          this.addReviewer(this.roleTechnicalOrIndependentRM);
        }

        this.#updateReviewers(
          UpdateReviewerActions.DELETE,
          this.updatedRiskManagerDetails[index].reviewerRoleId
        );
      }
    }
  }

  clearUpdatedRiskManagers(): void {
    this.updatedRiskManagerDetails = [];
    this.tempUpdatedRiskManagerDetails = [];
    this.selectedRiskManagers = [];
  }

  isEditingReviewer(row: SubmissionApprovers): boolean | undefined {
    const reviewerRoleID = this.roleIdMapper?.[this.roleAdditionalReviewer];
    return (
      row.isEditing &&
      row.reviewerRoleId === reviewerRoleID &&
      !row.isForTechnicalReviewer
    );
  }

  isEditingTechnicalReviewer(row: SubmissionApprovers): boolean | undefined {
    return (
      row.isEditing &&
      row.isForTechnicalReviewer && // if reviewer role is of technical reviewer
      this.isEligibleTechnicalReviewer && // if submission is eligible for technical reviewer
      this.permissionObj['isIntakeWorkflowTechnicalEnable']
    );
  }

  openOptOutReasonModal(modalBtn?: any): void {
    const optOutModelRef = this.modalService.open(OptOutPopupComponent);
    optOutModelRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: IntakeOptOutPopUpDataModel) => {
          if (data.submissionLevelUpdateSubmission) {
            this.model.reasonForOptOutSelfReview = data.optOutReason;
            this.optOutOfSelfReviewReason = data.optOutReason;
            this.isSelfReviewButtonVisible = false;
            this.showSubmitBtnWhnDraft =
              this.submissionStatus === IntakeOppertunitySubmissionStatus.DRAFT;
            this.setToast(this.optedOutToastOption);
            this.#saveCommonSectionDetails();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  // TODO: Once Employee ID is implemented this needs to be checked
  // checkActionsColumn() {
  //   for (let i in this.updatedRiskManagerDetails) {
  //     if (
  //       this.loggedInEmployeeId ===
  //         this.updatedRiskManagerDetails[i].reviewerId &&
  //       this.submissionStatus === 'Submitted' &&
  //       this.updatedRiskManagerDetails[i].statusReviewedDate === null
  //     ) {
  //       this.showActionsHeader = true;
  //       break;
  //     } else {
  //       this.showActionsHeader = false;
  //     }
  //   }
  // }

  setToast(toast: any): void {
    this.toastService.createToast(toast);
  }

  onclickSelfReviewBtn(): void {
    this.submitCommonSectionData(this.docStausSelfReviewedPendingCloseout);
  }

  onclickOptOutOfSelfReviewBtn(): void {
    this.openOptOutReasonModal();
  }

  selfReviewAllOperationSet(): void {
    if (this.submissionData.selfReviewEligibilityStatus) {
      this.isSelfReviewButtonVisible = isSelfReviewButtonVisible(
        this.submissionData.selfReviewEligibilityStatus,
        this.submissionStatus as IntakeOppertunitySubmissionStatus | null,
        this.submissionData.reasonForOptOutSelfReview
      );
      if (this.isSelfReviewButtonVisible) {
        this.selfReviewDocBanner =
          this.configData.find(
            (config: configurationType) =>
              config.appConfigurationLabelKey === 'SELFREVIEW_UPLOAD_DOCUMENTS'
          )?.appConfigurationLabelValue || this._EMPTY;
      }
      return;
    }
    this.selfReviewDocBanner = IntakeConstant.EMPTY_SPACE;
  }

  transformStatusStyle(status: string | undefined): StatusEnum {
    if (status) {
      return (
        Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
        StatusEnum.Draft
      );
    } else {
      return StatusEnum.Draft;
    }
  }

  updateCloseoutBanner(): void {
    if (
      this.model.winningProposal ===
        WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW &&
      this.checkDocumentStatus(this.DOC_DRAFT_STATUS)
    ) {
      this.showCompleteCloseoutFormMsg = false;
    } else {
      this.showCompleteCloseoutFormMsg = true;
    }
  }

  getSubmissionSubType(subTypeId: string | number | undefined | null): string {
    return (
      this.otherSubTypes.find((item) => item.subTypeId.toString() === subTypeId)
        ?.subTypeName || '-'
    );
  }

  isReviewerCheckboxChecked(isRequired: boolean, index: number): boolean {
    if (
      this.submissionStatus == this.DRAFT_STATUS &&
      this.selfReviewEligibility == this.valueYes &&
      !this.optOutOfSelfReviewReason
    ) {
      return false;
    }

    return isRequired ?? false;
  }

  isReviewerCheckboxDisabled(emailId: string, index: number): boolean {
    if (
      (this.selectedRiskManagers.length === 1 &&
        this.selectedRiskManagers.includes(emailId)) ||
      !this.permissionObj['isIntakeSubmissionWorkflowCheckboxEnable']
    ) {
      return true;
    }

    return this.isWorkflowDisabled();
  }

  isWorkflowDisabled(): boolean {
    if (this.submissionStatus == this.DRAFT_STATUS) {
      if (
        this.selfReviewEligibility == this.valueYes &&
        !this.optOutOfSelfReviewReason
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.submissionStatus == this.IntakeDocumentSubmissionStatusSubmitted ||
      this.submissionStatus == this.submissionStatusResubmitted
    ) {
      return false;
    } else {
      return true;
    }
  }

  checkUserAccess(section: string): boolean {
    const roles: string[] = this.userRolesAccess?.map(
      (role: any) => role.roleName
    );
    if (section === 'AddReviewer') {
      return (
        roles.includes(this.roleRiskManager) ||
        roles.includes(this.roleSupportingRiskManager) ||
        roles.includes(this.roleSpecializedRiskManager) ||
        roles.includes(this.roleTechnicalOrIndependentRM) ||
        roles.includes(this.roleAdditionalReviewer) ||
        roles.includes(this.roleRMSupport) ||
        roles.includes(this.roleSystemAdministrator)
      );
    }
    return false;
  }

  get isAddReviewerButtonDisabled(): boolean {
    return (
      !this.checkUserAccess('AddReviewer') ||
      this.disableFieldOnComplete() ||
      this.isWorkflowDisabled() ||
      this.disableAddReviewerButton ||
      !this.permissionObj['isIntakeSubmissionWorkflowAddReviewerEnable']
    );
  }

  isEditDeleteRowDisabled(roleName: string): boolean {
    return (
      !this.checkUserAccess('AddReviewer') ||
      this.disableFieldOnComplete() ||
      this.isWorkflowDisabled() ||
      this.disableAddReviewerButton ||
      (roleName === this.roleAdditionalReviewer &&
        !this.permissionObj['isIntakeSubmissionWorkflowAddReviewerEnable']) ||
      (roleName === this.roleTechnicalOrIndependentRM &&
        !this.permissionObj['isIntakeWorkflowTechnicalEnable'])
    );
  }

  checkValidationOfForm(): boolean {
    const submissionData = this.model;
    const submissionStatusId = this.submissionData?.submissionStatusId ?? -1;
    const submissionTypeId = this.submissionData?.submissionTypeId ?? -1;

    // Submitted, Re-submitted, Completed, Completed(Admin)
    if ([3, 4, 7, 8, 9].includes(submissionStatusId)) {
      return true;
    }

    if (!this.isValidDocumentUploaded) {
      return false;
    }

    // Draft, Returned for revisions
    if (
      [1, 2].includes(submissionStatusId) &&
      (this.submissionData.lepReviewedBy !== null ||
        this.submissionData.lepAcknowledgedBy !== null)
    ) {
      switch (submissionTypeId) {
        // Proposal
        case 1:
          return (
            !!submissionData.proposalDescription &&
            submissionData.relatedToRFP !== null
          );
        // ELSOW
        case 2:
          return true; // In ELSOW we check valid doc
        // CO
        case 3:
          return (
            !!submissionData.coDescription &&
            submissionData.coChangeScope !== null
          );
        // DEL
        case 4:
          return !!submissionData.delDescription;
        // Other
        case 5:
          return !!submissionData.otherDescription;
        default:
          return false;
      }
    }

    // RM/Self - Pending Closeout
    if ([5, 6].includes(submissionStatusId)) {
      switch (submissionTypeId) {
        case 1:
          return (
            (
              submissionData.winningProposal?.toLowerCase() ?? this._EMPTY
            ).includes(this.YES_CONST) ||
            (
              submissionData.winningProposal?.toLowerCase() ?? this._EMPTY
            ).includes(this.RESUBMIT_CONST)
          );
        case 2:
        case 3:
          return (
            (submissionData.wbsChargeCode !== null &&
              (
                submissionData.contractSignedExecuted?.toLowerCase() ??
                this._EMPTY
              ).includes(this.YES_CONST)) ||
            (
              submissionData.contractSignedExecuted?.toLowerCase() ??
              this._EMPTY
            ).includes(this.RESUBMIT_CONST)
          );
        case 4:
          return (
            (
              submissionData.updatedVersionForReview?.toLowerCase() ??
              this._EMPTY
            ).includes(this.YES_CONST) ||
            (
              submissionData.updatedVersionForReview?.toLowerCase() ??
              this._EMPTY
            ).includes('no')
          );
        case 5:
          return (
            (
              submissionData.otherUpdatedVersionForReview?.toLowerCase() ??
              this._EMPTY
            ).includes(this.YES_CONST) ||
            (
              submissionData.otherUpdatedVersionForReview?.toLowerCase() ??
              this._EMPTY
            ).includes(this.RESUBMIT_CONST)
          );
        default:
          return false;
      }
    }
    return false;
  }

  getUploadedDocumentData(): void {
    if (this.submissionId && this.submissionId !== 0) {
      this.progressIndicator.show();
      this.submissionService
        .getDocumentTableData(this.submissionId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (data: IntakeDocumentTableModel[]) => {
            this.uploadedDocData = data;
            this.progressIndicator.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.progressIndicator.hide();
            console.error('Error in fetching document data', err);
          },
        });
    }
  }

  updateWbsStatus(event: any): void {}

  getPermissions(): void {
    this.securityWebapiService.data.subscribe((data) => {
      const dataString = JSON.stringify(data);
      this.isValidTechnicalReviewer = dataString.includes(
        'Workflow.AddTechnicalReviewer'
      );
      this.isAllReviewerValid = dataString.includes('Workflow.AddReviewer');
    });
  }

  get isInternalQRMDisabled(): boolean {
    return (
      !this.permissionObj['isIntakeSubmissionInternalQRMEnable'] ||
      this.isStatusCompleted()
    );
  }

  get isInternalQRMVisible(): boolean {
    return (
      this.permissionObj['isIntakeSubmissionInternalQRMVisible'] &&
      this.userRolesAccess?.some((role: any) => {
        if (
          role.roleName == RoleEnum.RISK_MANAGER ||
          role.roleName == RoleEnum.SUPPORTING_RISK_MANAGER ||
          role.roleName == RoleEnum.SPECIALIZED_RISK_MANAGER ||
          role.roleName == RoleEnum.TECHNICAL_INDEPENDENT_RISK_MANAGER ||
          role.roleName == RoleEnum.SYSTEM_ADMINISTRATOR ||
          role.roleName == RoleEnum.SYSTEM_ADMINISTRATOR ||
          role.roleName == RoleEnum.ENGAGEMENT_TEAM_MEMBER ||
          (role.roleName == RoleEnum.REVIEWER &&
            this.updatedRiskManagerDetails.some(
              (item) =>
                item.roleName === RoleEnum.REVIEWER &&
                item.reviewerId === this.currentLoggedInUser
            ))
        ) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  userInWorkflowTable(): boolean {
    return this.updatedRiskManagerDetails.some(
      (item) => item.reviewerId === this.currentLoggedInUser
    );
  }

  checkButtonVisibility(): void {
    if (
      (this.submissionStatus === IntakeOppertunitySubmissionStatus.SUBMITTED ||
        this.submissionStatus ===
          IntakeOppertunitySubmissionStatus.RESUBMITTED) &&
      this.userInWorkflowTable()
    ) {
      this.updatePermissions.emit(); // TO update the permissions for workflowActions
      this.showReviewAndReturnBtn = true;
      if (this.userInWorkflowTable()) {
        const reviewerData = this.updatedRiskManagerDetails.find(
          (reviewer) => reviewer.reviewerId === this.currentLoggedInUser
        );
        if (reviewerData?.statusReviewedDate === null) {
          this.showReviewBtn = true;
        } else {
          this.showReviewBtn = false;
        }
      }
    } else {
      this.showReviewAndReturnBtn = false;
      this.showReviewBtn = false;
    }
  }

  openRelinkModel(modalBtn?: any): void {
    const relinkModel = this.modalService.open(RelinkOppPopupComponent, {
      documentInitiatedDetails: {
        submissionId: this.submissionId,
        opportunityId: this.dataOpportunityId,
      },
    });
    relinkModel
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: NavigationInfoRelinkModel) => {
        if (data) {
          this.reloadCurrentRoute(data);
        }
        if (modalBtn) {
          modalBtn?.focus();
        }
      });
  }

  async reloadCurrentRoute(data: NavigationInfoRelinkModel): Promise<void> {
    const link = `submission/opportunity-details/${data.opportunityId}`;
    const queryParams = {
      submissionId: data.submissionId,
    };
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    await this.router.navigate([link], { queryParams: queryParams });
  }

  scrollToTop(): void {
    this.headerService.scrollToTop();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next(this._EMPTY);
    this.unsubscriber$.complete();
    this.submissionService.openAdminCompleteModal(false);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.submissionService.setSelectedOpportunityDocStatus(null);
  }
}
