import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { DocumentLinkUploadComponent } from './document-upload/document-link-upload/document-link-upload.component';
import { DocumentEditPopupComponent } from './document-upload/document-edit-popup/document-edit-popup.component';
import { IntakeDocumentUploadComponents } from './document-upload/intake-document-upload/intake-document-upload.component';
import { DocumentTableComponent } from './document-upload/document-table/document-table.component';
import { ButtonOptions, UploaderOptions } from './document-settings';
import { IntakeOppertunitySubmissionStatus } from '../../../constants/intake_oppertunity_submission_status.enum';
import { IntakeDocumentTableModel } from '../../../../common/models/intake-document-table.model';
import { Subject } from 'rxjs';
import { ParsedRules } from '../../../../common/models/common-models';
import { SecurityWebapiService } from '../../../../http/security/security-webapi.service';
import { PermissionCheck } from '../../../../common/validations/PermissionCheck';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrl: './document.component.less',
})
export class DocumentComponent extends PermissionCheck {
  @Input() type: string = '';
  @Input() submissionTypeId: number = 0;
  @Input() submissionStatusId: number = 0;
  @Input() submissionId: number = 0;
  @Input() progressiveStatus: boolean | null = null;
  @Input() submissionTypeCode: string | undefined = '';
  @Input() submissionStatus: string | undefined = '';
  @Input() isDisabledDocuments: boolean = false;
  @Input() isDraftForSelfReview: boolean = false;
  @Output() documentTableData: EventEmitter<IntakeDocumentTableModel[]> =
    new EventEmitter<IntakeDocumentTableModel[]>();
  @ViewChild(DocumentTableComponent) tableComponent!: DocumentTableComponent;

  constructor(
    private readonly modalService: ModalService,
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
  }

  options = ButtonOptions;
  uploadAreaOptions = UploaderOptions;
  unsubscriber$: Subject<void> = new Subject<void>();

  triggerDocumentTableDataApi(): void {
    this.tableComponent.getDocumentMetaData();
  }

  openDocumentLinkModel(modalBtn?: any): void {
    let modalRef = this.modalService.open(DocumentLinkUploadComponent, {
      documentInitiatedDetails: {
        submissionId: this.submissionId,
        moduleName: 'Intake',
        submissionStatusId: this.submissionStatusId,
        submissionTypeId: this.submissionTypeId,
        type: this.type,
      },
    });
    modalRef.onClosed().subscribe(() => {
      this.triggerDocumentTableDataApi();
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  openDocumentEditModel(modalBtn?: any): void {
    let modalRef = this.modalService.open(DocumentEditPopupComponent);
    modalRef.onClosed().subscribe(() => {
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  openUploadModel(event: any, modalBtn?: ButtonComponent): void {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(IntakeDocumentUploadComponents, {
      isFooter: true,
      size: 'lg',
      isInverse: false,
      documentInitiatedDetails: {
        moduleName: 'Intake',
        submissionStatusId: this.submissionStatusId,
        submissionTypeId: this.submissionTypeId,
        submissionId: this.submissionId,
        documentUploadCount: 1,
        file: event,
        type: this.type,
      },
    });

    modalRef.onClosed().subscribe(() => {
      this.triggerDocumentTableDataApi();
      event.splice(0, event.length);
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  hideLinkButton(): boolean {
    return (
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
    );
  }

  removeBodyOverflow(): void {
    document.body.style.overflowY = 'hidden';
  }

  documentTableDataValue(data: IntakeDocumentTableModel[]): void {
    this.documentTableData.emit(data);
  }

  get isAddLinkButtonVisible(): boolean {
    return (
      !this.isDraftForSelfReview &&
      !this.hideLinkButton() &&
      this.isPermissionPresent(
        this.permissionEnums.Intake_Submission_AddDocumentLink,
        this.permissionType.Enable
      )
    );
  }
}
