import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { Subject, takeUntil } from 'rxjs';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import {
  DocumentStatus,
  DocumentType,
} from '../../../../common/models/document-dropdown.models';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { EditSubmissionDocument } from '../../../../common/models/external-communication.model';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  ModalCloseButtonOptions,
  SecondaryLoudButtonOptions,
} from '../../../external-communications.helper';
import { BrowserTabConstant } from '../../../../common/constants/browser-tab-constant';
import { CommonHelperService } from '../../../../common/services/common-helper.service';

@Component({
  selector: 'app-external-communication-document-edit-popup',
  templateUrl: './external-communication-document-edit-popup.component.html',
  styleUrl: './external-communication-document-edit-popup.component.less',
})
export class ExternalCommunicationDocumentEditPopupComponent {
  @Input() selectedDocument: any = {};
  @Input() set documentStatusData(value: SelectItemOptions[]) {
    this.documentStatusItemList = value;
  }
  @Input() set documentTypeData(value: SelectItemOptions[]) {
    this.documentTypeItemsList = value;
  }

  documentTypeItemsList: SelectItemOptions[] = [];
  documentStatusItemList: SelectItemOptions[] = [];
  documentTitle!: string;
  documentComment!: string;
  documentTypeId!: number;
  documentStatusId!: number;
  selectedDocumentType!: number;
  selectedDocumentStatus!: number;

  isSaveDisabled: boolean = false;
  modalCloseButtonOptions: ButtonOptions = ModalCloseButtonOptions;
  secondaryLoudButtonOptions: ButtonOptions = SecondaryLoudButtonOptions;
  UI_Element_Size: string = ExternalCommunicationConstant.UI_ELEMENT_SIZE;
  emptyString: string = ExternalCommunicationConstant.EMPTY_SPACE;

  unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    public readonly modalService: ModalService,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly progressIndicator: ProgressIndicatorService,
    private commonHelperService: CommonHelperService
  ) {}

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_ExternalCommunications;
    this.documentTitle = this.commonHelperService.trimPrefixEmptySpace(this.selectedDocument.documentTitle);
    this.documentComment = this.commonHelperService.trimPrefixEmptySpace(this.selectedDocument.comments);
    this.selectedDocumentType = this.selectedDocument.documentTypeId;
    this.selectedDocumentStatus = this.selectedDocument.documentStatusId;
  }

  updateStatusDropdown(): void {
    //TODO: For later stories, if not needed remove
  }

  async editDocument(): Promise<void> {
    const postRequestData: EditSubmissionDocument = {
      SubmissionDocumentId: this.selectedDocument.submissionDocumentId,
      SubmissionId: this.selectedDocument.submissionId,
      DocumentTitle: this.commonHelperService.trimPrefixEmptySpace(this.documentTitle),
      DocumentTypeId: this.selectedDocumentType,
      DocumentStatusId: this.selectedDocumentStatus,
      SubmissionDocumentTypeId: this.selectedDocument.submissionDocumentTypeId,
      DocumentUrl: this.selectedDocument.documentUrl,
      AttachmentId: this.selectedDocument.attachmentId,
      Comments: this.commonHelperService.trimPrefixEmptySpace(this.documentComment),
    };
    return new Promise((resolve, reject) => {
      this.externalCommService
        .editDocumentDetails(postRequestData)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            resolve();
          },
          error: (err) => {
            console.error('An error occurred during document edit:', err);
            reject(err);
          },
        });
    });
  }

  async saveAll(): Promise<void> {
    try {
      this.progressIndicator.show();
      await this.editDocument();
      this.close();
    } catch (error: any) {
      if (error?.status === 200) {
        this.close();
      } else {
        console.error('Error in updating the document', error);
      }
    } finally {
      this.progressIndicator.hide();
    }
  }

  close(): void {
    this.modalService.close();
  }

  isFormValid(): boolean {
    // TODO: Add logic to check if save is disabled
    return (
      this.documentTitle.length > 0 &&
      this.selectedDocumentType > 0 &&
      this.selectedDocumentStatus > 0
    );
  }

  updateStatusId(event: any): void {
    //TODO: For later stories, if not needed remove
  }
  updateTypeId(event: any): void {
    //TODO: For later stories, if not needed remove
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
