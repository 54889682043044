<progress-indicator></progress-indicator>
<div class="header">
  <app-page-header>
    <div class="header-custom-container row">
      <!-- TODO when functunality defined -->
      <div class="col-3 d-flex align-items-center flex-item-1">
        <span
          *ngIf="permissionObj['isIntakeHeaderMyDashboardVisible']"
          class="dds-icon dds-icon_home__l__stroke cursor-clickable"
          (click)="backToHome()"
        ></span>
        <div
          class="title"
          *ngIf="permissionObj['isIntakeHeaderOpportunityTitleVisible']"
        >
          {{ opportunityDetailTitle }}
        </div>
      </div>
      <div class="col-9 d-flex align-items-center flex-item-2">
        <div
          *ngIf="permissionObj['isIntakeHeaderGoToJupiterVisible']"
          class="icon-container"
          (click)="onNavigateToJupiterURL()"
        >
          <span>Go to Jupiter</span>
          <span>
            <img src="\assets\images\dds-icon [16px].svg" />
          </span>
        </div>
        <div class="icon-divider-container">
          <img src="\assets\images\divider.png" />
        </div>
        <div
          *ngIf="permissionObj['isIntakeHeaderGoToFocusVisible']"
          class="icon-container"
          (click)="onNavigateToFocusURL()"
        >
          <span>Go to Focus</span>
          <span>
            <img src="\assets\images\dds-icon [16px].svg" />
          </span>
        </div>
        <div class="icon-divider-container">
          <img src="\assets\images\divider.png" />
        </div>
        <div class="icon-container" (click)="navigateToSummary()">
          <span
            class="dds-icon dds-icon_pause_bordered__s__stroke rotate-icon padding-bottom-xxs inline-block"
          ></span>
          <span>Opportunity summary</span>
        </div>
      </div>
    </div>
  </app-page-header>
</div>

<div *ngIf="submissitionDetails.length !== 0" class="d-flex">
  <ng-container *ngIf="permissionObj['isIntakeLeftHandNavigationVisible']">
    <div class="row padding-top-l width26pt">
      <div class="col-3 width-left-menu-panel">
        <div
          class="sidebar position-top-intake sticky-element-after-black-header"
        >
          <div
            class="nav-item"
            [class.active]="activeIndex === -1"
            (click)="setActive(-1)"
          >
            <span
              class="status-indicator"
              [ngClass]="
                activeIndex === -1
                  ? 'status-active-indicator'
                  : 'status-inactive-indicator'
              "
            ></span>
            <div class="opportunity-detail-heading">
              <p class="margin-bottom-es margin-top-sm">Opportunity details</p>
              <span
                *ngIf="isOverAllRequired()"
                class="margin-bottom-es margin-top-sm"
                ><img class="right-a" src="\assets\images\draftIcon.svg"
              /></span>
            </div>
          </div>

          <p class="doc-sub">Document submission(s):</p>
          <div class="nav-items" id="left-nav-items">
            <div
              *ngFor="let item of submissitionDetails; let i = index"
              class="nav-item"
              [id]="'navItem-' + item?.submissionId"
              [class.active]="i === activeIndex"
              (click)="setActive(i)"
            >
              <span
                class="status-indicator"
                [ngClass]="
                  i === activeIndex
                    ? 'status-active-indicator'
                    : 'status-inactive-indicator'
                "
              ></span>
              <div
                class="nav-item-content"
                id="item.submissionId"
                [ngClass]="{
                  selected: selectedSubmissionId === item.submissionId
                }"
                (click)="selectNavItem(item.submissionId)"
              >
                <div class="icon-info-display">
                  <img src="\assets\images\file-icon.svg" alt="file img" />
                  <div>
                    <h4
                      [ddsTooltip]="item.submissionTitle"
                      [tooltipInvokeType]="options.tooltipInvokeType ?? ''"
                      [tooltipType]="options.tooltipType ?? ''"
                      [tooltipSize]="options.tooltipSize ?? ''"
                      [tooltipPosition]="options.tooltipPosition ?? ''"
                      [tooltipIndent]="options.tooltipIndent ?? 0"
                      [tooltipHasBeak]="options.tooltipHasBeak ?? false"
                      [tooltipIsDisabled]="options.tooltipIsDisabled ?? false"
                      [tooltipIsOutsideClick]="
                        options.tooltipIsOutsideClick ?? false
                      "
                      [tooltipIsDynamic]="options.tooltipIsDynamic ?? false"
                      [tooltipTheme]="options.tooltipTheme ?? ''"
                      [tooltipShowDelay]="options.tooltipShowDelay ?? 0"
                      [tooltipHideDelay]="options.tooltipHideDelay ?? 0"
                      ddsFocus
                    >
                      {{ item.submissionTitle }}
                    </h4>
                    <div class="sub-info">
                      <span class="item-type">{{
                        item.submissionTypeCode
                      }}</span>
                      <span
                        class="padding-xxs"
                        [style.color]="
                          transformStatus(item.submissionStatusName)
                            | statusStyle : 'color'
                        "
                        [style.backgroundColor]="
                          transformStatus(item.submissionStatusName)
                            | statusStyle : 'backgroundColor'
                        "
                        >{{ item.submissionStatusName }}</span
                      >
                    </div>
                  </div>
                </div>
                <span
                  class="draft-status"
                  *ngIf="
                    item.submissionId &&
                    !requiredCheckForIdList.includes(item.submissionId)
                  "
                >
                  <img src="\assets\images\draftIcon.svg"
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabsection"></div>
  </ng-container>
  <div class="padding-left-m padding-top-l">
    <div *ngIf="activeIndex === -1" class="row">
      <app-oportunity-detail-right-panel
        [opportunityNumber]="opportunityId"
        [tabTitle]="opportunityDetailTitle"
        [submissionDetails]="submissitionDetails"
        [permissionObj]="permissionObj"
        (lepValueChange)="onLepValueChange($event)"
      ></app-oportunity-detail-right-panel>
    </div>
    <div *ngIf="activeIndex > -1" class="row">
      <app-submission-common-section
        [dataOpportunityId]="opportunityId"
        [dataSubmissionId]="selectedSubmissionId"
        [dataSubmissionTypeCode]="dataSubmissionTypeCode"
        [dataLepValue]="lepValue"
        [opportunityDocsCount]="submissitionDetails.length"        
        [opportunityDocs]="submissitionDetails"
        [permissionObj]="permissionObj"
        (valueChange)="checkRequired($event)"
        (updateTitle)="updateDocumentTitle($event)"
        (updateLeftMenu)="getNavDetails(opportunityId)"
        (updatePermissions)="updatePermissions()"
      />
    </div>
  </div>
</div>
