import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { environment } from '../../../environment/environment';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { StatusEnum } from '../../common/constants/status-enum';
import { EntityType } from '../../http/user.service';
import { GlobalSearchResponse, SearchItem } from '../../common/models/start-page/global-search.model';
import { GlobalSearchWebApiService } from '../../http/dashboard/global-search-webapi.service';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.less']
})
export class SearchBoxComponent implements OnInit, OnDestroy {

  public items: Array<SearchItem> = [];
  public lastSearchItems: Array<SearchItem> = [];
  public isLoading: boolean = false;
  public isMoreItemsMessageVisible: boolean = false;
  public isSearchItemsVisible: boolean = false;
  public isLastSearchItemsVisible: boolean = false;
  public isNoLastSearchItemsMessageVisible: boolean = false;
  public isResultContainerVisible: boolean = false; 
  public searchKey: string = '';
  private searchKey$ = new Subject<string>();
  private subscriptions: Subscription[] = [];

  subTabs: any[] = [
    { searchBoxSubTabCode: 'AllResults', searchBoxSubTabName: 'All Results' },
    { searchBoxSubTabCode: 'Clients', searchBoxSubTabName: 'Clients' },
    { searchBoxSubTabCode: 'Opportunities', searchBoxSubTabName: 'Opportunities' },
    { searchBoxSubTabCode: 'Submissions', searchBoxSubTabName: 'Submissions' }
  ];

  searchOptions: SearchOptions = {
    placeholder: 'Search clients, opportunities or submissions by name or ID',
    ariaLabel: '',
    size: Size.lg,
    isInverse: true,
    customClass: '',
    isTrimValue: false,
    readonly: false,
    maxLength: 100,
  };

  public tabSetCurrentId: string = 'AllResults';
  public totalCount: number = 0;
  public totalSubmissionsCount: number = 0;
  public totalOpportunitiesCount: number = 0;
  public totalClientsCount: number = 0;
  public Opportunities: Array<SearchItem> = [];
  public Submissions: Array<SearchItem> = [];
  public clients: Array<SearchItem> = [];
  public tempitems: Array<SearchItem> = [];
  public rootUrl: string = environment.rootUrl;

  constructor(private globalSearchWebApiService: GlobalSearchWebApiService) { }

  ngOnInit(): void {
    const searchSubscription = this.searchKey$.pipe(
      debounceTime(500),
      filter(key => !!key && key.trim().length >= 3),
      switchMap(key => this.globalSearchWebApiService.search(key.trim()))
    ).subscribe({
      next: (response: GlobalSearchResponse) => {
        this.items = response.searchData;
        this.isLoading = false;
        this.isLastSearchItemsVisible = false;
        this.isSearchItemsVisible = true;
        this.isResultContainerVisible = true;
  
        this.totalCount = response.searchDataCount;
        this.totalClientsCount = response.clientSearchDataCount;
        this.totalSubmissionsCount = response.submissionSearchDataCount;
        this.totalOpportunitiesCount = response.opportunitySearchDataCount;
        this.clients = response.clientSearchData;
        this.Opportunities = response.opportunitySearchData;
        this.Submissions = response.submissionSearchData;
        this.setItems(this.tabSetCurrentId);

      this.subTabs.forEach(tab => {
        switch (tab.searchBoxSubTabCode) {
          case 'AllResults':
            tab.searchBoxSubTabNameCount = `${tab.searchBoxSubTabName} (${this.totalCount})`;
            break;
          case 'Clients':
            tab.searchBoxSubTabNameCount = `${tab.searchBoxSubTabName} (${this.totalClientsCount})`;
            break;
          case 'Opportunities':
            tab.searchBoxSubTabNameCount = `${tab.searchBoxSubTabName} (${this.totalOpportunitiesCount})`;
            break;
          case 'Submissions':
            tab.searchBoxSubTabNameCount = `${tab.searchBoxSubTabName} (${this.totalSubmissionsCount})`;
            break;
          default:
            tab.searchBoxSubTabNameCount = tab.searchBoxSubTabName;
        }
      });

      this.isMoreResultMessageShow(this.tabSetCurrentId);
    },
    error: (err) => {
      console.error('Error while Searching Data');
      this.isLoading = false;
    }
  });

    this.subscriptions.push(searchSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public itemClick(item: SearchItem) {
    this.globalSearchWebApiService.addLastSearchItem(item.entityId, item.entityTypeId).subscribe({
      next: () => {},
      error: (err) => console.error('Error adding last search item', err)
    });
    this.clearSearchResults();
    let url = '';
    switch (item.entityTypeId) {
      case EntityType.Submission:
        url = `${this.rootUrl}submission/opportunity-details/${item.opportunityNumber}?submissionId=${item.entityId}`;
        break;
      case EntityType.Opportunity:
        url = `${this.rootUrl}submission/opportunity-summary?id=${item.entityId}`;
        break;
      case EntityType.Client:
        url = `${this.rootUrl}client/${item.entityId}/details`;
        break;
    }
    window.open(url, '_blank');
  }

  public inputClick() {
    if (!this.searchKey) {
      this.fetchLastSearchData();
    }
  }

  public onSearchKeyChange() {
    this.isNoLastSearchItemsMessageVisible = false;
    this.isLastSearchItemsVisible = false;
  
    const trimmedSearchKey = this.searchKey.trim();
  
    if (trimmedSearchKey.length >= 3) {
      this.isLoading = true;
      this.isResultContainerVisible = true;
      this.searchKey$.next(trimmedSearchKey);
    } else {
      this.isSearchItemsVisible = false;
      this.isResultContainerVisible = false;
      this.isMoreItemsMessageVisible = false;
      if (trimmedSearchKey.length === 0) {
          this.clearSearchResults();
        }
    }
  
    this.toggleCloseButton(trimmedSearchKey.length === 0);
  }


  private toggleCloseButton(hide: boolean) {
    const searchBoxContainer = document.querySelector('.search-box-container');
    if (searchBoxContainer) {
      const closeButton = searchBoxContainer.querySelector('.dds-search__close');
      if (closeButton) {
        (closeButton as HTMLElement).style.display = hide ? 'none' : 'block';
      }
    }
  }

  public setItems(currentTab: string) {
    this.tabSetCurrentId = currentTab;
    switch (this.tabSetCurrentId) {
      case 'Clients':
        this.tempitems = this.clients;
        break;
      case 'Opportunities':
        this.tempitems = this.Opportunities;
        break;
      case 'Submissions':
        this.tempitems = this.Submissions;
        break;
      default:
        this.tempitems = this.items;
    }
    this.isMoreResultMessageShow(this.tabSetCurrentId);
  }

  public tabsetChange(tabCode: string) {
    this.tabSetCurrentId = tabCode;
    this.setItems(this.tabSetCurrentId);
  }

  public isMoreResultMessageShow(currentTab: string) {
    this.isMoreItemsMessageVisible = (
      (currentTab === 'Clients' && this.totalClientsCount > 50) ||
      (currentTab === 'Opportunities' && this.totalOpportunitiesCount > 50) ||
      (currentTab === 'Submissions' && this.totalSubmissionsCount > 50) ||
      (currentTab === 'AllResults' && this.totalCount > 150)
    );
  }

  transformStatus(status: string): StatusEnum {
    return Object.values(StatusEnum).find((enumValue) => enumValue === status) || StatusEnum.Draft;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (event.target instanceof Element && !event.target.closest('.search-box-container')) {
      this.clearSearchResults();
    }
  }

  public clearSearchResults() {
    this.searchKey = '';
    this.searchKey$.next(this.searchKey);
    this.tempitems = [];
    this.isLoading = false;
    this.isSearchItemsVisible = false;
    this.isResultContainerVisible = false;
    this.isMoreItemsMessageVisible = false;
    this.toggleCloseButton(true);
  }

  public highlightSearchKey(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text.replace(re, match => `<span class="highlight">${match}</span>`);
  }

  private fetchLastSearchData(): void {
    const lastSearchSubscription = this.globalSearchWebApiService.getLastSearchData().subscribe({
      next: (response) => {
        if (response.isSuccess && response.resultData.length > 0) {
          this.lastSearchItems = response.resultData;
          this.tempitems = this.lastSearchItems;
          this.isLastSearchItemsVisible = true;
          this.isNoLastSearchItemsMessageVisible = false;
        } else {
          this.isLastSearchItemsVisible = false;
          this.isNoLastSearchItemsMessageVisible = true;
        }
        this.isResultContainerVisible = true;
      },
      error: (err) => {
        console.error('Error while fetching last search data');
      }
    });
  
    this.subscriptions.push(lastSearchSubscription);
  }
}
