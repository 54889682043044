import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  btnOptions,
  filterData,
  GridColumn,
  options,
  sampleColumns,
  searchOptionsCtrl,
  selectItems,
  selectOptions,
} from './admin-table-helper.component';
import { AdminConstant } from '../../constants/admin-constants';
import { AdminModuleService } from '../../admin-services/admin-module.service';
import { Subject, takeUntil } from 'rxjs';
import {
  Column,
  PositionState,
  SortOptions,
  Themes,
} from '@usitsdasdesign/dds-ng/shared';
import {
  StickerDirective,
  StickerOptions,
} from '@usitsdasdesign/dds-ng/sticker';
import { Filter, FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ModifyMarketOfferingPopUpComponent } from './modify-market-offering-pop-up/modify-market-offering-pop-up.component';
import {
  filterState,
  MarketOfferingData,
  MarketOfferingRequest,
  MarketOfferingResponse,
} from '../../../common/models/admin-module.model';
import { DashboardGridSortParameter } from '../../../intake/constants/adminMoldule.enum';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { BrowserTabConstant } from '../../../common/constants/browser-tab-constant';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-admin-tables',
  templateUrl: './admin-tables.component.html',
  styleUrl: './admin-tables.component.less',
})
export class AdminTablesComponent implements OnInit, OnDestroy {
  selectOptions = selectOptions;
  tableNameSelected: string = AdminConstant.EMPTY_SPACE;
  tableNameTypes = selectItems;
  showMarketOfferingTable: boolean = false;
  showAdminExtCommTrainingTable: boolean = false;
  dropdownSelectedValue = AdminConstant.dropdownSelectedValue;
  adminExtCommTraining = AdminConstant.adminExtCommTrainingTableItem;
  private readonly unsubscriber$: Subject<void> = new Subject<void>();
  marketOfferingTableData: any[] = [];
  sampleColumns: GridColumn[] = sampleColumns;
  searchOptions: SearchOptions = searchOptionsCtrl;
  options: ButtonOptions = options;
  btnOptions: ButtonOptions = btnOptions;
  addRecordToTable: boolean = false;
  emptySpace: string = AdminConstant.EMPTY_SPACE;

  sortingState: SortOptions = {
    property: this.sampleColumns[0].name,
    descending: false,
    dataType: this.sampleColumns[0].dataType,
  };
  isShownFilter!: boolean;
  filteringColumn!: GridColumn;
  filterStickerOptions: StickerOptions = {
    stickerPosition: PositionState.bottomRight,
    stickerIsOutsideClick: true,
    stickerIndent: -1,
    stickerWidth: 280,
  };
  filterData: FilterItem[] = filterData;
  isAscending: boolean | null = null;
  sortParamaterCode: number | null = null;
  filterOptions: Filter = {
    theme: Themes.green,
    propName: 'name',
    itemPropNameValue: 'value',
  };

  @ViewChildren('stickerDir') sticker!: QueryList<StickerDirective>;
  filterSortParameterCode = DashboardGridSortParameter;
  filteringState: filterState[] = [];

  constructor(
    private readonly adminModuleService: AdminModuleService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_Admin;
    this.fetchMarketOfferingData();
  }

  fetchMarketOfferingData(): void {
    const requestBody: MarketOfferingRequest = {};

    if (this.sortParamaterCode !== null && this.isAscending !== null) {
      requestBody.SortParameterCode = this.sortParamaterCode;
      requestBody.IsAscending = this.isAscending;
    }

    if (this.filteringState.length > 0) {
      requestBody.FilterData = this.filteringState;
    }

    this.adminModuleService
      .getMarketOfferingTableData(requestBody)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: MarketOfferingResponse) => {
          this.marketOfferingTableData = response.dataList;
        },
        error: (err: HttpErrorResponse) => {
          console.error(
            'An error occurred during fetching market offering table data: ',
            err
          );
        },
      });
  }

  onTableNameSelected(): void {
    this.showMarketOfferingTable =
      this.tableNameSelected === this.dropdownSelectedValue;
    this.showAdminExtCommTrainingTable =
      this.tableNameSelected === this.adminExtCommTraining;
  }

  public toggleFilter(column: Column): void {
    this.filteringColumn = column;
    this.isShownFilter = true;
  }

  public filterOnHidden(): void {
    this.isShownFilter = false;
  }

  public filterSorted(isAscending: boolean | null): void {
    const parameterCode =
      this.filterSortParameterCode[
        this.filteringColumn.name as keyof typeof DashboardGridSortParameter
      ];
    this.sortParamaterCode = parameterCode;
    this.isAscending = isAscending;

    this.sortingState = {
      property: this.filteringColumn.name,
      descending: !this.isAscending,
      dataType: this.filteringColumn.dataType,
    };

    this.fetchMarketOfferingData();
    this.filterHide();
  }

  public filterApply(searchValue: string): void {
    this.filteringColumn.filterable = true;
    const parameterCode =
      this.filterSortParameterCode[
        this.filteringColumn.name as keyof typeof DashboardGridSortParameter
      ];
    let filterCriteria: string = searchValue;
    const newFilter = {
      filtertypecode: parameterCode,
      filtercriteria: filterCriteria,
    };
    this.filteringState.push(newFilter);
    this.fetchMarketOfferingData();
    this.filterHide();
  }

  public filterHide(): void {
    this.sticker.forEach((item) => {
      if (item.isActive) {
        item.hide();
      }
    });
  }

  openModal(row: MarketOfferingData): void {
    const adminTableModal = this.modalService.open(
      ModifyMarketOfferingPopUpComponent
    );
    adminTableModal.componentInstance.rowData = row;
    adminTableModal
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          setTimeout(() => {
            this.fetchMarketOfferingData();
          }, AdminConstant.TIMEONESEC);
        },
        error: (err: Error) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  onEditTypeClick(event: Event, row: MarketOfferingData) {
    event.stopPropagation();
    this.openModal(row);
  }

  shouldDisplayColumn(columnName: string): boolean {
    return (
      columnName !== 'editIcon' &&
      columnName !== 'activationStatus' &&
      columnName !== 'excludedFromSelfReview' &&
      columnName !== 'totalFeeThresholds' &&
      columnName !== 'reviewerAvailability'
    );
  }

  emitAddRecordToExtTable($event: Event): void {
    this.addRecordToTable = true;
    setTimeout(() => {
      this.addRecordToTable = false;
    }, AdminConstant.TIMEZEROSEC);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
