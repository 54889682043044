import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import {
  ButtonKind,
  Column,
  ErrorState,
  ExtThemes,
  LabelPosition,
  Size,
  SpinnerThemes,
  Themes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { GlobalEngagementsConstant } from './constants/global-engagements.constants';
import { IntakeConstant } from '../intake/constants/intake.constant';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { ProgressOptions } from '@usitsdasdesign/dds-ng/progress';

export const secondaryButton = {
  theme: ExtThemes.green,
  kind: ButtonKind.primary,
  width: WidthState.fixed,
  isLoading: false,
  icon: 'dds-icon_close',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Cancel',
  customClass: IntakeConstant.EMPTY_SPACE,
  role: 'button',
};

export const SecondaryLoudButtonOptions: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.secondaryLoud,
  size: GlobalEngagementsConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: undefined,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: undefined,
  customClass: IntakeConstant.EMPTY_SPACE,
  role: 'button',
};

export const primaryButton = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  width: WidthState.fixed,
  isLoading: false,
  icon: IntakeConstant.EMPTY_SPACE,
  arialLabel: 'Confirm',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: true,
  customClass: IntakeConstant.EMPTY_SPACE,
  role: 'button',
};

export const closeBtnOptions: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.secondary,
  size: Size.md,
  width: WidthState.fixed,
  isLoading: false,
  icon: 'dds-icon_close',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: IntakeConstant.EMPTY_SPACE,
  customClass: IntakeConstant.EMPTY_SPACE,
  role: 'button',
};

export const radioBtns: RadioButton[] = [
  {
    options: {
      label: IntakeConstant.YES,
      theme: Themes.green,
    },
    value: IntakeConstant.YES,
  },
  {
    options: {
      label: IntakeConstant.NO,
      theme: Themes.green,
    },
    value: IntakeConstant.NO,
  },
];

export const radioGroupOptions: RadioGroupOptions = {
  name: 'group1',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  isRequired: false,
  isInverse: false,
};

export const PrimaryButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primary,
  size: Size.lg,
  width: WidthState.fixed,
};

export const SHOW_MORE_BTN_OPTIONS: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.silent,
  size: GlobalEngagementsConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: GlobalEngagementsConstant.DDS_ICON_ARROW_UP,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Show Less',
  customClass: IntakeConstant.EMPTY_SPACE,
  role: 'button',
};

export const ModalCloseButtonOptions: ButtonOptions = {
  theme: ExtThemes.dark,
  kind: ButtonKind.silent,
  size: GlobalEngagementsConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  icon: GlobalEngagementsConstant.DDS_ICON_CLOSE,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Close modal',
  customClass: IntakeConstant.EMPTY_SPACE,
  role: 'button',
};

export const ToggleGroupOptions = {
  title: IntakeConstant.EMPTY_SPACE,
  isInverse: false,
  isRequired: false,
};

export const documentTitleTooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'top',
  tooltipIndent: 15,
  tooltipIsOutsideClick: false,
  tooltipHasBeak: true,
  tooltipIsDisabled: false,
  tooltipIsInverse: false,
  tooltipIsDynamic: false,
  tooltipCustomClass: IntakeConstant.EMPTY_SPACE,
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipShowDelay: 0,
  tooltipHideDelay: 0,
  tooltipTheme: Themes.dark,
  tooltipMaxWidth: 300,
};

export const spinnerOptions = {
  theme: SpinnerThemes.gray,
  thickness: '0.188rem',
  size: '1.875rem',
  customClass: IntakeConstant.EMPTY_SPACE,
  isInverse: false,
};

export const UploaderAreaOptions = {
  heading: '',
  dragAndDropText: 'Drag and drop file(s)',
  description:
    'All common file types are supported, including: docx, pptx, pdf, .zip, etc. Max size of 100MB per file',
  isDragAndDrop: true,
  isMultiple: true,
  acceptFormats: '',
  acceptExtensions: '',
  restrictedFormats: '',
  restrictedExtensions: '',
  exceptSymbols: '',
  btnLabel: 'Select file(s)',
  chooseFileBtnKind: 'primary',
  isDownloadable: false,
  allowFilesWithoutType: false,
  disabled: false,
  isInverse: false,
};

export const textFileOptions: TextareaOptions = {
  placeholder: '',
  minHeight: 100,
  maxLength: 400,
  maxLengthPosition: 'bottom-right',
  size: IntakeConstant.UI_ELEMENT_SIZE,
};

export const selectDocumentTypeOptions: SelectOptions = {
  label: '',
  labelPosition: LabelPosition.external,
  description: '',
  placeholder: 'Select type',
  size: IntakeConstant.UI_ELEMENT_SIZE,
  disabled: false,
  isResponsive: true,
  isRequired: true,
  isInverse: false,
  customClass: '',
  stickerWidth: 0,
  stickerShift: 0,
  stickerMaxHeight: '',
  stickerIsDisabled: false,
  stickerPosition: 'bottom-left',
  stickerIndent: 0,
  stickerCustomClass: '',
  stickerIsDynamic: true,
};

export const selectOptionsStatus: SelectOptions = {
  label: '',
  labelPosition: LabelPosition.external,
  description: '',
  placeholder: 'Select status',
  size: IntakeConstant.UI_ELEMENT_SIZE,
  disabled: false,
  isResponsive: true,
  isRequired: true,
  isInverse: false,
  customClass: '',
  stickerWidth: 0,
  stickerShift: 0,
  stickerMaxHeight: '',
  stickerIsDisabled: false,
  stickerPosition: 'bottom-left',
  stickerIndent: 0,
  stickerCustomClass: '',
  stickerIsDynamic: true,
};

export const options: ProgressOptions = {
  size: IntakeConstant.UI_ELEMENT_SIZE,
  theme: 'green',
  isInverse: false,
  label: '',
  description: '',
  isVertical: false,
};

export const DocumentColumns: Column[] = [
  { name: 'Title', header: 'Title', dataType: 'string' },
  { name: 'Document type', header: 'Document type', dataType: 'string' },
  { name: 'Status', header: 'Status', dataType: 'string' },
  {
    name: 'Comment(optional)',
    header: 'Comment (optional)',
    dataType: 'string',
  },
];

export const linkButton: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primary,
  size: Size.md,
  width: WidthState.fixed,
};
