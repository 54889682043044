<div class="tabs-container margin-bottom-xs">
  <dds-tabs
    [theme]="tabContainerOptions.theme ?? 'primary'"
    [themeType]="tabContainerOptions.themeType ?? 'primary'"
    [size]="tabContainerOptions.size ?? 'md'"
    [isResponsive]="tabContainerOptions.isResponsive ?? true"
    [disabled]="tabContainerOptions.disabled ?? false"
    [ariaLabel]="tabContainerOptions.ariaLabel ?? 'Tabs'"
    class="text-align-left"
  >
    <ng-container *ngFor="let tab of tabs">
      <dds-tab
        *ngIf="tab.count !== undefined; else tabwithcounter"
        [label]="tab.label"
        [counter]="tab.count"
        [customClass]="tab.tabCode === selectedTabCode ? 'active' : ''"
        (tabChanged)="onTabChange(tab.tabCode)"
        #tabButton
      >
      </dds-tab>
      <ng-template #tabwithcounter>
        <dds-tab
          [label]="tab.label"
          [customClass]="tab.tabCode === selectedTabCode ? 'active' : ''"
          (tabChanged)="onTabChange(tab.tabCode)"
          #tabButton
        >
        </dds-tab>
      </ng-template>
    </ng-container>
  </dds-tabs>

  <div class="button-block">
    <dds-button
      *ngIf="showClearFilterButton"
      class="padding-right-xs"
      [theme]="clearButtonoptions.theme ?? ''"
      [kind]="clearButtonoptions.kind ?? ''"
      [size]="clearButtonoptions.size ?? ''"
      [width]="clearButtonoptions.width ?? ''"
      (clicked)="clearAllFilter()"
    >
      Clear filters
    </dds-button>
    <dds-button
      *ngIf="showDownloadButton"
      [theme]="downlaodbuttonoptions.theme ?? ''"
      [kind]="downlaodbuttonoptions.kind ?? ''"
      [size]="downlaodbuttonoptions.size ?? ''"
      [icon]="downlaodbuttonoptions.icon ?? ''"
      (click)="downloadData()"
    ></dds-button>
  </div>
  <div class="extra-border"></div>
</div>
