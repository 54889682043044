import { Component, HostListener, Input } from '@angular/core';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { UploaderOptions, UploadFile } from '@usitsdasdesign/dds-ng/uploader';
import { Column, LabelPosition } from '@usitsdasdesign/dds-ng/shared';
import { ProgressOptions } from '@usitsdasdesign/dds-ng/progress';
import {
  SelectItemOptions,
  SelectOptions,
} from '@usitsdasdesign/dds-ng/select';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { DocumentService } from '../../../../http/document/document.service';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { CommonHelperService } from '../../../../common/services/common-helper.service';
import { NotifyPopupComponent } from '../../../../common/notify-popup/notify-popup.component';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { ExternalCommunicationConstant } from '../../../constants/external_communication.constant';
import { ExternalCommunicationService } from '../../../../http/external-communication/external-communication.service';
import {
  ModalCloseButtonOptions,
  newDocumentIds,
  UploaderAreaOptions,
} from '../../../external-communications.helper';
import { SaveSubmissionDocument, SubmissionDocumentDetails } from '../../../../common/models/external-communication.model';
import { RoleEnum } from '../../../../intake/constants/Role.enum';
import { UserService } from '../../../../http/user.service';
import { BrowserTabConstant } from '../../../../common/constants/browser-tab-constant';

@Component({
  selector: 'app-external-communication-document-upload',
  templateUrl: './external-communication-document-upload.component.html',
  styleUrl: './external-communication-document-upload.component.less',
})
export class ExternalCommunicationDocumentUploadComponent {
  constructor(
    private readonly externalCommService: ExternalCommunicationService,
    private readonly docService: DocumentService,
    private readonly modalService: ModalService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly commonHelperService: CommonHelperService
  ) {}
  unsubscriber$: Subject<void> = new Subject<void>();

  @Input() public documentInitiatedDetails: any = {};
  @Input() size: string = ExternalCommunicationConstant.EMPTY_SPACE;
  @Input() isInverse: boolean = false;
  @Input() set documentStatusDropdown(value: SelectItemOptions[]) {
    this.documentStatusItemList = value;
    this.allStatusAvailable = value; // TODO: Remove if not required
  }
  @Input() set documentTypeDropdown(value: SelectItemOptions[]) {
    this.documentTypeItemsList = value;
  }
  @Input() userHasAccess: boolean = false;
  @Input() documentTableDataCurrently: SubmissionDocumentDetails[] = [];

  filePath: string = ExternalCommunicationConstant.EMPTY_SPACE;
  uploadFileSourceList: any = [];
  maxFileLength: number = 5;
  notifyReason: string = ExternalCommunicationConstant.EMPTY_SPACE;
  uploadRequestList: any = [];
  maxFileSizeAllowed: number = 50 * 1024 * 1024; // 50 MB in bytes
  maxFileSizeAllowedUpto: number = 100 * 1024 * 1024; // 100 MB in bytes
  isFileSize100MBExceed: boolean = false;
  validFileExtensions: string =
    'doc,docx,msg,pptx,pdf,ppt,xls,xlsb,xlsx,xlsm,jpg,jpeg,png,zip'; //Valid File Extensions
  fileSourceList: any = [];
  removeUploadedDocumentFromList: any = [];
  fileUploadPercentage: number = 0;
  isEncrypted: boolean = true;
  isUploadingInProgress: boolean = false;
  isFileUploaded: boolean = false;
  isDocumentExists: boolean = false;
  documentUploadData: any = [];
  documentAllSectionsMetaData: any = [];
  documentUploadInProgressData: any = [];

  isEditing: boolean = false;
  otherTypeSelected: number = ExternalCommunicationConstant.OTHER_STATUS;
  emptyString: string = ExternalCommunicationConstant.EMPTY_SPACE;
  uploadAreaOptions: UploaderOptions = UploaderAreaOptions;
  modalCloseButtonOptions: ButtonOptions = ModalCloseButtonOptions;

  textFileOptions: TextareaOptions = {
    placeholder: ExternalCommunicationConstant.EMPTY_SPACE,
    minHeight: 100,
    maxLength: 400,
    maxLengthPosition: 'bottom-right',
    size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  };
  textareaOptions: TextareaOptions = {
    placeholder: 'Enter Comment',
    minHeight: 100,
    maxLength: 400,
    maxLengthPosition: 'bottom-right',
    size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
  };

  selectOptions: SelectOptions = {
    label: ExternalCommunicationConstant.EMPTY_SPACE,
    labelPosition: LabelPosition.external,
    description: ExternalCommunicationConstant.EMPTY_SPACE,
    placeholder: ExternalCommunicationConstant.EMPTY_SPACE,
    size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
    disabled: false,
    isResponsive: true,
    isRequired: true,
    isInverse: false,
    customClass: ExternalCommunicationConstant.EMPTY_SPACE,
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: ExternalCommunicationConstant.EMPTY_SPACE,
    stickerIsDisabled: false,
    stickerPosition: 'bottom-left',
    stickerIndent: 0,
    stickerCustomClass: ExternalCommunicationConstant.EMPTY_SPACE,
    stickerIsDynamic: true,
  };

  documentTypeItemsList: SelectItemOptions[] = [];
  documentStatusItemList: SelectItemOptions[] = [];
  allStatusAvailable: SelectItemOptions[] = [];
  options: ProgressOptions = {
    size: ExternalCommunicationConstant.UI_ELEMENT_SIZE,
    theme: 'green',
    isInverse: false,
    label: ExternalCommunicationConstant.EMPTY_SPACE,
    description: ExternalCommunicationConstant.EMPTY_SPACE,
    isVertical: false,
  };
  public DocumentColumns: Column[] = [
    { name: 'Title', header: 'Title', dataType: 'string' },
    { name: 'Document type', header: 'Document type', dataType: 'string' },
    { name: 'Status', header: 'Status', dataType: 'string' },
    {
      name: 'Comment(optional)',
      header: 'Comment (optional)',
      dataType: 'string',
    },
  ];

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_ExternalCommunications;
    this.filePath = this.commonHelperService.getDocumentFolderPath(
      this.documentInitiatedDetails.moduleName
    );

    this.uploadedItems(this.documentInitiatedDetails.file);
  }

  removeDocument(row: any): void {
    const index = this.documentUploadData.indexOf(row);
    if (index === -1) {
      return;
    }
    this.documentUploadData.splice(index, 1);
    this.fileSourceList = this.fileSourceList.filter(
      (element: any, index: any) =>
        this.removeUploadedDocumentFromList.indexOf(element.fileName) === -1
    );
    this.loadFileSourceData(this.fileSourceList);
  }

  async onSave(): Promise<void> {
    if (!this.isSaveButtonDisabled()) {
      try {
        this.progressIndicator.show();
        await this.UploadFiletoS3();
        await this.saveDocumentMetaData();
        this.closeModal();
      } catch (error) {
        console.error('Error in saving uploaded documents', error);
      } finally {
        this.progressIndicator.hide();
      }
    }
  }

  async saveDocumentMetaData(): Promise<void> {
    return new Promise((resolve, reject) => {
      const uploadedDocs: SaveSubmissionDocument[] =
        this.documentUploadData.map((doc: any) => {
          return {
            SubmissionId: doc.submissionId,
            DocumentTypeId: doc.documentTypeId,
            DocumentStatusId: doc.documentStatusId,
            SubmissionDocumentTypeId: doc.submissionDocumentTypeId,
            DocumentTitle: doc.documentTitle,
            DocumentUrl: doc.documentUrl,
            Comments: doc.comments,
            AttachmentId: doc.attachmentId,
          };
        });
      this.externalCommService
        .postDocumentSave(uploadedDocs)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            resolve();
          },
          error: (err: any) => {
            // TODO: Getting 200 status with parsing error of message. Check with backend
            if (err?.status === 200) {
              resolve();
            } else {
              console.error('An error occurred during metadata save:', err);
              reject(err);
            }
          },
        });
    });
  }

  validateDocumentAlreadyExists(title: boolean): boolean|string {
    let documentTitle = this.emptyString;
    this.documentTableDataCurrently.forEach((element: any) => {
      this.documentUploadData.forEach((element1: any) => {
        if (element.documentTitle === element1.documentTitle) {
          documentTitle = element.documentTitle;
        }
      });
    });
    return title ? documentTitle : Boolean(documentTitle);
  }

  isSaveButtonDisabled(): boolean {
    if (this.documentUploadData.length === 0) {
      return true;
    }
    let tempData = this.documentUploadData.some(
      (obj: any) =>
        obj.documentTitle.length === 0 ||
        obj.documentTypeId === 0 ||
        obj.documentStatusId === 0
    );

    return (tempData ? true : false) || Boolean(this.validateDocumentAlreadyExists(false));
  }

  loadFileSourceList(): void {
    this.uploadFileSourceList = [];
    this.documentUploadInProgressData = [];
    this.uploadRequestList = [];
    this.fileSourceList.forEach((element: any) => {
      this.uploadFileSourceList.push({ fileSource: element.file });
      this.documentUploadInProgressData.push({ fileName: element.file.name });
      let uploadRequest = {
        FileFolderPath: this.filePath,
        DocumentName: element.file.name,
      };

      this.uploadRequestList.push(uploadRequest);
      this.documentUploadData.push({
        submissionId: this.documentInitiatedDetails.submissionId,
        documentTypeId: 0,
        documentStatusId: 0,
        submissionDocumentTypeId: newDocumentIds.submissionDocumentId.file,
        documentTitle: element.file.name,
        documentUrl: ExternalCommunicationConstant.EMPTY_SPACE,
        comments: ExternalCommunicationConstant.EMPTY_SPACE,
      });
    });
    this.updateProgressBar('PresignedURLUpdated');
  }

  UploadDraggedAndSelectedDocument(): void {
    this.updateProgressBar('Validation');
    this.loadFileSourceList();
    this.updateProgressBar('uploaded');
  }

  updateProgressBar(event: any): void {
    if (event == 'Validation') {
      this.fileUploadPercentage = 50;
    } else if (event == 'PresignedURL') {
      this.fileUploadPercentage = 75;
    } else if (event == 'PresignedURLUpdated') {
      this.fileUploadPercentage = 95;
    } else if (event == 'uploaded') {
      this.fileUploadPercentage = 100;
    }
  }

  async UploadFiletoS3(): Promise<void> {
    const uploadPromises = this.uploadFileSourceList.map(
      (fileSource: any, index: number) => {
        return new Promise<void>((resolve, reject) => {
          this.docService
            .getUploadUrlForDCRMValidation(fileSource.fileSource.name)
            .subscribe({
              next: (presignedUrlData: any) => {
                this.docService
                  .uploadFileToS3UsingPresignedUrl(
                    presignedUrlData.resultData,
                    fileSource.fileSource
                  )
                  .subscribe(
                    () => {
                      this.docService
                        .uploadDocument(
                          fileSource.fileSource.name,
                          presignedUrlData.resultData.documentKey,
                          this.documentInitiatedDetails.moduleName
                        )
                        .subscribe({
                          next: (result: any) => {
                            this.updateProgressBar('PresignedURLUpdated');
                            if (this.documentUploadData[index]) {
                              this.documentUploadData[index].attachmentId =
                                result.attachmentID;
                            }
                            resolve();
                          },
                          error: (err: any) => {
                            console.error('Error uploading file:', err);
                            reject(err);
                          },
                        });
                    },
                    (error) => {
                      console.error('Error uploading file:', error);
                      this.progressIndicator.hide();
                    }
                  );
              },
              error: (error: any) => {
                console.error('Error getting presigned URL:', error);
                this.progressIndicator.hide();
              },
            });
        });
      }
    );

    await Promise.all(uploadPromises);
  }

  enableDisableUIDocumentDetails(): void {
    this.isUploadingInProgress = false;
    this.isFileUploaded = false;
    if (this.documentUploadData.length > 0) {
      this.isFileUploaded = true;
    }
    if (this.fileSourceList.length > 0) {
      this.isUploadingInProgress = true;
    }
  }

  applyBodyOverflow(): void {
    document.body.style.overflow = 'auto';
  }

  closeModal(): void {
    this.applyBodyOverflow();
    this.uploadFileSourceList = [];
    this.documentUploadInProgressData = [];
    this.uploadRequestList = [];
    this.documentInitiatedDetails.file = [];
    this.documentUploadData = [];

    this.modalService.close(this.documentAllSectionsMetaData);
  }

  isFileExtensionValid(): boolean {
    let isFileExtensionValid = true;
    this.fileSourceList.forEach((element: any) => {
      if (
        !this.validFileExtensions
          .toString()
          .toLowerCase()
          .includes(element.fileExtenstion.toString().toLowerCase())
      ) {
        isFileExtensionValid = false;
      }
    });
    return isFileExtensionValid;
  }

  isFileSizeExceed(): boolean {
    let isFileSizeExceed = false;
    this.isFileSize100MBExceed = false;
    this.fileSourceList.forEach((element: any) => {
      if (
        element.file.size > this.maxFileSizeAllowed &&
        element.file.size <= this.maxFileSizeAllowedUpto
      ) {
        isFileSizeExceed = true;
      } else if (element.file.size > this.maxFileSizeAllowedUpto) {
        this.isFileSize100MBExceed = true;
      }
    });
    return isFileSizeExceed;
  }

  isMaxFileSelectionExceed(): boolean {
    let isMaxFileSelectionExceed = false;
    let selectedFileCount =
      this.fileSourceList.length + this.documentUploadData.length;
    if (selectedFileCount > this.maxFileLength) {
      isMaxFileSelectionExceed = true;
    }
    return isMaxFileSelectionExceed;
  }

  removeBodyOverflow(): void {
    document.body.style.overflow = 'hidden';
  }

  showMsg(modalBtn?: ButtonComponent) {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(NotifyPopupComponent, {
      isFooter: true,
      size: 'md',
      isInverse: false,
      notifyReason: this.notifyReason,
    });

    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        if (modalBtn) {
          modalBtn.focus();
        }
      });
  }

  uploadedItems(filesList: UploadFile[]): void {
    if (filesList?.length > 0) {
      this.removePreviousUploadDocument(filesList);
      if (this.fileSourceList.length > 0) {
        if (this.isFileSizeExceed()) {
          this.notifyReason = 'inValidFileSize';
          this.showMsg();
          this.IsDocumentProtected();
        } else if (this.isMaxFileSelectionExceed()) {
          this.notifyReason = 'inValidFileSelection';
          this.showMsg();
        } else if (!this.isFileExtensionValid()) {
          this.notifyReason = 'inValidFileType';
          this.showMsg();
        } else {
          this.IsDocumentProtected();
        }
      }
    }
  }

  IsDocumentProtected(): void {
    this.UploadDraggedAndSelectedDocument();
  }

  loadFileSourceData(fileSource: any): void {
    fileSource.forEach((element: any) => {
      this.removeUploadedDocumentFromList.push(element.fileName);
    });
  }

  removePreviousUploadDocument(filesListSource: any): void {
    if (this.removeUploadedDocumentFromList.length == 0) {
      this.loadFileSourceData(filesListSource);
      this.fileSourceList = filesListSource;
    } else {
      this.fileSourceList = filesListSource.filter(
        (element: any, index: any) =>
          this.removeUploadedDocumentFromList.indexOf(element.fileName) === -1
      );
      this.loadFileSourceData(this.fileSourceList);
    }
  }

  onFileNameEdit($event: MouseEvent, row: any): void {
    $event.stopPropagation();
    row.isFileNameInEditMode = true;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    this.documentUploadData.forEach(
      (obj: { isFileNameInEditMode: boolean }) => {
        if (!target.closest('.editing')) {
          if (this.isEditing) {
            obj.isFileNameInEditMode = false;
            this.isEditing = false;
          } else {
            this.isEditing = true;
          }
          obj.isFileNameInEditMode = false;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
