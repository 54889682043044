<progress-indicator></progress-indicator>
<div class="main-container margin-left-s">
  <div class="title-container">
    <h4 class="eclipse-main-tab-header text-align-left">Engagement Details</h4>
  </div>
  <div class="accordian-width">
    <dds-accordion
      [disabled]="accordionOptions.disabled"
      [isMulti]="accordionOptions.isMulti"
      [isOpen]="accordionOptions.isOpen"
      [isInverse]="accordionOptions.isInverse"
      [size]="accordionOptions.size"
    >
      <dds-accordion-item [isOpen]="true">
        <span
          class="eclipse-section-header accordion-header text-align-left"
          header
          >Engagement information</span
        >
        <span [autosave-module]="savedetails" content>
          <div class="grid-container margin-top-l">
            <div
              class="grid-item"
              *ngIf="
                permissionObj['isGlobalEngEngagementDetailsEngagementIdVisible']
              "
            >
              <div>
                <div class="eclipse-small-header-grey text-align-left">
                  Engagement ID:
                </div>
                <div class="font-size-14 dropdown-width-small">
                  <span>{{ engagementDetails?.engagementId }} </span>
                </div>
              </div>
            </div>
            <div
              class="grid-item"
              *ngIf="
                permissionObj['isGlobalEngEngagementDetailsMemberFirmVisible']
              "
            >
              <div>
                <div class="eclipse-small-header-grey text-align-left">
                  Member firm:
                </div>
                <div *ngIf="isMemberFirmEditable; else displayFirm">
                  <dds-select
                    class="edit-select-submission"
                    autosave
                    [list]="memberFirmList"
                    [disabled]="
                      !permissionObj[
                        'isGlobalEngEngagementDetailsMemberFirmEnable'
                      ]
                    "
                    [(ngModel)]="memberFirmId"
                    (ngModelChange)="onMemberFirmChange($event)"
                    [stickerMaxHeight]="200"
                  ></dds-select>
                </div>
                <ng-template #displayFirm class="text-align-left">
                  <span class="font-size-14">
                    {{
                      memberFirmName !== undefinedString &&
                      memberFirmName !== nullString &&
                      memberFirmName !== undefined &&
                      memberFirmName !== null &&
                      memberFirmName !== emptyString
                        ? memberFirmName
                        : defaultValue
                    }}
                  </span>
                  <span
                    *ngIf="
                      permissionObj[
                        'isGlobalEngEngagementDetailsMemberFirmEnable'
                      ]
                    "
                    (click)="makeFeildEditable(memberFirmConstant)"
                    #editButton
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                  ></span>
                </ng-template>
              </div>
            </div>
            <div
              class="grid-item"
              *ngIf="
                permissionObj[
                  'isGlobalEngEngagementDetailsEngagementTitleVisible'
                ]
              "
            >
              <div>
                <div class="eclipse-small-header-grey text-align-left">
                  Engagement title:
                </div>
                <div *ngIf="isEngagementTitleEditable; else displayTitle">
                  <dds-textarea
                    autosave
                    [(ngModel)]="engagementTitle"
                    [maxLength]="textLength100"
                    [maxLengthPosition]="'bottom-right'"
                    [disabled]="
                      !permissionObj[
                        'isGlobalEngEngagementDetailsEngagementTitleEnable'
                      ]
                    "
                  ></dds-textarea>
                </div>
                <ng-template #displayTitle>
                  <span class="font-size-14">{{
                    engagementTitle !== undefinedString &&
                    engagementTitle !== nullString &&
                    engagementTitle !== undefined &&
                    engagementTitle !== null &&
                    engagementTitle !== emptyString
                      ? engagementTitle
                      : defaultValue
                  }}</span>
                  <span
                    *ngIf="
                      permissionObj[
                        'isGlobalEngEngagementDetailsEngagementTitleEnable'
                      ]
                    "
                    #editButton
                    (click)="makeFeildEditable(engagementTitleConstant)"
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                  ></span>
                </ng-template>
              </div>
            </div>
            <div
              class="grid-item"
              *ngIf="
                permissionObj[
                  'isGlobalEngEngagementDetailsEngagementStartDateVisible'
                ]
              "
            >
              <div>
                <div class="eclipse-small-header-grey text-align-left">
                  <span *ngIf="!startDate">
                    <img
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                      alt="yellow-icon"
                    /> </span
                  >Engagement start date:
                </div>
                <div *ngIf="isstartDateEditable; else displayStartDate">
                  <dds-datepicker
                    [(ngModel)]="tempStartDate"
                    [maxDate]="validStartDate"
                    (dateChanged)="startDateChanged()"
                    [disabled]="
                      !permissionObj[
                        'isGlobalEngEngagementDetailsEngagementStartDateEnable'
                      ]
                    "
                  ></dds-datepicker>
                </div>
                <ng-template #displayStartDate>
                  <span class="font-size-14">
                    {{
                      startDate !== undefined && startDate !== null
                        ? startDate
                        : ""
                    }}
                  </span>
                  <span
                    *ngIf="
                      permissionObj[
                        'isGlobalEngEngagementDetailsEngagementStartDateEnable'
                      ]
                    "
                    #editButton
                    (click)="makeFeildEditable(startDateConstant)"
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                  ></span>
                </ng-template>
              </div>
            </div>
            <div
              class="grid-item"
              *ngIf="
                permissionObj[
                  'isGlobalEngEngagementDetailsEngagementEndDateVisible'
                ]
              "
            >
              <div>
                <div class="eclipse-small-header-grey text-align-left">
                  <span *ngIf="!endDate">
                    <img
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                      alt="yellow-icon"
                    /> </span
                  >Engagement end date:
                </div>
                <div *ngIf="isendDateEditable; else displayEndDate">
                  <dds-datepicker
                    [(ngModel)]="tempEndDate"
                    [minDate]="validEndDate"
                    (dateChanged)="endDateChanged()"
                    [disabled]="
                      !permissionObj[
                        'isGlobalEngEngagementDetailsEngagementEndDateEnable'
                      ]
                    "
                  ></dds-datepicker>
                </div>
                <ng-template #displayEndDate>
                  <span class="font-size-14">
                    {{
                      endDate !== undefined && endDate !== null ? endDate : ""
                    }}
                  </span>
                  <span
                    *ngIf="
                      permissionObj[
                        'isGlobalEngEngagementDetailsEngagementEndDateEnable'
                      ]
                    "
                    (click)="makeFeildEditable(endDateConstant)"
                    #editButton
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                  ></span>
                </ng-template>
              </div>
            </div>
            <div class="grid-item"></div>
            <div
              class="grid-item"
              *ngIf="
                permissionObj[
                  'isGlobalEngEngagementDetailsJupiterOpportunityIdVisible'
                ]
              "
            >
              <div>
                <div class="eclipse-small-header-grey text-align-left">
                  Jupiter opportunity ID:
                </div>
                <div *ngIf="isOpportunityEditable; else displayOpportunity">
                  <div class="d-flex">
                    <div class="search-box-submission relative">
                      <dds-search
                        [icon]="'dds-icon_search'"
                        (fieldCleared)="fieldCleared()"
                        [disabled]="
                          !permissionObj[
                            'isGlobalEngEngagementDetailsJupiterOpportunityIdEnable'
                          ]
                        "
                      >
                        <input
                          ddsSearch
                          [placeholder]="'Search by Jupiter opportunity ID'"
                          [disabled]="
                            !permissionObj[
                              'isGlobalEngEngagementDetailsJupiterOpportunityIdEnable'
                            ]
                          "
                          [(ngModel)]="selectedOportunity"
                          (ngModelChange)="selectOpportunityChanged($event)"
                        />
                      </dds-search>
                      <div
                        *ngIf="isSpinnerVisible"
                        class="spinner-inside-input absolute"
                      >
                        <dds-spinner
                          [size]="spinnerOptions.size"
                          [theme]="spinnerOptions.theme"
                          [customClass]="spinnerOptions.customClass"
                          [isInverse]="spinnerOptions.isInverse"
                          [thickness]="spinnerOptions.thickness"
                        ></dds-spinner>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="showDropdown || notFound"
                    class="list-of-oppportunities d-flex flex-row-column flex-row-column padding-left-xs padding-right-xs margin-top-xs"
                    (scroll)="fetchOpportunitySearchResultMore($event)"
                  >
                    <div *ngIf="!notFound" class="tabs-container">
                      <dds-tabs
                        [theme]="tabContainerOptions.theme ?? 'primary'"
                        [themeType]="tabContainerOptions.themeType ?? 'primary'"
                        [size]="tabContainerOptions.size ?? 'md'"
                        [isResponsive]="
                          tabContainerOptions.isResponsive ?? true
                        "
                        [disabled]="tabContainerOptions.disabled ?? false"
                        [ariaLabel]="tabContainerOptions.ariaLabel ?? 'Tabs'"
                        [activeIndex]="selectTabIndex"
                        class="text-align-left"
                      >
                        <span *ngFor="let tab of tabs">
                          <dds-tab
                            class="padding-right-xs"
                            [label]="tab.label ?? ''"
                            [counter]="tab.count ?? 0"
                            (tabChanged)="tabChanged(tab.code)"
                          >
                          </dds-tab>
                        </span>
                      </dds-tabs>
                    </div>
                    <div *ngFor="let item of opportunityList">
                      <div
                        class="opportunity-items d-flex flex-row-column margin-left-s"
                        (click)="
                          fetchOpportunityDetails(item, 'searchOpportunity')
                        "
                      >
                        <div
                          class="opportunity-header font-size-14 font-bold-600 text-align-left"
                        >
                          {{ item?.opportunityName }}
                          <span
                            class="client-status font-bold-400"
                            *ngIf="item?.stage == 'Closed Lost'"
                            >[Lost]</span
                          >
                          <span
                            class="client-status font-bold-400"
                            *ngIf="item?.stage == 'Closed Abandoned'"
                            >[Abandoned]</span
                          >
                        </div>
                        <div
                          class="opportunity-details margin-top-xxs font-size-12 text-align-left font-bold-400"
                        >
                          <span class="detail-heading font-bold-400">ID: </span>
                          <span
                            class="details-values font-size-12 text-align-left font-bold-400"
                            >{{ item?.opportunityNumber }}
                          </span>
                          <span class="separator"> | </span>
                          <span class="detail-heading font-bold-400"
                            >Opportunity stage:
                          </span>
                          <span
                            class="details-values font-size-12 text-align-left font-bold-400"
                            >{{ item?.stage }}</span
                          >
                          <span class="separator"> | </span>
                          <span class="detail-heading font-bold-400"
                            >Leader:
                          </span>
                          <span
                            class="details-values font-size-12 text-align-left font-bold-400"
                            >{{ item?.leader }}</span
                          >
                          <span class="separator"> | </span>
                          <span class="detail-heading font-bold-400"
                            >Client:
                          </span>
                          <span
                            class="details-values font-size-12 text-align-left font-bold-400"
                            >{{ item?.client }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-template #displayOpportunity class="text-align-left">
                  <span class="font-size-14"
                    >{{
                      selectedOportunity ? selectedOportunity : defaultValue
                    }}
                  </span>
                  <span
                    (click)="makeFeildEditable(opportunityConstant)"
                    #editButton
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                  ></span>
                </ng-template>
              </div>
            </div>
            <div
              class="grid-item"
              *ngIf="
                permissionObj[
                  'isGlobalEngEngagementDetailsOfferingPortfolioVisible'
                ]
              "
            >
              <div>
                <div class="eclipse-small-header-grey text-align-left">
                  <span *ngIf="!offeringPortfolioId">
                    <img
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                      alt="yellow-icon"
                    /> </span
                  >Offering portfolio:
                </div>
                <div
                  *ngIf="isofferingPortfolioEditable; else displayPortfolioFirm"
                >
                  <dds-select
                    class="edit-select-submission"
                    [list]="offeringPortfolioList"
                    [(ngModel)]="offeringPortfolioId"
                    [isResponsive]="true"
                    [disabled]="
                      !permissionObj[
                        'isGlobalEngEngagementDetailsOfferingPortfolioEnable'
                      ]
                    "
                    (ngModelChange)="onOfferingPortfolioChange($event)"
                    [stickerMaxHeight]="200"
                  ></dds-select>
                </div>
                <ng-template #displayPortfolioFirm class="text-align-left">
                  <span class="font-size-14"
                    >{{
                      offeringPortfolioName !== undefinedString &&
                      offeringPortfolioName !== nullString
                        ? offeringPortfolioName
                        : defaultValue
                    }}
                  </span>
                  <span
                    *ngIf="
                      permissionObj[
                        'isGlobalEngEngagementDetailsOfferingPortfolioEnable'
                      ]
                    "
                    (click)="makeFeildEditable(offeringPortfolioConstant)"
                    #editButton
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                  ></span>
                </ng-template>
              </div>
            </div>
            <div
              *ngIf="
                permissionObj['isGlobalEngEngagementDetailsOfferingVisible'] &&
                offeringPortfolioName
              "
              class="grid-item"
            >
              <div>
                <div class="eclipse-small-header-grey text-align-left">
                  <span *ngIf="!offeringId">
                    <img
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                      alt="yellow-icon"
                    /> </span
                  >Offering:
                </div>
                <div *ngIf="isofferingEditable; else displayOffering">
                  <dds-select
                    class="edit-select-submission"
                    [list]="offeringList"
                    [(ngModel)]="offeringId"
                    [isResponsive]="true"
                    [disabled]="
                      !permissionObj[
                        'isGlobalEngEngagementDetailsOfferingEnable'
                      ]
                    "
                    (ngModelChange)="onOfferingChange($event)"
                    [stickerMaxHeight]="200"
                  ></dds-select>
                </div>
                <ng-template #displayOffering>
                  <span class="font-size-14">
                    {{
                      offeringName !== undefinedString &&
                      offeringName !== nullString &&
                      offeringName !== undefined
                        ? offeringName
                        : defaultValue
                    }}
                  </span>
                  <span
                    *ngIf="
                      permissionObj[
                        'isGlobalEngEngagementDetailsOfferingEnable'
                      ]
                    "
                    #editButton
                    (click)="makeFeildEditable(offeringConstant)"
                    class="dds-icon dds-icon_edit__s__filled edit-icon"
                  ></span>
                </ng-template>
              </div>
            </div>
          </div>
          <div>
            <div
              *ngIf="
                permissionObj[
                  'isGlobalEngEngagementDetailsEngagementDescriptionVisible'
                ]
              "
              class="d-flex flex-row margin-top margin-top-l"
            >
              <div class="d-flex flex-column">
                <div class="eclipse-content text-align-left margin-top-m">
                  <span *ngIf="!engagementDescription">
                    <img
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                      alt="yellow-icon"
                    /> </span
                  >Provide engagement description
                </div>
                <div class="textarea-container text-area">
                  <dds-textarea
                    autosave
                    [(ngModel)]="engagementDescription"
                    name="opportunityDescription"
                    [maxLength]="textLength1000"
                    [maxLengthPosition]="'bottom-right'"
                    [disabled]="
                      !permissionObj[
                        'isGlobalEngEngagementDetailsEngagementDescriptionEnable'
                      ]
                    "
                  ></dds-textarea>
                </div>
              </div>
            </div>
            <div
              *ngIf="permissionObj['isGlobalEngEngagementDetailsPPMDVisible']"
              class="d-flex flex-row margin-top-m"
            >
              <div class="d-flex flex-column">
                <div class="eclipse-content text-align-left">
                  <span
                    *ngIf="
                      !(ppmdName !== undefinedString && ppmdName !== nullString)
                    "
                  >
                    <img
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                      alt="yellow-icon"
                    /> </span
                  >Responsible Delivery Center PPMD
                </div>
                <div class="textarea-container">
                  <div>
                    <div class="select-submission margin-top-xs">
                      <div
                        *ngIf="
                          ppmdName !== undefinedString &&
                          ppmdName !== nullString
                        "
                        class="box-search d-flex justify-content-between padding-xxs"
                      >
                        <span class="margin-left-xs"> {{ ppmdName }} </span>
                        <span
                          *ngIf="
                            permissionObj[
                              'isGlobalEngEngagementDetailsPPMDEnable'
                            ]
                          "
                          (click)="removePPMD()"
                          class="font-bold cursor-clickable margin-right-xs"
                          >x</span
                        >
                      </div>
                      <app-people-picker
                        *ngIf="
                          !(
                            ppmdName !== undefinedString &&
                            ppmdName !== nullString
                          )
                        "
                        [isTechinalReviewerSearch]="true"
                        [isLep]="true"
                        [isDisabled]="
                          !permissionObj[
                            'isGlobalEngEngagementDetailsPPMDEnable'
                          ]
                        "
                        (userSelected)="onSelectedPPMD($event)"
                      ></app-people-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                permissionObj[
                  'isGlobalEngEngagementDetailsMemberFirmPartnerVisible'
                ]
              "
              class="d-flex flex-row margin-top-m"
            >
              <div class="d-flex flex-column">
                <div class="eclipse-content text-align-left">
                  <span
                    *ngIf="
                      !(
                        firmPartner !== undefinedString &&
                        firmPartner !== nullString
                      )
                    "
                  >
                    <img
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                      alt="yellow-icon"
                    /> </span
                  >Member firm partner
                </div>
                <div class="textarea-container">
                  <div>
                    <div class="select-submission margin-top-xs">
                      <div
                        *ngIf="
                          firmPartner !== undefinedString &&
                          firmPartner !== nullString
                        "
                        class="box-search d-flex justify-content-between padding-xxs"
                      >
                        <span class="margin-left-xs"> {{ firmPartner }} </span>
                        <span
                          *ngIf="
                            permissionObj[
                              'isGlobalEngEngagementDetailsMemberFirmPartnerEnable'
                            ]
                          "
                          (click)="removeFirmPartner()"
                          class="font-bold cursor-clickable margin-right-xs"
                          >x</span
                        >
                      </div>
                      <app-people-picker
                        *ngIf="
                          !(
                            firmPartner !== undefinedString &&
                            firmPartner !== nullString
                          )
                        "
                        [isTechinalReviewerSearch]="true"
                        [isLep]="true"
                        [isDisabled]="
                          !permissionObj[
                            'isGlobalEngEngagementDetailsMemberFirmPartnerEnable'
                          ]
                        "
                        (userSelected)="onSelectedFirmPartner($event)"
                      ></app-people-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="permissionObj['isGlobalEngEngagementDetailsPRRIDVisible']"
              class="d-flex flex-row margin-top-m margin-bottom-l"
            >
              <div class="d-flex flex-column">
                <div class="eclipse-content text-align-left margin-bottom-xs">
                  <span *ngIf="pprStatus()">
                    <img
                      class="margin-right-xxs"
                      src="\assets\images\draftIcon.svg"
                      alt="yellow-icon"
                    /> </span
                  >PRR ID:
                </div>
                <div class="textarea-container d-flex">
                  <dds-number-input
                    class="select-submission"
                    [placeholder]="'Enter Number'"
                    autosave
                    [(ngModel)]="prrId"
                    [disabled]="
                      !permissionObj['isGlobalEngEngagementDetailsPRRIDEnable']
                    "
                  ></dds-number-input>
                  <dds-checkbox
                    [value]="isPprNotAvailable"
                    (stateChanged)="pprChanged()"
                    class="margin-left-s"
                    [label]="'Not available'"
                  ></dds-checkbox>
                </div>
              </div>
            </div>
          </div>
        </span>
      </dds-accordion-item>
    </dds-accordion>
  </div>
</div>
