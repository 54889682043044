<div class="dds-modal-overlay" (click)="closeEditModal()"></div>

<div ddsResize [ngClass]="{
  'dds-modal_lg': size === 'lg',
  'dds-modal_inverse': isInverse
  }" class="dds-modal">
    <div class="dds-modal__header">
        <span class="dds-modal__title">Edit document</span>
        <dds-button size="sm" icon="dds-icon_close" kind="silent" theme="dark" ariaLabel="Close modal"
            class="dds-modal__close" [isInverse]="isInverse" (clicked)="closeEditModal()"></dds-button>
    </div>

    <div class="dds-modal__body body-overflow">
       <div>
        <div class="row">
          <span>Title</span>
        </div>
        <div class="row">
            <dds-input [placeholder]="textareaOptions.placeholder ?? ''"
            [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
            [size]="textareaOptions.size ?? ''" [(ngModel)]="fileName">
        </dds-input>
        </div>
        <br>
        <div class="row">
            <span>Document type</span>
        </div>
        <div class="row">
            <dds-select [isResponsive]="selectDocumentTypeOptions.isResponsive??false"
            [isRequired]="selectDocumentTypeOptions.isRequired??false"
            [isInverse]="selectDocumentTypeOptions.isInverse??false" [list]="documentTypeItemsList"
            [label]="selectDocumentTypeOptions.label??''"
            [labelPosition]="selectDocumentTypeOptions.labelPosition??''"
            [customClass]="selectDocumentTypeOptions.customClass??''"
            [placeholder]="selectDocumentTypeOptions.placeholder??''"
            [size]="selectDocumentTypeOptions.size??''"
            [stickerWidth]="selectDocumentTypeOptions.stickerWidth??''"
           (valueChanged)="valueChange()"  [(ngModel)]="selectedDocumentTypeId">
        </dds-select>
        </div>
        <br>
        <div class="row">
            <span>Comment (optional)</span>
        </div>
        <div class="row">
            <dds-input [placeholder]="textareaOptions.placeholder ?? ''" 
            [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
            [maxLength]="textareaOptions.maxLength ?? 0"
            [size]="textareaOptions.size ?? ''" [(ngModel)]="documentEditMetaData.fileComments">
             </dds-input>
        </div>
       </div>
    </div>
    <div class="dds-modal__footer">
        <div class="dds-modal__footer-content dds-modal__footer-content_left">
            <dds-button class="dds-modal__footer-item" (clicked)="onSave()" [disabled]="!fileName"
                (keyboardClicked)="onSave()">Save</dds-button>
            <dds-button class="dds-modal__footer-item" kind="secondaryLoud" [isInverse]="isInverse"
                (clicked)="closeEditModal()" (keyboardClicked)="closeEditModal()">Cancel</dds-button>

        </div>
        <div class="dds-modal__footer-content dds-modal__footer-content_right"></div>
    </div>
</div>
