import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { ADMIN_MODULE_ROUTES } from './admin-module.states';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AdminPageComponent],
  imports: [
    CommonModule,
    LibModule,
    RouterModule.forChild(ADMIN_MODULE_ROUTES),
  ],
})
export class AdminModule {}
