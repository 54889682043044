import { Injectable } from '@angular/core';
import {
  ArchiveDetailsExtendedModel,
  ArchiveDetailsResponseModel,
  wbsChargeCodeRequestModel,
  WbsModel,
  WbsResponseModel,
} from '../../common/models/wbs-picker.model';
import { Observable } from 'rxjs';
import { UrlConstant } from '../../intake/constants/url.constant';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class CloseOutService {
  constructor(private readonly httpClient: HttpClient) {}

  #apiUrl: string = environment.apiUrl;
  //#apiUrl: string = 'https://dapi.eclipse.deloitte.com/api/'; //dev
  //#apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa

  getWbsPickers(): Observable<WbsModel[]> {
    return this.httpClient.get<WbsModel[]>(UrlConstant.GET_WBS_CHARGECODES);
  }

  getWbsChargeCodes(
    requestBody: wbsChargeCodeRequestModel
  ): Observable<WbsResponseModel> {
    return this.httpClient.post<WbsResponseModel>(
      this.#apiUrl + UrlConstant.GET_WBS_CODE_API,
      requestBody
    );
  }

  postSearchArchiveByWbs(
    wbsNumber: string | null
  ): Observable<ArchiveDetailsResponseModel> {
    const url = `${this.#apiUrl}${UrlConstant.GET_ARCHIVE_DROPDOWN}`;
    return this.httpClient.post<ArchiveDetailsResponseModel>(url, {
      WbsNumber: wbsNumber,
    });
  }

  postGetArchiveDetails(
    archiveNumber: string | null
  ): Observable<ArchiveDetailsExtendedModel> {
    const url = `${this.#apiUrl}${UrlConstant.GET_ARCHIVE_DETAILS}`;
    return this.httpClient.post<ArchiveDetailsExtendedModel>(url, {
      ArchiveNumber: archiveNumber,
    });
  }
}
