import {
  ButtonKind,
  ExtThemes,
  Size,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../constants/intake.constant';
import { RoleEnum } from '../../../constants/Role.enum';

export const ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primary,
  size: Size.md,
  width: WidthState.fixed,
};

export const UploaderOptions = {
  heading: '',
  dragAndDropText: 'Drag and drop file(s)',
  description:
    'All common file types are supported including: docx, pptx, pdf, .zip, etc. Max size of 50MB per file',
  isDragAndDrop: true,
  isMultiple: true,
  acceptFormats: '',
  acceptExtensions: '',
  restrictedFormats: '',
  restrictedExtensions: '',
  exceptSymbols: '',
  btnLabel: 'Select file(s)',
  chooseFileBtnKind: 'primary',
  isDownloadable: false,
  allowFilesWithoutType: false,
  disabled: false,
  isInverse: false,
};

export const INTERNAL_RM_REVIEWED_DOC_STATUS_ALLOWED_ROLES: string[] = [
  RoleEnum.RISK_MANAGER,
  RoleEnum.SUPPORTING_RISK_MANAGER,
  RoleEnum.SPECIALIZED_RISK_MANAGER,
  RoleEnum.SYSTEM_ADMINISTRATOR,
  RoleEnum.REVIEWER,
  RoleEnum.TECHNICAL_INDEPENDENT_RISK_MANAGER,
  RoleEnum.RM_SUPPORT_GLOBAL,
];
