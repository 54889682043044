import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class MsGraphService {
  private readonly graphUrl: string = '';
  private readonly searchNonUS: boolean = false;
  private readonly searchOnlyUS: boolean = false;
  private readonly GraphAPI_PeoplePicker_All: string = '';
  private readonly GraphAPI_PeoplePicker_NonUS: string = '';
  private readonly GRAPHAPI_PeoplePicker_US: string = '';

  constructor(private http: HttpClient) {
    this.graphUrl = environment.graphAPIConfig.graphAPIURL;
    this.searchNonUS = environment.graphAPIConfig.searchOnlyNonUS;
    this.searchOnlyUS = environment.graphAPIConfig.searchOnlyNonUS;
    this.GraphAPI_PeoplePicker_All =
      environment.graphAPIConfig.GraphAPI_PeoplePicker_All;
    this.GraphAPI_PeoplePicker_NonUS =
      environment.graphAPIConfig.GraphAPI_PeoplePicker_NonUS;
    this.GRAPHAPI_PeoplePicker_US =
      environment.graphAPIConfig.GRAPHAPI_PeoplePicker_US;
  }

  private getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  searchUserByEmail(email: string): Observable<any> {
    const token = this.getAccessToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      ConsistencyLevel: 'eventual',
    };
    let filterQuery = `?$filter=mail eq '${email}'`;

    if (this.searchOnlyUS) {
      filterQuery += ` and ${this.GRAPHAPI_PeoplePicker_US}`;
    } else if (this.searchNonUS) {
      filterQuery += ` and ${this.GraphAPI_PeoplePicker_NonUS}`;
    } else {
      filterQuery += ` and ${this.GraphAPI_PeoplePicker_All}`;
    }
    filterQuery += `&$top=10&$count=true`;
    return this.http.get(`${this.graphUrl}/users${filterQuery}`, { headers });
  }

  searchUsers(query: string): Observable<any> {
    const token = this.getAccessToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      ConsistencyLevel: 'eventual',
    };
    let filterQuery = `?$filter=startswith(displayName,'${query}') or startswith(mail,'${query}')`;

    if (this.searchOnlyUS) {
      filterQuery += ` and ${this.GRAPHAPI_PeoplePicker_US}`;
    } else if (this.searchNonUS) {
      filterQuery += ` and ${this.GraphAPI_PeoplePicker_NonUS}`;
    } else {
      filterQuery += ` and ${this.GraphAPI_PeoplePicker_All}`;
    }
    filterQuery += `&$top=20&$count=true&$ConsistencyLevel=eventual`;

    return this.http.get(`${this.graphUrl}/users${filterQuery}`, { headers });
  }

  GetProfileImage(Email: string): Observable<any> {
    const token = this.getAccessToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      ConsistencyLevel: 'eventual',
    };
    let filterQuery = `/${Email}/photos/48x48/$value`;
    return this.http
      .get(`${this.graphUrl}/users${filterQuery}`, {
        headers,
        responseType: 'blob',
      })
      .pipe(switchMap((blob) => this.convertBlobToBase64(blob)));
  }

  private convertBlobToBase64(blob: Blob): Observable<string> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.onerror = (error) => observer.error(error);
      reader.readAsDataURL(blob);
    });
  }

  // Add more methods for other MS Graph API calls as needed
}
