<div *ngIf="permissionObj['isExtCommRequestedReviewDateVisible']">
  <span
    ><img
      *ngIf="!selectedDate && !isDisabled"
      class="margin-right-xxs"
      src="\assets\images\draftIcon.svg"
  /></span>
  <span>Requested review date</span>
  <form [formGroup]="dateForm">
    <div
      #datepicker
      class="datePicker font-size-12 margin-top-xxs"
      *ngIf="permissionObj['isExtCommRequestedReviewDateEnable']"
    >
      <dds-datepicker
        id="requestedReviewDate-datepicker"
        [size]="htmlElementSize"
        [placeholder]="datePickerOptions.placeholder ?? ''"
        [format]="datePickerOptions.format ?? ''"
        [minMode]="datePickerOptions.minMode ?? ''"
        [disabledDates]="datePickerOptions.disabledDates ?? {}"
        [isResponsive]="datePickerOptions.isResponsive ?? false"
        [isManualInput]="datePickerOptions.isManualInput ?? false"
        [readonly]="isDisabled"
        formControlName="selectedDateCtrl"
        [stickerPosition]="datePickerOptions.stickerPosition ?? ''"
      >
      </dds-datepicker>
    </div>
  </form>
  <ng-container *ngIf="!permissionObj['isExtCommRequestedReviewDateEnable']">
    {{
      dateForm.get("selectedDateCtrl")?.value | date : dateFormaterUS
    }}</ng-container
  >
  <span class="datePickerMsg">in {{ businessDays }} business day(s)</span>
</div>
