export class GlobalEngagementUrlConstants {
  static readonly #COMMON_GLOBAL_ENGAGEMENT_PATH =
    'GlobalEngagements/GlobalEngagements/';
  public static readonly GET_ENGAGEMENT_DETAILS =
    'GlobalEngagements/Engagement/GetExistingEngagementDetails';
  public static readonly GET_SUBMISSION_TYPE =
    GlobalEngagementUrlConstants.#COMMON_GLOBAL_ENGAGEMENT_PATH +
    'GetSubmissionType';
  public static readonly GET_DELIVERY_CENTER =
    GlobalEngagementUrlConstants.#COMMON_GLOBAL_ENGAGEMENT_PATH +
    'GetDeliveryCenter';
  public static readonly GET_Member_Firm =
    GlobalEngagementUrlConstants.#COMMON_GLOBAL_ENGAGEMENT_PATH +
    'GetMemberFirmDetails';
  public static readonly GET_GLOBAL_ENGAGEMENT_DETAILS =
    'GlobalEngagements/Engagement/GetEngagementDetails?engagementId=';
  public static readonly POST_GLOBAL_ENGAGEMENT_SUBMISSION_DETAILS =
    'GlobalEngagements/Engagement/UpdateEngagement';
  public static readonly POST_GLOBAL_ENGAGEMENT_SUBMISSION =
    'GlobalEngagements/Submission/InitiateSubmission';
  public static readonly GET_GLOBAL_ENGAGEMENT_LEFTNAV =
    'GlobalEngagements/Submission/GetLeftMenuSubmissionDetails?engagementId=';
  public static readonly GET_GLOBAL_ENGAGEMENT_SUBMISSION_DETAILS =
    'GlobalEngagements/Submission/GetSubmissionDetails?submissionId=';
  public static readonly PUT_GLOBAL_ENGAGEMENT_SUBMISSION_DETAILS =
    'GlobalEngagements/Submission/UpdateSubmission';
  public static readonly GET_ENGAGEMENT_DOCUMENT_STATUS =
    'GlobalEngagements/SubmissionDocument/GetDocumentStatus';
  public static readonly GET_ENGAGEMENT_DOCUMENT_TYPE =
    'GlobalEngagements/SubmissionDocument/GetDocumentType?submissionTypeId=';
  public static readonly SAVE_ENGAGEMENT_DOCUMENT =
    'GlobalEngagements/SubmissionDocument/SaveSubmissionDocument';
  public static readonly EDIT_ENGAGEMENT_DOCUMENT =
    'GlobalEngagements/SubmissionDocument/EditSubmissionDocument';
  public static readonly GET_ENGAGEMENT_DOCUMENT =
    'GlobalEngagements/SubmissionDocument/GetSubmissionDocumentDetails?submissionId=';
  public static readonly DELETE_ENGAGEMENT_DOCUMENT =
    'GlobalEngagements/SubmissionDocument/DeleteSubmissionDocument?submissionDocumentId=';
  public static readonly GET_EFA_ROLES =
    'Admin/GlobalEngagement/GetGlobalEngRoleConfig?roleId=702';

  public static readonly GET_ACTIONS_REASONS_DROPDOWN =
    'GlobalEngagements/GlobalEngagements/GetActionReason?submissionactionid=';

  public static readonly SAVE_ACTION_HISTORY =
    'GlobalEngagements/Submission/SaveSubmissionActionHistory';

  public static readonly GET_ACTION_HISTORY =
    'GlobalEngagements/Submission/GetSubmissionActionHistory?submissionid=';

  public static readonly GET_AUDIT_TRAIL_DATA =
    'GlobalEngagements/GlobalEngagements/GetAuditTrail?engagementId=';
}
