export enum StatusEnum {
  Pending = 'Pending',
  RMReviewed = 'RM Reviewed',
  Draft = 'Draft',
  Submitted = 'Submitted',
  RMReviewedPendingCloseout = 'RM reviewed - Pending closeout',
  RETURN_FOR_REWORK = 'Returned for rework',
  SUBMITTED = 'Submitted',
  RESUBMITTED = 'Re-submitted',
  SELF_REVIEWED_PENDING_CLOSEOUT = 'Self reviewed - Pending closeout',
  COMPLETED = 'Completed',
  DISCONTINUED = 'Discontinued',
  COMPLETED_ADMIN = 'Completed (Admin)',
}
