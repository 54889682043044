import { ExternalCommunicationsComponent } from "./external-communications/external-communications.component";
import { InitiateExternalCommunicationComponent } from "./initiate-external-communication/initiate-external-communication.component";

export const EXTERNAL_COMMUNICATIONS_ROUTES = [
    {
        path : 'externalCommunications',
        children : [
            {
                path : 'create',
                component : InitiateExternalCommunicationComponent,
            },
            {
                path : 'details/:id',
                component : ExternalCommunicationsComponent,
            }
        ],
    },
]