import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Column, Size, Themes } from '@usitsdasdesign/dds-ng/shared';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { SubmissionService } from '../../../http/intake/submission.service';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import {
  lepDataValue,
  riskManagerTableHeaderData,
} from '../opportunity-details-settings';
import { IntakeConstant } from '../../constants/intake-constant';
import {
  ADD_REVIEWER_BTN_OPTIONS,
  ARCHIVE_AND_COMPLETE_BTN_OPTIONS,
  CO_CHANGE_SCOPE_RADIO_GROUP_VALUE,
  CO_SEARCH_OPTION,
  PrimaryButton,
  RADIO_GROUP_OPTIONS_FOR_CO_CHANGE_SCOPE,
  RADIO_GROUP_OPTIONS_FOR_OTHER_SUBTYPE,
  RADIO_GROUP_OPTIONS_FOR_RFP,
  REP_RADIO_GROUP_VALUE,
  RESUBMIT_FOR_REVIEW_BTN_OPTIONS,
  SHOW_MORE_BTN_OPTIONS,
  SUBMIT_BTN_OPTIONS,
  toastMessage,
  submittedMessage,
  returnForReworkMessage,
  resubmittedToastMessage,
} from './submission-common-section.helper';
import { IntakeDocumentSubmissionStatus } from '../../constants/intake_document_submission_status.enum';
import { IntakeDocumentSubmissionTypeCode } from '../../constants/intake_submission_type_code.enum';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import { latestOpportunityDetails } from '../../../common/models/latestOpportunityDetails';
import { LepValue } from '../../../common/models/opportunity-details.model';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import {
  CoElSowEventEmitterType,
  EmitUpdatedTitleModal,
  IntakeSubmissionDetailsCommonSectionDataReceivedModal,
  IntakeSubmissionDetailsCommonSectionDataSendingModal,
  SubmissionApprovers,
} from '../../../common/models/intake-submission-details-common-section.model';
import { ActivatedRoute } from '@angular/router';
import {
  configurationType,
  SubmissionSubType,
} from '../../../common/models/common-models';
import { ReviewersRoleEnum } from '../../constants/Role-enum';
import { copyToClipboard, randomString } from '../../common/intake.util';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';

import { OpportunityListInformationComponent } from '../../../client/client-status/opportunity-list-information/opportunity-list-information.component';
import { DocumentSelectionPopupComponent } from './document-selection-popup/document-selection-popup.component';
import { DocumentComponent } from './document/document.component';
import { WinningProposalOptionValues } from '../../constants/intake_document_close_out_winning_proposal_option_values.enum';

import { ConfirmPopModalComponent } from './confirm-pop-modal/confirm-pop-modal.component';
import { OtherOptionValues } from '../../constants/intake_document_close_out_other_option_values.enum';
import { IntakeDocumentTableModel } from '../../../common/models/intake-document-table.model';
import { StatusEnum } from '../../enum/status-enum';
// import { ClientApprovers } from '../../../common/models/client-continuance.model';
import { ConfirmPopModel } from '../../../common/models/show-all-popup-model';
import { DeliverableOptionValues } from '../../constants/intake_document_close_out_deliverable_option_values.enum';
import { CoElSowOptionValues } from '../../constants/intake_document_close_out_co_elsow_option_values.enum';
import {
  ReviewerRole,
  ReviewerRoleType,
} from '../../../common/models/reviwer-role.model';
import { CommonService } from '../../../http/intake/common.service';

@Component({
  selector: 'app-submission-common-section',
  templateUrl: './submission-common-section.component.html',
  styleUrl: './submission-common-section.component.less',
})
export class SubmissionCommonSectionComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() dataOpportunityId: string = '';
  @Input() dataSubmissionId: number | null = null;
  @Input() opportunityDocsCount: number = 0;
  isAllReviewerValid: boolean = true;
  modalSetting: any = null;
  progressiveStatus: boolean | null = null;
  isEligibleTechnicalReviewer: boolean = false;
  isDocumentRequireMsgShow: boolean = false;
  isFinalDocumentAcceptedMsgShow: boolean = true;

  @Input() set dataSubmissionTypeCode(submissionTypeCode: string) {
    this.setSubMissionCodeDetails(submissionTypeCode);
  }
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateTitle: EventEmitter<EmitUpdatedTitleModal> =
    new EventEmitter<EmitUpdatedTitleModal>();
  @Output() updateLeftMenu: EventEmitter<string> = new EventEmitter<string>();

  submissionTypeCode!: string;
  @Input() dataLepValue: LepValue = lepDataValue;
  submissionData!: IntakeSubmissionDetailsCommonSectionDataReceivedModal;
  titleValue: string = '';
  isEditingTitle: boolean = false;
  copySuccess: string | null = null;
  requiredCheckForCommonSection: boolean = false;
  submissionId: number = 0;
  submissionStatus: string | undefined = '';
  selfReviewEligibility: string | undefined = '';
  updatedRiskManagerDetails!: SubmissionApprovers[];
  isEmployeePresent: boolean = false;
  showPeoplePicker: boolean = false;
  selectedRiskManagers: string[] = [];
  unsubscriber$: Subject<any> = new Subject<any>();
  isOpportunitySelected: boolean = true;
  isVisible: boolean = true;
  textareaOptions: any = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  enter_text_placeholder = IntakeConstant.ENTER_TEXT_PLACEHOLDER;
  intakeDocumentSubmissionTypeCodePro = IntakeDocumentSubmissionTypeCode.PRO;
  intakeDocumentSubmissionTypeCodeDelivery =
    IntakeDocumentSubmissionTypeCode.DEL;
  intakeDocumentSubmissionTypeCodeOther = IntakeDocumentSubmissionTypeCode.OTH;
  intakeDocumentSubmissionTypeCodeCO = IntakeDocumentSubmissionTypeCode.CO;
  intakeDocumentSubmissionTypeCodeELSOW =
    IntakeDocumentSubmissionTypeCode.EL_SOW;
  IntakeDocumentSubmissionStatusSubmitted = IntakeConstant.SUBMITTED_STATUS;
  RFP_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_RFP;
  REP_radio_values: any[] = REP_RADIO_GROUP_VALUE;
  other_subtype_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_OTHER_SUBTYPE;
  RADIO_THEME_COLOR: Themes = IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR;
  COChangeScope_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_CO_CHANGE_SCOPE;
  COChangeScope_radio_values: any[] = CO_CHANGE_SCOPE_RADIO_GROUP_VALUE;
  showMoreOptions: any = SHOW_MORE_BTN_OPTIONS;
  label: string = 'Update';
  roleAdditionalReviewer: string = ReviewersRoleEnum.REVIEWER;
  roleTechnicalOrIndependentReviewer: string =
    ReviewersRoleEnum.TECHNICAL_OR_INDEPENDENT_REVIEWER;
  private timeoutId: ReturnType<typeof setTimeout> | null = null;
  updateLabel: string = '';
  riskManagerTableHeader: Column[] = riskManagerTableHeaderData;
  isValidDocumentUploaded: boolean = false;
  changeOrderStatus: boolean = false;
  loggedInEmployeeId: string = '';
  submissionStatusClass: string = 'submission-status';
  configLableDocumet: string = '';
  configData: configurationType[] = [];
  submissionStatusCommonSection: string = '';
  showInformationalGuidanceForDeliverable: boolean = false;

  @ViewChild(DocumentComponent) documentComponent!: DocumentComponent;

  submitLabel: string = IntakeConstant.SUBMIT_BTN_LABEL;
  archiveAndCompleteBtnLabel: string =
    IntakeConstant.ARCHIVE_AND_COMPLETE_BTN_LABEL;
  reSubmitForReviewLabel: string = IntakeConstant.RESUBMIT_FOR_REVIEW_BTN_LABEL;
  submitButtonOptions: ButtonOptions = SUBMIT_BTN_OPTIONS;
  options: ButtonOptions = ADD_REVIEWER_BTN_OPTIONS;
  archiveAndCompleteBtnOption = ARCHIVE_AND_COMPLETE_BTN_OPTIONS;
  resubmitForReviewBtnOption = RESUBMIT_FOR_REVIEW_BTN_OPTIONS;
  toastOptions: ToastOptions = toastMessage;
  submittedToastOption: ToastOptions = submittedMessage;
  returnForReworkToastOption: ToastOptions = returnForReworkMessage;
  resubmittedToastOption: ToastOptions = resubmittedToastMessage;

  reworkButton: ButtonOptions = PrimaryButton;
  internalQrmCommunicationTextareaMaxlength: number =
    IntakeConstant.TEXTAREA_CHAR_LIMIT_BIGGEST;
  public model: IntakeSubmissionDetailsCommonSectionDataSendingModal =
    {} as IntakeSubmissionDetailsCommonSectionDataSendingModal;
  public commonSectionFormModule!: Saver;
  isShowMoreDocumentNotes: boolean = false;
  showMoreText: string = IntakeConstant.SHOW_MORE_TEXT;
  coHasParentOpportunityNumber: boolean = false;
  coSearchOption = CO_SEARCH_OPTION;
  selectedOportunityForCO = '';
  isCheckboxCheckedForNoEL_SOW: boolean = true;
  readonly #route = inject(ActivatedRoute);
  opportunityNumber!: string;
  otherSubTypes$!: Observable<SubmissionSubType[]>;
  submissionTypeId: number = 0;
  submissionStatusId: number = 0;
  lepAcknowledgedBy: null | string = null;
  lepAcknowledgementDate: null | string = null;
  lepReviewedBy: null | string = null;
  showLepConfirmationMessage: boolean = false;
  employeeIdNumber: number = 0;
  showActionsHeader: boolean = false;
  isArchiveAndCompleteBtnDisabled: boolean = true;
  isResubmitForReviewBtnDisabled: boolean = true;
  otherSubmissionValueSelected: string = '';
  documentTableData: IntakeDocumentTableModel[] = [];
  showUploadFinalVersionMessage: boolean = false;
  showUploadGuidanceYellowMsgForCoAndElSowOnYes: boolean = false;
  showUploadGuidanceInfoMsgForCoAndElSowOnYes: boolean = false;
  showInfoMsgForCoElSowOnSelectNoResubmit: boolean = false;
  showUpdatedDocumentMessage: boolean = false;
  additionalReviewer = ReviewersRoleEnum.REVIEWER;
  reviewerText = ReviewersRoleEnum.REVIEWER;
  roles!: ReviewerRole;
  constructor(
    private readonly submissionService: SubmissionService,
    private readonly autosaverService: AutosaverService,
    private readonly modalService: ModalService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly toastService: ToastService,
    private readonly commonService: CommonService
  ) {
    this.model.submissionTitle = this.titleValue;
    this.#route.paramMap.subscribe((params) => {
      this.opportunityNumber = params.get('id')!;
    });
  }
  ngOnInit(): void {
    this.loggedInEmployeeId = this.submissionService.employeeId;
    //Resetting the value on close of modal to null.
    this.submissionService.otherValue$.subscribe((value) => {
      this.otherSubmissionValueSelected = value;
    });
    this.loggedInEmployeeId = this.submissionService.employeeId;
    this.loggedInEmployeeId = this.submissionService.employeeId;
    this.submissionService.updateOtherSubmissionValue('');

    let autoSaverOptions = {
      onSuccess: (saver: any, result: any) => {
        return this.postAutoSaveHandler();
      },
    } as IAutosaverOptions;
    this.commonSectionFormModule = this.autosaverService.newSaver(
      'model',
      this,
      async (model) => this.#saveCommonSectionDetails(),
      autoSaverOptions
    );
    this.commonSectionFormModule.start();

    this.submissionService.new$.subscribe((status) => {
      this.isValidDocumentUploaded = status;
    });
  }

  getChangeOrderStatus(): void {
    this.submissionService
      .getChangeOrderStatus(this.opportunityNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          this.changeOrderStatus = response;
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  setSubMissionCodeDetails(submissionTypeCode: string): void {
    this.submissionTypeCode = submissionTypeCode;
    this.otherSubTypes$ = this.submissionService.getSubmissionSubTypes(5);
  }

  postAutoSaveHandler() {
    //TODO: fetch the save details
  }

  #saveCommonSectionDetails() {
    let commonSectionData = { ...this.model };
    commonSectionData.submissionId = this.submissionId;
    commonSectionData.parentOpportunityNumber = '';
    // TODO: add value from search field for CO, or add from existing value

    if (commonSectionData.coChangeScope != null) {
      commonSectionData.coChangeScope =
        IntakeConstant.YES_NO_CHECKBOX_VALUE_TO_BOOLEAN.get(
          commonSectionData.coChangeScope
        );
    }

    if (commonSectionData.relatedToRFP != null) {
      commonSectionData.relatedToRFP =
        IntakeConstant.YES_NO_CHECKBOX_VALUE_TO_BOOLEAN.get(
          commonSectionData.relatedToRFP
        );
    }

    if (
      this.model.submissionSubTypeId &&
      this.model.submissionSubTypeId != ''
    ) {
      commonSectionData.submissionSubTypeId = Number(
        this.model.submissionSubTypeId
      );
    }

    return this.submissionService
      .saveIntakeDetailsCommonData(commonSectionData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {},
        error: (err) => {
          this.progressIndicator.hide();
          console.error('Error saving data', err);
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['dataOpportunityId'] ||
      changes['dataSubmissionId'] ||
      changes['submissionTypeCode']
    ) {
      this.submissionService.updateOtherSubmissionValue('');
      this.submissionService.otherValue$.subscribe((value) => {
        this.otherSubmissionValueSelected = value;
      });
      this.isArchiveAndCompleteBtnDisabled = true;
      this.isResubmitForReviewBtnDisabled = true;
      this.loadSubmission();
      this.checkValidation();
      this.getLabelConfiguration();
      if (this.submissionTypeCode === IntakeConstant.CHANGE_ORDER_LABEL_CODE) {
        this.getChangeOrderStatus();
      }
    }
  }

  checkValidation(): void {
    switch (this.submissionTypeCode) {
      case this.intakeDocumentSubmissionTypeCodePro:
        {
          this.valueChange.emit(
            !!this.model.proposalDescription &&
              !!this.model.delDescription &&
              !!this.model.relatedToRFP
          );
        }
        break;
      case this.intakeDocumentSubmissionTypeCodeOther:
        {
          this.valueChange.emit(this.model.otherDescription ? true : false);
        }
        break;
      case this.intakeDocumentSubmissionTypeCodeCO:
        {
          this.valueChange.emit(
            this.model.coChangeScope && this.model.coDescription ? true : false
          );
        }
        break;
      case this.intakeDocumentSubmissionTypeCodeELSOW:
        {
          this.valueChange.emit(true);
        }
        break;
      default: {
        this.valueChange.emit(true);
      }
    }
  }

  //selecting row of the riskmanager table
  toggleRow(rowEmail: string): void {
    if (rowEmail) {
      let reviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (approver) => approver.emailId === rowEmail
      );
      this.updatedRiskManagerDetails[reviewerIndex].isRequired =
        !this.updatedRiskManagerDetails[reviewerIndex].isRequired;
      if (this.updatedRiskManagerDetails[reviewerIndex].isRequired) {
        if (
          !this.selectedRiskManagers.includes(
            this.updatedRiskManagerDetails[reviewerIndex].emailId
          )
        ) {
          this.selectedRiskManagers.push(
            this.updatedRiskManagerDetails[reviewerIndex].emailId
          );
        }
      } else {
        this.selectedRiskManagers = this.selectedRiskManagers.filter(
          (element) =>
            element != this.updatedRiskManagerDetails[reviewerIndex].emailId
        );
      }
      this.#updateReviewers();
    }
  }

  #updateReviewers() {
    let updatedArr = structuredClone(this.updatedRiskManagerDetails);
    updatedArr = updatedArr.filter(
      (reviewer) => reviewer.fullName != '' && reviewer.displayName != ''
    );
    updatedArr.forEach((reviewer) => {
      delete reviewer.isEditing;
      delete reviewer.isEditable;
      delete reviewer.isForTechnicalReviewer;
    });
    this.submissionService
      .updateSubmissionReviewers(updatedArr, Number(this.submissionId))
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          // do operation post updating the reviewers details
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
    this.checkValidation();
  }

  addReviewer(roleName: string): void {
    this.isAllReviewerValid =
      roleName === ReviewersRoleEnum.TECHNICAL_OR_INDEPENDENT_REVIEWER;
    const newReviewer: SubmissionApprovers = {
      submissionApproverId: 0, // 0 indicates a new reviewer to be saved
      reviewerId: randomString(20), // Unique identifier
      displayName: '',
      fullName: '',
      emailId: '',
      location: '',
      reviewerRoleId: Number(
        this.roles.roles.find(
          (item: ReviewerRoleType) => item.role === ReviewersRoleEnum.REVIEWER
        )?.id
      ), // Assign and increment reviewerRoleId
      isRequired: false, // Assuming all additional reviewers are required; adjust as needed
      roleName: roleName,
      isEditing: true, // Start in editing mode to show People Picker,
      isEditable: true,
      isForTechnicalReviewer:
        roleName === ReviewersRoleEnum.TECHNICAL_OR_INDEPENDENT_REVIEWER,
      submissionApproverStatus: null,
      submissionApproverDocumentId: null,
      statusReviewedDate: null,
    };
    this.updatedRiskManagerDetails.push(newReviewer);
  }

  onMemberSelected(
    reviewerEmail: string,
    reviewerId: number | string,
    member: any
  ): void {
    let reviewerIndex = -1;

    if (
      reviewerId &&
      reviewerId != undefined &&
      reviewerId != null &&
      reviewerId != ''
    ) {
      reviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (r) => r.reviewerId === reviewerId
      );
    } else {
      reviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (r) => r.emailId === reviewerEmail
      );
    }
    if (reviewerIndex > -1) {
      this.isAllReviewerValid = true;
      this.updatedRiskManagerDetails[reviewerIndex].isEditing = false;
      this.updatedRiskManagerDetails[reviewerIndex].displayName =
        member.displayName;
      this.updatedRiskManagerDetails[reviewerIndex].fullName = member.fullname;
      this.updatedRiskManagerDetails[reviewerIndex].emailId = member.email;
      this.updatedRiskManagerDetails[reviewerIndex].location = member.location;
      this.updatedRiskManagerDetails[reviewerIndex].reviewerId = '';
      this.#updateReviewers();
      this.checkValidation();
    }
  }

  editReviewer(reviewerEmailId: string): void {
    const editReviewerIndex = this.updatedRiskManagerDetails.findIndex(
      (reviewer) => reviewer.emailId === reviewerEmailId
    );
    if (editReviewerIndex > -1) {
      this.updatedRiskManagerDetails[editReviewerIndex].isEditing = true;
      this.updatedRiskManagerDetails[editReviewerIndex].displayName = '';
      this.updatedRiskManagerDetails[editReviewerIndex].fullName = '';
      this.updatedRiskManagerDetails[editReviewerIndex].emailId = '';
      this.updatedRiskManagerDetails[editReviewerIndex].location = '';
      this.updatedRiskManagerDetails[editReviewerIndex].reviewerId =
        randomString(20);
    }
  }

  //save on clicking outside of the input box
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (this.isEditingTitle && !target.closest('#title-editor')) {
      this.saveTitle();
    }
  }

  //initiate title editing
  editTitle(): void {
    this.isEditingTitle = true;
    this.model.submissionTitle = this.titleValue;
  }

  //save the title and update header
  saveTitle(): void {
    this.titleValue = this.model.submissionTitle;
    this.isEditingTitle = false;
    this.updateTitle.emit({
      submissionId: this.dataSubmissionId,
      updatedTitle: this.titleValue,
    });
  }

  //copy the ID to clipboard
  copyToClipboard(value: string | null): void {
    if (value) {
      copyToClipboard(value);
    }
  }

  loadSubmission(): void {
    this.progressIndicator.show();
    this.commonService
      .getReviewerRolesDetails()
      .subscribe((response: ReviewerRole) => {
        this.roles = response;
      });
    const submissionId: string =
      this.dataSubmissionId !== null ? this.dataSubmissionId.toString() : '';
    this.employeeIdNumber = Number(this.submissionService.employeeId);

    if (submissionId !== '') {
      this.isOpportunitySelected = false;
      this.updatedRiskManagerDetails = [];
      this.selectedRiskManagers = [];
      this.coHasParentOpportunityNumber = false;
      forkJoin([
        this.submissionService.getSubmissionBySubmissionId(
          this.dataOpportunityId,
          submissionId
        ),
        this.submissionService.getSubmissionReviewers(submissionId),
      ])
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            const submissionDetails = response[0];
            const reviewerDetailsFromReviewerApi = response[1];

            if (Object.keys(submissionDetails).length !== 0) {
              this.submissionData = { ...submissionDetails };
              this.submissionTypeId = Number(
                this.submissionData.submissionTypeId
              );
              this.submissionStatusId = Number(
                this.submissionData.submissionStatusId
              );
              this.titleValue = this.submissionData.submissionTitle;
              this.submissionId = this.submissionData.submissionId;
              this.lepAcknowledgedBy = this.submissionData.lepAcknowledgedBy;
              this.lepAcknowledgementDate =
                this.submissionData.lepAcknowledgementDate;
              this.lepReviewedBy = this.submissionData.lepReviewedBy;
              if (
                this.lepAcknowledgedBy === null &&
                this.lepAcknowledgementDate === null &&
                this.lepReviewedBy === null
              ) {
                this.showLepConfirmationMessage = true;
              } else {
                this.showLepConfirmationMessage = false;
              }

              if (
                this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.OTH
              ) {
                this.model.submissionSubTypeId =
                  this.submissionData.submissionSubTypeId?.toString() ?? '';
              }

              if (
                this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO
              ) {
                this.checkCOhasParentOpportunityNoOrNot();
              }

              this.submissionStatus = this.submissionData.submissionStatusName;
              if (this.submissionStatus === 'Submitted') {
                this.submissionStatusClass = 'submitted-status';
              } else if (
                this.submissionStatus === 'RM reviewed - Pending closeout'
              ) {
                this.submissionStatusClass = 'submitted-status';
              } else if (
                this.submissionStatus ===
                IntakeDocumentSubmissionStatus.RETURN_FOR_REWORK
              ) {
                this.submissionStatusClass = 'resubmitted-status';
              } else {
                this.submissionStatusClass = 'submission-status';
              }
              this.selfReviewEligibility =
                this.submissionData.selfReviewEligibilityStatus;
              this.model.coChangeScope =
                IntakeConstant.BOOLEAN_CHECKBOX_VALUE_TO_YES_NO.get(
                  this.submissionData.coChangeScope
                );
              this.model.coDescription = this.submissionData.coDescription;
              this.model.submissionTitle = this.submissionData.submissionTitle;
              this.model.otherDescription =
                this.submissionData.otherDescription;
              this.model.proposalDescription =
                this.submissionData.proposalDescription;
              this.model.relatedToRFP =
                IntakeConstant.BOOLEAN_CHECKBOX_VALUE_TO_YES_NO.get(
                  this.submissionData.relatedToRFP
                );
              this.model.delDescription = this.submissionData.delDescription;
              this.model.notesAndFeedback =
                this.submissionData.notesAndFeedback;
              this.model.internalQRMTeamCommunication =
                this.submissionData.internalQRMTeamCommunication;
              this.isEligibleTechnicalReviewer =
                this.submissionData.isEligibleTechnicalReviewer;
              this.setWorkflowReviewersDetails(
                this.submissionData.submissionApprovers,
                reviewerDetailsFromReviewerApi
              );
              // --------------- close out change order section start ----------------
              this.setCoElSowCloseOutDetails(this.submissionData);
              // --------------- close out change order section end ----------------

              // TODO: Once Employee ID is implemented
              // this.checkActionsColumn();

              this.isEmployeePresent = this.updatedRiskManagerDetails.some(
                (employee: SubmissionApprovers) =>
                  Number(employee.reviewerId) === this.employeeIdNumber
              );
            }
            this.progressIndicator.hide();
          },
          error: (err) => {
            this.progressIndicator.hide();
            console.error('Error fetching submission', err);
          },
        });
    } else {
      this.isOpportunitySelected = true;
    }
  }

  isProposalAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isDeliverableAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isOtherAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isCOAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isAccordianOpenCommon(): boolean {
    return (
      this.submissionStatus != '' &&
      this.submissionStatus !==
        IntakeDocumentSubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT &&
      this.submissionStatus !==
        IntakeDocumentSubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
    );
  }

  isAccordionVisible(
    submissionCodeType: IntakeDocumentSubmissionTypeCode
  ): boolean {
    return (
      this.submissionTypeCode != '' &&
      this.submissionTypeCode === submissionCodeType
    );
  }

  isCloseOutAccordionVisible(): boolean {
    return (
      this.submissionStatus != '' &&
      (this.submissionStatus ===
        IntakeDocumentSubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT ||
        this.submissionStatus ===
          IntakeDocumentSubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT)
    );
  }

  proposalDescriptionValueChanged(): void {
    this.checkValidation();
  }

  RelatedtoRFPstateChanged(): void {
    this.checkValidation();
  }

  deliverableDescValueChanged(): void {
    this.checkValidation();
  }

  internalQrmCommunicationInputValueChanged(): void {}
  feedbackInputValueChanged(): void {}
  OtherDescriptionValueChanged(): void {
    this.checkValidation();
  }

  COChangeScopeStateChanged(): void {
    if (this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeCO)
      this.checkValidation();
  }

  CODescriptionValueChanged(): void {
    this.checkValidation();
  }

  clickToShowDocumentMoreLepMessage(): void {
    this.isShowMoreDocumentNotes = !this.isShowMoreDocumentNotes;
    if (!this.isShowMoreDocumentNotes) {
      this.showMoreText = IntakeConstant.SHOW_MORE_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_DOWN;
    } else {
      this.showMoreText = IntakeConstant.SHOW_LESS_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_UP;
    }
  }

  checkCOhasParentOpportunityNoOrNot(): void {
    this.submissionService
      .getOpportunitySelected(this.dataOpportunityId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: latestOpportunityDetails) => {
          this.coHasParentOpportunityNumber =
            (data?.parentOpportunityNumber == null ||
              data?.parentOpportunityNumber == '') &&
            this.opportunityDocsCount === 1;
          this.isCheckboxCheckedForNoEL_SOW = this.coHasParentOpportunityNumber;
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  handleCompleteReview(data: SubmissionApprovers): void {
    this.openModal(data);
  }

  handleReturnforRework(data: SubmissionApprovers): void {
    let submissionStatusId = 2;
    this.progressIndicator.show();
    this.submissionService
      .submitCommonSectionData(this.submissionId, submissionStatusId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: string) => {
          this.submissionStatusCommonSection = response;
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
    this.timeoutId = setTimeout(() => {
      this.loadSubmission();
      this.documentComponent.triggerDocumentTableDataApi();
      this.updateLeftMenu.emit(this.opportunityNumber);
      this.progressIndicator.hide();
      this.setToast(this.returnForReworkToastOption);
    }, IntakeConstant.SET_TIMEOUT_DURATION);
  }

  resubmitCommonSectionData(): void {
    this.progressIndicator.show();
    this.submissionService
      .submitCommonSectionData(this.submissionId, 4)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: string) => {
          this.submissionStatusCommonSection = response;
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
    this.timeoutId = setTimeout(() => {
      this.loadSubmission();
      this.documentComponent.triggerDocumentTableDataApi();
      this.updateLeftMenu.emit(this.opportunityNumber);
      this.progressIndicator.hide();
      this.setToast(this.resubmittedToastOption);
    }, IntakeConstant.SET_TIMEOUT_DURATION);
  }

  openModal(rowDetails: SubmissionApprovers): void {
    let modalRef = this.modalService.open(DocumentSelectionPopupComponent, {
      submissionID: this.submissionId,
      rowDetails: rowDetails,
    });
    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          if (this.submissionId) {
            this.timeoutId = setTimeout(() => {
              this.loadSubmission();
              this.documentComponent.triggerDocumentTableDataApi();
              this.updateLeftMenu.emit(this.opportunityNumber);
              this.showToast();
            }, IntakeConstant.SET_TIMEOUT_DURATION);
          }
        },
        error: (err) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  setWorkflowReviewersDetails(
    reviewerDetailsFromSubmissionDetailsApi: SubmissionApprovers[],
    reviewerDetailsFromGetReviewerApi: SubmissionApprovers[]
  ): void {
    if (reviewerDetailsFromGetReviewerApi.length === 0) {
      reviewerDetailsFromSubmissionDetailsApi?.forEach((element) => {
        if (element.isRequired) {
          this.selectedRiskManagers.push(element.emailId);
        }
        this.updatedRiskManagerDetails.push({
          ...element,
          isRequired: element.isRequired ?? false,
          reviewerId: element.reviewerId,
          fullName: element.fullName,
          location: element.location,
          roleName: element.roleName,
          isEditing: false,
          isEditable: false,
        });
      });
      if (this.isEligibleTechnicalReviewer) {
        this.addReviewer(this.roleTechnicalOrIndependentReviewer);
      }
    } else {
      reviewerDetailsFromGetReviewerApi?.forEach((element) => {
        const findUserDetailsFromSumissionApi: SubmissionApprovers[] =
          this.findUserByEmailFromResponse(element.emailId);
        this.updatedRiskManagerDetails.push({
          ...element,
          isRequired:
            element.isRequired != null || element.isRequired != undefined
              ? element.isRequired
              : false,
          fullName: element.fullName,
          location: element.location,
          roleName: element.roleName,
          submissionApproverId: element.submissionApproverId,
          reviewerId: this.getReviewerId(
            element.reviewerId,
            findUserDetailsFromSumissionApi[0]?.reviewerId
          ),
          isEditing: false,
          isEditable:
            reviewerDetailsFromSubmissionDetailsApi.findIndex(
              (responseFromGetSubmissionDetails) =>
                responseFromGetSubmissionDetails.emailId === element.emailId
            ) === -1,
        });
      });
      let filteredArr = this.updatedRiskManagerDetails.filter(
        (reviewer) => reviewer.isRequired
        //(reviewer) => reviewer.isRequired = true /TODO: remove this line once everthing works fine
      );
      filteredArr.forEach((element) => {
        this.selectedRiskManagers.push(element.emailId);
      });
      if (this.isEligibleTechnicalReviewer) {
        this.addReviewer(this.roleTechnicalOrIndependentReviewer);
      }
    }
  }

  getReviewerId(
    reviewerIdFromGetReviewerApi: string | number,
    reviewerIdFromGetSubmissionApi: string | number
  ): string | number {
    if (
      reviewerIdFromGetReviewerApi &&
      reviewerIdFromGetReviewerApi != null &&
      reviewerIdFromGetReviewerApi != ''
    ) {
      return reviewerIdFromGetReviewerApi;
    } else if (
      reviewerIdFromGetSubmissionApi &&
      reviewerIdFromGetSubmissionApi != null &&
      reviewerIdFromGetSubmissionApi != ''
    ) {
      return reviewerIdFromGetSubmissionApi;
    } else {
      return '';
    }
  }

  findUserByEmailFromResponse(reviewerMaiId: string): SubmissionApprovers[] {
    const submissionReviewerFromSubmissionDetailsApi = [
      ...this.submissionData.submissionApprovers,
    ];
    let submissionReviewer = submissionReviewerFromSubmissionDetailsApi.filter(
      (user: SubmissionApprovers) => user.emailId === reviewerMaiId
    );
    return submissionReviewer.length > 0 ? submissionReviewer : [];
  }

  handleLEPCompletion(): void {
    this.submissionService
      .putLepStatus(this.dataSubmissionId, this.dataLepValue.lepReviewedBy)
      .toPromise()
      .then(() => {});
    this.timeoutId = setTimeout(() => {
      this.loadSubmission();
    }, IntakeConstant.SET_TIMEOUT_DURATION);
  }

  getLabelConfiguration(): void {
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response) {
            this.configData = response;
            if (
              this.submissionTypeCode === IntakeConstant.CHANGE_ORDER_LABEL_CODE
            ) {
              this.configLableDocumet =
                this.configData.find(
                  (config: configurationType) =>
                    config.appConfigurationLabelKey === 'UPLOAD_CO_DOCUMENTS'
                )?.appConfigurationLabelValue || '';
            } else {
              this.configLableDocumet =
                this.configData.find(
                  (config: configurationType) =>
                    config.appConfigurationLabelKey === 'UPLOAD_DOCUMENTS'
                )?.appConfigurationLabelValue || '';
            }
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  updateSubmitButtonStatus(): boolean {
    if (
      (this.changeOrderStatus &&
        this.model.coChangeScope &&
        this.model.coDescription) ||
      (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
        this.model.coChangeScope &&
        this.model.coDescription &&
        this.isValidDocumentUploaded) ||
      (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.DEL &&
        this.model.delDescription &&
        this.isValidDocumentUploaded) ||
      (this.isValidDocumentUploaded &&
        this.model.otherDescription &&
        this.model.submissionSubTypeId &&
        IntakeDocumentSubmissionTypeCode.OTH) ||
      (this.isValidDocumentUploaded &&
        this.model.relatedToRFP &&
        this.model.proposalDescription) ||
      (this.isValidDocumentUploaded &&
        this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.EL_SOW &&
        !this.showLepConfirmationMessage)
    ) {
      return false;
    }
    return true;
  }

  submitCommonSectionData(): void {
    const submittedubmissionStatusID = IntakeConstant.SubmittedStatusID
    this.submissionService
      .submitCommonSectionData(this.submissionId, submittedubmissionStatusID)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: string) => {
          this.submissionStatusCommonSection = response;
          if (response) {
            this.updateAfterSubmit();
          }
        },
        error: (error) => {
          this.updateAfterSubmit();
        },
      });
  }

  updateAfterSubmit(): void {
    this.commonToast();
    this.loadSubmission();
    this.#updateReviewers();
    this.updateLeftMenu.emit(this.opportunityNumber);
  }

  isSubmitBtnVisible(): boolean {
    return this.submissionStatus === IntakeDocumentSubmissionStatus.DRAFT;
  }

  isResubmitBtnVisible(): boolean {
    return (
      this.submissionStatus === IntakeDocumentSubmissionStatus.RETURN_FOR_REWORK
    );
  }

  isSubmitForReviewBtnVisible(): boolean {
    return (
      this.submissionStatus ===
        IntakeDocumentSubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT ||
      this.submissionStatus ===
        IntakeDocumentSubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT
    );
  }

  isArchiveAndCompleteBtnVisible(): boolean {
    return (
      this.submissionStatus ===
        IntakeDocumentSubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT ||
      this.submissionStatus ===
        IntakeDocumentSubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT
    );
  }

  onclickArchiveAndCompleteBtn(): void {
    // TODO : implement the logic for archive and complete btn click
    this.progressIndicator.show();
    this.submissionService
      .submitCommonSectionData(this.submissionId, 7)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: string) => {
          this.submissionStatusCommonSection = response;
        },
      });

    this.timeoutId = setTimeout(() => {
      this.loadSubmission();
      this.updateLeftMenu.emit(this.opportunityNumber);
      this.progressIndicator.hide();
    }, IntakeConstant.SET_TIMEOUT_DURATION);
  }

  onclickResubmitForReviewBtn(): void {
    // TODO : implement the logic for resubmit for review btn click
    this.progressIndicator.show();
    this.submissionService
      .submitCommonSectionData(this.submissionId, 4)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: string) => {
          this.submissionStatusCommonSection = response;
        },
      });
    this.timeoutId = setTimeout(() => {
      this.loadSubmission();
      this.updateLeftMenu.emit(this.opportunityNumber);
      this.progressIndicator.hide();
    }, IntakeConstant.SET_TIMEOUT_DURATION);
  }

  onChangeWinningProposalInCloseOut(winningProposalValue: string): void {
    this.model.winningProposal = winningProposalValue;
    this.modalSetting = null;
    this.showUpdatedDocumentMessage = false;
    this.isDocumentRequireMsgShow = false;

    if (winningProposalValue === WinningProposalOptionValues.YES) {
      this.progressiveStatus = true;
      this.isDocumentRequireMsgShow = true;
      const showArchiveButton = this.checkDocumentStatus(
        IntakeConstant.FINAL_ARCHIVE_READY_VERSION
      );
      if (showArchiveButton) {
        this.isArchiveAndCompleteBtnDisabled = false;
        this.isResubmitForReviewBtnDisabled = true;
        this.isFinalDocumentAcceptedMsgShow = false;
      } else {
        this.isFinalDocumentAcceptedMsgShow = true;
      }
    } else if (
      winningProposalValue ===
      WinningProposalOptionValues.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED
    ) {
      this.modalSetting = {
        value:
          WinningProposalOptionValues.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED,
      };
      this.#openModal();
    } else if (
      winningProposalValue ===
      WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW
    ) {
      this.progressiveStatus = false;
      this.isArchiveAndCompleteBtnDisabled = true;
      this.setDraftFlags();
    } else {
      this.model.winningProposal = null;
    }
  }

  onChangeOtherInCloseOut(otherValue: string): void {
    this.model.OtherUpdatedVersionForReview = otherValue;
    this.modalSetting = null;
    this.documentComponent.triggerDocumentTableDataApi();
    if (otherValue === OtherOptionValues.YES) {
      this.submissionService.updateOtherSubmissionValue(OtherOptionValues.YES);
      const showArchiveButton = this.checkDocumentStatus(
        IntakeConstant.FINAL_ARCHIVE_READY_VERSION
      );
      showArchiveButton
        ? (this.showUploadFinalVersionMessage = false)
        : (this.showUploadFinalVersionMessage = true);
      this.isArchiveAndCompleteBtnDisabled = !showArchiveButton;
      this.showUpdatedDocumentMessage = false;
      this.isResubmitForReviewBtnDisabled = true;
    } else if (otherValue === OtherOptionValues.NO_RESUBMIT_FOR_REVIEW) {
      this.submissionService.updateOtherSubmissionValue(
        OtherOptionValues.NO_RESUBMIT_FOR_REVIEW
      );
      const checkdraft = this.checkDocumentStatus(
        IntakeDocumentSubmissionStatus.DRAFT
      );
      checkdraft
        ? (this.showUpdatedDocumentMessage = false)
        : (this.showUpdatedDocumentMessage = true);
      this.isArchiveAndCompleteBtnDisabled = true;
      checkdraft
        ? (this.isResubmitForReviewBtnDisabled = false)
        : (this.isResubmitForReviewBtnDisabled = true);
      this.showUploadFinalVersionMessage = false;
    } else if (
      otherValue === OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED
    ) {
      this.submissionService.updateOtherSubmissionValue(
        OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED
      );
      this.modalSetting = {
        value: OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED,
      };
      this.isArchiveAndCompleteBtnDisabled = true;
      this.isResubmitForReviewBtnDisabled = true;
      this.showUploadFinalVersionMessage = false;
      this.showUpdatedDocumentMessage = false;
      this.#openModal();
    } else {
      this.model.OtherUpdatedVersionForReview = null;
      this.isArchiveAndCompleteBtnDisabled = true;
      this.isResubmitForReviewBtnDisabled = true;
      this.showUploadFinalVersionMessage = false;
      this.showUpdatedDocumentMessage = false;
    }
  }

  onChangeDeliverableInCloseOut(deliverableValue: string): void {
    this.model.UpdatedVersionForReview = deliverableValue;
    this.modalSetting = null;
    this.documentComponent.triggerDocumentTableDataApi();
    if (deliverableValue === DeliverableOptionValues.YES_RESUBMIT_FOR_REVIEW) {
      const showResubmitButton = this.checkDocumentStatus(
        IntakeDocumentSubmissionStatus.DRAFT
      );
      showResubmitButton
        ? (this.showUpdatedDocumentMessage = false)
        : (this.showUpdatedDocumentMessage = true);
      this.isResubmitForReviewBtnDisabled = !showResubmitButton;
      this.showInformationalGuidanceForDeliverable = false;
      this.isArchiveAndCompleteBtnDisabled = true;
    } else if (deliverableValue === DeliverableOptionValues.NO) {
      const showArchiveButton = this.checkDocumentStatus(
        IntakeConstant.FINAL_ARCHIVE_READY_VERSION
      );
      showArchiveButton
        ? (this.showInformationalGuidanceForDeliverable = false)
        : (this.showInformationalGuidanceForDeliverable = true);
      this.isArchiveAndCompleteBtnDisabled = !showArchiveButton;
      this.isResubmitForReviewBtnDisabled = true;
      this.showUpdatedDocumentMessage = false;
    } else {
      this.model.UpdatedVersionForReview = null;
      this.isArchiveAndCompleteBtnDisabled = true;
      this.isResubmitForReviewBtnDisabled = true;
      this.showUpdatedDocumentMessage = false;
      this.showInformationalGuidanceForDeliverable = false;
    }
  }

  async onChangeCoAndElSowCloseOut(
    coElSowWithWdsValue: CoElSowEventEmitterType
  ): Promise<void> {
    let selectedRadioValue = coElSowWithWdsValue?.contractSignedExecuted;
    this.model.contractSignedExecuted =
      coElSowWithWdsValue?.contractSignedExecuted;
    this.model.chargeCodeWBS = coElSowWithWdsValue?.chargeCodeWBS;
    this.model.numberOfOpenArchieve = coElSowWithWdsValue?.numberOfOpenArchieve;
    this.model.wbsDescription = coElSowWithWdsValue?.wbsDescription;

    await this.resetWhenCloseOutRadioValueChange();
    if (selectedRadioValue === CoElSowOptionValues.YES) {
      this.documentComponent.triggerDocumentTableDataApi();
      const showArchiveButton = await this.checkDocumentStatus(
        IntakeConstant.FINAL_ARCHIVE_READY_VERSION
      );

      this.showUploadGuidanceYellowMsgForCoAndElSowOnYes = !showArchiveButton;
      this.isArchiveAndCompleteBtnDisabled = !showArchiveButton;
      this.showUploadGuidanceInfoMsgForCoAndElSowOnYes = true;
      this.showInfoMsgForCoElSowOnSelectNoResubmit = false;
    } else if (
      selectedRadioValue === CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW
    ) {
      this.showUploadGuidanceInfoMsgForCoAndElSowOnYes = false;

      this.showUploadGuidanceYellowMsgForCoAndElSowOnYes = false;
      this.isArchiveAndCompleteBtnDisabled = true;
      const isAnyDocumentStausIsDraft = await this.checkDocumentStatus(
        IntakeDocumentSubmissionStatus.DRAFT
      );
      this.isResubmitForReviewBtnDisabled = !isAnyDocumentStausIsDraft;
      this.showInfoMsgForCoElSowOnSelectNoResubmit = !isAnyDocumentStausIsDraft;
    } else {
      this.isResubmitForReviewBtnDisabled = true;
      this.isArchiveAndCompleteBtnDisabled = true;
      this.showInfoMsgForCoElSowOnSelectNoResubmit = false;
      this.showUploadGuidanceInfoMsgForCoAndElSowOnYes = false;
      this.showUploadGuidanceYellowMsgForCoAndElSowOnYes = false;
    }
  }

  setCoElSowCloseOutDetails(
    submissionData: IntakeSubmissionDetailsCommonSectionDataReceivedModal
  ): void {
    this.model.contractSignedExecuted = submissionData.contractSignedExecuted;
    this.model.chargeCodeWBS = submissionData.chargeCodeWBS;
    this.model.numberOfOpenArchieve = submissionData.numberOfOpenArchieve;
    this.model.wbsDescription = submissionData.wbsDescription;
    const seletedCoElSow: CoElSowEventEmitterType = {
      contractSignedExecuted: submissionData.contractSignedExecuted,
      chargeCodeWBS: submissionData.chargeCodeWBS,
      numberOfOpenArchieve: submissionData.numberOfOpenArchieve,
      wbsDescription: submissionData.wbsDescription,
    };

    setTimeout(() => {
      this.onChangeCoAndElSowCloseOut(seletedCoElSow);
    }, IntakeConstant.TIMOUT_TIMER);
  }

  resetWhenCloseOutRadioValueChange(): void {
    this.modalSetting = null;
    this.documentComponent?.triggerDocumentTableDataApi();
  }

  handleDocumentTableData(data: IntakeDocumentTableModel[]): void {
    this.documentTableData = data;

    if (this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodePro) {
      if (this.model.winningProposal === WinningProposalOptionValues.YES) {
        if (
          this.checkDocumentStatus(IntakeConstant.FINAL_ARCHIVE_READY_VERSION)
        ) {
          this.isArchiveAndCompleteBtnDisabled = false;
          this.isFinalDocumentAcceptedMsgShow = false;
        } else {
          this.isArchiveAndCompleteBtnDisabled = true;
          this.isFinalDocumentAcceptedMsgShow = true;
        }
      } else if (
        this.model.winningProposal ===
        WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW
      ) {
        this.setDraftFlags();
      }
    } else if (
      this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeOther
    ) {
      if (this.model.OtherUpdatedVersionForReview === OtherOptionValues.YES) {
        if (
          this.checkDocumentStatus(IntakeConstant.FINAL_ARCHIVE_READY_VERSION)
        ) {
          this.showUploadFinalVersionMessage = false;
          this.isArchiveAndCompleteBtnDisabled = false;
        } else {
          this.showUploadFinalVersionMessage = true;
          this.isArchiveAndCompleteBtnDisabled = true;
        }
      } else if (
        this.model.OtherUpdatedVersionForReview ===
        OtherOptionValues.NO_RESUBMIT_FOR_REVIEW
      ) {
        this.setDraftFlags();
      }
    }
  }

  setDraftFlags(): void {
    this.updateFlags(
      this.checkDocumentStatus(IntakeDocumentSubmissionStatus.DRAFT),
      {
        isResubmitForReviewBtnDisabled: false,
        showUpdatedDocumentMessage: false,
      },
      { isResubmitForReviewBtnDisabled: true, showUpdatedDocumentMessage: true }
    );
  }

  updateFlags(
    condition: boolean,
    trueValues: {
      [key: string]: boolean;
    },
    falseValues: {
      [key: string]: boolean;
    }
  ): void {
    if (condition) {
      Object.keys(trueValues).forEach((key) => {
        (this as any)[key] = trueValues[key];
      });
    } else {
      Object.keys(falseValues).forEach((key) => {
        (this as any)[key] = falseValues[key];
      });
    }
  }

  checkDocumentStatus(status: string): boolean {
    return this.documentTableData.some((doc) => doc.documentStatus === status);
  }

  #openModal(modalBtn?: ButtonComponent): void {
    let modalRef = this.modalService.open(ConfirmPopModalComponent, {
      isFooter: true,
      size: Size.md,
      isInverse: false,
      modalSetting: this.modalSetting,
    });

    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: ConfirmPopModel) => {
        if (
          data !== null &&
          (data?.moveToCompleteOther || data?.moveToCompleteProposal)
        ) {
          this.onclickArchiveAndCompleteBtn();
        } else {
          this.model.winningProposal = null;
        }
        modalBtn?.focus();
      });
  }

  clearReviewer(emailId: string, isForTechnicalReviewer: boolean | undefined): void {
    this.updatedRiskManagerDetails = this.updatedRiskManagerDetails.filter(
      (reviewer) =>
        reviewer.emailId !== emailId
    );
    if (isForTechnicalReviewer && this.isEligibleTechnicalReviewer) {
      this.addReviewer(this.roleTechnicalOrIndependentReviewer);
    }
   
    this.#updateReviewers();
  }

  clearUpdatedRiskManagers() {
    this.updatedRiskManagerDetails = [];
    this.selectedRiskManagers = [];
  }

  isEditingReviewer(row: SubmissionApprovers): boolean | undefined {
    const reviewerID = this.roles.roles.find(
      (item: ReviewerRoleType) => item.role === ReviewersRoleEnum.REVIEWER
    )?.id;
    return (
      row.isEditing &&
      row.reviewerRoleId === reviewerID &&
      !row.isForTechnicalReviewer
    );
  }

  isEditingTechnicalReviewer(row: SubmissionApprovers): boolean | undefined {
    const technicalReviwerID = this.roles.roles.find(
      (item: ReviewerRoleType) =>
        item.role === ReviewersRoleEnum.TECHNICAL_OR_INDEPENDENT_REVIEWER
    )?.id;
    return (
      row.isEditing &&
      this.isEligibleTechnicalReviewer
    );
  }

  // TODO: Once Employee ID is implemented this needs to be checked
  // checkActionsColumn() {
  //   for (let i in this.updatedRiskManagerDetails) {
  //     if (
  //       this.loggedInEmployeeId ===
  //         this.updatedRiskManagerDetails[i].reviewerId &&
  //       this.submissionStatus === 'Submitted' &&
  //       this.updatedRiskManagerDetails[i].statusReviewedDate === null
  //     ) {
  //       this.showActionsHeader = true;
  //       break;
  //     } else {
  //       this.showActionsHeader = false;
  //     }
  //   }
  // }

  showToast(): void {
    this.toastService.createToast(this.toastOptions);
  }

  commonToast(): void {
    this.toastService.createToast(this.submittedToastOption);
  }

  setToast(toast: any): void {
    this.toastService.createToast(toast);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
