import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {

  transform(value: any): string {
    if (!value) {
      return value;
    }
  //Sentence Casing Except first Word.
    const words = value.split(' ');
    if (words.length === 1) {
      return value;
    }
    const firstWord = words[0];
    const restOfWords = words.slice(1).map((word:any) => word.charAt(0).toLowerCase() + word.slice(1).toLowerCase());

    return [firstWord, ...restOfWords].join(' ');
  }

}
