import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { GlobalEngagementService } from '../../../../http/global-engagement/global-engagement.service';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { CommonHelperService } from '../../../../common/services/common-helper.service';
import { Subject, takeUntil } from 'rxjs';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { IntakeConstant } from '../../../../intake/constants/intake.constant';
import {
  GlobalDocumentStatusModel,
  GlobalDocumentTypeModel,
} from '../../../../common/models/global-engagement.model';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalEngagementsConstant } from '../../../constants/global-engagements.constants';
import { ErrorResponse } from '../../../../common/models/external-communication.model';

@Component({
  selector: 'app-engagement-document-link',
  templateUrl: './engagement-document-link.component.html',
  styleUrl: './engagement-document-link.component.less',
})
export class EngagementDocumentLinkComponent {
  constructor(
    private readonly modalService: ModalService,
    private readonly globaleService: GlobalEngagementService,
    private readonly progressIndicator: ProgressIndicatorService,
    private commonHelperService: CommonHelperService
  ) {}
  @Input() public documentInitiatedDetails: any = {};
  documentLink: string = '';
  documentTitle: string = '';
  documentType: number = 0;
  documentStatus: number = 0;
  documentComment: string = '';
  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  documentTypeItemsList: SelectItemOptions[] = [];
  documentStatusItemList: SelectItemOptions[] = [];
  allStatusAvailable: SelectItemOptions[] = [];
  otherStatus: number = GlobalEngagementsConstant.otherType;

  ngOnInit(): void {
    console.log(this.documentInitiatedDetails);
    this.getDocumentTypeDropdownData(
      this.documentInitiatedDetails.submissionTypeId
    );
    this.getDocumentStatusDropdownData();
  }

  getDocumentStatusDropdownData(): void {
    this.globaleService.getGlobalDocumentStatus().subscribe({
      next: (result: GlobalDocumentStatusModel[]) => {
        let convertedArray = result.map((item: GlobalDocumentStatusModel) => ({
          value: item.documentStatusId,
          heading: item.documentStatusName,
        }));
        this.documentStatusItemList = convertedArray;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.progressIndicator.hide();
      },
    });
  }

  getDocumentTypeDropdownData(id: number): void {
    this.globaleService.getGlobalDocumentType(id).subscribe({
      next: (result: GlobalDocumentTypeModel[]) => {
        const convertedArray = result.map((item: GlobalDocumentTypeModel) => ({
          value: item.documentTypeId,
          heading: item.documentTypeName,
        }));
        this.documentTypeItemsList = convertedArray;
      },
      error: (err: ErrorResponse) => {
        console.log(err);
        this.progressIndicator.hide();
      },
    });
  }

  async saveDocumentLink(): Promise<void> {
    const postRequestData = [{
      SubmissionId: this.documentInitiatedDetails.submissionId,
      DocumentTypeId: this.documentType,
      DocumentStatusId: this.documentStatus,
      SubmissionDocumentTypeId: 2,
      DocumentTitle: this.commonHelperService.trimPrefixEmptySpace(
        this.documentTitle
      ),
      DocumentUrl: this.documentLink,
      Comments: this.commonHelperService.trimPrefixEmptySpace(
        this.documentComment
      ),
      AttachmentId: null,
    }]

    return new Promise((resolve, reject) => {
      this.globaleService
        .saveGlobalDocument(postRequestData)
        .pipe(takeUntil(this.#unsubscriber$))
        .subscribe({
          next: (response: string) => {
            resolve();
          },
          error: (err: ErrorResponse) => {
            reject(err);
          },
        });
    });
  }

  formValid(): boolean {
    if (
      this.documentLink.length > 0 &&
      this.documentTitle.length > 0 &&
      this.documentType != 0 &&
      this.documentStatus != 0
    ) {
      if (
        this.documentType === this.otherStatus &&
        this.documentComment.length > 0
      ) {
        return true;
      } else if (
        this.documentType === this.otherStatus &&
        this.documentComment.length === 0
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  async closeAll(): Promise<void> {
    try {
      await this.saveDocumentLink();
      this.close();
    } catch (error) {}
    this.close();
  }

  close(): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
