import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environment/environment';
import { Observable } from 'rxjs';
import { DashboardDataCollection, DashboardGridLevelFilter } from '../../common/models/start-page/start-page.model';
import { NCADashboardTilesCount, NCASpecialistDashboardModel } from '../../common/models/start-page/nca-specialist-dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class NCASpecialistWebapiService {
  constructor(private httpService: HttpClient) { }

  apiUrl: string = environment.apiUrl;

  getNCADashBoardGridData(tileCode: string, pageNumber: number, pageSize: number, sortParameterCode: number, isAscending: boolean, filterData : DashboardGridLevelFilter[]): Observable<DashboardDataCollection<NCASpecialistDashboardModel>> {
    const params = {
      tileCode: tileCode,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortParameterCode: sortParameterCode,
      isAscending: isAscending
    };
    return this.httpService.post<DashboardDataCollection<NCASpecialistDashboardModel>>(this.apiUrl + 'NCA/NCADashboard/GetNCADashBoardGridData',filterData, { params });
  }

  getNCADashboardTilesCount(): Observable<NCADashboardTilesCount> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpService.get<NCADashboardTilesCount>(this.apiUrl + 'NCA/NCADashboard/GetNCADashboardTilesCount', { headers });
  }
}

