<div class="dds-modal-overlay" (click)="closeModal()"></div>

<div
  ddsResize
  [ngClass]="{
    'dds-modal_lg': size === 'lg',
    'dds-modal_inverse': isInverse
  }"
  class="dds-modal"
>
  <div class="dds-modal__header">
    <span class="dds-modal__title">Attach document(s)</span>
    <dds-button
      [size]="modalCloseButtonOptions.size ?? emptyString"
      [icon]="modalCloseButtonOptions.icon ?? emptyString"
      [kind]="modalCloseButtonOptions.kind ?? emptyString"
      [theme]="modalCloseButtonOptions.theme ?? emptyString"
      [ariaLabel]="modalCloseButtonOptions.ariaLabel ?? emptyString"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="closeModal()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <dds-uploader
      class="dds-uploader"
      [heading]="uploadAreaOptions.heading ?? ''"
      [description]="uploadAreaOptions.description ?? ''"
      [dragAndDropText]="uploadAreaOptions.dragAndDropText ?? ''"
      [isDragAndDrop]="uploadAreaOptions.isDragAndDrop ?? false"
      [isMultiple]="uploadAreaOptions.isMultiple ?? false"
      [acceptFormats]="uploadAreaOptions.acceptFormats ?? ''"
      [acceptExtensions]="uploadAreaOptions.acceptExtensions ?? ''"
      [exceptSymbols]="uploadAreaOptions.exceptSymbols ?? ''"
      [btnLabel]="uploadAreaOptions.btnLabel ?? ''"
      [isInverse]="uploadAreaOptions.isInverse ?? false"
      [disabled]="uploadAreaOptions.disabled ?? true"
      [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind ?? ''"
      (uploadedItems)="uploadedItems($event)"
    ></dds-uploader>

    <!-- TODO: need to use table tag for creating table -->
    <!-- <div class="div-headers w-100">
      <table class="table">
        <thead>
          <tr>
            <th class="col-md-4 column">
              <span class="div-headers-style">Title</span>
            </th>
            <th class="col-md-2 column">
              <span class="div-headers-style">Type</span>
            </th>
            <th class="col-md-2 column" *ngIf="isStatusColumnVisible">
              <span class="div-headers-style">Status</span>
            </th>
            <th class="col-md-3 column">
              <span class="div-headers-style">Comment(Optional)</span>
            </th>
            <th class="col-md-1 column"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="isUploadingInProgress">
            <tr *ngFor="let row of documentUploadInProgressData">
              <td class="col-md-2 column">
                <span class="div-data-style data-alignment">{{
                  row.fileName
                }}</span>
              </td>
              <td class="col-md-3 column">
                <dds-progress
                  class="data-alignment"
                  [isInverse]="options.isInverse ?? false"
                  [description]="options.description ?? ''"
                  [isVertical]="options.isVertical ?? false"
                  [label]="options.label ?? ''"
                  [size]="options.size ?? ''"
                  [theme]="options.theme ?? ''"
                  [value]="fileUploadPercentage"
                ></dds-progress>
              </td>
              <td class="col-md-5 column">
                <span class="uploading-color margin"
                  >Uploading in progress</span
                >
              </td>
              <td class="col-md-1 column">
                <dds-button
                  [size]="modalCloseButtonOptions.size ?? emptyString"
                  [icon]="modalCloseButtonOptions.icon ?? emptyString"
                  [kind]="modalCloseButtonOptions.kind ?? emptyString"
                  [theme]="modalCloseButtonOptions.theme ?? emptyString"
                  ariaLabel="Remove document"
                  class="dds-modal__close"
                  [isInverse]="isInverse"
                  (clicked)="removeDocument(row)"
                ></dds-button>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!isUploadingInProgress">
            <tr *ngFor="let row of documentUploadData">
              <td class="file-upload-row padding-xs d-flex align-items-center">
                <div class="title-section align-items-center d-flex">
                  <div class="file-name ellipsis">
                    <span
                      [ddsTooltip]="row.documentTitle"
                      *ngIf="!row.isFileNameInEditMode"
                      >{{ row.documentTitle }}</span
                    >
                    <dds-input
                      class="editing"
                      *ngIf="row.isFileNameInEditMode"
                      [placeholder]="textFileOptions.placeholder ?? ''"
                      [maxLengthPosition]="
                        textFileOptions.maxLengthPosition ?? ''
                      "
                      [size]="textFileOptions.size ?? ''"
                      [(ngModel)]="row.documentTitle"
                    ></dds-input>
                  </div>
                  <div
                    class="cursor"
                    *ngIf="!row.isFileNameInEditMode"
                    (click)="onFileNameEdit(row)"
                  >
                    <img src="assets/dds-icons/pencil-icon.svg" />
                  </div>
                </div>
              </td>

              <td class="dropdown-section align-items-center d-flex">
                <span
                  *ngIf="row.documentTypeId === 0"
                  class="inline-block padding-left-sm padding-bottom-16 icon-required-yellow"
                ></span>
                <dds-select
                  class="const-width"
                  [isResponsive]="selectOptions.isResponsive ?? false"
                  [isRequired]="selectOptions.isRequired ?? false"
                  [isInverse]="selectOptions.isInverse ?? false"
                  [list]="documentTypeItemsList"
                  [label]="selectOptions.label ?? ''"
                  [labelPosition]="selectOptions.labelPosition ?? ''"
                  [customClass]="selectOptions.customClass ?? ''"
                  [placeholder]="'Select type'"
                  [size]="selectOptions.size ?? ''"
                  [stickerWidth]="selectOptions.stickerWidth ?? ''"
                  [(ngModel)]="row.documentTypeId"
                  [stickerPosition]="selectOptions.stickerPosition ?? ''"
                ></dds-select>
              </td>

              <td
                class="dropdown-section align-items-center d-flex"
                *ngIf="isStatusColumnVisible"
              >
                <span
                  *ngIf="row.documentStatusId == 0"
                  class="inline-block padding-left-sm padding-bottom-16 icon-required-yellow col-md-1"
                ></span>
                <dds-select
                  class="const-width"
                  [isResponsive]="selectOptions.isResponsive ?? false"
                  [isRequired]="selectOptions.isRequired ?? false"
                  [isInverse]="selectOptions.isInverse ?? false"
                  [list]="documentStatusItemList"
                  [label]="selectOptions.label ?? ''"
                  [labelPosition]="selectOptions.labelPosition ?? ''"
                  [customClass]="selectOptions.customClass ?? ''"
                  [placeholder]="'Select status'"
                  [size]="selectOptions.size ?? ''"
                  [stickerWidth]="selectOptions.stickerWidth ?? ''"
                  [stickerPosition]="selectOptions.stickerPosition ?? ''"
                  [(ngModel)]="row.documentStatusId"
                ></dds-select>
              </td>

              <td
                class="comment-section align-items-center d-flex padding-left-s"
              >
                <span
                  *ngIf="
                    row.documentTypeId === otherTypeSelected &&
                    row.comments.length === 0
                  "
                  class="inline-block padding-left-sm padding-bottom-16 icon-required-yellow col-md-1"
                ></span>
                <dds-input
                  [placeholder]="textareaOptions.placeholder ?? ''"
                  [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
                  [size]="textareaOptions.size ?? ''"
                  [(ngModel)]="row.comments"
                ></dds-input>
              </td>

              <td class="close-section">
                <dds-button
                  [size]="modalCloseButtonOptions.size ?? emptyString"
                  [icon]="modalCloseButtonOptions.icon ?? emptyString"
                  [kind]="modalCloseButtonOptions.kind ?? emptyString"
                  [theme]="modalCloseButtonOptions.theme ?? emptyString"
                  [ariaLabel]="modalCloseButtonOptions.ariaLabel ?? emptyString"
                  [isInverse]="modalCloseButtonOptions.isInverse ?? false"
                  class="dds-modal__close close-icon"
                  (clicked)="removeDocument(row)"
                ></dds-button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div> -->

    <div class="div-headers w-100">
      <div class="row">
        <div class="col-md-4 column">
          <span class="div-headers-style">Title</span>
        </div>
        <div class="col-md-2 column">
          <span class="div-headers-style">Type</span>
        </div>
        <div class="col-md-2 column" *ngIf="isStatusColumnVisible">
          <span class="div-headers-style">Status</span>
        </div>
        <div class="col-md-3 column">
          <span class="div-headers-style">Comment(Optional)</span>
        </div>
      </div>

      <div class="div-whole-data">
        <span *ngIf="isUploadingInProgress">
          <div
            class="row div-data align-items-baseline"
            *ngFor="let row of documentUploadInProgressData"
          >
            <div class="col-md-2 column">
              <span class="div-data-style data-alignment">{{
                row.fileName
              }}</span>
            </div>
            <div class="col-md-3 column">
              <dds-progress
                class="data-alignment"
                [isInverse]="options.isInverse ?? false"
                [description]="options.description ?? ''"
                [isVertical]="options.isVertical ?? false"
                [label]="options.label ?? ''"
                [size]="options.size ?? ''"
                [theme]="options.theme ?? ''"
                [value]="fileUploadPercentage"
              ></dds-progress>
            </div>
            <div class="col-md-5 column">
              <span class="uploading-color margin">Uploading in progress</span>
            </div>
            <div class="col-md-1 column">
              <dds-button
                [size]="modalCloseButtonOptions.size ?? emptyString"
                [icon]="modalCloseButtonOptions.icon ?? emptyString"
                [kind]="modalCloseButtonOptions.kind ?? emptyString"
                [theme]="modalCloseButtonOptions.theme ?? emptyString"
                ariaLabel="Remove document"
                class="dds-modal__close"
                [isInverse]="isInverse"
                (clicked)="removeDocument(row)"
              ></dds-button>
            </div>
          </div>
        </span>

        <span>
          <div
            class="row div-data d-flex align-items-center justify-content-center"
            *ngFor="let row of documentUploadData"
          >
            <div class="file-upload-row padding-xs d-flex align-items-center">
              <div class="title-section align-items-center d-flex">
                <div class="file-name ellipsis">
                  <span
                    [ddsTooltip]="row.documentTitle"
                    *ngIf="!row.isFileNameInEditMode"
                    >{{ row.documentTitle }}</span
                  >
                  <dds-input
                    class="editing"
                    *ngIf="row.isFileNameInEditMode"
                    [placeholder]="textFileOptions.placeholder ?? ''"
                    [maxLengthPosition]="
                      textFileOptions.maxLengthPosition ?? ''
                    "
                    [size]="textFileOptions.size ?? ''"
                    [(ngModel)]="row.documentTitle"
                  >
                  </dds-input>
                </div>
                <div
                  class="cursor"
                  *ngIf="!row.isFileNameInEditMode"
                  (click)="onFileNameEdit(row)"
                >
                  <img src="assets/dds-icons/pencil-icon.svg" />
                </div>
              </div>

              <div class="dropdown-section align-items-center d-flex">
                <span
                  *ngIf="row.documentTypeId === 0"
                  class="inline-block padding-left-sm padding-bottom-16 icon-required-yellow"
                ></span>
                <dds-select
                  class="const-width"
                  [isResponsive]="selectOptions.isResponsive ?? false"
                  [isRequired]="selectOptions.isRequired ?? false"
                  [isInverse]="selectOptions.isInverse ?? false"
                  [list]="documentTypeItemsList"
                  [label]="selectOptions.label ?? ''"
                  [labelPosition]="selectOptions.labelPosition ?? ''"
                  [customClass]="selectOptions.customClass ?? ''"
                  [placeholder]="'Select type'"
                  [size]="selectOptions.size ?? ''"
                  [stickerWidth]="selectOptions.stickerWidth ?? ''"
                  [(ngModel)]="row.documentTypeId"
                  [stickerPosition]="selectOptions.stickerPosition ?? ''"
                >
                </dds-select>
              </div>

              <div
                class="dropdown-section align-items-center d-flex"
                *ngIf="isStatusColumnVisible"
              >
                <span
                  *ngIf="row.documentStatusId == 0"
                  class="inline-block padding-left-sm padding-bottom-16 icon-required-yellow col-md-1"
                ></span>
                <dds-select
                  class="const-width"
                  [isResponsive]="selectOptions.isResponsive ?? false"
                  [isRequired]="selectOptions.isRequired ?? false"
                  [isInverse]="selectOptions.isInverse ?? false"
                  [list]="documentStatusItemList"
                  [label]="selectOptions.label ?? ''"
                  [labelPosition]="selectOptions.labelPosition ?? ''"
                  [customClass]="selectOptions.customClass ?? ''"
                  [placeholder]="'Select status'"
                  [size]="selectOptions.size ?? ''"
                  [stickerWidth]="selectOptions.stickerWidth ?? ''"
                  [stickerPosition]="selectOptions.stickerPosition ?? ''"
                  [(ngModel)]="row.documentStatusId"
                >
                </dds-select>
              </div>

              <div
                class="comment-section align-items-center d-flex padding-left-s"
              >
                <span
                  *ngIf="
                    row.documentTypeId === otherTypeSelected &&
                    row.comments.length === 0
                  "
                  class="inline-block padding-left-sm padding-bottom-16 icon-required-yellow col-md-1"
                ></span>
                <dds-input
                  [placeholder]="textareaOptions.placeholder ?? ''"
                  [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
                  [size]="textareaOptions.size ?? ''"
                  [(ngModel)]="row.comments"
                >
                </dds-input>
              </div>

              <div class="close-section">
                <dds-button
                  [size]="modalCloseButtonOptions.size ?? emptyString"
                  [icon]="modalCloseButtonOptions.icon ?? emptyString"
                  [kind]="modalCloseButtonOptions.kind ?? emptyString"
                  [theme]="modalCloseButtonOptions.theme ?? emptyString"
                  [ariaLabel]="modalCloseButtonOptions.ariaLabel ?? emptyString"
                  [isInverse]="modalCloseButtonOptions.isInverse ?? false"
                  class="dds-modal__close close-icon"
                  (clicked)="removeDocument(row)"
                ></dds-button>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item"
        (clicked)="onSave()"
        (keyboardClicked)="onSave()"
        [disabled]="isSaveButtonDisabled()"
        >Save</dds-button
      >
      <dds-button
        class="dds-modal__footer-item"
        kind="secondaryLoud"
        [isInverse]="isInverse"
        (clicked)="closeModal()"
        (keyboardClicked)="closeModal()"
        >Cancel</dds-button
      >
    </div>
    <div
      class="dds-modal__footer-content dds-modal__footer-content_right"
    ></div>
  </div>
</div>
