import {
  ErrorState,
  Size,
  ExtThemes,
  ButtonKind,
  WidthState,
  Column,
  Themes,
} from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../constants/intake-constant';
import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';

export const RADIO_GROUP_OPTIONS_FOR_RFP: RadioGroupOptions = {
  name: 'RelatedtoRFP',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  title: 'Is this proposal in response to a "Request for Proposal (RFP)?',
  isRequired: true,
  isInverse: false,
};

export const REP_RADIO_GROUP_VALUE: RadioButton[] = [
  {
    options: {
      label: 'Yes',
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
      disabled: undefined,
    },
    value: 'yes',
  },
  {
    options: {
      label: 'No',
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: 'no',
  },
];

export const RADIO_GROUP_OPTIONS_FOR_OTHER_SUBTYPE: RadioGroupOptions = {
  name: 'OtherSubtype',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  title: 'Subtype',
  isRequired: true,
  isInverse: false,
};
export const RADIO_GROUP_OPTIONS_FOR_CO_CHANGE_SCOPE: RadioGroupOptions = {
  name: 'COChangeScope',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  title:
    'Is the scope of the Change Order limited to changes in the timeline, effort (resources), and/or fees?',
  isRequired: true,
  isInverse: false,
};

export const CO_CHANGE_SCOPE_RADIO_GROUP_VALUE: RadioButton[] = [
  {
    options: {
      label: 'Yes',
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
      disabled: undefined,
    },
    value: 'yes',
  },
  {
    options: {
      label: 'No',
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: 'no',
  },
];

export const SHOW_MORE_BTN_OPTIONS: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.silent,
  size: Size.sm,
  width: WidthState.fixed,
  isLoading: false,
  icon: IntakeConstant.DDS_ICON_ARROW_DOWN,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Show Less',
  customClass: '',
  role: 'button',
};

export const CO_SEARCH_OPTION: SearchOptions = {
  placeholder:
    IntakeConstant.SEARCH_OPTION_PLACEHOLDER_FOR_COMMON_CO_WITHOUT_EL_SOW,
  size: Size.md,
  customClass: '',
};

const COMMON_BTN_OPTIONS: any = {
  theme: ExtThemes.green,
  kind: ButtonKind.primaryLoud,
  size: Size.lg,
  width: WidthState.fixed,
  isLoading: false,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: true,
  customClass: '',
  role: 'button',
};
export const PrimaryButton: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.primary,
  size: Size.sm,
  width: WidthState.fixed,
  isLoading: false,
  icon: '',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Button',
  customClass: '',
  role: 'button',
};

export const SUBMIT_BTN_OPTIONS: ButtonOptions = {
  ...COMMON_BTN_OPTIONS,
  icon: '',
  customClass: '',
  ariaLabel: IntakeConstant.SUBMIT_BTN_LABEL,
};

export const ADD_REVIEWER_BTN_OPTIONS: ButtonOptions = {
  ...COMMON_BTN_OPTIONS,
  kind: ButtonKind.silent,
  customClass: '',
  icon: 'dds-icon_plus',
  isIconLeft: false,
  ariaLabel: IntakeConstant.ADD_REVIEWER_BTN_LABEL,
};

export const ARCHIVE_AND_COMPLETE_BTN_OPTIONS: ButtonOptions = {
  ...COMMON_BTN_OPTIONS,
  icon: '',
  customClass: '',
  ariaLabel: IntakeConstant.ARCHIVE_AND_COMPLETE_BTN_LABEL,
};

export const RESUBMIT_FOR_REVIEW_BTN_OPTIONS: ButtonOptions = {
  ...COMMON_BTN_OPTIONS,
  icon: '',
  customClass: '',
  ariaLabel: IntakeConstant.RESUBMIT_FOR_REVIEW_BTN_LABEL,
};
export const SecondaryButton: ButtonOptions = {
  theme: ExtThemes.green,
  kind: ButtonKind.secondary,
  size: Size.sm,
  width: WidthState.fixed,
  isLoading: false,
  icon: '',
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: false,
  ariaLabel: 'Button',
  customClass: '',
  role: 'button',
};

export const CompleteReviewPopUpTableHeader: Column[] = [
  { name: 'title', header: 'Title', dataType: 'string', minWidth: '14.375rem' },
  { name: 'Status', header: 'Status', dataType: 'number', minWidth: '8.75rem' },
];

export const toastMessage = {
  title: 'Review Completed',
  message: '',
  lifeTime: 3000,
  withProgressBar: false,
  position: 'bottom-center',
  closeBtnIcon: 'dds-icon_close',
  isCloseIcon: false,
  customClass: '',
  isError: false,
  isInverse: false,
  isLoading: false,
  limit: 5,
  theme: Themes.dark,
};

export const submittedMessage = {
  title: 'Submitted',
  message: '',
  lifeTime: 3000,
  withProgressBar: false,
  position: 'bottom-center',
  isCloseIcon: false,
  customClass: 'toast-align',
  isError: false,
  isInverse: false,
  isLoading: false,
  limit: 5,
  theme: Themes.dark,
};

export const returnForReworkMessage = {
  title: 'Returned for rework',
  message: '',
  lifeTime: 3000,
  withProgressBar: false,
  position: 'bottom-center',
  isCloseIcon: false,
  customClass: 'toast-align',
  isError: false,
  isInverse: false,
  isLoading: false,
  limit: 5,
  theme: Themes.dark,
};

export const resubmittedToastMessage = {
  title: 'Re-submitted',
  message: '',
  lifeTime: 3000,
  withProgressBar: false,
  position: 'bottom-center',
  isCloseIcon: false,
  customClass: 'toast-align',
  isError: false,
  isInverse: false,
  isLoading: false,
  limit: 5,
  theme: Themes.dark,
};
