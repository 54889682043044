<div class="d-flex" *ngIf="permissionObj['isIntakeSubmissionWBSVisible']">
  <div class="row margin-top-m">
    <div class="col-12 d-flex">
      <span
        class="icon-required-yellow"
        *ngIf="wbsWarning && showEditWbsChargeCode"
      ></span>
      <span class="eclipse-small-header-grey text-align-left"
        >Charge code (WBS):</span
      >
    </div>
    <div>
      <ng-container *ngIf="showEditWbsChargeCode; else wbsChargeCodeSelected">
        <div class="col-12 padding-top-xs">
          <dds-suggestions-tags-input
            *ngIf="reloadWbsChargeCode"
            [(ngModel)]="wbsChargeCode"
            [ariaLabel]="options.ariaLabel ?? _EMPTY"
            [labelPosition]="options.labelPosition ?? _EMPTY"
            [placeholder]="options.placeholder ?? _EMPTY"
            [searchList]="searchList"
            [disabled]="
              wbsInputDisabled || !permissionObj['isIntakeSubmissionWBSEnable']
            "
            [isResponsive]="true"
            (ngModelChange)="onWbsChangeValue()"
            [debounceTime]="1000"
          >
          </dds-suggestions-tags-input>
          <div class="d-flex padding-top-xs">
            <dds-checkbox
              [value]="pendingWBSCheckboxValue"
              [(ngModel)]="pendingWBSCheckboxValue"
              [theme]="checkboxThemeColor"
              [disabled]="!permissionObj['isIntakeSubmissionWBSEnable']"
              (stateChanged)="wbsPendingCheckboxChange($event)"
            >
            </dds-checkbox>
            <span>WBS Pending</span>
          </div>
        </div>
      </ng-container>
      <ng-template class="col-8 padding-top-xs d-flex" #wbsChargeCodeSelected>
        <span>{{ wbsChargeCode }}</span>
        <span
          class="dds-icon dds-icon_copy__l__stroke icon margin-left-xs cursor-clickable"
          aria-label="Copy"
          aria-placeholder="Copy"
          title="Copy"
          (click)="clipboardCopy()"
        ></span>
        <span
          *ngIf="permissionObj['isIntakeSubmissionWBSEnable']"
          class="dds-icon dds-icon_edit__s__filled margin-left-xs cursor-clickable"
          aria-label="edit wbs charge code"
          aria-placeholder="edit wbs charge code"
          title="edit wbs charge code"
          (click)="editWBSChargeCode()"
        ></span>
      </ng-template>
    </div>
  </div>
  <div
    class="row margin-top-m"
    *ngIf="
      showDescriptionAndArchive() &&
      permissionObj['isIntakeSubmissionWBSDescriptionVisible']
    "
  >
    <div>
      <span class="eclipse-small-header-grey text-align-left"
        >WBS Description</span
      >
    </div>

    <div class="col-12">
      <div class="textarea-container">
        <div class="row padding-top-xs">
          <div class="col-12">
            {{ wbsDescription ? wbsDescription : defaultForEmptyFields }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="row margin-top-m" *ngIf="showDescriptionAndArchive()">
      <div class="col-12 d-flex">
        <span
          class="icon-required-yellow"
          *ngIf="selectedArchive === emptyString"
        ></span>
        <span class="eclipse-small-header-grey text-align-left">Archive:</span>
      </div>
      <div class="col-12">
        <div
          *ngIf="showArchiveDropdown; else showArchiveDetails"
          class="col-12"
        >
          <dds-select
            [placeholder]="archiveSelectOptions.placeholder ?? _EMPTY"
            [list]="actionDropdownList"
            [stickerMaxHeight]="'40vh'"
            [(ngModel)]="selectedArchive"
            [disabled]="!permissionObj['isIntakeCloseoutFormEnable']"
            (ngModelChange)="onArchiveSelect()"
          ></dds-select>
        </div>
        <ng-template #showArchiveDetails>
          <div class="col-12">
            <div class="textarea-container">
              <div class="row padding-top-xs">
                <div>
                  <span
                    [ddsTooltip]="wbsArchiveSwfidivetails"
                    [tooltipInvokeType]="
                      tooltipOptions.tooltipInvokeType ?? _EMPTY
                    "
                    [tooltipType]="tooltipOptions.tooltipType ?? _EMPTY"
                    [tooltipSize]="tooltipOptions.tooltipSize ?? _EMPTY"
                    [tooltipPosition]="tooltipOptions.tooltipPosition ?? _EMPTY"
                    [tooltipIndent]="tooltipOptions.tooltipIndent ?? 0"
                    [tooltipHasBeak]="tooltipOptions.tooltipHasBeak ?? false"
                    [tooltipIsDisabled]="
                      tooltipOptions.tooltipIsDisabled ?? false
                    "
                    [tooltipIsInverse]="
                      tooltipOptions.tooltipIsInverse ?? false
                    "
                    [tooltipTheme]="tooltipOptions.tooltipTheme ?? _EMPTY"
                    [tooltipCustomClass]="
                      tooltipOptions.tooltipCustomClass ?? _EMPTY
                    "
                    ddsFocus
                    [tooltipMaxWidth]="tooltipOptions.tooltipMaxWidth ?? 0"
                    class="dds-link"
                  >
                    {{ archiveDescription }}</span
                  >
                  <span
                    class="dds-icon dds-icon_edit__s__filled margin-left-xs cursor-clickable"
                    aria-label="Edit Archive Details"
                    aria-placeholder="Edit Archive Details"
                    title="Edit Archive Details"
                    (click)="editArchiveDetails()"
                  ></span>
                </div>
              </div>
            </div></div
        ></ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #wbsArchiveSwfidivetails>
  <div class="w-fit-content h-fit-content">
    <div class="d-flex" *ngIf="selectedArchive != archiveNotListedMsg">
      <div class="padding-xs">Archive name:</div>
      <div class="padding-xs font-bold-600">
        {{ archiveDescription ? archiveDescription : defaultForEmptyFields }}
      </div>
    </div>
    <div class="d-flex" *ngIf="selectedArchive != archiveNotListedMsg">
      <div class="padding-xs">Archive description:</div>
      <div class="padding-xs font-bold-600">
        {{
          archive?.archiveDescription
            ? archive?.archiveDescription
            : defaultForEmptyFields
        }}
      </div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project name:</div>
      <div class="padding-xs font-bold-600">
        {{ swiftDetails?.projectName ?? defaultForEmptyFields }}
      </div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project start:</div>
      <div class="padding-xs font-bold-600">
        {{ swiftDetails?.projectStartDate ?? defaultForEmptyFields }}
      </div>
    </div>
    <div class="d-flex">
      <div class="padding-xs">Swift project end:</div>
      <div class="padding-xs font-bold-600">
        {{ swiftDetails?.projectEndDate ?? defaultForEmptyFields }}
      </div>
    </div>
    <div>
      <div class="padding-xs">Swift project PPMD:</div>
      <div class="padding-xs font-bold-600">
        {{ swiftDetails?.projectPPMD ?? defaultForEmptyFields }}
      </div>
    </div>
  </div>
</ng-template>
