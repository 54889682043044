<ng-container *ngIf="showPopup"
  ><div class="dds-modal-overlay" (click)="close(false)"></div>

  <div ddsResize class="dds-modal dds-modal_lg">
    <div class="dds-modal__header eclipse-header">Export to PDF</div>

    <div class="dds-modal__body">
      <div>
        <div class="comments-text-label eclipse-small-header-grey">
          <p>Select any documents you want to include with the export</p>
        </div>
        <div>
          <table class="dds-data-table dds-data-table_selection">
            <thead class="dds-data-table__header">
              <tr>
                <th class="dds-data-table__header-cell">
                  <dds-checkbox
                    [isIndeterminate]="!!selectedRowCount && !isAllSelected"
                    theme="green"
                    [value]="isAllSelected"
                    ariaLabel="select all rows"
                    (stateChanged)="selectAllRows($event, docToShow)"
                  ></dds-checkbox>
                </th>
                <ng-container *ngIf="sampleColumns.length > 0">
                  <th
                    *ngFor="let column of sampleColumns"
                    class="dds-data-table__header-cell"
                    [ngStyle]="{ minWidth: column.minWidth }"
                  >
                    <div class="dds-data-table__content">
                      <span>{{ column.header || column.name }}</span>
                    </div>
                  </th>
                </ng-container>
              </tr>
            </thead>

            <tbody class="dds-data-table__body">
              <ng-container *ngIf="docToShow.length > 0">
                <tr
                  *ngFor="let row of docToShow; index as index"
                  class="dds-data-table__row"
                  [ngClass]="{ 'dds-data-table__row_selected': row.selected }"
                  (click)="toggleRow(row)"
                >
                  <td class="dds-data-table__cell">
                    <dds-checkbox
                      [ariaLabel]="'row ' + (index + 1)"
                      theme="green"
                      [value]="row.selected"
                    ></dds-checkbox>
                  </td>
                  <td
                    *ngFor="let column of sampleColumns"
                    class="dds-data-table__cell"
                  >
                    {{ row[column.name] }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="d-flex flex-row-reverse margin-top-s">
          <dds-button
            class="margin-left-s custom-button"
            [theme]="cancelButtonOptions.theme ?? ''"
            [kind]="cancelButtonOptions.kind ?? ''"
            (click)="close(false)"
            >Cancel</dds-button
          >
          <dds-button class="custom-button" (click)="exportSelectedDocs()"
            >Export submission</dds-button
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>
