<progress-indicator></progress-indicator>
<div class="initiate-submission-container relative margin-none">
  <div class="initiate-page padding-top-s padding-bottom-s">
    <div
      class="d-flex align-items-center justify-content-between initiate-page-header"
    >
      <h3 class="font-size-24 font-bold no-break">Initiate new submission</h3>
      <div class="submission-buttons d-flex">
        <dds-button
          class="custom-button"
          [theme]="confirmButtonOptions.theme ?? emptySpace"
          [kind]="confirmButtonOptions.kind ?? emptySpace"
          [size]="htmlElementSize"
          [width]="confirmButtonOptions.width ?? emptySpace"
          [icon]="confirmButtonOptions.icon ?? emptySpace"
          [isIconLeft]="confirmButtonOptions.isIconLeft ?? false"
          [role]="confirmButtonOptions.role ?? emptySpace"
          [isInverse]="confirmButtonOptions.isInverse ?? false"
          [isColorBg]="confirmButtonOptions.isColorBg ?? false"
          [isLoading]="confirmButtonOptions.isLoading ?? false"
          [ariaLabel]="confirmButtonOptions.ariaLabel ?? emptySpace"
          [customClass]="confirmButtonOptions.customClass ?? emptySpace"
          [disabled]="!checkEnable()"
          (clicked)="onSubmit()"
          >{{ confirmLabel }}</dds-button
        >

        <dds-button
          class="custom-button"
          [theme]="cancelOptions.theme ?? emptySpace"
          [kind]="cancelOptions.kind ?? emptySpace"
          [size]="htmlElementSize"
          [width]="cancelOptions.width ?? emptySpace"
          [icon]="cancelOptions.icon ?? emptySpace"
          [isIconLeft]="cancelOptions.isIconLeft ?? false"
          [role]="cancelOptions.role ?? emptySpace"
          [isInverse]="cancelOptions.isInverse ?? false"
          [isColorBg]="cancelOptions.isColorBg ?? false"
          [isLoading]="cancelOptions.isLoading ?? false"
          [ariaLabel]="cancelOptions.ariaLabel ?? emptySpace"
          [customClass]="cancelOptions.customClass ?? emptySpace"
          [disabled]="cancelOptions.disabled ?? false"
          (clicked)="onCancel()"
          >Cancel</dds-button
        >
      </div>
    </div>
  </div>
  <div class="submission-type-section">
    <div class="d-flex">
      <span class="text-label font-bold-600 font-size-16 line-height-m"
        >Select submission type
      </span>
    </div>
    <div class="d-flex">
      <div>
        <div class="select-submission margin-top-small">
          <dds-select
            class="custom-select"
            [disabled]="selectSubmissionSelectOptions.disabled ?? false"
            [list]="submissionTypes"
            [placeholder]="
              selectSubmissionSelectOptions.placeholder ?? emptySpace
            "
            (valueChanged)="onSubmissionTypeChange()"
            [(ngModel)]="submissionType"
            [size]="htmlElementSize"
          >
          </dds-select>
        </div>
      </div>
      <div *ngIf="showSubType">
        <div class="select-submission margin-top-small margin-left-l">
          <dds-select
            class="custom-select"
            [disabled]="otherSubTypeSelectOptions.disabled ?? false"
            [list]="submissionSubTypes"
            [placeholder]="otherSubTypeSelectOptions.placeholder ?? emptySpace"
            (valueChanged)="onSubmissionSubTypeChange()"
            [(ngModel)]="submissionSubType"
            [size]="htmlElementSize"
          >
          </dds-select>
        </div>
      </div>
    </div>
    <div *ngIf="showDescription" class="d-flex margin-top-small">
      <span
        *ngIf="CustomSubmissionType === emptySpace"
        class="required padding-left-s relative"
      ></span>
      <span class="nobold">Provide the name for the submission type: </span>
    </div>
    <div *ngIf="showDescription" class="d-flex margin-bottom-s">
      <div class="text-area">
        <dds-input
          [size]="htmlElementSize"
          class="custom-text-area"
          [placeholder]="customSubmissionTypeOptions.placeholder ?? emptySpace"
          [maxLength]="customSubmissionTypeOptions.maxLength ?? 0"
          [maxLengthPosition]="
            customSubmissionTypeOptions.maxLengthPosition ?? emptySpace
          "
          [(ngModel)]="CustomSubmissionType"
          (ngModelChange)="onCustomSubmissionTypeChange(1)"
        ></dds-input>
      </div>
    </div>
    <div class="section-ending margin-top-s padding-top-es"></div>
  </div>
  <div class="padding-left-s padding-right-s">
    <div
      *ngIf="showSelectOpportunity"
      class="select-opportunity-text-label font-size-16 font-bold-600 text-align-left margin-bottom-es"
    >
      Select opportunity
    </div>
    <div
      *ngIf="this.submissionType === 'Change Order' && !showOpportunityDetails"
      class="guidance-note text-align-left"
    >
      {{ getChangeOrderRequestHeader() }}
    </div>

    <!-- TO DO : To move the Search component to a seperate custom component to be used globally -->
    <div
      *ngIf="!showOpportunityDetails && showSelectOpportunity"
      class="margin-top-sm"
    >
      <div class="search-box-submission relative">
        <dds-search
          [size]="htmlElementSize"
          [customClass]="searchOptions.customClass ?? emptySpace"
          [icon]="searchOptions.icon || 'dds-icon_search'"
          (fieldCleared)="fieldCleared()"
        >
          <input
            ddsSearch
            [placeholder]="searchOptions.placeholder ?? emptySpace"
            [(ngModel)]="selectedOportunity"
            (ngModelChange)="selectOpportunityChanged($event)"
          />
        </dds-search>
        <div *ngIf="isSpinnerVisible" class="spinner-inside-input absolute">
          <dds-spinner
            [size]="spinnerOptions.size ?? emptySpace"
            [theme]="spinnerOptions.theme ?? emptySpace"
            [customClass]="spinnerOptions.customClass ?? emptySpace"
            [isInverse]="spinnerOptions.isInverse ?? false"
            [thickness]="spinnerOptions.thickness ?? emptySpace"
          ></dds-spinner>
        </div>
      </div>
      <div
        *ngIf="showDropdown || notFound"
        class="list-of-oppportunities d-flex flex-row-column flex-row-column padding-left-xs padding-right-xs margin-top-xs"
        (scroll)="fetchOpportunitySearchResultMore($event)"
      >
        <div *ngIf="!notFound" class="tabs-container">
          <dds-tabs
            [theme]="tabContainerOptions.theme ?? 'primary'"
            [themeType]="tabContainerOptions.themeType ?? 'primary'"
            [size]="tabContainerOptions.size ?? 'md'"
            [isResponsive]="tabContainerOptions.isResponsive ?? true"
            [disabled]="tabContainerOptions.disabled ?? false"
            [ariaLabel]="tabContainerOptions.ariaLabel ?? 'Tabs'"
            [activeIndex]="selectTabIndex"
            class="text-align-left"
          >
            <span *ngFor="let tab of tabs">
              <dds-tab
                class="padding-right-xs"
                [label]="tab.label ?? ''"
                [counter]="tab.count ?? 0"
                (tabChanged)="tabChanged(tab.code)"
              >
              </dds-tab>
            </span>
          </dds-tabs>
        </div>
        <div *ngFor="let item of opportunityList">
          <div
            class="opportunity-items d-flex flex-row-column"
            (click)="fetchOpportunityDetails(item, 'searchOpportunity')"
          >
            <div
              class="opportunity-header font-size-14 font-bold-600 text-align-left"
            >
              {{ item?.opportunityName }}
              <span class="client-status font-bold-400" *ngIf="item?.stage == 'Closed Lost'">[Lost]</span>
              <span class="client-status font-bold-400" *ngIf="item?.stage == 'Closed Abandoned'">[Abandoned]</span>
            </div>
            <div
              class="opportunity-details font-size-12 text-align-left font-bold-400"
            >
              <span class="detail-heading font-bold-400">ID: </span>
              <span
                class="details-values font-size-12 text-align-left font-bold-400"
                >{{ item?.opportunityNumber }}
              </span>
              <span class="separator"> | </span>
              <span class="detail-heading font-bold-400"
                >Opportunity stage:
              </span>
              <span
                class="details-values font-size-12 text-align-left font-bold-400"
                >{{ item?.stage }}</span
              >
              <span class="separator"> | </span>
              <span class="detail-heading font-bold-400">Leader: </span>
              <span
                class="details-values font-size-12 text-align-left font-bold-400"
                >{{ item?.leader }}</span
              >
              <span class="separator"> | </span>
              <span class="detail-heading font-bold-400">Client: </span>
              <span
                class="details-values font-size-12 text-align-left font-bold-400"
                >{{ item?.client }}</span
              >

              <span
                *ngIf="item?.isMarkedForDeletion; else swiftClientStatus"
                class="client-status font-size-12 text-align-left font-bold-400"
              >
                [{{
                  item?.swiftClientStatus == "Blocked" ||
                  item?.swiftClientStatus == "Duplicate"
                    ? item?.swiftClientStatus + " - " + markForDeletionText
                    : markForDeletionText
                }}]
              </span>
              <ng-template #swiftClientStatus>
                <span
                  *ngIf="
                    item?.swiftClientStatus == 'Blocked' ||
                    item?.swiftClientStatus == 'Duplicate'
                  "
                  class="client-status font-size-12 text-align-left font-bold-400"
                >
                  [{{ item?.swiftClientStatus }}]
                </span>
              </ng-template>
            </div>
          </div>
        </div>

        <div [innerHTML]="notFound" class="not-found"></div>
      </div>
    </div>
    <div *ngIf="showOpportunityDetails" class="opportunity-details-table">
      <table class="dds-data-table dds-data-table_fixed-header">
        <thead class="dds-data-table__header">
          <tr>
            <th
              *ngFor="let column of opportunityTableColumns"
              class="dds-data-table__header-cell"
              [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
            >
              <div>
                <span>{{ column.header }}</span>
              </div>
              <div class="dds-data-table__content">
                <span> </span>
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="dds-data-table__body">
          <tr
            *ngFor="let row of opportunityDetailsList; index as index"
            class="dds-data-table__row font-size-14 font-bold-400 line-height-sm"
          >
            <td
              *ngFor="let column of opportunityTableColumns"
              class="table-cell"
            >
              <span
                *ngIf="column.name !== 'numberOfSplits'"
                class="text-block"
                >{{ row[column.name] }}</span
              >
            </td>
            <td class="relative cross-btn-top">
              <dds-button
                class="custom-icon"
                [theme]="closeBtnOptions.theme ?? emptySpace"
                [kind]="closeBtnOptions.kind ?? emptySpace"
                [size]="closeBtnOptions.size ?? emptySpace"
                [width]="closeBtnOptions.width ?? emptySpace"
                [icon]="closeBtnOptions.icon ?? emptySpace"
                [isIconLeft]="closeBtnOptions.isIconLeft ?? false"
                [role]="closeBtnOptions.role ?? emptySpace"
                [isInverse]="closeBtnOptions.isInverse ?? false"
                [isColorBg]="closeBtnOptions.isColorBg ?? false"
                [isLoading]="closeBtnOptions.isLoading ?? false"
                [ariaLabel]="'Close'"
                [customClass]="closeBtnOptions.customClass ?? emptySpace"
                (clicked)="removeOpportunity(nonChangeOrder)"
              ></dds-button>
            </td>
          </tr>
          <tr
            *ngFor="let row of opportunityDetailsList; index as index"
            class="dds-data-table__row"
          >
            <td *ngFor="let column of opportunityTableColumns">
              <span
                *ngIf="
                  column.name == 'client' &&
                  (row['watchlistStatus'] === 'Serve with conditions' ||
                    row['watchlistStatus'] === 'Do not serve')
                "
                [ngClass]="{
                  'serve-with-conditions-container':
                    row['watchlistStatus'] === 'Serve with conditions',
                  'do-not-serve-container':
                    row['watchlistStatus'] === 'Do not serve'
                }"
              >
                {{ row["watchlistStatus"] }}
              </span>
              <span
                class="client-status-grid"
                *ngIf="
                  column.name == 'client' &&
                  (row['swiftClientStatus'] === 'Blocked' ||
                    row['swiftClientStatus'] === 'Duplicate')
                "
              >
                [{{ row["swiftClientStatus"] }}]
              </span>

              <span
                *ngIf="
                  column.name == 'client' && row.isMarkedForDeletion;
                  else rowSwiftClientStatus
                "
                class="client-status-grid"
              >
                [{{
                  row["swiftClientStatus"] === "Blocked" ||
                  row["swiftClientStatus"] === "Duplicate"
                    ? row["swiftClientStatus"] + " - " + markForDeletionText
                    : markForDeletionText
                }}]
              </span>
              <ng-template #rowSwiftClientStatus>
                <span
                  *ngIf="
                    column.name == 'client' &&
                    (row['swiftClientStatus'] === 'Blocked' ||
                      row['swiftClientStatus'] === 'Duplicate')
                  "
                  class="client-status-grid"
                >
                  [{{ row["swiftClientStatus"] }}]
                </span>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="row padding-left-s"
      *ngIf="opportunityDetailsList[0]?.watchlistStatus == 'Do not serve'"
    >
      <div class="col-11">
        <div
          class="container margin-top-small margin-bottom-s font-size-14 relative"
        >
          <div class="d-flex">
            <div class="icon-alert-logo-container">
              <div class="icon-alert-logo"></div>
            </div>
            <div class="d-flex">
              <div class="initial-text font-bold-600">
                <span
                  >Client has been flagged by our risk systems as high risk.
                  Please reach out to RM (Name and Email) to discuss next
                  steps.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="showNoreviewNote"
      class="d-flex align-items-center sub-text-section my-3"
    >
      <span class="icon-s d-inline-block icon-pinned"></span
      ><span class="eclipse-content-grey">
        {{ getNoReviewHeader() }}
      </span>
    </div>


    <!-- Restricted Opportunity Note -->
    <div *ngIf="isOpportunityConfidential">
      <div
        class="container margin-top-s margin-bottom-s d-flex relative flex-column"
      >
        <div class="d-flex">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="d-flex flex-column">
            <div class="initial-text font-bold-600">
              <span>{{ confidentialOpportunityHeader }}</span>
            </div>
            <div
              class="vertical-line absolute"
              *ngIf="isShowMoreVisibleConfidentialOppertunity"
            ></div>

            <dds-button
              *ngIf="!isShowMoreVisibleConfidentialOppertunity"
              [theme]="optionsShowMore.theme ?? emptySpace"
              [kind]="optionsShowMore.kind ?? emptySpace"
              [size]="optionsShowMore.size ?? emptySpace"
              [width]="optionsShowMore.width ?? emptySpace"
              [icon]="optionsShowMore.icon ?? emptySpace"
              [isIconLeft]="optionsShowMore.isIconLeft ?? false"
              [role]="optionsShowMore.role ?? emptySpace"
              [ariaLabel]="optionsShowMore.ariaLabel ?? emptySpace"
              [customClass]="optionsShowMore.customClass ?? emptySpace"
              (clicked)="clickShowMore($event, 'confidentialOppertunity')"
              >Show more</dds-button
            >
          </div>
        </div>
        <div
          class="moreText relative margin-top-xs"
          *ngIf="isShowMoreVisibleConfidentialOppertunity"
        >
          <div class="flexVertically d-flex flex-column">
            <div class="margin-top-xxs text-align-left">
              <span class="text-word-wrap" [innerHtml]="confidentialOpportunityBody"></span>
            </div>
            <div class="margin-top-s">
              <dds-button
                [theme]="optionsShowLessBtn.theme ?? emptySpace"
                [kind]="optionsShowLessBtn.kind ?? emptySpace"
                [size]="optionsShowLessBtn.size ?? emptySpace"
                [width]="optionsShowLessBtn.width ?? emptySpace"
                [icon]="optionsShowLessBtn.icon ?? emptySpace"
                [isIconLeft]="optionsShowLessBtn.isIconLeft ?? false"
                [role]="optionsShowLessBtn.role ?? emptySpace"
                [ariaLabel]="optionsShowLessBtn.ariaLabel ?? emptySpace"
                [customClass]="optionsShowLessBtn.customClass ?? emptySpace"
                (clicked)="clickShowLess($event, 'confidentialOppertunity')"
                >Show less</dds-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- M&A Warning Note-->
    <div *ngIf="isOpportunityMA">
      <div
        class="container margin-top-s margin-bottom-s d-flex relative flex-column"
      >
        <div class="d-flex">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="d-flex flex-column">
            <div class="initial-text font-bold-600">
              <span>{{ warningHeaderMA }}</span>
            </div>
            <div class="vertical-line absolute" *ngIf="isShowMoreVisible"></div>

            <dds-button
              *ngIf="!isShowMoreVisible"
              [theme]="optionsShowMore.theme ?? emptySpace"
              [kind]="optionsShowMore.kind ?? emptySpace"
              [size]="optionsShowMore.size ?? emptySpace"
              [width]="optionsShowMore.width ?? emptySpace"
              [icon]="optionsShowMore.icon ?? emptySpace"
              [isIconLeft]="optionsShowMore.isIconLeft ?? false"
              [role]="optionsShowMore.role ?? emptySpace"
              [ariaLabel]="optionsShowMore.ariaLabel ?? emptySpace"
              [customClass]="optionsShowMore.customClass ?? emptySpace"
              (clicked)="clickShowMore($event, MA)"
              >Show more</dds-button
            >
          </div>
        </div>
        <div class="moreText relative margin-top-xs" *ngIf="isShowMoreVisible">
          <div class="flexVertically d-flex flex-column">
            <div class="margin-top-xxs text-align-left">
              <span class="text-word-wrap" [innerHtml]="warningBodyMA"></span>
            </div>
            <div class="margin-top-s">
              <dds-button
                [theme]="optionsShowLessBtn.theme ?? emptySpace"
                [kind]="optionsShowLessBtn.kind ?? emptySpace"
                [size]="optionsShowLessBtn.size ?? emptySpace"
                [width]="optionsShowLessBtn.width ?? emptySpace"
                [icon]="optionsShowLessBtn.icon ?? emptySpace"
                [isIconLeft]="optionsShowLessBtn.isIconLeft ?? false"
                [role]="optionsShowLessBtn.role ?? emptySpace"
                [ariaLabel]="optionsShowLessBtn.ariaLabel ?? emptySpace"
                [customClass]="optionsShowLessBtn.customClass ?? emptySpace"
                (clicked)="clickShowLess($event, MA)"
                >Show less</dds-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- GPS Warning Note-->
    <div *ngIf="isOpportunityGPS">
      <div
        class="container margin-top-s margin-bottom-s d-flex relative flex-column"
      >
        <div class="d-flex">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="d-flex flex-column">
            <div class="initial-text font-bold-600">
              <span>{{ warningHeaderGPS }}</span>
            </div>
            <div
              class="vertical-line absolute"
              *ngIf="isShowMoreVisibleGPS"
            ></div>

            <dds-button
              *ngIf="!isShowMoreVisibleGPS"
              [theme]="optionsShowMore.theme ?? emptySpace"
              [kind]="optionsShowMore.kind ?? emptySpace"
              [size]="optionsShowMore.size ?? emptySpace"
              [width]="optionsShowMore.width ?? emptySpace"
              [icon]="optionsShowMore.icon ?? emptySpace"
              [isIconLeft]="optionsShowMore.isIconLeft ?? false"
              [role]="optionsShowMore.role ?? emptySpace"
              [ariaLabel]="optionsShowMore.ariaLabel ?? emptySpace"
              [customClass]="optionsShowMore.customClass ?? emptySpace"
              (clicked)="clickShowMore($event, GPS)"
              >Show more</dds-button
            >
          </div>
        </div>
        <div
          class="moreText relative margin-top-xs"
          *ngIf="isShowMoreVisibleGPS"
        >
          <div class="flexVertically d-flex flex-column">
            <div class="margin-top-xxs text-align-left">
              <span class="text-word-wrap" [innerHtml]="warningBodyGPS"></span>
            </div>
            <div class="margin-top-s">
              <dds-button
                [theme]="optionsShowLessBtn.theme ?? emptySpace"
                [kind]="optionsShowLessBtn.kind ?? emptySpace"
                [size]="optionsShowLessBtn.size ?? emptySpace"
                [width]="optionsShowLessBtn.width ?? emptySpace"
                [icon]="optionsShowLessBtn.icon ?? emptySpace"
                [isIconLeft]="optionsShowLessBtn.isIconLeft ?? false"
                [role]="optionsShowLessBtn.role ?? emptySpace"
                [ariaLabel]="optionsShowLessBtn.ariaLabel ?? emptySpace"
                [customClass]="optionsShowLessBtn.customClass ?? emptySpace"
                (clicked)="clickShowLess($event, GPS)"
                >Show less</dds-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DCF Warning Note-->
    <div *ngIf="isOpportunityDCF">
      <div
        class="container margin-top-s margin-bottom-s d-flex relative flex-column"
      >
        <div class="d-flex">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="d-flex flex-column">
            <div class="initial-text font-bold-600">
              <span>{{ warningHeaderDCF }}</span>
            </div>
            <div
              class="vertical-line absolute"
              *ngIf="isShowMoreVisibleDCF"
            ></div>

            <dds-button
              *ngIf="!isShowMoreVisibleDCF"
              [theme]="optionsShowMore.theme ?? emptySpace"
              [kind]="optionsShowMore.kind ?? emptySpace"
              [size]="optionsShowMore.size ?? emptySpace"
              [width]="optionsShowMore.width ?? emptySpace"
              [icon]="optionsShowMore.icon ?? emptySpace"
              [isIconLeft]="optionsShowMore.isIconLeft ?? false"
              [role]="optionsShowMore.role ?? emptySpace"
              [ariaLabel]="optionsShowMore.ariaLabel ?? emptySpace"
              [customClass]="optionsShowMore.customClass ?? emptySpace"
              (clicked)="clickShowMore($event, DCF)"
              >Show more</dds-button
            >
          </div>
        </div>
        <div
          class="moreText relative margin-top-xs"
          *ngIf="isShowMoreVisibleDCF"
        >
          <div class="flexVertically d-flex flex-column">
            <div class="margin-top-xxs text-align-left">
              <span class="text-word-wrap" [innerHtml]="warningBodyDCF"></span>
            </div>
            <div class="margin-top-s">
              <dds-button
                [theme]="optionsShowLessBtn.theme ?? emptySpace"
                [kind]="optionsShowLessBtn.kind ?? emptySpace"
                [size]="optionsShowLessBtn.size ?? emptySpace"
                [width]="optionsShowLessBtn.width ?? emptySpace"
                [icon]="optionsShowLessBtn.icon ?? emptySpace"
                [isIconLeft]="optionsShowLessBtn.isIconLeft ?? false"
                [role]="optionsShowLessBtn.role ?? emptySpace"
                [ariaLabel]="optionsShowLessBtn.ariaLabel ?? emptySpace"
                [customClass]="optionsShowLessBtn.customClass ?? emptySpace"
                (clicked)="clickShowLess($event, DCF)"
                >Show less</dds-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- M&A Deliverable Warning Note-->
    <div *ngIf="isOpportunityMaDeliverable">
      <div
        class="container margin-top-s margin-bottom-s d-flex relative flex-column"
      >
        <div class="d-flex">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="d-flex flex-column">
            <div class="initial-text font-bold-600">
              <span>{{ warningHeaderMaDeliverable }}</span>
            </div>
            <div
              class="vertical-line absolute"
              *ngIf="isShowMoreVisibleDCF"
            ></div>

            <dds-button
              *ngIf="!isShowMoreVisibleDCF"
              [theme]="optionsShowMore.theme ?? ''"
              [kind]="optionsShowMore.kind ?? ''"
              [size]="optionsShowMore.size ?? ''"
              [width]="optionsShowMore.width ?? ''"
              [icon]="optionsShowMore.icon ?? ''"
              [isIconLeft]="optionsShowMore.isIconLeft ?? false"
              [role]="optionsShowMore.role ?? ''"
              [ariaLabel]="optionsShowMore.ariaLabel ?? ''"
              [customClass]="optionsShowMore.customClass ?? ''"
              (clicked)="clickShowMore($event, MA_DELIVERABLE)"
              >Show more</dds-button
            >
          </div>
        </div>
        <div
          class="moreText relative margin-top-xs"
          *ngIf="isShowMoreVisibleDCF"
        >
          <div class="flexVertically d-flex flex-column">
            <div class="margin-top-xxs text-align-left">
              <span
                class="text-word-wrap"
                [innerHtml]="warningBodyMaDeliverable"
              ></span>
            </div>
            <div class="margin-top-s">
              <dds-button
                [theme]="optionsShowLessBtn.theme ?? ''"
                [kind]="optionsShowLessBtn.kind ?? ''"
                [size]="optionsShowLessBtn.size ?? ''"
                [width]="optionsShowLessBtn.width ?? ''"
                [icon]="optionsShowLessBtn.icon ?? ''"
                [isIconLeft]="optionsShowLessBtn.isIconLeft ?? false"
                [role]="optionsShowLessBtn.role ?? ''"
                [ariaLabel]="optionsShowLessBtn.ariaLabel ?? ''"
                [customClass]="optionsShowLessBtn.customClass ?? ''"
                (clicked)="clickShowLess($event, MA_DELIVERABLE)"
                >Show less</dds-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="showNoreviewNote"
      class="select-opportunity-text-label font-size-16 font-bold-600 text-align-left margin-bottom-es"
    >
      Enter the opportunity ID of the original EL/SOW or prior Change Order
    </div>
    <div *ngIf="showNoreviewNote" class="margin-top-sm">
      <div class="change-order-no-review">
        <div *ngIf="!displayChangeOrderCheck()">
          <div class="no-review-search d-flex flex-row">
            <div class="search-box-submission relative">
              <dds-search
                [size]="htmlElementSize"
                [customClass]="searchOptions.customClass ?? emptySpace"
                [icon]="searchOptions.icon || 'dds-icon_search'"
                (fieldCleared)="fieldCleared()"
                [disabled]="notAvailable"
              >
                <input
                  ddsSearch
                  [placeholder]="searchOptions.placeholder ?? emptySpace"
                  [(ngModel)]="selectedOportunity"
                  (ngModelChange)="selectOpportunityChanged($event)"
                  [disabled]="notAvailable"
                />
              </dds-search>
            </div>
            <dds-checkbox
              [value]="notAvailable"
              [(ngModel)]="notAvailable"
              theme="green"
              class="checkbox-custom"
            >
            </dds-checkbox>
            <span
              class="not-available-header font-size-14 font-bold-400 text-align-left relative"
              >Not Available</span
            >
            <span
              class="relative icon-s icon-info"
              [ddsTooltip]="gethangeOrderTooltip()"
              [tooltipWidth]="tooltipWidth"
            ></span>
          </div>
          <div
            *ngIf="showDropdown || notFound"
            class="list-of-oppportunities d-flex padding-xs"
            (scroll)="fetchOpportunitySearchResultMore($event)"
          >
            <div *ngFor="let item of opportunityList">
              <div
                class="opportunity-items d-flex flex-row-column"
                (click)="fetchOpportunityDetails(item, changeOrder)"
              >
                <div
                  class="opportunity-header font-size-14 font-bold-600 text-align-left"
                >
                  {{ item?.opportunityName }}
                </div>
                <div
                  class="opportunity-details font-size-12 text-align-left font-bold-400"
                >
                  <span class="detail-heading font-bold-400">ID:</span>
                  <span
                    class="details-values font-size-12 text-align-left font-bold-600"
                    >{{ item?.opportunityNumber }}
                  </span>
                  <span class="detail-heading font-bold-400"
                    >| Opportunity stage:</span
                  >
                  <span class="details-values">{{ item?.stage }}</span>
                  <span class="detail-heading font-bold-400">| Leader:</span>
                  <span class="details-values">{{ item?.leader }}</span>
                  <span class="detail-heading font-bold-400"> | Client:</span>
                  <span
                    class="details-values font-size-12 text-align-left font-bold-600"
                    >{{ item?.client }}</span
                  >
                </div>
              </div>
            </div>

            <div [innerHTML]="notFound" class="not-found"></div>
          </div>
        </div>
      </div>
      <div
        *ngIf="displayChangeOrderCheck()"
        class="change-order-details padding-s"
      >
        <table class="dds-data-table dds-data-table_fixed-header">
          <thead class="dds-data-table__header">
            <tr>
              <th
                *ngFor="let column of opportunityTableColumns"
                class="dds-data-table__header-cell"
                [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
              >
                <div>
                  <span>{{ column.header }}</span>
                </div>
                <div class="dds-data-table__content">
                  <span> </span>
                </div>
              </th>
            </tr>
          </thead>

          <tbody class="dds-data-table__body">
            <tr
              *ngFor="let row of changeOrderList; index as index"
              class="dds-data-table__row"
            >
              <td *ngFor="let column of opportunityTableColumns">
                <span
                  *ngIf="column.name === 'numberOfSplits'"
                  class="icon-hover"
                  [ddsTooltip]="getNumberOfSplits(changeOrder)"
                  [tooltipWidth]="tooltipWidth"
                  >{{ row[column.name] }}</span
                >
                <span *ngIf="column.name !== 'numberOfSplits'">{{
                  row[column.name]
                }}</span>
              </td>
              <td class="relative cross-btn-top">
                <dds-button
                  class="custom-icon"
                  [theme]="closeBtnOptions.theme ?? emptySpace"
                  [kind]="closeBtnOptions.kind ?? emptySpace"
                  [size]="closeBtnOptions.size ?? emptySpace"
                  [width]="closeBtnOptions.width ?? emptySpace"
                  [icon]="closeBtnOptions.icon ?? emptySpace"
                  [isIconLeft]="closeBtnOptions.isIconLeft ?? false"
                  [role]="closeBtnOptions.role ?? emptySpace"
                  [isInverse]="closeBtnOptions.isInverse ?? false"
                  [isColorBg]="closeBtnOptions.isColorBg ?? false"
                  [isLoading]="closeBtnOptions.isLoading ?? false"
                  [ariaLabel]="'Close'"
                  [customClass]="closeBtnOptions.customClass ?? emptySpace"
                  (clicked)="removeOpportunity(changeOrder)"
                ></dds-button>
              </td>
            </tr>
            <tr
              *ngFor="let row of changeOrderList; index as index"
              class="dds-data-table__row"
            >
              <td *ngFor="let column of opportunityTableColumns">
                <span
                  *ngIf="
                    column.name == 'client' &&
                    (row['watchlistStatus'] === 'Serve with conditions' ||
                      row['watchlistStatus'] === 'Do not serve')
                  "
                  [ngClass]="{
                    'serve-with-conditions-container':
                      row['watchlistStatus'] === 'Serve with conditions',
                    'do-not-serve-container':
                      row['watchlistStatus'] === 'Do not serve'
                  }"
                >
                  {{ row["watchlistStatus"] }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      *ngIf="showWarningMessage"
      class="container margin-top-s margin-bottom-s d-flex relative flex-column"
    >
      <div class="d-flex">
        <div class="icon-alert-logo-container">
          <div class="icon-alert-logo"></div>
        </div>
        <div class="d-flex flex-column">
          <div class="initial-text font-bold-600">
            <span>{{ coOppDonotMatchMessage }}</span>
          </div>
        </div>
      </div>
      <div
        *ngIf="changeOrderDuplicateError"
        class="guidance-note text-align-left"
      >
        {{ getDuplicateError() }}
      </div>
      <div
        class="row padding-left-s"
        *ngIf="changeOrderList[0]?.watchlistStatus == 'Do not serve'"
      >
        <div class="col-11">
          <div
            class="container margin-top-small margin-bottom-s d-flex flex-row-column"
          >
            <div class="d-flex">
              <div class="icon-alert-logo-container">
                <div class="icon-alert-logo"></div>
              </div>
              <div class="d-flex">
                <div class="initial-text font-bold-600">
                  <span
                    >Client has been flagged by our risk systems as high risk.
                    Please reach out to RM (Name and Email) to discuss next
                    steps.</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
