<div class="nested-multi-select">
    <div class="col-md-12 margin-bottom-s">
        <!-- Applicable FSS -->
      
        <span class="eclipse-small-header-grey">
            <span *ngIf="(selectedFSS?.length ?? 0) == 0"
            class="icon-required-yellow position-alignment"></span>
            Applicable FSS:</span>
        <div class="multiselect-div margin-top-xxs">
            <div class="col-md-5 padding-right-xs">
                <dds-multi-select class="box-size"
                    [displayTickAllBtn]="fssMultiSelectOptions.displayTickAllBtn ?? false"
                    [isResponsive]="fssMultiSelectOptions.isResponsive ?? false"
                    [isRequired]="fssMultiSelectOptions.isRequired ?? false"
                    [label]="fssMultiSelectOptions.label ?? 'Select Applicable FSS'"
                    [size]="fssMultiSelectOptions.size ?? ''" [placeholder]="fssMultiSelectOptions.placeholder ?? ''"
                    [type]="fssMultiSelectOptions.type ?? ''" [controlType]="fssMultiSelectOptions.controlType ?? ''"
                    [theme]="fssMultiSelectOptions.theme ?? ''" [isInverse]="fssMultiSelectOptions.isInverse ?? false"
                    [list]="fssOptions" (selectionChange)="onFSSChange($event)"
                    [value]="selectedFSS"
                    (valueChanged)="onFSSChange($event)"></dds-multi-select>
            </div>

            <div *ngIf="(selectedFSS?.length ?? 0) > 0"
                class="multiselectddl col-md-7">
                <span class="comments-text-label eclipse-small-header-grey">Selected values:</span>
                <dds-tag class="tag-width" *ngFor="let item of selectedFSS"
                [theme]="tagOptions.theme ?? ''" [isRemovable]="tagOptions.isRemovable ?? false"
                [customClass]="tagOptions.customClass ?? ''" [size]="tagOptions.size ?? ''"
                [isError]="tagOptions.isError ?? false" [isInverse]="tagOptions.isInverse ?? false"
                (removed)="removeFss(item.itemCode ?? '')"
                >
                {{ item?.label }}
            </dds-tag>
            </div>
        </div>

        <!-- Designated Industry -->
        <ng-container *ngFor="let fss of selectedFSS">
            <span class="eclipse-small-header-grey">
                <span *ngIf="(selectedIndustries[fss.itemCode?? '']?.length ?? 0) == 0"
                class="icon-required-yellow position-alignment"></span>
                {{ fss.label }} Industry:</span>
            <div class="multiselect-div margin-top-xxs">
                <div class="col-md-5 padding-right-xs">
                    <dds-multi-select class="box-size"
                        [displayTickAllBtn]="industryMultiSelectOptions.displayTickAllBtn ?? false"
                        [isResponsive]="industryMultiSelectOptions.isResponsive ?? false"
                        [isRequired]="industryMultiSelectOptions.isRequired ?? false"
                        [label]="industryMultiSelectOptions.label ?? 'Select Designated Industry'"
                        [size]="industryMultiSelectOptions.size ?? ''"
                        [placeholder]="industryMultiSelectOptions.placeholder ?? ''"
                        [type]="industryMultiSelectOptions.type ?? ''"
                        [controlType]="industryMultiSelectOptions.controlType ?? ''"
                        [theme]="industryMultiSelectOptions.theme ?? ''"
                        [isInverse]="industryMultiSelectOptions.isInverse ?? false" [list]="industryOptions[fss.itemCode ?? '']"
                        [value]="selectedIndustries[fss.itemCode ?? '']"
                        (selectionChange)="onIndustryChange($event, fss.itemCode ?? '')"
                        (valueChanged)="onIndustryChange($event, fss.itemCode ?? '')"></dds-multi-select>
                </div>
          
                <div *ngIf="(selectedIndustries[fss.itemCode ?? '']?.length ?? 0) > 0"
                    class="multiselectddl col-md-7">
                    <span class="comments-text-label eclipse-small-header-grey">Selected values:</span>
                    <dds-tag class="tag-width" *ngFor="let item of selectedIndustries[fss.itemCode ?? '']"
                    [theme]="tagOptions.theme ?? ''" [isRemovable]="tagOptions.isRemovable ?? false"
                    [customClass]="tagOptions.customClass ?? ''" [size]="tagOptions.size ?? ''"
                    [isError]="tagOptions.isError ?? false" [isInverse]="tagOptions.isInverse ?? false"
                    (removed)="removeIndustry(fss.itemCode ?? '', item.itemId ?? '')">
                    {{ item?.label }}
                </dds-tag>
                </div>
            </div>

            <!-- Designated Industry Sector -->
            <ng-container *ngFor="let ind of selectedIndustries[fss.itemCode ?? '']">
                <div class="industry-sector-group">
                    <span class="eclipse-small-header-grey">
                        <span *ngIf="(selectedSectors[ind.val]?.length ?? 0) == 0"
                        class="icon-required-yellow position-alignment"></span>
                        {{ ind.label }} Sector:</span>
                    <div class="multiselect-div margin-top-xxs">
                        <div class="col-md-5 padding-right-xs">
                            <dds-multi-select class="box-size"
                                [displayTickAllBtn]="sectorMultiSelectOptions.displayTickAllBtn ?? false"
                                [isResponsive]="sectorMultiSelectOptions.isResponsive ?? false"
                                [isRequired]="sectorMultiSelectOptions.isRequired ?? false"
                                [label]="sectorMultiSelectOptions.label ?? 'Select Designated Industry Sector'"
                                [size]="sectorMultiSelectOptions.size ?? ''"
                                [placeholder]="sectorMultiSelectOptions.placeholder ?? ''"
                                [type]="sectorMultiSelectOptions.type ?? ''"
                                [controlType]="sectorMultiSelectOptions.controlType ?? ''"
                                [theme]="sectorMultiSelectOptions.theme ?? ''"
                                [isInverse]="sectorMultiSelectOptions.isInverse ?? false"
                                [list]="sectorOptions[ind.val]" (selectionChange)="onSectorChange($event, ind.val, fss.itemCode ?? '')"
                                [value]="selectedSectors[ind.val]"
                                (valueChanged)="onSectorChange($event, ind.val, fss.itemCode ?? '')"></dds-multi-select>
                        </div>
                      
                        <div *ngIf="(selectedSectors[ind.val]?.length ?? 0) > 0"
                            class="multiselectddl col-md-7">
                            <span class="comments-text-label eclipse-small-header-grey">Selected values:</span>
                            <dds-tag class="tag-width" *ngFor="let item of selectedSectors[ind.val]"
                                    [theme]="tagOptions.theme ?? ''" [isRemovable]="tagOptions.isRemovable ?? false"
                                    [customClass]="tagOptions.customClass ?? ''" [size]="tagOptions.size ?? ''"
                                    [isError]="tagOptions.isError ?? false" [isInverse]="tagOptions.isInverse ?? false"
                                    (removed)="removeSector(ind.val, item.val)">
                                    {{ item?.label }}
                                </dds-tag>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>