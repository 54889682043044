import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EXTERNAL_COMMUNICATIONS_ROUTES } from './external-communications.states';
import { RouterModule } from '@angular/router';
import { InitiateExternalCommunicationComponent } from './initiate-external-communication/initiate-external-communication.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EclipseCommonModule } from '../common/common.module';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { ExternalCommunicationsComponent } from './external-communications/external-communications.component';
import { ExternalCommunicationSubmissionComponent } from './external-communications/external-communication-submission/external-communication-submission.component';
import { CommunicationAttributesComponent } from './external-communications/external-communication-submission/communication-attributes/communication-attributes.component';
import { ExternalCommunicationSubmissionDetailComponent } from './external-communications/external-communication-submission/external-communication-submission-detail/external-communication-submission-detail.component';
import { RushRequestPopupComponent } from './external-communications/external-communication-submission/external-communication-submission-detail/rush-request-popup/rush-request-popup.component';
import { RiskAssessmentComponent } from './external-communications/external-communication-submission/risk-assessment/risk-assessment.component';
import { ExternalCommunicationWorkflowSubmissionComponent } from './external-communications/external-communication-submission/external-communication-workflow-submission/external-communication-workflow-submission.component';
import { StatusStylePipeForExtComm } from './status-style-pipe/status-style.pipe';
import { ExternalCommunicationDocumentsComponent } from './external-communications/external-communication-submission/external-communication-documents/external-communication-documents.component';
import { ExternalCommunicationDocumentTableComponent } from './external-communications/external-communication-submission/external-communication-document-table/external-communication-document-table.component';
import { ExternalCommunicationDocumentUploadComponent } from './external-communications/external-communication-submission/external-communication-document-upload/external-communication-document-upload.component';
import { ExternalCommunicationDocumentLinkUploadComponent } from './external-communications/external-communication-submission/external-communication-document-link-upload/external-communication-document-link-upload.component';
import { ExternalCommunicationDocumentEditPopupComponent } from './external-communications/external-communication-submission/external-communication-document-edit-popup/external-communication-document-edit-popup.component';
import { ExtCommResetSubmissionStatusComponent } from './external-communications/external-communication-submission/ext-comm-reset-submission-status/ext-comm-reset-submission-status.component';
import { SelfReviewPopUpComponent } from './external-communications/external-communication-submission/self-review-pop-up/self-review-pop-up.component';
import { DiscontinuePopupComponent } from './external-communications/external-communication-submission/discontinue-popup/discontinue-popup.component';
import { ConfirmSubmissionPopupModalComponent } from './external-communications/confirm-submission-popup-modal/confirm-submission-popup-modal.component';
import { ExtCommDocSelectionPopupComponent } from './external-communications/external-communication-submission/ext-comm-doc-selection-popup/ext-comm-doc-selection-popup.component';
import { AdminCompletePopupComponent } from './external-communications/external-communication-submission/admin-complete-popup/admin-complete-popup.component';
import { EclipseHeaderModule } from '../eclipse-header/eclipse-header.module';
import { SuccessPageComponent } from './success-page/success-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ValidateRedirectComponent } from './validate-redirect/validate-redirect.component';
import { ExternalCommunicationExportPdfComponent } from './external-communications/external-communication-export-pdf/external-communication-export-pdf.component';

@NgModule({
  declarations: [
    InitiateExternalCommunicationComponent,
    ExternalCommunicationsComponent,
    ExternalCommunicationSubmissionComponent,
    CommunicationAttributesComponent,
    ExternalCommunicationSubmissionDetailComponent,
    RushRequestPopupComponent,
    RiskAssessmentComponent,
    ExternalCommunicationWorkflowSubmissionComponent,
    StatusStylePipeForExtComm,
    ExternalCommunicationDocumentsComponent,
    ExternalCommunicationDocumentTableComponent,
    ExternalCommunicationDocumentUploadComponent,
    ExternalCommunicationDocumentLinkUploadComponent,
    ExternalCommunicationDocumentEditPopupComponent,
    ExtCommResetSubmissionStatusComponent,
    SelfReviewPopUpComponent,
    DiscontinuePopupComponent,
    ConfirmSubmissionPopupModalComponent,
    ExtCommDocSelectionPopupComponent,
    AdminCompletePopupComponent,
    SuccessPageComponent,
    ErrorPageComponent,
    ValidateRedirectComponent,
    ExternalCommunicationExportPdfComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    EclipseCommonModule,
    ReactiveFormsModule,
    LibModule,
    RouterModule.forChild(EXTERNAL_COMMUNICATIONS_ROUTES),
    EclipseHeaderModule,
  ],
})
export class ExternalCommunicationsModule {}
