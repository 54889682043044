<div
  *ngIf="docMetaData.length > 0"
  class="table-with-data margin-bottom-m"
  [ngClass]="{
    'table-disabled':
      permissionObj['isGlobalEngSubmissionDetailsDocumentsTableEnable']
  }"
>
  <div class="d-flex w-100 div-headers">
    <table class="dds-data-table dds-data-table_selection">
      <thead class="dds-data-table__header position-top-0 header-style">
        <tr>
          <th
            *ngFor="let column of tableColumns"
            class="dds-data-table__header-cell"
          >
            <div class="dds-data-table__content">
              <span>{{ column.header || column.name }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="dds-data-table__body">
        <ng-container>
          <tr
            *ngFor="let row of docMetaData; index as i"
            class="dds-data-table__row"
          >
            <td class="dds-data-table__cell">
              <div class="d-flex align-items-center"></div>
            </td>

            <td class="dds-data-table__cell">
              <div class="d-flex align-items-center">
                <div class="w-100">
                  <span
                    [ddsTooltip]="row.documentTitle"
                    [tooltipInvokeType]="tooltipOptions.tooltipInvokeType ?? ''"
                    [tooltipType]="tooltipOptions.tooltipType ?? ''"
                    [tooltipSize]="tooltipOptions.tooltipSize ?? ''"
                    [tooltipPosition]="tooltipOptions.tooltipPosition ?? ''"
                    [tooltipIndent]="tooltipOptions.tooltipIndent ?? 0"
                    [tooltipHasBeak]="tooltipOptions.tooltipHasBeak ?? false"
                    [tooltipIsDisabled]="
                      tooltipOptions.tooltipIsDisabled ?? false
                    "
                    [tooltipIsOutsideClick]="
                      tooltipOptions.tooltipIsOutsideClick ?? false
                    "
                    [tooltipIsDynamic]="true"
                    [tooltipTheme]="tooltipOptions.tooltipTheme ?? ''"
                    [tooltipShowDelay]="tooltipOptions.tooltipShowDelay ?? 0"
                    [tooltipHideDelay]="tooltipOptions.tooltipHideDelay ?? 0"
                    ddsFocus
                  >
                    <div
                      class="d-flex"
                      *ngIf="row.submissionDocumentTypeId === 1"
                    >
                      <div class="margin-right-xxs">
                        <img
                          class="margin-right-xxs"
                          src="\assets\dds-icons\text-icon.svg"
                        />
                      </div>
                      <!-- TODO check download permission in if block and remove true value -->
                      <div
                        class="green-color long-title"
                        (click)="onDownload(row)"
                        *ngIf="true; else noDownload"
                      >
                        <u>
                          {{ row.documentTitle }}
                        </u>
                      </div>
                      <ng-template #noDownload>
                        <span class="long-title">
                          {{ row.documentTitle }}
                        </span>
                      </ng-template>
                    </div>
                    <div
                      class="green-color d-flex"
                      (click)="onOpenLink(row)"
                      *ngIf="row.submissionDocumentTypeId === 2"
                    >
                      <div>
                        <img
                          class="margin-right-xxs"
                          src="\assets\dds-icons\linkIcon.svg"
                        />
                      </div>
                      <u class="long-title">
                        {{ row.documentTitle }}
                      </u>
                    </div>
                  </span>
                </div>
              </div>
            </td>
            <td class="dds-data-table__cell p-0">
              <div
                *ngIf="row.comments"
                [ddsTooltip]="row.comments"
                class="icon-s background-no-repeat msg-style"
              ></div>
            </td>
            <td class="dds-data-table__cell col-md-2">
              <span>{{ row.documentType }}</span>
            </td>
            <td class="dds-data-table__cell col-md-2">
              <span *ngIf="row.documentStatus" class="padding-status">{{
                row.documentStatus
              }}</span>
            </td>
            <td class="dds-data-table__cell">
              <span>{{ row.createdDate }}</span>
            </td>
            <td class="dds-data-table__cell">
              <span>{{ row.createdBy }}</span>
            </td>
            <td class="dds-data-table__cell">
              <div class="d-flex justify-content-between">
                <div
                  [ddsTooltip]="'Edit'"
                  class="icon-s background-no-repeat action-items"
                  (click)="onEdit(row)"
                >
                  <img src="\assets\dds-icons\pencil-icon.svg" />
                </div>
                <div
                  [ddsTooltip]="'Delete'"
                  class="icon-s background-no-repeat action-items"
                  (click)="onDelete(row)"
                >
                  <img src="\assets\images\dds-icons\dds-bin-icon.svg" />
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
