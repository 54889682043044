import { Component } from '@angular/core';

@Component({
  selector: 'app-top-navigational-panel',
  templateUrl: './top-navigational-panel.component.html',
  styleUrl: './top-navigational-panel.component.less'
})
export class TopNavigationalPanelComponent {

}
