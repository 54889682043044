<div class="dds-modal-overlay" (click)="closeModal()"></div>

<div class="dds-modal">
  <div class="dds-modal__header">
    <span class="dds-modal__title">{{popupTitle}}</span>
    <dds-button size="sm" icon="dds-icon_close" kind="silent" theme="dark" ariaLabel="Close modal"
                class="dds-modal__close" [isInverse]="isInverse" (clicked)="closeModal()"></dds-button>
  </div>

  <div class="dds-modal__body">
    <div class="row margin-bottom-m">
      <div class="col-6 align-content-middle">
        <div class="eclipse-content-grey">Select period:</div>
        <div>
          <dds-checkbox *ngFor="let data of periodList; let index = index;"
          class="dds-filter__section dds-filter__section_checkbox custom-checkbox"          
          [value]="data['isChecked']"
          [ngClass]="{'chk-box-partial-selected': data['isIndeterminate']}"
          [label]="data['name']" [theme]="data['theme']"
          (stateChanged)="periodCheckBoxStateChanged($event, data)"></dds-checkbox>
        </div>
      </div>
      <div class="col-6">
        <div class="eclipse-content-grey">Status:</div>
        <div>
          <dds-checkbox *ngFor="let data of statusList; let index = index;"
                        class="dds-filter__section dds-filter__section_checkbox custom-checkbox"
                        [value]="data['isChecked']"
                        [ngClass]="{'chk-box-partial-selected': data['isIndeterminate']}"                       
                        [label]="data['name']" [theme]="data['theme']"
                        (stateChanged)="statusCheckBoxStateChanged($event, data)"></dds-checkbox>
        </div>
      </div>
    </div>
   </div>
  <div class="top-border reset-padding"></div>
  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content">
      <dds-button class="dds-modal__footer-item" (clicked)="onDownload()"
                  (keyboardClicked)="onDownload()" [disabled]="isExportButtonDisabled">Export</dds-button>
      <dds-button class="dds-modal__footer-item" kind="secondaryLoud" [isInverse]="isInverse"
                  (clicked)="closeModal()" (keyboardClicked)="closeModal()">Cancel</dds-button>
    </div>
  </div>
</div>
