import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Watchlist_ROUTES } from '../watchlist/watchlist.states'
import { WATCHLIST_PAGE_COMPONENTS } from '.';
import { FormsModule } from '@angular/forms';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { RouterModule } from '@angular/router';
import { EclipseCommonModule } from '../common/common.module';
import { ShowMoreComponent } from './show-more/show-more.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { WatchlistAuditComponent } from './watchlist-audit/watchlist-audit.component';
import { EclipseHeaderModule } from '../eclipse-header/eclipse-header.module';

@NgModule({
  declarations: [
    ShowMoreComponent,
    WATCHLIST_PAGE_COMPONENTS,
    WatchlistAuditComponent
  ],
  imports: [
    CommonModule,
    LibModule,
    FormsModule,
    EclipseCommonModule,
    EclipseHeaderModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    RouterModule.forChild(Watchlist_ROUTES),
  ]
})
export class WatchlistModule { }
