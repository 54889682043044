<div *ngIf="hasLinkedOpportunities">
  <dds-accordion
    [disabled]="accordionOptions.disabled"
    [isMulti]="accordionOptions.isMulti"
    [isOpen]="accordionOptions.isOpen"
    [isInverse]="accordionOptions.isInverse"
    [customClass]="accordionOptions.customClass"
    [size]="accordionOptions.size"
  >
    <dds-accordion-item>
      <span header class="accordion-title">Linked opportunities</span>
      <span content>
        <div class="">
          <table class="dds-data-table">
            <thead class="dds-data-table__header">
              <tr>
                <th
                  *ngFor="let column of tableHeader"
                  class="dds-data-table__header-cell"
                  [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
                >
                  <div class="dds-data-table__content">
                    <span>{{ column.header || column.name }}</span>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody class="dds-data-table__body">
              <tr
                *ngIf="
                  !hasLinkedOpportunities || linkedOpportunities.length === 0;
                  else displayRows
                "
              >
                No data available
              </tr>
              <ng-template #displayRows>
                <tr
                  *ngFor="let row of linkedOpportunities; index as index"
                  class="dds-data-table__row eclipse-content"
                >
                  <td class="dds-data-table__cell accepted text-underline">
                    {{ row.submissionId }}
                  </td>
                  <td class="dds-data-table__cell">
                    {{ row.jupiterOpportunityID }}
                  </td>
                  <td class="dds-data-table__cell">
                    {{ row.jupiterOpportunityStage }}
                  </td>
                  <td
                    class="dds-data-table__cell long-text"
                    [ddsTooltip]="row.marketOfferingSolution"
                    [tooltipInvokeType]="options.tooltipInvokeType"
                    [tooltipType]="options.tooltipType"
                    [tooltipSize]="options.tooltipSize"
                    [tooltipPosition]="options.tooltipPosition"
                    [tooltipIndent]="options.tooltipIndent"
                    [tooltipHasBeak]="options.tooltipHasBeak"
                    [tooltipIsDisabled]="options.tooltipIsDisabled"
                    [tooltipIsOutsideClick]="options.tooltipIsOutsideClick"
                    [tooltipIsDynamic]="options.tooltipIsDynamic"
                    [tooltipTheme]="options.tooltipTheme"
                    [tooltipShowDelay]="options.tooltipShowDelay"
                    [tooltipHideDelay]="options.tooltipHideDelay"
                    ddsFocus
                  >
                    {{ row.marketOfferingSolution }}
                  </td>
                  <td
                    class="dds-data-table__cell long-text"
                    [ddsTooltip]="row.marketOfferingSubSolution"
                    [tooltipInvokeType]="options.tooltipInvokeType"
                    [tooltipType]="options.tooltipType"
                    [tooltipSize]="options.tooltipSize"
                    [tooltipPosition]="options.tooltipPosition"
                    [tooltipIndent]="options.tooltipIndent"
                    [tooltipHasBeak]="options.tooltipHasBeak"
                    [tooltipIsDisabled]="options.tooltipIsDisabled"
                    [tooltipIsOutsideClick]="options.tooltipIsOutsideClick"
                    [tooltipIsDynamic]="options.tooltipIsDynamic"
                    [tooltipTheme]="options.tooltipTheme"
                    [tooltipShowDelay]="options.tooltipShowDelay"
                    [tooltipHideDelay]="options.tooltipHideDelay"
                    ddsFocus
                  >
                    {{ row.marketOfferingSubSolution }}
                  </td>
                  <td class="dds-data-table__cell">
                    {{
                      row.jupiterOpportunityEstimatedValue === ""
                        ? "-"
                        : row.jupiterOpportunityEstimatedValue
                    }}
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </span>
    </dds-accordion-item>
  </dds-accordion>
</div>
