import { Component } from '@angular/core';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrl: './opportunity.component.less'
})
export class OpportunityComponent {

}
