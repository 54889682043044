<div class="dds-modal-overlay" (click)="close()"></div>

<div ddsResize class="mars-access-modal dds-modal">
  <div class="dds-modal__header">
    <span class="dds-modal__title">MARS NCA Access</span>
    <dds-button size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      (clicked)="close()"></dds-button>
  </div>
  <div class="dds-modal__body">
    <p>Enable your ability to initiate MARS new client acceptance</p>
    <dds-toggle
      [(ngModel)]="isMarsAccessEnabled"
      label="MARS NCA Access"
    ></dds-toggle>
  </div>
  <div class="dds-modal__footer">
    <dds-button
      class="dds-modal__footer-item"
      kind="secondaryLoud"
      (click)="close()"
    >Cancel</dds-button>
    <dds-button
      class="dds-modal__footer-item"
      [disabled]="isSaveDisabled()"
      (click)="save()"
    >Save</dds-button>
  </div>
</div>