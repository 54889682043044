<div *ngIf="docMetaData.length > 0" class="table-with-data">
  <div class="toggle-data">
    <span>
      <dds-toggle-group
        [title]="groupOptions.title"
        [isInverse]="groupOptions.isInverse"
        [isRequired]="groupOptions.isRequired"
      >

        <dds-toggle
          [(ngModel)]="toggleOpen"
          [disabled]="options.disabled"
          [isProcessingStatusActive]="options.isProcessingStatusActive"
          [label]="options.label"
          [ariaLabel]="options.ariaLabel"
          [theme]="options.theme"
          [isError]="options.isError"
          [isRequired]="options.isRequired"
          [errorState]="options.errorState"
          [customClass]="options.customClass"
          (stateChanged)="stateChanged($event)"
          (processingStateActivated)="updateServerValue($event)"
          #toggle
        ></dds-toggle>
      </dds-toggle-group>
    </span>
  </div>

  <div class="d-flex w-100 div-headers">
    <table class="dds-data-table dds-data-table_selection">
      <thead class="dds-data-table__header position-top-0 header-style">
        <tr>
          <th
            *ngFor="let column of tableColumns"
            class="dds-data-table__header-cell"
          >
            <div class="dds-data-table__content">
              <span>{{ column.header || column.name }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="dds-data-table__body">
        <ng-container>
          <tr
            *ngFor="let row of docToShow; index as i"
            class="dds-data-table__row"
          >
            <td class="dds-data-table__cell">
              <div class="d-flex align-items-center">
                <div class="w-100">
                  <span
                    [ddsTooltip]="row.documentTitle"
                    [tooltipInvokeType]="tooltipOptions.tooltipInvokeType ?? ''"
                    [tooltipType]="tooltipOptions.tooltipType ?? ''"
                    [tooltipSize]="tooltipOptions.tooltipSize ?? ''"
                    [tooltipPosition]="tooltipOptions.tooltipPosition ?? ''"
                    [tooltipIndent]="tooltipOptions.tooltipIndent ?? 0"
                    [tooltipHasBeak]="tooltipOptions.tooltipHasBeak ?? false"
                    [tooltipIsDisabled]="
                      tooltipOptions.tooltipIsDisabled ?? false
                    "
                    [tooltipIsOutsideClick]="
                      tooltipOptions.tooltipIsOutsideClick ?? false
                    "
                    [tooltipIsDynamic]="true"
                    [tooltipTheme]="tooltipOptions.tooltipTheme ?? ''"
                    [tooltipShowDelay]="tooltipOptions.tooltipShowDelay ?? 0"
                    [tooltipHideDelay]="tooltipOptions.tooltipHideDelay ?? 0"
                    ddsFocus
                  >
                    <div
                      class="d-flex"
                      *ngIf="row.submissionDocumentTypeId === 1"
                    >
                      <div class="margin-right-xxs">
                        <img
                          class="margin-right-xxs"
                          src="\assets\dds-icons\text-icon.svg"
                        />
                      </div>
                      <div
                        class="green-color long-title"
                        (click)="onDownload(row)"
                        *ngIf="
                          isDownloadVisible(row.submissionDocumentTypeId);
                          else noDownload
                        "
                      >
                        <u>
                          {{ row.documentTitle }}
                        </u>
                      </div>
                      <ng-template #noDownload>
                        <span class="long-title">
                          {{ row.documentTitle }} 
                        </span>
                      </ng-template>
                    </div>
                    <div
                      class="green-color d-flex"
                      (click)="openLink(row.documentUrl)"
                      *ngIf="row.submissionDocumentTypeId === 2"
                    >
                      <div>
                        <img
                          class="margin-right-xxs"
                          src="\assets\dds-icons\linkIcon.svg"
                        />
                      </div>
                      <u class="long-title">
                        {{ row.documentTitle }}
                      </u>
                    </div>
                  </span>
                </div>
              </div>
            </td>
            <td class="dds-data-table__cell p-0">
              <div
                *ngIf="row.comments"
                [ddsTooltip]="row.comments"
                class="icon-s background-no-repeat msg-style"
              ></div>
            </td>
            <td class="dds-data-table__cell documnet-type">
              <span>{{ row.documentType }}</span>
            </td>
            <td class="dds-data-table__cell col-md-1">
              <span
                *ngIf="row.documentStatus"
                class="padding-status"
                [style.color]="
                  transformStatus(row.documentStatus) | statusStyle : 'color'
                "
                [style.backgroundColor]="
                  transformStatus(row.documentStatus)
                    | statusStyle : 'backgroundColor'
                "
                >{{ row.documentStatus }}</span
              >
            </td>
            <td class="dds-data-table__cell created-date">
              <span>{{ row.createdDate }}</span>
            </td>
            <td class="dds-data-table__cell">
              <span>{{ row.createdBy }}</span>
            </td>
            <td class="dds-data-table__cell">
              <div class="d-flex justify-content-between">
                <div
                  *ngIf="isDeleteEditBtnVisible()"
                  [ddsTooltip]="'Edit'"
                  class="icon-s background-no-repeat action-items"
                  (click)="onEdit(row)"
                >
                  <img src="\assets\dds-icons\pencil-icon.svg" />
                </div>
                <div
                  *ngIf="isDeleteEditBtnVisible()"
                  [ddsTooltip]="'Delete'"
                  class="icon-s background-no-repeat action-items"
                  (click)="onDelete(row)"
                >
                  <img src="\assets\images\dds-icons\dds-bin-icon.svg" />
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
