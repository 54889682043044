<div class="my-2">
  <dds-button
    [theme]="options.theme ?? ''"
    [kind]="options.kind ?? ''"
    [size]="options.size ?? ''"
    [width]="options.width ?? ''"
    [icon]="options.icon ?? ''"
    [isIconLeft]="options.isIconLeft ?? false"
    [role]="options.role ?? ''"
    [isInverse]="options.isInverse ?? false"
    [isColorBg]="options.isColorBg ?? false"
    [isLoading]="options.isLoading ?? false"
    [ariaLabel]="options.ariaLabel ?? ''"
    [customClass]="options.customClass ?? ''"
    [disabled]="options.disabled ?? false"
    [ngStyle]="{ margin: '2.3rem 0' }"
    (clicked)="click($event)"
    >Show recently viewed submissions</dds-button
  >
</div>
