import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EclipseHeaderModule } from './eclipse-header/eclipse-header.module';
import { InitiationModule } from './initiation/initiation.module';
import { ProfileModule } from './profile/profile.module';
import { MsalModule, MsalInterceptor, MsalService, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MsalRedirectComponent, MsalGuard } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { TokenService } from './http/token.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from '../environment/environment';
import { StartPageModule } from './start-page/start-page.module';
import { EclipseCommonModule } from './common/common.module';
import { DocumentUploadPopupComponent } from './document-upload/document-upload-popup/document-upload-popup.component';
import { AuthInterceptor } from './Interceptor/auth.interceptor';
import { IntakeModule } from './intake/intake.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { DocumentMetaDataComponent } from './document-upload/document-meta-data/document-meta-data.component';
import { WatchlistModule } from './watchlist/watchlist.module';
import { DocumentMetaDataEditComponent } from './document-upload/document-meta-data-edit/document-meta-data-edit.component'
import { StartNcaprocessDialogComponentComponent } from './start-ncaprocess-dialog-component/start-ncaprocess-dialog-component.component'

@NgModule({
  declarations: [
    AppComponent,
    DocumentUploadPopupComponent,
    DocumentMetaDataEditComponent,
    StartNcaprocessDialogComponentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    EclipseHeaderModule,
    InitiationModule,
    ProfileModule,
    StartPageModule,
    EclipseCommonModule,
    NgbModule,
    IntakeModule,
    LibModule,
    WatchlistModule,
    HttpClientModule, // Ensure HttpClientModule is imported
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: environment.msalConfig.auth.clientId,
        authority: environment.msalConfig.auth.authority,
        redirectUri: environment.msalConfig.auth.redirectUri
      },
      cache: {
        cacheLocation: 'localStorage'
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map<string, string[]>(),
    })
  ],
  providers: [
    MsalService,
    TokenService,
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
