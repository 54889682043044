import { Component, EventEmitter, input, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { RoleAssignedIndustryData, RoleAssignmentGridData, RoleAssignmentUpsertModel } from '../../../common/models/admin/roleassignment.model';
import { AdminModuleService } from '../../admin-services/admin-module.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { CheckboxOptions } from '@usitsdasdesign/dds-ng/checkbox';
import { ErrorState, Size, Themes } from '@usitsdasdesign/dds-ng/shared';
import { ToggleOptions } from '@usitsdasdesign/dds-ng/toggle';
import { PeoplePickerComponent } from '../../../common/components/people-picker/people-picker.component';
import { MultiSelectItem, MultiSelectOptions, SelectControlTypes, SelectType } from '@usitsdasdesign/dds-ng/multi-select';
import { FormControl } from '@angular/forms';
import { TagOptions } from '@usitsdasdesign/dds-ng/tags';
import { ClientStatusWebapiService } from '../../../http/client/client-status-webapi.service';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { RoleEnum, RoleIdMapper } from '../../../intake/constants/Role.enum';

@Component({
  selector: 'app-admin-upsert-dialog',
  templateUrl: './admin-upsert-dialog.component.html',
  styleUrl: './admin-upsert-dialog.component.less'
})
export class AdminUpsertDialogComponent implements OnInit {
  @Input() roleModel: RoleAssignmentUpsertModel = {} as RoleAssignmentUpsertModel;
  @Input() clientDictionary: any = <any>{};
  @Input() roleAssignmentGridData: RoleAssignmentGridData[] = [];
  @Output() confirmed = new EventEmitter<RoleAssignmentUpsertModel>();
  @ViewChild(PeoplePickerComponent) peoplePickerComponent!: PeoplePickerComponent;

  dialogRoleModel: any;
  selectedEmployee: any;
  actionType: string = 'Add';
  isNCAManagerCheckVisible: boolean = false;

  checkboxoptions: CheckboxOptions = {
    label: 'NCA Manager',
    isIndeterminate: false,
    isRequired: false,
    ariaLabel: '',
    customClass: ''
  };

  toggleOptions: ToggleOptions = {
    theme: Themes.green,
    label: ''
  };

  // #region Mulitselect options
  multiSelectOptions: MultiSelectOptions = {
    label: '',
    size: Size.lg,
    description: '',
    placeholder: 'Select',
    type: SelectType.counter,
    controlType: SelectControlTypes.checkbox,
    theme: 'green',
    isInverse: false,
    disabled: false,
    isResponsive: false,
    isError: false,
    isRequired: false,
    displayTickAllBtn: false,
    errorMessage: '',
    errorState: ErrorState.default,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: "",
    stickerIsDisabled: false,
    stickerPosition: "bottom-left",
    stickerIndent: 0,
    stickerCustomClass: "",
    counterTypeText: '',
  };

  multiOptions: TagOptions = {
    theme: Themes.dark,
    isRemovable: true,
    disabled: true,
    isError: false,
    isInverse: false,
    size: Size.lg,
    customClass: ''
  };

  isEditMode: boolean = false;

  // #endregion Mulitselect options
  industrySectorItems: MultiSelectItem[] = [];

  showApplicableFss: boolean = true;
  showConsultingIndustry: boolean = false;
  showMarsAuditIndustry: boolean = false;
  showMarsConsultingIndustry: boolean = false;
  showMarsTaxIndustry: boolean = false;

  applicableFssItems: MultiSelectItem[] = [];
  applicableFssItemsSelectedValues: any = [];
  msiApplicableFssItemsFormCtrl = new FormControl<MultiSelectItem[]>({ value: [], disabled: false });

  marsIndustryItems: MultiSelectItem[] = [];
  optionsOpportunityIndustryItems: MultiSelectItem[] = [];
  optionsOpportunityIndustrySectorItems: MultiSelectItem[] = [];

  uniqueConsultingIndustryItems: MultiSelectItem[] = [];
  uniqueMarsAuditIndustryItems: MultiSelectItem[] = [];
  uniqueMarsConsultingIndustryItems: MultiSelectItem[] = [];
  uniqueMarsTaxIndustryItems: MultiSelectItem[] = [];

  msiMarsConsultingIndustryItemsFormCtrl = new FormControl<MultiSelectItem[]>({ value: [], disabled: false });
  msiMarsTaxIndustryItemsFormCtrl = new FormControl<MultiSelectItem[]>({ value: [], disabled: false });
  msiMarsAuditIndustryItemsFormCtrl = new FormControl<MultiSelectItem[]>({ value: [], disabled: false });
  consultingIndustryItemsFormCtrl = new FormControl<MultiSelectItem[]>({ value: [], disabled: false });

  consultingIndustrySelectedValues: any = [];
  marsAuditIndustrySelectedValues: any = [];
  marsConsultingIndustrySelectedValues: any = [];
  marsTaxIndustrySelectedValues: any = [];

  
  excludedIndustrySectorCodes: {[key: string]: string[]} = {};
  selectedIndustrySectorCodes: {[key: string]: string[]} = {};

  textareaOptions: TextareaOptions = {
    placeholder: 'Enter text',
    minHeight: 10,
    maxLength: 1000,
    size: Size.md,
    maxLengthPosition: 'bottom-right',
  };

     
  selectedIndustryCodes: string[] = [];
  selectedSectorCodes: any = {} as any;
  selectedFssCodes: string[] = [];

  constructor(
    private readonly modalService: ModalService,
    private adminModuleService: AdminModuleService,
    private progressindicator: ProgressIndicatorService,
    private clientstatuswebapiservice: ClientStatusWebapiService
  ) { }

  public ngOnInit() {
    this.progressindicator.show();
    this.dialogRoleModel = JSON.parse(JSON.stringify(this.roleModel));
    if (!this.dialogRoleModel.roleData) {
      this.dialogRoleModel.roleData = {
        isActive:
          this.roleModel.roleId ===
            RoleIdMapper[RoleEnum.DELIVERY_CENTER_RISK_MANAGER] ||
          this.roleModel.roleId === RoleIdMapper[RoleEnum.GLOBAL_ENGAGEMENT_EFA]
            ? true
            : false,
      } as RoleAssignmentGridData;
    } else {
      this.actionType = 'Save';
    }
    this.isEditMode = this.actionType === 'Save';
    this.dialogRoleModel.roleData.roleId = this.dialogRoleModel.roleId ?? 0;
    this.isNCAManagerCheckVisible = this.dialogRoleModel.roleName == 'NCA specialist';

    if (!this.selectedEmployee) {
      this.selectedEmployee = {} as any;
    }
    this.selectedEmployee.empID = this.dialogRoleModel.roleData.empId;
    this.selectedEmployee.outlookDisplayName = this.dialogRoleModel.roleData.outlookDisplayName;

    const roleId = this.dialogRoleModel.roleData.roleId;

    if (
      this.dialogRoleModel.roleData.roleId === 523 /*MARS QRM*/ ||
      this.dialogRoleModel.roleData.roleId === 675 /*Watchlist Admin*/ ||
      this.dialogRoleModel.roleData.roleId ===
        RoleIdMapper[
          RoleEnum.DELIVERY_CENTER_RISK_MANAGER
        ] /*Delivery Center Risk Manager*/ ||
      this.dialogRoleModel.roleData.roleId ===
        RoleIdMapper[RoleEnum.GLOBAL_ENGAGEMENT_EFA] /*Global Engagement EFA*/
    ) {
      this.showApplicableFss = false;
    }
    
    const optionsApplicableFSS = this.clientDictionary['optionsApplicableFSS']?.resultData || [];

    this.applicableFssItems = roleId === 651 /*NCA specialist*/
      ? optionsApplicableFSS.filter((item: any) => item.itemCode === '1_DC' || item.itemCode === '2_Mars')
      : roleId === 686 /*Mars Industry Leader*/
        ? optionsApplicableFSS.filter((item: any) => item.itemCode === '2_MarsAud' || item.itemCode === '2_MarsCons' || item.itemCode === '2_MarsTax')
        : roleId === 536 /*Sector Leader*/
          ? optionsApplicableFSS.filter((item: any) => item.itemCode === '1_DC' || item.itemCode === '2_MarsCons' || item.itemCode === '2_MarsAud' || item.itemCode === '2_MarsTax')
          : optionsApplicableFSS;

    this.marsIndustryItems =
      this.clientDictionary['optionsMarsIndustry']?.resultData || [];

    this.optionsOpportunityIndustryItems =
      this.clientDictionary['optionsOpportunityIndustry']?.resultData || [];

    this.industrySectorItems =
      this.clientDictionary['optionsOpportunityIndustrySector']
        .resultData || [];

    if (this.dialogRoleModel.roleData.roleId === 651 /*NCA Specialist*/ || this.dialogRoleModel.roleData.roleId === 536 /*Sector leader*/
      || this.dialogRoleModel.roleData.roleId === 686 /*MARS Industry leader*/) {
      this.adminModuleService.getAllAssignedIndustries(this.dialogRoleModel.roleData.roleId, this.dialogRoleModel.roleData.empId).subscribe((data: RoleAssignedIndustryData[]) => {

        var consulting: any[] = [];
        var marsConsulting: any[] = [];
        var marsTax: any[] = [];
        var marsAudit: any[] = [];
        if (data.length) {
          consulting = data[0].dC_Industry_Sector
            .filter(item => /\d+(_[A-Za-z]+)?$/.test(item))
            .map(item => item.match(/^(\d+)_?/)?.[1])
            .filter((number): number is string => number !== undefined);

          marsConsulting = data[0].marsConsulting_Industry
            .filter(item => /\d+(_[A-Za-z]+)?$/.test(item))
            .map(item => item.match(/^(\d+)_?/)?.[1])
            .filter((number): number is string => number !== undefined);

          marsTax = data[0].marsTax_Industry
            .filter(item => /\d+(_[A-Za-z]+)?$/.test(item))
            .map(item => item.match(/^(\d+)_?/)?.[1])
            .filter((number): number is string => number !== undefined);

          marsAudit = data[0].marsAudit_Industry
            .filter(item => /\d+(_[A-Za-z]+)?$/.test(item))
            .map(item => item.match(/^(\d+)_?/)?.[1])
            .filter((number): number is string => number !== undefined);
        }
        if(this.dialogRoleModel.roleData.roleId === 536 /*Sector Leader*/){
          this.marsIndustryItems = this.optionsOpportunityIndustryItems;
            this.excludedIndustrySectorCodes = {
            '1_DC': data[0].dC_Industry_Sector,
            '2_MarsAud': data[0].marsAudit_Industry_Sector,
            '2_MarsCons': data[0].marsConsulting_Industry_Sector,
            '2_MarsTax': data[0].marsTax_Industry_Sector,
            };

            this.selectedIndustrySectorCodes = {
              '1_DC': this.dialogRoleModel.roleData.dC_Industry_Sector,
              '2_MarsAud': this.dialogRoleModel.roleData.marsAudit_Industry_Sector,
              '2_MarsCons': this.dialogRoleModel.roleData.marsConsulting_Industry_Sector,
              '2_MarsTax': this.dialogRoleModel.roleData.marsTax_Industry_Sector,
            };
        }
        this.uniqueMarsConsultingIndustryItems = this.marsIndustryItems.filter((item: any) => !marsConsulting.some(mc => item.itemId == mc));
        this.uniqueMarsTaxIndustryItems = this.marsIndustryItems.filter((item: any) => !marsTax.some(mt => item.itemId == mt)).map(item => ({ ...item, label: item.label + ' ' }));  // this is done due to multiselect control built-in issue for selected items
        this.uniqueMarsAuditIndustryItems = this.marsIndustryItems.filter((item: any) => !marsAudit.some(ma => item.itemId == ma)).map(item => ({ ...item, label: item.label + '  ' }));
        this.uniqueConsultingIndustryItems = this.optionsOpportunityIndustryItems.filter((item: any) => !consulting.some(c => item.itemId == c)).map(item => ({ ...item, label: item.label + '  ' }));
        
        setTimeout(() => {
          this.populateDropdown();
          this.progressindicator.hide();
        }, 500);

      });
    }
    else {
      this.progressindicator.hide();
    }
  }

  onUserSelected(event: any) {
    this.selectedEmployee = event;
    this.dialogRoleModel.roleData.empId = event.reviewerId;
    this.dialogRoleModel.roleData.outlookDisplayName = event.displayName;
  }

  clearUserSearch() {
    this.dialogRoleModel.roleData.empId = '';
    if (this.peoplePickerComponent)
      this.peoplePickerComponent.resetUser();
  }

  public confirm() {
    document.body.classList.remove('no-scroll');

    if(this.dialogRoleModel.roleData.roleId === 536 /*Sector Leader*/){
      var fssWiseIndustrySectorData = this.getFssWiseIndustryANdSector();
      this.dialogRoleModel.roleData.applicableFss = this.selectedFssCodes;
      this.dialogRoleModel.roleData.dC_Industry_Sector = fssWiseIndustrySectorData.dcSectorCodes;
      this.dialogRoleModel.roleData.marsConsulting_Industry_Sector = fssWiseIndustrySectorData.marsConsSectorCodes;
      this.dialogRoleModel.roleData.marsTax_Industry_Sector = fssWiseIndustrySectorData.marsTaxSectorCodes;
      this.dialogRoleModel.roleData.marsAudit_Industry_Sector = fssWiseIndustrySectorData.marsAuditSectorCodes;
      this.dialogRoleModel.roleData.dC_Industry = fssWiseIndustrySectorData.dcIndutries;
      this.dialogRoleModel.roleData.marsConsulting_Industry = fssWiseIndustrySectorData.marsConsIndutries;
      this.dialogRoleModel.roleData.marsTax_Industry = fssWiseIndustrySectorData.marsTaxIndutries;
      this.dialogRoleModel.roleData.marsAudit_Industry = fssWiseIndustrySectorData.marsAuditIndutries;
    } else {
    this.dialogRoleModel.roleData.applicableFss = this.applicableFssItemsSelectedValues.map((item: any) => item.itemCode);
    const mc = this.marsConsultingIndustrySelectedValues.map((x: any) => (x.itemId));
    const mt = this.marsTaxIndustrySelectedValues.map((x: any) => (x.itemId));
    const ma = this.marsAuditIndustrySelectedValues.map((x: any) => (x.itemId));

    this.dialogRoleModel.roleData.consultIndustry = this.consultingIndustrySelectedValues.map((x: any) => x.itemId);
    this.dialogRoleModel.roleData.marsConsulting_Industry = this.industrySectorItems.filter(item => mc.includes(Number(item.label))).map(item => item['itemCode']);
    this.dialogRoleModel.roleData.marsTax_Industry = this.industrySectorItems.filter(item => mt.includes(Number(item.label))).map(item => item['itemCode']);
    this.dialogRoleModel.roleData.marsAudit_Industry = this.industrySectorItems.filter(item => ma.includes(Number(item.label))).map(item => item['itemCode']);


    /* Private Equity Leader (101) doesn't have sector so manually mapping if selected */
    if (mc.includes(101)) {
      this.dialogRoleModel.roleData.marsConsulting_Industry = [...this.dialogRoleModel.roleData.marsConsulting_Industry, '101'];
    }
    if (mt.includes(101)) {
      this.dialogRoleModel.roleData.marsTax_Industry = [...this.dialogRoleModel.roleData.marsTax_Industry, '101'];
    }
    if (ma.includes(101)) {
      this.dialogRoleModel.roleData.marsAudit_Industry = [...this.dialogRoleModel.roleData.marsAudit_Industry, '101'];
    }
  }
    this.confirmed.emit(this.dialogRoleModel);
    this.modalService.close();
  }

  public cancel() {
    document.body.classList.remove('no-scroll');

    this.dialogRoleModel = JSON.parse(JSON.stringify(this.roleModel));
    this.modalService.close();
  }

  public allRequiredFielsAreFilled(): boolean {
    if (this.dialogRoleModel.roleData.roleId === 651) { // NCA Specialist
      return this.dialogRoleModel.roleData.outlookDisplayName && (this.msiApplicableFssItemsFormCtrl?.value?.length ?? 0) > 0;
    }
    else if (this.showApplicableFss) { /* MARS Industry leader */
      if(this.dialogRoleModel.roleData.roleId === 536 /*Sector Leader*/){
        return this.selectedFssCodes.length>0 && this.selectedIndustryCodes.length>0 && this.selectedSectorCodes.length>0 && this.dialogRoleModel.roleData.outlookDisplayName ? true : false;
      }
      else {
      const noFssItems = this.msiApplicableFssItemsFormCtrl?.value?.length === 0;
      const noConsultingIndustry = this.showMarsConsultingIndustry && !this.marsConsultingIndustrySelectedValues.length;
      const noTaxIndustry = this.showMarsTaxIndustry && !this.marsTaxIndustrySelectedValues.length;
      const noAuditIndustry = this.showMarsAuditIndustry && !this.marsAuditIndustrySelectedValues.length;

      if (noFssItems || noConsultingIndustry || noTaxIndustry || noAuditIndustry) {
        return false;
      }
    }
    }
    return this.dialogRoleModel.roleData.outlookDisplayName ? true : false;
  }

  selectIsNcaManager(event: any) {
    this.dialogRoleModel.roleData.isNcaManager = event;
  }

  fssChange(event: any) {
    this.applicableFssItemsSelectedValues = event;
    if(this.dialogRoleModel.roleData.roleId !== 651 /*NCA Specialist*/){
    this.showIndustryDropDowns();
    }
  }

  removeFss(item: any): void {
    const current = this.msiApplicableFssItemsFormCtrl?.value as MultiSelectItem[];
    const updated: any = current.filter((i) => i !== item);
    this.msiApplicableFssItemsFormCtrl?.setValue(updated);

    this.applicableFssItemsSelectedValues = [];
    if ((this.msiApplicableFssItemsFormCtrl?.value?.length ?? 0) > 0) {
      this.msiApplicableFssItemsFormCtrl.value?.forEach((item: any) => {
        this.applicableFssItemsSelectedValues.push({ 'itemId': item.itemId, 'value': true, 'label': item.label, 'itemCode': item.itemCode });
      });
    }
    if(this.dialogRoleModel.roleData.roleId !== 651 /*NCA Specialist*/){
      this.showIndustryDropDowns();
      }
  }

  populateDropdown() {
    /* Populate ddl with unique value for Industry leaders*/
    if (this.showApplicableFss) {
      this.applicableFssItemsSelectedValues = [];
      if (this.dialogRoleModel.roleData.applicableFss != null && this.dialogRoleModel.roleData.applicableFss.length > 0) {

        this.dialogRoleModel.roleData.applicableFss?.forEach((item: any) => {
          if (item) {
            let filterData = this.applicableFssItems.filter((row: any) => row.itemCode == item);
            this.applicableFssItemsSelectedValues.push({ 'itemCode': item, 'label': filterData.length > 0 ? filterData[0].label : '' });
          }
        });
        this.msiApplicableFssItemsFormCtrl?.setValue(this.applicableFssItemsSelectedValues);
      }
    }
    else {
      this.applicableFssItemsSelectedValues = [];
    }
    if(this.dialogRoleModel.roleData.roleId !== 651 /*NCA Specialist*/){
      this.showIndustryDropDowns();
    }
  }

  showIndustryDropDowns() {
    this.showConsultingIndustry = this.applicableFssItemsSelectedValues.some((x: any) => x.itemCode == '1_DC');
    this.showMarsAuditIndustry = this.applicableFssItemsSelectedValues.some((x: any) => x.itemCode == '2_MarsAud');
    this.showMarsConsultingIndustry = this.applicableFssItemsSelectedValues.some((x: any) => x.itemCode == '2_MarsCons');
    this.showMarsTaxIndustry = this.applicableFssItemsSelectedValues.some((x: any) => x.itemCode == '2_MarsTax');
    this.populateIndustry();
  }

  populateIndustry() {
    if (this.showMarsConsultingIndustry) {
      this.marsConsultingIndustrySelectedValues = [];
      if (this.dialogRoleModel.roleData.marsConsulting_Industry != null && this.dialogRoleModel.roleData.marsConsulting_Industry.length > 0) {
        this.dialogRoleModel.roleData.marsConsulting_Industry?.forEach((item: any) => {
          if (item) {
            const id = item?.split('_')[0];
            if (this.marsConsultingIndustrySelectedValues.length == 0 || !this.marsConsultingIndustrySelectedValues.some((x: any) => x.itemId == id)) {
              let filterData = this.uniqueMarsConsultingIndustryItems.filter((row: any) => row.itemId == id);
              if (filterData.length) {
                this.marsConsultingIndustrySelectedValues.push({ 'itemId': Number(id), 'value': true, 'label': filterData.length > 0 ? filterData[0].label : '' });
              }
            }
          }
        });
      }
      this.msiMarsConsultingIndustryItemsFormCtrl?.setValue(this.marsConsultingIndustrySelectedValues);
    }
    else {
      this.marsConsultingIndustrySelectedValues = [];
    }

    if (this.showMarsTaxIndustry) {
      this.marsTaxIndustrySelectedValues = [];
      if (this.dialogRoleModel.roleData.marsTax_Industry != null && this.dialogRoleModel.roleData.marsTax_Industry.length > 0) {
        this.dialogRoleModel.roleData.marsTax_Industry?.forEach((item: any) => {
          if (item) {
            const id = item?.split('_')[0];
            if (this.marsTaxIndustrySelectedValues.length == 0 || !this.marsTaxIndustrySelectedValues.some((x: any) => x.itemId == id)) {
              let filterData = this.uniqueMarsTaxIndustryItems.filter((row: any) => row.itemId == id);
              if (filterData.length) {
                this.marsTaxIndustrySelectedValues.push({ 'itemId': Number(id), 'value': true, 'label': filterData.length > 0 ? filterData[0].label : '' });
              }
            }
          }
        });
      }
      else {
        this.marsTaxIndustrySelectedValues = [];
      }
      this.msiMarsTaxIndustryItemsFormCtrl?.setValue(this.marsTaxIndustrySelectedValues);
    }

    if (this.showMarsAuditIndustry) {
      this.marsAuditIndustrySelectedValues = [];
      if (this.dialogRoleModel.roleData.marsAudit_Industry != null && this.dialogRoleModel.roleData.marsAudit_Industry.length > 0) {
        this.dialogRoleModel.roleData.marsAudit_Industry?.forEach((item: any) => {
          if (item) {
            const id = item?.split('_')[0];
            if (this.marsAuditIndustrySelectedValues.length == 0 || !this.marsAuditIndustrySelectedValues.some((x: any) => x.itemId == id)) {
              let filterData = this.uniqueMarsAuditIndustryItems.filter((row: any) => row.itemId == id);
              if (filterData.length) {
                this.marsAuditIndustrySelectedValues.push({ 'itemId': Number(id), 'value': true, 'label': filterData.length > 0 ? filterData[0].label : '' });
              }
            }
          }
        });
      }
      this.msiMarsAuditIndustryItemsFormCtrl?.setValue(this.marsAuditIndustrySelectedValues);
    }
    else {
      this.marsAuditIndustrySelectedValues = [];
    }
  }

  marsConsultingIndustryChange() {
    this.marsConsultingIndustrySelectedValues = [];
    if ((this.msiMarsConsultingIndustryItemsFormCtrl?.value?.length ?? 0) > 0) {
      this.msiMarsConsultingIndustryItemsFormCtrl.value?.forEach((item: any) => {
        this.marsConsultingIndustrySelectedValues.push({ 'itemId': item.itemId, 'value': true, 'label': item.label });
      });
    }
  }

  marsTaxIndustryChange() {
    this.marsTaxIndustrySelectedValues = [];
    if ((this.msiMarsTaxIndustryItemsFormCtrl?.value?.length ?? 0) > 0) {
      this.msiMarsTaxIndustryItemsFormCtrl.value?.forEach((item: any) => {
        this.marsTaxIndustrySelectedValues.push({ 'itemId': item.itemId, 'value': true, 'label': item.label });
      });
    }
  }

  marsMarsAuditIndustryChange() {
    this.marsAuditIndustrySelectedValues = [];
    if ((this.msiMarsAuditIndustryItemsFormCtrl?.value?.length ?? 0) > 0) {
      this.msiMarsAuditIndustryItemsFormCtrl.value?.forEach((item: any) => {
        this.marsAuditIndustrySelectedValues.push({ 'itemId': item.itemId, 'value': true, 'label': item.label });
      });
    }
  }

  consultingIndustryChange() {
    this.consultingIndustrySelectedValues = [];
    if ((this.consultingIndustryItemsFormCtrl?.value?.length ?? 0) > 0) {
      this.consultingIndustryItemsFormCtrl.value?.forEach((item: any) => {
        this.consultingIndustrySelectedValues.push({ 'itemId': item.itemId, 'value': true, 'label': item.label });
      });
    }
  }

  removeMarsConsultingIndustry(item: any): void {
    const current = this.msiMarsConsultingIndustryItemsFormCtrl?.value as MultiSelectItem[];
    const updated: any = current.filter((i) => i !== item);
    this.msiMarsConsultingIndustryItemsFormCtrl?.setValue(updated);
    this.marsConsultingIndustryChange();
  }

  removeMarsTaxIndustry(item: any): void {
    const current = this.msiMarsTaxIndustryItemsFormCtrl?.value as MultiSelectItem[];
    const updated: any = current.filter((i) => i !== item);
    this.msiMarsTaxIndustryItemsFormCtrl?.setValue(updated);
    this.marsTaxIndustryChange();
  }

  removeMarsAuditIndustry(item: any): void {
    const current = this.msiMarsAuditIndustryItemsFormCtrl?.value as MultiSelectItem[];
    const updated: any = current.filter((i) => i !== item);
    this.msiMarsAuditIndustryItemsFormCtrl?.setValue(updated);
    this.marsMarsAuditIndustryChange();
  }

  removeConsultingIndustry(item: any): void {
    const current = this.consultingIndustryItemsFormCtrl?.value as MultiSelectItem[];
    const updated: any = current.filter((i) => i !== item);
    this.consultingIndustryItemsFormCtrl?.setValue(updated);
    this.consultingIndustryChange();
  }

  // #region FSS Wise Industry and Sector
  selectedFssChange(event: any) {
    this.selectedFssCodes = event;
  }

  selectedIndustryChange(event: any) {
    this.selectedIndustryCodes = event; 
  }

  selectedIndustrySectorChange(event: any) {
    this.selectedSectorCodes = event;
  }

  private getFssWiseIndustryANdSector(): { dcIndutries: any; marsConsIndutries: any; marsTaxIndutries: any; marsAuditIndutries: any; dcSectorCodes: any; marsConsSectorCodes: any; marsTaxSectorCodes: any; marsAuditSectorCodes: any; } {
    const dcItem: any = Object.values(this.selectedSectorCodes).find((x: any) => x.fssCode === '1_DC');
    const dc = dcItem ? dcItem.industries : [];
    const marsConsItem: any = Object.values(this.selectedSectorCodes).find((x: any) => x.fssCode === '2_MarsCons');
    const marsCons = marsConsItem ? marsConsItem.industries : [];
    const marsTaxItem: any = Object.values(this.selectedSectorCodes).find((x: any) => x.fssCode === '2_MarsTax');
    const marsTax = marsTaxItem ? marsTaxItem.industries : [];
    const marsAuditItem: any = Object.values(this.selectedSectorCodes).find((x: any) => x.fssCode === '2_MarsAud');
    const marsAudit = marsAuditItem ? marsAuditItem.industries : [];

    const dcIndutries = dc.map((x: any) => x.industryCode);
    const marsConsIndutries = marsCons.map((x: any) => x.industryCode);
    const marsTaxIndutries = marsTax.map((x: any) => x.industryCode);
    const marsAuditIndutries = marsAudit.map((x: any) => x.industryCode);

    const dcSectorCodes = dc.map((x: { sectors: any[]; }) => x.sectors.map((sector: any) => sector))
      .reduce((acc: any[], val: any[]) => acc.concat(val), []);
    const marsConsSectorCodes = marsCons.map((x: { sectors: any[]; }) => x.sectors.map((sector: any) => sector))
      .reduce((acc: any[], val: any[]) => acc.concat(val), []);
    const marsTaxSectorCodes = marsTax.map((x: { sectors: any[]; }) => x.sectors.map((sector: any) => sector))
      .reduce((acc: any[], val: any[]) => acc.concat(val), []);
    const marsAuditSectorCodes = marsAudit.map((x: { sectors: any[]; }) => x.sectors.map((sector: any) => sector))
      .reduce((acc: any[], val: any[]) => acc.concat(val), []);

    return { dcIndutries, marsConsIndutries, marsTaxIndutries, marsAuditIndutries, dcSectorCodes, marsConsSectorCodes, marsTaxSectorCodes, marsAuditSectorCodes };
  }
}
