import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { AdminModuleService } from '../../../../admin-services/admin-module.service';
import {
  adminExtCommTrainingTableData,
  RequestBodyEditAndAddRecord,
  UserIdName,
} from '../../../../../common/models/admin-module.model';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  buttonOptions,
  inputOptions,
  ModalCloseButtonOptions,
  selectItems,
  selectOptions,
} from './edit-add-record-popup-extcomm-risk-manager-helper';
import { AdminConstant } from '../../../../constants/admin-constants';
import { InputOptions } from '@usitsdasdesign/dds-ng/input';
import {
  SelectItemOptions,
  SelectOptions,
} from '@usitsdasdesign/dds-ng/select';
import { Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonHelperService } from '../../../../../common/services/common-helper.service';

@Component({
  selector: 'app-edit-add-record-popup-extcomm-risk-manager',
  templateUrl: './edit-add-record-popup-extcomm-risk-manager.component.html',
  styleUrl: './edit-add-record-popup-extcomm-risk-manager.component.less',
})
export class EditAddRecordPopupExtCommRiskManagerComponent implements OnInit, OnDestroy {
  @Input() rowData!: adminExtCommTrainingTableData | null;
  @Input() showAddRecordPopup: boolean = false;
  @Input() isInverse: boolean = false;
  private readonly unsubscriber$: Subject<void> = new Subject<void>();

  inputOptions: InputOptions = inputOptions;
  inputComments: string = AdminConstant.EMPTY_SPACE;
  selectedActiveStatus: string = AdminConstant.DEFAULT_ACTIVE_STATUS;
  searchedName: string = AdminConstant.EMPTY_SPACE;
  ppmdId: string | null = null;

  modalCloseButtonOptions: ButtonOptions = ModalCloseButtonOptions;
  buttonOptions: ButtonOptions = buttonOptions;
  emptyString: string = AdminConstant.EMPTY_SPACE;
  selectItems: SelectItemOptions[] = selectItems;
  selectOptions: SelectOptions = selectOptions;

  constructor(
    private readonly modalService: ModalService,
    private readonly adminModuleService: AdminModuleService,
    private readonly commonHelperService: CommonHelperService
  ) {}

  ngOnInit(): void {
    if (this.rowData) {
      this.initializeEditRecordPopup(this.rowData);
    }
  }

  initializeEditRecordPopup(
    tableRecord: adminExtCommTrainingTableData | null
  ): void {
    this.inputComments = this.commonHelperService.trimPrefixEmptySpace(tableRecord?.comments) ?? AdminConstant.EMPTY_SPACE;
    this.selectedActiveStatus = tableRecord?.isActive
      ? AdminConstant.DEFAULT_ACTIVE_STATUS
      : AdminConstant.DEFAULT_INACTIVE_STATUS;
    this.ppmdId = tableRecord?.employeeId ? tableRecord.employeeId : null;
    this.searchedName = tableRecord?.name ?? AdminConstant.EMPTY_SPACE;
  }

  onSelected($event: UserIdName): void {
    this.ppmdId = $event?.reviewerId;
    this.searchedName = $event?.displayName;
  }

  removeSearchedPerson(): void {
    this.searchedName = AdminConstant.EMPTY_SPACE;
    this.ppmdId = null;
  }

  saveEditAndUpdateRecord(): void {
    const requestBody: RequestBodyEditAndAddRecord = {
      EmployeeId: this.ppmdId,
      Comments: this.commonHelperService.trimPrefixEmptySpace(this.inputComments),
      IsActive:
        this.selectedActiveStatus === AdminConstant.DEFAULT_ACTIVE_STATUS,
    };
    if (this.ppmdId !== this.rowData?.employeeId) {
      requestBody.OldEmployeeid = this.rowData?.employeeId;
    }
    this.saveAndEditRecord(requestBody);
  }

  saveAndEditRecord(requestBody: RequestBodyEditAndAddRecord): void {
    this.adminModuleService
      .saveAndUpdateExtCommRiskManagerData(requestBody)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.closeModal();
        },
        error: (err:HttpErrorResponse) => {
          console.error('Error saving record:', err);
        },
      });
  }

  closeModal(): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
