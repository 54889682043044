import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { CommonAuditTrailHeaderComponent } from './audit-trail/common-audit-trail/common-audit-trail-header/common-audit-trail-header.component';

export const AUDIT_TRAIL_ROUTES = [
  {
    path: '',
    children: [
      {
        path: '',
        component: AuditTrailComponent,
      },
      {
        path: 'details/:id',
        component: CommonAuditTrailHeaderComponent,
      },
    ],
  },
];
