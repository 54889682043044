import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { RoleEnum } from '../../intake/constants/Role.enum';
import { HttpClient } from '@angular/common/http';
import { UrlConstant } from '../../intake/constants/url.constant';
import { ReviewerRole } from '../../common/models/reviwer-role.model';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private readonly httpClient: HttpClient) {}
  readonly #submissionDiscontinueAllowedRolesSubject: BehaviorSubject<
    RoleEnum[]
  > = new BehaviorSubject<RoleEnum[]>([
    RoleEnum.SYSTEM_ADMIN,
    RoleEnum.RISK_MANAGER,
    RoleEnum.SUPPORTING_RISK_MANAGER,
    RoleEnum.SPECIALIZED_RISK_MANAGER,
    RoleEnum.ENGAGEMENT_TEAM_MEMBER,
    RoleEnum.LEAD_ENGAGEMENT_PARTNER,
  ]);
  submissionDiscontinueAllowedRoles$: Observable<RoleEnum[]> =
    this.#submissionDiscontinueAllowedRolesSubject.asObservable();

  readonly #currentUserRoleSubject: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>([]);
  currentUserRole$: Observable<string[]> =
    this.#currentUserRoleSubject.asObservable();

  getReviewerRolesDetails(): Observable<ReviewerRole> {
    return this.httpClient.get<ReviewerRole>(
      UrlConstant.GET_REVIEWERS_ROLES_PATH
    );
  }

  setCurrentUserRoles(roles: string[]): void {
    this.#currentUserRoleSubject.next(roles);
  }
}
