import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { DocumentLinkUploadComponent } from './document-upload/document-link-upload/document-link-upload.component';
import { DocumentEditPopupComponent } from './document-upload/document-edit-popup/document-edit-popup.component';
import { IntakeDocumentUploadComponents } from './document-upload/intake-document-upload/intake-document-upload.component';
import { DocumentTableComponent } from './document-upload/document-table/document-table.component';
import { ButtonOptions, UploaderOptions } from './document-settings';
import { IntakeOppertunitySubmissionStatus } from '../../../constants/intake_oppertunity_submission_status.enum';
import { IntakeDocumentTableModel } from '../../../../common/models/intake-document-table.model';
import { PermissionsObj } from '../../../../common/models/common-models';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { DocumentService } from '../../../../http/document/document.service';
import { OpportunityDetailsNavModel } from '../../../../common/models/opportunity-details.model';
import { IntakeConstant } from '../../../constants/intake.constant';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrl: './document.component.less',
})
export class DocumentComponent implements OnInit {
  _EMPTY = IntakeConstant.EMPTY_SPACE;
  @Input() type: string = this._EMPTY;
  @Input() submissionTypeId: number = 0;
  @Input() submissionStatusId: number = 0;
  @Input() submissionId: number = 0;
  @Input() progressiveStatus: boolean | null = null;
  @Input() submissionTypeCode: string | undefined = this._EMPTY;
  @Input() submissionStatus: string | undefined = this._EMPTY;
  @Input() isDisabledDocuments: boolean = false;
  @Input() isDraftForSelfReview: boolean = false;
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;
  @Input() showDocumentTable: boolean = false;
  @Input() showDocUploaderInFinalizeForm: boolean = false;
  @Input() DeliverableShowDocUploaderInFinalizeForm: boolean = false;
  @Input() OtherShowDocUploaderInFinalizeForm: boolean = false;
  @Input() ProposalShowDocUploaderInFinalizeForm: boolean = false;
  @Input() opportunityDocs!: OpportunityDetailsNavModel[];
  @Input() submissionTypeSubId: number | null | undefined = null;
  @Input() rmReviewedGuidanceNoteHeader: string = IntakeConstant.EMPTY_SPACE;
  @Input() rmReviewedGuidanceNoteBody: string = IntakeConstant.EMPTY_SPACE;

  @Output() documentTableData: EventEmitter<IntakeDocumentTableModel[]> =
    new EventEmitter<IntakeDocumentTableModel[]>();
  @ViewChild(DocumentTableComponent) tableComponent!: DocumentTableComponent;

  showDocumentTableData: boolean = false;
  closeDocUploaderInFinalizeForm: boolean = false;
  hideDocumentAttachBtn: boolean = false;
  @Output() docUploadedInFinalizeForm: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    private readonly modalService: ModalService,
    private readonly submissionService: SubmissionService,
    private readonly docService: DocumentService
  ) {}

  options = ButtonOptions;
  uploadAreaOptions = UploaderOptions;
  isSubmissionStatusRMorSelfReviewed: boolean = true;
  documentTableDataCurrently: IntakeDocumentTableModel[] = [];
  ngOnInit(): void {
    this.hideDocumentAttachBtn = true;
    this.closeDocUploaderInFinalizeForm = false;
    this.docUploadedInFinalizeForm.emit(false);
    this.submissionService.afterDocUploadedInFinalizeForm(false);
    this.docService.closeDocUploaderInFinalizeForm$.subscribe(() => {
      this.triggerDocumentTableDataApi();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['submissionTypeSubId'] &&
      changes['submissionTypeSubId'].currentValue
    ) {
      this.submissionTypeSubId = changes['submissionTypeSubId'].currentValue;
    }
  }

  triggerDocumentTableDataApi(): void {
    this.tableComponent?.getDocumentMetaData();
  }

  openDocumentLinkModel(modalBtn?: any): void {
    const modalRef = this.modalService.open(DocumentLinkUploadComponent, {
      documentInitiatedDetails: {
        submissionId: this.submissionId,
        moduleName: 'Intake',
        submissionStatusId: this.submissionStatusId,
        submissionTypeId: this.submissionTypeId,
        type: this.type,
        submissionTypeCode: this.submissionTypeCode,
        submissionStatus: this.submissionStatus,
        submissionTypeSubId: this.submissionTypeSubId,
      },
      rmReviewedGuidanceNoteHeader: this.rmReviewedGuidanceNoteHeader,
      rmReviewedGuidanceNoteBody: this.rmReviewedGuidanceNoteBody,
    });
    modalRef.onClosed().subscribe(() => {
      this.triggerDocumentTableDataApi();
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  openDocumentEditModel(modalBtn?: any): void {
    const modalRef = this.modalService.open(DocumentEditPopupComponent, {
      rmReviewedGuidanceNoteHeader: this.rmReviewedGuidanceNoteHeader,
      rmReviewedGuidanceNoteBody: this.rmReviewedGuidanceNoteBody,
    });
    modalRef.onClosed().subscribe(() => {
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  openUploadModel(event: any, modalBtn?: ButtonComponent): void {
    this.removeBodyOverflow();
    const modalRef = this.modalService.open(IntakeDocumentUploadComponents, {
      isFooter: true,
      size: 'lg',
      isInverse: false,
      documentTableDataCurrently: this.documentTableDataCurrently,
      documentInitiatedDetails: {
        moduleName: 'Intake',
        submissionStatusId: this.submissionStatusId,
        submissionTypeId: this.submissionTypeId,
        submissionId: this.submissionId,
        documentUploadCount: 1,
        file: event,
        type: this.type,
        submissionTypeCode: this.submissionTypeCode,
        submissionStatus: this.submissionStatus,
        submissionTypeSubId: this.submissionTypeSubId,
      },
      permissionObj: this.permissionObj,
      rmReviewedGuidanceNoteHeader: this.rmReviewedGuidanceNoteHeader,
      rmReviewedGuidanceNoteBody: this.rmReviewedGuidanceNoteBody,
    });

    modalRef.onClosed().subscribe(() => {
      //TODO : TO check if needed or not
      // this.triggerDocumentTableDataApi();
      this.docService.setCloseDocUploaderInFinalizeForm(true);
      event.splice(0, event.length);
      if (modalBtn) {
        modalBtn.focus();
      }
      this.docUploadedInFinalizeForm.emit(true);
      this.closeDocUploaderInFinalizeForm = true;
      this.hideDocumentAttachBtn = false;
      this.submissionService.afterDocUploadedInFinalizeForm(true);
    });
  }

  hideLinkButton(): boolean {
    return (
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
    );
  }

  removeBodyOverflow(): void {
    document.body.style.overflowY = 'hidden';
  }

  documentTableDataValue(data: IntakeDocumentTableModel[]): void {
    this.documentTableDataCurrently = data;
    this.documentTableData.emit(data);
    this.isSubmissionStatusRMorSelfReviewed =
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT;
  }

  get shouldShowUploader(): boolean {
    let result =
      this.permissionObj['isIntakeSubmissionDocumentDragAndDropVisible'] &&
      (!this.isSubmissionStatusRMorSelfReviewed ||
        (this.isSubmissionStatusRMorSelfReviewed &&
          !this.closeDocUploaderInFinalizeForm &&
          (this.showDocUploaderInFinalizeForm ||
            this.DeliverableShowDocUploaderInFinalizeForm ||
            this.OtherShowDocUploaderInFinalizeForm ||
            this.ProposalShowDocUploaderInFinalizeForm)));

    return result;
  }

  get isAddLinkButtonVisible(): boolean {
    return (
      !this.isDraftForSelfReview &&
      !this.hideLinkButton() &&
      this.permissionObj['isIntakeSubmissionAddDocumentLinkVisible']
    );
  }
}
