import {
  Component,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { AdminModuleService } from '../../../admin-services/admin-module.service';
import { Subject, takeUntil } from 'rxjs';
import {
  adminExtCommTrainingTableData,
  filterState,
  GridColumn,
} from '../../../../common/models/admin-module.model';
import {
  btnOptions,
  filter,
  filterData,
  groupToolTipOptions,
  options,
  sampleColumns,
  searchOptionsCtrl,
} from './extcomm-training-table-helper';
import {
  Column,
  PositionState,
  SortOptions,
  Themes,
} from '@usitsdasdesign/dds-ng/shared';
import {
  StickerDirective,
  StickerOptions,
} from '@usitsdasdesign/dds-ng/sticker';
import { Filter, FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { EditAddRecordPopupComponent } from './edit-add-record-popup/edit-add-record-popup.component';
import { AdminExtTableSortParameter } from '../../../../intake/constants/adminMoldule.enum';
import { AdminConstant } from '../../../constants/admin-constants';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-extcomm-training-table',
  templateUrl: './extcomm-training-table.component.html',
  styleUrl: './extcomm-training-table.component.less',
})
export class ExtcommTrainingTableComponent implements OnInit {
  @Input() addRecordToTable!: boolean;
  sampleColumns: GridColumn[] = sampleColumns;
  filteringColumn!: GridColumn;
  isShownFilter!: boolean;
  adminExtCommTrainingTableData: adminExtCommTrainingTableData[] = [];
  searchOptions: SearchOptions = searchOptionsCtrl;
  cancelButtonOptions: ButtonOptions = options;
  isAscending: boolean | null = null;
  filterData: FilterItem[] = filterData;
  filterSortParameterCode = AdminExtTableSortParameter;
  sortParamaterCode: number | null = null;
  filteringState: filterState[] = [];
  groupToolTipOptions = groupToolTipOptions;

  filterOptions: Filter = filter;
  @ViewChildren('stickerDir') sticker!: QueryList<StickerDirective>;

  sortingState: SortOptions = {
    property: this.sampleColumns[0].name,
    descending: false,
    dataType: this.sampleColumns[0].dataType,
  };
  filterStickerOptions: StickerOptions = {
    stickerPosition: PositionState.bottomRight,
    stickerIsOutsideClick: true,
    stickerIndent: -1,
    stickerWidth: 280,
  };
  private readonly unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    private readonly adminModuleService: AdminModuleService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.fetchExtCommTrainingData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['addRecordToTable'] &&
      changes['addRecordToTable'].currentValue
    ) {
      this.addRecordToExtCommTrainingTable();
    }
  }

  public toggleFilter(column: Column): void {
    if (
      column.name !== AdminConstant.COMMENTS_HEADER ||
      column.name !== AdminConstant.EDITICON
    ) {
      this.filteringColumn = column;
      this.isShownFilter = true;
    }
  }

  public filterOnHidden(): void {
    this.isShownFilter = false;
  }

  isSortFilterPresent(name: string): boolean {
    return (
      name === AdminConstant.COMMENTS_HEADER || name === AdminConstant.EDITICON
    );
  }

  public filterHide(): void {
    this.sticker.forEach((item) => {
      if (item.isActive) {
        item.hide();
      }
    });
  }

  onEditTypeClick(event: Event, row: adminExtCommTrainingTableData): void {
    event.stopPropagation();
    this.openModal(row, false);
  }

  openModal(
    row: adminExtCommTrainingTableData | null,
    showAddRecordPopup: boolean
  ): void {
    const adminExtTrainingTable = this.modalService.open(
      EditAddRecordPopupComponent
    );
    adminExtTrainingTable.componentInstance.rowData = row;
    adminExtTrainingTable.componentInstance.showAddRecordPopup =
      showAddRecordPopup;

    adminExtTrainingTable
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          this.addRecordToTable = false;
          this.fetchExtCommTrainingData();
        },
        error: (err: HttpErrorResponse) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  public filterApply(searchValue: string): void {
    this.filteringColumn.filterable = true;
    const parameterCode =
      this.filterSortParameterCode[
        this.filteringColumn.name as keyof typeof AdminExtTableSortParameter
      ];
    let filterCriteria: string = searchValue;
    const newFilter = {
      filtertypecode: parameterCode,
      filtercriteria: filterCriteria,
    };
    this.filteringState.push(newFilter);
    this.fetchExtCommTrainingData();
    this.filterHide();
  }

  public filterSorted(isAscending: boolean | null): void {
    const parameterCode =
      this.filterSortParameterCode[
        this.filteringColumn.name as keyof typeof AdminExtTableSortParameter
      ];
    this.sortParamaterCode = parameterCode;
    this.isAscending = isAscending;
    this.fetchExtCommTrainingData();
    this.filterHide();
  }

  addRecordToExtCommTrainingTable(): void {
    this.openModal(null, true);
  }

  fetchExtCommTrainingData(): void {
    const requestBody: filterState[] =
      this.filteringState.length > 0 ? this.filteringState : [];

    this.adminModuleService
      .getExtCommTrainingData(
        requestBody,
        this.sortParamaterCode,
        this.isAscending
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: adminExtCommTrainingTableData[]) => {
          this.adminExtCommTrainingTableData = response;
        },
        error: (err: HttpErrorResponse) => {
          console.error(
            'An error occurred during fetching market offering table data: ',
            err
          );
        },
      });
  }
}
