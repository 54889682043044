import {
  globalConfigColumns,
  selectDropdownOptions,
  selectItems,
} from './global-configuration.helper';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  LabelPosition,
  Size,
  ErrorState,
  Column,
} from '@usitsdasdesign/dds-ng/shared';
import {
  SelectOptions,
  SelectItemOptions,
} from '@usitsdasdesign/dds-ng/select';
import { AdminModuleService } from '../../admin-services/admin-module.service';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { InitiationEditPopupComponent } from './initiation-edit-popup/initiation-edit-popup.component';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { IntakeConstant } from '../../../intake/constants/intake.constant';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import {
  globalConfigurationItem,
  globalConfigurationRequestBody,
  globalConfigurationResponse,
  configurationCategory
} from '../../../common/models/admin/global-configuration.model';
import { AdminConstant } from '../../constants/admin-constants';
import { BrowserTabConstant } from '../../../common/constants/browser-tab-constant';

@Component({
  selector: 'app-global-configuration',
  templateUrl: './global-configuration.component.html',
  styleUrls: ['./global-configuration.component.less'],
})
export class GlobalConfigurationComponent implements OnInit, OnDestroy {
  globalConfigColumns: Column[] = globalConfigColumns;
  selectOptions: SelectOptions = selectDropdownOptions;
  selectItems: Array<configurationCategory> = [];
  searchOptions: SearchOptions = {
    placeholder: IntakeConstant.SEARCH_OPTION_PLACEHOLDER_SIMPLE,
    customClass: IntakeConstant.EMPTY_SPACE,
  };
  selectedOportunity: string = IntakeConstant.EMPTY_SPACE;
  selectedConfiguration: number | null = 1; //AdminConstant.Initiation;
  sampleData!: any;
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  selectedElement : configurationCategory = { value : 0, heading:"",ConfigurationCategoryId:0, ConfigurationName:""};

  private readonly searchOpportunityQuery$: Subject<string> =
    new Subject<string>();

  private readonly unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    private readonly modalService: ModalService,
    private readonly adminModuleService: AdminModuleService,
    private readonly sanitizer: DomSanitizer,
    private readonly progressIndicator: ProgressIndicatorService
  ) {}

  ngOnInit(): void {
    document.title = BrowserTabConstant.Browser_TabName_Admin;
    this.adminModuleService.getConfigurationCategories()
    .pipe(takeUntil(this.unsubscriber$))
    .subscribe((data) => {
      this.selectItems = data;
      let filtered = this.selectItems.filter(a => a.value == 1);// {value:this.selectItems[0].value}; //AdminConstant.Initiation;
      this.selectedElement = this.selectItems.map(a => {return {...a}})[0];
      this.selectedConfiguration = this.selectItems.map(a => {return {...a}})[0].value;
      this.fetchGlobalConfigurationData();
    });

    this.searchOpportunityQuery$
      .pipe(
        debounceTime(IntakeConstant.SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        filter((key) => !!key && key.length >= IntakeConstant.CHARACTER_LIMIT_IN_SEARCH),
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.selectedConfiguration = this.selectItems.map(a => {return {...a}})[0].value;
        this.fetchGlobalConfigurationData();
      });
  }

  valueChanged(value: any):void {
    this.selectedConfiguration = value;
    this.selectedOportunity = IntakeConstant.EMPTY_SPACE;
    this.fetchGlobalConfigurationData();
  }

  fieldCleared(): void {
    this.selectedOportunity = IntakeConstant.EMPTY_SPACE;
    this.selectedConfiguration = 1; //AdminConstant.Initiation;
    this.selectedElement = this.selectItems.map(a => {return {...a}})[0];
    this.fetchGlobalConfigurationData();
  }

  selectOpportunityChanged(event: string): void {
    const trimmedValue = event?.trim();

    if (!trimmedValue) {
      this.fieldCleared();
    } else if (trimmedValue.length >= IntakeConstant.CHARACTER_LIMIT_IN_SEARCH) {
      this.selectedConfiguration = 0;
      this.selectedElement.value = 0;
      this.searchOpportunityQuery$.next(trimmedValue);
    }
  }

  openModalForUpdateLabel(
    row: globalConfigurationItem,
    modalBtn?: ButtonComponent
  ): void {
    const updateLabelModal = this.modalService.open(InitiationEditPopupComponent, {
      isFooter: false,
      size: 'md',
      isInverse: false,
      keyDetail: row.appConfigurationDisplayName,
      value: row.appConfigurationLabelValue,
      modifiedBy: row.modifiedBy,
      modifiedDate: row.modifiedDate,
      labelId: row.appConfigurationLabelId,
      categoryId: row.categoryId
    });
    updateLabelModal
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.fetchGlobalConfigurationData();
      });
  }

  fetchGlobalConfigurationData(): void {
    this.progressIndicator.show();
    let requestBody: globalConfigurationRequestBody;
    if (this.selectedOportunity && this.selectedOportunity.trim().length >= IntakeConstant.CHARACTER_LIMIT_IN_SEARCH) {
      requestBody = {
        searchTerm: this.selectedOportunity,
        appConfigurationCategoryId: null,
        appConfigurationSubCategoryId: null,
      };
    } else {
      let category : configurationCategory = this.selectItems.filter(a => a.value == this.selectedConfiguration)[0];
      requestBody = {
        searchTerm: null,
        appConfigurationCategoryId: category["configurationCategoryId"], // Intake is always 1
        appConfigurationSubCategoryId:this.selectedConfiguration
      };
    }
    if(this.selectedConfiguration === 5 /* On Hover guidances*/) {
      this.adminModuleService.getGlobalConfigurations(5, /* On Hover guidances*/ this.selectedOportunity)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({ 
        next: (data: globalConfigurationResponse) => {
          this.sampleData = data.globalConfiguration;
          this.progressIndicator.hide();
        },
        error: (error) => {
          console.error('Error fetching global configuration data:', error);
          this.progressIndicator.hide();
        },
      });
    } else {
      this.adminModuleService
      .getGlobalConfigurationTableData(requestBody)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
      next: (data: globalConfigurationResponse) => {
        this.sampleData = data.globalConfiguration;
        this.progressIndicator.hide();
      },
      error: (error) => {
        console.error('Error fetching global configuration data:', error);
        this.progressIndicator.hide();
      },
      });
    }
  }
 
  getCategoryIdFromConfiguration(value:string):number {
    switch (value){
      case AdminConstant.Initiation:
        return AdminConstant.IntakeCategoryID; 
      case AdminConstant.Opportunity:
        return AdminConstant.IntakeCategoryID; 
      case AdminConstant.Submission:
        return AdminConstant.IntakeCategoryID; 
      case AdminConstant.ExternalCommunication: 
        return AdminConstant.ExternalCommunicationCategoryID; 
      default:
        return AdminConstant.IntakeCategoryID; 
    }
  }

  getSubCategoryIdFromConfiguration(value: string): number|null {
    switch (value) {
      case AdminConstant.Initiation:
        return AdminConstant.Initiation_Sub_Category_ID;
      case AdminConstant.Opportunity:
        return AdminConstant.Opportunity_Sub_Category_ID;
      case AdminConstant.Submission:
        return AdminConstant.Submission_Sub_Category_ID;
      case AdminConstant.ExternalCommunication:
        return AdminConstant.Null;
      default:
        return AdminConstant.Initiation_Sub_Category_ID;
    }
  }

  getSafeHTML(html: string | null): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html || IntakeConstant.EMPTY_SPACE);
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
