export enum DocumentStatus {
  Draft = 'Draft for review',
  RMReviewed = 'RM reviewed',
  SelfReviewed = 'Self reviewed',
  FinalArchiveReadyVersion = 'Final - Archive ready version',
  SupportingDocument = 'Supporting documentation - no review required',
  DOC_PRESCRENEED_INCLUDES_EDITS = 'Prescreened - includes suggested edits',
  DOC_PRESCREENED_RM_REVIEW = 'Prescreened for RM review - Not for team use',
  DOC_FINAL_VERSION = 'Final version',
  DOC_SELF_REVIEWED = 'Self-reviewed',
  DOC_RMReviewed = 'RM Reviewed',
}

export enum DocumentArchivalStatus {
  ARCHIVED = 'Archived',
  MOVED = 'Archived',
  PENDING = 'Pending',
}
