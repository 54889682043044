import { Component, Input, OnInit } from '@angular/core';
import { ModalModule, ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ClientStatusWebapiService } from '../../../http/client/client-status-webapi.service'
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';

@Component({
  selector: 'app-nca-process-dialog',
  templateUrl: './nca-process-dialog.component.html',
  styleUrl: './nca-process-dialog.component.less'
})
export class NcaProcessDialogComponent {

  public reason: string = '';
  public subStatusID: number = 0;
  public clientMilestoneSubStatusCustom: string = '';
  @Input() resolve: any;

  constructor(
    private modalService: ModalService,
    private clientstatuswebapiservice: ClientStatusWebapiService,
  ) {

  }
  public ngOnInit() {
   }

  textareaOptions: TextareaOptions = {
    placeholder: 'Enter text',
    minHeight: 100,
    maxLength: 4000,
    size: Size.md,
    maxLengthPosition: 'bottom-right'
  };

  public confirm() {

    if (this.resolve.isNCANotRequired) {
      this.clientMilestoneSubStatusCustom = this.resolve.optionsClientMilestoneSubStatus
        .filter((item: any) => item.value == this.subStatusID /*Not Required*/)
        .map((item: any) => item.heading);

      this.clientstatuswebapiservice
        .updateNCAStatus(this.resolve.clientNumber,
          this.reason, this.resolve.clientMilestoneId, this.clientMilestoneSubStatusCustom, 4 /*Not Required*/, this.subStatusID)
        .subscribe((data: any) => {
            location.reload();
        });
    }

    if (this.resolve.isOnhold) {
      this.clientstatuswebapiservice
        .updateNCAStatus(this.resolve.clientNumber, this.reason, this.resolve.clientMilestoneId,
          this.clientMilestoneSubStatusCustom, 3 /*On Hold*/, null).subscribe((data: any) => {
            location.reload();
        });
    }

    if(this.resolve.isSupplementalApproval){
      this.clientstatuswebapiservice.convertToSupplementalApproval(this.resolve.clientMilestoneId, this.reason).subscribe((data: any) => {
        location.reload();
    });
  }

  }

  public cancel() {
    //This will disable the scroll when the pop-up is open
    document.body.classList.remove('no-scroll');
    this.modalService.close();
}

}
