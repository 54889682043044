import { Component, OnInit } from '@angular/core';
import {
  primaryButton,
  secondaryButton,
} from '../external-communications.helper';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { ExternalCommunicationService } from '../../http/external-communication/external-communication.service';
import {
  InitiateExternalCommunicationDropdownModel,
  InitiateExternalCommunicationResponseModel,
} from '../../common/models/external-communication.model';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { PermissionCheck } from '../../common/validations/PermissionCheck';

@Component({
  selector: 'app-initiate-external-communication',
  templateUrl: './initiate-external-communication.component.html',
  styleUrl: './initiate-external-communication.component.less',
})
export class InitiateExternalCommunicationComponent
  extends PermissionCheck
  implements OnInit
{
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  unsubscriber$: Subject<void> = new Subject<void>();
  primaryButton = primaryButton;
  secondaryButton = secondaryButton;
  communicationType: string = IntakeConstant.EMPTY_SPACE;
  communicationTypeId: number = 0;
  communicationTypeList: SelectItemOptions[] = [];
  communicationTitle: string = IntakeConstant.EMPTY_SPACE;
  ppmdId: number | null = null;
  searchedName: string = '';
  hasConfirmActionPermission: boolean = false;
  isCommunicationTypeDrpDownVisisble: boolean = false;

  constructor(
    private readonly externalCommService: ExternalCommunicationService,
    private readonly router: Router,
    protected override readonly securityWebapiService: SecurityWebapiService
  ) {
    super(securityWebapiService);
  }

  ngOnInit(): void {
    this.isCommunicationTypeDrpDownVisisble = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_CommunicationTypeToggle,
      this.permissionType.Visible
    );
    this.hasConfirmActionPermission = this.isPermissionPresent(
      this.permissionEnums.EXT_COM_Confirm,
      this.permissionType.Enable
    );
    if (
      !this.isCommunicationTypeDrpDownVisisble &&
      !this.hasConfirmActionPermission
    ) {
      window.location.href = '/';
    }
    this.getCommunicationType();
  }

  getCommunicationType(): void {
    if (this.isCommunicationTypeDrpDownVisisble) {
      this.externalCommService
        .getSubmissionTypes()
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: InitiateExternalCommunicationDropdownModel[]) => {
            if (response) {
              const convertedArray = response.map(
                (item: InitiateExternalCommunicationDropdownModel) => ({
                  value: item.communicationTypeId,
                  heading: item.communicationTypeName,
                })
              );
              this.communicationTypeList = convertedArray;
            }
          },
          error: (err) => {
            console.error('An error occurred during submission: ', err);
          },
        });
    }
  }

  onSubmissionTypeChange($event: number): void {
    this.communicationTypeId = $event;
    this.externalCommService.setCommunicationType(this.communicationTypeId);
  }

  onCommunicationTitleInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value.length > 100) {
      inputElement.value = inputElement.value.substring(0, 100);
    }
    this.communicationTitle = inputElement.value;
  }

  onSubmit(): void {
    const requestBody = {
      SubmissionTitle: this.communicationTitle,
      CommunicationTypeId: this.communicationTypeId,
      PPMDId: String(this.ppmdId),
    };
    if (this.hasConfirmActionPermission) {
      this.externalCommService
        .postInitiateExternalCommunication(requestBody)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: InitiateExternalCommunicationResponseModel) => {
            this.router.navigate([
              '/externalCommunications/details',
              this.communicationTypeId,
            ]);
          },
          error: (err) => {
            console.error('Error initiating external communication', err);
          },
        });
    }
  }

  onCancel(): void {
    this.router.navigate(['/']);
  }

  onSelected($event: any): void {
    this.ppmdId = $event.reviewerId;
    this.searchedName = $event.displayName;
  }

  removeSearchedPerson(): void {
    this.searchedName = '';
    this.ppmdId = null;
  }

  isConfirmationDisabled(): boolean {
    return !this.communicationType || !this.communicationTitle || !this.ppmdId;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
