<div class="dds-modal-overlay" (click)="closeModal()"></div>

<div ddsResize [ngClass]="{
  'dds-modal_lg': size === 'lg',
  'dds-modal_inverse': isInverse
  }" class="dds-modal">
  <div class="dds-modal__header">
    <span class="dds-modal__title">{{popupTitle}}</span>
    <dds-button size="sm" icon="dds-icon_close" kind="silent" theme="dark" ariaLabel="Close modal"
      class="dds-modal__close" [isInverse]="isInverse" (clicked)="closeModal()"></dds-button>
  </div>

  <div class="dds-modal__body" [ngStyle]="{'overflow': !canUpload  ? 'auto' : 'hidden'}">
    <dds-uploader class="dds-uploader" [ngStyle]="{'padding': popupType === popupTypeEnum.ClientAgreement ? '8px 0' : '14px 0'}"
      [heading]="uploadAreaOptions.heading??''"
      [description]="uploadAreaOptions.description??''" [dragAndDropText]="uploadAreaOptions.dragAndDropText??''"
      [isDragAndDrop]="uploadAreaOptions.isDragAndDrop??false" [isMultiple]="uploadAreaOptions.isMultiple??false"
      [acceptFormats]="uploadAreaOptions.acceptFormats??''"
      [acceptExtensions]="uploadAreaOptions.acceptExtensions??''"
      [exceptSymbols]="uploadAreaOptions.exceptSymbols??''" [btnLabel]="uploadAreaOptions.btnLabel??''"
      [isInverse]="uploadAreaOptions.isInverse??false" [disabled]="uploadAreaOptions.disabled??true"
      [chooseFileBtnKind]="uploadAreaOptions.chooseFileBtnKind??''"
      (uploadedItems)="uploadedItems($event)"
      *ngIf="!isEditMode"></dds-uploader>
    <!-- Table logic -->
    <div class="div-headers" *ngIf="popupType === popupTypeEnum.DocumentUpload">
      <div class="row table-header-margin">
        <div *ngFor="let column of DocumentColumns" class="table-column">
          <span class="div-headers-style">{{column.header}}</span>
        </div>
      </div>
      <div class="div-whole-data" [ngStyle]="{'height': isEditMode ? '45vh' : 'inherit'}">
        <!--<span>-->
        <div class="row div-data" *ngFor="let row of documentUploadData; let i = index">
          <div class="table-column2"  [ngStyle]="bindStyleAutomatic(row)">
            <span *ngIf="!row.isUserClickedEdit" class="div-data-style data-alignment ellipsis float-left"
              [ddsTooltip]="row.fileName"
              [tooltipInvokeType]="fileTooltipOptions.tooltipInvokeType ?? ''"
              [tooltipType]="fileTooltipOptions.tooltipType ?? ''" 
              [tooltipSize]="fileTooltipOptions.tooltipSize  ?? ''"
              [tooltipPosition]="fileTooltipOptions.tooltipPosition ?? ''"
              [tooltipIndent]="fileTooltipOptions.tooltipIndent ?? 0"
              [tooltipHasBeak]="fileTooltipOptions.tooltipHasBeak ?? false"
              [tooltipTheme]="fileTooltipOptions.tooltipTheme ?? ''" ddsFocus
              [tooltipMaxWidth]="fileTooltipOptions.tooltipMaxWidth ?? 0">{{row.fileName}}
              <div *ngIf="!validateElipsisAppliedDocLength(row.fileName)" class="dds-card__image-block edit-style" (click)="onEditDocName(row)"></div>
            </span>
              <dds-input *ngIf="row.isUserClickedEdit" [placeholder]="textfileNameOptions.placeholder ?? ''"
              [maxLengthPosition]="textfileNameOptions.maxLengthPosition ?? ''" 
              [maxLength]="textfileNameOptions.maxLength ?? 0"
              [size]="textfileNameOptions.size ?? ''" [(ngModel)]="row.fileName" (onBlur)="onFocusOut(row,i)">
              </dds-input>
              <div *ngIf="!row.isUserClickedEdit && validateElipsisAppliedDocLength(row.fileName)" class="dds-card__image-block edit-style" (click)="onEditDocName(row)"></div>
          </div>
          <div class="table-column1">
            <span *ngIf="row.clientMilestoneDocumentTypeID == 0" class="icon-required-yellow mandatory-margin"></span>
          </div>
          <div class="table-column">
            <dds-select [isResponsive]="selectDocumentTypeOptions.isResponsive??false"
              [isRequired]="selectDocumentTypeOptions.isRequired??false"
              [isInverse]="selectDocumentTypeOptions.isInverse??false" [list]="documentTypeItemsList"
              [label]="selectDocumentTypeOptions.label??''"
              [labelPosition]="selectDocumentTypeOptions.labelPosition??''"
              [customClass]="selectDocumentTypeOptions.customClass??''"
              [placeholder]="selectDocumentTypeOptions.placeholder??''" 
              [size]="selectDocumentTypeOptions.size??''"
              [stickerWidth]="selectDocumentTypeOptions.stickerWidth??''" 
              (valueChanged)="valueChange(row)" [(ngModel)]="row.clientMilestoneDocumentTypeID">
            </dds-select>
          </div>
          <div class="table-column">
            <dds-input [placeholder]="textareaOptions.placeholder ?? ''"
              [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''" 
              [maxLength]="textareaOptions.maxLength ?? 0"
              [size]="textareaOptions.size ?? ''" [(ngModel)]="row.fileComments">
            </dds-input>

          </div>
          <div class="table-column1">
            <dds-button size="sm" icon="dds-icon_close" kind="silent" theme="dark" 
            ariaLabel="Close modal" class="dds-modal__close margin-top" [isInverse]="isInverse" 
            (clicked)="removeDocument(row)"></dds-button>
          </div>
        </div>
        <!--</span>-->
      </div>
    </div>
    <!--Client Agreement Attachment Popup-->
    <div *ngIf="popupType === popupTypeEnum.ClientAgreement">
      <div class="div-headers div-whole-data" [ngStyle]="{'height': !canUpload ? '45vh' : 'inherit'}">
        <div class="row table-header-margin">
          <div class="col-6">
            <span class="required-icon icon-required-yellow position-alignment" *ngIf="selectOptions.isRequired"></span>
            <span class="eclipse-small-header-grey line-height-l">Select document type:
              <span class="icon-s icon-info inline-block position-alignment" [ddsTooltip]="documentTypeSelectionTooltip"
                [tooltipInvokeType]="tooltipOptions.tooltipInvokeType ?? ''"
                [tooltipType]="tooltipOptions.tooltipType ?? ''" [tooltipSize]="tooltipOptions.tooltipSize  ?? ''"
                [tooltipPosition]="tooltipOptions.tooltipPosition ?? ''"
                [tooltipIndent]="tooltipOptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="tooltipOptions.tooltipHasBeak ?? false"
                [tooltipTheme]="tooltipOptions.tooltipTheme ?? ''" ddsFocus
                [tooltipMaxWidth]="tooltipOptions.tooltipMaxWidth ?? 0">
              </span>
            </span>

            <div class="select-document-type margin-top-small">
              <dds-select class="custom-select" [disabled]="selectOptions.disabled ?? false" [list]="selectItems"
                [placeholder]="selectOptions.placeholder ?? ''" (valueChanged)="onDocumentTypeChanged()"
                [(ngModel)]="selectedDocumentType" [size]="selectOptions.size ?? ''">
              </dds-select>
            </div>
            <span *ngIf="!canUpload"
              class="info-label error-text text-label font-bold-600 font-size-16 line-height-m">
              Document type already exists on the <a href="client/{{clientNumber}}/parent" target="_blank">Parent Client tab</a>. Are you sure this is an additional {{selectedDocumentTypeName}}?
            </span>
          </div>
          <div class="col-6">
            <span class="required-icon icon-required-yellow position-alignment" *ngIf="inputOptions.isRequired"></span>
            <span class="eclipse-small-header-grey line-height-l">File name:</span>
            <dds-input [(ngModel)]="uploadedDocumentFilename" [label]="inputOptions.label ?? ''"
              [labelPosition]="inputOptions.labelPosition ?? ''" [placeholder]="inputOptions.placeholder ?? ''"
              [isRequired]="inputOptions.isRequired ?? false" [isInverse]="inputOptions.isInverse ?? false"
              [isError]="inputOptions.isError ?? false" [readonly]="inputOptions.readonly ?? false"
              [size]="inputOptions.size ?? ''" [description]="inputOptions.description ?? ''"
              [errorMessage]="inputOptions.errorMessage ?? ''" [errorState]="inputOptions.errorState ?? ''"
              [descriptionType]="inputOptions.descriptionType ?? ''" [maxLength]="inputOptions.maxLength ?? 0"
              [maxLengthPosition]="inputOptions.maxLengthPosition ?? ''" [textAlign]="inputOptions.textAlign ?? ''"
              [customClass]="inputOptions.customClass ?? ''" [type]="inputOptions.type ?? ''"
              [accept]="inputOptions.accept ?? ''" [alt]="inputOptions.alt ?? ''"
              [autocomplete]="inputOptions.autocomplete ?? ''" [autofocus]="inputOptions.autofocus ?? false"
              [capture]="inputOptions.capture ?? ''" [checked]="inputOptions.checked ?? false"
              [dirname]="inputOptions.dirname ?? ''" [form]="inputOptions.form ?? ''"
              [height]="inputOptions.height ?? 0" [list]="inputOptions.list ?? ''" [max]="inputOptions.max ?? 0"
              [min]="inputOptions.min ?? 0" [minlength]="inputOptions.minlength ?? 0"
              [multiple]="inputOptions.multiple ?? false" [name]="inputOptions.name ?? ''"
              [pattern]="inputOptions.pattern ?? ''" [src]="inputOptions.src ?? ''" [step]="inputOptions.step ?? 0"
              [width]="inputOptions.width ?? 0" [mask]="inputOptions.mask ?? ''" [prefix]="inputOptions.prefix ?? ''"
              [suffix]="inputOptions.suffix ?? ''" [allowNegativeNumbers]="inputOptions.allowNegativeNumbers ?? false"
              [dropSpecialCharacters]="inputOptions.dropSpecialCharacters ?? false"
              [showMaskTyped]="inputOptions.showMaskTyped ?? false" [tooltipIcon]="inputOptions.tooltipIcon ?? ''"
              [tooltipType]="inputOptions.tooltipType ?? ''" [tooltipSize]="inputOptions.tooltipSize ?? ''"
              [tooltipPosition]="inputOptions.tooltipPosition ?? ''" [tooltipIndent]="inputOptions.tooltipIndent ?? 0"
              [tooltipWidth]="inputOptions.tooltipWidth ?? 0" [tooltipShift]="inputOptions.tooltipShift ?? 0"
              [tooltipShowDelay]="inputOptions.tooltipShowDelay ?? 0"
              [tooltipHideDelay]="inputOptions.tooltipHideDelay ?? 0"
              [tooltipHasBeak]="inputOptions.tooltipHasBeak ?? false"
              [tooltipIsDisabled]="inputOptions.tooltipIsDisabled ?? false"
              [tooltipIsOutsideClick]="inputOptions.tooltipIsOutsideClick ?? false"
              [tooltipCustomClass]="inputOptions.tooltipCustomClass ?? ''"
              [tooltipTheme]="inputOptions.tooltipTheme ?? ''" (valueChanged)="onFilenameValueChange()"
              (onBlur)="onFilenameValueBlur()"></dds-input>
          </div>
        </div>
        <div class="row table-header-margin">
          <div class="col-6">
            <span class="required-icon icon-required-yellow position-alignment"
              *ngIf="descriptionTextareaOptions.isRequired"></span>
            <span class="eclipse-small-header-grey">Description:</span>
            <dds-textarea [(ngModel)]="description" [placeholder]="descriptionTextareaOptions.placeholder ?? ''"
              [description]="descriptionTextareaOptions.description ?? ''"
              [minHeight]="descriptionTextareaOptions.minHeight ?? 0"
              [isRequired]="descriptionTextareaOptions.isRequired ?? false"
              [isInverse]="descriptionTextareaOptions.isInverse ?? false"
              [customClass]="descriptionTextareaOptions.customClass ?? ''"
              [isError]="descriptionTextareaOptions.isError ?? false"
              [errorMessage]="descriptionTextareaOptions.errorMessage ?? ''"
              [errorState]="descriptionTextareaOptions.errorState ?? ''"
              [maxLength]="descriptionTextareaOptions.maxLength ?? 0"
              [maxLengthPosition]="descriptionTextareaOptions.maxLengthPosition ?? ''"
              [size]="descriptionTextareaOptions.size ?? ''" [readonly]="descriptionTextareaOptions.readonly ?? false"
              (valueChanged)="onDocumentDescriptionChanged()"></dds-textarea>
          </div>
          <div class="col-6">
            <span class="eclipse-small-header-grey">Comments:</span>
            <dds-textarea [(ngModel)]="comments" [placeholder]="commentsTextareaOptions.placeholder ?? ''"
              [description]="commentsTextareaOptions.description ?? ''"
              [minHeight]="commentsTextareaOptions.minHeight ?? 0"
              [isRequired]="commentsTextareaOptions.isRequired ?? false"
              [isInverse]="commentsTextareaOptions.isInverse ?? false"
              [customClass]="commentsTextareaOptions.customClass ?? ''"
              [isError]="commentsTextareaOptions.isError ?? false"
              [errorMessage]="commentsTextareaOptions.errorMessage ?? ''"
              [errorState]="commentsTextareaOptions.errorState ?? ''"
              [maxLength]="commentsTextareaOptions.maxLength ?? 0"
              [maxLengthPosition]="commentsTextareaOptions.maxLengthPosition ?? ''"
              [size]="commentsTextareaOptions.size ?? ''" [readonly]="commentsTextareaOptions.readonly ?? false"
              ></dds-textarea>
          </div>
        </div>
        <div class="row table-header-margin last-row">
          <div class="col-6">
            <div class="row no-padding">
              <div class="col-6">
                <span class="required-icon icon-required-yellow position-alignment"
                  *ngIf="datepickerOptions.isRequired"></span>
                <span class="eclipse-small-header-grey">Document Expiry Date:
                </span>
                <dds-datepicker [placeholder]="datepickerOptions.placeholder ?? ''" [(ngModel)]="documentExpirationDate"
                  [isRequired]="datepickerOptions.isRequired ?? false"
                  [isInverse]="datepickerOptions.isInverse ?? false" [customClass]="datepickerOptions.customClass ?? ''"
                  [isError]="datepickerOptions.isError ?? false" [errorMessage]="datepickerOptions.errorMessage ?? ''"
                  [errorState]="datepickerOptions.errorState ?? ''" [size]="datepickerOptions.size ?? ''"
                  [disabled]="datepickerOptions.disabled ?? false" [format]="datepickerOptions.format ?? ''"
                  [isManualInput]="datepickerOptions.isManualInput ?? false"
                  [isResponsive]="datepickerOptions.isResponsive ?? false"
                  (dateChanged)="onDateChanged($event)"></dds-datepicker>
              </div>
              <div class="col-4 col-4-flex">
                <dds-checkbox class="no-expiarton-date" [(ngModel)]="noExpirationDate"
                  [theme]="checkboxOptions.theme ?? ''" [isIndeterminate]="checkboxOptions.isIndeterminate ?? false"
                  [isInverse]="checkboxOptions.isInverse ?? false" [isError]="checkboxOptions.isError ?? false"
                  [errorState]="checkboxOptions.errorState ?? ''" [isRequired]="checkboxOptions.isRequired ?? false"
                  [customClass]="checkboxOptions.customClass ?? ''" [ariaLabel]="checkboxOptions.ariaLabel ?? ''"
                  (stateChanged)="onNoExpirationStateChanged()">
                </dds-checkbox>
                <span class="eclipse-small-header-grey">
                  No Expiration
                </span>
              </div>
            </div>
          </div>
          <div class="col-6 padding-top-s">
            <span class="eclipse-small-header-grey">{{documentVisibilityTitle}}</span>

            <dds-radio-group [(ngModel)]="documentVisibility" [name]="radioGroupOptions.name ?? ''"
              [disabled]="radioGroupOptions.disabled ?? false" [isError]="radioGroupOptions.isError ?? false"
              [isUncheckable]="radioGroupOptions.isUncheckable ?? false" [title]="radioGroupOptions.title ?? ''"
              [isInverse]="radioGroupOptions.isInverse ?? false" [isRequired]="radioGroupOptions.isRequired ?? false"
              [errorState]="radioGroupOptions.errorState ?? ''" (stateChanged)="onDoucmentVisibilityStateChanged()">

              <ng-container *ngFor="let radio of radioBtns">
                <dds-radio [value]="radio.value" [label]="radio.options.label ?? ''"
                  [disabled]="radio.options.disabled ?? false" [customClass]="radio.options.customClass ?? ''"
                  [theme]="radio.options.theme ?? ''" style="margin-right: 12px"></dds-radio>
              </ng-container>
            </dds-radio-group>
            <span *ngIf="documentVisibility === 'no'"
              class="info-label error-text eclipse-small-header-grey">
              Please select the subsidiaries applicable for this document on the Parent Client tab.
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--Client Agreement Attachment Popup Ends-->
  </div>
  <div class="padding-top-xs">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button class="dds-modal__footer-item" (clicked)="onSave()" 
      (keyboardClicked)="onSave()" [disabled]="(popupType === popupTypeEnum.ClientAgreement ? !isDocumentDetailsValid:!isDocumentExists)">Save</dds-button>
      <dds-button class="dds-modal__footer-item" kind="secondaryLoud" [isInverse]="isInverse" 
      (clicked)="closeModal()" (keyboardClicked)="closeModal()">Cancel</dds-button>

    </div>
    <div class="dds-modal__footer-content dds-modal__footer-content_right"></div>
  </div>
</div>
