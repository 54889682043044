import { RecentlyViewedComponent } from './../recently-viewed/recently-viewed.component';
import { Component } from '@angular/core';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import {
  ButtonOptionsType,
  DashboardDataCollection,
  DashboardGridLevelFilter,
  DashboardTileCode,
  GridColumn,
  TabWithCounterType,
} from '../../common/models/start-page/start-page.model';
import {
  EngagementDashboardData,
  EngagementDashboardTilesCount,
} from '../../common/models/start-page/engagement-dashboard.model';
import {
  buttonOptions,
  EngagementCompletedColumns,
  engagementExtcommTabsList,
  EngagementExtCommColumns,
  EngagementInitiatedColumns,
  EngagementReviewedColumns,
  EngagementSubmittedColumns,
  GridColumns,
  engagementExtCommTabs,
  EngagementGlobalEngColumns,
} from '../dashboard.helper';

import { Router } from '@angular/router';
import {
  defaultGridColumns,
  engagementSortParamaterCode,
} from '../dashboard.helper';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Subject, takeUntil } from 'rxjs';
import { IntakeConstant } from '../../intake/constants/intake.constant';
import { EngagementDashboardWebapiService } from '../../http/dashboard/engagement-dashboard-webapi.service';
import { ButtonKind } from '@usitsdasdesign/dds-ng/shared';
import { OptOutPopupComponent } from '../../intake/intake-common-popups/opt-out-popup/opt-out-popup.component';
import { IntakeOptOutPopUpDataModel } from '../../common/models/initiateSubmission.model';
import {
  optedOutSelfReviewToastMessage,
  resubmittedToastMessage,
  submittedMessage,
} from '../../intake/opportunity-details/submission-common-section/submission-common-section.helper';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { SubmissionService } from '../../http/intake/submission.service';
import { CommonService } from '../../http/intake/common.service';
import { OppertunitySubmissionStatusCode } from '../../common/models/oppertunity-submission-statusCode.model';
import { IntakeOppertunitySubmissionStatus } from '../../intake/constants/intake_oppertunity_submission_status.enum';
import { IntakeSubmissionDetailsCommonSectionDataSendingModal } from '../../common/models/intake-submission-details-common-section.model';
import { EngagementTileCode } from '../../intake/constants/dashboard-tiles.enum';
import { BrowserTabConstant } from '../../common/constants/browser-tab-constant';
import { FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { SubmissionType } from '../../common/models/common-models';
import { HttpErrorResponse } from '@angular/common/http';
import { ExternalCommunicationConstant } from '../../external-communications/constants/external_communication.constant';
import { ExternalCommSubmissionStatus } from '../../external-communications/constants/submission-status.enum';
import { ExternalCommunicationService } from '../../http/external-communication/external-communication.service';
import { GenericResponse } from '../../common/models/external-communication.model';
import {
  ToastMessageOptions,
  toastMessageTitle,
} from '../../external-communications/external-communications.helper';
import { getStatusEnumKeyByValue } from '../../external-communications/constants/status-enum';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import {
  MatrixKeys,
  PermissionTypes,
} from '../../common/constants/security-matrix';

@Component({
  selector: 'app-engagement-dashboard',
  templateUrl: './engagement-dashboard.component.html',
  styleUrl: './engagement-dashboard.component.less',
})
export class EngagementDashboardComponent {
  public engagementTiles = EngagementTileCode;
  originalEngagementTileCodeDetails = Array.from(
    IntakeConstant.EngagementTileCodeDetails.values()
  );
  public engagementTileCodeDetails = Array.from(
    IntakeConstant.EngagementTileCodeDetails.values()
  );
  public selectedTileCode: string = '';
  public dashboard: string = IntakeConstant.ENGAGEMENT_TEAM_DASHBOARD;
  public engagementDashboardTilesCountModel:
    | EngagementDashboardTilesCount
    | any;
  public sortParamaterCode: number | null = 1;
  public isAscending: boolean = true;
  public countforPagination: number = 0;
  public gotoPage: number = 1;
  public itemsPerPage: number = IntakeConstant.PAGE_NUMBER_10;
  public itemsPerPageOptions: number[] = [10, 25, 50];
  public gridTitle: string = '';
  public model: EngagementDashboardData[] = [];
  public engagementDashboardfilters: DashboardGridLevelFilter[] = [];
  public currentDashboardTileCode = DashboardTileCode.EngagementTeam;
  public gridColumns: GridColumn[] = [];
  options: ButtonOptions = buttonOptions;
  public buttonOptionsList: ButtonOptionsType[] = [];
  public defaultGridColumns: GridColumn[] = defaultGridColumns;
  public columnName = GridColumns;
  public filterDataList: { name: string; data: FilterItem[] }[] = [];
  public submissionTypeData: FilterItem[] = [];
  unsubscriber$: Subject<void> = new Subject<void>();
  optedOutToastOption: ToastOptions = optedOutSelfReviewToastMessage;
  opportunityStatusCodes: OppertunitySubmissionStatusCode[] = [];
  commonSectionDetails: IntakeSubmissionDetailsCommonSectionDataSendingModal =
    {} as IntakeSubmissionDetailsCommonSectionDataSendingModal;
  tabsList: TabWithCounterType[] = engagementExtcommTabsList;
  selectedTabCode: string = this.tabsList[0]?.tabCode;
  toastMessageOptions: ToastOptions = ToastMessageOptions;
  _blank: string = '';
  isExtCommTileVisibile: boolean = false;
  isGlobalEngTileVisibile: boolean = false;

  constructor(
    private readonly modalService: ModalService,
    private readonly engagementWebapiService: EngagementDashboardWebapiService,
    private readonly externalCommService: ExternalCommunicationService,
    private readonly progressIndicatorService: ProgressIndicatorService,
    private readonly submissionService: SubmissionService,
    private readonly commonService: CommonService,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly securityWebapiService: SecurityWebapiService
  ) {}

  ngOnInit(): void {
    this.removeTilesWithoutPermission(); // To remove the tiles on initial load until we get permissions
    this.fetchSubmissionType();
    this.getRolesAndPermissions();
    document.title = BrowserTabConstant.Browser_TabName_Dashboard;
    this.engagementDashboardTilesCountModel = {
      initiatedCount: 0,
      submittedCount: 0,
      reviewedCount: 0,
      completedCount: 0,
      externalCommunications: 0,
    };
    this.progressIndicatorService.show();
    this.getOpportunityStatusCodes();
    this.selectedTileCode = this.engagementTiles.Initiated;

    this.gridTitle =
      this.engagementTileCodeDetails.find(
        (x) => x.tileCode === this.selectedTileCode
      )?.title || this._blank;

    this.getTilesCount();
    this.sortParamaterCode = engagementSortParamaterCode[this.selectedTileCode];
    this.gotoPage = 1;
    this.onPageChanged(this.gotoPage);
  }

  getRolesAndPermissions(): void {
    this.securityWebapiService.data
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (rules) => {
          if (!rules.empty) {
            this.isExtCommTileVisibile =
              rules[MatrixKeys.EXT_COM_EngagementTeamDashboard]?.[
                PermissionTypes.Visible
              ] ?? false;

            this.isGlobalEngTileVisibile =
              rules[MatrixKeys.GLOBAL_ENG_EngagementTeamDashboard]?.[
                PermissionTypes.Visible
              ] ?? false;

            this.removeTilesWithoutPermission();
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error('Error fetching security rules:', error);
        },
      });
  }

  fetchSubmissionType(): void {
    this.engagementWebapiService
      .getSubmissionTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: SubmissionType[]) => {
          this.submissionTypeData = response.map((item: SubmissionType) => {
            return {
              title: item.submissionTypeCode || IntakeConstant.EMPTY_SPACE,
              name: item.submissionTypeCode || IntakeConstant.EMPTY_SPACE,
              isChecked: true,
            };
          });
          this.setFilterData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(
            'An error occurred while fetching submission types:',
            err
          );
        },
      });
  }

  onTileSelected(selectedTileCode: string): void {
    const sameTileClicked: boolean = selectedTileCode === this.selectedTileCode;
    this.selectedTileCode = selectedTileCode;
    this.gridTitle =
      this.engagementTileCodeDetails.find(
        (x) => x.tileCode === this.selectedTileCode
      )?.title || this._blank;

    if (!sameTileClicked) {
      // Reset the filters and sorting state
      this.sortParamaterCode =
        engagementSortParamaterCode[this.selectedTileCode];
      this.isAscending = true;
      this.engagementDashboardfilters = [];
      // Reset the page number
      this.gotoPage = 1;
      this.onPageChanged(this.gotoPage);
    }
  }

  getTilesCount(): void {
    this.engagementWebapiService
      .getEngagementDashboardTilesCount()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: EngagementDashboardTilesCount) => {
          this.engagementDashboardTilesCountModel = response;
          this.tabsList = this.tabsList.map((tab: TabWithCounterType) => ({
            ...tab,
            count:
              this.engagementDashboardTilesCountModel[
                tab?.countCode ?? this._blank
              ],
          }));
          this.removeTilesWithoutPermission();
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching tiles count', err);
          // Optionally hide the progress indicator if needed
          this.progressIndicatorService.hide();
        },
      });
  }

  refreshGrid(): void {
    // TODO: Need to set model as [] to remove the existing data when we switch tab
    this.progressIndicatorService.show();
    this.engagementWebapiService
      .getEngagementDashBoardGridData(
        this.selectedTileCode,
        this.selectedTabCode,
        this.gotoPage,
        this.itemsPerPage,
        this.sortParamaterCode,
        this.isAscending,
        this.engagementDashboardfilters
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: DashboardDataCollection<EngagementDashboardData>) => {
          this.model = response.dataList;
          this.countforPagination = response.totalDataCount;
          this.progressIndicatorService.hide();
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching data', err);
          this.progressIndicatorService.hide();
        },
      });

    this.gridColumns = [...this.defaultGridColumns];

    if (this.selectedTileCode === this.engagementTiles.Initiated) {
      this.gridColumns = EngagementInitiatedColumns.map((tableColumn: string) =>
        this.gridColumns.find(
          (column: GridColumn) => column.name === tableColumn
        )
      ).filter((col) => col !== undefined);
    }
    if (this.selectedTileCode === this.engagementTiles.Submitted) {
      this.gridColumns = EngagementSubmittedColumns.map((tableColumn: string) =>
        this.gridColumns.find(
          (column: GridColumn) => column.name === tableColumn
        )
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.engagementTiles.Reviewed) {
      this.gridColumns = EngagementReviewedColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.engagementTiles.Completed) {
      this.gridColumns = EngagementCompletedColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (this.selectedTileCode === this.engagementTiles.GlobalEng) {
      this.gridColumns = EngagementGlobalEngColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (
      this.selectedTileCode === this.engagementTiles.ExtComm &&
      (this.selectedTabCode === engagementExtCommTabs.DRAFT.tabCode ||
        this.selectedTabCode === engagementExtCommTabs.REVIEWED.tabCode)
    ) {
      this.gridColumns = EngagementExtCommColumns.map((tableColumn) =>
        this.gridColumns.find((column) => column.name === tableColumn)
      ).filter((col) => col !== undefined);
    }

    if (
      this.selectedTileCode === this.engagementTiles.ExtComm &&
      (this.selectedTabCode === engagementExtCommTabs.SUBMITTED.tabCode ||
        this.selectedTabCode === engagementExtCommTabs.COMPLETED.tabCode)
    ) {
      const columns = EngagementExtCommColumns.filter(
        (column) => column !== 'actions'
      );
      this.gridColumns = columns
        .map((tableColumn) =>
          this.gridColumns.find((column) => column.name === tableColumn)
        )
        .filter((col) => col !== undefined);
    }

    // Adjust column widths dynamically excluding the expand-collapse icon column
    const totalColumns = this.gridColumns.length;
    const baseWidth = 78 / totalColumns; // 87% to leave space for the expand-collapse icon column
    this.gridColumns.forEach((column: GridColumn) => {
      if (
        column.name !== this.columnName.ActionByName &&
        column.name !== this.columnName.ClientName &&
        column.name !== this.columnName.LEP &&
        column.name != this.columnName.SubmissionTitle &&
        column.name != this.columnName.OpportunityIcon &&
        column.name !== this.columnName.Actions &&
        column.name !== this.columnName.JupiterID &&
        column.name !== this.columnName.TypeOfCommunication &&
        column.name !== this.columnName.ResponsiblePractitioner &&
        column.name !== this.columnName.SubmissionId &&
        column.name !== this.columnName.RequestReviewDate
      ) {
        column.width = `${baseWidth}%`;
      }

      if (
        column.name === this.columnName.OpportunityIcon ||
        column.name === this.columnName.JupiterID
      )
        column.width = column.minWidth;

      if (column.name === this.columnName.Actions)
        column.minWidth = column.width = '21rem';

      if (column.name === this.columnName.SubmissionTitle)
        column.minWidth = column.width = '18rem';
    });

    this.setActionButtons();
  }

  onPageChanged(value: number): void {
    this.gotoPage = value - 1;
    this.refreshGrid();
  }

  onValueChanged(item: number): void {
    this.itemsPerPage = item;
    this.gotoPage = 0;
    this.refreshGrid();
  }

  gridUpdatedEvent(event: {
    sortParamaterCode: number | null;
    isAscending: boolean;
    dashboardfilters: any[];
  }): void {
    const shouldRefreshGrid =
      this.sortParamaterCode !== event.sortParamaterCode ||
      this.isAscending !== event.isAscending ||
      JSON.stringify(this.engagementDashboardfilters) !==
        JSON.stringify(event.dashboardfilters);

    this.sortParamaterCode = event.sortParamaterCode;
    this.isAscending = event.isAscending;
    this.engagementDashboardfilters = event.dashboardfilters;
    if (shouldRefreshGrid) {
      this.refreshGrid();
    }
  }

  setActionButtons(): void {
    this.buttonOptionsList = [
      {
        label: 'Complete required action(s)',
        action: 'CompleteRequiredAction',
        buttonType: ButtonKind.primary,
        condition: 'enableSubmitResubmit',
        tooltip: null,
      },
      {
        label: 'Submit',
        action: 'Submit',
        buttonType: ButtonKind.primaryLoud,
        condition: 'enableSubmit',
        tooltip: null,
      },
      {
        label: 'Resubmit',
        action: 'Resubmit',
        buttonType: ButtonKind.primaryLoud,
        condition: 'enableSubmit',
        tooltip: null,
      },
      {
        label: 'Self-review',
        action: 'SelfReview',
        buttonType: ButtonKind.primaryLoud,
        condition: 'enableSelfReview',
        tooltip: null,
      },
      {
        label: 'Opt-out of self-review',
        action: 'OptOutSelfReview',
        buttonType: ButtonKind.primary,
        condition: 'reasonForOptOutSelfReview',
        tooltip: null,
      },
      {
        label: 'Archive and complete',
        action: 'ArchiveComplete',
        buttonType: ButtonKind.primaryLoud,
        condition: 'enableArchiveAndComplete',
        tooltip: null,
      },
      {
        label: 'Re-submit for review',
        action: 'ResubmitReview',
        buttonType: ButtonKind.primary,
        condition: 'enableResubmitForReview',
        tooltip: 'Use this option to submit for Risk Manager review',
      },
    ];
  }

  buttonSelected(event: {
    value: string;
    rowItem: EngagementDashboardData;
  }): void {
    const { value, rowItem } = event;
    if (this.selectedTileCode !== this.engagementTiles.ExtComm) {
      if (
        value === IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['SUBMIT'].action
      ) {
        this.updateSubmission(
          IntakeOppertunitySubmissionStatus.SUBMITTED,
          rowItem?.submissionId
        );
      }
      if (
        value ===
        IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['SELF_REVIEW'].action
      ) {
        this.updateSubmission(
          IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT,
          rowItem?.submissionId
        );
      }
      if (
        value ===
        IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['OPT_OUT_SELF_REVIEW']
          .action
      ) {
        this.getSubmissionDetails(rowItem);
        this.openOptOutReasonModal(rowItem);
      }
      if (
        value ===
        IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['ARCHIVE_COMPLETE'].action
      ) {
        this.updateSubmission(
          IntakeOppertunitySubmissionStatus.COMPLETED,
          rowItem?.submissionId
        );
      }
      if (
        value ===
        IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['RESUBMIT_REVIEW'].action
      ) {
        this.updateSubmission(
          IntakeOppertunitySubmissionStatus.RESUBMITTED,
          rowItem?.submissionId
        );
      }
      if (
        value ===
          IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['COMPLETE_ACTION']
            .action ||
        value === IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['RESUBMIT'].action
      ) {
        this.goToSubmission(rowItem?.jupiterId, rowItem?.submissionId);
      }
    } else {
      this.extCommButtonSelected(value, rowItem);
    }
  }

  extCommButtonSelected(value: string, rowItem: EngagementDashboardData) {
    if (
      value === IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['SUBMIT'].action
    ) {
      this.updateExtCommSubmissionStatus(
        rowItem?.submissionId,
        3,
        rowItem?.riskManager ?? this._blank
      );
    } else if (
      value === IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['RESUBMIT'].action
    ) {
      this.updateExtCommSubmissionStatus(
        rowItem?.submissionId,
        4,
        rowItem?.riskManager ?? this._blank
      );
    } else if (
      value ===
      IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['SELF_REVIEW'].action
    ) {
      this.updateExtCommSubmissionStatus(
        rowItem?.submissionId,
        7,
        rowItem?.riskManager ?? this._blank
      );
    } else if (
      value ===
      IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['OPT_OUT_SELF_REVIEW'].action
    ) {
      this.extCommOptOutSelfReview(rowItem?.submissionId);
    } else if (
      value ===
      IntakeConstant.ENGAGEMENT_DASHBOARD_BUTTONS['COMPLETE_ACTION'].action
    ) {
      if (rowItem?.communicationTypeId) {
        this.goToExtCommSubmission(
          rowItem?.communicationTypeId,
          rowItem?.submissionId
        );
      }
    }
  }

  public goToSubmission(jupiterId: string, submissionId: number): void {
    this.router.navigate(['/submission/opportunity-details', jupiterId], {
      state: {
        navigatingFromDashboard: true,
        submissionID: submissionId,
      },
    });
  }

  goToExtCommSubmission(
    communicationTypeId: number,
    submissionId: number
  ): void {
    this.router.navigate(
      ['/externalCommunications/details/', communicationTypeId],
      {
        queryParams: { submissionId: submissionId },
        state: {
          navigatingFromDashboard: true,
          submissionID: submissionId,
        },
      }
    );
  }

  updateExtCommSubmissionStatus(
    submissionId: number,
    submissionStatusId: number,
    riskManager: string
  ): void {
    this.progressIndicatorService.show();
    this.externalCommService
      .updateExtCommSubmissionStatus(submissionId, submissionStatusId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (res: GenericResponse) => {
          if (res.isSuccess) {
            this.setToast({
              ...this.toastMessageOptions,
              title: this.getExtCommToastMessage(
                submissionStatusId,
                !!riskManager
              ),
            });
            this.getTilesCount();
            this.refreshGrid();
          }
          this.progressIndicatorService.hide();
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error while updating submission', err);
          this.progressIndicatorService.hide();
        },
      });
  }

  openModalForRecentlyViewed(modalBtn?: ButtonComponent): void {
    let openRecentViewModal = this.modalService.open(RecentlyViewedComponent, {
      isFooter: false,
      size: 'lg',
      isInverse: false,
    });
    openRecentViewModal
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: () => {
          if (modalBtn) {
            modalBtn.focus();
          }
        },
        error: (err: Error) => {
          console.error('An error occurred:', err);
        },
      });
  }

  openOptOutReasonModal(modalBtn?: any): void {
    let optOutModelRef = this.modalService.open(OptOutPopupComponent);
    optOutModelRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: IntakeOptOutPopUpDataModel) => {
          if (data && data.submissionLevelUpdateSubmission !== undefined) {
            if (data.submissionLevelUpdateSubmission) {
              this.commonSectionDetails.reasonForOptOutSelfReview =
                data.optOutReason;
              this.saveIntakeDetails();
            } else {
              this.commonSectionDetails.reasonForOptOutSelfReview = undefined;
            }
          }
        },
        error: (err: Error) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  extCommOptOutSelfReview(submissionId: number): void {
    const optOutSelfReviewModalRef =
      this.modalService.open(OptOutPopupComponent);
    optOutSelfReviewModalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (popupData: IntakeOptOutPopUpDataModel) => {
          if (
            popupData.submissionLevelUpdateSubmission &&
            !!popupData.optOutReason
          ) {
            this.updateExtCommOptOutSelfReviewReason(
              submissionId,
              popupData.optOutReason
            );
          }
        },
      });
  }

  getSubmissionDetails(rowItem: EngagementDashboardData): void {
    this.progressIndicatorService.show();
    this.submissionService
      .getSubmissionBySubmissionId(
        rowItem?.jupiterId,
        rowItem?.submissionId?.toString()
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.commonSectionDetails = data;
          this.progressIndicatorService.hide();
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching submission', err);
          this.progressIndicatorService.hide();
        },
      });
  }

  saveIntakeDetails(): void {
    this.submissionService
      .saveIntakeDetailsCommonData(this.commonSectionDetails)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          this.setToast(this.optedOutToastOption);
          this.getTilesCount();
          this.refreshGrid();
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error saving data', err);
        },
      });
  }

  getOpportunityStatusCodes(): void {
    this.commonService
      .getOppertunityStatusCode()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[]) => {
          if (response) {
            this.opportunityStatusCodes = response;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error fetching intake status codes', err);
        },
      });
  }

  getSubmissionStatusId(statusName: string): number | undefined {
    return this.opportunityStatusCodes?.find(
      (status: OppertunitySubmissionStatusCode) =>
        status.statusName === statusName
    )?.id;
  }

  getSubmissionStatusName(id: number): string | undefined {
    return this.opportunityStatusCodes?.find(
      (status: OppertunitySubmissionStatusCode) => status.id === id
    )?.statusName;
  }

  updateSubmission(statusName: string, submissionId: number): void {
    const submissionStatusId = this.getSubmissionStatusId(statusName);
    if (submissionStatusId) {
      this.progressIndicatorService.show();
      this.submissionService
        .submitCommonSectionData(submissionId, submissionStatusId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: string) => {
            if (response) {
              const toastMsg = this.getToastMessage(statusName);
              if (toastMsg) this.setToast(toastMsg);

              this.commonSectionDetails =
                {} as IntakeSubmissionDetailsCommonSectionDataSendingModal;
              this.getTilesCount();
              this.refreshGrid();
            }
            this.progressIndicatorService.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.progressIndicatorService.hide();
          },
        });
    }
  }

  setFilterData(): void {
    this.filterDataList = [
      {
        name: 'submissionTypeFilterDataEngagement',
        data: this.submissionTypeData,
      },
    ];
  }

  getToastMessage(submissionStatusId: string): ToastOptions | null {
    switch (submissionStatusId) {
      case IntakeOppertunitySubmissionStatus.SUBMITTED:
        return submittedMessage;
      case IntakeOppertunitySubmissionStatus.RESUBMITTED:
        return resubmittedToastMessage;
      default:
        return null;
    }
  }

  getExtCommToastMessage(
    submissionStatusId: number,
    isRiskManagerPresent: boolean
  ): string {
    return (
      (!isRiskManagerPresent &&
      (submissionStatusId === 3 || submissionStatusId === 4)
        ? toastMessageTitle['SubmittedWithoutRM']
        : toastMessageTitle[
            getStatusEnumKeyByValue(submissionStatusId) ?? this._blank
          ]) ?? this._blank
    );
  }

  setToast(toast: any): void {
    this.toastService.createToast(toast);
  }

  tabChangeEvent(tabCode: string): void {
    this.selectedTabCode = tabCode;
    this.refreshGrid();
  }

  updateExtCommOptOutSelfReviewReason(
    submissionId: number,
    optOutReason: string
  ): void {
    this.progressIndicatorService.show();
    this.externalCommService
      .updateExtCommOptOutSelfReviewReason(submissionId, optOutReason)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (res: GenericResponse) => {
          if (res.isSuccess) {
            const toastMsg: string = toastMessageTitle['OptOutSelfReview'];
            this.setToast({
              ...this.toastMessageOptions,
              title: toastMsg,
            });
            this.progressIndicatorService.hide();
            this.getTilesCount();
            this.refreshGrid();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error while updating submission', err);
          this.progressIndicatorService.hide();
        },
      });
  }

  removeTilesWithoutPermission(): void {
    this.engagementTileCodeDetails = this.originalEngagementTileCodeDetails;

    // If permission is not present or Permission is present but count is 0
    if (
      !this.isExtCommTileVisibile ||
      (this.isExtCommTileVisibile &&
        this.engagementDashboardTilesCountModel?.externalCommunications < 1)
    ) {
      this.engagementTileCodeDetails = this.engagementTileCodeDetails.filter(
        (tile) => tile.tileCode !== this.engagementTiles.ExtComm
      );
    }

    if (!this.isGlobalEngTileVisibile) {
      this.engagementTileCodeDetails = this.engagementTileCodeDetails.filter(
        (tile) => tile.tileCode !== this.engagementTiles.GlobalEng
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
