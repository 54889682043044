import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { WINDOW_SCROLL_SIZE } from '../../app.constants';
import { EclipseHeaderService } from '../../http/eclipse-header.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.less'
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  isScrolled = false;

  constructor(private headerService: EclipseHeaderService) {
    this.checkScroll = this.checkScroll.bind(this);
  }

  ngOnInit(): void {
    this.checkScroll = this.checkScroll.bind(this);
    window.addEventListener('scroll', this.onWindowScroll);
    this.checkScroll();
  }

  ngAfterViewInit(): void {
    // Initialize the scroll listener to detect scroll after view initialization						
    this.checkScroll();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    if (this instanceof PageHeaderComponent)
      this.checkScroll();
  }

  checkScroll(): void {
    this.isScrolled = window.scrollY > WINDOW_SCROLL_SIZE; // Trigger the sticky header when scrolled more than 50px
    if(this.isScrolled){
      this.headerService.setEclipseHeaderVisiblity(false);
    }		
    else{
      this.headerService.setEclipseHeaderVisiblity(true);
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onWindowScroll);
  }
}
