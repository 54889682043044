<div class="margin-top-vs">
  <table class="dds-data-table dds-data-table_lg">
    <thead class="dds-data-table__header">
      <tr>
        <th
          *ngFor="let column of sampleColumns"
          class="dds-data-table__header-cell dds-data-table__header-cell_sorting"
          [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
          [ngClass]="{'right-align-heading': column.name === 'amount'}"
          (click)="sorted(column.name)"
          ddsFocus
        >
          <div class="dds-data-table__content" > 
            <span>{{ column.header || column.name }}</span>
            <span *ngIf="column.name === AMOUNT || column.name === FSS">
              <img src="\assets\images\dropdownIconArrow.svg" />
            </span>
          </div>
        </th>
      </tr>
    </thead>

    <tbody class="dds-data-table__body">
      <ng-container *ngFor="let row of tableData">
        <tr *ngIf="!row.deactivated" class="dds-data-table__row">
          <td *ngFor="let column of sampleColumns" class="dds-data-table__cell">
            <div *ngIf="column.name === CLIENTSERVICELEVEL">
              <div
                class="long-text"
                [ddsTooltip]="row[column.name]"
                [tooltipInvokeType]="options.tooltipInvokeType"
                [tooltipType]="options.tooltipType"
                [tooltipSize]="options.tooltipSize"
                [tooltipPosition]="options.tooltipPosition"
                [tooltipIndent]="options.tooltipIndent"
                [tooltipHasBeak]="options.tooltipHasBeak"
                [tooltipIsDisabled]="options.tooltipIsDisabled"
                [tooltipIsOutsideClick]="options.tooltipIsOutsideClick"
                [tooltipIsDynamic]="options.tooltipIsDynamic"
                [tooltipTheme]="options.tooltipTheme"
                [tooltipShowDelay]="options.tooltipShowDelay"
                [tooltipHideDelay]="options.tooltipHideDelay"
                ddsFocus
              >
                {{ row[column.name] }}
              </div>
            </div>
            <div *ngIf="!(column.name === CLIENTSERVICELEVEL)" [ngClass]="{'right-align': column.name === 'amount'}">
              <span *ngIf="column.name === AMOUNT">$</span>
              {{ row[column.name] }}
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<div
  *ngIf="totalTableData.length > INITIALSERVICETABLECOLUMN && !showAllSplits"
  class="button-container"
>
  <button *ngIf="showAllColumns" (click)="showLess()" class="show-less-btn">
    <span> Show less</span>
    <span><img src="\assets\images\greenUpIcon.svg" /></span>
  </button>
  <button *ngIf="!showAllColumns && !summary" (click)="showAll()" class="show-all-btn">
    <span>Show all ({{ totalTableData.length }})</span>
    <span><img src="\assets\images\greenDownIcon.svg" /></span>
  </button>
  <button *ngIf="!showAllColumns && summary" class="show-all-btn padding-overall clickable" (click)="openModal()"#modalBtn>
    <span>Show all splits ({{ totalTableData.length }})</span>
  </button>
</div>
