import { OpportunityDetailsNavModel } from '../../common/models/opportunity-details.model';
import { IntakeDocumentSubmissionTypeCode } from '../constants/intake_submission_type_code.enum';

export const commonSectionForSingleCoCheck = (
  submissionData: OpportunityDetailsNavModel[]
) => {
  return (
    submissionData.length === 1 &&
    submissionData[0].submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO
  );
};

export const randomString = (length: number = 15) => {
  let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const capitalizeFirstLetter =(val: string)=> {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export const copyToClipboard = (value: string) => { 
    if (value) {
        navigator.clipboard.writeText(value).then(
          () => {
          },
          (err) => {
            console.error('Could not copy text: ', err);
          }
        );
      }
 }
