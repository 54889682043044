<div class="tiles-container">
    <div class="page-header">
      <h2 class="title inline-block font-size-32">Audit Trail</h2>
    </div>
    <div class="row padding-top-l">
        <div *ngIf="clientNameValue !== null">
          <span class="eclipse-content-grey padding-right-xxs">Client name:</span>
          <span class="padding-right-vs">{{ clientNameValue }}</span>
        </div>
      <div class="row padding-top-s">
        <app-common-audit-trail
          [moduleId]="moduleId"
          [moduleName]="moduleName"
          (headerData)="headerData($event)"
        />
      </div>
    </div>
  </div>
  