import { OpportunityDetailsNavModel } from '../../common/models/opportunity-details.model';
import { ArchiveDetailsUpdatingModel, WbsWithArchiveDetailsModel } from '../../common/models/wbs-picker.model';
import { IntakeConstant } from '../constants/intake.constant';
import { IntakeDocumentSubmissionTypeCode } from '../constants/intake_submission_type_code.enum';

export const commonSectionForSingleCoCheck = (
  submissionData: OpportunityDetailsNavModel[]
) => {
  return (
    submissionData.length === 1 &&
    submissionData[0].submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO
  );
};

export const randomString = (length: number = 15) => {
  let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const capitalizeFirstLetter = (val: string) => {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
};

export const copyToClipboard = (value: string) => {
  if (value) {
    navigator.clipboard.writeText(value).then(
      () => {},
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  }
};

export const setArchiveDetails = (
    value: string,
    archiveFolderArrValue: string[]
  ): ArchiveDetailsUpdatingModel => {
    return {
      archiveNumber: value ?? IntakeConstant.EMPTY_SPACE,
      archiveName: value ?? IntakeConstant.EMPTY_SPACE,
      archiveID: value ?? IntakeConstant.EMPTY_SPACE,
      archiveFolder: value ?? IntakeConstant.EMPTY_SPACE,
      archiveFoldersArray: archiveFolderArrValue ?? [],
      clientName: value ?? IntakeConstant.EMPTY_SPACE,
      clientNumber: value ?? IntakeConstant.EMPTY_SPACE,
      partner: value ?? IntakeConstant.EMPTY_SPACE,
      business: value ?? IntakeConstant.EMPTY_SPACE,
      archiveDescription: value ?? IntakeConstant.EMPTY_SPACE,
      archiveStatus: value ?? IntakeConstant.EMPTY_SPACE,
    };
  }