import { SecurityWebapiService } from '../../http/security/security-webapi.service';
import { MatrixKeys, PermissionTypes } from '../constants/security-matrix';
import { Injectable } from '@angular/core';
import { PermissionsObj } from '../models/common-models';
interface PermissionsUpdate {
  key: string;
  enum: MatrixKeys;
  type: PermissionTypes;
}
@Injectable({
  providedIn: 'root',
})
export class PermissionCheckService {
  permissionEnums: typeof MatrixKeys = MatrixKeys;
  permissionType: typeof PermissionTypes = PermissionTypes;

  // ---------- Intake Permissions Starts ----------
  private readonly intakePermissions: PermissionsObj = {
    isIntakeNavSubmissionResetVisible: false,
    isIntakeNavSubmissionResetEnable: false,
    isIntakeNavSubmissionAdminVisible: false,
    isIntakeNavSubmissionAdminEnable: false,
    isIntakeNavSubmissionExportVisible: false,
    isIntakeNavSubmissionExportEnable: false,
    isIntakeNavSubmissionDiscontinueVisible: false,
    isIntakeNavSubmissionDiscontinueEnable: false,
    isIntakeHeaderMyDashboardVisible: false,
    isIntakeHeaderMyDashboardEnable: false,
    isIntakeHeaderOpportunityTitleVisible: false,
    isIntakeHeaderOpportunityTitleEnable: false,
    isIntakeHeaderGoToJupiterVisible: false,
    isIntakeHeaderGoToJupiterEnable: false,
    isIntakeHeaderGoToFocusVisible: false,
    isIntakeHeaderGoToFocusEnable: false,
    isIntakeLeftHandNavigationVisible: false,
    isIntakeLeftHandNavigationEnable: false,
    isIntakeSubmissionTitleVisible: false,
    isIntakeSubmissionTitleEnable: false,
    isIntakeSubmissionSubmissionTypeVisible: false,
    isIntakeSubmissionSubmissionTypeEnable: false,
    isIntakeSubmissionSubmissionStatusVisible: false,
    isIntakeSubmissionSubmissionStatusEnable: false,
    isIntakeSubmissionSubmissionIdVisible: false,
    isIntakeSubmissionSubmissionIdEnable: false,
    isIntakeSubmissionELSOWOpportunityIDVisible: false,
    isIntakeSubmissionELSOWOpportunityIDEnable: false,
    isIntakeSubmissionReSubmitBtnVisible: false,
    isIntakeSubmissionReSubmitBtnEnable: false,
    isIntakeSubmissionArchiveAndCompleteBtnVisible: false,
    isIntakeSubmissionArchiveAndCompleteBtnEnable: false,
    isIntakeSubmissionSubmitBtnVisible: false,
    isIntakeSubmissionSubmitBtnEnable: false,
    isIntakeSubmissionCompleteReviewBtnVisible: false,
    isIntakeSubmissionCompleteReviewBtnEnable: false,
    isIntakeSubmissionReturnForReworkBtnVisible: false,
    isIntakeSubmissionReturnForReworkBtnEnable: false,
    isIntakeSubmissionEligibleForSelfReviewVisible: false,
    isIntakeSubmissionEligibleForSelfReviewEnable: false,
    isIntakeSubmissionSubmissionTitleVisible: false,
    isIntakeSubmissionSubmissionTitleEnable: false,
    isIntakeSubmissionDeliverableVisible: false,
    isIntakeSubmissionDeliverableEnable: false,
    isIntakeSubmissionChangeOrderVisible: false,
    isIntakeSubmissionChangeOrderEnable: false,
    isIntakeSubmissionOtherVisible: false,
    isIntakeSubmissionOtherEnable: false,
    isIntakeSubmissionProposalVisible: false,
    isIntakeSubmissionProposalEnable: false,
    isIntakeSubmissionELSOWVisible: false,
    isIntakeSubmissionELSOWEnable: false,
    isIntakeSubmissionLEPConfirmationVisible: false,
    isIntakeSubmissionLEPConfirmationEnable: false,
    isIntakeSubmissionDocumentTableVisible: false,
    isIntakeSubmissionDocumentTableEnable: false,
    isIntakeSubmissionDocumentDownloadVisible: false,
    isIntakeSubmissionDocumentDownloadEnable: false,
    isIntakeSubmissionDocumentEditVisible: false,
    isIntakeSubmissionDocumentEditEnable: false,
    isIntakeSubmissionDocumentDeleteVisible: false,
    isIntakeSubmissionDocumentDeleteEnable: false,
    isIntakeSubmissionDocumentDragAndDropVisible: false,
    isIntakeSubmissionDocumentDragAndDropEnable: false,
    isIntakeSubmissionAttachDocumentVisible: false,
    isIntakeSubmissionAttachDocumentEnable: false,
    isIntakeSubmissionAddDocumentLinkVisible: false,
    isIntakeSubmissionAddDocumentLinkEnable: false,
    isIntakeSubmissionNotesAndFeedbackVisible: false,
    isIntakeSubmissionNotesAndFeedbackEnable: false,
    isIntakeSubmissionWorkflowCheckboxVisible: false,
    isIntakeSubmissionWorkflowCheckboxEnable: false,
    isIntakeSubmissionWorkflowRoleAndNameVisible: false,
    isIntakeSubmissionWorkflowRoleAndNameEnable: false,
    isIntakeSubmissionWorkflowActionVisible: false,
    isIntakeSubmissionWorkflowActionEnable: false,
    isIntakeSubmissionWorkflowAddReviewerVisible: false,
    isIntakeSubmissionWorkflowAddReviewerEnable: false,
    isIntakeSubmissionInternalQRMVisible: false,
    isIntakeSubmissionInternalQRMEnable: false,
    isIntakeSubmissionWBSVisible: false,
    isIntakeSubmissionWBSEnable: false,
    isIntakeSubmissionWBSDescriptionVisible: false,
    isIntakeSubmissionWBSDescriptionEnable: false,
    isIntakeServicesAndDeliveryVisible: false,
    isIntakeServicesAndDeliveryEnable: false,
    isIntakeComplexityDescriptionGreyGuidanceVisible: false,
    isIntakeComplexityDescriptionGreyGuidanceEnable: false,
    isIntakeOpportunityTitleVisible: false,
    isIntakeOpportunityTitleEnable: false,
    isIntakeOpportunityDescriptionVisible: false,
    isIntakeOpportunityDescriptionEnable: false,
    isIntakeOpportunityFieldsOutOfSyncVisible: false,
    isIntakeOpportunityFieldsOutOfSyncEnable: false,
    isIntakeOpportunityLinkedOpportunitiesVisible: false,
    isIntakeOpportunityLinkedOpportunitiesEnable: false,
    isIntakeOpportunityComplexityVisible: false,
    isIntakeOpportunityComplexityEnable: false,
    isIntakeOpportunityOfferingServiceQuestionsVisible: false,
    isIntakeOpportunityOfferingServiceQuestionsEnable: false,
    isIntakeOpportunityChangeArchiveVisible: false,
    isIntakeOpportunityChangeArchiveEnable: false,
    isIntakeOpportunityDetailsOpportunityNumberVisible: false,
    isIntakeOpportunityDetailsOpportunityNumberEnable: false,
    isIntakeOpportunityDetailsLEPVisible: false,
    isIntakeOpportunityDetailsLEPEnable: false,
    isIntakeOpportunityDetailsStartDateVisible: false,
    isIntakeOpportunityDetailsStartDateEnable: false,
    isIntakeOpportunityDetailsEndDateVisible: false,
    isIntakeOpportunityDetailsEndDateEnable: false,
    isIntakeOpportunityDetailsTotalFeesVisible: false,
    isIntakeOpportunityDetailsTotalFeesEnable: false,
    isIntakeOpportunityDetailsFeeTypeVisible: false,
    isIntakeOpportunityDetailsFeeTypeEnable: false,
    isIntakeOpportunityDetailsOpportunityDescriptionVisible: false,
    isIntakeOpportunityDetailsOpportunityDescriptionEnable: false,
    isIntakeWorkflowTechnicalVisible: false,
    isIntakeWorkflowTechnicalEnable: false,
    isIntakeCloseoutFormVisible: false,
    isIntakeCloseoutFormEnable: false,
    isIntakeNavSubmissionRelinkEnable: false,
    isIntakeNavSubmissionRelinkVisible: false,
  };
  // ---------- Intake Permissions Ends ----------

  // ---------- ExtComm Permissions Starts ----------
  private readonly extCommPermissions: PermissionsObj = {
    isExtCommAllFieldsVisible: false,
    isExtCommTitleAreaSubmissionTitleVisible: false,
    isExtCommTitleAreaEXTCOMMVisible: false,
    isExtCommTitleAreaSubmissionIdVisible: false,
    isExtCommTitleAreaSubmissionStatusVisible: false,
    isExtCommSelfReviewEligibiltyVisible: false,
    isExtCommRushRequestBadgeVisible: false,
    isExtCommCommunicationTypeVisible: false,
    isExtCommCommunicationTypeEnable: false,
    isExtCommPPMDTrainingCompletedVisible: false,
    isExtCommSubmissionTitleVisible: false,
    isExtCommSubmissionTitleEnable: false,
    isExtCommAudiencePublicationVisible: false,
    isExtCommAudiencePublicationEnable: false,
    isExtCommRelatedToGovernmentandPublicServicesVisible: false,
    isExtCommRelatedToGovernmentandPublicServicesEnable: false,
    isExtCommIndustrySectorVisible: false,
    isExtCommIndustrySectorEnable: false,
    isExtCommDeloitteCorporateFinanceVisible: false,
    isExtCommDeloitteCorporateFinanceEnable: false,
    isExtCommOfferingVisible: false,
    isExtCommOfferingEnable: false,
    isExtCommMarketOfferingVisible: false,
    isExtCommMarketOfferingEnable: false,
    isExtCommPrimaryContactVisible: false,
    isExtCommPrimaryContactEnable: false,
    isExtCommSecondaryContactVisible: false,
    isExtCommSecondaryContactEnable: false,
    isExtCommMarketingTalentPRContactVisible: false,
    isExtCommMarketingTalentPRContactEnable: false,
    isExtCommRiskAssessmentVisible: false,
    isExtCommRiskAssessmentEnable: false,
    isExtCommCommunicationAttributesVisible: false,
    isExtCommCommunicationAttributesEnable: false,
    isExtCommNotesAndFeedbackVisible: false,
    isExtCommNotesAndFeedbackEnable: false,
    isExtCommSubmitResubmitButtonVisible: false,
    isExtCommSubmitResubmitButtonEnable: false,
    isExtCommOptOutSelfReviewButtonVisible: false,
    isExtCommOptOutSelfReviewButtonEnable: false,
    isExtCommRequestedReviewDateVisible: false,
    isExtCommRequestedReviewDateEnable: false,
    isExtCommRequiredCheckboxVisible: false,
    isExtCommRequiredCheckboxEnable: false,
    isExtCommRiskManagerVisible: false,
    isExtCommRiskManagerEnable: false,
    isExtCommSupportingRiskManagerVisible: false,
    isExtCommSupportingRiskManagerEnable: false,
    isExtCommRMSupportVisible: false,
    isExtCommRMSupportEnable: false,
    isExtCommReviewerVisible: false,
    isExtCommReviewerEnable: false,
    isExtCommAddReviewerVisible: false,
    isExtCommAddReviewerEnable: false,
    isExtCommGuidanceUploadDocumentForReviewVisible: false,
    isExtCommDragAndDropEnable: false,
    isExtCommAddLinkVisible: false,
    isExtCommAddLinkEnable: false,
    isExtCommForcedSelfReviewEnable: false,
    isExtCommForcedSelfReviewVisible: false,
    isExtCommPpmdVisible: false,
    isExtCommPpmdEnable: false,
    isExtCommInternalQRMVisible: false,
    isExtCommInternalQRMEnable: false,
    // Below permission not added
    isExtCommUpdatedSelfReviewGuidanceVisible: false,
    isExtCommSolutionVisible: false,
    isExtCommSolutionEnable: false,
    isExtCommSubmissionDetailsTitleVisible: false,
    isExtCommDragAndDropVisible: false,
    isExtCommAttachDocumentsVisible: false,
    isExtCommAttachDocumentsEnable: false,
    isExtCommDiscontinueEnable: false,
  };
  // ---------- ExtComm Permissions Ends ----------

  // ---------- Global Eng Permissions Starts ----------
  private readonly globalEngPermissions: PermissionsObj = {
    isGlobalEngInitiationSubmissionTypeVisible: false,
    isGlobalEngInitiationSubmissionTypeEnable: false,
    isGlobalEngDefineEngagementSelectExistingVisible: false,
    isGlobalEngDefineEngagementSelectExistingEnable: false,
    isGlobalEngDefineEngagementSearchByEngagementVisible: false,
    isGlobalEngDefineEngagementSearchByEngagementEnable: false,
    isGlobalEngDefineEngagementSelectMemberFirmVisible: false,
    isGlobalEngDefineEngagementSelectMemberFirmEnable: false,
    isGlobalEngDefineEngagementSelectDeliveryCenterVisible: false,
    isGlobalEngDefineEngagementSelectDeliveryCenterEnable: false,
    isGlobalEngDefineEngagementEnterMemberFirmNameVisible: false,
    isGlobalEngDefineEngagementEnterMemberFirmNameEnable: false,
    isGlobalEngDefineEngagementSelectOpportunityVisible: false,
    isGlobalEngDefineEngagementSelectOpportunityEnable: false,
    isGlobalEngDefineEngagementSelectClientVisible: false,
    isGlobalEngDefineEngagementSelectClientEnable: false,
    isGlobalEngDefineEngagementEnterClientNameVisible: false,
    isGlobalEngDefineEngagementEnterClientNameEnable: false,
    isGlobalEngDefineEngagementConfirmAndProceedVisible: false,
    isGlobalEngDefineEngagementConfirmAndProceedEnable: false,
    isGlobalEngDefineEngagementCancelVisible: false,
    isGlobalEngDefineEngagementCancelEnable: false,
    isGlobalEngEngagementDetailsEngagementIdVisible: false,
    isGlobalEngEngagementDetailsEngagementIdEnable: false,
    isGlobalEngEngagementDetailsMemberFirmVisible: false,
    isGlobalEngEngagementDetailsMemberFirmEnable: false,
    isGlobalEngEngagementDetailsEngagementTitleVisible: false,
    isGlobalEngEngagementDetailsEngagementTitleEnable: false,
    isGlobalEngEngagementDetailsEngagementStartDateVisible: false,
    isGlobalEngEngagementDetailsEngagementStartDateEnable: false,
    isGlobalEngEngagementDetailsEngagementEndDateVisible: false,
    isGlobalEngEngagementDetailsEngagementEndDateEnable: false,
    isGlobalEngEngagementDetailsJupiterOpportunityIdVisible: false,
    isGlobalEngEngagementDetailsJupiterOpportunityIdEnable: false,
    isGlobalEngEngagementDetailsOfferingPortfolioVisible: false,
    isGlobalEngEngagementDetailsOfferingPortfolioEnable: false,
    isGlobalEngEngagementDetailsOfferingVisible: false,
    isGlobalEngEngagementDetailsOfferingEnable: false,
    isGlobalEngEngagementDetailsEngagementDescriptionVisible: false,
    isGlobalEngEngagementDetailsEngagementDescriptionEnable: false,
    isGlobalEngEngagementDetailsPPMDVisible: false,
    isGlobalEngEngagementDetailsPPMDEnable: false,
    isGlobalEngEngagementDetailsMemberFirmPartnerVisible: false,
    isGlobalEngEngagementDetailsMemberFirmPartnerEnable: false,
    isGlobalEngEngagementDetailsPRRIDVisible: false,
    isGlobalEngEngagementDetailsPRRIDEnable: false,
    isGlobalEngSubmissionDetailsSubmissionTitleVisible: false,
    isGlobalEngSubmissionDetailsSubmissionTitleEnable: false,
    isGlobalEngSubmissionDetailsChangeCodeVisible: false,
    isGlobalEngSubmissionDetailsChangeCodeEnable: false,
    isGlobalEngSubmissionDetailsWBSDescriptionVisible: false,
    isGlobalEngSubmissionDetailsWBSDescriptionEnable: false,
    isGlobalEngSubmissionDetailsArchiveVisible: false,
    isGlobalEngSubmissionDetailsArchiveEnable: false,
    isGlobalEngSubmissionDetailsDocumentsTableVisible: false,
    isGlobalEngSubmissionDetailsDocumentsTableEnable: false,
    isGlobalEngSubmissionDetailsDragAndDropVisible: false,
    isGlobalEngSubmissionDetailsDragAndDropEnable: false,
    isGlobalEngSubmissionDetailsArchiveAndCompleteVisible: false,
    isGlobalEngSubmissionDetailsArchiveAndCompleteEnable: false,
    isGlobalEngGearIconGlobalEngagementsVisible: false,
    isGlobalEngGearIconGlobalEngagementsEnable: false,
    isGlobalEngSubmitButtonVisible: false,
    isGlobalEngSubmitButtonEnable: false,
    isGlobalEngDiscontinueVisible: false,
    isGlobalEngDiscontinueEnable: false,
    isGlobalEngResetVisible: false,
    isGlobalEngResetEnable: false,
  };
  // ---------- Global Eng Permissions Ends ----------

  constructor(private readonly securityWebapiService: SecurityWebapiService) {}

  // Checks if a specific permission is present for a given field key.
  isPermissionPresent(fieldKey: string, permissionType: string): boolean {
    return this.securityWebapiService.isPermissionPresent(
      fieldKey,
      permissionType
    );
  }

  // Checks if the given permission enum has visibility - visible or enable permission.
  hasVisibilityPermission(permissionEnum: string): boolean {
    return (
      this.isPermissionPresent(permissionEnum, this.permissionType.Visible) ||
      this.isPermissionPresent(permissionEnum, this.permissionType.Enable)
    );
  }

  // Updates the permission keys in respective Permission Object.
  updatePermissionVariable(
    permissions: PermissionsUpdate[],
    permissionEntity: string
  ): void {
    permissions.forEach((permission: PermissionsUpdate) => {
      if (permission.type === this.permissionType.Visible) {
        (this as any)[permissionEntity][permission.key] =
          this.hasVisibilityPermission(permission.enum);
      } else if (permission.type === this.permissionType.Enable) {
        (this as any)[permissionEntity][permission.key] =
          this.isPermissionPresent(permission.enum, permission.type);
      }
    });
  }

  updateIntakePermissions(): void {
    const permissions: PermissionsUpdate[] = [
      {
        key: 'isIntakeNavSubmissionResetVisible',
        enum: this.permissionEnums.Intake_Nav_Submission_Reset,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeNavSubmissionResetEnable',
        enum: this.permissionEnums.Intake_Nav_Submission_Reset,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeNavSubmissionAdminVisible',
        enum: this.permissionEnums.Intake_Nav_Submission_Admin,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeNavSubmissionAdminEnable',
        enum: this.permissionEnums.Intake_Nav_Submission_Admin,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeNavSubmissionExportVisible',
        enum: this.permissionEnums.Intake_Nav_Submission_Export,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeNavSubmissionExportEnable',
        enum: this.permissionEnums.Intake_Nav_Submission_Export,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeNavSubmissionDiscontinueVisible',
        enum: this.permissionEnums.Intake_Nav_Submission_Discontinue,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeNavSubmissionDiscontinueEnable',
        enum: this.permissionEnums.Intake_Nav_Submission_Discontinue,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeHeaderMyDashboardVisible',
        enum: this.permissionEnums.Intake_Header_MyDashboard,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeHeaderMyDashboardEnable',
        enum: this.permissionEnums.Intake_Header_MyDashboard,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeHeaderOpportunityTitleVisible',
        enum: this.permissionEnums.Intake_Header_OpportunityTitle,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeHeaderOpportunityTitleEnable',
        enum: this.permissionEnums.Intake_Header_OpportunityTitle,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeHeaderGoToJupiterVisible',
        enum: this.permissionEnums.Intake_Header_GoToJupiter,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeHeaderGoToJupiterEnable',
        enum: this.permissionEnums.Intake_Header_GoToJupiter,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeHeaderGoToFocusVisible',
        enum: this.permissionEnums.Intake_Header_GoToFocus,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeHeaderGoToFocusEnable',
        enum: this.permissionEnums.Intake_Header_GoToFocus,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeLeftHandNavigationVisible',
        enum: this.permissionEnums.Intake_LeftHandNavigation,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeLeftHandNavigationEnable',
        enum: this.permissionEnums.Intake_LeftHandNavigation,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionTitleVisible',
        enum: this.permissionEnums.Intake_Submission_Title,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionTitleEnable',
        enum: this.permissionEnums.Intake_Submission_Title,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionSubmissionTitleVisible',
        enum: this.permissionEnums.Intake_Submission_SubmissionTitle,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionSubmissionTitleEnable',
        enum: this.permissionEnums.Intake_Submission_SubmissionTitle,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionSubmissionTypeVisible',
        enum: this.permissionEnums.Intake_Submission_SubmissionType,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionSubmissionTypeEnable',
        enum: this.permissionEnums.Intake_Submission_SubmissionType,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionSubmissionStatusVisible',
        enum: this.permissionEnums.Intake_Submission_SubmissionStatus,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionSubmissionStatusEnable',
        enum: this.permissionEnums.Intake_Submission_SubmissionStatus,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionSubmissionIdVisible',
        enum: this.permissionEnums.Intake_Submission_SubmissionId,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionSubmissionIdEnable',
        enum: this.permissionEnums.Intake_Submission_SubmissionId,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionELSOWOpportunityIDVisible',
        enum: this.permissionEnums.Intake_Submission_ELSOWOpportunityID,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionELSOWOpportunityIDEnable',
        enum: this.permissionEnums.Intake_Submission_ELSOWOpportunityID,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionReSubmitBtnVisible',
        enum: this.permissionEnums.Intake_Submission_ReSubmitBtn,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionReSubmitBtnEnable',
        enum: this.permissionEnums.Intake_Submission_ReSubmitBtn,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionArchiveAndCompleteBtnVisible',
        enum: this.permissionEnums.Intake_Submission_ArchiveAndCompleteBtn,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionArchiveAndCompleteBtnEnable',
        enum: this.permissionEnums.Intake_Submission_ArchiveAndCompleteBtn,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionSubmitBtnVisible',
        enum: this.permissionEnums.Intake_Submission_SubmitBtn,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionSubmitBtnEnable',
        enum: this.permissionEnums.Intake_Submission_SubmitBtn,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionCompleteReviewBtnVisible',
        enum: this.permissionEnums.Intake_Submission_CompleteReviewBtn,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionCompleteReviewBtnEnable',
        enum: this.permissionEnums.Intake_Submission_CompleteReviewBtn,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionReturnForReworkBtnVisible',
        enum: this.permissionEnums.Intake_Submission_ReturnForReworkBtn,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionReturnForReworkBtnEnable',
        enum: this.permissionEnums.Intake_Submission_ReturnForReworkBtn,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionEligibleForSelfReviewVisible',
        enum: this.permissionEnums.Intake_Submission_EligibleForSelfReview,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionEligibleForSelfReviewEnable',
        enum: this.permissionEnums.Intake_Submission_EligibleForSelfReview,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionDeliverableVisible',
        enum: this.permissionEnums.Intake_Submission_Deliverable,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionDeliverableEnable',
        enum: this.permissionEnums.Intake_Submission_Deliverable,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionChangeOrderVisible',
        enum: this.permissionEnums.Intake_Submission_ChangeOrder,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionChangeOrderEnable',
        enum: this.permissionEnums.Intake_Submission_ChangeOrder,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionOtherVisible',
        enum: this.permissionEnums.Intake_Submission_Other,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionOtherEnable',
        enum: this.permissionEnums.Intake_Submission_Other,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionProposalVisible',
        enum: this.permissionEnums.Intake_Submission_Proposal,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionProposalEnable',
        enum: this.permissionEnums.Intake_Submission_Proposal,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionELSOWVisible',
        enum: this.permissionEnums.Intake_Submission_ELSOW,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionELSOWEnable',
        enum: this.permissionEnums.Intake_Submission_ELSOW,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionLEPConfirmationVisible',
        enum: this.permissionEnums.Intake_Submission_LEPConfirmation,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionLEPConfirmationEnable',
        enum: this.permissionEnums.Intake_Submission_LEPConfirmation,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionDocumentTableVisible',
        enum: this.permissionEnums.Intake_Submission_DocumentTable,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionDocumentTableEnable',
        enum: this.permissionEnums.Intake_Submission_DocumentTable,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionDocumentDownloadVisible',
        enum: this.permissionEnums.Intake_Submission_DocumentDownload,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionDocumentDownloadEnable',
        enum: this.permissionEnums.Intake_Submission_DocumentDownload,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionDocumentEditVisible',
        enum: this.permissionEnums.Intake_Submission_DocumentEdit,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionDocumentEditEnable',
        enum: this.permissionEnums.Intake_Submission_DocumentEdit,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionDocumentDeleteVisible',
        enum: this.permissionEnums.Intake_Submission_DocumentDelete,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionDocumentDeleteEnable',
        enum: this.permissionEnums.Intake_Submission_DocumentDelete,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionDocumentDragAndDropVisible',
        enum: this.permissionEnums.Intake_Submission_DocumentDragAndDrop,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionDocumentDragAndDropEnable',
        enum: this.permissionEnums.Intake_Submission_DocumentDragAndDrop,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionAttachDocumentVisible',
        enum: this.permissionEnums.Intake_Submission_AttachDocument,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionAttachDocumentEnable',
        enum: this.permissionEnums.Intake_Submission_AttachDocument,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionAddDocumentLinkVisible',
        enum: this.permissionEnums.Intake_Submission_AddDocumentLink,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionAddDocumentLinkEnable',
        enum: this.permissionEnums.Intake_Submission_AddDocumentLink,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionNotesAndFeedbackVisible',
        enum: this.permissionEnums.Intake_Submission_NotesAndFeedback,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionNotesAndFeedbackEnable',
        enum: this.permissionEnums.Intake_Submission_NotesAndFeedback,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionWorkflowCheckboxVisible',
        enum: this.permissionEnums.Intake_Submission_WorkflowCheckbox,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionWorkflowCheckboxEnable',
        enum: this.permissionEnums.Intake_Submission_WorkflowCheckbox,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionWorkflowRoleAndNameVisible',
        enum: this.permissionEnums.Intake_Submission_WorkflowRoleAndName,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionWorkflowRoleAndNameEnable',
        enum: this.permissionEnums.Intake_Submission_WorkflowRoleAndName,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionWorkflowActionVisible',
        enum: this.permissionEnums.Intake_Submission_WorkflowAction,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionWorkflowActionEnable',
        enum: this.permissionEnums.Intake_Submission_WorkflowAction,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionWorkflowAddReviewerVisible',
        enum: this.permissionEnums.Intake_Submission_WorkflowAddReviewer,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionWorkflowAddReviewerEnable',
        enum: this.permissionEnums.Intake_Submission_WorkflowAddReviewer,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionInternalQRMVisible',
        enum: this.permissionEnums.Intake_Submission_InternalQRM,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionInternalQRMEnable',
        enum: this.permissionEnums.Intake_Submission_InternalQRM,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionWBSVisible',
        enum: this.permissionEnums.Intake_Submission_WBS,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionWBSEnable',
        enum: this.permissionEnums.Intake_Submission_WBS,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeSubmissionWBSDescriptionVisible',
        enum: this.permissionEnums.Intake_Submission_WBSDescription,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeSubmissionWBSDescriptionEnable',
        enum: this.permissionEnums.Intake_Submission_WBSDescription,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeServicesAndDeliveryVisible',
        enum: this.permissionEnums.Intake_ServicesAndDelivery,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeServicesAndDeliveryEnable',
        enum: this.permissionEnums.Intake_ServicesAndDelivery,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeComplexityDescriptionGreyGuidanceVisible',
        enum: this.permissionEnums.Intake_Complexity_DescriptionGreyGuidance,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeComplexityDescriptionGreyGuidanceEnable',
        enum: this.permissionEnums.Intake_Complexity_DescriptionGreyGuidance,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityTitleVisible',
        enum: this.permissionEnums.Intake_Opportunity_Title,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityTitleEnable',
        enum: this.permissionEnums.Intake_Opportunity_Title,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityDescriptionVisible',
        enum: this.permissionEnums.Intake_Opportunity_Description,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityDescriptionEnable',
        enum: this.permissionEnums.Intake_Opportunity_Description,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityFieldsOutOfSyncVisible',
        enum: this.permissionEnums.Intake_Opportunity_FieldsOutOfSync,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityFieldsOutOfSyncEnable',
        enum: this.permissionEnums.Intake_Opportunity_FieldsOutOfSync,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityLinkedOpportunitiesVisible',
        enum: this.permissionEnums.Intake_Opportunity_LinkedOpportunities,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityLinkedOpportunitiesEnable',
        enum: this.permissionEnums.Intake_Opportunity_LinkedOpportunities,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityComplexityVisible',
        enum: this.permissionEnums.Intake_Opportunity_Complexity,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityComplexityEnable',
        enum: this.permissionEnums.Intake_Opportunity_Complexity,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityOfferingServiceQuestionsVisible',
        enum: this.permissionEnums.Intake_Opportunity_OfferingServiceQuestions,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityOfferingServiceQuestionsEnable',
        enum: this.permissionEnums.Intake_Opportunity_OfferingServiceQuestions,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityChangeArchiveVisible',
        enum: this.permissionEnums.Intake_Opportunity_ChangeArchive,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityChangeArchiveEnable',
        enum: this.permissionEnums.Intake_Opportunity_ChangeArchive,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityDetailsOpportunityNumberVisible',
        enum: this.permissionEnums.Intake_OpportunityDetails_OpportunityNumber,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityDetailsOpportunityNumberEnable',
        enum: this.permissionEnums.Intake_OpportunityDetails_OpportunityNumber,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityDetailsLEPVisible',
        enum: this.permissionEnums.Intake_OpportunityDetails_LEP,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityDetailsLEPEnable',
        enum: this.permissionEnums.Intake_OpportunityDetails_LEP,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityDetailsStartDateVisible',
        enum: this.permissionEnums.Intake_OpportunityDetails_StartDate,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityDetailsStartDateEnable',
        enum: this.permissionEnums.Intake_OpportunityDetails_StartDate,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityDetailsEndDateVisible',
        enum: this.permissionEnums.Intake_OpportunityDetails_EndDate,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityDetailsEndDateEnable',
        enum: this.permissionEnums.Intake_OpportunityDetails_EndDate,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityDetailsTotalFeesVisible',
        enum: this.permissionEnums.Intake_OpportunityDetails_TotalFees,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityDetailsTotalFeesEnable',
        enum: this.permissionEnums.Intake_OpportunityDetails_TotalFees,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityDetailsFeeTypeVisible',
        enum: this.permissionEnums.Intake_OpportunityDetails_FeeType,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityDetailsFeeTypeEnable',
        enum: this.permissionEnums.Intake_OpportunityDetails_FeeType,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeOpportunityDetailsOpportunityDescriptionVisible',
        enum: this.permissionEnums
          .Intake_OpportunityDetails_OpportunityDescription,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeOpportunityDetailsOpportunityDescriptionEnable',
        enum: this.permissionEnums
          .Intake_OpportunityDetails_OpportunityDescription,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeWorkflowTechnicalVisible',
        enum: this.permissionEnums.Intake_Workflow_Technical,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeWorkflowTechnicalEnable',
        enum: this.permissionEnums.Intake_Workflow_Technical,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeCloseoutFormVisible',
        enum: this.permissionEnums.Intake_AllSubmissionTypes_CloseoutForm,
        type: this.permissionType.Visible,
      },
      {
        key: 'isIntakeCloseoutFormEnable',
        enum: this.permissionEnums.Intake_AllSubmissionTypes_CloseoutForm,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeNavSubmissionRelinkEnable',
        enum: this.permissionEnums.Intake_Submission_RelinkSubmission,
        type: this.permissionType.Enable,
      },
      {
        key: 'isIntakeNavSubmissionRelinkVisible',
        enum: this.permissionEnums.Intake_Submission_RelinkSubmission,
        type: this.permissionType.Visible,
      },
    ];

    this.updatePermissionVariable(permissions, 'intakePermissions');
  }

  updateExtCommPermissions(): void {
    const permissions: PermissionsUpdate[] = [
      {
        key: 'isExtCommAllFieldsVisible',
        enum: this.permissionEnums.EXT_COM_SubmissionListAllFields,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommTitleAreaSubmissionTitleVisible',
        enum: this.permissionEnums.EXT_COM_TitleAreaSubmissionTitle,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommTitleAreaEXTCOMMVisible',
        enum: this.permissionEnums.EXT_COM_TitleAreaEXTCOMM,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommTitleAreaSubmissionIdVisible',
        enum: this.permissionEnums.EXT_COM_TitleAreaSubmissionId,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommTitleAreaSubmissionStatusVisible',
        enum: this.permissionEnums.EXT_COM_TitleAreaSubmissionStatus,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommSelfReviewEligibiltyVisible',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsSelfReviewEligibilty,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommRushRequestBadgeVisible',
        enum: this.permissionEnums.EXT_COM_TitleAreaRushRequestBadge,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommCommunicationTypeVisible',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsCommunicationType,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommCommunicationTypeEnable',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsCommunicationType,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommPPMDTrainingCompletedVisible',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsPPMDTrainingCompleted,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommSubmissionTitleVisible',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsSubmissionTitle,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommSubmissionTitleEnable',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsSubmissionTitle,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommAudiencePublicationVisible',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsAudiencePublications,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommAudiencePublicationEnable',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsAudiencePublications,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommRelatedToGovernmentandPublicServicesVisible',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsRelatedToGovernmentandPublicServices,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommRelatedToGovernmentandPublicServicesEnable',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsRelatedToGovernmentandPublicServices,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommIndustrySectorVisible',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsIndustrySector,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommIndustrySectorEnable',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsIndustrySector,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommDeloitteCorporateFinanceVisible',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsDeloitteCorporateFinance,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommDeloitteCorporateFinanceEnable',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsDeloitteCorporateFinance,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommOfferingVisible',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsOffering,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommOfferingEnable',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsOffering,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommMarketOfferingVisible',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsMarketOffering,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommMarketOfferingEnable',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsMarketOffering,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommPrimaryContactVisible',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsPrimaryContact,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommPrimaryContactEnable',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsPrimaryContact,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommSecondaryContactVisible',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsSecondaryContact,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommSecondaryContactEnable',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsSecondaryContact,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommMarketingTalentPRContactVisible',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsMarketingTalentPRContact,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommMarketingTalentPRContactEnable',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsMarketingTalentPRContact,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommRiskAssessmentVisible',
        enum: this.permissionEnums.EXT_COM_RiskAssessment,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommRiskAssessmentEnable',
        enum: this.permissionEnums.EXT_COM_RiskAssessment,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommCommunicationAttributesVisible',
        enum: this.permissionEnums.EXT_COM_CommunicationAttributes,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommCommunicationAttributesEnable',
        enum: this.permissionEnums.EXT_COM_CommunicationAttributes,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommNotesAndFeedbackVisible',
        enum: this.permissionEnums.EXT_COM_NotesAndFeedback,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommNotesAndFeedbackEnable',
        enum: this.permissionEnums.EXT_COM_NotesAndFeedback,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommSubmitResubmitButtonVisible',
        enum: this.permissionEnums.EXT_COM_SubmitResubmit,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommSubmitResubmitButtonEnable',
        enum: this.permissionEnums.EXT_COM_SubmitResubmit,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommOptOutSelfReviewButtonVisible',
        enum: this.permissionEnums.EXT_COM_OptOutOfSelfReview,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommOptOutSelfReviewButtonEnable',
        enum: this.permissionEnums.EXT_COM_OptOutOfSelfReview,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommRequestedReviewDateVisible',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsRequestedReviewDate,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommRequestedReviewDateEnable',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsRequestedReviewDate,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommRequiredCheckboxVisible',
        enum: this.permissionEnums.EXT_COM_WorkflowRequiredCheckbox,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommRequiredCheckboxEnable',
        enum: this.permissionEnums.EXT_COM_WorkflowRequiredCheckbox,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommRiskManagerVisible',
        enum: this.permissionEnums.EXT_COM_WorkflowRiskManager,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommRiskManagerEnable',
        enum: this.permissionEnums.EXT_COM_WorkflowRiskManager,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommSupportingRiskManagerVisible',
        enum: this.permissionEnums.EXT_COM_WorkflowSupportingRiskManager,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommSupportingRiskManagerEnable',
        enum: this.permissionEnums.EXT_COM_WorkflowSupportingRiskManager,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommRMSupportVisible',
        enum: this.permissionEnums.EXT_COM_WorkflowRMSupport,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommRMSupportEnable',
        enum: this.permissionEnums.EXT_COM_WorkflowRMSupport,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommReviewerVisible',
        enum: this.permissionEnums.EXT_COM_WorkflowReviewer,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommReviewerEnable',
        enum: this.permissionEnums.EXT_COM_WorkflowReviewer,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommAddReviewerVisible',
        enum: this.permissionEnums.EXT_COM_WorkflowAddReviewer,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommAddReviewerEnable',
        enum: this.permissionEnums.EXT_COM_WorkflowAddReviewer,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommGuidanceUploadDocumentForReviewVisible',
        enum: this.permissionEnums
          .EXT_COM_DocumentsGuidanceUploadDocumentForReview,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommDragAndDropEnable',
        enum: this.permissionEnums.EXT_COM_DocumentsDragAndDrop,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommAddLinkVisible',
        enum: this.permissionEnums.EXT_COM_DocumentsAddLink,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommAddLinkEnable',
        enum: this.permissionEnums.EXT_COM_DocumentsAddLink,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommUpdatedSelfReviewGuidanceVisible',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsUpdatedSelfReviewGuidance,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommSolutionVisible',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsSolution,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommSolutionEnable',
        enum: this.permissionEnums.EXT_COM_SubmissionDetailsSolution,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommSubmissionDetailsTitleVisible',
        enum: this.permissionEnums
          .EXT_COM_SubmissionDetailsSubmissionDetailsTitle,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommDragAndDropVisible',
        enum: this.permissionEnums.EXT_COM_DocumentsDragAndDrop,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommAttachDocumentsVisible',
        enum: this.permissionEnums.EXT_COM_DocumentsAttachDocuments,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommAttachDocumentsEnable',
        enum: this.permissionEnums.EXT_COM_DocumentsAttachDocuments,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommDiscontinueEnable',
        enum: this.permissionEnums.EXT_COM_Discontinue,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommCompleteReviewEnable',
        enum: this.permissionEnums.EXT_COM_CompleteReview,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommCompleteReviewVisible',
        enum: this.permissionEnums.EXT_COM_CompleteReview,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommReturnForReworkEnable',
        enum: this.permissionEnums.EXT_COM_ReturnForRework,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommForcedSelfReviewEnable',
        enum: this.permissionEnums.EXT_COM_ForceSelfReview,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommForcedSelfReviewVisible',
        enum: this.permissionEnums.EXT_COM_ForceSelfReview,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommPpmdVisible',
        enum: this.permissionEnums.EXT_COM_WorkflowPpmd,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommPpmdEnable',
        enum: this.permissionEnums.EXT_COM_WorkflowPpmd,
        type: this.permissionType.Enable,
      },
      {
        key: 'isExtCommInternalQRMVisible',
        enum: this.permissionEnums.EXT_COM_InternalQRM,
        type: this.permissionType.Visible,
      },
      {
        key: 'isExtCommInternalQRMEnable',
        enum: this.permissionEnums.EXT_COM_InternalQRM,
        type: this.permissionType.Enable,
      },
    ];

    this.updatePermissionVariable(permissions, 'extCommPermissions');
  }

  updateGlobalEngPermissions(): void {
    const permissions: PermissionsUpdate[] = [
      {
        key: 'isGlobalEngInitiationSubmissionTypeVisible',
        enum: this.permissionEnums.GLOBAL_ENG_Initiation_SubmissionType,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngInitiationSubmissionTypeEnable',
        enum: this.permissionEnums.GLOBAL_ENG_Initiation_SubmissionType,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngDefineEngagementSelectExistingVisible',
        enum: this.permissionEnums.GLOBAL_ENG_DefineEngagement_SelectExisting,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngDefineEngagementSelectExistingEnable',
        enum: this.permissionEnums.GLOBAL_ENG_DefineEngagement_SelectExisting,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngDefineEngagementSearchByEngagementVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_DefineEngagement_SearchByEngagement,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngDefineEngagementSearchByEngagementEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_DefineEngagement_SearchByEngagement,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngDefineEngagementSelectMemberFirmVisible',
        enum: this.permissionEnums.GLOBAL_ENG_DefineEngagement_SelectMemberFirm,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngDefineEngagementSelectMemberFirmEnable',
        enum: this.permissionEnums.GLOBAL_ENG_DefineEngagement_SelectMemberFirm,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngDefineEngagementSelectDeliveryCenterVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_DefineEngagement_SelectDeliveryCenter,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngDefineEngagementSelectDeliveryCenterEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_DefineEngagement_SelectDeliveryCenter,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngDefineEngagementEnterMemberFirmNameVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_DefineEngagement_EnterMemberFirmName,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngDefineEngagementEnterMemberFirmNameEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_DefineEngagement_EnterMemberFirmName,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngDefineEngagementSelectOpportunityVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_DefineEngagement_SelectOpportunity,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngDefineEngagementSelectOpportunityEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_DefineEngagement_SelectOpportunity,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngDefineEngagementSelectClientVisible',
        enum: this.permissionEnums.GLOBAL_ENG_DefineEngagement_SelectClient,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngDefineEngagementSelectClientEnable',
        enum: this.permissionEnums.GLOBAL_ENG_DefineEngagement_SelectClient,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngDefineEngagementEnterClientNameVisible',
        enum: this.permissionEnums.GLOBAL_ENG_DefineEngagement_EnterClientName,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngDefineEngagementEnterClientNameEnable',
        enum: this.permissionEnums.GLOBAL_ENG_DefineEngagement_EnterClientName,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngDefineEngagementConfirmAndProceedVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_DefineEngagement_ConfirmAndProceed,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngDefineEngagementConfirmAndProceedEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_DefineEngagement_ConfirmAndProceed,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngDefineEngagementCancelVisible',
        enum: this.permissionEnums.GLOBAL_ENG_DefineEngagement_Cancel,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngDefineEngagementCancelEnable',
        enum: this.permissionEnums.GLOBAL_ENG_DefineEngagement_Cancel,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsEngagementIdVisible',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_EngagementId,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsEngagementIdEnable',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_EngagementId,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsMemberFirmVisible',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_MemberFirm,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsMemberFirmEnable',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_MemberFirm,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsEngagementTitleVisible',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_EngagementTitle,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsEngagementTitleEnable',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_EngagementTitle,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsEngagementStartDateVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_EngagementStartDate,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsEngagementStartDateEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_EngagementStartDate,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsEngagementEndDateVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_EngagementEndDate,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsEngagementEndDateEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_EngagementEndDate,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsJupiterOpportunityIdVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_JupiterOpportunityId,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsJupiterOpportunityIdEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_JupiterOpportunityId,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsOfferingPortfolioVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_OfferingPortfolio,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsOfferingPortfolioEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_OfferingPortfolio,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsOfferingVisible',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_Offering,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsOfferingEnable',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_Offering,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsEngagementDescriptionVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_EngagementDescription,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsEngagementDescriptionEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_EngagementDescription,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsPPMDVisible',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_PPMD,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsPPMDEnable',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_PPMD,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsMemberFirmPartnerVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_MemberFirmPartner,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsMemberFirmPartnerEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_EngagementDetails_MemberFirmPartner,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngEngagementDetailsPRRIDVisible',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_PRRID,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngEngagementDetailsPRRIDEnable',
        enum: this.permissionEnums.GLOBAL_ENG_EngagementDetails_PRRID,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngSubmissionDetailsSubmissionTitleVisible',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_SubmissionTitle,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngSubmissionDetailsSubmissionTitleEnable',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_SubmissionTitle,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngSubmissionDetailsChangeCodeVisible',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_ChangeCode,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngSubmissionDetailsChangeCodeEnable',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_ChangeCode,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngSubmissionDetailsWBSDescriptionVisible',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_WBSDescription,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngSubmissionDetailsWBSDescriptionEnable',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_WBSDescription,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngSubmissionDetailsArchiveVisible',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_Archive,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngSubmissionDetailsArchiveEnable',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_Archive,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngSubmissionDetailsDocumentsTableVisible',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_DocumentsTable,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngSubmissionDetailsDocumentsTableEnable',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_DocumentsTable,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngSubmissionDetailsDragAndDropVisible',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_DragAndDrop,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngSubmissionDetailsDragAndDropEnable',
        enum: this.permissionEnums.GLOBAL_ENG_SubmissionDetails_DragAndDrop,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngSubmissionDetailsArchiveAndCompleteVisible',
        enum: this.permissionEnums
          .GLOBAL_ENG_SubmissionDetails_ArchiveAndComplete,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngSubmissionDetailsArchiveAndCompleteEnable',
        enum: this.permissionEnums
          .GLOBAL_ENG_SubmissionDetails_ArchiveAndComplete,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngGearIconGlobalEngagementsVisible',
        enum: this.permissionEnums.GLOBAL_ENG_GearIcon_GlobalEngagements,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngGearIconGlobalEngagementsEnable',
        enum: this.permissionEnums.GLOBAL_ENG_GearIcon_GlobalEngagements,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngSubmitButtonVisible',
        enum: this.permissionEnums.GLOBAL_ENG_SubmitButton,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngSubmitButtonEnable',
        enum: this.permissionEnums.GLOBAL_ENG_SubmitButton,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngDiscontinueVisible',
        enum: this.permissionEnums.GLOBAL_ENG_Discontinue,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngDiscontinueEnable',
        enum: this.permissionEnums.GLOBAL_ENG_Discontinue,
        type: this.permissionType.Enable,
      },
      {
        key: 'isGlobalEngResetVisible',
        enum: this.permissionEnums.GLOBAL_ENG_Reset,
        type: this.permissionType.Visible,
      },
      {
        key: 'isGlobalEngResetEnable',
        enum: this.permissionEnums.GLOBAL_ENG_Reset,
        type: this.permissionType.Enable,
      },
    ];
    this.updatePermissionVariable(permissions, 'globalEngPermissions');
  }

  get getIntakePermissionsObj(): PermissionsObj {
    return this.intakePermissions;
  }

  get getExtCommPermissionsObj(): PermissionsObj {
    return this.extCommPermissions;
  }

  get getGlobalEngPermissionObj(): PermissionsObj {
    return this.globalEngPermissions;
  }
}
