import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { Subject, takeUntil } from 'rxjs';
import { SubmissionService } from '../../../http/intake/submission.service';
import { DocumentService } from '../../../http/document/document.service';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { sampleColumns } from './external-communication-export-pdf-helper';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  ButtonKind,
  Column,
  ExtThemes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../intake/constants/intake.constant';
import { HttpErrorResponse } from '@angular/common/http';
import { ExternalCommunicationService } from '../../../http/external-communication/external-communication.service';
import {
  ExtCommSubmissionDocumentDetailsPopupModel,
  SubmissionDocumentDetails,
} from '../../../common/models/external-communication.model';

@Component({
  selector: 'app-external-communication-export-pdf',
  templateUrl: './external-communication-export-pdf.component.html',
  styleUrl: './external-communication-export-pdf.component.less',
})
export class ExternalCommunicationExportPdfComponent {
  @Input() submissionId!: number;
  selectedDocumentIds: number[] = [];

  sampleColumns: Column[] = sampleColumns;
  isAllSelected: boolean = false;
  selectedRowCount: number = 0;
  docToShow: ExtCommSubmissionDocumentDetailsPopupModel[] = [];
  readonly #unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    private readonly modalService: ModalService,
    private readonly documentService: DocumentService,
    private readonly progressIndicatorService: ProgressIndicatorService,
    private readonly externalCommService: ExternalCommunicationService
  ) {}

  cancelButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: IntakeConstant.UI_ELEMENT_SIZE,
    width: WidthState.fixed,
    isLoading: false,
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: true,
    customClass: '',
    role: 'button',
  };

  ngOnInit(): void {
    this.getDocumentMetaData();
  }

  getDocumentMetaData(): void {
    this.progressIndicatorService.show();
    this.externalCommService
      .getDocumentDetails(this.submissionId)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (data: SubmissionDocumentDetails[]) => {
          this.progressIndicatorService.hide();
          this.docToShow = data.map((item) => ({ ...item, selected: false }));
        },
        error: (err: HttpErrorResponse) => {
          console.error('Error in fetching document data', err);
          this.progressIndicatorService.hide();
        },
      });
  }

  toggleRow(rowData: ExtCommSubmissionDocumentDetailsPopupModel): void {
    rowData.selected = !rowData.selected;
    this.checkSelectedRow(this.docToShow);
  }

  selectAllRows(
    status: boolean,
    data: ExtCommSubmissionDocumentDetailsPopupModel[]
  ): void {
    data.forEach((item) => (item.selected = status));
    this.checkSelectedRow(this.docToShow);
  }

  checkSelectedRow(data: ExtCommSubmissionDocumentDetailsPopupModel[]): void {
    this.selectedRowCount = data.filter((row) => row.selected).length;
    this.isAllSelected = data.length === this.selectedRowCount;
  }

  exportSelectedDocs(): void {
    this.progressIndicatorService.show();
    this.docToShow
      .filter((document) => document.selected)
      .forEach((document) =>
        this.selectedDocumentIds.push(document.submissionDocumentId)
      );

    let requestBody = {
      submissionId: this.submissionId,
      documentIds: this.selectedDocumentIds,
    };

    this.documentService.downloadPdf(requestBody, 'extcomm').subscribe({
      next: (response: any) => {
        if (response?.status === 200) {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          let fileName = 'ExtCommSubmission.pdf'; // Default file name

          if (contentDisposition) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
              contentDisposition
            );
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          this.close(true);
          this.progressIndicatorService.hide();
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error('Download error:', error);
        this.progressIndicatorService.hide();
      },
    });
  }

  close(isConfirmed: boolean): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
