import {
  Component,
  ViewChildren,
  QueryList,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  Column,
  SortOptions,
  SortingStates,
} from '@usitsdasdesign/dds-ng/shared';
import {
  StickerDirective,
  StickerOptions,
} from '@usitsdasdesign/dds-ng/sticker';
import { DaterangepickerOptions } from '@usitsdasdesign/dds-ng/datepicker';
import {
  dateRangePickerOptions,
  filterStickerOptions,
  auditTrailTableColumns,
  showMoreOptions,
  showLessOptions
} from './common-audit-trail.helper';
import { BrowserTabConstant } from '../../../common/constants/browser-tab-constant';
import { AuditTrailService } from '../../../http/audit-trail/audit-trail.service';
import {
  CommonAuditTrailResponseDataModel,
  FilterType,
  HeaderDescriptionDataModel,
  CommonAuditTrailResponseModel,
  AuditDescription,
} from '../../../common/models/audit-trail.model';
import { Subject, takeUntil } from 'rxjs';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { CommonAuditConstant } from '../../../common/constants/common-audit-constant';
import { HttpErrorResponse } from '@angular/common/http';
import { convertAuditDate, convertAuditTime, convertDate, convertTime } from '../../../common/utils/common-util';
import { AuditTrailTableHeaderSearchParameter } from '../../constants/audit-trail.enum';
import { ActivatedRoute, Params } from '@angular/router';

export interface GridColumn extends Column {
  filterable?: boolean;
  searchValue?: any;
}

@Component({
  selector: 'app-common-audit-trail',
  templateUrl: './common-audit-trail.component.html',
  styleUrl: './common-audit-trail.component.less'
})
export class CommonAuditTrailComponent  {
  @ViewChildren('stickerDir') sticker!: QueryList<StickerDirective>;

  @Input() moduleId!: number | null;
  @Input() moduleName!:string;
  @Output() headerData: EventEmitter<any> =
  new EventEmitter<any>();

  private readonly destroy$: Subject<void> = new Subject<void>();
  sampleColumns: Column[] = auditTrailTableColumns;
  tableData!: CommonAuditTrailResponseDataModel[];
  showMoreOptions:any=showMoreOptions;
  showLessOptions:any=showLessOptions;

  constructor(
    private readonly auditService: AuditTrailService,
    private route: ActivatedRoute, 
    private readonly progressIndicator: ProgressIndicatorService
  ) {}

  dateRangePickerOptions: DaterangepickerOptions = dateRangePickerOptions;

  filterStickerOptions: StickerOptions = filterStickerOptions;

  pageNumber: number = 0;
  filterColumnCode: number = 3;
  isAscending: boolean = false;
  filterArray: FilterType[] = [];
  totalDataCount: number = 0;

  sortingState: SortOptions | null = {
    property: this.sampleColumns[2].name,
    descending: false,
    dataType: this.sampleColumns[2].dataType,
  };

  itemsPerPage: number = 10;
  itemsPerPageList: number[] = [10, 25, 50, 100];
  isShownFilter!: boolean;
  filteringColumn!: GridColumn;

  public auditTrailTableCode = AuditTrailTableHeaderSearchParameter;

  descriptionLabel: string = CommonAuditConstant.DESCRIPTION_LABEL;

  ngOnInit(): void {
    this.progressIndicator.show();
    document.title = BrowserTabConstant.Browser_TabName_AuditTrail;
      this.refreshTable();   
  }

  pageChanged(value: number): void {
    this.pageNumber = value-1;
    this.refreshTable();
  }

  toggleFilter(column: Column): void {
    this.filteringColumn = column;
    this.isShownFilter = true;
  }

  filterSorted(sortedState: string): void {
    this.sortingState = {
      dataType: this.filteringColumn.dataType,
      descending: sortedState === SortingStates.descending,
      property: this.filteringColumn.name,
    };
    this.isAscending = sortedState === SortingStates.ascending;
    this.filterColumnCode =
      this.auditTrailTableCode[
        this.filteringColumn
          .name as keyof typeof AuditTrailTableHeaderSearchParameter
      ];
    this.filterHide();
    this.refreshTable();
  }

  filterApply(searchValue: string | [Date, Date]): void {
    this.filterColumnCode =
      this.auditTrailTableCode[
        this.filteringColumn
          .name as keyof typeof AuditTrailTableHeaderSearchParameter
      ];

      let formattedCriteria = '';

      if (Array.isArray(searchValue)) {
        const [fromDate, toDate] = searchValue;
        const fromDateString = fromDate.toLocaleDateString(CommonAuditConstant.DATESTRING_FORMAT_US);
        const toDateString = toDate.toLocaleDateString(CommonAuditConstant.DATESTRING_FORMAT_US);
        formattedCriteria = `${fromDateString},${toDateString}`;
      } else if (typeof searchValue === 'string') {
        formattedCriteria = searchValue;
      }
      const existingFilterIndex = this.filterArray.findIndex(
        (filter) => filter.filtertypecode === this.filterColumnCode
      );
      if (typeof searchValue === 'string' && searchValue.trim() === CommonAuditConstant.EMPTY_SPACE) {
        if (existingFilterIndex !== -1) {
          this.filterArray.splice(existingFilterIndex, 1);
        }
      } else {
        if (existingFilterIndex !== -1) {
          this.filterArray[existingFilterIndex].filtercriteria =
            formattedCriteria;
        } else {
          this.filterArray.push({
            filtertypecode: this.filterColumnCode,
            filtercriteria: formattedCriteria,
          });
        }
      }
    this.refreshTable();
    this.filterHide();
  }

  filterHide(): void {
    this.sticker.forEach((item) => {
      if (item.isActive) {
        item.hide();
      }
    });
  }

  filterOnHidden(): void {
    this.isShownFilter = false;
  }

  itemsPerPageChanged(value: number): void {
    this.pageNumber = 0;
    this.itemsPerPage = value;
    this.refreshTable();
  }

  filterSearchHandler(): void {
    if (this.filteringColumn.searchValue.length === 0) {
      this.filterApply(CommonAuditConstant.EMPTY_SPACE);
    }
  }
  clickShowLess(event: Event,row:any): void {
    row.isShowMoreVisible = false;
  }
  clickshowmore(event: Event,row:any): void {
    row.isShowMoreVisible = true;
  }
  refreshTable(): void {
    this.progressIndicator.show();
    const auditRequestParams = {
      PageNumber: this.pageNumber,
      pageSize: this.itemsPerPage,
      SortParameterCode: this.filterColumnCode,
      IsAscending: this.isAscending,
      FilterData: this.filterArray,
    };
    if (this.moduleId != null && this.moduleId != undefined) {
      this.auditService
        .getAuditTrailDetails(
          this.moduleId,
          this.moduleName,
          auditRequestParams
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: CommonAuditTrailResponseModel) => {
            this.progressIndicator.hide();
            this.tableData = response.dataList;
            const headerDescription = {
              clientName: (response?.dataList!=null && response?.dataList.length > 0) ? response?.dataList[0].clientName : null
            };
            this.headerData.emit(headerDescription);
            this.totalDataCount = response.totalDataCount;
          },
          error: (err: HttpErrorResponse) => {
            this.progressIndicator.hide();
            console.error('Error fetching audit trail data', err);
          },
        });
    }
  }

  convertDate(data: string): string {
    return convertAuditDate(data);
  }

  convertTime(data: string): string {
    return convertAuditTime(data);
  }
  parseJsonIntoArray(data: any): AuditDescription[] {
    return JSON.parse(data) as AuditDescription[];
  }
}
