import {
  ErrorState,
  LabelPosition,
  Size,
  Themes,
} from '@usitsdasdesign/dds-ng/shared';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { IntakeDocumentSubmissionTypeCode } from './intake_submission_type_code.enum';

export class IntakeConstant {
  public static readonly PAGE_NUMBER: number = 15;
  public static readonly TEXTAREA_CHAR_LIMIT: number = 1000;
  public static readonly TEXTAREA_CHAR_LIMIT_BIGGEST: number = 5000;
  public static readonly TEXTAREA_MIN_HEIGHT: number = 70;
  public static readonly INITIAL_SERVICE_TABLE_COLUMN: number = 3;
  public static readonly TOOLTIP_WIDTH = 450;
  public static readonly SET_TIMEOUT_DURATION = 2000;
  public static readonly FINAL_ARCHIVE_READY_ID = 3;
  public static readonly ENGAGEMENT_LETTER_CODE = 6;
  public static readonly ORIGNAL_Letter_SOW_Contract_CODE = 7;
  public static readonly ORIGNAL_Letter_CODE = 8;
  public static readonly SEARCH_DEBOUNCE_TIME: number = 500;
  public static readonly SEARCH_STRING_LIMIT: number = 2;
  public static readonly OTHER_STATUS: number = 11;
  public static readonly TIMOUT_TIMER: number = 300;
  public static readonly SubmittedStatusID: number = 3; //Todo: Need to remove harcoded statusID's in future

  public static readonly SEARCH_OPTION_PLACEHOLDER: string =
    'Search by Jupiter Opportunity ID, Client Name or Opportunity Leader';
  public static readonly SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER: string =
    'Search by user name'; // TODO: need yo update as per story
    public static readonly SEARCH_OPTION_PLACEHOLDER_FOR_PEOPLE_PICKER_TECHNICAL_REVIEWER: string =
    'Search by user name for technical reviewer'; 
  public static readonly SEARCH_OPTION_PLACEHOLDER_FOR_COMMON_CO_WITHOUT_EL_SOW: string =
    'Search by Jupiter Opportunity ID';
  public static readonly CHANGE_ORDER_LABEL: string = 'Change Order';
  public static readonly CHANGE_ORDER_LABEL_CODE: string = 'CO';

  public static readonly NON_CHANGE_ORDER: string = 'nonChangeOrder';
  public static readonly AMOUNT: string = 'amount';
  public static readonly FSS: string = 'fss';
  public static readonly CLIENT_SERVICE_LEVEL: string = 'clientServiceLevel';
  public static readonly ASCENDING: string = 'ascending';
  public static readonly DECENDING: string = 'decending';
  public static readonly REGULATORY_CONSIDERATION: string =
    'Regulatory considerations';
  public static readonly YES: string = 'Yes';
  public static readonly NO: string = 'No';
  public static readonly NOT_YET_DETERMINED: string = 'Not yet determined';
  public static readonly DISCONTINUED: string = 'Discontinued';
  public static readonly PURGED: string = 'Purged';
  public static readonly PROPOSAL: string = 'PRO';
  public static readonly YES_TEAMING: string = 'Yes, teaming';

  public static readonly UI_ELEMENT_SIZE: Size = Size.lg;
  public static readonly TEXTAREA_SIZE: string = IntakeConstant.UI_ELEMENT_SIZE;
  public static readonly TEXTAREA_CHAR_COUNT_POSTION: string = 'bottom-right';

  public static readonly DEFAULT_FOR_EMPTY_FIELDS: string = '-';
  public static readonly ENTER_TEXT_PLACEHOLDER: string = 'Enter text';
  public static readonly NO_MATCHES_FOUND: string = 'No matches found';
  public static readonly NONE_EXPLAIN: string = 'None [Explain]';
  public static readonly SHOW_MORE_TEXT: string = 'Show more';
  public static readonly SHOW_LESS_TEXT: string = 'Show less';
  public static readonly SUBMIT_BTN_LABEL: string = 'Submit';
  public static readonly RESUBMIT_FOR_REVIEW_BTN_LABEL: string = 'Resubmit for review';
  public static readonly ARCHIVE_AND_COMPLETE_BTN_LABEL: string = 'Archive and complete';
  public static readonly ADD_REVIEWER_BTN_LABEL: string = 'Add reviewer';
  public static readonly COPY_SUCCESS_MSG: string = 'Copied to clipboard!';
  public static readonly DDS_ICON_ARROW_UP: string = 'dds-icon_arrow-up';
  public static readonly DDS_ICON_ARROW_DOWN: string = 'dds-icon_arrow-down';
  public static readonly FEE_TYPE_LABEL: string = 'Fee type';
  public static readonly DURATION_LABEL: string = 'Duration';
  public static readonly CROSS_BUSINESS_LABEL: string =
    'Cross-business opportunity (i.e.,Green dot)';
  public static readonly DESCRIPTION_LABEL: string = 'Description';
  public static readonly ARCHIVE_LABEL: string = 'Archive';
  public static readonly DOCUMENT_SUBMISSION_LABEL: string =
    'Document Submission(s)';
  public static readonly INDEPENDENCE_LABEL: string =
    'Independence & conflicts  - DESC - restricted (acceptance in closeout status)';
  public static readonly TEAM_LABEL: string = 'Team';
  public static readonly CLIENT_LABEL: string = 'Client';
  public static readonly DATE_FORMAT: string = 'MM/dd/yyyy';
  public static readonly OPPORTUNITY_COMPLEXITY_LABEL: string =
    'Opportunity complexity';
  public static readonly PRODUCTS_AND_TOOLS_LABEL: string =
    'Products and tools';
  public static readonly THIRD_PARTY_LABEL: string = 'Third-party involvement';
  public static readonly SENSITIVE_DATA_LABEL: string = 'Sensitive data';
  public static readonly DATA_INDELOITTE_LABEL: string = `Data in Deloitte's or Deloitte's hosted environment`;
  public static readonly MEMBER_FIRM_INVOLVEMENT_LABEL: string =
    'Member firm involvement ';
  public static readonly USE_OF_EXISTING_LABEL: string =
    'Use of existing MSAs or Contract Maker GBTs';
  public static readonly DELOITTE_SOW_LABEL: string =
    'Deloitte standard SOW/EL template referred';
  public static readonly VIEW_EDIT_LABEL: string = 'View & edit';
  public static readonly FINAL_ARCHIVE_READY_VERSION: string ='Final - Archive ready version';
  public static readonly SUBMITTED_STATUS: string ='Submitted'



  public static readonly TEXT_AREA_SETTINGS_GLOBAL: TextareaOptions = {
    label: '',
    labelPosition: LabelPosition.external,
    placeholder: IntakeConstant.ENTER_TEXT_PLACEHOLDER,
    minHeight: IntakeConstant.TEXTAREA_MIN_HEIGHT,
    isRequired: false,
    isInverse: false,
    readonly: false,
    isError: false,
    errorState: ErrorState.default,
    description: '',
    customClass: '',
    errorMessage: '',
    maxLength: IntakeConstant.TEXTAREA_CHAR_LIMIT,
    maxLengthPosition: IntakeConstant.TEXTAREA_CHAR_COUNT_POSTION,
    size: IntakeConstant.TEXTAREA_SIZE,
  };


  public static readonly INTAKE_CHECKBOX_THEME_COLOR = Themes.green;
  public static readonly YES_NO_CHECKBOX_VALUE_TO_BOOLEAN = new Map<
    string | boolean,
    boolean
  >([
    ['yes', true],
    ['no', false],
  ]);
  public static readonly BOOLEAN_CHECKBOX_VALUE_TO_YES_NO = new Map<
    boolean | undefined | string,
    string
  >([
    [true, 'yes'],
    [false, 'no'],
  ]);
}


export const VALID_DOCUMENT_TYPES: Record<
  IntakeDocumentSubmissionTypeCode,
  string[]
> = {
  [IntakeDocumentSubmissionTypeCode.PRO]: [
    'Proposal',
    'Request for Proposal (RFP)',
  ],
  [IntakeDocumentSubmissionTypeCode.EL_SOW]: [
    'Engagement Letter/Statement of Work',
    'Interim Engagement Letter',
  ],
  [IntakeDocumentSubmissionTypeCode.DEL]: ['Deliverable'],
  [IntakeDocumentSubmissionTypeCode.CO]: [
    'Engagement Letter',
    'Original executed Engagement Letter/SOW/Contract',
    'Original Executed Interim Engagement Letter',
  ],
  [IntakeDocumentSubmissionTypeCode.OTH]: [
    'Conflict Check Name Match Report',
    'Conflict Analyst summary spreadsheet of responses',
    'Other (include comments below)',
  ],
};

