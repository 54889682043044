import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ButtonKind, Column, ExtThemes, Size, TabThemeType, Themes, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../constants/intake.constant';
import { TabsOptions } from '@usitsdasdesign/dds-ng/tabs';

export const opportunityTableColumns: Column[] = [
  {
    name: 'opportunityNumber',
    header: 'Opportunity ID:',
    dataType: 'string',
    minWidth: '6.25rem',
  },
  {
    name: 'opportunityName',
    header: 'Opportunity name:',
    dataType: 'string',
    minWidth: '8.75rem',
  },
  {
    name: 'stage',
    header: 'Opportunity stage:',
    dataType: 'string',
    minWidth: '8.75rem',
  },
  {
    name: 'client',
    header: 'Client:',
    dataType: 'string',
    minWidth: '8.75rem',
  },
  {
    name: 'leader',
    header: 'Lead Engagement Partner:',
    dataType: 'string',
    minWidth: '8.75rem',
  },

  {
    name: 'totalGlobalOpportunityAmount',
    header: 'Fees $:',
    dataType: 'string',
    minWidth: '12.25rem',
  },
];


  export const options: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.sm,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_arrow-up',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: 'Show Less',
    customClass: IntakeConstant.EMPTY_SPACE,
    role: 'button'
  }

  export const optionsShowMore: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.sm,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_arrow-down',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: 'Show More',
    customClass: IntakeConstant.EMPTY_SPACE,
    role: 'button'
  }

  export const tabContainerOptions: TabsOptions = {
      theme: Themes.green,
      themeType: TabThemeType.border,
      size: Size.lg,
      ariaLabel: 'Horizontal tabs',
      disabled: false,
      isResponsive: false,
      }

