import { AdminPageComponent } from './admin-page/admin-page.component';

export const ADMIN_MODULE_ROUTES = [
  {
    path: 'admin-page',
    children: [
      {
        path: '',
        component: AdminPageComponent,
      },
    ],
  },
];
