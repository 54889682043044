<div [autosave-module]="communicationAttributeSection">
  <div
    class="row"
    *ngIf="
      communicationAttributesInput.isEnableIsIncludesAlcoholSweepstakesAwards
    "
  >
    <div>
      <span
        *ngIf="!communicationAttributesInput.isIncludesAlcoholSweepstakesAwards"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span>
        <span>Does this communication include:<br /></span>
        <span class="padding-left-sm">- Alcohol related content<br /></span>
        <span class="padding-left-sm">- Mentions of sweepstakes<br /></span>
        <span class="padding-left-sm">- Award announcements</span></span
      >
    </div>

    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="
              communicationAttributesInput.isIncludesAlcoholSweepstakesAwards
            "
            [name]="alcohol_related_content_radioGroupOptions.name"
            [isUncheckable]="
              alcohol_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="alcohol_related_content_radioGroupOptions.isRequired"
            [errorState]="alcohol_related_content_radioGroupOptions.errorState"
            ngDefaultControl
            (stateChanged)="checkRequiredFields()"
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="communicationAttributesInput.isEnablePodcastInvolvesOtherParties"
  >
    <div>
      <span
        *ngIf="!communicationAttributesInput.podcastInvolvesOtherParties"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span> {{ podcast_related_content_radioGroupOptions?.title }}</span>
    </div>

    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="
              communicationAttributesInput.podcastInvolvesOtherParties
            "
            [name]="podcast_related_content_radioGroupOptions.name"
            [isUncheckable]="
              podcast_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="podcast_related_content_radioGroupOptions.isRequired"
            [errorState]="podcast_related_content_radioGroupOptions.errorState"
            ngDefaultControl
            (stateChanged)="checkRequiredFields()"
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="communicationAttributesInput.isEnableVideoInvolvesOtherParties"
  >
    <div>
      <span
        *ngIf="!communicationAttributesInput.videoInvolvesOtherParties"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span>
        {{
          other_parties_participation_related_content_radioGroupOptions?.title
        }}</span
      >
    </div>

    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="communicationAttributesInput.videoInvolvesOtherParties"
            [name]="
              other_parties_participation_related_content_radioGroupOptions.name
            "
            [isUncheckable]="
              other_parties_participation_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="
              other_parties_participation_related_content_radioGroupOptions.isRequired
            "
            [errorState]="
              other_parties_participation_related_content_radioGroupOptions.errorState
            "
            ngDefaultControl
            (stateChanged)="checkRequiredFields()"
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="communicationAttributesInput.isEnableIsLiveStreamed">
    <div>
      <span
        *ngIf="!communicationAttributesInput.isLiveStreamed"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span> {{ live_stream_related_content_radioGroupOptions?.title }}</span>
    </div>

    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="communicationAttributesInput.isLiveStreamed"
            [name]="live_stream_related_content_radioGroupOptions.name"
            [isUncheckable]="
              live_stream_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="
              live_stream_related_content_radioGroupOptions.isRequired
            "
            [errorState]="
              live_stream_related_content_radioGroupOptions.errorState
            "
            ngDefaultControl
            (stateChanged)="checkRequiredFields()"
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="communicationAttributesInput.isEnableIsPublishedInThirdParty"
  >
    <div>
      <span
        *ngIf="!communicationAttributesInput.isPublishedInThirdParty"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span>
        {{
          published_in_third_party_related_content_radioGroupOptions?.title
        }}</span
      >
    </div>
    <div
      class="d-flex"
      *ngIf="
        communicationAttributesInput.isEnableIsPublishedInThirdPartyGuidance &&
        communicationAttributesInput.isPublishedInThirdParty === YES
      "
    >
      <div class="styled-box-green">
        <img src="\assets\images\green-pin-icon.svg" alt="lock-icon" />
      </div>
      <div class="padding-left-sm col-8">
        Reach out to the mailbox for additional guidance on the proper agreement
        to use. Contact US Consulting External Communications Review with
        questions.
      </div>
    </div>
    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            autosave
            [disabled]="!isControlEnable"
            [(ngModel)]="communicationAttributesInput.isPublishedInThirdParty"
            [name]="
              published_in_third_party_related_content_radioGroupOptions.name
            "
            [isUncheckable]="
              published_in_third_party_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="
              published_in_third_party_related_content_radioGroupOptions.isRequired
            "
            [errorState]="
              published_in_third_party_related_content_radioGroupOptions.errorState
            "
            ngDefaultControl
            (stateChanged)="onPublishedInThirdPartyChange($event)"
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        communicationAttributesInput.isEnableIsRelatedToConference &&
        communicationAttributesInput.isPublishedInThirdParty === YES
      "
    >
      <div>
        <span
          *ngIf="!communicationAttributesInput.isPublishedInThirdParty"
          class="icon-required-yellow padding-left-sm"
        ></span>
        <span>
          {{ submission_related_to_conference_radioGroupOptions?.title }}</span
        >
      </div>
      <div
        class="d-flex"
        *ngIf="
          communicationAttributesInput.isEnableIsRelatedToConference &&
          communicationAttributesInput.isRelatedToConference === 'yes'
        "
      >
        <div class="styled-box-green">
          <img src="\assets\images\green-pin-icon.svg" alt="lock-icon" />
        </div>
        <div class="padding-left-sm col-8">
          Use the standard Deloitte speaker agreement.
          <a href="mailto:usconsultingexcommreviews@deloitte.com"
            >US Consulting External Communications Review</a
          >
          with questions.
        </div>
      </div>
      <div>
        <div class="row padding-top-xs">
          <div class="col-12">
            <dds-radio-group
              autosave
              [(ngModel)]="communicationAttributesInput.isRelatedToConference"
              [name]="submission_related_to_conference_radioGroupOptions.name"
              [isUncheckable]="
                submission_related_to_conference_radioGroupOptions.isUncheckable
              "
              [isRequired]="
                submission_related_to_conference_radioGroupOptions.isRequired
              "
              [errorState]="
                submission_related_to_conference_radioGroupOptions.errorState
              "
              ngDefaultControl
            >
              <ng-container
                *ngFor="let radio of yes_no_related_content_radio_values"
              >
                <dds-radio
                  [value]="radio.value"
                  [customClass]="'padding-right-half-rem'"
                  [label]="radio.options.label"
                  [theme]="radio.options.theme"
                ></dds-radio>
                <br />
              </ng-container>
            </dds-radio-group>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row"
      *ngIf="communicationAttributesInput.isPublishedInThirdParty === YES"
    >
      <div *ngIf="communicationAttributesInput.isEnablePublishedLocation">
        <span
          *ngIf="!communicationAttributesInput.publishedLocation"
          class="icon-required-yellow padding-left-sm"
        ></span>
        <span> List the publication or website where it will be posted:</span>
      </div>

      <div *ngIf="communicationAttributesInput.isEnablePublishedLocation">
        <div class="row padding-top-xs">
          <div class="col-12">
            <dds-input
              [disabled]="!isControlEnable"
              autosave
              [placeholder]="textareaOptions.placeholder ?? ''"
              [maxLength]="100"
              [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
              [size]="textareaOptions.size ?? ''"
              [(ngModel)]="communicationAttributesInput.publishedLocation"
              (ngModelChange)="checkRequiredFields()"
            >
            </dds-input>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="
      communicationAttributesInput.isEnableIsUsingSponsorOrThirdPartyTemplate
    "
  >
    <div>
      <span
        *ngIf="!communicationAttributesInput.isUsingSponsorOrThirdPartyTemplate"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span> {{ sponsorship_related_content_radioGroupOptions?.title }}</span>
    </div>

    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="
              communicationAttributesInput.isUsingSponsorOrThirdPartyTemplate
            "
            [name]="sponsorship_related_content_radioGroupOptions.name"
            [isUncheckable]="
              sponsorship_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="
              sponsorship_related_content_radioGroupOptions.isRequired
            "
            [errorState]="
              sponsorship_related_content_radioGroupOptions.errorState
            "
            ngDefaultControl
            (stateChanged)="checkRequiredFields()"
          >
            <ng-container
              *ngFor="let radio of sponsorship_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label ?? ''"
                [theme]="radio.options.theme ?? ''"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="communicationAttributesInput.isEnableIsSurveyExternalParties"
  >
    <div>
      <span
        *ngIf="!communicationAttributesInput.isSurveyExternalParties"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span>
        {{
          survey_external_parties_related_content_radioGroupOptions?.title
        }}</span
      >
    </div>

    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="communicationAttributesInput.isSurveyExternalParties"
            [name]="
              survey_external_parties_related_content_radioGroupOptions.name
            "
            [isUncheckable]="
              survey_external_parties_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="
              survey_external_parties_related_content_radioGroupOptions.isRequired
            "
            [errorState]="
              survey_external_parties_related_content_radioGroupOptions.errorState
            "
            (stateChanged)="onSurveyExternalPartiesChange($event)"
            ngDefaultControl
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
    <div *ngIf="communicationAttributesInput.isSurveyExternalParties === YES">
      <div
        class="row"
        *ngIf="communicationAttributesInput.isEnableTypeOfSurvey"
      >
        <div>
          <span
            *ngIf="!communicationAttributesInput.typeOfSurvey"
            class="icon-required-yellow padding-left-sm"
          ></span>
          <span>
            {{ type_of_survey_related_content_radioGroupOptions?.title }}</span
          >
        </div>

        <div>
          <div class="row padding-top-xs">
            <div class="col-12">
              <dds-radio-group
                [disabled]="!isControlEnable"
                autosave
                [(ngModel)]="communicationAttributesInput.typeOfSurvey"
                [name]="type_of_survey_related_content_radioGroupOptions.name"
                [isUncheckable]="
                  type_of_survey_related_content_radioGroupOptions.isUncheckable
                "
                [isRequired]="
                  type_of_survey_related_content_radioGroupOptions.isRequired
                "
                [errorState]="
                  type_of_survey_related_content_radioGroupOptions.errorState
                "
                ngDefaultControl
                (stateChanged)="checkRequiredFields()"
              >
                <ng-container
                  *ngFor="
                    let radio of type_of_survey_related_content_radio_values
                  "
                >
                  <dds-radio
                    [value]="radio.value"
                    [customClass]="'padding-right-half-rem'"
                    [label]="radio.options.label"
                    [theme]="radio.options.theme"
                  ></dds-radio>
                  <br />
                </ng-container>
              </dds-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="communicationAttributesInput.isEnableTypeOfSurvey2"
      >
        <div>
          <span
            *ngIf="!communicationAttributesInput.typeOfSurvey2"
            class="icon-required-yellow padding-left-sm"
          ></span>
          <span>
            {{ survey_tool_related_content_radioGroupOptions?.title }}</span
          >
        </div>

        <div>
          <div class="row padding-top-xs">
            <div class="col-12">
              <dds-radio-group
                [disabled]="!isControlEnable"
                autosave
                [(ngModel)]="communicationAttributesInput.typeOfSurvey2"
                [name]="survey_tool_related_content_radioGroupOptions.name"
                [isUncheckable]="
                  survey_tool_related_content_radioGroupOptions.isUncheckable
                "
                [isRequired]="
                  survey_tool_related_content_radioGroupOptions.isRequired
                "
                [errorState]="
                  survey_tool_related_content_radioGroupOptions.errorState
                "
                ngDefaultControl
                (stateChanged)="checkRequiredFields()"
              >
                <ng-container
                  *ngFor="
                    let radio of type_of_survey_2_related_content_radio_values
                  "
                >
                  <dds-radio
                    [value]="radio.value"
                    [customClass]="'padding-right-half-rem'"
                    [label]="radio.options.label"
                    [theme]="radio.options.theme"
                  ></dds-radio>
                  <br />
                </ng-container>
              </dds-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="communicationAttributesInput.isEnableLCSPSurveyApproval"
      >
        <div>
          <span
            *ngIf="!communicationAttributesInput.lcspSurveyApproval"
            class="icon-required-yellow padding-left-sm"
          ></span>
          <span>
            {{ lcsp_survey_related_content_radioGroupOptions?.title }}</span
          >
        </div>

        <div>
          <div class="row padding-top-xs">
            <div class="col-12">
              <dds-radio-group
                [disabled]="!isControlEnable"
                autosave
                [(ngModel)]="communicationAttributesInput.lcspSurveyApproval"
                [name]="lcsp_survey_related_content_radioGroupOptions.name"
                [isUncheckable]="
                  lcsp_survey_related_content_radioGroupOptions.isUncheckable
                "
                [isRequired]="
                  lcsp_survey_related_content_radioGroupOptions.isRequired
                "
                [errorState]="
                  lcsp_survey_related_content_radioGroupOptions.errorState
                "
                ngDefaultControl
                (stateChanged)="checkRequiredFields()"
              >
                <ng-container
                  *ngFor="let radio of yes_no_related_content_radio_values"
                >
                  <dds-radio
                    [value]="radio.value"
                    [customClass]="'padding-right-half-rem'"
                    [label]="radio.options.label"
                    [theme]="radio.options.theme"
                  ></dds-radio>
                  <br />
                </ng-container>
              </dds-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="communicationAttributesInput.isEnableProducingBenchMarksFor"
      >
        <div>
          <span
            *ngIf="!communicationAttributesInput.producingBenchMarksFor"
            class="icon-required-yellow padding-left-sm"
          ></span>
          <span> {{ benchmark_related_content_radioGroupOptions?.title }}</span>
        </div>

        <div>
          <div class="row padding-top-xs">
            <div class="col-12">
              <dds-radio-group
                [disabled]="!isControlEnable"
                autosave
                [(ngModel)]="
                  communicationAttributesInput.producingBenchMarksFor
                "
                [name]="benchmark_related_content_radioGroupOptions.name"
                [isUncheckable]="
                  benchmark_related_content_radioGroupOptions.isUncheckable
                "
                [isRequired]="
                  benchmark_related_content_radioGroupOptions.isRequired
                "
                [errorState]="
                  benchmark_related_content_radioGroupOptions.errorState
                "
                ngDefaultControl
                (stateChanged)="checkRequiredFields()"
              >
                <ng-container
                  *ngFor="let radio of benchmark_related_content_radio_values"
                >
                  <dds-radio
                    [value]="radio.value"
                    [customClass]="'padding-right-half-rem'"
                    [label]="radio.options.label"
                    [theme]="radio.options.theme"
                  ></dds-radio>
                  <br />
                </ng-container>
              </dds-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="communicationAttributesInput.isEnableIsIncentiveForSurvey"
      >
        <div>
          <span
            *ngIf="!communicationAttributesInput.isIncentiveForSurvey"
            class="icon-required-yellow padding-left-sm"
          ></span>
          <span>
            {{
              incentive_survey_related_content_radioGroupOptions?.title
            }}</span
          >
        </div>

        <div>
          <div class="row padding-top-xs">
            <div class="col-12">
              <dds-radio-group
                [disabled]="!isControlEnable"
                autosave
                [(ngModel)]="communicationAttributesInput.isIncentiveForSurvey"
                [name]="incentive_survey_related_content_radioGroupOptions.name"
                [isUncheckable]="
                  incentive_survey_related_content_radioGroupOptions.isUncheckable
                "
                [isRequired]="
                  incentive_survey_related_content_radioGroupOptions.isRequired
                "
                [errorState]="
                  incentive_survey_related_content_radioGroupOptions.errorState
                "
                ngDefaultControl
                (stateChanged)="checkRequiredFields()"
              >
                <ng-container
                  *ngFor="let radio of yes_no_related_content_radio_values"
                >
                  <dds-radio
                    [value]="radio.value"
                    [customClass]="'padding-right-half-rem'"
                    [label]="radio.options.label"
                    [theme]="radio.options.theme"
                  ></dds-radio>
                  <br />
                </ng-container>
              </dds-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="communicationAttributesInput.isEnableSurveyID">
        <div>
          <span
            *ngIf="!communicationAttributesInput.surveyID"
            class="icon-required-yellow padding-left-sm"
          ></span>
          <span>
            If this is the survey report, please provide the Submission ID for
            the survey questions.</span
          >
        </div>

        <div>
          <div class="row padding-top-xs">
            <div class="col-12">
              <dds-input
                [disabled]="!isControlEnable"
                autosave
                [placeholder]="textareaOptions.placeholder ?? ''"
                [maxLength]="100"
                [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
                [size]="textareaOptions.size ?? ''"
                [(ngModel)]="communicationAttributesInput.surveyID"
                (ngModelChange)="checkRequiredFields()"
              >
              </dds-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="communicationAttributesInput.isEnableIsInvolvesSweepstakes"
  >
    <div>
      <span
        *ngIf="!communicationAttributesInput.isInvolvesSweepstakes"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span> {{ sweepstakes_related_content_radioGroupOptions?.title }}</span>
    </div>

    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="communicationAttributesInput.isInvolvesSweepstakes"
            [name]="sweepstakes_related_content_radioGroupOptions.name"
            [isUncheckable]="
              sweepstakes_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="
              sweepstakes_related_content_radioGroupOptions.isRequired
            "
            [errorState]="
              sweepstakes_related_content_radioGroupOptions.errorState
            "
            (stateChanged)="onIsInvolvesSweepstakesChange($event)"
            ngDefaultControl
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
    <div *ngIf="communicationAttributesInput.isInvolvesSweepstakes === YES">
      <div
        class="row"
        *ngIf="communicationAttributesInput.isEnableIsDiversityEquity"
      >
        <div>
          <span
            *ngIf="!communicationAttributesInput.isDiversityEquity"
            class="icon-required-yellow padding-left-sm"
          ></span>
          <span>
            {{
              diversity_equity_related_content_radioGroupOptions?.title
            }}</span
          >
        </div>

        <div>
          <div class="row padding-top-xs">
            <div class="col-12">
              <dds-radio-group
                [disabled]="!isControlEnable"
                autosave
                [(ngModel)]="communicationAttributesInput.isDiversityEquity"
                [name]="diversity_equity_related_content_radioGroupOptions.name"
                [isUncheckable]="
                  diversity_equity_related_content_radioGroupOptions.isUncheckable
                "
                [isRequired]="
                  diversity_equity_related_content_radioGroupOptions.isRequired
                "
                [errorState]="
                  diversity_equity_related_content_radioGroupOptions.errorState
                "
                ngDefaultControl
                (stateChanged)="checkRequiredFields()"
              >
                <ng-container
                  *ngFor="let radio of yes_no_related_content_radio_values"
                >
                  <dds-radio
                    [value]="radio.value"
                    [customClass]="'padding-right-half-rem'"
                    [label]="radio.options.label"
                    [theme]="radio.options.theme"
                  ></dds-radio>
                  <br />
                </ng-container>
              </dds-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="communicationAttributesInput.isEnableIsInternalExternalContest"
      >
        <div>
          <span
            *ngIf="!communicationAttributesInput.isInternalExternalContest"
            class="icon-required-yellow padding-left-sm"
          ></span>
          <span>
            {{
              internal_external_contest_related_content_radioGroupOptions?.title
            }}</span
          >
        </div>

        <div>
          <div class="row padding-top-xs">
            <div class="col-12">
              <dds-radio-group
                [disabled]="!isControlEnable"
                autosave
                [(ngModel)]="
                  communicationAttributesInput.isInternalExternalContest
                "
                [name]="
                  internal_external_contest_related_content_radioGroupOptions.name
                "
                [isUncheckable]="
                  internal_external_contest_related_content_radioGroupOptions.isUncheckable
                "
                [isRequired]="
                  internal_external_contest_related_content_radioGroupOptions.isRequired
                "
                [errorState]="
                  internal_external_contest_related_content_radioGroupOptions.errorState
                "
                (stateChanged)="onIsInternalExternalContestChange($event)"
                ngDefaultControl
              >
                <ng-container
                  *ngFor="let radio of internal_external_contest_radio_values"
                >
                  <dds-radio
                    [value]="radio.value"
                    [customClass]="'padding-right-half-rem'"
                    [label]="radio.options.label"
                    [theme]="radio.options.theme"
                  ></dds-radio>
                  <br />
                </ng-container>
              </dds-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          communicationAttributesInput.isInternalExternalContest ===
          internalContest
        "
      >
        <div
          class="row"
          *ngIf="communicationAttributesInput.isEnableIsLessThanPriceThreshold"
        >
          <div>
            <span
              *ngIf="!communicationAttributesInput.isLessThanPriceThreshold"
              class="icon-required-yellow padding-left-sm"
            ></span>
            <span>
              {{
                internal_contest_related_content_radioGroupOptions?.title
              }}</span
            >
          </div>

          <div>
            <div class="row padding-top-xs">
              <div class="col-12">
                <dds-radio-group
                  [disabled]="!isControlEnable"
                  autosave
                  [(ngModel)]="
                    communicationAttributesInput.isLessThanPriceThreshold
                  "
                  [name]="
                    internal_contest_related_content_radioGroupOptions.name
                  "
                  [isUncheckable]="
                    internal_contest_related_content_radioGroupOptions.isUncheckable
                  "
                  [isRequired]="
                    internal_contest_related_content_radioGroupOptions.isRequired
                  "
                  [errorState]="
                    internal_contest_related_content_radioGroupOptions.errorState
                  "
                  ngDefaultControl
                  (stateChanged)="checkRequiredFields()"
                >
                  <ng-container
                    *ngFor="let radio of yes_no_related_content_radio_values"
                  >
                    <dds-radio
                      [value]="radio.value"
                      [customClass]="'padding-right-half-rem'"
                      [label]="radio.options.label"
                      [theme]="radio.options.theme"
                    ></dds-radio>
                    <br />
                  </ng-container>
                </dds-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          communicationAttributesInput.isInternalExternalContest ===
          externalContest
        "
      >
        <div class="row">
          <div>
            <span
              *ngIf="!communicationAttributesInput.hasWorkedWithOGC"
              class="icon-required-yellow padding-left-sm"
            ></span>
            <span>
              {{
                external_contest_related_content_radioGroupOptions?.title
              }}</span
            >
          </div>

          <div>
            <div class="row padding-top-xs">
              <div class="col-12">
                <dds-radio-group
                  [disabled]="!isControlEnable"
                  autosave
                  [(ngModel)]="communicationAttributesInput.hasWorkedWithOGC"
                  [name]="
                    external_contest_related_content_radioGroupOptions.name
                  "
                  [isUncheckable]="
                    external_contest_related_content_radioGroupOptions.isUncheckable
                  "
                  [isRequired]="
                    external_contest_related_content_radioGroupOptions.isRequired
                  "
                  [errorState]="
                    external_contest_related_content_radioGroupOptions.errorState
                  "
                  ngDefaultControl
                  (stateChanged)="checkRequiredFields()"
                >
                  <ng-container
                    *ngFor="let radio of yes_no_related_content_radio_values"
                  >
                    <dds-radio
                      [value]="radio.value"
                      [customClass]="'padding-right-half-rem'"
                      [label]="radio.options.label"
                      [theme]="radio.options.theme"
                    ></dds-radio>
                    <br />
                  </ng-container>
                </dds-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="communicationAttributesInput.isEnableIsAskedSignSpeakerAgreement"
  >
    <div>
      <span
        *ngIf="!communicationAttributesInput.isAskedSignSpeakerAgreement"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span>{{
        external_speaker_agreement_related_content_radioGroupOptions?.title
      }}</span>
    </div>
    <div class="d-flex">
      <div class="styled-box-green">
        <img src="\assets\images\green-pin-icon.svg" alt="lock-icon" />
      </div>
      <div class="padding-left-sm col-8">
        If Yes, do not sign the agreement, rather, contact the
        <a href="mailto:usconsultingexcommreviews@deloitte.com"
          >US Consulting External Communications</a
        >
        Review for guidance and reference this submission ID
      </div>
    </div>

    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="
              communicationAttributesInput.isAskedSignSpeakerAgreement
            "
            [name]="
              external_speaker_agreement_related_content_radioGroupOptions.name
            "
            [isUncheckable]="
              external_speaker_agreement_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="
              external_speaker_agreement_related_content_radioGroupOptions.isRequired
            "
            [errorState]="
              external_speaker_agreement_related_content_radioGroupOptions.errorState
            "
            ngDefaultControl
            (stateChanged)="checkRequiredFields()"
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="communicationAttributesInput.isEnableIsRecorded">
    <div>
      <span
        *ngIf="!communicationAttributesInput.isRecorded"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span>{{ isrecorded_related_content_radioGroupOptions?.title }}</span>
    </div>
    <div
      class="d-flex"
      *ngIf="communicationAttributesInput.isEnableIsRecordedGuidance"
    >
      <div class="styled-box-green">
        <img src="\assets\images\green-pin-icon.svg" alt="lock-icon" />
      </div>
      <div class="padding-left-sm col-8">
        Use the
        <a
          href="https://deloittenet.deloitte.com/About/NationalQRM/Pages/NQRM_Forms_Templates.aspx"
          >standard Deloitte speaker agreement</a
        >. Contact
        <a href="usconsultingexcommreviews@deloitte.com"
          >US Consulting External Communications</a
        >
        Review with questions.
      </div>
    </div>

    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="communicationAttributesInput.isRecorded"
            [name]="isrecorded_related_content_radioGroupOptions.name"
            [isUncheckable]="
              isrecorded_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="
              isrecorded_related_content_radioGroupOptions.isRequired
            "
            [errorState]="
              isrecorded_related_content_radioGroupOptions.errorState
            "
            ngDefaultControl
            (stateChanged)="checkRequiredFields()"
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="
      communicationAttributesInput.isEnableIsClientUseCaseAndSuccessStories
    "
  >
    <div>
      <span
        *ngIf="!communicationAttributesInput.isClientUseCaseAndSuccessStories"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span>{{
        client_usecase_success_stories_related_content_radioGroupOptions?.title
      }}</span>
    </div>
    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="
              communicationAttributesInput.isClientUseCaseAndSuccessStories
            "
            [name]="
              client_usecase_success_stories_related_content_radioGroupOptions.name
            "
            [isUncheckable]="
              client_usecase_success_stories_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="
              client_usecase_success_stories_related_content_radioGroupOptions.isRequired
            "
            [errorState]="
              client_usecase_success_stories_related_content_radioGroupOptions.errorState
            "
            ngDefaultControl
            (stateChanged)="checkRequiredFields()"
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="communicationAttributesInput.isEnableIsRelatedToSponsorship"
  >
    <div>
      <span
        *ngIf="!communicationAttributesInput.isRelatedToSponsorship"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span>{{
        related_to_sponsorship_related_content_radioGroupOptions?.title
      }}</span>
    </div>
    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="communicationAttributesInput.isRelatedToSponsorship"
            [name]="
              related_to_sponsorship_related_content_radioGroupOptions.name
            "
            [isUncheckable]="
              related_to_sponsorship_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="
              related_to_sponsorship_related_content_radioGroupOptions.isRequired
            "
            [errorState]="
              related_to_sponsorship_related_content_radioGroupOptions.errorState
            "
            ngDefaultControl
            (stateChanged)="checkRequiredFields()"
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="communicationAttributesInput.isEnableIsLabOrDemo">
    <div>
      <span
        *ngIf="!communicationAttributesInput.isLabOrDemo"
        class="icon-required-yellow padding-left-sm"
      ></span>
      <span>{{ demo_related_content_radioGroupOptions?.title }}</span>
    </div>
    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-radio-group
            [disabled]="!isControlEnable"
            autosave
            [(ngModel)]="communicationAttributesInput.isLabOrDemo"
            [name]="demo_related_content_radioGroupOptions.name"
            [isUncheckable]="
              demo_related_content_radioGroupOptions.isUncheckable
            "
            [isRequired]="demo_related_content_radioGroupOptions.isRequired"
            [errorState]="demo_related_content_radioGroupOptions.errorState"
            ngDefaultControl
            (stateChanged)="checkRequiredFields()"
          >
            <ng-container
              *ngFor="let radio of yes_no_related_content_radio_values"
            >
              <dds-radio
                [value]="radio.value"
                [customClass]="'padding-right-half-rem'"
                [label]="radio.options.label"
                [theme]="radio.options.theme"
              ></dds-radio>
              <br />
            </ng-container>
          </dds-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="communicationAttributesInput.isEnableAdditionalComments"
  >
    <div>
      <span>
        Add additional comments, instructions, or background information related
        to this External Communication</span
      >
    </div>

    <div>
      <div class="row padding-top-xs">
        <div class="col-12">
          <dds-textarea
            [readonly]="!isControlEnable"
            autosave
            [placeholder]="textareaOptions.placeholder ?? ''"
            [maxLength]="1000"
            [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
            [size]="textareaOptions.size ?? ''"
            [(ngModel)]="communicationAttributesInput.additionalComments"
          ></dds-textarea>
          <div class="text-right"></div>
        </div>
      </div>
    </div>
  </div>
</div>
