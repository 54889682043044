import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  CO_AND_EL_SOW_RADIO_GROUP_VALUE,
  RADIO_GROUP_OPTIONS_FOR_CO_AND_ELSOW_SCOPE,
} from './close-out-change-order.helper';
import {
  WbsArchiveReceivedModel,
  WbsWithArchiveDetailsModel,
} from '../../../../common/models/wbs-picker.model';
import { CoElSowOptionValues } from '../../../constants/intake_document_close_out_co_elsow_option_values.enum';
import { IntakeConstant } from '../../../constants/intake.constant';
import {
  CoElSowWithWbsArchiveEventEmitterModel,
  IntakeSubmissionDetailsCommonSectionDataReceivedModal,
  SwiftDetails,
} from '../../../../common/models/intake-submission-details-common-section.model';
import { PermissionsObj } from '../../../../common/models/common-models';

@Component({
  selector: 'app-close-out-change-order',
  templateUrl: './close-out-change-order.component.html',
  styleUrl: './close-out-change-order.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseOutChangeOrderComponent {
  constructor() {}
  @Input() contractSignedExecuted!: null | string;
  @Input() wbsChargeCode: string = IntakeConstant.EMPTY_SPACE;
  @Input() numberOfOpenArchieve: null | number = null;
  @Input() wbsDescription: string = IntakeConstant.EMPTY_SPACE;
  @Input() dataSubmissionId: number | null = null;
  @Input() dataOpportunityId: string = IntakeConstant.EMPTY_SPACE;
  @Input() archiveDetails: WbsArchiveReceivedModel | null = null;
  @Input() swiftDetails: SwiftDetails | null = null;
  @Input()
  submissionData: IntakeSubmissionDetailsCommonSectionDataReceivedModal | null =
    null;
  @Input() permissionObj: PermissionsObj = {} as PermissionsObj;
  @Output()
  coAndElSowWithWbsArchiveEventEmitter: EventEmitter<CoElSowWithWbsArchiveEventEmitterModel> =
    new EventEmitter<CoElSowWithWbsArchiveEventEmitterModel>();

  @Output() closeOutFormYesOrNoSelected: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() FinalizeFormAnswered: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() COorElsowFinalizeFormSelectedValue : EventEmitter<string>= new EventEmitter<string>();
  textareaOptions: any = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  co_elsow_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_CO_AND_ELSOW_SCOPE;
  co_elsow_radio_values: any = CO_AND_EL_SOW_RADIO_GROUP_VALUE;
  CoElSowOptionValueYes = CoElSowOptionValues.YES;
  numberOfOpenArchivesLabel = 'Archive : ';

  coAndElSowStateChanged(event: string): void {
    if(event === IntakeConstant.YES.toLowerCase() || event === IntakeConstant.NO_RESUBMIT_FOR_REVIEW) {
      this.closeOutFormYesOrNoSelected.emit(true);
    }else{
      this.closeOutFormYesOrNoSelected.emit(false);
    }
    this.FinalizeFormAnswered.emit(true);
    const coOrElSowWithWbsArchiveValue: CoElSowWithWbsArchiveEventEmitterModel =
      {
        contractSignedExecuted: event,
      };
    this.coAndElSowWithWbsArchiveEventEmitter.emit(
      coOrElSowWithWbsArchiveValue
    );
    this.COorElsowFinalizeFormSelectedValue.emit(event);
  }



  onWbsOrArchiveChange(wbsWithArchiveValue: WbsWithArchiveDetailsModel): void {
    const coOrElSowWithWbsArchiveValue: CoElSowWithWbsArchiveEventEmitterModel =
      {
        contractSignedExecuted:
          this.contractSignedExecuted ?? IntakeConstant.EMPTY_SPACE,
        ...wbsWithArchiveValue,
      };
    this.coAndElSowWithWbsArchiveEventEmitter.emit(
      coOrElSowWithWbsArchiveValue
    );
  }
}
