import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ClientStatusWebapiService } from '../../../http/client/client-status-webapi.service';
import { EmployeeView } from '../../models/common-models';

@Component({
  selector: 'app-nca-assign-to-dialog',
  templateUrl: './nca-assign-to-dialog.component.html',
  styleUrl: './nca-assign-to-dialog.component.less'
})
export class NcaAssignToDialogComponent implements OnInit {
  @Input() resolve: any;

  public users: Array<EmployeeView> = [];
  public selectedUser: string= '';
  public clientMilestoneID: number = 0;

  constructor(public modalService: ModalService, public clientstatuswebapiservice: ClientStatusWebapiService) {
  }

  public ngOnInit() {
      this.users = this.resolve.usersList || [];
      this.clientMilestoneID = this.resolve.clientMilestoneID;
  }

  public selectUser(employeeId: string) {
      this.selectedUser = employeeId;
  }
  public assignNCASpecialist() {
    this.clientstatuswebapiservice.AssignNCASpecialist(this.selectedUser, this.clientMilestoneID).subscribe((res:any) => {
        this.modalService.closeAll();
        location.reload();
    });
  }

  public close() {
    //This will enable the scroll when the pop-up is closed
    document.body.classList.remove('no-scroll');
    this.modalService.close();
  }
}
