import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import {
  ExtThemes,
  Size,
  WidthState,
  ButtonKind,
  Themes,
} from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { SubmissionType } from '../../common/models/common-models';
import { SubmissionSubType } from '../../common/models/common-models';
import { SubmissionService } from '../../http/intake/submission.service';
import { Router } from '@angular/router';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import {
  InitiateSubmissionRequestDataModel,
  IntakeSubmissionResponseDataModel,
} from '../../common/models/initiateSubmission.model';
import { Subject, takeUntil } from 'rxjs';
import {
  OpportunityDetails,
  OpportunityList,
} from '../../common/models/opportunity-details.model';
import { opportunityTableColumns } from './initiate-submission-helper';
import { configurationType } from '../../common/models/common-models';
import { latestOpportunityDetails } from '../../common/models/latestOpportunityDetails';
import { latestSubmissionDetails } from '../../common/models/latestSubmissionDetail';
import { toolTipConfig } from '../../common/models/common-models';
import { IntakeConstant } from '../constants/intake-constant';
import { IntakeDocumentSubmissionTypeCode } from '../constants/intake_submission_type_code.enum';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { ReviewerRole } from '../../common/models/reviwer-role.model';
@Component({
  selector: 'app-initiate-submission',
  templateUrl: './initiate-submission.component.html',
  styleUrl: './initiate-submission.component.less',
})
export class InitiateSubmissionComponent implements OnInit, OnDestroy {
  submissionData: SubmissionType[] = [];
  submissionTypes: string[] = [];
  submissionSubData: SubmissionSubType[] = [];
  submissionSubTypes: string[] = [];
  submissionType: string = '';
  submissionSubType: string = '';
  changeOrder: string = IntakeConstant.CHANGE_ORDER_LABEL;
  nonChangeOrder: string = IntakeConstant.NON_CHANGE_ORDER;
  tooltipWidth : number = IntakeConstant.TOOLTIP_WIDTH;
  secondSearchCheck: boolean = false;
  submissionTypeId: number | undefined;
  showSelectOpportunity: boolean = false;
  submissionSubTypeId: number | undefined;
  changeOrderDuplicateError: boolean = false;
  CustomSubmissionType: string = '';
  isRequired: boolean = true;
  showSubType: boolean = false;
  pageNumber: number = 0;
  pageSize: number = IntakeConstant.PAGE_NUMBER;
  latestSubmissionDetails: latestSubmissionDetails[] = [];
  notAvailable: boolean = false;
  totalCount: number = 0;
  showDescription: boolean = false;
  public selectedOportunity: string = '';
  showDropdown: boolean = false;
  showOpportunityDetails: boolean = false;
  postButtonEnabled: boolean = false;
  showNoreviewNote: boolean = false;
  changeOrderId: string = '';
  htmlElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;

  postData: InitiateSubmissionRequestDataModel = {
    SubmissionTypeDetails: {
      SubmissionTypeId: undefined,
      SubmissionSubTypeId: undefined,
      CustomSubmissionType: '',
    },
    OpportunityDetails: {
      OpportunityNumber: '',
      OpportunityName: '',
      OpportunityStatusId: null,
      ClientNumber: '',
      PursuitLeaderId: '',
      offeringPortfolioId: null,
      offeringId: null,
      ParentOpportunityNumber: null,
    },
  };
  options: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 15,
    tooltipIsOutsideClick: false,
    tooltipHasBeak: true,
    tooltipIsDisabled: false,
    tooltipIsInverse: false,
    tooltipIsDynamic: false,
    tooltipCustomClass: '',
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipShowDelay: 0,
    tooltipHideDelay: 0,
    tooltipTheme: Themes.green,
  };
  opportunityList: OpportunityDetails[] = [];
  opportunityTableColumns = opportunityTableColumns;
  public opportunityDetailsList: any[] = [];
  changeOrderList: any[] = [];

  unsubscriber$ = new Subject();
  searchOptions: SearchOptions = {
    placeholder: IntakeConstant.SEARCH_OPTION_PLACEHOLDER,
    customClass: '',
  };
  opportunityDetailName: string = '';
  configurationsIntake: configurationType[] = [];
  tooltipIntake: toolTipConfig[] = [];
  notFound: string = '';

  constructor(
    private submissionService: SubmissionService,
    private router: Router,
    private progressIndicator: ProgressIndicatorService
  ) {}

  ngOnInit(): void {
    this.loadSubmissionTypes();
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response && response.length > 0) {
            this.configurationsIntake = response;
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
    this.getIntakeTooltips();
  }
  

  getIntakeTooltips(): void {
    this.submissionService
      .getIntakeTooltips()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: toolTipConfig[]) => {
          if (response && response.length > 0) {
            this.tooltipIntake = response;
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getChangeOrderRequestHeader(): string {
    return (
      this.configurationsIntake.find(
        (config) => config.appConfigurationLabelKey === 'CO_REQ_GUIDANCEBOX'
      )?.appConfigurationLabelValue || ''
    );
  }

  gethangeOrderTooltip(): string {
    return (
      this.tooltipIntake.find(
        (config) => config.appConfigurationLabelKey === 'CHANGE_ORDER_TOOL_TIP'
      )?.appConfigurationLabelValue || ''
    );
  }


  getNoReviewHeader(): string {
    return (
      this.configurationsIntake.find(
        (config) =>
          config.appConfigurationLabelKey === 'CO_ECLIPSE_OPP_NOT_FOUND'
      )?.appConfigurationLabelValue || ''
    );
  }

  loadSubmissionTypes(): void {
    this.progressIndicator.show();
    this.submissionService
      .getSubmissionTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (types) => {
          this.submissionData = types;
          this.submissionTypes = this.submissionData.map(
            (sub) => sub.submissionTypeName
          );
          this.progressIndicator.hide();
        },
        error: (err) => {
          this.progressIndicator.hide();
          console.error('Error fetching submission types', err);
        },
      });
  }

  loadSubmissionSubTypes(ID: number | undefined): void {
    this.progressIndicator.show();
    this.submissionService
      .getSubmissionSubTypes(ID)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (subTypes) => {
          this.submissionSubData = subTypes;
          this.submissionSubTypes = this.submissionSubData.map(
            (sub) => sub.subTypeName
          );
          if (this.submissionSubData.length > 0) {
            this.showSubType = true;
            this.submissionSubType = '';
            this.showDescription = false;
          } else {
            this.showSubType = false;
            this.showDescription = false;
            this.submissionSubType = '';
            this.CustomSubmissionType = '';
          }
          this.progressIndicator.hide();
        },
        error: (err) => {
          console.error('Error fetching submission sub-types', err);
          this.progressIndicator.hide();
        },
      });
  }

  selectSubmissionSelectOptions: SelectOptions = {
    placeholder: 'Select type',
    disabled: false
  };

  otherSubTypeSelectOptions: SelectOptions = {
    placeholder: 'Select sub-type',
    disabled: false
  };

  customSubmissionTypeOptions: TextareaOptions = {
    placeholder: 'Enter text',
    minHeight: 100,
    maxLength: 50,
    maxLengthPosition: 'bottom-right',
  };

  confirmLabel = 'Confirm and proceed';
  confirmButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primaryLoud,
    width: WidthState.fixed,
    isLoading: false,
    icon: '',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: true,
    ariaLabel: this.confirmLabel,
    customClass: '',
    role: 'button',
  };

  cancelLabel = 'Cancel';
  cancelOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    width: WidthState.fixed,
    isLoading: false,
    icon: '',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: 'Cancel',
    customClass: '',
    role: 'button',
  };

  onSubmissionTypeChange(): void {
    if (this.submissionType !== 'Other') {
      this.showSelectOpportunity = true;

      this.postButtonEnabled = this.opportunityList.length > 0;
      this.confirmButtonOptions.disabled = !this.postButtonEnabled;
    } else {
      this.showSelectOpportunity = false;

      this.postButtonEnabled = false;
      this.confirmButtonOptions.disabled = !this.postButtonEnabled;
    }
    this.submissionTypeId = this.submissionData.find(
      (sub) => sub.submissionTypeName === this.submissionType
    )?.submissionTypeId;
    this.showSubType = false;
    this.showDescription = false;
    this.loadSubmissionSubTypes(this.submissionTypeId);

    this.postData.SubmissionTypeDetails.SubmissionTypeId =
      this.submissionTypeId;
    this.postData.SubmissionTypeDetails.SubmissionSubTypeId = undefined;
    this.opportunityList = [];
    this.opportunityDetailsList = [];
    this.showOpportunityDetails = false;
    this.selectedOportunity = '';
    this.showDropdown = false;
    this.notFound = '';
    this.showNoreviewNote = false;
    this.changeOrderDuplicateError = false;
  }

  onSubmissionSubTypeChange(): void {
    this.showSelectOpportunity = true;
    const selectedSubType = this.submissionSubData.find(
      (subType) => subType.subTypeName === this.submissionSubType
    );
    if (selectedSubType && selectedSubType.enableText) {
      this.showDescription = true;
      this.CustomSubmissionType = '';

      this.postButtonEnabled = false;
      this.confirmButtonOptions.disabled = !this.postButtonEnabled;
    } else {
      this.showDescription = false;
      this.CustomSubmissionType = '';

      this.updateConfirmButtonStatus(0);
      this.confirmButtonOptions.disabled = !this.postButtonEnabled;
    }
    this.postData.SubmissionTypeDetails.SubmissionSubTypeId =
      selectedSubType?.subTypeId;
  }

  onCancel(): void {
    //To Regain the zero state
    this.submissionSubData = [];
    this.submissionSubTypes = [];
    this.submissionType = '';
    this.submissionSubType = '';
    this.submissionTypeId = undefined;
    this.showSelectOpportunity = false;
    this.submissionSubTypeId = undefined;
    this.CustomSubmissionType = '';
    this.showSubType = false;
    this.pageNumber = 0;
    this.latestSubmissionDetails = [];
    this.notAvailable = false;
    this.totalCount = 0;
    this.showDescription = false;
    this.selectedOportunity = '';
    this.showDropdown = false;
    this.showOpportunityDetails = false;
    this.postButtonEnabled = false;
    this.showNoreviewNote = false;
    this.changeOrderId = '';
    this.opportunityList = [];
    this.opportunityDetailsList = [];
    this.changeOrderList = [];
    this.opportunityDetailName = '';
    this.notFound = '';
  }

  prepareInitiateSubmissionData(): void {
    this.postData.SubmissionTypeDetails.CustomSubmissionType =
      this.CustomSubmissionType;
    if (this.opportunityDetailsList.length) {
      this.postData.OpportunityDetails.ClientNumber =
        this.opportunityDetailsList[0]?.clientNumber;
      this.postData.OpportunityDetails.OpportunityName =
        this.opportunityDetailsList[0]?.opportunityName;
      this.postData.OpportunityDetails.OpportunityStatusId =
        this.opportunityDetailsList[0]?.opportunityStatusId;
      this.postData.OpportunityDetails.PursuitLeaderId =
        this.opportunityDetailsList[0]?.pursuitLeaderId;
      this.opportunityDetailName =
        this.opportunityDetailsList[0]?.opportunityName;
      this.postData.OpportunityDetails.offeringPortfolioId =
        this.opportunityDetailsList[0]?.offeringPortfolioId;
      this.postData.OpportunityDetails.offeringId =
        this.opportunityDetailsList[0]?.offeringId;
      if (!this.showNoreviewNote) {
        this.postData.OpportunityDetails.ParentOpportunityNumber = null;
        this.postData.OpportunityDetails.OpportunityNumber =
          this.opportunityDetailsList[0]?.opportunityNumber;
      } else {
        if (this.notAvailable) {
          this.postData.OpportunityDetails.OpportunityNumber =
            this.opportunityDetailsList[0]?.opportunityNumber;
          this.postData.OpportunityDetails.ParentOpportunityNumber = null;
        } else {
          this.postData.OpportunityDetails.OpportunityNumber =
            this.changeOrderList[0]?.opportunityNumber;
          this.postData.OpportunityDetails.ParentOpportunityNumber =
            this.opportunityDetailsList[0]?.opportunityNumber;
        }
      }
    }
  }

  onSubmit(): void {
    this.progressIndicator.show();
    this.postButtonEnabled = false;
    this.prepareInitiateSubmissionData();
    this.submissionService
      .postSubmissionRequest(this.postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: IntakeSubmissionResponseDataModel) => {
          if (response) {
            const id = response.opportunityNumber;
            const opportunityDetailName = this.opportunityDetailName;
            const submissionID = response.submissionId;
            this.progressIndicator.hide();
            this.router.navigate(['/opportunity-details', id], {
              state: {
                name: opportunityDetailName,
                submissionID: submissionID,
                submissionTypeCode:
                  this.submissionType === IntakeConstant.CHANGE_ORDER_LABEL
                    ? IntakeDocumentSubmissionTypeCode.CO
                    : '',
              },
            });
          }
        },
        error: (err) => {
          this.progressIndicator.hide();
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  selectOpportunityChanged(): void {
    this.totalCount = 0;
    this.pageNumber = 1;
    if (this.selectedOportunity.length > 2 && this.selectedOportunity !== '') {
      this.fetchOpportunitySearchResult(1);
    } else {
      this.notFound = '';
      this.opportunityList = [];
      this.totalCount = 0;
      this.showDropdown = false;
    }
  }

  fetchOpportunityDetails(
    opportunity: OpportunityDetails,
    fetchType: string
  ): void {
    if (this.submissionType === IntakeConstant.CHANGE_ORDER_LABEL) {
      this.checkOpportunity(opportunity?.opportunityNumber, fetchType);
    } else {
      this.updateConfirmButtonStatus(0);
    }
    this.opportunityList = [];
    this.showDropdown = false;
    this.showOpportunityDetails = true;
    if (fetchType === IntakeConstant.CHANGE_ORDER_LABEL) {
      this.changeOrderList = [opportunity];
      if (
        opportunity?.opportunityNumber ===
        this.opportunityDetailsList[0].opportunityNumber
      ) {
        this.changeOrderList = [];
        this.changeOrderDuplicateError = true;
      } else {
        this.changeOrderDuplicateError = false;
        this.selectedOportunity = '';
      }
    } else {
      this.opportunityDetailsList = [opportunity];
      this.changeOrderDuplicateError = false;
      this.selectedOportunity = '';
    }
  }

  getDuplicateError(): string {
    return (
      this.configurationsIntake.find(
        (config) => config.appConfigurationLabelKey === 'CO_DUPLICATE_OPP_ERROR'
      )?.appConfigurationLabelValue || ''
    );
  }

  fetchOpportunitySearchResult(pageNumber: number): void {
    this.progressIndicator.show();
    this.submissionService
      .getOpportunityDetails(this.selectedOportunity, pageNumber, this.pageSize)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (opportunity: OpportunityList) => {
          if (opportunity?.totalCount > 0) {
            this.notFound = '';
            if (pageNumber === 1) {
              this.opportunityList = opportunity?.opportunities;
            } else {
              this.opportunityList = [
                ...this.opportunityList,
                ...opportunity?.opportunities,
              ];
            }
            this.totalCount = opportunity?.totalCount;
            if (this.opportunityList.length > 0) this.showDropdown = true;
          } else {
            this.notFound = this.findText(opportunity);
            this.opportunityList = [];
            this.totalCount = 0;
            this.showDropdown = false;
          }
          this.progressIndicator.hide();
        },
        error: (err) => {
          this.progressIndicator.hide();
          console.error('Error fetching submission types', err);
        },
      });
  }

  findText(opportunity: OpportunityList): string {
    const notFoundtext = this.configurationsIntake.find(
      (config) => config.appConfigurationLabelKey === opportunity?.notFoundLabel
    )?.appConfigurationLabelValue;
    return notFoundtext ? notFoundtext : '';
  }

  fetchOpportunitySearchResultMore(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      if (this.pageNumber < this.totalCount / this.pageSize) {
        this.pageNumber++;
        this.fetchOpportunitySearchResult(this.pageNumber);
      }
    }
  }

  removeOpportunity(removeType: string): void {
    if (removeType === IntakeConstant.CHANGE_ORDER_LABEL) {
      this.changeOrderList = [];
    } else {
      this.showOpportunityDetails = false;
      this.selectedOportunity = '';
      this.opportunityList = [];
      this.opportunityDetailsList = [];
      this.showDropdown = false;
      this.postButtonEnabled = false;
      this.confirmButtonOptions.disabled = !this.postButtonEnabled;
      this.showNoreviewNote = false;
      this.changeOrderList = [];
      this.opportunityDetailsList = [];
    }
    this.changeOrderDuplicateError = false;
  }

  onCustomSubmissionTypeChange(callFrom: number): void {
    this.updateConfirmButtonStatus(callFrom);
  }

  updateConfirmButtonStatus(callFrom: number): void {
    if (this.showDescription) {
      if (callFrom == 1) {
        this.postButtonEnabled =
          this.CustomSubmissionType.trim() != '' &&
          this.opportunityDetailsList.length > 0;
      } else {
        this.postButtonEnabled =
          this.CustomSubmissionType.trim() != '' &&
          this.opportunityList.length > 0;
      }
    } else {
      this.postButtonEnabled = this.opportunityList.length > 0;
    }
    this.confirmButtonOptions.disabled = !this.postButtonEnabled;
  }

  fieldCleared(): void {
    this.opportunityList = [];
    this.selectedOportunity = '';
    this.showDropdown = false;
    this.notFound = '';
  }

  ngOnDestroy(): void {
    this.progressIndicator.hide();
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }

  checkOpportunity(opportunityId: string, fetchType: string): void {
    this.progressIndicator.show();
    this.submissionService
      .getOpportunitySelected(opportunityId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: latestOpportunityDetails) => {
          if (response) {
            this.latestSubmissionDetails = [response];
            this.confirmButtonOptions.disabled = false;
            if (fetchType === IntakeConstant.CHANGE_ORDER_LABEL) {
              this.showNoreviewNote = true;
              this.secondSearchCheck = true;
            } else {
              this.showNoreviewNote = false;
              this.secondSearchCheck = false;
            }
          } else {
            this.showNoreviewNote = true;
            this.secondSearchCheck = false;
          }
          this.progressIndicator.hide();
        },
        error: (err) => {
          this.progressIndicator.hide();
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  checkEnable(): boolean {
    if (this.submissionType === IntakeConstant.CHANGE_ORDER_LABEL) {
      if (this.showNoreviewNote) {
        return (
          (this.changeOrderList.length > 0 || this.notAvailable) &&
          this.checkClientStatus()
        );
      } else {
        return (
          this.opportunityDetailsList.length > 0 && this.checkClientStatus()
        );
      }
    } else {
      return this.postButtonEnabled && this.checkClientStatus();
    }
  }

  checkClientStatus(): boolean {
    return (
      this.opportunityDetailsList[0]?.watchlistStatus != 'Do not serve' &&
      this.changeOrderList[0]?.watchlistStatus != 'Do not serve'
    );
  }

  displayChangeOrderCheck(): boolean {
    return (
      this.changeOrderList.length > 0 &&
      !(
        this.changeOrderList[0]?.opportunityNumber ===
        this.opportunityDetailsList[0]?.opportunityNumber
      )
    );
  }

  getNumberOfSplits(fetchType: string): string {
    let splits = '';
    if (fetchType === this.changeOrder) {
      for (let offering of this.changeOrderList[0]?.offering) {
        splits += `${offering} \n`;
      }
    } else {
      for (let offering of this.opportunityDetailsList[0]?.offering) {
        splits += `${offering} \n`;
      }
    }
    return splits;
  }
}
