import { Component, OnInit } from '@angular/core';
import { SubmissionService } from '../../http/intake/submission.service';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrl: './audit-trail.component.less',
})
export class AuditTrailComponent implements OnInit {
  submissionID: number | null = null;
  opportunityNumber!: string;

  //TODO: Hardcoded values, need to be removed
  titleValue: string = 'Charging JV Engagement letter';
  submissionIdValue: string = '7175191';
  submissionTypeValue: string = 'EL/SOW';
  clientNameValue: string = 'Brainshark Demo';
  opportunityNameValue: string = 'Brainshark Demo Charging JV';

  constructor(private readonly submissioService: SubmissionService) {}
  ngOnInit(): void {
    this.submissioService.currentSubmissionId$.subscribe((request) => {
      this.submissionID = request.submissionid;
      this.opportunityNumber = request.opportunitynumber;
    });
    console.log(this.submissionID, this.opportunityNumber);
  }
}
