<progress-indicator></progress-indicator>
<div class="initiate-submission-container">
  <div class="initiate-page-header d-flex flex-row align-items-center">
    <h3 class="font-size-28 font-bold">Initiate new submission</h3>
    <div class="submission-buttons d-flex">
      <dds-button
        class="custom-button"
        [theme]="confirmButtonOptions.theme ?? ''"
        [kind]="confirmButtonOptions.kind ?? ''"
        [size]="htmlElementSize"
        [width]="confirmButtonOptions.width ?? ''"
        [icon]="confirmButtonOptions.icon ?? ''"
        [isIconLeft]="confirmButtonOptions.isIconLeft ?? false"
        [role]="confirmButtonOptions.role ?? ''"
        [isInverse]="confirmButtonOptions.isInverse ?? false"
        [isColorBg]="confirmButtonOptions.isColorBg ?? false"
        [isLoading]="confirmButtonOptions.isLoading ?? false"
        [ariaLabel]="confirmButtonOptions.ariaLabel ?? ''"
        [customClass]="confirmButtonOptions.customClass ?? ''"
        [disabled]="!checkEnable()"
        (clicked)="onSubmit()"
        >{{ confirmLabel }}</dds-button
      >

      <dds-button
        class="custom-button"
        [theme]="cancelOptions.theme ?? ''"
        [kind]="cancelOptions.kind ?? ''"
        [size]="htmlElementSize"
        [width]="cancelOptions.width ?? ''"
        [icon]="cancelOptions.icon ?? ''"
        [isIconLeft]="cancelOptions.isIconLeft ?? false"
        [role]="cancelOptions.role ?? ''"
        [isInverse]="cancelOptions.isInverse ?? false"
        [isColorBg]="cancelOptions.isColorBg ?? false"
        [isLoading]="cancelOptions.isLoading ?? false"
        [ariaLabel]="cancelOptions.ariaLabel ?? ''"
        [customClass]="cancelOptions.customClass ?? ''"
        [disabled]="cancelOptions.disabled ?? false"
        (clicked)="onCancel()"
        >Cancel</dds-button
      >
    </div>
  </div>
  <div class="submission-type-section">
    <div class="d-flex">
      <span class="text-label font-bold-600 font-size-16"
        >Select submission type
      </span>
    </div>
    <div class="d-flex">
      <div>
        <div class="select-submission margin-top-small">
          <dds-select
            class="custom-select"
            [disabled]="selectSubmissionSelectOptions.disabled ?? false"
            [list]="submissionTypes"
            [placeholder]="selectSubmissionSelectOptions.placeholder ?? ''"
            (valueChanged)="onSubmissionTypeChange()"
            [(ngModel)]="submissionType"
            [size]="htmlElementSize"
          >
          </dds-select>
        </div>
      </div>
      <div *ngIf="showSubType">
        <div class="select-submission margin-top-small margin-left-l">
          <dds-select
            class="custom-select"
            [disabled]="otherSubTypeSelectOptions.disabled ?? false"
            [list]="submissionSubTypes"
            [placeholder]="otherSubTypeSelectOptions.placeholder ?? ''"
            (valueChanged)="onSubmissionSubTypeChange()"
            [(ngModel)]="submissionSubType"
            [size]="htmlElementSize"
          >
          </dds-select>
        </div>
      </div>
    </div>
    <div *ngIf="showDescription" class="d-flex margin-top-small">
      <span *ngIf="CustomSubmissionType === ''" class="required padding-left-m relative"></span>
      <span class="nobold">Provide the name for the submission type: </span>
    </div>
    <div *ngIf="showDescription" class="d-flex margin-bottom-s">
      <div class="text-area">
        <dds-input
          [size]="htmlElementSize"
          class="custom-text-area"
          [placeholder]="customSubmissionTypeOptions.placeholder ?? ''"
          [maxLength]="customSubmissionTypeOptions.maxLength ?? 0"
          [maxLengthPosition]="
            customSubmissionTypeOptions.maxLengthPosition ?? ''
          "
          [(ngModel)]="CustomSubmissionType"
          (ngModelChange)="onCustomSubmissionTypeChange(1)"
        ></dds-input>
      </div>
    </div>
    <div class="section-ending margin-top-s padding-top-es"></div>
  </div>
  <div
    *ngIf="showSelectOpportunity"
    class="select-opportunity-text-label font-size-16 font-bold-600 text-align-left margin-bottom-es"
  >
    Select opportunity
  </div>
  <div
    *ngIf="this.submissionType === 'Change Order'"
    class="guidance-note text-align-left"
  >
    {{ getChangeOrderRequestHeader() }}
  </div>
  <!-- TO DO : To move the Search component to a seperate custom component to be used globally -->
  <div
    *ngIf="!showOpportunityDetails && showSelectOpportunity"
    class="select-opportunity-section"
  >
    <div class="search-box">
      <dds-search
        [size]="htmlElementSize"
        [customClass]="searchOptions.customClass ?? ''"
        [icon]="searchOptions.icon || 'dds-icon_search'"
        (fieldCleared)="fieldCleared()"
      >
        <input
          ddsSearch
          [placeholder]="searchOptions.placeholder ?? ''"
          [(ngModel)]="selectedOportunity"
          (keyup)="selectOpportunityChanged()"
        />
      </dds-search>
    </div>
    <div
      *ngIf="showDropdown || notFound"
      class="list-of-oppportunities d-flex flex-row-column flex-row-column padding-xs"
      (scroll)="fetchOpportunitySearchResultMore($event)"
    >
      <div *ngFor="let item of opportunityList">
        <div
          class="opportunity-items d-flex flex-row-column"
          (click)="fetchOpportunityDetails(item, 'searchOpportunity')"
        >
          <div
            class="opportunity-header font-size-14 font-bold-600 text-align-left"
          >
            {{ item?.opportunityName }}
          </div>
          <div class="opportunity-details font-size-12 text-align-left font-bold-400">
            <span class="detail-heading font-bold-400">ID: </span>
            <span
              class="details-values font-size-12 text-align-left font-bold-600"
              >{{ item?.opportunityNumber }}
            </span>
            <span class="detail-heading font-bold-400">| Opportunity stage: </span>
            <span
              class="details-values font-size-12 text-align-left font-bold-600"
              >{{ item?.stage }}</span
            >
            <span class="detail-heading font-bold-400">| Leader: </span>
            <span
              class="details-values font-size-12 text-align-left font-bold-600"
              >{{ item?.leader }}</span
            >
            <span class="detail-heading font-bold-400"> | Client: </span>
            <span
              class="details-values font-size-12 text-align-left font-bold-600"
              >{{ item?.client }}</span
            >
          </div>
        </div>
      </div>

      <div [innerHTML]="notFound" class="not-found"></div>
    </div>
  </div>
  <div *ngIf="showOpportunityDetails" class="opportunity-details-table">
    <table class="dds-data-table dds-data-table_fixed-header">
      <thead class="dds-data-table__header">
        <tr>
          <th
            *ngFor="let column of opportunityTableColumns"
            class="dds-data-table__header-cell"
            [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
          >
            <div>
              <span>{{ column.header }}</span>
            </div>
            <div class="dds-data-table__content">
              <span> </span>
            </div>
          </th>
        </tr>
      </thead>

      <tbody class="dds-data-table__body">
        <tr
          *ngFor="let row of opportunityDetailsList; index as index"
          class="dds-data-table__row"
        >
          <td *ngFor="let column of opportunityTableColumns">
            <span
              *ngIf="column.name === 'numberOfSplits'"
              class="icon-hover"
              [ddsTooltip]="getNumberOfSplits(nonChangeOrder)"
              [tooltipWidth]="tooltipWidth"
              >{{ row[column.name] }}</span
            >
            <span *ngIf="column.name !== 'numberOfSplits'">{{
              row[column.name]
            }}</span>
          </td>
          <td>
            <img
              class="cursor-clickable"
              (click)="removeOpportunity(nonChangeOrder)"
              src="../../../assets/images/crossAction.png"
            />
          </td>
        </tr>
        <tr
          *ngFor="let row of opportunityDetailsList; index as index"
          class="dds-data-table__row"
        >
          <td *ngFor="let column of opportunityTableColumns">
            <span
              *ngIf="column.name == 'client' && (row['watchlistStatus']==='Serve with conditions' || row['watchlistStatus'] === 'Do not serve')"
              [ngClass]="{
                'serve-with-conditions-container':
                  row['watchlistStatus'] === 'Serve with conditions',
                'do-not-serve-container':
                  row['watchlistStatus'] === 'Do not serve'
              }"
            >
              {{ row["watchlistStatus"] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    class="row padding-left-s"
    *ngIf="opportunityDetailsList[0]?.watchlistStatus == 'Do not serve'"
  >
    <div class="col-11">
      <div class="container margin-top-small margin-bottom-s font-size-14 relative">
        <div class="d-flex">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="d-flex">
            <div class="initial-text">
              <span
                >Client has been flagged by our risk systems as high risk.
                Please reach out to RM (Name and Email) to discuss next
                steps.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showNoreviewNote" class="guidance-note">
    {{ getNoReviewHeader() }}
  </div>
  <div
    *ngIf="showNoreviewNote"
    class="select-opportunity-text-label font-size-16 font-bold-600 text-align-left margin-bottom-es"
  >
    Enter the opportunity ID of the original EL/SOW or prior Change Order
  </div>
  <div *ngIf="showNoreviewNote" class="select-opportunity-section">
    <div class="change-order-no-review">
      <div *ngIf="!displayChangeOrderCheck()">
        <div class="no-review-search d-flex flex-row">
          <div class="search-box">
            <dds-search
              [size]="htmlElementSize"
              [customClass]="searchOptions.customClass ?? ''"
              [icon]="searchOptions.icon || 'dds-icon_search'"
              (fieldCleared)="fieldCleared()"
              [disabled]="notAvailable"
            >
              <input
                ddsSearch
                [placeholder]="searchOptions.placeholder ?? ''"
                [(ngModel)]="selectedOportunity"
                (keyup)="selectOpportunityChanged()"
                [disabled]="notAvailable"
              />
            </dds-search>
          </div>
          <dds-checkbox
            [value]="notAvailable"
            [(ngModel)]="notAvailable"
            theme="green"
            class="checkbox-custom"
          >
          </dds-checkbox>
          <span class="not-available-header font-size-14 font-bold-400 text-align-left relative">Not Available</span>
          <span class="relative icon-s icon-info" [ddsTooltip]="gethangeOrderTooltip()" [tooltipWidth]="tooltipWidth"
            ></span
          >
        </div>
        <div
          *ngIf="showDropdown || notFound"
          class="list-of-oppportunities d-flex padding-xs"
          (scroll)="fetchOpportunitySearchResultMore($event)"
        >
          <div *ngFor="let item of opportunityList">
            <div
              class="opportunity-items d-flex flex-row-column"
              (click)="fetchOpportunityDetails(item, changeOrder)"
            >
              <div
                class="opportunity-header font-size-14 font-bold-600 text-align-left"
              >
                {{ item?.opportunityName }}
              </div>
              <div class="opportunity-details font-size-12 text-align-left font-bold-400">
                <span class="detail-heading font-bold-400">ID:</span>
                <span
                  class="details-values font-size-12 text-align-left font-bold-600"
                  >{{ item?.opportunityNumber }}
                </span>
                <span class="detail-heading font-bold-400">| Opportunity stage:</span>
                <span class="details-values">{{ item?.stage }}</span>
                <span class="detail-heading font-bold-400">| Leader:</span>
                <span class="details-values">{{ item?.leader }}</span>
                <span class="detail-heading font-bold-400"> | Client:</span>
                <span
                  class="details-values font-size-12 text-align-left font-bold-600"
                  >{{ item?.client }}</span
                >
              </div>
            </div>
          </div>

          <div [innerHTML]="notFound" class="not-found"></div>
        </div>
      </div>
    </div>
    <div *ngIf="displayChangeOrderCheck()" class="change-order-details">
      <table class="dds-data-table dds-data-table_fixed-header">
        <thead class="dds-data-table__header">
          <tr>
            <th
              *ngFor="let column of opportunityTableColumns"
              class="dds-data-table__header-cell"
              [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
            >
              <div>
                <span>{{ column.header }}</span>
              </div>
              <div class="dds-data-table__content">
                <span> </span>
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="dds-data-table__body">
          <tr
            *ngFor="let row of changeOrderList; index as index"
            class="dds-data-table__row"
          >
            <td *ngFor="let column of opportunityTableColumns">
              <span
                *ngIf="column.name === 'numberOfSplits'"
                class="icon-hover"
                [ddsTooltip]="getNumberOfSplits(changeOrder)"
                [tooltipWidth]="tooltipWidth"
                >{{ row[column.name] }}</span
              >
              <span *ngIf="column.name !== 'numberOfSplits'">{{
                row[column.name]
              }}</span>
            </td>
            <td>
              <img
                class="cursor-clickable"
                (click)="removeOpportunity(changeOrder)"
                src="../../../assets/images/crossAction.png"
              />
            </td>
          </tr>
          <tr
            *ngFor="let row of changeOrderList; index as index"
            class="dds-data-table__row"
          >
            <td *ngFor="let column of opportunityTableColumns">
              <span
                *ngIf="column.name == 'client' && (row['watchlistStatus']==='Serve with conditions' || row['watchlistStatus'] === 'Do not serve')"
                [ngClass]="{
                  'serve-with-conditions-container':
                    row['watchlistStatus'] === 'Serve with conditions',
                  'do-not-serve-container':
                    row['watchlistStatus'] === 'Do not serve'
                }"
              >
                {{ row["watchlistStatus"] }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="changeOrderDuplicateError" class="guidance-note text-align-left">
    {{ getDuplicateError() }}
  </div>
  <div *ngIf="changeOrderDuplicateError" class="guidance-note">
    {{ getDuplicateError() }}
  </div>
  <div
    class="row padding-left-s"
    *ngIf="changeOrderList[0]?.watchlistStatus == 'Do not serve'"
  >
    <div class="col-11">
      <div class="container margin-top-small margin-bottom-s d-flex flex-row-column">
        <div class="d-flex">
          <div class="icon-alert-logo-container">
            <div class="icon-alert-logo"></div>
          </div>
          <div class="d-flex">
            <div class="initial-text">
              <span
                >Client has been flagged by our risk systems as high risk.
                Please reach out to RM (Name and Email) to discuss next
                steps.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

